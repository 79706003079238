import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const JOIN_REQUESTS_POPOVER = generateActionTypes(
  'JOIN_REQUESTS_POPOVER',
  ['ACCEPT', 'DECLINE', 'UNDO', 'MARK_AS_READ', 'TOGGLE_POPOVER']
);

export const onAccept = createAction(
  JOIN_REQUESTS_POPOVER.ACCEPT,
  (accountId, joinRequestId) => ({ accountId, joinRequestId })
);

export const onDecline = createAction(
  JOIN_REQUESTS_POPOVER.DECLINE,
  (joinRequestId) => ({ joinRequestId })
);

export const onUndo = createAction(
  JOIN_REQUESTS_POPOVER.UNDO,
  (joinRequestId) => ({ joinRequestId })
);

export const markJoinRequestsAsRead = createAction(
  JOIN_REQUESTS_POPOVER.MARK_AS_READ,
  (joinRequestIds) => ({ joinRequestIds })
);

export const togglePopover = createAction(
  JOIN_REQUESTS_POPOVER.TOGGLE_POPOVER,
  (isOpen) => ({ isOpen })
);
