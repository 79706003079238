import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const UNARCHIVE_PROJECT = generateActionTypes('UNARCHIVE_PROJECT', [
  'START',
]);

export const startUnarchiveProject = createAction(
  UNARCHIVE_PROJECT.START,
  (projectId) => ({ projectId })
);
