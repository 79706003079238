import { connect } from 'react-redux';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import { showSuccessToast } from 'actions/toasts';
import { newProjectInviteLinksEnabled as newProjectInviteLinksEnabledSelector } from 'utils/featureFlags';
import { getShareLinkForProject } from 'URLs';
import { projectIdSelector } from '../../selectors';
import { isUpdatingShareLinkSelector } from '../selectors';
import { updateProjectShareLink, copyToClipboard } from '../actions';
import ProjectInviteLink from './ProjectInviteLink';

function mapStateToProps(state) {
  const projectId = projectIdSelector(state);
  const { invite_url: shortUrl, shared: isProjectShared } =
    projectEntitySelector(state, { projectId }) || {};

  const newProjectInviteLinksEnabled = newProjectInviteLinksEnabledSelector(
    state
  );

  // Conditionally setting the invite link so that we retain the past behavior
  // whereby the `getShareLinkForProject` will fallback to local url generation
  // this is especially important for demo projects, as the invite_url will be null
  // in those cases, as documented in GROW-2134 / BUGS-1663 / SBC-39
  const inviteLink =
    newProjectInviteLinksEnabled && shortUrl
      ? shortUrl
      : getShareLinkForProject(projectId, shortUrl);

  return {
    inviteLink,
    isUpdating: isUpdatingShareLinkSelector(state),
    newProjectInviteLinksEnabled,
    isProjectShared,
    projectId,
  };
}

const mapDispatchToProps = {
  updateProjectShareLink,
  showSuccessToast,
  copyToClipboard,
};

export const testExports = { mapStateToProps, mapDispatchToProps };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectInviteLink);
