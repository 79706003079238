import React from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { MEDIUM_DOWN } from 'utils/mediaQueries';
import Switch, {
  SwitchTrack,
} from '@frameio/components/src/styled-components/Switch';
import ModalHeader from 'components/Modal/FullScreenModal/Header';
import PlanCard from './PlanCard';
import PlanWithUserMaxCard from './PlanWithUserMaxCard';
import { BILL_PERIOD, PLAN_TIERS } from '../PlanDisplay';

const { MONTHLY, YEARLY } = BILL_PERIOD;

const SelectPlanContainer = styled.div`
  height: ${({ theme, isAccountOnPlanWithUserMax }) =>
    isAccountOnPlanWithUserMax ? theme.spacing.units(96) : 'auto'};
`;

const SubheaderContainer = styled.div`
  text-align: center;
  margin: ${(p) => p.theme.spacing.medium};
  margin-top: 0;
  margin-bottom: ${({ theme, isAccountOnPlanWithUserMax }) =>
    isAccountOnPlanWithUserMax ? theme.spacing.medium : theme.spacing.large};
  ${SwitchTrack} {
    vertical-align: middle;
    margin: 0 ${(p) => p.theme.spacing.tiny};
  }
`;

const HeaderContainer = styled(Flex).attrs({
  alignCenter: true,
  column: true,
})`
  padding-top: ${(p) => p.theme.spacing.micro};
`;

const PlanModalHeader = styled(ModalHeader)`
  padding-bottom: ${({ theme, isAccountOnPlanWithUserMax }) =>
    isAccountOnPlanWithUserMax
      ? theme.spacing.units(1)
      : theme.spacing.units(2)};
`;

const PlanModalSubheader = styled.span`
  color: ${(p) => p.theme.color.gray};
  ${(p) => p.theme.fontStyle.bodyM};
  padding-bottom: ${(p) => (p.small ? '16px' : p.theme.spacing.large)};
`;

const BillPeriodContainer = styled.div`
  font-size: ${(p) => p.theme.fontSize[2]};
  color: ${(p) => p.theme.color.graphiteGray};
  display: inline-block;
`;

const MonthlyAnnuallyText = styled.span`
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  color: ${({ active, theme }) => (active ? theme.color.dimGray : 'inherit')};
`;

const SaveOnAnnual = styled.div`
  color: ${(p) => p.theme.color.success};
  font-size: ${(p) => p.theme.fontSize[2]};
  line-height: ${(p) => p.theme.lineHeight[2]};
  display: inline-block;
  position: absolute;
  margin-left: ${(p) => p.theme.spacing.micro};
`;

const CardsContainer = styled.div`
  overflow-x: auto;
`;

const Cards = styled(Flex).attrs(({ isLegacy }) => ({
  wrap: `${isLegacy}`,
}))`
  min-width: ${({ theme, isAccountOnPlanWithUserMax }) =>
    isAccountOnPlanWithUserMax ? theme.spacing.units(108) : '900px'};
  width: ${({ theme, isAccountOnPlanWithUserMax }) =>
    isAccountOnPlanWithUserMax ? theme.spacing.units(108) : 'auto'};
  margin-bottom: ${(p) => p.theme.spacing.units(3)};
  justify-content: center;

  @media ${MEDIUM_DOWN} {
    min-width: ${({ isLegacy, theme }) =>
      isLegacy ? `${theme.spacing.units(110)}` : '100%'};
  }
`;

const LegacyCardsContainer = styled.div`
  overflow: auto;
`;

class SelectPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPayPeriod: props.isYearlyPlanForbidden
        ? MONTHLY
        : props.currentPlan.period,
    };
  }

  getSubheader = () => {
    const { selectedPayPeriod } = this.state;
    const { isAccountOnPlanWithUserMax, isOnV6Plan } = this.props;
    const isMonthly = selectedPayPeriod === MONTHLY;
    return (
      <SubheaderContainer
        isAccountOnPlanWithUserMax={isAccountOnPlanWithUserMax}
      >
        <BillPeriodContainer>
          <MonthlyAnnuallyText active={isMonthly}>Monthly</MonthlyAnnuallyText>
          <Switch isOn={!isMonthly} onChange={this.togglePayPeriod} />
          <MonthlyAnnuallyText active={!isMonthly}>
            Annually
          </MonthlyAnnuallyText>
        </BillPeriodContainer>
        <SaveOnAnnual>{`(save ${isOnV6Plan ? '12%' : '10%'})`}</SaveOnAnnual>
      </SubheaderContainer>
    );
  };

  togglePayPeriod = () => {
    const { selectedPayPeriod } = this.state;
    const newSelectedPayPeriod =
      selectedPayPeriod === YEARLY ? MONTHLY : YEARLY;
    this.setState({ selectedPayPeriod: newSelectedPayPeriod });
  };

  // Legacy users can choose between 5 plans (instead of 3 plans for v2 users and above).
  renderLegacyPlansCards = () => {
    const { selectedPayPeriod } = this.state;
    const { chatWithOurTeam, currentPlan, plans, isLegacy } = this.props;

    const enterprisePlan = plans.Enterprise[selectedPayPeriod];

    return (
      <React.Fragment>
        <LegacyCardsContainer>
          <Cards isLegacy={isLegacy}>
            {Object.values(plans).map((plan) => {
              const thisPlan = plan[selectedPayPeriod];
              const isEnterprise = thisPlan.title === 'Enterprise';
              if (!isEnterprise) {
                return (
                  <PlanCard
                    isSelected={thisPlan.name === currentPlan.name}
                    key={thisPlan.title}
                    plan={thisPlan}
                    selectPlan={this.props.continueFlow}
                    onChatWithOurTeamClick={chatWithOurTeam}
                    isLegacy={isLegacy}
                  />
                );
              }
              return null;
            })}
          </Cards>
        </LegacyCardsContainer>
        <Cards>
          <PlanCard
            isSelected={false}
            plan={enterprisePlan}
            selectPlan={this.props.continueFlow}
            onChatWithOurTeamClick={chatWithOurTeam}
            isLegacy={isLegacy}
          />
        </Cards>
      </React.Fragment>
    );
  };

  // v2 users and above can choose between 3 plans (instead of 5 plans for legacy users).
  renderNonLegacyPlansCards = () => {
    const { selectedPayPeriod } = this.state;
    const { chatWithOurTeam, currentPlan, onTrial, plans } = this.props;

    const plansForPayPeriod = plans[selectedPayPeriod];

    return (
      <Cards>
        {plansForPayPeriod.map((plan) => {
          return (
            <PlanCard
              isSelectedTrial={plan.name === currentPlan.name && onTrial}
              isSelected={plan.name === currentPlan.name && !onTrial}
              key={plan.title}
              plan={plan}
              selectPlan={this.props.continueFlow}
              onChatWithOurTeamClick={chatWithOurTeam}
            />
          );
        })}
      </Cards>
    );
  };

  renderPlanWithUserMaxCards = () => {
    const { selectedPayPeriod } = this.state;
    const {
      chatWithOurTeam,
      continueFlow,
      currentPlan,
      plans,
      isAccountOnPlanWithUserMax,
      isAdobeBridgeOfferEligible,
      onTrial,
    } = this.props;

    return (
      <Cards isAccountOnPlanWithUserMax={isAccountOnPlanWithUserMax}>
        {plans[selectedPayPeriod].map((plan) => {
          const isFreeAdobePlanSelected =
            isAdobeBridgeOfferEligible &&
            plan.tier === PLAN_TIERS.FREE &&
            currentPlan.tier === PLAN_TIERS.FREE;
          return (
            <PlanWithUserMaxCard
              isAdobeBridgeOfferEligible={isAdobeBridgeOfferEligible}
              isSelectedTrial={plan.name === currentPlan.name && onTrial}
              isSelected={
                plan.name === currentPlan.name || isFreeAdobePlanSelected
              }
              key={plan.title}
              plan={plan}
              selectPlan={continueFlow}
              onChatWithOurTeamClick={chatWithOurTeam}
            />
          );
        })}
      </Cards>
    );
  };

  renderPlanCards = () => {
    const { isLegacy, isAccountOnPlanWithUserMax } = this.props;

    if (isAccountOnPlanWithUserMax) {
      return this.renderPlanWithUserMaxCards();
    }
    if (isLegacy) {
      return this.renderLegacyPlansCards();
    }
    return this.renderNonLegacyPlansCards();
  };

  getHeaderText = () => {
    const { isAdobeBridgeOfferEligible, countdown } = this.props;
    const shouldShowCountdown = countdown <= 3;

    if (!isAdobeBridgeOfferEligible || !shouldShowCountdown) {
      return 'Keep the flow going';
    }

    if (countdown <= 0) {
      return 'Your trial has ended';
    }

    return `Your free trial ends in ${countdown} ${
      countdown === 1 ? 'day' : 'days'
    }. Choose a plan.`;
  };

  getHeader = () => {
    const {
      onTrial,
      isAccountOnPlanWithUserMax,
      isAdobeBridgeOfferEligible,
    } = this.props;
    if (onTrial) {
      return (
        <HeaderContainer>
          <PlanModalHeader>{this.getHeaderText()}</PlanModalHeader>
          <PlanModalSubheader small={isAdobeBridgeOfferEligible}>
            Your trial is about to end. Start your subscription to keep using
            premium features.
          </PlanModalSubheader>
        </HeaderContainer>
      );
    }
    return (
      <HeaderContainer>
        <PlanModalHeader
          isAccountOnPlanWithUserMax={
            (isAccountOnPlanWithUserMax, isAdobeBridgeOfferEligible)
          }
        >
          Choose your plan
        </PlanModalHeader>
        <PlanModalSubheader small={isAdobeBridgeOfferEligible}>
          You can upgrade or downgrade at any time.
        </PlanModalSubheader>
      </HeaderContainer>
    );
  };

  render() {
    const {
      isAccountOnPlanWithUserMax,
      isLegacy,
      isYearlyPlanForbidden,
      plans,
    } = this.props;

    const hasYearlyPlans = isLegacy || plans.yearly?.length > 0;

    return (
      <SelectPlanContainer
        isAccountOnPlanWithUserMax={isAccountOnPlanWithUserMax}
      >
        {this.getHeader()}
        {!isYearlyPlanForbidden && hasYearlyPlans && this.getSubheader()}
        <CardsContainer>{this.renderPlanCards()}</CardsContainer>
      </SelectPlanContainer>
    );
  }
}

SelectPlan.defaultProps = {
  currentPlan: {},
};

SelectPlan.propTypes = {
  chatWithOurTeam: Proptypes.func.isRequired,
  continueFlow: Proptypes.func.isRequired,
  currentPlan: Proptypes.object,
  isLegacy: Proptypes.bool.isRequired,
  plans: Proptypes.object.isRequired,
  onTrial: Proptypes.bool.isRequired,
  countdown: Proptypes.number,
  isAdobeBridgeOfferEligible: Proptypes.bool,
};

export const testExports = {
  BILL_PERIOD,
  PlanCard,
  PlanModalHeader,
};

export default SelectPlan;
