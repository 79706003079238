import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { teamEntitySelector } from '@frameio/core/src/teams/selectors';
import {
  isAccountAdminSelector,
  isTeamManagerForTeamSelector,
} from 'selectors/roles';

export permittedActionsForAccountSelector from './permittedActionsForAccountSelector';
export permittedActionsForCommentSelector from './permittedActionsForCommentSelector';
export permittedActionsForAssetsSelector from './permittedActionsForAssetsSelector';
export permittedActionsForProjectSelector from './permittedActionsForProjectSelector';

export const canUserToggleLifecyclePolicy = createSelector(
  teamEntitySelector,
  isTeamManagerForTeamSelector,
  (state) => state,
  (team = {}, isTeamManager, state) => {
    const { account_id: accountId } = team;
    const isAccountAdmin = isAccountAdminSelector(state, { accountId });

    if (!team.asset_lifecycle_policy) return false;
    if ((team.admin_only_actions || {}).lifecycle) {
      return isAccountAdmin;
    }
    return isTeamManager || isAccountAdmin;
  }
);

export const projectPermissionsPropTypes = {
  canDelete: PropTypes.bool.isRequired,
  canDeleteProject: PropTypes.bool.isRequired,
  canDownload: PropTypes.bool.isRequired,
  canCopyToProject: PropTypes.bool.isRequired,
  canDownloadFolders: PropTypes.bool.isRequired,
  canInviteCollaborators: PropTypes.bool.isRequired,
  canRemoveCollaborators: PropTypes.bool.isRequired,
  canMoveToFolder: PropTypes.bool.isRequired,
  canPublishToDropbox: PropTypes.bool.isRequired,
  canPublishToVimeo: PropTypes.bool.isRequired,
  canRename: PropTypes.bool.isRequired,
  canResetAssetLifecycle: PropTypes.bool.isRequired,
  canRestoreDeletedAssets: PropTypes.bool.isRequired,
  canShareReview: PropTypes.bool.isRequired,
  canSharePresentation: PropTypes.bool.isRequired,
  canSharePublicly: PropTypes.bool.isRequired,
  canToggleInviteCollaborators: PropTypes.bool.isRequired,
  canTogglePrivacy: PropTypes.bool.isRequired,
  canToggleSharePresentations: PropTypes.bool.isRequired,
  canUseTeamComments: PropTypes.bool.isRequired,
  canChangeUserProjectRole: PropTypes.bool.isRequired,
  canArchiveProject: PropTypes.bool.isRequired,
  canUnarchiveProject: PropTypes.bool.isRequired,
  canManageVersions: PropTypes.bool.isRequired,
};

export const assetPermissionsPropTypes = {
  ...projectPermissionsPropTypes,
  canAccessAemIntegration: PropTypes.bool,
  canEditDescription: PropTypes.bool.isRequired,
  canHaveCustomActions: PropTypes.bool.isRequired,
  canSetStatus: PropTypes.bool.isRequired,
};

export const commentPermissionsPropTypes = {
  canDelete: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canComplete: PropTypes.bool.isRequired,
  canSeeReadBy: PropTypes.bool.isRequired,
  canUpVote: PropTypes.bool.isRequired,
};

export const allProjectPermissions = Object.keys(
  projectPermissionsPropTypes
).reduce(
  (permissions, name) => ({
    ...permissions,
    [name]: true,
  }),
  {}
);

export const noAssetPermissions = Object.keys(assetPermissionsPropTypes).reduce(
  (permissions, name) => ({
    ...permissions,
    [name]: false,
  }),
  {}
);

export const allAssetPermissions = Object.keys(
  assetPermissionsPropTypes
).reduce(
  (permissions, name) => ({
    ...permissions,
    [name]: true,
  }),
  {}
);
