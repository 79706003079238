import { CONTEXT_MENU } from './actions';

const INITIAL_STATE = {
  contextMenuType: null,
  isShowing: false,
  x: 0,
  y: 0,
  menuProps: {},
};

export default function(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case CONTEXT_MENU.OPEN: {
      const { contextMenuType, x, y, menuProps } = payload;
      return {
        contextMenuType,
        isShowing: true,
        x,
        y,
        menuProps,
      };
    }
    case CONTEXT_MENU.CLOSE: {
      return {
        ...state,
        isShowing: false,
      };
    }
    default: {
      return state;
    }
  }
}

export const testExports = { INITIAL_STATE };
