// Workfront constants
export const WORKFRONT = 'Workfront';
export const CONNECTED_TASK = 'Connected Task';
export const FAILED_TASK_MESSAGE = 'No task details available';
export const STATUS = 'Task Status';

// The task status label keys (INP, CPL) are matching on the status codes that we
// receive from workfront and are the values they require for updating.
export const MAP_TASK_STATUSES = {
  COMPLETE: 'CPL',
  IN_PROGRESS: 'INP',
  NEW: 'NEW',
};

export const MENU_ITEMS = {
  ADD_TO_WORKFRONT: 'Add to Workfront',
  ADD_TO_TASK: 'Add to Task…',
  ADD_TO_PROJECT_DOCUMENTS: 'Add to Project Documents',
  OPEN_IN_WORKFRONT: 'Open in Workfront',
  SEND_TO_AEM: 'Send to AEM Assets',
  AEM_DROPDOWN_PROMPT: 'Repositories…',
};

export const UPDATE_TASK_STATUS = {
  CHANGE_TO_IN_PROGRESS: 'Change to In Progress',
  MARK_AS_DONE: 'Mark as Done',
};

export const ERROR_MESSAGES = {
  aemPartialSendFailure:
    'There was a problem sending some of the assets to AEM Assets',
  aemPermissionFailure: 'You do not have access to AEM Assets',
  aemRepositoryEmpty: 'No Repositories are Available.',
  aemSendFailure: 'There was a problem sending the assets to AEM Assets',
  connectionFailure: 'There was a problem adding to Workfront',
  connectionPartialSuccess:
    'There was a problem adding some of the assets to Workfront',
  fetchAemFolderFailure: 'Failed to fetch AEM Assets folders',
  fetchAemRepositoryFailure: 'Failed to fetch a repository from AEM Assets',
  fetchTaskFailure: 'Failed to fetch tasks',
  taskUpdate: 'There was a problem updating the Workfront task status',
};

export const SUCCESS_MESSAGES = {
  aemSendSuccess: 'The selected assets were sent to AEM Assets',
};

export const DEFAULT_TASK_ID = '';
export const DEFAULT_TASK_TITLE = '';

// Adobe Experience Manager (AEM) constants
export const AEM = 'AEM';
