import { get } from 'lodash';
import ROLES from '@frameio/core/src/roles/helpers/constants';
import {
  roleDataForAccountIdSelector,
  roleDataForTeamIdSelector,
  roleDataForProjectIdSelector,
  rolesEntitiesByAccountSelector,
  rolesEntitiesByProjectSelector,
  rolesEntitiesByTeamSelector,
} from '@frameio/core/src/roles/selectors';
import {
  accountEntityForProjectIdSelector,
  teamEntityForProjectIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import { createSelector } from 'reselect';

export const shouldFetchAccountRole = (state, { accountId }) =>
  accountId && !rolesEntitiesByAccountSelector(state)[accountId];

export const shouldFetchProjectRole = (state, { projectId }) =>
  projectId && !rolesEntitiesByProjectSelector(state)[projectId];

export const shouldFetchTeamRole = (state, { teamId }) =>
  teamId && !rolesEntitiesByTeamSelector(state)[teamId];

/**
 * @returns {string} - User's highest-scoped role with respect to a given account id.
 */
export const highestScopedAccountRoleSelector = createSelector(
  roleDataForAccountIdSelector,
  (state, { accountId }) => accountId,
  (roleDataForAccount = {}) => {
    const { role, reviewer: isReviewer } = roleDataForAccount;
    return !role && isReviewer ? ROLES.REVIEWER : role;
  }
);

/**
 * @returns {string} - User's highest-scoped role with respect to a given project id.
 */
export const highestScopedProjectRoleSelector = createSelector(
  roleDataForProjectIdSelector,
  (state, { projectId }) => projectId,
  (roleDataForProject = {}) => {
    // Accessing this extra .roles property is redundant. See web-core comment here:
    // https://github.com/Frameio/web-core/blob/9dfeb0602c6d5e1e1f1a6de4240b72b0ae5e8e2e/src/roles/selectors/index.js#L2
    if (!roleDataForProject.roles) return undefined;

    const {
      collaborator: isCollaborator,
      team_role: teamRole,
    } = roleDataForProject.roles;

    return teamRole || (isCollaborator && ROLES.COLLABORATOR);
  }
);

/**
 * @returns {string} - User's highest-scoped role with respect to a given team id.
 */
export const highestScopedTeamRoleSelector = createSelector(
  roleDataForTeamIdSelector,
  (state, { teamId }) => teamId,
  (roleDataForTeam = {}) =>
    // Accessing this extra .role property is redundant. See web-core comment here:
    // https://github.com/Frameio/web-core/blob/9dfeb0602c6d5e1e1f1a6de4240b72b0ae5e8e2e/src/roles/selectors/index.js#L2
    roleDataForTeam.role
);

/**
 * @returns {boolean} - Whether or not a user is an account owner for a given account.
 * @param {string} accountId - Account id.
 */
export const isAccountOwnerSelector = createSelector(
  highestScopedAccountRoleSelector,
  (accountRole) => accountRole === ROLES.OWNER
);

/**
 * @returns {boolean} - Whether or not a user's highest-scoped account role is 'reviewer'.
 */
export const isReviewerHighestRoleSelector = createSelector(
  highestScopedAccountRoleSelector,
  (accountRole) => accountRole === ROLES.REVIEWER
);

/**
 * @returns {boolean} - Whether or not a user is a team member of a given project.
 */
export function isTeamMemberSelector(state, { projectId }) {
  let role;
  role = highestScopedProjectRoleSelector(state, { projectId });

  // Fallback to role by team
  if (!role) {
    const team = teamEntityForProjectIdSelector(state, { projectId }) || {};
    const { id: teamId } = team;
    role = highestScopedTeamRoleSelector(state, { teamId });

    // If team role isn't available in roles data, fallback to user_role in team entity.
    if (!role && team.user_role) {
      role = team.user_role.team_role;
    }
  }

  return role === ROLES.TEAM_MANAGER || role === ROLES.TEAM_MEMBER;
}

export const isTeamMemberForTeamSelector = createSelector(
  highestScopedTeamRoleSelector,
  (teamRole) =>
    teamRole === ROLES.TEAM_MANAGER || teamRole === ROLES.TEAM_MEMBER
);

/**
 * @returns {boolean} - Whether or not a user is a team manager of a given project.
 */
export function isTeamManagerSelector(state, { projectId }) {
  let role;
  role = highestScopedProjectRoleSelector(state, { projectId });

  if (!role) {
    // Fallback to role by team
    const { id: teamId } =
      teamEntityForProjectIdSelector(state, { projectId }) || {};
    role = highestScopedTeamRoleSelector(state, { teamId });
  }
  return role === ROLES.TEAM_MANAGER;
}

/**
 * @returns {boolean} - Whether or not a user is a team manager of a given team.
 */
export function isTeamManagerForTeamSelector(state, { teamId }) {
  return (
    highestScopedTeamRoleSelector(state, { teamId }) === ROLES.TEAM_MANAGER
  );
}

/**
 * @returns {boolean} - Whether or not a user's highest role for a given project is 'collaborator';
 */
export const isCollaboratorOnlySelector = createSelector(
  highestScopedProjectRoleSelector,
  (state, { projectId }) => projectId,
  (state) => state,
  (projectRole, projectId, state) => {
    let role = projectRole;

    if (!role) {
      // Fallback to role by team
      const { id: teamId } =
        teamEntityForProjectIdSelector(state, { projectId }) || {};
      role = highestScopedTeamRoleSelector(state, { teamId });

      role = !role && ROLES.COLLABORATOR;
    }

    return role === ROLES.COLLABORATOR;
  }
);

/**
 * @param {Object} state - Redux store state.
 * @param {Object} props - Props, must include `projectId`.
 * @returns {boolean} - Whether or not a user is a collaborator (e.g. is in
 * the collaborator table) of the project given by the `projectId`.
 * NOTE: Any user that is a collaborator on a project will receive notifications.
 */
export const isUserCollaboratorOfProjectSelector = createSelector(
  roleDataForProjectIdSelector,
  (projectRoleData) => get(projectRoleData, 'roles.collaborator')
);

/**
 * @param {string} projectId - Project id.
 * @returns {string} - A user‘s highest scoped role across account, team, and project context.
 *
 * NOTE: Because we are consistently fetching `byProject` roles data on project entry, we are
 * able to derive the highest-scoped role from a _project_ and _team_ context, but we still need
 * `byAccount` data to ensure that we return the role of highest precendence (e.g. 'owner',
 * 'admin', 'reviewer', etc.).
 */
export const highestRoleForProjectIdSelector = createSelector(
  highestScopedProjectRoleSelector,
  accountEntityForProjectIdSelector,
  (state) => state,
  (projectRole, account, state) => {
    const { id: accountId } = account || {};
    const accountRole = highestScopedAccountRoleSelector(state, { accountId });
    return accountRole || projectRole;
  }
);

/**
 *  ⚠️ Please read before using this selector! ⚠️
 * The most reliable way to derive a user's _specific role_ is on a project-level, as we can
 * definitively determine if the user is a collaborator or not. If project-context is available,
 * please use `highestRoleForProjectIdSelector` instead.
 *
 * In some instances, we may only have account-level context (e.g. any Account route: Search,
 * Inbox, etc.) and therefore can only derive the user's _highest-scoped account or team role_.
 *
 * Ex. 1: A user is a Non-Owner/Non-Admin on an account that contains multiple teams, some of
 * which the user manages (Team Manager), and other teams that the user is only a Team Member of.
 * ==> This selector will return `team_manager`.
 *
 * Ex. 2: A user is an Non-Owner/Admin on an account that contains multiple teams, and is
 * automatically a Team Manager of any teams that they are members of.
 * ==> This selector will return `admin`.
 *
 * Ex. 3: A user is a Non-Owner/Non-Admin on an account that contains multiple teams, some of
 * which the user has been invited to as a Team Member, and other teams containing projects that
 * the user has been invited to as a Collaborator.
 * ==> This selector will return `member`.
 *
 * @param {string} accountId - Account id.
 * @returns {string} - User's highest-scoped account or team role for a given accountid.
 */
export const highestAccountOrTeamRoleForAccountIdSelector = createSelector(
  roleDataForAccountIdSelector,
  (roleDataForAccount = {}) => {
    const {
      role: accountRole,
      reviewer: isReviewer,
      team_role: teamRole,
    } = roleDataForAccount;
    return accountRole || (isReviewer ? ROLES.REVIEWER : undefined) || teamRole;
  }
);

/**
 * @param {string} accountId - Account id.
 * @returns {boolean} - Whether or not a user is an account admin for a given account.
 */
export const isAccountAdminSelector = createSelector(
  highestAccountOrTeamRoleForAccountIdSelector,
  (accountRole) => accountRole === ROLES.OWNER || accountRole === ROLES.ADMIN
);
