import React from 'react';
import CancelSVG from '@frameio/components/src/svgs/icons/cancel.svg';
import Button from '@frameio/components/src/styled-components/Button';
import PulsingCircle from '@frameio/components/src/styled-components/PulsingCircle';
import PropTypes from 'prop-types';
import { SMALL } from 'utils/mediaQueries';
import styled, { css } from 'styled-components';
import ShadowContainer from 'components/ShadowContainer';

import Flex from 'styled-flex-component';
import ManageVersionStack, {
  AssetWrapper,
  VersionList,
  VersionBadge,
  VersionWrapper,
  ROW_HEIGHT,
} from 'components/ManageVersionStack';

const ModalWrapper = styled.div`
  position: relative;
`;

const Title = styled.div`
  ${(p) => p.theme.fontStyle.heading}
`;

const Header = styled(Flex).attrs(() => ({
  justifyBetween: true,
}))`
  padding: ${(p) => p.theme.spacing.medium};
`;

const CancelIcon = styled(CancelSVG)`
  width: 24px;
  height: 24px;
  color: ${(p) => p.theme.color.lightGray};
`;

const VersionNumber = styled.div`
  ${(p) => p.theme.fontStyle.caption};
  color: ${(p) => p.theme.color.graphiteGray};
`;

const ContentWrapper = styled(ShadowContainer)`
  ${({ theme, totalVersions }) => `
    display: flex;
    width: ${theme.spacing.units(52)};
    min-height: ${theme.spacing.units(40)};
    height: ${totalVersions * ROW_HEIGHT}px;
    max-height: 70vh;
    overflow-y: auto;

    @media ${SMALL} {
      width: ${theme.spacing.units(43)};
    }
  `}
`;

// The version management component has certain styles when displayed
// inside a modal on the project dashboard
const StyledManageVersionStack = styled(ManageVersionStack)`
  ${({ theme }) => css`
    width: 100%;

    ${AssetWrapper} {
      /* Assets should always have a divider above them in the modal
        on the project dashboard */
      box-shadow: 0 1px 0 0 ${theme.color.accentGrayLight} inset;
    }

    ${VersionList} {
      padding-top: 0;
      width: 100%;
      max-height: 100%;
    }

    ${VersionBadge} {
      margin-right: ${theme.spacing.medium};
    }

    ${VersionWrapper} {
      padding: 0 ${theme.spacing.medium};
    }

    @media ${SMALL} {
      ${VersionBadge} {
        margin-right: ${theme.spacing.small};
      }

      ${VersionWrapper} {
        padding: 0 ${theme.spacing.small};
      }
    }
  `}
`;

export default function ManageVersionStackModal({
  isFetching,
  closeManageVersionStack,
  totalVersions,
  assetId,
  removeVersionFromStack,
  reorderVersionInStack,
}) {
  return (
    <ModalWrapper>
      <Header>
        <div>
          <Title>Manage version stack</Title>
          <VersionNumber>
            {totalVersions} {totalVersions === 1 ? 'version' : 'versions'}
          </VersionNumber>
        </div>
        <Button icon="true" text onClick={closeManageVersionStack}>
          <CancelIcon />
        </Button>
      </Header>
      <ContentWrapper totalVersions={totalVersions}>
        {isFetching ? (
          <PulsingCircle />
        ) : (
          <StyledManageVersionStack
            assetId={assetId}
            trackingPage="project_view"
            removeVersionFromStack={removeVersionFromStack}
            reorderVersionInStack={reorderVersionInStack}
          />
        )}
      </ContentWrapper>
    </ModalWrapper>
  );
}

ManageVersionStackModal.defaultProps = {
  totalVersions: 0,
  isFetching: false,
};

ManageVersionStackModal.propTypes = {
  assetId: PropTypes.string.isRequired,
  totalVersions: PropTypes.number,
  closeManageVersionStack: PropTypes.func.isRequired,
  removeVersionFromStack: PropTypes.func.isRequired,
  reorderVersionInStack: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
};

export const testExports = {
  VersionNumber,
  StyledManageVersionStack,
};
