import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import LifetimeFileLimitReachedFlow from './LifetimeFileLimitReachedFlow';
import { startFlow, endFlow } from './actions';

const mapDispatchToProps = {
  closeModal,
  endFlow,
  startFlow,
};

export default connect(
  null,
  mapDispatchToProps
)(LifetimeFileLimitReachedFlow);
