import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  createJoinRequestForProject,
  createJoinRequestForAsset,
  createJoinRequestForPresentation,
  createJoinRequestForReviewLink,
} from '@frameio/core/src/joinRequests/sagas';
import { currentAccountSelector } from 'selectors/accounts';
import { redirectToAccount } from 'pages/AccountContainer/sagas';
import { closeModal } from 'components/Modal/actions';
import { REQUEST_ACCESS_TO_ENTITY } from './actions';
import { ENTITY_TYPE } from '../AuthGate/AuthGate';

function* requestAccessToAsset(assetId) {
  yield call(createJoinRequestForAsset, assetId);
}

function* requestAccessToProject(projectId) {
  yield call(createJoinRequestForProject, projectId);
}

function* requestAccessToPresentation(presentationId) {
  yield call(createJoinRequestForPresentation, presentationId);
}

function* requestAccessToReviewLink(reviewLinkId) {
  yield call(createJoinRequestForReviewLink, reviewLinkId);
}

function requestAccessToEntity(entityType, entityId) {
  switch (entityType) {
    case ENTITY_TYPE.ASSET:
      return requestAccessToAsset(entityId);
    case ENTITY_TYPE.PROJECT:
      return requestAccessToProject(entityId);
    case ENTITY_TYPE.PRESENTATION:
      return requestAccessToPresentation(entityId);
    case ENTITY_TYPE.REVIEW_LINK:
      return requestAccessToReviewLink(entityId);
    default:
      return undefined;
  }
}

function* returnToAccount() {
  const { id: accountId } = yield select(currentAccountSelector);
  yield call(redirectToAccount, accountId);
  yield put(closeModal());
}

export default [
  takeLatest(
    REQUEST_ACCESS_TO_ENTITY.REQUEST_ACCESS,
    ({ payload: { entityType, entityId } }) =>
      requestAccessToEntity(entityType, entityId)
  ),
  takeLatest(REQUEST_ACCESS_TO_ENTITY.RETURN_TO_ACCOUNT, returnToAccount),
];
