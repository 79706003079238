import React from 'react';
import PropTypes from 'prop-types';

/**
 * Project Settings Subheader component.
 * @param {Object} props - The props for the subheader.
 * @returns {ReactElement} A project settings subheader.
 */
function ProjectFormSubheader({ text }) {
  return (
    <div className="ph4 pv2 bg-fio-light-dark-gray">
      <div className="fio-dark-gray">{text}</div>
    </div>
  );
}

ProjectFormSubheader.propTypes = {
  text: PropTypes.string,
};

ProjectFormSubheader.defaultProps = {
  text: '',
};

export default ProjectFormSubheader;
