import { ASSET as CORE_ASSET } from '@frameio/core/src/assets/actions';
import { ASSET as CLIENT_ASSET } from 'actions/assets';
import { MODAL } from 'components/Modal/actions';
import { MOVE_TO, FolderCreationEvents } from './actions';

/**
 * Basic folder asset tree
 * note: INITIAL_STATE is provided by the project container reducer
 */

const INITIAL_TREE_STATE = {
  tree: {},
  loading: false,
  loaded: false,
  error: false,
};

function projectTreeReducer(state = INITIAL_TREE_STATE, action) {
  switch (action.type) {
    case MOVE_TO.FETCH_START:
      return {
        ...INITIAL_TREE_STATE,
        // do not delete the existing tree. so we do not get a collapse
        // while fetching the same tree.
        tree: state.tree,
        error: false,
        loading: true,
        loaded: false,
      };
    case MOVE_TO.FETCH_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        loaded: false,
      };
    case MOVE_TO.FETCH_SUCCESS:
      return {
        loading: false,
        error: false,
        tree: action.payload.tree,
        loaded: true,
      };
    default:
      return state;
  }
}

export const INITIAL_STATE = {
  loaded: true,
  // selected is used to copy to or move to.
  selected: undefined,
  expanded: [],
  error: false,
  currentLocationId: undefined,
  assetFolderTrees: {},
  isSaving: false,
  // this is the selectID of a folder that is selected when traversing the tree.
  selectedTreeItem: {
    id: undefined,
    createdId: undefined,
    canCreateFolder: undefined,
  },
  folderCreationState: FolderCreationEvents.NONE,
};

export default function moveToFolderReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MOVE_TO.RESET:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case MOVE_TO.IS_SAVING:
      return {
        ...state,
        isSaving: action.payload.isSaving,
      };
    case MOVE_TO.SELECT_FOLDER:
      return {
        ...state,
        selected: action.payload.assetId,
      };
    case MOVE_TO.EXPAND_FOLDER:
      return {
        ...state,
        expanded: action.payload.expanded,
      };
    case MOVE_TO.FETCH_START:
      return {
        ...state,
        assetFolderTrees: {
          ...state.assetFolderTrees,
          [action.payload.projectId]: projectTreeReducer(
            state.assetFolderTrees[action.payload.projectId],
            action
          ),
        },
      };
    case MOVE_TO.FETCH_ERROR:
      return {
        ...state,
        assetFolderTrees: {
          ...state.assetFolderTrees,
          [action.payload.projectId]: projectTreeReducer(
            state.assetFolderTrees[action.payload.projectId],
            action
          ),
        },
        selectedTreeItem: {
          ...state.selectedTreeItem,
        },
        folderCreationState: FolderCreationEvents.NONE,
      };
    case MOVE_TO.FETCH_SUCCESS:
      return {
        ...state,
        assetFolderTrees: {
          ...state.assetFolderTrees,
          [action.payload.projectId]: projectTreeReducer(
            state.assetFolderTrees[action.payload.projectId],
            action
          ),
        },
        selectedTreeItem: {
          ...state.selectedTreeItem,
          id: state.selectedTreeItem.createdId || state.selectedTreeItem.id,
          createdId: undefined,
        },
        folderCreationState: FolderCreationEvents.NONE,
      };
    case MOVE_TO.UPDATE_SELECTED_TREE_ITEM:
      return {
        ...state,
        selectedTreeItem: {
          id: action.payload.selectedId,
          canCreateFolder: action.payload.canCreateFolder,
        },
      };
    case MOVE_TO.UPDATE_FOLDER_CREATION:
      return {
        ...state,
        folderCreationState: action.payload.folderStatus,
      };
    case CLIENT_ASSET.CREATE_NEW_FOLDER:
      return {
        ...state,
        folderCreationState: FolderCreationEvents.IS_SAVING,
      };
    case CORE_ASSET.CREATE.FAILURE:
      /**
       * edge case, if folder creation fails it fails silently.
       * but at least the state will be updated to allow users to try again.
       */
      return {
        ...state,
        folderCreationState: FolderCreationEvents.NONE,
      };
    case CORE_ASSET.CREATE.SUCCESS:
      /**
       * This exact scenario should be very specific and aligns with
       * when a folder is being created
       * And we receive confirmation of creation.
       */
      if (state.folderCreationState === FolderCreationEvents.IS_SAVING) {
        return {
          ...state,
          folderCreationState: FolderCreationEvents.IS_CREATED,
          selectedTreeItem: {
            ...state.selectedTreeItem,
            // we are not ready to make the new folder ACTIVE/SELECTED until
            // the folder/project has been forced fetched with the new tree.
            createdId: action.payload.response.result,
            /**
             * If issues persist toggle to `false` to prevent the user from
             * clicking twice in a row on the create button
             * this solves issues with expanding multiple folders while still
             * keeping the ability to traverse the tree structure with the keyboard.
             */
            canCreateFolder: true,
          },
        };
      }
      return state;
    case MODAL.CLOSE:
      // Following "Cancel" and Move/Copy the Modal will close,
      // at which point let's clean up the store.
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
}
