import React from 'react';
import { get } from 'lodash';
import { useFormikContext } from 'formik';
import Flex from 'styled-flex-component';
import { withTheme } from 'styled-components';
import track from 'analytics';
import { Text, StyledCheckbox } from '../styles';

const SessionWatermarkCheckboxListItem = (props) => {
  const {
    children,
    className,
    theme,
    label,
    name,
    order,
    value,
    disabled,
    ...rest
  } = props;
  const {
    setFieldValue,
    values,
    values: { id, activeBlockId },
  } = useFormikContext();
  const dataPoints =
    get(values, ['watermark_blocks', activeBlockId, 'data_points']) || {};
  const checked = Boolean(get(dataPoints, [name, 'enabled']));
  const isOnlyOneItemChecked =
    Object.values(dataPoints).filter((item) => item.enabled).length === 1;

  return (
    <Flex alignCenter wrap={1}>
      <Text
        as="label"
        color={disabled ? 'accentGrayMedium' : 'coolBlack'}
        variant="body"
      >
        <StyledCheckbox
          {...rest}
          size={theme.spacing.units(2)}
          className={className}
          disabled={disabled || (checked && isOnlyOneItemChecked)}
          gutter={[0, 1.5, 0, 0]}
          data-test-id="checkbox"
          checked={checked}
          onChange={(e) => {
            track('session-watermark-template-editor-action', {
              datapoint_name: name,
              value: e.target.checked,
              watermark_template_id: id,
            });

            const path = [
              'watermark_blocks',
              activeBlockId,
              'data_points',
              name,
            ];

            setFieldValue(path, { enabled: e.target.checked, value, order });
          }}
        />
        {label}
      </Text>

      {children}
    </Flex>
  );
};

export default withTheme(SessionWatermarkCheckboxListItem);
