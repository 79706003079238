import { connect } from 'react-redux';
import { ARCHIVED_STORAGE_LIMIT } from '@frameio/core/src/subscriptionLineItems/utils/modifierTypes';
import { planLineItemForModifierAndPlanSelector } from '@frameio/core/src/planLineItems/selectors';
import { currentAccountWithSubscriptionAndPlanSelector } from 'selectors/accounts';
import { closeModal } from 'components/Modal/actions';
import { updateSubscriptionLineItem } from './actions';
import {
  isUpdatingSubscription,
  subscriptionLineItemSelector,
} from './selectors';
import ManageArchivalStorageModal from './ManageArchivalStorageModal';

export const mapDispatchToProps = {
  closeModal,
  updateItem: updateSubscriptionLineItem,
};

export function mapStateToProps(state) {
  const { account, plan } = currentAccountWithSubscriptionAndPlanSelector(
    state
  );
  const accountId = account.id;
  const storageLineItem = subscriptionLineItemSelector(state, {
    accountId,
    plan,
    modifier: ARCHIVED_STORAGE_LIMIT,
  });
  const planLineItem = planLineItemForModifierAndPlanSelector(state, {
    modifier: ARCHIVED_STORAGE_LIMIT,
    planId: plan.id,
  });

  return {
    isSubmitting: isUpdatingSubscription(state),
    planLineItem,
    planLimit: plan.archived_storage_limit,
    planPeriod: plan.period,
    totalStorage: storageLineItem.totalCount,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageArchivalStorageModal);
