import LinkifyIt from 'linkify-it';
import { uniqBy } from 'lodash';
import replaceAll from 'string.prototype.replaceall';

const wrapLinkText = (match) =>
  `<a class="white-85 no-underline hover-white" target="_blank" href="${match.url}">${match.raw}</a>`;

export default function linkText(text, wrapLinkFunc = wrapLinkText) {
  const linkify = new LinkifyIt();
  let formattedText = text;
  const linkMatches = linkify.match(formattedText);
  // Transform string url into a tag.
  if (linkMatches) {
    const uniqueLinkMatches = uniqBy(linkMatches, (match) => match.url);

    uniqueLinkMatches.forEach((match) => {
      formattedText = replaceAll(formattedText, match.raw, wrapLinkFunc(match));
    });
  }

  return formattedText;
}
