import React from 'react';
import PropTypes from 'prop-types';
import { MenuButton } from '@frameio/vapor';
import ArchiveIcon from '@frameio/components/src/svgs/icons/16/archive.svg';
import { trackButtonClick } from 'analytics';

export default function UnarchiveProject({
  projectId,
  onUnarchiveProjectClick,
  trackingPage,
  trackingPosition,
  ...rest
}) {
  return (
    <MenuButton
      onSelect={() => {
        onUnarchiveProjectClick(projectId);
        trackButtonClick('unarchive-project', trackingPage, trackingPosition);
      }}
      iconBefore={<ArchiveIcon />}
      {...rest}
    >
      Unarchive project
    </MenuButton>
  );
}

UnarchiveProject.defaultProps = {
  projectId: '',
  onUnarchiveProjectClick: () => {},
};

UnarchiveProject.propTypes = {
  projectId: PropTypes.string,
  onUnarchiveProjectClick: PropTypes.func,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};
