/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects';
import { poll } from '@frameio/core/src/shared/sagas/helpers';
import axios from 'axios';

import { showSuccessToast, showErrorToast } from 'actions/toasts';

const TOAST_DELAY = 3000;

export function* selectAndUploadImage(uploadUrl, file, imageUrl) {
  const options = {
    headers: {
      'Content-Type': file.type,
      'x-amz-acl': 'private',
    },
  };

  try {
    yield call(axios.put, uploadUrl, file, options);
    yield call(poll, () => axios.get(imageUrl), 10, 1000);
    yield put(
      showSuccessToast({
        header: 'Image successfully uploaded',
        autoCloseDelay: TOAST_DELAY,
      })
    );
    return true;
  } catch (e) {
    yield put(
      showErrorToast({
        header: 'Image failed to upload',
        autoCloseDelay: TOAST_DELAY,
      })
    );
    return false;
  }
}
