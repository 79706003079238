import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { map } from 'lodash';
import { setLocalFlag } from 'utils/featureFlags';
import Flex, { FlexItem } from 'styled-flex-component';
import Switch from '@frameio/components/src/styled-components/Switch';

const StyledP = styled.p`
  font-size: ${(p) => p.theme.fontSize[1]};
  padding: 0 0 ${(p) => p.theme.spacing.small};
`;

const StyledFlex = styled(Flex)`
  padding: ${(p) => p.theme.spacing.micro} 0;
  border-bottom: 1px solid ${(p) => p.theme.color.silver};
  user-select: text;

  &:last-of-type {
    border-bottom: none;
  }
`;

const StyledFlexItem = styled(FlexItem)`
  ${(p) => p.theme.fontStyle.caption};
  color: ${(p) => p.theme.color.almostBlack};
`;

const StyledH3 = styled.h3`
  font-size: ${(p) => p.theme.fontSize[1]};
  font-variant: all-small-caps;
  color: ${(p) => p.theme.color.slateGray};
`;

export default function FeatureFlags({ features }) {
  return (
    <React.Fragment>
      <StyledP>
        Once a value is set to <code>true</code> or <code>false</code> here,
        that value overrides anything set in the Support Tool until you log out.
      </StyledP>

      <StyledFlex justifyBetween>
        <StyledFlexItem>
          <StyledH3>Name</StyledH3>
        </StyledFlexItem>
        <StyledFlexItem>
          <StyledH3>localStorage</StyledH3>
        </StyledFlexItem>
      </StyledFlex>

      {map(features, (isToggled, feature) => (
        <StyledFlex justifyBetween key={feature}>
          <StyledFlexItem>{feature}</StyledFlexItem>
          <FlexItem>
            <Switch
              isOn={isToggled}
              onChange={() => {
                setLocalFlag(feature, !isToggled);

                // TODO(marvin): allow toggling multiple flags before reloading the page
                window.location.reload();
              }}
            />
          </FlexItem>
        </StyledFlex>
      ))}
    </React.Fragment>
  );
}

FeatureFlags.propTypes = {
  features: PropTypes.object.isRequired,
};
