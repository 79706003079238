import React, { memo } from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';

const StyledIcon = styled(Flex)`
  position: absolute;
  height: 100%;
  align-items: center;
  padding-left: ${(p) => p.theme.spacing.small};
  color: ${(p) => p.theme.color.slateGray};
  z-index: 1;
`;

const IconContainer = ({ children }) => <StyledIcon>{children}</StyledIcon>;

export default memo(IconContainer);
