import React, { memo, useEffect } from 'react';
import styled from 'styled-components';
import { usePrevious } from '@frameio/vapor';
import Flag from '@frameio/components/src/svgs/icons/24/flag.svg';
import Container from '../shared/Container';
import Input from '../shared/Input';
import ErrorMessage from '../shared/ErrorMessage';
import Select from '../shared/Select';
import USStates from '../../data/states.json';
import canadaProvinces from '../../data/canadaProvinces.json';
import { isCountryUS, isCountryUSorCanada } from '../../helpers';

const FlagContainer = styled.div`
  position: absolute;
  top: 9px;
  left: 5px;
  color: ${(p) => p.theme.color.slateGray};
  z-index: 1;
`;

function generateOptions({ isUS }) {
  const states = isUS ? USStates : canadaProvinces;

  return states.map((state) => ({
    label: state.name,
    value: state.code,
  }));
}

const StateInput = ({
  country,
  error,
  handleBlur,
  handleChange,
  setFieldTouched,
  setFieldValue,
  stateOrProvince,
  touched,
}) => {
  const isUS = isCountryUS(country);
  const isUSorCanada = isCountryUSorCanada(country);
  const placeholder = 'State/Province';
  const defaultValue =
    stateOrProvince?.length > 0 ? stateOrProvince : placeholder;
  const previousCountry = usePrevious(country);
  const showError = touched && error;

  useEffect(() => {
    // Reset State / Province field when the changing country.
    if (typeof previousCountry === 'string' && country !== previousCountry) {
      setFieldValue('stateOrProvince', '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <>
      <Container>
        {isUSorCanada ? (
          <Select
            defaultValue={stateOrProvince}
            error={error}
            icon={() => <Flag />}
            maxInputWidth={112}
            name="stateOrProvince"
            onBlur={() => setFieldTouched('stateOrProvince', true)}
            onChange={(value) => {
              setFieldValue('stateOrProvince', value);
            }}
            options={generateOptions({ isUS })}
            placeholder={placeholder}
            touched={touched}
          />
        ) : (
          <>
            <FlagContainer>
              <Flag />
            </FlagContainer>
            <Input
              error={!!touched && !!error}
              name="stateOrProvince"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={defaultValue}
            />
          </>
        )}
      </Container>
      <ErrorMessage isVisible={!!showError} marginTop>
        {error}
      </ErrorMessage>
    </>
  );
};

export default memo(StateInput);
