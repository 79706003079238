import { connect } from 'react-redux';
import { hydratedCollaboratorSelector } from '@frameio/core/src/collaborators/selectors';
import { pendingCollaboratorEntitySelector } from '@frameio/core/src/pendingCollaborators/selectors';
import { permittedActionsForProjectSelector } from 'selectors/permissions';
import {
  addToTeam,
  removeCollaborator,
  removePendingCollaborator,
  resendInvite,
} from '../../actions';
import { projectIdSelector } from '../../../selectors';
import { resendInviteStatusSelector } from '../../selectors';
import ManagePerson from './ManagePerson';

function mapStateToProps(state, { personId }) {
  const collaborator = hydratedCollaboratorSelector(state, {
    collaboratorId: personId,
  });
  const pendingCollaborator = pendingCollaboratorEntitySelector(state, {
    pendingCollaboratorId: personId,
  });

  const projectId = projectIdSelector(state);
  const { email } = pendingCollaborator || (collaborator || {}).user || {};

  const {
    canInviteCollaborators,
    canRemoveCollaborators,
    canChangeUserProjectRole,
  } = permittedActionsForProjectSelector(state, { projectId });

  return {
    canInviteCollaborators,
    canRemoveCollaborators,
    canChangeUserProjectRole,
    collaborator,
    pendingCollaborator,
    resendInviteStatus: resendInviteStatusSelector(state, { email }),
  };
}

const mapDispatchToProps = {
  addToTeam,
  resendInvite,
  removeCollaborator,
  removePendingCollaborator,
};

export const testExports = { mapStateToProps, mapDispatchToProps };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagePerson);
