/* global FIOEmojiData */

import moment from 'moment';
import JT from 'utilities/JT';
import JTConstants from 'utilities/JTConstants';
import JTFormats from 'utilities/JTFormats';
import JTSVG from 'core/JTSVG';
// Define JTDynamoObject as well as extensions

class JTDynamoObject {
  static initClass() {

    // Object Defining Methods

    this.objects = {};
    this.secondary_keys_to_hash = {};
  }

  // Class Objects and Methods

  static find_by_key(key) {
    let object = this.objects[key];
    if (object) { return object; } else { return null; }
  }

  static each(f) {
    return (() => {
      let result = [];
      for (let key in this.objects) {
        let object = this.objects[key];
        result.push(f(object));
      }
      return result;
    })();
  }

  static map(f) { return Object.keys(this.objects)
      .reduce(((acc, key) => (acc.push(f(this.objects[key])), acc)), []); }

  static all() { return this.map(x => x); }

  static first() { return this.all()[0]; }

  static last() { let all = this.all(); return all[all.length - 1]; }

  static count() { return this.all()
      .length; }

  static filter(c) { return Object.keys(this.objects)
      .reduce(((acc, key) => { if (c(this.objects[key])) { acc.push(this.objects[key]); } return acc; }), []); }

  static clear() { this.objects = null;
    this.secondary_keys_to_hash = null;
    this.secondary_keys_to_hash = {}; return this.objects = {}; }

  static responds_to(method) { return method in this && (typeof (this[method]) === 'function'); }

  static init(class_name) {
    class_name.objects = {};
    class_name.secondary_keys_to_hash = {};

    let secondary_keys = class_name.secondary_keys();
    if (secondary_keys) { this.init_secondary_keys(class_name, secondary_keys); }
  }

  // Constructor

  constructor(attrs) {

    this.set_sorted_by = this.set_sorted_by.bind(this);
    this.set_filter_by = this.set_filter_by.bind(this);
    this.set_hash_key = this.set_hash_key.bind(this);
    this.set_secondary_keys = this.set_secondary_keys.bind(this);
    this.set_db_attrs = this.set_db_attrs.bind(this);
    this.fire_updated_instance_event = this.fire_updated_instance_event.bind(this);
    this.set_local_attrs = this.set_local_attrs.bind(this);
    this.set_defaults = this.set_defaults.bind(this);
    this.set_json = this.set_json.bind(this);
    this.set_has_owner = this.set_has_owner.bind(this);
    this.set_has_master = this.set_has_master.bind(this);
    this.set_aggregates = this.set_aggregates.bind(this);
    this.set_chain_methods = this.set_chain_methods.bind(this);
    this.set_associates = this.set_associates.bind(this);
    this.set_collections = this.set_collections.bind(this);
    this.get_hash_key = this.get_hash_key.bind(this);
    this.delete = this.delete.bind(this);
    this.bind_attr = this.bind_attr.bind(this);
    this.bind_time = this.bind_time.bind(this);
    this.set_attrs = this.set_attrs.bind(this);
    if (attrs == null) { attrs = {}; }
    this['vals'] = {};

    this['attr_bindings'] = {};

    let hash_key = this.constructor.hash_key();
    if (hash_key) { this.set_hash_key(hash_key); }

    let secondary_keys = this.constructor.secondary_keys();
    if (secondary_keys) { this.set_secondary_keys(secondary_keys); }

    let db_attrs = this.constructor.db_attrs();
    if (db_attrs) { this.set_db_attrs(db_attrs); }

    let local_attrs = this.constructor.local_attrs();
    if (local_attrs) { this.set_local_attrs(local_attrs); }

    if (this.constructor.has_master()) { this.set_has_master(); } else { this['has_master'] = () => false; }

    if (this.constructor.has_owner()) { this.set_has_owner(); } else { this['has_owner'] = () => false; }

    let defaults = this.constructor.defaults();
    if (defaults) { this.set_defaults(defaults); }

    let chain_methods = this.constructor.chain_methods();
    if (chain_methods) { this.set_chain_methods(chain_methods); }

    let associates = this.constructor.associates();
    if (associates) { this.set_associates(associates); }

    let collections = this.constructor.collections();
    if (collections) { this.set_collections(collections); }

    let aggregates = this.constructor.aggregates();
    if (aggregates) { this.set_aggregates(aggregates); }

    let sorted_by = this.constructor.sorted_by();
    if (sorted_by) { this.set_sorted_by(sorted_by); }

    let filter_by = this.constructor.filter_by();
    if (filter_by) { this.set_filter_by(filter_by); }

    let json = this.constructor.json();
    if (json) { this.set_json(json); }

    this.set_attrs(attrs);
  }

  static hash_key() { return null; }

  static secondary_keys() { return []; }

  static db_attrs() { return []; }

  static local_attrs() { return []; }

  static has_master() { return false; }

  static has_owner() { return false; }

  static defaults() { return {}; }

  static json() { return {}; }

  static collections() { return {}; }

  static chain_methods() { return []; }

  static associates() { return {}; }

  static aggregates() { return {}; }

  static sorted_by() { return null; }

  static filter_by() { return null; }

  static factory() { return null; }

  set_sorted_by(attr) {

    return (attr => {

      return this["sorted_by"] = () => {
        return attr;
      };
    })(attr);
  }

  set_filter_by(attr) {

    return (attr => {

      return this["filter_by"] = () => {
        return attr;
      };
    })(attr);
  }

  set_hash_key(key) {

    if (key) {

      return (key => {

        this["hash_key"] = () => {
          return key;
        };

        return this["hash_key_value"] = () => {
          return this[this["hash_key"]()]();
        };
      })(key);
    }
  }


  static init_secondary_keys(constructor, keys) {

    if (constructor && keys) {

      return Array.from(keys)
        .map((key) =>
          (key => {

            return constructor[`find_by_${key}`] = key_val => {

              let object;
              let { secondary_keys_to_hash } = constructor;
              let key_hash = secondary_keys_to_hash[key];
              if (key_hash) { object = constructor.objects[key_hash[key_val]]; }

              return object ? object : null;
            };
          })(key));
    }
  }

  set_secondary_keys(keys) {

    if (keys) {

      return (keys => {
        return this["secondary_keys"] = () => {
          return keys;
        };
      })(keys);
    }
  }

  set_db_attrs(attrs) {

    let val;
    if (attrs) {

      (attrs => {

        return this["db_attrs"] = () => {
          return attrs;
        };
      })(attrs);

      for (let attr of Array.from(attrs)) {

        (attr => {

          this[`${attr}`] = () => {
            val = this.vals[attr];
            return ((val || (val === 0)) && (val !== "JTNullValue")) ? val : null;
          };

          return this[`set_${attr}`] = v => {

            if (this.constructor.conditional_set) {
              const conditionals = this.constructor.conditional_set();
              const conditional = conditionals[attr];
              if (conditional) {
                const oldVar = this[`${attr}`]();
                if (!conditional(oldVar, v)) return;
              }
            }

            v = JT.escape(v);

            if (Array.from(this.constructor.secondary_keys())
              .includes(attr)) {

              let { secondary_keys_to_hash } = this.constructor;

              let key_hash = secondary_keys_to_hash[attr];
              if (!key_hash) { key_hash = {}; }

              key_hash[v] = this["hash_key_value"]();
              secondary_keys_to_hash[attr] = key_hash;

              this.constructor.secondary_keys_to_hash = secondary_keys_to_hash;
            }

            this.vals[attr] = v;
            this.fire_updated_instance_event();
            if (this['attr_bindings'] && this['attr_bindings'][attr]) {
              return Array.from(this['attr_bindings'][attr])
                .map((f) => f(v));
            }
          };
        })(attr);
      }
    }

    return (() => {
      return this["db_attrs_hash"] = () => {
        let data = {};
        for (let attribute of Array.from(this['db_attrs']())) {
          val = this[`${attribute}`]();
          if (val || (val === 0)) {
            data[attribute] = val;
          }
        }
        return data;
      };
    })();
  }

  fire_updated_instance_event() {
    return JTConstants.EVENT_MANAGER.emit_updated_jt_dynamo_value(this.constructor.classname(), this);
  }

  serialize() {
    const data = this.db_attrs_hash();
    const collections = this.collections();
    const associates = this.associates();
    const collection_data = Object.keys(collections).reduce((collection_data, collection_key) => {
      collection_data[`${collection_key}s`] = this[`${collection_key}s`]().map((jt_entity) => {
        if (jt_entity.id) return jt_entity.id();
        if (jt_entity.email) return jt_entity.email();
        return null;
      });
      return collection_data;
    }, {})
    const associate_data = Object.keys(associates).reduce((associate_data, associate_key) => {
      const associate = this[associate_key]();
      associate_data[associate_key] = associate && associate.id && associate.id();
      return associate_data;
    }, {});
    return Object.assign({},
      data,
      collection_data,
      associate_data,
    );
  }

  set_local_attrs(attrs) {

    if (attrs) {

      (attrs => {
        return this["local_attrs"] = () => {
          return attrs;
        };
      })(attrs);

      return Array.from(attrs)
        .map((attr) =>

          (attr => {

            this[`${attr}`] = () => {
              return this.vals[attr];
            };

            return this[`set_${attr}`] = v => {

              if (Array.from(this.constructor.secondary_keys())
                .includes(attr)) {

                let key_hash;
                let { secondary_keys_to_hash } = this.constructor;

                if (secondary_keys_to_hash) { key_hash = secondary_keys_to_hash[attr]; }
                if (!key_hash) { key_hash = {}; }

                key_hash[v] = this["hash_key_value"]();
                secondary_keys_to_hash[attr] = key_hash;

                this.constructor.secondary_keys_to_hash = secondary_keys_to_hash;
              }

              if (this['attr_bindings'] && this['attr_bindings'][attr]) {
                for (let f of Array.from(this['attr_bindings'][attr])) { f(v); }
              }

              return this.vals[attr] = v;
            };
          })(attr));
    }
  }

  set_defaults(hash) {

    if (hash) {

      return (hash => {

        this["defaults"] = () => {
          return hash;
        };

        return (() => {
          let result = [];
          for (let key in hash) {
            let value = hash[key];
            let item;
            if (!this[`${key}`]()) {
              try {
                item = this[`set_${key}`](value);
              } catch (error) {}
            }
            result.push(item);
          }
          return result;
        })();
      })(hash);
    }
  }

  set_json(hash) {

    if (hash) {

      return (hash => {

        return (() => {
          let result = [];
          for (var key in hash) {

            var base_method;
            var value = hash[key];
            this[`${key}`] = () => {
              base_method = value['base_method'];
              if (this[base_method]) {

                let val;
                try {
                  val = JSON.parse(this[base_method]());
                } catch (err) {
                  val = {};
                }

                return val;
              }
            };

            result.push(this[`set_${key}`] = obj => {
              let current = this[`${key}`]();
              let new_obj = JT.merge(current, obj);

              base_method = value['base_method'];
              if (this[`set_${base_method}`]) {
                return this[`set_${base_method}`](JSON.stringify(new_obj));
              }
            });
          }
          return result;
        })();
      })(hash);
    }
  }

  set_has_owner() {

    return (() => {

      let class_name, key;
      this["has_owner"] = () => true;

      this["owner_key"] = () => {
        key = this.vals["owner_key"];
        return key ? key : null;
      };

      this["set_owner_key"] = key => {
        return this.vals["owner_key"] = key;
      };

      this["owner_class"] = () => {
        class_name = this.vals["owner_class"];
        return class_name ? class_name : null;
      };

      this["set_owner_class"] = class_name => {
        return this.vals["owner_class"] = class_name;
      };

      this["owner"] = () => {
        key = this["owner_key"]();
        class_name = this["owner_class"]();
        return (key && class_name) ? class_name.find_by_key(key) : null;
      };

      return this["set_owner"] = owner => {

        let owner_key = owner.hash_key_value();
        let owner_class = owner.constructor;

        if (owner_key && owner_class) {
          this["set_owner_key"](owner_key);
          return this["set_owner_class"](owner_class);
        }
      };
    })();
  }

  set_has_master() {

    return (() => {

      let class_name, key;
      this["has_master"] = () => true;

      this["master_key"] = () => {
        key = this.vals["master_key"];
        return key ? key : null;
      };

      this["set_master_key"] = key => {
        return this.vals["master_key"] = key;
      };

      this["master_class"] = () => {
        class_name = this.vals["master_class"];
        return class_name ? class_name : null;
      };

      this["set_master_class"] = class_name => {
        return this.vals["master_class"] = class_name;
      };

      this["master"] = () => {
        key = this["master_key"]();
        class_name = this["master_class"]();
        return (key && class_name) ? class_name.find_by_key(key) : null;
      };

      return this["set_master"] = master => {
        let master_key = master.hash_key_value();
        let master_class = master.constructor;

        if (master_key && master_class) {
          this["set_master_key"](master_key);
          return this["set_master_class"](master_class);
        }
      };
    })();
  }

  set_aggregates(hash) {
    if (!hash) return;

    Object.keys(hash).forEach((obj) => {
      let collection_list = hash[obj];
      let objs = obj.pluralize();

      this[`${objs}`] = (sorted_by, filter_by) => {
        if (this[`${objs}_cached`]) { return this[`${objs}_cached`]; }

        let objects = [];

        for (let collection of Array.from(collection_list)) {
          let cs = collection.pluralize();
          objects = objects.concat(this[cs]());
        }

        if (sorted_by && (typeof (sorted_by) === 'function')) {
          objects.sort(sorted_by);

        } else if (objects[0] && objects[0]['sorted_by']) {
          sorted_by = objects[0]['sorted_by']();
          if (sorted_by && (typeof (sorted_by) === 'function')) {
            objects.sort(sorted_by);
          }
        }

        if (filter_by && (typeof (filter_by) === 'function')) {
          objects = objects.filter(filter_by);

        } else if (objects[0] && objects[0]['filter_by']) {
          filter_by = objects[0]['filter_by']();
          if (filter_by && (typeof (filter_by) === 'function')) {
            objects = objects.filter(filter_by);
          }
        }

        objects.first = () => objects[0];
        objects.last = () => objects[objects.length - 1];
        objects.count = () => objects.length;
        objects.each = f => Array.from(objects).map((o) => f(o));

        this[`${objs}_cached`] = objects;

        return objects;
      };

      this[`add_${obj}`] = (object) => {
        let collection_hash = this.collections();

        for (let collection_name in collection_hash) {
          let collection_classname = collection_hash[collection_name];

          if (object instanceof collection_classname) {
            this[`add_${collection_name}`](object);
            break;
          }
        }

        return this[`clear_${obj}_cache`]();
      };

      this[`add_${obj}_multi`] = (arr) => {
        return arr.forEach(this[`add_${obj}`]);
      };

      this[`remove_${obj}`] = (object) => {
        let collection_hash = this.collections();

        for (let collection_name in collection_hash) {
          let collection_classname = collection_hash[collection_name];
          if (object instanceof collection_classname) {
            this[`remove_${collection_name}`](object);
            break;
          }
        }

        return this[`clear_${obj}_cache`]();
      };

      this[`remove_${objs}`] = (objects) => {
        let collection_hash = this.collections();

        for (let i in objects) {
          let object = objects[i];
          for (let collection_name in collection_hash) {
            let collection_classname = collection_hash[collection_name];

            if (object instanceof collection_classname) {
              this[`remove_${collection_name}`](object);
              break;
            }
          }
        }

        return this[`clear_${obj}_cache`]();
      };

      this[`delete_${obj}`] = (object) => {
        let collection_hash = this.collections();

        for (let collection_name in collection_hash) {
          let collection_classname = collection_hash[collection_name];
          if (object instanceof collection_classname) {
            this[`remove_${collection_name}`](object);
            object.delete();
            break;
          }
        }

        return this[`clear_${obj}_cache`]();
      };

      this[`clear_${objs}`] = () => {
        let collection_hash = this.collections();
        for (let collection_name in collection_hash) {
          this[`set_${collection_name}_keys`]([]);
        }

        return this[`clear_${obj}_cache`]();
      };

      this[`clear_${obj}_cache`] = () => {
        for (let collection of Array.from(collection_list)) {
          this[`clear_${collection}_cache`]();
        }

        return this[`${objs}_cached`] = null;
      };

      this[`has_${obj}`] = object => {
        let collection_hash = this.collections();

        for (let collection_name in collection_hash) {
          if (this[`has_${collection_name}`](object)) {
            return true;
          }
        }

        return false;
      };

      this[`map_${objs}_by_type`] = (f, sorted_by, filter_by) => {
        let collection_hash = this.collections();

        this[`clear_${obj}_cache`]();

        let objects = this[`${objs}`]();
        let map = {};

        for (let object of Array.from(objects)) {
          for (let collection_name in collection_hash) {
            if (this[`has_${collection_name}`](object)) {
              let name = collection_name.pluralize();
              (map[name] || (map[name] = [])).push(f(object));
              break;
            }
          }
        }

        this[`${objs}`](sorted_by, filter_by);

        return map;
      };

      this[`index_of_${obj}`] = (object, sorted_by, filter_by) => {
        let object_key = object.hash_key_value ? object.hash_key_value() : object;
        let keys = this[`${objs}`](sorted_by, filter_by)
          .reduce(((acc, x) => {
            acc.push(x.hash_key_value());
            return acc;
          }), []);

        return keys.indexOf(object_key);
      };

      this[`previous_${obj}`] = (object, sorted_by, filter_by) => {
        let index = this[`index_of_${obj}`](object, sorted_by, filter_by);
        let all_objs = this[`${objs}`](sorted_by, filter_by);
        let new_index = index === 0 ? (all_objs.count() - 1) : (index - 1);
        let new_obj = all_objs[new_index];
        return new_obj;
      };

      this[`next_${obj}`] = (object, sorted_by, filter_by) => {
        let index = this[`index_of_${obj}`](object, sorted_by, filter_by);
        let all_objs = this[`${objs}`](sorted_by, filter_by);
        let new_index = index === (all_objs.count() - 1) ? 0 : (index + 1);
        let new_obj = all_objs[new_index];
        return new_obj;
      };

      this[`find_${obj}_by_key`] = key => {
        for (let collection of Array.from(collection_list)) {
          let object = this[`find_${collection}_by_key`](key);
          if (object) { return object; }
        }

        return null;
      };
    });
  }

  set_chain_methods(array) {

    if (array) {

      let methods, target_func;
      return Array.from(array)
        .map((hash) =>

          ((target_func = hash['target']),
            (methods = hash['methods']),

            Array.from(methods)
            .map((method) =>

              ((target_func, method) => {

                return this[method] = (...args) => {

                  let target = this[target_func]();

                  if (target) { return target[method].apply(target, args); }
                };
              })(target_func, method))));
    }
  }

  set_associates(hash) {
    if (!hash) return;

    this.associates = () => hash;

    Object.keys(hash).forEach((obj) => {
      let class_name = hash[obj];

      this[`${obj}_key`] = () => {
        let key = this.vals[`${obj}_key`];
        return key || null;
      };

      this[`set_${obj}_key`] = (key) => {
        return this.vals[`${obj}_key`] = key;
      };

      this[`${obj}`] = () => {
        let object;

        // Check if object does not exists
        if (!this[`${obj}_exists`]() && this[`${obj}_data`]) {
          let obj_data = this[`${obj}_data`]();
          if (obj_data) {
            object = this[`create_${obj}`](obj_data);
          }
        } else {
          let key = this[`${obj}_key`]();
          object = class_name.find_by_key(key);
        }

        return object;
      };

      this[`set_${obj}`] = (object) => {
        let new_key = object.hash_key_value();
        return this[`set_${obj}_key`](new_key);
      };

      this[`${obj}_exists`] = () => {
        let key = this[`${obj}_key`]();
        let object = class_name.find_by_key(key);

        return !!object;
      };

      this[`has_${obj}`] = (object) => {
        let current_key = this[`${obj}_key`];
        let object_key = object.hash_key_value();
        return current_key === object_key;
      };

      this[`create_${obj}`] = (attrs, master=null, skip_child_update=false) => {
        let hash_key = class_name.hash_key();

        if (attrs) {
          let object_exists = false, object;

          for (let attr in attrs) {
            let val = attrs[attr];
            if ((attr === hash_key) && !object) {
              object = class_name.find_by_key(val);
              if (object) { object_exists = true; }

            } else if (Array.from(class_name.secondary_keys())
              .includes(attr) && !object) {
              if (class_name[`find_by_${attr}`]) {
                object = class_name[`find_by_${attr}`](val);
              }
              if (object) { object_exists = true; }
            }
          }

          if (object_exists) {
            if (!this[`has_${obj}`](object)) { this[`set_${obj}`](object); }
            if (skip_child_update) { return; }
          }

          let init_data = {};
          if (attrs[hash_key]) { init_data[hash_key] = attrs[hash_key]; }

          if (!object_exists) { object = new class_name(init_data); }

          if (object.has_owner()) { object.set_owner(this); }

          if (object.has_master()) {
            if (master) {
              object["set_master"](master);
            } else {

              if (this["has_master"]()) {
                master = this["master"]();
                if (master) {
                  object["set_master"](master);
                } else {
                  object["set_master"](this);
                }
              } else {
                object["set_master"](this);
              }
            }
          }

          object.set_attrs(attrs);

          if (!this[`has_${obj}`](object)) { this[`set_${obj}`](object); }

          return object;
        }
      };
    });
  }

  set_collections(hash) {
    if (!hash) return;

    this.collections = () => hash;

    Object.keys(hash).forEach((obj) => {
      let class_name = hash[obj];
      let objs = obj.pluralize();

      this[`${obj}_keys`] = () => {
        let keys = this.vals[`${obj}_keys`];
        return keys || [];
      };

      this[`set_${obj}_keys`] = (keys) => {
        this[`${objs}_cached`] = null;
        this.vals[`${obj}_keys`] = keys;
        if (this['attr_bindings'] && this['attr_bindings'][`${obj}_keys`]) {
          for (let f of Array.from(this['attr_bindings'][`${obj}_keys`])) { f(keys); }
        }
        return this.fire_updated_instance_event();
      };

      this[`clear_${objs}`] = () => {
        return this[`set_${obj}_keys`]([]);
      };

      this[`clear_${obj}_cache`] = () => {
        return this[`${objs}_cached`] = null;
      };

      this[`index_of_${obj}`] = (object, sorted_by, filter_by) => {
        let object_key = object && object.hash_key_value ? object.hash_key_value() : object;
        let keys = this[`${objs}`](sorted_by, filter_by)
          .reduce(((acc, x) => {
            acc.push(x.hash_key_value());
            return acc;
        }), []);

        return keys.indexOf(object_key);
      };

      this[`previous_${obj}`] = (object, sorted_by, filter_by) => {
        let index = this[`index_of_${obj}`](object, sorted_by, filter_by);
        let all_objs = this[`${objs}`](sorted_by, filter_by);
        let new_index = index === 0 ? (all_objs.count() - 1) : (index - 1);
        let new_obj = all_objs[new_index];
        return new_obj;
      };

      this[`next_${obj}`] = (object, sorted_by, filter_by) => {
        let index = this[`index_of_${obj}`](object, sorted_by, filter_by);
        let all_objs = this[`${objs}`](sorted_by, filter_by);
        let new_index = index === (all_objs.count() - 1) ? 0 : (index + 1);
        let new_obj = all_objs[new_index];
        return new_obj;
      };

      this[`${objs}`] = (sorted_by, filter_by) => {
        if (this[`${objs}_cached`]) { return this[`${objs}_cached`]; }

        if (!filter_by) { filter_by = () => true; }

        let objects = this[`${obj}_keys`]()
          .reduce(((acc, k) => {
            let o = class_name.find_by_key(k);
            if (o) { acc.push(o); }
            return acc;
          }), [])
          .filter(filter_by);

        if (sorted_by) {
          if (typeof (sorted_by) === 'function') {
            objects.sort(sorted_by);
          }
        } else {
          sorted_by = class_name['sorted_by']();
          if (sorted_by) {
            if (typeof (sorted_by) === 'function') {
              objects.sort(sorted_by);
            } else {
              objects.sort((a, b) => {
                let a1 = `${a[sorted_by]()}`.toLowerCase();
                let b1 = `${b[sorted_by]()}`.toLowerCase();
                return (a1 < b1) ? -1 : ((a1 > b1) ? 1 : 0);
              });
            }
          }
        }

        if (filter_by(typeof (filter_by) === 'function')) {
          objects = objects.filter(filter_by);
        } else {
          filter_by = class_name['filter_by']();
          if (filter_by && (typeof (filter_by) === 'function')) {
            objects = objects.filter(filter_by);
          }
        }

        objects.first = () => objects[0];
        objects.last = () => objects[objects.length - 1];
        objects.count = () => objects.length;
        objects.each = f => Array.from(objects)
          .map((o) => f(o));

        this[`${objs}_cached`] = objects;

        return objects;
      };

      this[`set_${objs}`] = (objects) => {
        let new_keys = objects.map(object => object.hash_key_value());
        return this[`set_${obj}_keys`](new_keys);
      };

      this[`add_${obj}_key`] = (key) => {
        this[`${objs}_cached`] = null;
        let new_keys = this[`${obj}_keys`]();
        if (!Array.from(new_keys).includes(key)) {
          new_keys.push(key);
        }
        return this[`set_${obj}_keys`](new_keys);
      };

      this[`add_${obj}`] = (object) => {
        if (object && object.hash_key_value) {
          return this[`add_${obj}_key`](object.hash_key_value());
        }
      };

      this[`add_${obj}_multi`] = (arr) => {
        return arr.forEach(this[`add_${obj}`]);
      };

      this[`remove_${obj}_key`] = (key) => {
        let keys = this[`${obj}_keys`]();
        let new_keys = keys.reduce(((acc, k) => {
          if (k !== key) {
            acc.push(k);
          } return acc;
        }), []);
        return this[`set_${obj}_keys`](new_keys);
      };

      this[`remove_${obj}`] = (object) => {
        if (object && object.hash_key_value) {
          return this[`remove_${obj}_key`](object.hash_key_value());
        }
      };

      this[`find_${obj}_by_key`] = (key) => {
        let keys = this[`${obj}_keys`]();
        return keys.reduce(((acc, k) => {
          if (k === key) {
            acc = class_name.find_by_key(key);
          }
          return acc;
        }), null);
      };

      this[`has_${obj}`] = (object) => {
        if (object && object.hash_key_value) {
          object = this[`find_${obj}_by_key`](object.hash_key_value());
        }
        return !!object;
      };

      this[`filter_${objs}`] = (c) => {
        return this[`${objs}`]().reduce((acc, object) => {
          if (c(object)) { acc.push(object); }
          return acc;
        }, []);
      };

      this[`each_${obj}`] = (f) => {
        return Array.from(this[`${objs}`]())
          .map((object) => f(object));
      };

      this[`create_${obj}`] = (attrs, master=null, skip_child_update=false) => {
        let hash_key = class_name.hash_key();
        let object;

        if (attrs) {
          let object_exists = false;

          for (let attr in attrs) {
            let val = attrs[attr];
            if ((attr === hash_key) && !object) {
              object = class_name.find_by_key(val);
              if (object) { object_exists = true; }

            } else if (Array.from(class_name.secondary_keys())
              .includes(attr) && !object) {
              if (class_name[`find_by_${attr}`]) {
                object = class_name[`find_by_${attr}`](val);
              }
              if (object) { object_exists = true; }
            }
          }

          if (object_exists) {
            if (!this[`has_${obj}`](object)) { this[`add_${obj}`](object); }
            if (skip_child_update) { return; }
          }

          let init_data = {};
          if (attrs[hash_key]) { init_data[hash_key] = attrs[hash_key]; }

          if (!object_exists) { object = new class_name(init_data); }

          if (object.has_master()) {
            if (master) {
              object["set_master"](master);
            } else {

              if (this["has_master"]()) {
                master = this["master"]();
                if (master) {
                  object["set_master"](master);
                } else {
                  object["set_master"](this);
                }
              } else {
                object["set_master"](this);
              }
            }
          }

          object.set_attrs(attrs);

          if (!this[`has_${obj}`](object)) { this[`add_${obj}`](object); }
          return object;
        }
      };

    });
  }

  // Instance Methods

  get_hash_key(key_list) {

    if (key_list == null) { key_list = []; }
    let generate = function () {
      let id = 'xxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (Math.random() * 16) | 0;
        let v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
      });
      return id;
    };

    let key = generate();
    while (Array.from(key_list)
      .includes(key)) {
      key = generate();
    }
    return key;
  }

  delete() {
    let hash_key_value = this.hash_key_value();

    let keys_to_hash = this.constructor.secondary_keys_to_hash;

    for (let attr of Array.from(this.constructor.secondary_keys())) {

      let sec_val = this[attr]();
      if (sec_val) {
        let key_hash = keys_to_hash[attr];
        delete key_hash[sec_val];
      }
    }

    return delete this.constructor.objects[hash_key_value];
  }

  bind_attr(attr, f) {

    if (!this['attr_bindings'][attr]) { this['attr_bindings'][attr] = []; }
    return this['attr_bindings'][attr].push(f);
  }

  bind_time(id, duration, callback) {

    if (!this['time_bindings']) { this['time_bindings'] = {}; }
    if (!this['time_bindings'][id]) { this['time_bindings'][id] = {}; }

    let last_called = this['time_bindings'][id]['last_called'];
    let now = Date.now();

    if (!last_called || ((last_called + duration) < now)) {

      callback(this);

      last_called = now;

      return this['time_bindings'][id]['last_called'] = last_called;
    }
  }

  set_attrs(attrs, skip_child_update, set_null_vals) {
    if (skip_child_update == null) { skip_child_update = false; }
    if (set_null_vals == null) { set_null_vals = false; }
    return ((attrs, skip_child_update) => {
      let objects, val;
      let key_found = false;
      let hash_key = this["hash_key"]();
      let secondary_keys_found = {};
      let collections_found = {};
      let associates_found = {};

      let object_exists = false;

      let object = null;

      for (var attr in attrs) {
        val = attrs[attr];
        if (attr === hash_key) {
          object = this.constructor.find_by_key(val);
          if (object) { object_exists = true; }
          break;

        } else if (Array.from(this.constructor.secondary_keys())
          .includes(attr)) {

          object = this.constructor[`find_by_${attr}`](val);
          if (object) { object_exists = true; }
          break;
        }
      }

      if (!object_exists) { object = this; }

      for (attr in attrs) {
        val = attrs[attr];
        if (attr === hash_key) {
          key_found = true;
          ({ objects } = this.constructor);
          if (!object_exists) { objects[val] = object; }
          this.constructor.objects = objects;
        } else if (Array.from(this.constructor.secondary_keys())
          .includes(attr)) {
          secondary_keys_found[attr] = val;
        }

        let attr_single = `${attr.singularize()}`;

        let class_name = object["collections"]()[attr_single];
        if (class_name && (val !== []) && $.isArray(val)) {

          collections_found[attr_single] = val;

        } else {

          class_name = object["associates"]()[attr_single];
          if (class_name && JT.exists_nonempty(val)) {
            associates_found[attr_single] = val;

          } else {
            try {

              if (JT.exists_nonempty(val) || set_null_vals) {
                object[`set_${attr}`](val);
              }
            } catch (error) {}
          }
        }
      }

      if (!key_found && object && object["hash_key_value"] && (!object["hash_key_value"]() || (object["hash_key_value"]() === undefined))) {
        ({ objects } = this.constructor);
        val = this.get_hash_key(Object.keys(objects));

        if (!object_exists) { objects[val] = object; }
        this.constructor.objects = objects;

        try {
          object[`set_${hash_key}`](val);
        } catch (error1) {}
      }

      for (let secondary_key in secondary_keys_found) {

        var hash_key_value;
        let secondary_value = secondary_keys_found[secondary_key];
        let keys_to_hash = this.constructor.secondary_keys_to_hash;
        let key_hash = keys_to_hash[secondary_key];
        if (!key_hash) { key_hash = {}; }

        if (object && object["hash_key_value"]) { hash_key_value = object["hash_key_value"](); }
        key_hash[secondary_value] = hash_key_value;
        keys_to_hash[secondary_key] = key_hash;

        this.constructor.secondary_keys_to_hash = keys_to_hash;

        try {
          object[`set_${secondary_key}`](secondary_value);
        } catch (error2) {}
      }

      for (attr in collections_found) {
        val = collections_found[attr];
        for (let sub_attrs of Array.from(val)) {
          object[`create_${attr}`](sub_attrs, null, skip_child_update);
        }
      }

      for (attr in associates_found) {
        val = associates_found[attr];
        object[`create_${attr}`](val, null, skip_child_update);
      }

      this.fire_updated_instance_event();
      if (this.constructor.after_init) { return this.constructor.after_init(object); }
    })(attrs, skip_child_update);
  }
}
JTDynamoObject.initClass();

export class JTUser extends JTDynamoObject {

  constructor(data) {
    super(data);

    this.data = this.data.bind(this);
    this.email_confirmed = this.email_confirmed.bind(this);
    this.only_collaborator = this.only_collaborator.bind(this);
    this.is_guest = this.is_guest.bind(this);
    this.is_guest_without_passive_login = this.is_guest_without_passive_login.bind(this);
    this.is_anonymous_user = this.is_anonymous_user.bind(this);
    this.get_account_role = this.get_account_role.bind(this);
    this.is_team_member = this.is_team_member.bind(this);
    this.set_account_role = this.set_account_role.bind(this);
    this.new_user_steps = this.new_user_steps.bind(this);
    this.new_user_steps_complete = this.new_user_steps_complete.bind(this);
    this.first_name_safe = this.first_name_safe.bind(this);
    this.last_name_safe = this.last_name_safe.bind(this);
    this.name_max = this.name_max.bind(this);
    this.simple_initials = this.simple_initials.bind(this);
    this.simple_initial = this.simple_initial.bind(this);
    this.update_name = this.update_name.bind(this);
    this.image = this.image.bind(this);
    this.set_image = this.set_image.bind(this);
    this.public_attrs = this.public_attrs.bind(this);
  }

  static classname() { return "JTUser"; }
  static hash_key() { return 'frontend_id'; }
  static secondary_keys() { return ['id', 'email', 'account_key']; }
  static db_attrs() {
    return ['id', 'name', 'link', 'location', 'role', 'bio', 'profile_image', 'email', 'bleeding', 'user_hash', 'first_name', 'last_name',
      'image_32', 'image_32_exists', 'image_64', 'image_64_exists', 'image_128', 'image_128_exists', 'image_256', 'image_256_exists', 'user_default_color', 'account_key', 'anonymous',
      'has_logged_in', 'joined_via', 'created_at_integer', 'email_confirmed_data', 'upload_chunk_size', 'digest_frequency', 'timezone_value', 'hide_premiere_banner', 'phone', 'from_google', 'from_adobe', 'num_sessions', 'created_through_admin'
    ];
  }

  static local_attrs() { return ['frontend_id', 'status', 'local_image', 'last_seen', 'twofa', 'selected', 'focused']; }
  static defaults() {
    return {
      profile_image: "https://static-assets.frame.io/app/anon.jpg",
      role: "Team Member",
      name: " ",
      twofa: "2FA",
      selected: false
    };
  }
  static collections() { return { team: JTTeam, shared_project: JTProject, notification: JTNotification, associated_account: JTAccount }; }

  static factory() { return 'user'; }

  data() {
    let data = {};
    data['user'] = this.db_attrs_hash();
    return data;
  }

  email_confirmed() {
    return this.email_confirmed_data() === "1";
  }

  only_collaborator() {
    return this.teams()
      .count() === 0;
  }

  is_guest() {
    return !JT.exists(this.id());
  }

  is_guest_without_passive_login() {
    return this.is_guest() && ((this.name() === " ") || (this.email() === null));
  }

  is_anonymous_user() {
    return this.anonymous() === "1";
  }

  get_account_role(account) {

    if (account) {

      if (account.has_admin(this) || (account.id() === this.account_key())) {
        return 'admin';
      }

      if (account.has_account_manager(this)) {
        return 'account_manager';
      }

      if (account.has_billing_manager(this)) {
        return 'billing_manager';
      }
    }

    return 'member';
  }

  get_team_role(team) {
    const is_team_manager = (team.team_managers() || []).some((manager) => manager.hash_key_value() == this.hash_key_value());
    const is_team_member = this.is_team_member();
    if (is_team_manager) return 'team_manager';
    else if (is_team_member) return 'team_member';
    return null;
  }

  is_team_member() {
    return ["Team Member", "member"].includes(this.role());
  }

  set_account_role(account, role) {

    if (account) {

      account.remove_admin(this);
      account.remove_account_manager(this);
      account.remove_billing_manager(this);

      switch (role) {
        case 'admin':
          return account.add_admin(this);
        case 'account_manager':
          return account.add_account_manager(this);
        case 'billing_manager':
          return account.add_billing_manager(this);
      }
    }
  }

  new_user_steps() {

    let new_user_steps = [];

    // Make sure user has a team for new user onboard
    if (!this.only_collaborator()) {

      // See video (always checked)
      let complete = true;
      new_user_steps.push({ key: "see-video", display: "Watch Getting Started Video", complete });

      // Upload User Image
      complete = this.image_32_exists() === "1";
      new_user_steps.push({ key: "user-image", display: "Upload Your Profile Image", complete });

      // Name Team
      complete = this.teams()
        .first()
        .name() !== "My Projects";
      new_user_steps.push({ key: "team-setup-name", display: "Name Your Team", complete });

      // Add Team Members
      let team = this.teams()
        .first();
      complete = (team.members()
        .count() > 1) || (team.pending_members()
        .count() > 0) || (team.solo() === "true");
      new_user_steps.push({ key: "team-setup-users", display: "Add Team Members", complete });

      // Upload Team Image
      complete = this.teams()
        .first()
        .image_32_exists() === "1";
      new_user_steps.push({ key: "team-setup-image", display: "Upload Your Logo", complete });
    }

    return new_user_steps;
  }

  new_user_steps_complete() {
    let steps = this.new_user_steps();
    for (let step of Array.from(steps)) {
      if (step['complete'] === false) { return false; }
    }
    return true;
  }

  first_name_safe() {
    if (JT.exists(this.first_name())) {
      return this.first_name();
    } else {
      let a = this.name()
        .split(/\s+/)
        .filter(a_name => a_name.length > 0);
      let b = [a.shift()].concat(a.join(" "));
      let first_name = b[0];
      return first_name;
    }
  }

  last_name_safe() {
    if (JT.exists(this.last_name())) {
      return this.last_name();
    } else {
      let a = this.name()
        .split(/\s+/)
        .filter(a_name => a_name.length > 0);
      let b = [a.shift()].concat(a.join(" "));
      let last_name = b[1];
      return last_name;
    }
  }

  name_max(max) {

    if (max == null) { max = 20; }
    let name = this.name();
    let name_length = name.length;

    if (name_length > max) {

      let first_name = this.first_name_safe();
      let last_name = this.last_name_safe();

      if (first_name && last_name) {

        // -3 accounts for space + last Initial + .
        if ((first_name.length > (max - 3)) && first_name[0]) {

          first_name = `${first_name[0]}.`;

          name_length = first_name.length + 1 + last_name.length;
        }

        if ((name_length > max) && last_name[0]) {

          last_name = last_name.split(/\s+/)
            .filter(a_name => a_name.length > 0)
            .pop();

          name_length = first_name.length + 1 + last_name.length;

          if ((name_length > max) && last_name[0]) {

            last_name = `${last_name[0]}.`;
          }
        }

        return `${first_name} ${last_name}`;
      }
    }

    return name;
  }

  simple_initials() {
    if (!this.name) { return ''; }

    let first_initial = this.name()[0];
    let last_initial = __guard__(this.name()
      .split(' ')[1], x => x[0]) || '';
    return first_initial.toUpperCase() + last_initial.toUpperCase();
  }

  simple_initial() {
    if (!this.name) { return ''; }
    return this.name()[0].toUpperCase();
  }

  update_name() {

    let first_name = this.first_name();
    let last_name = this.last_name();
    if (JT.exists(first_name) && JT.exists(last_name)) {
      return this.set_name(`${first_name} ${last_name}`);
    }
  }

  image(size) {
    if (this[`image_${size}_exists`]() === "1") {
      return this[`image_${size}`]();
    } else {
      return this.profile_image();
    }
  }

  set_image(image) {
    return this.set_local_image(image);
  }

  public_attrs() {
    let data = {};
    data['id'] = this.id();
    data['name'] = this.name();
    data['profile_image'] = this.image(32);
    data['role'] = this.role();

    return data;
  }
}

export class JTPendingUser extends JTDynamoObject {

  constructor(data) {
    super(data);
    this.name = this.name.bind(this);
    this.image = this.image.bind(this);
    this.get_account_role = this.get_account_role.bind(this);
  }

  static classname() { return "JTPendingUser"; }
  static hash_key() { return 'frontend_id'; }
  static secondary_keys() { return ['email']; }
  static db_attrs() { return ['email']; }
  static local_attrs() { return ['frontend_id', 'profile_image', 'role', 'focused', 'selected', 'project_invites']; }
  static defaults() { return { profile_image: "https://static-assets.frame.io/app/anon.jpg", selected: false }; }

  static collections() { return { team: JTTeam }; }

  name() { return this.email(); }

  image(size) { if (size == null) { size = null; } return this.profile_image(); }

  get_account_role(account) {
    return 'pending';
  }
}

export class JTAccount extends JTDynamoObject {

  constructor(data) {
    super(data);
    this.is_pending_cancellation = this.is_pending_cancellation.bind(this);
    this.past_due = this.past_due.bind(this);
    this.plan = this.plan.bind(this);
    this.usage = this.usage.bind(this);
    this.limits = this.limits.bind(this);
    this.data_usage = this.data_usage.bind(this);
    this.cc_exp = this.cc_exp.bind(this);
    this.can_make_items_private = this.can_make_items_private.bind(this);
    this.reset = this.reset.bind(this);
    this.add_to_usage = this.add_to_usage.bind(this);
    this.subtract_from_usage = this.subtract_from_usage.bind(this);
    this.update_limits = this.update_limits.bind(this);
    this.overlimit = this.overlimit.bind(this);
    this.is_free_plan = this.is_free_plan.bind(this);
    this.plan_as_usage = this.plan_as_usage.bind(this);
  }

  static classname() { return "JTAccount"; }
  static hash_key() { return 'frontend_id'; }
  static secondary_keys() { return ['id']; }
  static db_attrs() {
    return ['id', 'used_storage', 'total_storage', 'team_members', 'primary_email', 'created_at_integer',
      'additional_email', 'cc_name', 'cc_brand', 'cc_last4', 'cc_exp_month', 'cc_exp_year',
      'usage_data', 'limits_data', 'plan_data', 'expire_date', 'expired', 'invoice', 'upload_limit', 'balance', 'pending_cancellation', 'end_subscription_date', 'payment_period',
      'next_billing_date', 'invoices', 'delinquent', 'email', 'company_name', 'company_address', 'billing_emails', 'account_balance', 'used_promo', 'promo_expiration', 'on_trial', 'is_enterprise',
      'domains'
    ];
  }

  static local_attrs() { return ['frontend_id', 'expired_timeout', 'first_overlimit']; }

  static factory() { return 'account'; }

  static collections() { return { member: JTUser, pending_member: JTPendingUser, team: JTTeam, admin: JTUser, account_manager: JTUser, billing_manager: JTUser, teamless_member: JTUser }; }
  static aggregates() { return { all_member: ['member', 'pending_member'] }; }

  static associates() { return { user: JTUser, promo: FIOPromotion }; }

  static defaults() {
    return {
      balance: "0.00",
      plan_data: { collaborators: "5", cost_m: "0", cost_y: "0", id: "20150306-01-free", projects: "1", storage: "2", team: "5", title: "Free" },
      usage_data: { project_count: 0, member_count: 1, filesize: 0, collaborator_count: 0 },
      pending_cancellation: "0",
      end_subscription_date: null
    };
  }

  is_pending_cancellation() {
    if (this.pending_cancellation() === "1") {
      return true;
    } else {
      return false;
    }
  }

  past_due() {
    return JT.exists(this.balance()) && (parseFloat(this.balance()) > 0);
  }

  plan() {
    let plan = this.plan_data();
    return plan;
  }

  serialize() {
    const baseSerializedData = JTDynamoObject.prototype.serialize.call(this);
    const user = JTUser.find_by_account_key(this.id());
    return {
      ...baseSerializedData,
      user: user && user.id(),
    }
  }
  usage() {
    let usage = this.usage_data();
    return usage;
  }

  email_in_domains(email) {
    if (typeof email !== 'string') return false;
    const email_domain = email.split("@").pop(); //assumes theres at least 1 '@' in the email
    return (this.domains() || []).includes(email_domain);
  }

  limits() {
    let limits = this.limits_data();
    return limits || [];
  }

  data_usage() {
    return [this.used_storage(), this.total_storage()];
  }

  cc_exp() {
    return [this.cc_exp_month(), this.cc_exp_year()];
  }

  can_make_items_private() {
    let plan_id = this.plan()['id'];
    return !((plan_id === '20150306-01-free') || (plan_id === "20150306-01-starter"));
  }

  reset(overrides) {

    let usage_data = {
      collaborator_count: 0,
      duration: 0,
      file_reference_count: 0,
      filesize: 0,
      folder_count: 0,
      frames: 0,
      project_count: 0,
      member_count: 1,
      team_count: 1
    };

    for (let key in overrides) { let val = overrides[key];
      usage_data[key] = val; }

    this.set_usage_data(usage_data);

    let expire_date = Math.floor((new Date())
      .getTime() / 1000) + (6 * 24 * 60 * 60);
    this.set_expire_date(expire_date);

    this.set_expired("false");

    return this.update_limits();
  }

  add_to_usage(usage_hash) {

    let current_usage = this.usage();

    for (let key in usage_hash) {

      let value = usage_hash[key];
      if (!current_usage[key] && (current_usage[key] !== 0)) { current_usage[key] = 0; }
      current_usage[key] += parseInt(value);
    }

    this.set_usage_data(current_usage);

    return this.update_limits();
  }

  subtract_from_usage(usage_hash) {

    let current_usage = this.usage();

    for (let key in usage_hash) {

      let value = usage_hash[key];
      if (current_usage[key]) {

        if (!current_usage[key] && (current_usage[key] !== 0)) { current_usage[key] = 0; }
        current_usage[key] = parseInt(current_usage[key]) - parseInt(value);

        if (current_usage[key] < 0) { current_usage[key] = 0; }
      }
    }

    this.set_usage_data(current_usage);

    return this.update_limits();
  }

  update_limits(other_plan_data) {
    let expire_date;
    if (other_plan_data == null) { other_plan_data = null; }
    let plan_usage = this.plan_as_usage(other_plan_data);
    let usage = this.usage();
    let limits = [];

    if (usage && plan_usage) {

      for (let key in plan_usage) {

        let value = plan_usage[key];
        let plan_limit = value;
        let usage_value = usage[key];
        let object_text

        if (usage_value > plan_limit) {

          switch (key) {

            case 'member_count':
              object_text = "team member".pluralize(plan_limit);
              limits.push({ limit: `${plan_limit} ${object_text}`, type: 'team_members' });
              break;

            case 'project_count':
              object_text = "project".pluralize(plan_limit);
              limits.push({ limit: `${plan_limit} ${object_text}`, type: 'projects' });
              break;

            case 'collaborator_count':
              object_text = "collaborator".pluralize(plan_limit);
              limits.push({ limit: `${plan_limit} ${object_text}`, type: 'collaborators' });
              break;

            case 'filesize':
              {
                let gbs = Math.round(plan_limit / 1024 / 1024 / 1024);
                limits.push({ limit: `${gbs}GB`, type: 'storage' });
                break;
              }
          }
        }
      }
    }

    if (limits.length > 0) {

      expire_date = this.expire_date();

      if (!expire_date) {
        expire_date = Math.floor((new Date())
          .getTime() / 1000) + (6 * 24 * 60 * 60);
      }
    }

    if (!other_plan_data) {
      if (JT.exists(expire_date)) {
        this.set_expire_date(`${expire_date}`);
      }

      this.set_limits_data(limits);
    }

    return limits;
  }

  overlimit() {
    this.update_limits();
    return JT.exists(this.limits()) && (this.limits()
      .length > 0);
  }

  is_free_plan() {
    let plan = this.plan();
    return plan.title === "Free";
  }

  // Convert format of plan object to usage data format
  plan_as_usage(plan_data) {

    if (plan_data == null) { plan_data = null; }
    let full_usage = {};

    let plan = plan_data ? plan_data : this.plan();

    if (plan) {

      if (plan['team'] && (plan['team'] !== 'unlimited')) {
        full_usage['member_count'] = parseInt(plan['team']);
      }

      if (plan['projects'] && (plan['projects'] !== 'unlimited')) {
        full_usage['project_count'] = parseInt(plan['projects']);
      }

      if (plan['collaborators'] && (plan['collaborators'] !== 'unlimited')) {
        full_usage['collaborator_count'] = parseInt(plan['collaborators']);
      }

      if (plan['storage'] && (plan['storage'] !== 'unlimited')) {
        full_usage['filesize'] = parseInt(plan['storage']) * 1024 * 1024 * 1024;
      }
    }

    return full_usage;
  }
}

export class JTTeam extends JTDynamoObject {

  constructor(data) {
    super(data);

    this.data = this.data.bind(this);
    this.image = this.image.bind(this);
    this.has_slack_webhook = this.has_slack_webhook.bind(this);
    this.is_private = this.is_private.bind(this);
    this.is_restricted = this.is_restricted.bind(this);
  }

  static classname() { return "JTTeam"; }
  static hash_key() { return 'frontend_id'; }
  static secondary_keys() { return ['id']; }

  static db_attrs() {
    return ['id', 'name', 'team_image', 'bio', 'link', 'location', 'member_limit', 'color', 'background_color', 'colors', 'dark_theme', 'solo', 'account_data',
      'image_32', 'image_32_exists', 'image_64', 'image_64_exists', 'image_128', 'image_128_exists', 'image_256', 'image_256_exists', 'upload_limit',
      'team_owner_id', 'slack_webhook', 'private', 'restricted', 'created_at', 'owner_email', 'label', 'allocation_data', 'limits_data', 'total_filesize', 'project_count',
      'asset_lifecycle_policy', 'watermark', 'font_color', 'disable_sbwm_internally', 'disable_fwm_internally'
    ];
  }

  static local_attrs() { return ['frontend_id', 'selected', 'focused']; }

  static sorted_by() { return 'name'; }
  static collections() { return { member: JTUser, pending_member: JTPendingUser, pending_team_manager: JTPendingUser, project: JTProject, join_request: JTJoinRequest, team_manager: JTUser }; }

  static associates() { return { account: JTAccount }; }

  static aggregates() { return { all_member: ['member', 'pending_member'] }; }

  static defaults() { return { color: "5B53FF", background_color: "FFFFFF", dark_theme: "false", selected: false, 'total_filesize': "0" }; }

  data() {
    let data = {};
    data['team'] = this.db_attrs_hash();
    data['team']['image'] = this.image(128);
    data['team']['frontend_id'] = this.frontend_id();

    // Always omit the watermark
    delete data.team.watermark;

    return data;
  }

  image(size) {
    if (this[`image_${size}_exists`]() === "1") {
      return this[`image_${size}`]();
    } else {
      return this.team_image();
    }
  }

  limits() {
    return this.limits_data() || [];
  }

  has_slack_webhook() {
    if (this.slack_webhook() === null) {
      return "0";
    } else {
      return "1";
    }
  }

  is_private() {
    return this.private() === "1";
  }

  is_restricted() {
    return this.restricted() === "1";
  }
}

//TODO(christianevans): Need to take out the password DB attribute, and exchange for a 'has_password' field.
export class JTReviewLink extends JTDynamoObject {

  static classname() { return "JTReviewLink"; }
  static hash_key() { return 'frontend_id'; }
  static secondary_keys() { return ['id']; }
  static db_attrs() { return ['id', 'name', 'created_at', 'expires_at', 'views', 'is_active', 'password', 'allow_approvals', 'current_version_only', 'enable_downloading', 'notify_me_on_view', 'item_count', 'short_url']; }
  static local_attrs() { return ['frontend_id']; }
  static collections() { return { file: JTFileReference, version_stack: JTVersionStack }; }
  static associates() { return { project: JTProject, creator: JTUser }; }
  static aggregates() { return { asset: ['file', 'version_stack'] }; }

  static defaults() {
    return {
      name: 'New Review Link',
      views: 0,
      is_active: '1',
      password: '',
      allow_approvals: '1',
      current_version_only: '0',
      enable_downloading: '0',
      notify_me_on_view: '1',
      created_at: +moment().utc(),
      short_url: '',
    };
  }

  normalized_short_url() {
    const shortUrl = this.short_url();

    return JT.exists(shortUrl)
      ? shortUrl
      : null;
  }

  asset_count() {
    return this.item_count()
      ? this.item_count()
      : (this.assets() || []).length
  }
}

export class JTFileReference extends JTDynamoObject {

  constructor(data) {
    super(data);

    this.is_file_reference = this.is_file_reference.bind(this);
    this.is_folder = this.is_folder.bind(this);
    this.is_version_stack = this.is_version_stack.bind(this);
    this.label_templates = this.label_templates.bind(this);
    this.version_stack = this.version_stack.bind(this);
    this.set_version_stack = this.set_version_stack.bind(this);
    this.cell = this.cell.bind(this);
    this.set_cell = this.set_cell.bind(this);
    this.can_delete = this.can_delete.bind(this);
    this.is_private = this.is_private.bind(this);
    this.is_shared = this.is_shared.bind(this);
    this.ext = this.ext.bind(this);
    this.name_no_ext = this.name_no_ext.bind(this);
    this.is_general_access = this.is_general_access.bind(this);
    this.is_dropframe = this.is_dropframe.bind(this);
    this.start_timecode = this.start_timecode.bind(this);
    this.is_video = this.is_video.bind(this);
    this.folder = this.folder.bind(this);
    this.is_image = this.is_image.bind(this);
    this.has_thumb = this.has_thumb.bind(this);
    this.thumb_or_icon = this.thumb_or_icon.bind(this);
    this.is_audio = this.is_audio.bind(this);
    this.is_archive = this.is_archive.bind(this);
    this.is_document = this.is_document.bind(this);
    this.is_multipart = this.is_multipart.bind(this);
    this.create_parts = this.create_parts.bind(this);
    this.num_parts = this.num_parts.bind(this);
    this.part = this.part.bind(this);
    this.part_url = this.part_url.bind(this);
    this.image_best = this.image_best.bind(this);
    this.make_audio = this.make_audio.bind(this);
    this.should_process = this.should_process.bind(this);
    this.h264_resolutions = this.h264_resolutions.bind(this);
    this.download = this.download.bind(this);
    this.h264 = this.h264.bind(this);
    this.h264_exists = this.h264_exists.bind(this);
    this.webm = this.webm.bind(this);
    this.webm_exists = this.webm_exists.bind(this);
    this.width = this.width.bind(this);
    this.height = this.height.bind(this);
    this.profile_image = this.profile_image.bind(this);
    this.data = this.data.bind(this);
    this.get_user_stars = this.get_user_stars.bind(this);
    this.video_codec_long = this.video_codec_long.bind(this);
    this.audio_codec_long = this.audio_codec_long.bind(this);
    this.new_fps = this.new_fps.bind(this);
    this.total_frames = this.total_frames.bind(this);
  }

  static classname() { return "JTFileReference"; }
  static hash_key() { return 'frontend_id'; }
  static secondary_keys() { return ['id', 'title_id']; }
  static db_attrs() {
    return ['id', 'name', 'filetype', 'shared', 'private', 'can_download', 'presentation_expire', 'password', 'filesize', 'location', 'description', 'type', 'thumb', 'media_icon', 'thumb_scrub',
      'cover', 'cover_exists', 'h264_720', 'quicklook_media', 'split_media_01', 'split_media_02', 'h264_720_exists', 'original_download', 'original_upload', 'detail_type',
      'detail', 'index', 'created_at_integer', 'updated_at_integer', 'comment_count', 'temp_id',
      'owner_key', 'master_key', 'fps', 'frames', 'original_height', 'original_width', 'thumb_exists',
      'thumb_height', 'thumb_width', 'thumbs', 'thumb_type', 'thumb_state', 'project_key', 'stars', 'metadata', 'h264_best', 'h264_best_exists', 'webm_best', 'webm_best_exists',
      'h264_540', 'h264_540_exists', 'webm_540', 'webm_540_exists',
      'image_full', 'image_full_exists', 'user_stars', 'multipart_urls', 'custom_metadata_keys', 'streams', 'downloads', 'label', 'source',
      'archive_from',
    ];
  }

  static conditional_set() { return { metadata: this.metadata_set }; }
  static local_attrs() { return ['frontend_id', 'file', 'part_size', 'upload_request', 'upload_status', 'upload_retried', 'local_cell', 'version_stack_key', 'waiting_dots', 'title_cell', 'title_id', 'time', 'loaded', 'last_loaded', 'uploading', 'upload_monitor', 'upload_percent', 'upload_speed', 'upload_progress', 'project_id', 'parts']; }
  static sorted_by() { return this.sortable_index; }
  static collections() { return { comment: JTComment, version: JTFileReference }; }

  static defaults() {
    return {
      name: " ",
      thumb_state: 'media',
      thumb: "https://static-assets.frame.io/app/thumb_blank.png",
      media_icon: "https://static-assets.frame.io/app/clip-icon.png",
      comment_count: 0,
      fps: 25,
      upload_percent: 0,
      upload_speed: " ",
      upload_progress: " ",
      index: null,
      multipart_urls: null,
      upload_status: "queued",
      can_download: "0",
      private: "0",
      part_size: 20971520
    };
  }

  static json() {
    return {
      all_metadata: {
        base_method: "metadata"
      }
    };
  }

  static sortable_index(a, b) {
    return parseInt(a.index()) - parseInt(b.index());
  }

  // Only set metadata when new value is longer than old.
  static metadata_set(oldVal, newVal) {
    return !oldVal || newVal.length > oldVal.length;
  }

  is_file_reference() { return true; }

  is_folder() { return false; }

  is_version_stack() { return false; }

  // Polymorphic method from version stack.
  get_target() { return this; }

  label_templates() {
    let layout;
    let labels = ["approved", "in-progress", "needs-review", "remove-label"];
    if (labels.includes(this.label())) {
      layout = labels.filter(key => key !== this.label());
    } else {
      layout = ["add-label", "approved", "in-progress", "needs-review"];
    }

    return { layout, data: { "approved": { icon: JTSVG.plan_check("#454c61", 24, 24, "#2ae8cb"), text: "Approved", width: 126 }, "in-progress": { icon: JTSVG.settings_gear(), text: "In Progress", width: 135 }, "needs-review": { icon: JTSVG.exclamation_mark(), text: "Needs Review", width: 156 }, "remove-label": { icon: JTSVG.remove_cross(), text: "Remove Label" }, "add-label": { icon: JTSVG.down_carat(), text: "Approve" } } };
  }

  version_stack() {
    let vs = JTVersionStack.find_by_key(this.version_stack_key());
    if (!vs) { vs = JTVersionStack.find_by_id(this.owner_key()); }
    return vs;
  }

  set_version_stack(version_stack) {
    if (version_stack) {
      return this.set_version_stack_key(version_stack.hash_key_value());
    } else { return this.set_version_stack_key(null); }
  }

  cell() {
    let vs = this.version_stack();
    if (vs) { return vs.cell(); }
    return this.local_cell();
  }

  set_cell(cell, set_version_stack) {
    if (set_version_stack == null) { set_version_stack = true; }
    let vs = this.version_stack();
    if (vs && set_version_stack) {
      return vs.set_cell(cell);
    } else { return this.set_local_cell(cell); }
  }

  can_delete(user) {
    return this.master_key() === user.id();
  }

  is_private() {
    return this.private() === "1";
  }

  is_shared() {
    return this.shared() === "1";
  }

  ext() {
    let name_split = this.name()
      .split(".");
    if (name_split.length > 1) {
      return name_split.pop();
    } else {
      return "";
    }
  }

  name_no_ext() {
    let name_split = this.name()
      .split(".");
    if (name_split.length > 1) {
      name_split.pop();
      return name_split.join(".");
    } else {
      return this.name();
    }
  }

  is_general_access() {

    let owner_key = this.owner_key();

    let owner = JTFolder.find_by_id(owner_key);
    if (!owner) { owner = JTFolder.find_by_key(owner_key); }

    if (!owner) { owner = JTVersionStack.find_by_id(owner_key); }
    if (!owner) { owner = JTVersionStack.find_by_key(owner_key); }

    if (owner) {
      return owner.is_general_access() && !this.is_private();
    } else {
      return !this.is_private();
    }
  }

  is_dropframe() {
    let start_timecode = this.start_timecode();
    if (start_timecode) { return /[;,]/.test(start_timecode); }
    return false;
  }

  start_timecode() {
    let streams;
    let timecode = null;
    let metadata = this.all_metadata();
    if (metadata) {
      ({ streams } = metadata); }
    if (!streams) { return; }

    for (let stream of Array.from(streams)) {
      if (stream.codec_tag_string === 'tmcd') {
        timecode = stream.tags != null ? stream.tags.timecode : undefined;
      }
    }
    return timecode;
  }

  is_video() {
    let metadata = this.all_metadata();
    if (JT.exists(metadata)) {
      if (metadata['is_video'] === "true") {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.h264_exists()) {
        return true;
      } else {
        let filetype = this.filetype();
        if (JT.exists(filetype)) {
          let format = JTFormats.format(this.filetype());
          return JTFormats.is_video(format);

        } else {
          return false;
        }
      }
    }
  }

  is_video_or_audio() {
    return this.is_video() || this.is_audio();
  }

  folder() {

    let owner_key = this.owner_key();

    let folder = JTFolder.find_by_id(owner_key);
    if (!folder) { folder = JTFolder.find_by_key(owner_key); }

    if (!folder) {
      let version_stack = JTVersionStack.find_by_id(owner_key);
      if (!version_stack) { version_stack = JTVersionStack.find_by_key(owner_key); }

      if (version_stack) { folder = version_stack.folder(); }
    }

    return folder;
  }

  is_image() {
    let metadata = this.all_metadata();
    if (JT.exists(metadata)) {
      if (metadata['is_image'] === "true") {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.h264_exists()) {
        return false;
      } else {
        let filetype = this.filetype();
        if (JT.exists(filetype)) {
          let format = JTFormats.format(this.filetype());
          return JTFormats.is_image(format);

        } else {
          return false;
        }
      }
    }
  }

  has_thumb() {
    return this.thumb() !== "https://static-assets.frame.io/app/thumb_blank.png";
  }

  thumb_or_icon() {
    let thumb = this.thumb();
    if (!this.has_thumb()) {
      switch (true) {
        case this.is_audio():
          return "https://static-assets.frame.io/folder-images/audio%402x.png";
        case this.is_archive():
          return "https://static-assets.frame.io/folder-images/zip%402x.png";
        default:
          return "https://static-assets.frame.io/folder-images/document-2%402x.png";
      }
    } else {
      return thumb;
    }
  }

  is_audio() {
    let metadata = this.all_metadata();
    if (JT.exists(metadata)) {
      if (metadata['is_audio'] === "true") {
        return true;
      } else {
        return false;
      }
    } else {
      let filetype = this.name()
        .split('.')
        .pop();
      if (JT.exists(filetype)) {
        let format = JTFormats.format(filetype);
        return JTFormats.is_audio(format);
      } else {
        return false;
      }
    }
  }

  is_archive() {
    let filetype = this.name()
      .split('.')
      .pop();
    if (JT.exists(filetype)) {
      let format = JTFormats.format(filetype);
      return JTFormats.is_archive(format);
    } else {
      return false;
    }
  }

  is_document() {

    if (!this.is_video() && !this.is_audio() && !this.is_archive()) { return true; }
  }

  is_multipart() {

    if (JT.exists(File.prototype.slice)) {

      let filesize = parseInt(this.filesize());
      let min_size = parseInt(this.part_size()) * 2; // 50 MB

      if (filesize > min_size) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  create_parts(part_size) {

    if (part_size == null) { part_size = null; }
    if (JT.exists(part_size)) { this.set_part_size(parseInt(part_size)); }

    part_size = parseInt(this.part_size());

    let file = this.file();

    if (!this.parts() && file) {

      let i, part;
      let num_parts = this.num_parts();
      let parts = [];
      let start = 0;

      // Do all but last part

      if (num_parts > 1) {

        for (let x = 1, end1 = num_parts - 1, asc = 1 <= end1; asc ? x <= end1 : x >= end1; asc ? x++ : x--) {
          i = x - 1;
          let end = start + part_size;
          part = file.slice(start, end, file.type);
          part.name = `part_${i}`;
          parts.push(part);
          start = end;
        }
      }

      i = num_parts - 1;

      // Last Part
      part = file.slice(start, file.size, file.type);
      part.name = `part_${i}`;

      parts.push(part);

      return this.set_parts(parts);
    }
  }

  num_parts() {

    if (this.is_multipart()) {

      let filesize = parseInt(this.filesize());
      let parts = filesize / parseInt(this.part_size());
      let floor_parts = Math.floor(parts);
      if (parts !== floor_parts) { parts = floor_parts + 1; }

      return parts;
    } else {
      return 1;
    }
  }

  part(key) {

    if (this.is_multipart()) {
      let parts = this.parts();
      return parts ? parts[key] : null;
    } else {
      return this.file();
    }
  }

  part_url(key) {

    if (this.is_multipart()) {

      let urls = this.multipart_urls();
      if (urls) {
        return decodeURIComponent(urls[key]);
      } else {
        return null;
      }
    } else {
      return decodeURIComponent(this.original_upload());
    }
  }

  image_best() {
    let image_full = this.image_full();
    if ((this.image_full_exists() === "1") && JT.exists(image_full)) {
      return this.image_full;
    } else {
      return "";
    }
  }

  make_audio() {

    let filetype = this.filetype();
    if (filetype) {
      let ext = filetype.split("/")
        .pop();
      if (['wav', 'mp3'].includes(ext)) {
        this.set_thumb("https://static-assets.frame.io/test-videos/images/waveform.png");
        this.set_thumb_scrub("https://static-assets.frame.io/test-videos/images/waveform_scrub_1200.png");
        return this.set_thumbs("100");
      }
    }
  }

  should_process(res) {

    let metadata = this.all_metadata();

    return metadata ? metadata[`process_${res}`] === "true" : false;
  }

  h264_resolutions() {

    let streams = this.streams();
    let metadata = this.all_metadata();

    // resolutions = ['auto']
    let resolutions = [];

    if (streams) {

      let h264 = streams['h264'];

      if (h264) {
        if (h264['360'] || this.should_process('360')) { resolutions.unshift('360'); }
        if (h264['540'] || this.should_process('540')) { resolutions.unshift('540'); }
        if (h264['720'] || this.should_process('720')) { resolutions.unshift('720'); }
        if (h264['1080'] || this.should_process('1080')) { resolutions.unshift('1080'); }
      }
    }

    return resolutions;
  }

  download(resolution) {
    let downloads = this.downloads();

    return (downloads['h264'] != null ? downloads['h264'][resolution] : undefined);
  }

  h264(resolution, best_fit) {

    let h264;
    if (resolution == null) { resolution = 'auto'; }
    if (best_fit == null) { best_fit = false; }
    let stream = "";

    let streams = this.streams();

    if (streams) {

      h264 = streams['h264'];

      if (h264) {

        switch (resolution) {

          case 'auto':
            stream = h264['540'];
            if (!stream) { stream = h264['1080']; }
            break;

          case 'quicklook':
            stream = h264['quicklook'];
            break;

          case 'split_01':
            stream = h264['split_01'];
            break;

          case 'split_02':
            stream = h264['split_01'];
            break;

          case 'lowest':
            stream = h264['360'];
            if (!stream) { stream = h264['540']; }
            if (!stream) { stream = h264['720']; }
            if (!stream) { stream = h264['1080']; }
            break;

          case 'highest':
            stream = h264['1080'];
            if (!stream) { stream = h264['720']; }
            if (!stream) { stream = h264['540']; }
            if (!stream) { stream = h264['360']; }
            break;

          default:
            stream = h264[resolution];
        }
      }
    }

    if (!stream && best_fit) {

      stream = h264['540'];
      if (!stream) { stream = h264['720']; }
      if (!stream) { stream = h264['1080']; }
      if (!stream) { stream = h264['360']; }
    }

    if (!stream) { stream = ""; }

    return stream;
  }

  // Old keep

  // h264_best 	= @h264_best()
  // h264_720 	= @h264_720()

  // if @h264_best_exists() == "1" && JT.exists(h264_best)
  // 	return h264_best
  // else if @h264_720_exists() == "1" && JT.exists(h264_720)
  // 	return @h264_720()
  // else
  // 	return ""

  h264_exists() {

    let streams = this.streams();

    if (streams) {

      let h264 = streams['h264'];

      if (h264) {
        if (h264['1080']) { return true; }
        if (h264['720']) { return true; }
        if (h264['540']) { return true; }
        if (h264['360']) { return true; }
      }
    }

    return false;
  }

  // Old
  // return @h264_best_exists() == "1" || @h264_720_exists() == "1"

  webm(resolution, best_fit) {

    let webm;
    if (resolution == null) { resolution = 'auto'; }
    if (best_fit == null) { best_fit = false; }
    let stream = "";

    let streams = this.streams();

    if (streams) {

      webm = streams['webm'];

      if (webm) {

        switch (resolution) {
          case 'auto':
            stream = webm['540'];
            if (!stream) { stream = webm['1080']; }
            break;

          case 'quicklook':
            stream = webm['quicklook'];
            break;

          case 'split_01':
            stream = webm['split_01'];
            break;

          case 'split_02':
            stream = webm['split_01'];
            break;

          case 'lowest':
            stream = webm['360'];
            if (!stream) { stream = webm['540']; }
            if (!stream) { stream = webm['720']; }
            if (!stream) { stream = webm['1080']; }
            break;

          case 'highest':
            stream = webm['1080'];
            if (!stream) { stream = webm['720']; }
            if (!stream) { stream = webm['540']; }
            if (!stream) { stream = webm['360']; }
            break;

          default:
            stream = webm[resolution];
        }
      }
    }

    if (!stream && best_fit) {

      stream = webm['540'];
      if (!stream) { stream = webm['720']; }
      if (!stream) { stream = webm['1080']; }
      if (!stream) { stream = webm['360']; }
    }

    if (!stream) { stream = ""; }

    return stream;
  }

  webm_exists() {

    let streams = this.streams();

    if (streams) {

      let webm = streams['webm'];

      if (webm) {
        if (webm['1080']) { return true; }
        if (webm['720']) { return true; }
        if (webm['540']) { return true; }
        if (webm['360']) { return true; }
      }
    }

    return false;

    // Old
    return this.webm_best_exists() === "1";
  }

  width() {

    let original_width = this.original_width();

    if (!JT.exists(original_width) || (original_width === "0")) {
      let metadata = this.all_metadata();
      if (metadata) {
        let image = metadata['image'];
        if (image) {
          let geometry = metadata['geometry'];
          if (geometry) {
            let geo_split = geometry.split(/[^\d]+/);
            return parseInt(geo_split[0]);
          }
        }
      }
      return 1000;
    } else {
      return parseInt(original_width);
    }
  }

  height() {

    let original_height = this.original_height();

    if (!JT.exists(original_height) || (original_height === "0")) {
      let metadata = this.all_metadata();
      if (metadata) {
        let image = metadata['image'];
        if (image) {
          let geometry = metadata['geometry'];
          if (geometry) {
            let geo_split = geometry.split(/[^\d]+/);
            return parseInt(geo_split[1]);
          }
        }
      }

      return 1000;

    } else {
      return parseInt(original_height);
    }
  }

  profile_image() {

    let uploader = JTUser.find_by_id(this.master_key());
    if (uploader) {
      return uploader.image(64);
    } else {
      return "https://static-assets.frame.io/app/anon.jpg";
    }
  }

  data(front_end_attrs) {
    if (front_end_attrs == null) { front_end_attrs = true; }
    let data = {};
    data['file_reference'] = this.db_attrs_hash();

    let parts = this.parts();
    if (!JT.exists(parts)) { parts = []; }

    let part_count = parts.length;

    if (part_count > 1) {

      data['file_reference']['is_multipart'] = true;
      data['file_reference']['parts'] = part_count;
    }

    if (front_end_attrs) {
      data['file_reference']['frontend_id'] = this.hash_key_value();
    }

    return data;
  }

  get_user_stars(user) {

    let user_id = null;
    switch (true) {
      case user instanceof String:
        user_id = user;
        break;
      case user instanceof JTUser:
        user_id = user.id();
        break;
    }

    if (user_id) {
      let all_stars = this.user_stars();
      if (JT.exists(all_stars) && all_stars instanceof Object) {
        let user_stars = all_stars[user_id];
        if (user_stars) {
          return parseInt(user_stars);
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  video_codec_long() {

    let metadata = this.all_metadata();

    if (metadata) {
      // new metadata has codec name at top level.
      if (metadata['codec_name']) {
        return metadata['codec_name'].split(/\s+/)[0];
      }
      for (let i in metadata['streams']) {
        let stream = metadata['streams'][i];
        if (stream['codec_type'] === 'video') {
          let long_name = stream['codec_long_name'];
          if (long_name) {
            long_name = long_name.split(/\s+/)[0];
            return long_name;
          }
        }
      }
    }
    return null;
  }

  audio_codec_long() {

    let metadata = this.all_metadata();

    if (metadata) {
      for (let i in metadata['streams']) {
        let stream = metadata['streams'][i];
        if (stream['codec_type'] === 'audio') {
          let long_name = stream['codec_long_name'];
          if (long_name) {
            long_name = long_name.split(/\s+/)[0];
            return long_name;
          }
        }
      }
    }
    return null;
  }

  new_fps() {
    let fps;
    let metadata = this.all_metadata();

    if (metadata) {
      fps = metadata['fps'];
      if (!fps) { fps = 25; }
      return fps;
    } else {
      return this.fps();
    }
  }

  total_frames() {
    let metadata = this.all_metadata();

    if (metadata) {
      for (let i in metadata['streams']) {
        let stream = metadata['streams'][i];
        if (stream['codec_type'] === 'video') {
          let frames = stream['nb_frames'];
          if (frames) {
            frames = parseInt(frames);
            return frames;
          }
        }
      }
    }
    return null;
  }
}

export class JTNotification extends JTDynamoObject {

  constructor(data) {
    super(data);
    this.data = this.data.bind(this);
  }

  static classname() { return "JTNotification"; }
  static hash_key() { return 'frontend_id'; }
  static secondary_keys() { return ['id']; }
  static db_attrs() { return ['id', 'user_id', 'target_id', 'target_class', 'action', 'project_id', 'label', 'created_at_integer', 'ext', 'project_name', 'user_image', 'user_name', 'target']; }

  static local_attrs() { return ['frontend_id']; }
  static sorted_by() { return this.sortable_created_at_integer; }
  static has_master() { return true; }
  static has_owner() { return true; }

  static sortable_created_at_integer(a, b) {
    return parseInt(b.created_at_integer()) - parseInt(a.created_at_integer());
  }

  data(front_end_attrs) {
    if (front_end_attrs == null) { front_end_attrs = true; }
    let data = {};
    data['notification'] = this.db_attrs_hash();
    if (front_end_attrs) { data['notification']['frontend_id'] = this.hash_key_value(); }

    return data;
  }
}

export class JTJoinRequest extends JTDynamoObject {

  constructor(data) {
    super(data);
    this.data = this.data.bind(this);
  }

  static classname() { return "JTJoinRequest"; }
  static hash_key() { return 'frontend_id'; }
  static secondary_keys() { return ['id']; }
  static db_attrs() { return ['id', 'user_key', 'acceptor_key', 'accepted_at', 'label', 'created_at_integer', 'requester_type']; }

  static local_attrs() { return ['frontend_id', 'team']; }

  static associates() { return { user: JTUser, acceptor: JTUser, team: JTTeam }; }

  static collections() { return { seen_user: JTUser }; }

  data(front) {
    let data = {};
    data['join_request'] = this.db_attrs_hash();
    if (front_end_attrs) { data['join_request']['frontend_id'] = this.hash_key_value(); }

    return data;
  }
}

export class JTProject extends JTDynamoObject {

  constructor(data) {
    super(data);

    this.data = this.data.bind(this);
    this.analytics_data = this.analytics_data.bind(this);
    this.team = this.team.bind(this);
    this.team_members = this.team_members.bind(this);
    this.has_user_on_team = this.has_user_on_team.bind(this);
    this.is_only_collaborator = this.is_only_collaborator.bind(this);
    this.people = this.people.bind(this);
    this.review_links = this.review_links.bind(this);
    this.is_private = this.is_private.bind(this);
    this.is_shared = this.is_shared.bind(this);
    this.is_shared_text = this.is_shared_text.bind(this);
    this.collaborator_can_download = this.collaborator_can_download.bind(this);
    this.collaborator_can_share = this.collaborator_can_share.bind(this);
    this.collaborator_can_invite = this.collaborator_can_invite.bind(this);
    this.url = this.url.bind(this);
    this.explain_text = this.explain_text.bind(this);
    this.is_view_only = this.is_view_only.bind(this);
    this.is_view_only_text = this.is_view_only_text.bind(this);
    this.is_view_downloadable = this.is_view_downloadable.bind(this);
    this.is_view_downloadable_text = this.is_view_downloadable_text.bind(this);
    this.is_joinable = this.is_joinable.bind(this);
    this.is_joinable_text = this.is_joinable_text.bind(this);
    this.has_any_user_email_settings = this.has_any_user_email_settings.bind(this);
    this.remove_all_email_settings = this.remove_all_email_settings.bind(this);
    this.add_all_email_settings = this.add_all_email_settings.bind(this);
  }

  static classname() { return "JTProject"; }
  static hash_key() { return 'frontend_id'; }
  static secondary_keys() { return ['id']; }
  static db_attrs() { return ['id', 'name', 'shared', 'view_only', 'view_downloadable', 'joinable', 'description', 'created_at_integer', 'updated_at_integer', 'user_id', 'team_data', 'creator', 'root_folder_key', 'slack_notifications_pref', 'private', 'ignore_archive']; }
  static local_attrs() { return ['frontend_id', 'user_prefs', 'collaborator_ids', 'project_prefs', 'clear_from_usage', 'slack_notifications_pref', 'private']; }
  static sorted_by() { return 'name'; }
  static has_master() { return true; }
  static has_owner() { return true; }
  static collections() { return { file_reference: JTFileReference, collaborator: JTUser, pending_collaborator: JTPendingUser, folder: JTFolder }; }

  static aggregates() { return { item: ['folder', 'file_reference'], all_collaborator: ['collaborator', 'pending_collaborator'] }; }

  data(front_end_attrs) {
    if (front_end_attrs == null) { front_end_attrs = true; }
    let data = {};

    data['project'] = this.db_attrs_hash();

    if (front_end_attrs) {
      data['project']['frontend_id'] = this.hash_key_value();
      data['project']['team_id'] = this.team()
        .id();
      if (this.user_prefs()) { data['preferences'] = this.user_prefs(); }
      if (this.project_prefs()) { data['project_preferences'] = this.project_prefs(); }
      if (this.slack_notifications_pref()) { data['slack_notifications_pref'] = this.slack_notifications_pref(); }
      if (this.private()) { data['private'] = this.private(); }
    }

    return data;
  }

  analytics_data() {

    let data = {};

    let owner = this.owner();
    if (owner) {
      data['team_id'] = owner.id();
    }

    let prefs = this.project_prefs();

    if (prefs) {

      for (let pref_key in prefs) {

        let pref_value = prefs[pref_key];
        data[`project_setting_${pref_key}`] = pref_value === "1" ? true : false;
      }
    }

    data['user_id'] = this.user_id();

    let track_properties = ['created_at_integer', 'id', 'name', 'root_folder_key', 'updated_at_integer'];

    for (let property of Array.from(track_properties)) {

      data[property] = this[property]();
    }

    return data;
  }

  team() {
    let team;
    let team_data = this.team_data();
    if (team_data && team_data['id']) {
      team = JTTeam.find_by_id(team_data['id']);
    }

    return team;
  }

  team_members() {
    return __guard__(this.team(), x => x.members());
  }

  has_user_on_team(user_key) {
    if (this.team()
      .member_keys()
      .includes(user_key)) { return true; } else { return false; }
  }

  is_only_collaborator(user_id) {
    const jtUser = JTUser.find_by_id(user_id);
    if (!jtUser) return true; // True value is more restrictive.
    const is_team_member = this.team() && this.team().has_member(jtUser);
    const is_account_admin = jtUser.get_account_role(
      this.team() && this.team().account()
    ) === 'admin';
    return !(is_team_member || is_account_admin);
  }

  people() {
    let unique_people_arr;
    let team_members = this.team_members();
    let collaborators = this.collaborators();
    let everyone = team_members.concat(collaborators);
    let unique_people_hash = {};
    for (let person of Array.from(everyone)) {
      unique_people_hash[person.id()] = person;
    }
    return unique_people_arr = ((() => {
      let result = [];
      for (let key in unique_people_hash) {
        let val = unique_people_hash[key];
        result.push(val);
      }
      return result;
    })());
  }

  review_links() { if (this.id() === '7Pq2Oqyg') { return random_review_link_arr; } else { return []; } }

  is_private() {
    return this.private() === "1";
  }

  is_shared() {
    return this.shared() === "1";
  }

  is_shared_text() {
    let state = this.is_shared() ? "ON" : "OFF";
    return `Project Sharing ${state}`;
  }

  collaborator_can_download() {
    return __guard__(this.project_prefs(), x => x['collaborator_can_download']) === "1";
  }

  collaborator_can_share() {
    return __guard__(this.project_prefs(), x => x['collaborator_can_share']) === "1";
  }

  collaborator_can_invite() {
    return __guard__(this.project_prefs(), x => x['collaborator_can_invite']) === "1";
  }

  url() {
    return JT.exists(this.id()) ? `https://app.frame.io?p=${this.id()}` : "";
  }

  explain_text() {
    return "Sharing a project makes it joinable via a unique URL.";
  }

  is_view_only() {
    return this.view_only() === "1";
  }

  is_view_only_text() {
    let state = this.is_view_only() ? "ON" : "OFF";
    return `View Only ${state}`;
  }

  is_view_downloadable() {
    return this.view_downloadable() === "1";
  }

  is_view_downloadable_text() {
    let state = this.is_view_downloadable() ? "ON" : "OFF";
    return `View and Download Only ${state}`;
  }

  is_joinable() {
    return this.joinable() === "1";
  }

  is_joinable_text() {
    let state = this.is_joinable() ? "ON" : "OFF";
    return `Allow Joining Project ${state}`;
  }

  has_any_user_email_settings() {
    let user_prefs = this.user_prefs();
    for (let key in user_prefs) {
      let val = user_prefs[key];
      if ((key.includes("email") || key == "notify_on_updated_label") && val === "1") {
        return true;
      }
    }
    return false;
  }

  remove_all_email_settings() {
    let new_user_prefs = {};
    let user_prefs = this.user_prefs();
    for (let key in user_prefs) { let val = user_prefs[key];
      new_user_prefs[key] = "0"; }

    this.set_user_prefs(new_user_prefs);
    return new_user_prefs;
  }

  add_all_email_settings() {

    let new_user_prefs = {};
    let user_prefs = this.user_prefs();
    for (let key in user_prefs) { let val = user_prefs[key];
      new_user_prefs[key] = "1"; }

    this.set_user_prefs(new_user_prefs);
    return new_user_prefs;
  }
}

export class JTFolder extends JTDynamoObject {

  constructor(data) {
    super(data);

    this.data = this.data.bind(this);
    this.can_delete = this.can_delete.bind(this);
    this.is_file_reference = this.is_file_reference.bind(this);
    this.is_folder = this.is_folder.bind(this);
    this.is_version_stack = this.is_version_stack.bind(this);
    this.folder = this.folder.bind(this);
    this.thumbs = this.thumbs.bind(this);
    this.filetype = this.filetype.bind(this);
    this.get_user_stars = this.get_user_stars.bind(this);
    this.is_private = this.is_private.bind(this);
    this.is_shared = this.is_shared.bind(this);
    this.is_general_access = this.is_general_access.bind(this);
    this.add_item_at_index = this.add_item_at_index.bind(this);
    this.thumb_or_icon = this.thumb_or_icon.bind(this);
    this.reorder = this.reorder.bind(this);
    this.get_target = this.get_target.bind(this);
  }

  static classname() { return "JTFolder"; }
  static hash_key() { return 'frontend_id'; }
  static secondary_keys() { return ['id']; }
  static db_attrs() { return ['id', 'name', 'index', 'shared', 'private', 'general_access', 'can_download', 'presentation_expire', 'password', 'description', 'created_at_integer', 'updated_at_integer', 'creator', 'master_key', 'item_count', 'project_key']; }
  static local_attrs() { return ['frontend_id', 'thumb_state', 'cell', 'title_cell', 'waiting_dots', 'title_id', 'filesize']; }
  static sorted_by() { return this.sortable_index; }
  static has_master() { return true; }
  static has_owner() { return true; }
  static collections() { return { file_reference: JTFileReference, version_stack: JTVersionStack, folder: JTFolder }; }

  static aggregates() { return { item: ['folder', 'version_stack', 'file_reference'] }; }

  static defaults() {
    return {
      thumb_state: 'folder',
      index: 0,
      can_download: "0",
      private: "0",
      item_count: 0,
    };
  }

  data(front_end_attrs) {
    if (front_end_attrs == null) { front_end_attrs = true; }
    let data = {};
    data['folder'] = this.db_attrs_hash();

    if (front_end_attrs) {
      data['folder']['frontend_id'] = this.hash_key_value();
    }

    return data;
  }

  can_delete(user) {

    let can_delete = this.master_key() === user.id();

    this.items()
      .each(item => {
        if (can_delete) { return can_delete = item.can_delete(user); }
      });

    return can_delete;
  }

  is_file_reference() { return false; }

  is_folder() { return true; }

  is_version_stack() { return false; }

  folder() {

    let folder = JTFolder.find_by_id(this.owner_key());
    if (!folder) { folder = JTFolder.find_by_key(this.owner_key()); }
    return folder;
  }

  fill_thumbs(thumbs, count, collection) {
    for (var i=0; i < count; i++ ) {
      const item = collection[i];
      if (item) { thumbs.push(item.thumb_or_icon()); }
      if (thumbs.count == count) { break; }
    }
    return thumbs;
  }

  thumbs(count) {

    // New method prioritizes file_refs, version stacks, and folders

    if (count == null) { count = 3; }

    var thumbs = [];
    thumbs = this.fill_thumbs(thumbs, count, this.file_references());
    if (thumbs.length == count) { return thumbs; }

    thumbs = this.fill_thumbs(thumbs, count, this.version_stacks());
    if (thumbs.length == count) { return thumbs; }

    thumbs = this.fill_thumbs(thumbs, count, this.folders());
    if (thumbs.length == count) { return thumbs; }

    while (thumbs.length < 3) { thumbs.push(""); }
    return thumbs;
  }

  filetype() {
    return 'folder';
  }

  get_user_stars() {
    return 0;
  }

  is_private() {
    return this.private() === "1";
  }

  is_shared() {
    return this.shared() === "1";
  }

  is_general_access() {

    let folder = this.folder();
    if (folder) {
      return folder.is_general_access() && !this.is_private();
    } else {
      return !this.is_private();
    }
  }

  add_item_at_index(new_item, index) {

    switch (true) {
      case new_item instanceof JTFileReference:
        this.add_file_reference(new_item);
        break;
      case new_item instanceof JTVersionStack:
        this.add_version_stack(new_item);
        break;
      case new_item instanceof JTFolder:
        this.add_folder(new_item);
        break;
    }

    let i = 0;
    this.items()
      .each(item => {
        if (item) {
          if (item.hash_key_value() === new_item.hash_key_value()) {
            item.set_index(index);
          } else {
            if (i === index) { i += 1; }
            item.set_index(i);
          }
          return i += 1;
        }
      });
    return this.clear_item_cache();
  }

  thumb_or_icon() {
    return "https://static-assets.frame.io/folder-images/folder%402x.png";
  }

  reorder(overrides, sorted_by, filter_by) {

    let hash_keys = {};
    let hash_indices = {};

    this.clear_item_cache();

    if (overrides) {
      for (let override_item of Array.from(overrides)) {
        hash_keys[override_item.hash_key_value()] = override_item;
        hash_indices[parseInt(override_item.index())] = override_item;
      }
    }

    let new_indices = [];
    for (let i = 0, end = this.items()
        .count() - 1, asc = 0 <= end; asc ? i <= end : i >= end; asc ? i++ : i--) {
      if (!hash_indices[i]) {
        new_indices.push(parseInt(i));
      }
    }

    this.items()
      .each(item => {
        if (!hash_keys[item.hash_key_value()]) {
          return item.set_index(`${new_indices.shift()}`);
        }
      });

    this.clear_item_cache();

    return this.items(sorted_by, filter_by);
  }

  get_target() { return this.items()
      .first(); }

  static sortable_index(a, b) {
    return parseInt(a.index()) - parseInt(b.index());
  }
}

export class JTVersionStack extends JTDynamoObject {

  constructor(data) {
    super(data);

    this.can_delete = this.can_delete.bind(this);
    this.is_file_reference = this.is_file_reference.bind(this);
    this.is_folder = this.is_folder.bind(this);
    this.is_version_stack = this.is_version_stack.bind(this);
    this.is_private = this.is_private.bind(this);
    this.is_shared = this.is_shared.bind(this);
    this.is_general_access = this.is_general_access.bind(this);
    this.folder = this.folder.bind(this);
    this.get_target = this.get_target.bind(this);
    this.set_upload_status = this.set_upload_status.bind(this);
    this.add_file_reference_at_index = this.add_file_reference_at_index.bind(this);
    this.has_thumb = this.has_thumb.bind(this);
    this.thumb_or_icon = this.thumb_or_icon.bind(this);
  }

  static classname() { return "JTVersionStack"; }
  static hash_key() { return 'frontend_id'; }
  static secondary_keys() { return ['id']; }
  static db_attrs() { return ['id', 'index', 'shared', 'private', 'can_download', 'presentation_expire', 'password', 'updated_at_integer', 'owner_key', 'archive_from']; }
  static local_attrs() { return ['frontend_id', 'thumb_state', 'cell', 'title_cell', 'waiting_dots', 'title_id', 'item_count']; }
  static sorted_by() { return this.sortable_index; }
  static has_master() { return true; }
  static has_owner() { return true; }
  static collections() { return { file_reference: JTFileReference }; }

  static chain_methods() {
    return [{
      target: 'get_target',
      methods: ['name', 'created_at_integer', 'filetype', 'filesize', 'location', 'ext', 'name_no_ext', 'label',
        'description', 'type', 'thumb', 'media_icon', 'thumb_scrub', 'cover_720', 'h264_720',
        'h264_720_copy', 'h264_720_exists', 'quicklook_media', 'original_download', 'original_upload', 'detail_type',
        'detail', 'comment_count', 'master_key', 'fps', 'frames', 'h264', 'webm', 'new_fps',
        'original_height', 'original_width', 'thumb_height', 'thumb_width', 'thumbs', 'file', 'upload_status',
        'thumb_type', 'time', 'loaded', 'upload_percent', 'upload_speed', 'upload_progress', 'cover_exists', 'cover', 'thumb_exists',
        'profile_image', 'stars', 'is_image', 'h264_exists', 'image_full', 'image_full_exists', 'width', 'height', 'is_audio', 'is_video', 'is_image', 'is_document', 'is_archive', 'user_stars', 'get_user_stars',
        'is_video_or_audio', 'project_key'
      ]
    }];
  }

  static defaults() {
    return {
      thumb_state: 'version_stack',
      index: 0,
      can_download: "0",
      private: "0"
    };
  }

  static sortable_index(a, b) {
    return parseInt(a.index()) - parseInt(b.index());
  }

  can_delete(user) {

    let can_delete = true;

    this.file_references()
      .each(file_ref => {
        if (can_delete) { return can_delete = file_ref.can_delete(user); }
      });

    return can_delete;
  }

  is_file_reference() { return false; }

  is_folder() { return false; }

  is_version_stack() { return true; }

  is_private() {
    return this.private() === "1";
  }

  is_shared() {
    return this.shared() === "1";
  }

  is_general_access() {
    let folder = this.folder();
    if (folder) {
      return folder.is_general_access() && !this.is_private();
    } else {
      return !this.is_private();
    }
  }

  folder() {

    let folder = JTFolder.find_by_id(this.owner_key());
    if (!folder) { folder = JTFolder.find_by_key(this.owner_key()); }

    return folder;
  }

  get_target() { return this.file_references()
      .first(); }

  set_upload_status(status) {

    let target = this.get_target();
    if (target) { return target.set_upload_status(status); }
  }

  add_file_reference_at_index(file_reference, index) {

    this.add_file_reference(file_reference);

    let i = 0;
    this.file_references()
      .each(file_ref => {

        if (file_ref.hash_key_value() === file_reference.hash_key_value()) {

          file_ref.set_index(index);
        } else {
          if (i === index) { i += 1; }
          file_ref.set_index(i);
        }

        return i += 1;
      });

    return this.clear_file_reference_cache();
  }

  has_thumb() {
    return !!__guard__(this.get_target(), x => x.has_thumb());
  }

  thumb_or_icon() {
    let target = this.get_target();
    if (target) {
      return target.thumb_or_icon();
    } else {
      return "https://static-assets.frame.io/app/thumb_blank.png";
    }
  }
}

// Not really DB object just used for selections of items
export class JTItemList extends JTDynamoObject {

  constructor(data) {
    super(data);

    this.count = this.count.bind(this);
    this.first = this.first.bind(this);
    this.last = this.last.bind(this);
    this.add = this.add.bind(this);
    this.select = this.select.bind(this);
    this.remove = this.remove.bind(this);
    this.moving = this.moving.bind(this);
    this.clear_moving = this.clear_moving.bind(this);
    this.clear = this.clear.bind(this);
    this.drag = this.drag.bind(this);
    this.cancel_drag = this.cancel_drag.bind(this);
    this.can_delete = this.can_delete.bind(this);
    this.thumbs = this.thumbs.bind(this);
    this.thumbs_or_icons = this.thumbs_or_icons.bind(this);
    this.hide = this.hide.bind(this);
  }

  static classname() { return "JTItemList"; }
  static hash_key() { return 'frontend_id'; }
  static secondary_keys() { return ['id']; }
  static db_attrs() { return ['id']; }
  static local_attrs() { return ['frontend_id', 'last_selected']; }
  static sorted_by() { return this.sortable_index; }
  static has_master() { return true; }
  static has_owner() { return true; }
  static collections() { return { file_reference: JTFileReference, version_stack: JTVersionStack, folder: JTFolder }; }

  static aggregates() { return { item: ['folder', 'version_stack', 'file_reference'], command_item: ['folder', 'version_stack', 'file_reference'] }; }

  count() {
    return this.items()
      .length;
  }

  first() {
    return this.items()
      .first();
  }

  last() {
    return this.items()
      .last();
  }

  add(item, set_command_selected) {
    if (set_command_selected == null) { set_command_selected = true; }
    if (item && item.cell()) { item.cell()
        .select(false); }

    this.add_item(item);

    if (set_command_selected) {

      this.add_command_item(item);
      return this.set_last_selected(item);
    }
  }

  select() {
    return this.items()
      .each(item => {
        if (item.cell()) { return item.cell()
            .select(false); }
      });
  }

  remove(item) {

    if (item && item.cell()) { item.cell()
        .deselect(); }
    this.remove_item(item);

    if (this.has_command_item(item)) { return this.remove_command_item(item); }
  }

  moving() {
    this.is_moving = true;
    return this.items()
      .each(item => {
        if (item.cell()) { return item.cell()
            .moving(false); }
      });
  }

  clear_moving() {
    this.is_moving = false;
    return this.items()
      .each(item => {
        if (item.cell()) { return item.cell()
            .clear_moving(); }
      });
  }

  clear(clear_history) {
    if (clear_history == null) { clear_history = true; }
    this.is_moving = false;

    this.items()
      .each(item => {
        if (item.cell()) { return item.cell()
            .deselect(); }
      });
    this.clear_items();

    if (clear_history) {
      this.clear_command_items();
      return this.set_last_selected(null);
    }
  }

  drag() {
    this.is_dragging = true;
    return this.items()
      .each(item => {
        if (item.cell()) { return item.cell()
            .drag(); }
      });
  }

  cancel_drag() {
    this.is_dragging = false;
    return this.items()
      .each(item => {
        if (item.cell()) { return item.cell()
            .cancel_drag(); }
      });
  }

  can_delete(user) {
    let can_delete = true;
    this.items()
      .each(item => {
        if (can_delete) { return can_delete = item.can_delete(user); }
      });
    return can_delete;
  }

  thumbs(n) {
    this.clear_item_cache();
    if (!n) { n = this.count(); }
    return __range__(0, n - 1, true)
      .map(x => this.items()[x] && this.items()[x].thumb ? this.items()[x].thumb() : "");
  }

  thumbs_or_icons(n) {
    this.clear_item_cache();
    if (!n) { n = this.count(); }
    return __range__(0, n - 1, true)
      .map(x => this.items()[x] && this.items()[x].thumb_or_icon ? this.items()[x].thumb_or_icon() : "");
  }

  hide() {
    return this.items()
      .each(item => {
        if (item.cell()) { return item.cell()
            .hide(); }
      });
  }
}

export class FIOTeamMemberInvite extends JTDynamoObject {

  // Used only as a frontend object for inviting users to teams

  constructor(data) {
    super(data);

    this.clear = this.clear.bind(this);
    this.data = this.data.bind(this);
  }

  static classname() { return "FIOTeamMemberInvite"; }
  static hash_key() { return 'frontend_id'; }
  static secondary_keys() { return ['id']; }
  static db_attrs() { return ['id', 'role', 'message']; }
  static local_attrs() { return ['frontend_id']; }
  static defaults() { return { role: 'member' }; }

  static collections() { return { pending_user: JTPendingUser, existing_user: JTUser, team: JTTeam }; }

  static aggregates() { return { user: ['pending_user', 'existing_user'] }; }

  clear() {

    this.set_message(null);
    this.set_role('member');

    this.teams()
      .each(team => this.remove_team(team));
    return this.users()
      .each(user => this.remove_user(user));
  }

  data() {

    this.clear_team_cache();
    this.clear_user_cache();

    let data = {};
    data['role'] = this.role();
    data['message'] = this.message();
    data['teams'] = this.teams()
      .map(t => ({ id: t.id() }));
    data['users'] = this.users()
      .map(u => ({ email: u.email() }));

    return data;
  }
}

export class FIOUserCollection extends JTDynamoObject {

  constructor(data) {
    super(data);
    this.logout_users_data = this.logout_users_data.bind(this);
  }

  static classname() { return "FIOUserCollection"; }
  static hash_key() { return 'frontend_id'; }
  static secondary_keys() { return ['id']; }
  static db_attrs() { return ['id']; }
  static local_attrs() { return ['frontend_id']; }

  static collections() { return { pending_user: JTPendingUser, existing_user: JTUser }; }

  static aggregates() { return { user: ['pending_user', 'existing_user'] }; }

  logout_users_data() {

    this.clear_user_cache();

    let data = {};
    data['users'] = this.existing_users()
      .map(u => ({ id: u.id() }));

    return data;
  }
}

export class FIOTeamCollection extends JTDynamoObject {

  static classname() { return "FIOTeamCollection"; }
  static hash_key() { return 'frontend_id'; }
  static secondary_keys() { return ['id']; }
  static db_attrs() { return ['id']; }
  static local_attrs() { return ['frontend_id']; }

  static collections() { return { team: JTTeam }; }
}

export class JTComment extends JTDynamoObject {

  constructor(data) {
    super(data);

    this.data = this.data.bind(this);
    this.read_by = this.read_by.bind(this);
    this.read_by_names = this.read_by_names.bind(this);
    this.read_by_count_message = this.read_by_count_message.bind(this);
    this.mark_read = this.mark_read.bind(this);
    this.liked_by_names = this.liked_by_names.bind(this);
    this.liked_by = this.liked_by.bind(this);
    this.like = this.like.bind(this);
    this.unlike = this.unlike.bind(this);
    this.likes = this.likes.bind(this);
    this.to_comment = this.to_comment.bind(this);
    this.is_reply = this.is_reply.bind(this);
    this.user = this.user.bind(this);
    this.contains = this.contains.bind(this);
    this.html_text = this.html_text.bind(this);
    this.mark_complete = this.mark_complete.bind(this);
    this.unmark_complete = this.unmark_complete.bind(this);
    this.is_complete = this.is_complete.bind(this);
  }

  static classname() { return "JTComment"; }
  static hash_key() { return 'frontend_id'; }
  static secondary_keys() { return ['id']; }
  static db_attrs() { return ['id', 'text', 'timestamp', 'created_at_integer', 'updated_at_integer', 'annotation_object', 'complete', 'completed_by', 'completed_at', 'read_by_data', 'liked_by_data', 'pitch', 'yaw', 'fov']; }
  static local_attrs() { return ['frontend_id', 'failed', 'request_block', 'is_editing', 'cell_height', 'reply_box_text', 'reply_box_height', 'text_height', 'should_highlight', 'selected', 'hash_ids', 'link_ids', 'cell', 'indicator', 'text_cell', 'options_cell', 'file_reference', 'project', 'reply_box', 'thumb']; }
  static sorted_by() { return this.sortable_created_at_integer; }
  static has_master() { return true; }
  static has_owner() { return true; }
  static defaults() { return { selected: false, hash_ids: {}, link_ids: {}, should_highlight: false, reply_box_height: 0, read_by_data: {}, is_editing: false }; }
  static collections() { return { reply: JTComment }; }

  static sortable_created_at_integer(a, b) {
    return parseInt(a.created_at_integer()) - parseInt(b.created_at_integer());
  }

  data(front_end_attrs) {

    if (front_end_attrs == null) { front_end_attrs = true; }
    let data = {};

    if (this.is_reply()) {
      data['reply'] = this.db_attrs_hash();
      if (front_end_attrs) { data['reply']['frontend_id'] = this.frontend_id(); }
      data['reply']['master_key'] = this.master_key();
      data['reply']['owner_key'] = this.owner_key();
    } else {
      data['comment'] = this.db_attrs_hash();
      let annotation = this.annotation_object();
      if (annotation) { data['comment']['annotation'] = annotation; }

      if (front_end_attrs) { data['comment']['frontend_id'] = this.frontend_id(); }
      data['comment']['master_key'] = this.master_key();
    }

    data['oid'] = this.owner_key();

    return data;
  }

  read_by(user) {
    let read_by_data = this.read_by_data();
    if (!read_by_data) { read_by_data = {}; }
    return JT.exists(read_by_data[user.id()]) || (user.id() === this.master_key());
  }

  read_by_names(current_user) {

    let current_user_id;
    if (current_user == null) { current_user = null; }
    let read_by_data = this.read_by_data();

    let names = [];

    if (current_user) { current_user_id = current_user.id(); }
    let add_current_user = false;

    for (let id in read_by_data) {

      let timestamp = read_by_data[id];
      if (current_user_id && (current_user_id === id)) {
        add_current_user = true;
      } else {
        let user = JTUser.find_by_id(id);
        if (user) {
          names.push(user.name());
        }
      }
    }

    if (add_current_user && (current_user_id !== this.master_key())) { names.unshift("You"); }

    return names.join(", ");
  }

  read_by_count_message() {

    let read_by_data = this.read_by_data();

    let count = Object.keys(read_by_data)
      .length;

    return count === 1 ? "Read by 1 person" : `Read by ${count} people`;
  }

  mark_read(user) {

    let read_by_data = this.read_by_data();
    if (!read_by_data) { read_by_data = {}; }

    let timestamp = String(Math.round(Date.now() / 1000));

    if (!JT.exists(read_by_data[user.id()])) { read_by_data[user.id()] = { timestamp }; }

    return this.set_read_by_data(read_by_data);
  }

  liked_by_names(current_user) {

    let current_user_id;
    if (current_user == null) { current_user = null; }
    let liked_by_data = this.liked_by_data();

    let names = [];

    if (current_user) { current_user_id = current_user.id(); }
    let add_current_user = false;

    for (let id in liked_by_data) {

      let timestamp = liked_by_data[id];
      if (current_user_id && (current_user_id === id)) {
        add_current_user = true;
      } else {
        let user = JTUser.find_by_id(id);
        if (user) {
          names.push(user.name());
        }
      }
    }

    if (add_current_user) { names.unshift("You"); }

    return names.join(", ");
  }

  liked_by(user) {

    let liked_by_data = this.liked_by_data();
    if (!liked_by_data) { liked_by_data = {}; }

    return JT.exists(liked_by_data[user.id()]);
  }

  like(user) {

    let liked_by_data = this.liked_by_data();
    if (!liked_by_data) { liked_by_data = {}; }

    let timestamp = String(Math.round(Date.now() / 1000));

    if (!JT.exists(liked_by_data[user.id()])) { liked_by_data[user.id()] = { timestamp }; }

    return this.set_liked_by_data(liked_by_data);
  }

  unlike(user) {

    let liked_by_data = this.liked_by_data();
    if (!liked_by_data) { liked_by_data = {}; }

    delete liked_by_data[user.id()];

    return this.set_liked_by_data(liked_by_data);
  }

  likes() {

    let liked_by_data = this.liked_by_data();
    if (!liked_by_data) { liked_by_data = {}; }

    return Object.keys(liked_by_data)
      .length;
  }

  to_comment() {

    // for replies
    let comment_key = this.owner_key();

    let comment = JTComment.find_by_key(comment_key);
    if (!comment) { comment = JTComment.find_by_id(comment_key); }

    if (comment && (comment instanceof JTComment)) {
      return comment;
    } else {
      return null;
    }
  }

  is_reply() {

    let comment_key = this.owner_key();

    let comment = JTComment.find_by_key(comment_key);
    if (!comment) { comment = JTComment.find_by_id(comment_key); }

    if (comment && (comment instanceof JTComment)) {
      return true;
    } else {
      return false;
    }
  }

  user() {
    let master_key = this.master_key();
    let user = JTUser.find_by_id(master_key);
    if (!user) { user = JTUser.find_by_key(master_key); }
    return user;
  }

  contains(query_array) {

    let text = this.text();
    text = text ? text.toLowerCase() : "";

    let user = JTUser.find_by_id(this.master_key());

    if (user) { text += ` ${user.name().toLowerCase()}`; }

    let is_complete = this.is_complete();

    let contains = false;
    is_complete = this.is_complete();

    let is_reply = this.is_reply();

    for (let query of Array.from(query_array)) {

      if (is_complete && (query === "$complete") && !is_reply) { return true; }
      if (!is_complete && (query === "$incomplete") && !is_reply) { return true; }

      if (!JT.blank(query)) {
        query = query.toLowerCase();
        if (text.indexOf(query) > -1) {
          contains = true;
        } else {
          contains = false;
          break;
        }
      }
    }

    return contains;
  }

  html_text(shorten, comment_key, reply_key) {

    if (shorten == null) { shorten = false; }
    if (comment_key == null) { comment_key = null; }
    if (reply_key == null) { reply_key = null; }
    let text = this.text();
    let hash_key = this.hash_key_value();

    let shorten_word_count = 80;

    // Check if reply
    let comment = JTComment.find_by_id(this.owner_key());
    if (comment) { hash_key = `${hash_key}-${comment.hash_key_value()}`; }

    if (text) {

      let emoji_data, emoji_key, result, sprite_position_x, sprite_position_y;
      text = text.replace(/\n/g, "<br/>");

      let new_words = [];

      let words = text.split(" ");
      let words_length = words.length;

      if (words_length < shorten_word_count) { shorten = false; }

      if (shorten) { words_length = shorten_word_count; }

      let emojis_data = FIOEmojiData.emojisJSON;
      let { unicode_to_key } = FIOEmojiData;

      let link_index = 0;
      let link_ids = [];

      let link_regex = /(([^\s\."'])+\.)+[^\s\."':]{2,}/;
      let http_regex = /^http:\/\//;
      let https_regex = /^https:\/\//;

      let hash_index = 0;
      let hash_ids = {};
      let hash_regex = /#\w+/;
      let break_pattern = /\s/;

      let hash_callback = token => {

        let hash_id = `comments-hash-${hash_index}-${hash_key}`;
        hash_ids[hash_id] = token.substring(1);
        hash_index += 1;

        return `\
<div id='${hash_id}' class='hash-comments-link'>${token}</div>\
`;
      };

      let emoji_callback = token => {

        let unicode = token;

        emoji_key = unicode_to_key[unicode];

        if (emoji_key) {

          emoji_data = emojis_data[emoji_key];

          if (emoji_data) {

            sprite_position_x = -1 * emoji_data.sheet_x * 32;
            sprite_position_y = -1 * emoji_data.sheet_y * 32;

            result = `\
<div class='comments-emoji-cell-container'>
	<div style='background-position: ${sprite_position_x}px ${sprite_position_y}px' class='comments-emoji-cell'></div>
</div>\
`;
          } else {
            result = unicode;
          }

        } else {
          result = unicode;
        }

        return result;
      };

      let emoji_old_callback = token => {

        emoji_key = token.substring(1, token.length - 1);

        if (emoji_key) {

          emoji_data = emojis_data[emoji_key];

          if (emoji_data) {

            sprite_position_x = -1 * emoji_data.sheet_x * 32;
            sprite_position_y = -1 * emoji_data.sheet_y * 32;

            result = `\
<div class='comments-emoji-cell-container'>
	<div style='background-position: ${sprite_position_x}px ${sprite_position_y}px' class='comments-emoji-cell'></div>
</div>\
`;
          } else {
            result = token;
          }

        } else {
          result = token;
        }

        return result;
      };

      let link_callback = token => {

        // link_match 	= link_regex.exec(word)

        let url = !http_regex.test(token) && !https_regex.test(token) ? `http://${token}` : token;

        let link_id = `comments-link-${link_index}-${hash_key}`;

        link_ids[link_id] = url;

        link_index += 1;

        return `<div id='${link_id}' class='hash-comments-link'>${token}</div>`;
      };

      for (let i = 0, end = words_length - 1, asc = 0 <= end; asc ? i <= end : i >= end; asc ? i++ : i--) {

        let word = words[i];

        let parser = {
          rules: [
            { key: 'emoji', start: "&", end: ";", callback: emoji_callback },
            { key: 'link', pattern: link_regex, callback: link_callback },
            { key: 'hash', start: "#", callback: hash_callback }
          ]
        };

        let new_word = JT.multi_parse(parser, word);

        new_words.push(new_word);
      }

      if (shorten && comment_key) {

        new_words.push("<div class='comments-inline-cell-container'>...</div>");

        if (!reply_key) {
          new_words.push(`<span id='media-comments-table-cell-show-more-${comment_key}' class='media-comments-table-cell-show-more' >show more</span>`);
        } else {
          new_words.push(`<span id='media-comments-table-cell-reply-show-more-${reply_key}-${comment_key}' class='media-comments-table-cell-show-more' >show more</span>`);
        }
      }

      this.set_link_ids(link_ids);

      this.set_hash_ids(hash_ids);

      return new_words.join("<div class='comments-inline-cell-container'>&nbsp;</div>");
    }

    return "";
  }

  mark_complete() {

    this.set_complete("1");

    let text_cell = this.text_cell();
    if (text_cell) { return text_cell.css({ 'text-decoration': 'line-through', opacity: 0.7 }); }
  }

  unmark_complete() {

    this.set_complete("0");

    let text_cell = this.text_cell();
    if (text_cell) { return text_cell.css({ 'text-decoration': 'none', opacity: 1 }); }
  }

  is_complete() {

    return JT.exists(this.complete()) && (parseInt(this.complete()) === 1);
  }
}

export class FIOPromotion extends JTDynamoObject {

  static classname() { return "FIOPromotion"; }
  static hash_key() { return 'frontend_id'; }
  static secondary_keys() { return ['id']; }
  static db_attrs() { return ['id', 'plan', 'brand_image', 'header_text', 'header_subtext', 'submit_text', 'new_price', 'expires', 'is_trial', 'trial_length', 'no_credit_card', 'payment_period']; }
  static local_attrs() { return ['frontend_id']; }
}

const models = { JTUser, JTAccount, JTPendingUser, JTComment, JTTeam, JTFolder, JTProject, JTFileReference, JTVersionStack, JTItemList, JTNotification, JTJoinRequest, FIOPromotion, JTReviewLink };
Object.keys(models).forEach((class_name) => JTDynamoObject.init(models[class_name]));
export default models;
function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}
