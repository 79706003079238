import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { FadeTransition } from 'react-transition-components';
import Button from '@frameio/components/src/styled-components/Button';
import MoreIcon from '@frameio/components/src/svgs/icons/more.svg';
import { Menu, useActiveState } from '@frameio/vapor';
import Truncate from '@frameio/components/src/styled-components/Truncate';
import TruncateWithTooltip from '@frameio/components/src/styled-components/TruncateWithTooltip';
import LockIcon from '@frameio/components/src/svgs/icons/lock-filled.svg';
import ArchiveIcon from '@frameio/components/src/svgs/icons/16/archive-filled.svg';
import ProjectLink from 'components/ProjectLink';

import OptionsMenu from '../OptionsMenu';

const LIST_ROW_HEIGHT = '40px';

const PrivateIcon = styled(LockIcon).attrs(() => ({
  width: 14,
  height: 14,
}))`
  /* Because this icon is 14px, using a custom margin to align it with the
  AddProject button in ListTeamRow. */
  margin-right: 5px;
`;

const StyledMoreIcon = styled(MoreIcon)`
  transform: rotate(90deg);
`;

const StyledButton = styled(Button)`
  &[aria-expanded='true'] {
    opacity: 1;
  }
`;

const StyledProjectLink = styled(
  ({ isMouseDown, isSelected, isDropZone, isProjectArchived, ...rest }) => (
    <ProjectLink {...rest} />
  )
)`
  border-top: 1px solid ${(p) => p.theme.color.almostBlack};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  /*
     -6px on the left to center align the archived icon with the dropdown arrow
     in ArchivedProjectsHeader, which currently has the wrong size dropdown arrow
     icon.
  */
  padding: ${({ isProjectArchived, theme }) =>
    isProjectArchived
      ? `0 ${theme.spacing.tiny} 0 calc(${theme.spacing.large} - 6px)`
      : `0 ${theme.spacing.tiny} 0 ${theme.spacing.large}`};
  height: ${LIST_ROW_HEIGHT};
  font-size: ${(p) => p.theme.fontSize[2]};
  outline: none;
  text-align: left;
  border-radius: ${(p) => p.theme.radius.default};
  // CORE-2216: Removing the transition on background-color for now as part of
  // improvements to Project Link click responsiveness
  // transition: background-color 0.1s ease-out, color 0.3s ease-out;
  transition: color 0.3s ease-out;

  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.color.coolBlack : 'transparent'};
  color: ${({ theme, isDropZone, isSelected }) =>
    isDropZone || isSelected ? theme.color.coldWhite : theme.color.slateGray};

  ${({ theme, isDropZone }) =>
    isDropZone &&
    `
    box-shadow: inset 0 0 0 2px ${theme.color.brand};
    border-radius: ${theme.radius.large};
  `}

  ${StyledButton} {
    opacity: 0;
    transition: opacity 0.2s ease;
    pointer-events: none;
  }

  &:hover,
  &:focus {
    color: ${(p) => p.theme.color.coldWhite};
  }

  &:hover ${StyledButton}, &:focus ${StyledButton}, ${StyledButton}[aria-expanded="true"] {
    opacity: 1;
    pointer-events: auto;
  }

  // CORE-2216: Adding :active styles to mirror isSelected state; this is to
  // provide immediate feedback on project selection that precedes the sub-
  // sequent route change and start of ProjectContainer load.
  ${(p) =>
    p.isMouseDown &&
    `
    background-color: ${p.theme.color.coolBlack};
  `}


  ${PrivateIcon} {
    flex: 0 0 auto;
  }
`;

const StyledArchiveIcon = styled(ArchiveIcon)`
  margin-top: 1px; // vertically align with project name
  margin-right: ${(p) => p.theme.spacing.tiny};
`;

const NameContainer = styled(Flex).attrs(() => ({
  full: true,
  alignCenter: true,
  justifyBetween: true,
}))`
  overflow: hidden;
`;

/**
 * Dashboard Sidebar Project List Row.
 * @type {Object}
 */
function ListProjectRow({
  connectDropTarget,
  projectId,
  isDropZone,
  isPrivate,
  isSelected,
  deletedAt,
  name,
  onClick,
  isProjectArchived,
  enableTruncateWithTooltip,
  style,
}) {
  const { isActive, bind } = useActiveState();

  if (deletedAt) return null;

  return (
    <div ref={connectDropTarget} style={style}>
      <StyledProjectLink
        includeSearch
        isMouseDown={isActive}
        isDropZone={!isProjectArchived && isDropZone}
        isProjectArchived={isProjectArchived}
        isSelected={isSelected}
        onClick={onClick}
        projectId={projectId}
        shouldPreserveTab
        {...bind}
      >
        {isProjectArchived && <StyledArchiveIcon />}
        <NameContainer>
          {/* NEXT: Bypass TruncateWithTooltip for performance reasons. It calls getBoundingClientRect upon
            mount which is causing reflows. Instead, just use the built-in browser
            until we have the chance to refactor this.
            */}
          {enableTruncateWithTooltip ? (
            <TruncateWithTooltip>{name}</TruncateWithTooltip>
          ) : (
            <Truncate title={name}>{name}</Truncate>
          )}
          <FadeTransition in={isPrivate}>
            <PrivateIcon width={14} height={14} />
          </FadeTransition>
        </NameContainer>
        <Menu
          shouldUsePortal
          placement="right"
          onClick={(e) => e.preventDefault()}
          content={<OptionsMenu projectId={projectId} projectName={name} />}
        >
          <StyledButton
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
            onClick={(e) => {
              e.preventDefault();
            }}
            dark
            icon="true"
            text
          >
            <StyledMoreIcon width={18} height={18} />
          </StyledButton>
        </Menu>
      </StyledProjectLink>
    </div>
  );
}

export default React.memo(ListProjectRow);

ListProjectRow.defaultProps = {
  isDropZone: false,
  isPrivate: false,
  isSelected: false,
  onClick: undefined,
  userId: '',
  isProjectArchived: false,
  canArchiveProject: false,
  canUnarchiveProject: false,
  enableTruncateWithTooltip: true,
};

ListProjectRow.propTypes = {
  /**
   * Name of project.
   */
  name: PropTypes.string.isRequired,
  /**
   * Function provided by react-dnd used to make a component a drop target.
   */
  connectDropTarget: PropTypes.func.isRequired,
  /**
   * Whether the current project is a drop zone.
   */
  isDropZone: PropTypes.bool,
  /**
   * Whether project is private or not.
   */
  isPrivate: PropTypes.bool,
  /**
   * Project's API id.
   */
  projectId: PropTypes.string.isRequired,
  /**
   * Call back for when the link is clicked
   */
  onClick: PropTypes.func,
  /**
   * Whether the project is archived
   */
  isProjectArchived: PropTypes.bool,
  /**
   * Whether truncate with tooltip should be enabled.
   * TODO(Ben): Remove this once project refactor has been
   * improvements have been consolidated.
   */
  enableTruncateWithTooltip: PropTypes.bool,
};

export const testExports = {
  NameContainer,
  StyledArchiveIcon,
  StyledProjectLink,
};
