import React from 'react';
import {
  call,
  spawn,
  take,
  takeEvery,
  put,
  select,
  race,
} from 'redux-saga/effects';
import { get } from 'lodash';
import track from 'analytics';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import { unarchiveProject } from '@frameio/core/src/projects/sagas';
import { accountEntityForProjectIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import {
  takeSuccessWithEntityId,
  takeFailureWithEntityId,
} from '@frameio/core/src/shared/sagas/helpers';
import { getAssetSize } from '@frameio/core/src/assets/actions';
import { closeModal, openModal, MODAL } from 'components/Modal/actions';
import { showSuccessToast } from 'actions/toasts';
import StorageLimitReachedFlow from 'components/StorageLimitReachedFlow';
import { hasSpaceToAddAssets } from 'utils/planLimits';
import { shouldShowStorageHardBlock } from 'selectors/accounts';
import HardBlock from 'components/HardBlock';
import { limitTypes } from 'selectors/limits';
import { UNARCHIVE_PROJECT_FLOW } from './actions';
import history from './history';
import { UNARCHIVE_PROJECT_URL } from './UnarchiveProjectFlow';

function* startUnarchiveProjectFlow(
  projectId,
  isUpdatedArchivalMessagingEnabled
) {
  let action;
  const project = yield select(projectEntitySelector, { projectId });
  const account = yield select(accountEntityForProjectIdSelector, {
    projectId,
  });
  const accountId = account?.id;
  const rootFolderId = project.root_asset_id;
  const rootFolder = yield select(assetEntitySelector, {
    assetId: rootFolderId,
  });
  const projectUsage = get(rootFolder, 'usage');

  // Fetch root folder size, which isn't guaranteed to be there
  if (!projectUsage) {
    action = yield put(getAssetSize(rootFolderId));
    yield race([
      takeSuccessWithEntityId(action, rootFolderId),
      takeFailureWithEntityId(action, rootFolderId),
    ]);
  }

  const {
    usage: { filesize: projectSize },
  } = yield select(assetEntitySelector, { assetId: rootFolderId });

  const hasEnoughSpaceToUnarchive = yield call(
    hasSpaceToAddAssets,
    accountId,
    projectSize
  );

  if (!hasEnoughSpaceToUnarchive) {
    const shouldShowHardBlock = yield select(shouldShowStorageHardBlock);
    if (shouldShowHardBlock) {
      yield put(openModal(<HardBlock limitType={limitTypes.STORAGE} />));
      yield spawn(track, 'action-blocked', { _limit: limitTypes.STORAGE });
    } else {
      yield put(
        openModal(<StorageLimitReachedFlow accountId={accountId} />, {
          canCloseModal: false,
        })
      );
      yield spawn(track, 'action-blocked', { limit: limitTypes.STORAGE });
      return;
    }
  }

  // Step 1: Show limit reached info screen
  yield call(history.push, UNARCHIVE_PROJECT_URL);

  // Step 2: User has decided to proceed with the upgrade
  const { cancel } = yield race({
    projectContinue: take(UNARCHIVE_PROJECT_FLOW.CONTINUE),
    cancel: take(MODAL.CLOSE),
  });

  if (cancel) return;

  yield call(unarchiveProject, projectId);
  if (isUpdatedArchivalMessagingEnabled) {
    yield put(
      showSuccessToast({
        header: 'Project successfully unarchived!',
      })
    );
  }

  yield put(closeModal());
}

export default [
  takeEvery(
    UNARCHIVE_PROJECT_FLOW.START,
    ({ payload: { projectId, isUpdatedArchivalMessagingEnabled } }) =>
      startUnarchiveProjectFlow(projectId, isUpdatedArchivalMessagingEnabled)
  ),
];

export const testExports = {
  startUnarchiveProjectFlow,
};
