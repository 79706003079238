import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  MenuRadio,
  MenuRadioGroup,
  MenuButton,
  Label,
  Menu,
  Tooltip,
} from '@frameio/vapor';

import { FrameGuide as FrameGuidesMap } from '@frameio/components/src/components/PlayerSubcomponents/PlayerInterface';

import FrameIcon from '@frameio/components/src/svgs/icons/16/frame.svg';
import useHotKeys, { HOTKEY } from 'pages/PlayerContainers/hooks/useHotKeys';

import { trackViewer } from 'analytics';
import { Button } from 'components/PlayerControlBar/PlayerBarButton';

const FRAME_GUIDE_OFF = 'Off';

const StyledMenuButton = styled(MenuButton)`
  ${Label} {
    ${(p) => p.isActive && `color: ${p.theme.color.brand};`}
  }
`;

const FrameGuideWrapper = styled.div`
  align-items: center;
  display: inline-flex;
`;

const FrameGuidePickerButton = ({
  activeFrameGuide,
  frameGuides,
  isFrameGuideMaskOn,
  toggleFrameGuideMask,
  selectFrameGuide,
  setIsPopoverShowing,
  tooltipTitle,
}) => {
  const handleToggleFrameGuideMask = useCallback(
    () => toggleFrameGuideMask(!isFrameGuideMaskOn),
    [toggleFrameGuideMask, isFrameGuideMaskOn]
  );

  // frameGuides represents the list of values for the frame guides,
  // the last value sets the frame guides to off, which has a null value
  const renderedFrameGuideList = [...frameGuides, FRAME_GUIDE_OFF];

  const isFrameGuideTurnedOn =
    activeFrameGuide && activeFrameGuide !== FRAME_GUIDE_OFF;

  const toggleNextGuideOn = () => {
    const currentIndex = renderedFrameGuideList.indexOf(activeFrameGuide);
    let nextIndex = (currentIndex + 1) % renderedFrameGuideList.length;

    // if "Off" is currently selected, start at the top of the list
    if (!isFrameGuideTurnedOn) {
      nextIndex = 0;
    }

    selectFrameGuide(renderedFrameGuideList[nextIndex]);
  };

  useHotKeys({ [HOTKEY.G]: toggleNextGuideOn });

  return (
    <FrameGuideWrapper>
      <Menu
        placement="top"
        shouldUsePortal
        tooltip={
          <Tooltip
            className="playbar-tooltip"
            delayIn={500}
            title={tooltipTitle}
            placement="top"
          />
        }
        onRequestChange={(isShowing) => {
          setIsPopoverShowing(isShowing);
        }}
        content={
          <>
            <MenuRadioGroup
              label="Guides"
              checked={activeFrameGuide || FRAME_GUIDE_OFF}
              onChange={(frameGuide) => {
                selectFrameGuide(frameGuide);
                trackViewer('guides-displayed', { ratio: frameGuide });
              }}
            >
              {renderedFrameGuideList.map((frameGuide) => (
                <MenuRadio key={frameGuide} name={frameGuide}>
                  {frameGuide}
                </MenuRadio>
              ))}
            </MenuRadioGroup>
            <StyledMenuButton
              isActive={isFrameGuideMaskOn}
              onSelect={handleToggleFrameGuideMask}
            >
              {isFrameGuideMaskOn ? 'Hide Mask' : 'Show Mask'}
            </StyledMenuButton>
          </>
        }
      >
        {({ isShowing, bind }) => (
          <Button
            aria-label="Frame guides toggle"
            aria-valuenow={activeFrameGuide}
            aria-pressed={!!activeFrameGuide}
            isSelected={isFrameGuideTurnedOn}
            isFocused={!isFrameGuideTurnedOn && isShowing}
            {...bind}
          >
            <FrameIcon />
          </Button>
        )}
      </Menu>
    </FrameGuideWrapper>
  );
};

FrameGuidePickerButton.defaultProps = {
  frameGuides: Object.keys(FrameGuidesMap),
  setIsPopoverShowing: () => {},
};

FrameGuidePickerButton.propTypes = {
  activeFrameGuide: PropTypes.string,
  frameGuides: PropTypes.arrayOf(PropTypes.string),
  isFrameGuideMaskOn: PropTypes.bool,
  toggleFrameGuideMask: PropTypes.func,
  selectFrameGuide: PropTypes.func,
  setIsPopoverShowing: PropTypes.func.isRequired,
  tooltipTitle: PropTypes.string,
};

export default FrameGuidePickerButton;
