import { takeLatest, put, call, select, spawn } from 'redux-saga/effects';
import track from 'analytics';
import { push } from 'connected-react-router';
import {
  createProject as coreCreateProject,
  updateProject as coreUpdateProject,
  updateProjectUserPreferences as coreUpdateProjectUserPreferences,
} from '@frameio/core/src/projects/sagas';
import { getProjectUrl } from 'URLs';
import { showErrorToast } from 'actions/toasts';
import { isCollaboratorOnlySelector } from 'selectors/roles';
import { confirmDeleteProject as confirmDeleteProjectSaga } from 'sagas/projects';
import { PROJECT_FORMS } from './actions';
import { closeModal } from '../Modal/actions';

function* createProject(action) {
  const { teamId, params } = action.payload;

  const { success, failure } = yield call(coreCreateProject, teamId, params);
  if (failure) {
    yield put(showErrorToast({ header: 'Failed to create project' }));
  } else if (success) {
    const projectId = success.payload.response.id;
    yield call(
      coreUpdateProjectUserPreferences,
      projectId,
      params.user_preferences
    );

    const downloadFilesEnabled =
      params?.project_preferences?.collaborator_can_download;
    yield spawn(track, 'project-created-client', {
      is_collaborator_permission_share_enabled: downloadFilesEnabled,
    });

    yield put(push(getProjectUrl(projectId)));
  }
  yield put(closeModal());
}

function* updateProject(action) {
  const { projectId, params } = action.payload;

  const isCollaboratorOnly = yield select(isCollaboratorOnlySelector, {
    projectId,
  });

  // If the user is a collaborator, we only want to update the user_preferences.
  if (isCollaboratorOnly) {
    const { success, failure } = yield call(
      coreUpdateProjectUserPreferences,
      projectId,
      params.user_preferences
    );

    if (failure) {
      yield put(showErrorToast({ header: 'Project failed to update' }));
    } else if (success) {
      yield put(closeModal());
    }

    return;
  }

  // If the user is a team member, go ahead and update the project AND user_preferences.
  const { success, failure } = yield call(coreUpdateProject, projectId, params);
  if (failure) {
    yield put(showErrorToast({ header: 'Project failed to update' }));
  } else if (success) {
    yield call(
      coreUpdateProjectUserPreferences,
      projectId,
      params.user_preferences
    );
    yield put(closeModal());
  }
}

function* confirmDeleteProject(action) {
  const isConfirmed = yield call(confirmDeleteProjectSaga, action);
  if (isConfirmed) {
    yield put(closeModal());
  }
}

export const testExports = {
  createProject,
  updateProject,
  confirmDeleteProject,
};

export default [
  takeLatest(PROJECT_FORMS.CREATE, createProject),
  takeLatest(PROJECT_FORMS.UPDATE, updateProject),
  takeLatest(PROJECT_FORMS.DELETE, confirmDeleteProject),
];
