import React from 'react';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import styled from 'styled-components';
import { clamp, ceil } from 'lodash';

const Shadow = styled.div`
  width: 100%;
  height: 13px;
  background: ${({ theme }) => `linear-gradient(to bottom,
    ${rgba(theme.color.graphiteGray, 0.07)} 0%,
    ${rgba(theme.color.graphiteGray, 0)} 100%)`};
`;

const Wrapper = styled.div`
  position: relative;

  ${Shadow} {
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const clampedFloat = (n) => clamp(ceil(n, 2), 0, 1);

class ShadowContainer extends React.Component {
  state = { opacity: 0 };

  onScroll = ({ target }) => {
    const { scrollTop, scrollHeight } = target;
    const { scrollRate } = this.props;

    // calculate the shadow's opacity on scroll by dividing the wrapper's height by
    // a given scroll rate then divide that by current scroll position
    const opacity = clampedFloat(scrollTop / (scrollHeight / scrollRate));

    this.setState({ opacity });
  };

  render() {
    const { children, forwardedRef, ...rest } = this.props;
    const { opacity } = this.state;

    // NOTE: Opacity is passed directly into styles to bypass a feature
    // of styled-components that precompiles classnames.
    return (
      <Wrapper {...rest} ref={forwardedRef} onScroll={this.onScroll}>
        {children}
        <Shadow style={{ opacity }} />
      </Wrapper>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <ShadowContainer forwardedRef={ref} {...props} />
));

ShadowContainer.defaultProps = {
  children: null,
  scrollRate: 4,
};

ShadowContainer.propTypes = {
  children: PropTypes.node,
  scrollRate: PropTypes.number,
};

export const testExports = {
  Shadow,
};
