import React from 'react';
import Media from 'react-media';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { MEDIUM_UP } from 'utils/mediaQueries';
import InProgressIllustration from './in-progress.svg';
import InProgressAnimationPartOne from './in-progress-pt1.mp4';
import InProgressAnimationPartTwo from './in-progress-pt2.mp4';

// used to prevent the modal's height from changing thoughout `ArchiveProjectFlow`
const MIN_HEIGHT = '384px';

const Container = styled.div`
  min-width: 100vw;
  min-height: ${MIN_HEIGHT};

  @media ${MEDIUM_UP} {
    min-width: 400px;
    padding: ${({
      theme: {
        spacing: { units },
      },
    }) => `${units(7)} 0 ${units(13)}`};
  }
`;

const IllustrationContainer = styled(Flex)`
  margin-top: ${(p) => p.theme.spacing.units(8)};
  margin-bottom: ${(p) => p.theme.spacing.medium};
`;

const Header = styled.h1`
  text-align: center;
  font-size: ${(p) => p.theme.fontSize[3]};
  font-weight: bold;
  color: ${(p) => p.theme.color.coolBlack};
  margin-bottom: ${(p) => p.theme.spacing.units(7)};
  transform: scale(0.9);
`;

const HeaderVideo = styled.video`
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: 186px;
  height: 162px;
`;

const HeaderImageContainer = styled.div`
  padding-top: ${(p) => p.theme.spacing.units(5)};
`;

class InProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showPartTwo: false };
    this.videoPartOneRef = React.createRef();
    this.videoPartTwoRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // The animation in this component is made of two videos.
    // The first video starts playing when the component receives isAnimating prop.
    // The second video (looping animation) waits for the first video to end before playing.
    if (
      this.videoPartOneRef &&
      prevProps.isAnimating !== this.props.isAnimating
    ) {
      this.videoPartOneRef.current.currentTime = 0;
      this.videoPartOneRef.current.play();
      this.videoPartOneRef.current.addEventListener('ended', this.showPartTwo);
    }
  }

  componentWillUnmount() {
    if (this.videoPartOneRef) {
      this.videoPartOneRef.current.removeEventListener(
        'ended',
        this.showPartTwo
      );
    }
  }

  showPartTwo = () => {
    this.setState({ showPartTwo: true });
    this.videoPartTwoRef.current.play();
  };

  render() {
    return (
      <Container>
        <IllustrationContainer justifyCenter>
          <Media query={MEDIUM_UP}>
            {(isMediumUp) =>
              isMediumUp ? (
                <React.Fragment>
                  <HeaderVideo
                    muted
                    show={!this.state.showPartTwo}
                    ref={this.videoPartOneRef}
                  >
                    <source src={InProgressAnimationPartOne} type="video/mp4" />
                  </HeaderVideo>
                  <HeaderVideo
                    loop
                    muted
                    show={this.state.showPartTwo}
                    ref={this.videoPartTwoRef}
                  >
                    <source src={InProgressAnimationPartTwo} type="video/mp4" />
                  </HeaderVideo>
                </React.Fragment>
              ) : (
                <HeaderImageContainer>
                  <InProgressIllustration />
                </HeaderImageContainer>
              )
            }
          </Media>
        </IllustrationContainer>
        <Header data-test-id="header">Archiving…</Header>
      </Container>
    );
  }
}

InProgress.propTypes = {
  isAnimating: PropTypes.bool,
};

InProgress.defaultProps = {
  isAnimating: false,
};

export default InProgress;
