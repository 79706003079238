import { configureResponseInterceptor } from '@frameio/core';
import { registerAnonymousUser } from '@frameio/core/src/users/services';
import { isAnonymousUser } from '@frameio/core/src/users/utils';
import { currentUserEntitySelector } from 'selectors/users';
import { handleAuthError } from 'pages/ReviewLinkContainer/actions';
import { get, identity } from 'lodash';
import store from 'configureStore';
import moment from 'moment';
import Raven from 'raven-js';
import {
  getAuthToken,
  getAuthTokenExpiry,
  refreshAuth,
  retryRequest,
  setAuthCookies,
} from 'utils/auth';

/**
 * Logs an error to Sentry if the auth token expired prematurely.
 *
 * @returns {void}
 */
const maybeLogPrematureAuthTokenExpiration = () => {
  const currentTime = moment();
  const expectedExpiration = getAuthTokenExpiry();

  if (expectedExpiration && currentTime.isBefore(expectedExpiration)) {
    const { pathname, search, hash } = window.location;

    const extra = {
      currentTime: currentTime.unix(),
      expectedExpiration: moment(expectedExpiration).unix(),
      path: `${pathname}${search}${hash}`,
    };

    const tags = { jira_ticket: 'V3FRAME-1078' };
    const opts = { extra, tags, level: 'warning' };
    Raven.captureMessage('Premature auth token expiration', opts);
  }
};

/**
 * @returns {Object|undefined} - The current user if they are anonymous, otherwise undefined.
 */
const getAnonUser = () => {
  const state = store.getState();
  const user = currentUserEntitySelector(state) || {};
  const isAnonymous = isAnonymousUser(user);

  return isAnonymous ? user : undefined;
};

/**
 * Dispatches an action to handle the auth error.
 * For anonymous users, the request can be retried after registering the user.
 *
 * @param {Object} error - axios error
 * @returns {Promise} - A promise that resolves to the response or rejects with the original error.
 */
const handleError = (error) => {
  maybeLogPrematureAuthTokenExpiration();

  if (!getAnonUser()) {
    store.dispatch(handleAuthError({ error }));
    return Promise.reject(error);
  }

  return new Promise((resolve, reject) => {
    const retry = () => {
      const newPromise = retryRequest(getAuthToken(), error.config);
      return resolve(newPromise);
    };

    const cancel = () => reject(error);

    const request = { retry, cancel };
    store.dispatch(handleAuthError({ error, request }));
  });
};

/**
 * Attempts to refresh the auth token for an anonymous user.
 * Returns the new auth token if successful, otherwise returns undefined.
 *
 * @param {Object} error - axios error
 * @param {Object} user - anonymous user
 * @returns {Promise<string|undefined>} A promise that resolves to the new auth token if successful, or undefined.
 */
const refreshAnonAuth = async (error, user) => {
  const reviewLinkId = get(error, 'config.headers.x-review-link-id');
  if (!reviewLinkId) return undefined;

  const userPayload = {
    email: user.email,
    integrations: get(window, 'analytics.options.integrations'),
  };

  try {
    const res = await registerAnonymousUser(reviewLinkId, userPayload);
    const newToken = get(res, 'headers.authorization');

    setAuthCookies(newToken);

    return newToken;
  } catch {
    return undefined;
  }
};

/**
 * Attempts to refresh the auth token for authenticated users.
 * Returns the new auth token if successful, otherwise returns undefined.
 *
 * @param {Object} error - axios error
 * @returns {Promise<string|undefined>} A promise that resolves to the new auth token if successful, or undefined.
 */
const getNewToken = (error) => {
  const anonUser = getAnonUser();

  return anonUser
    ? refreshAnonAuth(error, anonUser)
    : refreshAuth('reviewLinkInterceptor');
};

/**
 * @param {Object} error - axios error
 * @returns {boolean} - true if the request has been retried less than 2 times.
 */
const getHasRetries = (error) => {
  const retries = get(error, 'config.retries', 0);
  return retries < 2;
};

/**
 * @param {Object} error - axios error
 * @returns {boolean} - true if the error is 401 not authorized.
 */
const getIsUnauthorized = (error) => {
  return error?.response?.status === 401;
};

/**
 * @param {Object} error - axios error
 * @returns {boolean} - true if the error is password required.
 */
const getIsPasswordRequired = (error) => {
  const responseMessage = get(error, 'response.data.message', '');
  return responseMessage === 'Password Required';
};

/**
 * @param {Object} error - axios error
 * @returns {boolean} - true if the error is related to an expired token.
 */
const getIsTokenExpired = (error) => {
  const responseErrors = get(error, 'response.data.errors', []);
  return responseErrors.some((e) => e.expired_token);
};

/**
 * @param {Object} error - axios error
 * @returns {boolean} - true if the token in cookies is not the same as the token in the error.
 */
const getShouldRetryWithCookies = (error) => {
  const authTokenFromCookies = getAuthToken();
  const authTokenFromError = get(error, 'config.headers.authorization');

  return authTokenFromCookies && authTokenFromCookies !== authTokenFromError;
};

/**
 * Retries the request with the auth token from cookies.
 *
 * @param {Object} error
 * @returns {Promise} - A promise that resolves to the response.
 */
const retryWithCookies = (error) => {
  const authTokenFromCookies = getAuthToken();
  return retryRequest(authTokenFromCookies, error.config);
};

/**
 * Auth interceptor.
 *
 * Used to intercept auth errors where authenticated users are required.
 * It will attempt to refresh the auth token and retry the original request.
 * Otherwise, it will dispatch an action to handle the auth error.
 *
 * @param {Object} error - axios error
 * @returns {Promise} - A promise that resolves to the retried request or rejects with the original error.
 */
const authInterceptor = async (error) => {
  /**
   * If the error is not related to auth, reject the promise.
   */
  const isUnauthorized = getIsUnauthorized(error);
  if (!isUnauthorized) return Promise.reject(error);

  /**
   * If the error is password required, reject the promise.
   * The error will be handled by the enterReviewLink saga.
   */
  const isPasswordRequired = getIsPasswordRequired(error);
  if (isPasswordRequired) return Promise.reject(error);

  /**
   * If the error is related to auth, but cannot be remedied by
   * refreshing the token, handle the error.
   */
  const isTokenExpired = getIsTokenExpired(error);
  if (!isTokenExpired) return handleError(error);

  /**
   * If the request has been retried too many times, handle the error.
   */
  const hasRetries = getHasRetries(error);
  if (!hasRetries) return handleError(error);

  /**
   * If the token in cookies is not the same as the token in the error,
   * retry the request with the token from cookies.
   */
  const shouldRetryWithCookies = getShouldRetryWithCookies(error);
  if (shouldRetryWithCookies) return retryWithCookies(error);

  /**
   * Attempt to refresh the auth token.
   * If the token is refreshed successfully, retry the request with the new token.
   */
  const newToken = await getNewToken(error);
  if (newToken) return retryRequest(newToken, error.config);

  return handleError(error);
};

/**
 * Adds the auth interceptor to all axios instances.
 *
 * @returns {function} - Function to remove the interceptor from all axios instances.
 */
const configureAuthRequiredInterceptor = () => {
  return configureResponseInterceptor(identity, authInterceptor);
};

export default configureAuthRequiredInterceptor;
