import React, { useReducer, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SearchingSpinner from './SearchingSpinner';
import FolderSearchResults from './FolderSearchResults';
import ProjectSearchResults from './ProjectSearchResults';
import NoResults from './NoResults';
import { queryFolders, queryProjects, queryTeams } from './queries';
import TreeTeamNavigator from './TreeTeamNavigator';
import useUpdateShadowContainer from './useUpdateShadowContainer';

const StyledSearchingSpinner = styled(SearchingSpinner)`
  margin-left: ${(p) => p.theme.spacing.units(1.5)};
`;

const StyledNoResults = styled(NoResults)`
  margin-left: ${(p) => p.theme.spacing.units(2)};
`;

/**
 * Use a reducer to handle fetch / paginated status.
 */

function searchReducer(state, action) {
  switch (action.type) {
    case 'fetch-start':
      return {
        ...state,
        isSearching: true,
      };
    case 'fetch-success':
      return {
        ...state,
        ...action.payload,
        results:
          action.payload.currentPage > 1
            ? [...state.results, ...action.payload.results]
            : action.payload.results,
        isSearching: false,
      };
    case 'reset':
      return {
        ...state,
        currentPage: 1,
        results: [],
      };
    case 'fetch-next-page':
      return {
        ...state,
        currentPage: state.currentPage + 1,
      };

    default:
      throw new Error();
  }
}

/**
 * Search results container for
 *  - Folder search
 *  - Project search
 */

function SearchResults({
  teams,
  query,
  filter,
  accountId,
  selectedId,
  limitToProjectId,
  onDoubleClick,
  onSelect,
  expanded,
  onExpand,
  onScroll,
}) {
  const [state, dispatch] = useReducer(searchReducer, {
    isSearching: false,
    results: [],
    hasNextPage: false,
    isNextPageLoading: false,
    currentPage: 1,
  });

  const { ref } = useUpdateShadowContainer(onScroll);

  /**
   * Perform debounced initial query when typing.
   */

  useEffect(() => {
    dispatch({ type: 'fetch-start' });

    // perform a search depending on the filter
    function performQuery() {
      if (filter === 'folders') {
        const PAGE_SIZE = 30;
        return queryFolders(
          query,
          accountId,
          limitToProjectId,
          state.currentPage,
          PAGE_SIZE
        );
      }

      if (filter === 'teams') {
        const PAGE_SIZE = 75;
        return queryTeams(teams, query, state.currentPage, PAGE_SIZE);
      }

      const PROJECTS_PAGE_SIZE = 30;
      return queryProjects(
        query,
        accountId,
        teams,
        state.currentPage,
        PROJECTS_PAGE_SIZE
      );
    }

    async function search() {
      try {
        const response = await performQuery();
        dispatch({
          type: 'fetch-success',
          payload: {
            results: response.results,
            hasNextPage: response.hasNextPage,
            currentPage: response.currentPage,
          },
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        dispatch({ type: 'fetch-error' });
      }
    }

    // invoke search immediately if fetching paginated results
    if (state.currentPage > 1) {
      search();
      return () => {};
    }

    // TODO(Ben-core-2222): Don't debounce unless the query explicitly
    // has changed. This also (wrongly) debounces when switching filters,
    // leading to a 400ms lag in loading.
    const handler = setTimeout(search, 400);

    return () => {
      clearTimeout(handler);
    };
  }, [state.currentPage, query, filter, accountId, teams, limitToProjectId]);

  /**
   * Updating our currentPage state will automatically
   * fetch the next page using the above useEffect.
   */

  const loadNextPage = useCallback(() => {
    dispatch({ type: 'fetch-next-page' });
  }, []);

  // reset our results any time the query changes
  useEffect(() => {
    dispatch({ type: 'reset' });
  }, [query, filter]);

  const sharedProps = {
    query,
    hasNextPage: state.hasNextPage,
    selectedId,
    isLoading: state.isLoading,
    items: state.results,
    onSelect,
    onScroll,
    scrollRef: ref,
    loadNextPage,
    onDoubleClick,
    expanded,
    onExpand,
  };
  return (
    <React.Fragment>
      {state.results.length ? (
        <React.Fragment>
          {filter === 'folders' && <FolderSearchResults {...sharedProps} />}
          {filter === 'projects' && <ProjectSearchResults {...sharedProps} />}
          {filter === 'teams' && (
            <TreeTeamNavigator {...sharedProps} teams={state.results} />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {state.isSearching ? (
            <StyledSearchingSpinner />
          ) : (
            <StyledNoResults>
              No results found for &ldquo;{query}&rdquo;
            </StyledNoResults>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

SearchResults.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.object),
  query: PropTypes.string,
  filter: PropTypes.string,
  accountId: PropTypes.string.isRequired,
  selectedId: PropTypes.string,
  limitToProjectId: PropTypes.string,
  onDoubleClick: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SearchResults;
