import { connect } from 'react-redux';
import {
  assetEntitySelector,
  coverAssetEntitySelector,
} from '@frameio/core/src/assets/selectors';
import { type as assetTypes } from '@frameio/core/src/assets/helpers/constants';

import { showSuccessToast } from 'actions/toasts';
import { getPlayerUrl, getFolderUrl, getAbsoluteUrl } from 'URLs';
import CopyLinkAddress from './CopyLinkAddress';

const { FILE, FOLDER, VERSION_STACK, BUNDLE } = assetTypes;

export function getLinkAddress({
  assetType,
  assetId,
  projectId,
  coverAssetId,
}) {
  // use the correct URL depending on the assetType
  const urlGetterByAssetType = {
    [BUNDLE]: getPlayerUrl,
    [FILE]: getPlayerUrl,
    [FOLDER]: (folderAssetId) => getFolderUrl(projectId, folderAssetId),
    [VERSION_STACK]: (versionStackAssetId) =>
      getPlayerUrl(versionStackAssetId, coverAssetId),
  };

  // default to `getPlayerUrl` when `assetType` is unknown
  const urlGetter = urlGetterByAssetType[assetType] || getPlayerUrl;
  const linkAddress = getAbsoluteUrl(urlGetter(assetId));
  return linkAddress;
}

export function getCoverAssetId(state, { assetType, assetId }) {
  if (assetType === VERSION_STACK) {
    const coverAsset = coverAssetEntitySelector(state, { assetId });
    const coverAssetId = coverAsset && coverAsset.id;
    return coverAssetId;
  }
  return null;
}

export function mapStateToProps(state, { assetId, projectId }) {
  const asset = assetEntitySelector(state, { assetId });
  const assetType = asset.type;
  const coverAssetId = getCoverAssetId(state, { assetType, assetId });
  const linkAddress = getLinkAddress({
    assetId,
    assetType,
    projectId,
    coverAssetId,
  });
  return { linkAddress };
}
const mapDispatchToProps = {
  showSuccessToast,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyLinkAddress);
