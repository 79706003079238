import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  isAccountEnterpriseSelector,
  currentAccountSelector,
} from 'selectors/accounts';
import { defaultSessionWatermarkTemplateIdSelector } from 'selectors/teams';
import {
  permittedActionsForAccountSelector,
  permittedActionsForProjectSelector,
} from 'selectors/permissions';
import {
  teamEntityForProjectIdSelector,
  planEntityForAccountIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import { copyShortUrlToClipboard } from 'pages/ProjectContainer/ProjectLinks/actions';
import { openSessionWatermarkTemplateEditor } from 'pages/WatermarkTemplateEditor/actions';
import {
  newSharingModalEnabled,
  secureSharingEnabled,
} from 'utils/featureFlags';
import { userPreferencesForProjectIdSelector } from 'selectors/projects';
import { isAccountAdminSelector } from 'selectors/roles';
import watermarkUpsellVariantSelector from 'components/SharePanel/WatermarkUpsellTab/selectors';
import { getPublicReviewLinkUrl } from 'URLs';
import {
  invitedEntitiesForItemIdSelector,
  reviewLinkEntityForEditorSelector,
  mergedSessionWatermarkTemplateEntitiesForTeamAndReviewLink,
} from './selectors';
import {
  exitEditor,
  inviteUsersToReviewLink,
  openEditor,
  setDefaultSessionWatermarkTemplateIdForTeam,
  setReviewLinkEditorId,
  updateProjectReviewLinkPreferences,
  updateReviewLinkSettings,
  upgradePlan,
} from './actions';
import FeatureFlaggedReviewLinkEditor from './FeatureFlaggedReviewLinkEditor';

function mapStateToProps(state) {
  const { id: accountId } = currentAccountSelector(state);
  const isEnterprise = isAccountEnterpriseSelector(state);
  const reviewLink = reviewLinkEntityForEditorSelector(state);
  const { id, project_id: projectId, short_url: shortUrl } = reviewLink;
  const reviewLinkUrl = getPublicReviewLinkUrl(id, shortUrl);
  const {
    canCustomizeEmailMessages,
    canDisableShareComments,
    canDisableShareDownloads,
    canDisableSharePreviousVersions,
    canToggleReviewLinkComments,
    canUseCustomSort,
    canUseShareLinkExpiration,
    canUsePasswordProtectedShares,
    isDrmSettingsEnabled,
    isForensicWatermarkingEnabled,
    isSessionBasedWatermarkingEnabled,
    isSecureSharingEnabled: isSecureSharingEnabledForAccount,
  } = permittedActionsForAccountSelector(state, { accountId });

  const {
    canPublicShareReviewLink,
    canSeeWatermarkingSettings,
    canSetDefaultTeamSessionWatermarkId,
    canShareDownloadableReviewLink,
    canShareUnwatermarkedReviewLink,
    canShareWithoutDrmReviewLink,
    canShareWithoutForensicWatermarkReviewLink,
  } = permittedActionsForProjectSelector(state, { projectId });

  const team =
    projectId && teamEntityForProjectIdSelector(state, { projectId });
  const invitedEntities = invitedEntitiesForItemIdSelector(state);
  const userPref = userPreferencesForProjectIdSelector(state, { projectId });
  const plan = planEntityForAccountIdSelector(state, { accountId });
  return {
    accountId,
    canCustomizeEmailMessages,
    canDisableShareComments,
    canDisableShareDownloads,
    canDisableSharePreviousVersions,
    canEnableDownloads: canShareDownloadableReviewLink,
    canUseCustomSort,
    canUseShareLinkExpiration,
    canUsePasswordProtectedShares,
    canSetDefaultTeamSessionWatermarkId,
    canSharePublicly: canPublicShareReviewLink,
    canToggleComments: canToggleReviewLinkComments,
    canShareUnwatermarked: canShareUnwatermarkedReviewLink,
    canShareWithoutForensicWatermark: canShareWithoutForensicWatermarkReviewLink,
    canSeeWatermarkingSettings,
    canShareWithoutDrm: canShareWithoutDrmReviewLink,
    defaultSessionWatermarkTemplateId: defaultSessionWatermarkTemplateIdSelector(
      state
    ),
    sessionWatermarkTemplates: mergedSessionWatermarkTemplateEntitiesForTeamAndReviewLink(
      state,
      { reviewLinkId: id }
    ),
    isEnterprise,
    newSharingModalEnabled: newSharingModalEnabled(state),
    teamId: get(team, 'id'),
    reviewLink,
    reviewLinkUrl,
    reviewLinkPreferences: userPref?.review_link_preferences || {},
    watermarkUpsellVariant: watermarkUpsellVariantSelector(state),
    plan,
    secureSharingEnabled:
      secureSharingEnabled(state) || isSecureSharingEnabledForAccount,
    isAdminLevel: isAccountAdminSelector(state, { accountId }),
    isDrmSettingsEnabled,
    isForensicWatermarkingEnabled,
    isSessionBasedWatermarkingEnabled,
    totalInviteCount:
      invitedEntities !== undefined ? invitedEntities.length : undefined,
  };
}

const mapDispatchToProps = {
  copyShortUrlToClipboard,
  exitEditor,
  inviteUsersToReviewLink,
  openEditor,
  openSessionWatermarkTemplateEditor,
  setDefaultSessionWatermarkTemplateIdForTeam,
  setReviewLinkEditorId,
  updateProjectReviewLinkPreferences,
  updateReviewLinkSettings,
  upgradePlan,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeatureFlaggedReviewLinkEditor);

export const testExports = { mapStateToProps };
