import { isFetchingReducerFactory } from 'factories/isFetching';
import { CANCEL_ACCOUNT_FLOW } from './actions';

const isFetchingReducer = isFetchingReducerFactory(
  CANCEL_ACCOUNT_FLOW.IS_FETCHING
);

const initialState = {
  isFetching: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CANCEL_ACCOUNT_FLOW.IS_FETCHING:
      return isFetchingReducer(state, action);
    default:
      return state;
  }
}
