import React from 'react';
import PropTypes from 'prop-types';
import { MenuButton } from '@frameio/vapor';
import LeaveIcon from '@frameio/components/src/svgs/icons/leave.svg';

export const LeaveProject = ({ onClick }) => (
  <MenuButton
    onSelect={onClick}
    iconBefore={<LeaveIcon />}
    data-test-id="leave"
    destructive
  >
    Leave project
  </MenuButton>
);

export const JoinProject = ({ onClick }) => (
  <MenuButton onSelect={onClick} iconBefore={<LeaveIcon />} data-test-id="join">
    Join project
  </MenuButton>
);

const defaultProps = {
  onClick: () => {},
};

const propTypes = {
  onClick: PropTypes.func,
};

LeaveProject.propTypes = propTypes;
JoinProject.propTypes = propTypes;
LeaveProject.defaultProps = defaultProps;
JoinProject.defaultProps = defaultProps;
