import { connect } from 'react-redux';

import { moveAssets } from './actions';

import MoveAssetsToDestinationFolder from './MoveAssetsToDestinationFolder';

const mapDispatchToProps = {
  moveAssets,
};

export default connect(
  null,
  mapDispatchToProps
)(MoveAssetsToDestinationFolder);

export const testExports = {
  mapDispatchToProps,
};
