import { connect } from 'react-redux';
import {
  confirmDownloadInBrowser,
  cancelModal,
  openURLInDesktopApp,
} from '../actions';
import LaunchDesktopApp from './LaunchDesktopApp';

const mapDispatchToProps = {
  onDownloadInBrowser: confirmDownloadInBrowser,
  onClose: cancelModal,
  openURLInDesktopApp,
};

export default connect(
  null,
  mapDispatchToProps
)(LaunchDesktopApp);
