import React from 'react';
import PropTypes from 'prop-types';
import { dialogTypes } from '@frameio/components/src/styled-components/Dialog';
import Button from '@frameio/components/src/styled-components/Button';
import { Copy, StyledDialog } from '../InitialScreen/InitialScreen';
import { ENTITY_TYPE } from '../../AuthGate/AuthGate';

export default function Success({ onClick, entityType }) {
  return (
    <StyledDialog
      type={dialogTypes.SUCCESS}
      header="Request sent"
      primaryButton={
        <Button primary onClick={onClick}>
          Return to project view
        </Button>
      }
    >
      <Copy>
        Your request has been sent to the {entityType} owner. You’ll be notified
        when access is granted to this {entityType}.
      </Copy>
    </StyledDialog>
  );
}

Success.propTypes = {
  onClick: PropTypes.func.isRequired,
  entityType: PropTypes.oneOf(Object.values(ENTITY_TYPE)).isRequired,
};
