import { generateActionTypes } from 'shared/actions/helpers';

export const DASHBOARD_SIDEBAR = generateActionTypes('DASHBOARD_SIDEBAR', [
  'IS_FETCHING',
  'SHOW',
  'HIDE',
]);

export function show() {
  return {
    type: DASHBOARD_SIDEBAR.SHOW,
    payload: {},
  };
}

export function hide() {
  return {
    type: DASHBOARD_SIDEBAR.HIDE,
    payload: {},
  };
}
