import React, { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import Flex from 'styled-flex-component';
import { withFormik } from 'formik';
import TextArea from '@frameio/components/src/styled-components/TextArea';
import CancelAccountFlowModal from '../CancelAccountFlowModal';
import { TEXT as SHARED_TEXT } from '../CancelAccountFlow';
import ButtonForm from '../ButtonForm';

const TEXT = {
  HEADER: 'One more question...',
  TEXT_AREA_PLACEHOLDER: 'Enter your feedback',
  EMPTY_WARNING: 'Please enter your feedback before proceeding.',
  MAX_CHARACTERS_WARNING: 'Feedback should not exceed 360 characters',
};

const Feedback = ({
  submitFeedback,
  currentStep,
  reasonKey,
  errors,
  handleChange,
  handleBlur,
  setTrackingFeedback,
  trackingReason,
  source,
  onClose,
}) => {
  const [feedback, setFeedback] = useState('');

  const handleTextAreaChange = (e) => {
    const newFeedback = e.target?.value;
    setFeedback(newFeedback);
    handleChange(e);
  };

  const updateReason = (newReason) => {
    setFeedback(newReason);
  };

  const finishedProject = reasonKey === 'FINISHED_PROJECT';

  const onSubmit = () => {
    const trackingFeedback = finishedProject
      ? SHARED_TEXT.REASON_OPTIONS.FINISHED_PROJECT.OPTIONS[feedback] || ''
      : feedback;
    setTrackingFeedback(trackingFeedback);
    submitFeedback({
      feedback: trackingFeedback,
      reason: trackingReason,
      source,
    });
  };

  const submitDisabled =
    !feedback || feedback === '' || (errors && errors.feedback);

  return (
    <CancelAccountFlowModal
      currentStep={currentStep}
      headerText={TEXT.HEADER}
      descriptionText={
        SHARED_TEXT.REASON_OPTIONS[reasonKey]?.DESCRIPTION_TEXT || ''
      }
      handleSubmit={onSubmit}
      submitDisabled={submitDisabled}
      source={source}
      onClose={onClose}
    >
      <FormContainer>
        {finishedProject ? (
          <ButtonForm
            options={SHARED_TEXT.REASON_OPTIONS.FINISHED_PROJECT.OPTIONS}
            selectedOption={feedback}
            onSubmit={onSubmit}
            updateValue={updateReason}
            finishedProject
          />
        ) : (
          <>
            <StyledTextArea
              placeholder={TEXT.TEXT_AREA_PLACEHOLDER}
              name="feedback"
              onChange={handleTextAreaChange}
              onBlur={handleBlur}
              autoFocus
              value={feedback}
              error={errors?.feedback}
            />
            {errors?.feedback && <Error>{errors.feedback}</Error>}
          </>
        )}
      </FormContainer>
    </CancelAccountFlowModal>
  );
};

const FormContainer = styled(Flex)`
  flex-grow: 1;
  margin-bottom: ${(p) => p.theme.spacing.units(2)};
  flex-direction: column;
  form {
    width: 100%;
  }
`;

const Error = styled.p`
  ${(p) => p.theme.fontStyle.body};
  color: ${(p) => p.theme.color.error};
  margin-top: ${(p) => p.theme.spacing.tiny};
`;

const StyledTextArea = styled(TextArea)`
  height: calc(100% - ${(p) => `${p.theme.spacing.large}`});
`;

export default withFormik({
  mapPropsToValues: ({ feedback }) => ({ feedback }),
  validationSchema: Yup.object().shape({
    feedback: Yup.string()
      .max(360, TEXT.MAX_CHARACTERS_WARNING)
      .required(TEXT.EMPTY_WARNING),
  }),
})(Feedback);
