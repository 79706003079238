import { connect } from 'react-redux';
import { customActionIdsSelector } from '@frameio/core/src/customActions/selectors';
import { assetEntitiesByAssetIdsSelector } from '@frameio/core/src/assets/selectors';
import { workfrontTaskSelector } from '@frameio/core/src/workfront/selectors';
import { teamEntitySelector } from '@frameio/core/src/teams/selectors';
import {
  aemIntegrationEnabled as aemIntegrationEnabledSelector,
  assetPublishingEnabled as assetPublishingEnabledSelector,
  dualViewEnabled as dualViewEnabledSelector,
} from 'utils/featureFlags';
import { currentAccountPresentationsDisabledSelector } from 'selectors/accounts';
import { permittedActionsForAssetsSelector } from 'selectors/permissions';
import { getIntegration } from 'components/AssetActions/AdobeIntegration/utils';
import { currentTeamSelector } from 'selectors/teams';
import {
  openModal,
  submit,
} from 'components/Dialog/AdobeIntegrationConnectDialog/actions';
import { manageWorkfrontTask } from '@frameio/core/src/workfront/actions';
import AssetContextMenu from './AssetContextMenu';
import {
  selectedAssetIdsSelector,
  projectIdSelector,
  folderSelector,
} from '../selectors';

function mapStateToProps(state) {
  const assetIds = selectedAssetIdsSelector(state);
  const assets = assetEntitiesByAssetIdsSelector(state, { assetIds });
  const customActionIds = customActionIdsSelector(state);
  const teamId = currentTeamSelector(state).id;
  const reduxTeamEntity = teamEntitySelector(state, { teamId });

  const { parent_id: parentFolderId, id: folderId } = folderSelector(state);
  const projectId = projectIdSelector(state);
  const permissions = permittedActionsForAssetsSelector(state, {
    projectId,
    assetIds,
  });
  const hasParentFolder = parentFolderId !== projectId;

  const isAemIntegrationEnabled = aemIntegrationEnabledSelector(state);

  const isTeamWorkfrontEnabled = reduxTeamEntity?.workfront_enabled;
  const isWorkfrontConnected = assets[0]?.workfront_connected;
  const workfrontTaskId = assets[0]?.workfront_task_id;

  // if the asset already has the workfront task on it from the current session
  // we don't need to search the current project entity for the task id.
  const workfrontTask =
    assets[0]?.workfront_task ||
    (workfrontTaskId &&
      workfrontTaskSelector(state, {
        projectId,
        workfrontTaskId,
      })) ||
    {};

  return {
    assetIds,
    customActionIds,
    folderId,
    isAemIntegrationEnabled,
    isWorkfrontConnected,
    permissions,
    projectId,
    assetPublishingEnabled: assetPublishingEnabledSelector(state),
    dualViewEnabled: dualViewEnabledSelector(state),
    integration: getIntegration(reduxTeamEntity),
    isPresentationsDisabled: currentAccountPresentationsDisabledSelector(state),
    isTeamWorkfrontEnabled,
    parentFolderId: hasParentFolder && parentFolderId,
    workfrontTaskInterface: {
      workfrontTaskId,
      workfrontTask,
    },
  };
}

const mapDispatchToProps = {
  openModal,
  submit,
  updateWorkfrontTask: manageWorkfrontTask,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetContextMenu);

export const testExports = {
  mapStateToProps,
};
