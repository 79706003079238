import { isModalOpenSelector } from '../Modal/selectors';

const quicklookSelector = (state) => ({
  ...state.quicklook,
  canOpen: !isModalOpenSelector(state),
});

export default quicklookSelector;

export const quicklookAssetIdSelector = (state) =>
  quicklookSelector(state).assetId;

export const isQuicklookTargetSelector = (state, { assetId }) =>
  assetId === quicklookAssetIdSelector(state);
