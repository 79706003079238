import styled, { css } from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { rgba } from 'polished';

import ThumbContainer from './ListThumbContainer';

export const ListRowName = styled(FlexItem)`
  ${(p) => p.theme.fontStyle.body}
  overflow: hidden;
  transition: color 0.1s ease-in-out;
`;

const ListRow = styled(Flex).attrs(() => ({
  full: true,
  alignCenter: true,
}))`
  ${({ isSelected, selectedBorderWidth, theme, height }) => css`
    ${theme.fontStyle.body};
    border-radius: ${theme.radius.medium};
    height: ${height}px;

    ${selectedBorderWidth > 0 &&
      `
      border: ${selectedBorderWidth}px solid ${
        isSelected ? theme.color.brand : 'transparent'
      };
      background-color: ${isSelected && rgba(theme.color.brand, 0.15)};
    `}

    ${ThumbContainer} {
      margin-right: ${theme.spacing.small};
    }

    &:hover {
      ${ListRowName} {
        color: ${theme.color.white};
      }
    }
  `}
`;

export default ListRow;
