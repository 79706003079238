import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Flex from 'styled-flex-component';
import { ThumbBox } from '../../sharedComponents';

const FPS_PRECISION_DECIMAL_PLACES = 6;

const Name = styled.div`
  ${(p) => p.theme.fontStyle.bodyM}
  font-weight: bold;
  color: ${(p) => p.theme.color.dimGray};
`;

const Details = styled.div`
  ${(p) => p.theme.fontStyle.bodyS}
  color: ${(p) => p.theme.color.graphiteGray};
`;

const Detail = styled.span`
  ${(p) => p.isHighlighted && `color: ${p.theme.color.red500};`}
`;

export default function AssetRow({ asset, mismatchingProps, ...rest }) {
  const {
    thumb_orig_ar_540: thumb,
    image_small: thumbFallback,
    name,
    fps,
    frames,
  } = asset;
  const hasFramerateError = mismatchingProps.includes('fps');
  const hasFramesError = mismatchingProps.includes('frames');

  const formattedFPS = fps.toFixed(FPS_PRECISION_DECIMAL_PLACES);
  return (
    <Flex alignCenter {...rest}>
      <ThumbBox
        compact
        name={name}
        thumb={thumb || thumbFallback}
        className="thumb-box"
      />
      <Flex column>
        <Name>{name}</Name>
        <Details>
          <Detail data-test-id="detail-frames" isHighlighted={hasFramesError}>
            {frames} Frames
          </Detail>
          &nbsp;&middot;&nbsp;
          <Detail
            data-test-id="detail-framerate"
            isHighlighted={hasFramerateError}
          >
            {formattedFPS} fps
          </Detail>
        </Details>
      </Flex>
    </Flex>
  );
}

AssetRow.propTypes = {
  asset: PropTypes.shape({
    image_small: PropTypes.string,
    name: PropTypes.string,
    fps: PropTypes.number,
    frames: PropTypes.number,
  }).isRequired,
  mismatchingProps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export const testExports = { Name };
