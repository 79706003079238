import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const COMMENTS_TOGGLE = generateActionTypes('COMMENTS_TOGGLE', [
  'SET_NUM_OF_COMMENTS',
  'TOGGLE_HIGHLIGHT',
  'WATCH_COMMENTS',
]);

export const setNumOfComments = createAction(
  COMMENTS_TOGGLE.SET_NUM_OF_COMMENTS,
  (numOfComments) => ({ numOfComments })
);

export const toggleHighlight = createAction(
  COMMENTS_TOGGLE.TOGGLE_HIGHLIGHT,
  (isHighlighted) => ({ isHighlighted })
);

export const watchComments = createAction(
  COMMENTS_TOGGLE.WATCH_COMMENTS,
  (assetId) => ({ assetId })
);
