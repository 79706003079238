import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import ReasonSelector from './ReasonSelector';
import Feedback from './Feedback';
import Confirmation from './Confirmation';

export const CANCELLATION_REASON_URL = '/StartCancellation';
export const CANCELLATION_FEEDBACK_URL = '/CancellationFeedback';
export const CANCELLATION_CONFIRMATION_URL = '/EndCancellation';

// The steps array is used in `<ProgressDots />` to show the user's progress in the flow.
export const steps = [
  CANCELLATION_REASON_URL,
  CANCELLATION_FEEDBACK_URL,
  CANCELLATION_CONFIRMATION_URL,
];

export const TEXT = {
  REASON_OPTIONS: {
    MISSING_FEATURE: {
      BUTTON_TEXT: 'It’s missing a feature I need',
      DESCRIPTION_TEXT:
        'What feature(s) are you missing?  We’ll do our best to improve based on your feedback.',
      TRACKING_TEXT: 'missing feature',
    },
    DIFFERENT_SOLUTION: {
      BUTTON_TEXT: 'I prefer a different solution',
      DESCRIPTION_TEXT:
        'What solution(s) do you prefer over Frame.io?  We’ll do our best to improve based on your feedback.',
      TRACKING_TEXT: 'prefer different solution',
    },
    FINISHED_PROJECT: {
      BUTTON_TEXT: 'I finished my current project',
      DESCRIPTION_TEXT: 'When do you expect your next project to begin?',
      OPTIONS: {
        ONE_TO_TWO_MONTHS: '1-2 months',
        THREE_TO_SIX_MONTHS: '3-6 months',
        SEVEN_TO_TWELVE_MONTHS: '7-12 months',
        NEXT_YEAR: 'Next year',
        NOT_SURE: 'I’m not sure',
      },
      TRACKING_TEXT: 'finished project',
    },
    TOO_EXPENSIVE: {
      BUTTON_TEXT: 'It’s too expensive',
      DESCRIPTION_TEXT:
        'What monthly price point would be more reasonable?  We’ll do our best to improve based on your feedback.',
      TRACKING_TEXT: 'too expensive',
    },
    OTHER: {
      BUTTON_TEXT: 'Other',
      DESCRIPTION_TEXT:
        'Can you share more detail about why you’re cancelling?  We really appreciate your feedback.',
      TRACKING_TEXT: 'other',
    },
  },
};

const CancelAccountFlow = ({
  accountId,
  startFlow,
  endFlow,
  source,
  onClose,
}) => {
  const [reasonKey, setReasonKey] = useState(null);
  const [trackingReason, setTrackingReason] = useState(null);
  const [trackingFeedback, setTrackingFeedback] = useState(null);

  useEffect(() => {
    startFlow(accountId);
    return () => {
      setReasonKey(null);
      endFlow();
    };
  }, [accountId, endFlow, startFlow]);

  return (
    <Router history={history}>
      <Switch>
        <Route
          path={CANCELLATION_REASON_URL}
          render={({ location }) => (
            <ReasonSelector
              currentStep={location.pathname}
              setReasonKey={setReasonKey}
              setTrackingReason={setTrackingReason}
              source={source}
              onClose={onClose}
            />
          )}
        />
        <Route
          path={CANCELLATION_FEEDBACK_URL}
          render={({ location }) => (
            <Feedback
              currentStep={location.pathname}
              reasonKey={reasonKey}
              setTrackingFeedback={setTrackingFeedback}
              trackingReason={trackingReason}
              source={source}
              onClose={onClose}
            />
          )}
        />
        <Route
          path={CANCELLATION_CONFIRMATION_URL}
          render={({ location }) => (
            <Confirmation
              currentStep={location.pathname}
              trackingReason={trackingReason}
              trackingFeedback={trackingFeedback}
              source={source}
              onClose={onClose}
            />
          )}
        />
      </Switch>
    </Router>
  );
};

CancelAccountFlow.propTypes = {
  accountId: PropTypes.string.isRequired,
  startFlow: PropTypes.func.isRequired,
  endFlow: PropTypes.func.isRequired,
  continueFlow: PropTypes.func.isRequired,
};

CancelAccountFlow.defaultProps = {};

export default CancelAccountFlow;
