/* eslint-disable import/prefer-default-export */
export const types = {
  ASSETS: 'assets',
  VERSIONS: 'versions',
  SESSION_WATERMARK_TEMPLATE_BLOCK: 'session-watermark-template-block',
};

export const assetsDragSourceSpec = {
  beginDrag({
    assetIds = [],
    selectedAssetIds = [],
    columnIndex,
    rowIndex,
    columnCount,
    isProjectArchived = false,
  }) {
    const previewData = selectedAssetIds.map((assetId) => {
      const selectedIndex = assetIds.indexOf(assetId);
      const selectedRow = Math.floor(selectedIndex / columnCount);
      const selectedColumn = selectedIndex % columnCount;
      return {
        assetId,
        rowDiff: selectedRow - rowIndex,
        columnDiff: selectedColumn - columnIndex,
        cellIndex: selectedIndex,
      };
    });

    return {
      draggedAssetIds: selectedAssetIds,
      assetProjectData: {
        isProjectArchived,
      },
      previewData,
    };
  },
  isDragging({ isSelected }) {
    return isSelected;
  },
  canDrag({ canDrag }) {
    return canDrag;
  },
};

export const mapAssetsDragStateToProps = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
  // selectedAssetIds` is only used to construct the dragging items, and should
  // not be used by any draggable component. Overriding it here to be undefined
  // so that when they change by reference, it doesn't cause a rerender.
  selectedAssetIds: undefined,
});
