/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { formatMoney } from 'formatters/money';
import { USER_LIMIT } from '@frameio/core/src/subscriptionLineItems/utils/modifierTypes';
import Button from '@frameio/components/src/styled-components/Button';
import CheckmarkIcon from '@frameio/components/src/svgs/icons/16/checkmark.svg';
import { MEDIUM_DOWN } from 'utils/mediaQueries';
import PremiumBadge from '@frameio/components/src/styled-components/PremiumBadge';
import { enterpriseBackgroundUrl } from '../utils';
import AdobeLogo from '../svgs/adobe-logo.svg';

const CardContainer = styled.div`
  width: ${({ theme }) => theme.spacing.units(32)};
  margin: 0 ${({ theme }) => theme.spacing.small};

  @media ${MEDIUM_DOWN} {
    width: ${({ theme }) => theme.spacing.units(25)};
    margin: ${({ theme }) => theme.spacing.tiny};
  }
`;

export const PlanTitle = styled.p`
  font-size: 24px;
  color: ${({ theme }) => theme.color.coolBlack};
  font-weight: bold;
  line-height: ${(p) => p.theme.lineHeight[3]};
  padding: 4px 0;
`;

// TODO(GROW-1281): remove the use of <Flex /> and simplify logic for legacy Enterprise card.
const PlanBody = styled(Flex).attrs({
  column: true,
})`
  height: ${({ theme }) => theme.spacing.units(55)};
  border-radius: ${(p) => p.theme.radius.large};
  padding: ${(p) => p.theme.spacing.units(2)};
  color: ${(p) => p.theme.color.graphiteGray};
  font-size: ${(p) => p.theme.fontSize[1]};
  line-height: ${(p) => p.theme.lineHeight[2]};
  border: solid 1px ${(p) => p.theme.color.lightGray};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03);
  transition: border 0.2s ease;

  @media ${MEDIUM_DOWN} {
    padding: ${(p) => p.theme.spacing.units(2)};
  }
`;

export const PlanDetails = styled.ul`
  ${(p) => p.theme.fontStyle.body};
  width: auto;
  list-style: none;
  line-height: ${(p) => p.theme.spacing.medium};
`;

const DetailLine = styled.li`
  color: ${({ theme }) => theme.color.black};
  width: auto;
  display: block;
`;

export const FeatureList = styled.ul`
  width: auto;
  list-style: none;
  font-size: 12px;
  margin-top: 0;
`;

export const FeatureLine = styled.li`
  color: ${({ theme }) => theme.color.coolBlack};
  margin-bottom: ${(p) => p.theme.spacing.micro};
  width: auto;
  display: block;
`;

const Price = styled.span`
  font-size: ${({ theme }) => theme.fontSize[3]};
  line-height: ${(p) => p.theme.spacing.medium};
  color: ${({ theme }) => theme.color.graphiteGray};
  font-weight: bold;
`;

const PriceDetail = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSize[2]};
  line-height: 1.3;
  color: ${({ theme }) => theme.color.graphiteGray};
  font-weight: normal;
  white-space: nowrap;

  @media ${MEDIUM_DOWN} {
    white-space: normal;
  }
`;

const PriceContainer = styled.div`
  margin-bottom: 2px;
  height: ${(p) => p.theme.spacing.medium};
`;

const FeatureListHeader = styled.p`
  color: ${({ theme }) => theme.color.brand};
  font-size: ${(p) => p.theme.fontSize[2]};
  margin-bottom: ${({ theme }) => theme.spacing.units(2)};
  padding-top: ${({ theme }) => theme.spacing.units(2)};
  border-top: 1px solid ${({ theme }) => theme.color.silver};
`;

const CurrentPlan = styled(Flex).attrs({
  center: true,
})`
  font-size: ${(p) => p.theme.fontSize[2]};
  color: ${(p) => p.theme.color.success};
  text-align: center;
  font-weight: bold;
  padding: ${(p) => p.theme.spacing.tiny};
  height: 2rem;
`;

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  width: 12px;
  height: 12px;
  color: ${(p) => p.theme.color.success};
  margin-left: ${(p) => p.theme.spacing.tiny};
`;

const BottomLine = styled.div`
  margin-top: auto;
`;

const BottomLineButton = styled(Button)`
  width: 100%;
  margin-top: auto;
`;

const PerMonthText = styled.span`
  font-size: ${(p) => p.theme.fontSize[2]};
  color: ${({ theme }) => theme.color.gray};
  margin-bottom: 2px;
`;

const TopContainer = styled.div`
  height: ${({ theme }) => theme.spacing.units(11)};
  margin-bottom: 2px;
`;

const DetailsContainer = styled.div`
  height: ${({ theme }) => theme.spacing.units(18)};
`;

const FeaturesContainer = styled.div`
  height: ${({ theme }) => theme.spacing.units(22)};
`;

const Footer = styled(Flex).attrs(() => ({
  justifyBetween: true,
}))`
  background-image: url(${enterpriseBackgroundUrl});
  background-position: center;
  // the background-size is slightly larger to account for rounded edges
  background-size: 110%;
  color: ${(p) => p.theme.color.white};
  width: 100%;
  height: ${(p) => p.theme.spacing.units(14)};
  border-radius: ${(p) => p.theme.spacing.tiny};
  margin: ${(p) => p.theme.spacing.medium} ${(p) => p.theme.spacing.medium} 0;
  padding: ${(p) => p.theme.spacing.medium};

  ${PlanDetails} {
    line-height: ${(p) => p.theme.lineHeight[1]};
  }

  ${FeatureLine} {
    margin-bottom: ${(p) => p.theme.spacing.small};
  }

  ${FeatureLine}, ${PlanTitle} {
    color: ${(p) => p.theme.color.white};
  }
`;

const FooterPrice = styled.p`
  ${(p) => p.theme.fontStyle.body};
  color: ${(p) => p.theme.color.slateGray};
`;

const Column = styled.div`
  width: ${(p) => p.theme.spacing.units(20)};
  margin-right: ${(p) => p.theme.spacing.units(5)};
`;

const StyledPremiumBadge = styled(PremiumBadge)`
  margin-left: ${(p) => p.theme.spacing.tiny};
`;

const AdobeAndEnterpriseCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 145px;
  justify-content: space-between;
  margin-top: 18px;
  padding: 0 ${(p) => p.theme.spacing.medium};
  width: 100%;

  @media ${MEDIUM_DOWN} {
    height: 142px;
    margin-top: 8px;
  }
`;

const SlimFooter = styled.div`
  align-items: center;
  background-image: ${(p) => p.enterprise && `url(${enterpriseBackgroundUrl})`};
  background-position: center;
  // the background-size is slightly larger to account for rounded edges
  background-size: 110%;
  border: solid 1px ${(p) => p.theme.color.lightGray};
  border-radius: ${(p) => p.theme.radius.large};
  color: ${(p) =>
    p.enterprise ? p.theme.color.white : p.theme.color.graphiteGray};
  display: flex;
  height: 65px;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;

  ${Button} {
    width: 144px;
  }
`;

const ColumnForSlimFooter = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`;

const AdobeFooterTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 30px;
  justify-content: space-between;
  padding-left: 19px;
`;

const AdobeFooterTitle = styled.div`
  color: ${({ theme }) => theme.color.coolBlack};
  font-size: ${(p) => p.theme.fontSize[2]};
  line-height: 1;
`;

const AdobeFooterEnterpriseTitle = styled.div`
  color: ${({ theme }) => theme.color.white};
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  padding-right: ${({ theme }) => theme.spacing.units(3)};
`;

const AdobeFooterSubtitle = styled.div`
  font-size: 12px;
  line-height: 1;
  transform: ${(p) => p.singleLine && 'translateY(2px)'};
`;

const StyledButtonForAdobeContact = styled(Button)`
  border: 1px solid ${({ theme }) => theme.color.lightGray};
  color: ${({ theme }) => theme.color.graphiteGray};
  width: 144px;
`;

function renderPlanDetails(plan) {
  return plan.details.map((detail) => {
    return (
      <DetailLine key={detail}>
        <Flex alignCenter>{detail}</Flex>
      </DetailLine>
    );
  });
}

function renderFeatures(plan) {
  return plan.features.map((feature) => (
    <FeatureLine key={feature}>
      <Flex alignCenter>{feature}</Flex>
    </FeatureLine>
  ));
}

const PlanWithUserMaxCard = ({
  isAdobeBridgeOfferEligible,
  isSelected,
  isSelectedTrial,
  onChatWithOurTeamClick,
  plan,
  selectPlan,
}) => {
  const isYearly = plan.period === 'yearly';
  const isEnterprise = plan.enterprise;

  const renderTopSection = () => {
    const lineItems = plan.lineItems;
    const perUserCost = lineItems[USER_LIMIT]?.cost || 0;

    const renderAdobeCopyForFreePlan =
      isAdobeBridgeOfferEligible && perUserCost === 0;

    const periodLabel = isYearly ? 'year' : 'month';

    return (
      <>
        <Flex alignCenter>
          <PlanTitle>{plan.title}</PlanTitle>
          {isSelectedTrial && (
            <StyledPremiumBadge>Your trial</StyledPremiumBadge>
          )}
        </Flex>
        <PriceContainer>
          <Price>
            {renderAdobeCopyForFreePlan ? (
              <PriceDetail>Frame.io for Adobe Creative Cloud</PriceDetail>
            ) : (
              (perUserCost && formatMoney(perUserCost)) || formatMoney(0)
            )}
          </Price>
          {!!perUserCost && (
            <PerMonthText>{` / ${periodLabel} (+ tax) per user`}</PerMonthText>
          )}
        </PriceContainer>
      </>
    );
  };

  const renderBottomLine = () => {
    if (isSelectedTrial) {
      return (
        <BottomLineButton
          primary
          onClick={() => selectPlan(plan)}
          data-test-id={`${plan.title}-plan-select-button`}
        >
          Continue with this plan
        </BottomLineButton>
      );
    }

    if (isSelected) {
      return (
        <CurrentPlan data-test-id={`${plan.title}-plan-select-button`}>
          Current plan
          <StyledCheckmarkIcon />
        </CurrentPlan>
      );
    }

    return (
      <BottomLineButton
        primary
        onClick={() => selectPlan(plan)}
        data-test-id={`${plan.title}-plan-select-button`}
      >
        {plan.ctaText || 'Choose plan'}
      </BottomLineButton>
    );
  };

  const renderNonEnterpriseCard = () => {
    return (
      <CardContainer>
        <PlanBody>
          <TopContainer>{renderTopSection()}</TopContainer>
          {plan.details.length > 0 && (
            <DetailsContainer>
              <PlanDetails>{renderPlanDetails(plan)}</PlanDetails>
            </DetailsContainer>
          )}
          <FeaturesContainer>
            <FeatureListHeader>{plan.featureListHeader}</FeatureListHeader>
            <div>
              <FeatureList>{renderFeatures(plan)}</FeatureList>
            </div>
          </FeaturesContainer>
          <BottomLine>{renderBottomLine()}</BottomLine>
        </PlanBody>
      </CardContainer>
    );
  };

  const renderEnterpriseCard = () => {
    const isDriftInitialized = window.drift;
    return (
      <Footer>
        <Flex justifyBetween>
          <Column>
            <PlanTitle>{plan.title}</PlanTitle>
            <FooterPrice>Custom</FooterPrice>
          </Column>
          <Column>
            <PlanDetails>{plan.featureListHeader}</PlanDetails>
          </Column>
          <Column>
            <FeatureList>{renderFeatures(plan)}</FeatureList>
          </Column>
        </Flex>
        <Flex alignCenter>
          {isDriftInitialized ? (
            <Button
              primary
              onClick={() => {
                onChatWithOurTeamClick();

                // Hack to keep the Drift input box in focus
                setTimeout(() => {
                  document.activeElement.blur();
                }, 100);
              }}
            >
              Chat with sales
            </Button>
          ) : (
            <Button
              primary
              forwardedAs="a"
              href="mailto:sales@frame.io?subject=Frame.io Enterprise"
            >
              Contact sales
            </Button>
          )}
        </Flex>
      </Footer>
    );
  };

  const renderAdobeAndEnterpriseCard = () => {
    const isDriftInitialized = window.drift;
    return (
      <AdobeAndEnterpriseCardContainer>
        <SlimFooter>
          <ColumnForSlimFooter>
            <AdobeLogo />
            <AdobeFooterTitleContainer>
              <AdobeFooterTitle>
                Adobe Creative Cloud for teams or enterprise user?
              </AdobeFooterTitle>
              <AdobeFooterSubtitle>
                Please contact your business’ administrator to purchase a plan
                directly.
              </AdobeFooterSubtitle>
            </AdobeFooterTitleContainer>
          </ColumnForSlimFooter>
          <ColumnForSlimFooter>
            <StyledButtonForAdobeContact
              forwardedAs="a"
              href="https://helpx.adobe.com/enterprise/kb/contact-administrator.html"
              target="_blank"
              rel="noopener noreferrer"
              text
            >
              Learn more
            </StyledButtonForAdobeContact>
          </ColumnForSlimFooter>
        </SlimFooter>
        <SlimFooter enterprise>
          <ColumnForSlimFooter>
            <AdobeFooterEnterpriseTitle>Enterprise</AdobeFooterEnterpriseTitle>
            <AdobeFooterSubtitle singleLine>
              Build a custom package tailored for the way your organization
              works.
            </AdobeFooterSubtitle>
          </ColumnForSlimFooter>
          <ColumnForSlimFooter>
            {isDriftInitialized ? (
              <Button
                primary
                onClick={() => {
                  onChatWithOurTeamClick();

                  // Hack to keep the Drift input box in focus
                  setTimeout(() => {
                    document.activeElement.blur();
                  }, 100);
                }}
              >
                Chat with sales
              </Button>
            ) : (
              <Button
                primary
                forwardedAs="a"
                href="mailto:sales@frame.io?subject=Frame.io Enterprise"
              >
                Contact sales
              </Button>
            )}
          </ColumnForSlimFooter>
        </SlimFooter>
      </AdobeAndEnterpriseCardContainer>
    );
  };

  if (isEnterprise && isAdobeBridgeOfferEligible) {
    return renderAdobeAndEnterpriseCard();
  }

  return isEnterprise ? renderEnterpriseCard() : renderNonEnterpriseCard();
};

PlanWithUserMaxCard.propTypes = {
  isAdobeBridgeOfferEligible: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
  isSelectedTrial: PropTypes.bool.isRequired,
  plan: PropTypes.shape({
    // period: PropTypes.string.isRequired,
    cost: PropTypes.number,
    enterprise: PropTypes.bool.isRequired,
    storage_limit: PropTypes.isRequired,
    title: PropTypes.string.isRequired,
    featureListHeader: PropTypes.string.isRequired,
    features: PropTypes.array.isRequired,
    selfServe: PropTypes.bool.isRequired,
  }).isRequired,
  selectPlan: PropTypes.func.isRequired,
};

export default PlanWithUserMaxCard;
