/* eslint-disable import/prefer-default-export */
import { MEDIUM_MAX } from 'utils/breakpoints';

// As of iOS 13.+ iPads are set to default to "desktop" in the user agent.
// Thus are not detected using the `iPad` test. So we look also for 'MacIntel' (and verify it is touch below)
export const isIOS =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

export const isAndroid =
  navigator.userAgent.toLowerCase().indexOf('android') > -1;

// Helpful to determine to use the ctrlKey or metaKey for hotkeying on different devices.
export const isPC = navigator.platform.indexOf('Win') > -1;

/*
 * if a device is a multi-touch or an iOS device we will show the turnstyle prompt.
 * This is used in ReviewLinkContainer.
 * This accounts for:
 * - Android,
 * - iOS
 * - Any other device that may have multiple touchpoints at the medium
 */
export const isTurnStylePromptDevice =
  isAndroid ||
  isIOS ||
  (navigator && navigator.maxTouchPoints > 1 && window.innerWidth < MEDIUM_MAX);
