import moment from 'moment-timezone';
import { restrictedCollaboratorRoleEnabled } from 'utils/featureFlags';

const CUTOFF_DATE = moment.tz('2018-08-23T09:00', 'America/New_York').toDate();

/**
 * New restricted collaborator role is enabled when:
 *
 * 1. the account is created after a 2018-08-23, or,
 * 2. the feature flag is turned on for the account.
 *
 * @param {Object} account - v2 account entity.
 * @returns {boolean} - True if restricted collaborator role is enabled.
 * @private
 */
export default function shouldRestrictCollaborators(account) {
  // Default to stricter rule if account entity is not available yet.
  if (!account) return true;

  const accountCreatedAt = new Date(account.inserted_at);
  return accountCreatedAt > CUTOFF_DATE || restrictedCollaboratorRoleEnabled();
}
