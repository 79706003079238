import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { MenuRadio, Select, Tooltip, MenuButton } from '@frameio/vapor';
import {
  Resolution,
  AUTO,
  RESOLUTION_VALUES,
} from '@frameio/components/src/components/PlayerSubcomponents/PlayerInterface';
import Spinner from '@frameio/components/src/styled-components/SpinnerBase';
import { trackViewer } from 'analytics';
import { Button } from 'components/PlayerControlBar/PlayerBarButton';

const Text = styled.p`
  display: inline-flex;
  justify-content: flex-end;
  min-width: ${(p) => p.theme.spacing.units(5)};
`;

const ResolutionIcon = styled.span`
  color: ${(p) => p.theme.color.brand};
  font-size: 8px;
  font-weight: 900;
  padding-left: 2px;
`;

const StyledPlaybarButton = styled(Button)`
  ${(p) => p.theme.fontStyle.button};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  width: 100% !important;
`;

const StyledSpinner = styled(Spinner)`
  margin-right: -4px;
`;

const ResolutionPickerWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: flex-end;
`;

const StyledMenuRadioButton = styled(MenuRadio)`
  ${Text}, ${ResolutionIcon} {
    opacity: ${({ is4KResolutionLocked }) =>
      is4KResolutionLocked ? '0.3' : '1'};
  }
`;

const is4KResolution = (resolution) => resolution === Resolution.R2160;

const is4KResolutionLocked = (canUse4KPlayback, resolution) =>
  !canUse4KPlayback && is4KResolution(resolution);

/**
 * @param   {string} resolution - Resolution string.
 * @returns {ReactElement} - Returns either the spinner or HD.
 */
const getResolutionIcon = (resolution) => {
  let component;
  const is4K = is4KResolution(resolution);
  const isHD = parseInt(resolution, 10) >= 720 && !is4K;
  if (isHD) {
    component = 'HD';
  } else if (is4K) {
    component = '4K';
  } else {
    return null;
  }
  return <ResolutionIcon>{component}</ResolutionIcon>;
};

const StyledTooltip = styled(Tooltip)`
  width: ${(p) => p.theme.spacing.units(19)};
`;

const ResolutionPickerButton = ({
  assetResolutions,
  children,
  canUse4KPlayback,
  isAdmin,
  isHlsPlayerEnabled,
  selectedAutoResolution,
  selectedResolution,
  selectResolution,
  setIsPopoverShowing,
  tooltipTitle,
  upgradePlan,
}) => {
  const { resolution: selectedAssetResolution } =
    assetResolutions.filter(
      (asset) => asset && asset.resolution === selectedResolution
    )[0] || {};
  const resolutionItems = [...assetResolutions];
  const selectedAssetIcon = getResolutionIcon(selectedAssetResolution);

  if (isHlsPlayerEnabled) {
    resolutionItems.push({ resolution: AUTO });
  }

  const onSelectResolution = (resolution) => {
    selectResolution(resolution);
    trackViewer('video-quality-updated', { quality: resolution });
  };

  const maybeStartUpgradePlanFlow = (callback) => {
    if (!isAdmin) return;
    upgradePlan(callback);
    trackViewer('feature-gate-clicked-client', { _limit: '4K playback' });
  };

  return (
    <Select
      shouldUsePortal
      tooltip={
        <Tooltip
          className="playbar-tooltip"
          delayIn={500}
          title={tooltipTitle}
          placement="top"
        />
      }
      onRequestChange={(isShowing) => {
        setIsPopoverShowing(isShowing);
      }}
      placement="top"
      defaultValue={selectedResolution}
      label="Quality"
      content={resolutionItems.map(
        ({ resolution: assetResolution, isTranscoding }) => {
          if (isTranscoding) {
            return (
              <MenuButton
                disabled
                key={`resolution-${assetResolution}`}
                iconAfter={
                  <StyledSpinner
                    radius={10}
                    color="150,150,150"
                    spinning
                    stroke={2}
                  />
                }
              >
                <Flex>
                  <Text>{assetResolution}</Text>
                  {getResolutionIcon(assetResolution)}
                </Flex>
              </MenuButton>
            );
          }

          return (
            <StyledMenuRadioButton
              key={`resolution-${assetResolution}`}
              name={assetResolution}
              is4KResolutionLocked={is4KResolutionLocked(
                canUse4KPlayback,
                assetResolution
              )}
              onSelect={() => {
                // The `onSelectResolution` callback is used to automatically change the resolution
                // to 4K after a succesful plan upgrade.
                const callback = () => onSelectResolution(assetResolution);

                // When 4K resolution is NOT available on the plan's `available_features`, open the
                // plan selection modal instead of switching to the 4k resolution.
                return is4KResolutionLocked(canUse4KPlayback, assetResolution)
                  ? maybeStartUpgradePlanFlow(callback)
                  : onSelectResolution(assetResolution);
              }}
              checked={
                // When 4K resolution is not available on the plan's `available_features` and a User
                // selects it, do NOT check the cell.
                is4KResolutionLocked(canUse4KPlayback, assetResolution)
                  ? assetResolution === selectedResolution &&
                    !is4KResolution(assetResolution)
                  : assetResolution === selectedResolution
              }
            >
              {is4KResolutionLocked(canUse4KPlayback, assetResolution) ? (
                <StyledTooltip
                  details={isAdmin && 'Click to learn more.'}
                  offset={[12, 20]}
                  placement="top"
                  title="Available on Pro ✨"
                  variant="dark"
                >
                  <div>
                    <Text>{assetResolution}</Text>
                    {getResolutionIcon(assetResolution)}
                  </div>
                </StyledTooltip>
              ) : (
                <Flex>
                  <Text>{assetResolution}</Text>
                  {getResolutionIcon(assetResolution)}
                </Flex>
              )}
            </StyledMenuRadioButton>
          );
        }
      )}
    >
      {({ isShowing, bind }) => {
        if (children) {
          return children({
            bind,
            selectedAutoResolution,
            selectedResolution,
            selectedAssetIcon,
          });
        }

        return (
          <ResolutionPickerWrapper>
            <StyledPlaybarButton
              isFocused={isShowing}
              aria-label="Quality"
              aria-valuetext={selectedResolution}
              {...bind}
            >
              {selectedResolution}
              {selectedAutoResolution && `\u00A0(${selectedAutoResolution}p)`}
              {selectedAssetIcon}
            </StyledPlaybarButton>
          </ResolutionPickerWrapper>
        );
      }}
    </Select>
  );
};

ResolutionPickerButton.defaultProps = {
  assetResolutions: [],
  selectResolution: noop,
  setIsPopoverShowing: noop,
  transcodeStatuses: {},
};

ResolutionPickerButton.propTypes = {
  assetResolutions: PropTypes.arrayOf(
    PropTypes.exact({
      isTranscoding: PropTypes.bool,
      resolution: PropTypes.oneOf(RESOLUTION_VALUES),
    })
  ),
  isAdmin: PropTypes.bool,
  isHlsPlayerEnabled: PropTypes.bool,
  selectedAutoResolution: PropTypes.number,
  selectedResolution: PropTypes.oneOf(RESOLUTION_VALUES),
  selectResolution: PropTypes.func.isRequired,
  setIsPopoverShowing: PropTypes.func.isRequired,
  tooltipTitle: PropTypes.string,
};

export default ResolutionPickerButton;

export const testExports = {
  StyledMenuRadioButton,
};
