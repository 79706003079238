import React from 'react';
import PropTypes from 'prop-types';

const SVGImage = ({ svg, className, ...style }) => (
  <div
    className={`svg-image-container ${className}`}
    style={style}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: svg }}
  />
);

SVGImage.defaultProps = {
  className: '',
  backgroundColor: 'transparent',
  height: 'auto',
  width: 'auto',
};

SVGImage.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  svg: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
};

export default SVGImage;
