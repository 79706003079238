import styled from 'styled-components';
import Flex from 'styled-flex-component';
import PropTypes from 'prop-types';

const Row = styled(Flex).attrs(() => ({
  alignCenter: true,
}))`
  &:focus,
  &:active {
    outline: none;
  }

  ${({ isHighlighted, theme }) =>
    isHighlighted && `background-color: ${theme.color.coldWhite};`}
`;

Row.propTypes = {
  isHighlighted: PropTypes.bool,
  disabled: PropTypes.bool,
};

Row.defaultProps = {
  isHighlighted: false,
  disabled: false,
};

export default Row;
