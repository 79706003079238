import React from 'react';
import Raven from 'raven-js';
import queryString from 'query-string';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getProject } from '@frameio/core/src/projects/sagas';
import { getTeam, addSlackWebhookToTeam } from '@frameio/core/src/teams/sagas';
import { v2Instance as v2Api } from '@frameio/core/src/shared/services/api';
import { teamEntitySelector } from '@frameio/core/src/teams/selectors';

import config from 'config';
import { getOAuthCallbackUrl } from 'URLs';
import { showErrorToast, showSlackToast } from 'actions/toasts';
import { openModal } from 'components/Modal/actions';
import { setProjectId } from 'components/ProjectForms/EditProject/actions';
import ConnectedEditProjectForm from 'components/ProjectForms/EditProject';
import history from 'browserHistory';
import getCSRFToken from 'utils/csrf';
import { goToRoute } from 'utils/router';

import { SLACK } from './actions';

function getRedirectUri() {
  return getOAuthCallbackUrl('slack');
}

function* authOnSlack(state) {
  const baseUrl = 'https://slack.com/oauth/authorize';
  const { pathname, search } = history.location;
  const query = queryString.stringify({
    client_id: config.slackClientId,
    redirect_uri: yield call(getRedirectUri),
    scope: 'incoming-webhook',
    state: JSON.stringify({
      ...state,
      csrfToken: yield call(getCSRFToken),
      originRoute: `${pathname}${search}`,
    }),
  });
  window.location.assign(`${baseUrl}?${query}`);
}

// TODO(marvin): move to core
function updateProject(projectId, body) {
  return v2Api.put(`/projects/${projectId}`, body);
}

function* receiveSlackOAuthResponse(code, state) {
  const { csrfToken, originRoute, projectId, teamId } = state;

  // Verify CSRF token
  if (csrfToken !== (yield call(getCSRFToken))) {
    yield call(goToRoute, originRoute);
    return;
  }

  const redirectUri = yield call(getRedirectUri);
  const { failure: addSlackWebhookFailure } = yield call(
    addSlackWebhookToTeam,
    teamId,
    code,
    redirectUri
  );

  if (addSlackWebhookFailure) {
    const {
      payload: { error },
    } = addSlackWebhookFailure;
    Raven.captureException(error);
    yield put(
      showErrorToast({
        header: 'There was a problem adding the Slack webhook',
      })
    );

    yield call(getTeam, teamId);
    yield call(getProject, projectId);
  } else {
    // Set `notify_slack` to true, so that the "Enable Slack notifications"
    // switch is turned on when the project settings modal is opened.

    // Legacy `updateProject()` needs the whole damn jungle passed in as the
    // update, and won't accept just that one property for Slack. /facepalm 1.
    // So use the v2 endpoint to update it instead.
    yield call(updateProject, projectId, {
      project_preferences: { notify_slack: true },
    });

    // Then, fetch the project to update the state store. Note that even if
    // legacyUpdateProject() above worked, the legacy service endpoint doesn't
    // return the project in the response, so we still have to fetch the
    // project again anyway to update the entity. /facepalm 2.
    //
    // TODO(marvin): remove this fetch when using updateProject v2
    yield call(getTeam, teamId);
    yield call(getProject, projectId);

    const team = yield select(teamEntitySelector, { teamId });
    yield put(
      showSlackToast({
        header: 'Slack has been successfully authorized',
        subHeader: `${team.name} will receive notifications`,
      })
    );
  }

  // Open the modal whether it went through or not, to go back to where they
  // where when they triggered the oAuth flow
  yield put(setProjectId(projectId));
  yield put(openModal(<ConnectedEditProjectForm />));

  yield call(goToRoute, originRoute);
}

export default [
  takeEvery(SLACK.AUTH, ({ payload: { state } }) => authOnSlack(state)),
  takeEvery(SLACK.RECEIVE_OAUTH_RESPONSE, ({ payload: { code, state } }) =>
    receiveSlackOAuthResponse(code, state)
  ),
];

export const testExports = {
  authOnSlack,
  getRedirectUri,
  receiveSlackOAuthResponse,
  updateProject,
};
