import { connect } from 'react-redux';
import { currentUserEntitySelector } from 'selectors/users';
import { logOut } from 'actions/users';
import { requestAccessToEntity, returnToAccount } from '../actions';
import InitialScreen from './InitialScreen';

function mapStateToProps(state) {
  const { email } = currentUserEntitySelector(state);

  return {
    email,
  };
}

const mapDispatchToProps = {
  requestAccessToEntity,
  logOut,
  closeModal: returnToAccount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InitialScreen);
