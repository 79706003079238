import { call, put, select, takeLatest } from 'redux-saga/effects';
import udpateStripeCustomer from 'components/CreditCardFormElements/sagas';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import { currentAccountSelector } from 'selectors/accounts';
import { UPDATE_BILLING_INFO, isFetching, resetFlow } from './actions';

export function* addCreditCard(stripeResponse) {
  yield put(isFetching(true));

  // When going through the update billing address flow, a User can either:
  // 1. submit a new billing address without updating its card
  // 2. submit a new billing address and update its card
  // This means that the `stripeResponse` will only be passed when #2 above is
  // true. Otherwise, `stripeResponse` will be `undefined`
  const token = stripeResponse?.token;

  const { id: accountId } = yield select(currentAccountSelector);

  const { failure } = yield call(udpateStripeCustomer, accountId, token?.id);

  if (failure) {
    yield put(
      showErrorToast({
        header: 'Card did not update. Please try again',
      })
    );
    yield put(isFetching(false));
    return;
  }

  yield put(
    showSuccessToast({
      header: 'Card successfully updated!',
    })
  );
  yield put(resetFlow());
  yield put(isFetching(false));
}

export default [
  takeLatest(UPDATE_BILLING_INFO.ADD_CARD, ({ payload: { stripeResponse } }) =>
    addCreditCard(stripeResponse)
  ),
];
