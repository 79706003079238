import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { getPath, INBOX_URL } from 'URLs';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InboxIcon from '@frameio/components/src/svgs/icons/16/inbox.svg';
import Badge from '@frameio/components/src/styled-components/Badge';

const Count = styled(Badge)`
  height: ${(p) => p.theme.spacing.medium};
  min-width: ${(p) => p.theme.spacing.medium};
  background-color: ${(p) => p.theme.color.coolBlack};
  color: ${(p) => p.theme.color.graphiteGray};
`;

const StyledLink = styled(NavLink)`
  ${Count} {
    margin-left: auto;
  }

  &.active {
    ${Count} {
      background-color: ${({ theme }) => theme.color.dimGray};
      color: inherit;
    }
  }
`;

export default class InboxRow extends PureComponent {
  componentDidMount() {
    const { accountId, fetchUnseenReviewsTotal } = this.props;
    fetchUnseenReviewsTotal(accountId);
  }

  componentDidUpdate({ accountId: lastAccountId }) {
    const { accountId, fetchUnseenReviewsTotal } = this.props;

    if (accountId !== lastAccountId) {
      fetchUnseenReviewsTotal(accountId);
    }
  }

  render() {
    const { totalUnseenReviews, accountId, className, onClick } = this.props;
    return (
      <StyledLink
        to={getPath(INBOX_URL, { accountId })}
        className={className}
        onClick={onClick}
      >
        <InboxIcon />
        Inbox
        {totalUnseenReviews > 0 && <Count>{totalUnseenReviews}</Count>}
      </StyledLink>
    );
  }
}

InboxRow.defaultProps = {
  totalUnseenReviews: 0,
  className: '',
};
InboxRow.propTypes = {
  accountId: PropTypes.string.isRequired,
  onClick: PropTypes.func,

  // connected props
  totalUnseenReviews: PropTypes.number,
  fetchUnseenReviewsTotal: PropTypes.func.isRequired,

  // styled-components
  className: PropTypes.string,
};

export const testExports = { Count };
