import { MANAGE_VERSION_STACK_MODAL } from './actions';

export const initialState = { isManageVersionStackModalFetching: false };

export default function(state = initialState, action) {
  switch (action.type) {
    case MANAGE_VERSION_STACK_MODAL.FETCH.PENDING:
      return { ...state, isManageVersionStackModalFetching: true };
    case MANAGE_VERSION_STACK_MODAL.FETCH.SUCCESS:
      return { ...state, isManageVersionStackModalFetching: false };
    default:
      return state;
  }
}
