import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RecentlyDeletedIcon from '@frameio/components/src/svgs/raw/ic-open-trashbin-20px.svg';
import { MenuButton } from '@frameio/vapor';

import { trackButtonClick } from 'analytics';

const TrashIcon = styled(RecentlyDeletedIcon).attrs(() => ({
  width: 18,
  height: 18,
}))`
  path {
    fill: currentColor;
  }
`;

export default function RecentlyDeletedItem({
  projectId,
  onRecentlyDeleted,
  trackingPage,
  trackingPosition,
  ...rest
}) {
  return (
    <MenuButton
      onSelect={() => {
        onRecentlyDeleted(projectId);
        trackButtonClick(
          'open-recently-deleted',
          trackingPage,
          trackingPosition
        );
      }}
      iconBefore={<TrashIcon />}
      {...rest}
    >
      Recently deleted
    </MenuButton>
  );
}

RecentlyDeletedItem.defaultProps = {
  projectId: '',
  onRecentlyDeleted: () => {},
};

RecentlyDeletedItem.propTypes = {
  projectId: PropTypes.string,
  onRecentlyDeleted: PropTypes.func,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};
