import { connect } from 'react-redux';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';

import ProjectLink from './ProjectLink';

function mapStateToProps(state, { projectId, folderId }) {
  const rootAssetId = (projectEntitySelector(state, { projectId }) || {})
    .root_asset_id;

  return {
    folderId: folderId === rootAssetId ? undefined : folderId,
  };
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(ProjectLink);

export const testExports = {
  mapStateToProps,
};
