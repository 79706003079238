import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash';

import { trackButtonClick } from 'analytics';

import QuicklookPlayer from '@frameio/components/src/styled-components/QuicklookPlayer';
import {
  timeToStandard,
  getStartTimecode,
  getTimeDisplayValue,
  setTimeDisplayValue,
} from '@frameio/components/src/components/PlayerSubcomponents/PlayerInterface';
import { formatLongDateTime } from '@frameio/components/src/utils/datetimeHelpers';

import PlayerControlBar from 'components/PlayerControlBar';
import { TOOLTIP_TITLES } from 'components/PlayerControlBar/PlayerControlBar';
import SeekBar from 'components/PlayerControlBar/SeekBar';
import AssetThumb from 'components/AssetThumb';
import { resolutionToResolutionField } from 'utils/downloads';

import { shouldUseHlsHook } from 'utils/media';
import { Container, Panel } from './QuicklookFolder';

export const PREVIEW_SIZE = 240;
export const PREVIEW_SVG_SIZE = '40px';

const TRACKING_PLAYER_TYPE = 'Quicklook';

const AudioContainer = styled(Container)`
  padding-bottom: 90px;

  /* react-html5video unnecessarily wraps the component in a div */
  > div:first-child {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }
`;

export const AudioAssetThumb = styled(AssetThumb)`
  svg {
    height: ${PREVIEW_SVG_SIZE};
    width: ${PREVIEW_SVG_SIZE};
  }
`;

export function getLowestStreamForAudio(asset) {
  const resolutions = get(asset, 'transcodes.resolutions', []);
  const lowestResolution = Math.min(...resolutions);
  return get(
    asset,
    `${resolutionToResolutionField[lowestResolution]}`,
    asset.original
  );
}

const QuicklookAudio = ({ asset, creator, ...rest }) => {
  const [timeDisplay, setTimeDisplay] = useState(getTimeDisplayValue());
  const isHlsRequired = shouldUseHlsHook(asset);

  const setAndTrackTimeDisplay = useCallback(
    (displayType) => {
      setTimeDisplayValue(displayType); // set timeDisplay value in local storage
      setTimeDisplay(displayType);
      trackButtonClick(
        `opened ${displayType}`,
        'quicklook player',
        'player controls'
      );
    },
    [setTimeDisplay]
  );

  const renderPlayerControlBar = useCallback(
    ({ media, mediaEl, mute, setVolume, toggleLoop, togglePause, unmute }) => {
      const startTimecode = getStartTimecode(asset);

      const loopProps = {
        toggleLoop,
      };

      const playPauseProps = {
        togglePlaying: togglePause,
      };

      const timeDisplayProps = {
        currentTimeDisplayType: timeDisplay,
        frameRate: asset.fps,
        isHlsPlayerEnabled: isHlsRequired,
        setTimeDisplay: setAndTrackTimeDisplay,
        startTimecode,
        totalFrames: asset.frames,
        audioCodec: asset.metadata?.blob?.audio_codec,
      };

      const tooltips = {
        ...TOOLTIP_TITLES,
        PLAYING: 'Pause',
        PAUSED: 'Play',
      };

      const volumeProps = {
        changeVolume: setVolume,
        mute,
        unmute,
      };

      return (
        <PlayerControlBar
          loop={loopProps}
          media={media}
          mediaEl={mediaEl}
          playPause={playPauseProps}
          timeDisplay={timeDisplayProps}
          tooltips={tooltips}
          volume={volumeProps}
          trackingPlayerType={TRACKING_PLAYER_TYPE}
        />
      );
    },
    [asset, isHlsRequired, setAndTrackTimeDisplay, timeDisplay]
  );

  const renderSeekBar = (seekProps) => {
    return <SeekBar {...seekProps} />;
  };

  return (
    <AudioContainer>
      <QuicklookPlayer
        autoPlay
        type="audio"
        key={asset.id}
        renderSeekBar={renderSeekBar}
        renderPlayerControlBar={renderPlayerControlBar}
        {...rest}
      >
        <source src={getLowestStreamForAudio(asset)} />
      </QuicklookPlayer>
      <Panel grow={0} shrink={0} basis="auto">
        <AudioAssetThumb
          asset={asset}
          height={PREVIEW_SIZE}
          width={PREVIEW_SIZE}
        />
      </Panel>
      <Panel grow={1} shrink={1} basis="100%">
        <h2>{asset.name}</h2>
        <p>{timeToStandard(asset.duration)}</p>
        <p>
          Uploaded by <strong>{creator.name}</strong>
          <br />
          {formatLongDateTime(asset.inserted_at)}
        </p>
      </Panel>
    </AudioContainer>
  );
};

QuicklookAudio.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,

    transcodes: PropTypes.shape({
      resolutions: PropTypes.arrayOf(PropTypes.number),
    }),
    duration: PropTypes.number,
    inserted_at: PropTypes.string,
  }).isRequired,
  creator: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default QuicklookAudio;
