import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { position, rgba } from 'polished';
import { shouldComponentUpdate } from 'reflective-bind';
import Card, {
  CardFace,
  CardChin,
  CardTitle,
  CardSubtitle,
  CardMeta,
  CardMetaPrimary,
  CardContextMenuButton,
  CardTooltip,
} from '@frameio/components/src/styled-components/Card';
import TruncateWithTooltip from '@frameio/components/src/styled-components/TruncateWithTooltip';
import WithTooltip from '@frameio/components/src/components/WithTooltip';

import Badge from '@frameio/components/src/styled-components/Badge';
import { color, spacing } from '@frameio/components/src/theme/darkTheme';
import { alignCenter } from '@frameio/components/src/mixins';
import FolderShape from '@frameio/components/src/svgs/illustrations/folder-shape.svg';
import LockIcon from '@frameio/components/src/svgs/icons/lock-filled.svg';
import ArchiveIcon from '@frameio/components/src/svgs/icons/16/archive-filled.svg';
import Caret from '@frameio/components/src/svgs/icons/carot-thin.svg';
import SharedFolderUserGroup from '@frameio/components/src/svgs/icons/folder-sharing-user-group.svg';
import { iconSizes } from '@frameio/components/src/utils/sizes';
import FolderThumb from 'components/FolderThumb';
import { ItemCount } from 'components/FolderSize';

const FOLDER_THUMB_PADDING = spacing.micro;
const FOLDER_TAB_HEIGHT_RATIO = 12 / 193;
export const FOLDER_CHIN_CLASSNAME = 'FolderCard__Chin';
export const GO_TO_FOLDER_CLASSNAME = 'FolderCard__GoToFolder';

/**
 * Card State Diagram
 * ┌─────────────────────┐  ┌─────────────────────┐  ┌─────────────────────┐  ┌────────────────────┐
 * │1. Start             │  │2. Hover             │  │3. Drop (mouseup)    │  │4. Animation done   │
 * │isDropZone: false    │  │isDropZone: true     │  │isDropZone: false    │  │isDropZone: false   │
 * │isDropping: false    │─▶│isDropping: false    │─▶│isDropping: true     │─▶│isDropping: false   │
 * │isDropComplete: false│  │isDropComplete: false│  │isDropComplete: false│  │isDropComplete: true│
 * └─────────────────────┘  └─────────────────────┘  └─────────────────────┘  └────────────────────┘
 *         ▲                                                                                  │
 *         │                                                                                  │
 *         └───────────────────────────XHR completes with new data────────────────────────────┘
 */

const FolderCardWrapper = styled(Card)`
  overflow: visible;
`;

export const FolderIcon = styled(
  // Filter props out of SC to suppress warnings about invalid attrs
  ({ showBorder, selectedBorderWidth, ...rest }) => <FolderShape {...rest} />
)`
  ${({ selectedBorderWidth }) =>
    position(
      'absolute',
      `-${selectedBorderWidth}px`,
      `-${selectedBorderWidth}px`,
      `-${selectedBorderWidth}px`,
      `-${selectedBorderWidth}px`
    )};
  color: ${color.dimGray};
  height: ${({ selectedBorderWidth }) =>
    `calc(100% + ${2 * selectedBorderWidth}px)`};
  width: ${({ selectedBorderWidth }) =>
    `calc(100% + ${2 * selectedBorderWidth}px)`};
  z-index: -1;

  path {
    stroke: ${({ showBorder }) => (showBorder ? color.brand : 'transparent')};
  }
`;

const FolderCardChin = styled(CardChin)`
  background-color: transparent;
`;

const StyledCaret = styled(Caret).attrs({
  height: 11,
  width: 11,
})`
  color: ${(p) => p.theme.color.graphiteGray};
  stroke: ${(p) => p.theme.color.graphiteGray};
  transform: rotate(180deg);
  transition: color 0.25s ease-in-out, stroke 0.25s ease-in-out;
`;

const SharingOverlay = styled.div`
  opacity: 0;
  position: absolute;
  bottom: 0;
  height: ${(p) => `calc(100% - ${p.folderTabHeight}px)`};
  width: 100%;
  background-image: linear-gradient(
    rgba(48, 50, 71, 0.5),
    rgba(48, 50, 71, 1) 100%
  );
  transition: opacity 0.3s ease-in-out;
`;

const SharingText = styled(Flex)`
  opacity: 0;
  line-height: 1;
  background-color: ${(p) => rgba(p.theme.color.coolBlack, 0.7)};
  position: absolute;
  height: 32px;
  font-size: 12px;
  font-weight: ${(p) => p.theme.fontWeight.bold};
  top: 50%;
  left: 50%;
  transform: translate(
    -50%,
    calc(-50% + ${(p) => p.folderTabHeight / 2 + 4}px)
  );
  color: ${(p) => p.theme.color.silver};
  border-radius: 18px;
  transition: transform 0.3s, opacity 0.3s, background-color 0.3s ease-in-out;
  padding-left: ${(p) => p.theme.spacing.small};
  padding-right: ${(p) => p.theme.spacing.tiny};
  width: ${(p) => p.theme.spacing.units(14)};

  &:hover {
    background-color: ${(p) => rgba(p.theme.color.coolBlack, 0.9)};
    cursor: pointer;

    ${StyledCaret} {
      color: ${(p) => p.theme.color.silver};
      stroke: ${(p) => p.theme.color.silver};
    }
  }
`;

const BadgesWrapper = styled(Flex)`
  ${position('absolute', null, spacing.tiny, spacing.tiny, spacing.tiny)};
`;

const FolderCardFace = styled(CardFace)`
  ${alignCenter()}
  box-sizing: border-box;
  padding: ${(p) => `${p.folderTabHeight}px ${FOLDER_THUMB_PADDING} 0`};

  ${Badge} {
    margin-left: ${FOLDER_THUMB_PADDING};
  }

  ${FolderThumb} {
    flex: 1;
  }

  ${StyledCaret} {
    margin-left: 6px;
  }

  &:hover {
    ${SharingText} {
      opacity: 1;
      transform: translate(
        -50%,
        calc(-50% + ${(p) => p.folderTabHeight / 2}px)
      );
      transition: transform 0.2s, opacity 0.1s,
        background-color 0.25s ease-in-out;
    }
    ${SharingOverlay} {
      opacity: 1;
      transition: opacity 0.15s ease-in-out;
    }
  }
`;

function UploadingItemCount({ count }) {
  return `Uploading ${count} ${count === 1 ? 'file' : 'files'}`;
}

export default class FolderCard extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    // Moving saga has completed: State 4 -> 1.
    if (prevState.isDropComplete && !nextProps.droppedAssetIds.length) {
      return {
        isDropComplete: false,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      isDropComplete: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shouldComponentUpdate(this, nextProps, nextState);
  }

  render() {
    const {
      droppedAssetIds,
      asset,
      height,
      isDropZone,
      isFolderSharingEnabled,
      isProjectArchived,
      isSelected,
      isSharedFolder,
      isSharingMode,
      isTouch,
      moveAssets,
      onDropDone,
      selectedBorderWidth,
      showQuicklookTooltip,
      thumbHeight,
      thumbRef,
      thumbWidth,
      hasContextMenu,
      numUploadingAssets,
    } = this.props;
    const { isDropComplete } = this.state;
    const isDropping = droppedAssetIds.length > 0 && !isDropComplete;

    const { name, item_count: itemCount, private: isPrivate } = asset;

    const showBorder =
      selectedBorderWidth && (isSelected || isDropZone || isDropping);
    const folderTabHeight =
      parseInt(FOLDER_THUMB_PADDING, 10) +
      selectedBorderWidth +
      Math.floor(FOLDER_TAB_HEIGHT_RATIO * height);
    const folderThumbHeight = thumbHeight - folderTabHeight;
    const folderThumbWidth =
      thumbWidth - 2 * parseInt(FOLDER_THUMB_PADDING, 10);

    return (
      <FolderCardWrapper selectedBorderWidth={0} isTouch={isTouch}>
        <FolderIcon
          showBorder={showBorder}
          selectedBorderWidth={selectedBorderWidth}
        />
        <FolderCardFace folderTabHeight={folderTabHeight} ref={thumbRef}>
          <FolderThumb
            droppedAssetIds={droppedAssetIds}
            asset={asset}
            width={folderThumbWidth}
            height={folderThumbHeight}
            isDropZone={isDropZone}
            isDropping={isDropping}
            isDropComplete={isDropComplete}
            onDropComplete={() => {
              if (!isDropComplete) {
                this.setState({ isDropComplete: true });
                // This moves them starting from the bottom of the stack upwards.
                moveAssets(droppedAssetIds, asset.id, onDropDone);
              }
            }}
          />
          <BadgesWrapper>
            {isProjectArchived && (
              <Badge>
                <ArchiveIcon width={iconSizes.S} height={iconSizes.S} />
              </Badge>
            )}
            {isPrivate && !isDropZone && (
              <Badge>
                <LockIcon width={iconSizes.S} height={iconSizes.S} />
              </Badge>
            )}
            {isFolderSharingEnabled && isSharedFolder && (
              <WithTooltip
                message="Folder is shared in a review link"
                position="top"
              >
                <Badge>
                  <SharedFolderUserGroup
                    width={iconSizes.S}
                    height={iconSizes.S}
                  />
                </Badge>
              </WithTooltip>
            )}
          </BadgesWrapper>
          {isSharingMode && (
            <>
              <SharingOverlay folderTabHeight={folderTabHeight} />
              <SharingText
                alignCenter
                className={GO_TO_FOLDER_CLASSNAME}
                folderTabHeight={folderTabHeight}
                justifyBetween
              >
                Go to Folder
                <StyledCaret />
              </SharingText>
            </>
          )}
        </FolderCardFace>
        <FolderCardChin
          className={FOLDER_CHIN_CLASSNAME}
          isSharingMode={isSharingMode}
          isTouch={isTouch}
        >
          <CardTitle>
            <TruncateWithTooltip>{name}</TruncateWithTooltip>
          </CardTitle>
          <CardSubtitle>
            {!showQuicklookTooltip && !isDropZone && numUploadingAssets === 0 && (
              <CardMeta>
                <CardMetaPrimary>
                  <ItemCount count={itemCount + droppedAssetIds.length} />
                </CardMetaPrimary>
              </CardMeta>
            )}
            {numUploadingAssets > 0 && (
              <CardMeta>
                <CardMetaPrimary>
                  <UploadingItemCount count={numUploadingAssets} />
                </CardMetaPrimary>
              </CardMeta>
            )}
            {showQuicklookTooltip && (
              <CardTooltip>Press spacebar to quicklook</CardTooltip>
            )}
            {isDropZone && (
              <CardTooltip>
                {isPrivate ? 'Add to private folder' : 'Add to folder'}
              </CardTooltip>
            )}
          </CardSubtitle>
          {hasContextMenu && (
            <CardContextMenuButton
              // Clicking on the context menu button has the same semantics as right-clicking on
              // the card, so we trap the click event, and dispatch a contextmenu
              // event instead.
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                evt.target.dispatchEvent(
                  new MouseEvent('contextmenu', evt.nativeEvent)
                );
              }}
            />
          )}
        </FolderCardChin>
      </FolderCardWrapper>
    );
  }
}

FolderCard.propTypes = {
  droppedAssetIds: PropTypes.arrayOf(PropTypes.string),
  asset: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    private: PropTypes.bool,
    item_count: PropTypes.number,
  }).isRequired,
  height: PropTypes.number,
  hasContextMenu: PropTypes.bool,
  isDropZone: PropTypes.bool,
  isProjectArchived: PropTypes.bool,
  isSelected: PropTypes.bool,
  isSharedFolder: PropTypes.bool,
  isSharingMode: PropTypes.bool,
  isTouch: PropTypes.bool,
  moveAssets: PropTypes.func,
  onDropDone: PropTypes.func,
  selectedBorderWidth: PropTypes.number,
  showQuicklookTooltip: PropTypes.bool,
  thumbHeight: PropTypes.number,
  thumbRef: PropTypes.shape({ currrent: PropTypes.instanceOf(Element) }),
  thumbWidth: PropTypes.number,
  numUploadingAssets: PropTypes.number,
};

FolderCard.defaultProps = {
  droppedAssetIds: [],
  hasContextMenu: false,
  height: 0,
  isDropZone: false,
  isSelected: false,
  isSharingMode: false,
  isSharedFolder: false,
  isTouch: false,
  moveAssets: () => {},
  onDropDone: () => {},
  selectedBorderWidth: 2,
  showQuicklookTooltip: false,
  thumbHeight: 0,
  thumbRef: undefined,
  thumbWidth: 0,
  numUploadingAssets: 0,
};

export const testExports = {
  UploadingItemCount,
  FolderCardChin,
  FolderCardFace,
};
