import config, { isLocal, isTest } from 'config';

const OPTS = {
  expires: 365,
  domain: config.domain,
  secure: !isLocal && !isTest,
  sameSite: 'lax',
};

export default OPTS;
