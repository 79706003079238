import * as yup from 'yup';
import { flow, size, inRange, partial } from 'lodash';
import {
  WATERMARK_STRING_ALLOWED_CHARS_REGEX,
  WATERMARK_STRING_CHAR_LIMIT,
} from './constants';

const maxMessage = (field) =>
  `The ${field.label} field cannot be more than ${WATERMARK_STRING_CHAR_LIMIT} characters`;
const invalidCharsMessage = 'Only printable unicode characters are allowed.';
const withinSafeZoneMessage =
  'Text blocks must be positioned inside the safe zone.';

const SAFE_ZONE_MIN = 0.1;
const SAFE_ZONE_MAX = 0.9;

const isWithinSafeZone = partial(
  inRange,
  partial.placeholder,
  SAFE_ZONE_MIN,
  SAFE_ZONE_MAX
);

export default function validationSchemaFactory({
  shouldValidateSafeZone,
} = {}) {
  const testPosition = (position) =>
    shouldValidateSafeZone ? isWithinSafeZone(position) : true;
  return yup.object({
    name: yup
      .string()
      .label('name')
      .max(WATERMARK_STRING_CHAR_LIMIT, maxMessage),
    watermark_blocks: yup.lazy((value) => {
      const blockSchema = Object.keys(value).reduce((acc, key) => {
        acc[key] = yup.object({
          position_x: yup.mixed().when('scroll_text', {
            // we only want to validation position_x for non-scrolling text blocks
            is: 'none',
            then: yup
              .number()
              .test(
                'position-x-is-within-safe-zone',
                withinSafeZoneMessage,
                testPosition
              ),
          }),
          position_y: yup
            .number()
            .test(
              'position-y-is-within-safe-zone',
              withinSafeZoneMessage,
              testPosition
            ),
          scroll_text: yup.string().oneOf(['ltr', 'rtl', 'none']),
          data_points: yup.object({
            custom_text: yup.object({
              enabled: yup.boolean(),
              value: yup
                .string()
                .label('custom text')
                .when('enabled', {
                  is: true,
                  then: yup
                    .string()
                    .ensure()
                    .min(
                      1,
                      (field) => `A ${field.label} field cannot be empty.`
                    )
                    .max(WATERMARK_STRING_CHAR_LIMIT, maxMessage)
                    .matches(
                      WATERMARK_STRING_ALLOWED_CHARS_REGEX,
                      invalidCharsMessage
                    ),
                }),
            }),
          }),
        });

        return acc;
      }, {});

      return yup
        .object(blockSchema)
        .test(
          'no-empty-template',
          'A template must contain at least one text block.',
          flow([Object.keys, size])
        );
    }),
  });
}

export const testExports = { SAFE_ZONE_MAX, SAFE_ZONE_MIN };
