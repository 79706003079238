import React from 'react';
import PropTypes from 'prop-types';
import { trackButtonClick } from 'analytics';
import CombineIcon from '@frameio/components/src/svgs/icons/24/combine.svg';
import { MenuButton } from '@frameio/vapor';

export default function CreateDualView({
  assetIds,
  confirmCreateDualView,
  trackingPage,
  trackingPosition,
  ...rest
}) {
  return (
    <MenuButton
      onSelect={() => {
        confirmCreateDualView(assetIds, trackingPage, trackingPosition);
        trackButtonClick('create turnstyle', trackingPage, trackingPosition);
      }}
      iconBefore={<CombineIcon width={16} height={16} />}
      {...rest}
    >
      Create turnstyle asset
    </MenuButton>
  );
}

CreateDualView.propTypes = {
  confirmCreateDualView: PropTypes.func.isRequired,
  assetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};
