import React, { Fragment, useEffect, useState, useCallback } from 'react';
import Media from 'react-media';
import { MEDIUM_UP } from 'utils/mediaQueries';
import { connect } from 'formik';
import { get, flow, partialRight } from 'lodash';
import { Tooltip } from '@frameio/vapor';
import InfoIcon from '@frameio/components/src/svgs/icons/24/info-filled.svg';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { sessionWatermarkTemplateEntitySelector } from '@frameio/core/src/sessionWatermarkTemplate/selectors';
import { currentAccountSelector } from 'selectors/accounts';
import { LINK_ACCESS_OPTIONS } from 'components/LinkAccess';
import {
  watermarkDownloadsEnabled,
  watermarkIdEndUserOverrideEnabled,
} from 'utils/featureFlags';
import { StyledBox } from 'components/PresentationEditor/SecureSidePanel/SecureSidePanel';
import SharePanelSettingsSwitchRow from 'components/SharePanel/SharePanelSettingsSwitchRow';
import SessionWatermarkTemplateList from 'components/SessionWatermarkTemplateList';
import { endUserOverrideAccountSettingSelector } from 'components/SessionWatermarkTemplateList/selectors';
import {
  revertSessionWatermarkTemplateForReviewLink,
  revertSessionWatermarkTemplateForPresentation,
} from 'pages/WatermarkTemplateEditor/actions';
import { StyledButton } from 'pages/WatermarkTemplateEditor/styles';
import SessionWatermarkTemplateListScrollContainer from 'components/SessionWatermarkTemplateList/SessionWatermarkTemplateListScrollContainer';

const DisabledBox = styled.div`
  color: ${(p) => p.theme.color.coldWhite};
`;

const DisabledMessage = styled.p`
  color: ${(p) => p.theme.color.slateGray};
`;

const StyledSpan = styled.span`
  display: inline-flex;
  margin-top: -2px;
  margin-left: ${(p) => p.theme.spacing.tiny};
  color: ${(p) => p.theme.color.slateGray};
`;

// This returns a session_watermark from the redux store if found.
// If it is not found, it's likely been deleted so it checks within
// both the presentations entities and reviewLinks entities to see if
// it exists there.
export const getSessionWatermarkEntityWithEntitiesFallback = (
  state,
  id,
  presentationOrReviewId
) => {
  let entity = sessionWatermarkTemplateEntitySelector(state, id);

  if (!entity && presentationOrReviewId) {
    try {
      entity =
        state.entities.presentations.entities[presentationOrReviewId]
          .session_watermark;
    } catch (e) {
      entity =
        state.entities.reviewLinks.entities[presentationOrReviewId]
          .session_watermark;
    }
  }

  return entity;
};

const SessionWatermarkOptionsTab = ({
  canShareUnwatermarked,
  canShareWithoutForensicWatermark,
  canSeeWatermarkingSettings,
  defaultSessionWatermarkTemplateId,
  formik: {
    setFieldValue,
    values: {
      currentMode,
      sessionWatermarkId,
      requireForensicWatermark,
      id: entityId,
    },
  },
  isAdmin,
  isForensicWatermarkingEnabled: isForensicWatermarkingFeatureEnabled,
  onChange,
  showToasts,
  openSessionWatermarkTemplateEditor,
  sessionWatermarkTemplates,
  setDefaultSessionWatermarkTemplateId,
}) => {
  const sessionWatermarkEntity = useSelector((state) =>
    getSessionWatermarkEntityWithEntitiesFallback(
      state,
      sessionWatermarkId,
      entityId
    )
  );

  const isEndUserOverrideFeatureFlagEnabled = useSelector(
    watermarkIdEndUserOverrideEnabled
  );
  const isWatermarkDownloadsEnabled = useSelector(watermarkDownloadsEnabled);

  const currentAccountId = useSelector(currentAccountSelector).id;
  const isEuoAccountSettingEnabled = Boolean(
    useSelector((state) =>
      endUserOverrideAccountSettingSelector(state, currentAccountId)
    )
  );

  const isEndUserOverrideEnabled =
    isEndUserOverrideFeatureFlagEnabled && isEuoAccountSettingEnabled;

  const dispatch = useDispatch();

  /*
    When we open up the panel we need to determine if the currently-selected template is deleted. If it is,
    we want to add an additional field for that deleted template. The user should be able to toggle back and
    forth between this deleted template and any other existing template.

    - User has deleted template A
    - User switches to live template B. We retain the visual option to switch back to deleted template A.
    - User closes modal. Upon reload, deleted template A is no longer an option.

    We need to shadow this value in state to allow this toggling back and forth. Without this shadowing,
    a user would change from the deleted-template to another template and see the deleted template disappear
  */

  const publicTextDisclaimer =
    currentMode === LINK_ACCESS_OPTIONS.PUBLIC &&
    Boolean(sessionWatermarkId) &&
    'When Public access is enabled, logged out users will not see their name and email included in the watermark.';

  const isWatermarkingEnabled = Boolean(sessionWatermarkId);

  const isForensicWatermarkingEnabled = requireForensicWatermark;

  const disableFwmToggle = canSeeWatermarkingSettings
    ? !canShareWithoutForensicWatermark && isForensicWatermarkingEnabled
    : canSeeWatermarkingSettings && isForensicWatermarkingEnabled;

  const forensicWatermarkSettingsWillRender =
    canSeeWatermarkingSettings ||
    (!canSeeWatermarkingSettings && isForensicWatermarkingEnabled);

  const [
    currentSessionWatermarkTemplateList,
    setCurrentSessionWatermarkTemplateList,
  ] = useState(sessionWatermarkTemplates);

  const onRevert = useCallback(
    (templateEntity) => {
      if (templateEntity.review_link_id) {
        dispatch(
          revertSessionWatermarkTemplateForReviewLink(
            templateEntity.review_link_id,
            templateEntity.id
          )
        );
      } else if (templateEntity.presentation_id) {
        dispatch(
          revertSessionWatermarkTemplateForPresentation(
            templateEntity.presentation_id,
            templateEntity.id
          )
        );
      }
    },
    [dispatch]
  );

  const isShareOnlyTemplate = Boolean(
    sessionWatermarkEntity?.original_session_watermark_template_id
  );

  // If the currently selected template is marked as deleted, it won't exist in
  // the active list of templates add it to the list to be rendered sorted by when
  // it was created
  useEffect(() => {
    const newTemplateList =
      sessionWatermarkEntity?.deleted_at && !isShareOnlyTemplate
        ? [...sessionWatermarkTemplates, sessionWatermarkEntity].sort((a, b) =>
            get(a, 'original_inserted_at', a.inserted_at) >
            get(b, 'original_inserted_at', b.inserted_at)
              ? -1
              : 1
          )
        : sessionWatermarkTemplates;

    setCurrentSessionWatermarkTemplateList(newTemplateList);
  }, [
    isShareOnlyTemplate,
    sessionWatermarkEntity,
    sessionWatermarkId,
    sessionWatermarkTemplates,
  ]);

  const isRevertDisabled = useSelector(
    flow([
      partialRight(
        sessionWatermarkTemplateEntitySelector,
        sessionWatermarkEntity?.original_session_watermark_template_id
      ),
      (entity) => (entity ? Boolean(entity.deleted_at) : true),
    ])
  );

  return (
    <Media query={MEDIUM_UP}>
      {(isMediumUp) => (
        <Fragment>
          <StyledBox fullWidth padding={isMediumUp ? [2, 3] : [1, 2]}>
            {isForensicWatermarkingFeatureEnabled &&
              forensicWatermarkSettingsWillRender && (
                <SharePanelSettingsSwitchRow
                  label={
                    <Flex>
                      Apply Forensic Watermarking
                      <Tooltip
                        shouldUsePortal
                        placement="top"
                        title={
                          <Fragment>
                            Apply imperceptible Forensic Watermarking to all
                            proxies being shared, and log all access to these
                            proxies.
                          </Fragment>
                        }
                        variant="dark"
                      >
                        <StyledSpan>
                          <InfoIcon width={24} height={24} />
                        </StyledSpan>
                      </Tooltip>
                    </Flex>
                  }
                  checked={isForensicWatermarkingEnabled}
                  disabled={disableFwmToggle}
                  disabledTooltipMessage={
                    <DisabledBox>
                      You don't have permission to
                      <br /> perform this action
                      <DisabledMessage>
                        Please contact your admin.
                      </DisabledMessage>
                    </DisabledBox>
                  }
                  name="requireForensicWatermark"
                  onToggle={(evt) => {
                    onChange({
                      target: {
                        name: 'requireForensicWatermark',
                        value: evt.target.checked,
                      },
                    });
                  }}
                />
              )}
            <SharePanelSettingsSwitchRow
              label={
                <Flex>
                  Apply Watermark ID
                  <Tooltip
                    shouldUsePortal
                    placement="top"
                    title={
                      <Fragment>
                        Apply a visible Watermark ID to all proxies being
                        shared.
                      </Fragment>
                    }
                    variant="dark"
                  >
                    <StyledSpan>
                      <InfoIcon width={24} height={24} />
                    </StyledSpan>
                  </Tooltip>
                </Flex>
              }
              checked={isWatermarkingEnabled}
              disabled={!canShareUnwatermarked && isWatermarkingEnabled}
              disabledTooltipMessage={
                <DisabledBox>
                  You don't have permission to
                  <br /> perform this action
                  <DisabledMessage>Please contact your admin.</DisabledMessage>
                </DisabledBox>
              }
              name="sessionWatermarkId"
              onToggle={(evt) => {
                if (evt.target.checked && !isWatermarkDownloadsEnabled) {
                  setFieldValue('allowDownloads', false);
                }

                // When toggling on, automatically select the default template
                // If the default template id doesn't exist, select first id in the templates list
                // if someone has deleted all of their templates, pass along an empty value
                const defaultId =
                  defaultSessionWatermarkTemplateId ||
                  get(sessionWatermarkTemplates, [0, 'id'], '');
                const value = evt.target.checked ? defaultId : '';
                onChange({ target: { name: 'sessionWatermarkId', value } });
              }}
              help={publicTextDisclaimer}
            />
          </StyledBox>

          {isWatermarkingEnabled && (
            <Fragment>
              <SessionWatermarkTemplateListScrollContainer moveUp hideTopShadow>
                <SessionWatermarkTemplateList
                  defaultSessionWatermarkTemplateId={
                    defaultSessionWatermarkTemplateId
                  }
                  isAdmin={isAdmin}
                  onChange={onChange}
                  sessionWatermarkTemplates={
                    currentSessionWatermarkTemplateList
                  }
                  openSessionWatermarkTemplateEditor={
                    openSessionWatermarkTemplateEditor
                  }
                  onRevert={onRevert}
                  setTemplateAsDefault={setDefaultSessionWatermarkTemplateId}
                  selectedSessionWatermarkTemplateId={sessionWatermarkId}
                  showToasts={showToasts}
                />
              </SessionWatermarkTemplateListScrollContainer>
              {isAdmin && (
                <StyledBox padding={[2, 3]}>
                  <StyledButton
                    large
                    full
                    onClick={() => openSessionWatermarkTemplateEditor()}
                  >
                    Create a new template
                  </StyledButton>
                </StyledBox>
              )}

              {!isAdmin && isEndUserOverrideEnabled && (
                <Tooltip
                  disabled={!isShareOnlyTemplate || !isRevertDisabled}
                  title="You cannot revert this template because the original has been deleted."
                  variant="dark"
                  placement="top"
                  shouldUsePortal
                >
                  <StyledBox padding={[2, 3]}>
                    {isShareOnlyTemplate ? (
                      <StyledButton
                        large
                        full
                        disabled={isRevertDisabled}
                        onClick={() => {
                          onRevert(sessionWatermarkEntity);
                        }}
                      >
                        Revert to original
                      </StyledButton>
                    ) : (
                      <StyledButton
                        large
                        full
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          openSessionWatermarkTemplateEditor(
                            sessionWatermarkEntity.id,
                            true // open the editor to create a new share only template
                          );
                        }}
                      >
                        Modify and apply
                      </StyledButton>
                    )}
                  </StyledBox>
                </Tooltip>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Media>
  );
};

SessionWatermarkOptionsTab.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  defaultSessionWatermarkTemplateId: PropTypes.string,
  formik: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.shape({
      allowDownloads: PropTypes.bool.isRequired,
      expiresAt: PropTypes.string,
      sessionWatermarkId: PropTypes.string,
      showLinkExpiration: PropTypes.bool.isRequired,
      watermark: PropTypes.string,
    }).isRequired,
  }).isRequired,
  openSessionWatermarkTemplateEditor: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  sessionWatermarkTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      creator_id: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      inserted_at: PropTypes.string,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setDefaultSessionWatermarkTemplateId: PropTypes.func.isRequired,
};

SessionWatermarkOptionsTab.defaultProps = {
  sessionWatermarkTemplates: [],
};

export default connect(SessionWatermarkOptionsTab);
