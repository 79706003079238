import React from 'react';
import PropTypes from 'prop-types';
import { Transformer } from '@frameio/components';
import { EASE_IN_OUT } from 'utils/animationConstants';
import BaseThumb from 'components/AssetSlider/SliderThumbs/BaseThumb';

export const TRANSFORMER_ANIMATION_DURATION = 500;

export default class AnimatingBaseThumb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getOutTransform = () => {
    if (this.props.isReady) {
      return '';
    }
    return 'translateX(-40px)';
  };

  render() {
    const { index } = this.props;
    return (
      <Transformer
        key={`asset-key-${index}`}
        inCurve={EASE_IN_OUT}
        outCurve={EASE_IN_OUT}
        inDuration={TRANSFORMER_ANIMATION_DURATION + index * 20}
        isVisible
        startHidden={!this.props.isReady}
        outTransform={this.getOutTransform()}
      >
        <div key="base-thumb-transition">
          <BaseThumb {...this.props} />
        </div>
      </Transformer>
    );
  }
}

AnimatingBaseThumb.defaultProps = {
  isReady: false,
};

AnimatingBaseThumb.propTypes = {
  index: PropTypes.number.isRequired,
  isReady: PropTypes.bool,
};
