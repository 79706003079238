import { put, take, takeEvery, call } from 'redux-saga/effects';

import CUSTOM_ACTION_FORM_DIALOG, { open, close } from './actions';

export function* takeResponse() {
  const {
    payload: { response },
  } = yield take(CUSTOM_ACTION_FORM_DIALOG.RESPOND);
  return response;
}

export function* closeDialog() {
  yield put(close());
}

export function* prompt(header, body, fields) {
  yield put(open(header, body, fields));
  return yield call(takeResponse);
}

export default [takeEvery(CUSTOM_ACTION_FORM_DIALOG.RESPOND, closeDialog)];
