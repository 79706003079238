import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const ASSET = generateActionTypes('ASSET', [
  'CONFIRM_DELETE',
  'CONFIRM_DELETE_BATCH',
  'CONFIRM_EXTEND_DELETION_DATE',
  'CONFIRM_EXTEND_DELETION_DATE_LEGACY',
  'CONFIRM_DOWNLOAD_ALL',
  'DOWNLOAD_BATCH',
  'DOWNLOAD',
  'PROMPT_RENAME',
  'PROMPT_NEW_FOLDER_NAME',
  'CREATE_NEW_FOLDER',
  'BATCH_MOVE',
  'BATCH_COPY_TO_PROJECT',
  'SET_FWM_FRAME_RATE_SUPPORTED',
]);

export function confirmDeleteAssets(assetIds, trackingPage, trackingPosition) {
  return {
    type: ASSET.CONFIRM_DELETE_BATCH,
    payload: {
      assetIds,
      trackingPage,
      trackingPosition,
    },
  };
}

export function confirmDownloadAllAssets(
  projectId,
  folderId,
  isRootFolder = false
) {
  return {
    type: ASSET.CONFIRM_DOWNLOAD_ALL,
    payload: {
      projectId,
      folderId,
      isRootFolder,
    },
  };
}

export function downloadAssets(assetIds) {
  return {
    type: ASSET.DOWNLOAD_BATCH,
    payload: {
      assetIds,
    },
  };
}

/**
 * @param {string} id Asset's id.
 * @returns {Object} - Action object.
 */
export function confirmDeleteAsset(id, shouldRedirectToProject) {
  return {
    type: ASSET.CONFIRM_DELETE,
    payload: {
      id,
      shouldRedirectToProject,
    },
  };
}

/**
 * @param {string[]} assetIds - Array with asset ids.
 * @returns {Object} - Action object.
 */
export function confirmExtendDeletionDate(
  assetIds,
  trackingPage,
  trackingPosition
) {
  return {
    type: ASSET.CONFIRM_EXTEND_DELETION_DATE,
    payload: {
      assetIds,
      trackingPage,
      trackingPosition,
    },
  };
}

/**
 * Download an asset with associated child assets.
 * @param   {string} assetId - THe current folder's id.
 * @returns {Object} - Action object.
 */
export const downloadAsset = createAction(
  ASSET.DOWNLOAD,
  (assetId, resolution) => ({ assetId, resolution })
);

export function promptRenameAsset(assetId, trackingPage, trackingPosition) {
  return {
    type: ASSET.PROMPT_RENAME,
    payload: {
      assetId,
      trackingPage,
      trackingPosition,
    },
  };
}

/**
 * Opens a dialog that asks for the new folder name and creates the new folder.
 * @param   {string} parentFolderId - THe current folder's id.
 * @param   {boolean} isPrivate - Flag for private folders.
 * @param   {number} index - Index to create the folder at.
 * @returns {Object} - Action object.
 */

export function promptNewFolderName(parentFolderId, isPrivate, index) {
  return {
    type: ASSET.PROMPT_NEW_FOLDER_NAME,
    payload: {
      parentFolderId,
      isPrivate,
      index,
    },
  };
}

/**
 * Just creates a folder within the given parentFolder.
 * @param   {string} parentFolderId - THe current folder's id.
 * @param   {boolean} isPrivate - Flag for private folders.
 * @param   {number} index - Index to create the folder at.
 * @returns {Object} - Action object.
 */

export function createNewFolder(parentFolderId, isPrivate, index, name) {
  return {
    type: ASSET.CREATE_NEW_FOLDER,
    payload: {
      parentFolderId,
      isPrivate,
      index,
      name,
    },
  };
}

/**
 * Move some assets into a folder, and then refresh the folder.
 * @param {string[]} assetIds
 * @param {string} folderId
 */
export function moveAssets(assetIds, folderId) {
  return {
    type: ASSET.BATCH_MOVE,
    payload: {
      assetIds,
      folderId,
    },
  };
}

export function copyAssetsToProject(assetIds, projectId) {
  return {
    type: ASSET.BATCH_COPY_TO_PROJECT,
    payload: {
      assetIds,
      projectId,
    },
  };
}

export function setFwmFrameRateSupported(fwmFrameRateSupported = true) {
  return {
    type: ASSET.SET_FWM_FRAME_RATE_SUPPORTED,
    payload: {
      fwmFrameRateSupported,
    },
  };
}
