import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import AddStorageFlow from './AddStorageFlow';
import { startFlow, continueFlow, endFlow } from './actions';

const mapDispatchToProps = {
  startFlow,
  continueFlow,
  endFlow,
  closeModal,
};

export default connect(
  null,
  mapDispatchToProps
)(AddStorageFlow);

export const testExports = {
  mapDispatchToProps,
};
