import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Flex from 'styled-flex-component';
import { rgba } from 'polished';
import Switch from '@frameio/components/src/styled-components/Switch';

import Media from 'react-media';
import { MEDIUM_UP } from 'utils/mediaQueries';

const Wrapper = styled(Flex)`
  position: relative;
  right: 20px;

  ${({ isSmallScreen }) =>
    isSmallScreen &&
    `
      width: 100%;
      padding-left: 16px;
  `}
`;

const CopyLink = styled.a`
  ${(p) => p.theme.fontStyle.body};
  color: ${(p) => p.theme.color.lightBrand};
  padding-left: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const LinkText = styled.span`
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: ${(p) => p.theme.fontSize[2]};
  color: ${({ theme, dimTextColor }) =>
    rgba(theme.color.coolBlack, dimTextColor ? 0.2 : 1.0)};
`;

const SwitchActionText = styled.span`
  font-size: ${(p) => p.theme.fontSize[2]};
`;

const SwitchWrapper = styled.div`
  padding: 2px 25px 0 15px;
`;

const LinkContainer = styled(Flex)`
  overflow-x: scroll;
  white-space: nowrap;
  padding: ${(p) => p.theme.spacing.tiny};
  border-radius: ${(p) => p.theme.radius.medium};
  color: ${(p) => p.theme.color.coolBlack};
  border: 1px solid ${(p) => p.theme.color.silver};
  box-shadow: 0 0 8px 0 ${({ theme }) => rgba(theme.color.brand, 0.1)};
  width: 225px;
  height: 40px;
  overflow-x: hidden;

  ${({ enabled }) =>
    enabled &&
    `
      &:hover {
        cursor: pointer;
      }
  `}

  ${CopyLink} {
    flex: 1;
  }
`;

const SmallScreenWrapper = styled.div`
  position: absolute;
  right: -${(p) => p.theme.spacing.micro};
`;

class LinkSharingContent extends React.Component {
  onClick = () => {
    const { enabled, sharingLinkURL, onSharingLinkClick } = this.props;

    if (enabled) {
      onSharingLinkClick(sharingLinkURL);
    }
  };

  smallScreen = () => {
    const { enabled } = this.props;

    return (
      <SmallScreenWrapper>
        {enabled && <CopyLink onClick={this.onClick}>copy link</CopyLink>}
      </SmallScreenWrapper>
    );
  };

  largeScreen = () => {
    const { enabled, sharingLinkURL } = this.props;

    return (
      <LinkContainer row alignCenter enabled={enabled} onClick={this.onClick}>
        <LinkText dimTextColor={!enabled}>{sharingLinkURL}</LinkText>
        {enabled && <CopyLink>copy link</CopyLink>}
      </LinkContainer>
    );
  };

  render() {
    const { onSettingsChange, enabled } = this.props;

    return (
      <Media query={MEDIUM_UP}>
        {(isLargeScreen) => (
          <Wrapper row alignCenter isSmallScreen={!isLargeScreen}>
            <SwitchActionText>Link Active</SwitchActionText>
            <SwitchWrapper>
              <Switch
                isOn={enabled}
                onChange={(e) =>
                  onSettingsChange({ enabled: e.target.checked })
                }
              />
            </SwitchWrapper>
            {isLargeScreen ? this.largeScreen() : this.smallScreen()}
          </Wrapper>
        )}
      </Media>
    );
  }
}

LinkSharingContent.propTypes = {
  sharingLinkURL: PropTypes.string.isRequired,
  onSharingLinkClick: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
  onSettingsChange: PropTypes.func.isRequired,
};

export default LinkSharingContent;

export const testExports = {
  CopyLink,
  LinkContainer,
};
