import { call, put, takeLatest } from 'redux-saga/effects';
import { showErrorToast } from 'actions/toasts';
import { updateApprovalDecisionForAssetAndUser } from '@frameio/core/src/workfront/services';
import {
  WORKFRONT,
  setCurrentDecision,
} from '@frameio/core/src/workfront/actions';

const ERROR_MESSAGES = {
  403: 'You don’t have permission to perform this action',
  404: 'No approval is in progress for this asset',
  500: 'Oops! Something went wrong. Please try again.',
};

const TOAST_DELAY = 3000;

function* updateWorkfrontApprovalDecision(assetId, reviewLinkId, decision) {
  if (!decision) return;
  try {
    yield call(
      updateApprovalDecisionForAssetAndUser,
      assetId,
      reviewLinkId,
      decision.value
    );
    // if successfull we want to update decision in the store
    yield put(setCurrentDecision(assetId, decision.label));
  } catch (err) {
    const { status } = err?.response || {};
    const errorMessage = ERROR_MESSAGES[status] || ERROR_MESSAGES[500];
    yield put(
      showErrorToast({
        header: errorMessage,
        autoCloseDelay: TOAST_DELAY,
      })
    );
  }
}

export default [
  takeLatest(
    WORKFRONT.UPDATE_DECISION,
    ({ payload: { assetId, reviewLinkId, decision } }) =>
      updateWorkfrontApprovalDecision(assetId, reviewLinkId, decision)
  ),
];
