import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${(p) => p.theme.spacing.small} 0;
`;

export default Row;
