import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import { updatedArchivalMessagingEnabled } from 'utils/featureFlags';
import { currentScreenSelector } from './selectors';
import ArchiveProjectFlow from './ArchiveProjectFlow';
import { startFlow, endFlow } from './actions';

const mapStateToProps = (state) => ({
  currentScreen: currentScreenSelector(state),
  isUpdatedArchivalMessagingEnabled: updatedArchivalMessagingEnabled(state),
});

const mapDispatchToProps = {
  startFlow,
  endFlow,
  closeModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchiveProjectFlow);

export const testExports = {
  mapDispatchToProps,
};
