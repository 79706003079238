import { call, put, select, spawn, takeLatest } from 'redux-saga/effects';
import { redirectToProject as redirectToProjectSaga } from 'sagas/projects';
import { openSelectPlanFlowModal } from 'components/SelectPlanFlow/actions';
import { currentAccountSelector } from 'selectors/accounts';
import { didUpgradePlan } from 'components/SelectPlanFlow/sagas';
import track from 'analytics';

import { PROJECT_HEADER } from './actions';

function* redirectToProject({ payload: { projectId } }) {
  yield call(redirectToProjectSaga, projectId);
}

function* upgradePlan({ payload: { feature } }) {
  const { id: accountId } = yield select(currentAccountSelector);

  yield put(
    openSelectPlanFlowModal(accountId, {
      source: 'custom sort option',
      feature,
    })
  );

  const planUpgraded = yield call(didUpgradePlan);

  if (planUpgraded) {
    yield spawn(track, 'feature-unlocked', {
      feature,
    });
  }
}

export default [
  takeLatest(PROJECT_HEADER.REDIRECT_TO_PROJECT, redirectToProject),
  takeLatest(PROJECT_HEADER.UPGRADE_PLAN, upgradePlan),
];
