import { createAction } from 'redux-actions';
import {
  generateActionTypes,
  generateFetchActionTypes,
} from 'shared/actions/helpers';

const module = 'USER_SEARCH';
export const USER_SEARCH = {
  ...generateActionTypes(module, ['RESET', 'EXECUTE_SEARCH_STRATEGIES']),
  ...generateFetchActionTypes(module, [
    'SEARCH_USER',
    'SEARCH_PENDING_REVIEWER',
    'SEARCH_TEAM',
    'SEARCH_GROUP',
  ]),
};

/**
 * Reset the user search values.
 */
export const resetUserSearch = createAction(USER_SEARCH.RESET, () => ({}));

export const executeSearchStrategies = createAction(
  USER_SEARCH.EXECUTE_SEARCH_STRATEGIES,
  (query, strategies) => ({ query, strategies })
);

/**
 * Search for users in all teams of an account.
 * @param {string} accountId - The account's id that is being searched in
 * @param {string} query - Search query.
 * @param {string} projectId (optional) - The project's id. Adding this prop
 * will include the users role relative to the project.
 * @returns {Object} - Action object.
 */
export const searchUsersInAccount = createAction(
  USER_SEARCH.SEARCH_USER.BASE,
  (accountId, query, projectId) => ({ accountId, query, projectId })
);

/**
 * Search for teams in an account.
 * @param {string} accountId - The account's id that is being searched in
 * @param {string} query - Search query.
 * @returns {Object} - Action object.
 */
export const searchTeamsInAccount = createAction(
  USER_SEARCH.SEARCH_TEAM.BASE,
  (accountId, query) => ({ accountId, query })
);
