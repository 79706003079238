import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import PropTypes from 'prop-types';
import { clamp, debounce, noop, uniqBy } from 'lodash';
import emailParser from 'email-addresses';
import { Input } from '@frameio/components/src/styled-components/TextInput';
import ListCell, {
  DetailsText,
} from '@frameio/components/src/styled-components/ListCell';
import { isEmailValid } from '@frameio/components/src/styled-components/LoginSignup/utils/helpers';
import PopoverBase from '@frameio/components/src/components/PopoverBase';
import { POPOVER_POSITIONS } from '@frameio/components/src/components/PopoverView/popoverConstants';
import Popoverable from '@frameio/components/src/components/Popoverable';
import MailIcon from '@frameio/components/src/svgs/icons/24/mail.svg';
import scrollIntoViewIfNeeded from '@frameio/components/src/lib/scrollIntoViewIfNeeded';
import track from 'analytics';
import SearchResult from './ConnectedUserSearchResult';
import Row from './Row';
import UserToken, { styledClassName } from '../UserToken';
import GroupToken from '../GroupToken';

const ROW_HEIGHT = 48;
const MAX_ROWS = 5.5;

export const TYPE = {
  USER: 'user',
  TEAM: 'team',
  EMAIL: 'email',
  GROUP: 'group',
  PENDING_REVIEWER: 'pending_reviewer',
};

/*
DropdownContainer replaces Popover as background. We can not use
the Popover itself because...
...popover doesn't allow to define a border radius for each corner separately
...popover has a non-customizable shadow (using filter on the svg path)
 */
const DropdownContainer = styled.div`
  background-color: ${(p) => p.theme.color.white};
  width: ${({ width }) => width}px;
  border: solid 1px ${(p) => p.theme.color.silver};
  border-radius: 0 0 ${(p) => p.theme.radius.default}
    ${(p) => p.theme.radius.default};
  font-size: ${(p) => p.theme.fontSize[1]};
  overflow-y: scroll;
  height: 100%;

  ${Row} {
    padding: ${({ theme }) => `${theme.spacing.micro} ${theme.spacing.small}`};
  }

  ${DetailsText} {
    line-height: 1.4;
  }
`;

const Wrapper = styled(Flex).attrs(() => ({
  alignCenter: true,
}))`
  border-radius: ${(p) => p.theme.radius.default};
  border: solid 1px ${(p) => p.theme.color.silver};
  position: relative;
  padding: ${(p) => p.theme.spacing.micro};
  // overflow-y: scroll makes the scrollbar always appear,
  // set it to auto after VQA feedback:
  overflow-y: auto;
  max-height: 190px;
  // NOTE: 'wrap: true' cannot be set in the attrs object due to a known issue:
  // https://github.com/SaraVieira/styled-flex-component/issues/9
  flex-wrap: wrap;

  ${({ hasFocus, theme }) =>
    hasFocus ? `border-color: ${theme.color.brand};` : ''}

  ${styledClassName} {
    margin: ${({ theme }) => `calc(${theme.spacing.micro} / 2)`};
  }

  ${Input} {
    width: auto;
    border: none;
    flex-grow: 1;

    &::placeholder {
      color: ${(p) => p.theme.color.gray};
      opacity: 1;
    }
    &:focus {
      box-shadow: none;
    }
  }
`;

const MailAvatar = styled(Flex).attrs(() => ({ center: true }))`
  color: ${(p) => p.theme.color.graphiteGray};
  background-color: ${(p) => p.theme.color.coldWhite};
  width: 32px;
  height: 32px;
  border-radius: ${(p) => p.theme.radius.circle};
`;

class UserSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      highlightedTokenIndex: -1,
      highlightedDropdownIndex: -1,
      isDropdownOpen: false,
    };
    this.searchInputRef = React.createRef();
    this.containerRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { setTokens, tokens } = this.props;
    const { highlightedTokenIndex } = this.state;
    const { tokens: prevTokens } = prevProps;
    const { highlightedTokenIndex: prevHighlightedTokenIndex } = prevState;
    const didNumTokensChange = tokens.length !== prevTokens.length;

    if (didNumTokensChange) {
      setTokens(tokens);
    }

    if (
      highlightedTokenIndex !== prevHighlightedTokenIndex ||
      didNumTokensChange
    ) {
      this.setFocus();
    }
  }

  onKeyDown = (event, searchResults) => {
    const { tokens } = this.props;
    const {
      highlightedTokenIndex,
      highlightedDropdownIndex,
      isDropdownOpen,
      query,
    } = this.state;
    const numSearchResults = searchResults.length;
    const trimmedQuery = query.trim();

    switch (event.key) {
      case 'ArrowLeft': {
        // do nothing if we have no tokens in the container
        if (tokens.length === 0 || query.length > 0) break;

        // don't walk further than the outmost token to the left
        if (highlightedTokenIndex === 0) break;

        // -1 means no token is selected, in that case select the last token
        const nextIndex =
          highlightedTokenIndex === -1
            ? tokens.length - 1
            : highlightedTokenIndex - 1;
        this.selectTokenAtIndex(nextIndex);
        break;
      }
      case 'ArrowRight': {
        // do nothing if no token is selected and we're in the input textfield
        if (highlightedTokenIndex < 0) break;
        // if we reached the outmost token to the right, deselect any token
        const nextIndex =
          highlightedTokenIndex + 1 >= tokens.length
            ? -1
            : highlightedTokenIndex + 1;
        this.selectTokenAtIndex(nextIndex);
        break;
      }
      case 'ArrowDown': {
        if (!isDropdownOpen || numSearchResults === 0) break;
        const newHighlightedIndex =
          (highlightedDropdownIndex + 1) % numSearchResults;
        this.setHighlightedDropdownIndex(newHighlightedIndex);
        event.preventDefault();
        break;
      }
      case 'ArrowUp': {
        if (!isDropdownOpen || numSearchResults === 0) break;
        const hasSelection = highlightedDropdownIndex !== -1;
        const newHighlightedIndex = hasSelection
          ? (highlightedDropdownIndex + numSearchResults - 1) % numSearchResults
          : numSearchResults - 1;
        this.setHighlightedDropdownIndex(newHighlightedIndex);
        event.preventDefault();
        break;
      }
      case 'Enter': {
        // If a row in the dropdown is selected, add it as token.
        // Otherwise check the current input value if it's an email.
        this.addSelectedUserToken(searchResults) ||
          this.addEmailToken(trimmedQuery);
        break;
      }
      case ' ':
      case ',': {
        const lastCharacterIsSpace = query[query.length - 1] === ' ';
        // When pressing spacebar twice, we create a token.
        // But we won't do anything when space is pressed once.
        if (!lastCharacterIsSpace && event.key === ' ') break;
        event.preventDefault();
        this.addEmailToken(trimmedQuery);
        break;
      }
      case 'Backspace': {
        // do nothing if we have no tokens in the container
        if (tokens.length === 0) break;

        // if a token is selected remove it.
        const hasTokenSelected = highlightedTokenIndex !== -1;
        if (hasTokenSelected) {
          event.preventDefault();
          this.removeTokenAtIndex(highlightedTokenIndex);
          break;
        }

        // only handle backspace if the input textfield is empty
        // and select the token left to the input textfield
        if (query.length === 0) this.selectTokenAtIndex(tokens.length - 1);
        break;
      }
      case 'Escape': {
        event.preventDefault();
        this.setState({ query: '', highlightedTokenIndex: -1 });
        break;
      }
      default:
        break;
    }
  };

  onPasteInput = (event) => {
    event.preventDefault(); // don't paste the data
    const input = event.clipboardData.getData('text') || '';
    const emails = input
      .replace(/(\r\n|\n|\r|"|')/gm, ',')
      .split(/[,;]/)
      .map((email) => emailParser.parseOneAddress(email.trim()))
      .filter((email) => !!email)
      .map((parsed) => this.createToken(TYPE.EMAIL, parsed.address));
    this.addTokens(emails);
  };

  // Depending which value is set for the `highlightedTokenIndex` state, either
  // the input or a token is focused by this function
  setFocus = () => {
    const { highlightedTokenIndex } = this.state;
    if (highlightedTokenIndex < 0) {
      this.searchInputRef.current.focus();
      return;
    }
    const tokens = this.containerRef.current.querySelectorAll(styledClassName);
    const highlightedToken = tokens[highlightedTokenIndex];
    if (highlightedToken) highlightedToken.focus();
  };

  getSearchResults = () => {
    const {
      teamIds,
      tokens,
      userIds,
      groupIds,
      pendingReviewerIds,
    } = this.props;

    const tokenIds = tokens.filter((t) => t).map((token) => token.id);

    const teams = teamIds.reduce((acc, teamId) => {
      if (!tokenIds.includes(teamId)) {
        acc.push(this.createToken(TYPE.TEAM, teamId));
      }
      return acc;
    }, []);

    const users = userIds.reduce((acc, userId) => {
      if (!tokenIds.includes(userId)) {
        acc.push(this.createToken(TYPE.USER, userId));
      }
      return acc;
    }, []);

    const groups = groupIds.reduce((acc, groupId) => {
      if (!tokenIds.includes(groupId)) {
        acc.push(this.createToken(TYPE.GROUP, groupId));
      }
      return acc;
    }, []);

    const pendingReviewers = pendingReviewerIds.reduce(
      (acc, pendingReviewerId) => {
        if (!tokenIds.includes(pendingReviewerId)) {
          acc.push(this.createToken(TYPE.PENDING_REVIEWER, pendingReviewerId));
        }
        return acc;
      },
      []
    );

    return [...pendingReviewers, ...users, ...teams, ...groups];
  };

  setHighlightedDropdownIndex = (index) =>
    this.setState({ highlightedDropdownIndex: index });

  search = debounce((query) => {
    const { executeSearchStrategies, strategies } = this.props;
    // API doesn't return any result for a single letter so
    // let's not request until we have at least 2 characters
    if (query.length > 1) {
      executeSearchStrategies(query, strategies);
    }
  }, 50);

  createToken = (type, data) => ({ id: data, type });

  addToken = (newToken) => this.addTokens([newToken]);

  addEmailToken = (value) => {
    const hasWhitespace = /\s/.test(value);
    if (isEmailValid(value) && !hasWhitespace) {
      this.addToken(this.createToken(TYPE.EMAIL, value));
      return true;
    }
    return false;
  };

  addSelectedUserToken = (searchResults) => {
    const { highlightedDropdownIndex } = this.state;
    if (highlightedDropdownIndex !== -1) {
      this.addToken(searchResults[highlightedDropdownIndex]);
      return true;
    }
    return false;
  };

  addTokens = (newTokens) => {
    const { setTokens, tokens } = this.props;
    setTokens(uniqBy([...tokens, ...newTokens], 'id'));

    this.setState({
      query: '',
      highlightedTokenIndex: -1,
      isDropdownOpen: false,
      highlightedDropdownIndex: -1,
    });
  };

  removeTokenAtIndex = (index) => {
    const { setTokens, tokens } = this.props;
    // remove the token in an immutable fashion
    const newTokens = [...tokens.slice(0, index), ...tokens.slice(index + 1)];
    // after removal, select the next token to the right or the input
    const nextIndex = index === newTokens.length ? -1 : index;

    setTokens(newTokens);
    this.setState({
      highlightedTokenIndex: nextIndex,
    });
  };

  selectTokenAtIndex = (index) =>
    this.setState({ highlightedTokenIndex: index });

  renderPopover = (searchResults) => {
    const width = this.containerRef.current
      ? this.containerRef.current.getBoundingClientRect().width
      : 0;

    const { newUserPrompt } = this.props;
    const { query } = this.state;
    const hasResults = searchResults.length > 0;
    const height = ROW_HEIGHT * clamp(searchResults.length, 1, MAX_ROWS);

    return (
      /* Using PopoverBase here because Popover is not taking any width/height props.
    In fact, Popover's only job is to measure the width/height of it's children
    and pass it down to PopoverBase. */
      <PopoverBase
        pointerHeight={0}
        position="bottom"
        color="none"
        borderRadius={6}
        scaleWhenClosed="1, 0"
        height={height}
        width={width}
      >
        <DropdownContainer width={width}>
          <div>
            {hasResults ? (
              searchResults.map((result, index) => {
                const isHighlighted =
                  this.state.highlightedDropdownIndex === index;
                return (
                  <Row
                    key={result.id}
                    isHighlighted={isHighlighted}
                    onClick={() => {
                      // V3FRAME-165 theory that this never happens, remove
                      // after checking for it in segment
                      track('user-search-row-on-click-ever-happen', {
                        title: 'select',
                        page: 'user_search',
                        position: 'middle',
                      });
                      this.addSelectedUserToken(searchResults);
                    }}
                    onMouseEnter={() => this.setHighlightedDropdownIndex(index)}
                    onMouseLeave={() => this.setHighlightedDropdownIndex(-1)}
                    ref={(el) =>
                      el && isHighlighted && scrollIntoViewIfNeeded(el, false)
                    }
                  >
                    <SearchResult id={result.id} type={result.type} />
                  </Row>
                );
              })
            ) : (
              <Row>
                <ListCell
                  title={query}
                  image={
                    <MailAvatar>
                      <MailIcon />
                    </MailAvatar>
                  }
                  details={
                    isEmailValid(query) ? newUserPrompt(query) : 'Keep typing!'
                  }
                />
              </Row>
            )}
          </div>
        </DropdownContainer>
      </PopoverBase>
    );
  };

  trackSelected = () => {
    this.props.trackEventName &&
      track(this.props.trackEventName, {
        title: 'select',
        page: 'user_search',
        position: 'middle',
      });
  };

  render() {
    const {
      autoFocus,
      className,
      placeholderText,
      tokens,
      resetUserSearch,
    } = this.props;
    const { isDropdownOpen, hasFocus } = this.state;
    const searchResults = this.getSearchResults();
    const bodyScrollTop = document.body.scrollTop || 0;

    return (
      <Popoverable
        position={POPOVER_POSITIONS.bottom}
        /*
          For Safari on mobile, we need to substract the body scrollTop.
          The body.scrollTop changes when the keyboard is revealed and messes up
          the Y-position of the dropdown.
        */
        offsetY={bodyScrollTop - 10}
        isOpen={isDropdownOpen}
        togglePopover={(isOpen) => this.setState({ isDropdownOpen: isOpen })}
        enableFocusLock={false} // if true, the input looses focus as soon as the Popover opens
        popover={this.renderPopover(searchResults)}
      >
        {/* we need to stopPropagation otherwise Popoverable would open the popover
        whenever we click to select a token. */}
        <Wrapper
          className={className}
          ref={this.containerRef}
          onClick={(event) => event.stopPropagation()}
          hasFocus={hasFocus}
        >
          {tokens
            .filter((t) => t)
            .map((token, index) => {
              const Component =
                token.type === TYPE.GROUP ? GroupToken : UserToken;
              return (
                <Component
                  id={token.id}
                  type={token.type}
                  key={`${token.type}-${token.id}`}
                  onFocus={() => this.selectTokenAtIndex(index)}
                  onRemove={() => this.removeTokenAtIndex(index)}
                  onClick={() => this.selectTokenAtIndex(index)}
                  onKeyDown={(evt) => this.onKeyDown(evt, searchResults)}
                  data-test-id="wrapper-child"
                />
              );
            })}

          <Input
            // When Popoverable releases the Focuslock (when the popover closes),
            // we need to give focus to the Input
            // which can not be achieved with a regular autofocus.
            data-autofocus={autoFocus}
            autoFocus={autoFocus} // makes sure that Safari focuses when modal opens
            autoComplete="nope"
            placeholder={tokens.length === 0 ? placeholderText : undefined}
            onKeyDown={(evt) => this.onKeyDown(evt, searchResults)}
            compact
            ref={this.searchInputRef}
            value={this.state.query}
            onBlur={({ target: { value } }) => {
              // make sure something was actually clicked and a user didn't just
              // click off of the input
              value && this.trackSelected();
              this.addSelectedUserToken(searchResults) ||
                this.addEmailToken(value);
              this.setState({ hasFocus: false });
            }}
            onFocus={() => {
              this.setState({ highlightedTokenIndex: -1, hasFocus: true });
            }}
            onChange={(event) => {
              const previousQuery = this.state.query;
              const query = event.target.value;
              // When the user clears the query (less than 2 letters) and the dropdown closes,
              // we need to clear the cached search results.
              const shouldClearSearchResults =
                previousQuery.length >= 2 && query.length <= 1;
              if (shouldClearSearchResults) resetUserSearch();
              this.setState({ query, isDropdownOpen: query.length > 1 });
              this.search(query);
            }}
            onPaste={this.onPasteInput}
          />
        </Wrapper>
      </Popoverable>
    );
  }
}

UserSearch.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  newUserPrompt: PropTypes.func,
  placeholderText: PropTypes.string,
  executeSearchStrategies: PropTypes.func.isRequired,
  resetUserSearch: PropTypes.func.isRequired,
  // setTokens(tokens) is invoked when a token was added or removed.
  // It passes all current tokens as argument
  setTokens: PropTypes.func.isRequired,
  strategies: PropTypes.array.isRequired,
  teamIds: PropTypes.array,
  tokens: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  userIds: PropTypes.array,
  groupIds: PropTypes.array,
  pendingReviewerIds: PropTypes.array,
};

UserSearch.defaultProps = {
  autoFocus: true,
  className: '',
  newUserPrompt: noop,
  placeholderText: undefined,
  teamIds: [],
  tokens: [],
  userIds: [],
  groupIds: [],
  pendingReviewerIds: [],
};

export const testExports = {
  Wrapper,
  DropdownContainer,
  MailAvatar,
  ROW_HEIGHT,
  MAX_ROWS,
};
export default UserSearch;
