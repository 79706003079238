import { SELECT_PLAN_FLOW } from './actions';
import { isFetchingReducerFactory } from '../../factories/isFetching';

const isFetchingReducer = isFetchingReducerFactory(
  SELECT_PLAN_FLOW.IS_FETCHING
);

const initialState = {
  isFetching: false,
};

export default function setPlan(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SELECT_PLAN_FLOW.CONTINUE:
      return {
        ...state,
        selectedPlan: payload.selectedPlan,
      };
    default:
      return isFetchingReducer(state, action);
  }
}
