import { call, put, select, takeEvery } from 'redux-saga/effects';
import { showErrorToast } from 'actions/toasts';
import { PROJECT } from 'actions/projects';
import { WORKFRONT } from '@frameio/core/src/workfront/actions';
import { getWorkfrontTasks } from '@frameio/core/src/workfront/sagas';
import { currentProjectEntitySelector } from 'selectors/projects';
import { currentTeamEntitySelector } from 'selectors/teams';
import { teamEntitySelector } from '@frameio/core/src/teams/selectors';

import { ERROR_MESSAGES } from './integrationConstants';

function* handleGetWorkfrontTasksForAsset() {
  const project = yield select(currentProjectEntitySelector);

  if (project) {
    const team = yield select(teamEntitySelector, {
      teamId: project.team_id,
    });

    if (team?.workfront_enabled) {
      yield call(getWorkfrontTasks, project.id);
    }
  }
}

function* handleGetWorkfrontTasksForProject() {
  // When in project grid currentProject loads after currentTeam,
  // we initiate this call on PROJECT.SET_CURRENT, to have both available.
  const team = yield select(currentTeamEntitySelector);

  if (team?.workfront_enabled) {
    const project = yield select(currentProjectEntitySelector);

    if (project) {
      yield call(getWorkfrontTasks, project.id);
    }
  }
  // When in internal player, currentTeam loads after currentProject,
  // if there is no currentTeam set, we will look to the team the currentProject is in.
  if (!team) yield call(handleGetWorkfrontTasksForAsset);
}

function* handleUpdateTaskFailure() {
  yield put(
    showErrorToast({
      header: ERROR_MESSAGES.taskUpdate,
    })
  );
}

export default [
  takeEvery(PROJECT.SET_CURRENT, handleGetWorkfrontTasksForProject),
  takeEvery(WORKFRONT.UPDATE_TASK.FAILURE, handleUpdateTaskFailure),
];
