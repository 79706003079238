import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import transitionFactory, { scale } from 'react-transition-factory';
import { FadeTransition } from 'react-transition-components';
import ClipPathTransition from 'components/ClipPathTransition';
import { PlaceholderPlus } from 'components/FileCard';
import FolderThumb, {
  DROP_PRIMARY_TIMEOUT,
  DROP_PRIMARY_POSITION_EASING,
} from './FolderThumb';

const PLACEHOLDER_RADIUS = 22;
export const DROP_ZONE_TIMEOUT = 300;
export const DROP_ZONE_EASING = 'cubic-bezier(0.17, 0.11, 0, 1)';

export const EmptyPlaceholder = styled(Flex)`
  height: ${PLACEHOLDER_RADIUS * 2}px;
  width: ${PLACEHOLDER_RADIUS * 2}px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.color.brand};
`;

const ClippedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  ${Flex} {
    position: absolute;
    top: 0;
  }
`;
const ScaleFadeTransition = transitionFactory(scale.all, 'opacity');

export default function EmptyDropZoneIndicator({
  droppedAssetIds,
  height,
  isDropZone,
  isDropping,
  isDropComplete,
  onDropComplete,
  width,
}) {
  const maskEndRadius = Math.hypot(width / 2, height / 2);
  const didDrop = isDropping || isDropComplete;

  return (
    <ClipPathTransition
      in={didDrop}
      // (CORE-497): When `clip-path` is set on an element, scrollable ancestors do not show
      // their scrollbars when scrolling. So we set it to 'none' until we need to actually
      // do the transitions.
      start={didDrop ? `circle(${PLACEHOLDER_RADIUS}px)` : 'none'}
      end={didDrop ? `circle(${maskEndRadius}px)` : 'none'}
      mountOnEnter={false}
      unmountOnExit={false}
      timeout={DROP_PRIMARY_TIMEOUT}
      easing={DROP_PRIMARY_POSITION_EASING}
    >
      <ClippedContainer>
        <FadeTransition
          in={didDrop}
          timeout={DROP_PRIMARY_TIMEOUT}
          easing={DROP_PRIMARY_POSITION_EASING}
          onEntered={onDropComplete}
        >
          <FolderThumb
            asset={{
              item_count: droppedAssetIds.length,
              children: droppedAssetIds,
            }}
            height={height}
            width={width}
          />
        </FadeTransition>
        <Flex full alignCenter justifyCenter>
          {/*
            It's actually a little redundant to transition the scale here since the clip-path
            already is transitioning from a small circle to the large circle. However, for browsers
            that don't support clip-path, this provides a decent enough approximation.
          */}
          <ScaleFadeTransition
            in={isDropZone || didDrop}
            timeout={isDropping ? DROP_PRIMARY_TIMEOUT : DROP_ZONE_TIMEOUT}
            easing={
              isDropping ? DROP_PRIMARY_POSITION_EASING : DROP_ZONE_EASING
            }
            start={[0, 1]}
            end={didDrop ? [maskEndRadius / PLACEHOLDER_RADIUS, 0] : [1, 1]}
          >
            <EmptyPlaceholder alignCenter justifyCenter>
              {/*
                Inverse scale the plus so that it doesn't grow super big.
              */}
              <ScaleFadeTransition
                in={isDropping}
                mountOnEnter={false}
                unmountOnExit={false}
                timeout={DROP_PRIMARY_TIMEOUT}
                easing={DROP_PRIMARY_POSITION_EASING}
                start={[1, 1]}
                end={[PLACEHOLDER_RADIUS / maskEndRadius, 0]}
              >
                <PlaceholderPlus />
              </ScaleFadeTransition>
            </EmptyPlaceholder>
          </ScaleFadeTransition>
        </Flex>
      </ClippedContainer>
    </ClipPathTransition>
  );
}

EmptyDropZoneIndicator.propTypes = {
  droppedAssetIds: PropTypes.arrayOf(PropTypes.string),
  height: PropTypes.number.isRequired,
  isDropping: PropTypes.bool,
  isDropZone: PropTypes.bool,
  isDropComplete: PropTypes.bool,
  onDropComplete: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
};

EmptyDropZoneIndicator.defaultProps = {
  droppedAssetIds: [],
  isDropping: false,
  isDropZone: false,
  isDropComplete: false,
};

export const testExports = {
  ScaleFadeTransition,
  PLACEHOLDER_RADIUS,
};
