import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SubscriptionChangedConfirmation from 'components/SubscriptionChangedConfirmation';

const Copy = styled.p`
  color: ${(p) => p.theme.color.graphiteGray};
  text-align: center;
  padding: 0 10px 6px;
`;

function renderCopy(location, seatUnit) {
  const { totalSeatsAdded } = location.state;
  const formattedTotalSeatsAdded = Math.abs(totalSeatsAdded);

  return (
    <Copy>
      {formattedTotalSeatsAdded}{' '}
      {formattedTotalSeatsAdded === 1 ? seatUnit : `${seatUnit}s`}
      {` ${formattedTotalSeatsAdded > 1 ? 'have' : 'has'}`} been{' '}
      {totalSeatsAdded > 0 ? 'added' : 'removed'}!
    </Copy>
  );
}

const ConfirmSeatsAdded = ({ onDoneClick, seatUnit, location }) => (
  <SubscriptionChangedConfirmation onDoneClick={onDoneClick}>
    {renderCopy(location, seatUnit)}
    <Copy>Keep an eye on your inbox for your receipt.</Copy>
  </SubscriptionChangedConfirmation>
);

ConfirmSeatsAdded.propTypes = {
  location: PropTypes.object.isRequired,
  onDoneClick: PropTypes.func,
  seatUnit: PropTypes.string.isRequired,
};

ConfirmSeatsAdded.defaultProps = {
  onDoneClick: undefined,
};

export default ConfirmSeatsAdded;
