import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withFormik } from 'formik';
import Flex from 'styled-flex-component';
import * as Yup from 'yup';

import TextInput from '@frameio/components/src/styled-components/TextInput';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import Button from '@frameio/components/src/styled-components/Button';
import UserSearch, { TYPE } from 'components/UserSearch';
import { searchUsersStrategy } from 'components/UserSearch/strategies';
import EmojiPicker from '@frameio/components/src/components/Emojis';
import DropArrowIcon from '@frameio/components/src/svgs/icons/caron.svg';

const Container = styled(Flex).attrs(() => ({
  column: true,
}))`
  color: ${(p) => p.theme.color.gray};
  width: ${(p) => p.theme.spacing.units(70)};
  height: ${(p) => p.theme.spacing.units(70)};
`;

const Heading = styled.h2`
  ${(p) => p.theme.fontStyle.heading};
  color: ${(p) => p.theme.color.coolBlack};
`;

const Label = styled.label`
  display: block;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;

  > *:not(:first-child) {
    margin-left: ${(p) => p.theme.spacing.units(2)};
  }
`;

const LabelText = styled.span`
  color: ${(p) => p.theme.color.graphiteGray};
  display: inline-block;
  font-size: 12px;
`;

const StyledTextInput = styled(TextInput)`
  border-color: ${(p) => p.theme.color.lightGray};
  border-radius: ${(p) => p.theme.radius.default};
  height: ${(p) => p.theme.spacing.units(5)};
  padding-right: ${(p) => p.theme.spacing.units(8)};

  &::-moz-placeholder {
    // fix positioning for firefox
    line-height: ${(p) => p.theme.spacing.units(4)};
  }
`;

// The height of this container is equal to the height of StyledTextInput, minus 2px, to prevent
// the border-left from overlapping the border of the TextInput. Without the tweak, the gray border
// would overlap and look gnarly when we are in a `focus` or `error` state
const EmojiDividerContainer = styled.div`
  height: ${(p) => `calc(${p.theme.spacing.units(5)} - 2px)`};
  border-left: 1px solid ${(p) => p.theme.color.silver};
  display: flex;
  align-items: center;
`;

const StyledDropArrowIcon = styled(DropArrowIcon)`
  color: ${(p) => p.theme.color.silver};
`;

const Section = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${(p) => p.theme.spacing.medium};
  ${Heading} {
    margin-bottom: ${(p) => p.theme.spacing.units(2)};
  }
  ${Label} {
    margin-bottom: ${(p) => p.theme.spacing.units(2)};
  }
  ${LabelText} {
    margin-bottom: ${(p) => p.theme.spacing.micro};
  }
`;

const ErrorMessage = styled.div`
  ${(p) => p.theme.fontStyle.bodyS};
  color: ${(p) => p.theme.color.error};
`;

const EmojiSelectTab = styled.div`
  display: flex;
  align-items: center;
  padding: ${(p) => `0 ${p.theme.spacing.micro} 0 ${p.theme.spacing.tiny}`};

  &:hover {
    cursor: pointer;

    svg {
      color: ${(p) => p.theme.color.gray};
    }
  }
`;

const Emoji = styled.span`
  position: relative;
  top: 1px;
  left: 1px;
`;

const DEFAULT_EMOJIS_FOR_GROUP = [
  '🎬',
  '🎤',
  '🎧',
  '📷',
  '🎥',
  '💸',
  '📊',
  '📝',
  '💻',
];
const getRandomDefaultEmoji = () =>
  DEFAULT_EMOJIS_FOR_GROUP[
    Math.floor(Math.random() * DEFAULT_EMOJIS_FOR_GROUP.length)
  ];

const GroupsModal = ({
  closeModal,
  editMode,
  errorMessage,
  accountId,
  values: { tokens, name, emoji },
  errors,
  touched,
  handleChange,
  setFieldValue,
  handleSubmit,
}) => {
  const clientError = errors.name;
  const serverValidationError = touched.name && !!errorMessage && !clientError;
  const nameError = touched.name && !!clientError;
  const showError = touched.name && (serverValidationError || nameError);

  return (
    <Container>
      <Section>
        <Flex column full>
          <ModalCloseButton onClick={closeModal} type="button" />
          <Heading>{editMode ? 'Edit group' : 'Create group'}</Heading>
          <Label>
            <LabelText>Name</LabelText>
            <StyledTextInput
              autoFocus
              compact
              name="name"
              value={name}
              error={!!showError}
              placeholder="e.g. Marketing"
              onChange={handleChange}
              iconAlignment="right"
              icon={
                <EmojiDividerContainer>
                  <EmojiPicker
                    onClick={(e) => setFieldValue('emoji', e.native)}
                  >
                    <EmojiSelectTab>
                      <Emoji>{emoji}</Emoji>
                      <StyledDropArrowIcon />
                    </EmojiSelectTab>
                  </EmojiPicker>
                </EmojiDividerContainer>
              }
            />
            {serverValidationError && (
              <ErrorMessage>This name already exists.</ErrorMessage>
            )}
            {nameError && <ErrorMessage>{errors.name}</ErrorMessage>}
          </Label>
          <LabelText>Members</LabelText>
          <UserSearch
            autoFocus={false}
            strategies={[searchUsersStrategy(accountId, null)]}
            canAddEmailAddresses={false}
            newUserPrompt={(user) => `Add ${user} to this group`}
            placeholderText="Search by name"
            setTokens={(value) => setFieldValue('tokens', value)}
            tokens={tokens}
          />
        </Flex>
        <Footer>
          <Button onClick={closeModal}>Cancel</Button>
          <Button primary onClick={handleSubmit}>
            {editMode ? 'Save' : 'Create'}
          </Button>
        </Footer>
      </Section>
    </Container>
  );
};

GroupsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.shape({
    emoji: PropTypes.string.isRequired,
    tokens: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

GroupsModal.defaultProps = {
  errors: {},
  touched: {},
  editMode: false,
};

export default withFormik({
  mapPropsToValues: ({ users, name, emailMemberships, emoji }) => {
    const userTokens = (users || []).map((user) => ({
      id: user,
      type: TYPE.USER,
    }));

    const emailTokens = (emailMemberships || []).map((membership) => ({
      id: membership.email,
      type: TYPE.EMAIL,
    }));

    return {
      tokens: userTokens.concat(emailTokens),
      name: name || '',
      emoji: emoji || getRandomDefaultEmoji(),
    };
  },
  handleSubmit: (values, { props }) => {
    if (props.editMode) {
      props.updateUserGroup(values);
    } else {
      props.createUserGroup(values);
    }
  },
  validateOnChange: true,
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required('This name cannot be empty')
      .max(
        60,
        ({ max }) => `The group name should be ${max} characters or less`
      ),
  }),
})(GroupsModal);
