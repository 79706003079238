import { connect } from 'react-redux';
import {
  currentAccountSelector,
  isAccountOnLegacyPlanSelector,
} from 'selectors/accounts';
import { highestAccountOrTeamRoleForAccountIdSelector } from 'selectors/roles';
import { currentUserEntitySelector } from 'selectors/users';
import SettingsLink from './SettingsLink';

function mapStateToProps(state) {
  const accountId = currentAccountSelector(state).id;
  const currentUser = currentUserEntitySelector(state);
  const currentUserAccountId = currentUser.account_id;
  const role = highestAccountOrTeamRoleForAccountIdSelector(state, {
    accountId,
  });

  return {
    accountId,
    currentUserAccountId,
    isCollaboratorOnly: !role,
    isOnLegacyPlan: isAccountOnLegacyPlanSelector(state, { accountId }),
    role,
  };
}

export const testExports = { mapStateToProps };

export default connect(mapStateToProps)(SettingsLink);
