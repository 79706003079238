const MAX_DAYS_REMAINING_IN_TRIAL = 4;
const DAYS_REMAINING_IN_TRIAL = 'days_remaining_in_trial';

export function saveTrialCountdown(countdown) {
  if (!Modernizr.localstorage) {
    return false;
  }
  localStorage.setItem(DAYS_REMAINING_IN_TRIAL, countdown);
  return true;
}

function getTrialCountdown() {
  if (!Modernizr.localstorage) {
    return -1;
  }

  const days = localStorage.getItem(DAYS_REMAINING_IN_TRIAL);
  return days !== null ? Number(days) : MAX_DAYS_REMAINING_IN_TRIAL;
}

// Comparing current countdown value to the countdown value when the modal was
// last automatically shown (the latter value is stored and retrieved in localStorage
// using saveTrialCountdown and getTrialCountdown, respectively).
// We can assume that if the countdown last stored is the same as the current countdown,
// the automatically opening modal has been seen today.
export const didNotShowToday = (countdown) => countdown < getTrialCountdown();

export const isTrialEndingIn3Days = (countdown) =>
  countdown < MAX_DAYS_REMAINING_IN_TRIAL;

export const testExports = {
  DAYS_REMAINING_IN_TRIAL,
  saveTrialCountdown,
  getTrialCountdown,
  didNotShowToday,
};
