import { call, /* put, */ spawn, take, takeLatest } from 'redux-saga/effects';
import { connectToSocket } from '@frameio/core';
import { SOCKET, SocketEvents } from '@frameio/core/src/sockets/actions';
import { joinClientVersionRoom } from '@frameio/core/src/sockets/sagas';
import { createSocketConnectionEventChannel } from '@frameio/core/src/sockets/helpers';

let hasWebsocketConnectionError = false;

/**
 * Watch the connection status of the socket service to trigger toasts that
 * informs the customer about their network connection status.
 */
function* watchSocketConnectionStatus(socket) {
  const socketConnectionEventsChannel = yield call(
    createSocketConnectionEventChannel,
    socket
  );

  while (true) {
    const event = yield take(socketConnectionEventsChannel);
    switch (event.type) {
      case SocketEvents.SocketError:
        if (window.navigator?.onLine) {
          break;
        }
        /*
          Because the SocketError event may be emitted multiple times on re-connect
          attempts (see https://hexdocs.pm/phoenix/js/#onerror-hooks), let's ensure
          that the error toast is only thrown once, e.g. when ‘hasInternet‘ changes
          from true to false.
        */
        if (!hasWebsocketConnectionError) {
          hasWebsocketConnectionError = true;
          // todo(GROW-3439): re-instate or remove offline toasts entirely
          // yield put(
          //   showErrorToast({
          //     header:
          //       'It looks like you’ve gone offline. Check your internet connection.',
          //   })
          // );
        }
        break;

      case SocketEvents.SocketOpen:
        /**
         * The client version room is used to let the client know when a new version
         * of the client is available and to update the client as soon as possible.
         *
         * Most channels are joined on demand, but we want to join the client version
         * room as soon as the socket is open in case an urgent update is required.
         */
        yield spawn(joinClientVersionRoom);

        /*
          When the socket connection is first established, a SocketOpen event
          is emitted, so let's ensure that a re-connection toast is only thrown
          when 'hasWebsocketConnectionError' has changed from true to false (i.e. indicating
          that the socket connect was previously dropped and has since been
          re-established).
        */
        if (hasWebsocketConnectionError) {
          hasWebsocketConnectionError = false;
          // todo(GROW-3439): re-instate or remove offline toasts entirely
          // yield put(
          //   showSuccessToast({
          //     header: 'It looks like you’re back online!',
          //   })
          // );
        }
        break;

      default:
        break;
    }
  }
}

export function* initSocketService() {
  const socket = yield call(connectToSocket);

  yield spawn(watchSocketConnectionStatus, socket);
}

function trackSuccess(entityType) {
  console.debug(`Successfully joined socket room `, entityType);
}

function trackFailure(entityType, { payload }) {
  // eslint-disable-next-line no-console
  console.warn(`Failed to join socket room "${payload.room}" `, entityType);
}

export default [
  takeLatest(SOCKET.JOIN_ACCOUNT.SUCCESS, () => trackSuccess('account')),
  takeLatest(SOCKET.JOIN_ASSET.SUCCESS, () => trackSuccess('asset')),
  takeLatest(SOCKET.JOIN_PROJECT.SUCCESS, () => trackSuccess('project')),
  takeLatest(SOCKET.JOIN_TEAM.SUCCESS, () => trackSuccess('team')),
  takeLatest(SOCKET.JOIN_USER.SUCCESS, () => trackSuccess('user')),

  takeLatest(SOCKET.JOIN_ACCOUNT.FAILURE, (action) =>
    trackFailure('account', action)
  ),
  takeLatest(SOCKET.JOIN_ASSET.FAILURE, (action) =>
    trackFailure('asset', action)
  ),
  takeLatest(SOCKET.JOIN_PROJECT.FAILURE, (action) =>
    trackFailure('project', action)
  ),
  takeLatest(SOCKET.JOIN_TEAM.FAILURE, (action) =>
    trackFailure('team', action)
  ),
  takeLatest(SOCKET.JOIN_USER.FAILURE, (action) =>
    trackFailure('user', action)
  ),
];

export const testExports = {
  watchSocketConnectionStatus,
};
