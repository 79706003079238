import { connect } from 'react-redux';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import { type as assetType } from '@frameio/core/src/assets/helpers/constants';

import { publishToVimeo } from 'components/PublishToVimeo/actions';
import PublishAssetToVimeo from './PublishAssetToVimeo';

function mapStateToProps(state, { assetId }) {
  const asset = assetEntitySelector(state, { assetId });
  return {
    assetId:
      asset.type === assetType.VERSION_STACK ? asset.cover_asset : assetId,
  };
}

const mapDispatchToProps = { publishToVimeo };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublishAssetToVimeo);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
