import { connect } from 'react-redux';

import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import { planEntityForAccountIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { userEntitySelector } from '@frameio/core/src/users/selectors';
import { currentAccountEntitySelector } from 'selectors/accounts';
import shouldRestrictCollaborators from 'selectors/permissions/shouldRestrictCollaborators';
import { currentProjectSelector } from 'selectors/projects';
import { highestRoleForProjectIdSelector } from 'selectors/roles';
import { subscriptionEntityByAccountIdSelector } from 'selectors/subscriptions';
import { currentTeamEntitySelector } from 'selectors/teams';
import { currentUserEntitySelector } from 'selectors/users';
import { getAssetIdForViewer } from 'analytics/helpers';
import { copyVersion } from 'components/DevelopmentTools/actions';

import Context from './Context';

const mapDispatchToProps = { copyVersion };

function mapStateToProps(state) {
  const account = currentAccountEntitySelector(state) || {};
  const { id: accountId, owner_id: ownerId } = account;
  const hasLegacyCollabs = !shouldRestrictCollaborators(account);
  const { email: ownerEmail, name: ownerName } =
    userEntitySelector(state, { userId: ownerId }) || {};
  const plan = planEntityForAccountIdSelector(state, { accountId });
  const subscription = subscriptionEntityByAccountIdSelector(state, accountId);

  const { id: projectId } = currentProjectSelector(state);
  const team = currentTeamEntitySelector(state);

  const assetId = getAssetIdForViewer();
  const asset = assetEntitySelector(state, { assetId });

  return {
    accountId,
    asset,
    hasLegacyCollabs,
    ownerEmail,
    ownerName,
    // TODO: For account-level routes (e.g. Search, Inbox), should we indicate
    // the highest-scoped role when project context isn't available?
    role: highestRoleForProjectIdSelector(state, { projectId }),
    plan,
    subscription,
    team,
    user: currentUserEntitySelector(state),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Context);
