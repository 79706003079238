import React from 'react';
import styled from 'styled-components';
import { ellipsis } from 'polished';
import Flex, { FlexItem } from 'styled-flex-component';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { connect } from 'formik';
import track from 'analytics';
import DropArrowIcon from '@frameio/components/src/svgs/icons/caron.svg';
import { TabSelect } from '@frameio/components';
import { ENTER_DURATION } from '@frameio/components/src/components/Modal';
import AnimateHeight from '@frameio/components/src/styled-components/AnimateHeight';
import Button from '@frameio/components/src/styled-components/Button';
import Switch from '@frameio/components/src/styled-components/Switch';
import TextArea from '@frameio/components/src/styled-components/TextArea';
import TextInput from '@frameio/components/src/styled-components/TextInput';
import MessageIcon from '@frameio/components/src/svgs/icons/16/message.svg';
import UserSearch, { TYPE } from 'components/UserSearch';
import { searchUsersStrategy } from 'components/UserSearch/strategies';

import { MEDIUM_UP } from 'utils/mediaQueries';

import AdvancedSettings from '../AdvancedSettings';
import { ENTERPRISE_MAX_MESSAGE_LENGTH_FOR_REVIEW_LINKS } from '../ReviewLinkEditorShareForm';

const MODAL_BUTTON_CLICKED_EVENT = 'review-link-modal-button-clicked';

export const shareTabs = [
  { key: 'share-link', text: 'Share via link' },
  { key: 'share-email', text: 'Share via email' },
];

export const Heading = styled.h2`
  ${(p) => p.theme.fontStyle.heading};
  color: ${(p) => p.theme.color.coolBlack};
`;

const TitleLabel = styled.label`
  display: block;
  margin-bottom: ${(p) => p.theme.spacing.units(2)};
`;

const TitleText = styled.span`
  color: ${(p) => p.theme.color.graphiteGray};
  display: inline-block;
  font-size: 12px;
  margin-bottom: ${(p) => p.theme.spacing.micro};
`;

const TitleTextInput = styled(TextInput)`
  border-radius: ${(p) => p.theme.radius.default};
`;

export const SettingSwitchRow = styled.div`
  padding-bottom: ${(p) => p.theme.spacing.tiny};
`;

export const SettingSwitch = styled(FlexItem)`
  margin-left: auto;
  label {
    display: block;
  }
`;

export const SettingLabel = styled.label`
  display: flex;
  justify-content: space-between;
  ${(p) => p.theme.fontStyle.body};
  color: ${(p) => p.theme.color.almostBlack};
`;

const DropArrow = styled(({ isOpen, ...rest }) => <DropArrowIcon {...rest} />)`
  width: 18px;
  height: 18px;
  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
  transition: transform 0.2s ease-in-out;
`;

const AdvancedSettingsToggle = styled(Button).attrs(() => ({
  compact: true,
  text: true,
}))`
  ${(p) => p.theme.fontStyle.body};
  line-height: 1.3;
  border: 1px solid transparent;

  span {
    margin-right: 2px;
  }
`;

const TabSelectWrapper = styled.div`
  /*
    Below styles are PLACEHOLDER only, and will be
    removed/updated once design and behaviors are
    finalized
  */
  height: 28px;
  margin-bottom: ${(p) => p.theme.spacing.units(2)};

  button {
    color: ${(p) => p.theme.color.graphiteGray};
    display: inline-block;
    margin: 0 0 ${(p) => p.theme.spacing.tiny};
    padding-right: ${(p) => p.theme.spacing.large};

    &.selected {
      color: ${(p) => p.theme.color.coolBlack};
    }
  }
  .tabs-container {
    height: 100%;
  }
`;

const ShareAsLinkText = styled(TextInput)`
  ${ellipsis()};
  width: 100%;
  background-color: ${(p) => p.theme.color.coldWhite};
  border-radius: ${(p) => p.theme.radius.default};
  color: ${(p) => p.theme.color.gray};
  font-size: ${(p) => p.theme.fontSize[2]};
  &,
  &:focus {
    border-color: ${(p) => p.theme.color.coldWhite};
  }
`;

const ShareAsLinkButton = styled(Button).attrs(() => ({
  primary: true,
  children: <React.Fragment>Copy link</React.Fragment>,
}))`
  margin-left: ${(p) => p.theme.spacing.small};
  height: auto;
  padding: 0 ${(p) => p.theme.spacing.medium};
`;

const StyledMessageIcon = styled(MessageIcon)`
  color: ${(p) => p.theme.color.graphiteGray};
  margin-right: ${(p) => p.theme.spacing.micro};
`;

const AddEmailMessage = styled(Button).attrs(() => ({
  text: true,
  children: (
    <React.Fragment>
      <StyledMessageIcon />
      Add a message
    </React.Fragment>
  ),
}))`
  ${(p) => p.theme.fontStyle.body};
  border: 1px solid transparent;
`;

const StyledMessageTextArea = styled(TextArea)`
  // Don't allow the text area to be resized below 32px:
  min-height: ${(p) => p.theme.spacing.large};
  padding: ${({ theme: { spacing } }) => `${spacing.micro} ${spacing.tiny}`};
  resize: vertical;
`;

const ShareEmailControls = styled(Flex).attrs(() => ({
  justifyBetween: true,
}))`
  padding-top: ${(p) => p.theme.spacing.tiny};

  ${StyledMessageTextArea} {
    margin-right: ${(p) => p.theme.spacing.small};
  }
`;

export const Section = styled.section`
  padding: ${(p) => p.theme.spacing.units(2)};

  ${Heading} {
    margin-bottom: ${(p) => p.theme.spacing.units(2)};
  }

  ${SettingSwitchRow} {
    border-bottom: 1px solid ${(p) => p.theme.color.silver};
    margin-bottom: ${(p) => p.theme.spacing.tiny};
  }

  &:first-of-type {
    padding-bottom: ${(p) => p.theme.spacing.tiny};
  }

  .Settings__UserSearch {
    box-shadow: none;
  }

  @media ${MEDIUM_UP} {
    padding: ${(p) => p.theme.spacing.medium};
  }
`;

export class SettingsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMeasuredContent: false,
    };
    /**
     * This is a hack to force the Basic Settings component to re-render, because
     * its height and width are derived from Measured. Measured breaks because the
     * parent modal scales in size, and the child Share TabSelect is not correctly
     * measured and rendered. This timeout forces a re-render after the modal is
     * already at its max size.
     */
    this.showMeasuredContentTimeout = setTimeout(
      () => this.setState({ showMeasuredContent: true }),
      ENTER_DURATION
    );
  }

  componentWillUnmount() {
    clearTimeout(this.showMeasuredContentTimeout);
  }

  shareLinkRef = React.createRef();

  debouncedTitleInput = debounce((title) => {
    const { submitForm } = this.props.formik;
    if (!title) return;
    submitForm();
  }, 200);

  sendEmails = () => {
    const {
      formik: {
        values: { emailMessage, userTokens },
      },
      id,
      sendReviewLinkEmails,
    } = this.props;

    const userIds = userTokens
      .filter((token) => token?.type === TYPE.USER)
      .map((token) => token.id);

    const recipients = userTokens
      .filter((token) => token?.type === TYPE.EMAIL)
      .map((token) => token.id);

    sendReviewLinkEmails(id, {
      recipients,
      userIds,
      message: emailMessage,
    });
  };

  render() {
    const {
      accountId,
      canToggleComments,
      canUsePasswordProtectedShares,
      canUseShareLinkExpiration,
      copyShortUrlToClipboard,
      errors,
      formik: {
        handleChange,
        setFieldValue,
        submitForm,
        values: {
          allowApprovals,
          allowComments,
          allowDownloads,
          emailMessage,
          name,
          selectedShareTab,
          showAdvancedSettings,
          showAllVersions,
          showEmailMessage,
          userTokens,
        },
      },
      id: reviewLinkId,
      isAdmin,
      itemIds,
      projectId,
      reviewLinkUrl,
      upgradePlan,
    } = this.props;

    const { hostname, pathname } = new URL(reviewLinkUrl);
    const { showMeasuredContent } = this.state;
    const isShareViaLinkTab = selectedShareTab === shareTabs[0].key;

    return (
      <React.Fragment>
        <Section>
          <Heading>
            Share {itemIds.length} {itemIds.length === 1 ? 'item' : 'items'}
          </Heading>
          <TitleLabel>
            <TitleText>Title</TitleText>
            <TitleTextInput
              autoFocus
              error={errors.name}
              name="name"
              value={name}
              onChange={(evt) => {
                handleChange(evt);
                this.debouncedTitleInput(evt.target.value);
              }}
              onFocus={(evt) => evt.target.select()}
            />
          </TitleLabel>
          <SettingSwitchRow>
            <SettingLabel>
              Allow downloads
              <SettingSwitch>
                <Switch
                  isOn={allowDownloads}
                  name="allowDownloads"
                  onChange={(evt) => {
                    handleChange(evt);
                    /*
                    TODO: Formik doesn't have first class support for submitting on change,
                    which means that calling `submitForm` here may happen before the
                    `setState` called by `handleChange. Wrapping it in a setTimeout defers
                    the submitForm to the next tick.
                    See https://github.com/jaredpalmer/formik/issues/1218.
                  */
                    setTimeout(() => submitForm(), 0);
                  }}
                  value={allowDownloads}
                />
              </SettingSwitch>
            </SettingLabel>
          </SettingSwitchRow>
          {canToggleComments && (
            <SettingSwitchRow>
              <SettingLabel>
                Allow comments
                <SettingSwitch>
                  <Switch
                    isOn={allowComments}
                    name="allowComments"
                    onChange={(evt) => {
                      handleChange(evt);
                      setTimeout(() => submitForm(), 0);
                    }}
                    value={allowComments}
                  />
                </SettingSwitch>
              </SettingLabel>
            </SettingSwitchRow>
          )}
          <SettingSwitchRow>
            <SettingLabel>
              Allow approvals
              <SettingSwitch>
                <Switch
                  isOn={allowApprovals}
                  name="allowApprovals"
                  onChange={(evt) => {
                    handleChange(evt);
                    setTimeout(() => submitForm(), 0);
                  }}
                  value={allowApprovals}
                />
              </SettingSwitch>
            </SettingLabel>
          </SettingSwitchRow>
          <SettingSwitchRow>
            <SettingLabel>
              Show all versions
              <SettingSwitch>
                <Switch
                  isOn={showAllVersions}
                  name="showAllVersions"
                  onChange={(evt) => {
                    handleChange(evt);
                    setTimeout(() => submitForm(), 0);
                  }}
                  value={showAllVersions}
                />
              </SettingSwitch>
            </SettingLabel>
          </SettingSwitchRow>
          <AdvancedSettings
            canUsePasswordProtectedShares={canUsePasswordProtectedShares}
            canUseShareLinkExpiration={canUseShareLinkExpiration}
            reviewLinkId={reviewLinkId}
            upgradePlan={upgradePlan}
            isAdmin={isAdmin}
          />
          <Flex justifyEnd>
            <AdvancedSettingsToggle
              name="showAdvancedSettings"
              onClick={() => {
                setFieldValue('showAdvancedSettings', !showAdvancedSettings);

                const title = `${
                  !showAdvancedSettings ? 'show' : 'hide'
                }_advanced_settings`;
                track(MODAL_BUTTON_CLICKED_EVENT, { title });
              }}
              type="button"
            >
              <Flex justifyBetween alignCenter>
                <span>
                  {showAdvancedSettings ? 'Hide' : 'Show'} advanced settings
                </span>
                <DropArrow isOpen={showAdvancedSettings} />
              </Flex>
            </AdvancedSettingsToggle>
          </Flex>
        </Section>
        <Section>
          <TabSelectWrapper>
            {showMeasuredContent && (
              <TabSelect
                selectedTab={selectedShareTab}
                tabs={shareTabs}
                onTabChange={(tabKey, evt) => {
                  evt.preventDefault();
                  setFieldValue('selectedShareTab', tabKey);

                  const title =
                    tabKey === shareTabs[0].key
                      ? 'share_via_link'
                      : 'share_via_email';
                  track(MODAL_BUTTON_CLICKED_EVENT, { title });
                }}
              />
            )}
          </TabSelectWrapper>
          {isShareViaLinkTab ? (
            <Flex justifyBetween>
              <ShareAsLinkText
                data-test-id="review-link-url"
                ref={this.shareLinkRef}
                onClick={() => this.shareLinkRef.current.select()}
                value={`${hostname}${pathname}`}
                readOnly
              />
              <ShareAsLinkButton
                type="button"
                data-test-id="copy-review-link-button"
                onClick={() => {
                  copyShortUrlToClipboard(reviewLinkUrl);
                  this.shareLinkRef.current.select();
                  track(MODAL_BUTTON_CLICKED_EVENT, { title: 'copy_link' });
                }}
              />
            </Flex>
          ) : (
            <React.Fragment>
              <UserSearch
                className="Settings__UserSearch"
                strategies={[searchUsersStrategy(accountId, projectId)]}
                name="userTokens"
                newUserPrompt={(user) => `Share this link with ${user}`}
                placeholderText="Enter names or emails"
                setTokens={(tokens) => setFieldValue('userTokens', tokens)}
                tokens={userTokens}
              />
              <AnimateHeight
                isVisible={userTokens.length > 0}
                duration={200}
                easing="cubic-bezier(0.25, 0, 0, 1)"
              >
                <ShareEmailControls>
                  {showEmailMessage ? (
                    <StyledMessageTextArea
                      autoFocus
                      name="emailMessage"
                      onBlur={() => {
                        if (!emailMessage)
                          setFieldValue('showEmailMessage', !showEmailMessage);
                      }}
                      onChange={handleChange}
                      rows="1"
                      value={emailMessage}
                      maxLength={ENTERPRISE_MAX_MESSAGE_LENGTH_FOR_REVIEW_LINKS}
                    />
                  ) : (
                    <AddEmailMessage
                      name="showEmailMessage"
                      onClick={() => {
                        setFieldValue('showEmailMessage', !showEmailMessage);
                        track(MODAL_BUTTON_CLICKED_EVENT, {
                          title: 'add_a_message',
                        });
                      }}
                      type="button"
                    />
                  )}
                  <Button
                    onClick={() => {
                      this.sendEmails();
                      track(MODAL_BUTTON_CLICKED_EVENT, { title: 'send' });
                    }}
                    primary
                    type="button"
                  >
                    Send
                  </Button>
                </ShareEmailControls>
              </AnimateHeight>
            </React.Fragment>
          )}
        </Section>
      </React.Fragment>
    );
  }
}

SettingsForm.propTypes = {
  accountId: PropTypes.string.isRequired,
  canToggleComments: PropTypes.bool.isRequired,
  copyShortUrlToClipboard: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  itemIds: PropTypes.array.isRequired,
  projectId: PropTypes.string.isRequired,
  reviewLinkUrl: PropTypes.string.isRequired,
  sendReviewLinkEmails: PropTypes.func.isRequired,

  // Provided by Formik connect
  formik: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    values: PropTypes.shape({
      allowApprovals: PropTypes.bool.isRequired,
      allowComments: PropTypes.bool.isRequired,
      allowDownloads: PropTypes.bool.isRequired,
      emailMessage: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      selectedShareTab: PropTypes.string.isRequired,
      showAdvancedSettings: PropTypes.bool.isRequired,
      showAllVersions: PropTypes.bool.isRequired,
      showEmailMessage: PropTypes.bool.isRequired,
      userTokens: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export default connect(SettingsForm);

export const testExports = {
  AddEmailMessage,
  AdvancedSettingsToggle,
  AdvancedSettings,
  Heading,
  ShareAsLinkText,
  ShareEmailControls,
  StyledMessageTextArea,
  TitleLabel,
  TitleTextInput,
  MODAL_BUTTON_CLICKED_EVENT,
  ShareAsLinkButton,
};
