import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';

import { formatLongDateTime } from '@frameio/components/src/utils/datetimeHelpers';
import { antialiasedAvenirHeavy } from '@frameio/components/src/mixins';
import { CARD_CHIN_HEIGHT } from '@frameio/components/src/styled-components/Card';

import FolderCard from 'components/FolderCard';
import FolderSize from 'components/FolderSize';

const INFO_PANEL_FONT_STYLE = css`
  font-size: 16px;
  line-height: 22px;
`;

const INFO_PANEL_TITLE_FONT_STYLE = css`
  font-size: 22px;
  line-height: 30px;
`;

export const Panel = styled(FlexItem)`
  ${INFO_PANEL_FONT_STYLE}
  color: white;

  h2 {
    ${INFO_PANEL_TITLE_FONT_STYLE}
    overflow-wrap: break-word;
  }

  h2,
  strong {
    ${antialiasedAvenirHeavy()};
  }

  h2,
  p {
    margin-bottom: 1em;
  }
`;

export const Container = styled(Flex)`
  padding: 50px;
  width: 720px;
  max-width: 100%;

  ${Panel} + ${Panel} {
    ${(props) =>
      !props.column &&
      `
      margin-left: 50px;
      overflow: hidden;
    `}
  }
`;

const MAX_THUMB_WIDTH = 268;
const MAX_THUMB_HEIGHT = 155;
const MAX_CARD_HEIGHT = MAX_THUMB_HEIGHT + CARD_CHIN_HEIGHT;
const CARD_SCALE = 1.25;

export const PREVIEW_HEIGHT = MAX_THUMB_HEIGHT * CARD_SCALE;
export const PREVIEW_WIDTH = MAX_THUMB_WIDTH * CARD_SCALE;
export const CARD_HEIGHT = MAX_CARD_HEIGHT * CARD_SCALE;

export default function QuicklookFolder({ assetId, asset, creator }) {
  return (
    <Container>
      <Panel grow={1} shrink={1}>
        <FolderCard
          assetId={assetId}
          thumbWidth={PREVIEW_WIDTH}
          thumbHeight={PREVIEW_HEIGHT}
          height={CARD_HEIGHT}
          hasContextMenu={false}
        />
      </Panel>
      <Panel grow={1} shrink={1} basis="50%">
        <h2>{asset.name}</h2>
        <FolderSize folderId={assetId} />
        <p>Last modified {formatLongDateTime(asset.updated_at)}</p>
        <p>
          Created by <strong>{creator.name}</strong>
          <br />
          {formatLongDateTime(asset.inserted_at)}
        </p>
      </Panel>
    </Container>
  );
}

QuicklookFolder.propTypes = {
  assetId: PropTypes.string.isRequired,
  asset: PropTypes.shape({
    name: PropTypes.string,
    item_count: PropTypes.number,
    inserted_at: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
  creator: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};
