import styled from 'styled-components';
import PlusIcon from '@frameio/components/src/svgs/icons/plus.svg';

const PlaceholderPlus = styled(PlusIcon).attrs(({ theme }) => ({
  width: 14,
  height: 14,
  color: theme.color.white,
}))``;

export default PlaceholderPlus;
