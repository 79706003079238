import React from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Switch } from 'react-router-dom';
import SelectPlanFlow from 'components/SelectPlanFlow';
import history from './history';
import OutOfUploads from './OutOfUploads';

export const OUT_OF_UPLOADS_URL = '/OutOfUploads';
export const SELF_SERVE_UPGRADE_PLAN_URL = '/SelfServeUpgradePlanFlow';
export const REQUEST_ADMIN_TO_UPGRADE_URL = '/RequestAdminToUpgrade';

export default class LifetimeFileLimitReachedFlow extends React.Component {
  componentDidMount() {
    const { startFlow, accountId } = this.props;
    startFlow(accountId);
  }

  componentWillUnmount() {
    this.props.endFlow();
  }

  render() {
    const { accountId, closeModal } = this.props;

    return (
      <Router history={history}>
        <Switch>
          <Route
            path={OUT_OF_UPLOADS_URL}
            render={() => <OutOfUploads accountId={accountId} />}
          />
          <Route
            path={SELF_SERVE_UPGRADE_PLAN_URL}
            render={() => (
              <SelectPlanFlow accountId={accountId} onComplete={closeModal} />
            )}
          />
        </Switch>
      </Router>
    );
  }
}

LifetimeFileLimitReachedFlow.propTypes = {
  accountId: PropTypes.string.isRequired,

  closeModal: PropTypes.func.isRequired,
  startFlow: PropTypes.func.isRequired,
  endFlow: PropTypes.func.isRequired,
};
