import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { MEDIUM_UP } from 'utils/mediaQueries';
import Media from 'react-media';
import transitionFactory from 'react-transition-factory';
import CancelIcon from '@frameio/components/src/svgs/icons/cancel-small.svg';
import User from 'components/PeopleList/User';
import PendingCollaborator from 'components/PeopleList/PendingCollaborator';
import RolePopover from './RolePopover';
import {
  trackClick,
  page as trackingPage,
  position as trackingPosition,
} from '../../tracking';

import {
  RemoveButton,
  MobileRemoveButton,
  Wrapper,
  StyledRemovePopover,
} from './sharedStyles';

const SwipeTransition = transitionFactory('width');

class ManagePerson extends React.Component {
  state = {
    showMobileRemoveButton: false,
  };

  render() {
    const {
      canInviteCollaborators,
      canRemoveCollaborators,
      canChangeUserProjectRole,
      collaborator,
      pendingCollaborator,
      addToTeam,
      resendInvite,
      removeCollaborator,
      removePendingCollaborator,
      resendInviteStatus,
      ...rest
    } = this.props;

    const { id: collaboratorId, user } = collaborator || {};
    const { id: pendingCollaboratorId, email } = pendingCollaborator || user;
    const isTeamMember = get(user, 'project_role.team_member', false);
    const { showMobileRemoveButton } = this.state;
    const removeButton = (
      <RemoveButton
        onClick={() => {
          this.setState({ showMobileRemoveButton: true });
          trackClick('remove_existing_project_member');
        }}
      >
        <CancelIcon width={16} height={16} />
      </RemoveButton>
    );

    const onRemove = () => {
      if (pendingCollaborator) {
        removePendingCollaborator(pendingCollaboratorId, email);
      } else {
        removeCollaborator(collaboratorId, user.name);
      }
      trackClick('confirm_remove_existing_project_member');
    };

    return (
      <Wrapper
        {...rest}
        onClick={() =>
          showMobileRemoveButton &&
          this.setState({ showMobileRemoveButton: false })
        }
      >
        {user ? (
          <User
            email={user.email}
            image={user.image_64}
            color={user.user_default_color}
            name={user.name}
            lastActivityDate={user.last_seen}
          />
        ) : (
          <PendingCollaborator
            resendInvite={canInviteCollaborators ? resendInvite : undefined}
            pendingCollaborator={pendingCollaborator}
            resendInviteStatus={resendInviteStatus}
            trackingPage={trackingPage}
            trackingPosition={trackingPosition}
          />
        )}

        <Media query={MEDIUM_UP} data-test-id="media-query-role">
          {(isMediumUp) =>
            isMediumUp && (
              <RolePopover
                isTeamMember={isTeamMember}
                isPendingCollaborator={!!pendingCollaborator && !collaborator}
                onAddToTeam={() => user && addToTeam(user.id, email)}
                disabled={!canChangeUserProjectRole}
              />
            )
          }
        </Media>

        {canRemoveCollaborators && (
          <Media query={MEDIUM_UP} data-test-id="media-query-remove">
            {(isMediumUp) =>
              isMediumUp ? (
                <StyledRemovePopover
                  onCancel={() =>
                    trackClick('cancel_remove_existing_project_member')
                  }
                  onConfirm={onRemove}
                  title="Remove from project?"
                  confirmButtonText="Remove"
                  position="right"
                >
                  {removeButton}
                </StyledRemovePopover>
              ) : (
                <React.Fragment>
                  {removeButton}
                  <SwipeTransition
                    in={showMobileRemoveButton}
                    start={0}
                    end={80}
                    timeout={150}
                  >
                    <MobileRemoveButton onClick={onRemove}>
                      Remove
                    </MobileRemoveButton>
                  </SwipeTransition>
                </React.Fragment>
              )
            }
          </Media>
        )}
      </Wrapper>
    );
  }
}

ManagePerson.propTypes = {
  addToTeam: PropTypes.func,
  canChangeUserProjectRole: PropTypes.bool,
  canInviteCollaborators: PropTypes.bool,
  canRemoveCollaborators: PropTypes.bool,
  collaborator: PropTypes.shape({
    id: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      project_role: PropTypes.shape({ team_member: PropTypes.bool.isRequired }),
    }),
  }),
  pendingCollaborator: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    project_id: PropTypes.string.isRequired,
  }),
  removeCollaborator: PropTypes.func.isRequired,
  removeCollaboratorFromProject: PropTypes.func,
  removePendingCollaborator: PropTypes.func.isRequired,
  resendInviteStatus: PropTypes.string,
};

ManagePerson.defaultProps = {
  canChangeUserProjectRole: false,
  canInviteCollaborators: false,
  canRemoveCollaborators: false,
  removeCollaboratorFromProject: () => {},
  resendInviteStatus: undefined,
  user: User.defaultProps,
};

export const testExports = {
  StyledRemovePopover,
  RemoveButton,
  MobileRemoveButton,
};
export default ManagePerson;
