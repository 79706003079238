import { connect } from 'react-redux';
import queryString from 'query-string';

import { receiveVimeoOAuthResponse as receiveVimeoOAuthResponseAction } from 'components/PublishToVimeo/actions';
import { receiveSlackOAuthResponse as receiveSlackOAuthResponseAction } from 'components/SlackIntegration/actions';

function getOAuthResponseFromQuery(search) {
  const response = queryString.parse(search);
  return {
    ...response,
    state: JSON.parse(response.state),
  };
}

function OAuth({
  location: { search },
  match: {
    params: { provider },
  },
  receiveSlackOAuthResponse,
  receiveVimeoOAuthResponse,
}) {
  const { code, state } = getOAuthResponseFromQuery(search);
  switch (provider) {
    case 'vimeo':
      receiveVimeoOAuthResponse(code, state);
      break;
    case 'slack':
      receiveSlackOAuthResponse(code, state);
      break;
    default:
      return null;
  }
  return null;
}

const mapDispatchToProps = {
  receiveSlackOAuthResponse: receiveSlackOAuthResponseAction,
  receiveVimeoOAuthResponse: receiveVimeoOAuthResponseAction,
};

export default connect(
  null,
  mapDispatchToProps
)(OAuth);

export const testExports = { OAuth };
