import { Modal } from '@frameio/components';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { DIALOG_PORTAL_ROOT_ID } from './DialogPortalRoot';

const DialogModal = ({
  portalRootId,
  isOpen,
  onCancel,
  canBackdropCancel = false,
  children,
  ...rest
}) => {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    const idReady = portalRootId && document.getElementById(portalRootId);
    if (idReady) {
      setIsReady(true);
    }
  }, [portalRootId]);
  const shouldOpen = portalRootId ? isOpen && isReady : isOpen;
  return (
    shouldOpen &&
    ReactDOM.createPortal(
      <Modal
        isOpen={isOpen}
        onClose={onCancel}
        closeOnEsc={!!onCancel}
        canBackdropClose={canBackdropCancel}
        {...rest}
      >
        {children}
      </Modal>,
      document.getElementById(portalRootId) || document.body
    )
  );
};

DialogModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  children: PropTypes.node.isRequired,
};

DialogModal.defaultProps = {
  onCancel: null,
  portalRootId: DIALOG_PORTAL_ROOT_ID,
};

export default DialogModal;
