import { connect } from 'react-redux';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import FolderSize from './FolderSize';

function mapStateToProps(state, { folderId }) {
  return {
    folder: assetEntitySelector(state, { assetId: folderId }),
  };
}

export default connect(mapStateToProps)(FolderSize);

export const testExports = {
  mapStateToProps,
};
