import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { CardExpiryElement as CardExpiryStripeElement } from '@stripe/react-stripe-js';
import Calendar from '@frameio/components/src/svgs/icons/calendar.svg';
import Container from '../shared/Container';
import IconContainer from '../shared/IconContainer';
import ErrorMessage from '../shared/ErrorMessage';
import { BASE_STYLES, stripeElementStyle } from '../styles';

const CardExpiryElement = styled(CardExpiryStripeElement)`
  ${({ theme, hasError, focused }) =>
    stripeElementStyle(theme, hasError, focused)}
`;

const CardExpirationElement = ({
  error,
  setFieldValue,
  setFieldTouched,
  touched,
  isEmpty,
}) => {
  const [focused, setFocused] = useState(false);
  const hasError = error?.code || (touched && isEmpty);
  const showError = !focused && hasError;
  return (
    <>
      <Container>
        <IconContainer>
          <Calendar height={12} width={12} />
        </IconContainer>
        <CardExpiryElement
          name="cardExpiry"
          onBlur={() => {
            setFieldTouched('cardExpiry', true);
            setFocused(false);
          }}
          onFocus={() => setFocused(true)}
          onChange={(values) => {
            setFieldValue('cardExpiry', values, false);
          }}
          options={BASE_STYLES}
          hasError={hasError}
          focused={focused}
        />
      </Container>
      <ErrorMessage isVisible={!!showError} marginTop>
        Invalid expiration
      </ErrorMessage>
    </>
  );
};

export default memo(CardExpirationElement);
