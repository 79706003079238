/**
 *
 * @param {Object} state
 * @returns {String} - the repository id of the selected aem destination
 */

export const selectedAemDestinationSelector = (state) => {
  return state?.aemRepositoryTrees?.selectedAemDestination;
};

/**
 *
 * @param {Object} state
 * @returns {String} - the folder id of the selected aem destination
 */

export const selectedAemFolderSelector = (state) => {
  return state?.aemRepositoryTrees?.selectedAemDestination?.aemFolderId;
};

/**
 * Grabs folder(s) for selected aem repository.
 *
 * @param {Object} state - Redux store state.
 * @param {String} aemRepositoryId - aem repository id.
 * @returns {Array<Object>} - aem repository folder structure.
 */
export const aemFoldersByRepositoryIdSelector = (state, aemRepositoryId) => {
  if (aemRepositoryId) {
    const aemFolder = state?.aemRepositoryTrees?.aemRepositories;

    return aemFolder[aemRepositoryId];
  }
  return null;
};

/**
 * Grabs the loading state of the aem repository tree
 *
 * @param {Object} state - Redux store state.
 * @returns {Boolean} - true/false if the aem repository trees are loading.
 */
export const isFetchingAemRepositoryTreeSelector = (state) => {
  return state?.aemRepositoryTrees?.isLoading;
};
