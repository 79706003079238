import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Flex from 'styled-flex-component';
import CheckmarkIcon from '@frameio/components/src/svgs/icons/16/checkmark.svg';
import Button from '@frameio/components/src/styled-components/Button';
import track from 'analytics';
import wavesBg from 'images/wavesbg.png';

const Container = styled.div`
  width: ${(p) => p.theme.spacing.units(50)};
  height: auto;
`;

const HeaderContainer = styled.h2`
  align-items: center;
  background-image: url(${wavesBg});
  background-color: ${(p) => p.theme.color.almostBlack};
  background-position-x: center;
  background-position-y: 75%;
  background-size: cover;
  color: ${(p) => p.theme.color.white};
  display: flex;
  height: 180px;
  max-width: ${(p) => p.theme.spacing.units(50)};
  padding: 0 ${(p) => p.theme.spacing.units(7)};
  text-align: center;
  ${(p) => p.theme.fontStyle.headerM}
`;

const BodyHeader = styled.p`
  ${(p) => p.theme.fontStyle.body};
  padding: ${(p) => p.theme.spacing.medium};
  padding-bottom: ${(p) => p.theme.spacing.units(2)};

  em {
    font-style: normal;
    font-weight: ${(p) => p.theme.fontWeight.bold};
  }
`;

const FeatureContainer = styled.div`
  padding: 0 ${(p) => p.theme.spacing.medium};
  margin-bottom: ${(p) => p.theme.spacing.tiny};
`;

const FeatureList = styled.ul`
  width: auto;
  list-style: none;
  font-size: 12px;
  margin-bottom: ${(p) => p.theme.spacing.tiny};
`;

const FeatureLine = styled.li`
  ${(p) => p.theme.fontStyle.body};
  color: ${({ theme }) => theme.color.coolBlack};
  line-height: 28px;
  width: auto;
  display: block;
`;

const FeatureFootnote = styled.p`
  ${(p) => p.theme.fontStyle.body};
  color: ${({ theme }) => theme.color.graphiteGray};
`;

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  width: 12px;
  height: 12px;
  color: ${(p) => p.theme.color.brand};
  margin-right: ${(p) => p.theme.spacing.tiny};
`;

const FooterContainer = styled(Flex).attrs(() => ({ justifyEnd: true }))`
  padding: ${(p) => p.theme.spacing.medium};

  ${Button} {
    margin-left: ${(p) => p.theme.spacing.tiny};

    &:focus:not(:active):not(:hover) {
      box-shadow: inset 0 0 0 1px ${(p) => p.theme.color.lightGray};
    }
  }
`;

const Contact = styled.p`
  ${(p) => p.theme.fontStyle.body};
  margin-top: ${(p) => p.theme.spacing.units(2)};

  a {
    color: ${(p) => p.theme.color.brand};
  }
`;

function renderFeatures(planFeatures) {
  return planFeatures.map((feature) => {
    return (
      <FeatureLine key={feature}>
        <Flex alignCenter>
          <StyledCheckmarkIcon />
          {feature}
        </Flex>
      </FeatureLine>
    );
  });
}

function HardBlock({
  limitCopy,
  upgradePlanName,
  upgradePlanFeatures,
  closeModal,
  upgradePlan,
  limitType,
  isAdmin,
  mailToLink,
  chatWithOurTeam,
}) {
  function renderPrimaryButton() {
    const isDriftInitialized = window.drift;
    if (!isDriftInitialized && upgradePlanName === 'Enterprise') {
      return (
        <Button
          primary
          forwardedAs="a"
          href="mailto:sales@frame.io?subject=Frame.io Enterprise"
        >
          Contact sales
        </Button>
      );
    }
    return upgradePlanName === 'Enterprise' ? (
      <Button
        primary
        onClick={() => {
          chatWithOurTeam();
          closeModal();
        }}
      >
        Chat with sales
      </Button>
    ) : (
      <Button primary onClick={() => upgradePlan(limitType)}>
        View plans
      </Button>
    );
  }

  return (
    <>
      <HeaderContainer>You've reached your {limitCopy} limit.</HeaderContainer>
      <Container>
        <div>
          <BodyHeader>
            Upgrade your plan to create additional {limitType}.
            <br />
            <em>The {upgradePlanName} plan includes:</em>
          </BodyHeader>
          <FeatureContainer>
            <FeatureList>{renderFeatures(upgradePlanFeatures)}</FeatureList>
            <FeatureFootnote>...and other premium features!</FeatureFootnote>
            {!isAdmin && (
              <Contact>
                <Button
                  isLink
                  as="a"
                  href={mailToLink}
                  onClick={() =>
                    track('button-clicked-contact-owner-client', {
                      _limit: limitType,
                    })
                  }
                >
                  Contact your account owner
                </Button>{' '}
                about an upgrade.
              </Contact>
            )}
          </FeatureContainer>
        </div>
        <FooterContainer>
          {isAdmin ? (
            <>
              <Button onClick={closeModal}>Keep current plan</Button>
              {renderPrimaryButton()}
            </>
          ) : (
            <Button primary onClick={closeModal}>
              Got it
            </Button>
          )}
        </FooterContainer>
      </Container>
    </>
  );
}

HardBlock.defaultProps = {
  limitCopy: '',
  planName: '',
  planFeatures: [],
  closeModal: noop,
  upgradePlan: noop,
  limitType: '',
  isAdmin: false,
  mailToLink: '',
};

HardBlock.propTypes = {
  limitCopy: PropTypes.string.isRequired,
  planName: PropTypes.string.isRequired,
  planFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  closeModal: PropTypes.func.isRequired,
  upgradePlan: PropTypes.func.isRequired,
  limitType: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  mailToLink: PropTypes.string.isRequired,
};

export const testExports = {
  Contact,
  FeatureLine,
};

export default HardBlock;
