import React from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Switch } from 'react-router-dom';
import PaymentFlow from 'components/PaymentFlow';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import {
  ManageStorageModal,
  ManageArchivalStorageModal,
} from 'components/ManageSubscription';
import {
  STORAGE_LIMIT,
  ARCHIVED_STORAGE_LIMIT,
} from '@frameio/core/src/subscriptionLineItems/utils/modifierTypes';
import history from './history';
import ConfirmStorageAdded from './ConfirmStorageAdded';

export const ADD_STORAGE_URL = '/AddStorage';
export const PAYMENT_FLOW_URL = '/PaymentFlow';
export const CONFIRM_STORAGE_ADDED_URL = '/ConfirmStorageAdded';

export default class AddStorageFlow extends React.Component {
  componentDidMount() {
    const { accountId, startFlow, trackingProps, type } = this.props;

    startFlow(accountId, type, trackingProps);
  }

  componentWillUnmount() {
    this.props.endFlow();
  }

  render() {
    const {
      accountId,
      closeModal,
      continueFlow,
      trackingProps,
      showTotalLineItem,
      type,
    } = this.props;
    return (
      <React.Fragment>
        <ModalCloseButton onClick={closeModal} />
        <Router history={history}>
          <Switch>
            <Route
              path={ADD_STORAGE_URL}
              render={() =>
                type === STORAGE_LIMIT ? (
                  <ManageStorageModal
                    accountId={accountId}
                    onCancel={closeModal}
                    continueFlow={continueFlow}
                    showTotalLineItem={showTotalLineItem}
                  />
                ) : (
                  <ManageArchivalStorageModal
                    accountId={accountId}
                    onCancel={closeModal}
                    continueFlow={continueFlow}
                    showTotalLineItem={showTotalLineItem}
                  />
                )
              }
            />
            <Route
              path={PAYMENT_FLOW_URL}
              render={(props) => (
                <PaymentFlow
                  accountId={accountId}
                  onCancel={closeModal}
                  trackingProps={trackingProps}
                  {...props}
                />
              )}
            />
            <Route
              path={CONFIRM_STORAGE_ADDED_URL}
              render={(props) => (
                <ConfirmStorageAdded
                  accountId={accountId}
                  onDoneClick={closeModal}
                  {...props}
                />
              )}
            />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

AddStorageFlow.propTypes = {
  accountId: PropTypes.string,
  closeModal: PropTypes.func,
  continueFlow: PropTypes.func,
  startFlow: PropTypes.func,
  endFlow: PropTypes.func,
  trackingProps: PropTypes.object,
  showTotalLineItem: PropTypes.bool,
  type: PropTypes.oneOf([STORAGE_LIMIT, ARCHIVED_STORAGE_LIMIT]),
};

AddStorageFlow.defaultProps = {
  accountId: undefined,
  closeModal: undefined,
  continueFlow: undefined,
  startFlow: undefined,
  endFlow: undefined,
  trackingProps: {},
  showTotalLineItem: true,
  type: STORAGE_LIMIT,
};

export const testExports = {};
