import { connect } from 'react-redux';
import {
  areAssetsSingleEmptyFolder,
  containsMultipleAssetsSelector,
  canAssetsBeSharedAsLegacyPresentationSelector,
} from '@frameio/core/src/assets/selectors';

import { isAccountEnterpriseSelector } from 'selectors/accounts';
import {
  legacyOpenPresentationEditor,
  selectPresentationAssets,
} from 'components/PresentationEditor/actions';
import { showBlockedCollaboratorMessage } from 'components/RequestToJoinTeamCard/actions';
import { permittedActionsForAssetsSelector } from 'selectors/permissions';
import ShareAssetsAsPresentation from './ShareAssetsAsPresentation';

function mapStateToProps(state, { assetIds, folderId, projectId }) {
  const assetIdsWithFolderIdFallback = assetIds.length ? assetIds : [folderId];
  const props = { assetIds: assetIdsWithFolderIdFallback };
  const {
    canSharePresentation: hasPermission,
  } = permittedActionsForAssetsSelector(state, { ...props, projectId });
  return {
    assetId: assetIdsWithFolderIdFallback[0],
    canAssetsBeShared: canAssetsBeSharedAsLegacyPresentationSelector(
      state,
      props
    ),
    hasPermission,
    isEmptyFolder: areAssetsSingleEmptyFolder(state, props),
    isEnterprise: isAccountEnterpriseSelector(state),
    isMultipleAssets: containsMultipleAssetsSelector(state, props),
  };
}

const mapDispatchToProps = {
  legacyOpenPresentationEditor,
  selectPresentationAssets,
  showBlockedCollaboratorMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareAssetsAsPresentation);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
