import { takeEvery, put, select, call, delay } from 'redux-saga/effects';
import track from 'analytics';
import { deleteCard } from '@frameio/core/src/cards/sagas';
import { firstCardEntityByAccountIdSelector } from '@frameio/core/src/cards/selectors';
import { currentAccountSelector } from 'selectors/accounts';
import { closeModal, updateModal } from 'components/Modal/actions';
import updateStripeCustomer from 'components/CreditCardFormElements/sagas';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import { getCardsByAccount } from '@frameio/core/src/cards/actions';
import { getSubscriptionByAccount } from '@frameio/core/src/subscriptions/sagas';
import { subscriptionEntityByAccountIdSelector } from 'selectors/subscriptions';
import { ADD_CREDIT_CARD, isFetching } from './actions';

let attempt = 0;
const MAX_RETRIES = 5;

function* waitForSubscriptionBalanceSync(accountId) {
  const subscription = yield select(
    subscriptionEntityByAccountIdSelector,
    accountId
  );
  const balance = subscription?.balance;

  // When an account is updating its payment info to pay its overdue balance,
  // we need to wait for Stripe to update the subscription's balance and send
  // the webhook to Massdriver.
  if (balance === 0 || attempt > MAX_RETRIES) {
    attempt = 0;
    return;
  }
  if (attempt <= MAX_RETRIES) {
    // Wait for a bit before refetching subscription's data again
    yield delay(2000);
    yield call(getSubscriptionByAccount, accountId);
    attempt += 1;
  }
  yield call(waitForSubscriptionBalanceSync, accountId);
}

export function* addCreditCard(stripeResponse, source) {
  const token = stripeResponse?.token;
  yield put(isFetching(true));
  yield put(updateModal({ canCloseModal: false }));
  const account = yield select(currentAccountSelector);
  const accountId = account?.id;

  // `AddCreditCard` is used when adding/updating a card in Account Settings and when
  // updating the card in `AccountOverdueBanner`.
  // When used in `AccountOverdueBanner`, we need to refetch the subscription
  // after a successful update so we get the updated subscription's balance
  // which will make the banner disappear.
  const shouldRefetchSubscription = source === 'accountOverdueBanner';

  const { success } = yield call(updateStripeCustomer, accountId, token?.id);
  if (success) {
    track('payment-method-updated-client', { source: 'billing page' });
    const card = yield select(firstCardEntityByAccountIdSelector, {
      accountId,
    });
    if (card) {
      yield call(deleteCard, card.id);
    }
    // Cards need to be re-fetched because cards are not stored in the API db.
    // For security reasons, they are only stored on Stripe.
    yield put(getCardsByAccount(accountId));
    if (shouldRefetchSubscription) {
      yield call(waitForSubscriptionBalanceSync, accountId);
    }
    yield put(closeModal());
    yield put(
      showSuccessToast({
        header: 'Card successfully updated!',
      })
    );
  } else {
    yield put(
      showErrorToast({
        header: 'Card did not update. Please try again',
      })
    );
    yield put(updateModal({ canCloseModal: true }));
  }
  yield put(isFetching(false));
}

export default [
  takeEvery(ADD_CREDIT_CARD.ADD, ({ payload: { stripeResponse, source } }) =>
    addCreditCard(stripeResponse, source)
  ),
];
