import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import PropTypes from 'prop-types';
import VideoSVG from '@frameio/components/src/svgs/icons/24/video-camcorder.svg';
import { ModalFooter } from '@frameio/components/src/styled-components/Modal/ModalContent';
import { FadeTransition } from 'react-transition-components';

// the ratio between the box and the image
const IMAGE_MULTIPLIER = 0.82;
const EASE_OUT = 'cubic-bezier(0.17, 0.17, 0, 1)';

export const Wrapper = styled.div`
  ${ModalFooter} {
    padding: ${(p) => `
      ${p.theme.spacing.micro}
      ${p.theme.spacing.medium}
      ${p.theme.spacing.medium}
    `};
  }
`;

export const Head = styled(Flex).attrs({
  justifyBetween: true,
})`
  padding: ${(p) => `
    ${p.theme.spacing.medium}
    ${p.theme.spacing.medium}
    ${p.theme.spacing.units(2)}
  `};
  border-bottom: 1px solid ${(p) => p.theme.color.coldWhite};
`;

const Box = styled(Flex).attrs({
  center: true,
})`
  background-color: ${(p) => p.theme.color.coldWhite};
  color: ${(p) => p.theme.color.graphiteGray};
  border-radius: ${(p) =>
    p.compact ? p.theme.radius.medium : p.theme.radius.default};
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
`;

const VideoIcon = styled(VideoSVG)`
  position: absolute;
`;

const ThumbImage = styled.img`
  max-width: ${(p) => Math.round(p.size * IMAGE_MULTIPLIER)}px;
  max-height: ${(p) => Math.round(p.size * IMAGE_MULTIPLIER)}px;
  position: relative;
  overflow: hidden;
  border-radius: ${(p) => p.theme.radius.medium};
  box-shadow: 0 0 20px 0 ${(p) => rgba(p.theme.color.graphiteGray, 0.4)};
`;

export function ThumbBox({ compact, thumb, name, ...rest }) {
  const iconSize = compact ? 24 : 32;
  const size = compact ? 70 : 220;
  return (
    <Box compact size={size} {...rest}>
      <VideoIcon width={iconSize} height={iconSize} />
      <FadeTransition in={!!thumb} easing={EASE_OUT} timeout={1000}>
        <ThumbImage src={thumb} alt={name} size={size} />
      </FadeTransition>
    </Box>
  );
}

ThumbBox.propTypes = {
  name: PropTypes.string.isRequired,
  thumb: PropTypes.string,
  compact: PropTypes.bool,
};

ThumbBox.defaultProps = {
  thumb: undefined,
  compact: false,
};

export const testExports = {
  ThumbImage,
  VideoIcon,
};
