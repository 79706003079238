import { get } from 'lodash';
import createCachedSelector from 're-reselect';
import {
  accountEntityForProjectIdSelector,
  teamEntityForProjectIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import {
  featureFlagsForAccountIdSelector,
  featureFlagByAccountEntitiesSelector,
} from '@frameio/core/src/featureFlags/selectors';
import {
  isAccountAdminSelector,
  isCollaboratorOnlySelector,
  isTeamManagerSelector,
  isTeamMemberSelector,
  isReviewerHighestRoleSelector,
} from 'selectors/roles';
import {
  isProjectArchivedSelector,
  isProjectUsingDRMSelector,
} from 'selectors/projects';
import {
  accountSettingsPermissionChecksEnabled,
  externalSharingEnabled,
  restrictedDownloadOptionsEnabled,
} from 'utils/featureFlags';
import permittedActionsForAccountSelector from './permittedActionsForAccountSelector';

function getCacheKey(state, { projectId }) {
  const account = accountEntityForProjectIdSelector(state, { projectId });

  // Bust cache after account feature flags are fetched. This assumes that we'll
  // always have at least one account feature flag.
  let areFlagsFetched = false;
  if (account) {
    const { id: accountId } = account;
    const flags = featureFlagsForAccountIdSelector(state, { accountId });
    if (flags && Object.keys(flags)) {
      areFlagsFetched = true;
    }
  }
  return `${projectId}-${areFlagsFetched}`;
}

/**
 * Returns a map of actions the user can take on a project.
 *
 * @param {Object} state - Redux state tree.
 * @param {Object} projectId - The project id.
 */
const permittedActionsForProjectSelector = createCachedSelector(
  projectEntitySelector,
  accountEntityForProjectIdSelector,
  isProjectArchivedSelector,
  isProjectUsingDRMSelector,
  (state, { projectId }) => projectId,
  (state) => state,
  (
    project = {},
    account,
    isProjectArchived,
    isProjectUsingDRM,
    projectId,
    state
  ) => {
    // TODO: Update accountEntityForProjectIdSelector in web-core so that it returns
    // undefined instead of null when a team entity cannot yet be derived; This will
    // allow us to set a default parameter for account at various callsites in web-client.
    const accountId = (account || {}).id;

    const team = teamEntityForProjectIdSelector(state, { projectId });
    const isAdmin = isAccountAdminSelector(state, { accountId });
    const isTeamMember = isTeamMemberSelector(state, { projectId });
    const isTeamManager = isTeamManagerSelector(state, { projectId });
    const isCollaboratorOnly = isCollaboratorOnlySelector(state, { projectId });
    const isReviewer = isReviewerHighestRoleSelector(state, { accountId });

    // `permittedActionsForAccountSelector` gracefully handles invalid account
    // ids by returning a bunch of `false` perms
    const {
      canAdminsAccessCleanAssets,
      canCollaboratorsAccessCleanAssets,
      canTeamManagersAccessCleanAssets,
      canTeamMembersAccessCleanAssets,
      canCollaboratorsDownloadFromProjectGlobal,
      canInviteCollaborators,
      canSharePresentation,
      canTeamMembersInvite,
      canToggleInviteCollaborators,
      canToggleSharePresentations,
      canToggleTeamOnlyComments,
      canUseArchivalStorage,
      canUseTeamOnlyComments,
      canUsePrivateProjects,
      c2cConnectionsFeatureEnabled,
      disableTeamMemberInvitesFeatureEnabled,
      isForensicFallbackEnabled,
      isForensicWatermarkingRequiredInternally,
      isSessionBasedWatermarkingRequiredInternally,
    } = permittedActionsForAccountSelector(state, { accountId });

    const canAccessProject =
      (isTeamMember || isCollaboratorOnly) && !isReviewer;
    const canMention = canAccessProject || isReviewer;

    const {
      collaborator_can_share: canCollaboratorsShareInProject = false,
      collaborator_can_download: canCollaboratorsDownloadInProject = false,
      collaborator_can_invite: canCollaboratorsInviteInProject = false,
    } = get(project, 'project_preferences', {});

    // ENT-1762: Workaround race condition to avoid false positive return value.
    const accountFeatureFlags = featureFlagByAccountEntitiesSelector(state);
    const hasAccountFeatureFlags = Boolean(
      accountFeatureFlags && Object.keys(accountFeatureFlags).length
    );
    const isDownloadEnabled =
      hasAccountFeatureFlags && !restrictedDownloadOptionsEnabled();

    // ENT-1588: Team managers and Admins ought to always be able to download.
    const isUserDownloadAllowed =
      isDownloadEnabled && (isTeamMember || canCollaboratorsDownloadInProject);
    const canDownloadLegacy = isTeamManager || isAdmin || isUserDownloadAllowed;

    // If we are using the Permission Check feature flag, populate select perms with the values
    // that come from the backend. Otherwise, continue to use the values from the old implementation
    const readFromUserPermissions = accountSettingsPermissionChecksEnabled();

    // TODO(Scott: ENT-2694) We now split permissions into separate boolean values for Review Links and Presentations.
    // (Previously there were 3 permissions, and now there are 3*2=6 permissions)

    // Downstream, the consumers of this selector (ConnectedReviewLinkEditor and ConnectedSecureSidePanel) ask for the
    // correct value for their type of entity.

    // Once the user_permissions feature goes live to everyone, most of this code will get to go away. We will not need to use
    // the ?? operator on any value, and will not need to fall back, because we can be confident that these values exist
    const canSharePublicly = readFromUserPermissions
      ? project?.user_permissions?.can_public_share ?? false
      : Boolean(isAdmin || externalSharingEnabled(state));

    const canPublicSharePresentation = readFromUserPermissions
      ? project?.user_permissions?.can_public_share_presentation
      : canSharePublicly;

    const canPublicShareReviewLink = readFromUserPermissions
      ? project?.user_permissions?.can_public_share_review_link
      : canSharePublicly;

    const canShareUnwatermarked = readFromUserPermissions
      ? project?.user_permissions?.can_share_unwatermarked
      : true;

    const canShareUnwatermarkedPresentation = readFromUserPermissions
      ? project?.user_permissions?.can_share_unwatermarked_presentation
      : canShareUnwatermarked;

    const canShareUnwatermarkedReviewLink = readFromUserPermissions
      ? project?.user_permissions?.can_share_unwatermarked_review_link
      : canShareUnwatermarked;

    const canShareWithoutForensicWatermarkPresentation = readFromUserPermissions
      ? project?.user_permissions
          ?.can_share_without_forensic_watermark_presentation
      : true;

    const canShareWithoutForensicWatermarkReviewLink = readFromUserPermissions
      ? project?.user_permissions
          ?.can_share_without_forensic_watermark_review_link
      : true;

    const canSeeWatermarkingSettings = readFromUserPermissions
      ? project?.user_permissions?.can_see_watermarking_settings
      : true;

    const canShareWithoutDrmPresentation = readFromUserPermissions
      ? project?.user_permissions?.can_share_without_drm_presentation
      : false;

    const canShareWithoutDrmReviewLink = readFromUserPermissions
      ? project?.user_permissions?.can_share_without_drm_review_link
      : false;

    // ENT-2909
    // in each of these three use cases, we first check for the legacy check, and then fall back
    // to the 'modern' check that looks for user permissions or the generic can download check

    const canDownloadModern = readFromUserPermissions
      ? project?.user_permissions?.can_download
      : canDownloadLegacy;

    const canDownload = canDownloadLegacy === false ? false : canDownloadModern;

    // If Forensic or Session Based watermarking is toggled on for a team, we must reference the minimum roles
    // for downloading assets without a watermark (configured via the account settings).
    const sbwmEnabled = team ? !team.disable_sbwm_internally : false;
    const fwmEnabled = team ? !team.disable_fwm_internally : false;

    const canAccessCleanAssets =
      (isAdmin && canAdminsAccessCleanAssets(fwmEnabled, sbwmEnabled)) ||
      (isTeamManager &&
        canTeamManagersAccessCleanAssets(fwmEnabled, sbwmEnabled)) ||
      (isTeamMember &&
        canTeamMembersAccessCleanAssets(fwmEnabled, sbwmEnabled)) ||
      (isCollaboratorOnly &&
        canCollaboratorsAccessCleanAssets(fwmEnabled, sbwmEnabled)) ||
      false;

    const canDownloadOriginalCleanAsset =
      isSessionBasedWatermarkingRequiredInternally ||
      isForensicWatermarkingRequiredInternally
        ? canDownload && canAccessCleanAssets
        : canDownload;

    const canShareDownloadablePresentationModern = readFromUserPermissions
      ? project?.user_permissions?.can_share_downloadable_presentation
      : canDownloadLegacy;

    const canShareDownloadablePresentation =
      canDownloadLegacy === false
        ? false
        : canShareDownloadablePresentationModern;

    const canShareDownloadableReviewLinkModern = readFromUserPermissions
      ? project?.user_permissions?.can_share_downloadable_review_link
      : canDownloadLegacy;

    const canShareDownloadableReviewLink =
      canDownloadLegacy === false
        ? false
        : canShareDownloadableReviewLinkModern;

    const canCopyToProject = canDownload || isTeamMember;
    const canResetAssetLifecycle =
      !isProjectArchived &&
      isTeamMember &&
      !project.ignore_archive &&
      typeof (team || {}).asset_lifecycle_policy === 'number';

    const canToggleDownloads =
      canCollaboratorsDownloadFromProjectGlobal &&
      canDownload &&
      (isAdmin || isTeamManager || isTeamMember);

    const canUseC2CConnections = Boolean(
      c2cConnectionsFeatureEnabled &&
        project?.user_permissions?.can_manage_devices
    );

    const canInviteCollaboratorsInProject = () => {
      const canManage = isAdmin || isTeamManager;
      const canInvite =
        isTeamMember ||
        (canInviteCollaborators && canCollaboratorsInviteInProject);
      return disableTeamMemberInvitesFeatureEnabled
        ? canManage || (canTeamMembersInvite && canInvite)
        : canInvite;
    };

    return {
      // ⚠️ Let's keep this list alphabetized to make it easier to find a given permission
      canAccess: canAccessProject,
      canArchiveProject:
        isTeamMember && !isProjectArchived && canUseArchivalStorage,
      canChangeUserProjectRole: isAdmin || isTeamManager,
      canCopyToProject,
      canDelete: isTeamMember,
      canDeleteProject: isTeamMember,
      canDownload,
      canDownloadFolders: !isProjectArchived && canDownload,
      canDownloadOriginalCleanAsset,
      canInviteCollaborators: canInviteCollaboratorsInProject(),
      canManageVersions: isTeamMember,
      canMention,
      canMoveToFolder: !isProjectArchived,
      canPublicSharePresentation,
      canPublicShareReviewLink,
      canPublishToDropbox:
        !isProjectUsingDRM &&
        !isProjectArchived &&
        (isTeamMember || canCollaboratorsShareInProject),
      canPublishToVimeo:
        !isProjectUsingDRM &&
        !isProjectArchived &&
        (isTeamMember || canCollaboratorsShareInProject),
      canRemoveCollaborators: isTeamMember,
      canRename: isTeamMember,
      canResetAssetLifecycle,
      canRestoreDeletedAssets: isTeamMember,
      canSeeWatermarkingSettings,
      canSetDefaultTeamSessionWatermarkId: Boolean(isAdmin || isTeamManager),
      canShareDownloadablePresentation,
      canShareDownloadableReviewLink,
      canSharePresentation:
        isTeamMember ||
        (canSharePresentation && canCollaboratorsShareInProject),
      canSharePublicly,
      canShareReview: isTeamMember,
      canShareUnwatermarked,
      canShareUnwatermarkedPresentation,
      canShareUnwatermarkedReviewLink,
      canShareWithoutDrmPresentation,
      canShareWithoutDrmReviewLink,
      canShareWithoutForensicWatermarkPresentation,
      canShareWithoutForensicWatermarkReviewLink,
      canToggleDownloads,
      canToggleInviteCollaborators:
        canToggleInviteCollaborators && isTeamMember,
      canTogglePrivacy: isTeamMember,
      canToggleSharePresentations: canToggleSharePresentations && isTeamMember,
      canToggleTeamComments: isTeamMember && canToggleTeamOnlyComments,
      canUnarchiveProject:
        isTeamMember && isProjectArchived && canUseArchivalStorage,
      canUseC2CConnections,
      canUseTeamComments: isTeamMember && canUseTeamOnlyComments,
      canUsePrivateProjects,
      isForensicFallbackEnabled,
    };
  }
)(getCacheKey);

export default permittedActionsForProjectSelector;
