import { PROJECT } from '@frameio/core/src/projects/actions';
import { EDIT_PROJECT_FORM } from './actions';

export default function editProjectFormReducer(state = {}, action) {
  switch (action.type) {
    case EDIT_PROJECT_FORM.SET_PROJECT_ID:
      return {
        ...state,
        projectId: action.payload.projectId,
      };
    case PROJECT.UPDATE.PENDING: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case PROJECT.UPDATE.SUCCESS: {
      return {
        ...state,
        isFetching: false,
      };
    }
    default:
      return state;
  }
}

/**
 * @param   {Object} state - Redux store state.
 * @returns {Object} - Create project form's data.
 */
export const editProjectFormSelector = (state) => state.editProjectForm;
