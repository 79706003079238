import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { noop } from 'lodash';
import { PopoverMenuSeparator } from '@frameio/components/src/styled-components/PopoverMenu';
import Switch from '@frameio/components/src/styled-components/Switch';
import PremiumBadge from '@frameio/components/src/styled-components/PremiumBadge';
import { Tooltip } from '@frameio/vapor';
import { allFeatures } from 'planFeatures';

const StyledFlex = styled(Flex)`
  ${(p) => p.theme.fontStyle.body};
  color: ${(p) => p.theme.color.almostBlack};
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: ${({ featureLocked }) => (featureLocked ? 'pointer' : 'auto')};
`;

const StyledFlexItem = styled(FlexItem)`
  margin-left: auto;
  label {
    display: block;
  }
`;

const StyledP = styled.p`
  margin-bottom: ${(p) => p.theme.spacing.tiny};
  margin-right: ${(p) => p.theme.spacing.units(7)};
  ${(p) => p.theme.fontStyle.bodyS};
`;

// TODO: consider replacing with framer-motion
const InputContainer = styled.div`
  height: ${(p) => (p.isVisible ? 'auto' : '0')};
  margin-bottom: ${(p) => (p.isVisible ? p.theme.spacing.tiny : '0')};
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
  overflow: ${(p) => (p.isVisible ? 'visible' : 'hidden')};
  transition: height ${(p) => p.inputTransitionDurationMs}ms ease-in-out;
  transition: opacity ${(p) => p.inputTransitionDurationMs / 3}ms ease-in;
`;

const StyledPopoverMenuSeparator = styled(PopoverMenuSeparator)`
  margin: ${(p) => p.theme.spacing.micro} 0;
  border-color: ${(p) => p.theme.color.coldWhite};
`;

const StyledTooltip = styled(Tooltip)`
  max-width: 250;
`;

export const upgradeToChange = 'Upgrade to change';

export function renderLabelWithStatus(
  label,
  isChecked = false,
  isFeatureGated = false
) {
  const checkedLabel = isChecked ? ' (On)' : ' (Off)';
  return `${label}${isFeatureGated ? checkedLabel : ''}`;
}

const SharePanelSettingsSwitchRow = ({
  checked,
  children,
  disabled: isInputDisabled,
  disabledTooltipMessage,
  help,
  isAdmin,
  featureLocked,
  featureGateLabel,
  isSeparatorHidden,
  inputTransitionDurationMs,
  label,
  name,
  onToggle,
  requiresUpgradeToPro,
}) => {
  const isShareLinkExpirationLocked =
    featureLocked === allFeatures.shareLinkExpiration;
  return (
    <React.Fragment>
      <StyledFlex
        as="label"
        featureLocked={featureLocked}
        justifyBetween
        onClick={featureLocked ? onToggle : noop}
      >
        {label}
        <StyledFlexItem>
          {featureLocked ? (
            <Tooltip
              details={isAdmin && 'Click to learn more.'}
              offset={[0, 10]}
              placement="top"
              title={`Available on ${
                isShareLinkExpirationLocked && !requiresUpgradeToPro
                  ? 'Team'
                  : 'Pro'
              } ✨`}
              variant="dark"
            >
              <PremiumBadge>{featureGateLabel || 'Upgrade'}</PremiumBadge>
            </Tooltip>
          ) : (
            <StyledTooltip
              disabled={!isInputDisabled}
              title={disabledTooltipMessage || ''}
              shouldUsePortal
              placement="top"
              variant="dark"
            >
              {/* The Switch component does not have a forward ref inside it, so wrapping it with an empty div to catch tooltip interaction */}
              <div>
                <Switch
                  isOn={checked}
                  disabled={isInputDisabled}
                  name={name}
                  onChange={onToggle}
                  value={checked}
                />
              </div>
            </StyledTooltip>
          )}
        </StyledFlexItem>
      </StyledFlex>
      {help && <StyledP>{help}</StyledP>}

      {children && (
        <InputContainer
          inputTransitionDurationMs={inputTransitionDurationMs}
          isVisible={checked}
        >
          {children}
        </InputContainer>
      )}

      {!isSeparatorHidden && <StyledPopoverMenuSeparator />}
    </React.Fragment>
  );
};

SharePanelSettingsSwitchRow.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  isSeparatorHidden: PropTypes.bool,
  inputTransitionDurationMs: PropTypes.number.isRequired,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  onToggle: PropTypes.func,
  disabledTooltipMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  featureLocked: PropTypes.string,
  featureGateLabel: PropTypes.string,
  requiresUpgradeToPro: PropTypes.boolean,
};

SharePanelSettingsSwitchRow.defaultProps = {
  inputTransitionDurationMs: 200,
  requiresUpgradeToPro: false,
};

export default React.memo(SharePanelSettingsSwitchRow);
