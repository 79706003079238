import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledInternalDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

/**
 * This helper component fills the space of its parent container and returns the width and height of its immediate child
 */

const ProvideElementSize = (props) => {
  const { children } = props;
  const [size, setSize] = React.useState([0, 0]);
  const ref = React.useRef(null);

  React.useLayoutEffect(() => {
    const handleResize = () => {
      if (!ref.current) {
        return;
      }

      const bounds = ref.current.getBoundingClientRect();

      setSize([bounds.width, bounds.height]);
    };

    const onResize = () => {
      requestAnimationFrame(handleResize);
    };

    window.addEventListener('resize', onResize);

    const checkSizeInterval = setInterval(() => {
      if (ref.current && ref.current.offsetHeight > 0) {
        onResize();
        clearInterval(checkSizeInterval);
      }
    }, 500);
  }, []);

  return (
    <StyledDiv ref={ref}>
      <StyledInternalDiv>
        {children({ width: size[0], height: size[1] })}
      </StyledInternalDiv>
    </StyledDiv>
  );
};

export default ProvideElementSize;
