import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TreeView from '@frameio/components/src/styled-components/TreeView';
import { motion } from 'framer-motion';
import ConnectedTreeAemRepositoryItem from 'components/Dialog/AdobeIntegrationConnectDialog/Aem/ConnectedTreeAemRepositoryItem';
import { noop } from 'lodash';
import useDeferredMount from 'components/AssetActions/MoveTo/useDeferredMount';

const TreeViewContainer = styled(motion.div)`
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  height: 100%;
  padding: ${(p) => `0 ${p.theme.spacing.units(2)}`};
`;

/**
 * TreeNavigator
 *
 * The main view for displaying trees with Aem Repositories
 */

function TreeAemNavigator({
  onSelect,
  projectId,
  selectedId,
  expanded,
  onExpand,
  onScroll,
  deferMount,
  scrollRef,
  selectedAemDestination,
}) {
  // we use deferred mount to delay mounting of the initial
  // treeview for perf reasons. it generally makes the initial
  // mount seem smoother.
  const shouldMount = useDeferredMount();

  if (deferMount && !shouldMount) {
    return null;
  }

  return (
    <TreeViewContainer
      animate={{ opacity: 1, transition: { duration: 0.2 } }}
      initial={{
        opacity: deferMount ? 0 : 1,
      }}
      ref={scrollRef}
      onScroll={onScroll}
    >
      <TreeView
        onDoubleClick={noop}
        selectOnFocus={false}
        onSelect={onSelect}
        selected={selectedId}
        expanded={expanded}
        onExpand={onExpand}
      >
        <ConnectedTreeAemRepositoryItem
          selectedAemDestination={selectedAemDestination}
          onSelect={onSelect}
          projectId={projectId}
        />
      </TreeView>
    </TreeViewContainer>
  );
}

TreeAemNavigator.propTypes = {
  projectId: PropTypes.string,
  selectedAemDestination: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
};

export default React.memo(TreeAemNavigator);
