import React, { useCallback, useState, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ellipsis } from 'polished';
import Flex from 'styled-flex-component';
import { Tooltip } from '@frameio/vapor';
import { userEntitySelector } from '@frameio/core/src/users/selectors';
import { currentAccountSelector } from 'selectors/accounts';
import { watermarkIdEndUserOverrideEnabled } from 'utils/featureFlags';
import Button from '@frameio/components/src/styled-components/Button';
import CircledAlert from '@frameio/components/src/svgs/dynamic_color/circled_alert.svg';
import MoreIcon from '@frameio/components/src/svgs/icons/more.svg';
import CheckIcon from '@frameio/components/src/svgs/icons/check.svg';
import TemplateCustomText from './TemplateCustomText';
import TemplateContextMenu from './TemplateContextMenu';
import { endUserOverrideAccountSettingSelector } from '../selectors';

const creatorNameForTemplateSelector = (state, sessionWatermarkEntity) => {
  const { creator_id: userId } = sessionWatermarkEntity;

  const user = userEntitySelector(state, {
    userId,
  });

  return user && user.name;
};

const ThreeDotsContainerAsStyledButton = styled(Button)`
  opacity: 0;
  transition: none;

  &:hover:not(:active):not(:disabled),
  &:active:not(:disabled) {
    color: ${(p) => p.theme.color.darkGray};
  }
`;

const StyledMoreIcon = styled(MoreIcon)`
  pointer-events: none;
`;

const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  top: 5px;
  left: 8px;
  top: ${(p) => p.theme.spacing.micro};
  color: ${(p) => p.theme.color.brand};
  transition: transform 0.167s ease;
  transform: scale(1);
  pointer-events: none;
`;

const ContentContainer = styled.div`
  width: 90%;
`;

const IconContainer = styled.div`
  width: 10%;
  position: relative;
  cursor: pointer;
  top: -5px;
  left: ${(p) => p.theme.spacing.micro};

  // Prevent safari flashing a focus ring around the icon on click
  div[aria-haspopup] {
    outline: 0;
  }
`;

const Container = styled.div`
  background-color: ${(p) => p.theme.color.white};
  border-radius: 6px;
  padding: ${(p) => p.theme.spacing.small} ${(p) => p.theme.spacing.units(2)};
  position: relative;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${(p) => p.theme.color.coldWhite};

    // Hide checkmark if row is hovered or focused if viewed by an admin
    ${(p) =>
      p.isMenuEnabled &&
      `${StyledCheckIcon} {
      transform: scale(0);
    }`}

    // Show dots if row is hovered or focused
    // Make dots dark gray if the menu is open
    ${ThreeDotsContainerAsStyledButton} {
      color: ${(p) =>
        p.isMenuOpen ? p.theme.color.darkGray : p.theme.color.slateGray};
      opacity: 1;

      // Only apply a transition if the dots are being hovered
      &:hover,
      &:focus {
        transition: color 0.167s ease, opacity 0.167s ease;
      }
    }
  }

  &:active {
    // If the row is active (being clicked/pressed) then make sure the dots are dark gray if they have hover or focus
    ${ThreeDotsContainerAsStyledButton} {
      &:hover,
      &:focus {
        color: ${(p) => p.theme.color.darkGray};
      }
    }
  }

  // Hide the checkmark whenever the menu is open
  ${StyledCheckIcon} {
    transform: ${(p) => (p.isMenuOpen ? 'scale(0)' : 'scale(1)')};
  }

  // If the menu is open, make the dots dark gray and visible
  ${ThreeDotsContainerAsStyledButton} {
    color: ${(p) =>
      p.isMenuOpen ? p.theme.color.darkGray : p.theme.color.slateGray};
    opacity: ${(p) => (p.isMenuOpen ? 1 : 0)};
  }
`;

/*
truncation!
   in normal circumstances we want the title to have an ellipsis and span the entire width
   "foo bar baz ..."

   when we are displaying the default template, we should not cut off the word 'default'
   "foo bar ... DEFAULT"
*/

export const TemplateTitle = styled.span`
  ${(p) => p.theme.fontStyle.bodyM};
  color: ${(p) => p.theme.color.coolBlack};
  ${ellipsis()}
  max-width: ${(p) => p.isDefault && '75%'};
  ${(p) => p.active && `font-weight: ${p.theme.fontWeight.bold};`};
`;

const StyledSpan = styled.span`
  ${(p) => p.theme.fontStyle.bodyS};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  color: ${(p) => p.theme.color.brand};
  margin-left: ${(p) => p.theme.spacing.tiny};
  text-transform: uppercase;
`;

const DeletedText = styled.span`
  color: ${(p) => p.theme.color.error};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  ${(p) => p.theme.fontStyle.bodyM}
  display: inline-block;
  margin-right: ${(p) => p.theme.spacing.tiny};
`;

const StyledButton = styled.button`
  background: none;
  border: none;
  padding: none;
`;

const StyledCircleAlert = styled(CircledAlert)`
  fill: ${(p) => p.theme.color.error};
  display: inline-block;
  width: 20px;
  height: 20px;
  opacity: 0.4;
  transition: opacity 250ms ease;

  &:hover {
    opacity: 1;
  }
`;

const MetaText = styled.div`
  ${(p) => p.theme.fontStyle.bodyM};
  color: ${(p) => p.theme.color.gray};
`;

const SessionWatermarkTemplateRow = ({
  isAdmin,
  isDefaultTemplate,
  isDeleteButtonEnabled,
  isDeletedEntity,
  isInternalPlaybackTemplate,
  isSelected,
  onChange,
  onRevert,
  onSelectForPreview,
  openSessionWatermarkTemplateEditor,
  setTemplateAsDefault,
  setTemplateAsInternalPlaybackTemplate,
  showToasts,
  showAdvancedAdminOptions,
  sessionWatermarkEntity,
}) => {
  const creatorName = useSelector((s) =>
    creatorNameForTemplateSelector(s, sessionWatermarkEntity)
  );

  const createdDate = moment(sessionWatermarkEntity.inserted_at).format(
    'MMMM D, YYYY'
  );
  const metaText = creatorName
    ? `Created on ${createdDate} by ${creatorName}`
    : `Created on ${createdDate}`;

  const isEndUserOverrideFeatureFlagEnabled = useSelector(
    watermarkIdEndUserOverrideEnabled
  );

  const currentAccountId = useSelector(currentAccountSelector).id;
  const isEuoAccountSettingEnabled = Boolean(
    useSelector((state) =>
      endUserOverrideAccountSettingSelector(state, currentAccountId)
    )
  );

  const isEndUserOverrideEnabled =
    isEndUserOverrideFeatureFlagEnabled && isEuoAccountSettingEnabled;

  const isModified =
    isEndUserOverrideFeatureFlagEnabled &&
    Boolean(sessionWatermarkEntity.original_session_watermark_template_id) &&
    !showAdvancedAdminOptions;

  const threeDotsContainerRef = useRef(null);
  const threeDotsRef = useRef(null);

  const onClickAssign = useCallback(
    (e) => {
      /* HACK: Is there a better way to get the Vapor Menu to eat clicks? */
      const menuButtonWasClicked = e.target?.parentNode?.classList[0]?.startsWith(
        'MenuButton'
      );

      if (
        e.target === threeDotsContainerRef.current ||
        e.target === threeDotsRef.current ||
        menuButtonWasClicked
      ) {
        return;
      }
      if (onChange) {
        onChange({
          target: {
            name: 'sessionWatermarkId',
            value: sessionWatermarkEntity.id,
          },
        });
      }

      if (onSelectForPreview) {
        onSelectForPreview(sessionWatermarkEntity.id);
      }
    },
    [
      sessionWatermarkEntity.id,
      onChange,
      onSelectForPreview,
      threeDotsContainerRef,
      threeDotsRef,
    ]
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Container
      active={isSelected}
      isMenuEnabled={isAdmin}
      isSelected={isSelected}
      isMenuOpen={isMenuOpen}
      onClick={onClickAssign}
      onTouchStart={onClickAssign}
    >
      <Flex alignStart>
        <ContentContainer>
          <Flex alignCenter justifyBetween>
            <Flex alignBaseline grow style={{ overflow: 'hidden' }}>
              <TemplateTitle active={isSelected}>
                {sessionWatermarkEntity.name}
                {isModified && ' [Modified]'}
              </TemplateTitle>
              {isDefaultTemplate && <StyledSpan>default</StyledSpan>}
            </Flex>
          </Flex>
          <MetaText>{metaText}</MetaText>
          {isDeletedEntity && (
            <Flex>
              <DeletedText>Template deleted by account admin</DeletedText>
              <Tooltip
                title="This template was deleted by your account admin. It can still be used on this share link."
                variant="dark"
                placement="top"
              >
                <StyledButton>
                  <StyledCircleAlert />
                </StyledButton>
              </Tooltip>
            </Flex>
          )}
        </ContentContainer>
        <IconContainer>
          {isAdmin && (
            <TemplateContextMenu
              onRevert={onRevert}
              isDefaultTemplate={isDefaultTemplate}
              isDeleteButtonEnabled={isDeleteButtonEnabled}
              isEndUserOverrideEnabled={isEndUserOverrideEnabled}
              isInternalPlaybackTemplate={isInternalPlaybackTemplate}
              isOpen={isMenuOpen}
              openSessionWatermarkTemplateEditor={
                openSessionWatermarkTemplateEditor
              }
              sessionWatermarkEntity={sessionWatermarkEntity}
              setTemplateAsDefault={setTemplateAsDefault}
              setTemplateAsInternalPlaybackTemplate={
                setTemplateAsInternalPlaybackTemplate
              }
              showAdvancedAdminOptions={showAdvancedAdminOptions}
              toggleMenu={setIsMenuOpen}
            >
              <div>
                {isSelected && <StyledCheckIcon width={18} height={18} />}

                <ThreeDotsContainerAsStyledButton
                  icon
                  dark
                  text
                  type="button"
                  ref={threeDotsContainerRef}
                >
                  <div ref={threeDotsRef}>
                    <StyledMoreIcon width={18} height={18} />
                  </div>
                </ThreeDotsContainerAsStyledButton>
              </div>
            </TemplateContextMenu>
          )}
          {!isAdmin && isSelected && <StyledCheckIcon width={18} height={18} />}
        </IconContainer>
      </Flex>
      {isSelected && onChange && (
        <Flex column>
          <TemplateCustomText
            showToasts={showToasts}
            sessionWatermarkEntity={sessionWatermarkEntity}
          />
        </Flex>
      )}
    </Container>
  );
};

export default SessionWatermarkTemplateRow;

SessionWatermarkTemplateRow.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isDefaultTemplate: PropTypes.bool,
  isDeleteButtonEnabled: PropTypes.bool,
  isDeletedEntity: PropTypes.bool,
  isInternalPlaybackTemplate: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onSelectForPreview: PropTypes.func,
  openSessionWatermarkTemplateEditor: PropTypes.func.isRequired,
  sessionWatermarkEntity: PropTypes.shape({
    creator_id: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    watermark_blocks: PropTypes.shape({
      data_points: PropTypes.shape({
        user_input: PropTypes.shape({
          enabled: PropTypes.bool,
          order: PropTypes.number,
        }),
      }),
    }).isRequired,
  }),
  setTemplateAsDefault: PropTypes.func.isRequired,
  setTemplateAsInternalPlaybackTemplate: PropTypes.func,
  showAdvancedAdminOptions: PropTypes.bool,
};
