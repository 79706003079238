import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter, flatMap, get, map, pipe, some } from 'lodash/fp';
import Raven from 'raven-js';
import { currentAccountSelector } from 'selectors/accounts';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import track from 'analytics';
import { onSubmit } from './actions';

/**
 * @param {Object} sessionWatermarkTemplate entity
 * @returns {String[]} dataPoints names that aren't disabled, as a sorted flat
 * array including duplicates
 */
export const getAllEnabledDatapointNames = pipe(
  get('watermark_blocks'),
  flatMap(get('data_points')),
  filter((dataPoint) => dataPoint.enabled !== false),
  map(get('type')),
  (arr) => Array.prototype.sort.call(arr)
);

export default function useSubmit(args) {
  const { getMessage, onNameTakenError, watermarkTemplateId } = args;
  const currentAccount = useSelector(currentAccountSelector);
  const dispatch = useDispatch();

  const dispatchSubmit = useCallback(
    (values, formikHelpers) => {
      const isNewTemplate = Boolean(!watermarkTemplateId);

      return (
        // The onSubmit action (and corresponding saga) take an `onSuccess`
        // and `onError` callback which are called with the data or error
        // returned from the API. This allows us to asynchronously handle the
        // results of the dispatched action inside the component, (where we
        // have access to things such as Formik context). This pattern was
        // borrowed from https://github.com/redux-saga/redux-saga/issues/161#issuecomment-229350795.
        // For more info, see https://github.com/Frameio/web-client/pull/9090.
        new Promise((resolve, reject) =>
          dispatch(
            onSubmit(
              currentAccount.id,
              values,
              watermarkTemplateId,
              resolve,
              reject
            )
          )
        )
          .then(() => {
            track('session-watermark-template-editor-closed', {
              enabled_datapoint_names: getAllEnabledDatapointNames(values),
              trigger: isNewTemplate ? 'created' : 'updated',
              watermark_template_id: watermarkTemplateId,
              block_count:
                values && values.watermark_blocks
                  ? Object.keys(values.watermark_blocks).length
                  : 0,
            });

            dispatch(
              showSuccessToast({ header: getMessage({ isNewTemplate }) })
            );
          })
          .catch((err) => {
            const hasNameTakenErrorDetail = pipe(
              get('errors'),
              some(({ detail }) => detail === 'name has already been taken')
            );

            if (hasNameTakenErrorDetail(err)) {
              onNameTakenError(values, formikHelpers);
            } else {
              dispatch(
                showErrorToast({
                  header: getMessage({ isError: true }),
                  subHeader: JSON.stringify(err.errors),
                })
              );
            }

            Raven.captureException(err, {
              extra: { sessionWatermarkTemplateName: values.name },
            });
          })
      );
    },
    [
      currentAccount.id,
      dispatch,
      getMessage,
      onNameTakenError,
      watermarkTemplateId,
    ]
  );

  return dispatchSubmit;
}
