import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { noop } from 'lodash';
import moment from 'moment-timezone';

import StyledFlatPickr from '@frameio/components/src/styled-components/DatePicker/StyledFlatpickr';
import hideVisually from '@frameio/components/src/mixins/hideVisually';
import { BigSwitch } from '@frameio/components';

import { updateProjectDevice } from '../actions';

const TEXT = { label: 'Expiration' };

const HiddenInput = styled.input`
  ${hideVisually()};
`;

const ExpirationToggle = styled.div`
  color: ${(p) => p.theme.color.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(p) => `${p.theme.spacing.tiny} ${p.theme.spacing.small}`};

  // The border color matches the border color inside the expiration component.
  border-bottom: 1px solid ${(p) => p.theme.color.coldWhite};
`;

const Container = styled.div`
  position: relative;
  display: flex;
  border-bottom-right-radius: ${(p) => p.theme.radius.large};
  border-bottom-left-radius: ${(p) => p.theme.radius.large};
  overflow: hidden;
  ${(p) => p.disabled && `opacity: 0.6; pointer-events: none;`}
`;

/**
 * Expiration component to be used for project devices. Currently intended to be
 * rendered in a popover, contains a boolean toggle due to the common
 * StyledFlatPickr not containing an intristic interface for nulling / clearing
 * the expiration.
 */
const ExpirationDatePicker = ({
  projectDeviceId,
  expiresAt,
  onChange = noop,
}) => {
  const dispatch = useDispatch();
  const calendarRef = useRef(null);

  const today = new Date();
  const nextWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 7
  );
  const defaultExpiresAt = expiresAt || nextWeek;

  const [isExpirationEnabled, setIsExpirationEnabled] = useState(!!expiresAt);

  const setExpiresAt = (date) => {
    const nextExpiresAt = moment(date)
      .utc()
      .toISOString();

    onChange(nextExpiresAt);
    if (expiresAt !== nextExpiresAt) {
      dispatch(
        updateProjectDevice(projectDeviceId, {
          expires_at: nextExpiresAt,
        })
      );
    }
  };

  const toggleExpiration = () => {
    let nextExpiresAt;
    if (isExpirationEnabled) {
      nextExpiresAt = null;

      // Bring the calendar back to its initial state
      // eslint-disable-next-line no-unused-expressions
      calendarRef.current?.setDate(defaultExpiresAt);
      // eslint-disable-next-line no-unused-expressions
      calendarRef.current?.jumpToDate(defaultExpiresAt);
    } else {
      nextExpiresAt = calendarRef.current?.selectedDates[0];
    }

    setExpiresAt(nextExpiresAt);
    setIsExpirationEnabled(!isExpirationEnabled);
  };

  return (
    <>
      <ExpirationToggle>
        {TEXT.label}
        <BigSwitch isOn={isExpirationEnabled} onChange={toggleExpiration} />
      </ExpirationToggle>
      <Container disabled={!isExpirationEnabled}>
        <StyledFlatPickr
          onCreate={(flatpickr) => {
            calendarRef.current = flatpickr;
          }}
          options={{
            mode: 'single',
            minDate: today,
            enableTime: true,
            inline: true, // needed to force display calendar for popup
            wrap: true, // needed for being able to hide Flatpickr input
            defaultDate: defaultExpiresAt,
            minuteIncrement: 1,
          }}
          onChange={(selectedDates) => setExpiresAt(selectedDates[0])}
        >
          {/* hides FlatPickr's bundled input */}
          <HiddenInput data-input readOnly />
        </StyledFlatPickr>
      </Container>
    </>
  );
};

export default ExpirationDatePicker;
