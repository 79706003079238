import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import UnconfirmedEmailLockout from 'components/UnconfirmedEmailLockout';

/**
 * This <Route> wrapper waits for `authenticate()` to set `state.currentUser`
 * before rendering the route.
 *
 * If `authenticate()` fails, whether because there's no auth token or
 * because the token is invalid, the session is reset and the user gets kicked
 * back to the auth flow.
 */
export default class AuthedRoute extends PureComponent {
  componentDidMount() {
    const { authenticate, handleUserMismatch } = this.props;

    // This sets the authed user as state.currentUser
    authenticate();

    // Handle potential user mismatch when the user changes in another tab.
    window.addEventListener('focus', handleUserMismatch);
  }

  componentWillUnmount() {
    const { handleUserMismatch, onUnmount } = this.props;
    onUnmount();
    window.removeEventListener('focus', handleUserMismatch);
  }

  render() {
    const { isAuthed, isReady, lockedOutEmail, ...rest } = this.props;

    if (lockedOutEmail) {
      return <UnconfirmedEmailLockout email={lockedOutEmail} />;
    }

    if (isAuthed && isReady) {
      return <Route {...rest} />;
    }
    return null;
  }
}

AuthedRoute.propTypes = {
  authenticate: PropTypes.func.isRequired,
  isReady: PropTypes.bool.isRequired,
  isAuthed: PropTypes.bool.isRequired,
  lockedOutEmail: PropTypes.string,
  onUnmount: PropTypes.func.isRequired,
};
AuthedRoute.defaultProps = {
  lockedOutEmail: undefined,
};
