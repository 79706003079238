import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';
import { generateFetchActionTypes } from '@frameio/core/src/shared/actions/helpers';

const module = 'MANAGE_VERSION_STACK_MODAL';

export const MANAGE_VERSION_STACK_MODAL = {
  ...generateActionTypes(module, [
    'OPEN',
    'REMOVE_FROM_STACK',
    'REORDER_VERSION_ASSET',
  ]),
  ...generateFetchActionTypes(module, ['FETCH']),
};

export const reorderVersionStackInModal = createAction(
  MANAGE_VERSION_STACK_MODAL.REORDER_VERSION_ASSET,
  (childAssetId, prevAssetId, nextAssetId) => ({
    childAssetId,
    prevAssetId,
    nextAssetId,
  })
);

export const openManageVersionStackInModal = createAction(
  MANAGE_VERSION_STACK_MODAL.OPEN,
  (assetId) => ({ assetId })
);

export const removeVersionFromStackInModal = createAction(
  MANAGE_VERSION_STACK_MODAL.REMOVE_FROM_STACK,
  (childAssetId, totalVersions) => ({ childAssetId, totalVersions })
);
