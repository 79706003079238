import { DASHBOARD_SIDEBAR } from './actions';
import { isFetchingReducerFactory } from '../../factories/isFetching';

const isFetchingReducer = isFetchingReducerFactory(
  DASHBOARD_SIDEBAR.IS_FETCHING
);

// `isFetching is set to `false` in AppViewController and cannot be derived
// from entities.*.fetchStatus yet
export default function dashboardSidebarReducer(
  state = {
    isFetching: false,
    isShowing: false,
  },
  action
) {
  switch (action.type) {
    case DASHBOARD_SIDEBAR.SHOW:
      return {
        ...state,
        isShowing: true,
      };
    case DASHBOARD_SIDEBAR.HIDE:
      return {
        ...state,
        isShowing: false,
      };
    default:
      return isFetchingReducer(state, action);
  }
}
