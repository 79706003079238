import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { teamEntitiesSelector } from '@frameio/core/src/teams/selectors';
import { projectEntitiesSelector } from '@frameio/core/src/projects/selectors';
import { showSuccessToast } from 'actions/toasts';
import { closeModal } from 'components/Modal/actions';
import { authOnSlack } from 'components/SlackIntegration/actions';
import { isTeamMemberSelector } from 'selectors/roles';
import { currentUserSelector } from 'selectors/users';
import { getShareLinkForProject } from 'URLs';
import {
  canUserToggleLifecyclePolicy,
  permittedActionsForProjectSelector,
} from 'selectors/permissions';
import { newProjectInviteLinksEnabled } from 'utils/featureFlags';
import EditProjectForm from './EditProject';
import { editProjectFormSelector } from './reducers';
import { updateProject, confirmDeleteProject } from '../actions';

function mapStateToProps(state) {
  const currentUser = currentUserSelector(state);
  const projects = projectEntitiesSelector(state);
  const teams = teamEntitiesSelector(state);
  const projectForm = editProjectFormSelector(state);
  const { projectId } = projectForm;
  const selectedProject = projects[projectId];
  const projectPreferences = selectedProject.project_preferences;

  // Set projectPreferences as the default value if user_preferences doesn't exist.
  const userPrefs = selectedProject.user_preferences || projectPreferences;

  const selectedTeam = teams[selectedProject.team_id];
  const isRestricted = !isTeamMemberSelector(state, { projectId });

  const {
    canToggleDownloads,
    canToggleInviteCollaborators,
    canToggleSharePresentations,
    canUseC2CConnections,
    canUsePrivateProjects,
  } = permittedActionsForProjectSelector(state, { projectId });

  const hasSlackWebhook = !isEmpty(
    teams[selectedProject.team_id].slack_webhook
  );

  return {
    projectName: selectedProject.name,
    isPrivate: selectedProject.private,
    isPrivacyDisabled: !(selectedProject.owner === currentUser.id),
    isProjectShared: selectedProject.shared,
    hasSlackWebhook,
    hasSlackNotificationsEnabled:
      hasSlackWebhook && projectPreferences.notify_slack,
    canCollaboratorInvite: projectPreferences.collaborator_can_invite,
    canCollaboratorShare: projectPreferences.collaborator_can_share,
    canCollaboratorDownload: projectPreferences.collaborator_can_download,
    canEmailForNewCommentEveryone: projectPreferences.notify_on_new_comment,
    canEmailForNewVideoEveryone: projectPreferences.notify_on_new_asset,
    canEmailForNewPersonEveryone: projectPreferences.notify_on_new_collaborator,
    canEmailForNewCommentMe: userPrefs.notify_on_new_comment,
    canEmailForNewVideoMe: userPrefs.notify_on_new_asset,
    canEmailForNewPersonMe: userPrefs.notify_on_new_collaborator,
    canEmailForUpdatedLabelEveryone: projectPreferences.notify_on_updated_label,
    canEmailForUpdatedLabelMe: userPrefs.notify_on_updated_label,
    canToggleDownloads,
    canToggleInviteCollaborators,
    canToggleSharePresentations,
    canUseC2CConnections,
    canUsePrivateProjects,
    c2cConnectionsEnabled: projectPreferences.devices_enabled,
    teamId: selectedProject.team_id,
    isFetching: projectForm.isFetching,
    isRestricted,
    projectId,
    projectShareLink: getShareLinkForProject(
      projectId,
      selectedProject.invite_url
    ),
    /**
     * Inversion of `ignore_archive`; e.g. setting `ignore_archive` false will enable
     * auto-archiving of assets after the associated lifecycle policy has elapsed
     */
    enableLifeCyclePolicy: !selectedProject.ignore_archive,
    canToggleAutomaticallyDeletingAssets: canUserToggleLifecyclePolicy(state, {
      accountId: selectedTeam.account_id,
      teamId: selectedTeam.id,
    }),
    assetLifecyclePolicy: selectedTeam.asset_lifecycle_policy,
    newProjectInviteLinksEnabled: newProjectInviteLinksEnabled(state),
  };
}

const mapDispatchToProps = {
  authOnSlack,
  confirmDeleteProject,
  updateProject,
  showSuccessToast,
  closeModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProjectForm);
