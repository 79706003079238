import React, { useState } from 'react';
import styled from 'styled-components';

import { Popover } from '@frameio/vapor';

import TextInput from '@frameio/components/src/styled-components/TextInput';
import { formatLongDateTime } from '@frameio/components/src/utils/datetimeHelpers';

import ExpirationDatePicker from '../ProjectDevice/ExpirationDatePicker';

const TEXT = {
  inputEmpty: 'Set Device Expiration (optional)',
  prefix: 'Expiration',
};

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing.tiny};
  padding-left: ${(p) => p.theme.spacing.units(0.25)};
  padding-right: ${(p) => p.theme.spacing.units(0.25)};
`;

const StyledTextInput = styled(TextInput)`
  height: ${(p) => p.theme.spacing.large};
  background: transparent;
  color: ${(p) => p.theme.color.white};
  border-color: ${(p) => p.theme.color.coolBlack};
  border-radius: ${(p) => p.theme.radius.large};
`;

const ExpirationInput = ({ projectDeviceId }) => {
  const [currentExpiration, setCurrentExpiration] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <Popover
      placement="top"
      isShowing={popoverOpen}
      onRequestChange={setPopoverOpen}
      shouldUsePortal
      content={
        <ExpirationDatePicker
          projectDeviceId={projectDeviceId}
          expiresAt={currentExpiration}
          onChange={(expirationValue) => setCurrentExpiration(expirationValue)}
        />
      }
    >
      <InputWrapper>
        <StyledTextInput
          autoComplete="off"
          placeholder={TEXT.inputEmpty}
          readOnly
          value={
            currentExpiration
              ? `${TEXT.prefix}: ${formatLongDateTime(currentExpiration)}`
              : ''
          }
        />
      </InputWrapper>
    </Popover>
  );
};

export default ExpirationInput;
