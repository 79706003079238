import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { size, rgba } from 'polished';
import { spacing } from '@frameio/components/src/theme/darkTheme';
import { type as assetType } from '@frameio/core/src/assets/helpers/constants';

import FolderThumb from 'components/FolderThumb';
import AssetThumb from 'components/AssetThumb';
import { FolderIcon } from 'components/FolderCard';

const PREVIEW_SHADOW = ({ theme }) =>
  `0 2px 20px ${rgba(theme.color.black, 0.5)}`;

const previewStyles = css`
  position: absolute;
  transform-origin: center;
`;

const FilePreview = styled(AssetThumb).attrs(() => ({
  scrubberBarWidth: 0,
}))`
  ${previewStyles}
  box-shadow: ${PREVIEW_SHADOW};
  border-radius: ${(p) => p.theme.radius.large};
`;

const FOLDER_PREVIEW_TOP_SPACING =
  parseInt(spacing.small, 10) + parseInt(spacing.tiny, 10);
const FOLDER_PREVIEW_SIDE_SPACING = parseInt(spacing.tiny, 10);

const FolderPreviewContainer = styled.div`
  ${previewStyles};
  padding: ${FOLDER_PREVIEW_TOP_SPACING}px ${FOLDER_PREVIEW_SIDE_SPACING}px;
  ${({ width, height }) => size(`${height}px`, `${width}px`)}

  svg {
    filter: drop-shadow(${PREVIEW_SHADOW});
  }
`;

const FolderPreview = ({ asset, width, height, ...rest }) => (
  <FolderPreviewContainer height={height} width={width} {...rest}>
    <FolderIcon selectedBorderWidth={0} />
    <FolderThumb
      asset={asset}
      height={height - FOLDER_PREVIEW_TOP_SPACING - FOLDER_PREVIEW_SIDE_SPACING}
      width={width - 2 * FOLDER_PREVIEW_SIDE_SPACING}
    />
  </FolderPreviewContainer>
);

FolderPreview.propTypes = {
  asset: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

function Preview({ asset, ...rest }) {
  const PreviewComponent =
    asset.type === assetType.FOLDER ? FolderPreview : FilePreview;
  return <PreviewComponent asset={asset} {...rest} />;
}

Preview.propTypes = {
  asset: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(assetType)),
  }).isRequired,
};

export default React.memo(Preview);

export const testExports = {
  FilePreview,
  FolderPreview,
  FOLDER_PREVIEW_TOP_SPACING,
  FOLDER_PREVIEW_SIDE_SPACING,
};
