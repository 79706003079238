import { all, call, put, take, select, takeLatest } from 'redux-saga/effects';

import { teamEntitySelector } from '@frameio/core/src/teams/selectors';
import { groupEntitySelector } from '@frameio/core/src/groups/selectors';
import { MODAL } from 'components/Modal/actions';
import { TYPE } from 'components/UserSearch/UserSearch';
import { USER_SEARCH, resetUserSearch } from './actions';

/*
  UserSearch accepts an array prop called `strategies`. Strategies are functions that return sagas
  and are called here. For more documentation, see ./strategies.js
*/

export function* executeSearchStrategies(action) {
  const { query, strategies } = action.payload;

  yield all(strategies.map((strategy) => call(strategy, query)));

  yield take(MODAL.CLOSE);
  yield put(resetUserSearch());
}

export function* calculateInvitedUsersCount(tokens) {
  // calculate the total number of users who are invited (single invites + group counts)
  // we use a for loop here because we cannot use `yield` inside of another function (ie reduce)
  let invitedUsersCount = 0;
  for (let i = 0; i < tokens.length; i += 1) {
    const token = tokens[i];

    if (token?.type === TYPE.GROUP) {
      const { user_count: count } = yield select(groupEntitySelector, {
        groupId: token.id,
      });
      invitedUsersCount += count;
    } else if (token?.type === TYPE.TEAM) {
      const { member_count: count } = yield select(teamEntitySelector, {
        teamId: token.id,
      });
      invitedUsersCount += count;
    } else if (token?.type === undefined) {
      // if token is undefined it shouldn't count
    } else {
      invitedUsersCount += 1;
    }
  }

  return invitedUsersCount;
}

export const calculateInvitedGroupsCount = (tokens) =>
  tokens.filter((t) => t?.type === TYPE.GROUP).length;

export default [
  takeLatest(USER_SEARCH.EXECUTE_SEARCH_STRATEGIES, executeSearchStrategies),
];
