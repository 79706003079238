import { connect } from 'react-redux';
import { invitedEntityAndTypeForId } from '../selectors';
import { deleteInvitee } from '../actions';
import PersonRow from './PersonRow';

export function mapStateToProps(state, { entityId }) {
  return invitedEntityAndTypeForId(state, { entityId });
}

const mapDispatchToProps = {
  deleteInvitee,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonRow);
