import JTEventManager from 'core/JTEventManager';
import JTAnimationEngine from 'core/JTAnimationEngine';

class JTConstants {
  static initClass() {

    this.LEFT_SHELF_SIZE = 242;
    this.RIGHT_SHELF_SIZE = 242;

    this.TOP_BAR_HEIGHT = 49;
    this.TOP_DECOR_HEIGHT = 5;

    this.METADATA_COLLAPSED_HEIGHT = 54;
    this.METADATA_EXPANDED_HEIGHT = 450;
    this.PLAYER_COMMENTS_HEADER_HEIGHT = 106;

    this.VERSION = '0.1.11';
    this.EVENT_MANAGER = new JTEventManager();
    this.ANIMATION_ENGINE = new JTAnimationEngine();
  }
}
JTConstants.initClass();

export default JTConstants;
