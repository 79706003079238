import {
  initPlaybackAnalytics,
  updatePlaybackAnalytics,
} from '@frameio/media-pipeline-toolkit';
import { call, select, takeLatest } from 'redux-saga/effects';
import Raven from 'raven-js';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import config from 'config';
import { currentUserEntitySelector } from 'selectors/users';
import { MUX } from './actions';

const _pageTypeByTrackingPage = /** @type {const} */ ({
  'player page': 'v3 Player',
  'presentation modal': 'v3 Presentation Editor',
  'presentation page': 'v3 Presentation',
  'review page': 'v3 Review',
});

function* initMonitor({
  payload: {
    assetId,
    autoplay,
    initialEncodingVariant,
    playerName,
    trackingPage,
    videoEl,
  },
}) {
  try {
    // `currentUser` may not be available on public pages
    const { id: userId } = (yield select(currentUserEntitySelector)) || {};
    const asset = assetId ? yield select(assetEntitySelector, { assetId }) : {};

    /** @type {Parameters<typeof initPlaybackAnalytics>[0]} */
    const args = {
      config: {
        beaconCollectionDomain: config.muxBeaconCollectionDomain,
        buildNumber: process.env.BUILD_NUMBER,
        envKey: config.muxEnvKey,
      },
      mediaInfoLegacy: asset,
      playerInfo: {
        autoplay,
        element: videoEl,
        initialEncodingVariant,
        pageType: _pageTypeByTrackingPage[trackingPage],
        playerName,
        userId,
      },
    };

    yield call(initPlaybackAnalytics, args);
  } catch (error) {
    Raven.captureException(error);
  }
}

function* monitorAsset({ payload: { assetId, videoEl } }) {
  try {
    const asset = assetId ? yield select(assetEntitySelector, { assetId }) : {};
    /** @type {Parameters<typeof updatePlaybackAnalytics>[1]} */
    const props = { mediaInfoLegacy: asset, isDebugEnabled: true };
    yield call(updatePlaybackAnalytics, videoEl, props);
  } catch (error) {
    Raven.captureException(error);
  }
}

export default [
  takeLatest(MUX.INIT_MONITOR, initMonitor),
  takeLatest(MUX.MONITOR_ASSET, monitorAsset),
];
