import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Dot = styled.div`
  width: ${(p) => p.theme.spacing.tiny};
  height: ${(p) => p.theme.spacing.tiny};
  border-radius: ${(p) => p.theme.radius.circle};
  background-color: ${({ theme, isCompleted }) =>
    isCompleted ? theme.color.brand : theme.color.silver};
`;

const ProgressDot = ({ isCompleted, ...rest }) => (
  <Dot isCompleted={isCompleted} {...rest} />
);

export default ProgressDot;

ProgressDot.propTypes = {
  isCompleted: PropTypes.bool.isRequired,
};
