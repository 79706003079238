import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@frameio/components';

const RestrictedEditProjectFooter = ({ isWaiting, onSaveButtonClick }) => (
  <div
    className="absolute z-4 bottom-0 w-100 pa3 flex items-center justify-center bt b--fio-gray bg-white"
    data-buttons
  >
    <Button
      isWaiting={isWaiting}
      className="w-100 pv3 bg-white bg-hover-fio-purple b--fio-gray dark-gray white-children-hover"
      onClick={onSaveButtonClick}
    >
      Save Settings
    </Button>
  </div>
);

RestrictedEditProjectFooter.defaultProps = {
  isWaiting: false,
  onSaveButtonClick: () => {},
};

RestrictedEditProjectFooter.propTypes = {
  isWaiting: PropTypes.bool,
  onSaveButtonClick: PropTypes.func,
};

export default RestrictedEditProjectFooter;
