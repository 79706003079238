import JT from 'utilities/JT';

class JTFormats {
  static initClass() {

    //  0 video
    //  1 audio
    //  2 image
    //  3 archive
    //  4 doc

    this.VIDEO = 1;
    this.AUDIO = 2;
    this.IMAGE = 4;
    this.ARCHIVE = 8;
    this.DOC = 0;

    // NOTE MOVED DPX TO DOC TYPE UNTIL DPX SUPPORT

    this.formats = {

      'webm': 1, // 00000001  # Video
      // 'r3d'         : 1     # 00000001
      'mkv': 1, // 00000001
      'flv': 1, // 00000001
      'ogv': 1, // 00000001
      'ogg': 1, // 00000001
      'drc': 1, // 00000001
      'mng': 1, // 00000001
      'avi': 1, // 00000001
      'mov': 1, // 00000001
      'qt': 1, // 00000001
      'wmv': 1, // 00000001
      'rm': 1, // 00000001
      'rmvb': 1, // 00000001
      'asf': 1, // 00000001
      'mp4': 1, // 00000001
      'm4p ': 1, // 00000001
      'm4v': 1, // 00000001
      'mpg': 1, // 00000001
      'mp2': 1, // 00000001
      'mpeg': 1, // 00000001
      'mpe': 1, // 00000001
      'mpv': 1, // 00000001
      'm2v': 1, // 00000001
      'svi': 1, // 00000001
      '3gp': 1, // 00000001
      '3g2': 1, // 00000001
      'mxf': 1, // 00000001
      'roq': 1, // 00000001
      'nsv': 1, // 00000001
      'quicktime': 1, // 00000001

      'act': 2, // 00000010 # AUDIO
      'aiff': 2, // 00000010
      'aac': 2, // 00000010
      'amr': 2, // 00000010
      'au': 2, // 00000010
      'awb': 2, // 00000010
      'dct': 2, // 00000010
      'dss': 2, // 00000010
      'dvf': 2, // 00000010
      'flac': 2, // 00000010
      'gsm': 2, // 00000010
      'iklax': 2, // 00000010
      'ivs': 2, // 00000010
      'm4a': 2, // 00000010
      'mmf': 2, // 00000010
      'mp3': 2, // 00000010
      'mpc': 2, // 00000010
      'msv': 2, // 00000010
      'ogg': 2, // 00000010
      'oga': 2, // 00000010
      'opus': 2, // 00000010
      'ra': 2, // 00000010
      'rm': 2, // 00000010
      'tta': 2, // 00000010
      'vox': 2, // 00000010
      'wav': 2, // 00000010
      'wma': 2, // 00000010
      'wv': 2, // 00000010

      'ani': 4, // 00000100 # Image
      'anim': 4, // 00000100
      'apng': 4, // 00000100
      'art': 4, // 00000100
      'bmp': 4, // 00000100
      'bsave': 4, // 00000100
      'cal': 4, // 00000100
      'cin': 4, // 00000100
      'cpc': 4, // 00000100
      'cpt': 4, // 00000100
      'dpx': 4, // 00000100
      'ecw': 4, // 00000100
      'exr': 4, // 00000100
      'fits': 4, // 00000100
      'flic': 4, // 00000100
      'fpx': 4, // 00000100
      'gif': 4, // 00000100
      'hdri': 4, // 00000100
      'hevc': 4, // 00000100
      'icer': 4, // 00000100
      'icns': 4, // 00000100
      'ico': 4, // 00000100
      'cur': 4, // 00000100
      'ics': 4, // 00000100
      'ilbm': 4, // 00000100
      'jbig': 4, // 00000100
      'jbig2': 4, // 00000100
      'jng': 4, // 00000100
      'jpg': 4, // 00000100
      'jpeg': 4, // 00000100
      'xr': 4, // 00000100
      'mng': 4, // 00000100
      'miff': 4, // 00000100
      'pam': 4, // 00000100
      'pbm': 4, // 00000100
      'pgm': 4, // 00000100
      'ppm': 4, // 00000100
      'pnm': 4, // 00000100
      'pcx': 4, // 00000100
      'pgf': 4, // 00000100
      'pictor': 4, // 00000100
      'png': 4, // 00000100
      'psd': 4, // 00000100
      'psb': 4, // 00000100
      'psp': 4, // 00000100
      'qtvr': 4, // 00000100
      'ras': 4, // 00000100
      'rbe': 4, // 00000100
      'logluv': 4, // 00000100
      'tif': 4, // 00000100
      'sgi': 4, // 00000100
      'tga': 4, // 00000100
      'tiff': 4, // 00000100
      'tiffep': 4, // 00000100
      'tiffit': 4, // 00000100
      'wbmp': 4, // 00000100
      'webp': 4, // 00000100
      'xbm': 4, // 00000100
      'xcf': 4, // 00000100
      'xpm': 4, // 00000100
      'xwd': 4, // 00000100
      'ciff': 4, // 00000100
      'dng': 4, // 00000100
      'ai': 4, // 00000100
      'cdr': 4, // 00000100
      'cgm': 4, // 00000100
      'dxf': 4, // 00000100
      'eva': 4, // 00000100
      'emf': 4, // 00000100
      'gerber': 4, // 00000100
      'hvif': 4, // 00000100
      'iges': 4, // 00000100
      'pgml': 4, // 00000100
      'svg': 4, // 00000100
      'vml': 4, // 00000100
      'wmf': 4, // 00000100
      'xar': 4, // 00000100
      'cdf': 4, // 00000100
      'djvu': 4, // 00000100
      'eps': 4, // 00000100
      'pict': 4, // 00000100
      'ps': 4, // 00000100
      'swf': 4, // 00000100
      'xaml': 4, // 00000100

      '3fr': 4, // 00000100 # RAW Image
      'arw': 4, // 00000100
      'cr2': 4, // 00000100
      'crw': 4, // 00000100
      'dcr': 4, // 00000100
      'erf': 4, // 00000100
      'jog': 4, // 00000100
      'kdc': 4, // 00000100
      'mdc': 4, // 00000100
      'mef': 4, // 00000100
      'mos': 4, // 00000100
      'mrw': 4, // 00000100
      'nef': 4, // 00000100
      'nrw': 4, // 00000100
      'orf': 4, // 00000100
      'pef': 4, // 00000100
      'raf': 4, // 00000100
      'raw': 4, // 00000100
      'rw2': 4, // 00000100
      'sr2': 4, // 00000100
      'srw': 4, // 00000100
      'x3f': 4, // 00000100
      'x-canon-crw': 4, // 00000100
      'x-adobe-dng': 4,
      'x-epson-erf': 4,
      'x-nikon-nrw': 4,
      'x-sony-sr2': 4,
      'vnd.adobe.photoshop': 4,

      'ar': 8, // 00001000  # Archive
      'cpio': 8, // 00001000
      'shar': 8, // 00001000
      'tar': 8, // 00001000
      'lbr': 8, // 00001000
      'bagit': 8, // 00001000
      'bzip2': 8, // 00001000
      'gzip': 8, // 00001000
      'lzip': 8, // 00001000
      'lzma': 8, // 00001000
      'lzop': 8, // 00001000
      'xz': 8, // 00001000
      'sq': 8, // 00001000
      'compress': 8, // 00001000
      '7z': 8, // 00001000
      'ace': 8, // 00001000
      'arc': 8, // 00001000
      'arj': 8, // 00001000
      'b1': 8, // 00001000
      'cabinet': 8, // 00001000
      'cf': 8, // 00001000
      'cpt': 8, // 00001000
      'dgca': 8, // 00001000
      'dmg': 8, // 00001000
      'egg': 8, // 00001000
      'gca': 8, // 00001000
      'kgb': 8, // 00001000
      'lha': 8, // 00001000
      'lzx': 8, // 00001000
      'mpq': 8, // 00001000
      'pea': 8, // 00001000
      'qda': 8, // 00001000
      'rar': 8, // 00001000
      'rzip': 8, // 00001000
      'sit': 8, // 00001000
      'sqx': 8, // 00001000
      'uda': 8, // 00001000
      'uharc': 8, // 00001000
      'xar': 8, // 00001000
      'zoo': 8, // 00001000
      'zip': 8 // 00001000

    };
  }

  static is_video(format) { return format & this.VIDEO ? true : false; }
  static is_audio(format) { return format & this.AUDIO ? true : false; }
  static is_image(format) { return format & this.IMAGE ? true : false; }
  static is_archive(format) { return format & this.ARCHIVE ? true : false; }
  static is_doc(format) { return !this.is_video(format) && !this.is_audio(format) && !this.is_image(format) && !this.is_archive(format); }

  // JTFormats.get_type file
  // Downloads in Safari have appended extensions
  static get_type(file) {

    let filetype;
    let convert_to_octet_stream = [
      'application/postscript'
    ];

    if (file) { filetype = file['type']; }
    if (!filetype || (filetype === '')) { filetype = "application/octet-stream"; }
    if (JT.includes(convert_to_octet_stream, filetype)) { filetype = "application/octet-stream"; }

    return filetype;
  }

  static format(string) {
    let f = this.formats[this.trim(string.toLowerCase())];
    return f ? f : 0;
  }

  static trim(input) {
    return input.split('/')
      .pop();
  }
}
JTFormats.initClass();

export default JTFormats;
