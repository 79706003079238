import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@frameio/vapor';

import CameraOnSVG from '@frameio/components/src/svgs/icons/camera-on.svg';
import CameraOffSVG from '@frameio/components/src/svgs/icons/camera-off.svg';
import MetadataOnSVG from '@frameio/components/src/svgs/icons/metadata-on.svg';
import MetadataOffSVG from '@frameio/components/src/svgs/icons/metadata-off.svg';
import SoundOnSVG from '@frameio/components/src/svgs/icons/sound-on.svg';
import SoundOffSVG from '@frameio/components/src/svgs/icons/sound-off.svg';

import Truncate from '@frameio/components/src/styled-components/Truncate';
import generateVisibleChannelName from './utils';

export const connectionStatus = {
  ONLINE: 'online',
  RECORDING: 'recording',
  OFFLINE: 'offline',
  PAUSED: 'paused',
};

const channelAssetTypes = {
  VIDEO: 'video',
  PHOTO: 'photo',
  AUDIO: 'audio',
  DATA: 'data',
};

const Container = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
`;

const LeftContainer = styled.div`
  display: flex;
  overflow: hidden;
`;
const ClientId = styled.span`
  margin-left: 4px;
  margin-right: 24px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(p) => p.theme.spacing.large};
  width: 50px;
  background-color: ${(p) => p.theme.color.dimGray};
  border-radius: ${(p) => p.theme.radius.medium};
  box-shadow: ${(p) => p.theme.shadow.medium};
`;

const ChannelNameContainer = styled.div`
  display: flex;
  overflow: hidden;
  ${(p) => p.theme.fontStyle.bodyM};
  padding-left: ${(p) => p.theme.spacing.units(1.5)};
  padding-top: ${(p) => p.theme.spacing.units(0.75)};
`;

const ChannelStatusContainer = styled.div`
  ${(p) => p.theme.fontStyle.bodyM};
  padding-top: ${(p) => p.theme.spacing.units(0.75)};
`;

const ProjectDeviceChannel = ({
  index,
  channel: { asset_type, device, name, status },
}) => {
  const client_id = device?.client_id || null;

  const { displayName, trailingClientId } = generateVisibleChannelName(
    name,
    client_id,
    index
  );

  const isOnline =
    status === connectionStatus.ONLINE || status === connectionStatus.RECORDING;

  const Icon = () => {
    if (isOnline) {
      switch (asset_type) {
        case channelAssetTypes.VIDEO:
        case channelAssetTypes.PHOTO:
          return <CameraOnSVG />;
        case channelAssetTypes.AUDIO:
          return <SoundOnSVG />;
        default:
          return <MetadataOnSVG />;
      }
    }

    switch (asset_type) {
      case channelAssetTypes.VIDEO:
      case channelAssetTypes.PHOTO:
        return <CameraOffSVG />;
      case channelAssetTypes.AUDIO:
        return <SoundOffSVG />;
      default:
        return <MetadataOffSVG />;
    }
  };

  return (
    <Container>
      <LeftContainer>
        <IconContainer isActivated={isOnline}>
          <Icon />
        </IconContainer>
        {client_id ? (
          <Tooltip
            shouldUsePortal
            placement="top"
            title={`ID: ${client_id}`}
            variant="light"
          >
            <ChannelNameContainer>
              <Truncate>{displayName}</Truncate>{' '}
              <ClientId>{trailingClientId}</ClientId>
            </ChannelNameContainer>
          </Tooltip>
        ) : (
          <ChannelNameContainer>
            <Truncate>{displayName}</Truncate>
          </ChannelNameContainer>
        )}
      </LeftContainer>
      <ChannelStatusContainer>{status}</ChannelStatusContainer>
    </Container>
  );
};

export default ProjectDeviceChannel;
