export const FIXED_PLAYBACK_RATES = [0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75];
export const HALF_THUMB_WIDTH = 6; // used for offset calculations
export const MIN = 0.25;
export const MAX = 1.75;
export const STEP = 0.05;
export const SLIDER_WIDTH = 296;
export const X_TRANSITION_DURATION = 0.4;

export const playbackRateLabelsVariants = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
    },
  },
  hidden: {},
  faded: {
    opacity: 0.3,
    transition: {
      type: 'tween',
      duration: 0.2,
    },
  },
};

export const playbackRateVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
  faded: {},
};

export const rangeTickWrapperVariants = {
  visible: {
    transition: {
      when: 'beforeChildren',
      delay: 0.2,
      staggerChildren: 0.07,
    },
  },
  hidden: {},
};

export const rangeTickVariants = {
  visible: {
    scaleY: 1,
    opacity: 1,
    transition: {
      scaleY: {
        type: 'spring',
        mass: 0.2,
        damping: 8,
        stiffness: 200,
      },
      opacity: {
        type: 'tween',
        duration: 0.2,
      },
    },
  },
  hidden: {
    scaleY: 0,
    opacity: 0,
  },
};

/**
 *
 * @param {number} value - original value to be mapped
 * @param {string} inMin - minimum of original range
 * @param {string} inMax - maximum of original range
 * @param {string} outMin - minimum of mapped range
 * @param {string} outMax - maximum of mapped range
 */
export const mapToRange = (value, inMin, inMax, outMin, outMax) =>
  ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;

/**
 *
 * @param {number} val - slider value
 */
export const convertRangeValueToOffset = (val) => {
  const newOffset = mapToRange(val, MIN, MAX, 0, SLIDER_WIDTH);
  return newOffset - 6; // 6 is used to offset slider thumb size
};

/**
 *
 * @param {number} value - original value to test
 * @param {number} threshold - buffer around the rates to increase range
 */
const getIsOnDefaultValue = (value, threshold = 0.025) =>
  FIXED_PLAYBACK_RATES.some(
    (rate) => value > rate - threshold && value < rate + threshold
  );

/**
 *
 * @param {Object} params
 * @param {boolean} params.isDragging - is the slider currently being dragged
 * @param {number} params.currentValue - the active value of the slider
 */
export const getThumbAnimateProps = ({ isDragging, currentValue }) => {
  let thumbScaleX = 1;
  let thumbBorderRadius = '50%';
  if (isDragging) {
    if (getIsOnDefaultValue(currentValue)) {
      thumbScaleX = 0.666;
    } else {
      thumbScaleX = 0.166;
      thumbBorderRadius = '0%';
    }
  }

  return {
    borderRadius: thumbBorderRadius,
    scaleX: thumbScaleX,
    scaleY: isDragging ? 0.666 : 1,
  };
};
