import React from 'react';
import styled from 'styled-components';
import SVGImage from 'components/AssetSlider/SliderThumbs/SVGImage';
import PropTypes from 'prop-types';

function PageNavigation({ onClick, svgIcon, visible, className, direction }) {
  const containerClasses = [
    `${direction}`,
    visible ? '' : 'is-hidden',
    className || '',
  ].join(' ');
  return (
    <div className={containerClasses} onClick={onClick} role="presentation">
      <SVGImage
        svg={svgIcon}
        width={20}
        height={20}
        backgroundColor="transparent"
      />
    </div>
  );
}

PageNavigation.LEFT = 'left';
PageNavigation.RIGHT = 'right';

PageNavigation.defaultProps = {
  svgIcon: '',
  visible: true,
  className: '',
};

PageNavigation.propTypes = {
  onClick: PropTypes.func.isRequired,
  svgIcon: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  className: PropTypes.string,
  direction: PropTypes.string.isRequired,
};
export default styled(PageNavigation)``;
