import { generateActionTypes } from 'shared/actions/helpers';

export const COMMENT_CARD = generateActionTypes('COMMENT_CARD', [
  'SET_IS_EDITING_COMMENT',
]);

export function setEditingCommentId(editingCommentId = null) {
  return {
    type: COMMENT_CARD.SET_IS_EDITING_COMMENT,
    payload: {
      editingCommentId,
    },
  };
}
