import styled from 'styled-components';
import { motion } from 'framer-motion';
import { rgba } from 'polished';

import { HALF_THUMB_WIDTH, SLIDER_WIDTH } from '../utils';

export const SliderInput = styled(motion.div)`
  align-items: center;
  background-color: ${(p) => p.theme.color.silver};
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  height: 6px;
  position: relative;
  margin: 0 6px;
  width: ${SLIDER_WIDTH}px;

  &::before,
  &::after {
    content: '';
    background-color: ${(p) => p.theme.color.silver};
    border-radius: 50px;
    height: 100%;
    position: absolute;
    width: ${HALF_THUMB_WIDTH * 2}px;
  }

  &::before {
    left: -${HALF_THUMB_WIDTH}px;
  }

  &::after {
    right: -${HALF_THUMB_WIDTH}px;
  }
`;

export const SliderInputGhost = styled(SliderInput)`
  height: 8px;
  position: absolute;
  left: 0;
  top: 2px;
`;

export const SliderThumb = styled(motion.div)`
  background-color: ${(p) => p.theme.color.brand};
  cursor: pointer;
  height: ${HALF_THUMB_WIDTH * 2}px;
  position: relative;
  width: ${HALF_THUMB_WIDTH * 2}px;
  z-index: 1;
`;

export const SliderValuePopover = styled.div`
  ${(p) => p.theme.fontStyle.bodyS};

  background-color: ${(p) => p.theme.color.almostBlack};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 ${(p) => rgba(p.theme.color.coolBlack, 0.3)};
  color: ${(p) => p.theme.color.white};
  left: 0;
  opacity: 0;
  padding: ${(p) => p.theme.spacing.micro} ${(p) => p.theme.spacing.tiny};
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: calc(${(p) => p.theme.spacing.micro} * -1);
  transition: opacity 150ms ease;
  width: ${(p) => p.theme.spacing.units(5)};
`;

export const SliderNativeInput = styled.input`
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  height: 6px;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const SliderWrapper = styled(motion.div)`
  padding: 3px 0;
  position: relative;

  ${(p) =>
    p.isDragging &&
    `
    ${SliderValuePopover} {
      opacity: 1;
      transition: opacity 250ms ease 50ms;
    }

    ${SliderThumb},
    ${SliderInput} {
      cursor: grabbing;
    }
  `};
`;
