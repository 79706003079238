import { ASSET } from '@frameio/core/src/assets/actions';
import { type as assetTypes } from '@frameio/core/src/assets/helpers/constants';
import reduceReducers from 'reduce-reducers';
import { VIEW_TYPES } from 'components/InfiniteGrid';
import ASSETS_SORT_OPTIONS from '../ProjectAssets/sortOptions';
import { PROJECT_CONTAINER } from '../actions';
import { getPlaceholderAssetId, isPlaceholderInFolder } from '../selectors';
import assetListReducer, {
  INITIAL_STATE as ASSETS_INITIAL_STATE,
} from './assetListReducer';

import manageVersionStackModalReducer, {
  initialState as MANAGE_VERSION_MODAL_INITIAL_STATE,
} from '../ManageVersionStackModal/reducer';
import peopleCircleStackReducer, {
  INITIAL_STATE as PEOPLE_CIRCLE_STACK_INITIAL_STATE,
} from '../PeopleCircleStack/reducers';
import managePeopleReducer, {
  INITIAL_STATE as MANAGE_PEOPLE_INITIAL_STATE,
} from '../ManagePeople/reducers';
import linksReducer, {
  INITIAL_STATE as LINKS_INITIAL_STATE,
} from '../ProjectLinks/reducers';
import presentationsReducer, {
  INITIAL_STATE as PRESENTATIONS_INITIAL_STATE,
} from '../ProjectPresentations/reducers';
import projectDevicesReducer, {
  INITIAL_STATE as PROJECT_DEVICES_INITIAL_STATE,
} from '../ProjectDevices/reducers';

const INITIAL_STATE = {
  projectId: undefined,
  folderId: undefined,
  selectedAssetIds: [],
  isLoaded: false,
  assetsScrollTop: 0,
  assets: ASSETS_INITIAL_STATE,
  assetsViewType: VIEW_TYPES.GRID,
  assetsSortBy: ASSETS_SORT_OPTIONS.CUSTOM.value,
  areAssetsSortDescending: false,
  placeholderAssets: {},
  peopleCircleStack: PEOPLE_CIRCLE_STACK_INITIAL_STATE,
  managePeople: MANAGE_PEOPLE_INITIAL_STATE,
  links: LINKS_INITIAL_STATE,
  presentations: PRESENTATIONS_INITIAL_STATE,
  projectDevices: PROJECT_DEVICES_INITIAL_STATE,
  isCalculatingQuicklookFolderSize: false,
  ...MANAGE_VERSION_MODAL_INITIAL_STATE,
};

function projectContainerReducer(state = INITIAL_STATE, action, rootState) {
  switch (action.type) {
    // When moving from the Project View to the Player Page, we completely
    // lose the context of what the previously viewed project was (since
    // that's in the URL params). So we persist the folderId and projectId
    // into store so that we can retrieve it when returning from the Player
    // page to the Project View. With the previously retrieved project / foler
    // Ids, we can now perform any clean up such as leaving the previous
    // project's socket room.
    case PROJECT_CONTAINER.ENTER_PROJECT_FOLDER: {
      const { projectId, folderId } = action.payload;
      return {
        ...state,
        projectId,
        folderId,
      };
    }
    case PROJECT_CONTAINER.SELECT_ASSETS: {
      const { assetIds: selectedAssetIds } = action.payload;
      return {
        ...state,
        selectedAssetIds,
      };
    }
    case PROJECT_CONTAINER.ASSETS_LOADED: {
      return {
        ...state,
        isLoaded: true,
      };
    }
    case PROJECT_CONTAINER.SET_ASSETS_SORT: {
      const {
        sortOption: { value: assetsSortBy },
        isDescending: areAssetsSortDescending,
      } = action.payload;

      return {
        ...state,
        assetsSortBy,
        areAssetsSortDescending,
      };
    }
    case PROJECT_CONTAINER.SET_ASSETS_VIEW_TYPE: {
      const { viewType: assetsViewType } = action.payload;
      return {
        ...state,
        assetsViewType,
      };
    }
    case PROJECT_CONTAINER.CREATE_ASSET_PLACEHOLDERS: {
      const { assets } = action.payload;
      return {
        ...state,
        placeholderAssets: {
          ...state.placeholderAssets,
          ...assets,
        },
        assets: assetListReducer(state.assets, action, rootState),
      };
    }
    case PROJECT_CONTAINER.SET_PLACEHOLDER_BUNDLE_CHILD_ASSET_ID: {
      const { bundlePlaceholderId, assetId } = action.payload;
      const bundle = state.placeholderAssets[bundlePlaceholderId];
      return {
        ...state,
        placeholderAssets: {
          ...state.placeholderAssets,
          [bundlePlaceholderId]: {
            ...bundle,
            childAssetIds: [...bundle.childAssetIds, assetId],
          },
        },
      };
    }
    case ASSET.CREATE_BUNDLE.SUCCESS:
    case ASSET.CREATE.SUCCESS: {
      const {
        response: {
          entities: { asset: assets },
          result,
        },
      } = action.payload;
      const { id: assetId, parent_id, index, type } = assets[result];
      const placeholderId = getPlaceholderAssetId(parent_id, index);
      // When we receive a create success action, we can drop the placeholder and rely on the
      // created asset entity.
      const {
        [placeholderId]: placeholderToDrop,
        ...remainingPlaceholderAssets
      } = state.placeholderAssets;

      if (type !== assetTypes.FOLDER) {
        return {
          ...state,
          placeholderAssets: remainingPlaceholderAssets,
          assets: assetListReducer(state.assets, action, rootState),
        };
      }

      // If the created asset is a folder, we need to update all the placeholder ids and parent_ids
      // of its children to reflect the new server-generated asset id.
      const newPlaceholderAssets = Object.values(
        remainingPlaceholderAssets
      ).reduce((map, placeholder) => {
        const isChildAsset = isPlaceholderInFolder(
          placeholder.id,
          placeholderId
        );

        if (isChildAsset) {
          const newPlaceholderId = getPlaceholderAssetId(
            assetId,
            placeholder.index
          );
          return {
            ...map,
            [newPlaceholderId]: {
              ...placeholder,
              id: newPlaceholderId,
              parent_id: assetId,
            },
          };
        }

        return {
          ...map,
          [placeholder.id]: placeholder,
        };
      }, {});

      return {
        ...state,
        placeholderAssets: newPlaceholderAssets,
        assets: assetListReducer(state.assets, action, rootState),
      };
    }
    case PROJECT_CONTAINER.SET_ASSETS_SCROLL_TOP:
      return {
        ...state,
        assetsScrollTop: action.payload.value,
      };
    case ASSET.GET_SIZE.PENDING:
      return {
        ...state,
        isCalculatingQuicklookFolderSize: true,
      };
    case ASSET.GET_SIZE.SUCCESS:
    case ASSET.GET_SIZE.FAILURE:
      return {
        ...state,
        isCalculatingQuicklookFolderSize: false,
      };
    default: {
      const newPeople = peopleCircleStackReducer(
        state.peopleCircleStack,
        action
      );
      const newAssets = assetListReducer(state.assets, action, rootState);
      const newManagePeople = managePeopleReducer(state.managePeople, action);
      const newLinks = linksReducer(state.links, action, rootState);
      const newPresentations = presentationsReducer(
        state.presentations,
        action,
        rootState
      );
      const newProjectDevices = projectDevicesReducer(
        state.projectDevices,
        action,
        rootState
      );

      if (
        newPeople !== state.peopleCircleStack ||
        newAssets !== state.assets ||
        newManagePeople !== state.managePeople ||
        newLinks !== state.links ||
        newPresentations !== state.presentations ||
        newProjectDevices !== state.projectDevices
      ) {
        return {
          ...state,
          peopleCircleStack: newPeople,
          assets: newAssets,
          managePeople: newManagePeople,
          links: newLinks,
          presentations: newPresentations,
          projectDevices: newProjectDevices,
        };
      }

      return state;
    }
  }
}

export default reduceReducers(
  projectContainerReducer,
  manageVersionStackModalReducer
);

export const testExports = {
  INITIAL_STATE,
};
