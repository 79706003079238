import { connect } from 'react-redux';
import { currentProjectSelector } from 'selectors/projects';
import { createNewFolder as onCreateNewFolder } from 'actions/assets';
import { moveToFolderSelector } from './selectors';
import {
  FolderCreationEvents,
  setFolderCreationStatus as onSetFolderCreationStatus,
} from './actions';
import TreeViewItemPhantom from './TreeViewItemPhantom';

function mapStateToProps(state) {
  const currentProject = currentProjectSelector(state);
  const {
    isSaving,
    folderCreationState,
    selectedTreeItem: { createdId },
  } = moveToFolderSelector(state);
  return {
    isSaving,
    currentProject: currentProject.id,
    isFetchingNewFolderTree: !!createdId,
    isNewFolderSaving: FolderCreationEvents.IS_SAVING === folderCreationState,
  };
}

const mapDispatchToProps = {
  onCreateNewFolder,
  onSetFolderCreationStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TreeViewItemPhantom);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
