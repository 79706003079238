import React from 'react';
import PropTypes from 'prop-types';
import UnconfirmedEmailLockout from 'components/UnconfirmedEmailLockout';
import { redirectToAuth } from 'utils/router';
import Flex from 'styled-flex-component';
import ReviewLinkUnavailable from 'pages/ReviewLinkContainer/ReviewLinkUnavailable';
import PasswordPrompt from 'pages/ReviewLinkContainer/PasswordPrompt';
import { getRedirectTrackingOpts } from 'analytics';
import { FAILURE_REASONS } from './failureReasons';

export const ENTITY_TYPE = {
  ASSET: 'asset',
  PRESENTATION: 'presentation',
  PROJECT: 'project',
  REVIEW_LINK: 'review link',
};

export default function AuthGate({
  failureReason,
  entityId,
  entityType,
  onPasswordSubmit,
}) {
  switch (failureReason) {
    case FAILURE_REASONS.EMAIL_UNCONFIRMED:
      return (
        <Flex center full>
          <UnconfirmedEmailLockout reason={FAILURE_REASONS.EMAIL_UNCONFIRMED} />
        </Flex>
      );
    case FAILURE_REASONS.NOT_LOGGED_IN:
      redirectToAuth(
        null,
        true,
        getRedirectTrackingOpts({
          details:
            'Visited private presentation or review link while logged out',
        })
      );
      return null;
    case FAILURE_REASONS.PASSWORD_REQUIRED:
      return (
        <PasswordPrompt
          reviewLinkId={entityId}
          onPasswordSubmit={onPasswordSubmit}
        />
      );
    default:
      return (
        <ReviewLinkUnavailable reason={failureReason} entityType={entityType} />
      );
  }
}

AuthGate.defaultProps = {
  entityId: null,
  entityType: undefined,
  onPasswordSubmit: undefined,
};

AuthGate.propTypes = {
  failureReason: PropTypes.oneOf(Object.values(FAILURE_REASONS)).isRequired,
  entityId: PropTypes.string,
  entityType: PropTypes.oneOf(Object.values(ENTITY_TYPE)),
  onPasswordSubmit: PropTypes.func,
};
