import React, { useState, useRef, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { map, get, has } from 'lodash';
import styled from 'styled-components';
import { showSuccessToast, removeToastsBy } from 'actions/toasts';
import { DATA_POINTS } from 'pages/WatermarkTemplateEditor/constants';
import TextInput from '@frameio/components/src/styled-components/TextInput';
import Spinner from '@frameio/components/src/styled-components/SpinnerBase';

const StyledTextInput = styled(TextInput)`
  margin: ${(p) => p.theme.spacing.units(1)} 0;
`;

// we have an entity with many watermark blocks, each with many data points, any one of which
// may be a custom text point.
export const doesTemplateContainCustomUserInputSelector = (entity) =>
  map(entity.watermark_blocks, (e) => get(e, 'data_points')).some(
    (dataPointsByKey) => has(dataPointsByKey, DATA_POINTS.USER_INPUT)
  );

const AUTOSAVE_INTERVAL = 500;

const TemplateCustomText = ({ showToasts, sessionWatermarkEntity }) => {
  const {
    submitForm,
    setFieldValue,
    values: { watermarkUserInput },
    errors: { watermarkUserInput: watermarkUserInputError },
  } = useFormikContext();
  const [showSpinner, setShowSpinner] = useState(false);
  const showCustomTextAffordance = doesTemplateContainCustomUserInputSelector(
    sessionWatermarkEntity
  );

  const dispatch = useDispatch();
  const submissionTimeout = useRef(null);

  // autosave the input text as the user types (after a brief pause). We keep a reference to the
  // last timeout around so the user doesn't see a bunch of submissions as they type
  const onChangeText = useCallback(
    (e) => {
      setFieldValue('watermarkUserInput', e.target.value);

      submissionTimeout.current && clearTimeout(submissionTimeout.current);
      submissionTimeout.current = setTimeout(() => {
        // don't submit if there is an error
        if (watermarkUserInputError) {
          return;
        }

        submitForm();
        setShowSpinner(true);

        setTimeout(() => {
          setShowSpinner(false);
          if (showToasts) {
            const header = 'Watermark successfully applied!';
            dispatch(removeToastsBy({ header }));
            dispatch(showSuccessToast({ header }));
          }
        }, 1000);
      }, AUTOSAVE_INTERVAL);
    },
    // we don't care about other deps and they were making this function lag when typing quickly
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watermarkUserInputError]
  );

  if (!showCustomTextAffordance) {
    return null;
  }

  return (
    <React.Fragment>
      <StyledTextInput
        name="watermarkUserInput"
        placeholder="Type here to add text to watermark"
        onChange={onChangeText}
        value={watermarkUserInput}
        error={watermarkUserInputError}
        icon={
          showSpinner && (
            <Spinner
              color="91,83,255"
              size={24}
              spinning={showSpinner}
              radius={10}
              stroke={2}
            />
          )
        }
        iconAlignment="right"
      />
    </React.Fragment>
  );
};

export default TemplateCustomText;
