import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import Dialog, {
  dialogTypes,
} from '@frameio/components/src/styled-components/Dialog';
import Button from '@frameio/components/src/styled-components/Button';
import StorageLimitIllustration from '@frameio/components/src/svgs/illustrations/storage-limit.svg';
import { noop } from 'lodash';

const StorageLimitIllustrationContainer = styled(Flex)`
  margin-top: ${(p) => p.theme.spacing.tiny};
  margin-bottom: ${(p) => p.theme.spacing.medium};
`;

const Copy = styled.p`
  color: ${(p) => p.theme.color.gray};
  text-align: center;
  padding: 0 10px;
`;

export default function OutOfArchivalSpace({ onCancel, onContinue }) {
  return (
    <Dialog
      type={dialogTypes.NONE}
      header="Add more archival storage"
      primaryButton={
        <Button primary onClick={onContinue}>
          Add more storage
        </Button>
      }
      secondaryButton={<Button onClick={onCancel}>No thanks</Button>}
    >
      <StorageLimitIllustrationContainer justifyCenter>
        <StorageLimitIllustration />
      </StorageLimitIllustrationContainer>
      <Copy>
        Glad to see you’re enjoying Frame.io! Looks like you’re out of archival
        storage space, but you can keep archiving with storage add-ons.
      </Copy>
    </Dialog>
  );
}

OutOfArchivalSpace.propTypes = {
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
};

OutOfArchivalSpace.defaultProps = {
  onCancel: noop,
  onContinue: noop,
};
