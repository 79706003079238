/**
 * The default team image.
 */
export const DEFAULT_TEAM_IMAGE =
  'https://static-assets.frame.io/default/team.png';

/**
 * The default user image.
 */
export const ANON_AVATAR_URL = 'https://static-assets.frame.io/app/anon.jpg';
