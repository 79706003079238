import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Flex from 'styled-flex-component';
import SimplePopoverable from '@frameio/components/src/components/SimplePopoverable';
import CheckIcon from '@frameio/components/src/svgs/icons/check.svg';
import PopoverMenu, {
  PopoverMenuItem,
  PopoverMenuList,
  PopoverMenuFooter,
} from '@frameio/components/src/styled-components/PopoverMenu';
import DropdownButton from '@frameio/components/src/styled-components/Button/DropdownButton';
import { TEAM_MEMBER_VS_COLLABORATOR_SUPPORT_URL } from 'URLs';
import { trackClick } from '../../tracking';

const Description = styled.span`
  font-size: 12px;
  line-height: 18px;
  color: ${(p) => p.theme.color.graphiteGray};
  white-space: normal;
  text-align: left;
`;

const RoleOption = styled(Flex).attrs(() => ({
  full: true,
  column: true,
  alignStart: true,
}))`
  padding: ${(p) => p.theme.spacing.tiny};

  strong {
    ${(p) => p.theme.fontStyle.body};
    line-height: ${(p) => p.theme.spacing.units(3)};
  }
`;

const LearnMoreLink = styled.a`
  display: block;
  width: 100%;
  text-align: center;
  color: ${(p) => p.theme.color.brand};
`;

const buttonStyles = css`
  font-size: ${(p) => p.theme.fontSize[0]};
  font-weight: bold;
  text-align: right;
  color: ${(p) => p.theme.color.gray};
`;

export const RoleButton = styled(DropdownButton)`
  ${buttonStyles}
`;

export const DisabledRole = styled(DropdownButton).attrs(() => ({
  compact: true,
  text: true,
  disabled: true,
}))`
  ${buttonStyles}

  svg {
    visibility: hidden;
  }
`;

const Check = styled(CheckIcon).attrs(() => ({
  width: 18,
  height: 18,
}))`
  color: ${(p) => p.theme.color.brand};
`;

function RolePopover({
  isTeamMember,
  isPendingCollaborator,
  disabled,
  className,
  onAddToTeam,
}) {
  const isCollaborator = !isTeamMember && !isPendingCollaborator;
  const isRoleEditable = !isPendingCollaborator;
  const roleTitle = isTeamMember ? 'Team member' : 'Collaborator';
  const learnMoreURL = TEAM_MEMBER_VS_COLLABORATOR_SUPPORT_URL;

  if (disabled || !isRoleEditable) {
    return <DisabledRole className={className}>{roleTitle}</DisabledRole>;
  }

  return (
    <SimplePopoverable
      position="bottom"
      popover={
        <PopoverMenu
          autoAdjust={false}
          fixedWidth={272}
          pointerHeight={0}
          trackingPage="dashboard"
          trackingPosition="manage-people-modal"
        >
          <PopoverMenuList
            id="ManagePeopleRole"
            footer={
              <PopoverMenuFooter>
                <LearnMoreLink
                  href={learnMoreURL}
                  target="_blank"
                  onClick={() => trackClick('learn_about_roles')}
                >
                  Learn more about Frame.io roles
                </LearnMoreLink>
              </PopoverMenuFooter>
            }
          >
            {/* Team Member */}
            <PopoverMenuItem
              data-test-id="team-member-menuitem"
              hideSubmenuArrow
              disabled={isTeamMember}
              onClick={() => {
                onAddToTeam();
                trackClick('change_role_to_team_member');
              }}
            >
              <RoleOption>
                <strong>Team member</strong>
                <Description>
                  Can access all team projects, see private assets and comments,
                  share content, and invite collaborators.
                </Description>
              </RoleOption>
              {isTeamMember && <Check />}
            </PopoverMenuItem>

            {/* Collaborator */}
            <PopoverMenuItem
              data-test-id="collaborator-menuitem"
              hideSubmenuArrow
              disabled={isTeamMember}
            >
              <RoleOption>
                <strong>Collaborator</strong>
                <Description>
                  Has single-project access with limited capabilities.
                </Description>
              </RoleOption>
              {isCollaborator && <Check />}
            </PopoverMenuItem>
          </PopoverMenuList>
        </PopoverMenu>
      }
    >
      <RoleButton
        className={className}
        compact
        text
        onClick={() => trackClick('open_role_dropdown')}
      >
        {roleTitle}
      </RoleButton>
    </SimplePopoverable>
  );
}

RolePopover.propTypes = {
  isTeamMember: PropTypes.bool,
  isPendingCollaborator: PropTypes.bool,
  disabled: PropTypes.bool,
  onAddToTeam: PropTypes.func,
  className: PropTypes.string,
};

RolePopover.defaultProps = {
  isTeamMember: false,
  isPendingCollaborator: false,
  onAddToTeam: () => {},
  disabled: false,
  className: undefined,
};

export const testExports = { DisabledRole, Check, RoleButton, LearnMoreLink };
export default RolePopover;
