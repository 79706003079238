import { connect } from 'react-redux';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import { userEntitiesSelector } from '@frameio/core/src/users/selectors';
import { closeModal } from 'components/Modal/actions';
import { currentAccountSelector } from 'selectors/accounts';

import EditInvoiceSettings from './EditInvoiceSettings';
import { updateInvoiceSettings } from './actions';

const mapDispatchToProps = {
  closeModal,
  onSubmit: updateInvoiceSettings,
};

function mapStateToProps(state) {
  const currentAccount = currentAccountSelector(state);
  const account =
    accountEntitySelector(state, { accountId: currentAccount.id }) || {};
  const owner = (userEntitiesSelector(state) || {})[account.owner_id] || {};
  const emails =
    account.invoice_emails && account.invoice_emails.length
      ? account.invoice_emails
      : [owner.email];
  const address = account?.line1 || account?.company_address || '';

  return {
    accountId: account.id || '',
    name: account.company_name || '',
    address,
    emails,
    valueAddedTax: account.vat || '',
  };
}

export const testExports = { mapDispatchToProps, mapStateToProps };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditInvoiceSettings);
