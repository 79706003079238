import { select } from 'redux-saga/effects';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import { subscriptionEntityForAccountIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { isAccountEnterpriseSelector } from 'selectors/accounts';

export function* hasSpaceToAddAssets(accountId, bytesToUpload) {
  const isEnterprise = yield select(isAccountEnterpriseSelector);
  if (isEnterprise) return true;

  const account = yield select(accountEntitySelector, { accountId });
  const subscription = yield select(subscriptionEntityForAccountIdSelector, {
    accountId,
  });

  const totalPlanStorageInBytes = subscription.total_storage_limit;

  // Exit early for unlimited plans. They can always upload.
  if (totalPlanStorageInBytes === null) return true;

  const proposedStorageSize = account.storage + bytesToUpload;
  return proposedStorageSize < totalPlanStorageInBytes;
}

export function* hasSpaceToArchive(accountId, bytesToArchive) {
  const isEnterprise = yield select(isAccountEnterpriseSelector);
  if (isEnterprise) return true;

  const account = yield select(accountEntitySelector, { accountId });
  const subscription = yield select(subscriptionEntityForAccountIdSelector, {
    accountId,
  });

  const totalArchivalStorage = subscription.total_archived_storage_limit;
  const totalArchivalStorageUsed = account.archived_storage;

  // Exit early for unlimited plans. They can always archive.
  if (totalArchivalStorage === null) return true;

  const proposedArchivalStorage = totalArchivalStorageUsed + bytesToArchive;

  return proposedArchivalStorage < totalArchivalStorage;
}
