import React from 'react';
import { connect } from 'react-redux';
import { groupEntitySelector } from '@frameio/core/src/groups/selectors';
import { userEntitySelector } from '@frameio/core/src/users/selectors';
import { teamEntitySelector } from '@frameio/core/src/teams/selectors';
import ListCell from '@frameio/components/src/styled-components/ListCell';
import Avatar from '@frameio/components/src/styled-components/Avatar';
import EmojiBadge from 'components/EmojiBadge';
import { TYPE as USER_SEARCH_TYPE } from './UserSearch';

function mapStateToProps(state, { id: entityId, type }) {
  switch (type) {
    case USER_SEARCH_TYPE.USER: {
      const { user_default_color: color, image_64: image, email, name } =
        userEntitySelector(state, { userId: entityId }) || {};

      return {
        image: <Avatar image={image} size={32} color={color} name={name} />,
        title: name,
        details: email,
      };
    }

    case USER_SEARCH_TYPE.PENDING_REVIEWER: {
      return {
        image: <Avatar image="" size={32} color="green" name={entityId} />,
        title: entityId,
        details: 'External',
      };
    }

    case USER_SEARCH_TYPE.TEAM: {
      const { name, image_64: image, member_count: memberCount } =
        teamEntitySelector(state, { teamId: entityId }) || {};
      const details = `${memberCount || 0} team member${
        memberCount === 1 ? '' : 's'
      }`;
      return {
        image: <Avatar image={image} size={32} name={name} />,
        title: name,
        details,
      };
    }

    case USER_SEARCH_TYPE.GROUP: {
      const { name, emoji, user_count: memberCount } = groupEntitySelector(
        state,
        { groupId: entityId }
      );
      const details = `${memberCount || 0} group ${
        memberCount === 1 ? 'member' : 'members'
      }`;
      const image = emoji ? (
        <EmojiBadge emoji={emoji} />
      ) : (
        <Avatar size={32} name={name} />
      );

      return {
        image,
        title: name,
        details,
      };
    }

    default:
      return { title: '', details: '' };
  }
}

export const testExports = { mapStateToProps };
export default connect(mapStateToProps)(ListCell);
