import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MenuButton, Tooltip } from '@frameio/vapor';
import ArchiveIcon from '@frameio/components/src/svgs/icons/16/archive.svg';
import { trackButtonClick } from 'analytics';
import PremiumBadge from '@frameio/components/src/styled-components/PremiumBadge';

const StyledPremiumBadge = styled(PremiumBadge)`
  margin-left: ${(p) => p.theme.spacing.tiny};
`;

const StyledMenuButton = styled(MenuButton)`
  opacity: ${({ disable }) => (disable ? '0.3' : '1')};
`;

export default function ArchiveProject({
  accountId,
  isAdmin,
  projectId,
  onArchiveProjectClick,
  trackingPage,
  trackingPosition,
  canArchiveProject,
  isAccountOnFreePlanWithUserMax,
  ...rest
}) {
  const onSelect = () => {
    onArchiveProjectClick(projectId);
    trackButtonClick('archive-project', trackingPage, trackingPosition);
  };

  const shouldShowUpgradeBadge =
    isAccountOnFreePlanWithUserMax && !canArchiveProject;
  const shouldDisableOption = !isAdmin && shouldShowUpgradeBadge;

  return (
    <StyledMenuButton
      disable={shouldDisableOption}
      onSelect={onSelect}
      iconBefore={<ArchiveIcon />}
      {...rest}
    >
      Archive project
      {shouldShowUpgradeBadge && (
        <Tooltip
          details={isAdmin && 'Click to learn more.'}
          offset={[0, 10]}
          placement="top"
          shouldUsePortal
          title="Available on Pro ✨"
          variant="dark"
        >
          <StyledPremiumBadge>Upgrade</StyledPremiumBadge>
        </Tooltip>
      )}
    </StyledMenuButton>
  );
}

ArchiveProject.defaultProps = {
  projectId: '',
  onArchiveProjectClick: () => {},
};

ArchiveProject.propTypes = {
  isAdmin: PropTypes.bool,
  isV5FreePlan: PropTypes.bool,
  projectId: PropTypes.string,
  onArchiveProjectClick: PropTypes.func,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};
