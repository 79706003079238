import React from 'react';
import PropTypes from 'prop-types';
import { WithTooltip } from '@frameio/components';
import classNames from 'classnames';

/**
 * @param   {Boolean} hasBorder - React prop.
 * @returns {string} - ClassNames for border on bottom of row.
 */
function getBorderClassNames(hasBorder) {
  return classNames('flex flex-row justify-between items-center pv3 mh4', {
    'bb b--fio-gray': hasBorder,
  });
}

/**
 * @param   {Boolean} hasSubtext - React prop.
 * @returns {string} - ClassNames for text container.
 */
function getTextContainerClassNames(hasSubtext) {
  return classNames(
    'flex flex-row',
    { 'items-center': !hasSubtext },
    { 'items-top': hasSubtext }
  );
}

/**
 * Row With Switch Component.
 * @param   {Object} props - The props.
 * @returns {ReactElement} The component.
 */
function RowWithSwitch(props) {
  const rowJSX = (
    <div className={`${props.className} relative row-with-switch`}>
      {props.isDisabled && (
        <div className="absolute h-100 w-100 bg-fio-white-gray o-40" />
      )}
      <div className={getBorderClassNames(props.hasBorder)}>
        <div className={getTextContainerClassNames(props.subtext)}>
          {props.icon && (
            <div className="mr2 reset-container">{props.icon}</div>
          )}

          <div>
            <div className="f5 lh-solid">{props.text}</div>
            {props.subtext && (
              <div className="mt1 f6 silver">{props.subtext}</div>
            )}
          </div>
        </div>
        {props.switchComponent}
      </div>
      {props.expansionPack && (
        <div className="mb3 mh4 animation-slide-down animation-duration-015 transform-origin-center-top">
          {props.expansionPack}
        </div>
      )}
    </div>
  );

  if (!props.tooltipText) {
    return rowJSX;
  }

  return (
    <WithTooltip message={props.tooltipText} placement="top">
      {rowJSX}
    </WithTooltip>
  );
}

/**
 * text - Row text.
 * isOn - Whether or not the switch is on.
 * onChange - function passed to Switch.
 * subtext - Row subheader information.
 * isDisabled - Gray overlay, disable switch.
 * tooltipText - Text inside of tooltip.
 * switchType - Private/Public switch or no text switch.
 * icon - SVG component.
 * expansionPack - Hidden text that expands the row.
 * @type {Object}
 */
RowWithSwitch.propTypes = {
  className: PropTypes.string,
  expansionPack: PropTypes.node,
  hasBorder: PropTypes.bool,
  icon: PropTypes.node,
  isDisabled: PropTypes.bool,
  subtext: PropTypes.string,
  switchComponent: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
};

RowWithSwitch.defaultProps = {
  className: '',
  expansionPack: null,
  hasBorder: false,
  icon: null,
  isDisabled: false,
  subtext: null,
  tooltipText: null,
};

export default RowWithSwitch;
