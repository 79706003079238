import { createAction } from 'redux-actions';
import {
  generateFetchActionTypes,
  generateActionTypes,
} from 'shared/actions/helpers';
import { isFetchingActionFactory } from 'factories/isFetching';

const MODULE = 'NOTIFICATIONS';
export const NOTIFICATIONS = {
  ...generateFetchActionTypes(MODULE, [
    'FETCH',
    'FETCH_MORE',
    'READ',
    'UNREAD',
    'READ_ALL',
  ]),
  ...generateActionTypes(MODULE, [
    'IS_FETCHING',
    'IS_FETCHING_MORE',
    'SET_HAS_OPENED_POPOVER',
    'SET_NEW_NOTIFICATIONS_COUNT_FOR_ACCOUNT',
    'SET_FILTER',
    'SET_RESULTS_FOR_ACCOUNT',
    'UPDATE_UNREAD_RESULTS_FOR_ACCOUNT',
  ]),
};

export const isFetching = isFetchingActionFactory(NOTIFICATIONS.IS_FETCHING);

/**
 * Creates Redux action for getting notifications by account.
 *
 * @returns {Object} - Redux Action.
 */
export const fetchNotifications = createAction(
  NOTIFICATIONS.FETCH.BASE,
  () => ({})
);

/**
 * Creates Redux action for getting subsequent pages of
 * notifications by account.
 *
 * @returns {Object} - Redux Action.
 */
export const fetchMoreNotifications = createAction(
  NOTIFICATIONS.FETCH_MORE.BASE,
  () => ({})
);

/**
 * Creates Redux action for storing results and page info.
 *
 * @returns {Object} - Redux Action.
 */
export const setResultsForAccount = createAction(
  NOTIFICATIONS.SET_RESULTS_FOR_ACCOUNT,
  ({ accountId, filter, pageInfo, results, mergeResults = false }) => ({
    accountId,
    filter,
    pageInfo,
    results,
    mergeResults,
  })
);

/**
 * Creates Redux action for marking notifications as read.
 *
 * @returns {Object} - Redux Action.
 */
export const markNotificationsRead = createAction(
  NOTIFICATIONS.READ.BASE,
  (notificationId) => ({
    notificationId,
  })
);

/**
 * Creates Redux action for marking notifications as read.
 *
 * @returns {Object} - Redux Action.
 */
export const markNotificationsUnread = createAction(
  NOTIFICATIONS.UNREAD.BASE,
  (notificationId) => ({
    notificationId,
  })
);

/**
 * Creates Redux action for removing a notification id from the
 * unread results once a user has marked it as read
 *
 * @returns {Object} - Redux Action.
 */
export const updateUnreadResultsForAccount = createAction(
  NOTIFICATIONS.UPDATE_UNREAD_RESULTS_FOR_ACCOUNT,
  ({ accountId, notificationId, results }) => ({
    accountId,
    notificationId,
    results,
  })
);

export const setIsFetchingMore = createAction(
  NOTIFICATIONS.IS_FETCHING_MORE,
  (accountId, isFetchingMore) => ({ accountId, isFetchingMore })
);

/**
 * Creates Redux action for marking all notifications as read.
 *
 * @returns {Object} - Redux Action.
 */
export const markNotificationsAllRead = createAction(
  NOTIFICATIONS.READ_ALL.BASE,
  (mostRecentNotificationId) => ({
    mostRecentNotificationId,
  })
);

export const setHasOpenedNotificationsPopover = createAction(
  NOTIFICATIONS.SET_HAS_OPENED_POPOVER,
  (accountId) => ({ accountId })
);

export const setNewNotificationsCountForAccount = createAction(
  NOTIFICATIONS.SET_NEW_NOTIFICATIONS_COUNT_FOR_ACCOUNT,
  (accountId, newNotificationsCount) => ({
    accountId,
    newNotificationsCount,
  })
);

export const setNotificationsFilter = createAction(
  NOTIFICATIONS.SET_FILTER,
  (filter) => ({ filter })
);
