import { createAction } from 'redux-actions';
import {
  generateActionTypes,
  generateFetchActionTypes,
} from 'shared/actions/helpers';
import { isFetchingActionFactory } from 'factories/isFetching';

const module = 'MANAGE_PEOPLE';
export const MANAGE_PEOPLE = {
  ...generateActionTypes(module, [
    'TOGGLE_OPEN',
    'FETCH_USERS',
    'INVITE_USERS',
    'IS_INVITING_USERS',
    'REMOVE_COLLABORATOR',
    'REMOVE_PENDING_COLLABORATOR',
    'UPDATE_SHARE_LINK',
    'IS_UPDATING_SHARE_LINK',
    'COPY_CLIPBOARD',
    'ADD_TO_TEAM',
    'RESET_RESEND_INVITES',
    'SET_PEOPLE_LIST_SCROLL_TOP',
    'SET_REDUX_TOKENS',
  ]),
  ...generateFetchActionTypes(module, [
    'FILTER_COLLABORATORS', // pending and existing collaborators
    'RESEND_INVITE',
  ]),
};

/**
 * Creates an action to fetch collaborators and pendingUsers of a project.
 * @param {string} projectId - Id of the requested project
 * @param {number} page - Page number to fetch. Defaults to 1.
 * @param {number} pageSize - Amount to fetch per page. Defaults to 50.
 * @param {string} sort - Collaborator property to sort the result by.
 * Defaults to most recently added.
 * @returns {Action} Action with payload
 */
export const fetchUsers = createAction(
  MANAGE_PEOPLE.FETCH_USERS,
  (projectId, page = 1, pageSize = 50, sort = '-inserted_at') => ({
    projectId,
    page,
    pageSize,
    sort,
  })
);

/**
 * Search for pending and existing collabs on a project.
 * Pending collaborators are only searched by email.
 * @param {string} projectId - The project's id
 * @param {string} query -  The search string
 * @returns {Object} - Action object.
 */
export const searchCollaboratorsInProject = createAction(
  MANAGE_PEOPLE.FILTER_COLLABORATORS.BASE,
  (projectId, query) => ({ projectId, query })
);

/**
 * Invite users with their email or userId as collaborators.
 * On success, it will invoke closeModal() and show a success toast
 * @param {string} projectId - The project's id.
 * @param {array} tokens - An array of userIds or emails.
 * @returns {Object} - Action object.
 */
export const inviteUsersToProject = createAction(
  MANAGE_PEOPLE.INVITE_USERS,
  (projectId, tokens, message) => ({ projectId, tokens, message })
);

/**
 * Resends an invitation to (mostly pending-) collaborators.
 * @param {string} projectId - The project's id.
 * @param {string} email - The collab's email.
 * @returns {Object} - Action object.
 */
export const resendInvite = createAction(
  MANAGE_PEOPLE.RESEND_INVITE.BASE,
  (projectId, email) => ({ projectId, email })
);

/**
 * Empties the `resendInviteEmails` slice when mounting the ManagePeople modal.
 * @returns {Object} - Action object.
 */
export const resetResendInvites = createAction(
  MANAGE_PEOPLE.RESET_RESEND_INVITES,
  () => ({})
);

/**
 * An action that sets the fetching state when inviting users as collabs to a project.
 */
export const setIsInvitingUsers = isFetchingActionFactory(
  MANAGE_PEOPLE.IS_INVITING_USERS
);

/**
 * An action that sets the fetching state when updating the project shared status.
 */
export const setIsUpdatingShareLink = isFetchingActionFactory(
  MANAGE_PEOPLE.IS_UPDATING_SHARE_LINK
);

/**
 * Toggles the ManagePeople modal open or close
 * @param {boolean} isOpen - Whether the modal is open or not.
 * @returns {Action} - Action object.
 */
export const toggleOpen = createAction(MANAGE_PEOPLE.TOGGLE_OPEN, (isOpen) => ({
  isOpen,
}));

/**
 * Removes a single collaborator from a project.
 * @param {string} collaboratorId - The collaborator's id.
 * @param {string} name - The user's name.
 * @returns {Action} - Action object.
 */
export const removeCollaborator = createAction(
  MANAGE_PEOPLE.REMOVE_COLLABORATOR,
  (collaboratorId, name) => ({ collaboratorId, name })
);

/**
 * Removes a single pending collaborator from a project.
 * @param {string} pendingCollaboratorId - The collaborator's id.
 * @param {string} email - The pending collaborator's email.
 * @returns {Action} - Action object.
 */
export const removePendingCollaborator = createAction(
  MANAGE_PEOPLE.REMOVE_PENDING_COLLABORATOR,
  (pendingCollaboratorId, email) => ({ pendingCollaboratorId, email })
);

/**
 * Enables/disables the project share link
 * @param {string} projectId - The project's id.
 * @param {boolean} isEnabled - Determines whether the
 * project sharing link is activated.
 */
export const updateProjectShareLink = createAction(
  MANAGE_PEOPLE.UPDATE_SHARE_LINK,
  (projectId, isEnabled) => ({ projectId, isEnabled })
);

/**
 * Adds a user to a team by email.
 * @param {string} userId - The user's id.
 * @param {string} email - The user's email.
 */
export const addToTeam = createAction(
  MANAGE_PEOPLE.ADD_TO_TEAM,
  (userId, email) => ({ userId, email })
);

/**
 * Copies a text to the clipboard and shows a success toast
 * @param {string} text - The string to copy.
 */
export const copyToClipboard = createAction(
  MANAGE_PEOPLE.COPY_CLIPBOARD,
  (text) => ({ text })
);

/**
 * Stores the people list scrollTop position in the store
 * so the scroll position can be recovered if the modal re-opens.
 * @param {number} scrollTop - scrollTop value of the PeopleList
 */
export const setPeopleListScrollTop = createAction(
  MANAGE_PEOPLE.SET_PEOPLE_LIST_SCROLL_TOP,
  (scrollTop) => ({ scrollTop })
);

/**
 * Sets user tokens from the user search input in the redux store
 * @param {Array} tokens - an array of user tokens
 * @returns {Action} - Action object.
 */
export const setReduxTokens = createAction(
  MANAGE_PEOPLE.SET_REDUX_TOKENS,
  (tokens) => ({
    tokens,
  })
);
