import React from 'react';
import track from 'analytics';
import { Checkbox } from '@frameio/vapor';
import DesktopAppModalBase, {
  StyledButton,
  CheckboxWrapper,
} from '../sharedComponents';
import { DOWNLOAD_IN_DESKTOP_APP } from '../constants';

export default function DownloadFolders({
  isAnonymousUser,
  onDownloadInApp,
  onDownloadInBrowser,
  onCancel,
}) {
  const [rememberChoice, setRememberChoice] = React.useState(false);

  const getTrackingOptions = () => ({
    modal: 'Downloading Folders?',
    is_save_preference_checked: rememberChoice,
  });

  const cancel = () => {
    onCancel();
    track('download-with-transfer-modal-closed');
  };

  return (
    <DesktopAppModalBase title="Downloading Folders?" onClose={cancel}>
      <React.Fragment>
        <span>
          Preserve your folder structure by downloading with Frame.io Transfer
          for Mac and Windows. Otherwise, you can download flat files in the
          browser.
        </span>
        {!isAnonymousUser && (
          <CheckboxWrapper onClick={() => setRememberChoice(!rememberChoice)}>
            <Checkbox
              label="Always use this download method"
              checked={rememberChoice}
            />
            <span>Always use this download method</span>
          </CheckboxWrapper>
        )}
        <StyledButton
          primary
          onClick={() => {
            if (rememberChoice)
              localStorage.setItem(DOWNLOAD_IN_DESKTOP_APP, true);
            onDownloadInApp();
            track(
              'download-with-transfer-button-clicked',
              getTrackingOptions()
            );
          }}
          data-test-id="download-with-app-button"
        >
          Download with app
        </StyledButton>
        <StyledButton
          text
          onClick={() => {
            if (rememberChoice)
              localStorage.setItem(DOWNLOAD_IN_DESKTOP_APP, false);
            onDownloadInBrowser();
            track('button-clicked-download-with-browser', getTrackingOptions());
          }}
          data-test-id="download-with-browser-button"
        >
          Download flat files
        </StyledButton>
      </React.Fragment>
    </DesktopAppModalBase>
  );
}

export const testExports = { CheckboxWrapper };
