import { searchAssetsInAccount } from '@frameio/core/src/assets/services';
import { searchProjects } from '@frameio/core/src/projects/services';

const ARCHIVED_STATUS = 'archived';

/**
 * Query for projects. Returns normalized
 * paginated results.
 * @param {Array[teams]} teams teams to query
 */

export async function queryProjects(
  term,
  accountId,
  teams,
  page = 1,
  pageSize = 10
) {
  const teamIds = teams.map((team) => team.id);
  const results = await searchProjects(term, accountId, teamIds, {
    includeSharedProjects: true,
    page,
    pageSize,
  });

  const {
    result,
    entities: { project, team },
  } = results;

  const denormalizedResponse = result.reduce((accumulatedResult, id) => {
    const currentProject = project[id] || {};
    // TODO: implement filtering on the backend
    const isProjectArchived = currentProject.archive_status === ARCHIVED_STATUS;
    if (!isProjectArchived) {
      accumulatedResult.push({
        ...currentProject,
        team: {
          ...team[currentProject.team],
        },
      });
    }

    return accumulatedResult;
  }, []);

  const { totalPages, currentPage } = results.headers;

  return {
    results: denormalizedResponse,
    hasNextPage: currentPage !== totalPages,
    currentPage,
  };
}

/**
 * Query for folders. Returns normalized
 * paginated results.
 */

export async function queryFolders(
  term,
  accountId,
  limitToProjectId,
  page = 1,
  pageSize = 10
) {
  const filters = {
    type: 'folder',
  };

  // optionally limit query folder search to a project
  if (limitToProjectId) {
    filters.project_id = limitToProjectId;
  }

  const results = await searchAssetsInAccount(term, accountId, {
    page,
    pageSize,
    includeTeam: true,
    filters,
  });

  const {
    result,
    entities: { project, asset },
  } = results;

  // denormalize the response so that it properly embeds
  // the required assets (project, parent, team)
  const denormalizedResponse = result.reduce((accumulatedResult, id) => {
    const currentAsset = asset[id];
    // TODO: implement filtering on the backend
    const isFolderInArchivedProject =
      project[currentAsset.project].archive_status === ARCHIVED_STATUS;
    if (!isFolderInArchivedProject) {
      accumulatedResult.push({
        ...currentAsset,
        project: {
          ...project[currentAsset.project],
        },
        parent: {
          ...asset[currentAsset.parent],
        },
      });
    }

    return accumulatedResult;
  }, []);

  const { totalPages, currentPage } = results.headers;

  return {
    results: denormalizedResponse,
    hasNextPage: currentPage !== totalPages,
    currentPage,
  };
}

/**
 * Query teams using naive string matching on the client
 * @param {Array} teamList a list of teams accessibile to the user
 * @param {String} query
 * @param {Number} limit
 */

export function queryTeams(teamList, query, page = 1, pageSize = 10) {
  const filteredTeams = teamList.filter(
    (team) => team.name.toLowerCase().indexOf(query.toLowerCase()) > -1
  );

  const initialIndex = (page - 1) * pageSize;
  const lastIndex = initialIndex + pageSize;
  const paginatedResults = filteredTeams.slice(initialIndex, lastIndex);

  return {
    results: paginatedResults,
    hasNextPage: page * pageSize < filteredTeams.length,
    currentPage: page,
  };
}
