import React from 'react';
import {
  takeLatest,
  put,
  select,
  race,
  take,
  call,
  spawn,
} from 'redux-saga/effects';
import {
  acceptJoinRequest as acceptJoinRequestFromCore,
  declineJoinRequest as declineJoinRequestFromCore,
  resetJoinRequest,
  markJoinRequestsRead,
} from '@frameio/core/src/joinRequests/sagas';
import { canAddTeamMemberSelector } from 'components/AddSeatsFlow/selectors';
import AddSeatsFlow from 'components/AddSeatsFlow';
import { ADD_SEATS_FLOW } from 'components/AddSeatsFlow/actions';
import { openModal, closeModal } from 'components/Modal/actions';
import { showSuccessToast } from 'actions/toasts';
import { shouldShowUserHardBlockSelector } from 'selectors/accounts';
import { limitTypes } from 'selectors/limits';
import track from 'analytics';
import HardBlock from 'components/HardBlock';

import { JOIN_REQUESTS_POPOVER, togglePopover } from './actions';

// TODO(gabrielmessager): replace all the yield put below
// with a call to the corresponding core saga.
const successMessage = 'Join request succesfully accepted!';

export function* acceptJoinRequest({ payload: { accountId, joinRequestId } }) {
  const canAddTeamMember = yield select(canAddTeamMemberSelector, {
    accountId,
  });
  const shouldShowUserHardBlock = yield select(shouldShowUserHardBlockSelector);

  // used for v5 and v6 pricing
  if (shouldShowUserHardBlock) {
    yield put(togglePopover(false));
    yield put(openModal(<HardBlock limitType={limitTypes.USERS} />));
    yield spawn(track, 'action-blocked', { _limit: limitTypes.USERS });
    return;
  }

  if (canAddTeamMember) {
    yield call(acceptJoinRequestFromCore, joinRequestId);
    return;
  }

  yield put(
    openModal(
      <AddSeatsFlow
        accountId={accountId}
        onComplete={closeModal}
        trackingProps={{ source: 'join request' }}
        showTotalLineItem={false}
      />
    )
  );

  // When the user has added a seat,
  // we automatically confirm the join request
  const { success } = yield race({
    success: take(ADD_SEATS_FLOW.REPORT_SUCCESS),
    cancelled: take(ADD_SEATS_FLOW.END),
  });

  if (success) {
    yield call(acceptJoinRequestFromCore, joinRequestId);

    yield put(
      showSuccessToast({
        header: successMessage,
      })
    );
  }
}

export function* declineJoinRequest({ payload: { joinRequestId } }) {
  yield call(declineJoinRequestFromCore, joinRequestId);
}

export function* undoJoinRequest({ payload: { joinRequestId } }) {
  yield call(resetJoinRequest, joinRequestId);
}

export function* markJoinRequestAsRead({ payload: { joinRequestIds } }) {
  yield call(markJoinRequestsRead, joinRequestIds);
}

export default [
  takeLatest(JOIN_REQUESTS_POPOVER.ACCEPT, acceptJoinRequest),
  takeLatest(JOIN_REQUESTS_POPOVER.DECLINE, declineJoinRequest),
  takeLatest(JOIN_REQUESTS_POPOVER.UNDO, undoJoinRequest),
  takeLatest(JOIN_REQUESTS_POPOVER.MARK_AS_READ, markJoinRequestAsRead),
];

export const testExports = {
  acceptJoinRequest,
  declineJoinRequest,
  undoJoinRequest,
  markJoinRequestAsRead,
};
