import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const DUAL_VIEW = generateActionTypes('TRANSCODE', [
  'CANCEL_MODAL',
  'CONFIRM_MODAL',
  'CONFIRM_CREATE',
  'FAILED_NOTIFICATION_CLICK',
]);

export const cancelModal = createAction(DUAL_VIEW.CANCEL_MODAL, () => ({}));

export const confirmDualViewModal = createAction(
  DUAL_VIEW.CONFIRM_MODAL,
  (wantsMerge) => ({ wantsMerge })
);

export const confirmCreateDualView = createAction(
  DUAL_VIEW.CONFIRM_CREATE,
  (assetIds, trackingPage, trackingPosition) => ({
    assetIds,
    trackingPage,
    trackingPosition,
  })
);
