import { connect } from 'react-redux';
import { status } from '@frameio/core/src/shared/reducers/factories';
import { projectByTeamFetchStatusSelector } from '@frameio/core/src/projects/selectors';
import TreeTeamItem from './TreeTeamItem';
import { fetchProjects } from '../../DashboardSidebar/ListTeamGroup/actions';
import { makeUnarchivedProjectEntitiesForTeamIdSelector } from '../../../selectors/projects';

function makeMapStateToProps() {
  const unarchivedProjectSelector = makeUnarchivedProjectEntitiesForTeamIdSelector();

  return (state, { team: { id } }) => ({
    projects: unarchivedProjectSelector(state, { teamId: id }),
    isLoading: projectByTeamFetchStatusSelector(state)[id] === status.PENDING,
  });
}

const mapDispatchToProps = {
  onFetchProjects: fetchProjects,
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(TreeTeamItem);

export const testExports = {
  makeMapStateToProps,
  mapDispatchToProps,
};
