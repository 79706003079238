import { connect } from 'react-redux';
import CreateDualView from './CreateDualView';
import { confirmDualViewModal, cancelModal } from '../actions';

const mapDispatchToProps = {
  confirmDualViewModal,
  cancelModal,
};

export default connect(
  null,
  mapDispatchToProps
)(CreateDualView);
