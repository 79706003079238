/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { formatMoney } from 'formatters/money';
import Button from '@frameio/components/src/styled-components/Button';
import CheckmarkIcon from '@frameio/components/src/svgs/icons/16/checkmark.svg';
import { MEDIUM_DOWN } from 'utils/mediaQueries';
import InfoIcon from '@frameio/components/src/svgs/icons/24/info-filled.svg';
import { Tooltip } from '@frameio/vapor';
import PremiumBadge from '@frameio/components/src/styled-components/PremiumBadge';

function adjustPriceForV1YearlyPlans(cost, isLegacy = false, isYearly = false) {
  // v1 plan pricing is provided as monthly cost, so we need to multiply by 12
  if (isLegacy && isYearly) {
    return cost * 12;
  }

  return cost;
}

const CardContainer = styled.div`
  width: ${({ isEnterprise, isLegacy, theme }) =>
    isLegacy
      ? isEnterprise
        ? '100%'
        : theme.spacing.units(25)
      : theme.spacing.units(32)};

  margin: ${({ isEnterprise, isLegacy, theme }) =>
    isLegacy
      ? isEnterprise
        ? `${theme.spacing.medium} ${theme.spacing.medium} 0`
        : `0 ${theme.spacing.small}`
      : `0 ${theme.spacing.small}`};

  @media ${MEDIUM_DOWN} {
    width: ${({ isEnterprise, isLegacy, theme }) =>
      isEnterprise && isLegacy ? '100%' : theme.spacing.units(25)};

    margin: ${({ isEnterprise, isLegacy, theme }) =>
      isEnterprise && isLegacy
        ? `0 ${theme.spacing.units(2)}`
        : `0 ${theme.spacing.tiny}`};
  }
`;

const PlanTitle = styled.p`
  font-size: 24px;
  color: ${({ isEnterprise, theme }) =>
    isEnterprise ? theme.color.white : theme.color.coolBlack};
  font-weight: bold;
  line-height: ${({ theme }) => theme.lineHeight[3]};
`;

// TODO(GROW-1281): remove the use of <Flex /> and simplify logic for legacy Enterprise card.
const PlanBody = styled(Flex).attrs(({ isEnterprise, isLegacy }) => ({
  alignCenter: isEnterprise && isLegacy,
  column: !isLegacy || (!isEnterprise && isLegacy),
  row: isEnterprise && isLegacy,
  justifyBetween: true,
}))`
  min-height: ${({ isEnterprise, isLegacy, theme }) =>
    isLegacy
      ? isEnterprise
        ? 'none'
        : theme.spacing.units(34)
      : theme.spacing.units(48)};

  max-height: ${({ isEnterprise, theme }) =>
    isEnterprise ? theme.spacing.units(16) : 'none'};
  border-radius: ${(p) => p.theme.radius.large};
  padding: ${(p) => p.theme.spacing.units(2)};
  color: ${(p) => p.theme.color.graphiteGray};
  font-size: ${(p) => p.theme.fontSize[1]};
  line-height: ${(p) => p.theme.lineHeight[2]};
  border: solid 1px ${(p) => p.theme.color.lightGray};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03);
  transition: border 0.2s ease;

  background: ${(p) =>
    p.isEnterprise
      ? 'linear-gradient(258deg, #17144a 0%, #0c0a28 100%)'
      : 'none'};

  @media ${MEDIUM_DOWN} {
    padding: ${(p) => p.theme.spacing.units(2)};
  }
`;

const FeatureList = styled.ul`
  width: ${({ isEnterprise, isLegacy, theme }) =>
    isEnterprise && isLegacy ? theme.spacing.units(42) : 'auto'};

  list-style: none;
  font-size: 12px;
  margin-top: ${({ isEnterprise, isLegacy, theme }) =>
    !isLegacy || (isLegacy && isEnterprise) ? '0' : theme.spacing.micro};
`;

const FeatureLine = styled.li`
  padding: 3px 0;
  color: ${({ isEnterprise, theme }) =>
    isEnterprise ? theme.color.white : theme.color.gray};
  margin-bottom: ${(p) => p.theme.spacing.micro};

  // When displaying the enterprise plan card along with legacy plans cards, the features list in
  // the enterprise plan card need to be displayed in two columns.
  // The styles below ensures that each feature in each column is left align so the spacing between
  // the two columns is consistent.
  width: ${({ isEnterprise, isLegacy }) =>
    isEnterprise && isLegacy ? '50%' : 'auto'};
  display: ${({ isEnterprise, isLegacy }) =>
    isEnterprise && isLegacy ? 'inline-block' : 'block'};
  :nth-last-child(1):nth-child(odd) {
    float: ${({ isEnterprise, isLegacy }) =>
      /* eslint-disable indent */
      isEnterprise && isLegacy ? 'right' : 'none'};
    /* eslint-enable indent */
  }
`;

const Price = styled.span`
  font-size: ${({ isEnterprise, theme }) =>
    isEnterprise ? theme.fontSize[2] : theme.fontSize[3]};
  line-height: 40px;
  color: ${({ isEnterprise, theme }) =>
    isEnterprise ? theme.color.slateGray : theme.color.gray};
  font-weight: ${(p) => (p.isEnterprise ? 'normal' : 'bold')};
  margin-top: ${(p) => p.theme.spacing.tiny};
`;

const PerPlanText = styled.div`
  color: ${({ isEnterprise, theme }) =>
    isEnterprise ? theme.color.white : theme.color.gray};
  font-size: 12px;
  margin-top: ${(p) => p.theme.spacing.micro};
  margin-bottom: ${(p) => p.theme.spacing.micro};
`;

const Subtitle = styled.div`
  color: ${({ isEnterprise, theme }) =>
    isEnterprise ? theme.color.white : theme.color.coolBlack};
  font-size: ${(p) => p.theme.fontSize[2]};
  display: flex;
  align-items: flex-end;
  margin-bottom: ${({ isEnterprise, theme }) =>
    isEnterprise ? theme.spacing.units(2) : theme.spacing.units(4)};
`;

const CurrentPlan = styled.div`
  font-size: ${(p) => p.theme.fontSize[2]};
  color: ${(p) => p.theme.color.gray};
  text-align: center;
  font-weight: bold;
  padding: ${(p) => p.theme.spacing.tiny};
`;

const BottomLine = styled.div`
  margin-top: ${({ isEnterprise, isLegacy }) =>
    isLegacy && isEnterprise ? '0px' : 'auto'};
`;

const BottomLineButton = styled(Button)`
  width: 100%;
`;

const PerMonthText = styled.span`
  font-size: ${(p) => p.theme.fontSize[2]};
  color: ${({ isEnterprise, theme }) =>
    isEnterprise ? theme.color.white : theme.color.gray};
`;

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  width: 12px;
  height: 12px;
  color: ${(p) => p.theme.color.brand};
  margin-right: ${(p) => p.theme.spacing.tiny};
`;

const GreyInfoIcon = styled(InfoIcon).attrs(({ theme }) => ({
  color: theme.color.slateGray,
}))`
  // center <GreyInfoIcon />
  position: relative;
  top: 2px;
  margin-left: ${(p) => p.theme.spacing.micro};
`;

const StyledWithTooltip = styled(Tooltip)`
  max-width: 250px;
`;

const BilledAnnuallyDisclaimer = styled.span`
  ${(p) => p.theme.fontStyle.bodyS};
  color: ${(p) => p.theme.color.slateGray};
`;

const StyledPremiumBadge = styled(PremiumBadge)`
  margin-left: ${(p) => p.theme.spacing.tiny};
`;

function renderFeatures(plan, isLegacy) {
  const isEnterprise = plan.enterprise;

  return plan.features.map(({ text, tooltipText }) => {
    if (!text) {
      return null;
    }

    return (
      <FeatureLine key={text} isEnterprise={isEnterprise} isLegacy={isLegacy}>
        <Flex alignCenter>
          {/* For v3 and v4 plans, the seat add-ons on the Team plan includes 3 seats.
          However, this is not true for v2 plans. They only have one seat included. */}
          <StyledCheckmarkIcon />
          {text}
          {tooltipText && (
            <StyledWithTooltip title={tooltipText} shouldUsePortal>
              <div>
                <GreyInfoIcon width={16} height={16} />
              </div>
            </StyledWithTooltip>
          )}
        </Flex>
      </FeatureLine>
    );
  });
}

const PlanCard = ({
  isSelected,
  isSelectedTrial,
  onChatWithOurTeamClick,
  plan,
  selectPlan,
  isLegacy,
}) => {
  const isYearly = plan.period === 'yearly';
  const cost = adjustPriceForV1YearlyPlans(plan.cost, isLegacy, isYearly);
  const isEnterprise = plan.enterprise;
  const isV2Plan = plan.version === 2;
  const periodLabel = isYearly ? 'year' : 'month';

  const renderTopSection = () => (
    <React.Fragment>
      <div>
        <Flex alignCenter>
          <PlanTitle isEnterprise={isEnterprise} isLegacy={isLegacy}>
            {plan.title}
          </PlanTitle>
          {isSelectedTrial && (
            <StyledPremiumBadge>Your trial</StyledPremiumBadge>
          )}
        </Flex>
        <Price isEnterprise={isEnterprise}>
          {(cost && formatMoney(cost)) || 'Custom'}
        </Price>
        {!!cost && (
          <PerMonthText>
            {isV2Plan && '/Team member'}
            {`/${periodLabel} (+ tax)`}{' '}
          </PerMonthText>
        )}
      </div>
      {!isLegacy && (
        <Subtitle isEnterprise={isEnterprise}>{plan.subtitle}</Subtitle>
      )}
    </React.Fragment>
  );

  const renderBottomLine = () => {
    if (!plan.selfServe) {
      const isDriftInitialized = window.drift;
      if (!isDriftInitialized) {
        return (
          <Button
            primary
            forwardedAs="a"
            href="mailto:sales@frame.io?subject=Frame.io Enterprise"
          >
            Contact sales
          </Button>
        );
      }
      return (
        <BottomLineButton
          primary
          onClick={() => {
            onChatWithOurTeamClick();

            // Hack to keep the Drift input box in focus
            setTimeout(() => {
              document.activeElement.blur();
            }, 100);
          }}
        >
          Chat with Sales
        </BottomLineButton>
      );
    }
    if (isSelected) {
      return <CurrentPlan>Current plan</CurrentPlan>;
    }
    return (
      <BottomLineButton primary onClick={() => selectPlan(plan)}>
        {isSelectedTrial ? 'Continue with this plan' : 'Choose plan'}
      </BottomLineButton>
    );
  };

  return (
    <CardContainer isEnterprise={isEnterprise} isLegacy={isLegacy}>
      <PlanBody isEnterprise={isEnterprise} isLegacy={isLegacy}>
        {renderTopSection()}
        <div>
          <FeatureList isEnterprise={isEnterprise} isLegacy={isLegacy}>
            {renderFeatures(plan, isLegacy)}
          </FeatureList>
        </div>
        <BottomLine isEnterprise={isEnterprise} isLegacy={isLegacy}>
          {renderBottomLine()}
        </BottomLine>
      </PlanBody>
    </CardContainer>
  );
};

PlanCard.defaultProps = {
  isLegacy: false,
  isSelected: false,
};

PlanCard.propTypes = {
  isLegacy: PropTypes.bool,
  isSelected: PropTypes.bool,
  onChatWithOurTeamClick: PropTypes.func.isRequired,
  plan: PropTypes.shape({
    period: PropTypes.string.isRequired,
    cost: PropTypes.number.isRequired,
    enterprise: PropTypes.bool.isRequired,
    storage_limit: PropTypes.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    features: PropTypes.array.isRequired,
    selfServe: PropTypes.bool.isRequired,
  }).isRequired,
  selectPlan: PropTypes.func.isRequired,
};

export const testExports = {
  BilledAnnuallyDisclaimer,
  BottomLine,
  BottomLineButton,
  CurrentPlan,
  PerPlanText,
  Price,
  PerMonthText,
};

export default PlanCard;
