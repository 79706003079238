import { connect } from 'react-redux';
import { get } from 'lodash';
import { status } from '@frameio/core/src/shared/reducers/factories';
import { sharedProjectsFetchStatusSelector } from '@frameio/core/src/projects/selectors';
import PairingProjectSelectorModal from './PairProjectSelectorModal';
import { closePairingProjectSelectModal } from '../../actions';
import { currentAccountSelector } from '../../../../selectors/accounts';

const getProjectsList = (state, accountId) => {
  const sharedProjectsFetchStatus = sharedProjectsFetchStatusSelector(state);
  const loaded = sharedProjectsFetchStatus[accountId] === status.SUCCESS;
  const projects = loaded ? state.entities?.projects?.entities : {};
  if (!projects || Object.keys(projects).length === 0) return [];
  return Object.values(projects).map((project) => {
    return project;
  });
};

function mapStateToProps(state, props) {
  const accountId = get(currentAccountSelector(state), 'id', null);
  const projectList = getProjectsList(state, accountId);

  return {
    currentProjectId: props.projectId,
    pairCode: props.pairCode,
    projectList,
  };
}

const mapDispatchToProps = {
  closeModal: closePairingProjectSelectModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PairingProjectSelectorModal);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
