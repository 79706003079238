import { COMMENT_CARD } from './actions';

export const INITIAL_STATE = {
  editingCommentId: null,
};

const commentCardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COMMENT_CARD.SET_IS_EDITING_COMMENT: {
      const { editingCommentId } = action.payload;
      return { ...state, editingCommentId };
    }
    default:
      return state;
  }
};

export default commentCardReducer;
