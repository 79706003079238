/**
 * There are two ways device channels names are stored.
 * 1. The host channel (or the channel with index = 0) shares the name of the project device (ie. "Project Device Example").
 * 2. All subsequent channels are have their names initialized with the last 4 digits of its Device's client_id added to it (ie. "Project Device Example 5678", etc.)
 * Our UI solution is to have a truncate-able device name, while persisting the 4 client_id digits when possible. Having access to those 2 strings separately will allow us to do so.
 * @param {string} name - The base name.
 * @param {string} client_id - The device's serial number.
 * @param {number} index - The index.
 * @returns {string} - The generated name for the project device channel.
 */
export default function generateVisibleChannelName(name, client_id, index) {
  const displayName = index !== 0 ? name.slice(0, -4) : name;
  const trailingClientId = client_id ? ` (${client_id.slice(-4)})` : null;
  const displayNameWithId = displayName + trailingClientId;

  return {
    displayName,
    trailingClientId,
    displayNameWithId,
  };
}
