import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import Button from '@frameio/components/src/styled-components/Button';
import DropdownButton from 'components/SharePanel/DropDown/DropdownButton';
import { Menu, MenuButton, MenuList } from '@frameio/vapor';
import { WORKFRONT } from 'components/AssetActions/AdobeIntegration/integrationConstants';
import TaskList from './TaskList';
import { TEXT, taskStatuses, taskStatusLabels } from './helper';

const StyledFlex = styled(Flex)`
  ${(p) => p.$disabled && `color: ${p.theme.color.lightGray}`};
  justify-content: space-between;
`;

const Divider = styled.div`
  padding: ${(p) => p.theme.spacing.tiny} 0px;
  border-top: 1px solid ${(p) => p.theme.color.coldWhite};
`;

const StyledTaskSelectionContainer = styled(Flex)`
  height: ${(p) => p.theme.spacing.units(5)};
  border: 1px solid ${(p) => p.theme.color.silver};
  border-radius: ${(p) => p.theme.radius.default};
  font-size: ${(p) => p.theme.fontSize[2]};
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-evenly;
`;

const StyledTaskContentContainer = styled(Flex)`
  flex-direction: column;
  margin: auto;
  height: ${(p) => p.theme.spacing.units(44)};
  overflow: auto;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: #d6d6d6;
    border-radius: 10px;
  }
`;

const StyledMenuList = styled(MenuList)`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const StyledTaskSelectionButton = styled(Button)`
  width: 50%;
  box-shadow: none;
`;

const StyledDropdownButton = styled(DropdownButton)`
  height: 24px;
  width: 175px;
`;

const WorkfrontTaskModalContent = ({
  workfrontTaskInterface: {
    setAllTasksSelected,
    setUpdateTaskSelected,
    state,
    workfrontAssignedTasks,
    workfrontTasks,
  } = {},
}) => {
  const {
    isUpdateTaskSelected,
    isAllTasksSelected,
    hasPermissionToUpdate,
    taskOptions,
  } = state || {};

  const { allTasks, assignedToYou, noChange, updateTask } =
    TEXT(WORKFRONT) || {};

  const handleUserTaskSelection = () => {
    if (isAllTasksSelected) {
      setAllTasksSelected(false);
    }
  };

  const handleAllTaskSelection = () => {
    if (!isAllTasksSelected) {
      setAllTasksSelected(true);
    }
  };

  const handleUpdateTask = (selectedTaskStatus) => {
    const payload =
      selectedTaskStatus === noChange
        ? {
            hasPermissionToUpdate,
            isUpdateTaskSelected: false,
            taskOptions: { ...taskOptions, updateStatus: '' },
          }
        : {
            hasPermissionToUpdate,
            isUpdateTaskSelected: true,
            taskOptions: { ...taskOptions, updateStatus: selectedTaskStatus },
          };
    setUpdateTaskSelected(payload);
  };

  return (
    <>
      <StyledTaskSelectionContainer>
        <StyledTaskSelectionButton
          primary={!isAllTasksSelected}
          onClick={handleUserTaskSelection}
        >
          {assignedToYou}
        </StyledTaskSelectionButton>
        <StyledTaskSelectionButton
          primary={isAllTasksSelected}
          onClick={handleAllTaskSelection}
        >
          {allTasks}
        </StyledTaskSelectionButton>
      </StyledTaskSelectionContainer>
      <StyledTaskContentContainer>
        <StyledMenuList>
          <TaskList
            isUpdateTaskSelected={isUpdateTaskSelected}
            setUpdateTaskSelected={setUpdateTaskSelected}
            tasks={isAllTasksSelected ? workfrontTasks : workfrontAssignedTasks}
            taskOptions={taskOptions}
          />
        </StyledMenuList>
      </StyledTaskContentContainer>
      <Divider />
      <StyledFlex $disabled={!hasPermissionToUpdate}>
        {updateTask}
        <Menu
          placement="bottom-start"
          content={taskStatuses.map((taskStatus, index) => (
            <MenuButton
              key={index}
              disabled={!hasPermissionToUpdate}
              onSelect={() => handleUpdateTask(taskStatus)}
            >
              {taskStatusLabels[taskStatus]}
            </MenuButton>
          ))}
        >
          <StyledDropdownButton disabled={!hasPermissionToUpdate} dropdown text>
            {isUpdateTaskSelected
              ? taskStatusLabels[taskOptions.updateStatus]
              : noChange}
          </StyledDropdownButton>
        </Menu>
      </StyledFlex>
    </>
  );
};

export default WorkfrontTaskModalContent;
