import QUICKLOOK from './actions';

const INITIAL_STATE = {
  isOpen: false,
  assetId: null,
  origin: [],
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case QUICKLOOK.OPEN:
      return {
        ...state,
        isOpen: true,
      };
    case QUICKLOOK.CLOSE:
      return {
        ...state,
        isOpen: false,
      };
    case QUICKLOOK.SET_ASSET_ID:
    case QUICKLOOK.SET_ORIGIN:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
