import { TYPE } from 'components/UserSearch/UserSearch';

export function getTokensToSubmit(tokens) {
  return tokens.reduce((acc, token) => {
    if (token?.type === TYPE.USER) {
      acc.push({ user_id: token.id });
      return acc;
    }
    if (token?.type === TYPE.PENDING_REVIEWER) {
      acc.push({ email: token.id });
      return acc;
    }
    if (token?.type === TYPE.EMAIL) {
      acc.push({ email: token.id });
      return acc;
    }
    if (token?.type === TYPE.GROUP) {
      acc.push({ user_group_id: token.id });
      return acc;
    }
    if (token?.type === TYPE.TEAM) {
      acc.push({ team_id: token.id });
      return acc;
    }
    if (token?.type === undefined) {
      // effectively deletes entry if nothing is pushed to accumulator
      // undefined tokens will return 422 errors from axios call to massdriver
      return acc;
    }

    // default if no type matches and token is defined
    acc.push({ team_id: token.id });
    return acc;
  }, []);
}

export function getTrackingData(tokens, entityType) {
  return tokens.reduce(
    (acc, token) => {
      if (token?.type === undefined) {
        // guard to prevent errors
      } else if (token.type === TYPE.USER) {
        acc.users_invited += 1;
      } else if (token.type === TYPE.EMAIL) {
        // if you are email we want to track you as an email _and_ a user, such that users
        // is the total count of people invited and emails is a subset of that count
        acc.users_invited += 1;
        acc.emails_invited += 1;
      } else if (token.type === TYPE.GROUP) {
        acc.groups_invited += 1;
      } else if (token.type === TYPE.TEAM) {
        acc.teams_invited += 1;
      }

      return acc;
    },
    {
      groups_invited: 0,
      users_invited: 0,
      emails_invited: 0,
      teams_invited: 0,
      type: entityType,
    }
  );
}
