import { PAYMENT_FLOW } from './actions';

const initialState = {
  errorMessage: null,
};

export default function setPlan(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case PAYMENT_FLOW.SET_ERROR:
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    default:
      return state;
  }
}
