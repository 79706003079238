import React from 'react';
import styled from 'styled-components';
import { PROJECT_C2C_CONNECTIONS_WITH_CODE_URL } from 'URLs';
import { goToRoute } from 'utils/router';
import ProjectIcon from '../../../../components/AssetActions/MoveTo/ProjectIcon';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const ProjectItem = styled.div`
  padding: ${(p) => p.theme.spacing.units(0.75)};
  margin: ${(p) => p.theme.spacing.units(0.25)};
  cursor: pointer;
  border-radius: ${(p) => p.theme.spacing.units(0.5)};
  width: fit-content;
  display: flex;
  gap: ${(p) => p.theme.spacing.units(1)};
  align-items: center;

  &:hover {
    color: ${(p) => p.theme.color.dimGray};
    background-color: ${(p) => p.theme.color.coldWhite};
  }
`;

export const ProjectListItem = ({ pairCode, project, onSelect }) => {
  const handleOnSelect = () => {
    goToRoute(PROJECT_C2C_CONNECTIONS_WITH_CODE_URL, {
      projectId: project.id,
      pairCode,
    });
    onSelect();
  };

  return (
    <ProjectItem onMouseDown={handleOnSelect}>
      <IconContainer>
        <ProjectIcon />
      </IconContainer>
      {project.name}
    </ProjectItem>
  );
};

export default ProjectListItem;
