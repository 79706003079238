import React from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';
import { openModal } from 'components/Modal/actions';
import SetupAccountFlow from 'components/SetupAccountFlow';
import { SETUP_ACCOUNT } from './actions';

function triggerOnboardingFlow() {
  vc.new_user_popover.open();
}

function* startSetupAccount() {
  yield put(openModal(<SetupAccountFlow />, { canCloseModal: false }));
}

function* startSetupLegacyAccount() {
  yield call(triggerOnboardingFlow);
}

export default [
  takeLatest(SETUP_ACCOUNT.START, () => startSetupAccount()),
  takeLatest(SETUP_ACCOUNT.START_LEGACY, () => startSetupLegacyAccount()),
];
