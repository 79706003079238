import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import { getSeatUnit } from 'selectors/accounts';
import AddSeatsFlow from './AddSeatsFlow';
import { startFlow, continueFlow, endFlow } from './actions';

function mapStateToProps(state) {
  return {
    seatUnit: getSeatUnit(state),
  };
}

const mapDispatchToProps = {
  startFlow,
  continueFlow,
  endFlow,
  closeModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSeatsFlow);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
