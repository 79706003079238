import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const STORAGE_LIMIT_REACHED_FLOW = generateActionTypes(
  'STORAGE_LIMIT_REACHED_FLOW',
  ['START', 'CONTINUE', 'END']
);

export const startFlow = createAction(
  STORAGE_LIMIT_REACHED_FLOW.START,
  (accountId, type) => ({ accountId, type })
);

export const continueFlow = createAction(
  STORAGE_LIMIT_REACHED_FLOW.CONTINUE,
  () => ({})
);

export const endFlow = createAction(STORAGE_LIMIT_REACHED_FLOW.END, () => ({}));
