import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const UNARCHIVE_PROJECT_FLOW = generateActionTypes(
  'UNARCHIVE_PROJECT_FLOW',
  ['START', 'CONTINUE', 'END', 'IS_UNARCHIVING']
);

export const startFlow = createAction(
  UNARCHIVE_PROJECT_FLOW.START,
  (projectId, isUpdatedArchivalMessagingEnabled) => ({
    projectId,
    isUpdatedArchivalMessagingEnabled,
  })
);

export const continueFlow = createAction(
  UNARCHIVE_PROJECT_FLOW.CONTINUE,
  () => ({})
);

export const endFlow = createAction(UNARCHIVE_PROJECT_FLOW.END, () => ({}));
