import { connect } from 'react-redux';

import { setAccountMigrationEligibility } from 'actions/accounts';
import {
  accountIdsForAccountSwitcherSelector,
  versionForAccountSwitcherSelector,
} from '../selectors';
import AccountSwitcherBar from './AccountSwitcherBar';

export const mapDispatchToProps = {
  setAccountMigrationEligibility,
};

/**
 * @param   {Object} state - Redux store state.
 * @returns {Object} - Maps the redux store state to populate props for the AccountSwitcher.
 */
const mapStateToProps = (state) => {
  const accountIds = accountIdsForAccountSwitcherSelector(state) || [];
  const versions = versionForAccountSwitcherSelector(state) || [];

  return {
    accountIds,
    versions,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSwitcherBar);

export const testExports = {
  mapStateToProps,
};
