import SIMPLE_DIALOG from './actions';

const defaultState = {
  modalProps: { isOpen: false },
};

/**
 * Dialog state reducer.
 *
 * Note that this reducer is not merging in the previous state so that the next
 * dialog instance (which might not be the same dialog) does not inherit props
 * from the previous instance.
 *
 * @param   {Object} state - The current state.
 * @param   {Object} action - The action describing how to change the state.
 * @returns {Object} - The new state.
 */
export default function simpleDialogReducer(
  state = { modalProps: { isOpen: false } },
  action
) {
  const { type, payload } = action;
  switch (type) {
    case SIMPLE_DIALOG.OPEN:
      if (payload.priority < state.priority) return state;
      return {
        ...payload,
        modalProps: { ...payload.modalProps, isOpen: true },
      };
    case SIMPLE_DIALOG.CLOSE:
      return defaultState;
    default:
      return state;
  }
}
