import React from 'react';
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';
import * as theme from '@frameio/components/src/theme/darkTheme';

/**
 * Use react-highlight-words to highlight
 * certain parts of the tree item name
 */

function HighlightedName({ query, name, className }) {
  return (
    <Highlighter
      searchWords={query.split(' ')}
      highlightStyle={{
        color: theme.color.dimGray,
        backgroundColor: 'rgba(91, 83, 255, 0.15)',
      }}
      autoEscape
      textToHighlight={name}
      className={className}
    />
  );
}

HighlightedName.propTypes = {
  query: PropTypes.string,
  name: PropTypes.string,
};

export default HighlightedName;
