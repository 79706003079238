import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import track from 'analytics';
import { TEXT as SHARED_TEXT } from '../CancelAccountFlow';
import CancelAccountFlowModal from '../CancelAccountFlowModal';
import ButtonForm from '../ButtonForm';

export const TEXT = {
  HEADER: 'Heading out already?',
  DESCRIPTION:
    'We’re sorry you’re thinking of leaving us. What’s your primary reason for cancelling your subscription?',
};

const ReasonSelector = ({
  setReasonKey,
  setTrackingReason,
  selectReason,
  currentStep,
  source,
  onClose,
}) => {
  const [reason, setReason] = useState(false);

  const updateReason = (newReason) => {
    setReason(newReason);
  };

  const onSubmit = () => {
    if (reason) {
      setReasonKey(reason);
      const trackingReason =
        SHARED_TEXT.REASON_OPTIONS[reason]?.TRACKING_TEXT || '';
      setTrackingReason(trackingReason);
      selectReason({ reason: trackingReason, source });
    }
  };

  useEffect(() => {
    track('cancellation-modal-shown', { cancellation_source: source });
  }, [source]);

  return (
    <CancelAccountFlowModal
      currentStep={currentStep}
      headerText={TEXT.HEADER}
      descriptionText={TEXT.DESCRIPTION}
      handleSubmit={onSubmit}
      submitDisabled={!reason}
      source={source}
      onClose={onClose}
    >
      <FormContainer>
        <ButtonForm
          options={SHARED_TEXT.REASON_OPTIONS}
          selectedOption={reason}
          onSubmit={onSubmit}
          updateValue={updateReason}
        />
      </FormContainer>
    </CancelAccountFlowModal>
  );
};

const FormContainer = styled(Flex)`
  flex-grow: 1;

  form {
    width: 100%;
  }
`;

export default ReasonSelector;
