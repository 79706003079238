import { generateActionTypes } from 'shared/actions/helpers';
import { createAction } from 'redux-actions';

export const TEAM = generateActionTypes('TEAM', [
  'SET_CURRENT',
  'REQUEST_TO_JOIN',
  'UPDATE_INTERNAL_WATERMARK_SETTING',
  'UPDATE_FORENSIC_WATERMARK_SETTING',
]);

/**
 * Set's redux store's current team.
 * @param {string} teamId - Team id.
 */
export const setCurrentTeam = createAction(
  TEAM.SET_CURRENT,
  (teamId, prevTeamId) => ({ teamId, prevTeamId })
);

export const requestToJoinTeam = createAction(
  TEAM.REQUEST_TO_JOIN,
  (teamId) => ({ teamId })
);

export const updateTeamInternalWatermarkSetting = createAction(
  TEAM.UPDATE_INTERNAL_WATERMARK_SETTING,
  (teamId, newValue) => ({
    teamId,
    newValue,
  })
);

export const updateTeamForensicWatermarkSetting = createAction(
  TEAM.UPDATE_FORENSIC_WATERMARK_SETTING,
  (teamId, newValue) => ({
    teamId,
    newValue,
  })
);
