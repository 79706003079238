import React, { useState, useCallback } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { trackButtonClick } from 'analytics';

import {
  getStartTimecode,
  getTimeDisplayValue,
  setTimeDisplayValue,
} from '@frameio/components/src/components/PlayerSubcomponents/PlayerInterface';
import QuicklookPlayer from '@frameio/components/src/styled-components/QuicklookPlayer';

import PlayerControlBar from 'components/PlayerControlBar';
import { TOOLTIP_TITLES } from 'components/PlayerControlBar/PlayerControlBar';
import SeekBar from 'components/PlayerControlBar/SeekBar';

import { resolutionToResolutionField } from 'utils/downloads';
import { shouldUseHlsHook } from 'utils/media';

/**
 * Given a width, select the stream at the highest possible resolution at or below the width to
 * optimise for both load times and stream quality.
 * @param {Object} asset - The asset object.
 * @param {Number} width - The desired size to show the stream at.
 */
export function getStreamSrcUrl(asset, width) {
  const resolutions = get(asset, 'transcodes.resolutions') || [];
  if (!resolutions.length) return asset.original;

  const bestResolution =
    resolutions
      .filter((resolution) =>
        get(asset, `${resolutionToResolutionField[resolution]}`)
      )
      .find((resolution) => Math.min(resolution, width) === resolution) ||
    Math.min(...resolutions);

  // BUGS-810: Don't pass asset.original into the default value for `get` in case
  // the best resolution field is `null`, which does not fall back to the default.
  return (
    get(asset, `${resolutionToResolutionField[bestResolution]}`) ||
    asset.original
  );
}

const TRACKING_PLAYER_TYPE = 'Quicklook';

const QuicklookVideo = ({
  asset,
  fwmFallbackPreference,
  isForensicallyWatermarked,
  isPlayer360Enabled,
  isReady,
  setIsFrameRateSupported,
  style,
  ...rest
}) => {
  const isHlsRequired = shouldUseHlsHook(asset);
  const isSessionWatermarked = asset?.is_session_watermarked;
  const [timeDisplay, setTimeDisplay] = useState(getTimeDisplayValue());
  const [fwmFrameRateSupported, setFwmFrameRateSupported] = useState(true);

  const setAndTrackTimeDisplay = useCallback(
    (displayType) => {
      setTimeDisplayValue(displayType); // set timeDisplay value in local storage
      setTimeDisplay(displayType);
      trackButtonClick(
        `opened ${displayType}`,
        'quicklook player',
        'player controls'
      );
    },
    [setTimeDisplay]
  );

  const renderPlayerControlBar = useCallback(
    ({
      media,
      mediaEl,
      mute,
      setVolume,
      toggleFullscreen,
      toggleLoop,
      togglePause,
      unmute,
    }) => {
      const startTimecode = getStartTimecode(asset);

      const fullScreenProps = {
        toggleFullscreen,
      };

      const loopProps = {
        toggleLoop,
      };

      const playPauseProps = {
        togglePlaying: togglePause,
      };

      const timeDisplayProps = {
        currentTimeDisplayType: timeDisplay,
        frameRate: asset.fps,
        isHlsPlayerEnabled: isHlsRequired,
        setTimeDisplay: setAndTrackTimeDisplay,
        startTimecode,
        totalFrames: asset.frames,
        audioCodec: asset.metadata?.blob?.audio_codec,
      };

      const tooltips = {
        ...TOOLTIP_TITLES,
        PLAYING: 'Pause',
        PAUSED: 'Play',
        FULLSCREEN: 'Fullscreen',
      };

      const volumeProps = {
        changeVolume: setVolume,
        mute,
        unmute,
      };

      return (
        <PlayerControlBar
          fullScreen={fullScreenProps}
          fwmFrameRateSupported={fwmFrameRateSupported}
          loop={loopProps}
          media={media}
          mediaEl={mediaEl}
          playPause={playPauseProps}
          timeDisplay={timeDisplayProps}
          tooltips={tooltips}
          volume={volumeProps}
          trackingPlayerType={TRACKING_PLAYER_TYPE}
          isSessionWatermarked={isSessionWatermarked}
        />
      );
    },
    [
      asset,
      fwmFrameRateSupported,
      isHlsRequired,
      isSessionWatermarked,
      setAndTrackTimeDisplay,
      timeDisplay,
    ]
  );

  const renderSeekBar = (seekProps) => {
    return <SeekBar {...seekProps} />;
  };

  const handleHlsError = (error = {}) => {
    if (error.response?.code === 515) {
      setFwmFrameRateSupported(false);
      setIsFrameRateSupported(false);
      document.fullscreenElement && document.exitFullscreen();
    }
  };

  return (
    <QuicklookPlayer
      autoPlay
      asset={asset}
      handleError={handleHlsError}
      fwmFallbackPreference={fwmFallbackPreference}
      fwmFrameRateSupported={fwmFrameRateSupported}
      isHlsPlayerEnabled={isHlsRequired}
      isForensicallyWatermarked={isForensicallyWatermarked}
      isSessionWatermarked={isSessionWatermarked}
      poster={asset.cover || asset.thumb}
      renderSeekBar={renderSeekBar}
      renderPlayerControlBar={renderPlayerControlBar}
      style={style}
      canPlay360={isPlayer360Enabled && asset.is_360}
      {...rest}
    >
      {isReady && <source src={getStreamSrcUrl(asset, style.width)} />}
    </QuicklookPlayer>
  );
};

export default QuicklookVideo;

QuicklookVideo.propTypes = {
  asset: PropTypes.shape({
    cover: PropTypes.string,
    thumb: PropTypes.string,
    transcodes: PropTypes.shape({
      resolutions: PropTypes.arrayOf(PropTypes.number),
    }),
    downloads: PropTypes.objectOf(PropTypes.string),
    original: PropTypes.string,
    is_360: PropTypes.bool,
  }).isRequired,
  isPlayer360Enabled: PropTypes.bool.isRequired,
  style: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  isReady: PropTypes.bool.isRequired,
};
