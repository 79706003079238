import { connect } from 'react-redux';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import { closeModal } from 'components/Modal/actions';
import { updatedArchivalMessagingEnabled } from 'utils/featureFlags';
import UnarchiveProject from './UnarchiveProject';
import { continueFlow } from '../actions';

function mapStateToProps(state, { projectId }) {
  const project = projectEntitySelector(state, { projectId }) || {};
  return {
    isUpdatedArchivalMessagingEnabled: updatedArchivalMessagingEnabled(state),
    projectName: project.name,
  };
}

const mapDispatchToProps = {
  onCancel: closeModal,
  onContinue: continueFlow,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnarchiveProject);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
