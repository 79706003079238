import React from 'react';

import styled from 'styled-components';

import { color as themeColors } from '@frameio/components/src/theme/darkTheme';
import { SMALL, MEDIUM } from 'utils/mediaQueries';
import { DynamicColorIcon } from '@frameio/components';
import LinkOutSVG from '@frameio/components/src/svgs/icons/16/open-arrow-top-right.svg';

const TEXT = {
  header: 'New Hardware Device',
};

const HeaderContainer = styled.div`
  background-color: #1b1b23;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
`;

const ModalTitle = styled.div`
  font-size: ${(p) => p.theme.fontSize[2]};
  color: ${(p) => p.theme.color.white};
  margin-left: ${(p) => p.theme.spacing.units(2.5)};
  margin-right: ${(p) => p.theme.spacing.units(2)};
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.color.coolBlack};
  height: 100%;
  width: 60px;
`;

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: ${(p) => p.theme.color.white};
  background-color: ${(p) => p.theme.color.gray900};
  width: ${(p) => p.theme.spacing.units(68)};
  max-width: ${(p) => p.theme.spacing.units(68)};
  height: ${(p) => p.theme.spacing.units(62)};

  @media ${MEDIUM} {
    width: 80vw;
  }

  @media ${SMALL} {
    width: 95vw;
    height: 95vh;
  }

  @media (max-height: ${(p) => p.theme.spacing.units(62)}) {
    height: 95vh;
    overflow: auto;
  }
`;

const Header = ({ onClose }) => {
  return (
    <HeaderContainer>
      <ModalTitle>{TEXT.header}</ModalTitle>
      <CloseButton className="pointer" role="button" onClick={() => onClose()}>
        <DynamicColorIcon kind="x" size={18} color="white" />
      </CloseButton>
    </HeaderContainer>
  );
};

export const FOOTER_VARIANTS = {
  error: 'error',
  info: 'info',
  none: 'none',
};

const Container = styled.div`
  display: flex;
  align-items: center;
  height: ${(p) => p.theme.spacing.units(8)};
`;

const Message = styled.div`
  margin-left: ${(p) => p.theme.spacing.tiny};
  padding-right: ${(p) => p.theme.spacing.tiny};
  color: ${(p) => (p.error ? p.theme.color.error : 'auto')};
`;

const StyledLinkOutSVG = styled.svg`
  color: ${(p) => p.theme.color.brand};
`;

const Footer = ({ variant, message, link }) => {
  return variant === FOOTER_VARIANTS.none ? (
    <div />
  ) : (
    <Container
      className={!!link && 'pointer'}
      onClick={link ? () => window.open(link) : undefined}
    >
      {variant === FOOTER_VARIANTS.error && (
        <DynamicColorIcon
          kind="circled_alert"
          size={18}
          color={themeColors.error}
        />
      )}
      <Message error={variant === FOOTER_VARIANTS.error}> {message} </Message>
      {!!link && <StyledLinkOutSVG as={LinkOutSVG} />}
    </Container>
  );
};

export const AuthorizationFlowModal = ({ footerProps, onClose, children }) => {
  return (
    <StyledModal>
      <Header onClose={onClose} />
      {children}
      <Footer {...footerProps} />
    </StyledModal>
  );
};
