import { connect } from 'react-redux';
import { hasFetchedFlagsSelector } from 'utils/featureFlags';
import { currentUserSelector } from 'selectors/users';
import { handleUserMismatch } from 'actions/shared';
import AuthedRoute from './AuthedRoute';
import { authenticate, unmount } from './actions';

/**
 * Returns the authed user entity when it becomes available.
 * @param {Object} state - Redux state.
 * @returns {Object} - Props map.
 */
export function mapStateToProps(state) {
  const { isAuthed, lockedOutEmail } = currentUserSelector(state);
  return {
    isAuthed,
    lockedOutEmail,
    isReady: hasFetchedFlagsSelector(state),
  };
}

const mapDispatchToProps = {
  authenticate,
  handleUserMismatch,
  onUnmount: unmount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthedRoute);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
