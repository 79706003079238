import React, { Component } from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SMALL } from 'utils/mediaQueries';
import { Flipper } from 'react-flip-toolkit';
import moveArrayElement from 'utils/moveArrayElement';
import { isAssetTranscoded } from '@frameio/core/src/assets/helpers/utils';
import WithTooltip from '@frameio/components/src/components/WithTooltip';
import Version, { ROW_HEIGHT } from './Version';
import Asset from './Asset';

const MAX_ROWS = 5;

export const List = styled.ol`
  ${({ theme }) => `
    padding: ${theme.spacing.tiny} 0;
    max-height: calc(${ROW_HEIGHT * MAX_ROWS}px + ${theme.spacing.tiny});
    width: calc(${theme.spacing.tiny} * 46);
    overflow-y: auto;

    @media ${SMALL} {
      padding: 0;
      width: calc(${theme.spacing.tiny} * 43);
    }
  `}
`;

export default class ManageVersionStack extends Component {
  static getDerivedStateFromProps(
    { assets: nextAssets },
    { resortedVersionList }
  ) {
    // local component state should only be used to store information
    // necessary for rendering optimistic UI updates. in all other cases,
    // we want to treat data recieved from the redux store as canonical.
    // we can determine when its appropriate to clear local state by
    // comparing the recieved `assets` prop to local state when the
    // component updates. if local state has been set and if the data
    // in the updated `assets` prop matches the data in state, we can
    // safely render from the data in props and wipe the local state.

    const mapById = (v) => v.id;
    const arePropsAndStateEqual = isEqual(
      resortedVersionList.map(mapById),
      nextAssets.map(mapById)
    );

    if (resortedVersionList.length && arePropsAndStateEqual) {
      return { resortedVersionList: [] };
    }

    return null;
  }

  state = { resortedVersionList: [] };

  onRemove = (renderedList, assetId) => {
    const { removeVersionFromStack } = this.props;

    // when removing an asset from the version stack, optimistically update
    // the UI by setting local state
    this.setState(
      {
        resortedVersionList: renderedList.filter(
          (asset) => asset.id !== assetId
        ),
      },
      () => removeVersionFromStack(assetId, renderedList.length)
    );
  };

  setSortableList = (list, sourceId, targetIndex) => {
    const { resortedVersionList } = this.state;
    const listToSearch = resortedVersionList.length
      ? resortedVersionList
      : this.props.assets;
    const sourceIndex = listToSearch.findIndex(
      (version) => version.id === sourceId
    );

    this.setState({
      resortedVersionList: moveArrayElement(list, sourceIndex, targetIndex),
    });
  };

  render() {
    const {
      assets,
      selectedAssetId,
      reorderVersionInStack,
      removeVersionFromStack,
      canManageVersions,
      onVersionClick,
      splitComparison,
      ...rest
    } = this.props;

    const { resortedVersionList } = this.state;

    // if the user has custom sorted the versions in the UI, then render
    // the sorted list, otherwise render the assets passed to the component
    // from the redux store. the store and backend will be brought into
    // sync with the UI through handling the response from the server
    // on version re-indexing
    const listToRender = resortedVersionList.length
      ? resortedVersionList
      : assets;
    const flipKey = listToRender.map((v) => v.id).join('');

    return (
      <Flipper spring="gentle" flipKey={flipKey} {...rest}>
        <List>
          {listToRender.map((asset, index) => {
            const { length: totalVersions } = listToRender;
            const versionNumber = totalVersions - index;
            const canReorder =
              canManageVersions && reorderVersionInStack && totalVersions > 1;
            const canRemove =
              canManageVersions && removeVersionFromStack && totalVersions > 1;
            // When using the ManageVersionStack in the split comparison view,
            // we want to disable the ability to click on a version that hasn't been transcoded yet.
            const isComparisonModeAndStillTranscoding =
              splitComparison && !isAssetTranscoded(asset);

            return (
              <li key={asset.id}>
                <WithTooltip
                  message="Version is still uploading."
                  position="top"
                  disabled={!isComparisonModeAndStillTranscoding}
                >
                  <Version
                    // Asset is given to the dragSourceSpec to pass
                    // along to the AssetDragPreview
                    asset={asset}
                    index={index}
                    versionNumber={versionNumber}
                    isSelected={selectedAssetId === asset.id}
                    isLoading={isComparisonModeAndStillTranscoding}
                    onClick={
                      !isComparisonModeAndStillTranscoding && onVersionClick
                        ? () => onVersionClick(asset.id)
                        : null
                    }
                    onRemove={
                      canRemove
                        ? () => this.onRemove(listToRender, asset.id)
                        : null
                    }
                    onDragOver={(sourceId, targetArrayIndex) =>
                      this.setSortableList(
                        listToRender,
                        sourceId,
                        targetArrayIndex
                      )
                    }
                    onAssetDropped={
                      canReorder
                        ? (childAssetId, targetIndex) => {
                            const prevAsset = listToRender[targetIndex - 1];
                            const nextAsset = listToRender[targetIndex + 1];

                            reorderVersionInStack(
                              childAssetId,
                              prevAsset ? prevAsset.id : null,
                              nextAsset ? nextAsset.id : null
                            );
                          }
                        : null
                    }
                  >
                    {(isCurrentAssetDragging) => (
                      <Asset
                        asset={asset}
                        index={index}
                        isDragging={isCurrentAssetDragging}
                      />
                    )}
                  </Version>
                </WithTooltip>
              </li>
            );
          })}
        </List>
      </Flipper>
    );
  }
}

ManageVersionStack.defaultProps = {
  removeVersionFromStack: null,
  reorderVersionInStack: null,
  selectedAssetId: null,
  onVersionClick: null,
  trackingPage: null,
};

ManageVersionStack.propTypes = {
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ).isRequired,
  canManageVersions: PropTypes.bool.isRequired,
  reorderVersionInStack: PropTypes.func,
  removeVersionFromStack: PropTypes.func,
  selectedAssetId: PropTypes.string,
  onVersionClick: PropTypes.func,
};
