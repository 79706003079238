import { connect } from 'react-redux';

import ROLES from '@frameio/core/src/roles/helpers/constants';
import { cardsByAccountFetchStatusSelector } from '@frameio/core/src/cards/selectors';
import { planEntityForAccountIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { userEntitySelector } from '@frameio/core/src/users/selectors';
import { getCardsByAccount } from '@frameio/core/src/cards/actions';
import { status } from '@frameio/core/src/shared/reducers/factories';

import { canAutoChargeCardSelector } from 'selectors/limits';
import { highestAccountOrTeamRoleForAccountIdSelector } from 'selectors/roles';
import { closeModal } from 'components/Modal/actions';
import { openSelectPlanFlowModal } from 'components/SelectPlanFlow/actions';
import LimitBlock from './LimitBlock';

function mapStateToProps(state, { account, role }) {
  const { id: accountId, owner_id: ownerId } = account || {};
  const accountOwner = userEntitySelector(state, { userId: ownerId });
  const currentPlan = planEntityForAccountIdSelector(state, { accountId });
  // todo(setsun): create a selector in core
  const cardsFetchStatus = cardsByAccountFetchStatusSelector(state)[accountId];

  // if the card fetch comes back as a failure, we still want the user
  // to continue the upgrade flow
  const hasFetchedCards =
    cardsFetchStatus === status.SUCCESS || cardsFetchStatus === status.FAILURE;

  const userRole =
    role ||
    highestAccountOrTeamRoleForAccountIdSelector(state, { accountId }) ||
    ROLES.COLLABORATOR;

  const canAutoChargeCard = canAutoChargeCardSelector(state, {
    role: userRole,
  });

  return {
    accountOwner,
    canAutoChargeCard,
    currentPlan,
    hasFetchedCards,
    userRole,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCardsByAccount: (accountId) => {
      dispatch(getCardsByAccount(accountId));
    },
    openSelectPlanFlowModal: (accountId) => {
      dispatch(openSelectPlanFlowModal(accountId));
    },
    onClose: () => {
      dispatch(closeModal());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LimitBlock);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
