import { eventChannel, END } from 'redux-saga';
import { call, select, take, takeLatest } from 'redux-saga/effects';
import { SHARED } from 'actions/shared';

import { alert, error, priority } from 'components/Dialog/SimpleDialog/sagas';
import { redirectToProject } from 'sagas/projects';
import { getAuthedUserId } from 'utils/auth';
import { redirectToAuth, reloadCurrentRoute } from 'utils/router';
import { currentUserEntitySelector } from 'selectors/users';
import { getRedirectTrackingOpts } from 'analytics';

/**
 * @deprecated This action/saga  coupling should not be called. Instead, components
 * should dispatch the corresponding SimpleDialog/action and simply push to the history.
 * Sagas should call the openError and redirectToProject sagas directly.
 */
export function* errorAndRedirect(action) {
  const { header, message } = action.payload;
  yield call(error, header, message);
  yield call(redirectToProject);
}

/**
 * A helper saga to select files using an `<input type="file" />`.
 * Since there are no semantics for the "cancel" button in the file
 * selection dialog, this saga will keep waiting until a file a chosen.
 * Ensure that the consuming saga is called with `takeLatest`, or
 * otherwise cancels previous calls to this or any consuming sagas.
 */
export function* selectFilesFromInput(attributes = {}) {
  const channel = yield call(eventChannel, (emitter) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    Object.keys(attributes).forEach((name) =>
      input.setAttribute(name, attributes[name])
    );
    input.addEventListener('change', (evt) => {
      emitter(evt.target.files);
      emitter(END);
    });
    input.click();
    // Nothing to cleanup since the input will get automatically GCed
    // so we return a no-op unsubscribe;
    return () => {};
  });
  return yield take(channel);
}

export function* requireLogin() {
  yield call(
    alert,
    'Oops! You’re not logged in.',
    'Please login to continue.',
    {
      primaryText: 'Log in',
      priority: priority.AUTH_REQUIRED,
      modalProps: {
        canCloseModal: false,
      },
    }
  );
  yield call(
    redirectToAuth,
    null,
    true,
    getRedirectTrackingOpts({
      details:
        'Logged out user navigated to app.frame.io and pressed Login button on to "Oops! You\'re not logged in." modal. This likely never happens because we redirect to auth before the user has a chance to do this.',
    })
  );
}

/**
 * If the user id from the auth token doesn't match the user id in the store, reload the page.
 * This can happen when the user logs out or logs in to a different account on another tab.
 *
 * When the page reloads, if there is an auth token user, then that account will be loaded.
 * Otherwise, the user will be redirected to the auth flow.
 */
export function* handleUserMismatch() {
  const user = yield select(currentUserEntitySelector);

  const userIdFromToken = getAuthedUserId();
  const userIdFromStore = user?.id;

  if (userIdFromStore && userIdFromStore !== userIdFromToken) {
    // This will wipe the store when the user changes.
    reloadCurrentRoute(true);
  }
}

export default [
  takeLatest(SHARED.ERROR_AND_REDIRECT, errorAndRedirect),
  takeLatest(SHARED.HANDLE_USER_MISMATCH, handleUserMismatch),
  takeLatest(SHARED.REQUIRE_LOGIN, requireLogin),
];
