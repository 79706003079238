import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  position: absolute;
  top: -${(p) => p.r}px;
  bottom: -${(p) => p.r}px;
  left: -${(p) => p.r}px;
  right: -${(p) => p.r}px;
`;

const StyledSpan = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  background: ${(p) => p.theme.color.coldWhite};
  opacity: 0.6;
  font-family: monospace;
  font-size: 16px;
  font-variant: tabular-nums;
  font-weight: bold;
  text-align: left;
  white-space: nowrap;
`;

const StyledSmall = styled.small`
  font-family: sans-serif;
  font-size: 60%;
`;

const StyledSvg = styled.svg`
  position: absolute;
  top: -${(p) => p.r}px;
  left: -${(p) => p.r}px;
  width: 100%;
  height: 100%;
  transform: translate(${(p) => p.offsetX}%, ${(p) => p.offsetY}%);
`;

/**
 * HACK: for dev debug purposes
 */
const DebugCoordinateMarker = (props) => {
  const {
    left,
    midpointRelativeOffset: [offsetX, offsetY],
    positionMax: [maxX, maxY],
    r,
    top,
  } = props;
  const format = (n) => (n * 100).toFixed(2);
  const prettyPrint = (x, y, unit) => (
    <React.Fragment>
      <StyledSmall>[</StyledSmall>
      {x}
      <StyledSmall>{unit}</StyledSmall>, {y}
      <StyledSmall>{unit}</StyledSmall>
      <StyledSmall>]</StyledSmall>
    </React.Fragment>
  );

  return (
    <StyledDiv r={r}>
      <StyledSpan r={r}>
        {prettyPrint(format(left / maxX), format(top / maxY), '%')}
        <br />
        {prettyPrint(Math.round(left), Math.round(top), 'px')}
      </StyledSpan>

      <StyledSvg
        shapeRendering="smoothEdges"
        r={r}
        offsetX={offsetX * -1}
        offsetY={offsetY * -1}
      >
        <circle
          cx={r}
          cy={r}
          fill="orange"
          r={r}
          stroke="orange"
          strokeWidth={1}
        />
      </StyledSvg>
    </StyledDiv>
  );
};

export default DebugCoordinateMarker;
