import Cookies from 'js-cookie';
import {
  all,
  call,
  spawn,
  takeLatest,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import Raven from 'raven-js';
import track, {
  getRedirectTrackingOpts,
  identifyPropertiesSelector,
  maybeClearExistingIdentityTraits,
  isPresentationOrReviewLinkPage,
  trackIdentify,
  hasRestrictDataCookie as checkRestrictDataCookie,
  removeRestrictDataCookie,
} from 'analytics';
import { get } from 'lodash';
import { setEdgeCacheReissueCookie } from '@frameio/core/src/auth';
import {
  ANON_USER as CORE_ANON_USER,
  AUTHED_USER as CORE_AUTHED_USER,
  updateUser,
} from '@frameio/core/src/users/actions';
import { logOut } from '@frameio/core/src/users/services';
import { joinUserRoom, leaveUserRoom } from '@frameio/core/src/sockets/actions';
import { getFlagsByMe } from '@frameio/core/src/featureFlags/sagas';
import { getRoleForAccount } from '@frameio/core/src/roles/sagas';
import { isAnonymousUser } from '@frameio/core/src/users/utils';
import { getAuthedUser } from '@frameio/core/src/users/sagas';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import { planEntitySelector } from '@frameio/core/src/plans/selectors';
import { USER_TYPES } from '@frameio/core/src/users/types';
import { updateAccount } from '@frameio/core/src/accounts/sagas';
import {
  AUTHED_USER as CLIENT_AUTHED_USER,
  setCurrentUser,
} from 'actions/users';
import { showErrorToast } from 'actions/toasts';
import config, { isProduction } from 'config';
import { CONFIRM_EMAIL } from 'pages/ConfirmEmailContainer/actions';
import {
  configureCore,
  getAuthedUserId,
  hasAuthCookies,
  removeAuthCookies,
  setAuthCookies,
  getAuthToken,
} from 'utils/auth';
import { Flags, clearLocalFlags } from 'utils/featureFlags';
import { redirectToAuth, reloadCurrentRoute } from 'utils/router';
import { currentUserEntitySelector } from 'selectors/users';
import { highestScopedAccountRoleSelector } from 'selectors/roles';
import { pathnameSelector } from 'selectors/router';
import { setLockedOutEmail } from 'components/AuthedRoute/actions';
import { subscriptionAndLineItemsCostSelector } from 'components/ManageSubscription/selectors';
import { subscriptionEntityByAccountIdSelector } from 'selectors/subscriptions';
import { syncAccountData } from 'sagas/accounts';
import { monthlyItemCost } from 'utils/plans/plansHelpers';
import { alert } from 'components/Dialog/SimpleDialog/sagas';
import confirmEmail, {
  handleConfirmEmailFailure,
  shouldShowconfirmEmailAddressToast,
} from './confirmEmail';
import maybeTrackMQLOrMGLEmail from './clearbitReveal';

function* resetSession() {
  yield call(configureCore);

  // Call logOut api only if there's a current session
  if (hasAuthCookies()) {
    try {
      yield call(logOut);
      yield put(leaveUserRoom(getAuthedUserId()));
    } catch (err) {
      Raven.captureException(err);
    }
  }

  // NOTE: make sure to take every event after logut so that no other reducer is called after this.

  // Clear all stored user data
  yield call([window.localStorage, 'clear']);

  // Clear the flags so that if a different user is logged into the flags won't clash with
  // the server flags.
  yield call(clearLocalFlags);

  // Clear session cookies
  yield call(removeAuthCookies);

  const hasRestrictDataCookie = yield call(checkRestrictDataCookie);
  if (hasRestrictDataCookie) {
    yield call(removeRestrictDataCookie);
  }

  // Reset Segment
  const reset = window.analytics && window.analytics.reset;

  if (typeof reset === 'function') {
    yield call(reset);
  }

  // Reset Intercom
  if (typeof window.Intercom === 'function') {
    yield call(window.Intercom, 'shutdown');
  }

  // Manually clear Intercom cookies since `Intercom('shutdown')` does
  // not seem to be working properly with segment integration.
  const cookies = document.cookie.split(';');
  cookies.forEach((_cookie) => {
    const cookie = _cookie.trim();
    const cookieName = cookie.split('=')[0];
    if (cookieName.startsWith('intercom')) {
      document.cookie = `${cookieName}=;domain=.frame.io;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  });

  // Send to welcome page
  yield call(
    redirectToAuth,
    null,
    false,
    getRedirectTrackingOpts({ details: 'Pressed the logout button' })
  );
}

// Cross-domain cookie so that marketing can target people on blog.frame.io.
function* createLoginCookies() {
  try {
    const cookieConfig = { expires: 365, domain: config.domain };
    const currentDate = new Date().toISOString();

    yield call(Cookies.set, 'fio-has-logged-in', '1', cookieConfig);
    yield call(Cookies.set, 'fio-last-login', currentDate, cookieConfig);
  } catch (err) {
    Raven.captureException(err);
  }
}

function* joinUserSocketRoom(userId) {
  try {
    yield put(joinUserRoom(userId));
  } catch (err) {
    Raven.captureException(err);
  }
}

function* persistAnonymousUser(action) {
  const {
    response: { authorizationToken, result: userId },
  } = action.payload;

  // We don't have a refresh token for anon users, so simply omit it
  yield call(setAuthCookies, authorizationToken);

  // We have to reconfigure core so that subsequent calls use the auth token
  yield call(configureCore);

  // Set the current user to show that we're authenticated now
  yield put(setCurrentUser(userId));
}

const AUTHENTICATED = 'authentication-confirmed-client';
const JOINED_ACCOUNT = 'joined_account';

function* trackUserJoinedAccount(email, role) {
  yield spawn(track, JOINED_ACCOUNT, {
    email,
    joined_as: role,
  });
}

function* trackUserSignIn(user, role) {
  const isEdu = user?.isEdu ?? false;
  yield spawn(track, AUTHENTICATED, {
    is_edu: isEdu,
    email: user.email,
    user_type: role,
    marketing_status:
      (window.adobePrivacy &&
        window.adobePrivacy.activeCookieGroups().includes('C0004')) ||
      false,
  });
}

function setRavenContext(userId) {
  Raven.setUserContext({
    id: userId,
  });
}

function* trackFirstOrSubsequentSignIn(user) {
  const {
    account_id: accountId,
    email,
    first_login_at: firstLoginAt,
    joined_via,
  } = user;

  if (accountId) {
    yield call(getRoleForAccount, accountId);
  }
  const role = yield select(highestScopedAccountRoleSelector, accountId);

  // If the user has never logged into the app before we perform some additional track calls
  if (!firstLoginAt) {
    yield spawn(trackUserJoinedAccount, email, role);
    yield spawn(track, 'first-app-page-viewed', { joined_via });
    yield call(maybeTrackMQLOrMGLEmail);

    // Mark the user as now signed in so that we don't fire this action again
    yield put(
      updateUser({
        first_login_at: new Date().toISOString(),
      })
    );
  } else {
    yield spawn(trackUserSignIn, user, role);
  }
}

function* updateIdentiferInfo(payload) {
  const user = yield select(currentUserEntitySelector);
  if (user) {
    const { id: userId, user_hash: intercomUserHash } = user;
    const identifyProperties = yield select(identifyPropertiesSelector);
    const fullIdentifyProperties = {
      ...identifyProperties,
      ...payload,
    };
    const authToken = yield call(getAuthToken);

    const pathname = yield select(pathnameSelector);
    const isPresentationOrReviewLink = yield call(
      isPresentationOrReviewLinkPage,
      pathname
    );
    const integrations = {
      Intercom: !isPresentationOrReviewLink && { user_hash: intercomUserHash },
      Drift: !isPresentationOrReviewLink && {
        // SBC-150 - https://segment.com/docs/connections/destinations/catalog/drift/#identify
        userJwt: authToken,
      },
    };

    // Clear any existing identity traits that are spurious
    yield call(maybeClearExistingIdentityTraits);

    yield spawn(trackIdentify, userId, fullIdentifyProperties, {
      integrations,
    });
  }
}

/**
 * We want to track user/identify profiles throughout the app.
 */

function* onAuthedAnonymousUserSuccess(user) {
  yield all([spawn(trackUserSignIn, user, USER_TYPES.ANONYMOUS_USER)]);
}

function* trackFirstPaymentIfNeeded(accountId) {
  const account = yield select(accountEntitySelector, { accountId });
  const subscription = yield select(
    subscriptionEntityByAccountIdSelector,
    accountId
  );

  if (!account) {
    /**
     * This could be a result of a user:
     * 1) Either having more accounts than the network request supports
     * 2) A user has deleted their own account and thus has none.
     */
    Raven.captureMessage(
      'AuthedRoute/sagas: trackFirstPaymentIfNeeded; user account is not included in 1st page of /accounts request.',
      {
        level: 'info',
        tags: {
          jira_ticket: 'CORE-5747',
        },
      }
    );
    return;
  }
  const { revenue_reported_at: revenueReportedAt } = account;
  const { last_payment_at: lastPaymentAt, on_trial: onTrial } = subscription;

  if (revenueReportedAt || onTrial) return;

  const plan = yield select(planEntitySelector, {
    planId: subscription.plan_id,
  });
  const totalCost = yield select(subscriptionAndLineItemsCostSelector, {
    accountId,
    plan,
  });
  const monthlyCost = yield call(monthlyItemCost, plan.period, totalCost);

  if (!revenueReportedAt && lastPaymentAt) {
    yield spawn(track, 'first-payment', {
      first_payment_monthly_amount: monthlyCost,
    });
    yield call(updateAccount, accountId, {
      revenue_reported_at: new Date(),
    });
  }
}

function* onAuthedRegularUserSuccess(user) {
  yield call(getFlagsByMe, user.id, Object.values(Flags));
  yield all([
    // Always set the edge reissue cookie on page load. There might be a couple
    // reasons why it might not be set yet:
    //
    // 1. When the Secure Edge Cache feature launches, existing sessions would
    //    not have gone through `AuthCallback.js` yet.
    // 2. The call in `AuthCallback.js` might've had a network failure. Calling
    //    it here ensures that this can be fixed with a page refresh.
    spawn(setEdgeCacheReissueCookie),

    call(createLoginCookies),
    call(joinUserSocketRoom, user.id),
    call(syncAccountData),
    call(shouldShowconfirmEmailAddressToast),
  ]);

  /**
   * Call trackFirstOrSubsequentSignIn and trackFirstPaymentIfNeeded after
   * the account data have been synced.
   */
  yield call(trackFirstOrSubsequentSignIn, user);
  yield call(trackFirstPaymentIfNeeded, user.account_id);
}

function* onAuthedUserSuccess() {
  const user = yield select(currentUserEntitySelector) || {};
  const isUserAnonymous = yield call(isAnonymousUser, user);

  // Calls that are consistent between user types should go here.
  yield call(setRavenContext, user.id);

  // Depending on the type of user we have here, we call a different success handler.
  // Each handler should fire off the relevant functions it needs to perform depending
  // on the user type.
  yield call(
    isUserAnonymous ? onAuthedAnonymousUserSuccess : onAuthedRegularUserSuccess,
    user
  );
}

function* onAuthedUserFailure(authedUserResponse) {
  const errorResponse = get(
    authedUserResponse,
    'failure.payload.error.response'
  );
  if (errorResponse) {
    // If the user has not confirmed their email, let's save the email to the
    // currentUser state and utilize it in `UnconfirmedEmailLockout`
    const email = errorResponse?.data?.errors?.[0]?.email;
    if (email) {
      yield put(setLockedOutEmail(email));
    }
    return;
  }

  // Handle null `errorResponse` exceptions for prod and non-prod envs
  const requiresVPN = !isProduction;
  if (requiresVPN) {
    yield put(
      showErrorToast({
        header: 'Do you need to connect to VPN?',
        autoCloseDelay: 0,
      })
    );
  } else {
    // When /me fails because of a transient network error, let the user to
    // manually refresh the page. The alternative here is auto-retries.
    yield call(
      alert,
      'Oops! Something went wrong.',
      'Please try refreshing your browser.',
      {
        primaryText: 'Refresh',
        modalProps: {
          canCloseModal: false,
        },
      }
    );

    // This only gets called when the button in the alert dialog above is
    // triggered.
    yield call(reloadCurrentRoute);
  }
}

/**
 * Fetch the user associated with the auth token.
 *
 * TODO (GROW-3319): Rename this to `setAuthedUser`.
 *
 * @returns {Object} - { success, failure }
 */
export function* authenticate() {
  try {
    // If already authed, return `success` state. This prevents the side-effects
    // below from getting called redundantly, when navigating from routes that
    // are not enforced by `AuthedRoute` like join shared project endpoint and
    // review link pages.
    const user = yield select(currentUserEntitySelector);
    if (user) return { success: true };

    const authedUserResponse = yield call(getAuthedUser);

    if (authedUserResponse.success) {
      yield call(onAuthedUserSuccess);
    } else {
      // This covers non-401 errors from `getAuthedUser`. A 401 would've been
      // intercepted by `authRequiredInterceptor`, which would've either
      // refreshed the auth token (and thus call `onAuthedUserSuccess`) or get
      // redirected to auth already.
      yield call(onAuthedUserFailure, authedUserResponse);
    }

    return authedUserResponse;
  } catch (e) {
    Raven.captureException(e);
    return { failure: e };
  }
}

export default [
  takeLatest(CLIENT_AUTHED_USER.LOG_OUT, resetSession),
  // TODO(marvin): Replace with AUTHED_USER.CONFIRM_EMAIL.BASE once <AuthRoute>
  // takes care of the redirect, so that when core confirmEmail() action is
  // called, auth state is guaranteed.
  takeLatest(CONFIRM_EMAIL, confirmEmail),
  takeEvery(CORE_AUTHED_USER.CONFIRM_EMAIL.FAILURE, handleConfirmEmailFailure),
  takeLatest(CORE_ANON_USER.REGISTER.SUCCESS, persistAnonymousUser),
  takeEvery(CLIENT_AUTHED_USER.UPDATE_IDENTIFER_INFO, updateIdentiferInfo),
];

export const testExports = {
  onAuthedUserSuccess,
  onAuthedRegularUserSuccess,
  onAuthedAnonymousUserSuccess,
  onAuthedUserFailure,
  trackFirstOrSubsequentSignIn,
  trackUserJoinedAccount,
  trackUserSignIn,
  setRavenContext,
  persistAnonymousUser,
  joinUserSocketRoom,
  createLoginCookies,
  resetSession,
  trackFirstPaymentIfNeeded,
  AUTHENTICATED,
  JOINED_ACCOUNT,
};
