import isAssetUrl from './isAssetUrl';
import redactQueryString from './redactQueryString';

/**
 * Get a dictionary of options for the telemetry API
 * https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters
 */
const getRumOptions = (isDatadogRumEnabled) =>
  isDatadogRumEnabled
    ? {
        /**
         * Enables automatic collection of users actions
         */
        trackInteractions: true,
        /**
         * A list of request origins used to inject tracing headers
         */
        allowedTracingOrigins: [
          `https://local.dev.frame.io`,
          `https://app-local.dev.frame.io`,
          `https://api.dev.frame.io`,
          `https://api.frame.io`,
          `https://api.staging.frame.io`,
          `https://assets.dev.frame.io`,
          `https://assets.eval.frame.io`,
          `https://assets.frame.io`,
          `https://assets.staging.frame.io`,
          `https://app.dev.frame.io`,
          `https://app.staging.frame.io`,
        ],
        /**
         * The `beforeSend` callback function gives you access to every event collected by the RUM SDK before they are sent to Datadog. Intercepting the RUM events allows you to:
         * https://docs.datadoghq.com/real_user_monitoring/guide/enrich-and-control-rum-data/?tab=event#event-and-context-structure
         * Enrich your RUM events with additional context attributes
         * Modify your RUM events to modify their content, or redact sensitive sequences (see list of editable properties)
         * Discard selected RUM events
         */
        beforeSend(evt) {
          /* eslint-disable no-param-reassign */
          if (evt.type === `resource` && isAssetUrl(evt.resource.url)) {
            evt.resource.url = redactQueryString(evt.resource.url);
          } else if (evt.type === `view` && isAssetUrl(evt.view.url)) {
            evt.view.url = redactQueryString(evt.view.url);
          }
          /* eslint-enable no-param-reassign */
        },
      }
    : {};

export default getRumOptions;
