import React from 'react';
import {
  call,
  takeEvery,
  put,
  select,
  takeLatest,
  spawn,
} from 'redux-saga/effects';
import { get } from 'lodash';
import { getPresentationByVanity } from '@frameio/core/src/presentations/sagas';
import { setReviewerSeenForPresentation } from '@frameio/core/src/reviewers/sagas';
import { presentationEntityByVanitySelector } from '@frameio/core/src/presentations/selectors';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import { openModal } from 'components/Modal/actions';
import RequestAccessToEntity from 'components/RequestAccessToEntity';
import getFailureReason, {
  FAILURE_REASONS,
} from 'components/AuthGate/failureReasons';
import { ENTITY_TYPE } from 'components/AuthGate/AuthGate';
import { getRoleForProject } from 'actions/roles';
import { hasCurrentUserConfirmedEmailAddress } from 'selectors/users';
import track from 'analytics';
import { configureCore, hasAuthCookies, removeAuthCookies } from 'utils/auth';
import { authenticate } from 'sagas/authedUser';
import { seriallyDownloadBatchFiles } from 'sagas/assets';
import { PRESENTATION_PAGE, presentationFetchFailed } from './actions';

export function* trackMediaViewed(presentationId, assetId, isAutoPlayEnabled) {
  const asset = yield select(assetEntitySelector, { assetId });
  yield spawn(track, 'media-viewed-client', {
    file_format: asset.filetype,
    filesize: asset.filesize,
    page_type: 'presentation page',
    presentation_id: presentationId,
    autoplay: isAutoPlayEnabled,
    is_version_stack: false,
  });
}

/**
 *
 * @param {string} reviewLinkId - Id of the review link to request
 * @returns {string} FAILURE_REASON - false if access succeeded
 */
export function* attemptPresentationAccess(presentationId, password) {
  const { success, failure } = yield call(
    getPresentationByVanity,
    presentationId,
    password
  );
  if (success) {
    return { success };
  }
  const errorCode = get(failure, 'payload.error.response.status');
  const hasCurrentUserConfirmedEmail = yield select(
    hasCurrentUserConfirmedEmailAddress
  );

  if (errorCode === 403 && hasCurrentUserConfirmedEmail) {
    yield put(
      openModal(
        <RequestAccessToEntity
          entityType={ENTITY_TYPE.PRESENTATION}
          entityId={presentationId}
        />,
        { canCloseModal: false }
      )
    );
  }

  const failureReason = yield call(getFailureReason, failure);
  return { failureReason };
}

export function* enterPresentation({
  isRetry = false,
  payload: { vanityId, password },
}) {
  yield call(configureCore);

  if (yield call(hasAuthCookies)) {
    yield call(authenticate);
  }

  // Get presentation and prompt to request access if failure reason is forbidden
  const { failureReason } = yield call(
    attemptPresentationAccess,
    vanityId,
    password
  );

  // If the reason for failure is NOT_LOGGED_IN,
  // Clear the auth cookies and reattempt to enter the presentation
  // The backend will return a 401 error if we are sending an unrefreshable
  // anonymous user auth token.
  // BUGS-1151
  if (
    // only attempt a retry once to avoid an infinite loading loop
    !isRetry &&
    failureReason &&
    failureReason === FAILURE_REASONS.NOT_LOGGED_IN
  ) {
    yield call(removeAuthCookies);
    yield call(enterPresentation, {
      isRetry: true,
      payload: { vanityId, password },
    });
    return;
  }

  if (failureReason) {
    if (failureReason !== FAILURE_REASONS.FORBIDDEN) {
      yield put(presentationFetchFailed(vanityId, failureReason));
    }
    return;
  }

  const { id: presentationId, project: projectId } = yield select(
    presentationEntityByVanitySelector,
    { vanity: vanityId }
  );
  yield put(getRoleForProject(projectId));
  yield spawn(track, 'presentation-page-viewed', {
    presentation_id: presentationId,
  });
  yield spawn(setReviewerSeenForPresentation, presentationId);
}

export function* handlePresentationBulkDownload(presentation) {
  const assetIds = presentation.assets;
  yield call(seriallyDownloadBatchFiles, assetIds);
}

export default [
  takeEvery(PRESENTATION_PAGE.ENTER_PRESENTATION, enterPresentation),
  takeLatest(PRESENTATION_PAGE.BULK_DOWNLOAD, ({ payload: { presentation } }) =>
    handlePresentationBulkDownload(presentation)
  ),
  takeLatest(
    PRESENTATION_PAGE.TRACK_MEDIA_VIEWED,
    ({ payload: { presentationId, assetId, isAutoPlayEnabled } }) =>
      trackMediaViewed(presentationId, assetId, isAutoPlayEnabled)
  ),
];
