import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const SHARED = generateActionTypes('SHARED', [
  'ERROR_AND_REDIRECT',
  'HANDLE_USER_MISMATCH',
  'REQUIRE_LOGIN',
]);

/**
 * @param {String} header - Error header
 * @param {String} message - Error message
 * @returns {Object} - Action object.
 */
export const errorAndRedirect = createAction(
  SHARED.ERROR_AND_REDIRECT,
  (header, message) => ({ header, message })
);

export const handleUserMismatch = createAction(
  SHARED.HANDLE_USER_MISMATCH,
  () => ({})
);

export const requireLogin = createAction(SHARED.REQUIRE_LOGIN, () => ({}));
