import { userEntitySelector } from '@frameio/core/src/users/selectors';

const SORT_OPTIONS = {
  ACTIVE: {
    value: 'is_active',
    canSortDescending: true,
    sortDescendingByDefault: false,
    label: 'Active',
    asc: 'Inactive first',
    desc: 'Active first',
  },
  CREATED_AT: {
    value: 'inserted_at',
    canSortDescending: true,
    sortDescendingByDefault: true,
    label: 'Date created',
    asc: 'Oldest first',
    desc: 'Newest first',
  },
  NAME: {
    value: 'name',
    canSortDescending: true,
    sortDescendingByDefault: false,
    label: 'Name',
  },
  VIEWS: {
    value: 'view_count',
    canSortDescending: true,
    sortDescendingByDefault: false,
    label: 'Views',
    asc: 'Fewest views',
    desc: 'Most views',
  },
  CREATED_BY: {
    value: 'owner.name',
    canSortDescending: true,
    sortDescendingByDefault: false,
    label: 'Created by',
  },
};

function getReviewLinkSortValue(link, sortByField, rootState) {
  let sortValue;

  if (sortByField === SORT_OPTIONS.CREATED_BY.value) {
    const { name } = userEntitySelector(rootState, { userId: link.owner_id });
    sortValue = name;
  } else {
    sortValue = link[sortByField];
  }
  sortValue =
    typeof sortValue === 'string' ? sortValue.toLowerCase() : sortValue;

  return {
    sortValue,
    createdAtValue: link[SORT_OPTIONS.CREATED_AT.value],
  };
}

export function sortReviewLinkIds(
  reviewLinkIds,
  entitiesMap,
  sortByField,
  sortDescending,
  rootState
) {
  const sortedReviewLinks = reviewLinkIds
    .map((id) => entitiesMap[id])
    .sort((a, b) => {
      const {
        sortValue: aValue,
        createdAtValue: aCreatedAt,
      } = getReviewLinkSortValue(a, sortByField, rootState);
      const {
        sortValue: bValue,
        createdAtValue: bCreatedAt,
      } = getReviewLinkSortValue(b, sortByField, rootState);

      if (aValue < bValue) return sortDescending ? 1 : -1;
      if (aValue > bValue) return sortDescending ? -1 : 1;

      // Secondary descending sort on created at
      if (aCreatedAt < bCreatedAt) return 1;
      if (aCreatedAt > bCreatedAt) return -1;
      return 0;
    });

  return sortedReviewLinks.map((link) => link.id);
}

export default SORT_OPTIONS;
