import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TreeItem } from '@frameio/components/src/styled-components/TreeView';
import { Input } from '@frameio/components/src/styled-components/TextInput';
import { DEFAULT_FOLDER_NAME } from 'sagas/assets';
import { FolderCreationEvents } from './actions';
import FolderIcon from './FolderIcon';
import TreeItemLoadingIndicator from './TreeItemLoadingIndicator';

const TreeItemInput = styled(TreeItem)`
  // Remove the focus glow.
  background-color: ${(p) => p.theme.color.white};
  &:hover {
    background-color: ${(p) => p.theme.color.white};
  }
`;

const TreeInput = styled(Input)`
  max-width: 359px;
  padding: 6px;
`;

/**
 * TreeViewItemPhantom
 *
 * Ability to create a folder by first generating a "phantom" folder
 * in a Project Tree. Once a user types enter we will create/save the folder.
 * After creation we force a refesh on the folder tree fetch,
 * and the phantom will be replaced with the successfully created.
 */

function TreeViewItemPhantom({
  parentFolder,
  isNewFolderSaving,
  isFetchingNewFolderTree,
  onCreateNewFolder,
  onSetFolderCreationStatus,
}) {
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState(DEFAULT_FOLDER_NAME);

  const performFolderCreate = useCallback(() => {
    /**
     * we assume the folder is set to "not private".
     * if the folder is placed inside of a private folder it will
     * Still be "encapsulated" in private-ness
     */
    const isPrivate = false;
    // DO we want to sanitize folder name, we are already limiting the length in the input.
    const name = inputRef.current.value || inputValue;
    // set to place up front.
    const index = 0;

    setInputValue(name);
    onCreateNewFolder(parentFolder, isPrivate, index, name);
  }, [parentFolder, inputRef, inputValue, onCreateNewFolder]);

  /**
   * This overrides all the events that happen at the components/TreeView level.
   * Intentionally left redundant for clarity about what it specifically is overriding.
   * Remove an e.stopPropagation events need to bubble up
   * but will cause conflict with folder creation state.
   */
  const onKeyDown = (e) => {
    //
    switch (e.key) {
      case 'Tab': {
        performFolderCreate();
        e.stopPropagation();
        return;
      }
      case 'Enter': {
        performFolderCreate();
        e.stopPropagation();
        return;
      }
      case 'Escape': {
        // Cancel
        onSetFolderCreationStatus(FolderCreationEvents.NONE);
        // prevent propagation otherwise the entire emodal will collapse: poof.
        e.stopPropagation();
        return;
      }
      case 'ArrowUp': {
        // do nothing (could be a user accidently typing arrow up/down instead of left right)
        e.stopPropagation();
        return;
      }
      case 'ArrowDown': {
        // do nothing (could be a user accidently typing arrow up/down instead of left right)
        e.stopPropagation();
        return;
      }
      default:
        // allow for typing in the input, and disregard tree keyDown events.
        // if we update the inputValue here, we negate cntrl-p commands.
        e.stopPropagation();
    }
  };

  const onClick = (e) => {
    e.stopPropagation();
  };

  const onDoubleClick = (e) => {
    e.stopPropagation();
  };

  const onFocus = (e) => {
    e.stopPropagation();
  };

  const onBlur = (e) => {
    // relatedTarget is not defined if a user clicks outside of the browser view:
    if (!e.relatedTarget) return;
    // a user has selected to cancel the entire modal. Do not create a folder.
    if (e.relatedTarget.dataset.modalbuttoncancel) return;

    performFolderCreate();
    e.stopPropagation();
  };

  return isNewFolderSaving || isFetchingNewFolderTree ? (
    <TreeItem
      nodeId="phantom-folder-saving"
      showChevron={false}
      isSelected={false}
      isSelectable={false}
      disabled
      label={inputValue}
      iconBefore={<FolderIcon isSelected={false} disabled />}
      iconAfter={<TreeItemLoadingIndicator isLoading />}
    />
  ) : (
    <TreeItemInput
      nodeId="phantom-folder-not-saved-yet"
      showChevron={false}
      isSelected={false}
      label={
        <TreeInput
          type="text"
          maxLength="255"
          placeholder={DEFAULT_FOLDER_NAME}
          autoFocus
          onClick={onClick}
          onDoubleClick={onDoubleClick}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={inputRef}
        />
      }
      iconBefore={<FolderIcon isSelected={false} />}
    />
  );
}

TreeViewItemPhantom.propTypes = {
  parentFolder: PropTypes.string.isRequired,
  isNewFolderSaving: PropTypes.bool.isRequired,
  isFetchingNewFolderTree: PropTypes.bool.isRequired,
  onCreateNewFolder: PropTypes.func.isRequired,
  onSetFolderCreationStatus: PropTypes.func.isRequired,
};

export default React.memo(TreeViewItemPhantom);
