import { createSelector } from 'reselect';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import { currentProjectEntitySelector } from '../../../selectors/projects';

export const moveToFolderSelector = (state) => state.moveTo;

export const moveAssetMenuItemSelector = createSelector(
  [assetEntitySelector, currentProjectEntitySelector],
  (folder, { id: projectId }) => {
    if (!folder || !projectId) return null;
    return {
      folder,
      projectId,
    };
  }
);

// Get project assets
export const projectAssetTree = (state, projectId) => {
  const moveToFolder = moveToFolderSelector(state).assetFolderTrees;
  return moveToFolder[projectId];
};
