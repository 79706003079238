import { useCallback, useRef, useState } from 'react';

/**
 *
 * @param {Array} indexes array of list indexes to cycle through
 * @param {Function} onDoubleClick handle double click events
 * @param {Function} onSelect handle select events
 */
function useKeyboardNav(indexes, onDoubleClick, onSelect) {
  const doubleEnteredRef = useRef(false);
  const doubleEnterTimerRef = useRef();
  const [focusedId, setFocusedId] = useState();

  const handleKeyDown = useCallback(
    (e) => {
      const getCurrentIndex = (id) => indexes.indexOf(id);

      // get the next index (stopping at the bottom)
      const getNextIndex = (id) => {
        const currentIndex = getCurrentIndex(id);
        if (currentIndex + 1 < indexes.length) {
          return currentIndex + 1;
        }
        return currentIndex;
      };

      // get the previous index (stopping at the top)
      const getPrevIndex = (id) => {
        const currentIndex = getCurrentIndex(id);
        if (currentIndex - 1 >= 0) {
          return currentIndex - 1;
        }
        return currentIndex;
      };

      let nextIndex;

      // eslint-disable-next-line default-case
      switch (e.key) {
        case 'ArrowDown': {
          nextIndex = getNextIndex(focusedId);
          break;
        }
        case 'ArrowUp': {
          nextIndex = getPrevIndex(focusedId);
          break;
        }
        case 'Enter': {
          onSelect(focusedId);

          // support "double enter" to emulate double click
          const shouldDoubleClick = doubleEnteredRef.current === focusedId;
          if (shouldDoubleClick) {
            onDoubleClick(focusedId);
            clearTimeout(doubleEnterTimerRef.current);
          } else {
            doubleEnteredRef.current = focusedId;
            doubleEnterTimerRef.current = setTimeout(() => {
              doubleEnteredRef.current = false;
            }, 300);
          }
          break;
        }
      }

      const nextId = indexes[nextIndex];
      if (nextId && nextId !== focusedId) {
        setFocusedId(nextId);
      }
    },
    [focusedId, indexes, onDoubleClick, onSelect]
  );

  return {
    handleKeyDown,
    setFocusedId,
    focusedId,
  };
}

export default useKeyboardNav;
