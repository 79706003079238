import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DropArrowIcon from '@frameio/components/src/svgs/icons/caron.svg';

const DropArrow = styled(DropArrowIcon)`
  margin-left: 6px;
  margin-right: -6px;
`;

const StyledButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 8px 12px;
  border: 1px solid ${(p) => p.theme.color.silver};
  border-radius: 4px;
`;

/**
 * Wraps a <Button> component and adds a dropdown arrow.
 */
function DropdownButton({ children, dropdown, forwardedRef, ...rest }) {
  return (
    <StyledButton ref={forwardedRef} {...rest}>
      {children}
      <DropArrow width={18} height={18} />
    </StyledButton>
  );
}

DropdownButton.defaultProps = {
  dropdown: undefined,
  children: undefined,
};

DropdownButton.propTypes = {
  dropdown: PropTypes.bool,
  children: PropTypes.node,
};

export default React.forwardRef((props, ref) => (
  <DropdownButton forwardedRef={ref} {...props} />
));
