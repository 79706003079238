import { createSelector } from 'reselect';
import { flow } from 'lodash';

import { contextMenuPropsSelector } from 'components/ContextMenuManager/selectors';
import { userEntitySelector } from '@frameio/core/src/users/selectors';
import {
  paginatedListAllResultsSelector,
  paginatedListTotalCountSelector,
  paginatedListMetadataSelector,
  paginatedListIsFetchingPageOneSelector,
  shouldFetchListPageSelector,
} from '@frameio/core/src/shared/selectors';
import { reviewLinkEntitySelector } from '@frameio/core/src/reviewLinks/selectors';
import { formatShortName } from 'formatters/name';
import { projectContainerSelector } from '../selectors';

const linksSelector = (state) => projectContainerSelector(state).links;

const projectLinksMetadataSelector = flow([
  linksSelector,
  paginatedListMetadataSelector,
]);

export const projectIdForLinksSelector = (state) =>
  linksSelector(state).projectId;

export const reviewLinkIdsSelector = createSelector(
  linksSelector,
  paginatedListAllResultsSelector
);

export const totalCountSelector = createSelector(
  linksSelector,
  paginatedListTotalCountSelector
);

export const isSortDescendingSelector = createSelector(
  linksSelector,
  (links) => links.isSortDescending
);

export const areLinksEmptySelector = createSelector(
  projectLinksMetadataSelector,
  ({ total }) => total === 0
);

export const totalPagesSelector = createSelector(
  projectLinksMetadataSelector,
  ({ totalPages }) => totalPages
);

export const shouldFetchLinksSelector = (state, { page }) => {
  const slice = linksSelector(state);
  return shouldFetchListPageSelector(slice, { page });
};

export const isFirstPageFetchingSelector = (state) => {
  const slice = linksSelector(state);
  return paginatedListIsFetchingPageOneSelector(slice);
};

export const sortBySelector = createSelector(
  linksSelector,
  (links) => links.sortBy
);

export const selectedReviewLinkIdSelector = (state) =>
  linksSelector(state).selectedReviewLinkId;

export const linksOptionsSelector = createSelector(
  sortBySelector,
  isSortDescendingSelector,
  (sortByField, sortDescending) => ({
    pageSize: 40,
    sortBy: sortByField,
    sortDescending,
  })
);

export function creatorNameForReviewLinkSelector(state, { reviewLinkId }) {
  const reviewLink = reviewLinkEntitySelector(state, { reviewLinkId });
  const userEntity = userEntitySelector(state, { userId: reviewLink.owner_id });
  return formatShortName(userEntity);
}

export const reviewLinkIdForContextMenuSelector = (state) => {
  const menuProps = contextMenuPropsSelector(state);

  return menuProps.reviewLinkId;
};
