import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';
import { isFetchingActionFactory } from 'factories/isFetching';

const module = 'CANCEL_ACCOUNT_FLOW';
export const CANCEL_ACCOUNT_FLOW = {
  ...generateActionTypes(module, [
    'START',
    'SELECT_REASON',
    'SUBMIT_FEEDBACK',
    'CONFIRM_CANCELLATION',
    'CONTINUE',
    'END',
    'IS_FETCHING',
  ]),
};

export const isFetching = isFetchingActionFactory(
  CANCEL_ACCOUNT_FLOW.IS_FETCHING
);

export const startFlow = createAction(
  CANCEL_ACCOUNT_FLOW.START,
  (accountId) => ({ accountId })
);

export const selectReason = createAction(
  CANCEL_ACCOUNT_FLOW.SELECT_REASON,
  (reason) => ({ reason })
);

export const submitFeedback = createAction(
  CANCEL_ACCOUNT_FLOW.SUBMIT_FEEDBACK,
  (feedback) => ({ feedback })
);

export const confirmCancellation = createAction(
  CANCEL_ACCOUNT_FLOW.CONFIRM_CANCELLATION,
  (accountId) => ({ accountId })
);

export const continueFlow = createAction(
  CANCEL_ACCOUNT_FLOW.CONTINUE,
  () => ({})
);

export const endFlow = createAction(CANCEL_ACCOUNT_FLOW.END, () => ({}));
