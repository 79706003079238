import { plans } from 'utils/plans/plansHelpers';
import { isProduction, isStaging, isEvaluation } from 'config';
import { formatMoney } from 'formatters/money';
import { formatBytes } from 'shared/filesizeHelpers';
import {
  ARCHIVED_STORAGE_LIMIT,
  STORAGE_LIMIT,
  MEMBER_LIMIT,
  USER_MAX,
} from '@frameio/core/src/subscriptionLineItems/utils/modifierTypes';

const USER_LIMIT = 'user_limit';

const planVersionsFiveThroughSeven = new Set([5, 6, 7]);

const TEXT = {
  freeTitle: 'Free',
  proTitle: 'Pro',
  teamTitle: 'Team',
  enterpriseTitle: 'Enterprise',
  users: (count) => `Up to ${count} ${count === 1 ? 'user' : 'users'}`,
  storage: (count) => `${formatBytes(count)} storage`,
  projects: (count) =>
    count === null
      ? 'Unlimited projects'
      : `${count} ${count === 1 ? 'project' : 'projects'}`,
};

export const BILL_PERIOD = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
};

export const PLAN_TIERS = {
  FREE: 'free',
  PRO: 'pro',
  TEAM: 'team',

  // The goal is to have this replace the `is_enterprise` flag eventually.
  ENTERPRISE: 'enterprise',
};

export const LEGACY_PLAN_TIERS = {
  STARTER: plans.STARTER,
  PRO: plans.PRO,
  TEAM: plans.TEAM,
  BUSINESS: plans.BUSINESS,
  ENTERPRISE: plans.ENTERPRISE,
};

const legacyPlanNames = {
  STARTER_MO: '20181119-01-starter-mo',
  STARTER_YR: '20181119-01-starter-yr',
  PRO_MO: '20181030-01-pro-mo',
  PRO_YR: '20181030-01-pro-yr',
  TEAM_MO: '20181030-01-team-mo',
  TEAM_YR: '20181030-01-team-yr',
  BUSINESS_MO: '20181030-01-business-mo',
  BUSINESS_YR: '20181030-01-business-yr',
};

// When upgrading or downgrading, legacy land relies on the plan name (e.g. `20181119-01-starter` -
// see `planIds` (which should really be named `planNames`) in `PlansHelpers.js`).
// `<SelectPlanFlow />`, however, relies on the plan id to peform the upgrade / downgrade.
const productionLegacyPlanIds = {
  [legacyPlanNames.STARTER_MO]: '71137001-598c-4a88-b399-15206e76c58f',
  [legacyPlanNames.STARTER_YR]: '0c343833-b3da-4b0c-8335-30f761810c76',
  [legacyPlanNames.PRO_MO]: 'f67ba21a-d560-4d95-af76-a38800cf1b20',
  [legacyPlanNames.PRO_YR]: 'b9d4e67c-b503-41cd-a657-915defcbfa74',
  [legacyPlanNames.TEAM_MO]: '600ab2a4-e78e-4639-bbb9-542fa32879be',
  [legacyPlanNames.TEAM_YR]: '877e0c27-be47-44fe-841b-f0d385eacd37',
  [legacyPlanNames.BUSINESS_MO]: '3b93eec3-7302-4e14-8b6b-7c62c399377f',
  [legacyPlanNames.BUSINESS_YR]: '5780cc0d-6b53-4784-9e48-cb5af5eb89b4',
};

// The Staging environment plans were copied over from the Prod DB, hence the
// duplicate plan IDs
const stagingLegacyPlanIds = {
  [legacyPlanNames.STARTER_MO]: '71137001-598c-4a88-b399-15206e76c58f',
  [legacyPlanNames.STARTER_YR]: '0c343833-b3da-4b0c-8335-30f761810c76',
  [legacyPlanNames.PRO_MO]: 'f67ba21a-d560-4d95-af76-a38800cf1b20',
  [legacyPlanNames.PRO_YR]: 'b9d4e67c-b503-41cd-a657-915defcbfa74',
  [legacyPlanNames.TEAM_MO]: '600ab2a4-e78e-4639-bbb9-542fa32879be',
  [legacyPlanNames.TEAM_YR]: '877e0c27-be47-44fe-841b-f0d385eacd37',
  [legacyPlanNames.BUSINESS_MO]: '3b93eec3-7302-4e14-8b6b-7c62c399377f',
  [legacyPlanNames.BUSINESS_YR]: '5780cc0d-6b53-4784-9e48-cb5af5eb89b4',
};

// The Evaluation environment plans were copied over from the Prod DB, hence the
// duplicate plan IDs
const evaluationLegacyPlanIds = {
  [legacyPlanNames.STARTER_MO]: '71137001-598c-4a88-b399-15206e76c58f',
  [legacyPlanNames.STARTER_YR]: '0c343833-b3da-4b0c-8335-30f761810c76',
  [legacyPlanNames.PRO_MO]: 'f67ba21a-d560-4d95-af76-a38800cf1b20',
  [legacyPlanNames.PRO_YR]: 'b9d4e67c-b503-41cd-a657-915defcbfa74',
  [legacyPlanNames.TEAM_MO]: '600ab2a4-e78e-4639-bbb9-542fa32879be',
  [legacyPlanNames.TEAM_YR]: '877e0c27-be47-44fe-841b-f0d385eacd37',
  [legacyPlanNames.BUSINESS_MO]: '3b93eec3-7302-4e14-8b6b-7c62c399377f',
  [legacyPlanNames.BUSINESS_YR]: '5780cc0d-6b53-4784-9e48-cb5af5eb89b4',
};

const devLegacyPlanIds = {
  [legacyPlanNames.STARTER_MO]: '8d479a38-c71f-46db-9640-c1881f902816',
  [legacyPlanNames.STARTER_YR]: '6703e634-8950-4587-81cb-55e62f92a6d2',
  [legacyPlanNames.PRO_MO]: '084b1a7f-d89a-4613-8a29-451921023d80',
  [legacyPlanNames.PRO_YR]: '9ebfed3b-3900-442d-910c-2cdd76ed06d2',
  [legacyPlanNames.TEAM_MO]: 'c48c06ec-0865-4bc5-ac5d-c10e00b7b06f',
  [legacyPlanNames.TEAM_YR]: '0026f2d6-faae-4fcc-867c-08ba18a85767',
  [legacyPlanNames.BUSINESS_MO]: '6c82d411-8a75-40d8-86a2-5e0c68fa2717',
  [legacyPlanNames.BUSINESS_YR]: 'e1be6a18-d135-4052-ba7a-39bcbd143ee0',
};

function getEnvironmentLegacyPlanId({ plan }) {
  if (isProduction) {
    return productionLegacyPlanIds[plan];
  }
  if (isStaging) {
    return stagingLegacyPlanIds[plan];
  }
  if (isEvaluation) {
    return evaluationLegacyPlanIds[plan];
  }
  return devLegacyPlanIds[plan];
}

export function getLegacyPlanIds() {
  const legacyPlanIds = {};
  Object.values(legacyPlanNames).forEach((value) => {
    legacyPlanIds[value] = getEnvironmentLegacyPlanId({
      plan: value,
    });
  });

  return legacyPlanIds;
}

const legacyPlanFeaturesToExtract = [
  'storage',
  'projects',
  'collaborators',
  'members',
];

export function generateLegacyFeatures(plan) {
  return legacyPlanFeaturesToExtract.reduce((features, feature) => {
    switch (feature) {
      case 'storage': {
        features.push({ text: `${plan[feature]} GB storage` });
        return features;
      }

      case 'projects': {
        features.push({
          text: `${
            plan[feature] === Infinity ? 'Unlimited' : plan[feature]
          } projects`,
        });
        return features;
      }

      case 'collaborators': {
        features.push({
          text: `${
            plan[feature] === Infinity ? 'Unlimited' : plan[feature]
          } collaborators`,
        });
        return features;
      }

      case 'members': {
        // do not show team members feature when the plan only include one team member.
        if (plan.members > 1) {
          features.push({ text: `${plan[feature]} team members` });
        }
        return features;
      }

      default:
        return features;
    }
  }, []);
}

// order featureDisplayNames keys the way they should appear in the UI
const featureDisplayNames = {
  custom_branded_presentations: 'Custom-branded presentations',
  password_protected_shares: 'Password-protected shares',
  archival_storage: 'Project archiving',
  '4k_playback': '4K Ultra HD playback',
  private_projects: 'Private projects',
  team_only_comments: 'Team-only comments',
  share_link_expiration: 'Share link expiration',
  custom_branded_emails: 'Custom-branded emails',
};

function appendPluralSuffix(value) {
  return value === 1 ? '' : 's';
}

function formatValueForPlanFeature(value) {
  if (value === null) {
    return 'Unlimited';
  }

  return value;
}

function getDetailsForMinimumV5Plans(plan) {
  const details = [
    `Up to ${plan[USER_MAX]} user${appendPluralSuffix(plan[USER_MAX])}`,
    `${formatValueForPlanFeature(plan.team_limit)} team${appendPluralSuffix(
      plan.team_limit
    )}`,
    `${formatValueForPlanFeature(
      plan.project_limit
    )} project${appendPluralSuffix(plan.project_limit)}`,
    `${formatBytes(plan[STORAGE_LIMIT])} of active storage`,
  ];

  if (plan[ARCHIVED_STORAGE_LIMIT]) {
    details.push(
      `${formatBytes(plan[ARCHIVED_STORAGE_LIMIT])} of archival storage`
    );
  }

  return details;
}

function getFeaturesForMinimumV5Plans(plan, previousPlan) {
  return Object.keys(featureDisplayNames).reduce((features, featureKey) => {
    if (
      !previousPlan?.available_features?.[featureKey] &&
      plan.available_features?.[featureKey]
    ) {
      features.push(featureDisplayNames[featureKey]);
    }

    return features;
  }, []);
}

const freePlanWithUserMax = (plan, lineItems) => {
  if (plan.version < 8) {
    return {
      title: TEXT.freeTitle,
      details: getDetailsForMinimumV5Plans(plan),
      featureListHeader: 'Features:',
      features: [
        'Unlimited free reviewers',
        'Apps & integrations',
        'Version management',
        '1080p HD playback',
      ],
      selfServe: true,
      lineItems,
      ...plan,
    };
  }

  return {
    title: TEXT.freeTitle,
    details: [],
    featureListHeader: 'Includes:',
    features: [
      TEXT.users(plan[USER_MAX]),
      TEXT.storage(plan[STORAGE_LIMIT]),
      TEXT.projects(plan.project_limit),
      'Feedback & collaboration tools',
      'Camera to Cloud',
      'NLE integrations',
    ],
    selfServe: true,
    lineItems,
    ctaText: 'Subscribe to Free',
    ...plan,
  };
};

const adobeFreePlanWithUserMax = (plan, lineItems) => {
  if (plan.version < 8) {
    return {
      title: TEXT.freeTitle,
      details: getDetailsForMinimumV5Plans(plan),
      featureListHeader: 'Features:',
      features: [
        'Camera to Cloud',
        'Unlimited free shares',
        'Apps & integrations',
        'Version management',
      ],
      selfServe: true,
      lineItems,
      ...plan,
    };
  }

  return {
    title: TEXT.freeTitle,
    details: [],
    featureListHeader: 'Includes:',
    features: [
      TEXT.users(plan[USER_MAX]),
      TEXT.storage(plan[STORAGE_LIMIT]),
      TEXT.projects(plan.project_limit),
      'Feedback & collaboration tools',
      'Camera to Cloud',
      'NLE integrations',
    ],
    selfServe: true,
    lineItems,
    ctaText: 'Subscribe to Free',
    ...plan,
  };
};

const PRICING_VERSION_2 = 2;
const PRICING_VERSION_3 = 3;
const PRICING_VERSION_4 = 4;

function getBillingPeriod(plan) {
  return plan.period === BILL_PERIOD.MONTHLY ? 'month' : 'year';
}

const isV2Plan = (planVersion) => planVersion === PRICING_VERSION_2;
const isV3OrV4Plan = (planVersion) =>
  planVersion === PRICING_VERSION_3 || planVersion === PRICING_VERSION_4;

// Ideally we would use the active_features.archival_storage property of the plan to determine
// whether or not a plan supports project archiving. This change is a hotfix until we can
// refactor this module to dynamically populate feature data.
export const versionsThatSupportProjectArchiving = new Set([5, 6]);

function getFeaturesHeader(featureCount = 0, previousPlanTitle) {
  if (!previousPlanTitle) {
    return 'Features:';
  }

  const andText = featureCount > 0 ? ', and...' : '';

  return `Everything in ${previousPlanTitle}${andText}`;
}

function getProPlanCopy(plan, lineItems, previousPlan) {
  if (isV2Plan(plan.version)) {
    return {
      title: TEXT.proTitle,
      subtitle: 'You make videos solo.',
      features: [
        {
          text: `${formatBytes(plan[STORAGE_LIMIT])} storage`,
        },
        { text: 'Unlimited free presentations' },
        { text: 'Unlimited free reviewers' },
      ],
      selfServe: true,
      lineItems,
      ...plan,
    };
  }
  if (isV3OrV4Plan(plan.version)) {
    const userSeatLimitKey = plan.version === 3 ? MEMBER_LIMIT : USER_LIMIT;
    return {
      title: TEXT.proTitle,
      subtitle: 'You make videos solo.',
      features: [
        {
          text: `${plan[userSeatLimitKey]} seat${appendPluralSuffix(
            plan[userSeatLimitKey]
          )} included`,
          tooltipText:
            lineItems.member_limit?.cost &&
            `Additional seats are ${formatMoney(
              lineItems.member_limit.cost
            )}/${getBillingPeriod(plan)} (+ tax).`,
        },
        { text: 'Unlimited free reviewers' },
        { text: 'Unlimited free presentations' },
        {
          text: `${formatBytes(plan[STORAGE_LIMIT])} active storage`,
        },
        {
          text:
            plan[ARCHIVED_STORAGE_LIMIT] &&
            `${formatBytes(plan[ARCHIVED_STORAGE_LIMIT])} archival storage`,
        },
      ],
      selfServe: true,
      lineItems,
      ...plan,
    };
  }

  if (planVersionsFiveThroughSeven.has(plan.version)) {
    const features = getFeaturesForMinimumV5Plans(plan, previousPlan);

    return {
      title: TEXT.proTitle,
      details: getDetailsForMinimumV5Plans(plan),
      featureListHeader: getFeaturesHeader(
        features.length,
        previousPlan?.title
      ),
      features,
      selfServe: true,
      lineItems,
      ...plan,
    };
  }

  return {
    title: TEXT.proTitle,
    details: [],
    featureListHeader: `Everything in ${TEXT.freeTitle}, plus:`,
    features: [
      TEXT.users(plan[USER_MAX]),
      TEXT.storage(plan[STORAGE_LIMIT]),
      TEXT.projects(plan.project_limit),
      'Custom-branded presentations',
      'Custom-branded emails',
      'Passphrase-protected shares',
      'Share link expiration',
      'Share permissions',
    ],
    selfServe: true,
    lineItems,
    ctaText: 'Subscribe to Pro',
    ...plan,
  };
}

function getTeamPlanCopy(plan, lineItems, previousPlan) {
  if (isV2Plan(plan.version)) {
    return {
      title: TEXT.teamTitle,
      subtitle: 'You make videos with a team.',
      features: [
        {
          text: `${formatBytes(plan[STORAGE_LIMIT])} storage`,
        },
        { text: 'Custom-branded emails' },
        { text: 'Team-only comments' },
        { text: 'All features in Pro' },
      ],
      selfServe: true,
      lineItems,
      ...plan,
    };
  }
  if (isV3OrV4Plan(plan.version)) {
    const userSeatLimitKey = plan.version === 3 ? MEMBER_LIMIT : USER_LIMIT;
    return {
      title: TEXT.teamTitle,
      subtitle: 'You make videos with a team.',
      features: [
        {
          text: `${plan[userSeatLimitKey]} seat${appendPluralSuffix(
            plan[userSeatLimitKey]
          )} included`,
          tooltipText:
            lineItems.member_limit?.cost &&
            `Additional seats are ${formatMoney(
              lineItems.member_limit.cost
            )}/${getBillingPeriod(plan)} (+ tax) up to 25 seats.`,
        },
        { text: 'Custom-branded emails' },
        { text: 'Team-only comments' },
        {
          text: `${formatBytes(plan[STORAGE_LIMIT])} active storage`,
        },
        {
          text:
            plan[ARCHIVED_STORAGE_LIMIT] &&
            `${formatBytes(plan[ARCHIVED_STORAGE_LIMIT])} archival storage`,
        },
        { text: 'All features in Pro' },
      ],
      selfServe: true,
      lineItems,
      ...plan,
    };
  }

  if (planVersionsFiveThroughSeven.has(plan.version)) {
    const features = getFeaturesForMinimumV5Plans(plan, previousPlan);

    return {
      title: TEXT.teamTitle,
      details: getDetailsForMinimumV5Plans(plan),
      featureListHeader: getFeaturesHeader(
        features.length,
        previousPlan?.title
      ),
      features: getFeaturesForMinimumV5Plans(plan, previousPlan),
      selfServe: true,
      lineItems,
      ...plan,
    };
  }

  return {
    title: TEXT.teamTitle,
    details: [],
    featureListHeader: `Everything in ${TEXT.proTitle}, plus:`,
    features: [
      TEXT.users(plan[USER_MAX]),
      TEXT.storage(plan[STORAGE_LIMIT]),
      'Private projects',
      'Internal comments',
    ],
    selfServe: true,
    lineItems,
    ctaText: 'Subscribe to Team',
    ...plan,
  };
}

export function getEnterprisePlanCopy(plan) {
  if (isV2Plan(plan.version) || isV3OrV4Plan(plan.version)) {
    return {
      title: TEXT.enterpriseTitle,
      subtitle: 'Multiple teams in your organization make videos.',
      features: [
        { text: 'Multi-Team management' },
        { text: 'Custom branding by Team' },
        { text: 'Watermark ID' },
        { text: 'Reviewer Inbox' },
        { text: 'Asset lifecycle management' },
        { text: 'SAML-based SSO' },
      ],
      selfServe: false,
      enterprise: true,
    };
  }

  if (planVersionsFiveThroughSeven.has(plan.version)) {
    return {
      title: TEXT.enterpriseTitle,
      details: [],
      featureListHeader:
        'Build a custom package tailored to the way your organization works.',
      features: [
        'Multi-Team management',
        'Custom branding by Team',
        'Watermark ID',
      ],
      selfServe: false,
      enterprise: true,
    };
  }

  return {
    title: TEXT.enterpriseTitle,
    details: [],
    featureListHeader: `Everything in ${TEXT.teamTitle}, plus:`,
    features: [
      'Single Sign-on',
      'Multiple Team Workspaces',
      'Watermarking & DRM',
    ],
    selfServe: false,
    enterprise: true,
  };
}

export const defaultPlanVersion = 8;

const basePlansByTier = (
  plan,
  lineItems = {},
  previousPlan,
  { isAdobeBridgeOfferEligible } = {}
) => {
  // return undefined to not include the plan in the selector
  switch (plan.tier) {
    case PLAN_TIERS.FREE: {
      if (plan.version < 5) {
        return undefined;
      }

      return isAdobeBridgeOfferEligible
        ? adobeFreePlanWithUserMax(plan, lineItems)
        : freePlanWithUserMax(plan, lineItems);
    }
    case PLAN_TIERS.PRO:
      return getProPlanCopy(plan, lineItems, previousPlan);
    case PLAN_TIERS.TEAM:
      return getTeamPlanCopy(plan, lineItems, previousPlan);
    case PLAN_TIERS.ENTERPRISE:
      return getEnterprisePlanCopy(plan);
    default:
      return undefined;
  }
};

export default basePlansByTier;

export const testExports = {
  basePlansByTier,
};
