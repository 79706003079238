/* eslint-disable import/prefer-default-export */
export const normalizeV2ProjectData = (formData) => ({
  name: formData.projectName,
  private: formData.isPrivate,
  shared: formData.isProjectShared,

  // `ignore_archive` is the property on the backend that excludes a project
  // from a team's lifecycle policy. We assign it the inverse value of
  // `enableLifeCyclePolicy` here so that the values are consistent with the
  // naming semantics
  ignore_archive: !formData.enableLifeCyclePolicy,

  project_preferences: {
    collaborator_can_download: formData.canCollaboratorDownload,
    collaborator_can_invite: formData.canCollaboratorInvite,
    collaborator_can_share: formData.canCollaboratorShare,
    devices_enabled: formData.c2cConnectionsEnabled,
    notify_on_new_comment: formData.canEmailForNewCommentEveryone,
    notify_on_new_collaborator: formData.canEmailForNewPersonEveryone,
    notify_on_new_asset: formData.canEmailForNewVideoEveryone,
    notify_on_updated_label: formData.canEmailForUpdatedLabelEveryone,
    notify_slack: formData.hasSlackNotificationsEnabled,
  },
  user_preferences: {
    notify_on_new_comment: formData.canEmailForNewCommentMe,
    notify_on_new_collaborator: formData.canEmailForNewPersonMe,
    notify_on_new_asset: formData.canEmailForNewVideoMe,
    notify_on_updated_label: formData.canEmailForUpdatedLabelMe,
  },
});

/**
 * Gets the text for the privacy switch.
 * @param {boolean} isPrivate - Indicates whether the project is private or not.
 * @returns {Object} An object containing the header and subheader text for the privacy switch.
 */
export const getPrivacySwitchText = (isPrivate) => {
  const privacyHeaderText = isPrivate
    ? 'Team Members must be invited to view this project'
    : 'Anyone on your team can view this project';

  const privacySubHeaderText = isPrivate
    ? 'Admins or team managers may be able to access this project'
    : '';

  return { privacyHeaderText, privacySubHeaderText };
};
