import React from 'react';
import track from 'analytics';
import PropTypes from 'prop-types';
import { MenuButton } from '@frameio/vapor';
import CopyLinkIcon from '@frameio/components/src/svgs/icons/24/link.svg';
import PowerIcon from '@frameio/components/src/svgs/icons/24/power.svg';
import Settings from '@frameio/components/src/svgs/icons/24/cog.svg';
import { SMALL } from 'utils/mediaQueries';
import Media from 'react-media';

export default function ProjectPresentationsContextMenu({
  presentationId,
  primaryAssetId,
  isActive,
  presentationUrl,
  copyShortUrlToClipboard,
  legacyOpenPresentationEditor,
  patchToggleIsActive,
}) {
  const trackMenuItemSelection = (item) =>
    track('project-links-page-context-menu-item-selected', {
      item_selected: item,
      type: 'presentations',
    });

  return (
    <React.Fragment>
      <MenuButton
        data-test-id="edit-presentation-settings"
        onSelect={() => {
          legacyOpenPresentationEditor(primaryAssetId);
          trackMenuItemSelection('settings');
        }}
        iconBefore={<Settings width={16} height={16} />}
      >
        Settings
      </MenuButton>
      <MenuButton
        data-test-id="copy-presentation-url"
        onSelect={() => {
          copyShortUrlToClipboard(presentationUrl);
          trackMenuItemSelection('copy_link');
        }}
        iconBefore={<CopyLinkIcon width={16} height={16} />}
      >
        Copy link
      </MenuButton>
      <Media query={SMALL}>
        {(isSmall) =>
          isSmall ? (
            <MenuButton
              onSelect={() => patchToggleIsActive(presentationId, !isActive)}
              data-test-id="presentation-active-toggle"
              icon={<PowerIcon width={16} height={16} />}
            >
              {isActive ? 'Deactivate' : 'Activate'} presentation
            </MenuButton>
          ) : null
        }
      </Media>
    </React.Fragment>
  );
}

ProjectPresentationsContextMenu.propTypes = {
  presentationId: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  presentationUrl: PropTypes.string.isRequired,
  primaryAssetId: PropTypes.string.isRequired,
  copyShortUrlToClipboard: PropTypes.func.isRequired,
  legacyOpenPresentationEditor: PropTypes.func.isRequired,
  patchToggleIsActive: PropTypes.func.isRequired,
};

ProjectPresentationsContextMenu.defaultProps = {
  presentationId: undefined,
};
