import React from 'react';
import Button from '@frameio/components/src/styled-components/Button';
import { PropTypes } from 'prop-types';
import { rgba } from 'polished';
import styled from 'styled-components';
import Asset from './Asset';
import { ROW_HEIGHT } from './Version';

const ASSET_PREVIEW_WIDTH = 320;

// icon buttons are 32px, 24 is used here to target center of icon + padding
const DRAG_HANDLE_WIDTH = 24;

// The <Button /> is set to `display: none` in the AssetPreview since the element's
// dimensions change slightly on preview. `display: none` prevents text and other
// content inside the asset preview from wrapping
const AssetPreview = styled(Asset)`
  background-color: ${(p) => p.theme.color.white};
  border-radius: ${(p) => p.theme.radius.medium};
  border: 1px solid ${(p) => p.theme.color.accentGrayLight};
  box-shadow: 0px 4px 8px 0px
    ${({ theme }) => rgba(theme.color.coolBlack, 0.35)};
  height: ${ROW_HEIGHT}px;
  left: 0;
  cursor: grab;
  width: ${ASSET_PREVIEW_WIDTH}px;
  padding-left: ${(p) => p.theme.spacing.tiny};
  position: fixed;
  top: 0;

  ${Button} {
    display: none;
  }
`;

export default function AssetDragPreview({ currentOffset, item }) {
  // By default, the drag preview will be rendered with
  // the cursor at the upper left hand side of the element.
  // Since the element has a fixed width and height, we can
  // use those values below to offset the position of the
  // element to place the element under the cursor at the
  // right hand side and in the middle of the element,
  // adjascent to the drag handle.
  const mouseX = currentOffset.x - (ASSET_PREVIEW_WIDTH - DRAG_HANDLE_WIDTH);
  const mouseY = currentOffset.y - ROW_HEIGHT / 2;

  const style = {
    pointerEvents: 'none',
    transform: `translate(${mouseX}px, ${mouseY}px)`,
  };

  return <AssetPreview style={style} asset={item.asset} />;
}

AssetDragPreview.defaultProps = {
  currentOffset: null,
  item: null,
};

AssetDragPreview.propTypes = {
  currentOffset: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
  item: PropTypes.shape({
    asset: PropTypes.object,
  }).isRequired,
};

export const testExports = {
  AssetDragPreview,
  AssetPreview,
  ASSET_PREVIEW_WIDTH,
  DRAG_HANDLE_WIDTH,
  ROW_HEIGHT,
};
