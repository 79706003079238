import { partial } from 'lodash';

const { ceil, max, min, PI, round } = Math;
const PIXEL_RATIO = window ? window.devicePixelRatio : 1;
const DOT_RADIUS = 1;
const GRID_DENSITY = 40;
const GRID_COLOR = '#D9DFEA';

/**
 *
 * @param {CanvasRenderingContext2D} canvasContext the canvas rendering context
 * @param {number} width the width of the drawing space
 * @param {number} height the height of the drawing space
 * @param {number} x the x coord to draw at
 * @param {number} y the y coord to draw at
 * @param {number} size the radius of the arc
 */
const drawGridMarker = (canvasContext, width, height, radius, x, y) => {
  let targetX = x;
  let targetY = y;

  if (x === 0) {
    targetX += radius;
  } else if (x + radius >= width) {
    targetX -= radius;
  }

  if (y === 0) {
    targetY += radius;
  } else if (y + radius >= height) {
    targetY -= radius;
  }

  canvasContext.moveTo(targetX, targetY);
  canvasContext.beginPath();
  canvasContext.arc(
    targetX * PIXEL_RATIO,
    targetY * PIXEL_RATIO,
    radius * PIXEL_RATIO,
    0,
    PI * 2
  );
  canvasContext.fill();
  canvasContext.closePath();
};

/**
 * gridOptions
 * @param {HTMLCanvasElement} canvasEl the canvas element
 * @param {CanvasRenderingContext2D} canvasContext the canvas rendering context
 * @param {BoundingClientRect} previewImageBounds the boundingClientRect() of the asset to shape the grid around
 * @param {string} [color] optionally set the color of the grid dots
 * @param {number} [density] optionally set the base grid dot density
 * @param {number} [radius] optionally set the radius of each grid dot
 * @param {number} width the width of the drawing area
 * @param {number} height the height of the drawing area
 */

/**
 *
 * @param {*} gridOptions
 */
const drawGrid = (gridOptions) => {
  const {
    width,
    height,
    canvasEl,
    canvasContext,
    previewImageBounds,
    color = GRID_COLOR,
    density = GRID_DENSITY,
    radius = DOT_RADIUS,
  } = gridOptions;
  // Clear the grid before redrawing.
  // Clearing for the window dimensions since the new dimensions in the DOM may be smaller than the prior dimensions and won't fully clear all old drawing data

  if (!canvasEl) {
    return;
  }

  // Get the current elements and store their bounds
  const canvasBounds = canvasEl.getBoundingClientRect();

  const clearSize = window
    ? [window.innerWidth, window.innerHeight]
    : [width, height];
  canvasContext.clearRect(0, 0, clearSize[0], clearSize[1]);

  if (height < 1 || previewImageBounds.height < 1) {
    return;
  }

  // Set the canvas element's width and height.
  canvasEl.width = width * PIXEL_RATIO;
  canvasEl.height = height * PIXEL_RATIO;

  // Calculate the four corners of the asset
  const x1 = previewImageBounds.left - canvasBounds.left;
  const x2 = x1 + previewImageBounds.width;
  const y1 = previewImageBounds.top - canvasBounds.top;
  const y2 = y1 + previewImageBounds.height;

  const widthGreaterThanHeight =
    previewImageBounds.width > previewImageBounds.height;

  const assetRatio = !widthGreaterThanHeight
    ? previewImageBounds.height / previewImageBounds.width
    : previewImageBounds.width / previewImageBounds.height;

  const gridDensityX = widthGreaterThanHeight
    ? round(density * assetRatio)
    : density;
  const gridDensityY = widthGreaterThanHeight
    ? density
    : round(density * assetRatio);

  const targetRadius = ceil(min(radius / assetRatio, radius));

  // Calculate the space needed between each grid dot based on the grid density and the size of the asset
  const gridGapX = max(previewImageBounds.width / gridDensityX, targetRadius);
  const gridGapY = max(previewImageBounds.height / gridDensityY, targetRadius);

  canvasContext.fillStyle = color;

  // Each loop draws outward from each corner of the asset, as well as the top and bottom edge.
  // Drawing in multiple passes this way means the grid always aligns with the edges of the asset.
  // Imperfections on the edges of the drawing area will not be easily perceptible.
  // If instead drawing the whole grid in one pass from the edges of the canvas bounding box, it'll be immediately obvious where it doesn't line up with the asset

  // Create partial to reduce code repetition
  const drawGridMarkerPartial = partial(
    drawGridMarker,
    canvasContext,
    canvasBounds.width,
    canvasBounds.height,
    targetRadius
  );

  /** Left side iterations */
  if (x1 > 0) {
    // Draw from the upper left corner of the asset to the upper left corner of the canvas
    for (let i = x1; i >= 0; i -= gridGapX) {
      for (let j = y1; j >= 0; j -= gridGapY) {
        drawGridMarkerPartial(i, j);
      }
    }

    // Draw from the upper left corner of the asset to the bottom left corner of the asset
    for (let i = x1; i >= 0; i -= gridGapX) {
      for (let j = y1 + gridGapY; j < y2; j += gridGapY) {
        drawGridMarkerPartial(i, j);
      }
    }

    // Draw from the bottom left corner of the asset to the bottom left corner of the canvas
    for (let i = x1; i >= 0; i -= gridGapX) {
      for (let j = y2; j <= canvasBounds.height; j += gridGapY) {
        drawGridMarkerPartial(i, j);
      }
    }
  }

  /** Right side iterations */

  if (x2 < canvasBounds.width) {
    // Draw from the upper right corner of the asset to the upper right corner of the canvas
    for (let i = x2; i <= canvasBounds.width; i += gridGapX) {
      for (let j = y1; j >= 0; j -= gridGapY) {
        drawGridMarkerPartial(i, j);
      }
    }

    // Draw from the upper right corner of the asset to the bottom right corner of the asset
    for (let i = x2; i <= canvasBounds.width; i += gridGapX) {
      for (let j = y1 + gridGapY; j < y2; j += gridGapY) {
        drawGridMarkerPartial(i, j);
      }
    }

    // Draw from the bottom right corner of the asset to the bottom right corner of the canvas
    for (let i = x2; i <= canvasBounds.width; i += gridGapX) {
      for (let j = y2; j <= canvasBounds.height; j += gridGapY) {
        drawGridMarkerPartial(i, j);
      }
    }
  }

  /** Top iterations */

  if (y1 > 0) {
    // Draw from the top edge of the asset to the top edge of the canvas
    for (let i = x1; i <= x2 + targetRadius; i += gridGapX) {
      for (let j = y1; j >= 0; j -= gridGapY) {
        drawGridMarkerPartial(i, j);
      }
    }
  }

  /** Bottom iterations */
  if (y2 < canvasBounds.height) {
    // Draw from the bottom edge of the asset to the bottom edge of the canvas
    for (let i = x1; i <= x2 + targetRadius; i += gridGapX) {
      for (let j = y2; j <= canvasBounds.height; j += gridGapY) {
        drawGridMarkerPartial(i, j);
      }
    }
  }
};

export default drawGrid;
