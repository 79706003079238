import { connect } from 'react-redux';
import MoveAssetsToFolder from './MoveAssetsToFolder';
import { promptMoveToFolder, fetchTree } from './actions';
import { moveAssetMenuItemSelector } from './selectors';

function mapStateToProps(state, props) {
  const { folderId } = props;
  const { folder, projectId } =
    moveAssetMenuItemSelector(state, {
      assetId: folderId,
    }) || {};

  return {
    projectId,
    folderParentId: folder && folder.parent_id,
  };
}

const mapDispatchToProps = {
  promptMoveToFolder,
  fetchTree,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveAssetsToFolder);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
