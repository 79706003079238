import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AddTeamMembers from 'components/AddTeamMembers';
import Flex from 'styled-flex-component';
import Button from '@frameio/components/src/styled-components/Button';
import { trackButtonClick } from 'analytics';
import ProgressDots from '../ProgressDots';
import { SETUP_ACCOUNT_FLOW } from '../SetupAccountFlow';

const ButtonsContainer = styled(Flex).attrs(() => ({
  justifyBetween: true,
}))`
  margin-top: ${(p) => p.theme.spacing.medium};

  ${Button} + ${Button} {
    margin-left:  ${(p) => p.theme.spacing.tiny};
  }
`;

const InviteButton = styled(Button)`
  height: ${(p) => p.theme.spacing.units(4)};
  padding: 0 12px; // necessary to match styling of Dialog buttons used in other steps of the SetupAccountFlow
`;

const SkipButton = styled(Button).attrs(() => ({ text: true }))``;

const AddMembers = ({ currentStep, onContinue, teamId, trackingPage }) => (
  <AddTeamMembers
    showCloseButton={false}
    teamId={teamId}
    trackingPage={trackingPage}
  >
    {({ handleSubmit, isDisabled }) => (
      <ButtonsContainer>
        <ProgressDots currentStep={currentStep} />
        <div>
          <SkipButton
            type="submit"
            onClick={() => {
              trackButtonClick('skip', SETUP_ACCOUNT_FLOW, trackingPage);
              onContinue();
            }}
          >
            Skip
          </SkipButton>
          <InviteButton
            primary
            large
            onClick={handleSubmit}
            disabled={isDisabled}
            type="submit"
          >
            Invite
          </InviteButton>
        </div>
      </ButtonsContainer>
    )}
  </AddTeamMembers>
);

export default AddMembers;

AddMembers.propTypes = {
  currentStep: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
  trackingPage: PropTypes.string.isRequired,
};
