import {
  currentAccountSelector,
  isAccountAdobeBridgeOfferEligible,
} from 'selectors/accounts';
import { planEntityForAccountIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { PLAN_TIERS } from 'components/SelectPlanFlow/PlanDisplay';
import { limitTypes } from 'selectors/limits';

const PRICING_V5 = 5;
const PRICING_V6 = 6;

const planCopy = {
  [PRICING_V5]: {
    [PLAN_TIERS.FREE]: {
      name: PLAN_TIERS.FREE,
      upgradePlanFeatures: [
        'Unlimited projects',
        '1–3 users',
        'Extra storage',
        'Custom branding',
      ],
      upgradePlanName: 'Pro',
      copy: {
        [limitTypes.USERS]: '2 user',
        [limitTypes.STORAGE]: '2 GB of active storage',
        [limitTypes.PROJECTS]: '3 project',
      },
    },
    [PLAN_TIERS.PRO]: {
      name: PLAN_TIERS.PRO,
      upgradePlanFeatures: [
        '3–20 users',
        'Extra storage',
        'Private projects',
        'Team-only comments',
      ],
      upgradePlanName: 'Team',
      copy: {
        [limitTypes.USERS]: '3 user',
        [limitTypes.STORAGE]: '250 GB of active storage',
        [limitTypes.ARCHIVAL_STORAGE]: '500 GB of archival storage',
      },
    },
    [PLAN_TIERS.TEAM]: {
      name: PLAN_TIERS.TEAM,
      upgradePlanFeatures: [
        '20+ users',
        'Custom storage packages',
        'Session-based watermarking',
        'Priority in-app support',
      ],
      upgradePlanName: 'Enterprise',
      copy: {
        [limitTypes.USERS]: '20 user',
        [limitTypes.STORAGE]: '500 GB of active storage',
        [limitTypes.ARCHIVAL_STORAGE]: '1 TB of archival storage',
      },
    },
  },
  [PRICING_V6]: {
    [PLAN_TIERS.FREE]: {
      name: PLAN_TIERS.FREE,
      upgradePlanFeatures: [
        'Unlimited projects',
        'Up to 10 users',
        'Extra storage',
        'Custom branded presentations',
      ],
      upgradePlanName: 'Pro',
      copy: {
        [limitTypes.USERS]: '2 user',
        [limitTypes.STORAGE]: '2 GB of active storage',
        [limitTypes.PROJECTS]: '2 project',
      },
    },
    [PLAN_TIERS.PRO]: {
      name: PLAN_TIERS.PRO,
      upgradePlanFeatures: [
        'Up to 20 users',
        'Extra storage',
        'Private projects',
        'Team-only comments',
      ],
      upgradePlanName: 'Team',
      copy: {
        [limitTypes.USERS]: '10 user',
        [limitTypes.STORAGE]: '250 GB of active storage',
        [limitTypes.ARCHIVAL_STORAGE]: '500 GB of archival storage',
      },
    },
    [PLAN_TIERS.TEAM]: {
      name: PLAN_TIERS.TEAM,
      upgradePlanFeatures: [
        'Custom users & storage',
        'Multi-Team management',
        'Custom branding by Team',
        'Watermark ID',
      ],
      upgradePlanName: 'Enterprise',
      copy: {
        [limitTypes.USERS]: '20 user',
        [limitTypes.STORAGE]: '500 GB of active storage',
        [limitTypes.ARCHIVAL_STORAGE]: '1 TB of archival storage',
      },
    },
  },
};

const adobePlanCopy = {
  [PLAN_TIERS.FREE]: {
    name: PLAN_TIERS.FREE,
    upgradePlanFeatures: [
      'Up to 10 users',
      '250 GB active storage',
      '500 GB archival storage',
      'Unlimited projects',
    ],
    upgradePlanName: 'Pro',
    copy: {
      [limitTypes.USERS]: '2 user',
      [limitTypes.STORAGE]: '100 GB storage',
      [limitTypes.PROJECTS]: '5 project',
    },
  },
  [PLAN_TIERS.PRO]: {
    name: PLAN_TIERS.PRO,
    upgradePlanFeatures: [
      'Up to 15 users',
      '1 team',
      'Unlimited projects',
      '500 GB active storage',
      '1 TB archival storage',
    ],
    upgradePlanName: 'Team',
    copy: {
      [limitTypes.USERS]: '10 user',
      [limitTypes.STORAGE]: '250 GB of active storage',
      [limitTypes.ARCHIVAL_STORAGE]: '500 GB of archival storage',
    },
  },
  [PLAN_TIERS.TEAM]: {
    name: PLAN_TIERS.TEAM,
    upgradePlanFeatures: [
      'Custom users & storage',
      'Multi-Team management',
      'Custom branding by Team',
      'Watermark ID',
    ],
    upgradePlanName: 'Enterprise',
    copy: {
      [limitTypes.USERS]: '15 user',
      [limitTypes.STORAGE]: '500 GB of active storage',
      [limitTypes.ARCHIVAL_STORAGE]: '1 TB of archival storage',
    },
  },
};

function hardBlockCopySelector(state) {
  const { id: accountId } = currentAccountSelector(state);
  const { tier: currentTier, version } = planEntityForAccountIdSelector(state, {
    accountId,
  });
  const isAdobeBridgeOfferEligible = isAccountAdobeBridgeOfferEligible(state, {
    accountId,
  });

  if (isAdobeBridgeOfferEligible) {
    return adobePlanCopy[currentTier];
  }

  if (version === PRICING_V5) {
    return planCopy[PRICING_V5][currentTier];
  }

  // pricing version 6 or greater
  return planCopy[PRICING_V6][currentTier];
}

export const testExports = { planCopy, PRICING_V5, PRICING_V6 };

export default hardBlockCopySelector;
