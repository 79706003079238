const userSearchSelector = (state) => state.userSearch.userSearch;
const teamSearchSelector = (state) => state.userSearch.teamSearch;
const pendingReviewerSearchSelector = (state) =>
  state.userSearch.pendingReviewerSearch;

export const userSearchResultIdsSelector = (state) =>
  userSearchSelector(state).ids;

export const teamSearchResultIdsSelector = (state) =>
  teamSearchSelector(state).ids;

export const groupSearchResultsIdsSelector = (state) =>
  state.userSearch.groupSearch.ids;

export const pendingReviewerSearchResultIdsSelector = (state) =>
  pendingReviewerSearchSelector(state).ids;
