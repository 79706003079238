import { connect } from 'react-redux';
import { errorMessageSelector } from 'components/PaymentFlow/selectors';
import CreditCardFormElements from './CreditCardFormElements';
import { saveAddress } from './actions';

function mapStateToProps(state) {
  const submissionError = errorMessageSelector(state);

  return {
    submissionError,
  };
}

const mapDispatchToProps = {
  saveAddress,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditCardFormElements);
