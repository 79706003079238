import { connect } from 'react-redux';
import { getAssetSize } from '@frameio/core/src/assets/actions';
import {
  accountIdForAssetIdSelector,
  creatorEntityForAssetIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import { permittedActionsForAccountSelector } from 'selectors/permissions';
import {
  assetEntitySelector,
  coverAssetEntitySelector,
} from '@frameio/core/src/assets/selectors';
import { isCalculatingQuicklookFolderSizeSelector } from 'pages/ProjectContainer/selectors';
import { close } from './actions';
import quicklookSelector, { quicklookAssetIdSelector } from './selectors';
import QuicklookModal from './QuicklookModal';

function mapStateToProps(state) {
  const assetId = quicklookAssetIdSelector(state);
  const accountId = accountIdForAssetIdSelector(state, { assetId }) || {};
  const accountPermissions = permittedActionsForAccountSelector(state, {
    accountId,
  });

  return {
    ...quicklookSelector(state),
    asset:
      coverAssetEntitySelector(state, { assetId }) ||
      assetEntitySelector(state, { assetId }),
    creator: creatorEntityForAssetIdSelector(state, { assetId }),
    fwmFallbackPreference: accountPermissions.fwm_fallback_preference,
    isCalculatingFolderSize: isCalculatingQuicklookFolderSizeSelector(state),
  };
}

const mapDispatchToProps = {
  getAssetSize,
  onClose: close,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuicklookModal);

export const testExports = {
  mapStateToProps,
};
