import { generateActionTypes } from 'shared/actions/helpers';
import { createAction } from 'redux-actions';

const actions = ['GET_ALL', 'REVOKE'];

export const OAUTH_APPS_CONTAINER = generateActionTypes(
  'OAUTH_APPS_CONTAINER',
  actions
);

export const getConnectedOAuthApps = createAction(
  OAUTH_APPS_CONTAINER.GET_ALL,
  () => ({})
);

export const revokeConnectedOAuthApp = createAction(
  OAUTH_APPS_CONTAINER.REVOKE,
  (oauthAppId) => ({ oauthAppId })
);
