import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';
import { errorAndRedirect } from 'actions/shared';

export const PLAYER_CONTAINER = generateActionTypes('PLAYER_CONTAINER', [
  'ASSET_NOT_FOUND',
  'COPY_COMMENTS',
  'DOWNLOAD_FRAME_GRAB',
  'GET_PARENT_ASSET',
  'IS_FETCHING',
  'IS_FETCHING_PARENT_ASSET',
  'NEWEST_COMMENT_CREATED_ID',
  'PRELOAD_MEDIA_TIME',
  'REORDER_VERSION_ASSET',
  'SET_BACK_URL',
  'SAVE_DEFAULT_PLAYBACK_RATE',
  'SET_SHOW_ALL_ANNOTATIONS',
  'SET_SHOW_COMMENTS',
  'SET_RELATIONAL_DATA',
]);

const assetUnavailableMessage =
  'Sorry, this asset is no longer available. Please click on the button below to return to your projects.';

export function fetchPlayerContainerAssets(assetId, versionStackId) {
  return {
    type: PLAYER_CONTAINER.IS_FETCHING,
    payload: {
      assetId,
      versionStackId,
    },
  };
}

export function fetchPlayerContainerAssetsWithoutTouchingData(assetId) {
  return {
    type: PLAYER_CONTAINER.IS_FETCHING_PARENT_ASSET,
    payload: {
      assetId,
    },
  };
}

export function downloadFrameGrab(assetId, frame) {
  return {
    type: PLAYER_CONTAINER.DOWNLOAD_FRAME_GRAB,
    payload: {
      assetId,
      frame,
    },
  };
}

export function copyComments(copyFileId) {
  return {
    type: PLAYER_CONTAINER.COPY_COMMENTS,
    payload: { copyFileId },
  };
}

export function setNewestCommentCreatedId(commentId) {
  return {
    type: PLAYER_CONTAINER.NEWEST_COMMENT_CREATED_ID,
    payload: { commentId },
  };
}

export function setInitialMediaTime(time) {
  return {
    type: PLAYER_CONTAINER.PRELOAD_MEDIA_TIME,
    payload: {
      time,
    },
  };
}

export function setRelationalData({ folderId, projectId, teamId, accountId }) {
  return {
    type: PLAYER_CONTAINER.SET_RELATIONAL_DATA,
    payload: {
      folderId,
      projectId,
      teamId,
      accountId,
    },
  };
}

export function showAssetNotFoundError() {
  return errorAndRedirect('Asset Unavailable', assetUnavailableMessage);
}

export const onAssetNotFound = createAction(
  PLAYER_CONTAINER.ASSET_NOT_FOUND,
  (assetId) => ({ assetId })
);

export const reorderVersionStackInPlayer = createAction(
  PLAYER_CONTAINER.REORDER_VERSION_ASSET,
  (childAssetId, prevAssetId, nextAssetId) => ({
    childAssetId,
    prevAssetId,
    nextAssetId,
  })
);

export const setBackUrl = createAction(
  PLAYER_CONTAINER.SET_BACK_URL,
  (backUrl) => ({ backUrl })
);

export const saveDefaultPlaybackRate = createAction(
  PLAYER_CONTAINER.SAVE_DEFAULT_PLAYBACK_RATE,
  (defaultPlaybackRate) => ({ defaultPlaybackRate })
);

/**
 * @description - Updates redux store with user preference to show all annotations. (Syncs with LocalStorage)
 * @param {boolean} showAllAnnotations - User preference to show all annotations.
 * @returns {Object} - Action object.
 */
export const setShowAllAnnotations = createAction(
  PLAYER_CONTAINER.SET_SHOW_ALL_ANNOTATIONS,
  (showAllAnnotations) => ({ showAllAnnotations })
);

/**
 * @description - Updates redux store with user preference to show or hide comments. (Syncs with LocalStorage)
 * @param {boolean} showComments - User preference to show or hide comments.
 * @returns {Object} - Action object.
 */
export const setShowComments = createAction(
  PLAYER_CONTAINER.SET_SHOW_COMMENTS,
  (showComments) => ({ showComments })
);
