import { connect } from 'react-redux';
import { showAdBlockDisclaimer } from './actions';
import AdBlockDetector from './AdBlockDetector';

const mapDispatchToProps = {
  showAdBlockDisclaimer,
};

export default connect(
  null,
  mapDispatchToProps
)(AdBlockDetector);
