import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const COMMENT_COMPOSER = generateActionTypes('COMMENT_COMPOSER', [
  'SET_PRIVACY',
  'TOGGLE_PRIVACY',
  'UPGRADE_PLAN',
]);

export const setCommentPrivacy = createAction(
  COMMENT_COMPOSER.SET_PRIVACY,
  (isTeamOnlySelected) => ({ isTeamOnlySelected })
);

export const toggleCommentPrivacy = createAction(
  COMMENT_COMPOSER.TOGGLE_PRIVACY,
  (assetId, isTeamOnlySelected) => ({ assetId, isTeamOnlySelected })
);
