import { label as WORKFLOW_STATUSES } from '@frameio/core/src/assets/helpers/constants';
import { isProduction } from 'config';
import favicon from 'images/favicon.png';
import approvedIcon from 'images/favicon-approved.png';
import developmentIcon from 'images/favicon-development.png';
import inProgressIcon from 'images/favicon-in-progress.png';
import needsReviewIcon from 'images/favicon-needs-review.png';

const BASE_TITLE = 'Frame.io';

export const FAVICON_KEY = 'favicon';

/**
 * Returns a page title for use in the `<title>` tag.
 * @param {String} additionalTitle - The additional title to prepended
 */
export function pageTitle(additionalTitle) {
  return additionalTitle ? `${additionalTitle} - ${BASE_TITLE}` : BASE_TITLE;
}

/**
 * Returns a favicon file based on the workflow status (label)
 * @param {String} workflowStatus - The workflow status
 */
export function getWorkflowStatusFavicon(workflowStatus) {
  switch (workflowStatus) {
    case WORKFLOW_STATUSES.APPROVED:
      return approvedIcon;
    case WORKFLOW_STATUSES.IN_PROGRESS:
      return inProgressIcon;
    case WORKFLOW_STATUSES.NEEDS_REVIEW:
      return needsReviewIcon;
    default:
      return favicon;
  }
}

/**
 * Returns the appropriate favicon for the development site
 */
export function getFavicon() {
  if (isProduction) return favicon;
  return developmentIcon;
}
