import { JOIN_REQUEST as JOIN_REQUEST_CORE } from '@frameio/core/src/joinRequests/actions';
import { JOIN_REQUESTS_POPOVER } from './actions';

const INITIAL_STATE = {
  isOpen: false,
};

export default function joinRequestsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case JOIN_REQUESTS_POPOVER.TOGGLE_POPOVER:
      return {
        ...state,
        isOpen: action.payload.isOpen,
      };

    // always keep the join requests popover opened when join requests are being acted on (accept,
    // decline, undo, and mark as read)
    case JOIN_REQUESTS_POPOVER.ACCEPT:
    case JOIN_REQUESTS_POPOVER.DECLINE:
    case JOIN_REQUESTS_POPOVER.UNDO:
    case JOIN_REQUESTS_POPOVER.MARK_AS_READ:
    case JOIN_REQUEST_CORE.ACCEPT:
    case JOIN_REQUEST_CORE.ACCEPT.PENDING:
    case JOIN_REQUEST_CORE.ACCEPT.SUCCESS:
    case JOIN_REQUEST_CORE.ACCEPT.FAILURE:
    case JOIN_REQUEST_CORE.DECLINE:
    case JOIN_REQUEST_CORE.DECLINE.PENDING:
    case JOIN_REQUEST_CORE.DECLINE.SUCCESS:
    case JOIN_REQUEST_CORE.DECLINE.FAILURE:
    case JOIN_REQUEST_CORE.RESET:
    case JOIN_REQUEST_CORE.RESET.PENDING:
    case JOIN_REQUEST_CORE.RESET.SUCCESS:
    case JOIN_REQUEST_CORE.RESET.FAILURE:
    case JOIN_REQUEST_CORE.MARK_AS_READ_FOR_ACCOUNT:
    case JOIN_REQUEST_CORE.MARK_AS_READ_FOR_ACCOUNT.PENDING:
    case JOIN_REQUEST_CORE.MARK_AS_READ_FOR_ACCOUNT.SUCCESS:
    case JOIN_REQUEST_CORE.MARK_AS_READ_FOR_ACCOUNT.FAILURE:
      return {
        ...state,
        isOpen: true,
      };

    default:
      return state;
  }
}
