import axios from 'axios';
import queryString from 'query-string';
import { merge } from 'lodash';
import Cookies from 'js-cookie';

import config from 'config';
import { getOAuthCallbackUrl } from 'URLs';
import { v2Instance as apiInstance } from '@frameio/core/src/shared/services/api';

const COOKIE_KEY = 'vimeoAccessToken';

// NOTE: Axios shallow merges config objects, which can result in one instance
// accidentally inheriting deeply-nested config objects of another instance.
//
// See https://github.com/axios/axios/issues/385

const vimeoApiUrl = 'https://api.vimeo.com';

// TODO(marvin): Do this on module init when there's no longer a circular dep
// between this file and URLs.js.
function getRedirectUrl() {
  return getOAuthCallbackUrl('vimeo');
}

export const vimeoInstance = axios.create({
  baseURL: vimeoApiUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.vimeo.*+json;version=3.2',
  },
});

export function configureAuth(token) {
  vimeoInstance.defaults.headers = merge({}, vimeoInstance.defaults.headers, {
    common: {
      authorization: `Bearer ${token}`,
    },
  });
}

export function getAuthenticatedVimeoUser() {
  const fields = ['name', 'account', 'link', 'upload_quota'].join(',');

  return vimeoInstance
    .get(`/me?fields=${fields}`)
    .then((response) => response.data);
}

export function publishToVimeo(data) {
  return vimeoInstance
    .post('/me/videos', data)
    .then((response) => response.data);
}

export function addVideoTags(videoId, tags) {
  const params = tags.map((tag) => ({ tag }));
  return vimeoInstance.put(`/videos/${videoId}/tags`, params);
}

export function patchVideoMetadata(videoId, params) {
  return vimeoInstance.patch(`/videos/${videoId}/`, params);
}

export function setVimeoToken(token) {
  Cookies.set(COOKIE_KEY, token);
}

export function clearVimeoToken() {
  Cookies.remove(COOKIE_KEY);
}

export function getVimeoToken() {
  return Cookies.get(COOKIE_KEY);
}

export function generateVimeoAccessToken(code) {
  const params = {
    code,
    grant_type: 'authorization_code',
    redirect_uri: getRedirectUrl(),
  };

  return apiInstance
    .post('/vimeo/tokens', params)
    .then((response) => response.data);
}

export function getOAuthUrl(state = {}) {
  const params = queryString.stringify({
    state: JSON.stringify(state),
    scope: 'public private create edit upload video_files',
    response_type: 'code',
    client_id: config.vimeoClientId,
    redirect_uri: getRedirectUrl(),
  });

  return `${vimeoApiUrl}/oauth/authorize?${params}`;
}
