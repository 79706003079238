import { takeLatest, put, select, call } from 'redux-saga/effects';
import {
  updateSubscriptionLineItem as updateSubscriptionLineItemCoreSaga,
  getSubscriptionLineItemsForAccount,
  createSubscriptionLineItemForAccount,
  deleteSubscriptionLineItem,
} from '@frameio/core/src/subscriptionLineItems/sagas';
import { subscriptionLineItemForModifierAndSubscriptionSelector } from '@frameio/core/src/subscriptionLineItems/selectors';
import { putFetchActionAndWait } from '@frameio/core/src/shared/sagas/helpers';
import { getLineItemsForPlan } from '@frameio/core/src/planLineItems/actions';
import { firstPlanEntityForAccount } from '@frameio/core/src/plans/selectors';
import {
  planLineItemForModifierAndPlanSelector,
  planLineItemsForPlanSelector,
} from '@frameio/core/src/planLineItems/selectors';
import { currentAccountWithSubscriptionAndPlanSelector } from 'selectors/accounts';
import { showErrorToast } from 'actions/toasts/index';
import { isUpdatingSubscription, MANAGE_SUBSCRIPTION } from './actions';

/**
 * Calls endpoints to grab plan and subscription line item information
 * @param {String} accountId
 * @param {String} modifier which type of line item we need
 */
function* fetchPlanAndSubscriptionLineItems(accountId, modifier) {
  const plan = yield select(firstPlanEntityForAccount, { accountId });
  const planLineItem = yield select(planLineItemForModifierAndPlanSelector, {
    modifier,
    planId: plan.id,
  });
  if (!planLineItem) {
    // TODO(yuval): change getLineItemsForPlan saga in core to async saga and call directly
    yield putFetchActionAndWait(getLineItemsForPlan(plan.id), plan.id);
  }
  yield call(getSubscriptionLineItemsForAccount, accountId);
}

export function fetchPlanAndSubscriptionLineItemsForModifier(
  accountId,
  modifier
) {
  return fetchPlanAndSubscriptionLineItems(accountId, modifier);
}

/**
 * Creates, updates, or deletes a subscription line item.
 * @param {String} type line item modifier (ex. member_limit)
 * @param {Number} amount the new total amount of the line item
 */
// TODO(gabrielmessager): add tests
export function* updateSubscriptionLineItem(type, amount) {
  const { account, subscription, plan } = yield select(
    currentAccountWithSubscriptionAndPlanSelector
  );

  yield call(getSubscriptionLineItemsForAccount, account.id);

  const planId = plan.id;
  const planLineItems = yield select(planLineItemsForPlanSelector, { planId });
  const planLineItem = planLineItems.find((li) => li.modifier === type);
  if (!planLineItem) {
    // There isn't a matching plan line item of this type
    return yield put(
      showErrorToast({
        header: 'Something went wrong, please try again',
      })
    );
  }

  // The plan might come with its own number of seats/storage,
  // so we don't want to add those on as line items.
  const existingLineItemOfType = yield select(
    subscriptionLineItemForModifierAndSubscriptionSelector,
    { subscriptionId: subscription.id, modifier: type }
  );

  const planLimit = plan[type] || 0;
  const newLineItemAmount = amount - planLimit;
  const newLineItemQuantity = newLineItemAmount / planLineItem.increment;

  yield put(isUpdatingSubscription(true));
  if (existingLineItemOfType) {
    // If a subscription line item already exists - we either update or delete it
    if (newLineItemAmount === 0) {
      return yield call(deleteSubscriptionLineItem, existingLineItemOfType.id);
    }
    return yield call(
      updateSubscriptionLineItemCoreSaga,
      existingLineItemOfType.id,
      newLineItemQuantity
    );
  }

  return yield call(
    createSubscriptionLineItemForAccount,
    account.id,
    planLineItem.id,
    newLineItemQuantity
  );
}

export default [
  takeLatest(
    MANAGE_SUBSCRIPTION.UPDATE_LINE_ITEM,
    ({ payload: { type, amount } }) => updateSubscriptionLineItem(type, amount)
  ),
];
