import { connect } from 'react-redux';
import { isOnPaidCollaboratorsPlanSelector } from 'selectors/accounts';
import { batchAddTeamMembers } from 'components/AddTeamMembers/actions';
import AddTeamMembers from './AddTeamMembers';

export function mapStateToProps(state) {
  return {
    isPaidCollaboratorsPlan: isOnPaidCollaboratorsPlanSelector(state),
  };
}

export const mapDispatchToProps = {
  batchAddTeamMembers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTeamMembers);
