import { createSelector } from 'reselect';

export const setupAccountFlowSelector = (state) => state.setupAccountFlow;

export const isUploadingImageForMeSelector = createSelector(
  setupAccountFlowSelector,
  (setupAccountFlow) => setupAccountFlow.uploadImageForMe.isUploading
);

export const isUploadingTeamImageSelector = createSelector(
  setupAccountFlowSelector,
  (setupAccountFlow) => setupAccountFlow.uploadTeamImage.isUploading
);

export const isApplyingPromotionSelector = createSelector(
  setupAccountFlowSelector,
  (setupAccountFlow) => setupAccountFlow.isApplyingPromotion
);
