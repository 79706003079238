import React from 'react';
import { connect } from 'react-redux';
import { accountEntityForPresentationIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { currentAccountWithSubscriptionAndPlanSelector } from 'selectors/accounts';
import { upgradePlan } from 'components/PresentationEditor/actions';
import { allFeatures, getLowestTierForFeature } from 'planFeatures';
import UpgradeToUnlockOverlay from 'components/UpgradeToUnlockOverlay';
import basePlansByTier, {
  defaultPlanVersion,
} from 'components/SelectPlanFlow/PlanDisplay';
import { isAccountAdminSelector } from 'selectors/roles';
import { v8PricingOnlyAndMigrationFlowsUpdatesEnabled } from 'utils/featureFlags';

function mapStateToProps(state, { presentationId }) {
  const areV8PricingOnlyAndMigrationFlowsUpdatesEnabled = v8PricingOnlyAndMigrationFlowsUpdatesEnabled(
    state
  );
  const { id: accountId } =
    accountEntityForPresentationIdSelector(state, { presentationId }) || {};
  const {
    plan: { version },
  } = currentAccountWithSubscriptionAndPlanSelector(state);
  const tier = getLowestTierForFeature(allFeatures.reelPlayer);
  const versionForPlanSelector = areV8PricingOnlyAndMigrationFlowsUpdatesEnabled
    ? defaultPlanVersion
    : version;
  const plan = basePlansByTier({ version: versionForPlanSelector, tier });
  return {
    accountId,
    bodyCopy: (
      <React.Fragment>
        Upgrade to the <strong>{plan.title} plan</strong> to use the reel
        layout.
      </React.Fragment>
    ),
    isAccountAdmin: isAccountAdminSelector(state, { accountId }),
  };
}

function mapDispatchToProps(dispatch, { presentationId }) {
  return {
    onPlanUpgrade: () =>
      dispatch(
        upgradePlan(
          presentationId,
          allFeatures.reelPlayer,
          'reel player feature gate'
        )
      ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpgradeToUnlockOverlay);

export const testExports = { mapStateToProps, mapDispatchToProps };
