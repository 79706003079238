import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import { Modal } from '@frameio/components';
import { MARGIN as MODAL_MARGIN } from '@frameio/components/src/components/Modal';
import { SMALL } from 'utils/mediaQueries';

export default function ModalRoot({
  closeModal,
  isOpen,
  maxHeight,
  onClose,
  ...rest
}) {
  return isOpen ? (
    <Media query={SMALL}>
      {(isSmall) => (
        <Modal
          isOpen={isOpen}
          /*
            NOTE: A max-height can be set on the Modal component via the ‘maxHeight‘
            or ‘style‘ props, but defining a max-height as a ‘style‘ attribute
            _will override_ the SMALL breakpoint default below and the ‘maxHeight‘
            prop value, if it is also defined.

            ⚠️ Using the style attribute as the primary means of styling elements is
            generally not recommended (see https://reactjs.org/docs/dom-elements.html#style).

            Related TODO(WK-134): Refactor Modal component into a styled-component.
          */
          maxHeight={isSmall ? `calc(100vh - 2 * ${MODAL_MARGIN})` : maxHeight}
          onClose={() => {
            onClose();
            closeModal();
          }}
          {...rest}
        />
      )}
    </Media>
  ) : null;
}

ModalRoot.propTypes = {
  isOpen: PropTypes.bool,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  closeModal: PropTypes.func,
};

ModalRoot.defaultProps = {
  isOpen: false,
  maxHeight: undefined,
  onClose: () => {},
  closeModal: () => {},
};
