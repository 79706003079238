import { connect } from 'react-redux';
import { currentUserEntitySelector } from 'selectors/users';
import DevTools from './DevTools';

function mapStateToProps(state) {
  return {
    currentUser: currentUserEntitySelector(state),
  };
}

export default connect(mapStateToProps)(DevTools);
