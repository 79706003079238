import React from 'react';
import { CURRENT_STATUS_LABEL_COLORS } from 'components/Dialog/AdobeIntegrationConnectDialog/helper';
import {
  getStatusIndicatorVariant,
  MenuRadio,
  useActiveState,
} from '@frameio/vapor';
import { StyledStatusIndicator } from './AdobeIntegrationMenuItem';

const WorkfrontTaskStatusIndicator = ({
  checked,
  disabled,
  handleSelect,
  taskName,
  taskStatus,
}) => {
  const { bind, isActive } = useActiveState();

  return (
    <MenuRadio
      {...bind}
      disabled={disabled}
      iconBefore={({ isSelected }) => (
        <StyledStatusIndicator
          isSelected
          variant={getStatusIndicatorVariant({
            isChecked: checked,
            isHovering: isSelected,
            isActive,
          })}
          color={CURRENT_STATUS_LABEL_COLORS[taskStatus]}
        />
      )}
      checked={checked}
      onSelect={() => handleSelect(taskStatus)}
    >
      {taskName}
    </MenuRadio>
  );
};

export default WorkfrontTaskStatusIndicator;
