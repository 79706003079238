import CUSTOM_ACTION_FORM_DIALOG from './actions';
/**
 * Dialog state reducer.
 *
 * Note that this reducer is not merging in the previous state so that the next
 * dialog instance (which might not be the same dialog) does not inherit props
 * from the previous instance.
 *
 * @param   {Object} state - The current state.
 * @param   {Object} action - The action describing how to change the state.
 * @returns {Object} - The new state.
 */
export default function customActionFormDialogReducer(
  state = { isOpen: false },
  action
) {
  switch (action.type) {
    case CUSTOM_ACTION_FORM_DIALOG.OPEN:
      return { ...action.payload, isOpen: true };
    case CUSTOM_ACTION_FORM_DIALOG.CLOSE:
      return { isOpen: false };
    default:
      return state;
  }
}
