import React from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import PropTypes from 'prop-types';

import { buttonReset } from '@frameio/components/src/mixins';
import TruncateWithTooltip from '@frameio/components/src/styled-components/TruncateWithTooltip';

const resetStyles = css`
  ${buttonReset()};
  color: inherit;
  font: inherit;
  line-height: inherit;
  width: 100%;
  height: 100%;
`;

const ToggleButton = styled.button`
  ${resetStyles};
  text-align: left;
`;

const Input = styled.input`
  ${resetStyles};
  background: ${({ theme }) => rgba(theme.color.black, 0.2)};
`;

/**
 * Given an asset's name, select everything but the extension, or the whole
 * string if no extension exists.
 * @param {Event} FocusEvent - The event object.
 */
export function selectNameOnFocus({ target }) {
  const { value } = target;
  target.setSelectionRange(
    0,
    value.includes('.') ? value.lastIndexOf('.') : value.length
  );
}

export default class EditableText extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, newValue) {
    if (newValue && this.props.value !== newValue) {
      this.props.onChange(newValue);
    }
  }

  getSnapshotBeforeUpdate({ isEditing: wasEditing, disabled: wasDisabled }) {
    const { disabled, isEditing } = this.props;

    const didStopEditing = wasEditing && !isEditing;
    const didBecomeDisabled = !wasDisabled && disabled;

    if (this.inputRef.current && (didStopEditing || didBecomeDisabled)) {
      return this.inputRef.current.value;
    }
    return null;
  }

  render() {
    const { isEditing, toggleEditing, value, disabled, style } = this.props;

    const truncatedValue = (
      <TruncateWithTooltip style={style}>{value}</TruncateWithTooltip>
    );

    if (disabled) return truncatedValue;

    if (isEditing) {
      return (
        <Input
          autoFocus
          spellCheck={false}
          defaultValue={value}
          onBlur={() => {
            toggleEditing(false);
          }}
          onFocus={selectNameOnFocus}
          ref={this.inputRef}
          onKeyDown={(evt) => {
            evt.stopPropagation();

            if (evt.key === 'Enter') {
              toggleEditing(false);
            } else if (evt.key === 'Escape') {
              this.inputRef.current.value = value;
              toggleEditing(false);
            }
          }}
        />
      );
    }

    return (
      <ToggleButton onClick={() => toggleEditing(true)}>
        {truncatedValue}
      </ToggleButton>
    );
  }
}

EditableText.propTypes = {
  value: PropTypes.string,
  toggleEditing: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isEditing: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
};

EditableText.defaultProps = {
  value: '',
  disabled: true,
  isEditing: false,
  style: undefined,
};

export const testExports = {
  ToggleButton,
  Input,
};
