import { put, race, take, takeEvery } from 'redux-saga/effects';
import { AUTHED_USER } from '@frameio/core/src/users/actions';
import { showSuccessToast, showErrorToast } from 'actions/toasts';

export function* onSendConfirmationEmailResponse() {
  const { success } = yield race({
    success: take(AUTHED_USER.SEND_CONFIRMATION_EMAIL.SUCCESS),
    failure: take(AUTHED_USER.SEND_CONFIRMATION_EMAIL.FAILURE),
  });

  if (success) {
    return yield put(
      showSuccessToast({
        header: 'Email confirmation sent',
      })
    );
  }

  return yield put(
    showErrorToast({
      header: 'That didn’t work, please retry later',
    })
  );
}

export default [
  takeEvery(
    AUTHED_USER.SEND_CONFIRMATION_EMAIL.BASE,
    onSendConfirmationEmailResponse
  ),
];
