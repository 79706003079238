import { get, partial } from 'lodash';
import {
  getBestResolutionForScreen,
  Resolution,
  AUTO,
  ResolutionToAssetResolutionVal,
} from '@frameio/components/src/components/PlayerSubcomponents/PlayerInterface';

export const ASSET_STATUS = {
  CREATED: 'created',
  UPLOADED: 'uploaded',
  TRANSCODED: 'transcoded',
};

export const resolutionOptionToAssetTranscodeKeyName = /** @type {const} */ ({
  [Resolution.R360]: 'h264_360',
  [Resolution.R540]: 'h264_540',
  [Resolution.R720]: 'h264_720',
  [Resolution.R1080]: 'h264_1080_best',
  [Resolution.R2160]: 'h264_2160',
  [Resolution.RORIG]: 'original',
});

const playableTranscodeStates = ['success', 'warning'];

export const isResolutionAvailableInAsset = (asset, resolutionOption) => {
  const resolution = resolutionOptionToAssetTranscodeKeyName[resolutionOption];

  if (asset[resolution] === null) return false;

  if (resolutionOption === Resolution.RORIG) {
    return asset.original !== undefined;
  }

  return playableTranscodeStates.includes(
    get(asset, ['transcode_statuses', resolution, 'encode_status'])
  );
};

export const getAssetTranscodes = (asset) =>
  Object.values(Resolution)
    .filter(partial(isResolutionAvailableInAsset, asset))
    .filter((res) => res !== Resolution.RORIG)
    .map(
      (resolutionOption) => ResolutionToAssetResolutionVal[resolutionOption]
    );

export function getClosestMatchingAssetResolution(resolution, asset) {
  if (resolution === AUTO || resolution === Resolution.RORIG) {
    return resolution;
  }

  const numResolution = ResolutionToAssetResolutionVal[resolution];
  const resolutions = getAssetTranscodes(asset);
  let bestNumResolution = resolutions
    .sort((a, b) => b - a)
    .find((res) => res <= numResolution);

  // if no videos with lower res than what we need, just pick lowest it has.
  // ONLY happens for hella old videos.
  const lowestResolution = resolutions[resolutions.length - 1];
  bestNumResolution = bestNumResolution || lowestResolution;

  return Object.values(Resolution)
    .filter((res) => res !== Resolution.RORIG)
    .find(
      (resolutionOption) =>
        ResolutionToAssetResolutionVal[resolutionOption] === bestNumResolution
    );
}

/*
  We only want to have the original be the default resolution if it is the only available
  asset to playback. 
  
  This is because the bit rate could be high causing long load times and the off chance that
  the video metadata is wrong the video would not playback causing a degraded experience.
*/
export function getSuggestedResolution(
  asset,
  canPlaybackOriginalFile,
  canUse4KPlaybackFromProp
) {
  // The only time the original asset is most desirable is when no transcodes are available yet.
  if (canPlaybackOriginalFile && getAssetTranscodes(asset).length === 0) {
    return Resolution.RORIG;
  }

  let canUse4KPlayback = canUse4KPlaybackFromProp;

  /**
   * Twist! For assets that have either regular or forensic watermarks, we don't want the
   * suggested resolution to be 4k, because at the moment, the performance is too poor to try
   * to play them at 4k off the bat.
   */
  if (asset?.is_session_watermarked || asset?.is_forensically_watermarked) {
    canUse4KPlayback = false;
  }

  return getClosestMatchingAssetResolution(
    getBestResolutionForScreen(canUse4KPlayback),
    asset
  );
}

// Returns a list of available resolutions to playback
export const formatResolutionOptions = (asset) =>
  Object.values(Resolution)
    .filter(partial(isResolutionAvailableInAsset, asset))
    .map((res) => ({ resolution: res }));

export const formatAllResolutionOptions = (asset, canPlaybackOriginalFile) => {
  const ResolutionOptions = Object.values(Resolution)
    .filter((resolution) =>
      ['success', 'warning', 'started'].includes(
        get(asset, [
          'transcode_statuses',
          resolutionOptionToAssetTranscodeKeyName[resolution],
          'encode_status',
        ])
      )
    )
    .map((res) => ({
      resolution: res,
      isTranscoding: !['success', 'warning'].includes(
        get(asset, [
          'transcode_statuses',
          resolutionOptionToAssetTranscodeKeyName[res],
          'encode_status',
        ])
      ),
    }));

  if (canPlaybackOriginalFile) {
    /*
    Is transcoding set to false because the original never transcodes
    Also safe to put it at the top because it will always be the largest
    or tied for largest resolution.
    */
    ResolutionOptions.unshift({ resolution: 'Original', isTranscoding: false });
  }

  return ResolutionOptions;
};

export function getVideoEncodingNameForProgressiveSrc(asset = {}, src = '') {
  const transcodeKeys = /** @type {const} */ ([
    'h264_360',
    'h264_540',
    'h264_720',
    'h264_1080_best',
    'h264_2160',
    'h265_hdr_360',
    'h265_hdr_540',
    'h265_hdr_720',
    'h265_hdr_1080',
    'h265_hdr_2160',
    'original',
  ]);
  return (src && transcodeKeys.find((key) => asset[key] === src)) || undefined;
}
