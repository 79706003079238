import { filter } from 'lodash';
import { connect } from 'react-redux';
import {
  assetEntityInclDeletedSelector,
  childAssetEntitiesSortedByIndexSelector,
} from '@frameio/core/src/assets/selectors';
import { type as assetType } from '@frameio/core/src/assets/helpers/constants';
import { permittedActionsForAssetsSelector } from 'selectors/permissions';

import ManageVersionStack from './ManageVersionStack';

const mapStateToProps = (state, { assetId }) => {
  const asset = assetEntityInclDeletedSelector(state, { assetId });

  // the `assetId` property accepts the id of any type of asset.
  // we need to check if `assetId` belongs to a version stack. if
  // it doesn't return, then simply return the `assets` prop as an
  // array with the asset passed in as its sole element
  if (asset.type !== assetType.VERSION_STACK) {
    return {
      canManageVersions: false,
      assets: [asset],
    };
  }

  const { canManageVersions } = permittedActionsForAssetsSelector(state, {
    assetIds: [assetId],
    projectId: asset.project_id,
  });

  // otherwise, fetch the child assets of the version stack.
  // NOTE: This selection process should be refactored. Read more about this
  // at TODO(CORE-1040)
  const assets = childAssetEntitiesSortedByIndexSelector(state, { assetId });

  // There are edge cases in which the selector above will erroneously
  // return the children of a version stack. For instance, after an asset
  // has been removed from a version stack, that asset will correctly
  // update its `parent_id` to match the project's root directory, but the
  // selector will still include that asset on successive updates. To guard
  // against that, we apply a filter below that returns only assets whose
  // `parent_id` matches the id of the version stack.
  const assetIsVersionChild = (child) => child.parent_id === assetId;

  return {
    canManageVersions,
    assets: filter(assets, assetIsVersionChild),
  };
};

export default connect(mapStateToProps)(ManageVersionStack);

export const testExports = { mapStateToProps };
