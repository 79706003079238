import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

const CUSTOM_ACTION_FORM_DIALOG = generateActionTypes(
  'CUSTOM_ACTION_FORM_DIALOG',
  ['OPEN', 'RESPOND', 'CLOSE']
);

export const open = createAction(
  CUSTOM_ACTION_FORM_DIALOG.OPEN,
  (header, body, fields) => ({ header, body, fields })
);

export const respond = createAction(
  CUSTOM_ACTION_FORM_DIALOG.RESPOND,
  (response) => ({ response })
);

export const close = createAction(
  CUSTOM_ACTION_FORM_DIALOG.CLOSE,
  (response) => ({ response })
);

export default CUSTOM_ACTION_FORM_DIALOG;
