import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { noop } from 'lodash';
import { getPath, ACCOUNT_URL, ACCOUNT_SEARCH_URL } from 'URLs';

// eslint-disable-next-line react/prefer-stateless-function
class AccountLink extends React.Component {
  render() {
    const {
      accountId,
      children,
      match: { path },
      onClick,
      className,
    } = this.props;

    return (
      <NavLink
        to={getPath(
          path === ACCOUNT_SEARCH_URL ? ACCOUNT_SEARCH_URL : ACCOUNT_URL,
          { accountId }
        )}
        onClick={onClick}
        className={className}
      >
        {children}
      </NavLink>
    );
  }
}

export default withRouter(AccountLink);

AccountLink.defaultProps = {
  accountId: null,
  onClick: noop,
  className: undefined,
};

AccountLink.propTypes = {
  className: PropTypes.string,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  accountId: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};
