import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { debounce, get, has, isEmpty, isEqual } from 'lodash';
import * as Yup from 'yup';
import moment from 'moment';
import TextInput from '@frameio/components/src/styled-components/TextInput';
import { ENTER_DURATION } from '@frameio/components/src/components/Modal';
import CancelSVG from '@frameio/components/src/svgs/icons/16/cancel.svg';
import Button from '@frameio/components/src/styled-components/Button';
import SessionWatermarkOptionsTab from 'components/SharePanel/SessionWatermarkOptionsTab';
import { shareTabs as Tabs } from 'components/SharePanel/constants';
import WatermarkUpsellTab from 'components/SharePanel/WatermarkUpsellTab';
import Flex, { FlexItem } from 'styled-flex-component';
import { MEDIUM_UP } from 'utils/mediaQueries';
import { TabSelect } from '@frameio/components';
import { LINK_ACCESS_OPTIONS } from 'components/LinkAccess';
import addInviteOnlyAccessControl from 'utils/accessControl';
import { stickyLinkSettingsEnabled } from 'utils/featureFlags';
import ShadowContainer from '@frameio/components/src/styled-components/ShadowContainer';
import {
  WATERMARK_STRING_CHAR_LIMIT,
  WATERMARK_STRING_ALLOWED_CHARS_REGEX,
} from 'pages/WatermarkTemplateEditor/constants';
import { SORT_CONST } from 'components/SharePanel/DropDown/sortOptions';
import track from 'analytics';
import { shareTabs } from './Settings/Settings';
import ReviewLinkEditorShareForm from './ReviewLinkEditorShareForm';
import ReviewLinkEditorSettingsForm from './ReviewLinkEditorSettingsForm';
import SharePanelSettingsSwitchRow from '../SharePanel/SharePanelSettingsSwitchRow';
import StickyLinkFooter from '../SharePanel/StickyLinkFooter';

const StyledFlexForm = styled(Flex)`
  color: ${(p) => p.theme.color.gray};
  width: ${(p) => p.theme.spacing.units(42)};
  height: 100%;

  @media ${MEDIUM_UP} {
    max-width: ${(p) => p.theme.spacing.units(70)};
    width: 100%;
    height: 100%;
  }
`;

const ScrollContainer = styled.div`
  padding: 0 ${(p) => p.theme.spacing.units(3)}
    ${(p) => p.theme.spacing.units(3)} ${(p) => p.theme.spacing.units(3)};
  overflow-x: hidden;
`;

const StyledCloseButton = styled(Button)`
  height: ${(p) => p.theme.spacing.units(4)};
  padding: ${(p) => p.theme.spacing.units(1)};
  position: absolute;
  right: ${(p) => p.theme.spacing.units(2)};
  top: ${(p) => p.theme.spacing.units(2)};
  width: ${(p) => p.theme.spacing.units(4)};
`;

export const SettingsSubHeader = styled.div`
  font-size: ${(p) => p.theme.fontSize[2]};
  height: ${(p) => p.theme.spacing.medium};
  padding: ${(p) => p.theme.spacing.medium} 0 30px 0;
  font-weight: ${(p) => p.theme.fontWeight.bold};
  color: ${(p) => p.theme.color.coolBlack};
`;

export const StyledH2 = styled.h2`
  ${(p) => p.theme.fontStyle.heading};
  color: ${(p) => p.theme.color.coolBlack};
  margin-bottom: 0;
  line-height: 1.2;
`;

const TitleEntryContainer = styled.div`
  margin-top: ${(p) => p.theme.spacing.tiny};
  width: ${(p) => p.theme.spacing.units(66)};
  position: relative;
  right: ${(p) => p.theme.spacing.tiny};
`;

const TitleText = styled.div`
  ${(p) => p.theme.fontStyle.body};
  color: ${(p) => p.theme.color.graphiteGray};
  padding: ${(p) => `${p.theme.spacing.micro} ${p.theme.spacing.tiny}`};
  border-radius: ${(p) => p.theme.radius.default};
  border: 1px solid ${(p) => p.theme.color.white};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    border-color: ${(p) => p.theme.color.silver};
  }
`;

export const TitleTextInput = styled(TextInput)`
  border-radius: ${(p) => p.theme.radius.default};
`;

const fadeIn = () => keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledFlex = styled(Flex)`
  min-height: ${(p) => (p.minHeight === undefined ? 'auto' : p.minHeight)};
`;

const Section = styled(StyledFlex)`
  padding: ${(p) => p.theme.spacing.units(2)};
  /* Will Change is necessary otherwise the section will have a shift/pop on end of reveal */
  will-change: opacity;
  /* add an animation on reveal. as it shows on a delay. */
  animation: ${(props) => fadeIn(props)} 750ms ${(p) => p.theme.easing.primary};
  animation-delay: 0ms;
  animation-fill-mode: backwards;

  &:first-of-type {
    padding-bottom: 0;
  }

  &:last-of-type {
    /* the padding is set deeper in the component
    so that the StyledShadowContainer is flush to the modal edge for the content. */
    padding: ${(p) => p.theme.spacing.small} 0 0;
  }

  @media ${MEDIUM_UP} {
    padding: ${(p) => p.theme.spacing.medium};
  }

  .Settings__UserSearch {
    box-shadow: none;
  }
`;

const TabSelectWrapper = styled(Flex)`
  border-bottom: 1px solid ${(p) => p.theme.color.coldWhite};
  padding: 0 ${(p) => p.theme.spacing.units(3)};
  button {
    color: ${(p) => p.theme.color.graphiteGray};
    margin: 0 0 ${(p) => p.theme.spacing.tiny};
    padding-right: ${(p) => p.theme.spacing.medium};

    &.selected {
      color: ${(p) => p.theme.color.coolBlack};
      font-weight: ${(p) => p.theme.fontWeight.bold};
    }
  }
`;

class ReviewLinkEditor extends React.Component {
  constructor(props) {
    super(props);

    this.tabs = [
      { key: Tabs.sharing, text: 'Sharing' },
      { key: Tabs.settings, text: 'Settings' },
      {
        key: Tabs.watermark,
        text: `Watermark${props.watermarkUpsellVariant ? ' ✨' : ''}`,
      },
    ];

    this.state = {
      activeTab: props.defaultTab || Tabs.sharing,
      isSharing: !!props.shortUrl,
      showMeasuredContent: false,
      showCustomWatermarkSubmit: false,
    };

    this.buttonRef = React.createRef();

    this.showMeasuredContentTimeout = setTimeout(
      () => this.setState({ showMeasuredContent: true }),
      ENTER_DURATION
    );
  }

  componentWillUnmount() {
    clearTimeout(this.showMeasuredContentTimeout);
  }

  safelyPersistTitleChange = () => {
    const {
      setFieldValue,
      handleSubmit,
      values: { name, revertableName },
    } = this.props;

    if (this.hitEscape == null) {
      const newValue = revertableName.length ? revertableName : name;
      setFieldValue('editingName', false);
      setFieldValue('name', newValue);
      setFieldValue('revertableName', newValue);

      setTimeout(() => {
        handleSubmit();
        this.buttonRef.current.focus(); // don't break the esc key behavior with a submit
      }, 0);
    }
  };

  setDefaultSessionWatermarkTemplateId = (...args) =>
    this.props.setDefaultSessionWatermarkTemplateIdForTeam(
      this.props.teamId,
      ...args
    );

  onClickSetInviteOnly = (evt) => {
    const { setFieldValue } = this.props;

    evt.preventDefault();
    setFieldValue('allowDownloads', false);
    setFieldValue('currentMode', 'private');
  };

  onTabChange = (tabKey, event) => {
    event.preventDefault();
    this.setState({ activeTab: tabKey });
  };

  submitFormDebounced = debounce(this.props.submitForm, 200);

  onSaveSettingsToggle = (evt) => {
    track('review-link-share-modal-toggle-save-settings', {
      title: `share-modal-toggle-save-settings toggle ${evt.target.checked}`,
      page: 'review-link-settings',
      position: 'middle',
    });
    this.onChange(evt);
  };

  onSelectSortBy = (evt) => {
    track('review-link-share-modal-select-sort-by', {
      title: `share-modal-select-sort-by, value: ${evt.target.value}`,
      page: 'review-link-share-modal',
      position: 'middle',
    });
    this.onChange(evt);
  };

  onChange = (evt) => {
    const nextValue = has(evt.target, 'checked')
      ? evt.target.checked
      : evt.target.value;
    this.props.setFieldValue(evt.target.name, nextValue);
    this.submitFormDebounced();
  };

  onClickOpenSessionWatermarkTemplateEditor = (
    templateId = null,
    isShareOnlyTemplate = false
  ) => {
    const {
      openEditor,
      openSessionWatermarkTemplateEditor,
      reviewLink: { id: reviewLinkId },
      sessionWatermarkTemplates,
      updateReviewLinkSettings,
    } = this.props;
    const templateCount = Object.keys(sessionWatermarkTemplates).length;
    const initialValues = {
      name: `Untitled watermark template ${templateCount + 1}`,
      review_link_id: isShareOnlyTemplate ? reviewLinkId : null,
    };
    const onClose = (sessionWatermarkTemplateId) => {
      openEditor(reviewLinkId, Tabs.watermark);
      if (sessionWatermarkTemplateId) {
        updateReviewLinkSettings(reviewLinkId, {
          session_watermark_id: sessionWatermarkTemplateId,
        });
      }
    };

    openSessionWatermarkTemplateEditor(
      templateId,
      onClose,
      initialValues,
      'review-modal',
      isShareOnlyTemplate
    );
  };

  componentDidUpdate(prevProps) {
    const { reviewLink, setFieldValue } = this.props;

    if (
      prevProps.reviewLink.session_watermark_id !==
      reviewLink.session_watermark_id
    ) {
      setFieldValue('sessionWatermarkId', reviewLink.session_watermark_id);
    }
  }

  renderWatermarkTab = () => {
    const {
      canShareUnwatermarked,
      canShareWithoutForensicWatermark,
      canSeeWatermarkingSettings,
      defaultSessionWatermarkTemplateId,
      isAdminLevel,
      sessionWatermarkTemplates,
      watermarkUpsellVariant: variant,
      isForensicWatermarkingEnabled,
    } = this.props;

    if (variant) {
      return <WatermarkUpsellTab variant={variant} />;
    }

    return (
      <StyledFlex full column minHeight={0}>
        <SessionWatermarkOptionsTab
          canShareUnwatermarked={canShareUnwatermarked}
          canShareWithoutForensicWatermark={canShareWithoutForensicWatermark}
          canSeeWatermarkingSettings={canSeeWatermarkingSettings}
          isForensicWatermarkingEnabled={isForensicWatermarkingEnabled}
          defaultSessionWatermarkTemplateId={defaultSessionWatermarkTemplateId}
          isAdmin={isAdminLevel}
          onChange={this.onChange}
          openSessionWatermarkTemplateEditor={
            this.onClickOpenSessionWatermarkTemplateEditor
          }
          sessionWatermarkTemplates={sessionWatermarkTemplates}
          setDefaultSessionWatermarkTemplateId={
            this.setDefaultSessionWatermarkTemplateId
          }
          showToasts
        />
      </StyledFlex>
    );
  };

  render() {
    const {
      accountId,
      canCustomizeEmailMessages,
      canDisableShareComments,
      canDisableShareDownloads,
      canDisableSharePreviousVersions,
      canEnableDownloads,
      canSetDefaultTeamSessionWatermarkId,
      canSharePublicly,
      canShareWithoutDrm,
      canToggleComments,
      canUseCustomSort,
      canUseShareLinkExpiration,
      canUsePasswordProtectedShares,
      exitEditor,
      copyShortUrlToClipboard,
      defaultSessionWatermarkTemplateId,
      handleChange,
      handleSubmit,
      isAdminLevel,
      isDrmSettingsEnabled,
      isEnterprise,
      secureSharingEnabled,
      inviteUsersToReviewLink,
      isSessionBasedWatermarkingEnabled,
      plan,
      reviewLink: { id: reviewLinkId, project_id: projectId },
      sessionWatermarkTemplates,
      setFieldValue,
      totalInviteCount,
      upgradePlan,
      values: {
        currentMode,
        editingName,
        isSharing,
        isStickyLinkSettingsEnabled,
        itemIds,
        name,
        revertableName,
      },
    } = this.props;

    const {
      activeTab,
      showCustomWatermarkSubmit,
      showMeasuredContent,
    } = this.state;

    /*
    RNC-1471
    What on earth is going on here? We need to support a few different use cases when the user is
    editing text.
      - clicking outside the field should be interpreted as "confirming" the text, meaning we need
        to save it on blur. Thus we have to have support for an 'onBlur' action
      - hitting escape in the field should cancel out the text and run the cleanup code. This means
        we need to run that behavior and override the onBlur code, which will still be called.
        We track whether or not the user actively hit escape as a way to determine if the onBlur
        code needs to run.
      - We cannot run this check in the `onBlur` prop directly, because the event passed to onBlur
        is not the same event as the keyboard press event.
    */
    this.hitEscape = null;

    return (
      <StyledFlexForm onSubmit={handleSubmit} as="form" column justifyBetween>
        <Section as="section" column>
          <StyledCloseButton
            icon
            text
            type="button"
            onClick={exitEditor}
            ref={this.buttonRef}
          >
            <CancelSVG />
          </StyledCloseButton>
          <StyledH2>
            Share {itemIds.length} {itemIds.length === 1 ? 'item' : 'items'}
          </StyledH2>
          <TitleEntryContainer>
            {editingName ? (
              <TitleTextInput
                autoFocus
                compact
                name="revertableName"
                value={revertableName}
                onChange={(evt) => {
                  handleChange(evt);
                }}
                onKeyDown={(evt) => {
                  if (evt.key === 'Enter' || evt.key === 'Tab') {
                    this.safelyPersistTitleChange();
                  } else if (evt.key === 'Escape' && editingName) {
                    // If someone is editing, revert the changes and stop editing. Then force
                    // focus on the button to "reset" the escape button so that presses correctly
                    // close the modal
                    evt.stopPropagation();
                    this.hitEscape = true;
                    setFieldValue('revertableName', name);
                    setFieldValue('editingName', false);
                    this.buttonRef.current.focus();
                  }
                }}
                onFocus={(evt) => evt.target.select()}
                onBlur={this.safelyPersistTitleChange}
              />
            ) : (
              <TitleText onClick={() => setFieldValue('editingName', true)}>
                {name}
              </TitleText>
            )}
          </TitleEntryContainer>
        </Section>
        <Section column full minHeight={0} as="section">
          <TabSelectWrapper as={FlexItem} noShrink alignEnd>
            {showMeasuredContent && (
              <TabSelect
                onTabChange={this.onTabChange}
                selectedTab={activeTab}
                tabs={this.tabs}
              />
            )}
          </TabSelectWrapper>
          {showMeasuredContent && activeTab === Tabs.sharing && (
            <StyledFlex full minHeight={0} as={ShadowContainer}>
              {({ onScroll: shadowCallback }) => (
                <FlexItem
                  as={ScrollContainer}
                  grow={1}
                  onScroll={shadowCallback}
                >
                  <ReviewLinkEditorShareForm
                    accountId={accountId}
                    canSharePublicly={canSharePublicly}
                    canCustomizeEmailMessages={canCustomizeEmailMessages}
                    copyShortUrlToClipboard={copyShortUrlToClipboard}
                    secureSharingEnabled={secureSharingEnabled}
                    inviteUsersToReviewLink={inviteUsersToReviewLink}
                    isEnterprise={isEnterprise}
                    isSharing={isSharing}
                    onChange={this.onChange}
                    projectId={projectId}
                    reviewLinkId={reviewLinkId}
                    totalInviteCount={totalInviteCount}
                  />
                </FlexItem>
              )}
            </StyledFlex>
          )}
          {showMeasuredContent && activeTab === Tabs.settings && (
            <StyledFlex full minHeight={0} as={ShadowContainer}>
              {({ onScroll: shadowCallback }) => (
                <FlexItem
                  as={ScrollContainer}
                  grow={1}
                  onScroll={shadowCallback}
                >
                  <ReviewLinkEditorSettingsForm
                    canDisableShareComments={canDisableShareComments}
                    canDisableShareDownloads={canDisableShareDownloads}
                    canDisableSharePreviousVersions={
                      canDisableSharePreviousVersions
                    }
                    canEnableDownloads={canEnableDownloads}
                    canToggleComments={canToggleComments}
                    canSetDefaultTeamSessionWatermarkId={
                      canSetDefaultTeamSessionWatermarkId
                    }
                    canShareWithoutDrm={canShareWithoutDrm}
                    canUseCustomSort={canUseCustomSort}
                    canUseShareLinkExpiration={canUseShareLinkExpiration}
                    canUsePasswordProtectedShares={
                      canUsePasswordProtectedShares
                    }
                    isAdmin={isAdminLevel}
                    isDrmSettingsEnabled={isDrmSettingsEnabled}
                    isLinkWatermarkable={currentMode === 'private'}
                    isSessionBasedWatermarkingEnabled={
                      isSessionBasedWatermarkingEnabled
                    }
                    onChange={this.onChange}
                    onSelectSortBy={this.onSelectSortBy}
                    openSessionWatermarkTemplateEditor={
                      this.onClickOpenSessionWatermarkTemplateEditor
                    }
                    onClickSetInviteOnly={this.onClickSetInviteOnly}
                    defaultSessionWatermarkTemplateId={
                      defaultSessionWatermarkTemplateId
                    }
                    plan={plan}
                    reviewLinkId={reviewLinkId}
                    setDefaultSessionWatermarkTemplateId={
                      this.setDefaultSessionWatermarkTemplateId
                    }
                    sessionWatermarkTemplates={sessionWatermarkTemplates}
                    // we store this value in state instead of in formik because we do not want
                    // the field to be over-written when Formik is reinitialized
                    showCustomWatermarkSubmit={showCustomWatermarkSubmit}
                    setCustomWatermarkSubmit={() =>
                      this.setState({ showCustomWatermarkSubmit: true })
                    }
                    upgradePlan={upgradePlan}
                  />
                </FlexItem>
              )}
            </StyledFlex>
          )}
          {activeTab === Tabs.watermark && this.renderWatermarkTab()}
        </Section>
        {stickyLinkSettingsEnabled() && (
          <StickyLinkFooter
            label="Save settings"
            description="Saves link access type, asset sorting preferences, and reviewer permissions for your next Share."
            isToggled={isStickyLinkSettingsEnabled}
            content={
              <SharePanelSettingsSwitchRow
                isSeparatorHidden
                checked={isStickyLinkSettingsEnabled}
                name="isStickyLinkSettingsEnabled"
                onToggle={this.onSaveSettingsToggle}
              />
            }
          />
        )}
      </StyledFlexForm>
    );
  }
}

ReviewLinkEditor.propTypes = {
  // Props from component
  accountId: PropTypes.string.isRequired,
  canCustomizeEmailMessages: PropTypes.bool.isRequired,
  canEnableDownloads: PropTypes.bool.isRequired,
  canSetDefaultTeamSessionWatermarkId: PropTypes.bool,
  canSharePublicly: PropTypes.bool,
  canShareUnwatermarked: PropTypes.bool,
  canToggleComments: PropTypes.bool.isRequired,
  exitEditor: PropTypes.func.isRequired,
  copyShortUrlToClipboard: PropTypes.func.isRequired,
  secureSharingEnabled: PropTypes.bool,
  inviteUsersToReviewLink: PropTypes.func.isRequired,
  isAdminLevel: PropTypes.bool.isRequired,
  isDrmSettingsEnabled: PropTypes.bool,
  isForensicWatermarkingEnabled: PropTypes.bool,
  isSessionBasedWatermarkingEnabled: PropTypes.bool,
  setDefaultSessionWatermarkTemplateIdForTeam: PropTypes.func.isRequired,
  totalInviteCount: PropTypes.number,

  // review link entity
  reviewLink: PropTypes.shape({
    access_control: PropTypes.shape({ invite_only: PropTypes.bool.isRequired })
      .isRequired,
    allow_approvals: PropTypes.bool.isRequired,
    current_version_only: PropTypes.bool.isRequired,
    disable_drm: PropTypes.bool,
    enable_comments: PropTypes.bool.isRequired,
    enable_downloading: PropTypes.bool.isRequired,
    expires_at: PropTypes.string,
    id: PropTypes.string.isRequired,
    is_active: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string.isRequired,
    password: PropTypes.string,
    project_id: PropTypes.string.isRequired,
    short_url: PropTypes.string.isRequired,
  }),

  // Props from withFormik
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

ReviewLinkEditor.defaultProps = {
  reviewLink: {},
  secureSharingEnabled: false,
};

const withFormikConfig = {
  mapPropsToValues: (props) => {
    const {
      reviewLink: {
        access_control: { invite_only: isPrivate },
        allow_approvals: allowApprovals,
        current_version_only: currentVersionOnly,
        disable_drm: disableDrm,
        enable_comments: allowComments,
        enable_downloading: allowDownloads,
        expires_at: expiresAt,
        id,
        is_active: isActive,
        items: itemIds,
        name,
        password,
        require_forensic_watermark: requireForensicWatermark,
        session_watermark_id: sessionWatermarkId,
        short_url: shortUrl,
        sort_direction: sortDirection,
        sort_type: sortType,
        watermark_user_input: watermarkUserInput,
      },
      reviewLinkPreferences,
      secureSharingEnabled,
    } = props;

    return {
      allowApprovals,
      allowComments,
      allowDownloads,
      currentMode:
        (isActive &&
          isPrivate &&
          secureSharingEnabled &&
          LINK_ACCESS_OPTIONS.PRIVATE) ||
        (isActive && LINK_ACCESS_OPTIONS.PUBLIC) ||
        LINK_ACCESS_OPTIONS.DISABLED,
      disableDrm,
      editingName: false,
      emailMessage: '',
      expiresAt,
      hasClickedShareLinkButton: false,
      id,
      isExpired:
        Boolean(expiresAt) &&
        moment(expiresAt)
          .utc()
          .isBefore(+moment().utc()),
      isSharing: false,
      isStickyLinkSettingsEnabled:
        reviewLinkPreferences.is_sticky_link_settings_enabled,
      itemIds,
      name,
      password,
      requireForensicWatermark: requireForensicWatermark || false,
      revertableName: name,
      shortUrl,
      sessionWatermarkId: sessionWatermarkId || '',
      selectedShareTab: shareTabs[0].key,
      sendEmail: true,
      showAdvancedSettings: Boolean(expiresAt || password),
      showCustomWatermarkSubmit: false,
      showAllVersions: !currentVersionOnly,
      showEmailMessage: false,
      showLinkExpiration: Boolean(expiresAt),
      showPassword: Boolean(password),
      sortDirection: sortDirection || SORT_CONST.ASC,
      sortType: sortType || SORT_CONST.INDEX,
      watermarkUserInput: watermarkUserInput || '',
      userTokens: [],
    };
  },
  handleSubmit: (values, formikBag) => {
    const {
      allowApprovals,
      allowComments,
      allowDownloads,
      currentMode,
      disableDrm,
      expiresAt,
      isStickyLinkSettingsEnabled,
      name,
      password,
      requireForensicWatermark,
      sessionWatermarkId,
      showAllVersions,
      showLinkExpiration,
      showPassword,
      sortDirection,
      sortType,
      watermarkUserInput,
    } = values;
    const {
      props: {
        reviewLink,
        reviewLinkPreferences,
        updateProjectReviewLinkPreferences,
        updateReviewLinkSettings,
      },
    } = formikBag;

    const nextReviewLink = {
      access_control: addInviteOnlyAccessControl(currentMode),
      allow_approvals: allowApprovals,
      current_version_only: !showAllVersions,
      disable_drm: disableDrm,
      enable_comments: allowComments,
      enable_downloading: allowDownloads,
      expires_at: showLinkExpiration ? expiresAt : null,
      is_active: currentMode !== LINK_ACCESS_OPTIONS.DISABLED,
      name,
      password: showPassword ? password : null,
      require_forensic_watermark: requireForensicWatermark,
      session_watermark_id: sessionWatermarkId,
      sort_direction: sortDirection,
      sort_type: sortType,
      watermark_user_input: watermarkUserInput,
    };

    const changes = (prev, next) =>
      Object.keys(next)
        .filter((key) => !isEqual(get(next, key), get(prev, key)))
        .filter(
          // ENT-2981: an existing null value must not be changed to empty string
          (key) => !(next[key] === '' && prev[key] === null)
        )
        .reduce((acc, key) => {
          acc[key] = next[key];
          return acc;
        }, {});

    // If we don't add the id here a change will always be registered. reviewLink.access_control has an id but nextReviewLink.access_control does not
    // when changing is_active to false, nextReviewLink.access_control comes back as null
    // This function returns nextReviewLink.access_control with id if nextReviewLink.access_control is not null.
    // else returns reviewLink.access_control so no change is registered
    const nextAccessControl =
      nextReviewLink.access_control !== null
        ? {
            ...nextReviewLink.access_control,
            id: reviewLink.access_control.id,
          }
        : reviewLink.access_control;

    const reviewLinkChanges = changes(reviewLink, {
      ...nextReviewLink,
      access_control: nextAccessControl,
    });

    if (!isEmpty(reviewLinkChanges)) {
      updateReviewLinkSettings(reviewLink.id, reviewLinkChanges);
    }

    const nextReviewLinkPreferences = {
      allow_approvals: nextReviewLink.allow_approvals,
      current_version_only: nextReviewLink.current_version_only,
      enable_comments: nextReviewLink.enable_comments,
      enable_downloading: nextReviewLink.enable_downloading,
      invite_only: nextAccessControl.invite_only,
      is_active: nextReviewLink.is_active,
      is_sticky_link_settings_enabled: isStickyLinkSettingsEnabled,
      sort_direction: nextReviewLink.sort_direction,
      sort_type: nextReviewLink.sort_type,
    };

    const reviewLinkPreferenceChanges = changes(
      reviewLinkPreferences,
      nextReviewLinkPreferences
    );

    const updateReviewLinkPreferences = (prefs) => {
      updateProjectReviewLinkPreferences(reviewLink.project_id, {
        user_preferences: { review_link_preferences: prefs },
      });
    };

    if (!isEmpty(reviewLinkPreferenceChanges)) {
      if (isStickyLinkSettingsEnabled) {
        updateReviewLinkPreferences(reviewLinkPreferenceChanges);
      } else if (
        'is_sticky_link_settings_enabled' in reviewLinkPreferenceChanges
      ) {
        updateReviewLinkPreferences({
          is_sticky_link_settings_enabled: false,
        });
      }
    }
  },
  validationSchema: Yup.object().shape({
    emailMessage: Yup.string().max(2500),
    name: Yup.string().required('Title should not be blank'),
    watermarkUserInput: Yup.string()
      .max(
        WATERMARK_STRING_CHAR_LIMIT,
        ({ max }) => `Your text must be within ${max} characters.`
      )
      .test(
        'no-disallowed-characters',
        'Your text includes invalid characters',
        (value) => WATERMARK_STRING_ALLOWED_CHARS_REGEX.test(value)
      ),
  }),
};

export default withFormik(withFormikConfig)(ReviewLinkEditor);
