import { useRef, useEffect } from 'react';

function useFocus(isFocused) {
  const ref = useRef();

  useEffect(() => {
    if (isFocused && ref.current) {
      ref.current.focus();
    }
  }, [isFocused]);

  return ref;
}

export default useFocus;
