import { combineReducers } from 'redux';
import REVIEW_LINK from '@frameio/core/src/reviewLinks/actions';
import { REVIEW_LINK_ITEMS } from '@frameio/core/src/reviewLinkItems/actions';
import commentsToggle from './CommentsToggle/reducer';
import { REVIEW_LINK_CONTAINER } from './actions';

export default combineReducers({
  isFetching: function isFetchingReducer(state = true, action) {
    switch (action.type) {
      case REVIEW_LINK_CONTAINER.FETCH_DATA:
        return true;
      case REVIEW_LINK_ITEMS.GET_SHARED.SUCCESS:
      case REVIEW_LINK_CONTAINER.FETCH_FAILED:
        return false;
      default:
        return state;
    }
  },
  failureReason: function failureReasonReducer(state = null, action) {
    switch (action.type) {
      case REVIEW_LINK_CONTAINER.FETCH_FAILED:
        return action.payload.failureReason;
      case REVIEW_LINK_CONTAINER.FETCH_DATA:
        return null;
      default:
        return state;
    }
  },
  hasBundle: function hasBundleReducer(state = null, action) {
    switch (action.type) {
      case REVIEW_LINK_CONTAINER.FETCH_FAILED:
        return action.payload.hasBundle;
      case REVIEW_LINK.GET_SHARED.SUCCESS: {
        const reviewLinkId = action.payload.entityId;
        const reviewLinkPayload = reviewLinkId
          ? action.payload.response.entities.reviewLink[reviewLinkId]
          : null;
        const bundleCount = reviewLinkPayload
          ? reviewLinkPayload.bundle_count
          : 0;

        return bundleCount > 0;
      }
      default:
        return state;
    }
  },
  isDetailPaneOpen: function isDetailPaneOpenReducer(state = false, action) {
    switch (action.type) {
      case REVIEW_LINK_CONTAINER.TOGGLE_DETAIL_PANE:
        return action.payload.isDetailPaneOpen;
      default:
        return state;
    }
  },
  selectedPageNumber: function selectedPageNumberReducer(state = 1, action) {
    switch (action.type) {
      case REVIEW_LINK_CONTAINER.SET_SELECTED_PAGE_NUMBER:
        return action.payload.page;
      default:
        return state;
    }
  },
  showNameInput: function showNameInputReducer(state = false, action) {
    switch (action.type) {
      case REVIEW_LINK_CONTAINER.SHOW_NAME_INPUT_CHANGE:
        return action.payload.showNameInput;
      default:
        return state;
    }
  },
  commentsToggle,
});
