import { connect } from 'react-redux';
import {
  assetEntityInclDeletedSelector,
  coverAssetEntityInclDeletedSelector,
} from '@frameio/core/src/assets/selectors';
import FileAssetThumb from './FileAssetThumb';

/**
 * TODO(CORE-1621): this should really just be
 * components/AssetThumb/ConnectedAssetThumb but until we can refactor
 * all the other callsites of AssetThumb, let's leave this here and do it
 * all at once as part of CORE-1621.
 *
 * Note that these use the *InclDeleted selectors because we can always show
 * the thumb of the asset even if it’s soft deleted. It’s up to consumers to
 * filter out deleted assetIds before rendering the AssetThumb if they do not
 * want to show deleted Assets.
 */
function mapStateToProps(state, { assetId }) {
  const coverAsset = coverAssetEntityInclDeletedSelector(state, { assetId });
  return {
    asset: coverAsset || assetEntityInclDeletedSelector(state, { assetId }),
  };
}

export default connect(mapStateToProps)(FileAssetThumb);

export const testExports = {
  mapStateToProps,
};
