import React from 'react';
import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';
import styled from 'styled-components';
import { NativeTypes } from 'react-dnd-html5-backend';
import { absoluteFill } from '@frameio/components/src/mixins';

import { types } from 'utils/dragAndDrop';
import ManageVersions from 'components/ManageVersionStack/AssetDragPreview';
import AssetsPreview from './AssetsPreview';

const Wrapper = styled.div`
  ${absoluteFill()};
  pointer-events: none;
`;

// the DragLayer requires the component passed into it
// to be a class, since it passes in a ref
// eslint-disable-next-line react/prefer-stateless-function
class DragPreview extends React.Component {
  render() {
    const { item, itemType, isDragging, currentOffset } = this.props;

    if (!currentOffset) {
      return null;
    }

    let Preview;
    switch (itemType) {
      case types.ASSETS:
        Preview = AssetsPreview;
        break;
      case types.VERSIONS:
        Preview = ManageVersions;
        break;
      default:
        return null;
    }
    return (
      <Wrapper>
        {isDragging && <Preview {...{ currentOffset, item }} />}
      </Wrapper>
    );
  }
}

DragPreview.propTypes = {
  currentOffset: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  isDragging: PropTypes.bool.isRequired,
  item: PropTypes.object,
  itemType: PropTypes.oneOf([
    ...Object.values(types),
    NativeTypes.FILE,
    NativeTypes.URL,
  ]),
};

DragPreview.defaultProps = {
  item: undefined,
  itemType: undefined,
  currentOffset: null,
};

const mapDragStateToProps = (monitor) => ({
  currentOffset: monitor.getClientOffset(),
  isDragging: monitor.isDragging(),
  item: monitor.getItemType() !== NativeTypes.FILE ? monitor.getItem() : null,
  itemType: monitor.getItemType(),
});

export const testExports = {
  DragPreview,
  mapDragStateToProps,
  Wrapper,
};

export default DragLayer(mapDragStateToProps)(DragPreview);
