import { connect } from 'react-redux';
import UserSearch from './UserSearch';
import { executeSearchStrategies, resetUserSearch } from './actions';
import {
  userSearchResultIdsSelector,
  teamSearchResultIdsSelector,
  groupSearchResultsIdsSelector,
  pendingReviewerSearchResultIdsSelector,
} from './selectors';

function mapStateToProps(state) {
  return {
    groupIds: groupSearchResultsIdsSelector(state),
    teamIds: teamSearchResultIdsSelector(state),
    userIds: userSearchResultIdsSelector(state),
    pendingReviewerIds: pendingReviewerSearchResultIdsSelector(state),
  };
}

const mapDispatchToProps = {
  executeSearchStrategies,
  resetUserSearch,
};

export const testExports = { mapStateToProps, mapDispatchToProps };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSearch);
