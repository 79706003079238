import React from 'react';
import styled from 'styled-components';
import OptionButton from './OptionButton';

const ButtonForm = ({
  options,
  selectedOption,
  onSubmit,
  updateValue,
  finishedProject,
}) => (
  <Form onSubmit={onSubmit}>
    {Object.entries(options).map(([key, value]) => {
      return (
        <OptionButton
          key={key}
          updateValue={updateValue}
          isSelected={selectedOption === key}
          text={finishedProject ? value : value.BUTTON_TEXT}
          value={key}
        />
      );
    })}
  </Form>
);

const Form = styled.form`
  button:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export default ButtonForm;
