import { connect } from 'react-redux';

import { redirectToProject } from 'pages/ProjectContainer/ProjectHeader/actions';
import {
  createReviewLinkWithAssets,
  legacyShareItemsAsNewReviewLink,
  selectReviewLinkItems,
  toggleReviewLinkSelectionMode,
} from 'components/ReviewLinkEditor/actions';
import { showBlockedCollaboratorMessage } from 'components/RequestToJoinTeamCard/actions';
import { isAccountEnterpriseSelector } from 'selectors/accounts';
import { permittedActionsForAssetsSelector } from 'selectors/permissions';

import ShareAssetsAsReview from './ShareAssetsAsReview';

function mapStateToProps(state, { assetIds, projectId }) {
  const { canShareReview: hasPermission } = permittedActionsForAssetsSelector(
    state,
    { assetIds, projectId }
  );

  return {
    isEnterprise: isAccountEnterpriseSelector(state),
    hasPermission,
  };
}

const mapDispatchToProps = {
  createReviewLinkWithAssets,
  legacyShareItemsAsNewReviewLink,
  redirectToProject,
  selectReviewLinkItems,
  showBlockedCollaboratorMessage,
  toggleReviewLinkSelectionMode,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareAssetsAsReview);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
