import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import { updatedArchivalMessagingEnabled } from 'utils/featureFlags';
import ArchiveProject from './ArchiveProject';
import { continueFlow } from '../actions';

const mapStateToProps = (state) => ({
  isUpdatedArchivalMessagingEnabled: updatedArchivalMessagingEnabled(state),
});

const mapDispatchToProps = {
  onCancel: closeModal,
  onContinue: continueFlow,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchiveProject);

export const testExports = {
  mapDispatchToProps,
};
