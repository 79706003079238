import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@frameio/components/src/styled-components/Button';
import CancelIcon from '@frameio/components/src/svgs/icons/cancel.svg';

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

function ModalCloseButton({ forwardedRef, onClick, ...rest }) {
  return (
    <CloseButton
      icon="true"
      text
      onClick={onClick}
      ref={forwardedRef}
      {...rest}
    >
      <CancelIcon width={24} height={24} />
    </CloseButton>
  );
}

ModalCloseButton.propTypes = {
  forwardedRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  onClick: PropTypes.func.isRequired,
};
ModalCloseButton.defaultProps = {
  forwardedRef: null,
};

export default React.forwardRef((props, ref) => (
  <ModalCloseButton {...props} forwardedRef={ref} />
));
