// @flow

import React from 'react';

import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import ScaleFadeTransition from 'components/ScaleFadeTransition';
import Toast from '@frameio/components/src/components/Toast';
import ToastIcons from '../ToastIcons';

import { removeToastsBy } from '../../actions/toasts';
import type { ToastPayload } from '../../actions/toasts';
import './ToastPortal.scss';

type ToastRootProps = {
  toasts: Array<ToastPayload>,
  onToastRemove: Function,
};

class ToastPortal extends React.Component<ToastRootProps> {
  render() {
    const toastPortalRoot = document.body;

    const { toasts, onToastRemove } = this.props;

    return ReactDOM.createPortal(
      <TransitionGroup className="toasts-container">
        {toasts.map((toast, index) => {
          const { key, iconType, onClose, autoCloseDelay, ...rest } = toast;

          // Don't show more than 3 toasts in the portal.
          // Otherwise close the oldest toast by decreasing
          // the `autoCloseDelay`
          const delay = index < toasts.length - 3 ? 0 : autoCloseDelay;

          return (
            <ScaleFadeTransition key={key}>
              <Toast
                icon={<ToastIcons type={iconType} />}
                onClose={onToastRemove(key, onClose)}
                autoCloseDelay={delay}
                {...rest}
              />
            </ScaleFadeTransition>
          );
        })}
      </TransitionGroup>,
      toastPortalRoot
    );
  }
}

// eslint-disable-next-line arrow-body-style
const mapStateToProps = ({ toasts }) => {
  return {
    toasts,
  };
};

// eslint-disable-next-line arrow-body-style
const mapDispatchToProps = (dispatch) => {
  return {
    onToastRemove: (key, callback) => () => {
      if (callback) callback();
      dispatch(removeToastsBy({ key }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToastPortal);
export const testExports = { ToastPortal };
