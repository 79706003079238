/**
 * Context created to manage information across different components
 * Currently used for:
 * -assetsToMove to be passed to TreeProjectItem (and to idenfity when to disable an item)
 *
 */

import React from 'react';

export default React.createContext({});
