// Note: this is a magic list of subdomains that we know are asset URLs

const LIST_OF_ASSET_SUBDOMAINS = [`assets`, `picture`, `preview`, `stream`];

/**
 * @desc Check if the given URL is an asset URL
 */
const isAssetUrl = (url, list = LIST_OF_ASSET_SUBDOMAINS) =>
  list.some((str) => url.includes(str));

export default isAssetUrl;
