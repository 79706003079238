import React from 'react';
import ListCell from '@frameio/components/src/styled-components/ListCell';
import Avatar from '@frameio/components/src/styled-components/Avatar';

export default function LoadingPerson({ size }) {
  return <ListCell image={<Avatar size={size} />} title="" />;
}

LoadingPerson.propTypes = {
  size: Avatar.propTypes.size,
};

LoadingPerson.defaultProps = {
  size: 28,
};
