import React from 'react';
import PropTypes from 'prop-types';
import { MenuButton } from '@frameio/vapor';
import VimeoIcon from '@frameio/components/src/svgs/icons/vimeo.svg';

import { trackButtonClick } from 'analytics';

export default function PublishAssetToVimeo({
  assetId,
  publishToVimeo,
  trackingPage,
  trackingPosition,
  ...rest
}) {
  return (
    <MenuButton
      onSelect={() => {
        publishToVimeo(assetId);
        trackButtonClick(
          'publish asset to vimeo',
          trackingPage,
          trackingPosition
        );
      }}
      iconBefore={<VimeoIcon width={16} height={16} />}
      {...rest}
    >
      Publish to Vimeo
    </MenuButton>
  );
}

PublishAssetToVimeo.propTypes = {
  assetId: PropTypes.string.isRequired,
  publishToVimeo: PropTypes.func.isRequired,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};
