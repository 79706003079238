import { generateActionTypes } from 'shared/actions/helpers';
import { isFetchingActionFactory } from 'factories/isFetching';

export const TRIAL_COUNTDOWN = generateActionTypes('TRIAL_COUNTDOWN', [
  'IS_FETCHING',
  'FETCH_CARD',
  'ADD_CARD',
]);

export const isFetching = isFetchingActionFactory(TRIAL_COUNTDOWN.IS_FETCHING);
