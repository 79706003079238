import React from 'react';
import Flex from 'styled-flex-component';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { buttonReset } from '@frameio/components/src/mixins';
import Button from '@frameio/components/src/styled-components/Button';
import Checkbox from '@frameio/components/src/components/Checkbox';
import TextInput from '@frameio/components/src/styled-components/TextInput';
import PersonIcon from '@frameio/components/src/svgs/icons/24/person.svg';
import EmailIcon from '@frameio/components/src/svgs/icons/24/mail.svg';
import { TERMS_URL, PRIVACY_URL } from 'URLs';
import { redirectToAuth } from 'utils/router';
import { addHooksToOneTrustButtons } from '@frameio/segment-ot';
import { updateAnonCookieCategories } from '@frameio/core/src/users/actions';
import { SubmitContainer } from '../ReviewLink/ReviewLinkPromptContainer/promptComponents';

const FieldsContainer = styled.div`
  position: relative;
  max-height: ${(p) => (p.extended ? '142px' : '42px')};
  transition: max-height 0.2s ease-out;
  overflow: hidden;
  padding-top: 2px;
`;

const LoginCTA = styled.div`
  font-size: ${(p) => p.theme.fontSize[1]};
  display: flex;
`;

const LoginPrompt = styled.div`
  color: ${(p) => p.theme.color.graphiteGray};
`;

const LoginLink = styled.button`
  ${buttonReset()}
  color: ${(p) => p.theme.color.slateGray};
  margin-left: 6px;
  &:hover {
    text-decoration: underline;
  }
`;

// NEW
const MODAL_PADDING = '20px';
const Title = styled.h1`
  ${(p) => p.theme.fontStyle.heading};
  color: ${(p) => p.theme.color.almostBlack};
`;

const TermsContainer = styled(Flex)`
  margin-top: ${MODAL_PADDING};

  .acceptTerms.error .checkbox-decoy {
    border-color: ${(p) => p.theme.color.error};
  }
`;

const TermsText = styled.p`
  color: ${(p) => p.theme.color.graphiteGray};
  font-size: 12px;
  margin-left: 8px;
  line-height: 1.5;
`;

const OwnerName = styled.span`
  ${(p) => p.theme.fontStyle.body};
  color: ${(p) => p.theme.color.almostBlack};
  font-weight: bold;
`;

const BodyText = styled.p`
  ${(p) => p.theme.fontStyle.body};
  color: ${(p) => p.theme.color.graphiteGray};
`;

const NameWrapper = styled.div``;

const FormWrapper = styled.div`
  max-width: 400px;
  padding: ${MODAL_PADDING};
  background-color: ${(p) => p.theme.color.white};

  ${BodyText} {
    margin-bottom: ${MODAL_PADDING};
  }

  ${SubmitContainer} {
    margin-top: ${MODAL_PADDING};
    justify-content: space-between;
  }

  ${NameWrapper} {
    margin-top: 16px;
  }

  ${Button} + ${Button} {
    margin-left: ${(p) => p.theme.spacing.tiny};
  }
`;

const StyledTextInput = styled(TextInput)`
  /* icon padding + icon + icon padding + border + input padding */
  padding-left: ${({ theme: { spacing } }) =>
    `calc(${spacing.small} + 24px + ${spacing.small} + 1px + ${spacing.tiny})`};
`;

const IconWrapper = styled.div`
  padding-right: ${({ theme }) => `calc(${theme.spacing.small} - 1px)`};
  border-right: 1px solid ${(p) => p.theme.color.silver};
  color: ${(p) => p.theme.color.graphiteGray};
  svg {
    display: block;
  }
`;

function RegistrationForm({
  handleSubmit,
  handleChange,
  handleBlur,
  errors,
  showNameInput,
  touched,
  values: { name = '', email = '', acceptTerms = false },
  modalHeaderText,
  ownerName,
  onCancel,
  onSubmitEmail,
  submitCount,
  setFieldValue,
  reviewLinkId,
}) {
  const nameInput = React.useRef();

  React.useEffect(() => {
    if (nameInput && showNameInput) nameInput.current.focus();
  }, [showNameInput]);

  // clean up the store on unmount
  React.useEffect(() => {
    return () => {
      if (submitCount === 0) onCancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reviewLinkOwner = <OwnerName>{ownerName}</OwnerName>;
  const message = (
    <BodyText>
      Add your info here so {reviewLinkOwner} knows who left the comment.
    </BodyText>
  );
  const isChecked = !!acceptTerms;
  const acceptTermsError = !!submitCount && errors.acceptTerms;

  const redirect = () =>
    redirectToAuth({}, true, {
      details:
        'Logged out user in review link leaving comment pressed Log In button in Anonymous User Registration modal',
      location: window?.location?.href,
    });

  return (
    <FormWrapper>
      <Title>{modalHeaderText}</Title>
      {message}
      <form
        onSubmit={(event) => {
          // V3FRAME-254 - update massdriver with the selections a user has made
          // when they anonymously login to the review link
          addHooksToOneTrustButtons(updateAnonCookieCategories, {
            reviewLinkId,
            email,
          });
          if (showNameInput) {
            handleSubmit(event);
          } else {
            event.preventDefault();
            onSubmitEmail(email);
          }
        }}
      >
        <FieldsContainer extended={showNameInput}>
          <StyledTextInput
            error={!!submitCount && touched.email && errors.email}
            icon={
              <IconWrapper>
                <EmailIcon />
              </IconWrapper>
            }
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="user@example.com"
            type="email"
            value={email}
          />

          <NameWrapper>
            <StyledTextInput
              ref={nameInput}
              error={!!submitCount && touched.name && errors.name}
              icon={
                <IconWrapper>
                  <PersonIcon />
                </IconWrapper>
              }
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="John Doe"
              type="text"
              value={name}
            />
          </NameWrapper>
        </FieldsContainer>
        {showNameInput && (
          <>
            <TermsContainer alignCenter>
              <Checkbox
                name="acceptTerms"
                classNames={`acceptTerms ${acceptTermsError ? 'error' : ''}`}
                isChecked={isChecked}
                onChange={() => setFieldValue('acceptTerms', !acceptTerms)}
              />
              <TermsText>
                I agree to the
                <a target="_blank" rel="noopener noreferrer" href={TERMS_URL}>
                  {' '}
                  Terms and Conditions{' '}
                </a>
                and our
                <a target="_blank" rel="noopener noreferrer" href={PRIVACY_URL}>
                  {' '}
                  Privacy Policy
                </a>
                .
              </TermsText>
            </TermsContainer>
          </>
        )}
        <SubmitContainer>
          <LoginCTA>
            <LoginPrompt>Use Frame.io?</LoginPrompt>
            <LoginLink type="button" onClick={redirect}>
              Log In
            </LoginLink>
          </LoginCTA>
          <Flex justifyBetween>
            <Button text type="button" onClick={onCancel}>
              Cancel
            </Button>
            <Button primary type="submit">
              Continue
            </Button>
          </Flex>
        </SubmitContainer>
      </form>
    </FormWrapper>
  );
}

export const testExports = {
  Button,
  RegistrationForm,
  SubmitContainer,
};

export default withFormik({
  mapPropsToValues: ({ email, name, acceptTerms, cookieCategories }) => ({
    email,
    name,
    acceptTerms,
    cookieCategories,
  }),
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values),
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Name should not be blank'),
    email: Yup.string()
      .email('Must be an email address')
      .required('Email should not be blank'),
    acceptTerms: Yup.boolean()
      .oneOf([true])
      .required(),
  }),
})(RegistrationForm);

RegistrationForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmitEmail: PropTypes.func.isRequired,
  modalHeaderText: PropTypes.string.isRequired,
  ownerName: PropTypes.string.isRequired,

  // Props from Formik
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showNameInput: PropTypes.bool.isRequired,
  submitCount: PropTypes.number.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};
