import { combineReducers } from 'redux';
import { PRESENTATION } from '@frameio/core/src/presentations/actions';
import { PRESENTATION_PAGE } from './actions';

export default combineReducers({
  isFetching: function isFetchingReducer(state = false, action) {
    switch (action.type) {
      case PRESENTATION_PAGE.ENTER_PRESENTATION:
      case PRESENTATION_PAGE.FETCH_DATA:
        return true;
      case PRESENTATION.GET_BY_VANITY.SUCCESS:
      case PRESENTATION_PAGE.FETCH_FAILED:
        return false;
      default:
        return state;
    }
  },
  failureReason: function failureReasonReducer(state = null, action) {
    const { type, payload } = action;
    switch (type) {
      case PRESENTATION_PAGE.ENTER_PRESENTATION:
        return null;
      case PRESENTATION_PAGE.FETCH_FAILED: {
        const { failureReason } = payload;

        return failureReason;
      }
      default:
        return state;
    }
  },
});
