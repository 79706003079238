import React from 'react';
import PropTypes from 'prop-types';

import Timecode from '@frameio/smpte-timecode';
import {
  TimeDisplay as TimeDisplayType,
  timeToStandard,
} from '@frameio/components/src/components/PlayerSubcomponents/PlayerInterface';

import {
  getOriginalCurrentTime,
  getOffsetTimecode,
  getSafeTotalFrames,
} from '@frameio/components/src/utils/mediaUtils';

import MonospaceTime from '../MonospaceTime';

const TimeDisplayText = ({
  audioCodec,
  currentTime,
  displayType,
  duration,
  frameRate,
  isHlsPlayerEnabled,
  timecodeIn,
  totalFrames,
}) => {
  const computedTotalFrames = getSafeTotalFrames(
    duration,
    frameRate,
    totalFrames
  );

  // Audio files have total frames value of 0, so we need to calculate the correct value.
  const originalCurrentTime = getOriginalCurrentTime({
    currentTime,
    duration,
    audioCodec,
    frameRate,
    isUsingHlsJs: isHlsPlayerEnabled,
    totalFrames,
  });

  // When displaying clock time, use the actual `videoEl.currentTime` that may
  // already be calibrated for frame-accuracy (player and review pages) or not
  // (quicklook, presentation and split player page).
  //
  // Note that because `PlayerInterface.timeToStandard` (correctly) floors the
  // value of seconds, it's important not to use `getOriginalCurrentTime` for
  // clock time because the substractions it performs can nudge the value
  // under the floor, which will causes this to under-report the current
  // second by 1s.
  if (displayType === TimeDisplayType.STANDARD) {
    return (
      <MonospaceTime
        current={timeToStandard(currentTime)}
        end={timeToStandard(duration)}
      />
    );
  }

  // Get the starting frame number, which needs to be added to the current
  // frame number.
  let startTc;
  let validatedTimecodeIn = timecodeIn;
  try {
    startTc = new Timecode(timecodeIn, frameRate);
  } catch (error) {
    // This check should happen way higher up in the decision tree, when
    // deciding if the video can be played in frame-accurate mode at all.
    //
    // TODO: move out when unifying player/review page
    validatedTimecodeIn = '00:00:00:00';
    startTc = new Timecode(validatedTimecodeIn, frameRate);
  }
  const startFrame = startTc.frameCount;
  const tc = getOffsetTimecode(
    validatedTimecodeIn,
    frameRate,

    // When displaying the exact frame number and timecode, use the original
    // `currentTime` without the calibration offsets.
    originalCurrentTime
  );

  // Because the current frame number starts from 0, the max frame number that
  // can be reached is `computedTotalFrames - 1`.
  const maxFrames = startFrame + computedTotalFrames - 1;

  if (tc.frameCount > maxFrames) {
    tc.subtract(tc.frameCount - maxFrames);
  }

  if (displayType === TimeDisplayType.FRAME) {
    return <MonospaceTime current={`${tc.frameCount}`} end={`${maxFrames}`} />;
  }
  if (displayType === TimeDisplayType.TIMECODE) {
    return <MonospaceTime current={tc.toString()} />;
  }

  return null;
};

TimeDisplayText.defaultProps = {
  currentTime: 0,
  duration: 0,
};

TimeDisplayText.propTypes = {
  currentTime: PropTypes.number,
  displayType: PropTypes.string.isRequired,
  duration: PropTypes.number,
  frameRate: PropTypes.number.isRequired,
  isHlsPlayerEnabled: PropTypes.bool,
  timecodeIn: PropTypes.string,
  totalFrames: PropTypes.number.isRequired,
};

export default TimeDisplayText;
