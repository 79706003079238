export const NOTIFICATIONS_FILTER_OPTIONS = {
  ALL: 'All',
  UNREAD: 'Unread',
};

export const NOTIFICATION_STATUS = {
  READ: 'read',
  UNREAD: 'unread',
};

export const LINK_TYPES = {
  ASSET: 'asset',
  COMMENT: 'comment',
  FOLDER: 'folder',
  PRESENTATION: 'presentation',
  PROJECT: 'project',
  REVIEW_LINK: 'review_link',
  TEAM: 'team',
  JOIN_TEAM_REQUEST_APPROVED: 'join_team_request_approved',
};

const NOTIFICATION_TYPES = {
  COLLABORATOR_ADDED: 'collaborator_added',
  COMMENT_CREATED: 'comment_created',
  COMMENT_LIKED: 'comment_liked',
  FILE_UPLOADED: 'file_uploaded',
  JOIN_PRESENTATION_REQUEST_APPROVED: 'join_presentation_request_approved',
  JOIN_PROJECT_REQUEST_APPROVED: 'join_project_request_approved',
  JOIN_REVIEW_LINKREQUEST_APPROVED: 'join_review_link_request_approved',
  JOIN_TEAM_REQUEST_APPROVED: 'join_team_request_approved',
  LABEL_UPDATED: 'label_updated',
  PROJECT_UNARCHIVED: 'project_unarchived',
  REPLY_CREATED: 'reply_created',
  USER_MENTIONED: 'user_mentioned',
  WORKFRONT_ASSET_APPROVAL_STATUS_UPDATED:
    'workfront_asset_approval_status_updated',
};

export const supportedNotificationTypes = Object.values(NOTIFICATION_TYPES);

export const numNotificationsToFetch = 25;
