import { connect } from 'react-redux';

import { reviewLinkEntitySelector } from '@frameio/core/src/reviewLinks/selectors';
import { getPublicReviewLinkUrl } from 'URLs';
import {
  confirmDeleteReviewLink,
  copyShortUrlToClipboard,
  duplicateReviewLink,
  editReviewLinkSettings,
  editReviewLinkContent,
  patchToggleIsActive,
} from '../actions';
import { reviewLinkIdForContextMenuSelector } from '../selectors';
import ProjectReviewLinkContextMenu from './ProjectReviewLinkContextMenu';

const mapStateToProps = (state) => {
  const reviewLinkId = reviewLinkIdForContextMenuSelector(state);
  const reviewLink = reviewLinkEntitySelector(state, { reviewLinkId });
  const reviewLinkUrl = getPublicReviewLinkUrl(
    reviewLink.id,
    reviewLink.short_url
  );
  const { is_active: isActive } = reviewLink;

  return {
    reviewLinkId,
    isActive,
    reviewLinkUrl,
  };
};

const mapDispatchToProps = {
  confirmDeleteReviewLink,
  copyShortUrlToClipboard,
  duplicateReviewLink,
  editReviewLinkSettings,
  editReviewLinkContent,
  patchToggleIsActive,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectReviewLinkContextMenu);

export const testExports = { mapStateToProps, mapDispatchToProps };
