import React from 'react';
import Proptypes from 'prop-types';
import { STORAGE_LIMIT } from '@frameio/core/src/subscriptionLineItems/utils/modifierTypes';
import { formatBytes } from 'shared/filesizeHelpers';
import { generateTotalAndUnit } from './utils';
import ManageSubscriptionModal from './ManageSubscriptionModal';

const generateChargedTodaySummary = (lineItemQuantity, increment, unit) => (
  <>
    You'll be charged today for{' '}
    <b>
      {lineItemQuantity}{' '}
      {lineItemQuantity === 1 ? 'storage add-on' : 'storage add-ons'} (
      {increment} {unit}
      {lineItemQuantity > 1 && ' each'})
    </b>
    , prorated for the current billing period.
  </>
);

const ManageStorageModal = ({
  planLineItem,
  updateItem,
  planLimit,
  totalStorage,
  continueFlow,
  showTotalLineItem,
  ...rest
}) => {
  if (!planLineItem) return null;

  const header = 'Manage storage';

  const subHeader = showTotalLineItem ? (
    <span>Your current storage limit is {formatBytes(totalStorage)}.</span>
  ) : (
    <span>
      <strong>You are out of storage.</strong> To upload more files, please add
      additional storage.
    </span>
  );

  return (
    <ManageSubscriptionModal
      subHeader={subHeader}
      blockInput
      header={header}
      generateChargedTodaySummary={generateChargedTodaySummary}
      type={STORAGE_LIMIT}
      currentLimit={totalStorage}
      planLineItem={planLineItem}
      planLimit={planLimit}
      updateItem={(amount) => updateItem(STORAGE_LIMIT, amount)}
      continueFlow={continueFlow}
      toolTipText="storage"
      generateItemText={(plural) => `storage ${plural ? 'add-ons' : 'add-on'}`}
      howToUseModalText="Click on the controls to add or remove storage to your account."
      minWidth={200}
      showTotalLineItem={showTotalLineItem}
      generateTotalAndUnit={generateTotalAndUnit}
      {...rest}
    />
  );
};

ManageStorageModal.defaultProps = {
  isOpen: false,
  continueFlow: undefined,
};

ManageStorageModal.propTypes = {
  closeModal: Proptypes.func.isRequired,
  planLineItem: Proptypes.shape({
    cost: Proptypes.number.isRequired,
    increment: Proptypes.number.isRequired,
  }).isRequired,
  planLimit: Proptypes.number.isRequired,
  updateItem: Proptypes.func.isRequired,
  totalStorage: Proptypes.number.isRequired,
  continueFlow: Proptypes.func,
  showTotalLineItem: Proptypes.bool.isRequired,
};

export default ManageStorageModal;
