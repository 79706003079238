import { connect } from 'react-redux';
import { subscriptionEntityForAccountIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { closeModal } from 'components/Modal/actions';
import {
  isAccountOnLegacyPlanSelector,
  isAccountFreeSelector,
} from 'selectors/accounts';
import { isAccountAdminSelector } from 'selectors/roles';
import OutOfSpace from './OutOfSpace';
import { continueFlow } from '../actions';

function mapStateToProps(state, { accountId }) {
  return {
    subscription: subscriptionEntityForAccountIdSelector(state, { accountId }),
    isAccountOnLegacyPlan: isAccountOnLegacyPlanSelector(state, { accountId }),
    isAccountAdmin: isAccountAdminSelector(state, { accountId }),
    isAccountFree: isAccountFreeSelector(state, { accountId }),
    accountId,
  };
}

const mapDispatchToProps = {
  onCancel: closeModal,
  onContinue: continueFlow,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutOfSpace);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
