import { createAction } from 'redux-actions';
import {
  generateActionTypes,
  generateFetchActionTypes,
} from 'shared/actions/helpers';

const module = 'BRANDING_CONTAINER';
export const BRANDING_CONTAINER: Object = {
  ...generateActionTypes(module, [
    'PROMPT_RENAME_ACCOUNT',
    'PROMPT_RENAME_TEAM',
    'UPGRADE_PLAN',
    'UPDATE_TEAM',
  ]),
  ...generateFetchActionTypes(module, [
    'REMOVE_ACCOUNT_LOGO',
    'REMOVE_EMAIL_BRANDING',
    'REMOVE_TEAM_LOGO',
    'UPLOAD_ACCOUNT_LOGO',
    'UPLOAD_EMAIL_BRANDING',
    'UPLOAD_TEAM_LOGO',
  ]),
};

export const promptRenameAccount = createAction(
  BRANDING_CONTAINER.PROMPT_RENAME_ACCOUNT,
  (accountId) => ({ accountId })
);

export const promptRenameTeam = createAction(
  BRANDING_CONTAINER.PROMPT_RENAME_TEAM,
  (teamId) => ({ teamId })
);

export const removeAccountLogo = createAction(
  BRANDING_CONTAINER.REMOVE_ACCOUNT_LOGO.BASE,
  (accountId) => ({ accountId })
);

export const removeEmailBranding = createAction(
  BRANDING_CONTAINER.REMOVE_EMAIL_BRANDING.BASE,
  (teamId) => ({ teamId })
);

export const removeTeamLogo = createAction(
  BRANDING_CONTAINER.REMOVE_TEAM_LOGO.BASE,
  (teamId) => ({ teamId })
);

export const upgradePlan = createAction(
  BRANDING_CONTAINER.UPGRADE_PLAN,
  (accountId, trackingProps) => ({ accountId, trackingProps })
);

export const uploadAccountLogo = createAction(
  BRANDING_CONTAINER.UPLOAD_ACCOUNT_LOGO.BASE,
  (accountId) => ({ accountId })
);

export const uploadEmailBranding = createAction(
  BRANDING_CONTAINER.UPLOAD_EMAIL_BRANDING.BASE,
  (teamId) => ({ teamId })
);

export const uploadTeamLogo = createAction(
  BRANDING_CONTAINER.UPLOAD_TEAM_LOGO.BASE,
  (teamId) => ({ teamId })
);

/**
 * @param {string} teamId - Team id.
 * @param {Object} data - Data to update a team with.
 * @returns {Action} - Action object.
 */
export const updateTeam = createAction(
  BRANDING_CONTAINER.UPDATE_TEAM,
  (teamId, data) => ({ teamId, data })
);
