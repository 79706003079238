import React from 'react';
import spinnerUrl from './logo-spinner.gif';

/**
 * Make the browser download the gif first, so that it's (hopefully) available
 * for render by the time `LogoSpinner` is mounted.
 */
export function preload() {
  const img = new Image();
  img.src = spinnerUrl;
}

/**
 * A stylized Frame.io logo spinner. Because the spinner is a gif, the component
 * is restricted to a single size optimized for @2x screens.
 */
export default function LogoSpinner() {
  return <img src={spinnerUrl} width="64" height="64" alt="Spinner" />;
}
