import reduceReducers from 'reduce-reducers';
import { without, get, clamp, subtract } from 'lodash';
import PENDING_COLLABORATORS from '@frameio/core/src/pendingCollaborators/actions';
import COLLABORATOR from '@frameio/core/src/collaborators/actions';
import { status } from '@frameio/core/src/shared/reducers/factories';
import { isFetchingReducerFactory } from 'factories/isFetching';
import { MANAGE_PEOPLE } from './actions';

export const INITIAL_STATE = {
  isOpen: false,
  manageUsers: {
    collaborators: [],
    pendingCollaborators: [],
    fetchStatus: {
      collaborators: null,
      pendingCollaborators: null,
    },
    totalCollaborators: undefined,
    totalPendingCollaborators: undefined,
  },
  isInvitingUsers: false,
  isUpdatingShareLink: false,
  resendInviteEmails: {},
  peopleListScrollTop: 0,
  tokens: [],
};

const isInvitingUsersReducer = isFetchingReducerFactory(
  MANAGE_PEOPLE.IS_INVITING_USERS,
  'isInvitingUsers'
);

const isUpdateShareLinkReducer = isFetchingReducerFactory(
  MANAGE_PEOPLE.IS_UPDATING_SHARE_LINK,
  'isUpdatingShareLink'
);

/**
 * Extracts the fetch type from the an action's type
 * @param {string} actionType - An action's type.
 * @returns {string} The fetch action type like 'BASE', 'PENDING'..
 */
const getFetchType = (actionType) => actionType.split('_').pop();

function managePeopleReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MANAGE_PEOPLE.SET_REDUX_TOKENS: {
      const { tokens } = action.payload;
      return {
        ...state,
        tokens,
      };
    }
    case MANAGE_PEOPLE.TOGGLE_OPEN: {
      const { isOpen } = action.payload;
      return {
        ...state,
        isOpen,
      };
    }
    case COLLABORATOR.REMOVE.SUCCESS: {
      const {
        response: { result: removeId },
      } = action.payload;

      return {
        ...state,
        manageUsers: {
          ...state.manageUsers,
          collaborators: without(state.manageUsers.collaborators, removeId),
          totalCollaborators: clamp(
            subtract(state.manageUsers.totalCollaborators, 1),
            0,
            Infinity
          ),
        },
      };
    }
    case PENDING_COLLABORATORS.REMOVE_FROM_PROJECT.SUCCESS: {
      const {
        response: { result: removeId },
      } = action.payload;
      return {
        ...state,
        manageUsers: {
          ...state.manageUsers,
          pendingCollaborators: without(
            state.manageUsers.pendingCollaborators,
            removeId
          ),
          totalPendingCollaborators: clamp(
            subtract(state.manageUsers.totalPendingCollaborators, 1),
            0,
            Infinity
          ),
        },
      };
    }

    case MANAGE_PEOPLE.FILTER_COLLABORATORS.PENDING:
      return {
        ...state,
        manageUsers: {
          ...state.manageUsers,
          totalCollaborators: undefined,
          totalPendingCollaborators: undefined,
          fetchStatus: {
            collaborators: status.PENDING,
            pendingCollaborators: status.PENDING,
          },
        },
      };

    case MANAGE_PEOPLE.FILTER_COLLABORATORS.SUCCESS: {
      const { collaborators, pendingCollaborators } = action.payload;
      return {
        ...state,
        manageUsers: {
          collaborators: collaborators.result,
          pendingCollaborators: pendingCollaborators.result,
          totalCollaborators: get(collaborators, 'headers.total', 0),
          totalPendingCollaborators: get(
            pendingCollaborators,
            'headers.total',
            0
          ),
          fetchStatus: {
            collaborators: status.SUCCESS,
            pendingCollaborators: status.SUCCESS,
          },
        },
      };
    }

    case COLLABORATOR.GET.PENDING:
      return {
        ...state,
        manageUsers: {
          ...state.manageUsers,
          fetchStatus: {
            ...state.manageUsers.fetchStatus,
            collaborators: status.PENDING,
          },
        },
      };

    case COLLABORATOR.GET.FAILURE:
      return {
        ...state,
        manageUsers: {
          ...state.manageUsers,
          fetchStatus: {
            ...state.manageUsers.fetchStatus,
            collaborators: status.FAILURE,
          },
        },
      };

    case COLLABORATOR.GET.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        manageUsers: {
          ...state.manageUsers,
          collaborators: response.result || [],
          totalCollaborators: get(response, 'headers.total', 0),
          fetchStatus: {
            ...state.manageUsers.fetchStatus,
            collaborators: status.SUCCESS,
          },
        },
      };
    }

    case PENDING_COLLABORATORS.LIST_FOR_PROJECT.PENDING:
      return {
        ...state,
        manageUsers: {
          ...state.manageUsers,
          fetchStatus: {
            ...state.manageUsers.fetchStatus,
            pendingCollaborators: status.PENDING,
          },
        },
      };

    case PENDING_COLLABORATORS.LIST_FOR_PROJECT.FAILURE:
      return {
        ...state,
        manageUsers: {
          ...state.manageUsers,
          fetchStatus: {
            ...state.manageUsers.fetchStatus,
            pendingCollaborators: status.FAILURE,
          },
        },
      };

    case PENDING_COLLABORATORS.LIST_FOR_PROJECT.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        manageUsers: {
          ...state.manageUsers,
          pendingCollaborators: response.result || [],
          totalPendingCollaborators: get(response, 'headers.total', 0),
          fetchStatus: {
            ...state.manageUsers.fetchStatus,
            pendingCollaborators: status.SUCCESS,
          },
        },
      };
    }

    case MANAGE_PEOPLE.RESEND_INVITE.PENDING:
    case MANAGE_PEOPLE.RESEND_INVITE.FAILURE:
    case MANAGE_PEOPLE.RESEND_INVITE.SUCCESS: {
      const { email } = action.payload;
      return {
        ...state,
        resendInviteEmails: {
          ...state.resendInviteEmails,
          [email]: getFetchType(action.type),
        },
      };
    }

    case MANAGE_PEOPLE.RESET_RESEND_INVITES: {
      return {
        ...state,
        resendInviteEmails: INITIAL_STATE.resendInviteEmails,
      };
    }

    case MANAGE_PEOPLE.SET_PEOPLE_LIST_SCROLL_TOP: {
      const { scrollTop } = action.payload;
      return {
        ...state,
        peopleListScrollTop: scrollTop,
      };
    }

    default:
      return state;
  }
}

export default reduceReducers(
  managePeopleReducer,
  isInvitingUsersReducer,
  isUpdateShareLinkReducer
);

export const testExports = { getFetchType };
