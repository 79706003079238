import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import DropFilesIllustration from '@frameio/components/src/svgs/illustrations/drop-files.svg';

const Wrapper = styled(Flex).attrs({
  center: true,
  full: true,
  column: true,
})`
  background-color: ${(p) => p.theme.color.gray900};
  color: ${(p) => p.theme.color.silver};
  font-size: ${(p) => p.theme.fontSize[2]};

  p {
    margin: ${(p) => p.theme.spacing.units(2)} 0;
  }
`;

const Disclaimer = styled.div`
  color: ${(p) => p.theme.color.graphiteGray};
`;

export default function EmptyPresentationPlaceholder() {
  return (
    <Wrapper>
      <DropFilesIllustration />
      <p>No assets found</p>
      <Disclaimer>The assets in this link may have been deleted.</Disclaimer>
    </Wrapper>
  );
}
