/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';

import {
  getProjectUrl,
  getPath,
  PROJECT_LINKS_URL,
  PROJECT_PRESENTATIONS_URL,
} from 'URLs';
import { mergeQueryParams } from 'utils/router';

/**
 * Lightweight wrapper around <Link> in order to route to the right dashboard
 * @param {Object} props React props
 * @returns {ReactElement} The <Link> element
 */
const ProjectLink = React.forwardRef(
  (
    {
      folderId,
      includeSearch,
      projectId,
      shouldPreserveTab,

      // ⚠️ Don't splat this into ...rest props in NavLink below.
      dispatch,

      ...rest
    },
    ref
  ) => {
    const { search } = useLocation();
    const { path } = useRouteMatch();

    const doesCurrentPathIncludeTabs = [
      PROJECT_PRESENTATIONS_URL,
      PROJECT_LINKS_URL,
    ].includes(path);

    let url =
      doesCurrentPathIncludeTabs && shouldPreserveTab
        ? getPath(path, { projectId })
        : getProjectUrl(projectId, folderId);

    if (includeSearch) {
      const { p, d, ...queryParams } = queryString.parse(search);
      url = mergeQueryParams(url, queryParams);
    }
    return <NavLink to={url} {...rest} ref={ref} />;
  }
);

ProjectLink.propTypes = {
  /** Id of the folder to link to */
  folderId: PropTypes.string,
  /** Whether or not to include query params from window.location */
  includeSearch: PropTypes.bool,
  /** Id of the project to link to */
  projectId: PropTypes.string,
  /** Wehether to preserve the current tab. If `false`, always goes to the media tab */
  shouldPreserveTab: PropTypes.bool,
};

ProjectLink.defaultProps = {
  folderId: undefined,
  includeSearch: true,
  projectId: undefined,
  shouldPreserveTab: false,
};

export default ProjectLink;
