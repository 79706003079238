import { call, put } from 'redux-saga/effects';
import { searchUsersInAccount } from '@frameio/core/src/users/sagas';
import { searchPendingReviewersInProject } from '@frameio/core/src/pendingReviewers/sagas';
import { searchTeamsInAccount as searchTeamsInAccountSaga } from '@frameio/core/src/teams/sagas';
import { searchGroupsInAccount as searchGroupsInAccountCoreSaga } from '@frameio/core/src/groups/sagas';
import { USER_SEARCH } from './actions';

/*
  UserSearch accepts an array prop called strategies. A strategy is a function that returns a saga.
  As the user types into the search field, we fire the `executeSearchStrategies` action which calls
  the executeSearchStrategies saga, which itself calls each of the prop-provided strategies.

  The only contract to a strategy is returning a saga. In the future we may further refactor
  UserSearch to force strategies to return a value of a given shape.

  For now, the results are collated inside ConnectedUserSearch.js.

  For more information about this architecture refer to discussion at
  https://github.com/Frameio/web-client/pull/7684
*/

export function searchUsersStrategy(accountId, projectId) {
  return function* searchUsers(query) {
    const { success, failure } = yield call(
      searchUsersInAccount,
      accountId,
      query,
      { projectIdForRole: projectId, groupByRole: true }
    );

    if (success) {
      yield put({ ...success, type: USER_SEARCH.SEARCH_USER.SUCCESS });
    }

    if (failure) {
      yield put({ ...failure, type: USER_SEARCH.SEARCH_USER.FAILURE });
    }
  };
}

export function searchPendingReviewersStrategy(projectId) {
  return function* searchPendingReviewers(query) {
    const { success, failure } = yield call(
      searchPendingReviewersInProject,
      projectId,
      query
    );

    if (success) {
      yield put({
        ...success,
        type: USER_SEARCH.SEARCH_PENDING_REVIEWER.SUCCESS,
      });
    }

    if (failure) {
      yield put({
        ...failure,
        type: USER_SEARCH.SEARCH_PENDING_REVIEWER.FAILURE,
      });
    }
  };
}

export function searchTeamsStrategy(accountId) {
  return function* searchTeams(query) {
    const { success, failure } = yield call(
      searchTeamsInAccountSaga,
      accountId,
      query
    );
    if (success) {
      yield put({ ...success, type: USER_SEARCH.SEARCH_TEAM.SUCCESS });
    }

    if (failure) {
      yield put({ ...failure, type: USER_SEARCH.SEARCH_TEAM.FAILURE });
    }
  };
}

export function searchGroupsStrategy(accountId) {
  return function* searchGroups(query) {
    const { success, failure } = yield call(
      searchGroupsInAccountCoreSaga,
      accountId,
      query
    );

    if (success) {
      yield put({ ...success, type: USER_SEARCH.SEARCH_GROUP.SUCCESS });
    }

    if (failure) {
      yield put({ ...failure, type: USER_SEARCH.SEARCH_GROUP.FAILURE });
    }
  };
}
