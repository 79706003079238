import archiveStatus from '@frameio/core/src/projects/helpers';
import { ARCHIVE_PROJECT_FLOW } from './actions';

const initialState = {
  currentScreen: archiveStatus.STANDARD,
};

export default function setCurrentScreen(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case ARCHIVE_PROJECT_FLOW.SHOW_INITIAL_SCREEN:
      return {
        ...state,
        currentScreen: archiveStatus.STANDARD,
      };
    case ARCHIVE_PROJECT_FLOW.SHOW_IN_PROGRESS_SCREEN:
      return {
        ...state,
        currentScreen: archiveStatus.ARCHIVING,
      };
    case ARCHIVE_PROJECT_FLOW.SHOW_SUCCESS_SCREEN:
      return {
        ...state,
        currentScreen: archiveStatus.ARCHIVED,
      };
    default:
      return state;
  }
}
