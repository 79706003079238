import React, { useCallback } from 'react';
import { get, keys, reverse, size, indexOf, partial } from 'lodash';
import { useFormikContext } from 'formik';
import CaretSVG from '@frameio/components/src/svgs/icons/caron.svg';
import { Text, StyledIcon, StyledButton } from './styles';

export default function WatermarkBlockSelector() {
  const { values, setFieldValue } = useFormikContext();
  const activeBlockId = get(values, 'activeBlockId');
  const watermarkBlocks = get(values, 'watermark_blocks');
  const setActiveBlockId = partial(setFieldValue, 'activeBlockId');
  const totalBlockCount = size(watermarkBlocks);

  const nextArrayValue = (arr, currentValue) =>
    arr[indexOf(arr, currentValue) + 1] || arr[0];

  const selectNextBlock = useCallback(() => {
    setActiveBlockId(nextArrayValue(keys(watermarkBlocks), activeBlockId));
  }, [activeBlockId, setActiveBlockId, watermarkBlocks]);

  const selectPreviousBlock = useCallback(() => {
    setActiveBlockId(
      nextArrayValue(reverse(keys(watermarkBlocks)), activeBlockId)
    );
  }, [activeBlockId, setActiveBlockId, watermarkBlocks]);

  return (
    <React.Fragment>
      {totalBlockCount > 1 && (
        <StyledButton
          compact
          text
          icon
          onClick={selectPreviousBlock}
          gutter={[0, 1, 0, 0]}
        >
          <StyledIcon color="graphiteGray" as={CaretSVG} rotate={90} />
        </StyledButton>
      )}
      <Text variant="bodyM" color="graphiteGray">
        {indexOf(keys(watermarkBlocks), activeBlockId) + 1} of {totalBlockCount}{' '}
        {totalBlockCount > 1 ? 'blocks' : 'block'}
      </Text>
      {totalBlockCount > 1 && (
        <StyledButton
          compact
          text
          icon
          onClick={selectNextBlock}
          gutter={[0, 0, 0, 1]}
        >
          <StyledIcon color="graphiteGray" as={CaretSVG} rotate={270} />
        </StyledButton>
      )}
    </React.Fragment>
  );
}
