import Raven from 'raven-js';

let errorState;
export default function logNullishAccountForProjectIdError({
  accountForProjectId,
  presentation,
  presentationId,
  team,
}) {
  const capturedPresentationId = presentationId || presentation?.id;
  if (!accountForProjectId?.id) {
    /*
     * BUGS-1331
     * Log an error everytime the account id is undefined.
     * */
    Raven.captureException('BUGS-1331 ', {
      level: 'error',
      extra: {
        state: 'error',
        accountForProjectId,
        presentation,
        presentationId,
        team,
      },
      tags: { jira_ticket: 'BUGS-1331' },
    });
    if (capturedPresentationId) {
      errorState = { capturedPresentationId };
    }
  } else if (
    errorState?.capturedPresentationId &&
    errorState.capturedPresentationId === capturedPresentationId
  ) {
    /*
     * BUGS-1331
     * Assuming the bug can be caused when the user either doesn't have
     * permission to share a presentation or the data was just not there yet,
     * this might help spot it out.
     * */
    Raven.captureException('BUGS-1331 ', {
      level: 'error',
      extra: {
        state: 'received-data',
        accountForProjectId,
        presentation,
        presentationId,
        team,
      },
      tags: { jira_ticket: 'BUGS-1331' },
    });
  }
}
