import { connect } from 'react-redux';

import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import {
  accountIdsForAccountSwitcherSelector,
  versionForAccountSwitcherSelector,
} from '../selectors';
import AccountSwitcherDropdown from './AccountSwitcherDropdown';

/**
 * @param   {Object} state - Redux store state.
 * @returns {Object} - Maps the redux store state to populate props for the AccountSwitcher.
 */
const mapStateToProps = (state, { currentAccountId: accountId }) => {
  const accountIds = accountIdsForAccountSwitcherSelector(state) || [];
  const versions = versionForAccountSwitcherSelector(state) || [];
  const currentAccount = accountEntitySelector(state, { accountId }) || {};

  return {
    accountIds,
    currentAccount,
    versions,
  };
};

export default connect(mapStateToProps)(AccountSwitcherDropdown);

export const testExports = {
  mapStateToProps,
};
