/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import {
  paginatedListAllResultsSelector,
  paginatedListMetadataSelector,
  shouldFetchListPageSelector,
} from '@frameio/core/src/shared/selectors';

import { currentFolderOrProjectRootIdSelector } from 'selectors/folders';
import {
  isSelectingItemsForReviewLinkSelector,
  reviewLinkEditorAssetIdsSelector,
} from 'components/ReviewLinkEditor/selectors';
import { isFetchingSelector } from './ManageVersionStackModal/selectors';
import SORT_OPTIONS from './ProjectAssets/sortOptions';
/**
 * Gets the state of store relevant to the ProjectContainer
 * @param {Object} state - Redux store state.
 * @returns {Object} The projectContainer slice.
 */
export const projectContainerSelector = (state) => state.projectContainer;

/**
 * Gets the Id of the project that was most recently visited.
 * @param {Object} state - Redux store state.
 * @returns {string} The previous project id.
 */
export const projectIdSelector = (state) =>
  projectContainerSelector(state).projectId;

/**
 * Gets the project entity of the most recently visited project.
 */
export const projectSelector = createSelector(
  (state) => state,
  projectIdSelector,
  (state, projectId) => projectEntitySelector(state, { projectId })
);

/**
 * Gets the Id of the project that was most recently visited.
 * @param {Object} state - Redux store state.
 * @returns {string} The previous project id.
 */
export const projectSelectedAssetIdsSelector = (state) =>
  projectContainerSelector(state).selectedAssetIds;

/**
 * Gets the Id of the folder that was most recently visited.
 * @param {Object} state - Redux store state.
 * @returns {string} The previous folder id.
 */
export const folderIdSelector = (state) =>
  projectContainerSelector(state).folderId;

/**
 * Gets the folder entity of the most recently visited folder.
 */
export const folderSelector = createSelector(
  (state) => state,
  folderIdSelector,
  (state, assetId) => assetEntitySelector(state, { assetId })
);

/**
 * Gets ids for assets that are currently selected in the file navigator.
 * @param {Object} state - Redux store state.
 * @returns {string[]} Array of asset ids.
 */
export const selectedAssetIdsSelector = createSelector(
  isSelectingItemsForReviewLinkSelector,
  reviewLinkEditorAssetIdsSelector,
  projectSelectedAssetIdsSelector,
  (
    isSelectingItemsForReviewLink,
    reviewLinkAssetIds,
    projectSelectedAssetIds
  ) =>
    isSelectingItemsForReviewLink ? reviewLinkAssetIds : projectSelectedAssetIds
);

/**
 * Gets the assets slice containing the paginated list data for the dashboard assets.
 * @param {Object} state - Redux store state.
 * @returns {Object} Assets state.
 */
export const assetsSelector = (state) => projectContainerSelector(state).assets;

/**
 * Gets the sort by value for the dashboard assets.
 * @param {Object} state - Redux store state.
 * @returns {string} One of the values of SORT_OPTIONS.
 */
export const assetsSortBySelector = (state) =>
  projectContainerSelector(state).assetsSortBy;

/**
 * Gets the value of whether the dashboard assets should be sorted descending.
 * @param {Object} state - Redux store state.
 * @returns {boolean} True if should sort descending.
 */
export const assetsSortDescendingSelector = (state) =>
  projectContainerSelector(state).areAssetsSortDescending;

/**
 * Gets the set of options to pass into the fetch action, including sort by and sort descending.
 * @param {Object} state - Redux store state.
 * @returns {Object} The fetch options.
 */
export const assetsRequestOptionsSelector = createSelector(
  assetsSortBySelector,
  assetsSortDescendingSelector,
  (sortByField, sortDescending) => ({
    includeFolderPreview: true,
    pageSize: 20,
    // When custom sort is selected, we always want to send undefined to the server
    // as `custom` is the default sort value.
    sortBy: sortByField === SORT_OPTIONS.CUSTOM.value ? undefined : sortByField,
    sortDescending,
  })
);

/**
 * Gets the metadata for the dashboard assets, including total count and total page count.
 * @param {Object} state - Redux store state.
 * @returns {Object} The metadata object.
 */
export const assetsMetadataSelector = createSelector(
  assetsSelector,
  paginatedListMetadataSelector
);

/** Whether we can distinguish between unfetched assets and no assets. */
export const isEmptyStateSelector = createSelector(
  assetsMetadataSelector,
  ({ total }) => total === 0
);

/**
 * Counts the total assets in the folder so that we know how many placeholders to render.
 * Does not use the usage object since that is recursive.
 * @param {Object} state
 * @param {Object} ownProps - Props of the component.
 * @param {Object} ownProps.folder - Current folder entity.
 * @returns {Number} Count of assets.
 */
export const totalAssetCountSelector = createSelector(
  (state) => state,
  folderSelector,
  assetsMetadataSelector,
  (state, folder, { total }) => {
    if (total !== undefined) {
      return Math.max(0, total);
    }
    if (folder) {
      return Math.max(0, folder.item_count || 0);
    }
    return 0;
  }
);

/**
 * Whether or not a fetch for the given asset page should happen.
 * @param {Object} state - Redux store state.
 * @returns {boolean} True if should fetch. Right now this only returns false if there's an
 * in-flight fetch of the same page.
 */
export const shouldFetchAssetsPageSelector = (state, { page }) => {
  const assetsState = assetsSelector(state);
  return shouldFetchListPageSelector(assetsState, { page });
};

const PLACEHOLDER_INDEX_SEPARATOR = '@';
/**
 * Helper function to get the placeholder id of an asset that will be uploaded into a given `index`
 * of a folder. When we get rid of custom sort, we'll need to figure out a way to make a unique
 * id from the properties of a placeholder item.
 * @param {string} folderId - Id of the folder into which the asset will be uploaded.
 * @param {number} index - Index in the folder indicating the position into which the asset will
 * be uploaded.
 * @returns {string} The generated id.
 */
export function getPlaceholderAssetId(folderId, index) {
  return `${folderId}${PLACEHOLDER_INDEX_SEPARATOR}${index}`;
}

/**
 * Helper function to test if a given placeholder belongs to a specific folder.
 * @param {string} placeholderId - Id of the placeholder.
 * @param {string} folderId - Id of the folder to test on.
 * @returns {boolean}
 */
export function isPlaceholderInFolder(placeholderId, folderId) {
  const paths = placeholderId.split(PLACEHOLDER_INDEX_SEPARATOR);
  return paths.slice(0, -1).join(PLACEHOLDER_INDEX_SEPARATOR) === folderId;
}

/**
 * Gets the state slice of the placeholder asssets.
 * @param {Object} - Redux store state.
 * @returns {Object} - Object hash of placeholder id to placeholder asset.
 */
export const placeholderAssetsSelector = (state) =>
  projectContainerSelector(state).placeholderAssets;

/**
 * Gets a single placeholder asset by its id.
 * @param {Object} state - Redux store state.
 * @param {Object} ownProps - Props to call the seletor with.
 * @param {string} ownProps.assetId - Placeholder asset id
 * @returns {Object} - Placeholder asset, if any.
 */
export const assetOrPlaceholderAssetSelector = (state, { assetId }) =>
  assetEntitySelector(state, { assetId }) ||
  placeholderAssetsSelector(state)[assetId];

/**
 * Gets the array of placeholder assets that belong to the current folder.
 * @param {Object} - Redux store state.
 * @returns {Asset[]} - Array of placeholder assets.
 */
export const placeholderAssetsForFolderSelector = createSelector(
  placeholderAssetsSelector,
  currentFolderOrProjectRootIdSelector,
  (placeholders, folderId) =>
    Object.keys(placeholders)
      .filter((placeholderId) => isPlaceholderInFolder(placeholderId, folderId))
      .map((key) => placeholders[key])
);

/**
 * Gets the array of asset and placeholder ids.
 * Note: This selector returns ‘undefined‘ ids for any assets
 * that haven't been fetched via pagination.
 * @param {Object} - Redux store state.
 * @returns {string[]} - Array of asset or placeholder ids.
 */
export const assetIdsSelector = createSelector(
  assetsSelector,
  paginatedListAllResultsSelector
);

/**
 * Gets the array of only fetched asset and placeholder ids.
 * @param {Object} - Redux store state.
 * @returns {string[]} - Array of fetched asset ids.
 */
export const fetchedAssetIdsSelector = createSelector(
  assetIdsSelector,
  (assetIds) => assetIds.filter((id) => !!id)
);

/**
 * Gets the asset or placeholder entity at a given location on the page.
 * @param {Object} - Redux store state.
 * @returns {Asset} - Asset or placeholder entity.
 */
export function assetAtCellSelector(state, { cellIndex }) {
  const assetId = assetIdsSelector(state)[cellIndex];
  return assetOrPlaceholderAssetSelector(state, { assetId });
}

/**
 * Gets the asset and placeholder entities of all fetched pages in the current folder.
 * @param {Object} state - Redux store state.
 * @returns {Asset[]} Array of hydrated assets.
 */
export const assetEntitiesInFolderSelector = createSelector(
  (state) => state,
  assetIdsSelector,
  placeholderAssetsSelector,
  (state, assetIds, placeholderAssets) =>
    assetIds.map(
      (assetId) =>
        assetId &&
        (placeholderAssets[assetId] || assetEntitySelector(state, { assetId }))
    )
);

/**
 * Gets the lowest index for all assets in the current folder.
 * @param {Object} state - Redux store state.
 * @returns {number} Lowest index for the current folder.
 */
export const assetsMinIndexSelector = createSelector(
  assetEntitiesInFolderSelector,
  (assets) =>
    assets.length
      ? Math.min(
          ...assets.filter((asset) => asset).map((asset) => asset.index || 0)
        )
      : 0
);

/**
 * Gets the index at which new assets should be created.
 */
export const indexToCreateAssetsSelector = createSelector(
  assetsMinIndexSelector,
  (minIndex) =>
    Math.floor(minIndex) === minIndex ? minIndex - 1 : Math.floor(minIndex)
);

/**
 * Gets the view type for assets.
 * @param {Object} state - Redux store state.
 * @returns {string} View type value.
 */
export const assetsViewTypeSelector = (state) =>
  projectContainerSelector(state).assetsViewType;

/**
 * Gets the scroll top value for the assets grid.
 * @param {Object} state - Redux store state.
 * @returns {number} Scroll top value.
 */
export const assetsScrollTopSelector = (state) =>
  projectContainerSelector(state).assetsScrollTop;

/**
 * A key used to trigger the flip animation.
 * @param {Object} state - Redux store state.
 * @returns {number} A number that changes when the animation should occur.
 */
export const assetsFlipKeySelector = (state) => assetsSelector(state).flipKey;

export const isManageVersionStackModalFetchingSelector = createSelector(
  projectContainerSelector,
  isFetchingSelector
);

/**
 * Whether the quicklook folder size is being fetched.
 * @param {Object} state - Redux store state.
 * @returns {boolean} `true` if a fetch is pending.
 */
export const isCalculatingQuicklookFolderSizeSelector = (state) =>
  projectContainerSelector(state).isCalculatingQuicklookFolderSize;

export const testExports = {
  PLACEHOLDER_INDEX_SEPARATOR,
};
