import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const LIFETIME_FILE_LIMIT_REACHED_FLOW = generateActionTypes(
  'LIFETIME_FILE_LIMIT_REACHED_FLOW',
  ['START', 'CONTINUE', 'END']
);

export const startFlow = createAction(
  LIFETIME_FILE_LIMIT_REACHED_FLOW.START,
  () => ({})
);

export const continueFlow = createAction(
  LIFETIME_FILE_LIMIT_REACHED_FLOW.CONTINUE,
  () => ({})
);

export const endFlow = createAction(
  LIFETIME_FILE_LIMIT_REACHED_FLOW.END,
  () => ({})
);
