import { takeEvery, race, put, take, select } from 'redux-saga/effects';
import {
  SUBSCRIPTION_BY_ACCOUNT,
  getSubscriptionByAccount,
} from '@frameio/core/src/subscriptions/actions';
import { SUBSCRIPTION } from 'actions/subscriptions';

import { subscriptionEntityByAccountIdSelector } from 'selectors/subscriptions';

/**
 * Fetches a subscription, waiting for it to return, regardless of the result.
 * @param {string} accountId - Id of the project to fetch
 * @returns {Object} Returns the subscription if it fetched successfully.
 */
export function* getAndWaitForSubscriptionByAccountId(accountId) {
  const getSubscriptionAction = getSubscriptionByAccount(accountId);
  yield put(getSubscriptionAction);
  yield race({
    success: take(SUBSCRIPTION_BY_ACCOUNT.GET.SUCCESS),
    failure: take(SUBSCRIPTION_BY_ACCOUNT.GET.FAILURE),
  });
  return yield select(subscriptionEntityByAccountIdSelector, accountId);
}

export default [
  takeEvery(SUBSCRIPTION.GET.BASE, ({ payload: { accountId } }) =>
    getAndWaitForSubscriptionByAccountId(accountId)
  ),
];
