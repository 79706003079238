import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import AddCreditCardWithPlanSummary from './AddCreditCardWithPlanSummary';

const mapDispatchToProps = {
  closeModal,
};

export default connect(
  null,
  mapDispatchToProps
)(AddCreditCardWithPlanSummary);
