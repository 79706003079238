import React from 'react';
import Media from 'react-media';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Flex from 'styled-flex-component';
import { MEDIUM_UP, SMALL } from 'utils/mediaQueries';
import { buttonReset } from '@frameio/components/src/mixins';
import { color as themeColor } from '@frameio/components/src/theme/darkTheme';
import Button from '@frameio/components/src/styled-components/Button';
import DynamicColorIcon from '@frameio/components/src/components/DynamicColorIcon';
import LinkIcon from '@frameio/components/src/svgs/icons/16/link.svg';
import DropArrowIcon from '@frameio/components/src/svgs/icons/caron.svg';
import { formatShortDate } from '@frameio/components/src/utils/datetimeHelpers';
import { Tooltip, Select, MenuRadio } from '@frameio/vapor';
import LinkAccessDropdownButton from '../ReviewLinkEditor/LinkAccessDropdownButton';

export const LINK_ACCESS_OPTIONS = {
  PUBLIC: 'public',
  PRIVATE: 'private',
  DISABLED: 'disabled',
};

const LinkAccessContainer = styled(Flex)`
  height: ${(p) => p.theme.spacing.units(5)};
  border: 1px solid ${(p) => p.theme.color.silver};
  border-radius: ${(p) => p.theme.radius.default};
  font-size: ${(p) => p.theme.fontSize[2]};

  @media ${SMALL} {
    flex-direction: row-reverse;
  }
`;

const LinkWrapper = styled(Flex)`
  overflow: hidden;
  width: 100%;
`;

const CopyLinkContainer = styled(Flex)`
  border-right: 1px solid ${(p) => p.theme.color.silver};
  height: 100%;
  flex: 1;
  min-width: 0;
  padding-right: ${(p) => p.theme.spacing.tiny};
  color: ${({ isDisabled, theme }) =>
    isDisabled ? `${theme.color.red500}` : `${theme.color.coolBlack}`};
  user-select: ${({ isDisabled }) => (isDisabled ? 'none' : 'text')};

  .a:link, // TODO: determine if this line from #8032 was a typo
  a:visited,
  a:active {
    color: ${(p) => p.theme.color.brand};
  }

  a:hover {
    text-decoration: underline};
  }

  @media ${SMALL} {
    border-right: none;
    border-left: 1px solid ${(p) => p.theme.color.silver};
    padding: 0;

    ${Button} {
      color: ${(p) => p.theme.color.brand};
      /* The definition of <Button />'s :hover state is way overspecified in web-components, so
      we raise the specificity of the rule below in order to not render a hover state on mobile
      viewports on tap */
      &&& {
        &:hover {
          background-color: tranparent;
        }
      }
    }
  }
`;

const CopyLinkButtonContainer = styled(Flex).attrs({
  alignCenter: true,
})`
  margin-left: auto;

  @media ${SMALL} {
    margin: auto;
  }
`;

const StyledMenuRadio = styled(MenuRadio)`
  height: auto;
`;

const LinkAccessDropdownContainer = styled(Flex).attrs({
  center: true,
})`
  height: 100%;
  width: ${(p) => p.theme.spacing.units(18)};
`;

const PrivacyContainer = styled.button`
  ${buttonReset()}
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  border-bottom-right-radius: ${(p) => p.theme.radius.default};
  border-top-right-radius: ${(p) => p.theme.radius.default};
  color: ${(p) => p.theme.color.coolBlack};
  font-weight: 600;

  &:hover {
    background-color: ${(p) => p.theme.color.coldWhite};
  }
`;

const MobileLinkIcon = styled(LinkIcon)`
  margin-right: ${(p) => p.theme.spacing.micro};
`;

const PrivacyIconContainer = styled.div`
  height: ${(p) => p.theme.spacing.units(2)};
  margin: ${(p) => p.theme.spacing.micro};
  margin-left: ${(p) => p.theme.spacing.small};
`;

const PrivacyTextContainer = styled.div`
  height: 14px;
`;

const PrivacyDropArrowIcon = styled(DropArrowIcon)`
  margin-right: ${(p) => p.theme.spacing.tiny};
  color: ${(p) => p.theme.color.gray};
`;

const StyledLinkText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  /**
   * placing the padding on the <a> tag prevents the text in the
   *link from being cropped. Which happens with lowercase "j"s or "g"s
   */
  padding: ${(p) => p.theme.spacing.small} 0px ${(p) => p.theme.spacing.small}
    ${(p) => p.theme.spacing.small};
`;

const getCopyableUrl = (shortUrl, isExpired, expiresAt) => {
  const { hostname, pathname } = new URL(shortUrl);
  const expiryText = expiresAt
    ? `Expired on ${formatShortDate(expiresAt)}` // TODO: remove legacy variant
    : 'Link expired';
  return isExpired ? expiryText : hostname + pathname;
};

const TooltipContent = () => (
  <React.Fragment>
    This link is currently only accessible <br /> to the users in this project
  </React.Fragment>
);

const tooltipDetails =
  'Add new users by inviting them below or changing the link to public';

export default function LinkAccess({
  canSharePublicly,
  copyShortUrlToClipboard,
  currentMode,
  expiresAt,
  isExpired,
  onAccessModeSelect,
  secureSharingEnabled,
  shortUrl,
  showSecureSharingTooltip,
}) {
  const copyableUrl = getCopyableUrl(shortUrl, isExpired, expiresAt);

  const isDisabled = currentMode === LINK_ACCESS_OPTIONS.DISABLED || isExpired;
  let privacyIcon;
  let privacyIconColor;
  let privacyTitle;
  switch (currentMode) {
    case LINK_ACCESS_OPTIONS.PUBLIC:
      privacyIcon = 'globe';
      privacyIconColor = themeColor.success;
      privacyTitle = 'Public';
      break;
    case LINK_ACCESS_OPTIONS.PRIVATE:
      privacyIcon = 'person';
      privacyIconColor = themeColor.alert;
      privacyTitle = 'Invite only';
      break;
    case LINK_ACCESS_OPTIONS.DISABLED:
      privacyIcon = 'x_small';
      privacyIconColor = themeColor.error;
      privacyTitle = 'Disabled';
      break;
    default:
      break;
  }

  // https://frame-io.atlassian.net/browse/ENT-1748
  // https://github.com/facebook/react/issues/3907#issuecomment-363948471
  // This fixes a bug in which pressing enter to add a user inside UserSearch would also trigger
  // the submit button inside of this component to display a "Link Copied!" message. We only want
  // to call this logic if the Button is directly interacted with via a click.
  const safelyTriggerCopyUrlToClipboard = (event) => {
    const userDidPressEnterKey = event.detail === 0;
    if (userDidPressEnterKey) {
      return;
    }
    copyShortUrlToClipboard(shortUrl);
  };

  return (
    <LinkAccessContainer>
      <CopyLinkContainer isDisabled={isDisabled}>
        <Media query={SMALL}>
          <CopyLinkButtonContainer>
            {isExpired ? (
              <React.Fragment>Link expired</React.Fragment>
            ) : (
              <Tooltip
                disabled={!showSecureSharingTooltip}
                placement="top"
                title={<TooltipContent />}
                details={tooltipDetails}
                variant="dark"
              >
                <Button
                  text
                  disabled={isDisabled}
                  onClick={safelyTriggerCopyUrlToClipboard}
                >
                  <MobileLinkIcon />
                  Copy link
                </Button>
              </Tooltip>
            )}
          </CopyLinkButtonContainer>
        </Media>
        <Media query={MEDIUM_UP}>
          {isDisabled ? (
            <StyledLinkText>{copyableUrl}</StyledLinkText>
          ) : (
            <Tooltip
              disabled={!showSecureSharingTooltip}
              placement="top"
              title={<TooltipContent />}
              details={tooltipDetails}
              variant="dark"
            >
              <LinkWrapper>
                <StyledLinkText
                  as="a"
                  href={shortUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {copyableUrl}
                </StyledLinkText>
                <CopyLinkButtonContainer>
                  <Button text icon onClick={safelyTriggerCopyUrlToClipboard}>
                    <LinkIcon />
                  </Button>
                </CopyLinkButtonContainer>
              </LinkWrapper>
            </Tooltip>
          )}
        </Media>
      </CopyLinkContainer>

      <LinkAccessDropdownContainer data-testid="link-access-launcher">
        <Select
          shouldUsePortal
          data-test-id="select-menu"
          value={currentMode}
          content={
            <React.Fragment>
              {canSharePublicly && (
                <StyledMenuRadio name={LINK_ACCESS_OPTIONS.PUBLIC}>
                  <LinkAccessDropdownButton mode={LINK_ACCESS_OPTIONS.PUBLIC} />
                </StyledMenuRadio>
              )}
              {secureSharingEnabled && (
                <StyledMenuRadio name={LINK_ACCESS_OPTIONS.PRIVATE}>
                  <LinkAccessDropdownButton
                    mode={LINK_ACCESS_OPTIONS.PRIVATE}
                  />
                </StyledMenuRadio>
              )}
              <StyledMenuRadio name={LINK_ACCESS_OPTIONS.DISABLED}>
                <LinkAccessDropdownButton mode={LINK_ACCESS_OPTIONS.DISABLED} />
              </StyledMenuRadio>
            </React.Fragment>
          }
          onChange={onAccessModeSelect}
        >
          <PrivacyContainer>
            <PrivacyIconContainer>
              <DynamicColorIcon
                kind={privacyIcon}
                color={privacyIconColor}
                size={16}
              />
            </PrivacyIconContainer>
            <PrivacyTextContainer>{privacyTitle}</PrivacyTextContainer>
            <PrivacyDropArrowIcon />
          </PrivacyContainer>
        </Select>
      </LinkAccessDropdownContainer>
    </LinkAccessContainer>
  );
}

LinkAccess.propTypes = {
  canSharePublicly: PropTypes.bool.isRequired,
  currentMode: PropTypes.string.isRequired,
  copyShortUrlToClipboard: PropTypes.func.isRequired,
  isExpired: PropTypes.bool.isRequired,
  onAccessModeSelect: PropTypes.func.isRequired,
  secureSharingEnabled: PropTypes.bool.isRequired,
  shortUrl: PropTypes.string.isRequired,
  showSecureSharingTooltip: PropTypes.bool,
};
