import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { sendConfirmationEmail } from '@frameio/core/src/users/actions';
import { currentUserSelector } from 'selectors/users';
import Button from '@frameio/components/src/styled-components/Button';
import Dialog from '@frameio/components/src/styled-components/Dialog';
import { showErrorToast as showErrorToastAction } from 'actions/toasts';
import DialogModal from 'components/Dialog/DialogModal';
import { LOGOUT_URL } from 'URLs';
import { FAILURE_REASONS } from 'components/AuthGate/failureReasons';

function mapStateToProps(state, { email, reason }) {
  let emailAddress;
  if (reason === FAILURE_REASONS.EMAIL_UNCONFIRMED) {
    const currentUser = currentUserSelector(state);
    emailAddress = currentUser?.lockedOutEmail;
  } else {
    emailAddress = email;
  }

  return {
    email: emailAddress,
    isResending: state.unconfirmedEmailLockout.isResending,
  };
}

const mapDispatchToProps = {
  sendEmail: sendConfirmationEmail,
  showErrorToast: showErrorToastAction,
};

// TODO: Replace with `Button.as(Link).attrs({ text: true })` after upgrading to
// SC v4+, when the `to` prop can be correctly passed to `Link`
const LogOutLink = styled(Link)`
  color: ${(p) => p.theme.color.gray};
  font-weight: bold;
  ${(p) => p.theme.fontStyle.bodyM};
  &:hover {
    text-decoration: underline;
  }
`;

const StyledDialog = styled(Dialog)`
  p + p {
    margin-top: ${(p) => p.theme.spacing.small};
  }
`;

export class UnconfirmedEmailLockout extends PureComponent {
  resendEmail = () => {
    const { sendEmail, email } = this.props;
    sendEmail(email);
  };

  securityMessage = () => {
    const { email, reason } = this.props;

    if (reason === FAILURE_REASONS.EMAIL_UNCONFIRMED) {
      return (
        <p>
          We care about your security. To ensure that you are{' '}
          <strong>{email}</strong>, please click on the link we sent to you over
          email.
        </p>
      );
    }

    return (
      <p>
        We care about your security. To ensure that you are the rightful owner
        and to continue accessing this account, please click on the link in the
        email we sent to <strong>{email}</strong>.
      </p>
    );
  };

  render() {
    const { isResending } = this.props;

    return (
      <div className="unconfirmed-email-lockout">
        <DialogModal isOpen>
          <StyledDialog
            header="Please confirm your email"
            primaryButton={
              <Button
                onClick={this.resendEmail}
                isWaiting={isResending}
                showSpinnerOnly
              >
                Resend
              </Button>
            }
            secondaryButton={<LogOutLink to={LOGOUT_URL}>Log out</LogOutLink>}
          >
            {this.securityMessage()}
            <p>
              You can resend the email using the button below. It may take up to
              several minutes to arrive. If you don’t see it, please check your
              spam folder.
            </p>
          </StyledDialog>
        </DialogModal>
      </div>
    );
  }
}

UnconfirmedEmailLockout.propTypes = {
  email: PropTypes.string.isRequired,
  sendEmail: PropTypes.func.isRequired,
  isResending: PropTypes.bool,
  reason: PropTypes.string,
};
UnconfirmedEmailLockout.defaultProps = {
  isResending: false,
  reason: '',
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconfirmedEmailLockout);

export const testExports = {
  Dialog: StyledDialog,
  mapStateToProps,
};
