import { connect } from 'react-redux';
import { userEntitySelector } from '@frameio/core/src/users/selectors';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import UserPill from '@frameio/components/src/styled-components/UserPill';

export function mapStateToProps(state, { accountId }) {
  const account = accountEntitySelector(state, { accountId }) || {};

  return {
    user: userEntitySelector(state, { userId: account.owner_id }),
  };
}

export default connect(mapStateToProps)(UserPill);
