import React from 'react';
import { createSelector } from 'reselect';
import Fuse from 'fuse.js';
import styled from 'styled-components';
import { sortBy, get } from 'lodash';
import { type as assetType } from '@frameio/core/src/assets/helpers/constants';
import {
  searchFiltersForAccountSelector,
  SEARCH_FILTER_OPERATORS,
} from '@frameio/core/src/search/selectors';
import {
  paginatedListAllResultsSelector,
  paginatedListTotalCountSelector,
  paginatedListIsFetchingPageOneSelector,
  shouldFetchListPageSelector,
} from '@frameio/core/src/shared/selectors';
import {
  SYSTEM_FIELD_IDS,
  FIELD_TYPES,
  systemFieldSelector,
} from '@frameio/core/src/systemFields/selectors';

import StatusLabel from 'components/AssetWorkflowStatus/AssetWorkflowStatusLabel';
import { PREFIX_UNIT_MAP } from 'shared/filesizeHelpers';
import { currentAccountSelector } from 'selectors/accounts';
import { projectsForCurrentAccountSelector } from 'selectors/projects';
import { teamsInCurrentAccountWhereCurrentUserIsMemberSelector } from 'selectors/teams';
import accountContainerSelector from '../selectors';

const {
  CREATOR_NAME,
  FILE_SIZE,
  FILE_TYPE,
  UPLOADED_COMPLETED_AT,
  IS_ARCHIVED,
  IS_DELETED,
  IS_PRIVATE,
  MEDIA_TYPE,
  PROJECT_ID,
  STATUS,
  TEAM_ID,
} = SYSTEM_FIELD_IDS;

const {
  EQUAL,
  MATCH,
  LESS_THAN,
  LESS_THAN_OR_EQUAL,
  GREATER_THAN,
  GREATER_THAN_OR_EQUAL,
} = SEARCH_FILTER_OPERATORS;

const searchSelector = (state) => accountContainerSelector(state).search;

export default searchSelector;

const searchResultsSelector = (state) => searchSelector(state).results;

export const resultIdsSelector = createSelector(
  searchResultsSelector,
  paginatedListAllResultsSelector
);

export function shouldFetchSearchPageSelector(state, { page }) {
  const slice = searchResultsSelector(state);
  return shouldFetchListPageSelector(slice, { page, canRefetch: true });
}

export const totalResultCountSelector = createSelector(
  searchResultsSelector,
  paginatedListTotalCountSelector
);

export const isSearchingFirstPageSelector = createSelector(
  searchResultsSelector,
  paginatedListIsFetchingPageOneSelector
);

const FILTER_NAME_MAP = {
  [CREATOR_NAME]: 'Uploader',
  [FILE_SIZE]: 'Size',
  [FILE_TYPE]: 'File type',
  [UPLOADED_COMPLETED_AT]: 'Date uploaded',
  [IS_ARCHIVED]: 'Archived',
  [IS_DELETED]: 'Deleted',
  [IS_PRIVATE]: 'Private',
  [MEDIA_TYPE]: 'Type',
  [PROJECT_ID]: 'Project',
  [STATUS]: 'Status',
  [TEAM_ID]: 'Team',
};

// The API does not formally support a `between` operator, but accepts an array
// of conditions on any filter name, and returns results that meet *ALL* of
// the conditions. So we avail a `between` operator to the UI and construct
// that array when this operator is applied.
export const BETWEEN = 'between';

const FIELDS_WITH_BETWEEN = [UPLOADED_COMPLETED_AT];

export const OPERATOR_NAMES = {
  [UPLOADED_COMPLETED_AT]: {
    [EQUAL]: 'is on',
    [MATCH]: 'is on',
    [LESS_THAN]: 'is before',
    [LESS_THAN_OR_EQUAL]: 'is on or before',
    [GREATER_THAN]: 'is after',
    [GREATER_THAN_OR_EQUAL]: 'is on or after',
    [BETWEEN]: 'is between',
  },
  default: {
    [EQUAL]: 'is',
    [MATCH]: 'is',
    [LESS_THAN]: 'is less than',
    [LESS_THAN_OR_EQUAL]: 'is less than or equal to',
    [GREATER_THAN]: 'is greater than',
    [GREATER_THAN_OR_EQUAL]: 'is greater than or equal to',
    [BETWEEN]: 'is between',
  },
};

const Option = styled.span`
  white-space: nowrap;
`;

export const DEFAULT_OPERATOR = {
  id: EQUAL,
  name: <Option>{OPERATOR_NAMES.default[EQUAL]}</Option>,
};

const SentenceCase = styled(Option)`
  ::first-letter {
    text-transform: capitalize;
  }
`;

const FIELD_ID_UNITS_MAP = {
  filesize: {
    // Sort these from smallest prefix to largest for better UX.
    options: Object.keys(PREFIX_UNIT_MAP).reverse(),
    getName: (option) => (option ? `${option}B` : 'Bytes'),
  },
};

function createFuseSearchFunction(entities, keys = ['name']) {
  const fuse = new Fuse(entities, {
    threshold: 0.3,
    tokenize: true,
    location: 0,
    distance: 150,
    maxPatternLength: Math.max(
      5,
      ...entities.map((entity) => entity.name.length)
    ),
    minMatchCharLength: 1,
    keys,
  });
  return (query) => (query ? fuse.search(query) : entities);
}

export const teamsFuseSelector = createSelector(
  teamsInCurrentAccountWhereCurrentUserIsMemberSelector,
  createFuseSearchFunction
);

export const projectsFuseSelector = createSelector(
  projectsForCurrentAccountSelector,
  createFuseSearchFunction
);

/**
 * Transforms SytemField entities into a more normalized format, so that
 * displaying them in the FilterMenu is a lot more sane and simpler. Operators
 * and values are in the format `{ id: string, name: node }` So the serializble
 * string (`id`) used can be kept separate from the presentational display
 * (`name`).
 * Also does any filter-specific transformations here.
 */
export const searchFieldSelector = createSelector(
  systemFieldSelector,
  teamsInCurrentAccountWhereCurrentUserIsMemberSelector,
  projectsForCurrentAccountSelector,
  (field, teams, projects) => {
    if (!field) return undefined;

    const { id, type, meta, operators } = field;
    const isBoolean = type === FIELD_TYPES.BOOLEAN;
    const isStatus = id === STATUS;
    const isType = id === MEDIA_TYPE;
    const isTeam = id === TEAM_ID;
    const isProject = id === PROJECT_ID;
    const hasEntityTypeahead = isTeam || isProject;

    let options;

    if (isTeam) {
      options = teams;
    } else if (isProject) {
      options = projects;
    } else if (isStatus) {
      options = meta.options.map((option) => ({
        id: option,
        name: <StatusLabel status={option} useLargeIndicator />,
      }));
    } else {
      options = get(meta, 'options', []);

      if (isType) {
        // Merge the MEDIA_TYPE and ASSET_TYPE filters into one
        options = [assetType.FOLDER, ...options].sort();
      } else if (isBoolean) {
        options = ['true', 'false'];
      }

      options = options.map((option) => ({
        id: option,
        name: <SentenceCase>{option}</SentenceCase>,
      }));
    }

    // `match` and `eq` do basically the same thing, but match is less strict about
    // the value, and will actually return us results. So when we see `match` let's
    // proritise that over `eq`.
    let normalizedOperators = operators.some((operator) => operator === MATCH)
      ? operators.filter((operator) => operator !== EQUAL)
      : operators;

    if (FIELDS_WITH_BETWEEN.includes(id)) {
      normalizedOperators = [BETWEEN, ...normalizedOperators];
    }

    return {
      ...field,
      name: FILTER_NAME_MAP[field.id],
      operators: normalizedOperators.map((operator) => ({
        id: operator,
        name: (
          <Option>
            {get(OPERATOR_NAMES, id, OPERATOR_NAMES.default)[operator]}
          </Option>
        ),
      })),
      meta: {
        ...(meta || {}),
        options,
        units: FIELD_ID_UNITS_MAP[id],
        hasEntityTypeahead,
      },
    };
  }
);

export const searchFieldsSelector = createSelector(
  currentAccountSelector,
  teamsInCurrentAccountWhereCurrentUserIsMemberSelector,
  (state) => state,
  ({ id: accountId }, teams, state) => {
    const fields = searchFiltersForAccountSelector(state, { accountId })
      .filter(({ id }) => {
        const isValidFilter = !!FILTER_NAME_MAP[id];
        const canUseTeamFilter = id !== TEAM_ID || teams.length;
        return isValidFilter && canUseTeamFilter;
      })
      .map((filter) => searchFieldSelector(state, { fieldId: filter.id }));
    return sortBy(fields, 'name');
  }
);

export const searchFieldsFuseSelector = createSelector(
  searchFieldsSelector,
  (fields) =>
    createFuseSearchFunction(fields, [
      'name',
      'meta.options.name.props.status',
      'meta.options.name.props.children',
    ])
);

const sortOptionsSelector = (state) => searchSelector(state).sortOptions;

export const sortBySelector = (state) => sortOptionsSelector(state).sortBy;
export const isDescendingSelector = (state) =>
  sortOptionsSelector(state).isDescending;

export const viewTypeSelector = (state) => searchSelector(state).viewType;

export const selectedResultIdsSelector = (state) =>
  searchSelector(state).selectedIds;

export const testExports = {
  OPERATOR_NAMES,
  FIELD_ID_UNITS_MAP,
  FIELDS_WITH_BETWEEN,
  Option,
  SentenceCase,
};
