import { hydratedAssetEntitySelector } from '@frameio/core/src/assets/selectors';
import { connect } from 'react-redux';
import { compareVersions } from 'pages/SplitPlayerContainer/actions';
import CompareVersions from './CompareVersions';

const mapStateToProps = (state, { assetId }) => ({
  asset: hydratedAssetEntitySelector(state, { assetId }) || {},
});

const mapDispatchToProps = { compareVersions };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompareVersions);
