import React from 'react';
import styled from 'styled-components';
import { shouldComponentUpdate } from 'reflective-bind';
import Card from '@frameio/components/src/styled-components/Card';
import FileCardFace from './FileCardFace';
import FileCardChin from './FileCardChin';
import DropZoneIndicator from './DropZoneIndicator';

/**
 * Card Animation State Diagram
 * ┌─────────────────────┐  ┌─────────────────────┐  ┌─────────────────────┐  ┌────────────────────┐
 * │1. Start             │  │2. Hover             │  │3. Drop (mouseup)    │  │4. Dropped          │
 * │isDropZone: false    │  │isDropZone: true     │  │isDropZone: false    │  │isDropZone: false   │
 * │isDropping: false    │─▶│isDropping: false    │─▶│isDropping: true     │─▶│isDropping: false   │
 * │isDropComplete: false│  │isDropComplete: false│  │isDropComplete: false│  │isDropComplete: true│
 * └─────────────────────┘  └─────────────────────┘  └─────────────────────┘  └────────────────────┘
 *         ▲                                                                                  │
 *         │                                                                                  │
 *         └───────────────────────────XHR completes with new data────────────────────────────┘
 */

const StyledCard = styled(Card)`
  background-color: ${(p) => p.theme.color.purpleBlack};
`;

class FileCard extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    // Versioning saga has completed: State 4 -> 1.
    if (prevState.isDropComplete && !nextProps.assetToVersion) {
      return {
        isDropComplete: false,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      // Used to optimistically update the FileCard to indicate that the
      // transitions for versioning have completed. If we relied on redux state,
      // we would have to wait for the network call to complete. This allows us
      // to selectively render the new state of the FileCard as if it were
      // already versioned. When the network call completes, it will reset the
      // state and re-render with the versioned asset as its cover asset (i.e.
      // it should look the same). See the state diagram above.
      isDropComplete: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shouldComponentUpdate(this, nextProps, nextState);
  }

  // Clicking on the context menu button has the same semantics as right-clicking on
  // the card, so we trap the click event, and dispatch a contextmenu
  // event instead.
  onContextMenuButtonClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    evt.target.dispatchEvent(new MouseEvent('contextmenu', evt.nativeEvent));
  };

  render() {
    const {
      asset,
      assetToVersion,
      assetToVersionCreatorName,
      coverAsset,
      creatorName,
      daysLeftBeforeDeletedOnLifecyclePolicy,
      hasContextMenu,
      isAssetLifecyclePolicyExpiring,
      isDropZone,
      isSelected,
      isSharingMode,
      isTouch,
      onDropDone,
      parentAssetType,
      selectedBorderWidth,
      showAssetThumb,
      showQuicklookTooltip,
      sortedBy,
      style,
      thumbHeight,
      thumbRef,
      thumbWidth,
      versionAsset,
      ...rest
    } = this.props;
    const { isDropComplete } = this.state;
    return (
      <StyledCard
        isSelected={isSelected || isDropZone}
        isTouch={isTouch}
        selectedBorderWidth={selectedBorderWidth}
        style={style}
        {...rest}
      >
        <FileCardFace
          asset={asset}
          assetToVersion={assetToVersion}
          coverAsset={coverAsset}
          isDropComplete={isDropComplete}
          isDropZone={isDropZone}
          onDropDone={onDropDone}
          parentAssetType={parentAssetType}
          showAssetThumb={showAssetThumb}
          thumbHeight={thumbHeight}
          thumbRef={thumbRef}
          thumbWidth={thumbWidth}
          versionAsset={versionAsset}
        />
        <FileCardChin
          asset={asset}
          assetToVersion={assetToVersion}
          assetToVersionCreatorName={assetToVersionCreatorName}
          coverAsset={coverAsset}
          creatorName={creatorName}
          daysLeftBeforeDeletedOnLifecyclePolicy={
            daysLeftBeforeDeletedOnLifecyclePolicy
          }
          hasContextMenu={hasContextMenu}
          isAssetLifecyclePolicyExpiring={isAssetLifecyclePolicyExpiring}
          isDropComplete={isDropComplete}
          isDropZone={isDropZone}
          isSharingMode={isSharingMode}
          isTouch={isTouch}
          onContextMenuButtonClick={this.onContextMenuButtonClick}
          showAssetThumb={showAssetThumb}
          showQuicklookTooltip={showQuicklookTooltip}
          sortedBy={sortedBy}
        />
        <DropZoneIndicator
          assetToVersion={assetToVersion}
          isDropComplete={isDropComplete}
          isDropZone={isDropZone}
          onDropEnd={() => this.setState({ isDropComplete: true })}
          thumbHeight={thumbHeight}
          thumbWidth={thumbWidth}
        />
      </StyledCard>
    );
  }
}

export default React.memo(FileCard);

FileCard.propTypes = {
  ...FileCardFace.propTypes,
  ...FileCardChin.propTypes,
  ...DropZoneIndicator.propTypes,
};

FileCard.defaultProps = {
  ...FileCardFace.defaultProps,
  ...FileCardChin.defaultProps,
  ...DropZoneIndicator.defaultProps,
};

export const testExports = {
  StyledCard,
};
