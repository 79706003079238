import { extname } from 'path';
import { isVideo as isAssetVideo } from '@frameio/core/src/assets/helpers/mediaTypes';
import { type as assetTypes } from '@frameio/core/src/assets/helpers/constants';

const ERROR = {
  TWO_ASSETS_REQUIRED: 'The Turnstyle files are missing',
  NOT_VIDEO_FILE: 'Not a video file',
};

/**
 * Checks if the give files are a valid Dual View file pair and if they have certain
 * matching properties.
 * @returns {undefined | string | []string} error - When no error is found, returns undefined.
 * When one of the files is an invalid file (audio etc) we return a single error string.
 * When properties mismatch, we return an array of mismatching property names.
 */
export function validateAssetsAndGetErrors(assets) {
  if (assets.length !== 2) {
    return ERROR.TWO_ASSETS_REQUIRED;
  }
  const [assetA, assetB] = assets;

  // is video?
  const nonVideoAsset = assets.find((asset) => !isAssetVideo(asset));
  if (nonVideoAsset) {
    return `${ERROR.NOT_VIDEO_FILE}: ${nonVideoAsset.name}`;
  }

  const mismatchingProps = [];

  if (assetA.frames !== assetB.frames) {
    mismatchingProps.push('frames');
  }

  // check framerate
  // due to floating imprecisions, we're checking the
  // difference of both asset's fps and tolerate
  const diff = Math.abs(assetA.fps - assetB.fps);
  if (diff > 0.000001) {
    mismatchingProps.push('fps');
  }
  return mismatchingProps.length > 0 ? mismatchingProps : undefined;
}

function isExtensionSupported(filename) {
  const supportedExtensions = ['mov', 'mp4', 'm4v', 'avi', 'mxf'];
  const extension = extname(filename)
    .toLowerCase()
    .replace('.', '');
  return supportedExtensions.includes(extension);
}

export function isFileSupported(filename, mimeType) {
  // if the mimetype is not given (which occasionally happens)
  // then check the extension
  if (typeof mimeType !== 'string' || mimeType.trim().length < 1) {
    return isExtensionSupported(filename);
  }
  return /video/.test(mimeType);
}

const DUAL_VIEW_NAME = 'Turnstyle';
export function generateName(filename) {
  let newName = filename;
  const extension = extname(newName);
  // select H or V characters that are surrounded by non-alhpanumeric characters
  const regexShort = new RegExp(
    /([^a-zA-Z\d\u00C0-\u00FF])[HV]([^a-zA-Z\d\u00C0-\u00FF])/,
    'i'
  );
  const regexLong = new RegExp('(horizontal|vertical)', 'i');

  if (regexShort.test(filename)) {
    const replacer = (match, p1, p2) => [p1, DUAL_VIEW_NAME, p2].join('');
    newName = filename.replace(regexShort, replacer);
  } else if (regexLong.test(newName)) {
    newName = newName.replace(regexLong, DUAL_VIEW_NAME);
  } else {
    // hardcoding the extension here because
    // the turnstyle file will always be a mp4
    return newName.replace(extension, `-${DUAL_VIEW_NAME}.mp4`);
  }
  return newName.replace(extension, '.mp4');
}

/**
 * Very basic checks to make sure we have a
 * potential dual-view upload
 */
export function isDualViewCandidate(items) {
  if (
    items.length !== 2 ||
    items.some(({ type }) => type === assetTypes.FOLDER)
  )
    return false;

  const [itemA, itemB] = items;
  return (
    isFileSupported(itemA.name, itemA.file.type) &&
    isFileSupported(itemB.name, itemB.file.type)
  );
}

export const testExports = { ERROR, DUAL_VIEW_NAME };
