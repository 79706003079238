import React from 'react';
import Truncate from '@frameio/components/src/styled-components/Truncate';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { cloneProject as cloneProjectSaga } from '@frameio/core/src/projects/sagas';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import { openModal } from 'components/Modal/actions';
import { canSeeDevToolsSelector } from './OpenDevTools/selectors';
import { DEVELOPMENT_TOOLS } from './actions';
import DevTools from './DevTools';

function* openDevToolsModal() {
  const canOpen = yield select(canSeeDevToolsSelector);
  if (canOpen) {
    yield put(
      openModal(<DevTools />, {
        style: { maxHeight: '95vh', overflow: 'auto' },
      })
    );
  }
}

function* cloneProject(projectId) {
  const { failure } = yield call(cloneProjectSaga, projectId);

  if (failure) {
    yield put(
      showErrorToast({
        header: 'Could not clone the project successfully',
      })
    );
    return;
  }

  yield put(
    showSuccessToast({
      header: 'The project has been cloned',
    })
  );
}

function* copyVersion({ payload }) {
  const { version } = payload;

  try {
    yield navigator.clipboard.writeText(version);

    yield put(
      showSuccessToast({
        header: 'Copied to clipboard!',
        subHeader: <Truncate>{version}</Truncate>,
        autoCloseDelay: 4000,
      })
    );
  } catch {
    yield put(
      showErrorToast({
        header: 'Could not copy to clipboard',
      })
    );
  }
}

export default [
  takeLatest(DEVELOPMENT_TOOLS.COPY_VERSION, copyVersion),
  takeLatest(DEVELOPMENT_TOOLS.OPEN_MODAL, openDevToolsModal),

  takeLatest(DEVELOPMENT_TOOLS.CLONE_PROJECT, ({ payload: { projectId } }) =>
    cloneProject(projectId)
  ),
];
