import { PLAYER_CONTAINER } from './actions';

export const INITIAL_STATE = {
  accountId: null,
  assetId: null,
  backUrl: null,
  copyFileId: null,
  defaultPlaybackRate: 1,
  folderId: null,
  initialMediaTime: null,
  newestCommentCreatedId: null,
  projectId: null,
  showAllAnnotations: false,
  showComments: true,
  teamId: null,
  versionStackId: null,
};

const playerContainerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PLAYER_CONTAINER.IS_FETCHING: {
      const { assetId, versionStackId } = action.payload;
      return { ...state, assetId, versionStackId };
    }
    case PLAYER_CONTAINER.IS_FETCHING_PARENT_ASSET: {
      const { assetId } = action.payload;
      return { ...state, assetId };
    }
    case PLAYER_CONTAINER.COPY_COMMENTS: {
      const { copyFileId } = action.payload;
      return {
        ...state,
        copyFileId,
      };
    }
    case PLAYER_CONTAINER.NEWEST_COMMENT_CREATED_ID: {
      const { commentId } = action.payload;
      return {
        ...state,
        newestCommentCreatedId: commentId,
      };
    }
    case PLAYER_CONTAINER.PRELOAD_MEDIA_TIME: {
      const { time } = action.payload;
      return {
        ...state,
        // Time, in seconds, for the media to go to.
        initialMediaTime: time,
      };
    }
    case PLAYER_CONTAINER.SET_RELATIONAL_DATA: {
      const { folderId, projectId, teamId, accountId } = action.payload;
      return {
        ...state,
        folderId,
        projectId,
        teamId,
        accountId,
      };
    }
    case PLAYER_CONTAINER.SET_BACK_URL: {
      const { backUrl } = action.payload;
      return { ...state, backUrl };
    }
    case PLAYER_CONTAINER.SAVE_DEFAULT_PLAYBACK_RATE: {
      const { defaultPlaybackRate } = action.payload;
      return { ...state, defaultPlaybackRate };
    }
    case PLAYER_CONTAINER.SET_SHOW_ALL_ANNOTATIONS: {
      const { showAllAnnotations } = action.payload;
      return { ...state, showAllAnnotations };
    }
    case PLAYER_CONTAINER.SET_SHOW_COMMENTS: {
      const { showComments } = action.payload;
      return { ...state, showComments };
    }
    default:
      return state;
  }
};

export default playerContainerReducer;
