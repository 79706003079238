import { createSelector } from 'reselect';

import {
  paginatedListMetadataSelector,
  paginatedListAllResultsSelector,
  shouldFetchListPageSelector,
  paginatedListIsFetchingPageOneSelector,
} from '@frameio/core/src/shared/selectors';

const deletedAssetsSelector = (state) => state.deletedAssets;

export default deletedAssetsSelector;

// Search in Team selectors

const assetsInTeamSelector = (state) =>
  deletedAssetsSelector(state).assetsInTeam;

export const teamAssetIdsSelector = createSelector(
  assetsInTeamSelector,
  paginatedListAllResultsSelector
);

export const teamAssetsMetaDataSelector = createSelector(
  assetsInTeamSelector,
  paginatedListMetadataSelector
);

export function shouldFetchTeamAssetsSelector(state, { page }) {
  const slice = assetsInTeamSelector(state);
  return shouldFetchListPageSelector(slice, { page });
}

export const isFetchingTeamAssetsFirstPageSelector = createSelector(
  assetsInTeamSelector,
  paginatedListIsFetchingPageOneSelector
);

// Get or Search in Project selectors

const assetsInProjectSelector = (state) =>
  deletedAssetsSelector(state).assetsInProject;

export const projectAssetIdsSelector = createSelector(
  assetsInProjectSelector,
  paginatedListAllResultsSelector
);

export const projectAssetsMetaDataSelector = createSelector(
  assetsInProjectSelector,
  paginatedListMetadataSelector
);

export function shouldFetchProjectAssetsSelector(state, { page }) {
  const slice = assetsInProjectSelector(state);
  return shouldFetchListPageSelector(slice, { page });
}

export const isFetchingProjectAssetsFirstPageSelector = createSelector(
  assetsInProjectSelector,
  paginatedListIsFetchingPageOneSelector
);

export const selectedAssetIdsSelector = (state) =>
  deletedAssetsSelector(state).selectedAssetIds;
