import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Folder from '@frameio/components/src/svgs/icons/16/folder2.svg';

const FolderIcon = styled(({ isSelected, disabled, ...other }) => (
  <Folder {...other} />
))`
  color: ${(p) =>
    p.disabled ? p.theme.color.slateGray : p.theme.color.graphiteGray};
  path {
    transition: fill 0.07s ease;
    fill: ${(p) => (p.isSelected ? p.theme.color.graphiteGray : 'transparent')};
  }
`;

FolderIcon.propTypes = {
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FolderIcon;
