import { takeEvery, select, put } from 'redux-saga/effects';
import {
  createPaginatedListSaga,
  createDelegatedSaga,
} from '@frameio/core/src/shared/sagas/helpers';
import {
  listReviewersForAccount,
  fetchTotalUnseenReviewsForAccount,
} from '@frameio/core/src/reviewers/sagas';
import { currentAccountSelector } from 'selectors/accounts';
import REVIEWER_CORE from '@frameio/core/src/reviewers/actions';
import {
  INBOX,
  fetchUnseenReviewsTotal as fetchUnseenReviewTotalAction,
} from './actions';

const fetchReviewers = createPaginatedListSaga(
  INBOX.FETCH_REVIEWERS,
  ({ payload: { accountId, page } }) =>
    listReviewersForAccount(accountId, { page }),
  {
    extractPage: ({ payload: { page } }) => page,
  }
);
const fetchUnseenReviewTotal = createDelegatedSaga(
  INBOX.FETCH_UNSEEN_REVIEWS_TOTAL,
  ({ payload: { accountId } }) => fetchTotalUnseenReviewsForAccount(accountId)
);
function* updateUnseenReviewTotalOnSocketEvent() {
  const { id: currentAccountId } = yield select(currentAccountSelector);
  yield put(fetchUnseenReviewTotalAction(currentAccountId));
}

export default [
  takeEvery(INBOX.FETCH_REVIEWERS.BASE, fetchReviewers),
  takeEvery(INBOX.FETCH_UNSEEN_REVIEWS_TOTAL.BASE, fetchUnseenReviewTotal),
  takeEvery(REVIEWER_CORE.RECEIVE_EVENT, updateUnseenReviewTotalOnSocketEvent),
];

export const testExports = {
  fetchReviewers,
  updateUnseenReviewTotalOnSocketEvent,
};
