import { connect } from 'react-redux';
import { promptNewFolderName } from 'actions/assets';
import NewFolder from './NewFolder';

const mapDispatchToProps = {
  promptNewFolderName,
};

export default connect(
  null,
  mapDispatchToProps
)(NewFolder);

export const testExports = { mapDispatchToProps };
