import React from 'react';
import PropTypes from 'prop-types';
import { trackButtonClick } from 'analytics';
import Button from '@frameio/components/src/styled-components/Button';
import styled from 'styled-components';
import LOCATION from '../constants';

const StyledButton = styled(Button)`
  flex: 1;
`;

export default function SetupAccountCTA({
  trackingLocation,
  startSetupAccount,
}) {
  return (
    <StyledButton
      dark={trackingLocation === LOCATION.PROJECT_HEADER}
      primary={trackingLocation === LOCATION.ACCOUNT_DROPDOWN}
      compact
      onClick={() => {
        startSetupAccount();
        trackButtonClick('set up account', 'main', trackingLocation);
      }}
    >
      Create my own account
    </StyledButton>
  );
}

SetupAccountCTA.propTypes = {
  trackingLocation: PropTypes.string.isRequired,
  startSetupAccount: PropTypes.func.isRequired,
};

export const testExports = {
  StyledButton,
};
