import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';

import Monospace from '@frameio/components/src/components/Monospace';

const MonospaceTimeWrapper = styled(Flex)`
  ${(p) => p.theme.fontStyle.bodyM};
`;

const MonospaceCurrent = styled.div`
  color: ${(p) => p.theme.color.coldWhite};
  white-space: nowrap;
`;

const MonospaceEnd = styled.div`
  color: ${(p) => p.theme.color.graphiteGray};
  white-space: nowrap;
`;

const Divider = styled.div`
  color: ${(p) => p.theme.color.graphiteGray};
  padding: 0 ${(p) => p.theme.spacing.micro};
`;

const MonospaceTime = ({ current, end }) => (
  <MonospaceTimeWrapper center>
    <MonospaceCurrent>
      <Monospace spaceWidth={8} text={current} />
    </MonospaceCurrent>
    {end && (
      <React.Fragment>
        <Divider>/</Divider>
        <MonospaceEnd>
          <Monospace spaceWidth={8} text={end} />
        </MonospaceEnd>
      </React.Fragment>
    )}
  </MonospaceTimeWrapper>
);

MonospaceTime.propTypes = {
  current: PropTypes.string.isRequired,
  end: PropTypes.string,
};

export default MonospaceTime;
