import React, { memo } from 'react';
import styled from 'styled-components';
import AnimateHeight from '../AnimateHeight';

const StyledDiv = styled.div`
  color: ${({ theme }) => theme.color.error};
  font-size: ${({ theme }) => theme.fontSize[1]};
  margin-top: ${({ marginTop, theme }) => marginTop && `${theme.spacing.tiny}`};
  margin-bottom: ${({ marginBottom }) => marginBottom && '16px'};
`;

const ErrorMessage = ({ children, isVisible, marginBottom, marginTop }) => {
  return (
    <AnimateHeight isVisible={isVisible}>
      <StyledDiv marginBottom={marginBottom} marginTop={marginTop}>
        {children}
      </StyledDiv>
    </AnimateHeight>
  );
};

export default memo(ErrorMessage);
