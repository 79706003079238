import { createSelector } from 'reselect';
import { filter, sortBy, get } from 'lodash';
import {
  projectEntitiesSelector,
  projectEntitySelector,
} from '@frameio/core/src/projects/selectors';
import { currentAccountSelector } from 'selectors/accounts';
import { isCollaboratorOnlySelector } from 'selectors/roles';
import { teamEntitiesSelector } from '@frameio/core/src/teams/selectors';
/**
 * @param   {Object} state - Redux store state.
 * @returns {Object} - Current project's object.
 */
export const currentProjectSelector = (state) => state.currentProject;

/**
 * Get the hierarchy of all project folders.
 * @param {Object} state - Redux store state.
 * @returns {Object} - All folder trees.
 */
export const projectFolderTreesSelector = (state) => state.projectFolderTrees;

/**
 * @param {string} projectId - Project id.
 * @returns {Object} - Folder hierarchy for a given project.
 */
export const folderTreeForProjectIdSelector = createSelector(
  projectFolderTreesSelector,
  (state, { projectId }) => projectId,
  (folderTrees, projectId) => folderTrees[projectId]
);

/**
 * @param {Object} state - Redux store state.
 * @returns {Object} - Current project's data.
 */
export const currentProjectEntitySelector = createSelector(
  [projectEntitiesSelector, currentProjectSelector],
  (projectEntities, currentProject) => projectEntities[currentProject.id]
);

export const sortedProjectEntitiesArraySelector = createSelector(
  projectEntitiesSelector,
  (projects) =>
    sortBy(Object.values(projects), (project) => project.name.toLowerCase())
);

/**
 * @returns {Array} - An array of projects where the user is only a collaborator.
 * For legacy reasons, referred to as 'Shared Projects'.
 */
export const sharedProjectsSelector = createSelector(
  sortedProjectEntitiesArraySelector,
  (state) => state,
  (projects, state) =>
    projects.filter((project) =>
      isCollaboratorOnlySelector(state, { projectId: project.id })
    )
);

export const isProjectArchivedSelector = createSelector(
  projectEntitySelector,
  (project) => {
    if (!project) return false;
    return !!project.archived_at;
  }
);

/**
 * If a project (Account) has DRM regardless of which assets:
 * We will need to disable key features.
 */
export const isProjectUsingDRMSelector = createSelector(
  projectEntitySelector,
  (project) => {
    if (!project) return false;
    return !!project.has_drm_assets;
  }
);

const projectsForAccountIdSelector = createSelector(
  sortedProjectEntitiesArraySelector,
  (state, { accountId }) => accountId,
  teamEntitiesSelector,
  (projects, accountId, teams) =>
    projects.filter((project) => {
      const { team_id } = project;
      const isProjectInAccount =
        get(teams, [team_id, 'account_id'], '') === accountId;
      return isProjectInAccount;
    })
);

export const projectsForCurrentAccountSelector = createSelector(
  currentAccountSelector,
  (state) => state,
  ({ id: accountId }, state) =>
    projectsForAccountIdSelector(state, { accountId })
);

/**
 * @param {string} teamId - Team id
 * @returns {string[]} - An array of project entities belonging to a given team
 * id, sorted ascending by project name.
 */
export const sortedProjectsEntitiesForTeamIdSelector = createSelector(
  projectEntitiesSelector,
  (state, { teamId }) => teamId,
  (projectEntities, teamId) => {
    const projects = Object.values(projectEntities);
    const projectsForTeam = projects.filter(
      (project) => project.team_id === teamId
    );
    return sortBy(projectsForTeam, [({ name }) => name && name.toLowerCase()]);
  }
);

/**
 * @param {string} teamId - Team id
 * @returns {string[]} - An array of unarchived project ids belonging to a given team.
 */
export const unarchivedProjectIdsForTeamIdSelector = createSelector(
  sortedProjectsEntitiesForTeamIdSelector,
  (_, { filterDeleted }) => filterDeleted,
  (projectEntities, filterDeleted = false) =>
    projectEntities
      .filter(
        (project) =>
          !project.archived_at && (filterDeleted ? !project.deleted_at : true)
      )
      .map((project) => project.id)
);

/**
 * @param {string} teamId - Team id
 * @returns {string[]} - An array of archived project ids belonging to a given team.
 */
export const archivedProjectIdsForTeamIdSelector = createSelector(
  sortedProjectsEntitiesForTeamIdSelector,
  (_, { filterDeleted }) => filterDeleted,
  (projectEntities, filterDeleted = false) =>
    projectEntities
      .filter(
        (project) =>
          !!project.archived_at && (filterDeleted ? !project.deleted_at : true)
      )
      .map((project) => project.id)
);

export const userPreferencesForProjectIdSelector = createSelector(
  projectEntitiesSelector,
  (state, { projectId }) => projectId,
  (projectEntities, projectId) => projectEntities[projectId].user_preferences
);

// TODO(CORE-2247): Create tests for the below helper and makeSelector functions
function getArchivedProjectsForTeam(projectEntities, teamId) {
  return sortBy(
    filter(
      projectEntities,
      (project) => project.team_id === teamId && !!project.archived_at
    ),
    ({ name }) => name && name.toLowerCase()
  );
}

function getUnarchivedProjectsForTeam(projectEntities, teamId) {
  return sortBy(
    filter(
      projectEntities,
      (project) => project.team_id === teamId && !project.archived_at
    ),
    ({ name }) => name && name.toLowerCase()
  );
}

const makeUniqueProjectEntitiesForTeamIdSelector = (transformFn) =>
  createSelector(
    projectEntitiesSelector,
    (state, { teamId }) => teamId,
    transformFn
  );

export const makeArchivedProjectIdsForTeamIdSelector = () =>
  makeUniqueProjectEntitiesForTeamIdSelector((projectEntities, teamId) =>
    getArchivedProjectsForTeam(projectEntities, teamId).map(
      (project) => project.id
    )
  );

export const makeUnarchivedProjectEntitiesForTeamIdSelector = () =>
  makeUniqueProjectEntitiesForTeamIdSelector((projectEntities, teamId) =>
    getUnarchivedProjectsForTeam(projectEntities, teamId)
  );

export const makeUnarchivedProjectIdsForTeamIdSelector = () =>
  makeUniqueProjectEntitiesForTeamIdSelector((projectEntities, teamId) =>
    getUnarchivedProjectsForTeam(projectEntities, teamId).map(
      (project) => project.id
    )
  );
