import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'styled-flex-component';
import Button from '@frameio/components/src/styled-components/Button';
import LOCATION from 'components/AccountCTA/constants';
import TrialRemaining from 'components/AccountCTA/PlanUpgradeCTA/TrialRemaining';
import styled from 'styled-components';
import { MenuButton } from '@frameio/vapor';

const StyledButton = styled(Button)`
  flex: 1;
`;

const StyledFlex = styled(Flex)`
  padding: ${(p) => p.hasPadding && p.theme.spacing.units(1)};
  padding-bottom: 0;
`;

class PlanUpgradeCTA extends React.Component {
  componentDidMount() {
    const { getCardsByAccount, accountId, hasFetchedCards } = this.props;
    if (!hasFetchedCards && accountId) {
      getCardsByAccount(accountId);
    }
  }

  renderButton = () => {
    const {
      hasCard,
      onTrial,
      addPayment,
      upgradeAccount,
      accountId,
      trackingLocation,
    } = this.props;
    const showAddPayment = onTrial && !hasCard;

    if (trackingLocation === LOCATION.ACCOUNT_DROPDOWN_SECONDARY) {
      return (
        <MenuButton
          onClick={
            showAddPayment
              ? () => addPayment(accountId, trackingLocation)
              : () => upgradeAccount(accountId, trackingLocation)
          }
        >
          {showAddPayment ? 'Subscribe now' : 'Upgrade Plan'}
        </MenuButton>
      );
    }

    return (
      <StyledButton
        compact={trackingLocation !== LOCATION.C2C_TAB}
        large={trackingLocation === LOCATION.C2C_TAB}
        dark={trackingLocation === LOCATION.PROJECT_HEADER}
        primary={[LOCATION.ACCOUNT_DROPDOWN, LOCATION.C2C_TAB].includes(
          trackingLocation
        )}
        onClick={
          showAddPayment
            ? () => addPayment(accountId, trackingLocation)
            : () => upgradeAccount(accountId, trackingLocation)
        }
      >
        {showAddPayment ? 'Subscribe now' : 'Upgrade'}
      </StyledButton>
    );
  };

  render() {
    const {
      accountId,
      countdown,
      hasFetchedCards,
      isEnterprise,
      onTrial,
      trackingLocation,
    } = this.props;

    if (!accountId || !hasFetchedCards) return null;

    // Ideally we refactor this so that state lives further up the tree,
    // and we can take advantage of composition and only supply the padding
    // if necessary higher up the tree.
    const hasPadding =
      trackingLocation === LOCATION.ACCOUNT_DROPDOWN &&
      (onTrial || !isEnterprise);

    const shouldShowTrialRemaining =
      onTrial && trackingLocation === LOCATION.PROJECT_HEADER;

    if (trackingLocation === LOCATION.ACCOUNT_DROPDOWN_SECONDARY) {
      return (
        <>
          {shouldShowTrialRemaining && <TrialRemaining countdown={countdown} />}
          {!isEnterprise && this.renderButton()}
        </>
      );
    }

    return (
      <StyledFlex hasPadding={hasPadding} alignCenter justifyCenter wrap="true">
        {shouldShowTrialRemaining && <TrialRemaining countdown={countdown} />}
        {!isEnterprise && this.renderButton()}
      </StyledFlex>
    );
  }
}

PlanUpgradeCTA.defaultProps = {
  accountId: null,
  isEnterprise: null,
  onTrial: false,
  planName: null,
  hasFetchedCards: false,
  addPayment: null,
  upgradeAccount: null,
};

PlanUpgradeCTA.propTypes = {
  accountId: PropTypes.string,
  countdown: PropTypes.number.isRequired,
  getCardsByAccount: PropTypes.func.isRequired,
  hasCard: PropTypes.bool.isRequired,
  hasFetchedCards: PropTypes.bool,
  isEnterprise: PropTypes.bool,
  onTrial: PropTypes.bool.isRequired,
  trackingLocation: PropTypes.string.isRequired,
  addPayment: PropTypes.func.isRequired,
  upgradeAccount: PropTypes.func.isRequired,
};

export default PlanUpgradeCTA;

export const testExports = {
  StyledButton,
};
