import React, {
  useContext,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';
import noop from 'lodash/noop';

const defaultState = {
  isSegmentLoaded: false,
  setSegmentLoaded: noop,
};

export const SegmentContext = createContext(defaultState);

export const SegmentContextProvider = ({ children }) => {
  const [isSegmentLoaded, setSegmentIsLoaded] = useState(false);

  const setSegmentLoaded = useCallback(() => setSegmentIsLoaded(true), []);

  const value = useMemo(
    () => ({
      isSegmentLoaded,
      setSegmentLoaded,
    }),
    [isSegmentLoaded, setSegmentLoaded]
  );

  return (
    <SegmentContext.Provider value={value}>{children}</SegmentContext.Provider>
  );
};

export const useIsSegmentLoaded = () => useContext(SegmentContext);
