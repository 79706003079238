import { connect } from 'react-redux';
import TreeProjectItem from './TreeProjectItem';
import {
  fetchTree,
  setFolderCreationStatus,
  FolderCreationEvents,
} from './actions';
import { projectAssetTree, moveToFolderSelector } from './selectors';

function mapStateToProps(state, { project: { id } }) {
  const {
    folderCreationState,
    selectedTreeItem: { id: selectedId, createdId },
  } = moveToFolderSelector(state);

  return {
    folders: projectAssetTree(state, id),
    isFolderCreationInProgress:
      folderCreationState !== FolderCreationEvents.NONE,
    isNamingNewFolder:
      folderCreationState === FolderCreationEvents.IS_BEING_NAMED,
    isNewFolderSaving: folderCreationState === FolderCreationEvents.IS_SAVING,
    isNewFolderCreated: folderCreationState === FolderCreationEvents.IS_CREATED,
    selectedId,
    createdId,
  };
}

const mapDispatchToProps = {
  onFetchFolders: fetchTree,
  onSetFolderCreationStatus: setFolderCreationStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TreeProjectItem);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
