import { connect } from 'react-redux';
import { isCurrentUserAnonymousSelector } from 'selectors/users';
import {
  confirmDownloadInDesktopApp,
  confirmDownloadInBrowser,
  cancelModal,
} from '../actions';
import DownloadWithDesktopApp from './DownloadWithDesktopApp';

function mapStateToProps(state) {
  return {
    isAnonymousUser: isCurrentUserAnonymousSelector(state),
  };
}

const mapDispatchToProps = {
  onDownloadInApp: confirmDownloadInDesktopApp,
  onDownloadInBrowser: confirmDownloadInBrowser,
  onCancel: cancelModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadWithDesktopApp);
