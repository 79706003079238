import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

const VIMEO = generateActionTypes('VIMEO', [
  'AUTH',
  'CONFIRM_PUBLISH',
  'PUBLISH',
  'RECEIVE_OAUTH_RESPONSE',
]);

export default VIMEO;

export const authOnVimeo = createAction(VIMEO.AUTH, (assetId) => ({ assetId }));

export const confirmPublishToVimeo = createAction(
  VIMEO.CONFIRM_PUBLISH,
  (data) => ({ data })
);

export const publishToVimeo = createAction(VIMEO.PUBLISH, (assetId) => ({
  assetId,
}));

export const receiveVimeoOAuthResponse = createAction(
  VIMEO.RECEIVE_OAUTH_RESPONSE,
  (code, state) => ({ code, state })
);
