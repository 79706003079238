import React from 'react';
import { clamp } from 'lodash';
import getRelativeOffset from './getRelativeOffset';
import getTrueDimensions from './getTrueDimensions';

/**
 *
 * @param {[number, number]} blockDimensions - dimensions of bounding client rect
 * @param {[number, number]} positionMax - max x, y of containing background
 * @param {[number, number]} positionNormalized - position as percentage of background between [0, 1]
 * @param {[number, number]} positionOffset - position to shift output, in px
 * @param {[number, number]} referencePointRelativeOffset - int percentage of how far from top left corner of container
 * @param {[number, number]} initialReferencePointRelativeOffset - initial relative offset of previous position
 * @param {number} rotation - rotation of block, between (-45, 45)
 * @returns {[number, number]} - coordinates of absolute position
 */
const getAbsoluteCoordinates = (
  blockDimensions,
  positionMax,
  positionNormalized,
  positionOffset,
  referencePointRelativeOffset,
  initialReferencePointRelativeOffset,
  rotation
) => {
  // length and width of the wmid block
  const trueDimensions = getTrueDimensions(blockDimensions, rotation);

  // initial x and y of block before offsets
  const [x0, y0] = [
    positionMax[0] * positionNormalized[0],
    positionMax[1] * positionNormalized[1],
  ];

  // difference in what percentage of the relative offsets
  const [xRelativeOffsetDifference, yRelativeOffsetDifference] = [
    (initialReferencePointRelativeOffset[0] - referencePointRelativeOffset[0]) /
      100,
    (initialReferencePointRelativeOffset[1] - referencePointRelativeOffset[1]) /
      100,
  ];

  // how much the point of origin moves in x and y direction before rotation
  const offsetX = trueDimensions[0] * xRelativeOffsetDifference;
  const offsetY = trueDimensions[1] * yRelativeOffsetDifference;

  const theta = (rotation * Math.PI) / 180;
  // rotate the offset points
  const rotatedOffsetX = offsetX * Math.cos(theta) - offsetY * Math.sin(theta);
  const rotatedOffsetY = offsetX * Math.sin(theta) + offsetY * Math.cos(theta);

  // new x and y position after offsets
  const x1 = x0 + positionOffset[0] + rotatedOffsetX;
  const y1 = y0 + positionOffset[1] + rotatedOffsetY;

  return [clamp(x1, 0, positionMax[0]), clamp(y1, 0, positionMax[1])];
};

/**
 * Compute a reference point's absolute position and relative offset, where the
 * reference point is one of nine positions along the left/center/right and
 * top/middle/bottom of a rectangle with dynamic size. The sum of a reference
 * point's position and it's rectangle's offset describe the expected location
 * of a text watermark rendered by the Frame.io stream service. For more
 * information refer to https://github.com/Frameio/stream#features.
 *
 * ```
 * +----------------------------------+
 * |                                △ |
 * |                                │ |
 * |                          TOP=6px |
 * |                                │ |
 * | ◁──LEFT=18px──────────────────▷│ |
 * |                                ▽ |
 * |                A┄┄┄┄┄┄┄B┄┄┄┄┄┄┄C | ☜ RECT_OFFSET_C=[-100%,0]
 * |                ┆               ┆ |
 * |                D       E       F |
 * |                ┆               ┆ |
 * |                G┄┄┄┄┄┄┄H┄┄┄┄┄┄┄I |
 * +----------------------------------+
 * ```
 *
 * @param {*} args.clientRectRef - React Ref for rectangle DOM element.
 * @param {string} args.referencePoint - desired reference point for output
 * computations, is a string such as 'middle_center' etc
 * @param {string} args.initialReferencePoint - optionally provide to shift
 * output offset by the amount of initial offset, is a string such as 'middle_center' etc
 * @param {[number, number]} args.positionMax - position upper upper limits.
 * @param {[number, number]} args.positionNormalized - desired position as a
 * percentage from 0 to 1.
 * @param {[number, number]} args.positionOffset - optionally provide to shift
 * the output position by this number of pixels, for example `[15,-8]` to
 * increase output position for a drag operation 15 pixels to the right and 8
 * pixels up.
 * @returns {Object} result
 * @returns {[number, number]} result.position - reference point's pixel
 * coordinates on each axis, for example `[138,6]`.
 * @returns {[number, number]} result.referencePointOffset - relative position
 * of the rectangle's top left corner with respect to the reference point as an
 * integer percentage, for example reference point "top_right" gives the
 * rectangle offset `[-100,0]`.
 */
export default (args) => {
  const {
    clientRectRef,
    initialReferencePoint,
    positionMax,
    positionNormalized,
    positionOffset,
    referencePoint,
    rotation = 0,
  } = args;

  // TODO: consider reducing DOM reads as a perf optimization
  const clientRect =
    clientRectRef && clientRectRef.current
      ? clientRectRef.current.getBoundingClientRect()
      : { width: 0, height: 0 };
  const blockDimensions = [clientRect.width, clientRect.height];

  const referencePointRelativeOffset = getRelativeOffset(
    referencePoint,
    rotation
  );
  const initialReferencePointRelativeOffset = getRelativeOffset(
    initialReferencePoint
  );
  const position = React.useMemo(
    () =>
      getAbsoluteCoordinates(
        blockDimensions,
        positionMax,
        positionNormalized,
        positionOffset,
        referencePointRelativeOffset,
        initialReferencePointRelativeOffset,
        rotation
      ),
    [
      blockDimensions,
      initialReferencePointRelativeOffset,
      positionMax,
      positionNormalized,
      positionOffset,
      referencePointRelativeOffset,
      rotation,
    ]
  );

  return {
    position,
    relativeOffset: referencePointRelativeOffset,
  };
};
