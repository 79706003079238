import React from 'react';
import {
  call,
  spawn,
  put,
  select,
  take,
  takeEvery,
  race,
  delay,
} from 'redux-saga/effects';
import track from 'analytics';
import { ARCHIVED_STORAGE_LIMIT } from '@frameio/core/src/subscriptionLineItems/utils/modifierTypes';
import { archiveProject } from '@frameio/core/src/projects/sagas';
import { accountEntityForProjectIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { getAssetSize } from '@frameio/core/src/assets/actions';
import {
  takeSuccessWithEntityId,
  takeFailureWithEntityId,
} from '@frameio/core/src/shared/sagas/helpers';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import StorageLimitReachedFlow from 'components/StorageLimitReachedFlow';
import { MODAL, openModal, closeModal } from 'components/Modal/actions';
import { showErrorToast, showSuccessToast } from 'actions/toasts';
import { DEFAULT_TRANSITION_DURATION } from 'components/ArchiveProjectFlow/ArchiveProject/ArchiveProject';
import { hasSpaceToArchive } from 'utils/planLimits';
import { shouldShowStorageHardBlock } from 'selectors/accounts';
import HardBlock from 'components/HardBlock';
import { limitTypes } from 'selectors/limits';
import {
  ARCHIVE_PROJECT_FLOW,
  showInitialScreen,
  showInProgressScreen,
  showSuccessScreen,
} from './actions';

function shouldCancelArchiveProjectFlow({ type }) {
  return type === ARCHIVE_PROJECT_FLOW.END || type === MODAL.CLOSE;
}

const minInProgressAnimationDuration = 1500;

function* startArchiveProjectFlow(
  projectId,
  isUpdatedArchivalMessagingEnabled
) {
  let action;
  const project = yield select(projectEntitySelector, { projectId });
  const account = yield select(accountEntityForProjectIdSelector, {
    projectId,
  });
  const rootFolderId = project.root_asset_id;
  const rootFolder = yield select(assetEntitySelector, {
    assetId: rootFolderId,
  });

  if (!isUpdatedArchivalMessagingEnabled) {
    yield put(showInitialScreen());
  }

  // Fetch root folder size, which isn't guaranteed to be there
  if (!rootFolder) {
    action = yield put(getAssetSize(rootFolderId));
    yield race([
      takeSuccessWithEntityId(action, rootFolderId),
      takeFailureWithEntityId(action, rootFolderId),
    ]);
  }

  const {
    usage: { filesize: projectSize },
  } = yield select(assetEntitySelector, { assetId: rootFolderId });

  const hasEnoughSpaceToArchive = yield call(
    hasSpaceToArchive,
    account.id,
    projectSize
  );

  if (!hasEnoughSpaceToArchive) {
    const shouldShowHardBlock = yield select(shouldShowStorageHardBlock);
    if (shouldShowHardBlock) {
      yield put(
        openModal(<HardBlock limitType={limitTypes.ARCHIVAL_STORAGE} />)
      );
      yield spawn(track, 'action-blocked', {
        _limit: limitTypes.ARCHIVAL_STORAGE,
      });
    } else {
      yield put(
        openModal(
          <StorageLimitReachedFlow
            accountId={account.id}
            type={ARCHIVED_STORAGE_LIMIT}
          />,
          { canCloseModal: false }
        )
      );
      yield spawn(track, 'action-blocked', { limit: 'archival storage' });
    }
    return;
  }

  const response = yield take([
    ARCHIVE_PROJECT_FLOW.CONTINUE,
    ARCHIVE_PROJECT_FLOW.END,
    MODAL.CLOSE,
  ]);

  const shouldCancelFlow = yield call(shouldCancelArchiveProjectFlow, response);

  if (shouldCancelFlow) return;

  // let the animation for the first step finish before showing the inProgressScreen
  if (!isUpdatedArchivalMessagingEnabled) {
    yield delay(DEFAULT_TRANSITION_DURATION);
    yield put(showInProgressScreen());
  }

  const requestStartTime = new Date();
  const { success } = yield call(archiveProject, projectId);
  const requestEndTime = new Date();

  if (success) {
    // If the api call took less than 1.5s, delay transitioning to the completed
    // state so that the "in progress" state runs for at least 1.5s
    if (isUpdatedArchivalMessagingEnabled) {
      yield put(closeModal());
      yield put(
        showSuccessToast({
          header: 'Project successfully archived!',
        })
      );
    } else {
      const apiCallDuration = requestEndTime - requestStartTime;
      if (apiCallDuration < minInProgressAnimationDuration) {
        yield delay(minInProgressAnimationDuration - apiCallDuration);
      }
      yield put(showSuccessScreen());
    }
    return;
  }

  yield put(
    showErrorToast({
      header: 'Something went wrong while archiving this project.',
      subHeader: 'Please try again.',
    })
  );
  yield put(closeModal());
}

export default [
  takeEvery(
    ARCHIVE_PROJECT_FLOW.START,
    ({ payload: { projectId, isUpdatedArchivalMessagingEnabled } }) =>
      startArchiveProjectFlow(projectId, isUpdatedArchivalMessagingEnabled)
  ),
];

export const testExports = {
  startArchiveProjectFlow,
  minInProgressAnimationDuration,
};
