import React from 'react';
import PropTypes from 'prop-types';

function ThumbImage({ className, imageUrl, height, width, style }) {
  return (
    <div
      className={`thumb-image-container ${className}`}
      style={{
        ...style,
        height,
        width,
        backgroundImage: `url(${imageUrl})`,
      }}
    />
  );
}

ThumbImage.defaultProps = {
  height: 'auto',
  width: 'auto',
  imageUrl: '',
  className: '',
  style: {},
};

ThumbImage.propTypes = {
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageUrl: PropTypes.string,
  style: PropTypes.object,
};

export default ThumbImage;
