import React from 'react';
import { call, put, select, spawn, takeLatest } from 'redux-saga/effects';
import { openModal } from 'components/Modal/actions';
import HardBlock from 'components/HardBlock';
import { isAccountOnPlanWithUserMaxSelector } from 'selectors/accounts';
import { isAccountAtLimit } from 'components/HardBlock/sagas';
import { limitTypes } from 'selectors/limits';
import ConnectedCreateProjectForm from 'components/ProjectForms/CreateProject';
import { setTeamId } from 'components/ProjectForms/CreateProject/actions';
import { SHARED_PROJECT_ID } from 'components/DashboardSidebar/constants';
import track from 'analytics';
import { LIST_TEAM_ROW } from './actions';

export function* openCreateProjectForm(teamId) {
  yield put(setTeamId(teamId));
  yield put(openModal(<ConnectedCreateProjectForm />));
}

function* handleAddProject(teamId) {
  if (teamId === SHARED_PROJECT_ID) return;

  const isAccountAtProjectLimit = yield call(
    isAccountAtLimit,
    limitTypes.PROJECTS
  );

  // This selector checks if the account is on a v5 or v6 pricing plan since
  // having a user max differentiates them from older plans.
  const isAccountOnPlanWithUserMax = yield select(
    isAccountOnPlanWithUserMaxSelector
  );

  if (isAccountAtProjectLimit && isAccountOnPlanWithUserMax) {
    yield put(openModal(<HardBlock limitType={limitTypes.PROJECTS} />));
    yield spawn(track, 'action-blocked', { _limit: limitTypes.PROJECTS });
    return;
  }

  yield call(openCreateProjectForm, teamId);
}

export const testExports = {
  openCreateProjectForm,
  handleAddProject,
};

export default [
  takeLatest(LIST_TEAM_ROW.HANDLE_ADD_PROJECT, ({ payload: { teamId } }) =>
    handleAddProject(teamId)
  ),
];
