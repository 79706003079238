import { connect } from 'react-redux';
import { currentProjectSelector } from 'selectors/projects';
import { isCollaboratorOnlySelector } from 'selectors/roles';
import { createNewFolder as onCreateNewFolder } from 'actions/assets';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import { teamEntitiesForAccountIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import MoveAssetsDialog from './MoveAssetsDialog';
import {
  FolderCreationEvents,
  cancel,
  moveAssets,
  copyAssets,
  duplicateAssets,
  setFolderCreationStatus as onSetFolderCreationStatus,
  setUserSelectedId as onSetUserSelectedId,
} from './actions';
import { currentAccountSelector } from '../../../selectors/accounts';
import { moveToFolderSelector } from './selectors';

function mapStateToProps(state, props) {
  const { assetsToMove } = props;
  const assetToMove = assetEntitySelector(state, { assetId: assetsToMove[0] });
  const { id: accountId } = currentAccountSelector(state);
  const currentProject = currentProjectSelector(state);
  const teams = teamEntitiesForAccountIdSelector(state, { accountId });
  const {
    folderCreationState,
    isSaving,
    selectedTreeItem: { id: selectedId },
    selectedTreeItem: { canCreateFolder },
  } = moveToFolderSelector(state);

  return {
    isSaving,
    teams,
    isCollaboratorOnly: isCollaboratorOnlySelector(state, {
      projectId: currentProject.id,
    }),
    currentProject: projectEntitySelector(state, {
      projectId: currentProject.id,
    }),
    currentLocationId: assetToMove.parent_id,
    isNoNewFolderCreation: folderCreationState === FolderCreationEvents.NONE,
    isNamingNewFolder:
      folderCreationState === FolderCreationEvents.IS_BEING_NAMED,
    isNewFolderSaving: folderCreationState === FolderCreationEvents.IS_SAVING,
    isNewFolderCreated: folderCreationState === FolderCreationEvents.IS_CREATED,
    selectedId,
    canCreateFolder,
  };
}

const mapDispatchToProps = {
  closeModal: cancel,
  moveAssets,
  copyAssets,
  duplicateAssets,
  onCreateNewFolder,
  onSetFolderCreationStatus,
  onSetUserSelectedId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveAssetsDialog);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
