import { connect } from 'react-redux';
import { confirmExtendDeletionDate } from 'actions/assets';
import { daysLeftBeforeDeletedByLifecyclePolicySelector } from '@frameio/core/src/lifecyclePolicy/selectors';

import ResetLifecycle from './ResetLifecycle';

function mapStateToProps(state, { assetIds }) {
  return {
    daysLeftBeforeDeleted:
      assetIds.length === 1
        ? daysLeftBeforeDeletedByLifecyclePolicySelector(state, {
            assetId: assetIds[0],
          })
        : 0,
  };
}

const mapDispatchToProps = {
  confirmExtendDeletionDate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetLifecycle);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
