import { createSelector } from 'reselect';
import { type as assetType } from '@frameio/core/src/assets/helpers/constants';
import {
  assetEntitySelector,
  assetEntitiesByAssetIdsSelector,
} from '@frameio/core/src/assets/selectors';

import { rolesEntitiesByProjectSelector } from '@frameio/core/src/roles/selectors';
import { presentationEntityByAssetIdSelector } from '@frameio/core/src/presentations/selectors';
import { exceedsMaxDownloadSize } from 'utils/downloads';
import { REEL_LAYOUT } from 'components/Presentation/layouts';

export const NEW_PRESENTATION_ID = 'new-presentation-link';

export const presentationEditorSelector = (state) => state.presentationEditor;

export const currentPresentationIdSelector = (state) =>
  presentationEditorSelector(state).presentationId;

export const isCurrentPresentationNewSelector = createSelector(
  currentPresentationIdSelector,
  (id) => id === NEW_PRESENTATION_ID
);

/**
 * Gets an array of hydrated presentation assets using an array of assetIds.
 * This selector assumes we know which assets need to be hyrdated ahead of time.
 * @param {Object} state - Redux state.
 * @param {Object} props - Props from react component. Must have an `assetIds` property.
 * @returns {Asset[]} - The list of fully hydrated assets.
 */
export const assetsForPresentationSelector = createSelector(
  [assetEntitiesByAssetIdsSelector],
  (assets) => {
    const nonFolderAssets = assets.filter(
      (item) => item.type !== assetType.FOLDER
    );
    return nonFolderAssets.map((item) => {
      if (item.type === assetType.VERSION_STACK) {
        return item.cover_asset;
      }
      return item;
    });
  }
);
/**
 * Gets roles entity for a specific project
 * @param {Object} state - Redux state.
 * @param {string} projectId - ProjectId.
 * @returns {Object} - Roles entity object.
 */
const rolesEntityByProjectSelector = (state, { projectId }) =>
  rolesEntitiesByProjectSelector(state || {})[projectId];

/**
 * Checks if user has permission to link back to project || asset.
 * @param {Object} state - Redux state.
 * @param {Object} props - Props from react component.
 * Must have `projectId` property.
 * @returns {bool} - True/False has permission.
 */
export const authorizedToLinkBackToAssetSelector = createSelector(
  [rolesEntityByProjectSelector],
  (entities) => {
    const roles = entities && entities.roles;
    return roles && (roles.team_member || roles.collaborator);
  }
);

/**
 * Checks if filesize is over 5GB.
 * @param {Object} state - Redux state.
 * @param {Object} props - Props from react component.
 * Must have `assetId` property.
 * @returns {bool} - True/False over 5GB.
 */
export const exceedsMaxDownloadSizeSelector = createSelector(
  [assetEntitySelector],
  (asset = {}) => exceedsMaxDownloadSize(asset.filesize)
);

export const invitedEntitiesForItemIdSelector = (state) =>
  state.presentationEditor.invitedIds;

export const isBlockingReelPlayerSelector = (state) =>
  state.presentationEditor.isBlockingReelPlayer;

/**
 * When the editor is showing `ReelPlayerBlock`, force the presentation to show
 * the reel layout behind the overlay.
 */
export const presentationForEditorSelector = createSelector(
  presentationEntityByAssetIdSelector,
  isBlockingReelPlayerSelector,
  (presentation, isBlockingReelPlayer) =>
    isBlockingReelPlayer
      ? { ...presentation, layout: REEL_LAYOUT }
      : presentation
);
