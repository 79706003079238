import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const PAYMENT_FLOW = generateActionTypes('PAYMENT_FLOW', [
  'START',
  'CONTINUE',
  'END',
  'SET_ERROR',
  'REPORT_PAYMENT_SUCCESS',
  'REPORT_CALLBACK_FAILURE',
]);

export const startFlow = createAction(
  PAYMENT_FLOW.START,
  (accountId, onPaymentMethodReady, trackingProps) => ({
    accountId,
    onPaymentMethodReady,
    trackingProps,
  })
);

export const continueFlow = createAction(
  PAYMENT_FLOW.CONTINUE,
  (stripeResponse) => ({ stripeResponse })
);

export const endFlow = createAction(PAYMENT_FLOW.END, () => ({}));

export const setPaymentError = createAction(
  PAYMENT_FLOW.SET_ERROR,
  (errorMessage) => ({ errorMessage })
);

export const reportPaymentSuccess = createAction(
  PAYMENT_FLOW.REPORT_PAYMENT_SUCCESS,
  () => ({})
);

export const reportCallbackFailure = createAction(
  PAYMENT_FLOW.REPORT_CALLBACK_FAILURE,
  () => ({})
);
