import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@frameio/vapor';
import ProjectReviewLinkContextMenu from 'pages/ProjectContainer/ProjectLinks/ProjectReviewLinkContextMenu';
import ProjectPresentationsContextMenu from 'pages/ProjectContainer/ProjectPresentations/ProjectPresentationsContextMenu';
import AssetsContextMenu from 'pages/ProjectContainer/AssetContextMenu';
import ProjectContextMenu from 'pages/ProjectContainer/ProjectContextMenu';
import CONTEXT_MENU_TYPES from './contextMenuTypes';

/**
 * Display a context menu
 */

export default function ContextMenuManager({
  contextMenuType,
  isShowing,
  closeContextMenu,
  menuProps,
  x,
  y,
}) {
  const [customTarget, setCustomTarget] = useState(null);

  const handleMenuChange = useCallback(
    (open) => {
      if (!open) {
        closeContextMenu();
      }
    },
    [closeContextMenu]
  );

  useEffect(() => {
    setCustomTarget(
      isShowing
        ? {
            getBoundingClientRect: () => ({
              width: 0,
              height: 0,
              top: y,
              right: x,
              bottom: y,
              left: x,
            }),
          }
        : null
    );
  }, [isShowing, x, y]);

  let ContextMenuComponent;

  switch (contextMenuType) {
    case CONTEXT_MENU_TYPES.PROJECT_ASSETS: {
      ContextMenuComponent = AssetsContextMenu;
      break;
    }
    case CONTEXT_MENU_TYPES.PROJECT: {
      ContextMenuComponent = ProjectContextMenu;
      break;
    }
    case CONTEXT_MENU_TYPES.PROJECT_PRESENTATIONS: {
      ContextMenuComponent = ProjectPresentationsContextMenu;
      break;
    }
    case CONTEXT_MENU_TYPES.PROJECT_REVIEW_LINKS: {
      ContextMenuComponent = ProjectReviewLinkContextMenu;
      break;
    }
    default: {
      return null;
    }
  }

  return (
    <Menu
      shouldUsePortal
      isShowing={!!customTarget}
      anchorPosition={customTarget}
      onRequestChange={handleMenuChange}
      placement="right-start"
      content={<ContextMenuComponent {...menuProps} />}
    />
  );
}

ContextMenuManager.propTypes = {
  closeContextMenu: PropTypes.func.isRequired,
  contextMenuType: PropTypes.string,
  menuProps: PropTypes.object,
  x: PropTypes.number,
  y: PropTypes.number,
};

ContextMenuManager.defaultProps = {
  contextMenuType: null,
  menuProps: {},
  x: 0,
  y: 0,
};
