import { get } from 'lodash';

const EXPIRED = 'Expired';
const DEFAULT_ERROR = { title: 'Not found', status: 404 };

export const FAILURE_REASONS = {
  EXPIRED: 'expired',
  NOT_FOUND: 'not_found',
  PASSWORD_REQUIRED: 'password_required',
  UNKNOWN: 'unknown',
  PASSWORD_REQUIRED_BUT_EMPTY: 'password_required_and_empty',
  FORBIDDEN: 'forbidden',
  EMAIL_UNCONFIRMED: 'unconfirmed',
  NOT_LOGGED_IN: 'not_logged_in',
};

export default function getFailureReason({ payload }) {
  const { status, title, detail } = get(
    payload,
    'error.response.data.errors[0]',
    DEFAULT_ERROR
  );

  /* eslint-disable no-else-return */
  if (status === 404 && title === EXPIRED) {
    return FAILURE_REASONS.EXPIRED;
  } else if (status === 404) {
    return FAILURE_REASONS.NOT_FOUND;
  } else if (status === 401 && get(detail, 'item_count') === 0) {
    return FAILURE_REASONS.PASSWORD_REQUIRED_BUT_EMPTY;
  } else if (status === 401 && title === 'Password Required') {
    return FAILURE_REASONS.PASSWORD_REQUIRED;
  } else if (status === 401 && title === 'Not Authorized') {
    return FAILURE_REASONS.NOT_LOGGED_IN;
  } else if (status === 403 && title === 'Forbidden') {
    return FAILURE_REASONS.FORBIDDEN;
  } else if (status === 403 && title === 'Email Unconfirmed') {
    return FAILURE_REASONS.EMAIL_UNCONFIRMED;
  }
  /* eslint-enable no-else-return */

  return FAILURE_REASONS.UNKNOWN;
}
