import queryString from 'query-string';
import history from 'browserHistory';
import { ADOBE_LOGIN, getPath, WELCOME_URL } from 'URLs';
import config from 'config';
import track from 'analytics';

/**
 * Shallow merges any query parameters into a url.
 * @param {string} url - A url that contains a optional search part.
 * @param {Object} [queryParams] - Any query params to merge in.
 * @returns {string} - Url with a search part, if any.
 */
export function mergeQueryParams(url, queryParams) {
  const urlParts = url.split('?');
  if (urlParts.length > 1) {
    // eslint-disable-next-line no-param-reassign
    url = urlParts[0];
    // eslint-disable-next-line no-param-reassign
    queryParams = {
      ...queryString.parse(urlParts[1]),
      ...queryParams,
    };
  }
  const query = queryString.stringify(queryParams);
  const search = query ? `?${query}` : '';
  return `${url}${search}`;
}

/**
 * Redirects to any url, shallow merging any query parameters into it.
 * @param {string} url - A URL to go to.
 * @param {Object} [queryParams] - Any query params to serialize into the search.
 */
export function redirectTo(url, queryParams) {
  history.replace(mergeQueryParams(url, queryParams));
}

/**
 * Pushes a new url into the router history
 * @param {string} routePath - Any regexable path defined in {@link URLs.js}.
 * @param {Object} [params] - Any named parameters that the routePath accepts.
 * @param {Object} [queryParams] - Any query params to serialize into the search.
 */
export function goToRoute(routePath, params, queryParams) {
  const path = getPath(routePath, params);
  const url = mergeQueryParams(path, queryParams);
  history.push(url);
}

/**
 * When constructing a URL to redirect the user to auth portal, these query params
 * are included as actual query params on the auth portal URL and NOT encoded into
 * the redirect_path query param.
 */
const AUTH_PORTAL_QUERY_PARAM_ALLOWLIST = [
  'utm_source',
  'signup_as_v4_account',
];

/**
 * Redirects to the auth application.
 *
 * During the redirect process, the current path and query params are preserved in the url if
 * shouldReturnToPage is true.
 *
 * @param {Object} params - params to pass to the auth app, as key value pairs
 * @param {Boolean} shouldReturnToPage - whether to keep the current path and search params for
 * the return redirect
 */
export function redirectToAuth(
  params,
  shouldReturnToPage = true,
  trackingOpts = {}
) {
  const { pathname, search, hash } = window.location;

  const parsedQueryParams = queryString.parse(search);

  /**
   * Separate query params based on whether they should be included in the redirect_path
   * or included in the auth portal query params
   */
  const [allowedAuthPortalQueryParams, otherQueryParams] = Object.entries(
    parsedQueryParams
  ).reduce(
    ([allowlisted, other], [key, value]) =>
      AUTH_PORTAL_QUERY_PARAM_ALLOWLIST.includes(key)
        ? [{ ...allowlisted, [key]: value }, other]
        : [allowlisted, { ...other, [key]: value }],
    [{}, {}]
  );

  const hasOtherQueryParams = Object.keys(otherQueryParams).length > 0;
  const redirectQuerystring = hasOtherQueryParams
    ? `?${queryString.stringify(otherQueryParams)}`
    : '';

  const redirectQueryParam = shouldReturnToPage
    ? {
        redirect_path: `${pathname}${redirectQuerystring}${hash}`,
      }
    : {};

  const authPath = mergeQueryParams(WELCOME_URL, {
    auth_callback: config.authCallback,
    ...redirectQueryParam,
    ...allowedAuthPortalQueryParams,
    ...params,
  });

  track('redirected-to-auth', trackingOpts);

  window.location.assign(`${config.authClientHost}${authPath}`);
}

export function redirectToAdobeIms(params) {
  if (!params || !params.userEmail) {
    const shouldReturnToPage = !!params?.originPath;
    return redirectToAuth(params, shouldReturnToPage);
  }

  const authPath = mergeQueryParams(ADOBE_LOGIN, {
    auth_callback: config.authCallback,
    email: params.userEmail,
    redirect_path: params.originPath,
  });

  return window.location.assign(`${config.authClientHost}${authPath}`);
}

/**
 * @param {Object}  options to the next url, shallow merging any query parameters into it.
 * @param {string?} options.path to redirect to
 * @param {Object?} options.params Any query params to serialize into the search.
 */
export function redirectToNext(options = {}) {
  const { path, params } = options;

  const queryPrams = params || {};
  const pathname = path || '/';
  // Immediately hide the V3 app
  document.body.style.background = '#000';
  document.body.innerHTML = '';

  const query = queryString.stringify(queryPrams);
  const search = query ? `?${query}` : '';
  window.location.assign(`${config.nextUrl}${pathname}${search}`);
}

/**
 * Refreshes the current application route.
 */
export function reloadCurrentRoute(clearCache = false) {
  window.location.reload(clearCache);
}
