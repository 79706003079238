import { call, put, take, takeEvery } from 'redux-saga/effects';
import {
  INTERACTION as CORE_INTERACTION,
  InteractionEvents,
  executeInteraction,
} from '@frameio/core/src/interactions/actions';
import { CUSTOM_ACTION as CORE_CUSTOM_ACTION } from '@frameio/core/src/customActions/actions';
import { RECEIVE_EVENT_TYPE } from '@frameio/core/src/shared/actions/helpers';
import {
  joinInteractionRoom,
  leaveInteractionRoom,
} from '@frameio/core/src/sockets/actions';
import { getEntityFromNormalizedResponse } from '@frameio/core/src/shared/utils/entities';
import { prompt } from 'components/Dialog/CustomActionFormDialog/sagas';

/**
 * Subscribe to an interaction.
 * @param {String} interactionId
 */
export function* subscribeToInteraction(interactionId) {
  yield put(joinInteractionRoom(interactionId));

  while (true) {
    const response = yield take(({ type, payload: { event } }) => {
      if (type !== CORE_INTERACTION[RECEIVE_EVENT_TYPE]) return false;
      return event.type === InteractionEvents.InteractionResponded;
    });

    const interaction = getEntityFromNormalizedResponse(
      response.payload.event.data
    );
    const {
      response_body: { title, description, fields },
    } = interaction;

    const data = yield call(prompt, title, description, fields || []);

    // On form "submit" check if the response is a boolean or not.
    // If boolean, the interaction is "complete" and the socket
    // room should be left. Otherwise, submit the form and
    // continue checking for new events.
    if (typeof data !== 'boolean') {
      yield put(executeInteraction(interactionId, data));
    } else {
      return yield put(leaveInteractionRoom(interactionId));
    }
  }
}

export default [
  takeEvery(
    CORE_CUSTOM_ACTION.EXECUTE.SUCCESS,
    ({
      payload: {
        response: { result },
      },
    }) => subscribeToInteraction(result)
  ),
];
