import { call, spawn, select, takeLatest } from 'redux-saga/effects';
import {
  getSharedProject,
  joinProjectAsCollaborator,
} from '@frameio/core/src/projects/sagas';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import { getPath, getProjectUrl, SHARED_PROJECT_URL } from 'URLs';
import track, { getRedirectTrackingOpts } from 'analytics';
import { configureCore, getAuthedUserId } from 'utils/auth';
import { redirectToAuth, redirectTo } from 'utils/router';
import { PROJECT } from 'actions/projects';
import { authenticate } from 'sagas/authedUser';
import { isUserCollaboratorOfProjectSelector } from 'selectors/roles';

function* joinSharedProject(action) {
  // Attempt to auth the user
  yield call(configureCore);
  const { success: isAuthedUser, failure: isUnauthedUser } = yield call(
    authenticate
  );

  // Fetch the shared project
  const { projectId } = action.payload;

  const { failure: getSharedProjectFailure } = yield call(
    getSharedProject,
    projectId
  );

  if (getSharedProjectFailure && isAuthedUser) {
    yield call(redirectTo, getProjectUrl());
    return;
  }

  if (getSharedProjectFailure && isUnauthedUser) {
    yield call(
      redirectToAuth,
      null,
      true,
      getRedirectTrackingOpts({
        details: 'getSharedProjectFailure and user is unauthed',
      })
    );
    return;
  }

  const project = yield select(projectEntitySelector, { projectId });

  // Only public/shareable projects can be fetched by an unauthed user. In
  // this case, send the user to the auth flow to join the project.
  if (isUnauthedUser) {
    const { id, name } = project;
    const to = getPath(SHARED_PROJECT_URL, { id });
    yield call(
      redirectToAuth,
      {
        message: `Join project: ${name}`,
        // Param to come back here to actually join the project below
        redirect_path: to,
      },
      true,
      getRedirectTrackingOpts({
        details: 'getSharedProject success and user is unauthed',
      })
    );
    return;
  }

  const isCollaborator = yield select(isUserCollaboratorOfProjectSelector, {
    projectId,
  });
  const isJoiningProject = project.shared && !isCollaborator;

  // If user is authed, project is joinable and user is not yet a collaborator,
  // call the api to join the project
  if (isJoiningProject) {
    const userId = yield call(getAuthedUserId);
    yield call(joinProjectAsCollaborator, projectId, userId);
    yield spawn(track, 'project-joined-client', { via: 'new url' });
  }

  yield call(redirectTo, getProjectUrl(projectId));
}

export const testExports = { joinSharedProject };

export default [takeLatest(PROJECT.JOIN_SHARED, joinSharedProject)];
