import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';
import { isFetchingActionFactory } from 'factories/isFetching';

export const ADD_CREDIT_CARD = generateActionTypes('ADD_CREDIT_CARD', [
  'IS_FETCHING',
  'ADD',
]);

/**
 * Add a card to the account.
 * @param {Object} stripeResponse - Response from stripe's createToken.
 * @returns {Object} - Object with type and payload.
 */
export const addCardToAccount = createAction(
  ADD_CREDIT_CARD.ADD,
  (stripeResponse, source) => ({ stripeResponse, source })
);

export const isFetching = isFetchingActionFactory(ADD_CREDIT_CARD.IS_FETCHING);
