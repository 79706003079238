import { connect } from 'react-redux';
import { downloadAsset } from 'actions/assets';
import Blog from './Blog';

const mapDispatchToProps = {
  downloadAsset,
};

export default connect(
  null,
  mapDispatchToProps
)(Blog);
