import React from 'react';
import PropTypes from 'prop-types';
import { clamp, zipWith } from 'lodash';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';
import { useField } from 'formik';
import { types as dragTypes } from 'utils/dragAndDrop';
import { getAspectContain } from '@frameio/components/src/components/AspectContain';
import { FrameGuidesInner } from '@frameio/components/src/components/FrameGuides/FrameGuides';
import SessionWatermarkTemplateBlockDragSource from './SessionWatermarkTemplateBlockDragSource';
import SessionWatermarkTemplateBlockDragLayer from './SessionWatermarkTemplateBlockDragLayer';
import getTrueDimensions from './getTrueDimensions';

import getRelativeOffset from './getRelativeOffset';

const getReferencePoint = (x, y) => {
  let sectorX = 'unknown';
  if (x < 1 / 3) {
    sectorX = 'left';
  } else if (x < 2 / 3) {
    sectorX = 'center';
  } else {
    sectorX = 'right';
  }

  let sectorY = 'unknown';
  if (y < 1 / 3) {
    sectorY = 'top';
  } else if (y < 2 / 3) {
    sectorY = 'middle';
  } else {
    sectorY = 'bottom';
  }

  return `${sectorY}_${sectorX}`;
};

const StyledFrameGuidesInnerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  /* HACK */
  .guide-box {
    border-color: transparent;
  }
`;

const StyledFrameGuidesInner = styled((props) => (
  <StyledFrameGuidesInnerWrapper>
    <FrameGuidesInner {...props} />
  </StyledFrameGuidesInnerWrapper>
))``;

const StyledDiv = styled.div`
  position: relative;
  height: ${(p) => p.height}px;
  width: ${(p) => p.width}px;
  overflow: hidden;
`;

const StyledImg = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
  pointer-events: none;
`;

const DebugSectorOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  height: ${(p) => p.height}px;
  width: ${(p) => p.width}px;
  border: 1px dashed white;
  pointer-events: none;

  div {
    border: 1px dashed white;
    height: ${(p) => p.height / 3}px;
    width: ${(p) => p.width / 3}px;
  }
`;

const SessionWatermarkTemplateBlockDropTarget = (props) => {
  const {
    blockIds,
    imgNaturalHeight,
    imgNaturalWidth,
    imgSrc,
    isDebugModeEnabled,
    isFrameGuideEnabled,
    isShareOnlyTemplate,
    maxHeight,
    maxWidth,
    onLoad,
    updateBlock,
  } = props;
  const { height, width } = getAspectContain(
    maxWidth,
    maxHeight,
    `${imgNaturalWidth}:${imgNaturalHeight}`
  );
  const scale = Math.min(width / imgNaturalWidth, 1);

  const [{ value: activeId }] = useField('activeBlockId');

  const [{ value: { rotation } = {} }] = useField(
    `watermark_blocks[${activeId}]`
  );

  const renderBlock = React.useCallback(
    (sessionWatermarkTemplateBlockId) => (
      <SessionWatermarkTemplateBlockDragSource
        key={sessionWatermarkTemplateBlockId}
        id={sessionWatermarkTemplateBlockId}
        isDebugModeEnabled={isDebugModeEnabled}
        isShareOnlyTemplate={isShareOnlyTemplate}
        height={height}
        width={width}
        scale={scale}
      />
    ),
    [height, isDebugModeEnabled, isShareOnlyTemplate, scale, width]
  );

  // eslint-disable-next-line no-unused-vars
  const [__collectedProps, drop] = useDrop({
    accept: dragTypes.SESSION_WATERMARK_TEMPLATE_BLOCK,

    // TODO: refactor this function
    drop: (item, monitor) => {
      const initialOffset = monitor.getInitialSourceClientOffset();
      const currentOffset = monitor.getSourceClientOffset();
      const blockDimensions = item.initialBlockDimensions;
      const trueDimensions = getTrueDimensions(blockDimensions, rotation);
      const positionMax = [width, height];
      const positionOffset = [
        currentOffset.x - initialOffset.x,
        currentOffset.y - initialOffset.y,
      ];

      const positionNormalized = [item.initialX, item.initialY];

      const getNextRelativePosition = (offset, max, initial) =>
        offset / max + initial;
      const nextRelativePosition = zipWith(
        positionOffset,
        positionMax,
        positionNormalized,
        getNextRelativePosition
      );
      const nextReferencePoint = getReferencePoint(...nextRelativePosition);
      const nextReferencePointRelativeOffset = getRelativeOffset(
        nextReferencePoint
      );
      const referencePointRelativeOffset = getRelativeOffset(
        item.initialReferencePoint
      );

      // difference in what percentage of the relative offsets
      const xRelativeOffsetDifference =
        (referencePointRelativeOffset[0] -
          nextReferencePointRelativeOffset[0]) /
        100;
      const yRelativeOffsetDifference =
        (referencePointRelativeOffset[1] -
          nextReferencePointRelativeOffset[1]) /
        100;

      // offset in x and y direction before rotation
      const offsetX = trueDimensions[0] * xRelativeOffsetDifference;
      const offsetY = trueDimensions[1] * yRelativeOffsetDifference;

      const theta = (rotation * Math.PI) / 180;
      // new offset x,y after rotated
      const rotatedOffsetX =
        offsetX * Math.cos(theta) - offsetY * Math.sin(theta);
      const rotatedOffsetY =
        offsetX * Math.sin(theta) + offsetY * Math.cos(theta);

      const nextX =
        item.initialX + (positionOffset[0] + rotatedOffsetX) / width;
      const nextY =
        item.initialY + (positionOffset[1] + rotatedOffsetY) / height;

      const nextValue = {
        position_reference_point: nextReferencePoint,
        position_x: clamp(nextX, 0, 1),
        position_y: clamp(nextY, 0, 1),
        text_alignment: nextReferencePoint.split('_')[1],
      };
      updateBlock(item.id, nextValue);
    },
  });

  return (
    <StyledDiv height={height} ref={drop} width={width}>
      <StyledImg height={height} width={width} src={imgSrc} onLoad={onLoad} />

      {isFrameGuideEnabled && (
        <StyledFrameGuidesInner
          aspectHeight={imgNaturalHeight}
          aspectWidth={imgNaturalWidth}
          measured={{ height, width }}
        />
      )}

      {/* TODO: remove later */}
      {isDebugModeEnabled && (
        <DebugSectorOverlay width={width} height={height}>
          {[...Array(9)].map((i) => (
            <div key={i} />
          ))}
        </DebugSectorOverlay>
      )}

      <SessionWatermarkTemplateBlockDragLayer
        height={height}
        isDebugModeEnabled={isDebugModeEnabled}
        isShareOnlyTemplate={Boolean(isShareOnlyTemplate)}
        scale={scale}
        width={width}
      />
      {width > 1 && blockIds.map(renderBlock)}
    </StyledDiv>
  );
};

SessionWatermarkTemplateBlockDropTarget.propTypes = {
  blockIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  imgNaturalHeight: PropTypes.string.isRequired,
  imgNaturalWidth: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
  isFrameGuideEnabled: PropTypes.bool.isRequired,
  isShareOnlyTemplate: PropTypes.bool.isRequired,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  onLoad: PropTypes.func,
  updateBlock: PropTypes.func.isRequired,
};

export default SessionWatermarkTemplateBlockDropTarget;
