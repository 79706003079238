import React from 'react';
import { useDispatch } from 'react-redux';

import { MenuButton, MenuSeparator } from '@frameio/vapor';

import AddConnectionSVG from '@frameio/components/src/svgs/icons/add-c2c-connection.svg';
import TrashCanIcon from '@frameio/components/src/svgs/icons/trashcan.svg';
import PauseSVG from '@frameio/components/src/svgs/icons/pause-c2c-connections.svg';
import ResumeSVG from '@frameio/components/src/svgs/icons/resume-c2c-connections.svg';

import { openModal } from 'components/Modal/actions';
import {
  confirmDeleteAllProjectDevices,
  togglePauseAllProjectDevices,
} from './actions';
import AddNewCloudDevice from './AuthenticationFlow';

const TEXT = {
  pause: 'Pause all device connections',
  new: 'Add new device',
  forget: 'Forget all devices',
  resume: 'Resume all device connections',
};

const ContextMenu = ({ projectId, projectName }) => {
  const dispatch = useDispatch();

  const togglePauseAll = (pause) => {
    dispatch(togglePauseAllProjectDevices(projectId, pause));
  };

  const addNew = () => {
    dispatch(
      openModal(
        <AddNewCloudDevice projectId={projectId} projectName={projectName} />
      )
    );
  };

  const forgetAll = () => {
    dispatch(confirmDeleteAllProjectDevices(projectId));
  };

  return (
    <React.Fragment>
      <MenuButton
        data-test-id="add-new-device"
        onSelect={addNew}
        iconBefore={<AddConnectionSVG width={16} height={16} />}
      >
        {TEXT.new}
      </MenuButton>
      <MenuButton
        data-test-id="pause-all-devices"
        onSelect={() => togglePauseAll(true)}
        iconBefore={<PauseSVG width={16} height={16} />}
      >
        {TEXT.pause}
      </MenuButton>
      <MenuButton
        data-test-id="resume-all-devices"
        onSelect={() => togglePauseAll(false)}
        iconBefore={<ResumeSVG width={16} height={16} />}
      >
        {TEXT.resume}
      </MenuButton>
      <MenuButton
        data-test-id="forget-all-devices"
        destructive
        onSelect={forgetAll}
        iconBefore={<TrashCanIcon width={16} height={16} />}
      >
        {TEXT.forget}
      </MenuButton>
      <MenuSeparator />
    </React.Fragment>
  );
};

export default ContextMenu;
