import { takeEvery, call, put, select } from 'redux-saga/effects';
import { getTeam } from '@frameio/core/src/teams/sagas';

import { setCurrentTeam } from 'actions/teams';
import { currentTeamSelector } from 'selectors/teams';

import TEAM_CONTAINER from './actions';

function* enterTeam(teamId) {
  const { id: prevTeamId } = yield select(currentTeamSelector);
  if (prevTeamId === teamId) return;

  yield call(getTeam, teamId);
  yield put(setCurrentTeam(teamId, prevTeamId));
}

export default [
  takeEvery(TEAM_CONTAINER.ENTER_TEAM, ({ payload: { teamId } }) =>
    enterTeam(teamId)
  ),
];

export const testExports = {
  enterTeam,
};
