import React, { useCallback, useRef, useEffect, useContext } from 'react';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import Flex from 'styled-flex-component';
import styled from 'styled-components';
import { TreeItem } from '@frameio/components/src/styled-components/TreeView';
import RotatingArrow from '@frameio/components/src/styled-components/TreeView/RotatingArrow';
import { TreeText } from '@frameio/components/src/styled-components/TreeView/TreeItem';
import TreeViewContext from '@frameio/components/src/styled-components/TreeView/TreeViewContext';
import { FolderCreationEvents } from './actions';
import ProjectIcon from './ProjectIcon';
import { StyledTeamIcon, Crumb } from './FolderSearchResults';
import TreeItemLoadingIndicator from './TreeItemLoadingIndicator';
import ConnectedTreeViewItemPhantom from './ConnectedTreeViewItemPhantom';
import MoveAssetsDialogContext from './MoveAssetsDialogContext';
import TreeFolderItem from './TreeFolderItem';

const StyledTreeItemDetails = styled(TreeItem)`
  height: 56px;
  align-items: flex-start;
  padding-top: ${(p) => p.theme.spacing.units(0.5)};
  padding-bottom: ${(p) => p.theme.spacing.units(0.5)};
`;

const LabelContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  overflow: hidden;
  width: 100%;
`;

const StyledTreeText = styled(TreeText)`
  margin-left: ${(p) => p.theme.spacing.units(0.5)};
  overflow: hidden;
  white-space: nowrap;
  margin-top: 0;
`;

const StyledRotatingArrow = styled(RotatingArrow)`
  margin-top: ${(p) => p.theme.spacing.units(0.75)};
`;

/**
 * TreeProjectItem
 *
 * A custom tree item used to display a project and all it's folders.
 */

function TreeProjectItem({
  project,
  showDetails,
  onFetchFolders,
  label, // optionally override the label (for text highlighting)
  folders = {}, // the asset tree for a project + loading status
  onSelect,
  isFolderCreationInProgress,
  isNamingNewFolder,
  isNewFolderSaving,
  isNewFolderCreated,
  onSetFolderCreationStatus,
  selectedId,
  createdId,
  ...other
}) {
  const {
    id,
    name,
    // folder_count: folderCount,
    root_asset_id: rootAssetId,
  } = project;

  const { loading } = folders;
  const canShowPhantomTreeItem = isFolderCreationInProgress;
  const selectedRefTreeView = useRef();
  const { assetsToMove } = useContext(MoveAssetsDialogContext);
  const { onFocus } = useContext(TreeViewContext);

  useEffect(() => {
    if (isFolderCreationInProgress && selectedRefTreeView.current) {
      /**
       * Scroll Into View only on an input element or after
       * a new folder was created. Otherwise we risk of the scrollIntoView
       * Jumping on every click to the selected element.
       */

      selectedRefTreeView.current.scrollIntoView({
        // 'smooth' will trigger an animation, which is nice, but too much on a tree with over 20 sub-folders.
        behavior: 'auto',
        block: 'center',
        inline: 'nearest',
      });
    }
    if (createdId) {
      /**
       * After creation of a new folder:
       * we need to enforce the createdId is focused.
       */
      onFocus(createdId);
    }
  }, [
    isNewFolderCreated,
    onFocus,
    createdId,
    selectedRefTreeView,
    isFolderCreationInProgress,
  ]);

  /**
   * Only attempt to fetch folders for a project
   * if that project actually contains folders.
   * Or if we force fetch: as a result of the store being out of date,
   * after creation of a new folder within this modal
   */

  const handleFetchFolders = useCallback(() => {
    const forceFetch = isNewFolderCreated;

    if (forceFetch) {
      /**
       * When we force a fetch the store will maintain the tree structure
       * otherwise the folder will collapse and reopen during a new load.
       */
      onSetFolderCreationStatus(FolderCreationEvents.IS_FORCE_FETCHING_UPDATE);
    }

    onFetchFolders(id, forceFetch);
  }, [id, isNewFolderCreated, onFetchFolders, onSetFolderCreationStatus]);

  /**
   * Recursively render through our tree to render
   * our various subfolders.
   * @param {*} data children to render
   * @param {*} assetFolderTree our entire asset tree (linked list)
   */

  const renderTree = (data, assetFolderTree) => {
    if (data && data.length) {
      return orderBy(data, (entry) => entry.name.toLowerCase()).map((child) => {
        const children = assetFolderTree[child.id];
        const isSelected = child.id === selectedId;
        const isSharedFolder = child.review_links.length > 0;

        let isDisabled = false;
        // run a loop that breaks.
        for (let i = 0; i < assetsToMove.length; i += 1) {
          if (child.id === assetsToMove[i]) {
            isDisabled = true;
            break;
          }
        }

        const phantomFolder = canShowPhantomTreeItem && isSelected && (
          <ConnectedTreeViewItemPhantom
            key="phantom-folder-not-saved-yet"
            parentFolder={selectedId}
          />
        );

        const treeItemProps =
          canShowPhantomTreeItem && isSelected
            ? {
                isExpanded: true,
                isSelected: false,
                // if this is focused then the phantom item will not be focused.
                isFocused: false,
                showChevron: true,
              }
            : {
                // overriding isFocus breaks the keyboard navigation,
                // We let the focus rest on the prior item
                isSelected,
                showChevron: !isDisabled && children.length > 0,
                isSelectable: !isDisabled,
              };

        return (
          <TreeFolderItem
            assetFolderTree={assetFolderTree}
            childName={child.name}
            folderId={child.id}
            isSharedFolder={isSharedFolder}
            isDisabled={isDisabled}
            isSelected={isSelected}
            key={child.id}
            phantomFolder={phantomFolder}
            renderTree={renderTree}
            selectedRefTreeView={selectedRefTreeView}
            {...treeItemProps}
          >
            {children}
          </TreeFolderItem>
        );
      });
    }
    return null;
  };

  /**
   * Render a loading indicator if loading takes
   * a bit of time.
   */

  const iconAfter = useCallback(
    ({ isExpanded }) => (
      <TreeItemLoadingIndicator isLoading={loading && isExpanded} />
    ),
    [loading]
  );

  /**
   * Determine which label to render. In detail mode, we
   * need to pass additional details. We also need to
   * permit the parent to provide a custom label for text
   * highlighting during queries.
   */

  const customLabel = useCallback(
    ({ isSelected }) => {
      const teamName = project.team ? project.team.name : '';

      if (showDetails) {
        return (
          <LabelContainer>
            <Flex alignCenter>
              <ProjectIcon isSelected={isSelected} />
              <StyledTreeText>{label || name}</StyledTreeText>
            </Flex>
            <Flex>
              <StyledTeamIcon />
              <Crumb showChevron={false}>{teamName}</Crumb>
            </Flex>
          </LabelContainer>
        );
      }

      return <TreeText>{name}</TreeText>;
    },
    [project.team, showDetails, name, label]
  );

  const sharedProps = {
    onMouseDown: handleFetchFolders, // preload our folders on mousedown
    onExpand: handleFetchFolders, // fetch folder tree if expanded with keyboard
    // showChevron: folderCount > 0, For now, let's show chevrons on all project items
    // with the assumption that they contain folders. Once we've properly addressed
    // folder counts within our redux cache, we can be smarter about this.
    showChevron: true,
    nodeId: rootAssetId, // use rootAssetId because this is our target (and not the project id) for moving assets
    iconAfter,
  };

  // render a phantom root folder at a project if a user is selecting to do so:
  // rootAssetId is not accessible until folders is loaded.
  const phantomRootItem = canShowPhantomTreeItem &&
    sharedProps.nodeId === selectedId && (
      <ConnectedTreeViewItemPhantom
        key="phantom-folder-not-saved-yet"
        parentFolder={sharedProps.nodeId}
      />
    );

  if (phantomRootItem) {
    // only include the isExpanded value if phantomRoot exists.
    sharedProps.isExpanded = true;
    sharedProps.isFocused = false;
  }

  /**
   * Originaly we would check if folders.loading,
   * but we want to repurpose the existing tree structure at the current root
   * while we are fetching an update on the tree.
   * Regardless of 'loading' or not, look for the tree and rootAssetID to show:
   */
  const renderedFolderTree =
    folders.tree && folders.tree[rootAssetId]
      ? [phantomRootItem].concat(
          renderTree(folders.tree[rootAssetId], folders.tree)
        )
      : [phantomRootItem];

  // project icon render callback
  const projectIcon = useCallback(({ isSelected }) => {
    return <ProjectIcon isSelected={isSelected} />;
  }, []);

  // render a rotating arrow
  const rotatingArrow = useCallback(({ isExpanded }) => {
    return <StyledRotatingArrow isExpanded={isExpanded} />;
  }, []);

  // Optionally render a project with additional details. This is currently
  // being used in the "project search" view.
  if (showDetails) {
    return (
      <StyledTreeItemDetails
        chevron={rotatingArrow}
        label={customLabel}
        {...other}
        {...sharedProps}
      >
        {renderedFolderTree}
      </StyledTreeItemDetails>
    );
  }

  // Otherwise, render a regular TreeItem
  return (
    <TreeItem
      iconBefore={projectIcon}
      label={customLabel}
      {...other}
      {...sharedProps}
    >
      {renderedFolderTree}
    </TreeItem>
  );
}

TreeProjectItem.propTypes = {
  project: PropTypes.object,
  showDetails: PropTypes.bool,
  onFetchFolders: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  folders: PropTypes.shape({
    loaded: PropTypes.bool,
    error: PropTypes.bool,
    tree: PropTypes.object,
  }),
  isLoading: PropTypes.bool,
  isFolderCreationInProgress: PropTypes.bool.isRequired,
  isNamingNewFolder: PropTypes.bool.isRequired,
  isNewFolderSaving: PropTypes.bool.isRequired,
  isNewFolderCreated: PropTypes.bool.isRequired,
  onSetFolderCreationStatus: PropTypes.func.isRequired,
  selectedId: PropTypes.string,
  createdId: PropTypes.string,
};

export default React.memo(TreeProjectItem);
