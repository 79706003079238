import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import { firstCardEntityByAccountIdSelector } from '@frameio/core/src/cards/selectors';
import { planEntityForAccountIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { isTaxLocationDeterminedSelector } from 'selectors/accounts';
import { subscriptionEntityByAccountIdSelector } from 'selectors/subscriptions';
import { PLAN_TIERS } from 'components/SelectPlanFlow/PlanDisplay';
import { PAYMENT_METHODS } from 'pages/SettingsContainer/BillingContainer/Payment/Payment';
import { isAccountAdminSelector } from 'selectors/roles';
import {
  getTaxCollectionPhase,
  hasSeenTaxInfo,
  getTaxDeadlineOverride,
  getTaxLocationDeterminedOverride,
} from './helpers';
import { TAX_COLLECTION_PHASES } from './constants';

/*
* State per phase 
*
+---------+-------------------------+------------------------+
|  PHASE  |          Admin          |       Non-Admin        |
+---------+-------------------------+------------------------+
| PHASE 1 | Non-dismissible Banner  | N/A                    |
| PHASE 2 | Dismissible Modal       | Non-dismissible Banner |
| PHASE 3 | Non-dismissible Modal   | Non-dismissible Modal  |
+---------+-------------------------+------------------------+
*/
// override deadline or use constant
const taxCollectionDeadline = getTaxDeadlineOverride();
// override phase (this will change phase irregardless of deadline)
export const taxCollectionPhase = getTaxCollectionPhase(taxCollectionDeadline);

const isAccountOnNonCCTrialSelector = (state, { accountId }) => {
  if (!accountId) return false;
  const subscription = subscriptionEntityByAccountIdSelector(state, accountId);
  const onTrial = !!subscription?.on_trial;
  const card = firstCardEntityByAccountIdSelector(state, { accountId });
  return onTrial && !card;
};

const isQualifiedPlanForSalesTaxCollectionSelector = (state, { accountId }) => {
  if (!accountId) return false;
  const plan = planEntityForAccountIdSelector(state, { accountId });
  const isAccountOnNonCCTrial = isAccountOnNonCCTrialSelector(state, {
    accountId,
  });
  return (
    plan &&
    plan.tier !== PLAN_TIERS.ENTERPRISE &&
    plan.tier !== PLAN_TIERS.FREE &&
    plan.title !== 'Free' &&
    plan.payment_method === PAYMENT_METHODS.STRIPE &&
    !isAccountOnNonCCTrial
  );
};

const isAccountUnpaidSelector = (state, { accountId }) => {
  if (!accountId) return false;
  const account = accountEntitySelector(state, { accountId });
  const subscription = subscriptionEntityByAccountIdSelector(state, accountId);
  const isAccountUnpaid = !!account?.unpaid_at;
  const hasOverdueBalance = !!subscription?.balance;

  return isAccountUnpaid || hasOverdueBalance;
};

const isQualifiedAccountForSalesTaxCollectionSelector = (
  state,
  { accountId }
) => {
  const taxLocationDeterminedOverride = getTaxLocationDeterminedOverride();
  const isAccountPlanQualified = isQualifiedPlanForSalesTaxCollectionSelector(
    state,
    {
      accountId,
    }
  );

  const isAccountUnpaid = isAccountUnpaidSelector(state, { accountId });

  const isTaxLocationDetermined =
    typeof taxLocationDeterminedOverride === 'boolean'
      ? taxLocationDeterminedOverride
      : isTaxLocationDeterminedSelector(state, { accountId });

  return !isAccountUnpaid && isAccountPlanQualified && !isTaxLocationDetermined;
};

export const isModalDismissible =
  taxCollectionPhase === TAX_COLLECTION_PHASES.TWO ||
  taxCollectionPhase === TAX_COLLECTION_PHASES.ONE;

export const shouldShowBillingInfoBannerSelector = (state, { accountId }) => {
  // only show banner for admins and non-admins in phase 2
  if (taxCollectionPhase === TAX_COLLECTION_PHASES.TWO) {
    return isQualifiedAccountForSalesTaxCollectionSelector(state, {
      accountId,
    });
  }
  return (
    isQualifiedAccountForSalesTaxCollectionSelector(state, { accountId }) &&
    isAccountAdminSelector(state, { accountId }) &&
    taxCollectionPhase === TAX_COLLECTION_PHASES.ONE
  );
};

export const isUpdateBillingInfoModalOpened = (state) =>
  state?.updateBillingInfo?.isOpen;

export const shouldUpdateBillingInfoSelector = (state, { accountId }) => {
  // only show dismissible modal to admins in phase 2
  if (
    isAccountAdminSelector(state, { accountId }) &&
    taxCollectionPhase === TAX_COLLECTION_PHASES.TWO
  ) {
    return (
      isQualifiedAccountForSalesTaxCollectionSelector(state, { accountId }) &&
      // dismiss modal for the length of admin's session if "later" button has been pressed
      !hasSeenTaxInfo('modal', { accountId })
    );
  }
  return (
    isQualifiedAccountForSalesTaxCollectionSelector(state, { accountId }) &&
    !isModalDismissible &&
    // make sure we are in a tax collection phase
    taxCollectionPhase === TAX_COLLECTION_PHASES.THREE
  );
};

export const shouldShowBillingInfoModalSelector = (state, { accountId }) =>
  shouldUpdateBillingInfoSelector(state, { accountId }) ||
  isUpdateBillingInfoModalOpened(state);

export const isFetchingSelector = (state) => state.updateBillingInfo.isFetching;

export function isPaymentMethodStripeSelector(state, { accountId }) {
  if (!accountId) return false;

  const plan = planEntityForAccountIdSelector(state, { accountId });

  return plan.payment_method === PAYMENT_METHODS.STRIPE;
}

export const testExports = {
  isQualifiedPlanForSalesTaxCollectionSelector,
  isQualifiedAccountForSalesTaxCollectionSelector,
};
