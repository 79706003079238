import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const PROJECT_FORMS = generateActionTypes('PROJECT_FORMS', [
  'CREATE',
  'UPDATE',
  'DELETE',
]);

/**
 * Creates a project and sets it's setting with the params.
 * @param   {string} teamId - Team backend id.
 * @param   {Object} params - Project preferences.
 * @returns {Object} - Object with type and a payload with teamId and params.
 */
export const createProject = createAction(
  PROJECT_FORMS.CREATE,
  (teamId, params) => ({ teamId, params })
);

/**
 * Updates a project.
 * @param   {string} projectId - Project's backend id.
 * @param   {Object} params - Project preferences.
 * @returns {Object} - Object with type and payload.
 */
export const updateProject = createAction(
  PROJECT_FORMS.UPDATE,
  (projectId, params) => ({ projectId, params })
);

/**
 * Dispatches an action that displays a warning modal which asks
 * the user to confirm the project deletion.
 * @param   {string} projectId - Project's backend id.
 * @param   {string} projectName - Project's name, you guessed it.
 * @returns {Object} - Object with type and a payload with projectId.
 */
export const confirmDeleteProject = createAction(
  PROJECT_FORMS.DELETE,
  (projectId, projectName) => ({ projectId, projectName })
);
