import React from 'react';
import styled from 'styled-components';
import {
  DATA_POINT_DEFAULT_VALUES,
  TEXT_SIZE_FACTOR,
  LINE_HEIGHT_FACTOR,
} from './constants';
import SessionWatermarkTemplateBlockMarquee from './SessionWatermarkTemplateBlockMarquee';

const StyledDiv = styled.div`
  color: ${(p) => p.textColor};
  font-size: ${(p) => TEXT_SIZE_FACTOR[p.textSize]}px;
  line-height: ${(p) => LINE_HEIGHT_FACTOR[p.textSize]}px;
  font-weight: 700;
  white-space: nowrap;
  text-shadow: ${(p) => (p.isShadowEnabled ? '-1px 1px 0 black' : 'none')};
`;

const SessionWatermarkTemplateBlock = (props) => {
  const {
    isAnimationPaused,
    positionMaxX,
    scaleX,
    value: {
      alpha,
      data_points: dataPoints = {},
      font_size: textSize,
      scroll_text: textScrollDirection,
      text_color: textColor = '#FFFFFF',
      text_shadow: textShadow,
    } = {},
  } = props;
  const lines = Object.values(dataPoints)
    .filter((dataPoint) => dataPoint.enabled === true)
    .sort((a, b) => a.order - b.order)
    .map(({ type, value }) => value || DATA_POINT_DEFAULT_VALUES[type])
    .map((value) => (typeof value === 'function' ? value() : value));

  return (
    <StyledDiv
      isShadowEnabled={textShadow}
      style={{ opacity: alpha }}
      textColor={textColor}
      textSize={textSize}
    >
      {textScrollDirection === 'none' ? (
        lines.map((value, i) => <div key={i}>{value}</div>)
      ) : (
        <SessionWatermarkTemplateBlockMarquee
          isAnimationPaused={isAnimationPaused}
          isReverse={textScrollDirection === 'rtl'}
          scaleX={scaleX}
          targetPosition={Math.round(positionMaxX)}
        >
          {lines.join(' | ')}
        </SessionWatermarkTemplateBlockMarquee>
      )}
    </StyledDiv>
  );
};

export default SessionWatermarkTemplateBlock;
