import { connect } from 'react-redux';
import PersonRow from 'components/ReviewLinkEditor/PersonRow';
import { invitedEntityAndTypeForId } from '../../../ReviewLinkEditor/selectors';
import { deleteInvitee } from '../../actions';

export function mapStateToProps(state, { entityId }) {
  return invitedEntityAndTypeForId(state, { entityId });
}

const mapDispatchToProps = {
  deleteInvitee,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonRow);
