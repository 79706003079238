import React from 'react';
import Flex from 'styled-flex-component';
import PropTypes from 'prop-types';
import Media from 'react-media';
import styled from 'styled-components';

import Badge from '@frameio/components/src/styled-components/Badge';
import { iconSizes } from '@frameio/components/src/utils/sizes';
import { timeToStandard } from '@frameio/components/src/components/PlayerSubcomponents/PlayerInterface';

import { CardOverlay } from '@frameio/components/src/styled-components/Card';
import { label as WORKFLOW_STATUSES } from '@frameio/core/src/assets/helpers/constants';
import LockIcon from '@frameio/components/src/svgs/icons/lock-filled.svg';
import ArchiveIcon from '@frameio/components/src/svgs/icons/16/archive-filled.svg';
import AssetWorkflowStatusLabel, {
  Indicator as StatusIndicator,
} from 'components/AssetWorkflowStatus/AssetWorkflowStatusLabel';

import { SMALL } from 'utils/mediaQueries';
import WorkfrontApprovalStatusLabel from 'components/AssetWorkfrontApprovalStatus/WorkfrontApprovalStatusLabel';

const SecondaryBadges = styled(Flex).attrs(() => ({
  justifyBetween: true,
  alignCenter: true,
}))`
  width: 100%;

  ${Badge} + ${Badge}{
    margin-left: ${(p) => p.theme.spacing.micro};
  }
`;

const StyledWorkfrontBadge = styled(Badge)`
  display: inline-block;
  max-width: 175px;
  padding: 2px 4px;
`;

function FileCardOverlay({
  assetIsPrivate,
  assetIsArchived,
  isWorkfrontConnected,
  workflowStatus,
  workfrontApprovalStatus,
  duration,
  children,
  ...rest
}) {
  const isWorkflowStatus =
    workflowStatus && workflowStatus !== WORKFLOW_STATUSES.NONE;
  return (
    <CardOverlay {...rest}>
      <Flex>
        {isWorkflowStatus && !isWorkfrontConnected ? (
          <Flex alignCenter>
            <Badge>
              <Media query={SMALL}>
                {(isSmall) =>
                  isSmall ? (
                    <StatusIndicator isLarge />
                  ) : (
                    <AssetWorkflowStatusLabel
                      status={workflowStatus}
                      useLargeIndicator
                    />
                  )
                }
              </Media>
            </Badge>
          </Flex>
        ) : (
          isWorkfrontConnected && (
            <StyledWorkfrontBadge>
              <WorkfrontApprovalStatusLabel
                size="16px"
                workfrontApprovalStatus={workfrontApprovalStatus}
                iconColor="#ec1000"
              />
            </StyledWorkfrontBadge>
          )
        )}
        {children}
      </Flex>
      <SecondaryBadges>
        <Flex alignCenter>
          {assetIsPrivate && (
            <Badge>
              <LockIcon width={iconSizes.S} height={iconSizes.S} />
            </Badge>
          )}
          {assetIsArchived && (
            <Badge>
              <ArchiveIcon width={iconSizes.S} height={iconSizes.S} />
            </Badge>
          )}
        </Flex>
        <div>
          {duration > 0 && (
            <Badge test-data-id="badge-time">{timeToStandard(duration)}</Badge>
          )}
        </div>
      </SecondaryBadges>
    </CardOverlay>
  );
}

export default React.memo(FileCardOverlay);

FileCardOverlay.propTypes = {
  duration: PropTypes.number,
  workflowStatus: PropTypes.oneOf(Object.values(WORKFLOW_STATUSES)),
  assetIsArchived: PropTypes.bool,
  assetIsPrivate: PropTypes.bool,
  children: PropTypes.node,
};

FileCardOverlay.defaultProps = {
  children: undefined,
};
