import React from 'react';
import styled, { css } from 'styled-components';
import Media from 'react-media';
import { shade, size } from 'polished';
import { FadeTransition } from 'react-transition-components';
import PropTypes from 'prop-types';
import Flex from 'styled-flex-component';
import { color } from '@frameio/components/src/theme/darkTheme';
import Button from '@frameio/components/src/styled-components/Button';
import DownloadIcon from '@frameio/components/src/svgs/icons/download.svg';
import {
  highestDownloadResolution,
  isDownloadDisabledCheck,
} from 'utils/downloads';
import { MEDIUM_DOWN, SMALL } from 'utils/mediaQueries';
import { REEL_LAYOUT } from 'components/Presentation/layouts';
import { trackButtonClick } from 'analytics';
import { Tooltip } from '@frameio/vapor';

const tooltipMessage = 'Download single asset';
const PROJECT_LOGO_SIZE = '50px';
const PRESENTATION_SPACING = '20px';
const TRANSITION_SETTINGS = '0.2s ease-in-out';
const HEADER_OPACITY = 0.7;
export const DOWNLOAD_ICON_SIZE = 16;

const assetTextStyles = css`
  font-size: ${(p) => p.theme.fontSize[2]};
  font-weight: bold;
  user-select: text;
`;

const Logo = styled.div`
  ${size(PROJECT_LOGO_SIZE)};
  position: relative;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  background-image: url(${({ src }) => src});
  border-radius: ${(p) => p.theme.radius.circle};
  border-radius: 6px;
  margin: 0 auto;
  display: ${({ src }) => !src && 'none'};
  user-select: text;
`;

const AssetCount = styled.h3`
  ${assetTextStyles};
  color: ${({ textColor }) => textColor};
  transition: color ${TRANSITION_SETTINGS};
  opacity: ${HEADER_OPACITY};
  white-space: no-wrap;
`;

export const IconDownloadButton = styled(Button)`
  background-color: ${({ accentColor }) => accentColor};
  box-shadow: none;
  padding: 0;
  min-width: ${(p) => p.theme.spacing.large};

  // needed to override hover styles
  &:hover:not(:active):not(:disabled) {
    background-color: ${({ accentColor }) => shade(0.9, accentColor)};
  }

  // needed to override active styles
  &:active,
  &:active:not(:disabled) {
    background-color: ${({ accentColor }) => shade(0.8, accentColor)};
  }
`;

const DownloadButton = styled(Button)`
  background-color: ${({ accentColor }) => accentColor};
  transition: background-color ${TRANSITION_SETTINGS};
  box-shadow: none;
  color: ${(p) => p.theme.color.white};

  // needed to override hover styles
  &:hover:not(:active):not(:disabled) {
    background-color: ${({ accentColor }) => shade(0.9, accentColor)};
  }

  // needed to override active styles
  &:active,
  &:active:not(:disabled) {
    background-color: ${({ accentColor }) => shade(0.8, accentColor)};
  }
`;

const DownloadButtonWrapper = styled(Flex)`
  ${IconDownloadButton} {
    margin-left: ${(p) => p.theme.spacing.small};
  }
`;

const TitleWrapper = styled(Flex)``;

const reelPlayerStyles = css`
  @media ${MEDIUM_DOWN} {
    ${DownloadButtonWrapper} {
      position: absolute;
      right: ${PRESENTATION_SPACING};
      top: 90px;
    }

    @media ${MEDIUM_DOWN} and (orientation: landscape) {
      ${AssetCount},
      ${DownloadButtonWrapper} {
        display: none;
      }

      ${Logo} {
        ${size('36px')};
      }

      ${TitleWrapper} {
        align-items: center;
      }
    }
  }
`;

const blogStyles = css`
  padding: 0;
  flex-direction: column;
  align-items: center;

  ${TitleWrapper} {
    flex-direction: column;
    text-align: center;
  }

  ${Logo} {
    margin: auto;
    align-self: center;
    margin-bottom: ${(p) => p.theme.spacing.medium};
  }

  ${DownloadButtonWrapper} {
    margin-top: ${(p) => p.theme.spacing.medium};
  }
`;

const Header = styled(Flex)`
  flex-shrink: 0;
  padding: ${({ theme }) =>
    `calc(${theme.spacing.medium} - ${theme.spacing.micro})`};

  ${AssetCount} {
    margin-top: ${(p) => p.theme.spacing.micro};
  }

  ${Logo} {
    margin-right: ${PRESENTATION_SPACING};
  }

  ${IconDownloadButton} {
    display: ${({ isReelLayout }) => !isReelLayout && 'none'};
  }

  ${({ isReelLayout, hasTouchEvents }) =>
    isReelLayout ? hasTouchEvents && reelPlayerStyles : blogStyles};
`;

const PresentationHeader = ({
  presentation,
  assets,
  downloadAsset,
  handlePresentationBulkDownload,
  isForensicFallbackEnabled,
  presentationTitle,
  logoSrc,
  selectedAssetIndex,
  trackingPage,
  colors: { textColor, accentColor },
  className,
}) => {
  const { can_download: canDownload, layout } = presentation;
  const assetCount = assets.length;
  const isReelLayout = layout === REEL_LAYOUT;
  const selectedAsset = assets[selectedAssetIndex];
  const isDownloadDisabled = isDownloadDisabledCheck(
    selectedAsset,
    isForensicFallbackEnabled,
    highestDownloadResolution(selectedAsset)
  );

  return (
    <Header
      justifyBetween
      isReelLayout={isReelLayout}
      className={className}
      hasTouchEvents={Modernizr.touchevents}
    >
      <TitleWrapper justifyBetween>
        <Logo src={logoSrc} />
        <Flex column justifyBetween>
          {presentationTitle}
          <AssetCount textColor={textColor}>
            {assetCount} {assetCount === 1 ? 'item' : 'items'}
          </AssetCount>
        </Flex>
      </TitleWrapper>
      <FadeTransition in={canDownload}>
        <DownloadButtonWrapper>
          {assetCount > 1 && (
            <DownloadButton
              accentColor={accentColor}
              onClick={() => {
                trackButtonClick('download all', trackingPage, 'bottom');
                handlePresentationBulkDownload(presentation);
              }}
            >
              Download All
            </DownloadButton>
          )}
          {isReelLayout && selectedAsset && (
            <Media query={SMALL}>
              {(isSmallScreen) => (
                <Tooltip
                  disabled={isSmallScreen}
                  title={tooltipMessage}
                  position="bottom"
                >
                  <IconDownloadButton
                    accentColor={accentColor}
                    disabled={isDownloadDisabled}
                    onClick={() => {
                      trackButtonClick('download', trackingPage, 'bottom');
                      downloadAsset(selectedAsset.id);
                    }}
                  >
                    <DownloadIcon
                      height={DOWNLOAD_ICON_SIZE}
                      width={DOWNLOAD_ICON_SIZE}
                      color={color.white}
                    />
                  </IconDownloadButton>
                </Tooltip>
              )}
            </Media>
          )}
        </DownloadButtonWrapper>
      </FadeTransition>
    </Header>
  );
};

PresentationHeader.defaultProps = {
  className: '',
  presentationTitle: undefined,
  logoSrc: undefined,
};

PresentationHeader.propTypes = {
  trackingPage: PropTypes.string.isRequired,
  className: PropTypes.string,
  colors: PropTypes.shape({
    accentColor: PropTypes.string,
    textColor: PropTypes.string,
  }).isRequired,
  downloadAsset: PropTypes.func.isRequired,
  presentation: PropTypes.object.isRequired,
  assets: PropTypes.array.isRequired,
  selectedAssetIndex: PropTypes.number.isRequired,
  logoSrc: PropTypes.string,
  presentationTitle: PropTypes.element,
  handlePresentationBulkDownload: PropTypes.func.isRequired,
};

export default styled(PresentationHeader)``;

export const testExports = {
  AssetCount,
  Logo,
  DownloadButtonWrapper,
  DownloadButton,
  IconDownloadButton,
};
