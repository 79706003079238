import { DropTarget } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import DropZone from './DropZone';

// Connect `onDrop` callback
const spec = {
  drop(props, monitor) {
    props.onDrop(monitor.getItem());
  },
};

const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
});

export default DropTarget(NativeTypes.FILE, spec, collect)(DropZone);

export const testExports = {
  DropZoneSpec: spec,
};
