import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { FAILED_TASK_MESSAGE } from './integrationConstants';

const StyledTaskTitleContainer = styled(Flex)`
  align-items: flex-start;
  font-size: 14px;
  line-height: 16px;
  cursor: default;
  font-family: 'Avenir Next', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
  background-color: #f7f8f9;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin-top: -8px;
  margin-bottom: ${(p) => p.$hasNoTaskDetails && `-8px;`};
`;

const StyledTaskBackground = styled(Flex)`
  flex-direction: column;
  width: auto;
  gap: 4px;
  padding: 10px 12px;
`;

const StyledTaskName = styled(Flex)`
  line-height: 22px;
  color: ${(p) => p.theme.color.coolBlack};
`;

const WorkfrontManageTaskSubmenu = ({
  isWorkfrontConnected,
  workfrontTaskName,
}) =>
  isWorkfrontConnected ? (
    <StyledTaskTitleContainer
      $hasNoTaskDetails={workfrontTaskName === FAILED_TASK_MESSAGE}
    >
      <StyledTaskBackground>
        <StyledTaskName>{workfrontTaskName}</StyledTaskName>
      </StyledTaskBackground>
    </StyledTaskTitleContainer>
  ) : null;

export default WorkfrontManageTaskSubmenu;
