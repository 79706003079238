export const countriesThatForbidYearlyPlan = {
  BR: 'Brazil',
};

export function isYearlyPlanForbiddenInCountry(countryCode) {
  return countriesThatForbidYearlyPlan[countryCode];
}

export const usaStatesThatForbidYearlyPlan = {
  VT: 'Vermont',
};

export function isYearlyPlanForbiddenInUsaState(stateCode) {
  return usaStatesThatForbidYearlyPlan[stateCode];
}
