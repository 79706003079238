import { useState, useCallback, useEffect } from 'react';

/**
 * A hook for manually updating the ShadowContainer scroll
 * metrics. This is a bit of a hack until ShadowContainer
 * monitors the height of its child and updates accordingly.
 * @param {Function} onScroll function provided by ShadowContainer
 * @param {Boolean} updateOnMount should it reset on mount?
 */

function useUpdateShadowContainer(onScroll) {
  const [el, setEl] = useState(null);

  const callbackRef = useCallback((node) => {
    setEl(node);
  }, []);

  // manually read the scrollTop and scrollHeight props
  // to update the shadow container
  const onUpdate = useCallback(() => {
    onScroll({
      target: el
        ? { scrollHeight: el.scrollHeight, scrollTop: el.scrollTop }
        : { scrollHeight: 0, scrollTop: 0 },
    });
  }, [onScroll, el]);

  // force update whenever our scroll element changes or on mount
  useEffect(() => {
    onUpdate();
  }, [onUpdate, el]);

  return {
    ref: callbackRef,
    onUpdate,
  };
}

export default useUpdateShadowContainer;
