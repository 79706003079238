import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import { MEDIUM_UP } from 'utils/mediaQueries';
import Settings, { shareTabs } from './Settings';

const StyledForm = styled.form`
  color: ${(p) => p.theme.color.gray};
  width: ${(p) => p.theme.spacing.units(42)};

  @media ${MEDIUM_UP} {
    width: ${(p) => p.theme.spacing.units(50)};
  }
`;

const StyledModalCloseButton = styled(ModalCloseButton)`
  top: 18px;
  right: 18px;
`;

const LegacyReviewLinkEditorForm = ({ exitEditor, handleSubmit, errors }) => {
  return (
    <StyledForm
      onSubmit={handleSubmit}
      onKeyDown={(evt) => {
        /*
          We need to prevent the default click/submission behavior on Enter
          keypress for any buttons in this form for which ‘type‘ attribute of
          ‘button‘ cannot easily be set (e.g. I'm looking atchu, TabSelect tabs)
        */
        if (evt.key === 'Enter') evt.preventDefault();
      }}
    >
      <StyledModalCloseButton onClick={exitEditor} type="button" />
      <Settings errors={errors} />
    </StyledForm>
  );
};

LegacyReviewLinkEditorForm.propTypes = {
  // Props from component
  exitEditor: PropTypes.func.isRequired,

  // review link entity
  reviewLink: PropTypes.shape({
    allow_approvals: PropTypes.bool.isRequired,
    current_version_only: PropTypes.bool.isRequired,
    enable_comments: PropTypes.bool.isRequired,
    expires_at: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    password: PropTypes.string,
    project_id: PropTypes.string.isRequired,
  }),

  // Props from withFormik
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

LegacyReviewLinkEditorForm.defaultProps = {
  expiresAt: null,
  password: null,
};

const withFormikConfig = {
  mapPropsToValues: ({
    reviewLink: {
      allow_approvals: allowApprovals,
      current_version_only,
      enable_comments: allowComments,
      enable_downloading: allowDownloads,
      expires_at: expiresAt,
      id,
      name,
      password,
    },
  }) => ({
    allowApprovals,
    allowComments,
    allowDownloads,
    emailMessage: '',
    expiresAt,
    id,
    name,
    password,
    selectedShareTab: shareTabs[0].key,
    showAdvancedSettings: !!expiresAt || !!password,
    showAllVersions: !current_version_only,
    showEmailMessage: false,
    showLinkExpiration: !!expiresAt,
    showPassword: !!password,
    userTokens: [],
  }),
  handleSubmit: (values, { props }) => {
    const { reviewLink, updateReviewLinkSettings } = props;
    const {
      allowApprovals: allow_approvals,
      allowComments: enable_comments,
      allowDownloads: enable_downloading,
      expiresAt: expires_at,
      name,
      password,
      showAllVersions,
    } = values;

    updateReviewLinkSettings(reviewLink.id, {
      allow_approvals,
      current_version_only: !showAllVersions,
      enable_comments,
      enable_downloading,
      expires_at: expires_at || null,
      name,
      password: password || null,
    });
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Title should not be blank'),
  }),
};

export default withFormik(withFormikConfig)(LegacyReviewLinkEditorForm);

export const testExports = {
  LegacyReviewLinkEditorForm,
  Settings,
  StyledModalCloseButton,
};
