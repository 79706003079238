import { isFetchingReducerFactory } from 'factories/isFetching';
import { ADD_CREDIT_CARD } from './actions';

const isFetchingReducer = isFetchingReducerFactory(ADD_CREDIT_CARD.IS_FETCHING);

const initialState = {
  isFetching: false,
};

export default function updatePaymentMethod(state = initialState, action) {
  switch (action.type) {
    default:
      return isFetchingReducer(state, action);
  }
}
