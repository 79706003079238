import React, { memo } from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  margin-top: ${(p) => p.theme.spacing.medium};
  margin-bottom: ${(p) => p.theme.spacing.tiny};
  ${(p) => p.theme.fontStyle.bodyM};
  white-space: nowrap;
`;

const Title = ({ children }) => <StyledDiv>{children}</StyledDiv>;

export default memo(Title);
