import { createSelector } from 'reselect';
import {
  paginatedListAllResultsSelector,
  shouldFetchListPageSelector,
  paginatedListTotalCountSelector,
} from '@frameio/core/src/shared/selectors';
import settingsSelector from '../selectors';

/** Shared Selectors */

const peopleSettingsSelector = (state) => settingsSelector(state).people;

export default peopleSettingsSelector;

/** Collaborators and Reviewers Users List  */

export const collaboratorsSelector = (state) =>
  peopleSettingsSelector(state).collaborators;
export const reviewersSelector = (state) =>
  peopleSettingsSelector(state).reviewers;

export const collaboratorUserIdsSelector = createSelector(
  collaboratorsSelector,
  paginatedListAllResultsSelector
);

export const reviewerUserIdsSelector = createSelector(
  reviewersSelector,
  paginatedListAllResultsSelector
);

export function shouldFetchCollaboratorsPageSelector(state, { page }) {
  const slice = collaboratorsSelector(state);
  return shouldFetchListPageSelector(slice, { page });
}

export function shouldFetchReviewersPageSelector(state, { page }) {
  const slice = reviewersSelector(state);
  return shouldFetchListPageSelector(slice, { page });
}

export const totalCollaboratorCountSelector = createSelector(
  collaboratorsSelector,
  paginatedListTotalCountSelector
);

export const totalReviewerCountSelector = createSelector(
  reviewersSelector,
  paginatedListTotalCountSelector
);

/** Pending Collaborators and Reviewers List  */

const pendingCollaboratorsSelector = (state) =>
  peopleSettingsSelector(state).pendingCollaborators;

const pendingReviewersSelector = (state) =>
  peopleSettingsSelector(state).pendingReviewers;

export const pendingCollaboratorIdsSelector = createSelector(
  pendingCollaboratorsSelector,
  paginatedListAllResultsSelector
);

export const pendingReviewerIdsSelector = createSelector(
  pendingReviewersSelector,
  paginatedListAllResultsSelector
);

export function shouldFetchPendingCollaboratorsPageSelector(state, { page }) {
  const slice = pendingCollaboratorsSelector(state);
  return shouldFetchListPageSelector(slice, { page });
}

export function shouldFetchPendingReviewersPageSelector(state, { page }) {
  const slice = pendingReviewersSelector(state);
  return shouldFetchListPageSelector(slice, { page });
}

export const totalPendingCollaboratorCountSelector = createSelector(
  pendingCollaboratorsSelector,
  paginatedListTotalCountSelector
);

export const totalPendingReviewerCountSelector = createSelector(
  pendingReviewersSelector,
  paginatedListTotalCountSelector
);
