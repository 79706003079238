import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const DEVELOPMENT_TOOLS = generateActionTypes('DEVELOPMENT_TOOLS', [
  'CLONE_PROJECT',
  'COPY_VERSION',
  'OPEN_MODAL',
]);

export const openDevToolsModal = createAction(
  DEVELOPMENT_TOOLS.OPEN_MODAL,
  () => ({})
);

export const cloneProject = createAction(
  DEVELOPMENT_TOOLS.CLONE_PROJECT,
  (projectId) => ({ projectId })
);

/**
 * @param {string} version
 * @returns {Object} action
 */
export const copyVersion = createAction(
  DEVELOPMENT_TOOLS.COPY_VERSION,
  (version) => ({ version })
);
