import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { PopoverMenuSeparator } from '@frameio/components/src/styled-components/PopoverMenu';
import SortMenu from 'components/SortMenu';
import { allFeatures } from 'planFeatures';
import SORT_OPTIONS, { SORT_CONST } from './sortOptions';

const StyledFlex = styled(Flex)`
  ${(p) => p.theme.fontStyle.body};
  color: ${(p) => p.theme.color.almostBlack};
  padding-top: 6px;
  padding-bottom: 6px;
  align-items: center;
`;

const StyledFlexItem = styled(Flex)`
  min-width: 200px;
`;

const StyledPopoverMenuSeparator = styled(PopoverMenuSeparator)`
  margin: ${(p) => p.theme.spacing.micro} 0;
  border-color: ${(p) => p.theme.color.coldWhite};
`;

const SharePanelSettingsSortDropdown = ({
  canUseCustomSort,
  label,
  onChange,
  onSelectSortBy,
  isDescending: isDescendingProp,
  sortBy: sortByProp,
  maybeStartUpgradePlanFlow,
}) => {
  const setChange = (name, value) => onChange({ target: { name, value } });

  const setSelectSortBy = (name, value) =>
    onSelectSortBy({ target: { name, value } });

  const handleSetSort = (sortBy, isDescending) => {
    if (sortByProp !== sortBy) {
      setSelectSortBy('sortType', sortBy);
    }
    if (isDescendingProp !== isDescending) {
      const sortDirection = isDescending ? SORT_CONST.DESC : SORT_CONST.ASC;
      setChange('sortDirection', sortDirection);
    }
  };

  return (
    <React.Fragment>
      <StyledFlex as="label" justifyBetween>
        {label}
        <StyledFlexItem justifyCenter>
          <SortMenu
            options={SORT_OPTIONS}
            sortBy={sortByProp}
            isDescending={isDescendingProp}
            isSharing
            setSort={({ value }, isDescending) =>
              handleSetSort(value, isDescending)
            }
            featureGatedSortOptions={{
              [SORT_OPTIONS.CUSTOM.value]: {
                handleSelect: () =>
                  maybeStartUpgradePlanFlow(allFeatures.customSort),
                isFeatureGated: !canUseCustomSort,
              },
            }}
          />
        </StyledFlexItem>
      </StyledFlex>
      <StyledPopoverMenuSeparator />
    </React.Fragment>
  );
};

SharePanelSettingsSortDropdown.propTypes = {
  label: PropTypes.node,
  onChange: PropTypes.func,
};

export default React.memo(SharePanelSettingsSortDropdown);
