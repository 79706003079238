import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const ARCHIVE_PROJECT = generateActionTypes('ARCHIVE_PROJECT', [
  'START',
]);

export const startArchiveProject = createAction(
  ARCHIVE_PROJECT.START,
  (projectId) => ({ projectId })
);
