import { connect } from 'react-redux';
import { startSetupAccount } from './actions';
import SetupAccountCTA from './SetupAccountCTA';

export const mapDispatchToProps = {
  startSetupAccount,
};

export default connect(
  null,
  mapDispatchToProps
)(SetupAccountCTA);
