import { useCallback } from 'react';
import { dialogTypes } from '@frameio/components/src/styled-components/Dialog';
import { useDispatch } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import track from 'analytics';
import { openUnsavedChangesDialog } from './actions';

export default function useCloseConfirmation() {
  const dispatch = useDispatch();

  return useCallback(
    (watermarkTemplateId, isDirty) => {
      if (isDirty) {
        const dialogHeader = 'Discard edits?';
        const dialogBody =
          'Closing this modal will discard any edits to this template.';

        dispatch(
          openUnsavedChangesDialog(dialogHeader, dialogBody, {
            primaryText: 'Discard edits',
            secondaryText: 'Cancel',
            type: dialogTypes.DESTRUCTIVE,
          })
        );
      } else {
        track('session-watermark-template-editor-closed', {
          trigger: 'no-changes',
          watermark_template_id: watermarkTemplateId,
        });

        dispatch(closeModal());
      }
    },
    [dispatch]
  );
}
