import { connect } from 'react-redux';
import { accountEntityForProjectIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { canSeeDevToolsSelector } from 'components/DevelopmentTools/OpenDevTools/selectors';
import { hasFetchedAccountDataSelector } from 'selectors/accounts';
import { currentUserEntitySelector } from 'selectors/users';
import { secureSharingEnabled } from 'utils/featureFlags';
import { isReviewerHighestRoleSelector } from 'selectors/roles';
import { permittedActionsForAccountSelector } from 'selectors/permissions';

import { hide, show } from './actions';
import DashboardSidebarView from './DashboardSidebar';

/**
 * @param   {Object} state - Redux store state.
 * @returns {Object} - Maps the redux store state to populate props for the DashboardSidebar.
 */
function mapStateToProps(
  state,
  { currentAccountId: accountId, selectedProjectId: projectId }
) {
  // NOTE [AP]: It's not clear that the dashboard's open/close state needs to be
  // the concern of the global store. The TopBar checks this data, which might
  // require some additional refactoring and re-organization, but typically
  // this is the kind of thing that should be scoped to the component level
  // whenever possible.
  // See more: https://github.com/Frameio/web-client/pull/7550#discussion_r317794224
  const { isShowing } = state.dashboardSidebar;

  const permittedActionsForAccount = permittedActionsForAccountSelector(state, {
    accountId,
  });

  const { locked_at: accountLockedAt, unpaid_at: accountUnpaidAt } =
    accountEntityForProjectIdSelector(state, { projectId }) || {};

  return {
    currentUser: currentUserEntitySelector(state),
    hasFetchedAccountData: hasFetchedAccountDataSelector(state, { accountId }),
    isAccountLocked: !!accountLockedAt,
    isAccountUnpaid: !!accountUnpaidAt,
    isUserReviewer:
      isReviewerHighestRoleSelector(state, { accountId }) || false,
    isShowing,
    isShowingDevTools: canSeeDevToolsSelector(state),
    secureSharingEnabled:
      secureSharingEnabled(state) ||
      permittedActionsForAccount.isSecureSharingEnabled,
  };
}

const mapDispatchToProps = {
  hideSidebar: hide,
  showSidebar: show,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardSidebarView);

export const testExports = { mapStateToProps, mapDispatchToProps };
