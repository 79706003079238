import React from 'react';
import { put, select } from 'redux-saga/effects';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import LimitBlock from 'components/LimitBlock';
import { currentUserEntitySelector } from 'selectors/users';
import { bytesToMB } from 'shared/filesizeHelpers';
import { limitTypes } from 'selectors/limits';
import { openModal } from 'components/Modal/actions';
import { getUsageDataFromAccountEntity } from 'utils/plans/plansHelpers';

function buildNewUsageData(account, storageToAdd, fileCountToAdd) {
  const existingUsage = getUsageDataFromAccountEntity(account);
  return {
    ...existingUsage,
    storage: existingUsage.storage + storageToAdd,
    lifetime_file_count: existingUsage.lifetime_file_count + fileCountToAdd,
  };
}

export default function* triggerLimitBlockModal(
  accountId,
  uploadSize,
  numItems,
  hasSpaceToAddAssets,
  hasFileCountToUpload
) {
  const currentUser = yield select(currentUserEntitySelector);
  const account = yield select(accountEntitySelector, { accountId });
  const blockType = !hasFileCountToUpload
    ? limitTypes.LIFETIME_UPLOADS
    : limitTypes.STORAGE;
  const newUsageData = buildNewUsageData(
    account,
    hasSpaceToAddAssets ? 0 : uploadSize,
    hasFileCountToUpload ? 0 : numItems
  );

  yield put(
    openModal(
      <div className="limit-block-wrapper" role="button" tabIndex="-1">
        <LimitBlock
          account={account}
          currentUser={currentUser}
          eventProperties={{
            attempted_file_size_mb: bytesToMB(uploadSize),
          }}
          newUsageData={newUsageData}
          type={blockType}
        />
      </div>
    )
  );
}

export const testExports = {
  buildNewUsageData,
};
