import React from 'react';
import styled from 'styled-components';
import { noop } from 'lodash';

const StyledLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: ${(p) => p.theme.color.error};
`;

function ContactSupport({
  buttonText = 'support',
  errorText = 'Something went wrong, and we couldn’t process your information. If this error persists, please contact ',
}) {
  const onClick = window.Intercom ? () => window.Intercom('show') : noop;
  return (
    <span>
      {errorText}
      <StyledLink
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        onClick={onClick}
      >
        {buttonText}
      </StyledLink>
      .
    </span>
  );
}

export default ContactSupport;
