import store from 'configureStore';
import { setNewNotificationsCountForAccount } from './actions';

export const SOCKET_MESSAGES = {
  NOTIFICATIONS_OPENED: 'notifications_opened',
};

export const setNewNotificationsCount = ({
  accountId,
  newNotificationsCount,
}) => {
  store.dispatch(
    setNewNotificationsCountForAccount(accountId, newNotificationsCount)
  );
};
