import { connect } from 'react-redux';
import {
  permittedActionsForAssetsSelector,
  permittedActionsForProjectSelector,
} from 'selectors/permissions';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import ProjectContextMenu from './ProjectContextMenu';
import {
  indexToCreateAssetsSelector,
  projectIdSelector,
  folderIdSelector,
} from '../selectors';

function mapStateToProps(state) {
  const projectId = projectIdSelector(state);
  const { name: projectName } =
    projectEntitySelector(state, { projectId }) || {};
  const folderId = folderIdSelector(state);
  return {
    ...permittedActionsForProjectSelector(state, { projectId }),
    ...permittedActionsForAssetsSelector(state, {
      projectId,
      assetIds: [folderId],
    }),
    insertAssetAtIndex: indexToCreateAssetsSelector(state),
    projectId,
    folderId,
    projectName,
  };
}

export default connect(mapStateToProps)(ProjectContextMenu);

export const testExports = { mapStateToProps };
