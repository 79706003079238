import { call, spawn, put, select, takeLatest } from 'redux-saga/effects';
import { projectEntityForAssetIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import {
  joinAssetRoom,
  leaveAssetRoom,
} from '@frameio/core/src/sockets/actions';

import track from 'analytics';
import { FOLDER } from 'actions/folders';
import { redirectToProject } from 'sagas/projects';
import { redirectTo } from 'utils/router';
import { getProjectUrl } from 'URLs';
import { error, priority } from 'components/Dialog/SimpleDialog/sagas';

export function* setCurrentFolder({ payload: { folderId, prevFolderId } }) {
  if (prevFolderId) {
    yield put(leaveAssetRoom(prevFolderId));
  }
  yield put(joinAssetRoom(folderId));
}

export function* redirectUnavailableFolder(folderId) {
  yield spawn(track, 'folder-error-modal-shown', {
    access_attempt_folder_id: folderId,
  });
  yield call(
    error,
    'Whoops!',
    'Looks like that folder is no longer available.',
    {
      priority: priority.FOLDER_NOT_FOUND,
    }
  );
  const project = yield select(projectEntityForAssetIdSelector, {
    assetId: folderId,
  });

  // Don't know what project this folder belongs to, so just redirect to default
  if (!project) {
    yield call(redirectToProject);
    return;
  }

  // Is the root folder of the project, so redirect to default
  const isRoot = project.root_asset_id === folderId;
  if (isRoot) {
    yield call(redirectToProject);
    return;
  }

  // This particular folder cannot be accessed, try to redirect to root.
  yield call(redirectTo, getProjectUrl(project.id));
}

export default [takeLatest(FOLDER.SET_CURRENT, setCurrentFolder)];
