import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@frameio/components/src/styled-components/Button';
import { expireAuthToken } from '@frameio/core/src/auth';
import {
  configureCore,
  getRefreshTokenId,
  getSessionToken,
  setAuthCookies,
} from 'utils/auth';

const Label = styled.span`
  margin-left: ${(p) => p.theme.spacing.small};
  vertical-align: middle;
  ${(p) => p.theme.fontStyle.bodyS};
`;

const StyledButton = styled(Button)`
  margin-bottom: ${(p) => p.theme.spacing.micro};
`;

export default function ExpireAuthToken() {
  const [isDone, setIsDone] = useState(false);

  async function configureCoreWithExpiredToken() {
    const expiredAuthToken = await expireAuthToken();

    setAuthCookies(expiredAuthToken, getRefreshTokenId(), getSessionToken());
    configureCore();

    setIsDone(true);
  }

  return (
    <div>
      <StyledButton
        primary
        type="button"
        onClick={configureCoreWithExpiredToken}
      >
        Force expire auth token
      </StyledButton>

      {isDone && (
        // eslint-disable-next-line jsx-a11y/accessible-emoji
        <Label>Token is expired ✅</Label>
      )}
    </div>
  );
}
