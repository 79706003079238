import React from 'react';
import track from 'analytics';
import PropTypes from 'prop-types';
import { MenuButton } from '@frameio/vapor';
import Delete from '@frameio/components/src/svgs/icons/24/trash.svg';
import Duplicate from '@frameio/components/src/svgs/icons/24/duplicate.svg';
import CopyLinkIcon from '@frameio/components/src/svgs/icons/24/link.svg';
import AddRemoveFiles from '@frameio/components/src/svgs/icons/24/pencil.svg';
import PowerIcon from '@frameio/components/src/svgs/icons/24/power.svg';
import Settings from '@frameio/components/src/svgs/icons/24/cog.svg';
import Media from 'react-media';
import { SMALL } from 'utils/mediaQueries';

export default function ProjectReviewLinkContextMenu({
  confirmDeleteReviewLink,
  copyShortUrlToClipboard,
  duplicateReviewLink,
  editReviewLinkContent,
  editReviewLinkSettings,
  reviewLinkId,
  isActive,
  patchToggleIsActive,
  reviewLinkUrl,
}) {
  const trackMenuItemSelection = (item) =>
    track('project-links-page-context-menu-item-selected', {
      item_selected: item,
      type: 'review_pages',
    });

  return (
    <React.Fragment>
      <MenuButton
        onSelect={() => {
          editReviewLinkSettings(reviewLinkId);
          trackMenuItemSelection('settings');
        }}
        iconBefore={<Settings width={16} height={16} />}
      >
        Settings
      </MenuButton>
      <MenuButton
        onSelect={() => {
          editReviewLinkContent(reviewLinkId);
          trackMenuItemSelection('add_or_remove_files');
        }}
        iconBefore={<AddRemoveFiles width={16} height={16} />}
      >
        Add or remove files
      </MenuButton>
      <MenuButton
        onSelect={() => {
          copyShortUrlToClipboard(reviewLinkUrl);
          trackMenuItemSelection('copy_link');
        }}
        iconBefore={<CopyLinkIcon width={16} height={16} />}
      >
        Copy link
      </MenuButton>
      <Media query={SMALL}>
        {(isSmall) =>
          isSmall ? (
            <MenuButton
              onSelect={() => patchToggleIsActive(reviewLinkId, !isActive)}
              data-test-id="link-active-toggle"
              iconBefore={<PowerIcon width={16} height={16} />}
            >
              {isActive ? 'Deactivate' : 'Activate'} link
            </MenuButton>
          ) : null
        }
      </Media>

      <MenuButton
        onSelect={() => {
          duplicateReviewLink(reviewLinkId);
          trackMenuItemSelection('duplicate');
        }}
        iconBefore={<Duplicate width={16} height={16} />}
      >
        Duplicate
      </MenuButton>
      <MenuButton
        destructive
        onSelect={() => {
          confirmDeleteReviewLink(reviewLinkId);
          trackMenuItemSelection('delete');
        }}
        iconBefore={<Delete width={16} height={16} />}
      >
        Delete
      </MenuButton>
    </React.Fragment>
  );
}

ProjectReviewLinkContextMenu.propTypes = {
  reviewLinkid: PropTypes.string,
  copyShortUrlToClipboard: PropTypes.func.isRequired,
  confirmDeleteReviewLink: PropTypes.func.isRequired,
  duplicateReviewLink: PropTypes.func.isRequired,
  editReviewLinkContent: PropTypes.func.isRequired,
  editReviewLinkSettings: PropTypes.func.isRequired,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  reviewLinkUrl: PropTypes.string,
  patchToggleIsActive: PropTypes.func.isRequired,
};

ProjectReviewLinkContextMenu.defaultProps = {
  reviewLinkId: null,
  isActive: undefined,
  reviewLinkUrl: undefined,
};
