import { hydratedAssetEntitiesByAssetIdsSelector } from '@frameio/core/src/assets/selectors';
import { connect } from 'react-redux';
import CompareAssets from './CompareAssets';

const mapStateToProps = (state, { assetIds }) => ({
  hydratedAssets:
    hydratedAssetEntitiesByAssetIdsSelector(state, { assetIds }) || [],
});

export default connect(mapStateToProps)(CompareAssets);
