import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';
import { isFetchingActionFactory } from '../../factories/isFetching';

export const MANAGE_SUBSCRIPTION = generateActionTypes('MANAGE_SUBSCRIPTION', [
  'IS_FETCHING',
  'UPDATE_LINE_ITEM',
]);

export const updateSubscriptionLineItem = createAction(
  MANAGE_SUBSCRIPTION.UPDATE_LINE_ITEM,
  (type, amount) => ({ type, amount })
);

export const isUpdatingSubscription = isFetchingActionFactory(
  MANAGE_SUBSCRIPTION.IS_FETCHING
);
