import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ARCHIVED_STORAGE_LIMIT } from '@frameio/core/src/subscriptionLineItems/utils/modifierTypes';
import SubscriptionChangedConfirmation from 'components/SubscriptionChangedConfirmation';
import { formatBytes } from 'shared/filesizeHelpers';

const Copy = styled.p`
  color: ${(p) => p.theme.color.graphiteGray};
  text-align: center;
  padding: 0 10px 6px;
`;

function renderCopy(totalStorageAdded, type) {
  // Convert totalStorageAdded to an absolute value so
  // formatBytes doesn’t return NAN when passing a negative value
  const formattedTotalStorageAdded = formatBytes(Math.abs(totalStorageAdded));
  const storageType =
    type === ARCHIVED_STORAGE_LIMIT ? 'archival storage' : 'storage';

  if (totalStorageAdded < 0) {
    return (
      <Copy>
        {formattedTotalStorageAdded} of {storageType} has been removed!
      </Copy>
    );
  }
  return (
    <Copy>
      {formattedTotalStorageAdded} of {storageType} has been added!
    </Copy>
  );
}

const ConfirmStorageAdded = ({
  onDoneClick,
  location: {
    state: { totalStorageAdded, type },
  },
}) => (
  <SubscriptionChangedConfirmation onDoneClick={onDoneClick}>
    {renderCopy(totalStorageAdded, type)}
    <Copy>Keep an eye on your inbox for your receipt.</Copy>
  </SubscriptionChangedConfirmation>
);

ConfirmStorageAdded.propTypes = {
  onDoneClick: PropTypes.func,
  location: PropTypes.object.isRequired,
};

ConfirmStorageAdded.defaultProps = {
  onDoneClick: undefined,
};

export default ConfirmStorageAdded;
