import { connect } from 'react-redux';
import ContextMenuManager from './ContextMenuManager';
import { closeContextMenu } from './actions';
import { contextMenuSelector } from './selectors';

const mapStateToProps = (state) => {
  const { contextMenuType, isShowing, menuProps, x, y } = contextMenuSelector(
    state
  );

  return {
    isShowing,
    contextMenuType,
    menuProps,
    x,
    y,
  };
};

const mapDispatchToProps = { closeContextMenu };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContextMenuManager);

export const testExports = { mapStateToProps, mapDispatchToProps };
