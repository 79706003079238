import React, { useRef, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import PropTypes from 'prop-types';
import WithTooltip from '@frameio/components/src/components/WithTooltip';
import Arrow from '@frameio/components/src/svgs/icons/16/arrow.svg';
import TeamIcon from '@frameio/components/src/svgs/icons/16/team2.svg';
import { isSharedFolderSelector } from 'selectors/folders';
import HighlightedName from './HighlightedName';
import SearchingSpinner from './SearchingSpinner';
import SharedFolderIcon from './SharedFolderIcon';
import FolderIcon from './FolderIcon';
import { RESULTS_HEIGHT } from './constants';
import useFocus from './useFocus';
import useKeyboardNav from './useKeyboardNav';

const ScrollContainer = styled.div`
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  height: ${RESULTS_HEIGHT}px;
  position: relative;
`;

const StyledHighlightedName = styled(HighlightedName)`
  margin-top: 2px;
  color: ${(p) => p.theme.color.dimGray};
  ${(p) => p.isSelected && `font-weight: ${p.theme.fontWeight.bold}`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledTeamIcon = styled(TeamIcon)`
  transform: scale(0.8);
  margin-right: ${(p) => p.theme.spacing.units(0.5)};
  flex: 0 0 auto;
  color: ${(p) => p.theme.color.slateGray};
`;

const ChevronContainer = styled.div``;

const Chevron = styled(Arrow)`
  transform: rotate(-90deg);
`;

const StyledSharedFolderIcon = styled(SharedFolderIcon)`
  background-color: ${(p) =>
    p.isSelected ? p.theme.color.lightGray : p.theme.color.silver};
`;

const FolderContainer = styled.div`
  ${(p) => p.theme.fontStyle.body};
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  border-radius: ${(p) => p.theme.radius.large};
  padding-left: ${(p) => p.theme.spacing.units(1)};
  padding-right: ${(p) => p.theme.spacing.units(1)};
  outline: none;

  &:focus {
    box-shadow: ${(p) =>
      !p.isSelected && `inset 0 0 0 2px ${p.theme.color.silver}`};
  }

  &:hover {
    background-color: ${(p) => !p.isSelected && p.theme.color.coldWhite};
  }

  &:active {
    background-color: ${(p) => p.theme.color.silver};
  }

  ${(p) =>
    p.isSelected &&
    `
    background-color: ${p.theme.color.silver};
    &:hover,
    &:active {
      background-color: ${p.theme.color.silver};
    }
  `}

  ${StyledSharedFolderIcon} {
    margin-left: ${(p) => p.theme.spacing.micro};
  }
`;

const SpinnerContainer = styled.div`
  padding-left: ${(p) => p.theme.spacing.units(0.5)};
  padding-top: ${(p) => p.theme.spacing.units(1)};
`;

const CrumbContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-right: ${(p) => p.theme.spacing.units(2)};
  color: ${(p) => p.theme.color.gray};

  ${ChevronContainer} {
    position: absolute;
    right: 0;
    top: 50%;
    height: ${(p) => p.theme.spacing.units(2)};
    transform: translateY(-50%);
  }

  ${Chevron} {
    color: ${(p) => p.theme.color.slateGray};
  }
`;

const CrumbText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
  font-size: 12px;
  font-size: ${(p) => p.theme.fontSize[1]};
`;

const ListPadding = styled.div`
  padding-left: ${(p) => p.theme.spacing.units(2)};
  padding-right: ${(p) => p.theme.spacing.units(2)};
  padding-bottom: 1px;
`;

const List = styled.div``;

export function Crumb({ children, showChevron = true }) {
  return (
    <CrumbContainer>
      <CrumbText>{children}</CrumbText>
      {showChevron && (
        <ChevronContainer>
          <Chevron />
        </ChevronContainer>
      )}
    </CrumbContainer>
  );
}

const FolderIconContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-right: ${(p) => p.theme.spacing.units(1)};
`;

// prettier-ignore
const FolderPath = styled.div`
  display: grid;
  grid-template-columns: min-content
    minmax(auto, min-content)
    minmax(auto, min-content)
    minmax(auto, min-content)
    minmax(auto, min-content);
`;

/**
 * An individual list item in our
 * folders search results.
 */

export function FolderListItem({
  query,
  isSelected,
  onDoubleClick,
  onKeyDown,
  onSelect,
  item,
  isFocused,
  onFocus,
}) {
  const ref = useFocus(isFocused);

  const { name } = item;

  const hasEllipsis =
    item.parent && item.parent.parent && item.parent.parent.name !== 'root';

  const hasParent = item.parent && item.parent.name !== 'root';

  const isSharedFolder = useSelector((state) =>
    isSharedFolderSelector(state, { folderId: item.id })
  );

  return (
    <WithTooltip
      message={name}
      position="top"
      disabled={name.length <= 75}
      maxWidth={450}
    >
      <FolderContainer
        ref={ref}
        isSelected={isSelected}
        onClick={() => {
          onSelect(item.id, false);
        }}
        role="button"
        onDoubleClick={onDoubleClick}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        tabIndex={0}
      >
        <Flex alignCenter>
          <FolderIconContainer>
            <FolderIcon isSelected={isSelected} />
          </FolderIconContainer>
          <StyledHighlightedName
            isSelected={isSelected}
            query={query}
            name={name}
          />
          {isSharedFolder && <StyledSharedFolderIcon isSelected={isSelected} />}
        </Flex>
        <FolderPath>
          <StyledTeamIcon />
          {item.team && item.team.name && <Crumb>{item.team.name}</Crumb>}
          {item.project && (
            <Crumb showChevron={hasEllipsis || hasParent}>
              {item.project.name}
            </Crumb>
          )}
          {hasEllipsis && <Crumb>&hellip;</Crumb>}
          {hasParent && <Crumb showChevron={false}>{item.parent.name}</Crumb>}
        </FolderPath>
      </FolderContainer>
    </WithTooltip>
  );
}

FolderListItem.propTypes = {
  /** the current user query, used for text highlighting */
  query: PropTypes.string,
  /** whether this list item is selected */
  isSelected: PropTypes.bool,
  /** handle double clicks */
  onDoubleClick: PropTypes.func.isRequired,
  /** handle select */
  onSelect: PropTypes.func.isRequired,
  /** the folder to render */
  item: PropTypes.object.isRequired,
  onKeyDown: PropTypes.func,
};

/**
 * FolderList windowed, and infinite loading.
 * Used for rendering folder search results. This view is unique
 * to all of the other search results, because it
 * doesn't render using the TreeView.
 */

function FolderSearchResults({
  query,
  hasNextPage,
  selectedId,
  isLoading,
  loadNextPage,
  onDoubleClick,
  items,
  onSelect,
  onScroll,
}) {
  const listRef = useRef();
  const folderIds = useMemo(() => items.map((folder) => folder.id), [items]);

  const { handleKeyDown, setFocusedId, focusedId } = useKeyboardNav(
    folderIds,
    onDoubleClick,
    onSelect
  );

  /**
   * Potentially load more results when
   * scrolling to the bottom
   */
  const handleScroll = useCallback(
    (e) => {
      // for now, let's cap our max items at 200. We don't use windowing
      // here, so perf could become an issue once we start rendering
      // 200+
      const hasScrolledToBottom =
        e.target.scrollTop + RESULTS_HEIGHT === e.target.scrollHeight;
      const shouldLoadNextPage =
        hasNextPage && !isLoading && items.length < 200 && loadNextPage;
      if (shouldLoadNextPage && hasScrolledToBottom) {
        loadNextPage();
      }

      onScroll(e);
    },
    [hasNextPage, isLoading, items, loadNextPage, onScroll]
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <ScrollContainer onKeyDown={handleKeyDown}>
      <List
        className="List"
        height={RESULTS_HEIGHT}
        ref={listRef}
        onScroll={handleScroll}
      >
        {items.map((folder) => (
          <div key={folder.id}>
            <ListPadding>
              <FolderListItem
                item={folder}
                query={query}
                onDoubleClick={() => onDoubleClick(folder.id)}
                isSelected={selectedId === folder.id}
                onSelect={onSelect}
                isFocused={focusedId === folder.id}
                onFocus={() => setFocusedId(folder.id)}
              />
            </ListPadding>
          </div>
        ))}
      </List>

      {isLoading && (
        <ListPadding>
          <SpinnerContainer>
            <SearchingSpinner label="Loading" />
          </SpinnerContainer>
        </ListPadding>
      )}
    </ScrollContainer>
  );
}

FolderSearchResults.propTypes = {
  query: PropTypes.string,
  hasNextPage: PropTypes.bool,
  selectedId: PropTypes.string,
  isLoading: PropTypes.bool,
  loadNextPage: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
};

export default FolderSearchResults;

export const testExports = {
  FolderContainer,
  ScrollContainer,
  Crumb,
  List,
};
