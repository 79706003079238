import {
  call,
  select,
  takeEvery,
  // fork,
  put,
  cancelled,
} from 'redux-saga/effects';
import { isEqual } from 'lodash';
import { v4 as uuid } from 'uuid';
import {
  createPaginatedListSaga,
  takeEveryUntil,
} from '@frameio/core/src/shared/sagas/helpers';
import { createFailureAction } from '@frameio/core/src/shared/actions/helpers';
import { searchAssetsInAccount } from '@frameio/core/src/assets/sagas';

// import track from 'analytics';

import {
  shouldFetchSearchPageSelector,
  sortBySelector,
  isDescendingSelector,
} from './selectors';
import SEARCH_CONTAINER from './actions';
import SORT_OPTIONS from './sortOptions';

// This uuid is used to tie multiple tracking events together. A single query
// is used as long as the query string and filters don't change. So for a given
// search, if the user paginates or changes the sort order, the tracking events
// will all share the same query_id. This will allow us to differentiate when
// a user chooses to search using the search system, vs search using a manual
// visual search.
let trackingQueryId = uuid();

const searchAccountList = createPaginatedListSaga(
  SEARCH_CONTAINER.SEARCH,
  searchAssetsInAccount,
  { extractPage: (query, accountId, { page }) => page }
);

function* searchAccount({ payload: { accountId, query, page, options } }) {
  try {
    const shouldSearch = yield select(shouldFetchSearchPageSelector, { page });
    if (!shouldSearch) return;

    const sortBy = yield select(sortBySelector);
    const sortDescending = yield select(isDescendingSelector);
    // const { filters } = options;

    // @todo (BUGS-2589): when tracking events this way is fixed, can bring this back
    // if (page === 1) {
    //   yield fork(track, 'advanced_search_request_sent', {
    //     query_id: trackingQueryId,
    //     has_query: !!query,
    //     filter_ids: Object.keys(filters)
    //       .sort()
    //       .join(','),
    //     sort_by: `${sortBy} ${sortDescending ? 'desc' : 'asc'}`,
    //   });
    // } else {
    //   yield fork(track, 'advanced_search_results_paginated', {
    //     query_id: trackingQueryId,
    //     sort_by: `${sortBy} ${sortDescending ? 'desc' : 'asc'}`,
    //     page,
    //   });
    // }

    const order = sortDescending ? '-' : '';

    yield call(searchAccountList, query, accountId, {
      ...options,
      includeFolderPreview: true,
      page,
      sort:
        sortBy === SORT_OPTIONS.RELEVANCE.value ? null : `${order}${sortBy}`,
    });
  } finally {
    if (yield cancelled()) {
      yield put(createFailureAction(SEARCH_CONTAINER.SEARCH.FAILURE, {}, page));
    }
  }
}

// @todo (BUGS-2589): when tracking events this way is fixed, can bring this back
function* trackSort() {
  // {
  // payload: { option, isDescending, prevSortBy, wasDescending },
  // }
  // yield fork(track, 'advanced_search_results_reordered', {
  //   query_id: trackingQueryId,
  //   sort_from: `${prevSortBy} ${wasDescending ? 'desc' : 'asc'}`,
  //   sort_to: `${option.value} ${isDescending ? 'desc' : 'asc'}`,
  // });
}

function didSearchParamsChange(
  {
    payload: {
      accountId: lastAccountId,
      query: lastQuery,
      options: lastOptions,
    },
  },
  { payload: { accountId, query, options } }
) {
  const didChange =
    accountId !== lastAccountId ||
    query !== lastQuery ||
    !isEqual(options, lastOptions);

  if (didChange) trackingQueryId = uuid();

  return didChange;
}

export default [
  takeEveryUntil(
    SEARCH_CONTAINER.SEARCH.BASE,
    searchAccount,
    didSearchParamsChange
  ),
  takeEvery(SEARCH_CONTAINER.SET_SORT, trackSort),
];

export const testExports = {
  searchAccountList,
  searchAccount,
  didSearchParamsChange,
  trackSort,
  get trackingQueryId() {
    return trackingQueryId;
  },
  set trackingQueryId(id) {
    trackingQueryId = id;
  },
};
