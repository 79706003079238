import { connect } from 'react-redux';
import Data from './Data';
import { cloneProject } from '../../actions';

const mapDispatchToProps = {
  cloneProject,
};

export default connect(
  null,
  mapDispatchToProps
)(Data);
