import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Flex from 'styled-flex-component';
import { isEqual } from 'lodash';
import { trackButtonClick } from 'analytics';
import Media from 'react-media';
import { buttonReset } from '@frameio/components/src/mixins';
import Hide from '@frameio/components/src/styled-components/Hide';
import SearchIcon from '@frameio/components/src/svgs/raw/ic-search-glass-16px.svg';

import { MEDIUM_DOWN } from 'utils/mediaQueries';
import { getPath, ACCOUNT_SEARCH_URL, PERSONAL_POLICY_URL } from 'URLs';
import strings, {
  ACCOUNT_NAVIGATION_VIEW,
} from 'components/AccountNavigation/AccountNavigationStrings';
import ProjectList from './ProjectList';
import InboxRow from './InboxRow';
import AccountNavigationMobile from '../AccountNavigation/AccountNavigationMobile';
import TopBarNav from '../TopBar/TopBarNav';
import { DASHBOARD_SIDEBAR_WIDTH } from './constants';
import Panel from './Panel';

const MobileMask = styled.button`
  ${buttonReset()};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  width: calc(100vw - ${DASHBOARD_SIDEBAR_WIDTH.min}px);
`;

const linkStyles = css`
  display: flex;
  align-items: center;
  ${(p) => p.theme.fontStyle.body};
  line-height: 24px;
  color: ${(p) => p.theme.color.slateGray};
  padding: ${(p) => p.theme.spacing.tiny};
  border-radius: ${(p) => p.theme.radius.default};
  transition: color 0.3s ease-out;

  &:hover {
    color: ${(p) => p.theme.color.coldWhite};
  }

  &.active {
    color: ${(p) => p.theme.color.white};
    background-color: ${(p) => p.theme.color.coolBlack};
  }

  svg {
    margin-right: ${(p) => p.theme.spacing.tiny};
  }
`;

// NOTE [ENT-1490] When we upgrade to styled-components v4 we can have one `MainLink` component
// defined here and use the `as` property to define different base components to be styled
const MainLink = styled(NavLink)`
  ${linkStyles}
`;
const InboxLink = styled(InboxRow)`
  ${linkStyles}
`;

const Wrapper = styled(Flex).attrs(() => ({
  column: true,
}))`
  background-color: #0F0F14;
  color: #C5CBD4;
  font: 15px;
  position: relative;
  flex: 0 0 auto;
  transform: none;
  height: 100vh;
  width: 100%;
  min-width: ${DASHBOARD_SIDEBAR_WIDTH.min}px;

  padding: ${({ theme: { spacing } }) => spacing.tiny} 0;

  ${MainLink},
  ${InboxLink} {
    flex-shrink: 0;

    &:first-of-type {
      margin-top:  ${(p) => p.theme.spacing.tiny};
    }
    &:last-of-type {
      margin-bottom:  ${(p) => p.theme.spacing.tiny};
    }
  }

  .DashboardSidebar__TopBarNav,
  ${MainLink},
  ${InboxLink} {
    margin: 0 ${({ theme: { spacing } }) => spacing.tiny};
  }

  @media ${MEDIUM_DOWN} {
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    width: ${DASHBOARD_SIDEBAR_WIDTH.min}px;


    transform: translateX(-100%);
    transition: transform 300ms ${(p) => p.theme.easing.easeInOutQuint};
    /*
      Because .TopBar__JumpTo has a z-index of 1 to make it above the rest of the header buttons,
      we need to bump the mobile sidebar up one z-index so that it appears on top of that. When
      the Search component removed from the Topbar, we can bump this back down to 1.
    */
    z-index: 2;

    ${({ isShowing }) =>
      isShowing &&
      `
      transform: translateX(0);
    `}
    }

    ${({ isDisabled }) =>
      isDisabled &&
      `${MainLink}, ${InboxLink} {
        pointer-events: none;
        opacity: 0.5;
      }
      `}
  }
`;

const PrivacyLink = styled.a`
  color: ${(p) => p.theme.color.graphiteGray} !important;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  font-size: 11px;
  line-height: 8px;
  padding: 8px 0px;
  margin-bottom: env(safe-area-inset-bottom);
`;

/**
 * Closes the sidebar when an unhandled escape is pressed.
 * @param {SyntheticEvent} event
 */
export function closeOnEscapeKeyDown(event) {
  if (this.props.isShowing && event.key === 'Escape') {
    this.props.hideSidebar();
  }
}
/**
 * Sidebar with teams, projects, and account dropdown.
 */
export class DashboardSidebar extends React.Component {
  componentDidMount() {
    window.addEventListener('keydown', this.handleWindowKeyDown);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.state, nextState) || !isEqual(this.props, nextProps);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleWindowKeyDown);
  }

  /**
   * Handles key down events on the window
   * @param {SyntheticEvent} event The keydown event
   */
  handleWindowKeyDown = (event) => {
    closeOnEscapeKeyDown.call(this, event);
  };

  render() {
    const {
      currentAccountId,
      currentUser,
      hideSidebar,
      isAccountLocked,
      isAccountUnpaid,
      isShowing,
      isShowingDevTools,
      isUserReviewer,
      selectedProjectId,
      secureSharingEnabled,
    } = this.props;

    const isBlocked = isAccountLocked || isAccountUnpaid;

    if (!currentUser) return null;

    return (
      <Panel
        defaultMin={DASHBOARD_SIDEBAR_WIDTH.min}
        defaultMax={DASHBOARD_SIDEBAR_WIDTH.max}
        disableResizeAtQuery={MEDIUM_DOWN}
        panelOffset={0}
      >
        <Wrapper isShowing={isShowing} isDisabled={isBlocked}>
          <Media query={MEDIUM_DOWN}>
            {isShowing && (
              <MobileMask onClick={hideSidebar}>
                <Hide>Close</Hide>
              </MobileMask>
            )}
          </Media>
          <Media query={MEDIUM_DOWN}>
            {(matches) =>
              matches ? (
                <AccountNavigationMobile
                  {...{ currentAccountId, currentUser, isShowingDevTools }}
                />
              ) : (
                <TopBarNav className="DashboardSidebar__TopBarNav" />
              )
            }
          </Media>
          {currentAccountId && !isUserReviewer && (
            <MainLink
              className="DashboardSidebar__Search"
              to={getPath(ACCOUNT_SEARCH_URL, { accountId: currentAccountId })}
              onClick={hideSidebar}
            >
              <SearchIcon /> Search
            </MainLink>
          )}
          {secureSharingEnabled && currentAccountId && (
            <InboxLink accountId={currentAccountId} onClick={hideSidebar} />
          )}
          <ProjectList
            selectedProjectId={selectedProjectId}
            isDisabled={isBlocked}
          />
          <Media query={MEDIUM_DOWN}>
            <PrivacyLink
              href={PERSONAL_POLICY_URL}
              onClick={() => {
                trackButtonClick(
                  'privacy policy',
                  ACCOUNT_NAVIGATION_VIEW,
                  'top'
                );
              }}
              rel="noopener noreferrer"
              target="_blank"
            >
              {strings.PERSONAL_POLICY}
            </PrivacyLink>
          </Media>
        </Wrapper>
      </Panel>
    );
  }
}

DashboardSidebar.defaultProps = {
  currentUser: null,
  teams: [],
  projectsByTeamFetchStatuses: {},
  selectedProjectId: null,
  isAccountLocked: false,
  isAccountUnpaid: false,
  isShowingDevTools: false,
  currentAccountId: null,
  secureSharingEnabled: false,
};

/**
 * teams - An array of team objects received from redux store.
 * selectedProjectId - The currently selected project's API ID.
 * user - An object containing user's name, avatar, and email.
 * projectOptionsMenu - Functions necessary for ProjectOptionsMenu.
 */
DashboardSidebar.propTypes = {
  currentAccountId: PropTypes.string,
  currentUser: PropTypes.shape({ id: PropTypes.string }),
  hasFetchedAccountData: PropTypes.bool.isRequired,
  hideSidebar: PropTypes.func.isRequired,
  isAccountLocked: PropTypes.bool.isRequired,
  isAccountUnpaid: PropTypes.bool.isRequired,
  isShowing: PropTypes.bool.isRequired,
  isShowingDevTools: PropTypes.bool,
  isUserReviewer: PropTypes.bool.isRequired,
  projectsByTeamFetchStatuses: PropTypes.object,
  selectedProjectId: PropTypes.string,
  showSidebar: PropTypes.func.isRequired,
  secureSharingEnabled: PropTypes.bool,
};

export default DashboardSidebar;

export const testExports = { MobileMask, InboxLink };
