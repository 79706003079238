import { connect } from 'react-redux';
import NameTeam from './NameTeam';
import { continueFlow, changeTeamAndAccountName } from '../actions';

const mapDispatchToProps = {
  changeTeamAndAccountName,
  onContinue: continueFlow,
};

export default connect(
  null,
  mapDispatchToProps
)(NameTeam);
