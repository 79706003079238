import React from 'react';
import Media from 'react-media';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { MEDIUM_UP } from 'utils/mediaQueries';
import Button from '@frameio/components/src/styled-components/Button';
import SuccessIllustration from './success.svg';
import SuccesssAnimation from './success.mp4';

const Container = styled.div`
  min-width: 100vw;
  min-height: 360px;

  @media ${MEDIUM_UP} {
    min-width: 400px;
    padding: ${({
      theme: {
        spacing: { units },
      },
    }) => `${units(5)} 0 ${units(12)} 0`};
  }
`;

const IllustrationContainer = styled(Flex)`
  margin: ${({ theme }) => `${theme.spacing.huge} 0 ${theme.spacing.medium}`};
`;

const Header = styled.h1`
  text-align: center;
  font-size: ${(p) => p.theme.fontSize[3]};
  font-weight: bold;
  color: ${(p) => p.theme.color.coolBlack};
  margin-bottom: ${(p) => p.theme.spacing.units(8)};
  transform: scale(0.9);
`;

const HeaderVideo = styled.video`
  width: 186px;
  height: 162px;
`;

const HeaderImageContainer = styled.div`
  padding-top: ${(p) => p.theme.spacing.units(5)};
`;

const StyledButton = styled(Button)`
  position: absolute;
  bottom: ${(p) => p.theme.spacing.medium};
  right: ${(p) => p.theme.spacing.medium};
`;

const Success = ({ closeModal }) => (
  <Container>
    <IllustrationContainer justifyCenter>
      <Media query={MEDIUM_UP}>
        {(isMediumUp) =>
          isMediumUp ? (
            <HeaderVideo autoPlay muted>
              <source src={SuccesssAnimation} type="video/mp4" />
            </HeaderVideo>
          ) : (
            <HeaderImageContainer>
              <SuccessIllustration />
            </HeaderImageContainer>
          )
        }
      </Media>
    </IllustrationContainer>
    <Header data-test-id="header">Project archived</Header>
    <StyledButton primary onClick={closeModal}>
      Done
    </StyledButton>
  </Container>
);

Success.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default Success;
