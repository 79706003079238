// @flow
import Cookies from 'js-cookie';
import { identity } from 'lodash';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import Raven from 'raven-js';
import {
  configureLegacyAuth,
  configureUserId,
  configureAuth,
  configureSocketAuth,
  configureResponseInterceptor,
} from '@frameio/core';
import { refreshAuthTokenWithCycle } from '@frameio/core/src/auth';
import { v2Instance as v2Api } from '@frameio/core/src/shared/services/api';
import config, {
  isLocal,
  isTest,
  isProduction,
  isStaging,
  isEvaluation,
} from 'config';
import store from 'configureStore';
import { requireLogin as requireLoginAction } from 'actions/shared';
import { v2AuthCookieCallbackEnabled } from 'utils/featureFlags';

// Cookie names
let authTokenKey = 'devAuth';
let refreshTokenIdKey = 'devRefreshTokenId';
let sessionTokenKey = 'devSessionToken';

if (isLocal) {
  authTokenKey = 'localAuth';
  refreshTokenIdKey = 'localRefreshTokenId';
  sessionTokenKey = 'localSessionToken';
}

if (isEvaluation) {
  authTokenKey = 'evalAuth';
  refreshTokenIdKey = 'evalRefreshTokenId';
  sessionTokenKey = 'evalSessionToken';
}

if (isStaging) {
  authTokenKey = 'stagingAuth';
  refreshTokenIdKey = 'stagingRefreshTokenId';
  sessionTokenKey = 'stagingSessionToken';
}

if (isProduction) {
  authTokenKey = 'auth';
  refreshTokenIdKey = 'refreshTokenId';
  sessionTokenKey = 'sessionToken';
}

let hasLoggedPrematureTokenExpiration = false;

export function getAuthToken() {
  return Cookies.get(authTokenKey);
}

const guidRegex = new RegExp(
  /:([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})$/,
  'i'
);

export function getAuthTokenExpiryForAuthToken(authToken) {
  try {
    const jwtToken = jwtDecode(authToken);
    return new Date(jwtToken.exp * 1000);
  } catch (err) {
    return null;
  }
}

export function getAuthTokenExpiredForAuthToken(authToken) {
  try {
    const jwtToken = jwtDecode(authToken);
    const authTokenExpiry = new Date(jwtToken.exp * 1000);
    const currentTime = moment();
    return currentTime.diff(authTokenExpiry) > 0;
  } catch (err) {
    return null;
  }
}

export function getAuthTokenExpiry() {
  const authToken = getAuthToken();
  return getAuthTokenExpiryForAuthToken(authToken);
}

export function getAuthTokenExpired() {
  const authToken = getAuthToken();
  return getAuthTokenExpiredForAuthToken(authToken);
}

export function getAuthedUserId() {
  let jwtToken;
  try {
    jwtToken = jwtDecode(getAuthToken());

    // "sub" prop may look like this:
    // - User:8f62e1cd-51a0-4fd5-8125-26e10b41ed57
    // - AnonymousUser:91a6ad98-2cae-42b1-a50c-045d495ddd9f
    return jwtToken.sub.match(guidRegex)[1];
  } catch (err) {
    return null;
  }
}

export function getIsAnonToken() {
  try {
    const jwtToken = jwtDecode(getAuthToken());
    const [userType] = jwtToken.sub.split(':');

    // "sub" prop may look like this:
    // - User:8f62e1cd-51a0-4fd5-8125-26e10b41ed57
    // - AnonymousUser:91a6ad98-2cae-42b1-a50c-045d495ddd9f
    return userType === 'AnonymousUser';
  } catch (err) {
    return false;
  }
}

export function getRefreshTokenId() {
  return Cookies.get(refreshTokenIdKey);
}

export function getSessionToken() {
  return Cookies.get(sessionTokenKey);
}

/**
 * Set auth credentials returned by the auth call.
 * @param {string} authToken - Auth token.
 * @param {string} refreshTokenId - Refresh token id.
 */
export function setAuthCookies(
  authToken: string,
  refreshTokenId: string,
  sessionToken: string
): void {
  const opts = {
    expires: 365,
    domain: config.domain,
    secure: !isLocal && !isTest,
    sameSite: 'lax',
  };
  authToken && Cookies.set(authTokenKey, authToken, opts);
  refreshTokenId && Cookies.set(refreshTokenIdKey, refreshTokenId, opts);
  if (sessionToken) {
    Cookies.set(sessionTokenKey, sessionToken, opts);
  } else {
    Cookies.remove(sessionTokenKey, opts);
  }
}

/**
 * Remove auth credentials.
 */
export function removeAuthCookies(): void {
  Cookies.remove(authTokenKey, { domain: config.domain });
  Cookies.remove(refreshTokenIdKey, { domain: config.domain });
  Cookies.remove(sessionTokenKey, { domain: config.domain });
}

/**
 * Remove refresh token for DeleteRefreshToken dev tool.
 */
export function removeRefreshToken(): void {
  Cookies.remove(refreshTokenIdKey, { domain: config.domain });
}

/**
 * Check if valid auth cookies are set.
 * @returns {Boolean} True if valid.
 */
export function hasAuthCookies(): boolean {
  return !!(getAuthToken() && getRefreshTokenId());
}

/**
 * Configure core services with auth credentials.
 */
export function configureCore(): void {
  configureSocketAuth(getAuthToken, getAuthedUserId);

  const shouldUseAuthCallback = v2AuthCookieCallbackEnabled(store.getState());

  // TODO: V3FRAME-1048 - Remove the ternary when the flag is removed
  // Use the callback instead of the token when the flag is removed
  const authTokenOrCallback = shouldUseAuthCallback
    ? getAuthToken
    : getAuthToken();

  // TODO: The following should also receive getters like above.
  const userId = getAuthedUserId();
  configureAuth(authTokenOrCallback);
  configureLegacyAuth(userId, authTokenOrCallback);
  configureUserId(userId);
}

export function retryRequest(newAuthToken: string, axiosConfig: Object) {
  return v2Api({
    ...axiosConfig,
    headers: {
      ...axiosConfig.headers,
      authorization: newAuthToken,
    },
    retries: (axiosConfig.retries || 0) + 1,
  });
}

function checkForPrematureTokenExpiration(source) {
  const currentTime = moment();
  const lastLogin = Cookies.get('fio-last-login');

  if (!lastLogin) return;

  const expectedExpiration = moment(lastLogin)
    .add(14, 'days')
    .subtract(1, 'minute');

  const expiredPrematurely = currentTime.diff(expectedExpiration) < 0;

  if (expiredPrematurely && !hasLoggedPrematureTokenExpiration) {
    const { pathname, search, hash } = window.location;

    Raven.captureMessage('Premature refresh token expiration', {
      level: 'warning',
      extra: {
        currentTime: currentTime.unix(),
        expectedExpiration: expectedExpiration.unix(),
        path: `${pathname}${search}${hash}`,
      },
      tags: {
        jira_ticket: 'BUGS-2197',
        hours_since_auth: currentTime.diff(moment(lastLogin), 'hours'),
        premature_logout_source: source,
      },
    });

    hasLoggedPrematureTokenExpiration = true;
  }
}

/**
 * Use the token refresh request here to make sure that only one request is made
 * at any time.
 */
let refreshReq;

function resetTokenRefreshReq() {
  refreshReq = null;
}

/**
 * Attempt to fetch a new auth token using the refresh token.
 *
 * @returns {Promise<string>} - New auth token if successful, null otherwise.
 */
export async function refreshAuth(source = 'refreshAuth') {
  const refreshToken = getRefreshTokenId();
  const sessionToken = getSessionToken();

  if (!refreshToken) {
    const { pathname, search, hash } = window.location;

    Raven.captureMessage('Refresh token undefined', {
      level: 'warning',
      extra: {
        path: `${pathname}${search}${hash}`,
      },
      tags: {
        jira_ticket: 'BUGS-2330',
      },
    });
    return null;
  }

  // To prevent excessive `refreshAuthTokenWithCycle` calls from other requests
  // that 401-ed concurrently, make all other requests wait for the same
  // `refreshAuthTokenWithCycle` call if it's already in flight.
  refreshReq =
    refreshReq || refreshAuthTokenWithCycle(refreshToken, sessionToken);
  let newTokens;

  try {
    newTokens = await refreshReq;
  } catch (e) {
    // TODO: Is it possible to tell apart expected refresh attempts vs
    // non-expected attempts to we can log them here?

    checkForPrematureTokenExpiration(source);

    resetTokenRefreshReq();
    return null;
  }

  // Unable to refresh auth token, likely because our refresh token has expired
  if (!newTokens?.authorization) {
    checkForPrematureTokenExpiration(source);
    return null;
  }

  const {
    authorization: newAuthToken,
    refreshToken: newRefreshToken,
    sessionToken: newSessionToken,
  } = newTokens;

  // Reset the refresh req for the next token refresh cycle
  resetTokenRefreshReq();

  // Reconfigure services with the new auth token
  setAuthCookies(newAuthToken, newRefreshToken, newSessionToken);
  configureCore();

  return newAuthToken;
}

/**
 * Enforces auth on the api by showing a dialog that asks unauthed user to
 * login in when the api responds with HTTP 401 Unauthorized.
 *
 * TODO(joel): Once we have a way to designate page-specific sagas, we can
 * instead just `take` on every action that is a FAILURE, and sniff it's HTTP
 * status from there to fire the requireLogin saga, rather than going through
 * the whole action song and dance. This way, pages can opt-in to this on a per-
 * route basis. Listening to it system wide is not possible since review links
 * and presentation pages use the 401 status code to throw a passphrase/login
 * dialog.
 */
export function configureAuthRequiredInterceptor() {
  async function authRequiredInterceptor(error) {
    if (error.response?.status !== 401) return Promise.reject(error);

    const authTokenFromCookies = getAuthToken();
    const authTokenFromError = error?.config?.headers?.authorization;

    const hasTokens = authTokenFromCookies && authTokenFromError;

    /**
     * When the client is running on multiple tabs, any time the user logs in or out
     * on another tab, the auth token in the cookies will be updated, but the axios
     * instance will still have the old auth token for an idle tab.
     * V3FRAME-1034: https://github.com/Frameio/web-client/pull/11187
     */
    if (hasTokens && authTokenFromError !== authTokenFromCookies) {
      configureCore();
      return retryRequest(authTokenFromCookies, error.config);
    }

    const currentTime = moment();
    const expectedExpiration = getAuthTokenExpiry();

    if (expectedExpiration && currentTime.isBefore(expectedExpiration)) {
      const { pathname, search, hash } = window.location;
      Raven.captureMessage('Premature auth token expiration', {
        level: 'warning',
        extra: {
          currentTime: currentTime.unix(),
          expectedExpiration: moment(expectedExpiration).unix(),
          path: `${pathname}${search}${hash}`,
        },
        tags: {
          jira_ticket: 'BUGS-2197',
        },
      });
    }

    const hasRetriesRemaining = (error?.config?.retries || 0) < 2;

    // Attempt to refresh the auth token
    const newAuthToken =
      hasRetriesRemaining && (await refreshAuth('authRequiredInterceptor'));

    if (newAuthToken) {
      // Retry the original request with the new auth token
      return retryRequest(newAuthToken, error.config);
    }

    store.dispatch(requireLoginAction());

    return Promise.reject(error);
  }

  return configureResponseInterceptor(identity, authRequiredInterceptor);
}

export const testExports = {
  getRefreshTokenId,
  resetTokenRefreshReq,
};
