import React from 'react';
import styled, { keyframes } from 'styled-components';
import Flex from 'styled-flex-component';
import { Flipper, Flipped } from 'react-flip-toolkit';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { FadeTransition } from 'react-transition-components';
import { MEDIUM_UP } from 'utils/mediaQueries';
import Media from 'react-media';
import AnimateHeight from '@frameio/components/src/styled-components/AnimateHeight';
import CaronIcon from '@frameio/components/src/svgs/icons/caron.svg';
import Button from '@frameio/components/src/styled-components/Button';
import TextInput from '@frameio/components/src/styled-components/TextInput';
import SearchIcon from '@frameio/components/src/svgs/icons/search.svg';
import CancelIcon from '@frameio/components/src/svgs/icons/cancel-small.svg';
import PulsingCircle from '@frameio/components/src/styled-components/PulsingCircle';
import InfiniteGrid, { VIEW_TYPES } from 'components/InfiniteGrid';
import track from 'analytics';
import { TEAM_MEMBER_VS_COLLABORATOR_SUPPORT_URL } from 'URLs';
import LoadingPerson from './LoadingPerson';

export const ROW_HEIGHT = 48;
const HEADER_HEIGHT = 28;

const TRANSITION_DURATION = 400;
const TRANSITION_EASE = `cubic-bezier(0.4, 0, 0, 1)`;
const TRANSITION_EASE_IN = `cubic-bezier(0.45, 0, 1, 1)`;
const TRANSITION_EASE_OUT = `cubic-bezier(0.3, 0, 0, 1)`;

const SUPPORT_URL = TEAM_MEMBER_VS_COLLABORATOR_SUPPORT_URL;

const FilterButton = styled(Button).attrs(() => ({
  compact: true,
  text: true,
}))`
  font-weight: normal;

  @media ${MEDIUM_UP} {
    svg {
      margin-right: ${(p) => p.theme.spacing.micro};
    }
  }
`;

const slideIn = keyframes`
  0% {
    margin-left: 90%;
    opacity: 0;
  }
  50% { opacity: 1; }
  100% { margin-left: 0%; }
`;

const AnimateSlideIn = styled(Flex).attrs(() => ({ full: true }))`
  animation: ${slideIn} ${TRANSITION_DURATION}ms cubic-bezier(0.1, 0, 0, 1);
`;

const StyledTextInput = styled(TextInput)`
  background-color: ${(p) => p.theme.color.coldWhite};
  border-radius: ${(p) => p.theme.radius.default};
  font-size: ${(p) => p.theme.fontSize[1]};
  border: none;
  color: ${(p) => p.theme.color.gray};
  line-height: 1.6;
  padding-left: ${({ theme }) =>
    `calc(${theme.spacing.large} + ${theme.spacing.micro})`};

  &::placeholder {
    font-size: ${(p) => p.theme.fontSize[1]};
    color: ${(p) => p.theme.color.gray};
    opacity: 1;
  }

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
`;

const ClearSearchButton = styled(Button).attrs(() => ({
  icon: 'true',
  text: true,
  compact: true,
}))`
  position: absolute;
  right: ${(p) => p.theme.spacing.medium};

  svg {
    animation: ${keyframes`
      0% { transform: rotate(45deg); }
      100% { transform: rotate(0deg); }
    `} 250ms ease-out;
  }
`;

const ToggleButton = styled(Button).attrs(() => ({
  compact: true,
  text: true,
}))`
  margin-left: 0px;
  svg {
    margin-left: -${(p) => p.theme.spacing.micro};
    margin-right: ${({ theme }) => `calc(${theme.spacing.micro}/2)`};
    ${(p) => p.disabled && `width: 0px;`}
  }
`;

const DropArrow = styled(({ isCollapsed, isEnabled, ...rest }) => (
  <CaronIcon width={18} height={18} {...rest} />
))`
  transition-property: transform, width, opacity;
  transition-duration: 200ms, 300ms, 300ms;
  transition-delay: 0ms, 150ms, 150ms;
  transition-timing-function: ${TRANSITION_EASE};

  ${({ isEnabled, isCollapsed }) =>
    `
  transform: rotate(${isCollapsed ? '-90deg' : '0deg'});
  opacity: ${isEnabled ? '1' : '0'}
  `};
`;

export const Filter = styled(Flex).attrs(() => ({
  alignCenter: true,
  justifyBetween: true,
}))`
  /* We need to add extra padding when the people list is collapsed. */
  transition: margin 0.1s linear;
  margin-bottom: ${({ hasBottomMargin, theme }) =>
    hasBottomMargin ? theme.spacing.tiny : 0};
`;

export const GridFlipper = styled(Flipper)`
  width: 100%;
  /* Subtract the top padding from the height to keep the empty state centered */
  height: ${({ height, theme: { spacing } }) =>
    height - parseInt(spacing.medium, 0)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  ${({ makeAllRowsVisible }) => makeAllRowsVisible && `overflow-y: hidden;`}
`;

const Wrapper = styled.div`
  font-size: ${(p) => p.theme.fontSize[2]};
  color: ${(p) => p.theme.color.coolBlack};
`;

export const NoResultsWrapper = styled.div`
  text-align: center;
  padding: ${(p) => p.theme.spacing.medium};

  h4 {
    ${(p) => p.theme.fontStyle.body};
    color: ${(p) => p.theme.color.gray};
    margin-bottom: ${(p) => p.theme.spacing.tiny};
  }

  p {
    ${(p) => p.theme.fontStyle.caption};
    color: ${(p) => p.theme.color.slateGray};
  }

  a {
    color: ${(p) => p.theme.color.brand};
    text-decoration: underline;
  }
`;
// In the Secure Sharing modal we don't want to use the AnimateHeight component.
// Safari does not always update the height correctly
// if a user is very fast at clicking the expand while the modal is still "opening"
const ShowAllWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  ${(p) => {
    // Use Math.min() to set a min duration Xms (if count is low)
    // Use Math.max() to set a max duration Xms if count is Y+
    const calcDuration = Math.min(Math.max(p.count * 50, 300), 750);

    return `height: ${p.visibleHeight}px;
      transition: height ${
        p.visibleHeight === 0
          ? `${calcDuration}ms ${TRANSITION_EASE_OUT}`
          : `${calcDuration}ms ${TRANSITION_EASE_IN}`
      };`;
  }}
`;

function NoResults({ query, userType }) {
  return (
    <NoResultsWrapper>
      {query ? (
        <React.Fragment>
          <h4>
            No {userType} found matching “{query}”.
          </h4>
          <p>Try filtering for something else.</p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h4>Looks like you’re all alone in here.</h4>
          <p>
            Frame.io is better with collaborators.&nbsp;
            <a href={SUPPORT_URL} target="_blank" rel="noopener noreferrer">
              Learn more
            </a>
            .
          </p>
        </React.Fragment>
      )}
    </NoResultsWrapper>
  );
}
NoResults.propTypes = {
  query: PropTypes.string,
  userType: PropTypes.string,
};

NoResults.defaultProps = {
  query: undefined,
  userType: 'collaborators',
};

export default class PeopleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      isCollapsed: props.scrollTop > 0 ? false : props.canCollapse,
      isFiltering: false,
    };
  }

  componentDidMount() {
    this.fetchPage(1);
  }

  onFilterChange = debounce(() => {
    this.fetchPage(1);
  }, 200);

  fetchPage = (page) => {
    const { filterPeople, fetchPeople } = this.props;
    const { query } = this.state;
    if (query.length > 0) {
      filterPeople(query, page);
    } else {
      fetchPeople(page);
    }
  };

  clearFilter = () => {
    if (this.state.query) {
      this.setState({ isFiltering: false, query: '' }, () => this.fetchPage(1));
    } else {
      this.setState({ isFiltering: false });
    }
  };

  renderToggleButton = () => {
    const {
      canCollapse,
      renderHeader,
      totalCount,
      trackingEvent,
      trackingTitle,
      trackingPage,
      trackingPosition,
    } = this.props;

    const { isCollapsed } = this.state;

    let canRenderPersonList = false;
    let isEnabled = false;
    // We are looking specifically for undefined.
    // totalCount could equal 0, and v.likely.
    if (totalCount !== undefined) {
      isEnabled = canCollapse && totalCount > 0;
      canRenderPersonList = true;
    }

    return (
      <ToggleButton
        disabled={!isEnabled}
        onClick={() => {
          if (isCollapsed && trackingTitle) {
            track(trackingEvent, {
              title: `view_existing_${trackingTitle}_members`,
              page: trackingPage,
              position: trackingPosition,
            });
          }
          this.setState({ isCollapsed: !isCollapsed });
        }}
      >
        {/* isEnabled is passed as prop not a condition, so that when we do show the DropArrow, it animates in  */}
        <DropArrow isCollapsed={isCollapsed} isEnabled={isEnabled} />
        {canRenderPersonList && renderHeader(totalCount)}
      </ToggleButton>
    );
  };

  renderFilter = () => {
    const {
      trackingEvent,
      trackingTitle,
      trackingPage,
      trackingPosition,
    } = this.props;
    const { isCollapsed, isFiltering, query } = this.state;

    return (
      <Filter hasBottomMargin={isCollapsed}>
        {isFiltering ? (
          <React.Fragment>
            <AnimateSlideIn>
              <StyledTextInput
                placeholder="Filter by name or email"
                compact
                type="search"
                autoFocus
                maxLength={50}
                value={query}
                icon={<SearchIcon width={16} height={16} />}
                onKeyDown={(event) => {
                  if (event.key === 'Escape') {
                    event.stopPropagation();
                    this.clearFilter();
                  }
                }}
                onChange={(event) => {
                  this.setState(
                    { query: event.target.value },
                    this.onFilterChange
                  );
                }}
              />
            </AnimateSlideIn>
            <ClearSearchButton onClick={this.clearFilter}>
              <CancelIcon width={16} height={16} />
            </ClearSearchButton>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <FadeTransition>{this.renderToggleButton()}</FadeTransition>

            <Media query={MEDIUM_UP} data-test-id="media-filter-button">
              {(isMediumUp) => (
                <FadeTransition in={!isCollapsed} data-filter-transition>
                  <FilterButton
                    icon={isMediumUp ? undefined : 'true'}
                    onClick={() => {
                      this.setState({ isFiltering: true });

                      if (trackingTitle) {
                        track(trackingEvent, {
                          title: `filter_existing_${trackingTitle}_members`,
                          page: trackingPage,
                          position: trackingPosition,
                        });
                      }
                    }}
                  >
                    <SearchIcon width={16} height={16} />
                    {isMediumUp && 'Filter by name or email'}
                  </FilterButton>
                </FadeTransition>
              )}
            </Media>
          </React.Fragment>
        )}
      </Filter>
    );
  };

  render() {
    const {
      canRenderList,
      canCollapse,
      children,
      className,
      personIds,
      numVisibleRows,
      makeAllRowsVisible,
      totalCount,
      scrollTop,
      setScrollTop,
      shouldShowFilter,
      renderEmptyList,
      userType,
      hasShadowOnScroll,
    } = this.props;

    let calcTotalHeight = 0;
    const { isCollapsed, query } = this.state;
    let content;

    if (totalCount) {
      calcTotalHeight = totalCount * ROW_HEIGHT + HEADER_HEIGHT;

      content = (
        <InfiniteGrid
          backgroundColor="transparent"
          hasShadowOnScroll={hasShadowOnScroll}
          scrollTop={scrollTop}
          setScrollTop={setScrollTop}
          listRowHeight={ROW_HEIGHT}
          itemIds={personIds}
          hasRowSeparator={false}
          totalItemCount={totalCount}
          overscanRowsCount={20}
          fetchItemsForPage={this.fetchPage}
          viewType={VIEW_TYPES.LIST}
          renderLoadingItem={() => <LoadingPerson />}
          makeAllRowsVisible={makeAllRowsVisible}
        >
          {({ itemId }) => (
            <Flipped flipId={itemId}>{children(itemId)}</Flipped>
          )}
        </InfiniteGrid>
      );
    } else {
      content =
        totalCount === undefined ? (
          <PulsingCircle />
        ) : (
          renderEmptyList(query, userType)
        );
    }

    const list = (
      <GridFlipper
        makeAllRowsVisible={makeAllRowsVisible}
        flipKey={personIds.length}
        height={
          makeAllRowsVisible
            ? calcTotalHeight
            : numVisibleRows * ROW_HEIGHT + HEADER_HEIGHT
        }
      >
        {content}
      </GridFlipper>
    );

    const listWithWrapper = makeAllRowsVisible ? (
      <ShowAllWrapper
        visibleHeight={isCollapsed ? 0 : calcTotalHeight}
        count={totalCount}
      >
        {list}
      </ShowAllWrapper>
    ) : (
      <AnimateHeight
        isVisible={!isCollapsed}
        duration={TRANSITION_DURATION}
        easing={TRANSITION_EASE}
      >
        {list}
      </AnimateHeight>
    );

    return (
      <Wrapper className={className}>
        {shouldShowFilter ? this.renderFilter() : this.renderToggleButton()}

        {canRenderList && (canCollapse ? listWithWrapper : list)}
      </Wrapper>
    );
  }
}

PeopleList.propTypes = {
  canCollapse: PropTypes.bool,
  scrollTop: PropTypes.number,
  // func that returns the last scollTop value before unmounting
  setScrollTop: PropTypes.func,
  canRenderList: PropTypes.bool,
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  personIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchPeople: PropTypes.func.isRequired,
  filterPeople: PropTypes.func.isRequired,
  numVisibleRows: PropTypes.number,
  // An override of the numVisibleRows prop, if set true,
  // we will dynamically calcualte the height by the totalCount of items in the list:
  makeAllRowsVisible: PropTypes.bool,
  renderHeader: PropTypes.func,
  totalCount: PropTypes.number,
  trackingEvent: PropTypes.string,
  trackingPage: PropTypes.string,
  trackingPosition: PropTypes.string,
  trackingTitle: PropTypes.string,
  shouldShowFilter: PropTypes.bool,
  renderEmptyList: PropTypes.func,
  userType: PropTypes.string,
  hasShadowOnScroll: PropTypes.bool,
};

PeopleList.defaultProps = {
  canCollapse: true,
  scrollTop: 0,
  setScrollTop: undefined,
  canRenderList: true,
  children: null,
  className: undefined,
  renderHeader: (count) => (
    <h4>
      {count} {count === 1 ? 'person' : 'people'} in this project
    </h4>
  ),
  renderEmptyList: (query, userType) => (
    <NoResults query={query} userType={userType} />
  ),
  totalCount: undefined,
  numVisibleRows: 5.5,
  makeAllRowsVisible: false,
  trackingEvent: undefined,
  trackingPage: undefined,
  trackingPosition: undefined,
  trackingTitle: undefined,
  shouldShowFilter: true,
  userType: 'collaborators',
  hasShadowOnScroll: true,
};

export const testExports = {
  DropArrow,
  ToggleButton,
  FilterButton,
  StyledTextInput,
  ClearSearchButton,
  GridFlipper,
  NoResults,
  ROW_HEIGHT,
  HEADER_HEIGHT,
  SUPPORT_URL,
  SearchIcon,
};
