import React from 'react';
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { get, partition } from 'lodash';
import styled from 'styled-components';
import {
  listUserGroups as listUserGroupsCoreSaga,
  createUserGroup as createUserGroupCoreSaga,
  showUserGroup as showUserGroupCoreSaga,
  updateUserGroup as updateUserGroupCoreSaga,
  deleteUserGroup as deleteUserGroupCoreSaga,
} from '@frameio/core/src/groups/sagas';
import { groupEntitySelector } from '@frameio/core/src/groups/selectors';
import { currentAccountSelector } from 'selectors/accounts';
import { openModal, closeModal } from 'components/Modal/actions';
import { confirmDelete } from 'components/Dialog/SimpleDialog/sagas';
import { TYPE } from 'components/UserSearch';
import fetchAllPages from '@frameio/core/src/shared/sagas/fetchAllPages';
import { GROUPS_MODAL, createOrUpdateGroupError } from './actions';
import { groupsContainerIdSelector } from './selectors';

import GroupsModal from './GroupsModal';

const DeleteGroup = styled.span`
  word-wrap: break-word;
`;

function formatValuesForServerCall(values) {
  const { tokens, ...rest } = values;

  const [userTokens, emailTokens] = partition(
    tokens,
    (token) => token.type === TYPE.USER
  );

  return {
    ...rest,
    user_ids: userTokens.map((t) => t.id),
    emails: emailTokens.map((t) => t.id),
  };
}

function* fetchUserGroups() {
  const { id: accountId } = yield select(currentAccountSelector);
  yield call(fetchAllPages, (params) =>
    listUserGroupsCoreSaga(accountId, { ...params })
  );
}

function* openCreateGroupModal() {
  yield put(openModal(<GroupsModal />));
}

function* openEditGroupModal(action) {
  const { groupId } = action.payload;
  const { success } = yield call(showUserGroupCoreSaga, groupId);

  if (success) {
    yield put(openModal(<GroupsModal editMode />));
  }
}

function* createUserGroup(action) {
  const params = formatValuesForServerCall(action.payload.params);

  const { id: accountId } = yield select(currentAccountSelector);
  const { success, failure } = yield call(
    createUserGroupCoreSaga,
    accountId,
    params
  );

  if (success) {
    yield put(closeModal());
  } else {
    const errorMessage = get(failure, 'payload.error.response.data.message');
    yield put(createOrUpdateGroupError(errorMessage));
  }
}

function* updateUserGroup(action) {
  const params = formatValuesForServerCall(action.payload.params);

  const groupId = yield select(groupsContainerIdSelector);
  const { success, failure } = yield call(
    updateUserGroupCoreSaga,
    groupId,
    params
  );

  if (success) {
    yield put(closeModal());
  } else {
    const errorMessage = get(failure, 'payload.error.response.data.message');
    yield put(createOrUpdateGroupError(errorMessage));
  }
}

function* deleteUserGroup(action) {
  const { groupId } = action.payload;
  const { name: groupName } = yield select(groupEntitySelector, { groupId });

  const shouldDelete = yield call(
    confirmDelete,
    'Delete group?',
    <DeleteGroup>
      Are you sure you want to delete <strong>{groupName}</strong>?
    </DeleteGroup>
  );

  if (shouldDelete) {
    yield call(deleteUserGroupCoreSaga, groupId);
  }
}

export default [
  takeLatest(GROUPS_MODAL.FETCH_DATA, fetchUserGroups),
  takeLatest(GROUPS_MODAL.OPEN_CREATE_MODAL, openCreateGroupModal),
  takeLatest(GROUPS_MODAL.OPEN_EDIT_GROUP_MODAL, openEditGroupModal),
  takeLatest(GROUPS_MODAL.CREATE_GROUP, createUserGroup),
  takeLatest(GROUPS_MODAL.UPDATE_GROUP, updateUserGroup),
  takeLatest(GROUPS_MODAL.DELETE_GROUP, deleteUserGroup),
];

export const testExports = {
  createUserGroup,
  deleteUserGroup,
  openEditGroupModal,
  openCreateGroupModal,
  updateUserGroup,
  DeleteGroup,
};
