// the modal variants are named after the launch dates
export const MODAL_VARIANT_BETA_V4 = '040924';
export const MODAL_VARIANT_V4 = '100224';
export const MODAL_VARIANT_V4_VARIANT_A = '002';

export const getModalVariantV4 = (
  isV4MigrationModalEnabled,
  isV4MigrationModalVariantAEnabled
) => {
  if (isV4MigrationModalEnabled) {
    return isV4MigrationModalVariantAEnabled
      ? MODAL_VARIANT_V4_VARIANT_A
      : MODAL_VARIANT_V4;
  }
  return MODAL_VARIANT_BETA_V4;
};
