import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const animate = { height: 'auto' };
const initial = { height: 0 };

const Container = styled(motion.div)`
  overflow: hidden;
`;

function AnimateHeight({ isVisible, children, ...other }) {
  return (
    <AnimatePresence>
      {isVisible && (
        <Container
          animate={animate}
          exit={initial}
          initial={initial}
          {...other}
        >
          {children}
        </Container>
      )}
    </AnimatePresence>
  );
}

AnimateHeight.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default AnimateHeight;
