import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import PropTypes from 'prop-types';
import Button from '@frameio/components/src/styled-components/Button';
import { SimplePopoverable, Popover } from '@frameio/components';

const Title = styled.div`
  color: ${(p) => p.theme.color.coolBlack};
  font-weight: bold;
  font-size: ${(p) => p.theme.fontSize[1]};
`;

const ButtonGroup = styled(Flex).attrs(() => ({ justifyCenter: true }))`
  ${Button} {
    margin: 0 ${(p) => p.theme.spacing.micro};
  }
`;

const Content = styled.div`
  padding: ${({ theme }) => `calc(${theme.spacing.tiny} * 2)`};

  ${ButtonGroup} {
    margin-top: ${({ theme }) => `calc(${theme.spacing.tiny} * 2)`};
  }
`;

function ConfirmPopover({
  title,
  confirmButtonText,
  onConfirm,
  onCancel,
  children,
  ...rest
}) {
  return (
    <SimplePopoverable
      popover={
        <Popover borderRadius={6} pointerHeight={9} pointerWidth={36}>
          <div>
            <Content>
              <Title>{title}</Title>

              <ButtonGroup>
                <Button compact data-test-id="cancel-button" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  data-test-id="confirm-button"
                  compact
                  destructive
                  onClick={onConfirm}
                >
                  {confirmButtonText}
                </Button>
              </ButtonGroup>
            </Content>
          </div>
        </Popover>
      }
      {...rest}
    >
      {children}
    </SimplePopoverable>
  );
}

ConfirmPopover.propTypes = {
  // The title of the Popover
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  // The title of the confirm button
  confirmButtonText: PropTypes.string.isRequired,

  // The function that is called when the confirm-button is triggered
  onConfirm: PropTypes.func,

  // The function that is called when the cancel button is clicked
  onCancel: PropTypes.func,

  children: PropTypes.node.isRequired,
};

ConfirmPopover.defaultProps = {
  title: undefined,
  onConfirm: () => {},
  onCancel: () => {},
};

export const testExports = { Title };
export default ConfirmPopover;
