import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import PropTypes from 'prop-types';
import { buttonReset } from '@frameio/components/src/mixins';
import Button from '@frameio/components/src/styled-components/Button';
import CancelIcon from '@frameio/components/src/svgs/icons/16/cancel.svg';
import Animation from '@frameio/components/src/components/Animation';
import useLazyLoadJson from 'utils/useLazyLoadJson';
import animationDataUrl from './DesktopApp.animation.json';

const Wrapper = styled.div`
  padding: ${(p) => p.theme.spacing.medium};
  width: 400px;
`;

export const CheckboxWrapper = styled(Flex).attrs({
  alignCenter: true,
  justifyCenter: true,
})`
  ${buttonReset()}
  margin: ${(p) => `${p.theme.spacing.units(2)} auto ${p.theme.spacing.tiny}`};

  span {
    margin-left: ${(p) => p.theme.spacing.tiny};
  }
`;

export const StyledButton = styled(Button)`
  width: 200px;
  margin: ${(p) => p.theme.spacing.micro} 0;
`;

const CloseButton = styled(Button).attrs(() => ({
  icon: 'true',
  text: true,
  compact: true,
}))`
  position: absolute;
  right: ${(p) => p.theme.spacing.medium};
  top: ${(p) => p.theme.spacing.medium};
`;

const VisuallyVeryPleasingAnimation = styled.div`
  margin: ${(p) => p.theme.spacing.units(2)};
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
`;

const Title = styled.span`
  ${(p) => p.theme.fontStyle.headerXS}
`;

const Content = styled(Flex).attrs({
  alignCenter: true,
})`
  flex-direction: column;
  padding: ${(p) => p.theme.spacing.units(2)};
  padding-bottom: 0;
  text-align: center;
  ${(p) => p.theme.fontStyle.bodyM}
`;

/**
 * DesktopAppModalBase is the base modal component used by
 * `DownloadWithDesktopApp` and `LaunchDesktopApp`. It provides
 * the basic modal skeleton.
 */
export default function DesktopAppModalBase({ title, children, onClose }) {
  const animationData = useLazyLoadJson(animationDataUrl);

  return (
    <Wrapper onKeyDown={(evt) => evt.key === 'Escape' && onClose()}>
      <CloseButton onClick={onClose}>
        <CancelIcon />
      </CloseButton>

      <Flex column center>
        <VisuallyVeryPleasingAnimation width={166} height={116}>
          {animationData && <Animation data={animationData} loop={false} />}
        </VisuallyVeryPleasingAnimation>
        <Title>{title}</Title>
      </Flex>

      <Content>{children}</Content>
    </Wrapper>
  );
}

DesktopAppModalBase.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  primaryLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
  onPrimaryClick: PropTypes.func,
  onSecondaryClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

DesktopAppModalBase.defaultProps = {
  children: null,
};

export const testExports = { CloseButton, Title };
