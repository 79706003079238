import React, { memo } from 'react';
import Globe from '@frameio/components/src/svgs/icons/24/globe.svg';
import Title from '../shared/Title';
import Container from '../shared/Container';
import Select from '../shared/Select';
import defaultCountries from '../../data/countries.json';

function generateOptions() {
  return defaultCountries.map((country) => ({
    label: country.name,
    value: country.code,
  }));
}

const options = generateOptions();

const CountryInput = React.forwardRef(
  ({ country, error, setFieldTouched, setFieldValue, touched }, ref) => {
    return (
      <>
        <Title>Billing Address</Title>
        <Container marginBottom>
          <Select
            defaultValue={country}
            error={error}
            icon={() => <Globe />}
            name="country"
            onBlur={() => setFieldTouched('country', true)}
            onChange={(value) => setFieldValue('country', value)}
            options={options}
            placeholder="Country"
            ref={ref}
            touched={touched}
          />
        </Container>
      </>
    );
  }
);

export default memo(CountryInput);
