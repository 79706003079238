import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import DialogAssetThumb from 'components/DialogAssetThumb';
import { type as assetType } from '@frameio/core/src/assets/helpers/constants';

/**
 * Determines if an asset is a bundle. If it is, then it checks if it is
 * a "Turnstyle" bundle, otherwise falls back to the generic "bundle".
 * @param {Object} asset - The asset object.
 * @returns {string | undefined} - In case it is not a bundle, returns undefined.
 */
function getBundleType(asset) {
  if (!asset.bundle) return undefined;
  const isTurnstyle = Object.values(asset.layout || {}).some(
    (layout) => layout.view_type === 'side-by-side'
  );
  return isTurnstyle ? 'Turnstyle asset' : 'bundle asset';
}

const Message = styled.span`
  word-break: break-word;
`;

export function getConfirmHeader(assets) {
  const numAssets = assets.length;
  if (numAssets === 1) {
    const asset = assets[0];
    switch (asset.type) {
      case assetType.VERSION_STACK:
        return 'Delete all versions?';
      case assetType.FOLDER:
        return 'Delete folder?';
      default:
        return `Delete ${getBundleType(asset) || 'file'}?`;
    }
  }

  if (assets.every(({ type }) => type === assetType.FOLDER)) {
    return `Delete ${numAssets} folders?`;
  }
  if (assets.every(({ type }) => type === assetType.FILE)) {
    return `Delete ${numAssets} files?`;
  }

  return `Delete ${numAssets} items?`;
}

export default function ConfirmDeleteAssets({ assets }) {
  const itemCount = assets.length;
  let message;

  if (itemCount === 1) {
    const asset = assets[0];
    const bundleType = getBundleType(asset);
    if (asset.type === assetType.VERSION_STACK) {
      message = (
        <span>
          Are you sure you want to delete ALL{' '}
          <strong>{asset.versions} versions</strong> from this version stack?
        </span>
      );
    } else if (asset.type === assetType.FOLDER) {
      message = (
        <span>
          Are you sure you want to delete{' '}
          <strong>{asset.name || 'this folder'}</strong>?
        </span>
      );
    } else if (bundleType) {
      message = (
        <span>
          Are you sure you want to delete ALL files associated with{' '}
          <strong>{asset.name || `this ${bundleType}`}</strong>?
        </span>
      );
    } else {
      message = (
        <span>
          Are you sure you want to delete{' '}
          <strong>{asset.name || 'this file'}</strong>?
        </span>
      );
    }
  } else if (assets.every(({ type }) => type === assetType.FOLDER)) {
    message = `Are you sure you want to delete ${itemCount} folders?`;
  } else if (assets.every(({ type }) => type === assetType.FILE)) {
    message = `Are you sure you want to delete ${itemCount} files?`;
  } else {
    message = `Are you sure you want to delete ${itemCount} items?`;
  }

  return (
    <Flex alignCenter>
      <FlexItem noShrink grow={0}>
        <DialogAssetThumb asset={assets[0]} />
      </FlexItem>
      <Message data-test-id="message">{message}</Message>
    </Flex>
  );
}

ConfirmDeleteAssets.propTypes = {
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(Object.values(assetType)),
      versions: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};
