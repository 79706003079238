import { connect } from 'react-redux';
import {
  aemFoldersByRepositoryIdSelector,
  isFetchingAemRepositoryTreeSelector,
  selectedAemDestinationSelector,
} from './selectors';
import AemModalContent from './AemModalContent';

function mapStateToProps(state) {
  const selectedAemDestination = selectedAemDestinationSelector(state) || {};
  const aemFolders = aemFoldersByRepositoryIdSelector(
    state,
    selectedAemDestination?.repo_id
  );

  return {
    ...state,
    aemFolders,
    aemFoldersLoading: isFetchingAemRepositoryTreeSelector(state),
    aemRepositories: state?.aemRepositoryTrees?.aemRepositories || {},
    selectedAemDestination,
  };
}

export default connect(mapStateToProps)(AemModalContent);
