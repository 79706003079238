import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sessionWatermarkTemplateEntitySelector } from '@frameio/core/src/sessionWatermarkTemplate/selectors';
import { currentAccountSelector } from 'selectors/accounts';
import { getSessionWatermarkTemplate } from './actions';

export default function useWatermarkEntity(watermarkTemplateId) {
  const dispatch = useDispatch();
  const currentAccount = useSelector(currentAccountSelector);
  const entity = useSelector((state) =>
    sessionWatermarkTemplateEntitySelector(state, watermarkTemplateId)
  );

  useEffect(() => {
    if (!entity && currentAccount.id && watermarkTemplateId) {
      dispatch(
        getSessionWatermarkTemplate(currentAccount.id, watermarkTemplateId)
      );
    }
  }, [entity, currentAccount, watermarkTemplateId, dispatch]);

  return entity;
}
