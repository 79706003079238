import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import styled from 'styled-components';
import { rgba } from 'polished';
import { shouldComponentUpdate } from 'reflective-bind';
import { FadeTransition } from 'react-transition-components';

import { absoluteFill } from '@frameio/components/src/mixins';
import { type as assetType } from '@frameio/core/src/assets/helpers/constants';
import {
  CardFace,
  CardOverlay,
} from '@frameio/components/src/styled-components/Card';

import ClipPathTransition from 'components/ClipPathTransition';
import ScaleFadeTransition from 'components/ScaleFadeTransition';

import FileAssetThumb from './FileAssetThumb/FileAssetThumb';
import VersionBadgeButton, { BadgeButton, VersionBadge } from './VersionBadge';
import FileCardOverlay from './FileCardOverlay';
import { assetPropType } from './FileCardChin';
import {
  DEFAULT_EASE_OUT,
  DROP_CARD_FACE_TRANSITION_DURATION_MS,
} from './DropZoneIndicator';

const DROP_FACE_TRANSITION_EASING = 'cubic-bezier(0.17, -0.12, 0, 1)';
const DROP_FACE_TRANSITION_OPTS = `${DROP_CARD_FACE_TRANSITION_DURATION_MS}ms ${DROP_FACE_TRANSITION_EASING}`;

// Transition thumbnail in with a circle mask that expands until it reveals the
// entire thumbnail.
const CIRCLE_MASK_EASING = 'cubic-bezier(0.33, 0, 0, 1)';
const CIRCLE_MASK_DURATION = 800;
const CIRCLE_MASK_START = '9px';

const StyledCardFace = styled(CardFace)`
  background-color: ${(p) => p.theme.color.coolBlack};
  overflow: hidden;
  transition:
    transform ${DROP_FACE_TRANSITION_OPTS},
    border-radius ${DROP_FACE_TRANSITION_OPTS},
    box-shadow ${DROP_FACE_TRANSITION_OPTS};

  ${({ isDropZone }) => isDropZone && 'transform: scale(0.8);'}
  ${({ isDropping }) => isDropping && 'transform: scale(0.6);'}
  ${({ isDropZone, isDropping, theme }) =>
    (isDropZone || isDropping) &&
    `
    border-radius: ${theme.radius.large};
    box-shadow: 0 2px 4px 0 ${rgba(theme.color.black, 0.12)};
  `};

  ${BadgeButton} {
    margin-left: ${(p) => p.theme.spacing.micro};
  }

  ${CardOverlay}::after {
    ${absoluteFill()};
    content: '';
    transition: background-color ${DROP_FACE_TRANSITION_OPTS};
    pointer-events: none;

    ${({ isDropZone, isDropping, theme }) =>
      (isDropZone || isDropping) &&
      `
      background-color: ${rgba(theme.color.black, 0.2)};
    `}
  }

  /* Once the versioning animation is complete we don't need it to transition back. When the
     POST request completes, the new CardFace it will just re-render in place of this. */
  &,
  ${CardOverlay}::after {
    ${({ isDropComplete }) =>
      isDropComplete &&
      `
      transition: none;
    `}
  }
`;

export default class FileCardFace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldTransitionClipPath: !props.showAssetThumb,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shouldComponentUpdate(this, nextProps, nextState);
  }

  getOverlayProps = (asset) => {
    // A bit of a hack: the cover asset is not always updated at the same time
    const { workfront_connected, workfront_approval_status } = this.props.asset;

    return {
      isWorkfrontConnected: workfront_connected,
      workflowStatus: asset.label,
      workfrontApprovalStatus: workfront_approval_status,
      duration: asset.duration,
      assetIsPrivate: this.props.asset.private,
      assetIsArchived: !!asset.archived_at,
      assetName: asset.name,
    };
  };

  render() {
    const {
      asset,
      assetToVersion,
      coverAsset,
      isDropComplete,
      isDropZone,
      onDropDone,
      parentAssetType,
      showAssetThumb,
      thumbHeight,
      thumbRef,
      thumbWidth,
      versionAsset,
    } = this.props;
    const { shouldTransitionClipPath } = this.state;

    const isDropping = !!assetToVersion && !isDropComplete;
    const coverOrAsset = coverAsset || asset;
    const assetForThumb = isDropComplete ? assetToVersion : coverOrAsset;
    const nextVersion = asset.versions > 1 ? asset.versions + 1 : 2;
    const maxMaskRadius = Math.ceil(
      Math.hypot(thumbWidth / 2, thumbHeight / 2)
    );

    return (
      <StyledCardFace
        isDropZone={isDropZone}
        isDropping={isDropping}
        isDropComplete={isDropComplete}
        ref={thumbRef}
      >
        <ClipPathTransition
          in={showAssetThumb}
          mountOnEnter={false}
          unmountOnExit={false}
          appear={false}
          timeout={CIRCLE_MASK_DURATION}
          easing={CIRCLE_MASK_EASING}
          // (CORE-497): When `clip-path` is set on an element, scrollable
          // ancestors do not show their scrollbars when scrolling. So we set it
          // to 'none' until we need to actually do the transitions.
          start={
            shouldTransitionClipPath ? `circle(${CIRCLE_MASK_START})` : 'none'
          }
          end={shouldTransitionClipPath ? `circle(${maxMaskRadius}px)` : 'none'}
          onEnter={() => this.setState({ shouldTransitionClipPath: true })}
          onEntered={() => this.setState({ shouldTransitionClipPath: false })}
        >
          <div>
            <ScaleFadeTransition
              in={showAssetThumb}
              mountOnEnter={false}
              unmountOnExit={false}
              appear={false}
              timeout={CIRCLE_MASK_DURATION}
              easing={DEFAULT_EASE_OUT}
              start={[0.2, 0.92]}
              end={[1, 1]}
            >
              <FileAssetThumb
                asset={assetForThumb}
                height={thumbHeight}
                width={thumbWidth}
                scrubberBarWidth={2}
              />
            </ScaleFadeTransition>
            {isDropComplete ? ( // State 3 -> 4: Fade in the new overlay and version
              <FadeTransition>
                <FileCardOverlay {...this.getOverlayProps(assetToVersion)}>
                  <ScaleFadeTransition
                    delay={200}
                    timeout={DROP_CARD_FACE_TRANSITION_DURATION_MS}
                    onEntered={() =>
                      versionAsset(assetToVersion.id, asset.id, onDropDone)
                    }
                  >
                    {/*
                      During the transition, optimistically update the total
                      number of versions in the version stack by passing the
                      incremented total directly into an unconnected component.
                    */}
                    <VersionBadge versions={nextVersion} />
                  </ScaleFadeTransition>
                </FileCardOverlay>
              </FadeTransition>
            ) : (
              // All other states
              <FileCardOverlay {...this.getOverlayProps(coverOrAsset)}>
                {/*
                  If the asset being passed in is a version in a version stack,
                  then we show what its version in that stack is. If the asset
                  is the version stack itself, then we show a button that allows
                  the user to manage the version stack.

                  The former will really only happen in Search, where version
                  stacks are never returned, but the individual versions in
                  each stack are.
                */}
                {parentAssetType === assetType.VERSION_STACK ? (
                  <VersionBadge versions={Math.abs(asset.index)} />
                ) : (
                  <VersionBadgeButton
                    assetId={asset.id}
                    versions={asset.versions}
                  />
                )}
              </FileCardOverlay>
            )}
          </div>
        </ClipPathTransition>
      </StyledCardFace>
    );
  }
}

FileCardFace.propTypes = {
  asset: assetPropType.isRequired,
  assetToVersion: assetPropType,
  coverAsset: assetPropType,
  isDropComplete: PropTypes.bool,
  isDropZone: PropTypes.bool,
  onDropDone: PropTypes.func,
  parentAssetType: PropTypes.string,
  showAssetThumb: PropTypes.bool,
  thumbHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thumbRef: PropTypes.shape({ currrent: PropTypes.instanceOf(Element) }),
  thumbWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  versionAsset: PropTypes.func,
};

FileCardFace.defaultProps = {
  assetToVersion: undefined,
  coverAsset: undefined,
  isDropComplete: false,
  isDropZone: false,
  onDropDone: noop,
  parentAssetType: undefined,
  showAssetThumb: true,
  thumbHeight: 0,
  thumbRef: undefined,
  thumbWidth: 0,
  versionAsset: noop,
};

export const testExports = {
  StyledCardFace,
  CIRCLE_MASK_START,
};
