import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MenuButton } from '@frameio/vapor';
import SettingsIcon from '@frameio/components/src/svgs/icons/settings.svg';
import { trackButtonClick } from 'analytics';
import { openEditProjectForm } from 'pages/ProjectContainer/actions';
import { setProjectId } from 'components/ProjectForms/EditProject/actions';

function mapDispatchToProps(dispatch) {
  return {
    onSettingsClick: (projectId) => {
      dispatch(setProjectId(projectId));
      dispatch(openEditProjectForm(projectId));
    },
  };
}

function ProjectSettingsItem({
  projectId,
  onSettingsClick,
  trackingPage,
  trackingPosition,
  ...rest
}) {
  return (
    <MenuButton
      onSelect={() => {
        onSettingsClick(projectId);
        trackButtonClick(
          'open-project-settings',
          trackingPage,
          trackingPosition
        );
      }}
      iconBefore={<SettingsIcon />}
      {...rest}
    >
      Project settings
    </MenuButton>
  );
}

ProjectSettingsItem.defaultProps = {
  projectId: '',
  onSettingsClick: () => {},
};

ProjectSettingsItem.propTypes = {
  projectId: PropTypes.string,
  onSettingsClick: PropTypes.func,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(ProjectSettingsItem);
