import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import PropTypes from 'prop-types';
import ProgressDot from './ProgressDot';
import { steps } from '../CancelAccountFlow';

const ProgressDotsContainer = styled(Flex)`
  .ProgressDots__dot {
    margin-right: ${(p) => p.theme.spacing.units(2)};
  }
`;

const ProgressDots = ({ currentStep }) => (
  <ProgressDotsContainer alignCenter justifyBetween>
    {steps.map((step, stepNum) => (
      <ProgressDot
        className="ProgressDots__dot"
        key={step}
        isCurrent={steps.indexOf(currentStep) === stepNum}
      />
    ))}
  </ProgressDotsContainer>
);

export default ProgressDots;

ProgressDots.propTypes = {
  currentStep: PropTypes.string.isRequired,
};
