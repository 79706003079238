// Polyfills
import 'core-js/es6/promise';
import 'core-js/es6/set';
import 'core-js/es6/symbol';
import 'core-js/fn/array/includes';
import 'core-js/fn/string/includes';
import 'core-js/fn/array/find';
import 'core-js/fn/array/find-index';
import 'core-js/fn/array/from';
import 'core-js/fn/math/hypot';
import 'core-js/fn/object/assign';
import 'core-js/fn/object/define-property';
import 'core-js/fn/object/define-properties';
import 'core-js/fn/object/entries';
import 'core-js/fn/object/values';
import 'core-js/fn/promise/finally';
import 'requestidlecallback';
import 'clipboard-polyfill';

// TODO (cjbell): remove these legacy initializers
// Custom prototypes
import 'utilities/prototypes';
// Things attached to the window object
import 'core/jt_cross_platform';

// Sentry logging
import configureRaven from 'utils/configureRaven';

// Core config
import config from 'config';
import {
  configureLegacyURL,
  configureBaseURL,
  configureSocketUrl,
  configureConnectionId,
  configureClientId,
} from '@frameio/core';

import { setReqHeader } from '@frameio/core/src/shared/services/api';

import configureDatadogRUM from 'utils/datadog';

// This is sent to the api as an `x-frameio-client` header to identify requests,
// which is useful when parsing logs.
//
// - Prod: "web/1.2.3" (git version tag)
// - Dev/Stage/Eval: "web/beef123..." (git commit hash)
// - Local: "web/local"
function getClientId() {
  return `web/${process.env.RELEASE_VERSION}`;
}

configureDatadogRUM();
configureRaven();
configureLegacyURL(config.apiHost);
configureBaseURL(config.apiHost);
configureSocketUrl(config.socketV2Host);
configureConnectionId(config.instanceId);
configureClientId(getClientId());
setReqHeader('x-client-ostrich-enabled', 'true');
