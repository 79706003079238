export const HOTKEY = {
  RIGHT_ARROW: 'ArrowRight',
  LEFT_ARROW: 'ArrowLeft',
  K: 'k',
  SPACE: ' ',
  I: 'i',
  L: 'l',
  O: 'o',
  J: 'j',
  R: 'r',
  S: 's',
  F: 'f',
  V: 'v',
  M: 'm',
  G: 'g',
  ONE: '1',
  TWO: '2',
  THREE: '3',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  SHIFT: 'Shift',
  SHIFT_RIGHT_ARROW: 'ShiftArrowRight',
  SHIFT_LEFT_ARROW: 'ShiftArrowLeft',
  TAB: 'Tab',
};

export default { HOTKEY };
