import isHexcolor from 'is-hexcolor';
import path from 'path';
import { isString, get, find } from 'lodash';
import Raven from 'raven-js';
import { color as themeColor } from '@frameio/components/src/theme/darkTheme';

// Remove the `#` from the hexcodes
const coolBlack = themeColor.coolBlack.substring(1);
const white = themeColor.white.substring(1);
const brand = themeColor.brand.substring(1);

// [BUGS-552] - We were seeing some very strange results whereby some of the values passed into the
// ColorPicker were actually objects, so this validation is here to ensure we pass correctly
// formatted hex values around 🙃.
function validHexOrNull(color) {
  if (!color) return null;

  if (isString(color) && isHexcolor(`#${color}`)) {
    return color;
  }

  Raven.captureMessage(
    `Expected a valid hex color, received ${JSON.stringify(color)}`
  );
  return null;
}

function firstValidHex(...colors) {
  return find(colors, (color) => validHexOrNull(color));
}

export function presentationColorsWithFallback(presentation, team) {
  const background = firstValidHex(
    get(presentation, 'background_color'),
    get(team, 'background_color'),
    get(team, 'default_background_color'),
    white
  );
  const text = firstValidHex(
    get(presentation, 'text_color'),
    get(team, 'font_color'),
    get(team, 'default_font_color'),
    coolBlack
  );
  const accent = firstValidHex(
    get(presentation, 'color'),
    get(team, 'color'),
    get(team, 'default_color'),
    brand
  );

  return {
    background,
    text,
    accent,
  };
}

/**
 * Removes file extension.
 * @param {string} fileName - Asset name.
 */
export const truncateExtension = (fileName) =>
  path.basename(fileName, path.extname(fileName));

/**
 * Renders the name of a file with or without its extension, based on the presentation value
 * @param {object} asset - Asset.
 * @param {object} presentation - Presentation.
 */
export const getPresentationAssetName = (asset, presentation) => {
  const { include_ext: includeExt } = presentation;

  const name = get(asset, 'name');
  return includeExt ? name : truncateExtension(name);
};

export const testExports = {
  truncateExtension,
  validHexOrNull,
  firstValidHex,
};
