import React from 'react';
import { call, put, spawn, takeLatest } from 'redux-saga/effects';
import AddMembers from 'components/AddMembers';
import { openModal, closeModal } from 'components/Modal/actions';
import { batchAddTeamMembers as coreBatchAddTeamMembers } from '@frameio/core/src/teamMembers/sagas';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import track, { trackButtonClick } from 'analytics';
import { ADD_TEAM_MEMBERS_MODAL } from './actions';

const ADD_TEAM_MEMBER_MODAL = 'add-team-member-modal';
const successMessage = 'Team members succesfully invited!';
const errorMessage = 'Oops! Something went wrong. Please try again.';
const TOAST_DELAY = 3000;

function* onTeamMembersAddedCallback() {
  yield put(closeModal());
}

export function* openAddTeamMembersModal(teamId) {
  yield put(
    openModal(
      (onClose) => (
        <AddMembers
          teamId={teamId}
          onClose={onClose}
          onTeamMembersAdded={onTeamMembersAddedCallback}
          trackingPage={ADD_TEAM_MEMBER_MODAL}
        />
      ),
      {
        onClose: () => {
          trackButtonClick('exit', ADD_TEAM_MEMBER_MODAL, 'middle');
          track('team-member-modal-closed');
        },
      }
    )
  );
  yield spawn(track, 'add-team-member-modal-shown', {
    location: 'empty notifications list',
  });
}

export function* batchAddTeamMembers(teamId, emails, onTeamMembersAdded) {
  const { failure } = yield call(coreBatchAddTeamMembers, teamId, emails);
  if (failure) {
    yield put(
      showErrorToast({
        header: errorMessage,
        autoCloseDelay: TOAST_DELAY,
      })
    );
    return;
  }

  yield put(
    showSuccessToast({
      header: successMessage,
      autoCloseDelay: TOAST_DELAY,
    })
  );
  yield spawn(track, 'team-member-modal-submitted', {
    count: emails.length,
    invited_emails: emails,
  });

  if (onTeamMembersAdded) yield call(onTeamMembersAdded);
}

export default [
  takeLatest(ADD_TEAM_MEMBERS_MODAL.OPEN, ({ payload: { teamId } }) =>
    openAddTeamMembersModal(teamId)
  ),
  takeLatest(
    ADD_TEAM_MEMBERS_MODAL.ADD_TEAM_MEMBERS,
    ({ payload: { teamId, emails, onTeamMembersAdded } }) =>
      batchAddTeamMembers(teamId, emails, onTeamMembersAdded)
  ),
];

export const testExports = { batchAddTeamMembers, openAddTeamMembersModal };
