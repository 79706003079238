import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextInput, {
  ErrorMessage,
} from '@frameio/components/src/styled-components/TextInput';

const placeholder = 'Enter email address';

const TextInputsContainer = styled.div`
  // target error div from Formik and change spacing to have error message closer to input field
  ${ErrorMessage} {
    margin-top: 0;
    margin-bottom: ${(p) => p.theme.spacing.tiny};
  }
`;

export const StyledTextInput = styled(TextInput)`
  margin-bottom: ${(p) => p.theme.spacing.tiny};
  &:focus {
    border-color: ${(p) => p.theme.color.brand};
  }
`;

class EmailFields extends React.Component {
  shouldShowError = (emailFieldName) => {
    const { touched, errors } = this.props;
    return touched[emailFieldName] && errors[emailFieldName];
  };

  render() {
    const { emailFieldNames, handleChange, handleBlur } = this.props;
    return (
      <TextInputsContainer>
        {emailFieldNames.map((emailFieldName) => (
          <StyledTextInput
            key={emailFieldName}
            placeholder={placeholder}
            type="email"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={this.trackStartEvent}
            name={emailFieldName}
            error={this.shouldShowError(emailFieldName)}
            shouldErrorPreSubmit
            autoFocus={emailFieldName === emailFieldNames[0]}
            // In Chrome, if the user auto-completes the email address in the first email field
            // it adds that email address in all other email fields in the modal.
            // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#Disabling_autocompletion
            autoComplete="nope"
          />
        ))}
      </TextInputsContainer>
    );
  }
}

EmailFields.propTypes = {
  // Props from Formik
  emailFieldNames: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

export default EmailFields;

export const testExports = {
  StyledTextInput,
};
