import { createAction } from 'redux-actions';
import { generateFetchActionTypes } from 'shared/actions/helpers';

export const INBOX = generateFetchActionTypes(
  'INBOX',
  ['FETCH_REVIEWERS', 'FETCH_UNSEEN_REVIEWS_TOTAL'],
  true
);

export const fetchReviewers = createAction(
  INBOX.FETCH_REVIEWERS.BASE,
  (accountId, page) => ({
    accountId,
    page,
  })
);
export const resetInbox = createAction(
  INBOX.FETCH_REVIEWERS.RESET,
  (preserveMetadata = false) => ({ preserveMetadata })
);
export const fetchUnseenReviewsTotal = createAction(
  INBOX.FETCH_UNSEEN_REVIEWS_TOTAL.BASE,
  (accountId) => ({ accountId })
);
