import { useState, useEffect, useCallback } from 'react';

/**
 * Safely fetches json from a url.
 * Returns null while loading.
 * Silently catches errors and returns null.
 * @param {string | null} jsonUrl
 */
const useLazyLoadJson = (jsonUrl) => {
  const [jsonData, setJsonData] = useState(null);
  const fetchJsonData = useCallback(() => {
    (async () => {
      try {
        const response = await fetch(jsonUrl, { cache: 'force-cache' });
        const parsedJsonData = await response.json();
        setJsonData(parsedJsonData);
      } catch (e) {
        setJsonData(null);
      }
    })();
  }, [jsonUrl]);
  useEffect(() => fetchJsonData(), [fetchJsonData, jsonUrl]);
  return jsonData;
};

export default useLazyLoadJson;
