import { status } from '@frameio/core/src/shared/reducers/factories';
import JUMP_TO from './actions';

const { PENDING, SUCCESS, FAILURE } = status;
const INITIAL_STATE = {
  assetIds: [],
  projectIds: [],
  assetFetchStatus: null,
  projectFetchStatus: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case JUMP_TO.TYPEAHEAD.PENDING:
      return {
        ...state,
        assetFetchStatus: PENDING,
        projectFetchStatus: PENDING,
      };

    case JUMP_TO.TYPEAHEAD.SUCCESS: {
      const { assets, projects } = payload.response;
      return {
        ...state,
        assetIds: assets && assets.result,
        projectIds: projects && projects.result,
        assetFetchStatus: assets ? SUCCESS : FAILURE,
        projectFetchStatus: projects ? SUCCESS : FAILURE,
      };
    }
    case JUMP_TO.TYPEAHEAD.FAILURE:
      return {
        ...state,
        assetFetchStatus: FAILURE,
        projectFetchStatus: FAILURE,
      };

    case JUMP_TO.RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const testExports = {
  INITIAL_STATE,
};
