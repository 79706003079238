import React from 'react';
import styled from 'styled-components';
import Proptypes from 'prop-types';
import { formatLongDate } from '@frameio/components/src/utils/datetimeHelpers';

const SubHeader = styled.h4`
  margin-bottom: 18px;
  font-size: ${(p) => p.theme.fontSize[2]};
  color: ${(p) => p.theme.color.gray};
  line-height: ${(p) => p.theme.lineHeight[2]};

  > span {
    color: ${(p) => p.theme.color.coolBlack};
  }
`;

const ReactivateSubscription = ({ subscriptionEndAt }) => (
  <SubHeader>
    Your subscription has been cancelled. It will remain active until
    <span> {formatLongDate(subscriptionEndAt)}</span>. Want to upgrade or add
    capacity to your account? No problem. Just reactivate your account to get
    started.
  </SubHeader>
);

ReactivateSubscription.propTypes = {
  subscriptionEndAt: Proptypes.string.isRequired,
};

export default ReactivateSubscription;
