import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Dialog, {
  dialogTypes,
  dialogTypesPropType,
} from '@frameio/components/src/styled-components/Dialog';
import Input from '@frameio/components/src/styled-components/TextInput';
import ModalCloseButton from 'components/Modal/ModalCloseButton';

import DialogModal from '../DialogModal';

export default class SimpleDialog extends React.Component {
  static propTypes = {
    // Whether the dialog is open
    modalProps: PropTypes.shape({
      isOpen: PropTypes.bool.isRequired,
    }).isRequired,
    // Redux action to respond to the dialog
    respond: PropTypes.func,
    // Type of dialog; mostly for styling the icon and button
    type: dialogTypesPropType,
    // Header copy
    header: PropTypes.node,
    // Body copy or render function. When `promptValue` is not `undefined`, a prompt dialog is
    // rendered, and body must be a render function with the input component as its only argument.
    body: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    // Copy of the primary, confirmation button.
    primaryText: PropTypes.string,
    // Copy of the secondary, cancellation button.
    secondaryText: PropTypes.string,
    // If this is not `undefined`, it renders an input as a prompt dialog
    promptValue: PropTypes.string,
    // Should the dialog disable submit if promptInputValue doesn't match the expectedPromptValue
    confirmBeforeResponding: PropTypes.bool,
    // The desired value of the promptInputValue if confirmBeforeResponding is true
    expectedPromptValue: PropTypes.string,

    // Set a custom response when you want the secondary button to perform
    // a secondary action (i.e. not just "cancel"/closing the dialog).
    // An "X" button will be rendered so that users can close the dialog
    // without triggering the secondary action.
    secondaryButtonResponse: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
  };

  static defaultProps = {
    respond: noop,
    type: dialogTypes.NEUTRAL,
    header: '',
    body: '',
    primaryText: '',
    secondaryText: null,
    promptValue: undefined,
    secondaryButtonResponse: false,
    confirmBeforeResponding: false,
  };

  static getDerivedStateFromProps(props, state) {
    // explicitly checking for undefined since empty string is also a valid value.
    if (
      props.promptValue !== undefined &&
      state.promptInputValue === undefined
    ) {
      return {
        promptInputValue: props.promptValue,
      };
    }

    if (!props.modalProps.isOpen) {
      return {
        promptInputValue: undefined,
      };
    }

    return null;
  }

  state = {
    promptInputValue: undefined,
  };

  onSecondaryButtonClick = () => {
    this.props.respond(this.props.secondaryButtonResponse);
  };

  onPrimaryButtonClick = () => {
    const { promptValue, respond } = this.props;
    const { promptInputValue } = this.state;

    if (this.canSubmit()) {
      respond(promptValue === undefined ? true : promptInputValue);
    }
  };

  cancel = () => {
    this.props.respond(false);
  };

  canSubmit = () => {
    const { confirmBeforeResponding, expectedPromptValue } = this.props;
    const { promptInputValue } = this.state;

    if (!confirmBeforeResponding) return true;

    return expectedPromptValue.toLowerCase() === promptInputValue.toLowerCase();
  };

  render() {
    const {
      type,
      header,
      body,
      primaryText,
      secondaryText,
      modalProps,
      secondaryButtonResponse,
      confirmBeforeResponding,
    } = this.props;

    const { promptInputValue } = this.state;
    const isPrompt = promptInputValue !== undefined;

    if (isPrompt && typeof body !== 'function') {
      throw new Error(`When "promptInputValue" is defined,
      "body" should be a function, but ${typeof body} ${body} recieved instead.`);
    }

    return (
      <DialogModal onCancel={this.cancel} {...modalProps}>
        <Dialog
          type={type}
          header={header}
          primaryButton={React.cloneElement(
            Dialog.primaryButton,
            {
              disabled: !this.canSubmit(),
              onClick: this.onPrimaryButtonClick,
            },
            primaryText
          )}
          secondaryButton={
            secondaryText &&
            React.cloneElement(
              Dialog.secondaryButton,
              {
                onClick: this.onSecondaryButtonClick,
              },
              secondaryText
            )
          }
        >
          {secondaryButtonResponse && (
            <ModalCloseButton onClick={this.cancel} />
          )}
          {isPrompt
            ? body(
                <Input
                  value={promptInputValue}
                  onChange={(evt) =>
                    this.setState({ promptInputValue: evt.target.value })
                  }
                  onFocus={(evt) => evt.target.select()}
                  onKeyDown={(evt) => {
                    if (evt.key === 'Enter') {
                      evt.preventDefault();
                      this.onPrimaryButtonClick();
                    } else if (
                      !confirmBeforeResponding &&
                      evt.key === 'Escape'
                    ) {
                      this.onSecondaryButtonClick();
                    }
                  }}
                />
              )
            : body}
        </Dialog>
      </DialogModal>
    );
  }
}
