import { dialogTypes } from '@frameio/components/src/styled-components/Dialog';
import { put, take, takeEvery, call } from 'redux-saga/effects';

import SIMPLE_DIALOG, {
  openDialog,
  closeDialog,
  openAlert,
  openError,
} from './actions';

// Dialogs with custom priority. Higher priority wins.
export const priority = {
  FOLDER_NOT_FOUND: 1,
  PROJECT_NOT_FOUND: 2,
  PROJECT_NOT_AVAILABLE: 3,
  AUTH_REQUIRED: 4, // Auth Required should always be the largest priority.
};

export function* takeResponse() {
  const {
    payload: { response },
  } = yield take(SIMPLE_DIALOG.RESPOND);
  return response;
}

export function* close() {
  yield put(closeDialog());
}

// alert and error have corresponding actions because some times we don't care about the response
// of these dialog, so it's easier to just dispatch those actions from components. For every
// other type of dialog, we do care about the response, so the entry point needs to be a saga.

export function* alert(header, body, options = {}) {
  yield put(
    openAlert(header, body, {
      primaryText: 'OK',
      ...options,
    })
  );
  return yield call(takeResponse);
}

export function* error(header, body, options = {}) {
  yield put(
    openError(header, body, {
      primaryText: 'OK',
      ...options,
    })
  );
  return yield call(takeResponse);
}

export function* confirmDelete(header, body, options = {}) {
  const { primaryText = 'Delete', secondaryText = 'Cancel' } = options;

  yield put(
    openDialog(header, body, {
      primaryText,
      secondaryText,
      type: dialogTypes.DESTRUCTIVE,
      ...options,
    })
  );
  return yield call(takeResponse);
}

export function* confirm(header, body, options = {}) {
  yield put(
    openDialog(header, body, {
      primaryText: 'OK',
      secondaryText: 'Cancel',
      ...options,
    })
  );
  return yield call(takeResponse);
}

export function* prompt(header, body, promptValue, options = {}) {
  yield put(
    openDialog(header, body, {
      primaryText: 'OK',
      secondaryText: 'Cancel',
      promptValue,
      ...options,
    })
  );
  return yield call(takeResponse);
}

export default [takeEvery(SIMPLE_DIALOG.RESPOND, close)];
