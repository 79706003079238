import styled from 'styled-components';

const ModalHeader = styled.div`
  text-align: center;
  font-size: 24px;
  color: ${(p) => p.theme.color.coolBlack};
  padding: ${(p) => p.theme.spacing.medium};
`;

export default ModalHeader;
