export default function getRelativeOffset(positionReferencePoint = '') {
  const relativeOffsetByReferencePoint = {
    bottom_center: [-50, -100],
    bottom_left: [0, -100],
    bottom_right: [-100, -100],
    middle_center: [-50, -50],
    middle_left: [0, -50],
    middle_right: [-100, -50],
    top_center: [-50, 0],
    top_left: [0, 0],
    top_right: [-100, 0],
  };
  return relativeOffsetByReferencePoint[positionReferencePoint] || [0, 0];
}
