import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const AUTHED_USER = generateActionTypes('AUTHED_USER', [
  'LOG_OUT',
  'SET_CURRENT',
  'UPDATE_IDENTIFER_INFO',
]);

export function logOut() {
  return {
    type: AUTHED_USER.LOG_OUT,
    payload: {},
  };
}

export function setCurrentUser(userId) {
  return {
    type: AUTHED_USER.SET_CURRENT,
    payload: {
      userId,
    },
  };
}

/*
 * This action will dispatch with a payload to update whatever info we
 * need for the track identifer command.
 */
export const updateTrackingIdentiferInfo = createAction(
  AUTHED_USER.UPDATE_IDENTIFER_INFO,
  (identifyProps) => identifyProps
);
