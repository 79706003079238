import styled, { css } from 'styled-components';
import React from 'react';
import Flex from 'styled-flex-component';
import { getLuminance, ellipsis } from 'polished';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import PurpleLogo from '@frameio/components/src/svgs/logos/logo-arrow-purple.svg';
import WhiteLogo from '@frameio/components/src/svgs/logos/logo-light.svg';
import { alignCenter } from '@frameio/components/src/mixins';
import { color } from '@frameio/components/src/theme/darkTheme';
import { isVideo } from '@frameio/core/src/assets/helpers/mediaTypes';
import { getProjectUrl, getPlayerUrl, MARKETING_URL } from 'URLs';
import ReelPlayer from 'components/Presentation/Layouts/ReelPlayer';
import Blog from 'components/Presentation/Layouts/Blog';
import PresentationHeader from 'components/Presentation/PresentationHeader';
import {
  TRANSITION_SETTINGS,
  hasAttachedLinkIcon,
  StyledGoToIcon,
  AssetIconContainer,
} from 'components/Presentation/Layouts/shared';
import { trackExternalLinkClick } from 'analytics';
import {
  presentationColorsWithFallback,
  getPresentationAssetName,
} from './utils';
import { REEL_LAYOUT } from './layouts';

export const MAX_WIDTH = '960px';
export const FRAMEIO_LOGO_SIZE = 24;
export const HLS_VIDEO_AUTOLOAD_MAX = 10;
export const ICON_SIZE = 16;
export const TEXT_OPACITY = 0.7;
export const PRESENTATION_SPACING = '20px';
export const customPurple = '#8b85ff';
export const customSpacing = '15px';
export const customPresentationTitleFontStyles = css`
  font-size: 24px;
  font-weight: bold;
`;

/**
 * Chooses white or black text depending on the darkness of the background
 * @param {string} backgroundColor - Hex color;
 */
export const readableColor = css`
  color: ${({ backgroundColor }) =>
    getLuminance(backgroundColor) < 0.5 ? color.white : color.coolBlack};
  transition: color ${TRANSITION_SETTINGS};
`;

export const ContentWrapper = styled(Flex)`
  max-width: ${MAX_WIDTH};
  width: 100%;
  margin: 0 auto;
`;

export const presentationTitleLinkStyles = css`
  ${customPresentationTitleFontStyles};
  ${ellipsis()};
  color: ${({ textColor }) => textColor};
  white-space: normal;

  // Long titles get cut off in presentations, so this truncates after 2 lines if line clamp is supported
  // If line clamp is not supported, the title will wrap without truncation
  @supports (-webkit-line-clamp: 2) and (display: -webkit-box) and
    (-webkit-box-orient: vertical) {
    & {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
`;

export const PresentationTitleLink = styled.a`
  ${presentationTitleLinkStyles};
  ${hasAttachedLinkIcon};
  text-decoration: none;
`;

export const PresentationTitle = styled.h2`
  ${presentationTitleLinkStyles};
`;

export const assetNameLinkStyles = css`
  font-weight: ${(p) => p.theme.fontWeight.bolder};
  ${ellipsis()};
  color: ${({ textColor }) => textColor};
  font-size: 17px;
  display: block;
  position: relative;
  top: 1px;
`;

export const AssetNameLink = styled.a`
  ${assetNameLinkStyles};
  ${hasAttachedLinkIcon};
  text-decoration: none;
`;

export const AssetName = styled.h3`
  ${assetNameLinkStyles};
`;

export const LearnMoreButton = styled.a`
  padding: ${(p) => p.theme.spacing.small} ${PRESENTATION_SPACING};
  border-radius: ${(p) => p.theme.radius.medium};
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  border: solid 2px transparent;
  text-transform: uppercase;
  color: ${(p) => p.theme.color.brand};
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(to right, ${color.brand}, ${customPurple});
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 2px ${({ backgroundColor }) => backgroundColor} inset;
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: none;
    color: ${({ backgroundColor }) => backgroundColor};
  }
`;

export const TagLine = styled.span`
  ${readableColor}
  ${(p) => p.theme.fontStyle.body};
  font-size: bold;
  opacity: ${TEXT_OPACITY};
  margin-top: ${customSpacing};
  margin-bottom: ${(p) => p.theme.spacing.large};
`;

export const PoweredBy = styled.div`
  ${alignCenter()};
  ${readableColor};
  ${(p) => p.theme.fontStyle.header};
  white-space: nowrap;

  svg {
    margin-left: ${(p) => p.theme.spacing.tiny};
  }
`;

export const Footer = styled.footer`
  ${alignCenter()};
  flex-direction: column;
  width: 100%;
  padding: ${PRESENTATION_SPACING};

  ${TagLine} {
    margin-top: ${(p) => p.theme.spacing.tiny};
    margin-bottom: ${(p) => p.theme.spacing.large};
  }
`;

export const PresentationWrapper = styled.div`
  padding: ${({ isReelLayout }) => !isReelLayout && PRESENTATION_SPACING};
  background-color: ${({ backgroundColor }) => backgroundColor};
  transition: background-color ${TRANSITION_SETTINGS};
  overflow-x: hidden;
  position: relative;

  ${({ isReelLayout }) =>
    isReelLayout &&
    `
    max-height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    flex-direction: column;
  `};
`;

class Presentation extends React.Component {
  state = { selectedIndex: 0 };

  /**
   * Receives the index of the current asset index on the carousel
   * from the ReelPlayer component.
   * @param {number} index - Index of current asset.
   */
  setSelectedIndex = (selectedIndex) => {
    this.setState({ selectedIndex });
  };

  displayNameForPresentationAsset = (asset) =>
    getPresentationAssetName(asset, this.props.presentation);

  /**
   * @returns {ReactElement} - Presentation.
   */
  render() {
    const {
      accountId,
      canUse4KPlayback,
      className,
      logo: logoSrc,
      title,
      presentation,
      presentation: {
        asset_id: assetId,
        can_download: canDownload,
        disable_drm: disableDrm,
        include_upload_date: includeUploadDate,
        layout,
        project_id: projectId,
        session_watermark_id: sessionWatermarkId,
      },
      assets,
      team,
      isAuthorized,
      isForensicFallbackEnabled,
      handlePresentationBulkDownload,
      trackingPage,
      readOnly,
      onAssetChange,
    } = this.props;
    const { selectedIndex } = this.state;
    const isSessionWatermarked = Boolean(sessionWatermarkId);

    const isOwnedByFreeAccount = !get(
      presentation,
      'available_features.custom_branded_presentations'
    );
    const { accent, background, text } = presentationColorsWithFallback(
      presentation,
      team
    );

    const backgroundColor = `#${background}`;
    const textColor = `#${text}`;
    const accentColor = `#${accent}`;
    const folderId = assetId;
    const isReelLayout = layout === REEL_LAYOUT;

    let videoCount = 0;

    const presentationTitleOrLink = isAuthorized ? (
      <PresentationTitleLink
        target="_blank"
        rel="noopener noreferrer"
        accentColor={accentColor}
        href={
          assets.length === 1
            ? getPlayerUrl(assets[0].id)
            : getProjectUrl(projectId, folderId)
        }
        textColor={textColor}
      >
        {title}
        <AssetIconContainer>
          <StyledGoToIcon
            height={ICON_SIZE}
            width={ICON_SIZE}
            color={textColor}
          />
        </AssetIconContainer>
      </PresentationTitleLink>
    ) : (
      <PresentationTitle textColor={textColor}>{title}</PresentationTitle>
    );
    const assetNameOrLink = (assetName, asset) => (
      <Flex justifyCenter alignCenter>
        {isAuthorized ? (
          <AssetNameLink
            target="_blank"
            rel="noopener noreferrer"
            accentColor={accentColor}
            href={getPlayerUrl(asset.id)}
            textColor={textColor}
          >
            {assetName}
            <StyledGoToIcon
              height={ICON_SIZE}
              width={ICON_SIZE}
              color={textColor}
            />
          </AssetNameLink>
        ) : (
          <AssetName textColor={textColor}>{assetName}</AssetName>
        )}
      </Flex>
    );

    return (
      <PresentationWrapper
        column
        isReelLayout={isReelLayout}
        backgroundColor={backgroundColor}
        className={className}
      >
        <PresentationHeader
          trackingPage={trackingPage}
          assets={assets}
          presentation={presentation}
          handlePresentationBulkDownload={handlePresentationBulkDownload}
          isForensicFallbackEnabled={isForensicFallbackEnabled}
          presentationTitle={presentationTitleOrLink}
          logoSrc={logoSrc}
          selectedAssetIndex={selectedIndex}
          colors={{ accentColor, textColor }}
        />
        {isReelLayout ? (
          <ReelPlayer
            accountId={accountId}
            assets={assets}
            canUse4KPlayback={canUse4KPlayback}
            displayNameForPresentationAsset={
              this.displayNameForPresentationAsset
            }
            isAuthorized={isAuthorized}
            includeUploadDate={includeUploadDate}
            isForensicFallbackEnabled={isForensicFallbackEnabled}
            isSessionWatermarked={isSessionWatermarked}
            onAssetChange={onAssetChange}
            onSelect={this.setSelectedIndex}
            presentation={presentation}
            readOnly={readOnly}
            team={team}
            trackingPage={trackingPage}
          />
        ) : (
          <ContentWrapper data-test-id="blog-player-assets" column>
            {assets.map((asset, index) => {
              videoCount += isVideo(asset) ? 1 : 0;
              const shouldAutoLoad = videoCount <= HLS_VIDEO_AUTOLOAD_MAX;

              return (
                <Blog
                  accountId={accountId}
                  asset={asset}
                  assetNameOrLink={assetNameOrLink}
                  canUse4KPlayback={canUse4KPlayback}
                  canDownload={canDownload}
                  disableDrm={disableDrm}
                  colors={{ textColor, accentColor }}
                  displayNameForPresentationAsset={this.displayNameForPresentationAsset(
                    asset
                  )}
                  includeUploadDate={includeUploadDate}
                  isForensicFallbackEnabled={isForensicFallbackEnabled}
                  isSessionWatermarked={isSessionWatermarked}
                  key={`${asset.id}${index}`}
                  layout={layout}
                  shouldAutoLoad={shouldAutoLoad}
                  trackingPage={trackingPage}
                />
              );
            })}
          </ContentWrapper>
        )}
        {isOwnedByFreeAccount && (
          <Footer>
            <Flex>
              <PoweredBy backgroundColor={backgroundColor}>
                Powered by
                {getLuminance(backgroundColor) < 0.5 ? (
                  <WhiteLogo height={FRAMEIO_LOGO_SIZE} />
                ) : (
                  <PurpleLogo height={FRAMEIO_LOGO_SIZE} />
                )}
              </PoweredBy>
            </Flex>
            <TagLine backgroundColor={backgroundColor}>
              Video collaboration, solved.
            </TagLine>
            <LearnMoreButton
              backgroundColor={backgroundColor}
              href={MARKETING_URL}
              rel="noopener noreferrer"
              target="_blank"
              onClick={(e) =>
                trackExternalLinkClick(e, 'button-clicked', {
                  title: 'learn more cta',
                  page: trackingPage,
                  position: 'bottom',
                })
              }
            >
              Learn More
            </LearnMoreButton>
          </Footer>
        )}
      </PresentationWrapper>
    );
  }
}

Presentation.defaultProps = {
  assets: [],
  className: '',
  handlePresentationBulkDownload: () => {},
  isAuthorized: false,
  logo: '',
  readOnly: false,
  title: undefined,
  onAssetChange: () => {},
};

Presentation.propTypes = {
  accountId: PropTypes.string.isRequired,
  assets: PropTypes.array,
  className: PropTypes.string,
  logo: PropTypes.string,
  handlePresentationBulkDownload: PropTypes.func,
  isAuthorized: PropTypes.bool,
  presentation: PropTypes.shape({
    session_watermark_id: PropTypes.string,
  }).isRequired,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  team: PropTypes.shape({
    background_color: PropTypes.string,
    color: PropTypes.string,
    font_color: PropTypes.string,
  }).isRequired,
  trackingPage: PropTypes.string.isRequired,
  onAssetChange: PropTypes.func,
};

export default Presentation;

export const testExports = {
  Blog,
};
