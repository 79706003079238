import React, { useCallback } from 'react';
import styled from 'styled-components';
import Button from '@frameio/components/src/styled-components/Button';
import { fontStyle } from '@frameio/components/src/theme/darkTheme';
import Spinner from '@frameio/components/src/styled-components/SpinnerBase';
import { ProjectListItem } from './ProjectListItem';

const Modal = styled.div`
  width: ${(p) => p.theme.spacing.units(70)};
  display: flex;
  max-width: 100%;
  flex-direction: column;
  border-radius: ${(p) => p.theme.radius.medium};
  ${(p) => p.theme.fontStyle.body};
`;

const StyledModalContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${(p) =>
    `${p.theme.spacing.units(2)} 0 ${p.theme.spacing.units(2)} 0`};
`;

const ButtonAndCheckBoxContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const Header = styled.div`
  padding: ${(p) => p.theme.spacing.units(2)};
  padding-top: ${(p) => p.theme.spacing.units(1)};
  padding-bottom: ${(p) => p.theme.spacing.units(1)};
`;

const Title = styled.h2`
  margin: 0;
  ${fontStyle.headerXS};
  color: ${(p) => p.theme.color.coolBlack};
  ${(p) => p.theme.fontStyle.headerXS};
  margin-bottom: ${(p) => p.theme.spacing.units(2)};
  flex: 1;
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${(p) => p.theme.spacing.units(2)};
  margin-bottom: 0;
`;

const Content = styled.div`
  position: relative;
  padding: ${(p) => p.theme.spacing.units(2)};
  height: ${(p) => p.theme.spacing.units(32)};
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: ${(p) => p.theme.color.silver};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const VerticalCenter = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: ${(p) => p.theme.spacing.units(1)};
  justify-content: center;
`;

const TEXT = {
  title: 'Pair C2C connection',
};

const PairingProjectSelectorModal = ({
  projectList = [],
  pairCode,
  closeModal,
}) => {
  const onCancel = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleSelect = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const noProjects = projectList.length === 0;

  return (
    <Modal>
      <StyledModalContent>
        <Header>
          <Title>{TEXT.title}</Title>
        </Header>
        <Content>
          {noProjects && (
            <VerticalCenter>
              <Spinner spinning radius={9} color="125,130,156" stroke={2} />
            </VerticalCenter>
          )}
          {projectList &&
            projectList?.map((project) => (
              <ProjectListItem
                key={project.id}
                project={project}
                pairCode={pairCode}
                onSelect={handleSelect}
              />
            ))}
        </Content>
        <ModalFooter>
          <ButtonAndCheckBoxContainer>
            <Button data-modalbuttoncancel="1" onClick={onCancel}>
              Cancel
            </Button>
          </ButtonAndCheckBoxContainer>
        </ModalFooter>
      </StyledModalContent>
    </Modal>
  );
};

export default PairingProjectSelectorModal;
