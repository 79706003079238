const SORT_OPTIONS = {
  CUSTOM: {
    value: 'index',
    canSortDescending: false,
    sortDescendingByDefault: false,
    label: 'Custom',
  },
  RECENCY: {
    value: 'uploaded_at',
    canSortDescending: true,
    sortDescendingByDefault: true,
    label: 'Date uploaded',
    asc: 'Oldest first',
    desc: 'Newest first',
  },
  NAME: {
    value: 'name',
    canSortDescending: true,
    sortDescendingByDefault: false,
    label: 'Name',
  },
  SIZE: {
    value: 'filesize',
    canSortDescending: true,
    sortDescendingByDefault: true,
    label: 'Size',
    asc: 'Smallest first',
    desc: 'Largest first',
  },
  WORKFLOW_STATUS: {
    value: 'label',
    canSortDescending: true,
    sortDescendingByDefault: false,
    label: 'Status',
    // The server sorts in reverse order for the status labels because they are represented
    // as an enum and stored as integers, with `approved` being the highest value (3) and
    // `none` being the lowest (0).
    asc: 'No status first',
    desc: 'Approved first',
  },
  COMMENTS: {
    value: 'comment_count',
    canSortDescending: true,
    sortDescendingByDefault: false,
    label: 'Comments',
    asc: 'Fewest comments',
    desc: 'Most comments',
  },
};

export const SORT_CONST = {
  ASC: 'asc',
  DESC: 'desc',
  FILETYPE: 'filetype',
  INDEX: 'index',
};

export default SORT_OPTIONS;
