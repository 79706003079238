class FIOSelect {
  static initClass() {

    this.timezone_data = [
      { offset: -11, value: 'Pacific/Midway', name: 'UTC-11:00 - Midway Island, Samoa' },
      { offset: -10, value: 'America/Adak', name: 'UTC-10:00 - Hawaii-Aleutian' },
      { offset: -10, value: 'Etc/GMT+10', name: 'UTC-10:00 - Hawaii' },
      { offset: -9.5, value: 'Pacific/Marquesas', name: 'UTC-09:30 - Marquesas Islands' },
      { offset: -9, value: 'Pacific/Gambier', name: 'UTC-09:00 - Gambier Islands' },
      { offset: -9, value: 'America/Anchorage', name: 'UTC-09:00 - Alaska' },
      { offset: -8, value: 'America/Ensenada', name: 'UTC-08:00 - Tijuana, Baja California' },
      { offset: -8, value: 'Etc/GMT+8', name: 'UTC-08:00 - Pitcairn Islands' },
      { offset: -8, value: 'America/Los_Angeles', name: 'UTC-08:00 - Pacific Time, US & Canada' },
      { offset: -7, value: 'America/Denver', name: 'UTC-07:00 - Mountain Time, US & Canada' },
      { offset: -7, value: 'America/Chihuahua', name: 'UTC-07:00 - Chihuahua, La Paz, Mazatlan' },
      { offset: -7, value: 'America/Dawson_Creek', name: 'UTC-07:00 - Arizona' },
      { offset: -6, value: 'America/Belize', name: 'UTC-06:00 - Saskatchewan, Central America' },
      { offset: -6, value: 'America/Cancun', name: 'UTC-06:00 - Guadalajara, Mexico City, Monterrey' },
      { offset: -6, value: 'Chile/EasterIsland', name: 'UTC-06:00 - Easter Island' },
      { offset: -6, value: 'America/Chicago', name: 'UTC-06:00 - Central Time, US & Canada' },
      { offset: -5, value: 'America/New_York', name: 'UTC-05:00 - Eastern Time, US & Canada' },
      { offset: -5, value: 'America/Havana', name: 'UTC-05:00 - Cuba' },
      { offset: -5, value: 'America/Bogota', name: 'UTC-05:00 - Bogota, Lima, Quito, Rio Branco' },
      { offset: -4.5, value: 'America/Caracas', name: 'UTC-04:30 - Caracas' },
      { offset: -4, value: 'America/Santiago', name: 'UTC-04:00 - Santiago' },
      { offset: -4, value: 'America/La_Paz', name: 'UTC-04:00 - La Paz' },
      { offset: -4, value: 'Atlantic/Stanley', name: 'UTC-04:00 - Faukland Islands' },
      { offset: -4, value: 'America/Campo_Grande', name: 'UTC-04:00 - Brazil' },
      { offset: -4, value: 'America/Goose_Bay', name: 'UTC-04:00 - Atlantic Time, Goose Bay' },
      { offset: -4, value: 'America/Glace_Bay', name: 'UTC-04:00 - Atlantic Time, Canada' },
      { offset: -3.5, value: 'America/St_Johns', name: 'UTC-03:30 - Newfoundland' },
      { offset: -3, value: 'America/Araguaina', name: 'UTC-03:00 - UTC-3' },
      { offset: -3, value: 'America/Montevideo', name: 'UTC-03:00 - Montevideo' },
      { offset: -3, value: 'America/Miquelon', name: 'UTC-03:00 - Miquelon, St. Pierre' },
      { offset: -3, value: 'America/Godthab', name: 'UTC-03:00 - Greenland' },
      { offset: -3, value: 'America/Argentina/Buenos_Aires', name: 'UTC-03:00 - Buenos Aires' },
      { offset: -3, value: 'America/Sao_Paulo', name: 'UTC-03:00 - Brasilia' },
      { offset: -2, value: 'America/Noronha', name: 'UTC-02:00 - Mid-Atlantic' },
      { offset: -1, value: 'Atlantic/Cape_Verde', name: 'UTC-01:00 - Cape Verde Is.' },
      { offset: -1, value: 'Atlantic/Azores', name: 'UTC-01:00 - Azores' },
      { offset: 0, value: 'Europe/Belfast', name: 'UTC - Greenwich Mean Time, Belfast' },
      { offset: 0, value: 'Europe/Dublin', name: 'UTC - Greenwich Mean Time, Dublin' },
      { offset: 0, value: 'Europe/Lisbon', name: 'UTC - Greenwich Mean Time, Lisbon' },
      { offset: 0, value: 'Europe/London', name: 'UTC - Greenwich Mean Time, London' },
      { offset: 0, value: 'Africa/Abidjan', name: 'UTC - Monrovia, Reykjavik' },
      { offset: 1, value: 'Europe/Amsterdam', name: 'UTC+01:00 - Amsterdam, Berlin, Rome, Stockholm, Vienna' },
      { offset: 1, value: 'Europe/Belgrade', name: 'UTC+01:00 - Belgrade, Bratislava, Budapest, Prague' },
      { offset: 1, value: 'Europe/Brussels', name: 'UTC+01:00 - Brussels, Copenhagen, Madrid, Paris' },
      { offset: 1, value: 'Africa/Algiers', name: 'UTC+01:00 - West Central Africa' },
      { offset: 1, value: 'Africa/Windhoek', name: 'UTC+01:00 - Windhoek' },
      { offset: 2, value: 'Asia/Beirut', name: 'UTC+02:00 - Beirut' },
      { offset: 2, value: 'Africa/Cairo', name: 'UTC+02:00 - Cairo' },
      { offset: 2, value: 'Asia/Gaza', name: 'UTC+02:00 - Gaza' },
      { offset: 2, value: 'Africa/Blantyre', name: 'UTC+02:00 - Harare, Pretoria' },
      { offset: 2, value: 'Asia/Jerusalem', name: 'UTC+02:00 - Jerusalem' },
      { offset: 2, value: 'Europe/Minsk', name: 'UTC+02:00 - Minsk' },
      { offset: 2, value: 'Asia/Damascus', name: 'UTC+02:00 - Syria' },
      { offset: 3, value: 'Europe/Moscow', name: 'UTC+03:00 - Moscow, St. Petersburg, Volgograd' },
      { offset: 3, value: 'Africa/Addis_Ababa', name: 'UTC+03:00 - Nairobi' },
      { offset: 3, value: 'Asia/Tehran', name: 'UTC+03:30 - Tehran' },
      { offset: 4, value: 'Asia/Dubai', name: 'UTC+04:00 - Abu Dhabi, Muscat' },
      { offset: 4, value: 'Asia/Yerevan', name: 'UTC+04:00 - Yerevan' },
      { offset: 4.5, value: 'Asia/Kabul', name: 'UTC+04:30 - Kabul' },
      { offset: 5, value: 'Asia/Yekaterinburg', name: 'UTC+05:00 - Ekaterinburg' },
      { offset: 5, value: 'Asia/Tashkent', name: 'UTC+05:00 - Tashkent' },
      { offset: 5.5, value: 'Asia/Kolkata', name: 'UTC+05:30 - Chennai, Kolkata, Mumbai, New Delhi' },
      { offset: 5.75, value: 'Asia/Katmandu', name: 'UTC+05:45 - Kathmandu' },
      { offset: 6, value: 'Asia/Dhaka', name: 'UTC+06:00 - Astana, Dhaka' },
      { offset: 6, value: 'Asia/Novosibirsk', name: 'UTC+06:00 - Novosibirsk' },
      { offset: 6.5, value: 'Asia/Rangoon', name: 'UTC+06:30 - Yangon Rangoon' },
      { offset: 7, value: 'Asia/Bangkok', name: 'UTC+07:00 - Bangkok, Hanoi, Jakarta' },
      { offset: 7, value: 'Asia/Krasnoyarsk', name: 'UTC+07:00 - Krasnoyarsk' },
      { offset: 8, value: 'Asia/Hong_Kong', name: 'UTC+08:00 - Beijing, Chongqing, Hong Kong, Urumqi' },
      { offset: 8, value: 'Asia/Irkutsk', name: 'UTC+08:00 - Irkutsk, Ulaan Bataar' },
      { offset: 8, value: 'Australia/Perth', name: 'UTC+08:00 - Perth' },
      { offset: 8.75, value: 'Australia/Eucla', name: 'UTC+08:45 - Eucla' },
      { offset: 9, value: 'Asia/Tokyo', name: 'UTC+09:00 - Osaka, Sapporo, Tokyo' },
      { offset: 9, value: 'Asia/Seoul', name: 'UTC+09:00 - Seoul' },
      { offset: 9, value: 'Asia/Yakutsk', name: 'UTC+09:00 - Yakutsk' },
      { offset: 9.5, value: 'Australia/Adelaide', name: 'UTC+09:30 - Adelaide' },
      { offset: 9.5, value: 'Australia/Darwin', name: 'UTC+09:30 - Darwin' },
      { offset: 10, value: 'Australia/Brisbane', name: 'UTC+10:00 - Brisbane' },
      { offset: 10, value: 'Australia/Hobart', name: 'UTC+10:00 - Hobart' },
      { offset: 10, value: 'Asia/Vladivostok', name: 'UTC+10:00 - Vladivostok' },
      { offset: 10.5, value: 'Australia/Lord_Howe', name: 'UTC+10:30 - Lord Howe Island' },
      { offset: 11, value: 'Etc/GMT-11', name: 'UTC+11:00 - Solomon Is., New Caledonia' },
      { offset: 11, value: 'Asia/Magadan', name: 'UTC+11:00 - Magadan' },
      { offset: 11.5, value: 'Pacific/Norfolk', name: 'UTC+11:30 - Norfolk Island' },
      { offset: 12, value: 'Asia/Anadyr', name: 'UTC+12:00 - Anadyr, Kamchatka' },
      { offset: 12, value: 'Pacific/Auckland', name: 'UTC+12:00 - Auckland, Wellington' },
      { offset: 12, value: 'Etc/GMT-12', name: 'UTC+12:00 - Fiji, Kamchatka, Marshall Is.' },
      { offset: 12.75, value: 'Pacific/Chatham', name: 'UTC+12:45 - Chatham Islands' },
      { offset: 13, value: 'Pacific/Tongatapu', name: 'UTC+13:00 - Nuku\'alofa' },
      { offset: 14, value: 'Pacific/Kiritimati', name: 'UTC+14:00 - Kiritimati' }

    ];
  }

  static getNameByValue(value) {
    const timezone = this.timezone_data.find((item) => item.value === value);
    return timezone ? timezone.name : null;
  }

  static timezone(id, class_name, timezone_value) {

    let options_html = "";

    let set = false; // for offset value setting

    if (timezone_value) {

      for (let zone of Array.from(FIOSelect.timezone_data)) {

        let selected = "";
        if (timezone_value === zone.value) {
          selected = "selected";
        }

        options_html += `<option ${selected} value='${zone.value}'>${zone.name}</option>`;
      }
    }

    let html = `\
<select id='${id}' class='fio-select-timezone ${class_name}'>
	${options_html}
</select>\
`;
    return html;
  }

  static generate(hash) {

    if (hash == null) { hash = {}; }
    let id = hash['id'];
    let class_name = hash['class_name'];
    let default_value = hash['default'];
    let options = hash['options'];

    let options_html = "";

    if (options[0] && !default_value) { default_value = options[0].value; }

    for (let option of Array.from(options)) {

      let selected = "";
      if (option.value === default_value) {
        selected = "selected";
      }

      options_html += `<option ${selected} value='${option.value}'>${option.name}</option>`;
    }

    let html = `\
<select id='${id}' class='${class_name}'>
	${options_html}
</select>\
`;

    return html;
  }
}
FIOSelect.initClass();

export default FIOSelect;
