import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const PROJECT_HEADER = generateActionTypes('PROJECT_HEADER', [
  'REDIRECT_TO_PROJECT',
  'UPGRADE_PLAN',
]);

export const redirectToProject = createAction(
  PROJECT_HEADER.REDIRECT_TO_PROJECT,
  (projectId) => ({ projectId })
);

export const upgradePlan = createAction(
  PROJECT_HEADER.UPGRADE_PLAN,
  (feature) => ({ feature })
);
