import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import Button from '@frameio/components/src/styled-components/Button';
import cloneableProjects from './cloneableProjects';

const SectionTitle = styled.h3`
  font-size: ${(p) => p.theme.fontSize[1]};
  color: ${(p) => p.theme.color.slateGray};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => `${theme.spacing.small}`};
`;

const Row = styled(Flex)`
  margin-bottom: ${({ theme }) => `${theme.spacing.small}`};
`;

const ProjectName = styled.h2`
  font-size: ${(p) => p.theme.fontSize[1]};
  color: ${(p) => p.theme.color.almostBlack};
`;

const CloneButton = styled(Button)`
  margin-left: auto;
`;

function ProjectCloneRow({ name, projectId, cloneProject }) {
  return (
    <Row alignCenter>
      <ProjectName>{name}</ProjectName>

      <CloneButton compact onClick={() => cloneProject(projectId)}>
        Clone
      </CloneButton>
    </Row>
  );
}

export default function Data({ cloneProject }) {
  return (
    <React.Fragment>
      <SectionTitle>Reset project data</SectionTitle>

      {cloneableProjects().map((project) => (
        <ProjectCloneRow
          key={project.id}
          projectId={project.id}
          name={project.name}
          cloneProject={cloneProject}
        />
      ))}
    </React.Fragment>
  );
}

ProjectCloneRow.propTypes = {
  name: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  cloneProject: PropTypes.func.isRequired,
};

Data.propTypes = {
  cloneProject: PropTypes.func.isRequired,
};
