import { useState, useEffect, useCallback, useRef } from 'react';
import { get } from 'lodash';

/**
 * useMediaRaf hook runs a RAF to ensure that time updates happen at 60fps.
 * This is useful for things like smooth playbars and fast time displays.
 *
 * @param {Object} media - Media state
 * @param {HTMLMediaElement} mediaEl - Media element
 */
const useMediaRAF = (media, mediaEl) => {
  const raf = useRef(null);
  const [tick, setTick] = useState(0);
  const mediaTime = get(mediaEl, 'currentTime', 0);

  const nextFrame = useCallback(
    (timestamp) => {
      if (media.paused) return;
      setTick(timestamp);
      raf.current = requestAnimationFrame(nextFrame);
    },
    [media.paused]
  );

  useEffect(() => {
    if (media.paused) return;
    raf.current = requestAnimationFrame(nextFrame);
  }, [media.paused, nextFrame]);

  useEffect(() => {
    if (media.paused) cancelAnimationFrame(raf.current);
  }, [tick, mediaTime, media.paused]);

  // Cancel animation frame on unmount.
  useEffect(() => {
    return () => cancelAnimationFrame(raf.current);
  }, []);

  return {
    currentTime: mediaTime,
  };
};

export default useMediaRAF;
