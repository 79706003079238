import { AUTHED_USER } from '@frameio/core/src/users/actions';

export default function unconfirmedEmailLockout(
  state = {
    isResending: false,
  },
  action
) {
  const { type } = action;
  switch (type) {
    case AUTHED_USER.SEND_CONFIRMATION_EMAIL.PENDING:
      return {
        ...state,
        isResending: true,
      };

    case AUTHED_USER.SEND_CONFIRMATION_EMAIL.SUCCESS:
    case AUTHED_USER.SEND_CONFIRMATION_EMAIL.FAILURE:
      return {
        ...state,
        isResending: false,
      };

    default:
      return state;
  }
}
