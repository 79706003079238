import React from 'react';
import moment from 'moment';
import {
  takeLatest,
  takeEvery,
  put,
  race,
  select,
  call,
  take,
  fork,
  spawn,
} from 'redux-saga/effects';
import { get, sortBy } from 'lodash';
import {
  assetEntitySelector,
  assetEntitiesByAssetIdsSelector,
} from '@frameio/core/src/assets/selectors';
import DOMPurify from 'dompurify';
import { getProject } from '@frameio/core/src/projects/sagas';
import { getTeam } from '@frameio/core/src/teams/sagas';
import { UPLOAD, uploadItems } from '@frameio/core/src/uploads/actions';
import {
  getUploadItemsFromDrop,
  getUploadItemsFromInput,
} from '@frameio/core/src/uploads/helpers/folderTree';
import {
  getAccount,
  getAccountMigrationEligibility,
} from '@frameio/core/src/accounts/sagas';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import {
  ASSET as CORE_ASSET,
  AssetEvents,
  getAssetChildren as getAssetChildrenAction,
  versionAsset as versionAssetAction,
  getAssetSize,
  batchUpdateAssets,
  patchAssets,
} from '@frameio/core/src/assets/actions';
import {
  getAccountByResource,
  updateLastViewedProjectForAccount,
} from '@frameio/core/src/accounts/services';
import {
  getAssetChildren,
  getAssetAncestors,
  updateAsset,
} from '@frameio/core/src/assets/sagas';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import { BATCH_RECEIVE_EVENTS_TYPE } from '@frameio/core/src/shared/actions/helpers';
import {
  leadingThrottle,
  takeSuccessWithEntityId,
  takeFailureWithEntityId,
  takeEveryUntil,
  createPaginatedListSaga,
} from '@frameio/core/src/shared/sagas/helpers';
import {
  accountEntityForAssetIdSelector,
  accountEntityForProjectIdSelector,
  teamEntityForProjectIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import {
  type as AssetType,
  label as AssetLabel,
} from '@frameio/core/src/assets/helpers/constants';

import track from 'analytics';
import config, { NEXT_VERSION_NUMBER } from 'config';
import {
  setCurrentAccount,
  setAccountMigrationEligibility,
} from 'actions/accounts';
import { ASSET, confirmDeleteAssets } from 'actions/assets';
import { setFolderAncestors, setCurrentFolder } from 'actions/folders';
import { setCurrentProject } from 'actions/projects';
import {
  showActivityToast,
  showErrorToast,
  removeToastsBy,
} from 'actions/toasts';
import {
  takeResponse,
  error,
  priority,
} from 'components/Dialog/SimpleDialog/sagas';
import { batchMoveAssets } from 'sagas/assets';
import { selectFilesFromInput } from 'sagas/shared';
import { redirectUnavailableProject, redirectToRoot } from 'sagas/projects';
import { redirectUnavailableFolder } from 'sagas/folders';
import { redirectToFirstTeamOrSharedProject } from 'pages/AccountContainer/sagas';
import {
  isProjectArchivedSelector,
  currentProjectSelector,
} from 'selectors/projects';
import { currentFolderSelector } from 'selectors/folders';
import { legacyHasBlockedUpload, hasBlockedUpload } from 'sagas/uploads';
import {
  currentUserEntitySelector,
  hasCurrentUserConfirmedEmailAddress,
} from 'selectors/users';
import {
  accountMigrationEligibilitySelector,
  currentAccountSelector,
  isAccountOnLegacyPlanSelector,
} from 'selectors/accounts';
import { exitSelectionMode } from 'components/ReviewLinkEditor/sagas';
import { isSelectingItemsForReviewLinkSelector } from 'components/ReviewLinkEditor/selectors';
import manageVersionStackModalSagas from 'pages/ProjectContainer/ManageVersionStackModal/sagas';
import RequestAccessToEntity from 'components/RequestAccessToEntity';
import ConnectedEditProjectForm from 'components/ProjectForms/EditProject';
import { ENTITY_TYPE } from 'components/AuthGate/AuthGate';
import { openModal, closeModal } from 'components/Modal/actions';
import {
  dualViewEnabled,
  folderSharingEnabled,
  v4MigrationModal,
  v4MigrationModalVariantA,
} from 'utils/featureFlags';

import { setCurrentTeam } from 'actions/teams';
import { currentTeamEntitySelector } from 'selectors/teams';
import {
  LOCAL_STORAGE_MIGRATION_MODAL_KEY,
  getModalVariantV4,
} from 'components/MigrationModal';
import ConnectedMigrationModal from 'components/MigrationModal/ConnectedMigrationModal';
import { redirectToNext } from 'utils/router';
import {
  PROJECT_CONTAINER,
  selectAssets,
  createAssetPlaceholders,
  resetAssets,
} from './actions';
import projectDevicesSagas from './ProjectDevices/sagas';
import projectHeaderSagas from './ProjectHeader/sagas';
import projectLinksSagas from './ProjectLinks/sagas';
import projectPresentationsSagas from './ProjectPresentations/sagas';
import { getCircleStackForProject } from './PeopleCircleStack/sagas';

import {
  assetAtCellSelector,
  assetIdsSelector,
  assetsRequestOptionsSelector,
  assetsViewTypeSelector,
  folderIdSelector,
  getPlaceholderAssetId,
  indexToCreateAssetsSelector,
  projectIdSelector,
  selectedAssetIdsSelector,
  shouldFetchAssetsPageSelector,
} from './selectors';

import managePeopleSagas from './ManagePeople/sagas';
import dualViewSagas, { showDualViewModalIfRequired } from './DualView/sagas';
import desktopAppSagas from './DesktopApp/sagas';
import { isDualViewCandidate } from './DualView/helper';
import ConnectedPairingProjectSelectorModal from './ProjectDevices/PairProjectSelector/ConnectedPairProjectSelectorModal';

export const onlyFieldsArray = [
  'u.account_id',
  'u.name',
  'u.inserted_at',
  'a.parent_id',
  'a.creator_id',
  'a.upload_completed_at',
  'a.project_id',
  'a.filetype', // Needed for AssetContextMenu/CompareAssets
  'a.filesize',
  'a.allow_original_playback', // Needed to prevent upload indicator on player page
  'a.name',
  'a.cover_asset',
  'a.inserted_at',
  'a.comment_count',
  'a.label',
  'a.item_count',
  'a.private',
  'a.archived_at',
  'a.archive_from',
  'a.versions',
  'a.uploaded_at',
  'a.downloads',
  'a.type',
  'a.duration',
  'a.status',
  'a.thumb_scrub',
  'a.thumb',
  'a.fps', // Required for Quicklook
  'a.frames', // Required for Quicklook
  'a.image_full', // Required for Quicklook
  'a.original', // Required for Quicklook
  'a.is_hls_required', // Required for Quicklook
  'a.hls_manifest', // Required for Quicklook
  'a.is_forensically_watermarked', // Required for Quicklook
  'a.is_session_watermarked', // Required for Quicklook
  'a.drm', // Required for Quicklook
  'a.includes',
  'a.transcodes',
  'a.transcoded_at',
  'a.index', // Required for custom sort in assets panel.
  'a.workfront_approval_status', // Required for workfront integration
  'a.workfront_connected', // Required for workfront integration
  'a.workfront_connected', // Required for workfront integration
  'a.workfront_task_id', // Required for workfront integration
  'a.workfront_task_title', // Required for workfront integration
];

export const excludedFieldsArray = [
  'a.checksums',
  'a.h264_1080_best',
  'a.source',
];

export const dropIncludesArray = [
  'a.trancode_statuses',
  'a.source', // This isn't needed anywhere in the front-end, should really be an include everywhere
  'a.checksums', // Not needed except on the player page
];

export const hardDropFieldsArray = ['a.source'];

const fetchAssetPage = createPaginatedListSaga(
  PROJECT_CONTAINER.FETCH_ASSETS,
  getAssetChildren
);

/**
 * Fetches a page of assets in a folder.
 * @param {string} folderId - The id of the folder to fetch for.
 * @param {number} page - Page number to fetch.
 */
function* fetchAssets(folderId, page) {
  const shouldFetch = yield select(shouldFetchAssetsPageSelector, { page });
  // Right now we don't really care about the actual success response of this
  // saga so let's indicate to consumers that they can continue. This assumption
  // might not hold in future, so we may need to fix the return here.
  if (!shouldFetch) return { success: true };
  const options = yield select(assetsRequestOptionsSelector);
  const isFolderSharingEnabled = yield select(folderSharingEnabled);

  options.onlyFieldsArray = onlyFieldsArray;
  options.excludedFieldsArray = excludedFieldsArray;
  options.dropIncludesArray = dropIncludesArray;
  options.hardDropFieldsArray = hardDropFieldsArray;

  if (isFolderSharingEnabled) {
    return yield call(fetchAssetPage, folderId, {
      ...options,
      page,
      includeReviewLinks: true,
    });
  }
  return yield call(fetchAssetPage, folderId, { ...options, page });
}

/**
 *
 * @param {string} projectId - Id of the project to request
 * @returns {boolean} True if the request flow is triggered. False could mean:
 * 2. The user *does* have access to the project.
 * 3. That the project doesn't exist (i.e. nothing to request), or
 * 4. The user has not confirmed their email (i.e. user does not have ability to request).
 */
function* maybeRequestAccessToProject(projectId) {
  // We need to make an extra call to getProject endpoint in order to know
  // the error status code.
  const { success, failure } = yield call(getProject, projectId);
  if (success) return false;

  const errorStatus = get(failure, 'payload.error.response.status');

  const hasCurrentUserConfirmedEmail = yield select(
    hasCurrentUserConfirmedEmailAddress
  );

  // if:
  // 1) the user is forbidden to access a project, and
  // 2) they have confirmed their email address
  // they should have the option to request access to that project.
  if (errorStatus === 403 && hasCurrentUserConfirmedEmail) {
    yield put(
      openModal(
        <RequestAccessToEntity
          entityType={ENTITY_TYPE.PROJECT}
          entityId={projectId}
        />,
        { canCloseModal: false }
      )
    );
    return true;
  }
  return false;
}

function* getProjectWithCompleteTeam(projectId) {
  let projectEntity = yield select(projectEntitySelector, { projectId });
  // The team entity is incomplete when sideloaded by legacy /projects/shared
  // api. When so, refetch the project, which then sideloads the team entity.
  //
  // Not doing getTeam() because that fetches v2 data, but things like
  // `planHelpers.js` depend on legacy team data.
  //
  // TODO(WK-79): this should not be needed in v2 when data is sideloaded more
  // consistently.
  const teamEntity = yield select(teamEntityForProjectIdSelector, {
    projectId,
  });
  if (!teamEntity) {
    const response = yield call(getAccountByResource, 'project', projectId);
    if (response?.version === NEXT_VERSION_NUMBER) {
      redirectToNext({ path: `/project/${projectId}` });
    }
  }
  const hasCompleteTeam =
    teamEntity && (teamEntity.account_id || teamEntity.account);
  if (!projectEntity || !hasCompleteTeam) {
    const { success } = yield call(getProject, projectId);
    if (success) {
      projectEntity = yield select(projectEntitySelector, { projectId });
      const { team_id: teamId } = projectEntity;
      yield call(getTeam, teamId);
      yield put(setCurrentTeam(teamId));
    }
  }
  const currentTeam = yield select(currentTeamEntitySelector);
  if (hasCompleteTeam && currentTeam?.id !== teamEntity.id) {
    yield put(setCurrentTeam(teamEntity.id));
  }
  return projectEntity;
}

function* enterFolder(folderId) {
  const isFolderSharingEnabled = yield select(folderSharingEnabled);
  const isEditingReviewLink = yield select(
    isSelectingItemsForReviewLinkSelector
  );
  if (!isEditingReviewLink) {
    yield put(selectAssets([]));
  }
  // For whatever reason, fetchAssets is not seen by redux-saga as a
  // generator, so it doesn't wait for it to complete, so we have to `fork` and
  // `take` here instead of `call`.
  yield fork(fetchAssets, folderId, 1);
  const [success] = yield race([
    take(PROJECT_CONTAINER.FETCH_ASSETS.SUCCESS),
    take(PROJECT_CONTAINER.FETCH_ASSETS.FAILURE),
  ]);
  if (!success) {
    yield call(redirectUnavailableFolder, folderId);
    return;
  }

  // getAssetAncestors will get all the folders from the resolvedFolderId
  // all the way to the root, so we don't even need to get the leaf (requested) folder.
  yield fork(getAssetAncestors, folderId, {
    params: { include: 'review_links' },
  });

  yield put(getAssetSize(folderId));
  // Fetch the lowest index in this folder so that uploads can always go before this index
  if (isFolderSharingEnabled) {
    yield put(getAssetChildrenAction(folderId, 1, 1, false, true));
  } else {
    yield put(getAssetChildrenAction(folderId, 1, 1));
  }
}

function* getAccountData(projectId) {
  // Load v2 account data for shared projects. For regular projects, v2 accounts
  // are already fetched by `syncAccountData()` in sagas/accounts.
  //
  // TODO(WK-79): This needs to be reconsidered when migrating to v2 endpoints.
  // The team/account data for a project should be fetched exactly the same way,
  // whether the user is a team member or a collaborator in the project.
  const { account_id: accountId } = yield select(
    teamEntityForProjectIdSelector,
    { projectId }
  );
  const accountEntity = yield select(accountEntitySelector, { accountId });
  if (!accountEntity) {
    yield call(getAccount, accountId);
  }
}

function* getShouldShowMigrationModal(accountId) {
  let eligibilityResponse;
  let canUserMigrate;
  let isAccountEligible;

  const { isAccountEligibleForMigration } = yield select(
    accountMigrationEligibilitySelector
  );

  /**
   * It's possible to directly enter a project without having yet queried all account data, so if the eligibility status
   * is still undefined, then query it.
   */
  if (typeof isAccountEligible === 'undefined') {
    eligibilityResponse = yield call(getAccountMigrationEligibility, {
      accountId,
    });

    canUserMigrate =
      eligibilityResponse?.success?.payload?.response?.permissions
        .can_migrate ?? false;
    isAccountEligible =
      eligibilityResponse?.success?.payload?.response?.status.type ===
        'eligible' ?? false;

    yield put(
      setAccountMigrationEligibility(canUserMigrate, isAccountEligible)
    );
  } else {
    isAccountEligible = isAccountEligibleForMigration;
  }

  /**
   * Check for an existing timestamp around the last the modal was automatically shown
   */
  const hasSeenModalTimestamp = localStorage.getItem(
    `${LOCAL_STORAGE_MIGRATION_MODAL_KEY}-${accountId}`
  );

  const timeSinceLastSeen = Math.ceil(
    moment(hasSeenModalTimestamp).diff(moment(), 'days')
  );

  const queryParams = DOMPurify.sanitize(window.location.search);

  const urlParams =
    typeof window !== 'undefined' && new URLSearchParams(queryParams);
  const referralSource = urlParams?.get('utm_source');
  const fromEmail = referralSource?.startsWith('v4_upgrade_email');
  const fromWebsite = referralSource?.startsWith('v4_upgrade_site');

  /**
   * Show the modal if it has not been shown before, or if the last time it was shown was more than 30 days ago, or if
   * a user was brought here including a migration_awareness search param. Even if an account is ineligible for
   * migration, the modal should be shown if a url param was provided.
   */
  const shouldShowModal =
    ((!hasSeenModalTimestamp || timeSinceLastSeen < -30) &&
      isAccountEligible) ||
    (fromEmail || fromWebsite);

  /**
   * Remove the local storage item if the modal should be shown in order to reset the 30 day time window
   */
  if (shouldShowModal) {
    localStorage.removeItem(
      `${LOCAL_STORAGE_MIGRATION_MODAL_KEY}-${accountId}`
    );
  }

  return {
    hasModalBeenSeenBefore: Boolean(hasSeenModalTimestamp),
    referralSource: (fromEmail || fromWebsite) && referralSource,
    shouldShowModal,
  };
}

function* enterProjectFolder({
  payload: { didFolderChange, didProjectChange, folderId, projectId },
}) {
  const { id: prevCurrentFolderId } =
    (yield select(currentFolderSelector)) || {};
  if (folderId !== prevCurrentFolderId) {
    yield put(setCurrentFolder(folderId, prevCurrentFolderId));
  }

  // Clear out any existing UI state belonging to the previous folder
  // or project. As much as possible, any actions fired in this block
  // should *NOT* make a network call, otherwise that can delay rendering
  // of the assets.
  if (didFolderChange) {
    yield put(resetAssets());
  }
  if (didProjectChange) {
    yield call(exitSelectionMode);
  }

  // This handles an edge-case where a user has been removed from a project while they are in a session
  // and still have the cached project data
  const wasRequestFlowTriggered = yield call(
    maybeRequestAccessToProject,
    projectId
  );
  if (wasRequestFlowTriggered) return;

  // Make sure this is a project that actually exists
  const projectEntity = yield call(getProjectWithCompleteTeam, projectId);
  const isProjectDeleted = projectEntity?.deleted_at;

  if (isProjectDeleted) {
    const { id: accountId } = yield select(currentAccountSelector);
    yield call(
      error,
      'Whoops!',
      'Looks like that project is no longer available.',
      {
        priority: priority.PROJECT_NOT_FOUND,
      }
    );
    if (accountId) {
      yield call(redirectToFirstTeamOrSharedProject, accountId);
      return;
    }
    yield call(redirectToRoot);
    return;
  }

  if (!projectEntity) {
    if (!wasRequestFlowTriggered) {
      yield call(redirectUnavailableProject, projectId);
    }
    return;
  }

  // Note: on first page load of the url `/projects/:project_id`,
  // didFolderChange will always be false since we will not have downloaded the
  // project prior to firing this action, and thus root_asset id is unknown.
  // Thus, what happens is that getAccountData get called first, delaying the
  // render of the assets in the root folder.
  // On every subsequent folder or project change, didFolderChange will be true,
  // allowing us to call enterFolder prior to calling getAccountData, thus
  // prioritising fetching the assets over any extraneous data.
  if (didFolderChange) {
    yield call(enterFolder, folderId);
  }

  if (didProjectChange) {
    yield call(getAccountData, projectId);
  }

  // Fetch account data for the project entity
  const { id: accountId } =
    (yield select(accountEntityForProjectIdSelector, { projectId })) || {};
  const { id: prevAccountId } = (yield select(currentAccountSelector)) || {};
  if (accountId !== prevAccountId) {
    yield put(setCurrentAccount(accountId, prevAccountId, projectId));
  }

  // Run side effects of current Project changing last since it
  // shouldnt affect the rendering of the ProjectPage.
  const { id: prevCurrentProjectId } =
    (yield select(currentProjectSelector)) || {};
  if (projectId !== prevCurrentProjectId) {
    yield put(setCurrentProject(projectId, prevCurrentProjectId));
  }

  yield spawn(getCircleStackForProject, projectId);

  // Track whenever an Adobe user has viewed the root of the project
  const isRoot = projectEntity.root_asset_id === folderId;
  const { from_adobe: isAdobeUser } = yield select(currentUserEntitySelector);

  if (isRoot && isAdobeUser) {
    yield spawn(track, 'project-viewed-client', { from_adobe: true });
  }

  // Track whenever we are at root and an account is eligible for V4 migration
  const {
    hasModalBeenSeenBefore,
    referralSource,
    shouldShowModal: isEligible,
  } = yield call(getShouldShowMigrationModal, accountId);

  if (isRoot && isEligible) {
    let entryPoint = 'autoload-first_time';

    if (hasModalBeenSeenBefore) {
      entryPoint = 'autoload-x_days';
    }

    if (referralSource) {
      entryPoint = referralSource;
    }

    const isV4MigrationModalEnabled = yield select(v4MigrationModal);
    const isV4MigrationModalVariantAEnabled = yield select(
      v4MigrationModalVariantA
    );

    yield spawn(track, 'v4-upgrade-awareness-modal-shown', {
      context_entrypoint: entryPoint,
      variant: getModalVariantV4(
        isV4MigrationModalEnabled,
        isV4MigrationModalVariantAEnabled
      ),
    });

    yield put(
      openModal(<ConnectedMigrationModal />, {
        maxHeight: 'none',
        style: { background: 'none' },
      })
    );
  }

  if (!didProjectChange) return;
  // Update last viewed project id for the current account
  yield spawn(updateLastViewedProjectForAccount, accountId, projectId);

  yield spawn(track, 'project-viewed-client');
}

/**
 * Whether or not previous pending fetches should be cancelled.
 * @param {Action} lastAction - The action used to launch the last fetch.
 * @param {Action} action - The action used to launch the current fetch.
 * @returns {boolean} Returns true when we no longer care about the previous fetches.
 */
function shouldCancelPreviousFetches(
  { payload: { folderId: lastFolderId, options: lastOptions } },
  { payload: { folderId, options } }
) {
  return (
    folderId !== lastFolderId ||
    options.sortDescending !== lastOptions.sortDescending ||
    options.sortBy !== lastOptions.sortBy
  );
}

/**
 * Moves assets into a folder.
 * @param {string} assetIds - Ids of the assets being moved.
 * @param {string} folderId - Id of the folder being moved into.
 * @param {Function} [onDone] - Callback for when the api call is done.
 */
export function* moveAssets(assetIds, folderId, onDone) {
  const failedAssetIds = yield call(batchMoveAssets, assetIds, folderId);
  yield put(selectAssets(failedAssetIds));
  if (onDone) yield call(onDone);
  return failedAssetIds;
}

/**
 * Sync the time to auto-close asset activity toasts with the timeout that
 * throttles the frequency of the toasts. I.e. when the first `AssetCreated`
 * event happens, the toast show for the first time. In the next 7s, as the
 * toast stays open, subsequent `AssetCreated` events will be ignored. After 7s,
 * `AssetCreated` event will then trigger a new toast.
 */
const autoCloseDelay = 7000;

export function* showAssetCreatedToast() {
  const header = 'New file(s) added';
  yield put(removeToastsBy({ header }));
  yield put(showActivityToast({ header, autoCloseDelay }));
}

/**
 * Listen to asset event actions that are triggered remotely.
 */
const takeRemoteAssetEventByType = (assetEventType) => ({
  type,
  payload: { events },
}) => {
  if (type !== CORE_ASSET[BATCH_RECEIVE_EVENTS_TYPE]) return false;
  return events.some((event) => {
    const { type: eventType, connection_id: connId } = event;
    return eventType === assetEventType && connId !== config.instanceId;
  });
};

/**
 * Upserts a file asset into version stack.
 * @param {string} assetId - Asset id of the file being added.
 * @param {string} targetAssetId - Asset id of the file or version stack being added to.
 * @param {Function} [onDone] - Callback for when the api call is done.
 */
export function* versionAsset(assetId, targetAssetId, onDone) {
  const versionAction = versionAssetAction(assetId, targetAssetId);
  yield put(versionAction);

  const { success } = yield race({
    success: takeSuccessWithEntityId(versionAction, assetId, targetAssetId),
    failure: takeFailureWithEntityId(versionAction, assetId, targetAssetId),
  });

  if (success) {
    yield put(selectAssets([]));
  } else {
    yield put(
      showErrorToast({
        header: 'Whoops, something went wrong while versioning this file.',
        subHeader: 'Please try again.',
      })
    );
  }
  if (onDone) {
    const isNewVersionStack =
      success && success.payload.response.result !== targetAssetId;
    yield call(onDone, isNewVersionStack);
  }
}

function buildPlaceholderAsset(
  parentId,
  creator,
  { type, file, index, ...data }
) {
  const placeholderId = getPlaceholderAssetId(parentId, index);
  // This is an asset-like object that should have just enough fields set on it for us to be
  // able to sort on.
  const placeholder = {
    ...data,
    comment_count: 0,
    creator_id: creator.id,
    creator,
    filesize: 0,
    id: placeholderId,
    index,
    inserted_at: new Date().toISOString(),
    isPlaceholder: true,
    item_count: 0,
    label: AssetLabel.NONE,
    parent_id: parentId,
    type,
    uploaded_at: new Date().toISOString(),
  };

  if (type === AssetType.FOLDER) {
    placeholder.children = [];
  } else if (file) {
    placeholder.filesize = file.size;
    placeholder.filetype = file.type;
  }

  return placeholder;
}

function* optimisticallyUploadItems(folderId) {
  const creator = yield select(currentUserEntitySelector);
  const projectId = yield select(projectIdSelector);
  const {
    payload: { items },
  } = yield take(UPLOAD.REPORT_ITEMS_TO_UPLOAD);

  const uploadIdToPlaceholderIdMap = {};
  const placeholders = items.reduce((map, { parentFolderId, id, ...data }) => {
    const parentId = uploadIdToPlaceholderIdMap[parentFolderId] || folderId;
    const placeholder = buildPlaceholderAsset(parentId, creator, {
      ...data,
      project_id: projectId,
    });
    uploadIdToPlaceholderIdMap[id] = placeholder.id;

    return {
      ...map,
      [placeholder.id]: placeholder,
    };
  }, {});

  yield put(createAssetPlaceholders(placeholders));
}

function* upload(folderId, items) {
  yield put(uploadItems(folderId, items));
  yield call(optimisticallyUploadItems, folderId);
}

function* uploadDualView(folderId, items) {
  const { cancel, isDualViewMerge } = yield call(
    showDualViewModalIfRequired,
    folderId,
    items
  );
  if (cancel) return;
  if (isDualViewMerge) {
    // Mark these assets as bundle children and upload them
    yield put(
      uploadItems(
        folderId,
        items.map((item) => ({ ...item, isBundleChild: true }))
      )
    );
  } else {
    yield call(upload, folderId, items);
  }
}

function* trackUploadAttempt(numOfItems, method) {
  yield call(track, 'upload-attempt-client', {
    count: numOfItems,
    method,
  });
}

/**
 * Uploads files and folders dragged and dropped into a given folder.
 * @param {string} folderId - Id of the folder to upload into.
 * @param {File[]} files - `event.dataTransfer.files`.
 * @param {DataTransferItem[]} dataTransferItems - `event.dataTransfer.items`.
 */
export function* uploadDroppedItems(folderId, files, dataTransferItems) {
  const folder = yield select(assetEntitySelector, { assetId: folderId });
  const projectId = folder.project_id;
  const isProjectArchived = yield select(isProjectArchivedSelector, {
    projectId,
  });
  if (isProjectArchived) {
    yield call(
      error,
      'Cannot upload asset',
      'You cannot upload assets to an archived project.'
    );
    return;
  }

  const index = yield select(indexToCreateAssetsSelector);
  const items = yield call(
    getUploadItemsFromDrop,
    files,
    dataTransferItems,
    index
  );
  yield spawn(trackUploadAttempt, items.length, 'dnd');
  const account = yield select(accountEntityForAssetIdSelector, {
    assetId: folderId,
  });
  const isAccountOnLegacyPlan = yield select(isAccountOnLegacyPlanSelector, {
    accountId: account.id,
  });

  const hasBlockedUploads = isAccountOnLegacyPlan
    ? yield call(legacyHasBlockedUpload, items, folderId)
    : yield call(hasBlockedUpload, items, folderId);
  if (hasBlockedUploads) return;

  const isDualViewEnabled = yield select(dualViewEnabled);
  const uploadFunc =
    isDualViewEnabled && isDualViewCandidate(items) ? uploadDualView : upload;
  yield call(uploadFunc, folderId, items);
}

/**
 * Upload files or a single folder via an Input element.
 * @param {string} folderId - Id of the folder to upload into.
 * @param {boolean} isFolder - `true` if uploading a folder.
 */
function* selectAndUploadItems(folderId, isFolder) {
  const files = yield call(
    selectFilesFromInput,
    isFolder
      ? {
          directory: true,
          mozdirectory: true,
          webkitdirectory: true,
        }
      : { multiple: true }
  );
  const index = yield select(indexToCreateAssetsSelector);
  const items = yield call(getUploadItemsFromInput, files, index);
  yield spawn(trackUploadAttempt, items.length, 'input');

  const account = yield select(accountEntityForAssetIdSelector, {
    assetId: folderId,
  });
  const isAccountOnLegacyPlan = yield select(isAccountOnLegacyPlanSelector, {
    accountId: account.id,
  });

  const hasBlockedUploads = isAccountOnLegacyPlan
    ? yield call(legacyHasBlockedUpload, items, folderId)
    : yield call(hasBlockedUpload, items, folderId);
  if (hasBlockedUploads) return;

  const isDualViewEnabled = yield select(dualViewEnabled);
  const uploadFunc =
    isDualViewEnabled && isDualViewCandidate(items) ? uploadDualView : upload;
  yield call(uploadFunc, folderId, items);
}

function* optimisticallyCreateFolder(parentFolderId, isPrivate, index) {
  const name = yield call(takeResponse);
  if (!name) return;

  const projectId = yield select(projectIdSelector);
  const creator = yield select(currentUserEntitySelector);
  const placeholder = buildPlaceholderAsset(parentFolderId, creator, {
    type: AssetType.FOLDER,
    name,
    private: isPrivate,
    index,
    project_id: projectId,
  });
  yield put(
    createAssetPlaceholders({
      [placeholder.id]: placeholder,
    })
  );
}

/**
 * Custom sorts assets into a given cell index.
 * @param {string[]} - Ids of assets to custom sort.
 * @param {number} - Index of the page result array to move them to.
 */
export function* customSortAssets(droppedAssetIds, cellIndex) {
  const count = droppedAssetIds.length;
  const assetIds = yield select(assetIdsSelector);
  const isFirst = cellIndex === 0;
  const isLast = cellIndex > assetIds.length - 1;
  const asset = yield select(assetAtCellSelector, {
    cellIndex: isLast ? assetIds.length - 1 : cellIndex,
  });
  const currentSortIndex = asset.index;

  // Note: This is an exclusive range: if we're dropping 3 assets
  // between start = 0 and end = 1, they will have indices of
  // 0.25, 0.50 and 0.75.
  let start;
  let end;

  if (isFirst) {
    start = currentSortIndex - count - 1;
    end = currentSortIndex;
  } else if (isLast) {
    start = currentSortIndex;
    end = currentSortIndex + count + 1;
  } else {
    start = (yield select(assetAtCellSelector, { cellIndex: cellIndex - 1 }))
      .index;
    end = currentSortIndex;
  }
  const diff = end - start;
  const fraction = diff / (count + 1);

  const assets = yield select(assetEntitiesByAssetIdsSelector, {
    assetIds: droppedAssetIds,
  });
  const updates = sortBy(assets, 'index').map((droppedAsset, offset) => ({
    id: droppedAsset.id,
    index: start + fraction * (offset + 1),
  }));
  yield put(batchUpdateAssets(updates));

  track('project-media-reordered');
}

function* sortAssets(sortOption) {
  const { canSortDescending, value } = sortOption;
  const viewType = yield select(assetsViewTypeSelector);
  const folderId = yield select(folderIdSelector);

  if (!folderId) return;

  yield put(resetAssets(true));
  yield fork(fetchAssets, folderId, 1);
  yield spawn(track, 'project-arena-sorted', {
    sort_to: value,
    sort_order: canSortDescending ? 'descending' : 'ascending',
    view_type: viewType,
  });
}

function* renameAsset(id, name) {
  const patch = { id, name };
  const currentAsset = yield select(assetEntitySelector, { assetId: id });
  yield put(patchAssets([patch]));
  const { failure } = yield call(updateAsset, id, patch);
  if (failure) {
    yield put(patchAssets([currentAsset]));
    yield put(
      showErrorToast({
        header: (
          <span>
            Something went wrong while renaming{' '}
            <strong>{currentAsset.name}</strong>
          </span>
        ),
      })
    );
  }
}

function* confirmDeleteSelectedAssets({ payload: { page, position } }) {
  const selectedIds = yield select(selectedAssetIdsSelector);
  yield put(confirmDeleteAssets(selectedIds, page, position));
}

function* onGetAncestorSuccess({ payload: { entityId, response } }) {
  const { result: ancestorIds } = response;
  yield put(setFolderAncestors(entityId, ancestorIds));
}

function* openEditProjectForm({ payload: { projectId } }) {
  const projectEntity = yield select(projectEntitySelector, { projectId });
  if (projectEntity?.project_preferences) {
    return yield put(openModal(<ConnectedEditProjectForm />));
  }

  const { success } = yield call(getProject, projectId);
  if (success) {
    return yield put(openModal(<ConnectedEditProjectForm />));
  }

  return yield put(
    showErrorToast({
      header: "Something went wrong while fetching the project's settings.",
      subHeader: 'Please try again.',
    })
  );
}

function* openProjectSelectorForPairing({ payload: { projectId, pairCode } }) {
  return yield put(
    openModal(
      <ConnectedPairingProjectSelectorModal
        projectId={projectId}
        pairCode={pairCode}
      />
    )
  );
}

function* closeProjectSelectorForPairing() {
  return yield put(closeModal());
}

export default [
  ...managePeopleSagas,
  ...manageVersionStackModalSagas,
  ...projectDevicesSagas,
  ...projectHeaderSagas,
  ...projectLinksSagas,
  ...projectPresentationsSagas,
  ...dualViewSagas,
  ...desktopAppSagas,
  takeLatest(PROJECT_CONTAINER.ENTER_PROJECT_FOLDER, enterProjectFolder),
  takeEveryUntil(
    PROJECT_CONTAINER.FETCH_ASSETS.BASE,
    ({ payload: { folderId, options } }) => fetchAssets(folderId, options.page),
    shouldCancelPreviousFetches
  ),
  takeLatest(
    PROJECT_CONTAINER.MOVE_ASSETS,
    ({ payload: { assetIds, folderId, onDone } }) =>
      moveAssets(assetIds, folderId, onDone)
  ),
  leadingThrottle(
    autoCloseDelay,
    takeRemoteAssetEventByType(AssetEvents.AssetCreated),
    showAssetCreatedToast
  ),
  takeLatest(
    PROJECT_CONTAINER.VERSION_ASSET,
    ({ payload: { assetId, targetAssetId, onDone } }) =>
      versionAsset(assetId, targetAssetId, onDone)
  ),
  takeEvery(
    PROJECT_CONTAINER.UPLOAD_DROPPED_ITEMS,
    ({ payload: { folderId, files, items } }) =>
      uploadDroppedItems(folderId, files, items)
  ),
  takeEvery(
    PROJECT_CONTAINER.UPLOAD_INPUT_ITEMS,
    ({ payload: { folderId, isFolder } }) =>
      selectAndUploadItems(folderId, isFolder)
  ),
  takeEvery(
    PROJECT_CONTAINER.CUSTOM_SORT_ASSETS,
    ({ payload: { assetIds, cellIndex } }) =>
      customSortAssets(assetIds, cellIndex)
  ),
  takeEvery(PROJECT_CONTAINER.SET_ASSETS_SORT, ({ payload: { sortOption } }) =>
    sortAssets(sortOption)
  ),
  takeLatest(
    ASSET.PROMPT_NEW_FOLDER_NAME,
    ({ payload: { parentFolderId, isPrivate, index } }) =>
      optimisticallyCreateFolder(parentFolderId, isPrivate, index)
  ),
  takeEvery(PROJECT_CONTAINER.RENAME_ASSET, ({ payload: { id, name } }) =>
    renameAsset(id, name)
  ),
  takeEvery(
    PROJECT_CONTAINER.CONFIRM_DELETE_SELECTED_ASSETS,
    confirmDeleteSelectedAssets
  ),
  takeEvery(CORE_ASSET.GET_ANCESTORS.SUCCESS, onGetAncestorSuccess),
  takeEvery(PROJECT_CONTAINER.OPEN_EDIT_PROJECT_FORM, openEditProjectForm),

  takeEvery(
    PROJECT_CONTAINER.OPEN_PAIRING_PROJECT_SELECT_MODAL,
    openProjectSelectorForPairing
  ),

  takeEvery(
    PROJECT_CONTAINER.CLOSE_PAIRING_PROJECT_SELECT_MODAL,
    closeProjectSelectorForPairing
  ),
];

export const testExports = {
  buildPlaceholderAsset,
  confirmDeleteSelectedAssets,
  enterFolder,
  enterProjectFolder,
  fetchAssetPage,
  fetchAssets,
  getAccountData,
  getProjectWithCompleteTeam,
  maybeRequestAccessToProject,
  optimisticallyCreateFolder,
  optimisticallyUploadItems,
  renameAsset,
  selectAndUploadItems,
  shouldCancelPreviousFetches,
  sortAssets,
  takeRemoteAssetEventByType,
};
