import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import { getSubscriptionByAccount } from '@frameio/core/src/subscriptions/actions';
import { getLineItemsForPlan } from '@frameio/core/src/planLineItems/actions';
import { currentAccountWithSubscriptionAndPlanSelector } from 'selectors/accounts';
import StorageLimitReachedFlow from './StorageLimitReachedFlow';
import { startFlow, endFlow } from './actions';

const mapDispatchToProps = {
  startFlow,
  endFlow,
  closeModal,
  getSubscriptionByAccount,
  getLineItemsForPlan,
};

export function mapStateToProps(state) {
  const { account, plan } = currentAccountWithSubscriptionAndPlanSelector(
    state
  );

  return {
    planId: plan.id,
    accountId: account.id,
    isEnterprise: plan.enterprise,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StorageLimitReachedFlow);

export const testExports = {
  mapDispatchToProps,
};
