import { combineReducers } from 'redux';
import brandingContainer from './BrandingContainer/reducer';
import groups from './GroupsContainer/reducer';
import people from './People/reducer';

export default combineReducers({
  brandingContainer,
  people,
  groups,
});
