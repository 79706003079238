import { connect } from 'react-redux';
import {
  canAssetsBeSharedAsLegacyPresentationSelector,
  canAssetsBeSharedSelector,
} from '@frameio/core/src/assets/selectors';
import { folderSharingEnabled } from 'utils/featureFlags';

import ShareAssets from './ShareAssets';

function mapStateToProps(
  state,
  { canSharePresentation, canShareReview, assetIds }
) {
  const canAssetsBeSharedAsPresentation = canAssetsBeSharedAsLegacyPresentationSelector(
    state,
    { assetIds }
  );

  const canAssetsBeSharedAsReview =
    folderSharingEnabled(state) ||
    canAssetsBeSharedSelector(state, {
      assetIds,
    });

  return {
    canShareAsPresentation:
      canSharePresentation && canAssetsBeSharedAsPresentation,
    canShareAsReview: canShareReview && canAssetsBeSharedAsReview,
  };
}

export default connect(mapStateToProps)(ShareAssets);

export const testExports = {
  mapStateToProps,
};
