import { get } from 'lodash';
import { isSimpleDialogOpenSelector } from 'components/Dialog/SimpleDialog/selectors';

/**
 * @param   {Object} state - Redux store state.
 * @returns {Boolean} - whether the current modal is closable.
 */
export const canCloseModalSelector = (state) =>
  get(state, 'modal.canCloseModal');

export const isModalOpenSelector = (state) =>
  get(state, 'modal.isOpen', false) || isSimpleDialogOpenSelector(state);
