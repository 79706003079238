import { MANAGE_SUBSCRIPTION } from './actions';
import { isFetchingReducerFactory } from '../../factories/isFetching';

const isFetchingReducer = isFetchingReducerFactory(
  MANAGE_SUBSCRIPTION.IS_FETCHING
);

const initialState = {
  isFetching: false,
};

export default function setSubscription(state = initialState, action) {
  switch (action.type) {
    default:
      return isFetchingReducer(state, action);
  }
}
