import { connect } from 'react-redux';
import PeopleList from 'components/PeopleList';
import { personIdsSelector } from '../selectors';
import { fetchUsers, searchCollaboratorsInProject } from '../actions';

function mapStateToProps(state) {
  return { personIds: personIdsSelector(state) };
}

function mapDispatchToProps(dispatch, { projectId }) {
  return {
    filterPeople: (query) =>
      dispatch(searchCollaboratorsInProject(projectId, query)),
    fetchPeople: () => dispatch(fetchUsers(projectId)),
  };
}

export const testExports = { mapStateToProps, mapDispatchToProps };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeopleList);
