import { includes } from 'lodash';
import ROLES from '@frameio/core/src/roles/helpers/constants';
/**
 * Returns whether or not the user has permissions to change account things
 * @param {string} role - Role in question.
 */
// eslint-disable-next-line import/prefer-default-export
export function canSelfService(role) {
  return includes([ROLES.OWNER, ROLES.ADMIN], role);
}
