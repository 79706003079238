import React from 'react';
import { takeLatest, put, call, select, spawn } from 'redux-saga/effects';
import track from 'analytics';
import { getEntityFromNormalizedResponse } from '@frameio/core/src/shared/utils/entities';
import { teamEntitySelector } from '@frameio/core/src/teams/selectors';
import { getSubscriptionLineItemsForAccount as getSubscriptionLineItemsForAccountCoreSaga } from '@frameio/core/src/subscriptionLineItems/sagas';
import {
  createTeamByAccount as createTeamByAccountSaga,
  updateTeam as updateTeamSaga,
  getTeamsByUser as getTeamsByUserSaga,
} from '@frameio/core/src/teams/sagas';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import { JTTeam } from 'core/JTDynamoObject';
import { openModal } from 'components/Modal/actions';

import { shouldFetchCardsByAccount } from 'sagas/accounts';
import CancelAccountFlow from 'components/CancelAccountFlow';
import { SETTINGS_CONTAINER } from './actions';
import OAuthAppsContainerSagas from './OAuthAppsContainer/sagas';
import BrandingContainerSagas from './BrandingContainer/sagas';
import GroupsContainerSagas from './GroupsContainer/sagas';
import EditInvoiceSettingsSagas from './BillingContainer/EditInvoiceSettings/sagas';
import PeopleSettingsSagas from './People/sagas';

const CLICKED_CANCEL_SUBSCRIPTION = 'clicked_cancel_subscription';

function* fetchSettingsContainerData(userId) {
  yield call(getTeamsByUserSaga, userId);
}

function* onCancelSubscriptionClick() {
  const source = 'Cancel subscription';
  yield put(
    openModal(
      (onClose) => <CancelAccountFlow source={source} onClose={onClose} />,
      {
        canCloseModal: true,
        onClose: () => {
          track('cancellation-modal-closed', {
            cancellation_source: source,
          });
        },
      }
    )
  );
  yield spawn(track, 'cancellation-started');
}

/**
 * Add new team to JTDynamoObject, re-layout cells for account pages.
 * @param {Object} response - Response object from core.
 */
function* createTeam(accountId, teamData) {
  const { failure, success } = yield call(
    createTeamByAccountSaga,
    accountId,
    teamData
  );

  if (failure) {
    yield put(
      showErrorToast({
        header: 'Failed to create team',
      })
    );
    return;
  }

  const team = getEntityFromNormalizedResponse(success.payload.response);

  yield put(
    showSuccessToast({
      header: `Successfully created ${team.name}`,
    })
  );
  const jtTeam = new JTTeam({
    id: team.id,
    name: team.name,
    watermark: team.watermark,
  });

  vc.account_settings_page.current_account.add_team(jtTeam);
  vc.account_settings_page.layout_cells();
}

/**
 * Show success toast when team updates.
 * @param {Object} response - Response object from core.
 */
function* updateTeam(teamId, data) {
  const { failure } = yield call(updateTeamSaga, teamId, data);
  const team = yield select(teamEntitySelector, { teamId });

  if (failure) {
    yield put(
      showErrorToast({
        header: `Failed to update ${team.name}‘s settings`,
      })
    );
    return;
  }

  yield put(
    showSuccessToast({
      header: `Successfully updated ${team.name}‘s settings`,
    })
  );

  const jtTeam = JTTeam.find_by_id(teamId);
  jtTeam.set_attrs(
    {
      name: team.name,
      watermark: team.watermark,
      slack_webhook: team.slack_webhook,
    },
    null,
    true
  );
  vc.account_settings_page.layout_cells();
}

function* getSubscriptionLineItemsForAccount(accountId) {
  yield call(getSubscriptionLineItemsForAccountCoreSaga, accountId);
}

export default [
  ...OAuthAppsContainerSagas,
  ...BrandingContainerSagas,
  ...EditInvoiceSettingsSagas,
  ...GroupsContainerSagas,
  ...PeopleSettingsSagas,

  takeLatest(SETTINGS_CONTAINER.FETCH_DATA, ({ payload: { userId } }) =>
    fetchSettingsContainerData(userId)
  ),
  takeLatest(SETTINGS_CONTAINER.UPDATE_TEAM, ({ payload: { teamId, data } }) =>
    updateTeam(teamId, data)
  ),
  takeLatest(
    SETTINGS_CONTAINER.CREATE_TEAM,
    ({ payload: { accountId, team } }) => createTeam(accountId, team)
  ),
  takeLatest(
    SETTINGS_CONTAINER.CANCEL_SUBSCRIPTION,
    ({ payload: { accountId } }) => onCancelSubscriptionClick(accountId)
  ),
  takeLatest(
    SETTINGS_CONTAINER.GET_SUBSCRIPTION_LINE_ITEMS,
    ({ payload: { accountId } }) =>
      getSubscriptionLineItemsForAccount(accountId)
  ),
  takeLatest(
    SETTINGS_CONTAINER.FETCH_CARDS_FOR_ACCOUNT,
    ({ payload: { accountId } }) => shouldFetchCardsByAccount(accountId)
  ),
];

export const testExports = {
  fetchSettingsContainerData,
  updateTeam,
  createTeam,
  createTeamByAccountSaga,
  onCancelSubscriptionClick,
  CLICKED_CANCEL_SUBSCRIPTION,
  getSubscriptionLineItemsForAccount,
};
