import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';
import { formatLongDateTime } from '@frameio/components/src/utils/datetimeHelpers';
import FileSVG from '@frameio/components/src/svgs/raw/ic-file-230px.svg';

import { formatBytes } from 'shared/filesizeHelpers';

import { Container, Panel, PREVIEW_HEIGHT } from './QuicklookFolder';

export const FileIcon = styled(FileSVG)`
  color: ${(p) => p.theme.color.dimGray};
  width: auto;
  height: ${PREVIEW_HEIGHT}px;
  position: relative;
  filter: drop-shadow(0 0 6px ${({ theme }) => rgba(theme.color.black, 0.5)});

  &:after {
    content: '${({ type }) => type}';
    position: absolute;
    bottom: ${(p) => p.theme.spacing.medium};
    width: 100%;
    text-align: center;
    display: block;
    font-size: 32px;
    color: ${(p) => p.theme.color.white};
  }
`;

export default function QuicklookFile({ asset, creator }) {
  return (
    <Container>
      <Panel grow={0} shrink={0} basis={`${PREVIEW_HEIGHT}px`}>
        <FileIcon
          type={asset.filetype.slice(asset.filetype.lastIndexOf('/') + 1)}
        />
      </Panel>
      <Panel grow={1} shrink={1} basis="50%">
        <h2>{asset.name}</h2>
        <p>{formatBytes(asset.filesize)}</p>
        <p>Last modified {formatLongDateTime(asset.updated_at)}</p>
        <p>
          Uploaded by <strong>{creator.name}</strong>
          <br />
          {formatLongDateTime(asset.inserted_at)}
        </p>
      </Panel>
    </Container>
  );
}

QuicklookFile.propTypes = {
  asset: PropTypes.shape({
    filetype: PropTypes.string,
    name: PropTypes.string,
    filesize: PropTypes.number,
    inserted_at: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
  creator: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};
