import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

const module = 'PROJECT_CONTAINER';

const QUICKLOOK = {
  ...generateActionTypes(module, [
    'OPEN',
    'CLOSE',
    'SET_ASSET_ID',
    'SET_ORIGIN',
  ]),
};

/**
 * 🚨**HALT**🚨: Have you taken a look at withQuicklook? The withQuicklook
 * HOC abstracts away the fact that Quicklook is connected to the store,
 * providing useful methods to pass to your React component for updating the
 * Quicklook state.
 */

export default QUICKLOOK;

export const open = createAction(QUICKLOOK.OPEN, () => ({}));

export const close = createAction(QUICKLOOK.CLOSE, () => ({}));

export const setAssetId = createAction(QUICKLOOK.SET_ASSET_ID, (assetId) => ({
  assetId,
}));

export const setOrigin = createAction(QUICKLOOK.SET_ORIGIN, (origin) => ({
  origin,
}));
