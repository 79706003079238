import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { MenuGroup, MenuRadio } from '@frameio/vapor';
import { FAILED_TASK_MESSAGE, STATUS } from './integrationConstants';
import WorkfrontManageTaskSubmenu from './WorkfrontManageTaskSubmenu';

const StyledSubmenuIconContainer = styled.div`
  margin-right: -12px;
  svg {
    color: #b2b4b8;
  }
`;

const WorkfrontTaskSubmenu = ({
  assetIds,
  integration,
  isWorkfrontConnected,
  menuItems,
  projectId,
  submenu,
}) =>
  !submenu.length ? (
    <WorkfrontManageTaskSubmenu
      isWorkfrontConnected={isWorkfrontConnected}
      workfrontTaskName={FAILED_TASK_MESSAGE}
    />
  ) : (
    <>
      <WorkfrontManageTaskSubmenu
        isWorkfrontConnected={isWorkfrontConnected}
        workfrontTaskName={menuItems.details}
      />
      {isWorkfrontConnected ? (
        <MenuGroup label={STATUS}>
          {submenu.map(
            ({ canChangeStatus, details: ManageWorkfrontTask, status }) => (
              <div key={status}>
                <ManageWorkfrontTask
                  disabled={
                    (isWorkfrontConnected && !canChangeStatus) ||
                    status === 'New'
                  }
                />
              </div>
            )
          )}
        </MenuGroup>
      ) : (
        <MenuGroup>
          {submenu.map(({ icon: Icon, name, onSelect }) => (
            <div key={name}>
              <MenuRadio
                key={name}
                iconBefore={
                  <StyledSubmenuIconContainer>
                    <Icon height={16} />
                  </StyledSubmenuIconContainer>
                }
                onSelect={() => onSelect({ projectId, assetIds, integration })}
              >
                <Flex>{name}</Flex>
              </MenuRadio>
            </div>
          ))}
        </MenuGroup>
      )}
    </>
  );

export default WorkfrontTaskSubmenu;
