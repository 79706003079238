import PropTypes from 'prop-types';
import Raven from 'raven-js';
import { setEdgeCacheReissueCookie } from '@frameio/core/src/auth';
import { redirectToAuth } from 'utils/router';
import {
  configureCore,
  getAuthTokenExpiryForAuthToken,
  getAuthTokenExpiredForAuthToken,
  setAuthCookies,
} from 'utils/auth';
import { getRedirectTrackingOpts } from 'analytics';

export default function AuthCallback({ history }) {
  const hash = new URLSearchParams(window.location.hash.replace('#', ''));
  const token = hash.get('token');
  const refreshToken = hash.get('refreshToken');
  const sessionToken = hash.get('sessionToken');
  const params = {
    message: 'Please login again to view this page',
  };
  if (!token || !refreshToken) {
    const trackingOpts = getRedirectTrackingOpts({
      authTokenExpiry: getAuthTokenExpiryForAuthToken(token),
      authTokenExpired: getAuthTokenExpiredForAuthToken(token),
      details:
        'Navigated to app.frame.io/auth/callback, no tokens found in url params',
      hasRefreshToken: Boolean(refreshToken),
      hasAuthToken: Boolean(token),
    });

    return redirectToAuth(params, true, trackingOpts);
  }
  setAuthCookies(token, refreshToken, sessionToken);

  // This call sets a `httpOnly` cookie that's used to reissue credentials
  // for media requests.
  try {
    configureCore();
    setEdgeCacheReissueCookie();
  } catch (error) {
    Raven.captureException(error);
  }

  // WK-183
  if (window.location.hostname === 'lvh.me') {
    // eslint-disable-next-line no-console
    console.error(
      "Local development with 'http://lvh.me:8080' is deprecated, instead use 'https://app-local.dev.frame.io'."
    );
  }

  // Go to the requested page.
  const query = new URLSearchParams(window.location.search);
  const redirectPath = query.get('redirect_path');
  const isRedirectPathValid = redirectPath?.[0] === '/';
  history.push(isRedirectPathValid ? redirectPath : '/');

  return null;
}

AuthCallback.propTypes = {
  history: PropTypes.object.isRequired,
};
