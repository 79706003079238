import { get } from 'lodash';
import { call, put, all } from 'redux-saga/effects';
import { getPendingCollaboratorsForProject } from '@frameio/core/src/pendingCollaborators/sagas';
import { getCollaboratorsForProject } from '@frameio/core/src/collaborators/sagas';
import PEOPLE_CIRCLE_STACK from './actions';

export function* getCircleStackForProject(projectId) {
  const params = {
    page: 1,
    pageSize: 5,
    sort: '-inserted_at',
  };
  const [collaboratorsResult, pendingCollaboratorsResult] = yield all([
    call(getCollaboratorsForProject, projectId, params),
    call(getPendingCollaboratorsForProject, projectId, params),
  ]);

  const totalCollaborators = get(
    collaboratorsResult,
    'success.payload.response.headers.total',
    0
  );
  const totalPendingCollaborators = get(
    pendingCollaboratorsResult,
    'success.payload.response.headers.total',
    0
  );

  if (collaboratorsResult.success) {
    yield put({
      type: PEOPLE_CIRCLE_STACK.GET_COLLABORATORS.SUCCESS,
      payload: {
        ...collaboratorsResult.success.payload,
        totalCount: totalCollaborators + totalPendingCollaborators,
      },
    });
  }
}

export const testExports = {
  getCircleStackForProject,
};
