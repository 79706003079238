import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SharePresentationIcon from '@frameio/components/src/svgs/raw/ic-sharing-presentation-16px.svg';
import { trackButtonClick } from 'analytics';
import { MenuButton } from '@frameio/vapor';

const Icon = styled(SharePresentationIcon)`
  path {
    fill: currentColor;
  }
`;

export default function ShareAssetsAsPresentation({
  assetId,
  hasPermission,
  canAssetsBeShared,
  hasExplanation,
  isEmptyFolder,
  isEnterprise,
  isMultipleAssets,
  legacyOpenPresentationEditor,
  projectId,
  selectPresentationAssets,
  showBlockedCollaboratorMessage,
  trackingPage,
  trackingPosition,
  ...rest
}) {
  const isDisabled = !canAssetsBeShared;
  let secondaryText = 'View-only links with custom branding';
  if (isMultipleAssets) {
    secondaryText =
      'You can only create a presentation with a single file or with all files in a folder.';
  } else if (isEmptyFolder) {
    secondaryText =
      'This folder is empty. Please select a file or a folder containing some assets.';
  }
  return (
    <MenuButton
      onSelect={() => {
        if (isDisabled) return;

        if (hasPermission) {
          legacyOpenPresentationEditor(assetId);
          trackButtonClick(
            'shared presentation',
            trackingPage,
            trackingPosition
          );
        } else {
          showBlockedCollaboratorMessage(projectId, 'presentation_page');
          if (isEnterprise) {
            trackButtonClick(
              'enterprise collaborator attempted to share presentation',
              trackingPage,
              trackingPosition
            );
          } else {
            trackButtonClick(
              'non-enterprise collaborator attempted to share presentation',
              trackingPage,
              trackingPosition
            );
          }
        }
      }}
      iconBefore={<Icon />}
      disabled={isDisabled}
      details={hasExplanation ? secondaryText : null}
      {...rest}
    >
      Share as Presentation
    </MenuButton>
  );
}

ShareAssetsAsPresentation.propTypes = {
  assetId: PropTypes.string.isRequired,
  hasPermission: PropTypes.bool,
  canAssetsBeShared: PropTypes.bool.isRequired,
  hasExplanation: PropTypes.bool,
  isEmptyFolder: PropTypes.bool.isRequired,
  isEnterprise: PropTypes.bool.isRequired,
  isMultipleAssets: PropTypes.bool.isRequired,
  legacyOpenPresentationEditor: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  selectPresentationAssets: PropTypes.func.isRequired,
  showBlockedCollaboratorMessage: PropTypes.func.isRequired,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};

ShareAssetsAsPresentation.defaultProps = {
  hasPermission: false,
  hasExplanation: false,
};
