import React, { useCallback, useEffect, useState } from 'react';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { debounce } from 'lodash';
import styled from 'styled-components';
import Media from 'react-media';
import { MEDIUM_DOWN } from 'utils/mediaQueries';
import { LARGE_MIN } from 'utils/breakpoints';
import { DASHBOARD_SIDEBAR_WIDTH } from '../constants';
import { getPersistedPanelWidth, savePersistedPanelWidth } from './utils';

const DEBOUNCE_WAIT = 150;

const PanelContainer = styled(motion.div)`
  position: relative;
  ${(p) => p.side}: 0px;
  width: ${(p) => p.min}px;
  flex-shrink: 0;
  height: 100%;
  min-height: 100vh;
`;

const DragHandle = styled(motion.div)`
  position: absolute;
  top: 0;
  ${(p) => `${p.side}: ${p.min + p.panelOffset}px`};
  height: 100%;
  width: 5px;
  margin-right: -2.5px;
  cursor: col-resize;
  background-color: transparent;
  z-index: 1;
`;

const Panel = ({
  children,
  style,
  defaultMin = DASHBOARD_SIDEBAR_WIDTH.min,
  defaultMax = DASHBOARD_SIDEBAR_WIDTH.max,
  side = 'left',
  panelOffset = 0,
  disableResizeAtQuery = MEDIUM_DOWN, // disable resizing at certain media query
  widthBeforePanelTruncation = LARGE_MIN + DASHBOARD_SIDEBAR_WIDTH.min,
  ...props
}) => {
  // A motion value for the x-axis offset of the drag handle.
  const mvOffset = useMotionValue(0);
  // Max value avail to panel, can change on screen resize.
  const [panelMax, setPanelMax] = useState(defaultMax);

  // A motion value for the width of the panel, based on the offset of the drag handle
  const mvWidth = useTransform(mvOffset, (incomingOffset) => {
    const calcWidth =
      side === 'left'
        ? incomingOffset + defaultMin
        : defaultMin - incomingOffset;

    // Clamping to the min value prevents odd overflow issues with the adjacent panel.
    if (calcWidth < defaultMin) return defaultMin;

    return calcWidth;
  });

  const calcHandleOffset = useCallback(
    (existingOffset) => {
      return Math.max(0, Math.min(panelMax - defaultMin, existingOffset));
    },
    [defaultMin, panelMax]
  );

  const startDragging = () => {
    // Set the global cursor
    document.body.style.cursor = 'col-resize';
  };

  const stopDragging = () => {
    // Reset the cursor
    document.body.style.cursor = 'default';
    const offset = calcHandleOffset(mvOffset.get());
    savePersistedPanelWidth({ side, offset });
  };

  /*
   * Prioritize using the saved offset (if we have one) or use default.
   * Calculate the offset after avail screen width and max panel is defined.
   */
  useEffect(() => {
    const saved = getPersistedPanelWidth({ side });
    // Saved value could be 0 so explictly look for null:
    const offset = calcHandleOffset(saved === null ? mvOffset.get() : saved);
    mvOffset.set(offset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcHandleOffset, panelMax, side]);

  useEffect(() => {
    // resets cursor on unmount
    return () => {
      document.body.style.cursor = 'default';
    };
  }, []);

  // Watch window resize and detect a new max width if needed.
  React.useLayoutEffect(() => {
    const calcMaxPanelWidth = () => {
      const availWidth = window.innerWidth;
      let newMax = defaultMax;
      if (availWidth <= widthBeforePanelTruncation) {
        /*
         * When the window width is less than the const,
         * We will need a new max width so there is enough space for content
         * in the list view.
         */
        newMax = defaultMax - (widthBeforePanelTruncation - availWidth);
      }
      // Set max:
      setPanelMax(newMax);
    };

    // On mount calc the max
    calcMaxPanelWidth();

    const debouncedOnResize = debounce(calcMaxPanelWidth, DEBOUNCE_WAIT);

    window.addEventListener('resize', debouncedOnResize);

    return () => window.removeEventListener('resize', debouncedOnResize);
  }, [defaultMax, widthBeforePanelTruncation]);

  return (
    <Media query={disableResizeAtQuery}>
      {(matches) =>
        matches ? (
          children
        ) : (
          <PanelContainer
            side={side}
            min={defaultMin}
            style={{ width: mvWidth, ...style }}
            {...props}
          >
            {children}
            <DragHandle
              min={defaultMin}
              side={side}
              panelOffset={panelOffset}
              style={{ x: mvOffset }}
              drag="x"
              dragElastic={0.03}
              dragConstraints={{
                left: side === 'left' ? 0 : defaultMin - panelMax,
                right: side === 'left' ? panelMax - defaultMin : 0,
              }}
              dragMomentum={false}
              whileTap="active"
              whileHover="active"
              onPointerDown={startDragging}
              onPointerUp={stopDragging}
              onPanEnd={stopDragging}
              onTap={stopDragging}
            />
          </PanelContainer>
        )
      }
    </Media>
  );
};

export default Panel;
