import { connect } from 'react-redux';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import {
  getAssetForViewType,
  isCompositeViewAsset,
} from '@frameio/components/src/components/CompletePlayer/CompositeView/utils';
import { Resolution } from '@frameio/components/src/components/PlayerSubcomponents/PlayerInterface';
import {
  getClosestMatchingAssetResolution,
  resolutionOptionToAssetTranscodeKeyName,
} from 'components/PlayerContainers/utils/videoProxyUtils';

function mapStateToProps(state, { assetId: bundleAssetId, selectedViewType }) {
  const bundleAssetEntity = assetEntitySelector(state, {
    assetId: bundleAssetId,
  });
  let childAssets = [];
  let fullscreenSrc;

  // BundleProvider is going to take an asset and try to provide consumers any necessary
  // state if the asset is bundle.
  // In the case of DualView the only relevant info is the 1080 source on the selected stream.
  // We use this to switch to the full res source when going fullscreen on a DualView asset.
  if (isCompositeViewAsset(bundleAssetEntity) && selectedViewType) {
    const childIds = Object.keys(bundleAssetEntity.layout);
    childAssets = childIds.map((assetId) =>
      assetEntitySelector(state, { assetId })
    );
    const childAsset = getAssetForViewType(
      selectedViewType,
      bundleAssetEntity,
      childAssets
    );

    const fullscreenResolution =
      childAsset &&
      getClosestMatchingAssetResolution(Resolution.R1080, childAsset);

    fullscreenSrc =
      childAsset &&
      childAsset[resolutionOptionToAssetTranscodeKeyName[fullscreenResolution]];
  }

  return { childAssets, fullscreenSrc };
}

const BundleProvider = (props) => {
  const { childAssets, children: renderChildren, fullscreenSrc } = props;

  return renderChildren({ childAssets, fullscreenSrc });
};

export default connect(mapStateToProps)(BundleProvider);
