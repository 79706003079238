import { connect } from 'react-redux';
import {
  confirmLeaveProject,
  joinProjectAsCollaborator,
} from 'actions/projects';
import { isUserCollaboratorOfProjectSelector } from 'selectors/roles';
import JoinLeaveProject from './JoinLeaveProject';

export const mapStateToProps = (state, { projectId }) => ({
  isProjectCollaborator: isUserCollaboratorOfProjectSelector(state, {
    projectId,
  }),
});

export const mapDispatchToProps = {
  onLeaveProject: confirmLeaveProject,
  onJoinProject: joinProjectAsCollaborator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JoinLeaveProject);
