import { connect } from 'react-redux';
import { userEntitySelector } from '@frameio/core/src/users/selectors';
import { currentUserEntitySelector } from 'selectors/users';
import UploadImageStep from '../UploadImageStep';
import {
  continueFlow,
  removeImageForMe,
  uploadDroppedImageForMe,
  uploadSelectedImageForMe,
} from '../actions';
import { isUploadingImageForMeSelector } from '../selectors';

export const UPLOAD_USER_IMAGE_STEP = 'upload-user-image-step';

function mapStateToProps(state, { currentStep }) {
  const { id: userId } = currentUserEntitySelector(state);
  const user = userEntitySelector(state, { userId });
  const isUploading = isUploadingImageForMeSelector(state);

  return {
    currentStep,
    header: 'Add your photo',
    isUploading,
    imageUrl: user.image_128,
    subheader: 'Upload a picture of your lovely face 😀',
    trackingPageName: UPLOAD_USER_IMAGE_STEP,
  };
}

const mapDispatchToProps = {
  onContinue: continueFlow,
  removeImage: removeImageForMe,
  uploadDroppedItem: uploadDroppedImageForMe,
  uploadSelectedItem: uploadSelectedImageForMe,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadImageStep);
