import React from 'react';
import {
  put,
  take,
  takeLatest,
  select,
  call,
  spawn,
  fork,
  all,
  race,
} from 'redux-saga/effects';
import { writeText } from 'clipboard-polyfill';
import { get, set } from 'lodash';
import track from 'analytics';
import { patchUsers } from '@frameio/core/src/users/actions';
import { patchProjects } from '@frameio/core/src/projects/actions';
import { updateProject } from '@frameio/core/src/projects/sagas';
import { createPendingAction } from '@frameio/core/src/shared/actions/helpers';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import {
  accountEntityForProjectIdSelector,
  teamEntityForProjectIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import AddSeatsFlow from 'components/AddSeatsFlow';
import { canAddTeamMemberSelector } from 'components/AddSeatsFlow/selectors';
import { ADD_SEATS_FLOW } from 'components/AddSeatsFlow/actions';
import Truncate from '@frameio/components/src/styled-components/Truncate';
import {
  addCollaboratorsToProject,
  removeCollaboratorFromProject,
  getCollaboratorsForProject,
  searchCollaboratorsInProject,
} from '@frameio/core/src/collaborators/sagas';
import {
  getPendingCollaboratorsForProject,
  searchPendingCollaboratorsInProject,
  removePendingCollaboratorFromProject,
} from '@frameio/core/src/pendingCollaborators/sagas';
import { userEntitySelector } from '@frameio/core/src/users/selectors';
import { batchAddTeamMembers } from '@frameio/core/src/teamMembers/sagas';
import { MODAL, openModal, closeModal } from 'components/Modal/actions';
import {
  calculateInvitedGroupsCount,
  calculateInvitedUsersCount,
} from 'components/UserSearch/sagas';
import { getTokensToSubmit } from 'components/UserSearch/utils';
import { isOnPaidCollaboratorsPlanSelector } from 'selectors/accounts';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import { newProjectInviteLinksEnabled } from 'utils/featureFlags';

import ManagePeople from './index';
import {
  MANAGE_PEOPLE,
  toggleOpen,
  setIsInvitingUsers,
  setIsUpdatingShareLink,
} from './actions';
import { projectIdSelector } from '../selectors';
import { peopleListScrollTopSelector } from './selectors';

function* getUsersForProject(projectId, page, pageSize, sort) {
  const options = { page, pageSize, sort };
  yield fork(getPendingCollaboratorsForProject, projectId, options);
  yield fork(getCollaboratorsForProject, projectId, {
    ...options,
    includeRole: true,
  });
}

function putAction(type, payload = {}) {
  return put({ type, payload });
}

function* resendInvite(projectId, email) {
  yield putAction(MANAGE_PEOPLE.RESEND_INVITE.PENDING, { email });
  const serverParams = {
    batch: [{ email }],
  };
  const { failure } = yield call(
    addCollaboratorsToProject,
    projectId,
    serverParams
  );
  if (failure) {
    yield putAction(MANAGE_PEOPLE.RESEND_INVITE.FAILURE, { email });
    return;
  }

  yield putAction(MANAGE_PEOPLE.RESEND_INVITE.SUCCESS, { email });
}

function* inviteToProject(projectId, tokens, message) {
  const tokensToSubmit = getTokensToSubmit(tokens);
  const serverParams = {
    batch: tokensToSubmit,
    default_message: message,
  };

  yield put(setIsInvitingUsers(true));

  const { success } = yield call(
    addCollaboratorsToProject,
    projectId,
    serverParams
  );

  yield put(setIsInvitingUsers(false));

  const invitedUsersCount = yield call(calculateInvitedUsersCount, tokens);
  const invitedGroupsCount = yield call(calculateInvitedGroupsCount, tokens);

  if (success) {
    yield put(closeModal());
    yield spawn(track, 'add-people-modal-submitted', {
      is_successful: true,
      users_invited: invitedUsersCount,
      groups_invited: invitedGroupsCount,
    });
  } else {
    if (tokens.length > 50) {
      yield put(
        showErrorToast({
          header: 'You cannot share with more than 50 people at a time',
        })
      );
    } else {
      yield put(
        showErrorToast({ header: 'There was an error adding collaborators' })
      );
    }

    yield spawn(track, 'add-people-modal-submitted', {
      is_successful: false,
      users_invited: invitedUsersCount,
      groups_invited: invitedGroupsCount,
    });
  }
}

function* toggleModalOpen(isOpen, props = {}) {
  if (!isOpen) return;

  yield put(openModal(<ManagePeople {...props} />));
  yield spawn(track, 'add-people-modal-shown');
  const isClosingModal = yield take(MODAL.CLOSE);
  if (isClosingModal) {
    yield spawn(track, 'add-people-modal-closed');
  }
  yield put(toggleOpen(false));
}

function* onRemovePerson({ success, failure }, name) {
  if (success) {
    yield put(
      showSuccessToast({
        header: `Successfully removed ${name} from this project`,
      })
    );
  }
  if (failure) {
    yield put(
      showErrorToast({
        header: `There was an error removing ${name} from this project`,
      })
    );
  }
}

function* removeCollaborator(collaboratorId, name) {
  const projectId = yield select(projectIdSelector);
  const resp = yield call(
    removeCollaboratorFromProject,
    projectId,
    collaboratorId
  );
  yield call(onRemovePerson, resp, name);
}

function* removePendingCollaborator(pendingCollaboratorId, email) {
  const projectId = yield select(projectIdSelector);
  const resp = yield call(
    removePendingCollaboratorFromProject,
    projectId,
    pendingCollaboratorId
  );
  yield call(onRemovePerson, resp, email);
}

function* copyToClipboard(text) {
  if (!writeText(text)) return;
  yield put(
    showSuccessToast({
      header: 'Copied to clipboard!',
      subHeader: <Truncate>{text}</Truncate>,
      autoCloseDelay: 4000,
    })
  );
}

// TODO: Deprecate and remove this once we've ramped the `api.projects.new_invite_links`
// feature flag up to 100% and this code path is no longer valid
function* optimisticallyUpdateProjectShareLink(projectId, isEnabled) {
  const params = { shared: isEnabled };
  const patch = { id: projectId, shared: isEnabled };
  const origProject = yield select(projectEntitySelector, { projectId });

  // Optimistically update
  yield put(patchProjects([patch]));
  yield call(copyToClipboard, origProject.invite_url);

  const { success, failure } = yield call(updateProject, projectId, params);

  if (success && !isEnabled) {
    yield put(showSuccessToast({ header: 'Project share link disabled' }));
  }

  if (failure) {
    yield put(patchProjects([origProject]));
    yield put(
      showErrorToast({
        header: `There was an error ${
          isEnabled ? 'enabling' : 'disabling'
        } the project share link`,
      })
    );
  }
}

// This function will always ensure that we round trip the shared update to the server
// before showing the invite link back to the user. That's because the invite_url is now
// generated on the server when the shared status is toggled
function* asyncUpdateProjectShareLink(projectId, isEnabled) {
  const params = { shared: isEnabled };
  yield put(setIsUpdatingShareLink(true));
  const { failure } = yield call(updateProject, projectId, params);
  yield put(setIsUpdatingShareLink(false));

  if (failure) {
    yield put(
      showErrorToast({
        header: `There was an error ${
          isEnabled ? 'enabling' : 'disabling'
        } the project share link`,
      })
    );
    return;
  }

  const project = yield select(projectEntitySelector, { projectId });

  if (isEnabled) {
    yield call(copyToClipboard, project.invite_url);
  } else {
    yield put(showSuccessToast({ header: 'Project share link disabled' }));
  }
}

function* updateProjectShareLink(projectId, isEnabled) {
  const newProjectLinksEnabled = yield select(newProjectInviteLinksEnabled);

  if (newProjectLinksEnabled) {
    yield call(asyncUpdateProjectShareLink, projectId, isEnabled);
  } else {
    yield call(optimisticallyUpdateProjectShareLink, projectId, isEnabled);
  }
}

function* filterCollaborators(projectId, query) {
  yield put(createPendingAction(MANAGE_PEOPLE.FILTER_COLLABORATORS.PENDING));

  const [collaboratorsSearch, pendingCollaboratorSearch] = yield all([
    call(searchCollaboratorsInProject, projectId, query, { includeRole: true }),
    call(searchPendingCollaboratorsInProject, projectId, query),
  ]);

  const collaborators = get(
    collaboratorsSearch,
    'success.payload.response',
    []
  );
  const pendingCollaborators = get(
    pendingCollaboratorSearch,
    'success.payload.response',
    []
  );

  yield put({
    type: MANAGE_PEOPLE.FILTER_COLLABORATORS.SUCCESS,
    payload: {
      collaborators,
      pendingCollaborators,
    },
  });
}

function* addTeamSeats(accountId) {
  yield put(
    openModal(
      <AddSeatsFlow
        accountId={accountId}
        onComplete={closeModal}
        trackingProps={{ source: 'manage people' }}
        showTotalLineItem={false}
      />
    )
  );

  const { cancelled } = yield race({
    success: take(ADD_SEATS_FLOW.REPORT_SUCCESS),
    // TODO: Right now `ADD_SEATS_FLOW.END` is used/dispatched on two occasions:
    // * user cancels the flow
    // * user goes through the complete flow and clicks `Done` on the last confirmation modal
    // The latter case is what we're waiting for here.
    // To make it more readable, we need to add a
    // new action type `ADD_SEATS_FLOW.CANCELLED`
    cancelled: take(ADD_SEATS_FLOW.END),
  });
  if (cancelled) return false;
  // wait until the confirmation modal closed
  yield take(ADD_SEATS_FLOW.END);
  return true;
}

function* addToTeam(userId, email) {
  const projectId = yield select(projectIdSelector);
  const account = yield select(accountEntityForProjectIdSelector, {
    projectId,
  });
  const canAddTeamMember = yield select(canAddTeamMemberSelector, {
    accountId: account.id,
  });
  const isOnPaidCollaboratorsPlan = yield select(
    isOnPaidCollaboratorsPlanSelector
  );

  let scrollTop = 0;

  const shoulShowdAddSeatsFlow =
    !canAddTeamMember && !isOnPaidCollaboratorsPlan;

  // When switching a user's role from a Collaborator to a Team Member, we only want to trigger the
  // add seats flow if the account is on a v2/v3 plan (where the account should be charged for any
  // new Team Member)
  if (shoulShowdAddSeatsFlow) {
    yield put(closeModal());
    const success = yield call(addTeamSeats, account.id);
    scrollTop = yield select(peopleListScrollTopSelector);
    if (!success) {
      yield fork(toggleModalOpen, true, { openAndScrollTo: scrollTop });
      return;
    }
  }

  const team = yield select(teamEntityForProjectIdSelector, { projectId }) ||
    {};
  const origUser = yield select(userEntitySelector, { userId });
  const patch = set({ id: userId }, 'project_role.team_member', true);
  yield put(patchUsers([patch]));
  const { success, failure } = yield call(batchAddTeamMembers, team.id, [
    email,
  ]);

  if (!canAddTeamMember) {
    // Open the modal again if we previously went through the AddTeamSeatsFlow.
    // We need to make the fork call here in order to avoid a race condition.
    // Opening the modal fetches the updated collab data, doing it before
    // the batchAddTeamMembers call, we would have old collab data.
    yield fork(toggleModalOpen, true, { openAndScrollTo: scrollTop });
  }

  if (success) {
    yield put(
      showSuccessToast({
        header: 'Successfully added a new member to the team',
      })
    );
  }
  if (failure) {
    yield put(patchUsers([origUser]));
    yield put(
      showErrorToast({
        header: 'There was an error adding a new member to the team',
      })
    );
  }
}

export default [
  takeLatest(MANAGE_PEOPLE.TOGGLE_OPEN, ({ payload: { isOpen } }) =>
    toggleModalOpen(isOpen)
  ),
  takeLatest(
    MANAGE_PEOPLE.FETCH_USERS,
    ({ payload: { projectId, page, pageSize, sort } }) =>
      getUsersForProject(projectId, page, pageSize, sort)
  ),
  takeLatest(
    MANAGE_PEOPLE.FILTER_COLLABORATORS.BASE,
    ({ payload: { projectId, query } }) => filterCollaborators(projectId, query)
  ),
  takeLatest(
    MANAGE_PEOPLE.INVITE_USERS,
    ({ payload: { projectId, tokens, message } }) =>
      inviteToProject(projectId, tokens, message)
  ),
  takeLatest(
    MANAGE_PEOPLE.RESEND_INVITE.BASE,
    ({ payload: { projectId, email } }) => resendInvite(projectId, email)
  ),
  takeLatest(
    MANAGE_PEOPLE.REMOVE_COLLABORATOR,
    ({ payload: { collaboratorId, name } }) =>
      removeCollaborator(collaboratorId, name)
  ),
  takeLatest(
    MANAGE_PEOPLE.REMOVE_PENDING_COLLABORATOR,
    ({ payload: { pendingCollaboratorId, email } }) =>
      removePendingCollaborator(pendingCollaboratorId, email)
  ),
  takeLatest(
    MANAGE_PEOPLE.UPDATE_SHARE_LINK,
    ({ payload: { projectId, isEnabled } }) =>
      updateProjectShareLink(projectId, isEnabled)
  ),
  takeLatest(MANAGE_PEOPLE.COPY_CLIPBOARD, ({ payload: { text } }) =>
    copyToClipboard(text)
  ),
  takeLatest(MANAGE_PEOPLE.ADD_TO_TEAM, ({ payload: { userId, email } }) =>
    addToTeam(userId, email)
  ),
];

export const testExports = {
  getUsersForProject,
  inviteToProject,
  toggleModalOpen,
  removeCollaborator,
  removePendingCollaborator,
  updateProjectShareLink,
  optimisticallyUpdateProjectShareLink,
  asyncUpdateProjectShareLink,
  copyToClipboard,
  filterCollaborators,
  resendInvite,
  addTeamSeats,
  addToTeam,
};
