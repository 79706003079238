import * as Yup from 'yup';
import React from 'react';
import Media from 'react-media';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import moment from 'moment';
import { map, debounce, get, has, isEmpty, isEqual } from 'lodash';
import { padding, margin } from 'polished';
import TextInput from '@frameio/components/src/styled-components/TextInput';
import { ENTER_DURATION } from '@frameio/components/src/components/Modal';
import ShadowContainer from '@frameio/components/src/styled-components/ShadowContainer';
import { presentationColorsWithFallback } from 'components/Presentation/utils';
import { LINK_ACCESS_OPTIONS } from 'components/LinkAccess';
import addInviteOnlyAccessControl from 'utils/accessControl';
import Flex, { FlexItem } from 'styled-flex-component';
import { MEDIUM_UP } from 'utils/mediaQueries';
import { TabSelect } from '@frameio/components';
import {
  WATERMARK_STRING_CHAR_LIMIT,
  WATERMARK_STRING_ALLOWED_CHARS_REGEX,
} from 'pages/WatermarkTemplateEditor/constants';
import SessionWatermarkOptionsTab from 'components/SharePanel/SessionWatermarkOptionsTab';
import WatermarkUpsellTab from 'components/SharePanel/WatermarkUpsellTab';
import { shareTabs as Tabs } from 'components/SharePanel/constants';

import SecureSidePanelShareForm from './SecureSidePanelShareForm';
import SecureSidePanelSettingsForm from './SecureSidePanelSettingsForm';
import SecureSidePanelLayoutForm from './SecureSidePanelLayoutForm';

const StyledForm = styled(Flex).attrs({ as: 'form' })`
  background-color: ${(p) => p.theme.color.white};
  color: ${(p) => p.theme.color.gray};
  height: 100%;

  @media ${MEDIUM_UP} {
    border-right: 1px solid ${(p) => p.theme.color.silver};
  }
`;

export const StyledH2 = styled.h2`
  ${(p) => p.theme.fontStyle.heading};
  color: ${(p) => p.theme.color.coolBlack};
  line-height: 1.2;
  margin-bottom: 0;
`;

const TitleEntryContainer = styled.div`
  margin-top: ${(p) => p.theme.spacing.tiny};
  position: relative;
  right: 9px;
`;

const TitleText = styled.div`
  ${(p) => p.theme.fontStyle.body};
  color: ${(p) => p.theme.color.graphiteGray};
  padding: ${(p) => `${p.theme.spacing.micro} ${p.theme.spacing.tiny}`};
  border-radius: ${(p) => p.theme.radius.default};
  border: 1px solid ${(p) => p.theme.color.white};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    border-color: ${(p) => p.theme.color.silver};
  }
`;

export const TitleTextInput = styled(TextInput)`
  border-radius: ${(p) => p.theme.radius.default};
`;

export const StyledBox = styled.div`
  ${(p) => padding(...map(p.padding, p.theme.spacing.units))}
  ${(p) => margin(...map(p.gutter, p.theme.spacing.units))}
  ${(p) => p.fullWidth && `width: 100%;`}
  overflow-y: ${(p) => (p.verticalScroll ? 'scroll' : 'visible')};

  /* sometimes its necessary to define a value for min-height, e.g.,
  min-height: 0, in order to avoid layout problems. if none is
  provided we can safely fallback to 'auto'. */
  min-height: ${(p) => get(p, 'minHeight', 'auto')};
`;

const TabPanelContainer = styled(StyledBox)`
  box-shadow: inset 0 -1px 0 0 ${(p) => p.theme.color.coldWhite};

  button {
    color: ${(p) => p.theme.color.graphiteGray};
    margin: 0 0 ${(p) => p.theme.spacing.tiny};
    padding-right: ${(p) => p.theme.spacing.medium};

    &:last-of-type {
      padding-right: 0;
    }

    &.selected {
      color: ${(p) => p.theme.color.coolBlack};
      font-weight: ${(p) => p.theme.fontWeight.bold};
    }
  }
`;

class SecureSidePanel extends React.PureComponent {
  constructor(props) {
    super(props);

    this.tabs = [
      { key: Tabs.sharing, text: 'Sharing' },
      { key: Tabs.appearance, text: 'Layout' },
      { key: Tabs.settings, text: 'Settings' },
      {
        key: Tabs.watermark,
        text: `Watermark${props.watermarkUpsellVariant ? ' ✨' : ''}`,
      },
    ];

    this.state = {
      activeTab: props.defaultTab || Tabs.sharing,
      showMeasuredContent: false,
      showCustomWatermarkSubmit: false,
    };

    this.buttonRef = React.createRef();

    // TODO: refactor this requirement out
    // see https://github.com/Frameio/web-client/pull/7771
    this.showMeasuredContentTimeout = setTimeout(
      () => this.setState({ showMeasuredContent: true }),
      ENTER_DURATION
    );
  }

  componentWillUnmount() {
    clearTimeout(this.showMeasuredContentTimeout);
  }

  componentDidUpdate(prevProps) {
    const { presentation, setFieldValue } = this.props;

    if (
      prevProps.presentation.session_watermark_id !==
      presentation.session_watermark_id
    ) {
      setFieldValue('sessionWatermarkId', presentation.session_watermark_id);
    }
  }

  safelyPersistTitleChange = () => {
    const {
      handleSubmit,
      setFieldValue,
      values: { name, revertableName },
    } = this.props;

    if (this.hitEscape == null) {
      const newValue = revertableName.length ? revertableName : name;
      setFieldValue('editingName', false);
      setFieldValue('name', newValue);
      setFieldValue('revertableName', newValue);

      setTimeout(() => {
        handleSubmit();
        this.buttonRef.current.focus(); // don't break the esc key behavior with a submit
      }, 0);
    }
  };

  setDefaultSessionWatermarkTemplateId = (...args) =>
    this.props.setDefaultSessionWatermarkTemplateIdForTeam(
      this.props.team.id,
      ...args
    );

  onClickSetInviteOnly = (evt) => {
    const { setFieldValue } = this.props;

    evt.preventDefault();
    setFieldValue('canDownload', false);
    setFieldValue('currentMode', 'private');
  };

  onTabChange = (tabKey, event) => {
    event.preventDefault();
    this.setState({ activeTab: tabKey });
  };

  submitFormDebounced = debounce(this.props.submitForm, 200);

  onChange = (evt) => {
    const { setFieldValue } = this.props;
    const nextValue = has(evt.target, 'checked')
      ? evt.target.checked
      : evt.target.value;
    setFieldValue(evt.target.name, nextValue);
    this.submitFormDebounced();
  };

  onClickOpenSessionWatermarkTemplateEditor = (
    templateId = null,
    isShareOnlyTemplate = false
  ) => {
    const {
      legacyOpenPresentationEditor,
      openSessionWatermarkTemplateEditor,
      presentation: { id: presentationId, vanity: presentationVanityId },
      sessionWatermarkTemplates,
      updatePresentation,
    } = this.props;
    const templateCount = Object.keys(sessionWatermarkTemplates).length;
    const initialValues = {
      name: `Untitled watermark template ${templateCount + 1}`,
      presentation_id: isShareOnlyTemplate ? presentationId : null,
    };
    const onClose = (sessionWatermarkTemplateId) => {
      legacyOpenPresentationEditor(presentationVanityId, Tabs.watermark);
      if (sessionWatermarkTemplateId) {
        updatePresentation(presentationId, {
          session_watermark_id: sessionWatermarkTemplateId,
        });
      }
    };

    openSessionWatermarkTemplateEditor(
      templateId,
      onClose,
      initialValues,
      'presentation-modal',
      isShareOnlyTemplate
    );
  };

  renderWatermarkTab = () => {
    const {
      canShareUnwatermarked,
      canShareWithoutForensicWatermark,
      canSeeWatermarkingSettings,
      defaultSessionWatermarkTemplateId,
      isAdminLevel,
      isForensicWatermarkingEnabled,
      sessionWatermarkTemplates,
      watermarkUpsellVariant: variant,
    } = this.props;

    if (variant) {
      return <WatermarkUpsellTab variant={variant} />;
    }

    return (
      <SessionWatermarkOptionsTab
        defaultSessionWatermarkTemplateId={defaultSessionWatermarkTemplateId}
        isAdmin={isAdminLevel}
        canShareUnwatermarked={canShareUnwatermarked}
        canShareWithoutForensicWatermark={canShareWithoutForensicWatermark}
        canSeeWatermarkingSettings={canSeeWatermarkingSettings}
        isForensicWatermarkingEnabled={isForensicWatermarkingEnabled}
        onChange={this.onChange}
        openSessionWatermarkTemplateEditor={
          this.onClickOpenSessionWatermarkTemplateEditor
        }
        sessionWatermarkTemplates={sessionWatermarkTemplates}
        setDefaultSessionWatermarkTemplateId={
          this.setDefaultSessionWatermarkTemplateId
        }
      />
    );
  };

  render() {
    const {
      accountId,
      assetCount,
      canCustomizeEmailMessages,
      canDisableShareDownloads,
      canEnableDownloads,
      canShareWithoutDrm,
      canUseCustomBrandedPresentations,
      canUseReelPlayer,
      canUseShareLinkExpiration,
      canUsePasswordProtectedShares,
      canSetDefaultTeamSessionWatermarkId,
      canSharePublicly,
      copyShortUrlToClipboard,
      defaultSessionWatermarkTemplateId,
      handleChange,
      handleSubmit,
      isAdminLevel,
      isDrmSettingsEnabled,
      isEnterprise,
      inviteUsersToPresentation,
      isSessionBasedWatermarkingEnabled,
      plan,
      presentation: { id: presentationId, vanity: presentationVanityId },
      secureSharingEnabled,
      sessionWatermarkTemplates,
      setFieldValue,
      values: { currentMode, editingName, name, revertableName },
      upgradePlan,
      totalInviteCount,
    } = this.props;
    const {
      activeTab,
      showCustomWatermarkSubmit,
      showMeasuredContent,
    } = this.state;

    /*
    RNC-1471
    What on earth is going on here? We need to support a few different use cases when the user is
    editing text.
      - clicking outside the field should be interpreted as "confirming" the text, meaning we need
        to save it on blur. Thus we have to have support for an 'onBlur' action
      - hitting escape in the field should cancel out the text and run the cleanup code. This means
        we need to run that behavior and override the onBlur code, which will still be called.
        We track whether or not the user actively hit escape as a way to determine if the onBlur
        code needs to run.
      - We cannot run this check in the `onBlur` prop directly, because the event passed to onBlur
        is not the same event as the keyboard press event.
    */
    this.hitEscape = null;

    return (
      <Media query={MEDIUM_UP}>
        {(isMediumUp) => (
          <StyledForm onSubmit={handleSubmit} column>
            <StyledBox padding={isMediumUp ? [3, 2, 0, 3] : [2, 2, 0, 2]}>
              <StyledH2>
                Share {assetCount} {assetCount === 1 ? 'item' : 'items'}
              </StyledH2>
              <TitleEntryContainer>
                {editingName ? (
                  <TitleTextInput
                    autoFocus
                    compact
                    name="revertableName"
                    value={revertableName}
                    onChange={(evt) => {
                      handleChange(evt);
                    }}
                    onKeyDown={(evt) => {
                      if (evt.key === 'Enter' || evt.key === 'Tab') {
                        this.safelyPersistTitleChange();
                      } else if (evt.key === 'Escape' && editingName) {
                        // If someone is editing, revert the changes and stop editing. Then force
                        // focus on the button to "reset" the escape button so that presses correctly
                        // close the modal
                        evt.stopPropagation();
                        this.hitEscape = true;
                        setFieldValue('revertableName', name);
                        setFieldValue('editingName', false);
                        this.buttonRef.current.focus();
                      }
                    }}
                    onFocus={(evt) => evt.target.select()}
                    onBlur={this.safelyPersistTitleChange}
                  />
                ) : (
                  <TitleText onClick={() => setFieldValue('editingName', true)}>
                    {name}
                  </TitleText>
                )}
              </TitleEntryContainer>
            </StyledBox>
            <StyledBox as={Flex} minHeight={0} column full>
              <TabPanelContainer
                as={Flex}
                forwardedAs={FlexItem}
                noShrink
                padding={isMediumUp ? [1.5, 2, 0, 3] : [2, 2, 0, 2]}
              >
                {showMeasuredContent && (
                  <TabSelect
                    onTabChange={this.onTabChange}
                    selectedTab={activeTab}
                    tabs={this.tabs}
                  />
                )}
              </TabPanelContainer>

              {activeTab === Tabs.sharing && (
                <StyledBox
                  as={Flex}
                  forwardedAs={ShadowContainer}
                  full
                  minHeight={0}
                >
                  {({ onScroll: shadowCallback }) => (
                    <StyledBox
                      fullWidth
                      verticalScroll
                      onScroll={shadowCallback}
                      padding={isMediumUp ? [0, 3, 3, 3] : [0, 2, 2, 2]}
                    >
                      <SecureSidePanelShareForm
                        accountId={accountId}
                        canCustomizeEmailMessages={canCustomizeEmailMessages}
                        canSharePublicly={canSharePublicly}
                        copyShortUrlToClipboard={copyShortUrlToClipboard}
                        secureSharingEnabled={secureSharingEnabled}
                        isEnterprise={isEnterprise}
                        inviteUsersToPresentation={inviteUsersToPresentation}
                        onChange={this.onChange}
                        presentationId={presentationId}
                        vanityId={presentationVanityId}
                        totalInviteCount={totalInviteCount}
                      />
                    </StyledBox>
                  )}
                </StyledBox>
              )}
              {activeTab === Tabs.appearance && (
                <StyledBox
                  as={Flex}
                  forwardedAs={ShadowContainer}
                  minHeight={0}
                  full
                >
                  {({ onScroll: shadowCallback }) => (
                    <StyledBox
                      fullWidth
                      onScroll={shadowCallback}
                      padding={isMediumUp ? [1.5, 3, 3, 3] : [1, 2, 2, 2]}
                      verticalScroll
                    >
                      <SecureSidePanelLayoutForm
                        onChange={this.onChange}
                        canUseCustomBrandedPresentations={
                          canUseCustomBrandedPresentations
                        }
                        canUseReelPlayer={canUseReelPlayer}
                        isAdmin={isAdminLevel}
                      />
                    </StyledBox>
                  )}
                </StyledBox>
              )}
              {activeTab === Tabs.settings && (
                <StyledBox
                  as={Flex}
                  minHeight={0}
                  forwardedAs={ShadowContainer}
                  full
                >
                  {({ onScroll: shadowCallback }) => (
                    <StyledBox
                      verticalScroll
                      fullWidth
                      padding={isMediumUp ? [0, 3, 3, 3] : [0, 2, 2, 2]}
                      onScroll={shadowCallback}
                    >
                      <SecureSidePanelSettingsForm
                        plan={plan}
                        canDisableShareDownloads={canDisableShareDownloads}
                        canEnableDownloads={canEnableDownloads}
                        canShareWithoutDrm={canShareWithoutDrm}
                        canUseShareLinkExpiration={canUseShareLinkExpiration}
                        canUsePasswordProtectedShares={
                          canUsePasswordProtectedShares
                        }
                        canSetDefaultTeamSessionWatermarkId={
                          canSetDefaultTeamSessionWatermarkId
                        }
                        defaultSessionWatermarkTemplateId={
                          defaultSessionWatermarkTemplateId
                        }
                        isAdmin={isAdminLevel}
                        isDrmSettingsEnabled={isDrmSettingsEnabled}
                        isLinkWatermarkable={currentMode === 'private'}
                        isSessionBasedWatermarkingEnabled={
                          isSessionBasedWatermarkingEnabled
                        }
                        onChange={this.onChange}
                        onClickSetInviteOnly={this.onClickSetInviteOnly}
                        openSessionWatermarkTemplateEditor={
                          this.onClickOpenSessionWatermarkTemplateEditor
                        }
                        presentationId={presentationId}
                        sessionWatermarkTemplates={sessionWatermarkTemplates}
                        setDefaultSessionWatermarkTemplateId={
                          this.setDefaultSessionWatermarkTemplateId
                        }
                        // we store this value in state instead of in formik because we do not want
                        // the field to be over-written when Formik is reinitialized
                        showCustomWatermarkSubmit={showCustomWatermarkSubmit}
                        setCustomWatermarkSubmit={() =>
                          this.setState({ showCustomWatermarkSubmit: true })
                        }
                        upgradePlan={upgradePlan}
                      />
                    </StyledBox>
                  )}
                </StyledBox>
              )}
              {activeTab === Tabs.watermark && this.renderWatermarkTab()}
            </StyledBox>
          </StyledForm>
        )}
      </Media>
    );
  }
}

SecureSidePanel.propTypes = {
  // Props from component
  accountId: PropTypes.string.isRequired,
  assetCount: PropTypes.number.isRequired,
  canCustomizeEmailMessages: PropTypes.bool.isRequired,
  canEnableDownloads: PropTypes.bool.isRequired,
  canSetDefaultTeamSessionWatermarkId: PropTypes.bool,
  canSharePublicly: PropTypes.bool.isRequired,
  canShareUnwatermarked: PropTypes.bool.isRequired,
  isAdminLevel: PropTypes.bool.isRequired,
  isDrmSettingsEnabled: PropTypes.bool,
  isSessionBasedWatermarkingEnabled: PropTypes.bool,
  totalInviteCount: PropTypes.number,

  // Props from withFormik
  copyShortUrlToClipboard: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  inviteUsersToPresentation: PropTypes.func.isRequired,
  secureSharingEnabled: PropTypes.bool.isRequired,
  presentation: PropTypes.shape({
    access_control: PropTypes.shape({ invite_only: PropTypes.bool.isRequired })
      .isRequired,
    assets: PropTypes.arrayOf(PropTypes.string).isRequired,
    can_download: PropTypes.bool.isRequired,
    disable_drm: PropTypes.bool,
    enabled: PropTypes.bool.isRequired,
    expires_at: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    password: PropTypes.string,
    project_id: PropTypes.string.isRequired,
    short_url: PropTypes.string.isRequired,
  }).isRequired,
  presentationTitle: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

SecureSidePanel.defaultProps = {
  presentation: {},
};

const withFormikConfig = {
  mapPropsToValues: (props) => {
    const {
      canEnableDownloads,
      isDrmSettingsEnabled,
      presentation,
      presentation: {
        access_control: { invite_only: isPrivate },
        assets,
        autoplay,
        can_download: allowDownloads,
        disable_drm: disableDrm,
        enabled,
        expires_at: expiresAt,
        id,
        include_ext: showFileExtension,
        include_upload_date: showUploadDate,
        layout,
        password,
        project_id: projectId,
        require_forensic_watermark: requireForensicWatermark,
        session_watermark_id: sessionWatermarkId,
        short_url: shortUrl,
        watermark_user_input: watermarkUserInput,
      },
      presentationTitle,
      team,
      secureSharingEnabled,
    } = props;

    // TODO(Scott): these values are also calculated in Presentation. We could
    // hoist the values up to SecurePresentationEditor and pass them down once
    const {
      accent: color,
      background: backgroundColor,
      text: textColor,
    } = presentationColorsWithFallback(presentation, team);

    return {
      allowDownloads,
      assets,
      autoplay,
      backgroundColor,
      canEnableDownloads,
      color,
      currentMode:
        (enabled &&
          isPrivate &&
          secureSharingEnabled &&
          LINK_ACCESS_OPTIONS.PRIVATE) ||
        (enabled && LINK_ACCESS_OPTIONS.PUBLIC) ||
        LINK_ACCESS_OPTIONS.DISABLED,
      disableDrm,
      editingName: false,
      emailMessage: '',
      expiresAt,
      id,
      isDrmSettingsEnabled,
      isExpired:
        Boolean(expiresAt) &&
        moment(expiresAt)
          .utc()
          .isBefore(+moment().utc()),
      layout,
      name: presentationTitle,
      password: password || '',
      projectId,
      revertableName: presentationTitle,
      requireForensicWatermark,
      sessionWatermarkId: sessionWatermarkId || '',
      shortUrl,
      showEmailMessage: false,
      showFileExtension,
      showLinkExpiration: Boolean(expiresAt),
      showPassword: Boolean(password),
      showUploadDate,
      textColor,
      watermarkUserInput: watermarkUserInput || '',
      userTokens: [],
    };
  },
  handleSubmit: (values, formikBag) => {
    const {
      assets,
      autoplay,
      backgroundColor,
      allowDownloads,
      color,
      currentMode,
      disableDrm,
      expiresAt,
      id,
      layout,
      name,
      password,
      projectId,
      sessionWatermarkId,
      requireForensicWatermark,
      shortUrl,
      showFileExtension,
      showLinkExpiration,
      showPassword,
      showUploadDate,
      textColor,
      watermarkUserInput,
    } = values;
    const {
      props: { presentation, updatePresentation },
    } = formikBag;

    const nextPresentation = {
      access_control: addInviteOnlyAccessControl(
        currentMode,
        presentation.access_control
      ),
      assets,
      autoplay,
      background_color: backgroundColor,
      can_download: allowDownloads,
      color,
      disable_drm: disableDrm,
      enabled: currentMode !== LINK_ACCESS_OPTIONS.DISABLED,
      expires_at: showLinkExpiration ? expiresAt : null,
      id,
      include_ext: showFileExtension,
      include_upload_date: showUploadDate,
      layout,
      name,
      password: showPassword ? password : null,
      project_id: projectId,
      require_forensic_watermark: requireForensicWatermark,
      session_watermark_id: sessionWatermarkId,
      short_url: shortUrl,
      text_color: textColor,
      title: name,
      watermark_user_input: watermarkUserInput,
    };

    const getChanges = (a, b) =>
      Object.keys(b)
        .filter((k) => !isEqual(get(nextPresentation, k), get(presentation, k)))
        // ENT-2981: an existing null value must not be changed to empty string
        .filter(
          (k) => !(nextPresentation[k] === '' && presentation[k] === null)
        )
        .reduce((acc, k) => {
          acc[k] = nextPresentation[k];
          return acc;
        }, {});

    const presentationChanges = getChanges(presentation, nextPresentation);

    if (!isEmpty(presentationChanges)) {
      updatePresentation(presentation.id, presentationChanges);
    }
  },
  validationSchema: Yup.object().shape({
    emailMessage: Yup.string().max(2500),
    name: Yup.string().required('Title should not be blank'),
    watermarkUserInput: Yup.string()
      .max(
        WATERMARK_STRING_CHAR_LIMIT,
        ({ max }) => `Your text must be within ${max} characters.`
      )
      .test(
        'no-disallowed-characters',
        'Your text includes invalid characters',
        (value) => WATERMARK_STRING_ALLOWED_CHARS_REGEX.test(value)
      ),
  }),
};

export default withFormik(withFormikConfig)(SecureSidePanel);
