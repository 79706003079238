import { connect } from 'react-redux';
import { isArray } from 'lodash';
import { adobeIntegrationModalLoadingFetchStatusSelector } from '@frameio/core/src/adobeIntegrations/selectors';
import { currentProjectEntitySelector } from 'selectors/projects/index';
import { selectedAemDestinationSelector } from './Aem/selectors';
import { selectAemDestination } from './Aem/actions';
import { closeModal, submit } from './actions';
import AdobeIntegrationConnectDialog from './AdobeIntegrationConnectDialog';

/**
 * Maps state to props for the AdobeIntegrationConnectDialog Component.
 * @param {Object} state - The state object.
 * @returns {Object} An object with state mapped as props
 */
const mapStateToProps = (state) => {
  const project = currentProjectEntitySelector(state);

  const { workfront_assigned_tasks, workfront_tasks } = project || {};

  const workfrontTasksArray = isArray(workfront_tasks) ? workfront_tasks : [];

  const selectedAemDestination = selectedAemDestinationSelector(state);

  return {
    ...state.adobeIntegrationConnectDialog,
    isLoading: adobeIntegrationModalLoadingFetchStatusSelector(state),
    project,
    selectedAemDestination,
    workfrontAssignedTasks: workfront_assigned_tasks,
    workfrontTasks: workfrontTasksArray,
  };
};

const mapDispatchToProps = {
  closeModal,
  selectAemDestination,
  submit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdobeIntegrationConnectDialog);
