/* eslint-disable import/prefer-default-export */

import { createSelector } from 'reselect';
import { map, pick, filter, partialRight, get, flow } from 'lodash';
import { teamsSessionWatermarkTemplateEntitiesSelector } from 'selectors/teams';
import { sessionWatermarkTemplateEntitiesSelector } from '@frameio/core/src/sessionWatermarkTemplate/selectors';

export const persistedSessionWatermarkTemplatesSelector = createSelector(
  teamsSessionWatermarkTemplateEntitiesSelector,
  (templates) => {
    return flow([
      partialRight(filter, ['deleted_at', null]),
      partialRight(
        map,
        partialRight(pick, [
          'id',
          'name',
          'creator_id',
          'inserted_at',
          'watermark_blocks',
          'original_session_watermark_template_id',
          'review_link_id',
          'presentation_id',
        ])
      ),
    ])(templates);
  }
);

const sessionWatermarkTemplateEntitiesForPresentationSelector = createSelector(
  (_state, { presentationId }) => presentationId,
  sessionWatermarkTemplateEntitiesSelector,
  (presentationId, sessionWatermarkTemplateEntities) =>
    filter(sessionWatermarkTemplateEntities, [
      'presentation_id',
      presentationId,
    ])
);

export const mergedSessionWatermarkTemplateEntitiesForTeamAndPresentation = createSelector(
  sessionWatermarkTemplateEntitiesForPresentationSelector,
  persistedSessionWatermarkTemplatesSelector,
  (templatesForPresentation, templatesForTeam) => {
    // In order to appropriately sort templates for presentations that replace
    // "parent" templates at the account level, we need to persist some knowledge
    // of the original template's `inserted_at` value.
    const transformedPresentationTemplates = templatesForPresentation.map(
      (templateEntityForPresentation) => {
        // For each presentation-only template, find an account level template with an id
        // that matches the given `original_session_watermark_template_id`
        const { inserted_at } =
          templatesForTeam.find(
            ({ id }) =>
              templateEntityForPresentation.original_session_watermark_template_id ===
              id
          ) || {};

        return {
          ...templateEntityForPresentation,
          original_inserted_at: inserted_at,
        };
      }
    );

    const originalTemplateIds = map(
      transformedPresentationTemplates,
      'original_session_watermark_template_id'
    );
    // Generate a subset of templatesForTeam of templates with any matching "parent"
    // templates removed
    return [
      ...templatesForTeam.filter(({ id }) => !originalTemplateIds.includes(id)),
      ...transformedPresentationTemplates,
    ].sort((a, b) =>
      get(a, 'original_inserted_at', a.inserted_at) >
      get(b, 'original_inserted_at', b.inserted_at)
        ? -1
        : 1
    );
  }
);
