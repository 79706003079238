import React from 'react';
import PropTypes from 'prop-types';
import hideVisually from '@frameio/components/src/mixins/hideVisually';
import styled from 'styled-components';

export const Checkbox = styled.input.attrs(() => ({
  type: 'checkbox',
}))`
  ${hideVisually()};
`;

export const Label = styled.label`
  display: flex;

  &:hover {
    cursor: pointer;
  }
`;

/**
 * Hidden Checkbox Container Component.
 * @param {Object} props - Props passed by parent.
 * @returns {ReactComponent} - React Component.
 */
const Toggle = ({ isOn, children, className, ...rest }) => (
  <Label className={className}>
    <Checkbox checked={isOn} {...rest} readOnly />
    {children}
  </Label>
);

Toggle.defaultProps = {
  isOn: false,
  children: null,
  className: null,
};

Toggle.propTypes = {
  isOn: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

// TODO(Christi): move into a separate directory
export default styled(Toggle)``;
