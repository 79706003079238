import { createSelector } from 'reselect';
import { keyBy, some } from 'lodash';
import {
  assetEntitiesSelector,
  assetEntitySelector,
  hydratedAssetEntitiesByAssetIdsSelector,
  hydratedChildAssetsSelector,
} from '@frameio/core/src/assets/selectors';
import { type as assetType } from '@frameio/core/src/assets/helpers/constants';
import { reviewLinkEntitySelector } from '@frameio/core/src/reviewLinks/selectors';
import { reviewLinkItemsEntitiesForReviewLinkSelector } from '@frameio/core/src/reviewLinkItems/selectors';
import { reviewerEntitiesSelector } from '@frameio/core/src/reviewers/selectors';
import { currentUserSelector } from 'selectors/users';

export const BREADCRUMB_TYPES = {
  FOLDER: 'folder',
  REVIEW_LINK: 'review_link',
};

/**
 * Returns the loading status of the reviewLink page
 * @param {Object} state
 */
export const reviewLinkFetchingStatusSelector = (state) =>
  state.reviewLinkContainer.isFetching;

/**
 * Derive if a user is assigned as a reviewer for a review link by
 * checking if any review entity's match a given userId and reviewLinkId
 * @param {Object} state
 * @param {Object} props
 * @param {string} props.userId
 * @param {string} props.reviewLinkId
 * @return {boolean}
 */
export const isUserReviewerForReviewLinkSelector = createSelector(
  reviewerEntitiesSelector,
  (state, props) => props,
  (reviewerEntities, { userId, reviewLinkId }) =>
    some(
      reviewerEntities,
      (reviewerEntity) =>
        reviewerEntity.user_id === userId &&
        reviewerEntity.review_link_id === reviewLinkId
    )
);

export const isCurrentUserReviewerForReviewLinkSelector = (
  state,
  { reviewLinkId }
) => {
  const { id: userId } = currentUserSelector(state);
  return isUserReviewerForReviewLinkSelector(state, { userId, reviewLinkId });
};

/**
 * Returns the failure reason for the review link page
 * @param {Object} state
 * @returns {string} failureReason
 */
export const reviewLinkFailureReasonSelector = (state) =>
  state.reviewLinkContainer.failureReason;

/**
 * Returns the a true/false value only for 401 responses (not logged in) for the review link page
 * @param {Object} state
 * @returns {number} count
 */
export const reviewLinkHasBundleSelector = (state) =>
  state.reviewLinkContainer.hasBundle;

export const reviewLinkShowNameInputSelector = (state) =>
  state.reviewLinkContainer.showNameInput;

/**
 * Returns the selected page number for the review link page
 * @param {Object} state
 * @returns {number} selectedPageNumber
 */
export const reviewLinkPageNumberSelector = (state) =>
  state.reviewLinkContainer.selectedPageNumber;

/**
 * Returns a hydrated list of review link's assets for the
 * given `reviewLinkId`.
 * @param {Object} state - redux state tree
 * @param {Object} props - outside props, must include `reviewLinkId`
 */
export const reviewLinkAssetsSelector = createSelector(
  reviewLinkItemsEntitiesForReviewLinkSelector,
  (state) => state,
  (reviewLinkItems, state) => {
    const assetIds = Object.values(reviewLinkItems).map(
      (item) => item.asset_id
    );
    return hydratedAssetEntitiesByAssetIdsSelector(state, {
      assetIds,
    });
  }
);

/**
 * Returns an array of hydrated review link assetIds for the
 * given `reviewLinkId`.
 * @param {string} reviewLinkId - Review link id.
 * @returns {?string[]} - Array review link asset ids.
 */
export const reviewLinkAssetIdsSelector = createSelector(
  reviewLinkAssetsSelector,
  (reviewLinkAssets) => reviewLinkAssets.map((asset) => asset.id)
);

/**
 * Returns an array of review link breadcrumb path objects.
 * @param {string} assetId - Asset id for a given folder entity.
 * @param {string} reviewLinkId - reviewLinkId - Review link id.
 * @returns {?Object[]} - Array of breadcrumb data.
 */
export const reviewLinkBreadcrumbPathSelector = createSelector(
  assetEntitySelector,
  assetEntitiesSelector,
  reviewLinkEntitySelector,
  reviewLinkAssetIdsSelector,
  (folderEntity, assetEntitites, reviewLink, reviewLinkAssetIds) => {
    // Walk up folder entity parents
    const path = [];
    let currentFolder = folderEntity;

    while (currentFolder) {
      path.unshift({
        name: currentFolder.name,
        id: currentFolder.id,
        type: currentFolder.type,
      });

      if (reviewLinkAssetIds.includes(currentFolder.id)) break;
      currentFolder = assetEntitites[currentFolder.parent_id];
    }

    // Review link is always the first item
    path.unshift({
      id: reviewLink.id,
      name: reviewLink.name,
      type: BREADCRUMB_TYPES.REVIEW_LINK,
    });

    return path;
  }
);

/**
 * @param {string} reviewLinkId - Review link id.
 * @param {string} assetId - Asset id.
 * @returns {Boolean} - Whether or not an asset id is a review link item.
 */
export const isAssetIdReviewLinkItemSelector = createSelector(
  reviewLinkAssetIdsSelector,
  (state, { assetId }) => assetId,
  (reviewLinkAssetIds = [], assetId) => reviewLinkAssetIds.includes(assetId)
);

/**
 * Returns a map of review link player asset data that may either be review link
 * items or sibling _non-folder_ assets within a given folder context.
 * @param {string} reviewLinkId - Review link id.
 * @param {string} assetId - Asset id.
 * * @returns {Object} - Map of review link player asset data.
 */
export const reviewLinkPlayerAssetsSelector = createSelector(
  isAssetIdReviewLinkItemSelector,
  reviewLinkAssetsSelector,
  assetEntitySelector,
  (state) => state,
  (isAssetIdReviewLinkItem, reviewLinkAssets, assetEntity = {}, state) => {
    const { parent_id: parentId } = assetEntity;

    const assets = isAssetIdReviewLinkItem
      ? reviewLinkAssets
      : hydratedChildAssetsSelector(state, { assetId: parentId });

    // Don't return folders since they cannot be previewed in the player
    const nonFolderAssets = assets.filter(
      ({ type }) => type !== assetType.FOLDER
    );

    /*
      TODO(CORE-2552|Anna): Refactor ReviewLink.js and ReviewLinkPlayer.js to accept
      an array of assets/assetIds instead of an assets map. NOTE: This requires
      breaking changes in web-components to PlayerPageControl.js.
    */
    return keyBy(nonFolderAssets, 'id');
  }
);
