import React, { memo } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import { BASE_STYLES, stripeElementStyle } from '../../styles';

const StyledInput = styled(Field)`
  ${({ theme }) => stripeElementStyle(theme)}
  border: ${({ theme, error }) =>
    error
      ? `1px solid ${theme.color.error}`
      : `1px solid ${theme.color.slateGray}`};
  padding-left: ${({ theme }) => theme.spacing.large};
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  &::placeholder {
    color: ${({ theme }) => theme.color.slateGray};
  }
  &:focus-visible {
    outline: none;
    border-color: ${({ theme }) => theme.color.brand};
  }
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.color.brand};
  }
`;

const Input = React.forwardRef(
  ({ error, name, placeholder, required, onChange, ...rest }, ref) => {
    return (
      <StyledInput
        name={name}
        ref={ref}
        error={error ? 1 : 0}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        {...BASE_STYLES}
        {...rest}
      />
    );
  }
);

export default memo(Input);
