import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Media from 'react-media';
import { get } from 'lodash';
import { LARGE } from 'utils/mediaQueries';

import ConnectedDashboardSidebar from 'components/DashboardSidebar';
import AccountSwitcherBar from './AccountSwitcher/AccountSwitcherBar';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;

  @media ${LARGE} {
    flex-wrap: nowrap;
    flex-direction: row;
  }
`;

class LayoutWithNav extends React.Component {
  render() {
    const { children, selectedProjectId, currentAccount } = this.props;
    const currentAccountId = get(currentAccount, 'id');
    return (
      <Wrapper>
        <Media query={LARGE}>
          <AccountSwitcherBar currentAccountId={currentAccountId} />
        </Media>
        <ConnectedDashboardSidebar
          selectedProjectId={selectedProjectId}
          currentAccountId={currentAccountId}
        />
        {children}
      </Wrapper>
    );
  }
}

LayoutWithNav.propTypes = {
  children: PropTypes.node.isRequired,
  selectedProjectId: PropTypes.string,
  currentAccount: PropTypes.object,
};

LayoutWithNav.defaultProps = {
  selectedProjectId: null,
  currentAccount: null,
  currentProject: null,
};

export default LayoutWithNav;
