import { createAction } from 'redux-actions';
import { defaults } from 'lodash';
import { dialogTypes } from '@frameio/components/src/styled-components/Dialog';
import { generateActionTypes } from 'shared/actions/helpers';

const SIMPLE_DIALOG = generateActionTypes('SIMPLE_DIALOG', [
  'OPEN',
  'RESPOND',
  'CLOSE',
]);

export const openDialog = createAction(
  SIMPLE_DIALOG.OPEN,
  (header, body, options) => ({
    header,
    body,
    ...defaults(options, {
      primaryText: 'OK',
      type: dialogTypes.NEUTRAL,
      priority: 0,
      modalProps: {},
    }),
  })
);

export const openAlert = openDialog;

export function openError(header, body, options) {
  return openDialog(header, body, { ...options, type: dialogTypes.NEGATIVE });
}

export const respond = createAction(SIMPLE_DIALOG.RESPOND, (response) => ({
  response,
}));

export const closeDialog = createAction(SIMPLE_DIALOG.CLOSE, () => ({}));

export default SIMPLE_DIALOG;
