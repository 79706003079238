import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { rgba } from 'polished';

import Button from '@frameio/components/src/styled-components/Button';
import { absoluteFill } from '@frameio/components/src/mixins';
import AccountOwnerPill from 'components/AccountOwnerPill';

const Title = styled.h3`
  font-size: ${(p) => p.theme.fontSize[3]};
  margin-bottom: ${(p) => p.theme.spacing.tiny};
`;

const Copy = styled.p`
  font-size: ${(p) => p.theme.fontSize[2]};
  line-height: ${(p) => p.theme.lineHeight[2]};
  color: ${(p) => p.theme.color.gray};
`;

const AccountOwner = styled.div`
  margin: ${(p) => p.theme.spacing.medium} 0;
  ${Copy} {
    margin-bottom: ${(p) => p.theme.spacing.small};
  }
`;
const StyledAccountOwnerPill = styled(AccountOwnerPill)`
  display: inline-flex;
`;

const Footer = styled.footer`
  margin-top: ${(p) => p.theme.spacing.large};
`;

const Overlay = styled(Flex).attrs(() => ({
  center: true,
}))`
  ${absoluteFill()};
  background-color: ${({ theme }) => rgba(theme.color.white, 0.9)};
  text-align: center;
`;

const Container = styled.div`
  text-align: center;
`;

const UpgradePlan = ({ bodyCopy, onContinue }) => (
  <React.Fragment>
    <Copy>{bodyCopy}</Copy>
    <Footer>
      <Button primary onClick={onContinue}>
        Upgrade
      </Button>
    </Footer>
  </React.Fragment>
);
UpgradePlan.propTypes = {
  bodyCopy: PropTypes.node.isRequired,
  onContinue: PropTypes.func.isRequired,
};

const RequestOwnerToUpgrade = ({ accountId, bodyCopy }) => (
  <React.Fragment>
    <Copy>
      {bodyCopy}
      <br />
      Notify your account owner that you’d like this upgrade.
    </Copy>

    <AccountOwner>
      <Copy>Account owner:</Copy>
      <StyledAccountOwnerPill accountId={accountId} />
    </AccountOwner>
  </React.Fragment>
);
RequestOwnerToUpgrade.propTypes = {
  bodyCopy: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
};

export default function UpgradeToUnlockOverlay({
  accountId,
  bodyCopy,
  isAccountAdmin,
  onPlanUpgrade,
}) {
  return (
    <Overlay>
      <Container>
        <Title>Upgrade to unlock</Title>
        {isAccountAdmin ? (
          <UpgradePlan onContinue={onPlanUpgrade} bodyCopy={bodyCopy} />
        ) : (
          <RequestOwnerToUpgrade accountId={accountId} bodyCopy={bodyCopy} />
        )}
      </Container>
    </Overlay>
  );
}

export const testExports = {
  Button,
  Copy,
  RequestOwnerToUpgrade,
  UpgradePlan,
};

UpgradeToUnlockOverlay.propTypes = {
  accountId: PropTypes.string.isRequired,
  bodyCopy: PropTypes.node.isRequired,
  isAccountAdmin: PropTypes.bool.isRequired,
  onPlanUpgrade: PropTypes.func.isRequired,
};
