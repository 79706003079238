import React from 'react';
import PropTypes from 'prop-types';
import { Measured } from '@frameio/components';

class Scrubber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrubberOffset: 0,
    };
    this.scrubber = null;
  }

  onMouseMoveCalcScrubberOffset = (evt) => {
    const { left } = this.props.measured;
    return this.setState({ scrubberOffset: evt.clientX - left });
  };

  getFrameFromOffset(offset) {
    const {
      measured: { width },
      numFrames,
    } = this.props;
    return Math.round((offset / width) * numFrames);
  }

  getScrubOffsetForFrame() {
    const frame = this.getFrameFromOffset(this.state.scrubberOffset);
    return frame * this.aspectAdjustedHeight();
  }

  maxScrubOffset() {
    return (this.props.numFrames - 2) * this.aspectAdjustedHeight();
  }

  widerThanAspectRatio() {
    const {
      measured: { width, height },
      aspectWidth,
      aspectHeight,
    } = this.props;
    return width / height > aspectWidth / aspectHeight;
  }

  aspectAdjustedHeight() {
    if (this.widerThanAspectRatio()) {
      return (this.props.measured.width * 9) / 16;
    }
    return this.props.measured.height;
  }

  render() {
    const scrubOffset = Math.min(
      this.getScrubOffsetForFrame(),
      this.maxScrubOffset()
    );
    const {
      width,
      height,
      measured,
      onRef,
      scrubImage,
      numFrames,
      barWidth,
    } = this.props;
    return (
      <div
        className="scrubber-container"
        style={{
          height,
          width,
        }}
        ref={onRef}
        onMouseMove={this.onMouseMoveCalcScrubberOffset}
      >
        <div
          className="scrubber-image"
          style={{
            height: measured.height,
            width: measured.width,
          }}
        >
          <div
            className="scrubber-image-container"
            style={{
              backgroundImage: `url(${scrubImage})`,
              width: measured.width,
              height: this.aspectAdjustedHeight() * numFrames,
              transform: `translateY(${scrubOffset * -1}px)`,
            }}
          />
        </div>
        <div
          className="scrubber-bar"
          style={{
            width: barWidth,
            height: measured.height,
            left: this.state.scrubberOffset,
          }}
        />
      </div>
    );
  }
}

Scrubber.defaultProps = {
  aspectHeight: 9,
  aspectWidth: 16,
  barWidth: 1,
  height: 'auto',
  width: 'auto',
};

Scrubber.propTypes = {
  barWidth: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  measured: Measured.propType.isRequired,
  onRef: PropTypes.func.isRequired,
  scrubImage: PropTypes.string.isRequired,
  numFrames: PropTypes.number.isRequired,
  aspectHeight: PropTypes.number,
  aspectWidth: PropTypes.number,
};

export default Measured(Scrubber);
