import React, { useState } from 'react';
import track from 'analytics';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import Flex from 'styled-flex-component';
import { Tooltip } from '@frameio/vapor';
import PremiumBadge from '@frameio/components/src/styled-components/PremiumBadge';
import styled from 'styled-components';
import config from 'config';
import { openDrift } from 'utils/drift';
import { watermarkUserFlowVariants } from 'components/SharePanel/constants';
import UnlockDialog from './UnlockDialog';
import SelectPlanModal from './SelectPlanModal';
import { StyledBox, Text } from './styles';

const StyledPremiumBadge = styled(PremiumBadge)`
  cursor: pointer;
`;

const Container = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.color.coldWhite};
`;

export default function WatermarkUpsellTab({ variant }) {
  const [isUnlockDialogOpen, toggleUnlockDialog] = useState(false);
  const [isSelectPlanModalOpen, toggleSelectPlanModal] = useState(false);

  let onClickHandler = noop;

  if (variant === watermarkUserFlowVariants.nonEnterpriseUser) {
    onClickHandler = () => toggleUnlockDialog(true);
  } else if (variant === watermarkUserFlowVariants.nonEnterpriseAdmin) {
    onClickHandler = () => toggleSelectPlanModal(true);
  } else if (variant === watermarkUserFlowVariants.enterpriseWithoutSbw) {
    onClickHandler = () => {
      openDrift(config.driftEnterpriseExpansionId);
    };
  }

  const badgeText =
    variant === watermarkUserFlowVariants.enterpriseWithoutSbw
      ? 'Learn More'
      : 'Upgrade';

  return (
    <Container
      as={StyledBox}
      forwardedAs={Flex}
      justifyBetween
      alignCenter
      padding={[2, 0]}
      gutter={[0, 3]}
    >
      <Text variant="bodyM" color="coolBlack">
        Apply Watermark ID
      </Text>
      <Tooltip
        title={
          <Text textAlign="center">
            Watermark ID is an Enterprise
            <br />
            plan add-on️️ 🚀
          </Text>
        }
        details="Click here to learn more."
        variant="dark"
        shouldUsePortal
        disabled={isSelectPlanModalOpen}
      >
        <StyledPremiumBadge
          data-test-id="action-badge"
          onMouseOver={() => {
            track('feature-gate-viewed-client', { _limit: 'watermark id' });
          }}
          onClick={() => {
            onClickHandler();
            track('feature-gate-clicked-client', { _limit: 'watermark id' });
            // Hack to keep the Drift input box in focus
            setTimeout(() => {
              document.activeElement.blur();
            }, 100);
          }}
        >
          {badgeText}
        </StyledPremiumBadge>
      </Tooltip>
      <UnlockDialog
        isOpen={isUnlockDialogOpen}
        onClose={() => toggleUnlockDialog(false)}
      />
      <SelectPlanModal
        isOpen={isSelectPlanModalOpen}
        onClose={() => toggleSelectPlanModal(false)}
      />
    </Container>
  );
}

WatermarkUpsellTab.propTypes = {
  variant: PropTypes.string.isRequired,
};
