import { generateActionTypes } from 'shared/actions/helpers';
import { createAction } from 'redux-actions';

export const LIST_TEAM_ROW = generateActionTypes('LIST_TEAM_ROW', [
  'HANDLE_ADD_PROJECT',
]);

export const handleAddProject = createAction(
  LIST_TEAM_ROW.HANDLE_ADD_PROJECT,
  (teamId) => ({ teamId })
);
