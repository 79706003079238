import { useEffect } from 'react';
import { trackKeyDown } from 'analytics';
import { HOTKEY } from './constants';

/**
 * useHotkey hook is used for binding hotkeys to actions.
 *
 * usage:
 * useHotkeys({
 *   [HOTKEY.J]: () => playBackward(),
 *   [HOTKEY.K]: () => togglePlaying(),
 *   [HOTKEY.L]: () => playForward(),
 * })
 *
 * @param {Object} hotKeyDownMap - Map of hotkeys for keyDown.
 * @param {Object} hotKeyUpMap - Map of hotkeys for keyUp.
 */
const useHotKeys = (hotKeyDownMap = {}, hotKeyUpMap = {}) => {
  const getKeyForEvent = (evt) => {
    if (evt.shiftKey && evt.key !== HOTKEY.SHIFT) {
      return `${HOTKEY.SHIFT}${evt.key}`;
    }
    return evt.key;
  };

  const onKeyEvent = (keyMap) => {
    return (evt) => {
      if (evt.metaKey || evt.ctrlKey) return;
      const key = getKeyForEvent(evt);
      if (!keyMap[key]) return;
      evt.preventDefault();
      keyMap[key](evt);
      trackKeyDown(evt);
    };
  };

  const onKeyDown = onKeyEvent(hotKeyDownMap);
  const onKeyUp = onKeyEvent(hotKeyUpMap);

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    };
  });
};

export default useHotKeys;
