import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';

import { MenuButton } from '@frameio/vapor';
import DownloadIcon from '@frameio/components/src/svgs/icons/download.svg';
import { trackButtonClick } from 'analytics';
import { confirmDownloadAllAssets } from 'actions/assets';

function mapStateToProps(state, { projectId, folderId }) {
  const { root_asset_id: rootAssetId } =
    projectEntitySelector(state, { projectId }) || {};
  return {
    isRoot: folderId === rootAssetId,
  };
}

const mapDispatchToProps = {
  onDownloadAll: confirmDownloadAllAssets,
};

function DownloadAllItem({
  projectId,
  isRoot,
  onDownloadAll,
  folderId,
  trackingPage,
  trackingPosition,
  ...rest
}) {
  return (
    <MenuButton
      data-testid="project-context-download"
      onSelect={() => {
        onDownloadAll(projectId, folderId, isRoot);
        trackButtonClick('download-all', trackingPage, trackingPosition);
      }}
      iconBefore={<DownloadIcon />}
      {...rest}
    >
      Download all
    </MenuButton>
  );
}

DownloadAllItem.defaultProps = {
  isRoot: false,
  onDownloadAll: () => {},
};

DownloadAllItem.propTypes = {
  projectId: PropTypes.string.isRequired,
  folderId: PropTypes.string.isRequired,
  isRoot: PropTypes.bool,
  onDownloadAll: PropTypes.func,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadAllItem);
