import {
  FETCH_ACTION_TYPES,
  combineActionTypes,
} from '@frameio/core/src/shared/actions/helpers';

export const FETCH_ACTION_TYPES_WITH_RESET = {
  ...FETCH_ACTION_TYPES,
  RESET: 'RESET',
};

/**
 * Generate an action type namespaced to web-client.
 * @param {string} module - The module, like DASHBOARD or MODAL.
 * @param {string} action - The action name, usually a verb.
 * @returns {string} - Redux action type, like "FIOClient/USER/SET_CURRENT".
 */
export function generateActionType(module, action) {
  return `FIOClient/${module}/${action}`;
}

/**
 * Generates action types.
 * @example
 * `generateActionTypes('MODAL', ['OPEN', 'CLOSE'])` returns:
 *
 * ```
 * {
 *   OPEN: 'FIOClient/MODAL/OPEN',
 *   CLOSE: 'FIOClient/MODAL/CLOSE',
 * }
 * ```
 *
 * @param {string} module - The module, like DASHBOARD or MODAL.
 * @param {Array} actions - The action names, usually verbs.
 * @returns {Object} - An object that generates an action for each actionType.
 */
export function generateActionTypes(module, actions) {
  return combineActionTypes(module, actions, generateActionType);
}

/**
 * For a fetch action, generate its associated module, PENDING, SUCCESS and FAILURE action types.
 * @example
 * `generateFetchActionType('PROJECT_CONTAINER', 'CREATE', true)` returns:
 *
 * ```
 * {
 *   CREATE: {
 *     BASE: 'FIOClient/PROJECT_CONTAINER/CREATE_BASE',
 *     PENDING: 'FIOClient/PROJECT_CONTAINER/CREATE_PENDING',
 *     SUCCESS: 'FIOClient/PROJECT_CONTAINER/CREATE_SUCCESS',
 *     FAILURE: 'FIOClient/PROJECT_CONTAINER/CREATE_FAILURE',
 *     RESET: 'FIOClient/PROJECT_CONTAINER/CREATE_RESET',
 *   },
 * }
 * ```
 * @param {string} module - The module, like PROJECT_CONTAINER or REVIEW_LINK_EDITOR.
 * @param {string} name - The action name, usually a verb.
 * @param {boolean} withReset - Whether to include a RESET type or not.
 */
export function generateFetchActionType(module, name, withReset) {
  return {
    [name]: combineActionTypes(
      module,
      Object.keys(
        withReset ? FETCH_ACTION_TYPES_WITH_RESET : FETCH_ACTION_TYPES
      ),
      (innerModule, action) => generateActionType(module, `${name}_${action}`)
    ),
  };
}

/**
 * Generate multiple fetch actions.
 * @param {string} module - The module, like PROJECT or ASSETS.
 * @param {Array} actions - The action names, usually verbs.
 * @param {boolean} withReset - Whether to include a RESET type or not.
 * @returns {Object} - A map of action => actionType(s).
 */
export function generateFetchActionTypes(module, actions, withReset) {
  return combineActionTypes(module, actions, (innerModule, name) =>
    generateFetchActionType(innerModule, name, withReset)
  );
}
