import { createSelector } from 'reselect';
import { some, pick } from 'lodash';
import { isProduction } from 'config';
import { currentUserEntitySelector } from 'selectors/users';

/*
  Check that the user has a role of either `admin`, `support` or `sales`.

  Note: these roles are internal roles and are seperate from the concept of
  team member, admin and user roles that we use elsewhere.
*/
export function hasElevatedRole(currentUser) {
  const rolesToCheck = pick(currentUser.roles, ['admin', 'support', 'sales']);
  return some(rolesToCheck, (hasRole) => hasRole);
}

export const canSeeDevToolsSelector = createSelector(
  [currentUserEntitySelector],
  (currentUser) =>
    !isProduction || (currentUser && hasElevatedRole(currentUser))
);
