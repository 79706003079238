import { connect } from 'react-redux';
import { presentationEntitySelector } from '@frameio/core/src/presentations/selectors';
import { getPublicPresentationUrl } from 'URLs';
import { legacyOpenPresentationEditor } from 'components/PresentationEditor/actions';
import { presentationIdForContextMenuSelector } from '../selectors';
import {
  confirmDeletePresentation,
  copyShortUrlToClipboard,
  patchToggleIsActive,
} from '../actions';
import ProjectPresentationsContextMenu from './ProjectPresentationsContextMenu';

const mapStateToProps = (state) => {
  const presentationId = presentationIdForContextMenuSelector(state);
  const presentationEntity = presentationEntitySelector(state, {
    presentationId,
  });
  const presentationUrl = getPublicPresentationUrl(
    presentationEntity.vanity,
    presentationEntity.short_url
  );

  return {
    presentationId,
    primaryAssetId: presentationEntity.asset_id,
    isActive: presentationEntity.enabled,
    presentationUrl,
  };
};

const mapDispatchToProps = {
  confirmDeletePresentation,
  copyShortUrlToClipboard,
  legacyOpenPresentationEditor,
  patchToggleIsActive,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectPresentationsContextMenu);

export const testExports = { mapStateToProps, mapDispatchToProps };
