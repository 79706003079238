import { connect } from 'react-redux';
import CopyAssetsToFolder from './CopyAssetsToFolder';
import { promptCopyToFolder } from './actions';
import { moveAssetMenuItemSelector } from './selectors';

function mapStateToProps(state, props) {
  const { folderId } = props;
  const { folder, projectId } =
    moveAssetMenuItemSelector(state, {
      assetId: folderId,
    }) || {};

  return {
    projectId,
    folderParentId: folder && folder.parent_id,
  };
}

const mapDispatchToProps = {
  promptCopyToFolder,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyAssetsToFolder);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
