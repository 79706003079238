import React, { Fragment, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { Tooltip } from '@frameio/vapor';
import InfoIcon from '@frameio/components/src/svgs/icons/24/info-filled.svg';
import Password from '@frameio/components/src/styled-components/Password';
import DatePicker from '@frameio/components/src/styled-components/DatePicker';
import TextInput from '@frameio/components/src/styled-components/TextInput';
import { allFeatures } from 'planFeatures';
import { watermarkDownloadsEnabled } from 'utils/featureFlags';
import track from 'analytics';
import SharePanelSettingsSwitchRow, {
  renderLabelWithStatus,
  upgradeToChange,
} from '../../SharePanel/SharePanelSettingsSwitchRow';

const DisabledBox = styled.div`
  color: ${(p) => p.theme.color.coldWhite};
`;

const DisabledMessage = styled.p`
  color: ${(p) => p.theme.color.slateGray};
`;

const StyledSpan = styled.span`
  display: inline-flex;
  margin-top: -2px;
  margin-left: ${(p) => p.theme.spacing.tiny};
  color: ${(p) => p.theme.color.slateGray};
`;

const TopPadding = styled.div`
  height: ${(p) => p.theme.spacing.medium};
`;

const SecureSidePanelSettingsForm = ({
  canDisableShareDownloads,
  canEnableDownloads,
  canShareWithoutDrm,
  canUseShareLinkExpiration,
  canUsePasswordProtectedShares,
  formik: {
    setFieldValue,
    values: {
      allowDownloads,
      disableDrm,
      expiresAt,
      password,
      sessionWatermarkId,
      showLinkExpiration,
      showPassword,
    },
  },
  isAdmin,
  isDrmSettingsEnabled,
  onChange,
  plan,
  presentationId,
  upgradePlan,
}) => {
  const isWatermarkDownloadsEnabled = useSelector(watermarkDownloadsEnabled);
  const maybeStartUpgradePlanFlow = useCallback(
    (feature, source) => {
      if (isAdmin) {
        track('feature-gate-clicked-client', { _limit: feature });
        upgradePlan(presentationId, feature, source);
      }
    },
    [isAdmin, presentationId, upgradePlan]
  );
  const downloadsToggle = isWatermarkDownloadsEnabled
    ? {
        disabled: !canEnableDownloads,
        disabledTooltipMessage:
          'Only account admins can change download permissions',
      }
    : {
        disabled: Boolean(sessionWatermarkId),
        disabledTooltipMessage:
          'Downloading is disabled when Watermark ID is on',
      };

  useEffect(() => {
    if (!showLinkExpiration) {
      setFieldValue('expiresAt', null);
    }
  }, [showLinkExpiration, setFieldValue]);
  useEffect(() => {
    if (!showPassword) {
      setFieldValue('password', null);
    }
  }, [showPassword, setFieldValue]);

  const planVersion = plan?.version;
  const isPlanVersion8 = planVersion === 8;

  return (
    <Fragment>
      <TopPadding />
      {(canEnableDownloads || allowDownloads) && (
        <SharePanelSettingsSwitchRow
          checked={allowDownloads}
          label={renderLabelWithStatus(
            'Allow downloads',
            allowDownloads,
            !canDisableShareDownloads
          )}
          name="allowDownloads"
          onToggle={
            canDisableShareDownloads
              ? onChange
              : () =>
                  maybeStartUpgradePlanFlow(allFeatures.disableShareDownloads)
          }
          isAdmin={isAdmin}
          featureLocked={
            !canDisableShareDownloads && allFeatures.disableShareDownloads
          }
          featureGateLabel={upgradeToChange}
          {...downloadsToggle}
        />
      )}
      {isDrmSettingsEnabled && (
        <SharePanelSettingsSwitchRow
          label={
            <Flex>
              Apply DRM
              <Tooltip
                shouldUsePortal
                placement="top"
                title={
                  <Fragment>
                    Apply Digital Rights Management content protection for
                    proxies being shared.
                  </Fragment>
                }
                variant="dark"
              >
                <StyledSpan>
                  <InfoIcon width={24} height={24} />
                </StyledSpan>
              </Tooltip>
            </Flex>
          }
          checked={canShareWithoutDrm ? !disableDrm : true}
          disabled={!canShareWithoutDrm && isDrmSettingsEnabled}
          disabledTooltipMessage={
            <DisabledBox>
              You don't have permission to
              <br /> perform this action
              <DisabledMessage>Please contact your admin.</DisabledMessage>
            </DisabledBox>
          }
          name="disableDrm"
          onToggle={(evt) => {
            onChange({
              target: {
                name: 'disableDrm',
                value: !evt.target.checked,
              },
            });
          }}
        />
      )}
      <SharePanelSettingsSwitchRow
        checked={showPassword}
        label={renderLabelWithStatus(
          'Passphrase protect',
          showPassword,
          !canUsePasswordProtectedShares
        )}
        name="showPassword"
        onToggle={
          canUsePasswordProtectedShares
            ? onChange
            : () =>
                maybeStartUpgradePlanFlow(
                  allFeatures.passwordProtectedShares,
                  'pw-protected shares feature gate'
                )
        }
        isAdmin={isAdmin}
        featureLocked={
          !canUsePasswordProtectedShares && allFeatures.passwordProtectedShares
        }
        featureGateLabel={upgradeToChange}
      >
        {showPassword && (
          <Password
            autoComplete="new-password"
            name="password"
            value={password}
            onChange={onChange}
          />
        )}
      </SharePanelSettingsSwitchRow>
      <SharePanelSettingsSwitchRow
        checked={showLinkExpiration}
        label={renderLabelWithStatus(
          'Link expiration',
          showLinkExpiration,
          !canUseShareLinkExpiration
        )}
        name="showLinkExpiration"
        onToggle={
          canUseShareLinkExpiration
            ? onChange
            : () =>
                maybeStartUpgradePlanFlow(
                  allFeatures.shareLinkExpiration,
                  'share link expiration feature gate'
                )
        }
        isAdmin={isAdmin}
        featureLocked={
          !canUseShareLinkExpiration && allFeatures.shareLinkExpiration
        }
        featureGateLabel={upgradeToChange}
        requiresUpgradeToPro={isPlanVersion8}
      >
        <DatePicker
          name="expiresAt"
          onChange={(isoDate) =>
            onChange({ target: { name: 'expiresAt', value: isoDate } })
          }
          value={expiresAt || ''}
          minDate="today"
        >
          {({ value }) => (
            <TextInput
              autoComplete="off"
              placeholder="Set expiration date"
              readOnly
              value={value}
            />
          )}
        </DatePicker>
      </SharePanelSettingsSwitchRow>
    </Fragment>
  );
};

SecureSidePanelSettingsForm.propTypes = {
  canEnableDownloads: PropTypes.bool.isRequired,
  canSetDefaultTeamSessionWatermarkId: PropTypes.bool,
  defaultSessionWatermarkTemplateId: PropTypes.string,
  formik: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.shape({
      allowDownloads: PropTypes.bool.isRequired,
      disableDrm: PropTypes.bool,
      expiresAt: PropTypes.string,
      password: PropTypes.string,
      showLinkExpiration: PropTypes.bool.isRequired,
      showPassword: PropTypes.bool.isRequired,
      watermark: PropTypes.string,
    }).isRequired,
  }).isRequired,
  isLinkWatermarkable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickSetInviteOnly: PropTypes.func.isRequired,
  sessionWatermarkTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setDefaultSessionWatermarkTemplateId: PropTypes.func,
  showCustomWatermarkSubmit: PropTypes.bool,
  setCustomWatermarkSubmit: PropTypes.func,
};

SecureSidePanelSettingsForm.defaultProps = {
  canSetDefaultTeamSessionWatermarkId: false,
  sessionWatermarkTemplates: [],
};

export default connect(SecureSidePanelSettingsForm);
