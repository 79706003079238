import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { buttonReset } from '@frameio/components/src/mixins';
import { Menu, MenuGroup, MenuButton } from '@frameio/vapor';
import track from 'analytics';
import Avatar from '@frameio/components/src/styled-components/Avatar';
import LoadingIndicator from '@frameio/components/src/styled-components/LoadingIndicator';
import ConnectedMigrationModal from 'components/MigrationModal/ConnectedMigrationModal';

import { SUPPORT_URL, STATUS_URL, LOGOUT_URL, PRODUCT_UPDATES_URL } from 'URLs';
import Truncate from '@frameio/components/src/styled-components/Truncate';
import OpenDevTools from 'components/DevelopmentTools/OpenDevTools';
import AccountNavigationSettings from 'components/AccountNavigation/AccountNavigationSettings/AccountNavigationSettings';
import DropArrowIcon from '@frameio/components/src/svgs/icons/caron.svg';
import Logotype from '@frameio/components/src/svgs/logos/logo-light.svg';
import AccountCTA from 'components/AccountCTA';
import TRACKING_LOCATION from 'components/AccountCTA/constants';
import { motion } from 'framer-motion';
import { noop } from 'lodash';
import { getModalVariantV4 } from 'components/MigrationModal';

// Align account popover point with arrow.
const LOGO_HEIGHT = 32;
const DROP_ARROW_SIZE = 18;

const Header = styled.button`
  ${buttonReset()}
  cursor: pointer;
  padding: ${(p) => p.theme.spacing.tiny};
  opacity: 1;
  transition: opacity 0.12s linear;
  &:active {
    opacity: 0.7;
  }
  &[aria-expanded='true'] {
    cursor: default;
  }
`;

const AccountName = styled(Truncate)`
  color: ${(p) => p.theme.color.white};
  font-size: 20px;
  max-width: calc(100% - ${DROP_ARROW_SIZE}px);

  /*
    line-height is set here to ensure that the default
    logo display and Account Name display have the same height
  */
  line-height: ${LOGO_HEIGHT}px;
`;

const DropArrow = styled(DropArrowIcon)`
  margin-top: ${(p) => p.theme.spacing.micro};
  width: ${DROP_ARROW_SIZE}px;
  height: ${DROP_ARROW_SIZE}px;
  color: ${(p) => p.theme.color.graphiteGray};
`;

const LoadingPlaceholder = styled.div`
  height: 48px;
`;

const StyledMenu = styled(Menu)`
  width: ${(p) => p.theme.spacing.units(33)};
`;

const UserProfile = styled(MenuButton)`
  &[disabled] {
    opacity: 1;
  }
`;

const StyledButtonForV4Upgrade = styled.div`
  padding: 0 8px;

  button {
    background-color: transparent;
    border: 1.5px solid rgba(91, 83, 255, 1);
    border-radius: 6px;
    box-sizing: border-box;
    color: #20222a;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    height: 36px;
    line-height: 24px; /* 171.429% */
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
    transition: background-color 0.1s ease, color 0.1s ease;

    &:hover,
    &:focus-visible {
      background-color: rgba(91, 83, 255, 1);
      color: #fff;
    }
  }
`;

function TopBarNav({
  accountDisplayName,
  accountId,
  accountSettingsPermissions,
  canOpenMigrationModal,
  openModal,
  className,
  email,
  isFetching,
  defaultColor,
  image,
  name,
  hasOwnAccount,
  isV4MigrationModalEnabled,
  isV4MigrationModalVariantAEnabled,
  startSetupAccount,
  openLegalModal,
}) {
  const isAccountDataLoaded = !!accountId;
  const [hasLoadedAvatar, setHasLoadedAvatar] = useState(false);
  const [showingMenu, setShowingMenu] = useState(false);

  const modalVariantV4 = getModalVariantV4(
    isV4MigrationModalEnabled,
    isV4MigrationModalVariantAEnabled
  );

  // This is a hack to properly fade-in the avatar. Ideally
  // we will refactor the Avatar component to properly
  // support this moving forward.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (showingMenu) {
      const timer = setTimeout(() => {
        setHasLoadedAvatar(true);
      }, 200);

      return () => {
        clearTimeout(timer);
      };
    }
    setHasLoadedAvatar(false);
  }, [showingMenu]);

  if (!isAccountDataLoaded) {
    return <LoadingPlaceholder />;
  }

  return (
    <StyledMenu
      placement="bottom"
      isShowing={showingMenu}
      onRequestChange={setShowingMenu}
      content={
        <>
          <MenuGroup>
            <UserProfile
              disabled
              iconBefore={
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.25, ease: [0.4, 0, 0, 1] }}
                  animate={{
                    opacity: hasLoadedAvatar ? 1 : 0,
                    scale: hasLoadedAvatar ? 1 : 0.8,
                  }}
                >
                  <Avatar
                    image={image}
                    name={name}
                    color={defaultColor}
                    size={32}
                  />
                </motion.div>
              }
              details={email}
            >
              {name}
            </UserProfile>
            {canOpenMigrationModal ? (
              <StyledButtonForV4Upgrade>
                <button
                  onClick={() => {
                    track('v4-upgrade-awareness-modal-shown', {
                      context_entrypoint: 'manual_user-triggered',
                      variant: modalVariantV4,
                    });

                    openModal(<ConnectedMigrationModal />, {
                      maxHeight: 'none',
                      style: { background: 'none' },
                    });
                    setShowingMenu(false);
                  }}
                  role="menuitem"
                >
                  {isV4MigrationModalEnabled ? 'Update to V4' : 'Join V4 Beta'}
                </button>
              </StyledButtonForV4Upgrade>
            ) : (
              <AccountCTA
                trackingLocation={TRACKING_LOCATION.ACCOUNT_DROPDOWN}
              />
            )}
          </MenuGroup>
          <MenuGroup>
            <AccountNavigationSettings
              accountSettingsPermissions={accountSettingsPermissions}
            />
          </MenuGroup>
          <MenuGroup>
            {canOpenMigrationModal && (
              <AccountCTA
                trackingLocation={TRACKING_LOCATION.ACCOUNT_DROPDOWN_SECONDARY}
              />
            )}

            <MenuButton
              as="a"
              isLink
              target="_blank"
              rel="noopener noreferrer"
              href={PRODUCT_UPDATES_URL}
            >
              Product updates
            </MenuButton>
            <MenuButton isLink as="a" href={STATUS_URL}>
              Status updates
            </MenuButton>
            <MenuButton isLink as="a" href={SUPPORT_URL}>
              Support FAQ
            </MenuButton>
            <MenuButton onClick={openLegalModal}>Legal notices</MenuButton>
            <OpenDevTools>
              {({ onSelect }) => (
                <MenuButton onSelect={onSelect}>Open dev tools</MenuButton>
              )}
            </OpenDevTools>
            {!hasOwnAccount && (
              <MenuButton isLink onClick={startSetupAccount}>
                Create my own account
              </MenuButton>
            )}
            <MenuButton destructive isLink as="a" href={LOGOUT_URL}>
              Log out
            </MenuButton>
          </MenuGroup>
        </>
      }
    >
      <Header className={className}>
        <Flex alignCenter>
          {/*
            Display the header logo if:
            1. A user is unable to switch accounts
            2. A user is able to switch accounts, and the current account data is loaded,
                but the account does not have a display name set. This is to ensure that there
                isn't a brief flash of the default logo on initial page load while account data
                is being fetched.
          */}
          {accountDisplayName ? (
            <AccountName>{accountDisplayName}</AccountName>
          ) : (
            <Logotype width={108} height={LOGO_HEIGHT} />
          )}
          <DropArrow />
        </Flex>
        {isFetching && <LoadingIndicator />}
      </Header>
    </StyledMenu>
  );
}

TopBarNav.defaultProps = {
  accountDisplayName: undefined,
  accountId: undefined,
  canOpenMigrationModal: false,
  className: undefined,
  defaultColor: undefined,
  email: undefined,
  isFetching: false,
  image: undefined,
  name: undefined,
  openModal: noop,
};

TopBarNav.propTypes = {
  accountDisplayName: PropTypes.string,
  accountId: PropTypes.string,
  canOpenMigrationModal: PropTypes.bool,
  className: PropTypes.string,
  defaultColor: PropTypes.string,
  email: PropTypes.string,
  isFetching: PropTypes.bool,
  image: PropTypes.string,
  name: PropTypes.string,
  openModal: PropTypes.func.isRequired,
};

export default React.memo(TopBarNav);

export const testExports = {
  Header,
  Logotype,
  AccountName,
  DropArrow,
};
