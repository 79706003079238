import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Flex from 'styled-flex-component';
import styled from 'styled-components';
import { TreeItem } from '@frameio/components/src/styled-components/TreeView';
import FolderIcon from './FolderIcon';
import SharedFolderIcon from './SharedFolderIcon';

const StyledTreeItem = styled(TreeItem)`
  ${(p) =>
    p.disabled &&
    `cursor: not-allowed;
    pointer-events: all;`}
`;

const StyledSharedFolderIcon = styled(SharedFolderIcon)`
  background-color: ${(p) =>
    p.isSelected ? p.theme.color.lightGray : p.theme.color.silver};
`;

const Label = styled(Flex)`
  span {
    max-width: ${(p) =>
      p.isSharedFolder ? `calc(100% - ${p.theme.spacing.units(3)})` : '100%'};
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${SharedFolderIcon} {
    margin-left: ${(p) => p.theme.spacing.micro};
    flex: 0 0 auto;
  }
`;

/**
 * TreeFolderItem
 *
 * A custom tree item used to display a folder.
 */
function TreeFolderItem({
  assetFolderTree,
  childName,
  children,
  folderId,
  isDisabled,
  isSelected,
  isSharedFolder,
  phantomFolder,
  renderTree,
  selectedRefTreeView,
  ...other
}) {
  /**
   * We have a function component here so that IF the item is selected
   * We can attach a ref to it, so that after the list is rendered we can scroll to it.
   * Particularly relevant for new folders once created they
   * are orderBy() name and no longer at the top.
   * the ref attribute doesn't like it if we assign an inline ternary.
   */
  const NestedTreeItemLabel = useCallback(
    ({ isSelectedRef }) => {
      if (isSelectedRef) {
        return <span ref={selectedRefTreeView}>{childName}</span>;
      }

      return <span>{childName}</span>;
    },
    [childName, selectedRefTreeView]
  );

  return (
    <StyledTreeItem
      childName={childName}
      disabled={isDisabled}
      iconBefore={<FolderIcon isSelected={isSelected} disabled={isDisabled} />}
      label={
        <Label isSharedFolder={isSharedFolder}>
          <NestedTreeItemLabel
            childName={childName}
            isSelectedRef={isSelected}
          />
          {isSharedFolder && <StyledSharedFolderIcon isSelected={isSelected} />}
        </Label>
      }
      nodeId={folderId}
      {...other}
    >
      {/*
       * if there are children and a phantom folder we put the phantom
       * first for ease of access/view to the user.
       */}
      {!isDisabled &&
        children.length !== 0 &&
        [phantomFolder].concat(renderTree(children, assetFolderTree))}
      {children.length === 0 && phantomFolder}
    </StyledTreeItem>
  );
}

TreeFolderItem.defaultProps = {
  assetFolderTree: {},
  childName: undefined,
  children: [],
  isSelected: false,
  phantomFolder: null,
  renderTree: noop,
  selectedRefTreeView: null,
};

TreeFolderItem.propTypes = {
  assetFolderTree: PropTypes.object,
  childName: PropTypes.string,
  children: PropTypes.array,
  folderId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  phantomFolder: PropTypes.node,
  renderTree: PropTypes.func,
  selectedRefTreeView: PropTypes.object,
};

export default React.memo(TreeFolderItem);
