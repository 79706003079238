import { connect } from 'react-redux';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import { closeModal } from 'components/Modal/actions';
import { logOut } from 'actions/users';
import StartAccount from './StartAccount';
import { applyStarterTrial } from '../actions';
import { isApplyingPromotionSelector } from '../selectors';

function mapStateToProps(state, { accountId }) {
  const account = accountEntitySelector(state, { accountId }) || {};
  const isApplyingPromotion = isApplyingPromotionSelector(state);

  return {
    displayNameForAccount: account.display_name,
    isApplyingPromotion,
  };
}

const mapDispatchToProps = {
  onCancel: closeModal,
  applyStarterTrial,
  logOut,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StartAccount);
