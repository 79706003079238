import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import OutOfArchivedSpace from './OutOfArchivedSpace';
import { continueFlow } from '../actions';

const mapDispatchToProps = {
  onCancel: closeModal,
  onContinue: continueFlow,
};

export default connect(
  null,
  mapDispatchToProps
)(OutOfArchivedSpace);

export const testExports = {
  mapDispatchToProps,
};
