export default function loadImage(src) {
  return new Promise((resolve, reject) => {
    try {
      const img = new Image();
      img.onload = resolve;
      img.src = src;
    } catch (error) {
      reject(error);
    }
  });
}
