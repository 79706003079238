import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import track from 'analytics';
import Button from '@frameio/components/src/styled-components/Button';
import Flex from 'styled-flex-component';
import CreditCardForm from 'components/CreditCardForm';
import CreditCardFormElements from 'components/CreditCardFormElements';

const defaultTrackingProps = {
  is_modal_for_purchase: true,
  is_modal_for_trial: false,
};

const Header = styled.h3`
  ${(p) => p.theme.fontStyle.heading};
`;

const CancelButton = styled(Button)`
  margin-right: ${(p) => p.theme.spacing.small};
`;

const Footer = styled(Flex)`
  justify-content: flex-end;
`;

export const ModalFooter = ({
  dirty,
  isFetching,
  isSubmitting,
  isValid,
  onCancel,
}) => (
  <Footer>
    {typeof onCancel === 'function' && (
      <CancelButton
        disabled={isFetching || isSubmitting}
        onClick={onCancel}
        data-test-id="cancel-btn"
        type="button"
      >
        Cancel
      </CancelButton>
    )}
    <Button
      primary
      disabled={!isValid || isFetching || isSubmitting || !dirty}
      showSpinner={isFetching || isSubmitting}
      data-test-id="submit-btn"
      type="submit"
    >
      Confirm
    </Button>
  </Footer>
);

class AddCreditCard extends React.Component {
  state = { isValid: false };

  componentDidMount() {
    track('credit-card-modal-shown', {
      ...defaultTrackingProps,
      ...this.props.trackingProps,
    });
  }

  onValidate = (isValid) => {
    this.setState({ isValid });
  };

  render() {
    const {
      onContinue,
      onCancel,
      isFetching,
      isSubmitting,
      trackingProps,
    } = this.props;
    const { isValid } = this.state;

    return (
      <React.Fragment>
        <Header>Payment Details</Header>
        <CreditCardForm
          onSubmit={(e) => {
            track('credit-card-modal-submitted', {
              ...defaultTrackingProps,
              ...trackingProps,
            });
            return onContinue(e);
          }}
        >
          <CreditCardFormElements
            trackingPage="payment-flow"
            onValidate={this.onValidate}
          />
          <ModalFooter
            onCancel={onCancel}
            isFetching={isFetching}
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        </CreditCardForm>
      </React.Fragment>
    );
  }
}

AddCreditCard.defaultProps = {
  isFetching: false,
  onCancel: null,
  errorMessage: '',
  trackingProps: {},
};

AddCreditCard.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  trackingProps: PropTypes.object,
};

ModalFooter.defaultProps = {
  isFetching: false,
  isValid: false,
  onCancel: null,
};

ModalFooter.propTypes = {
  onCancel: PropTypes.func,
  isFetching: PropTypes.bool,
  isValid: PropTypes.bool,
};

export default AddCreditCard;
