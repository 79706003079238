import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import MotionClick from '@frameio/components/src/components/MotionClick';
import Flex from 'styled-flex-component';
import ProjectLink from 'components/ProjectLink';
import { pageTitle } from 'utils/page';
import { FAILURE_REASONS } from 'components/AuthGate/failureReasons';

// TODO[AP | ENT-1428] Move this to the svgs/illustrations folder in web-components library
import UnavailableIllustration from './unavailable.svg';

const Title = styled.h2`
  color: ${(p) => p.theme.color.white};
`;

const StyledProjectLink = styled(ProjectLink)`
  background-color: ${(p) => p.theme.color.brand};
  border-radius: ${(p) => p.theme.radius.pill};
  color: ${(p) => p.theme.color.white};
  display: inline-block;
  font-size: ${(p) => p.theme.fontSize[2]};
  padding: ${(p) => `${p.theme.spacing.small} ${p.theme.spacing.medium}`};
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: ${(p) => p.theme.color.lightBrand};
  }
`;

const Body = styled.div`
  ${(p) => p.theme.fontStyle.bodyM};
  color: ${(p) => p.theme.color.slateGray};
`;

const Container = styled(Flex).attrs(() => ({
  full: true,
  center: true,
  column: true,
}))`
  text-align: center;

  ${Title} {
    margin: ${(p) => p.theme.spacing.medium} 0;
  }

  ${StyledProjectLink} {
    margin-top: ${(p) => p.theme.spacing.medium};
  }
`;

export default function ReviewLinkUnavailable({ reason, entityType }) {
  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle(`This ${entityType} is unavailable`)}</title>
      </Helmet>
      <Container>
        <UnavailableIllustration />
        {reason === FAILURE_REASONS.FORBIDDEN ? (
          <React.Fragment>
            <Title>This is a private {entityType}</Title>
            <Body>
              You do not have access to this link. Please contact the
              <br />
              account owner for access.
            </Body>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Title>This {entityType} is unavailable</Title>
            <Body>
              This {entityType} is no longer accessible. Please contact the
              <br />
              account owner if you still need access.
            </Body>
          </React.Fragment>
        )}
        <MotionClick>
          <StyledProjectLink>Return to Frame.io</StyledProjectLink>
        </MotionClick>
      </Container>
    </React.Fragment>
  );
}

ReviewLinkUnavailable.defaultProps = {
  reason: undefined,
  entityType: 'link',
};

ReviewLinkUnavailable.propTypes = {
  reason: PropTypes.string,
  entityType: PropTypes.string,
};

export const testExports = { StyledProjectLink, Title };
