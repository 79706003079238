import { generateActionTypes } from 'shared/actions/helpers';
import { createAction } from 'redux-actions';

export const ADBLOCK_DETECTOR = generateActionTypes('ADBLOCK_DETECTOR', [
  'SHOW_DISCLAIMER',
]);

export const showAdBlockDisclaimer = createAction(
  ADBLOCK_DETECTOR.SHOW_DISCLAIMER,
  () => ({})
);
