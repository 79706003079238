import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SubscriptionChangedConfirmation from 'components/SubscriptionChangedConfirmation';

const Header = styled.p`
  color: ${(p) => p.theme.color.darkGray};
  padding-bottom: ${(p) => p.theme.spacing.micro};
`;

const Subheader = styled.p`
  color: ${(p) => p.theme.color.graphiteGray};
  font-size: ${(p) => p.theme.fontSize[1]};
`;

const ConfirmPlanChange = ({
  onDoneClick,
  location: {
    state: { newPlanTitle, newPlanPeriod, isFreePlanWithUserMaxSelected },
  },
}) => (
  <SubscriptionChangedConfirmation onDoneClick={onDoneClick}>
    <Header>
      You’ve subscribed to the {newPlanPeriod} {newPlanTitle} plan!
    </Header>
    {!isFreePlanWithUserMaxSelected && (
      <Subheader>Keep an eye on your inbox for your receipt.</Subheader>
    )}
  </SubscriptionChangedConfirmation>
);

ConfirmPlanChange.propTypes = {
  onDoneClick: PropTypes.func,
  location: PropTypes.object.isRequired,
};

ConfirmPlanChange.defaultProps = {
  onDoneClick: undefined,
};

export default ConfirmPlanChange;
