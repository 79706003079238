import React from 'react';
import styled from 'styled-components';
import SharedFolderUserGroup from '@frameio/components/src/svgs/icons/folder-sharing-user-group.svg';
import Badge from '@frameio/components/src/styled-components/Badge';
import { Tooltip } from '@frameio/vapor';

const StyledUserGroupIcon = styled(SharedFolderUserGroup)`
  color: ${(p) => p.theme.color.graphiteGray};
`;

const SharedFolderIcon = styled((props) => (
  <Tooltip
    placement="top"
    title="Folder is shared in a review link"
    variant="dark"
  >
    <Badge {...props}>
      <StyledUserGroupIcon width={12} />
    </Badge>
  </Tooltip>
))``;

export default SharedFolderIcon;
