/* eslint-disable react/jsx-no-bind */

import React from 'react';
import PropTypes from 'prop-types';
import { FadeTransition } from 'react-transition-components';
import {
  BasicTextField,
  SelectDropdown,
  SelectOption,
  IconAvatar,
  PrivateSwitch,
  BigSwitch,
  StaticColorIcon,
  DynamicColorIcon,
} from '@frameio/components';
import { noop } from 'lodash';
import ModalHeader from 'components/Modal/ModalHeader';
import RowWithSwitch from 'components/Modal/RowWithSwitch';
import ProjectFormSubheader from 'components/ProjectForms/ProjectFormSubheader';
import LimitBlock from 'components/LimitBlock';
import { limitTypes } from 'selectors/limits';
import { ENTER } from 'utils/keyCodes';
import { DEFAULT_TEAM_IMAGE } from 'shared/defaultURLs';
import { getUsageDataFromAccountEntity } from 'utils/plans/plansHelpers';
import 'styles/_modal.scss';
import { normalizeV2ProjectData, getPrivacySwitchText } from '../utils';
import CreateProjectFooter from './CreateProjectFooter';

/**
 * In case of fields changing, make corresponding changes to Edit Project &
 * Create Project forms.
 */
class CreateProjectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectName: '',
      isValid: true,
      isPrivate: false,
      canEmailForNewCommentEveryone: true,
      canEmailForNewPersonEveryone: true,
      canEmailForNewVideoEveryone: true,
      canEmailForUpdatedLabelEveryone: true,
      canEmailForUpdatedLabelMe: true,
      canEmailForNewPersonMe: true,
      canEmailForNewVideoMe: true,
      canEmailForNewCommentMe: true,
      canCollaboratorShare: props.canToggleSharePresentations,
      canCollaboratorInvite: props.canToggleInviteCollaborators,
      canCollaboratorDownload: true,
      c2cConnectionsEnabled: false,
      enableLifeCyclePolicy: true,
    };

    // get the role of the user on demand on component mount
    props.getRoleForTeam(props.selectedTeam.id);
  }

  componentWillUnmount() {
    // unset the team id when modal closes
    this.props.setTeamId(null);
  }

  /**
   * Sets the project name in state.
   * @param {string} value - The project name.
   */
  onProjectNameChange = (value) => {
    this.setState({ projectName: value });
  };

  /**
   * Sets the selected team in state.
   * @param {Object} selection - The team selection object from the SelectDropdown.
   */
  onTeamSelect = ({ value: teamId }) => {
    this.props.setTeamId(teamId);
  };

  /**
   * Validates data before clicking create.  Form is not valid if there is no
   * project name.
   */
  onSubmitCreate = () => {
    if (!this.state.projectName) {
      this.bodyContainer.scrollTop = 0;
      this.setState({ isValid: false });
    } else {
      const { createProject, selectedTeam } = this.props;

      const formData = normalizeV2ProjectData(this.state);
      createProject(selectedTeam.id, formData);
    }
  };

  /**
   * Submit's form to create project on Enter.
   * @param   {SyntheticEvent} evt - Keydown event.
   */
  onEnterSubmit = (evt) => {
    if (evt.key === ENTER) {
      this.onSubmitCreate();
    }
  };

  /**
   * Sets the the value of the switch on change in state.
   * @param {string} switchName - The name of the switch changing.
   */
  onSwitchChange = (switchName) => {
    this.setState({ [switchName]: !this.state[switchName] });
  };

  /**
   * Generates the SelectOption components for each team.
   * @returns {Array} An array of teams formatted as SelectOption components.
   */
  generateTeamOptions = () => {
    const { selectedTeam, teams } = this.props;
    return teams
      .filter((team) => team.id !== selectedTeam.id)
      .map((team) => {
        const { id, image, member_count: memberCount, name } = team;

        return (
          <SelectOption key={id} value={id} text={name} classNames="pa3">
            <div className="flex flex-row w-100 br2 overflow-x-hidden">
              <div className="w2">
                <IconAvatar imageUrl={image || DEFAULT_TEAM_IMAGE} size="M" />
              </div>
              <div className="ph2">
                <div className="b">{name}</div>
                <div className="gray">{memberCount} members</div>
              </div>
            </div>
          </SelectOption>
        );
      });
  };

  /**
   * Renders the limit block for projects.
   * @returns {ReactElement} JSX.
   */
  renderLimitBlock = () => {
    const { canCreateProject, currentUser, role, selectedAccount } = this.props;

    if (canCreateProject || !selectedAccount) {
      return null;
    }

    const newUsageData = getUsageDataFromAccountEntity(selectedAccount);
    newUsageData.project_count += 1;

    return (
      <FadeTransition delay={500}>
        <LimitBlock
          account={selectedAccount}
          currentUser={currentUser}
          newUsageData={newUsageData}
          userRole={role}
          type={limitTypes.PROJECTS}
        />
      </FadeTransition>
    );
  };

  /**
   * Renders the form for picking your project preferences.
   * @returns {ReactElement} JSX.
   */
  renderForm = () => {
    const {
      canToggleAutomaticallyDeletingAssets,
      canToggleSharePresentations,
      canToggleInviteCollaborators,
      canUseC2CConnections,
      canUsePrivateProjects,
      selectedTeam,
      teams,
      newProjectInviteLinksEnabled,
    } = this.props;

    const { isPrivate } = this.state;

    const { privacyHeaderText, privacySubHeaderText } = getPrivacySwitchText(
      isPrivate
    );

    return (
      <div
        className="relative vh-100 form-modal-height-ns form-modal-width-ns overflow-y-scroll"
        ref={(bodyContainer) => {
          this.bodyContainer = bodyContainer;
        }}
      >
        <div className="modal-body-spacing">
          <div className="pv3-5 ph4 bb b--fio-gray">
            {!this.state.isValid && (
              <div className="red tc mb3"> Project Name is Required.</div>
            )}
            <BasicTextField
              autoFocus
              name="New-Project"
              placeholder="Enter Project Name"
              defaultValue={this.state.projectName}
              onChange={this.onProjectNameChange}
              validationFunctions={[(val) => val.length > 0]}
              runValidation={this.state.isValid}
              tabIndex={0}
            />
            {teams.length > 1 && (
              <div className="mt3">
                <SelectDropdown
                  selectedValue={selectedTeam.id}
                  selectedText={selectedTeam.name}
                  onClick={this.onTeamSelect}
                >
                  {this.generateTeamOptions()}
                </SelectDropdown>
              </div>
            )}
          </div>
          <div className="bg-fio-lightest-gray">
            <div>
              {canUsePrivateProjects && (
                <RowWithSwitch
                  data-test-id="private-project-switch"
                  text={privacyHeaderText}
                  subtext={privacySubHeaderText}
                  switchComponent={
                    <PrivateSwitch
                      onChange={this.onSwitchChange.bind(this, 'isPrivate')}
                      isOn={!this.state.isPrivate}
                    />
                  }
                  icon={
                    <DynamicColorIcon
                      className="svg-fio-mid-gray"
                      kind={this.state.isPrivate ? 'lock' : 'globe'}
                      size={22}
                    />
                  }
                  hasBorder
                />
              )}
              {canUseC2CConnections && (
                <RowWithSwitch
                  data-test-id="c2c-connections-switch"
                  text="C2C Connections"
                  subtext="Enable connections to upload media"
                  switchComponent={
                    <BigSwitch
                      onChange={this.onSwitchChange.bind(
                        this,
                        'c2cConnectionsEnabled'
                      )}
                      isOn={this.state.c2cConnectionsEnabled}
                    />
                  }
                  icon={
                    <DynamicColorIcon
                      className="svg-fio-mid-gray"
                      kind="connected"
                      size={22}
                    />
                  }
                />
              )}
              <RowWithSwitch
                text="Enable Slack Notifications"
                switchComponent={<BigSwitch isOn={false} />}
                icon={<StaticColorIcon kind="slack" size={22} />}
                isDisabled
                tooltipText="You can enable Slack notifications after creating the project."
                hasBorder
              />
              {!newProjectInviteLinksEnabled && (
                <RowWithSwitch
                  text="Project Sharing"
                  switchComponent={<BigSwitch isOn={false} />}
                  icon={
                    <DynamicColorIcon
                      className="svg-fio-mid-gray"
                      kind="people"
                      size={22}
                    />
                  }
                  subtext="Sharing a project will make it joinable via a unique URL."
                  isDisabled
                  tooltipText="You can enable project sharing after creating the project."
                  hasBorder={!!canToggleAutomaticallyDeletingAssets}
                />
              )}
              {canToggleAutomaticallyDeletingAssets && (
                <RowWithSwitch
                  text="Enable Lifecycle Policy"
                  subtext={`Assets in this project will be deleted ${selectedTeam.asset_lifecycle_policy} days after their upload date`}
                  switchComponent={
                    <BigSwitch
                      isOn={this.state.enableLifeCyclePolicy}
                      onChange={this.onSwitchChange.bind(
                        this,
                        'enableLifeCyclePolicy'
                      )}
                    />
                  }
                  icon={
                    <DynamicColorIcon
                      className="svg-fio-mid-gray"
                      kind="evergreen"
                      size={22}
                    />
                  }
                />
              )}
              <ProjectFormSubheader text="Collaborator Permissions" />
              <RowWithSwitch
                text="Can Download Files"
                switchComponent={
                  <BigSwitch
                    onChange={this.onSwitchChange.bind(
                      this,
                      'canCollaboratorDownload'
                    )}
                    isOn={this.state.canCollaboratorDownload}
                  />
                }
                hasBorder
              />
              {canToggleInviteCollaborators && (
                <RowWithSwitch
                  text="Can Invite Collaborators"
                  switchComponent={
                    <BigSwitch
                      onChange={this.onSwitchChange.bind(
                        this,
                        'canCollaboratorInvite'
                      )}
                      isOn={this.state.canCollaboratorInvite}
                    />
                  }
                  hasBorder
                />
              )}
              {canToggleSharePresentations && (
                <RowWithSwitch
                  text="Can Share Presentations"
                  switchComponent={
                    <BigSwitch
                      onChange={this.onSwitchChange.bind(
                        this,
                        'canCollaboratorShare'
                      )}
                      isOn={this.state.canCollaboratorShare}
                    />
                  }
                />
              )}
            </div>

            <ProjectFormSubheader text="My Email and iOS Push Notifications" />
            <RowWithSwitch
              text="New Comment"
              switchComponent={
                <BigSwitch
                  onChange={this.onSwitchChange.bind(
                    this,
                    'canEmailForNewCommentMe'
                  )}
                  isOn={this.state.canEmailForNewCommentMe}
                />
              }
              hasBorder
            />
            <RowWithSwitch
              text="New Media Uploads"
              switchComponent={
                <BigSwitch
                  onChange={this.onSwitchChange.bind(
                    this,
                    'canEmailForNewVideoMe'
                  )}
                  isOn={this.state.canEmailForNewVideoMe}
                />
              }
              hasBorder
            />
            <RowWithSwitch
              text="New Person Joins"
              switchComponent={
                <BigSwitch
                  onChange={this.onSwitchChange.bind(
                    this,
                    'canEmailForNewPersonMe'
                  )}
                  isOn={this.state.canEmailForNewPersonMe}
                />
              }
              hasBorder
            />
            <RowWithSwitch
              text="Media Status Updated"
              switchComponent={
                <BigSwitch
                  onChange={this.onSwitchChange.bind(
                    this,
                    'canEmailForUpdatedLabelMe'
                  )}
                  isOn={this.state.canEmailForUpdatedLabelMe}
                />
              }
            />
            <div>
              <ProjectFormSubheader text="Default Email and iOS Push Notifications for New Project Members" />
              <RowWithSwitch
                className="ee-new-comment"
                text="New Comment"
                switchComponent={
                  <BigSwitch
                    onChange={this.onSwitchChange.bind(
                      this,
                      'canEmailForNewCommentEveryone'
                    )}
                    isOn={this.state.canEmailForNewCommentEveryone}
                  />
                }
                hasBorder
              />
              <RowWithSwitch
                className="ee-new-media-uploads"
                text="New Media Uploads"
                switchComponent={
                  <BigSwitch
                    onChange={this.onSwitchChange.bind(
                      this,
                      'canEmailForNewVideoEveryone'
                    )}
                    isOn={this.state.canEmailForNewVideoEveryone}
                  />
                }
                hasBorder
              />
              <RowWithSwitch
                className="ee-new-person-joins"
                text="New Person Joins"
                switchComponent={
                  <BigSwitch
                    onChange={this.onSwitchChange.bind(
                      this,
                      'canEmailForNewPersonEveryone'
                    )}
                    isOn={this.state.canEmailForNewPersonEveryone}
                  />
                }
                hasBorder
              />
              <RowWithSwitch
                text="Media Status Updated"
                switchComponent={
                  <BigSwitch
                    onChange={this.onSwitchChange.bind(
                      this,
                      'canEmailForUpdatedLabelEveryone'
                    )}
                    isOn={this.state.canEmailForUpdatedLabelEveryone}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { closeModal, isFetching } = this.props;

    return (
      <div
        className="font-regular fio-black relative w-100 h-100 button-reset"
        onKeyDown={(evt) => this.onEnterSubmit(evt)}
        role="button"
        tabIndex="-1"
      >
        <ModalHeader text="New Project" onClickClose={closeModal} />
        <div className="relative">
          {this.renderForm()}
          {this.renderLimitBlock()}
        </div>
        <CreateProjectFooter
          onCreateButtonClick={this.onSubmitCreate}
          isWaiting={isFetching}
        />
      </div>
    );
  }
}

CreateProjectForm.defaultProps = {
  canToggleAutomaticallyDeletingAssets: false,
  dispatch: noop,
  isFetching: false,
};

CreateProjectForm.propTypes = {
  canCreateProject: PropTypes.bool.isRequired,
  /**
   * Toggles the asset lifecycle method on the project.
   */
  canToggleAutomaticallyDeletingAssets: PropTypes.bool,
  canToggleInviteCollaborators: PropTypes.bool.isRequired,
  canToggleSharePresentations: PropTypes.bool.isRequired,
  canUseC2CConnections: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  createProject: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  getRoleForTeam: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  role: PropTypes.string.isRequired,
  selectedAccount: PropTypes.object.isRequired,
  selectedTeam: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    asset_lifecycle_policy: PropTypes.number,
  }).isRequired,
  setTeamId: PropTypes.func.isRequired,
  teams: PropTypes.array.isRequired,
};

export default CreateProjectForm;
