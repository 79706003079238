import React from 'react';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import { currentAccountSelector } from 'selectors/accounts';
import { confirm } from 'components/Dialog/SimpleDialog/sagas';
import { restoreSubscription } from '@frameio/core/src/subscriptions/sagas';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import { REACTIVATE_SUBSCRIPTION, isFetching } from './actions';
import ReactivateSubscription from '.';

const errorMessage = 'Your subscription couldn’t be reactivated';
const successMessage = 'Your subscription has been succesfully reactivated';
const headerCopy = 'Reactivate your account?';

export function* confirmReactivateSubscription() {
  const account = yield select(currentAccountSelector);
  const accountId = account.id;
  const confirmsToReactivateSubscription = yield call(
    confirm,
    headerCopy,
    <ReactivateSubscription />,
    {
      primaryText: 'Reactivate',
    }
  );

  if (confirmsToReactivateSubscription) {
    yield put(isFetching(true));
    const { failure } = yield call(restoreSubscription, accountId);
    if (failure) {
      yield put(
        showErrorToast({
          header: errorMessage,
        })
      );
      yield put(isFetching(false));
      return;
    }
    yield put(
      showSuccessToast({
        header: successMessage,
      })
    );
    yield put(isFetching(false));
  }
}

export default [
  takeLatest(
    REACTIVATE_SUBSCRIPTION.CONFIRM_REACTIVATE,
    confirmReactivateSubscription
  ),
];

export const testExports = {
  headerCopy,
  ReactivateSubscription,
};
