import { takeLatest, call, put } from 'redux-saga/effects';
import {
  getConnectedOAuthApps as getConnectedOAuthAppsCoreSaga,
  revokeConnectedOAuthApp as revokeConnectedOAuthAppCoreSaga,
} from '@frameio/core/src/oauthApps/sagas';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import { OAUTH_APPS_CONTAINER } from './actions';

function* getConnectedOAuthApps() {
  yield call(getConnectedOAuthAppsCoreSaga);
}

export function* revokeConnectedOAuthApp(oauthAppId) {
  const { failure } = yield call(revokeConnectedOAuthAppCoreSaga, oauthAppId);
  if (failure) {
    yield put(
      showErrorToast({
        header: 'Unable to revoke app!',
      })
    );
    return;
  }

  yield put(
    showSuccessToast({
      header: 'Successfully revoked app!',
    })
  );
}

export default [
  takeLatest(OAUTH_APPS_CONTAINER.GET_ALL, getConnectedOAuthApps),
  takeLatest(OAUTH_APPS_CONTAINER.REVOKE, ({ payload: { oauthAppId } }) =>
    revokeConnectedOAuthApp(oauthAppId)
  ),
];
