import { connect } from 'react-redux';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import { teamEntityForProjectIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { isProjectArchivedSelector } from 'selectors/projects';
import {
  getDeletedAssetsInProject,
  searchDeletedAssetsInProject,
  searchDeletedAssetsInTeam,
  undeleteAsset,
  selectAssets,
  resetSearchAssetsInProject,
  resetSearchAssetsInTeam,
} from './actions';
import {
  isFetchingProjectAssetsFirstPageSelector,
  isFetchingTeamAssetsFirstPageSelector,
  projectAssetIdsSelector,
  projectAssetsMetaDataSelector,
  selectedAssetIdsSelector,
  teamAssetIdsSelector,
  teamAssetsMetaDataSelector,
} from './selectors';
import DeletedAssetsMenu from './DeletedAssetsMenu';

function mapStateToProps(state, { projectId }) {
  const project = projectEntitySelector(state, { projectId }) || {};
  return {
    assetIdsInProject: projectAssetIdsSelector(state),
    assetIdsInTeam: teamAssetIdsSelector(state),
    assetsInProjectPaginationData: projectAssetsMetaDataSelector(state),
    assetsInTeamPaginationData: teamAssetsMetaDataSelector(state),
    isFetchingFirstProjectPage: isFetchingProjectAssetsFirstPageSelector(state),
    isFetchingFirstTeamPage: isFetchingTeamAssetsFirstPageSelector(state),
    isProjectArchived: isProjectArchivedSelector(state, { projectId }),
    project,
    selectedAssetIds: selectedAssetIdsSelector(state),
    team: teamEntityForProjectIdSelector(state, { projectId }),
  };
}

const mapDispatchToProps = {
  getDeletedAssetsInProject,
  searchDeletedAssetsInProject,
  searchDeletedAssetsInTeam,
  undeleteAsset,
  selectAssets,
  resetSearchAssetsInProject,
  resetSearchAssetsInTeam,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeletedAssetsMenu);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
