import createCachedSelector from 're-reselect';
import { teamEntityForCommentIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { rolesEntitiesByTeamSelector } from '@frameio/core/src/roles/selectors';
import { isCommentOwnedByCurrentUserSelector } from 'selectors/comments';
import {
  isCurrentUserAnonymousSelector,
  isAuthedUserSelector,
} from 'selectors/users';

/**
 * Gets the permissions for the current user acting on a single comment.
 * @param {Object} stat[[e - Redux state.
 * @param {Object} ownProps - Must contain `commentId` key.
 * @returns {Object} Permissions hash.
 */
const permittedActionsForCommentSelector = createCachedSelector(
  teamEntityForCommentIdSelector,
  rolesEntitiesByTeamSelector,
  isCommentOwnedByCurrentUserSelector,
  isCurrentUserAnonymousSelector,
  isAuthedUserSelector,
  (
    team,
    rolesByTeam,
    isCommentOwnedByCurrentUser,
    isCurrentUserAnonymous,
    isAuthedUser
  ) => {
    const isCurrentUserPartOfTeam = !!team && !!rolesByTeam[team.id];

    return {
      canDelete: isCommentOwnedByCurrentUser || isCurrentUserPartOfTeam,
      canEdit: isCommentOwnedByCurrentUser,
      canComplete: isAuthedUser && !isCurrentUserAnonymous,
      canSeeReadBy: !isCurrentUserAnonymous,
      canUpVote: !isCurrentUserAnonymous,
    };
  }
)((state, { commentId }) => commentId);

export default permittedActionsForCommentSelector;
