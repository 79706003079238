import { PRESENTATION } from '@frameio/core/src/presentations/actions';
import { CONTEXT_MENU } from 'components/ContextMenuManager/actions';
import { PRESENTATION_EDITOR } from './actions';

const INITIAL_STATE = {
  assetIds: [],
  invitedIds: undefined,
  presentationId: null,
  // Holds the state for when the reel player is selected even though the
  // presentation entity itself isn't, so that we can show the `ReelPlayerBlock`
  // overlay over the preview panel.
  isBlockingReelPlayer: false,
};

export default function presentationEditorReducer(
  state = INITIAL_STATE,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case CONTEXT_MENU.CLOSE:
      /**
       * On close of the Modal, make sure to clear the invitedIds
       * otherwise the people list will show the Previous number on next modal opened.
       */

      return {
        ...state,
        invitedIds: INITIAL_STATE.invitedIds,
      };
    case PRESENTATION.GET.SUCCESS: {
      const { result: presentationId } = payload.response;

      return {
        ...state,
        presentationId,
      };
    }
    case PRESENTATION_EDITOR.OPEN: {
      // This is actually vanityId
      const { assetId: presentationId } = payload;

      return {
        ...state,
        presentationId,
      };
    }
    case PRESENTATION_EDITOR.EDIT: {
      const { result: presentationId } = payload.response;

      return {
        ...state,
        presentationId,
      };
    }
    case PRESENTATION_EDITOR.SELECT_ASSETS: {
      const { assetIds } = action.payload;
      return {
        ...state,
        assetIds,
      };
    }
    case PRESENTATION_EDITOR.STORE_INVITED_IDS: {
      const { invitedIds } = action.payload;
      return {
        ...state,
        invitedIds,
      };
    }
    case PRESENTATION_EDITOR.TOGGLE_REEL_PLAYER_BLOCK:
      return {
        ...state,
        isBlockingReelPlayer: payload.isOpen,
      };

    default:
      return state;
  }
}
