import { connect } from 'react-redux';
import { startUnarchiveProject } from './actions';
import UnarchiveProject from './UnarchiveProject';

export const mapDispatchToProps = {
  onUnarchiveProjectClick: startUnarchiveProject,
};

export default connect(
  null,
  mapDispatchToProps
)(UnarchiveProject);
