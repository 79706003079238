import Button from '@frameio/components/src/styled-components/Button';
import CopyIcon from '@frameio/components/src/svgs/icons/24/copy.svg';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import config, { isProduction } from 'config';
import ExpireAuthToken from './ExpireAuthToken';
import InvalidAuthToken from './InvalidAuthToken';
import DeleteRefreshToken from './DeleteRefreshToken';

const clientVersion = process.env.RELEASE_VERSION;

const circleBuildNumber =
  process.env.NODE_ENV !== 'test' ? process.env.BUILD_NUMBER : null;

const circleBuildUrl =
  circleBuildNumber !== 'local'
    ? `https://app.circleci.com/pipelines/github/Frameio/web-client/${circleBuildNumber}`
    : null;

const ExternalLink = styled.a.attrs(() => ({
  target: '_blank',
}))`
  text-decoration: none;
`;

const CopyButton = styled(Button).attrs(() => ({
  icon: 'true',
  text: true,
}))``;

function SupportToolLink({ path, children }) {
  return (
    <ExternalLink href={`${config.supportToolUrl}${path}`}>
      {children}
    </ExternalLink>
  );
}
SupportToolLink.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const SectionTitle = styled.h3`
  font-size: ${(p) => p.theme.fontSize[1]};
  color: ${(p) => p.theme.color.slateGray};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => `${theme.spacing.micro}`};
`;

const StyledTable = styled.table`
  font-size: ${(p) => p.theme.fontSize[1]};
`;
const Th = styled.th`
  text-align: left;
  width: 5em;
  padding: 2px 0;
`;
const Td = styled.td`
  padding-left: ${(p) => p.theme.spacing.small};
`;

const Container = styled.div`
  section + section {
    margin-top: ${(p) => p.theme.spacing.medium};
  }
`;

function Flag({ value }) {
  return value ? 'Yes' : 'No';
}

function Table({ rows }) {
  return (
    <StyledTable>
      <tbody>
        {rows.map(({ label, value }) => (
          <tr key={label}>
            <Th>{label}</Th>
            <Td>{value}</Td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
}
Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.node.isRequired,
    })
  ).isRequired,
};

function Asset({ asset: { id, name } }) {
  return (
    <Table
      rows={[
        {
          label: 'Id',
          value: <SupportToolLink path={`/assets/${id}`}>{id}</SupportToolLink>,
        },
        {
          label: 'Name',
          value: name,
        },
      ]}
    />
  );
}
Asset.propTypes = {
  user: PropTypes.object.isRequired,
};

function User({ user: { id, email } }) {
  return (
    <Table
      rows={[
        {
          label: 'Id',
          value: <SupportToolLink path={`/users/${id}`}>{id}</SupportToolLink>,
        },
        {
          label: 'Email',
          value: email,
        },
      ]}
    />
  );
}
User.propTypes = {
  user: PropTypes.object.isRequired,
};

function Team({ accountId, userId, team: { id, name } }) {
  const path = `/users/${userId}/accounts/${accountId}/teams/${id}`;
  return (
    <Table
      rows={[
        {
          label: 'Id',
          value: <SupportToolLink path={path}>{id}</SupportToolLink>,
        },
        {
          label: 'Name',
          value: name,
        },
      ]}
    />
  );
}
Team.propTypes = {
  accountId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
};

function Account({
  accountId,
  hasLegacyCollabs,
  ownerEmail,
  ownerName,
  role,
  userId,
}) {
  const path = `/users/${userId}/accounts/${accountId}`;
  return (
    <Table
      rows={[
        {
          label: 'Id',
          value: <SupportToolLink path={path}>{accountId}</SupportToolLink>,
        },
        {
          label: 'Owner',
          value: `${ownerName} ${ownerEmail}`,
        },
        {
          label: 'Your role',
          value: role,
        },
        {
          label: 'Legacy collabs',
          value: <Flag value={hasLegacyCollabs} />,
        },
      ]}
    />
  );
}
Account.propTypes = {
  accountId: PropTypes.string,
  hasLegacyCollabs: PropTypes.bool.isRequired,
  ownerEmail: PropTypes.string,
  ownerName: PropTypes.string,
  role: PropTypes.string,
  userId: PropTypes.string.isRequired,
};

Account.defaultProps = {
  accountId: undefined,
  ownerEmail: undefined,
  ownerName: undefined,
  role: undefined,
};

function Subscription({
  accountId,
  subscription: { id, on_trial: onTrial },
  userId,
}) {
  const path = `/users/${userId}/accounts/${accountId}/subscriptions/${id}`;
  return (
    <Table
      rows={[
        {
          label: 'Id',
          value: <SupportToolLink path={path}>{id}</SupportToolLink>,
        },
        {
          label: 'On trial',
          value: <Flag value={onTrial} />,
        },
      ]}
    />
  );
}
Subscription.propTypes = {
  accountId: PropTypes.string.isRequired,
  subscription: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
};

function Plan({ plan: { enterprise, id, name, version } }) {
  return (
    <Table
      rows={[
        {
          label: 'Id',
          value: <SupportToolLink path={`/plans/${id}`}>{id}</SupportToolLink>,
        },
        {
          label: 'Name',
          value: `${name} (v${version})`,
        },
        {
          label: 'Enterprise',
          value: <Flag value={enterprise} />,
        },
      ]}
    />
  );
}
Plan.propTypes = {
  plan: PropTypes.object.isRequired,
};

const ClientVersion = ({ copyVersion }) => {
  const handleCopyClick = () => copyVersion(clientVersion);

  return (
    <StyledTable>
      <tbody>
        <tr>
          <Th>Version</Th>
          <Td>{clientVersion}</Td>
          <Td />
          <Td>
            <CopyButton onClick={handleCopyClick}>
              <CopyIcon />
            </CopyButton>
          </Td>
        </tr>
      </tbody>
    </StyledTable>
  );
};

ClientVersion.propTypes = { copyVersion: PropTypes.func.isRequired };

export default function Context({
  accountId,
  asset,
  copyVersion,
  hasLegacyCollabs,
  ownerEmail,
  ownerName,
  plan,
  role,
  subscription,
  team,
  user,
}) {
  return (
    <Container>
      {circleBuildUrl && (
        <section>
          <SectionTitle>CircleCI build</SectionTitle>
          <div>
            <ExternalLink href={circleBuildUrl}>
              {circleBuildNumber}
            </ExternalLink>
          </div>
        </section>
      )}
      {user && (
        <section>
          <SectionTitle>You</SectionTitle>
          <User user={user} />
        </section>
      )}
      {team && (
        <section>
          <SectionTitle>Team</SectionTitle>
          <Team team={team} accountId={accountId} userId={user.id} />
        </section>
      )}
      {accountId && (
        <section>
          <SectionTitle>Account</SectionTitle>
          <Account
            accountId={accountId}
            ownerEmail={ownerEmail}
            ownerName={ownerName}
            userId={user.id}
            role={role}
            hasLegacyCollabs={hasLegacyCollabs}
          />
        </section>
      )}
      {plan && (
        <section>
          <SectionTitle>Plan</SectionTitle>
          <Plan plan={plan} />
        </section>
      )}
      {subscription && (
        <section>
          <SectionTitle>Subscription</SectionTitle>
          <Subscription
            accountId={accountId}
            userId={user.id}
            subscription={subscription}
          />
        </section>
      )}
      {asset && (
        <section>
          <SectionTitle>Asset</SectionTitle>
          <Asset asset={asset} />
        </section>
      )}
      {clientVersion && (
        <section>
          <SectionTitle>Client Version</SectionTitle>
          <ClientVersion copyVersion={copyVersion} />
        </section>
      )}
      {!isProduction && (
        <section>
          <SectionTitle>Auth</SectionTitle>
          <ExpireAuthToken />
          <InvalidAuthToken />
          <DeleteRefreshToken />
        </section>
      )}
    </Container>
  );
}
Context.propTypes = {
  accountId: PropTypes.string,
  hasLegacyCollabs: PropTypes.bool,
  ownerEmail: PropTypes.string,
  ownerName: PropTypes.string,
  plan: PropTypes.object,
  role: PropTypes.string,
  subscription: PropTypes.object,
  team: PropTypes.object,
  user: PropTypes.object,
};
Context.defaultProps = {
  accountId: undefined,
  hasLegacyCollabs: undefined,
  ownerEmail: undefined,
  ownerName: undefined,
  plan: undefined,
  role: undefined,
  subscription: undefined,
  team: undefined,
  user: undefined,
};
