import reduceReducers from 'reduce-reducers';
import { identity } from 'lodash';

import {
  generatePaginatedListReducer,
  INITIAL_PAGINATED_LIST_STATE,
} from '@frameio/core/src/shared/reducers/factories';
import { paginatedListPageMutationReducer } from '@frameio/core/src/shared/reducers/helpers';
import { paginatedListAllResultsSelector } from '@frameio/core/src/shared/selectors';
import { presentationEntitiesSelector } from '@frameio/core/src/presentations/selectors';
import { PROJECT_PRESENTATIONS } from './actions';
import SORT_OPTIONS, {
  sortPresentationIds as sortPresentationIdsHelper,
} from './sortOptions';

export const INITIAL_STATE = {
  ...INITIAL_PAGINATED_LIST_STATE,
  isSortDescending: true,
  sortBy: SORT_OPTIONS.CREATED_AT.value,
  pageSize: 40,
};

const presentationsListReducer = generatePaginatedListReducer(
  PROJECT_PRESENTATIONS.FETCH
);

function presentationsSortReducer(state, { type, payload }) {
  if (type !== PROJECT_PRESENTATIONS.SORT) {
    return state;
  }

  const {
    option: { value: sortBy },
    isDescending: isSortDescending,
  } = payload;

  return {
    ...state,
    sortBy,
    isSortDescending,
  };
}

function removeDeletedPresentationById(state, id) {
  return paginatedListPageMutationReducer(state, (page, presentation) =>
    presentation.filter((presentationId) => presentationId !== id)
  );
}

function sortPresentationIds(state, rootState, presentationIds) {
  const { isSortDescending, sortBy } = state;
  const presentationIdsToSort =
    presentationIds || paginatedListAllResultsSelector(state).filter(identity);
  const presentationEntities = presentationEntitiesSelector(rootState);

  const sortedPresentationIds = sortPresentationIdsHelper(
    presentationIdsToSort,
    presentationEntities,
    sortBy,
    isSortDescending,
    rootState
  );

  return paginatedListPageMutationReducer(state, (page) =>
    page === 1 ? sortedPresentationIds : []
  );
}

function insertPresentation(state, rootState, id) {
  const presentationIds = paginatedListAllResultsSelector(state).filter(
    identity
  );
  const presentationIdsToSort = [...presentationIds, id];
  return sortPresentationIds(state, rootState, presentationIdsToSort);
}

// NOTE [ENT-1617] A lot of this logic is duplicated from ProjectLinks/reducers.
// Maybe that's okay. But there could be a useful abstraction to draw here that
// reduce code duplication?
function presentationsMutationReducer(state, action, rootState) {
  switch (action.type) {
    case PROJECT_PRESENTATIONS.DELETE.BASE: {
      const { id } = action.payload;
      return removeDeletedPresentationById(state, id);
    }

    case PROJECT_PRESENTATIONS.DELETE.FAILURE: {
      const { entityId: id } = action.payload;
      return insertPresentation(state, rootState, id);
    }

    case PROJECT_PRESENTATIONS.PATCH_SORT: {
      return sortPresentationIds(state, rootState);
    }

    default:
      return state;
  }
}

export default reduceReducers(
  presentationsListReducer,
  presentationsSortReducer,
  presentationsMutationReducer
);
