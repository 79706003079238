import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Spinner from '@frameio/components/src/styled-components/SpinnerBase';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: ${(p) => p.theme.spacing.units(1)};
`;

const Label = styled.div`
  margin-left: ${(p) => p.theme.spacing.units(1)};
  color: ${(p) => p.theme.color.gray};
`;

// Our icons typically have some padding. Let's do the
// same for our spinner.
const StyledSpinner = styled(Spinner)`
  padding: 2px;
`;

/**
 * The spinner + label used in place of content while
 * a search is being performed.
 */
function SearchingSpinner({ className, label = 'Searching' }) {
  return (
    <Container className={className}>
      <StyledSpinner spinning radius={9} color="125,130,156" stroke={2} />
      <Label>{label}&hellip;</Label>
    </Container>
  );
}

SearchingSpinner.propTypes = {
  label: PropTypes.string,
};

export default SearchingSpinner;
