import styled from 'styled-components';
import { rgba } from 'polished';
import TextInput from '@frameio/components/src/styled-components/TextInput';
import { SMALL } from 'utils/mediaQueries';

const PROMPT_SPACING = '30px';
export const TRANSLATE_X = '40px';

export const PromptTitleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const IconContainer = styled.div`
  margin-right: ${(p) => p.theme.spacing.small};
`;

export const PromptTitle = styled.h2`
  font-size: ${(p) => p.theme.fontSize[3]};
  text-align: center;
  color: ${(p) => p.theme.color.coldWhite};
  @media ${SMALL} {
    font-size: 16px;
  }
`;

export const PromptSubtext = styled.div`
  color: ${(p) => p.theme.color.gray};
  font-size: ${(p) => p.theme.fontSize[2]};
  margin-bottom: ${PROMPT_SPACING};
`;

export const PromptInput = styled(TextInput)`
  margin-bottom: ${PROMPT_SPACING};
`;

export const SubmitContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const PromptContainer = styled.div`
  position: relative;
  border-radius: ${(p) => p.theme.radius.large};
  background-color: ${({ theme }) => rgba(theme.color.white, 0.06)};
  padding: ${PROMPT_SPACING};

  @media ${SMALL} {
    padding: 20px;
  }
`;
