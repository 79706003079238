import { connect } from 'react-redux';
import { returnToAccount } from '../actions';
import Success from './Success';

const mapDispatchToProps = {
  onClick: returnToAccount,
};

export default connect(
  null,
  mapDispatchToProps
)(Success);
