import {
  generateActionTypes,
  generateFetchActionTypes,
} from 'shared/actions/helpers';
import { createAction } from 'redux-actions';

const module = 'SESSION_WATERMARK_TEMPLATE_EDITOR';
const SESSION_WATERMARK_TEMPLATE_EDITOR = {
  ...generateFetchActionTypes(module, [
    'GET',
    'SUBMIT',
    'CREATE',
    'CREATE_FOR_REVIEW_LINK',
    'CREATE_FOR_PRESENTATION',
    'REVERT_FOR_REVIEW_LINK',
    'REVERT_FOR_PRESENTATION',
    'UPDATE_FOR_REVIEW_LINK',
    'UPDATE_FOR_PRESENTATION',
    'UPDATE',
    'DELETE',
  ]),
  ...generateActionTypes(module, ['OPEN', 'CONFIRM_CLOSE']),
};

export const openSessionWatermarkTemplateEditor = createAction(
  SESSION_WATERMARK_TEMPLATE_EDITOR.OPEN,
  (
    watermarkTemplateId,
    onClose,
    initialValues,
    sourceName = '',
    isShareOnlyTemplate
  ) => ({
    watermarkTemplateId,
    onClose,
    initialValues,
    sourceName,
    isShareOnlyTemplate,
  })
);

export const onSubmit = createAction(
  SESSION_WATERMARK_TEMPLATE_EDITOR.SUBMIT.BASE,
  (accountId, params, watermarkTemplateId, onSuccess, onError) => ({
    accountId,
    params,
    watermarkTemplateId,
    onSuccess,
    onError,
  })
);

export const onSubmitSuccess = createAction(
  SESSION_WATERMARK_TEMPLATE_EDITOR.SUBMIT.SUCCESS,
  (result) => ({ result })
);

export const openUnsavedChangesDialog = createAction(
  SESSION_WATERMARK_TEMPLATE_EDITOR.CONFIRM_CLOSE,
  (header, body, options) => ({ header, body, options })
);

export const deleteSessionWatermarkTemplate = createAction(
  SESSION_WATERMARK_TEMPLATE_EDITOR.DELETE.BASE,
  (watermarkTemplateId) => ({ watermarkTemplateId })
);

export const getSessionWatermarkTemplate = createAction(
  SESSION_WATERMARK_TEMPLATE_EDITOR.GET.BASE,
  (accountId, watermarkTemplateId) => ({ accountId, watermarkTemplateId })
);

export const revertSessionWatermarkTemplateForReviewLink = createAction(
  SESSION_WATERMARK_TEMPLATE_EDITOR.REVERT_FOR_REVIEW_LINK.BASE,
  (reviewLinkId, watermarkTemplateId) => ({ watermarkTemplateId, reviewLinkId })
);

export const revertSessionWatermarkTemplateForPresentation = createAction(
  SESSION_WATERMARK_TEMPLATE_EDITOR.REVERT_FOR_PRESENTATION.BASE,
  (presentationId, watermarkTemplateId) => ({
    watermarkTemplateId,
    presentationId,
  })
);

export default SESSION_WATERMARK_TEMPLATE_EDITOR;
