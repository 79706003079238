import React from 'react';
import PropTypes from 'prop-types';
import Success from './Success';
import InitialScreen from './InitialScreen';
import { ENTITY_TYPE } from '../AuthGate/AuthGate';

export default class RequestAccessToEntity extends React.Component {
  state = {
    hasRequestedAccessToEntity: false,
  };

  render() {
    const { entityType, entityId } = this.props;
    return (
      <React.Fragment>
        {this.state.hasRequestedAccessToEntity ? (
          <Success entityType={entityType} />
        ) : (
          <InitialScreen
            entityType={entityType}
            entityId={entityId}
            toggleHasRequestedAccessToEntity={() =>
              this.setState({ hasRequestedAccessToEntity: true })
            }
          />
        )}
      </React.Fragment>
    );
  }
}

RequestAccessToEntity.propTypes = {
  entityType: PropTypes.oneOf(Object.values(ENTITY_TYPE)).isRequired,
  entityId: PropTypes.string.isRequired,
};
