/* eslint-disable import/prefer-default-export */
import React from 'react';
import { matchPath } from 'react-router-dom';
import {
  takeEvery,
  call,
  select,
  put,
  race,
  take,
  spawn,
} from 'redux-saga/effects';
import queryString from 'query-string';
import track from 'analytics';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import { reviewLinkEntitySelector } from '@frameio/core/src/reviewLinks/selectors';
import { presentationEntityByVanitySelector } from '@frameio/core/src/presentations/selectors';
import { type as AssetType } from '@frameio/core/src/assets/helpers/constants';
import { currentUserSelector } from 'selectors/users';
import { currentAccountSelector } from 'selectors/accounts';
import { currentProjectSelector } from 'selectors/projects';
import { openModal, closeModal } from 'components/Modal/actions';
import { PROJECT_CONTAINER } from 'pages/ProjectContainer/actions';
import { resolutionToResolutionField } from 'utils/downloads';
import {
  isPathNameReviewPage,
  isPathNamePresentationPage,
  REVIEW_LINK_URL,
  PRESENTATION_URL,
} from 'URLs';
import { isTurnStylePromptDevice } from '../../../utils/devices';
import DownloadFoldersModal from './DownloadFolders';
import DownloadWithDesktopAppModal from './DownloadWithDesktopApp';
import LaunchDesktopAppModal from './LaunchDesktopApp';
import { DESKTOP_APP, openURLInDesktopApp } from './actions';
import { DOWNLOAD_IN_DESKTOP_APP, IFRAME_ID } from './constants';
import animationDataUrl from './DesktopApp.animation.json';

/**
 * Composes the protocol url string
 * @param {Array<string>} assetIds An array of assetIds
 * @param {Number} resolution The resolution number
 * @param {Boolean} downloadAll (optional) Defaults to false. A flag
 * that tells the transfer app when a use clicked the "Download All" button
 * in a Review Link
 * @returns {string} The protocol url
 */
function* createProtocolURL(assetIds, resolution, downloadAll = false) {
  const { id: userId } = yield select(currentUserSelector);
  const { id: accountId } = yield select(currentAccountSelector);
  const { id: projectId, name: projectName } = yield select(
    currentProjectSelector
  );
  const asset = yield select(assetEntitySelector, { assetId: assetIds[0] });
  const jobName = asset.name === 'root' ? projectName : asset.name;

  let props = {
    assetIds: assetIds.join(','),
    userId,
    accountId,
    projectId,
    name: jobName,
    type: asset.type,
    proxy: resolutionToResolutionField[resolution] || '',
  };

  const { pathname } = window.location;

  if (isPathNameReviewPage(pathname)) {
    const { params } = matchPath(pathname, {
      path: REVIEW_LINK_URL,
    });
    const { reviewLinkId } = params;
    const { password } = yield select(reviewLinkEntitySelector, {
      reviewLinkId,
    });
    // TODO(INTS-672, Thomas): Remove assetIds when downloadAll is true.
    // After Transfer v1.0.4, the app will fetch all assets of a review link by itself.
    // This way we're reducing the size of the protocol url string.
    props = { ...props, reviewLinkId, password, downloadAll };
  }

  if (isPathNamePresentationPage(pathname)) {
    const { params } = matchPath(pathname, {
      path: PRESENTATION_URL,
    });
    const { vanityId: vanity } = params;
    const { password, id: presentationId } = yield select(
      presentationEntityByVanitySelector,
      { vanity }
    );
    props = { ...props, presentationId, vanity, password };
  }

  const query = queryString.stringify(props);
  return `frameio-transfer://download?${query}`;
}

/**
 * Opens the protocol url in an iframe because Safari and Firefox
 * opens a new window when we do `location.assign(url)`
 * @param {Action} action Redux action
 * @param {string} action.payload.url The protocol url
 */
function openProtocolURL({ payload: { url } }) {
  let iframe = document.getElementById(IFRAME_ID);
  if (!iframe) {
    iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
  }
  iframe.setAttribute('id', IFRAME_ID);
  iframe.setAttribute('src', url);
}

/**
 * Removes the iframe from the DOM that we needed in order
 * to open the protocol url in the desktop app
 */
function cleanUp() {
  const iframe = document.getElementById(IFRAME_ID);
  if (iframe) iframe.parentNode.removeChild(iframe);
}

/**
 * Opens a modal which asks the user if downloads should be done in the
 * desktop app or browser. Also, optionally persists the user's decision
 * to localstorage.
 * @returns {boolean} If the user wants to download in the desktop app
 */
function* promptDownloadDesktopAppModal(hasFolders) {
  yield put(
    openModal(
      hasFolders ? <DownloadFoldersModal /> : <DownloadWithDesktopAppModal />
    )
  );
  yield spawn(track, 'download-with-transfer-modal-shown');
  const selectedAction = yield race({
    appConfirmed: take(DESKTOP_APP.CONFIRM_DOWNLOAD_IN_APP),
    browserConfirmed: take(DESKTOP_APP.CONFIRM_DOWNLOAD_IN_BROWSER),
    isCanceled: take(DESKTOP_APP.CANCEL_MODAL),
  });
  yield put(closeModal());
  return selectedAction;
}

/**
 * Opens a modal that informs the user that we're launching the download
 * in the desktop app. Also allows the user to download the app itself.
 * @param {string} url Protocol url
 * @returns {boolean} If the user wants to download in the desktop app
 */
function* promptLaunchDesktopAppModal(url) {
  yield put(openModal(<LaunchDesktopAppModal url={url} />));
  yield spawn(track, 'launching-transfer-modal-shown');
  const { confirmed } = yield race({
    canceled: take(DESKTOP_APP.CANCEL_MODAL),
    confirmed: take(DESKTOP_APP.CONFIRM_DOWNLOAD_IN_BROWSER),
  });
  yield put(closeModal());
  return !!confirmed;
}

/**
 * Before we show the user the download preferences (app or browser)
 * we check if it's either a folder, 2+ assets or a big asset.
 * @param {Array<string>} assetIds Array of asset ids
 */
function* checkAssetsFulfillCriteria(assetIds) {
  if (assetIds.length === 0) return false;

  // more than two assets qualify for a funky desktop download
  if (assetIds.length >= 2) return true;

  // if a single asset is selected

  // always download folders in the desktop app
  const asset = yield select(assetEntitySelector, { assetId: assetIds[0] });
  if (asset.type === AssetType.FOLDER) return true;

  // if the asset is bigger than 2GB
  const minSize = 1024 * 1024 * 1024 * 2;
  if (asset.filesize > minSize) return true;

  return false;
}

/**
 * Asks the user if downloads should be handled in `Frame.io Transfer` desktop app.
 * If agreed, downloads the given assets using the desktop app.
 * @param {Array<string>} assetIds An array of assetIds
 * @param {Number} resolution The resolution number
 * @returns {boolean} If the user chooses to download in the desktop app
 */
export function* downloadInAppIfRequired(
  assetIds,
  resolution,
  hasFolder = false,
  downloadAll = false
) {
  // Check if the user has set a preference earlier for downloading in the app
  const userPref = localStorage.getItem(DOWNLOAD_IN_DESKTOP_APP);

  // Return if preference says that user decided to always download in the browser
  if (userPref === 'false') return { isDownloadingInApp: false };

  // If assets do not meet criteria, return false
  const isEligibleForDownloadPrompt = yield call(
    checkAssetsFulfillCriteria,
    assetIds
  );

  if (!isEligibleForDownloadPrompt || isTurnStylePromptDevice)
    return { isDownloadingInApp: false };

  // If preference has not been set yet, let's open the modal and
  // propose to download the asset(s) in the desktop app
  if (!userPref) {
    const { browserConfirmed, isCanceled } = yield call(
      promptDownloadDesktopAppModal,
      hasFolder
    );

    // Ok got it, downloading in the browser is your thing... well then.
    if (browserConfirmed) return { isDownloadingInApp: false };

    // If canceled, return
    if (isCanceled) return { isCanceled };
  }

  // If we got this far then we know the user chose to download in the app.
  // So let's not wait and download those goodies.

  // Create that protocol url which unleashes all the magic
  const url = yield call(createProtocolURL, assetIds, resolution, downloadAll);

  // Open the protocol url in an iframe because Safari and Firefox
  // opens a new window when we do `location.assign(url)`... which we really don't want.
  yield put(openURLInDesktopApp(url));

  // By now the user should be amazed how freakin awesome modern technology works.
  // But in case nothing happens (because the app might not be installed),
  // let's inform the user with a modal that awesomeness is about to happen.
  const browserDownloadConfirmed = yield call(promptLaunchDesktopAppModal, url);

  // Remove the iframe that we needed for launching opening the protocol url
  cleanUp();

  // Let's tell the caller of this function that we're
  // handling the download in the desktop app.
  return { isDownloadingInApp: !browserDownloadConfirmed };
}

/**
 * Lazy loads large animation JSON
 */
function* preloadModalAnimationData() {
  yield fetch(animationDataUrl);
}

export default [
  takeEvery(DESKTOP_APP.OPEN_URL_IN_DESKTOP_APP, openProtocolURL),
  takeEvery(PROJECT_CONTAINER.ASSETS_LOADED, preloadModalAnimationData),
];

export const testExports = {
  checkAssetsFulfillCriteria,
  createProtocolURL,
  promptDownloadDesktopAppModal,
  promptLaunchDesktopAppModal,
};
