import { connect } from 'react-redux';
import { teamEntitiesForAccountIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { currentAccountSelector } from 'selectors/accounts';
import SearchResults from './SearchResults';

export function mapStateToProps(state) {
  const { id: accountId } = currentAccountSelector(state);
  const teams = teamEntitiesForAccountIdSelector(state, { accountId });

  return {
    accountId,
    teams,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResults);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
