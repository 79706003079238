import { generateActionTypes } from 'shared/actions/helpers';

const REDUX_PAUSE = generateActionTypes('REDUX_PAUSE', ['PAUSE', 'RESUME']);

export default REDUX_PAUSE;

export function pauseRedux() {
  return {
    type: REDUX_PAUSE.PAUSE,
    payload: {},
  };
}

export function resumeRedux() {
  return {
    type: REDUX_PAUSE.RESUME,
    payload: {},
  };
}
