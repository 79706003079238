import reduceReducers from 'reduce-reducers';
import { identity } from 'lodash';

import {
  generatePaginatedListReducer,
  INITIAL_PAGINATED_LIST_STATE,
} from '@frameio/core/src/shared/reducers/factories';
import { paginatedListPageMutationReducer } from '@frameio/core/src/shared/reducers/helpers';
import { paginatedListAllResultsSelector } from '@frameio/core/src/shared/selectors';

import { PROJECT_DEVICES } from './actions';
import { connectionStatus } from './ProjectDevice/ProjectDeviceChannel';

export const INITIAL_STATE = {
  ...INITIAL_PAGINATED_LIST_STATE,
  projectId: null,
  currentAuthorizationId: null,
};

// SORT helpers
function statusValue(status) {
  switch (status) {
    case connectionStatus.RECORDING:
      return 3;
    case connectionStatus.ONLINE:
      return 2;
    case connectionStatus.PAUSED:
      return 1;
    case connectionStatus.OFFLINE:
      return 0;
    default:
      return 0;
  }
}

const collator = new Intl.Collator('en', {
  numeric: true,
  sensitivity: 'base',
});

function sortProjectDevices(rootState, projectDeviceIds) {
  const projectDevices = rootState.entities.projectDevices.entities;

  return projectDeviceIds.sort((idA, idB) => {
    const a = projectDevices[idA];
    const b = projectDevices[idB];

    if (statusValue(a.status) < statusValue(b.status)) return 1;
    if (statusValue(a.status) > statusValue(b.status)) return -1;

    return collator.compare(a.name, b.name);
  });
}

// STATE helper functions
function insertProjectDevice(state, rootState, id) {
  const projectDeviceIds = paginatedListAllResultsSelector(state).filter(
    identity
  );

  // Dont add duplicates.
  if (projectDeviceIds.indexOf(id) === -1) {
    projectDeviceIds.push(id);
  }

  const sortedProjectDeviceIds = sortProjectDevices(
    rootState,
    projectDeviceIds
  );

  return paginatedListPageMutationReducer(state, () => sortedProjectDeviceIds);
}

function removeDeletedProjectDeviceById(state, id) {
  return paginatedListPageMutationReducer(state, (_, projectDevices) =>
    projectDevices.filter((projectDeviceId) => projectDeviceId !== id)
  );
}

function removeAllProjectDevices(state) {
  return paginatedListPageMutationReducer(state, () => []);
}

// REDUCERS
const projectDevicesListReducer = generatePaginatedListReducer(
  PROJECT_DEVICES.FETCH
);

function projectDevicesMutationReducer(state, action, rootState) {
  switch (action.type) {
    case PROJECT_DEVICES.DELETE.BASE: {
      const { id } = action.payload;
      return removeDeletedProjectDeviceById(state, id);
    }

    case PROJECT_DEVICES.DELETE_ALL.BASE: {
      return removeAllProjectDevices(state);
    }

    case PROJECT_DEVICES.DELETE.FAILURE: {
      const { id } = action.payload;
      return insertProjectDevice(state, rootState, id);
    }

    case PROJECT_DEVICES.DELETE_ALL.FAILURE: {
      const { projectDeviceIds } = action.payload;
      return paginatedListPageMutationReducer(state, () => projectDeviceIds);
    }

    case PROJECT_DEVICES.GET.SUCCESS: {
      const { result: id } = action.payload.response;
      return {
        ...insertProjectDevice(state, rootState, id),
        currentAuthorizationId: id,
      };
    }

    case PROJECT_DEVICES.FETCH.SUCCESS: {
      const projectDeviceIds = paginatedListAllResultsSelector(state).filter(
        identity
      );
      return paginatedListPageMutationReducer(state, () =>
        sortProjectDevices(rootState, projectDeviceIds)
      );
    }

    case PROJECT_DEVICES.AUTHORIZATION_CLEAR: {
      return { ...state, currentAuthorizationId: null };
    }

    default:
      return state;
  }
}

function projectIdReducer(state, { type, payload }) {
  if (type !== PROJECT_DEVICES.FETCH.BASE) return state;

  return {
    ...state,
    projectId: payload.projectId,
  };
}

export default reduceReducers(
  projectIdReducer,
  projectDevicesListReducer,
  projectDevicesMutationReducer
);
