import Raven from 'raven-js';
import { takeEvery, put, call, select } from 'redux-saga/effects';
import { joinTeamRoom, leaveTeamRoom } from '@frameio/core/src/sockets/actions';
import { resetCustomActions } from '@frameio/core/src/customActions/actions';
import { getCustomActionsByTeam } from '@frameio/core/src/customActions/sagas';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import { updateTeam as updateTeamSaga } from '@frameio/core/src/teams/sagas';
import { teamEntitySelector } from '@frameio/core/src/teams/selectors';
import { TEAM } from 'actions/teams';

function* setCurrentTeam({ payload: { teamId, prevTeamId } }) {
  if (prevTeamId) {
    yield put(leaveTeamRoom(prevTeamId));
  }
  yield put(joinTeamRoom(teamId));

  // On every team change, we want to fully reset the custom actions in state and
  // load in the next set for the current team context.
  yield put(resetCustomActions());

  if (!teamId) {
    yield call(
      [Raven, Raven.captureMessage],
      'BUGS-2036: getCustomActionsByTeam (GET v2/teams/{teamId}/actions) teamId undefined',
      {
        tags: { jira_ticket: 'BUGS-2036' },
      }
    );
  }
  yield call(getCustomActionsByTeam, teamId);
}

function* updateTeamForensicWatermarkSetting({
  payload: { teamId, newValue },
}) {
  const { failure } = yield call(updateTeamSaga, teamId, {
    disable_fwm_internally: newValue,
  });

  const team = yield select(teamEntitySelector, { teamId });

  if (failure) {
    showErrorToast({
      header: 'There was a problem with your request.',
    });
    return;
  }

  yield put(
    showSuccessToast({
      header: newValue
        ? `Disabled Forensic Watermark ID in ${team.name}`
        : `Enabled Forensic Watermark ID in ${team.name}`,
    })
  );
}

function* updateTeamInternalWatermarkSetting({
  payload: { teamId, newValue },
}) {
  const { failure } = yield call(updateTeamSaga, teamId, {
    disable_sbwm_internally: newValue,
  });

  const team = yield select(teamEntitySelector, { teamId });

  if (failure) {
    showErrorToast({
      header: 'There was a problem with your request.',
    });
    return;
  }

  yield put(
    showSuccessToast({
      header: newValue
        ? `Disabled internal Watermark ID in ${team.name}`
        : `Enabled internal Watermark ID in ${team.name}`,
    })
  );
}

export default [
  takeEvery(TEAM.SET_CURRENT, setCurrentTeam),
  takeEvery(
    TEAM.UPDATE_INTERNAL_WATERMARK_SETTING,
    updateTeamInternalWatermarkSetting
  ),
  takeEvery(
    TEAM.UPDATE_FORENSIC_WATERMARK_SETTING,
    updateTeamForensicWatermarkSetting
  ),
];

export const testExports = {
  setCurrentTeam,
  updateTeamInternalWatermarkSetting,
  updateTeamForensicWatermarkSetting,
};
