import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import freeze from 'redux-freeze';
import { isLocal, isProduction } from 'config';
import Raven from 'raven-js';
import createRavenMiddleware from 'raven-for-redux';
import { routerMiddleware } from 'connected-react-router';
import persistState, { mergePersistedState } from 'redux-localstorage';
import adapter from 'redux-localstorage/lib/adapters/localStorage';
import filter from 'redux-localstorage-filter';
import { composeWithDevTools } from 'redux-devtools-extension';
import { composeWithDevTools as composeWithDevToolsLogOnly } from 'redux-devtools-extension/logOnly';
import { merge } from 'lodash';
import coreSagas from '@frameio/core/src/sagas';

import clientSagas from 'sagas';
import history from 'browserHistory';
import rootReducerFactory from 'reducers/rootReducer';
import reduxPauseMiddleware from 'redux-pause/middleware';
import { assetsSortBySelector } from 'pages/ProjectContainer/selectors';
import SORT_OPTIONS from 'pages/ProjectContainer/ProjectAssets/sortOptions';
import { actionSanitizer as muxActionSanitizer } from 'components/mux/actions';

function* sagas() {
  yield all(coreSagas.concat(clientSagas));
}

const routeMiddleware = routerMiddleware(history);

const USER_PREFERENCES_STORAGE_KEY = 'user-preferences';

// these should be the path to the redux slice
const userPreferencePaths = [
  'accountContainer.search.sortOptions',
  'accountContainer.search.viewType',
  'lastViewedAccountId',
  'playerContainer.defaultPlaybackRate',
  'playerContainer.showAllAnnotations',
  'playerContainer.showComments',
  'projectContainer.assetsSortBy',
  'projectContainer.areAssetsSortDescending',
  'projectContainer.assetsViewType',
];

const storage = compose(filter(userPreferencePaths))(
  adapter(window.localStorage)
);

// https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#actionsanitizer--statesanitizer
function actionSanitizer(action) {
  return muxActionSanitizer(action);
}

function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    createRavenMiddleware(Raven, {
      stateTransformer: (state) => {
        const { entities, ...restState } = state;
        return restState;
      },
    }), // needs to be first argument to applyMiddleware
    reduxPauseMiddleware(),
    sagaMiddleware,
    routeMiddleware,
  ];
  if (isLocal) middlewares.push(freeze);

  const composeEnhancers = isProduction
    ? composeWithDevToolsLogOnly
    : composeWithDevTools;

  const enhancer = composeEnhancers({ actionSanitizer })(
    applyMiddleware(...middlewares),
    persistState(storage, USER_PREFERENCES_STORAGE_KEY)
  );

  // merges user preferences from localStorage into Redux store
  const reducer = compose(
    mergePersistedState((initialState, persistedState) => {
      // BUGS-767: After changing the RECENCY sort field, we need to make sure that any user with
      // the old value in their local storage gets the new value. Until we move to using the
      // preferences endpoint, we'll have to do it here.
      if (assetsSortBySelector(persistedState) === 'inserted_at') {
        // eslint-disable-next-line no-param-reassign
        persistedState.projectContainer.assetsSortBy =
          SORT_OPTIONS.RECENCY.VALUE;
      }
      return merge({}, initialState, persistedState);
    })
  )(rootReducerFactory(history));

  const store = createStore(reducer, enhancer);

  sagaMiddleware.run(sagas);
  return store;
}

export default configureStore();
