import { connect } from 'react-redux';
import { showErrorToast, showDropboxToast } from 'actions/toasts';
import PublishAssetsToDropbox from './PublishAssetsToDropbox';

const mapDispatchToProps = {
  showErrorToast,
  showDropboxToast,
};

export default connect(
  (state) => state,
  mapDispatchToProps
)(PublishAssetsToDropbox);

export const testExports = {
  mapDispatchToProps,
};
