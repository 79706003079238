import { call, select, takeLatest } from 'redux-saga/effects';
import {
  getRoleForAccount as getRoleForAccountCoreSaga,
  getRoleForProject as getRoleForProjectCoreSaga,
  getRoleForTeam as getRoleForTeamCoreSaga,
} from '@frameio/core/src/roles/sagas';
import {
  shouldFetchAccountRole as shouldFetchAccountRoleSelector,
  shouldFetchProjectRole as shouldFetchProjectRoleSelector,
  shouldFetchTeamRole as shouldFetchTeamRoleSelector,
} from 'selectors/roles';
import { ACCOUNT } from 'actions/accounts';
import { ROLE } from 'actions/roles';
import { TEAM } from 'actions/teams';

export function* getRoleForAccount({ payload: { accountId } }) {
  const shouldFetchAccountRole = yield select(shouldFetchAccountRoleSelector, {
    accountId,
  });

  if (shouldFetchAccountRole) {
    yield call(getRoleForAccountCoreSaga, accountId);
  }
}
export function* getRoleForProject({ payload: { projectId, shouldFetch } }) {
  const shouldFetchProjectRole = yield select(shouldFetchProjectRoleSelector, {
    projectId,
  });

  if (shouldFetchProjectRole || shouldFetch) {
    yield call(getRoleForProjectCoreSaga, projectId);
  }
}

export function* getRoleForTeam({ payload: { teamId } }) {
  const shouldFetchTeamRole = yield select(shouldFetchTeamRoleSelector, {
    teamId,
  });

  if (shouldFetchTeamRole) {
    yield call(getRoleForTeamCoreSaga, teamId);
  }
}

// TODO: Fixme - these roles are fetched simultaneously with their setCurrent* sagas,
// therefore it is possible they could be causing a race condition if the saga depends on a role
// See this PR for more info: https://github.com/Frameio/web-client/pull/9587
export default [
  takeLatest(ACCOUNT.SET_CURRENT, getRoleForAccount),
  takeLatest(ROLE.GET_FOR_ACCOUNT, getRoleForAccount),
  takeLatest(ROLE.GET_FOR_PROJECT, getRoleForProject),
  takeLatest(ROLE.GET_FOR_TEAM, getRoleForTeam),
  takeLatest(TEAM.SET_CURRENT, getRoleForTeam),
];

export const testExports = {
  getRoleForAccount,
  getRoleForProject,
  getRoleForTeam,
};
