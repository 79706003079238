import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import styled, { withTheme } from 'styled-components';
import Flex from 'styled-flex-component';
import Button from '@frameio/components/src/styled-components/Button';
import CancelIcon from '@frameio/components/src/svgs/icons/cancel.svg';
import { MEDIUM_UP } from 'utils/mediaQueries';
import track, { trackButtonClick } from 'analytics';
import { once, pick, noop, times } from 'lodash';
import EmailFields from './EmailFields';
import Header from './Header';

const NUMBER_OF_TEAM_MEMBERS = 3;
const MUST_BE_AN_EMAIL_ADDRESS = 'Must be an email address';
const ERROR_MESSAGE_HEIGHT = '32px';

const Container = styled(Flex).attrs(() => ({
  column: true,
}))`
  height: ${({ theme }) =>
    `calc(${theme.spacing.units(43)} + ${ERROR_MESSAGE_HEIGHT})`};
  max-width: ${(p) => p.theme.spacing.units(50)};
  padding: ${(p) => p.theme.spacing.medium};

  @media ${MEDIUM_UP} {
    height: ${({ theme }) =>
      `calc(${theme.spacing.units(41)} + ${ERROR_MESSAGE_HEIGHT})`};
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: ${(p) => p.theme.spacing.small};
  right: ${(p) => p.theme.spacing.small};
`;

const emailFieldNames = times(
  NUMBER_OF_TEAM_MEMBERS,
  (index) => `teamMemberEmail${index}`
);

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .AddTeamMembers__EmailFieldsContainer {
    flex-grow: 1;
  }
`;

export class InnerForm extends React.Component {
  componentDidUpdate() {
    const { touched, errors } = this.props;
    if (touched && !!errors) {
      this.trackErrorEvent();
    }
  }

  trackErrorEvent = once(() => {
    track('form-field-errored', {
      page: this.props.trackingPage,
      field: 'email',
    });
  });

  trackStartEvent = once(() => {
    track('form-field-started', {
      page: this.props.trackingPage,
      field: 'email',
    });
  });

  render() {
    const {
      children,
      onClose,
      handleSubmit,
      handleChange,
      handleBlur,
      isValid,
      touched,
      errors,
      isPaidCollaboratorsPlan,
      showCloseButton,
    } = this.props;

    return (
      <Container>
        {showCloseButton && (
          <CloseButton icon="true" text onClick={onClose}>
            <CancelIcon width={24} height={24} />
          </CloseButton>
        )}
        <Header isPaidCollaboratorsPlan={isPaidCollaboratorsPlan} />
        <StyledForm onSubmit={handleSubmit}>
          <div className="AddTeamMembers__EmailFieldsContainer">
            <EmailFields
              emailFieldNames={emailFieldNames}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
            />
          </div>
          {// render buttons to invite members
          children({
            handleSubmit,
            isDisabled: !isValid,
          })}
        </StyledForm>
      </Container>
    );
  }
}

InnerForm.propTypes = {
  // Props from Formik
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,

  // Props from component
  children: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  isPaidCollaboratorsPlan: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  onTeamMembersAdded: PropTypes.func,
  showCloseButton: PropTypes.bool,
  trackingPage: PropTypes.string.isRequired,
};

InnerForm.defaultProps = {
  showCloseButton: true,
  onTeamMembersAdded: noop,
  onClose: noop,
};

export const formikOptions = {
  mapPropsToValues: (props) => pick(props, emailFieldNames),
  handleSubmit: (values, { props }) => {
    const {
      teamId,
      onTeamMembersAdded,
      batchAddTeamMembers,
      trackingPage,
    } = props;
    trackButtonClick('submit', trackingPage, 'middle');
    const emailFieldsValues = Object.values(values);
    const emails = emailFieldsValues
      .filter((email) => email)
      .map((email) => email);
    batchAddTeamMembers(teamId, emails, onTeamMembersAdded);
  },
  validateOnChange: true,
  validationSchema: Yup.object().shape({
    teamMemberEmail0: Yup.string()
      .email(MUST_BE_AN_EMAIL_ADDRESS)
      .required('Email should not be blank'),

    teamMemberEmail1: Yup.string().email(MUST_BE_AN_EMAIL_ADDRESS),

    teamMemberEmail2: Yup.string().email(MUST_BE_AN_EMAIL_ADDRESS),
  }),
};

export default withTheme(withFormik(formikOptions)(InnerForm));

export const testExports = {
  NUMBER_OF_TEAM_MEMBERS,
};
