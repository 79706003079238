import React from 'react';
import PropTypes from 'prop-types';
import { JoinProject, LeaveProject } from './JoinLeave';

const JoinLeaveProject = ({
  isLeaveProjectEnabled,
  isProjectCollaborator,
  onJoinProject,
  onLeaveProject,
  projectId,
  trackingPage,
  trackingPosition,
  ...rest
}) => {
  if (isProjectCollaborator && isLeaveProjectEnabled) {
    return (
      <LeaveProject
        {...rest}
        onClick={() =>
          onLeaveProject(projectId, trackingPage, trackingPosition)
        }
      />
    );
  }

  if (!isProjectCollaborator) {
    return (
      <JoinProject
        {...rest}
        onClick={() => onJoinProject(projectId, trackingPage, trackingPosition)}
      />
    );
  }
  return null;
};

JoinLeaveProject.defaultProps = {
  projectId: null,
  isProjectCollaborator: false,
  onLeaveProject: () => {},
  onJoinProject: () => {},
};

JoinLeaveProject.propTypes = {
  projectId: PropTypes.string,
  isProjectCollaborator: PropTypes.bool,
  onLeaveProject: PropTypes.func,
  onJoinProject: PropTypes.func,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};

export default JoinLeaveProject;
