import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import drawGrid from './drawWatermarkTemplateGrid';

const DEFAULT_TRANSITION_LENGTH = 500;

const StyledCanvas = styled.canvas`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
  opacity: ${(p) => p.opacity};
  transition: opacity ${(p) => p.transitionLength}ms ease;
`;

const SessionWatermarkTemplateDotBackground = (props) => {
  const {
    isVisible,
    previewImageBounds,
    color,
    density,
    radius,
    transitionLength = DEFAULT_TRANSITION_LENGTH,
    width = 1,
    height = 1,
  } = props;

  const gridCanvasRef = React.useRef();

  // Leave the grid canvas context undefined for now since we need to wait for a full render before the element is available
  const gridContext = React.useRef(null);

  React.useLayoutEffect(() => {
    if (!gridCanvasRef.current || !previewImageBounds) {
      return;
    }

    // If the grid context doesn't exist yet, create it
    if (!gridContext.current) {
      gridContext.current = gridCanvasRef.current.getContext('2d');
    }

    // Waiting one rAF tick helps with layout rendering accuracy
    requestAnimationFrame(() => {
      drawGrid({
        width,
        height,
        canvasEl: gridCanvasRef.current,
        canvasContext: gridContext.current,
        previewImageBounds,
        color,
        density,
        radius,
      });
    });
  }, [width, height, previewImageBounds, color, density, radius]);
  return (
    <StyledCanvas
      ref={gridCanvasRef}
      opacity={isVisible ? 1 : 0}
      transitionLength={transitionLength}
    />
  );
};

SessionWatermarkTemplateDotBackground.defaultProps = {
  transitionLength: DEFAULT_TRANSITION_LENGTH,
  width: 1,
  height: 1,
};

SessionWatermarkTemplateDotBackground.propTypes = {
  isVisible: PropTypes.bool,
  color: PropTypes.string,
  density: PropTypes.number,
  radius: PropTypes.number,
  transitionLength: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SessionWatermarkTemplateDotBackground;
