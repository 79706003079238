import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import Flex from 'styled-flex-component';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { rgba } from 'polished';
import { MEDIUM_UP } from 'utils/mediaQueries';
import CrossFader from '@frameio/components/src/styled-components/CrossFader';
import CopyIcon from '@frameio/components/src/svgs/icons/24/copy.svg';
import LinkIcon from '@frameio/components/src/svgs/icons/16/link.svg';
import InfoIcon from '@frameio/components/src/svgs/icons/24/support-filled.svg';
import Button from '@frameio/components/src/styled-components/Button';
import WithTooltip from '@frameio/components/src/components/WithTooltip';
import { Input } from '@frameio/components/src/styled-components/TextInput/TextInput';
import { PROJECT_SHARING_SUPPORT_URL } from 'URLs';
import { trackClick } from '../tracking';

const StyledInput = styled(Input).attrs(() => ({
  compact: true,
  truncate: true,
}))`
  font-size: 12px;
  border: none;
  outline: none;
  width: 15em;
  text-align: left;

  @media ${MEDIUM_UP} {
    text-align: right;
  }

  &:focus {
    box-shadow: none;
    border: none;
  }
`;

const InfoTootipText = styled.div`
  font-size: ${(p) => p.theme.fontSize[1]};
`;

const InfoLink = styled.a`
  display: inline-flex;
  color: ${(p) => p.theme.color.slateGray};
`;

const CopyButton = styled(Button).attrs(() => ({
  icon: 'true',
  text: true,
}))``;

const EnableButton = styled(Button).attrs(() => ({
  icon: 'true',
}))`
  transition: background-color 0.2s ease;

  ${({ theme, isEnabled }) =>
    isEnabled &&
    `
    color: ${theme.color.success};
    background-color: ${rgba(theme.color.lightSuccess, 0.7)};
    box-shadow: inset 0 0 0 1px transparent;

    &:hover:not(:active):not(:disabled) {
      background-color: ${rgba(theme.color.lightSuccess, 0.5)};
    };

    &:active:not(:disabled) {
      background-color: ${rgba(theme.color.lightSuccess, 0.9)};
    }
  `}

  svg {
    transform: rotate(${({ isEnabled }) => (isEnabled ? '45deg' : '0deg')});
    transition: transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 0.1);
  }
`;

const ContentWrapper = styled(Flex).attrs(() => ({
  full: true,
  alignCenter: true,
  justifyEnd: true,
}))`
  flex-direction: row-reverse;
  line-height: 1;

  @media ${MEDIUM_UP} {
    flex-direction: row;
  }
`;

const Wrapper = styled(ContentWrapper).attrs(() => ({
  full: true,
  alignCenter: true,
}))`
  position: relative;
  font-size: 12px;

  ${Button} {
    font-size: 12px;
    flex-shrink: 0;
  }

  ${EnableButton},
  .infoText {
    margin: 0 ${(p) => p.theme.spacing.tiny} 0 0;
  }

  @media ${MEDIUM_UP} {
    ${EnableButton} {
      margin: 0 0 0 ${(p) => p.theme.spacing.tiny};
    }
    .infoText {
      margin: 0 0 0 ${(p) => p.theme.spacing.micro};
    }
  }
`;

const slideIn = (fromRight) => keyframes`
  0% { transform: translate(${fromRight ? '8' : '-8'}px, 0); }
  100% { transform: translate(0, 0); }
`;

const StyledCrossFader = styled(({ fromRight, ...other }) => (
  <CrossFader {...other} />
))`
  height: 100%;
  width: 100%;
  animation: ${(p) =>
    `${p.fromRight ? css(slideIn(true)) : css(slideIn())} 300ms`};
`;

class ProjectInviteLink extends React.Component {
  constructor(props) {
    super(props);
    this.inviteLinkRef = React.createRef();
  }

  focusInviteLink = () =>
    this.inviteLinkRef.current && this.inviteLinkRef.current.focus();

  getTooltipCopy = () => {
    // TODO: Move this back to be inline once the feature flag is enabled for all
    const { newProjectInviteLinksEnabled, isProjectShared } = this.props;

    if (newProjectInviteLinksEnabled) {
      return isProjectShared
        ? 'Delete invite link for this project'
        : 'Create and copy public link';
    }

    return isProjectShared
      ? 'Turn off invite link sharing for this project'
      : 'Enable and copy public link';
  };

  render() {
    const {
      projectId,
      isProjectShared,
      updateProjectShareLink,
      isUpdating,
      inviteLink,
      copyToClipboard,
    } = this.props;

    return (
      <Media query={MEDIUM_UP}>
        {(isMediumUp) => (
          <Wrapper justifyEnd={isMediumUp}>
            <StyledCrossFader
              timeout={200}
              easing="cubic-bezier(0.25, 0.1, 0.25, 0.1)"
              fromRight={isMediumUp}
              style={{
                right: 0,
              }}
            >
              {isProjectShared && (
                <ContentWrapper>
                  <StyledInput
                    readOnly
                    ref={this.inviteLinkRef}
                    value={inviteLink.replace('https://', '')}
                    onFocus={(event) => event.target.select()}
                    onKeyDown={(event) => {
                      if (
                        event.key === 'c' &&
                        (event.ctrlKey || event.metaKey)
                      ) {
                        copyToClipboard(inviteLink); // copy and toast
                        trackClick('copy_enabled_invite_link');
                      }
                    }}
                  />
                  <CopyButton
                    onClick={() => {
                      this.focusInviteLink();
                      copyToClipboard(inviteLink);
                      trackClick('copy_enabled_invite_link');
                    }}
                  >
                    <CopyIcon />
                  </CopyButton>
                </ContentWrapper>
              )}

              {!isProjectShared && (
                <ContentWrapper>
                  <WithTooltip
                    disabled={!isMediumUp}
                    type="hover"
                    maxWidth={320}
                    offsetY={-12}
                    message={
                      <InfoTootipText>
                        Allow anyone to join via a public URL
                      </InfoTootipText>
                    }
                  >
                    <InfoLink
                      href={PROJECT_SHARING_SUPPORT_URL}
                      target="_blank"
                      onClick={() => trackClick('learn_more_invite_link')}
                    >
                      <InfoIcon />
                    </InfoLink>
                  </WithTooltip>
                  <span className="infoText">
                    {isMediumUp
                      ? 'Invite via link'
                      : 'Enable and copy project invite link'}
                  </span>
                </ContentWrapper>
              )}
            </StyledCrossFader>

            <WithTooltip
              type={isMediumUp ? 'hover' : 'none'}
              data-test-id="tooltip-enable-button"
              message={this.getTooltipCopy()}
            >
              <EnableButton
                isEnabled={isProjectShared}
                showSpinner={isUpdating}
                onClick={() => {
                  if (isProjectShared) {
                    // disable link
                    updateProjectShareLink(projectId, false);
                    trackClick('disable_enabled_invite_link');
                  } else {
                    // enable link
                    updateProjectShareLink(projectId, true);
                    trackClick('enable_invite_link');
                  }
                }}
              >
                <LinkIcon />
              </EnableButton>
            </WithTooltip>
          </Wrapper>
        )}
      </Media>
    );
  }
}

ProjectInviteLink.propTypes = {
  projectId: PropTypes.string,
  inviteLink: PropTypes.string.isRequired,
  isProjectShared: PropTypes.bool,
  isUpdating: PropTypes.bool,
  newProjectInviteLinksEnabled: PropTypes.bool,

  // mapDispatchToProps
  updateProjectShareLink: PropTypes.func.isRequired,
  copyToClipboard: PropTypes.func.isRequired,
};

ProjectInviteLink.defaultProps = {
  projectId: undefined,
  isProjectShared: false,
};

export const testExports = {
  CopyButton,
  EnableButton,
  InfoLink,
  StyledInput,
  Wrapper,
};

export default ProjectInviteLink;
