import { combineReducers } from 'redux';
import { BRANDING_CONTAINER } from './actions';

const initialState = {
  isUploading: false,
};

function generateUploadReducer(scope) {
  return (state = initialState, action) => {
    switch (action.type) {
      case scope.PENDING: {
        return { ...state, isUploading: true };
      }
      case scope.SUCCESS:
      case scope.FAILURE:
        return { ...state, isUploading: false };
      default:
        return state;
    }
  };
}

const uploadAccountLogoImage = generateUploadReducer(
  BRANDING_CONTAINER.UPLOAD_ACCOUNT_LOGO
);
const uploadEmailBrandingImage = generateUploadReducer(
  BRANDING_CONTAINER.UPLOAD_EMAIL_BRANDING
);
const uploadTeamLogoImage = generateUploadReducer(
  BRANDING_CONTAINER.UPLOAD_TEAM_LOGO
);

export default combineReducers({
  uploadAccountLogoImage,
  uploadEmailBrandingImage,
  uploadTeamLogoImage,
});
