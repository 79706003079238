import { TOASTS } from 'actions/toasts';

const toasts = (state = [], action) => {
  switch (action.type) {
    case TOASTS.SHOW: {
      return [...state, action.payload];
    }
    case TOASTS.REMOVE: {
      const { payload } = action;

      if (!payload) {
        return [];
      }

      const keys = Object.keys(payload);

      return state.filter((toast) => {
        for (let i = 0; i < keys.length; i += 1) {
          const key = keys[i];
          if (payload[key] === toast[key]) return false;
        }
        return true;
      });
    }
    default:
      return state;
  }
};

export default toasts;
