import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

const REVIEW_LINK_EDITOR = generateActionTypes('REVIEW_LINK_EDITOR', [
  'CANCEL_SELECTION',
  'CONFIRM_FROM_SELECTION',
  'CREATE_WITH_ASSETS',
  'DELETE_INVITEE',
  'EXIT',
  'INVITE_USERS',
  'LEGACY_EDIT_ITEMS',
  'LEGACY_SELECT_ITEMS',
  'LEGACY_SHARE_ITEMS_AS_NEW',
  'LIST_REVIEWERS_FOR_REVIEW_LINK_ID',
  'OPEN_EDITOR',
  'SELECT_ALL',
  'SELECT_ITEMS',
  'SEND_EMAILS',
  'SET_CURRENT_EDITOR_ID',
  'SET_DEFAULT_TEAM_SESSION_WATERMARK_ID',
  'SET_FOLDER_SELECTION_STATUS',
  'STORE_INVITED_IDS',
  'TOGGLE_SELECTION_MODE',
  'UPDATE_PROJECT_REVIEW_LINK_PREFERENCES',
  'UPDATE_SETTINGS',
  'UPGRADE_PLAN',
]);

export default REVIEW_LINK_EDITOR;

/**
 * Creates an action to cancel the selection of assets
 * for a _new_ or _existing_ review link.
 * @param {?string} id - Review link's id; null if
 * a new review link has not yet been created
 */
export const cancelSelection = createAction(
  REVIEW_LINK_EDITOR.CANCEL_SELECTION,
  () => ({})
);

/**
 * Creates an action to confirm the selected assets in state to
 * be shared as a review link.
 */
export const confirmSelection = createAction(
  REVIEW_LINK_EDITOR.CONFIRM_FROM_SELECTION,
  () => ({})
);

/**
 * Creates an action to create a review link with an array of asset ids
 * or asset data objects.
 * @param {?(string[]|Object[])} assets - Asset ids or data objects.
 */
export const createReviewLinkWithAssets = createAction(
  REVIEW_LINK_EDITOR.CREATE_WITH_ASSETS,
  (assets) => ({ assets })
);

/**
 * Creates an action to set the review link that is being edited.
 * @param {string} id - Review link's id.
 */
export const legacyEditReviewLinkItems = createAction(
  REVIEW_LINK_EDITOR.LEGACY_EDIT_ITEMS,
  (id) => ({ id })
);

/**
 * Creates an action to select the assets that are part of the review link.
 * @param {?(string[]|Object[])} assets - Asset ids or data objects.
 */
export const legacySelectReviewLinkItems = createAction(
  REVIEW_LINK_EDITOR.LEGACY_SELECT_ITEMS,
  (assets = []) => ({ assets })
);

/**
 * Creates an action to share the provided assets inside a new review link.
 * @param {?(string[]|Object[])} assets - Asset ids or data objects.
 * @param {boolean} canSkipSelection - Flag to indicate whether to select assets or not.
 */
export const legacyShareItemsAsNewReviewLink = createAction(
  REVIEW_LINK_EDITOR.LEGACY_SHARE_ITEMS_AS_NEW,
  (assets = [], canSkipSelection = false) => ({ assets, canSkipSelection })
);

/**
 * Opens an editor modal to set review link preferences
 */
export const openEditor = createAction(
  REVIEW_LINK_EDITOR.OPEN_EDITOR,
  (id, defaultTab) => ({ id, defaultTab })
);

export const exitEditor = createAction(REVIEW_LINK_EDITOR.EXIT, () => ({}));

/**
 * Creates an action to select all assets within a given project
 * context (e.g. in the root folder, or a child folder) to share
 * as a review link.
 */
export const selectAllAssets = createAction(
  REVIEW_LINK_EDITOR.SELECT_ALL,
  () => ({})
);

/**
 * Creates an action to list all reviewers for a given
 * review link id.
 */
export const listReviewers = createAction(
  REVIEW_LINK_EDITOR.LIST_REVIEWERS_FOR_REVIEW_LINK_ID,
  () => ({})
);

/**
 * Creates an action to select the assets that are part of the review link.
 * @param {(string[]|Object[])} assets - Asset ids or data objects.
 * @param {boolean} [resetSelectedAssets] - Whether or not a review link's selected assets
 * should be reset/reconciled; NOTE: Currently this is only necessary when a `Select All`
 * action is dispatched, which may necessitate the removal of previously selected descendant
 * asset(s) of newly selected folders.
 */
export const selectReviewLinkItems = createAction(
  REVIEW_LINK_EDITOR.SELECT_ITEMS,
  (assets = [], resetSelectedAssets) => ({ assets, resetSelectedAssets })
);

/**
 * Creates an action to send a review link email to user(s)/recipient(s).
 * @param {string} id - Review link's id.
 * @param {Object} [options] - Additional options, specifically:
 * - [options.recipients] - Array of recipient emails.
 * - [options.userIds] - Array of user ids.
 * - [options.message] - Custom message to be included in the share email.
 */
export const sendReviewLinkEmails = createAction(
  REVIEW_LINK_EDITOR.SEND_EMAILS,
  (id, options) => ({ id, options })
);

/**
 * Creates an action to send a review link email to user(s)/recipient(s).
 * @param {string} id - Review link's id.
 * @param {Object} [options] - Additional options, specifically:
 * - [options.tokens] - Tokens representing user ids or email addresses or groups.
 * - [options.message] - Custom message to be included in the share email.
 */
export const inviteUsersToReviewLink = createAction(
  REVIEW_LINK_EDITOR.INVITE_USERS,
  (tokens, sendEmail, message) => ({ tokens, sendEmail, message })
);

/**
 * Sets the current review editor id in redux
 * @param {string} id - Review link's id.
 */
export const setReviewLinkEditorId = createAction(
  REVIEW_LINK_EDITOR.SET_CURRENT_EDITOR_ID,
  (id) => ({ id })
);

/**
 * Creates an action to set toggle selection mode for review link items.
 * @param {?(string[]|Object[])} assets - Array of asset ids or asset data
 * objects to be shared, or undefined; If an array (non-empty or empty) is passed in, an associated
 * selector - isSelectingItemsForReviewLinkSelector - will derive the
 * selection mode to be true, false if assets is undefined.
 */
export const toggleReviewLinkSelectionMode = createAction(
  REVIEW_LINK_EDITOR.TOGGLE_SELECTION_MODE,
  (assets) => ({ assets })
);

export const updateReviewLinkSettings = createAction(
  REVIEW_LINK_EDITOR.UPDATE_SETTINGS,
  (id, params) => ({ id, params })
);

export const storeInvitedIds = createAction(
  REVIEW_LINK_EDITOR.STORE_INVITED_IDS,
  (ids) => ({ ids })
);

export const deleteInvitee = createAction(
  REVIEW_LINK_EDITOR.DELETE_INVITEE,
  (entityId) => ({ entityId })
);

export const setDefaultSessionWatermarkTemplateIdForTeam = createAction(
  REVIEW_LINK_EDITOR.SET_DEFAULT_TEAM_SESSION_WATERMARK_ID,
  (teamId, defaultSessionWatermarkTemplateId) => ({
    teamId,
    defaultSessionWatermarkTemplateId,
  })
);

export const setFolderSelectionStatus = createAction(
  REVIEW_LINK_EDITOR.SET_FOLDER_SELECTION_STATUS,
  (folderId, selectionStatus) => ({ folderId, selectionStatus })
);

export const upgradePlan = createAction(
  REVIEW_LINK_EDITOR.UPGRADE_PLAN,
  (reviewLinkId, feature) => ({ reviewLinkId, feature })
);

/**
 * Updates a users review link preferences for a project.
 * @param   {string} projectId - Project's backend id.
 * @param   {Object} params - Review link preferences.
 * @returns {Object} - Object with type and payload.
 */
export const updateProjectReviewLinkPreferences = createAction(
  REVIEW_LINK_EDITOR.UPDATE_PROJECT_REVIEW_LINK_PREFERENCES,
  (projectId, params) => ({ projectId, params })
);
