import { createSelector } from 'reselect';
import { flow } from 'lodash';

import {
  paginatedListAllResultsSelector,
  paginatedListMetadataSelector,
  paginatedListIsFetchingPageOneSelector,
  shouldFetchListPageSelector,
} from '@frameio/core/src/shared/selectors';

import { projectContainerSelector } from '../selectors';

const projectDevicesSelector = (state) =>
  projectContainerSelector(state).projectDevices;

export const allProjectDevicesSelector = (state) =>
  state.entities.projectDevices.entities;

const projectDevicesMetadataSelector = flow([
  projectDevicesSelector,
  paginatedListMetadataSelector,
]);

export const currentAuthorizationIdSelector = createSelector(
  projectDevicesSelector,
  ({ currentAuthorizationId }) => currentAuthorizationId
);

export const idsSelector = createSelector(
  projectDevicesSelector,
  paginatedListAllResultsSelector
);

export const isEmptySelector = createSelector(
  projectDevicesMetadataSelector,
  ({ total }) => total === 0
);

export const isFirstPageFetchingSelector = createSelector(
  projectDevicesSelector,
  paginatedListIsFetchingPageOneSelector
);

export const shouldFetchSelector = (state, { page }) => {
  const slice = projectDevicesSelector(state);
  return shouldFetchListPageSelector(slice, { page });
};
