import { connect } from 'react-redux';
import { teamEntityForProjectIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { presentationEntitySelector } from '@frameio/core/src/presentations/selectors';
import {
  currentAccountSelector,
  isAccountOnLegacyPlanSelector,
} from 'selectors/accounts';
import {
  permittedActionsForAccountSelector,
  permittedActionsForProjectSelector,
} from 'selectors/permissions';
import { isBlockingReelPlayerSelector } from 'components/PresentationEditor/selectors';
import { isProjectArchivedSelector } from 'selectors/projects';
import { isAccountAdminSelector } from 'selectors/roles';
import { upgradePlan } from '../actions';
import PresentationSidePanel from './PresentationSidePanel';

function mapStateToProps(state, { presentationId }) {
  const presentation = presentationEntitySelector(state, { presentationId });
  const { project_id: projectId } = presentation;
  const team = teamEntityForProjectIdSelector(state, { projectId });
  const { id: accountId } = currentAccountSelector(state);
  const {
    canUseReelPlayer,
    canUsePasswordProtectedShares,
    canUseCustomBrandedPresentations,
    canUseShareLinkExpiration,
  } = permittedActionsForAccountSelector(state, {
    accountId,
  });
  const isOnLegacyPlan = isAccountOnLegacyPlanSelector(state, { accountId });
  const { canDownload } = permittedActionsForProjectSelector(state, {
    projectId,
  });
  const isAdmin = isAccountAdminSelector(state, { accountId });

  return {
    canUseCustomBrandedPresentations,
    canUsePasswordProtectedShares,
    canUseReelPlayer,
    canUseShareLinkExpiration,
    canEnableDownloads: canDownload,
    isAdmin,
    isBlockingReelPlayer: isBlockingReelPlayerSelector(state),
    isOnLegacyPlan,
    presentation,
    team,
    isProjectArchived: isProjectArchivedSelector(state, { projectId }),
  };
}

const mapDispatchToProps = {
  upgradePlan,
};

export const testExports = { mapStateToProps };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PresentationSidePanel);
