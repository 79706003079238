import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const PRESENTATION_PAGE = generateActionTypes('PRESENTATION_PAGE', [
  'ENTER_PRESENTATION',
  'BULK_DOWNLOAD',
  'TRACK_MEDIA_VIEWED',
  'FETCH_DATA',
  'FETCH_FAILED',
]);

/**
 * Creates an action to get the presentaton by vanity id.
 * @param {string} vanityId - Presentation's vanity id.
 * @param {string} password - Presentation's password (optional)
 */
export const enterPresentation = createAction(
  PRESENTATION_PAGE.ENTER_PRESENTATION,
  (vanityId, token, password) => ({ vanityId, token, password })
);

export function handlePresentationBulkDownload(presentation) {
  return {
    type: PRESENTATION_PAGE.BULK_DOWNLOAD,
    payload: { presentation },
  };
}

export function trackMediaViewed(presentationId, assetId, isAutoPlayEnabled) {
  return {
    type: PRESENTATION_PAGE.TRACK_MEDIA_VIEWED,
    payload: {
      presentationId,
      assetId,
      isAutoPlayEnabled,
    },
  };
}

/**
 * Marks the presentation fetching as failed for the provided reason.
 * @param {string} presentationId
 * @param {string} failureReason
 */
export function presentationFetchFailed(presentationId, failureReason) {
  return {
    type: PRESENTATION_PAGE.FETCH_FAILED,
    payload: {
      presentationId,
      failureReason,
    },
  };
}
