import { connect } from 'react-redux';

import { groupEntitySelector } from '@frameio/core/src/groups/selectors';
import { currentAccountSelector } from 'selectors/accounts';
import { closeModal } from 'components/Modal/actions';
import { createUserGroup, updateUserGroup } from '../actions';
import {
  groupsContainerIdSelector,
  groupsContainerErrorMessageSelector,
} from '../selectors';

import GroupsModal from './GroupsModal';

export const mapStateToProps = (state) => {
  const { id: accountId } = currentAccountSelector(state);
  const errorMessage = groupsContainerErrorMessageSelector(state);

  const groupId = groupsContainerIdSelector(state);

  const { name, users, email_memberships: emailMemberships, emoji } =
    groupEntitySelector(state, { groupId }) || {};

  return {
    accountId,
    emailMemberships,
    emoji,
    errorMessage,
    name,
    users,
  };
};

const mapDispatchToProps = {
  createUserGroup,
  updateUserGroup,
  closeModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupsModal);
