/**
 * createThumb's purpose is to try to generate a thumb image from a
 * local video file. This is likely to fail because we use the native <video>
 * element to load the video and then push the first frame into a canvas and
 * grab the bitmap data.
 * Since the html5 video element doesn't support all video types, there's a
 * 50/50 chance to succeed.
 */

function createCanvasElement(width, height) {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  return canvas;
}

function getRenderSize(origWidth, origHeight, maxSize) {
  if (Math.max(origWidth, origHeight) <= maxSize)
    return { width: origWidth, height: origHeight };
  const isVertical = origWidth < origHeight;
  const ratio = origWidth / origHeight;
  if (isVertical)
    return { width: Math.round(maxSize * ratio), height: maxSize };
  return { width: maxSize, height: Math.round(maxSize / ratio) };
}

function grabScreenshot(videoElem) {
  const { videoWidth, videoHeight } = videoElem;

  // we don't need the full frame size, let's decrease the size
  const { width, height } = getRenderSize(videoWidth, videoHeight, 400);
  const canvas = createCanvasElement(width, height);
  const context = canvas.getContext('2d');

  // draw the frame from the <video> on the canvas
  context.drawImage(videoElem, 0, 0, width, height);
  const dataURL = canvas.toDataURL('image/jpeg');

  // make sure the dataURL has any meaningful data content
  if (typeof dataURL !== 'string' || dataURL.length < 20)
    throw new Error('Failed grabbing the first frame');
  return dataURL;
}

function cleanUp(elem, errorFunc, loadedDataFunc, timeout) {
  clearTimeout(timeout);
  // remove the blob URL from the video elem so it stops
  // loading the video. Otherwise it continues to load
  // and shows errors because the url was revoked with revokeObjectURL
  const url = elem.src;
  elem.setAttribute('src', '#');
  URL.revokeObjectURL(url);
  elem.removeEventListener('error', errorFunc);
  elem.removeEventListener('abort', errorFunc);
  elem.removeEventListener('stalled', errorFunc);
  elem.removeEventListener('loadeddata', loadedDataFunc);
}

export default function(file) {
  return new Promise((resolve, reject) => {
    let timeout;

    function onError(evt) {
      cleanUp(evt.target, onError, onLoadedData, timeout); // eslint-disable-line no-use-before-define
      reject();
    }

    function onLoadedData(evt) {
      const elem = evt.target;
      try {
        const imageData = grabScreenshot(elem);
        resolve(imageData);
      } catch (error) {
        reject(error);
      } finally {
        cleanUp(elem, onError, onLoadedData, timeout);
      }
    }

    const videoElem = document.createElement('video');
    timeout = setTimeout(() => onError({ target: videoElem }), 1500);

    // stalled - Fired when the user agent is trying to
    // fetch media data, but data is unexpectedly not forthcoming.
    // Can happen with big (> 1GB files) in Safari
    videoElem.addEventListener('stalled', onError);
    videoElem.addEventListener('abort', onError);
    videoElem.addEventListener('error', onError);
    videoElem.addEventListener('loadeddata', onLoadedData);
    videoElem.src = URL.createObjectURL(file);

    // we need to set the video elem to a non-zero value
    // otherwise the first frame is not rendered reliably
    videoElem.currentTime = 0.01;
    videoElem.load();
  });
}
