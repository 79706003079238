import { ACCOUNT, NEW_SETTINGS_APP_PERMISSIONS } from 'actions/accounts';

export const currentAccount = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT.SET_CURRENT: {
      const { accountId, lastViewedProjectId } = action.payload;
      return { ...state, id: accountId, lastViewedProjectId };
    }
    default:
      return state;
  }
};

export const lastViewedAccountId = (state = '', action) => {
  switch (action.type) {
    case ACCOUNT.SET_LAST_VIEWED: {
      const { accountId } = action.payload;
      return accountId;
    }
    default:
      return state;
  }
};

export const accountMigrationEligibility = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT.SET_MIGRATION_ELIGIBILITY: {
      const {
        canUserInitiateAccountMigration,
        isAccountEligibleForMigration,
      } = action.payload;
      return {
        ...state,
        canUserInitiateAccountMigration,
        isAccountEligibleForMigration,
      };
    }
    default:
      return state;
  }
};

export const accountSettingsPermissions = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case NEW_SETTINGS_APP_PERMISSIONS.GET.SUCCESS: {
      return {
        ...state,
        [payload.entityId]: payload.response.data,
      };
    }
    default:
      return state;
  }
};
