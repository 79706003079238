import React from 'react';
import PropTypes from 'prop-types';
import { MenuButton } from '@frameio/vapor';
import UnversionIcon from '@frameio/components/src/svgs/icons/unversion.svg';
import { trackButtonClick } from 'analytics';

export default function ManageVersionStackInModal({
  assetId,
  openManageVersionStackInModal,
  trackingPage,
  trackingPosition,
  ...rest
}) {
  return (
    <MenuButton
      {...rest}
      onSelect={() => {
        openManageVersionStackInModal(assetId);
        trackButtonClick('manage-versions', trackingPage, trackingPosition);
      }}
      iconBefore={<UnversionIcon width={16} height={16} />}
    >
      Manage version stack
    </MenuButton>
  );
}

ManageVersionStackInModal.defaultProps = {
  trackingPage: null,
  trackingPosition: null,
};

ManageVersionStackInModal.propTypes = {
  assetId: PropTypes.string.isRequired,
  openManageVersionStackInModal: PropTypes.func.isRequired,
  trackingPage: PropTypes.string,
  trackingPosition: PropTypes.string,
};
