import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';
import { isFetchingActionFactory } from 'factories/isFetching';

const module = 'REACTIVATE_SUBSCRIPTION';
export const REACTIVATE_SUBSCRIPTION = {
  ...generateActionTypes(module, ['CONFIRM_REACTIVATE', 'IS_FETCHING']),
};

export const confirmReactivateSubscription = createAction(
  REACTIVATE_SUBSCRIPTION.CONFIRM_REACTIVATE,
  () => ({})
);

export const isFetching = isFetchingActionFactory(
  REACTIVATE_SUBSCRIPTION.IS_FETCHING
);
