import { connect } from 'react-redux';
import {
  teamEntityForProjectIdSelector,
  accountEntityForProjectIdSelector,
  planEntityForAccountIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import { presentationEntitySelector } from '@frameio/core/src/presentations/selectors';
import { isAccountOnLegacyPlanSelector } from 'selectors/accounts';
import {
  permittedActionsForAccountSelector,
  permittedActionsForProjectSelector,
} from 'selectors/permissions';
import {
  assetsForPresentationSelector,
  invitedEntitiesForItemIdSelector,
  isBlockingReelPlayerSelector,
} from 'components/PresentationEditor/selectors';
import { isProjectArchivedSelector } from 'selectors/projects';
import { defaultSessionWatermarkTemplateIdSelector } from 'selectors/teams';
import { copyShortUrlToClipboard } from 'pages/ProjectContainer/ProjectLinks/actions';
import { secureSharingEnabled } from 'utils/featureFlags';
import { isAccountAdminSelector } from 'selectors/roles';
import { mergedSessionWatermarkTemplateEntitiesForTeamAndPresentation } from 'components/SharePanel/selectors';
import watermarkUpsellVariantSelector from 'components/SharePanel/WatermarkUpsellTab/selectors';
import { openSessionWatermarkTemplateEditor } from 'pages/WatermarkTemplateEditor/actions';
import SecureSidePanel from './SecureSidePanel';
import logNullishAccountForProjectIdError from './logNullishAccountForProjectIdError';
import {
  setDefaultSessionWatermarkTemplateIdForTeam,
  updatePresentation,
  inviteUsersToPresentation,
  legacyOpenPresentationEditor,
  upgradePlan,
} from '../actions';

// nearly this entire thing is copy+pasted from ConnectedPresentationSidePanel
// and we need to figure out which parts are still important
function mapStateToProps(state, { presentationId }) {
  const presentation = presentationEntitySelector(state, { presentationId });
  const projectId = presentation?.project_id;
  const assetCount = assetsForPresentationSelector(state, {
    assetIds: presentation?.assets,
  }).length;
  const team = teamEntityForProjectIdSelector(state, { projectId });
  const accountForProjectId = accountEntityForProjectIdSelector(state, {
    projectId,
  });
  const accountId = accountForProjectId?.id;
  const {
    canCustomizeEmailMessages,
    canDisableShareDownloads,
    canUseCustomBrandedPresentations,
    canUseShareLinkExpiration,
    canUsePasswordProtectedShares,
    canUseReelPlayer,
    isDrmSettingsEnabled,
    isSessionBasedWatermarkingEnabled,
    isForensicWatermarkingEnabled,
    isSecureSharingEnabled: isSecureSharingEnabledForAccount,
  } = permittedActionsForAccountSelector(state, {
    accountId,
  });
  const isOnLegacyPlan = isAccountOnLegacyPlanSelector(state, { accountId });

  const {
    canPublicSharePresentation,
    canSetDefaultTeamSessionWatermarkId,
    canShareDownloadablePresentation,
    canShareUnwatermarkedPresentation,
    canShareWithoutDrmPresentation,
    canShareWithoutForensicWatermarkPresentation,
    canSeeWatermarkingSettings,
  } = permittedActionsForProjectSelector(state, { projectId });
  const invitedEntities = invitedEntitiesForItemIdSelector(state);

  // BUGS-1331: Log an error everytime whenever the accountForProjectId is null
  logNullishAccountForProjectIdError({
    accountForProjectId,
    presentation,
    presentationId,
    team,
  });

  const plan = planEntityForAccountIdSelector(state, { accountId });

  return {
    plan,
    accountId,
    assetCount,
    canCustomizeEmailMessages,
    canDisableShareDownloads,
    canUseReelPlayer,
    canUseShareLinkExpiration,
    canUsePasswordProtectedShares,
    canEnableDownloads: canShareDownloadablePresentation,
    canSetDefaultTeamSessionWatermarkId,
    canSharePublicly: canPublicSharePresentation,
    canShareUnwatermarked: canShareUnwatermarkedPresentation,
    canShareWithoutDrm: canShareWithoutDrmPresentation,
    canShareWithoutForensicWatermark: canShareWithoutForensicWatermarkPresentation,
    canSeeWatermarkingSettings,
    canUseCustomBrandedPresentations,
    defaultSessionWatermarkTemplateId: defaultSessionWatermarkTemplateIdSelector(
      state
    ),
    secureSharingEnabled:
      secureSharingEnabled(state) || isSecureSharingEnabledForAccount,
    isAdminLevel: isAccountAdminSelector(state, { accountId }),
    isBlockingReelPlayer: isBlockingReelPlayerSelector(state),
    isOnLegacyPlan,
    presentation: presentation || {},
    sessionWatermarkTemplates: mergedSessionWatermarkTemplateEntitiesForTeamAndPresentation(
      state,
      { presentationId: presentation?.id }
    ),
    team,
    isDrmSettingsEnabled,
    isProjectArchived: isProjectArchivedSelector(state, { projectId }),
    isSessionBasedWatermarkingEnabled,
    isForensicWatermarkingEnabled,
    watermarkUpsellVariant: watermarkUpsellVariantSelector(state),
    totalInviteCount:
      invitedEntities !== undefined ? invitedEntities.length : undefined,
  };
}

const mapDispatchToProps = {
  copyShortUrlToClipboard,
  inviteUsersToPresentation,
  setDefaultSessionWatermarkTemplateIdForTeam,
  updatePresentation,
  openSessionWatermarkTemplateEditor,
  legacyOpenPresentationEditor,
  upgradePlan,
};

export const testExports = { mapStateToProps };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecureSidePanel);
