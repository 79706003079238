import React from 'react';
import Flex from 'styled-flex-component';
import Media from 'react-media';
import styled, { css } from 'styled-components';
import { FadeTransition } from 'react-transition-components';
import { get } from 'lodash';
import LeftCarouselArrow from '@frameio/components/src/svgs/illustrations/left-carousel-arrow.svg';
import PropTypes from 'prop-types';
import RightCarouselArrow from '@frameio/components/src/svgs/illustrations/right-carousel-arrow.svg';
import Switch from '@frameio/components/src/styled-components/Switch';
import {
  HEIGHT_OFFSET,
  WIDTH_OFFSET,
} from 'components/AssetSlider/SliderThumbs/BaseThumb';
import { formatLongDateTime } from '@frameio/components/src/utils/datetimeHelpers';

import {
  isVideo as isVideoType,
  isAudio as isAudioType,
} from '@frameio/core/src/assets/helpers/mediaTypes';
import Truncate from '@frameio/components/src/styled-components/Truncate';
import { AspectContain, WithTooltip } from '@frameio/components';
import VirtualCarousel from '@frameio/components/src/styled-components/VirtualCarousel';
import AssetViewer from 'components/Presentation/AssetViewer';
import { rgba } from 'polished';
import { getPlayerUrl } from 'URLs';
import {
  hasAttachedLinkIcon,
  AssetIconContainer,
  StyledGoToIcon,
  TRANSITION_SETTINGS,
} from 'components/Presentation/Layouts/shared';
import PlayerAssetSlider from 'components/AssetSlider/PlayerAssetSlider';
import AssetSliderPageNavigation from 'components/AssetSlider/PageNavigation';
import AnimatingBaseThumb from 'components/AssetSlider/SliderThumbs/AnimatingBaseThumb';
import AssetSliderPaginator from 'components/AssetSlider/Paginator';
import { presentationColorsWithFallback } from 'components/Presentation/utils';
import AssetThumb from 'components/AssetThumb';
import { trackButtonClick, trackMediaStartPlayed } from 'analytics';
import { MEDIUM_DOWN, SMALL } from 'utils/mediaQueries';
import JTConstants from 'utilities/JTConstants';
import { shouldUseHlsHook } from 'utils/media';

// BaseThumb modifies the height & width of AssetThumb
// see: https://bit.ly/2pYhOir
// these numbers below take that into account
// TODO(Christi): refactor BaseThumb to not modify AssetThumb's height & width
const ASSET_THUMB_WIDTH = 142 + WIDTH_OFFSET;
const ASSET_THUMB_HEIGHT = 80 + HEIGHT_OFFSET + WIDTH_OFFSET;
const ASSET_SLIDER_HEIGHT = 125;
const ASSET_THUMB_PADDING = 16;
const PRESENTATION_SPACING = '20px';
const AUTOPLAY_TOP_OFFSET = '90px';
const ASSET_THUMB_BACKGROUND = '#2B2F3B';
const SMALL_ANIMATION_CURVE = 'cubic-bezier(0.25, 0.1, 0.25, 1)';
const ARROW_PADDING = '10px';
const TEXT_OPACITY = 0.7;

const StyledPlayerAssetSlider = styled(PlayerAssetSlider)`
  // responsive width overrides from style object
  .fior-asset-slider-container {
    width: 100% !important;
  }

  .fior-asset-slider-thumb-container {
    display: block;
    padding-top: 16px;
  }

  // responsive width overrides from style object
  .fior-asset-slider-container {
    width: 100% !important;
  }

  // centers slider arrows on thumbs
  .player-asset__page-navigation .svg-image-container {
    top: -20px;
  }

  // changes the fade gradients on the front & end of slider
  ${AssetSliderPageNavigation} {
    &.right {
      background: linear-gradient(
        to right,
        ${({ backgroundColor }) => rgba(backgroundColor, 0)} 0%,
        // transparent hack for safari
          ${({ backgroundColor }) => rgba(backgroundColor, 1)} 100%
      );
    }

    &.left {
      background: linear-gradient(
        to left,
        ${({ backgroundColor }) => rgba(backgroundColor, 0)} 0%,
        ${({ backgroundColor }) => rgba(backgroundColor, 1)} 100%
      );
    }
  }

  // changes page dot colors to accent color
  ${AssetSliderPaginator} {
    bottom: 2px;

    .fio-player-header-paginator-dot {
      background-color: ${({ accentColor }) => accentColor};
    }
  }

  // removes borders on thumbs
  .base-thumb-image-container {
    background-color: transparent;
    height: 80px !important;
    width: 142px !important;
  }

  // changes selection border to accent color
  .base-thumb-image-container-selected {
    box-sizing: border-box;
    border-color: ${({ accentColor }) => accentColor};
  }
`;

const StyledAspectContain = styled(AspectContain)`
  padding: 0 60px;

  // for centering short assets within AspectContain
  > div {
    display: flex;
    align-items: center;
  }
`;

export const Header = styled(Flex)`
  color: ${({ textColor }) => textColor};
  margin: ${PRESENTATION_SPACING};
`;

const PlayerWrapper = styled(Flex)`
  position: relative;
  padding: 0 60px;
  flex-grow: 1;
`;

const SliderWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
`;

const arrowStyle = css`
  width: 44px;
  height: 40px;
  position: absolute;
  margin: auto 0;
  top: 0;
  bottom: 0;
  transition-duration: 0.3s;
  transition-property: transform, stroke-width;
  transition-timing-function: ${SMALL_ANIMATION_CURVE};
  stroke-width: 1px;

  &:hover {
    stroke-width: 2px;
    cursor: pointer;
  }
`;

const LeftArrow = styled(LeftCarouselArrow)`
  ${arrowStyle};
  left: 0;
  padding-left: ${ARROW_PADDING};

  &:hover {
    transform: ${({ theme }) => `translate(-${theme.spacing.micro}, 0)`};
  }
`;

const RightArrow = styled(RightCarouselArrow)`
  ${arrowStyle};
  right: 0;
  padding-right: ${ARROW_PADDING};

  &:hover {
    transform: ${({ theme }) => `translate(${theme.spacing.micro}, 0)`};
  }
`;

const AutoplayText = styled.span`
  color: ${({ textColor }) => textColor};
  font-size: ${(p) => p.theme.fontSize[2]};
`;

const AutoPlayLabel = styled.label`
  flex: 1;
  display: flex;
  align-items: center;

  ${AutoplayText} {
    margin-right: ${(p) => p.theme.spacing.tiny};
  }
`;

const AssetIndex = styled.span`
  align-self: center;
  flex: 1;
  font-size: ${(p) => p.theme.fontSize[2]};
  text-align: right;
  white-space: nowrap;
`;

const assetNameLinkStyles = css`
  padding: ${(p) => p.theme.spacing.tiny};
  font-size: 17px;
  font-weight: ${(p) => p.theme.fontWeight.bolder};
  text-align: center;
  color: inherit;
`;

export const AssetNameLink = styled.a`
  ${assetNameLinkStyles};
  ${hasAttachedLinkIcon};
`;

export const AssetName = styled.span`
  ${assetNameLinkStyles};
`;

const InfoWrapper = styled(Flex)`
  color: ${({ textColor }) => textColor};
  padding: 14px ${PRESENTATION_SPACING} ${PRESENTATION_SPACING};
`;

const UploadDate = styled.div`
  color: ${(p) => p.textColor};
  font-size: ${(p) => p.theme.fontStyle.bodyS};
  opacity: ${TEXT_OPACITY};
  padding-top: ${(p) => p.theme.spacing.small};
  transition: color ${TRANSITION_SETTINGS};

  @media ${MEDIUM_DOWN} {
    padding-left: ${(p) => p.theme.spacing.tiny};
  }
`;

const ContentWrapper = styled(Flex)`
  flex-grow: 1;
`;

const COUNTDOWN_DURATION = 50;

const mobileStyles = css`
  @media ${MEDIUM_DOWN} {
    ${AutoPlayLabel} {
      top: ${AUTOPLAY_TOP_OFFSET};
      left: ${PRESENTATION_SPACING};
      position: absolute;
    }

    ${Header} {
      margin: 0;
    }

    ${PlayerWrapper} {
      flex-direction: column;
      padding: 0;
    }

    ${LeftArrow},
    ${RightArrow} {
      display: none;
    }

    ${StyledPlayerAssetSlider} {
      ${AssetSliderPageNavigation} {
        &.right,
        &.left {
          display: none;
        }
      }
    }

    ${StyledAspectContain} {
      padding: 0;
    }
  }

  @media ${MEDIUM_DOWN} and (orientation: landscape) {
    overflow: hidden;

    ${ContentWrapper} {
      border: none;
    }

    ${AutoPlayLabel} {
      left: auto;
      right: ${PRESENTATION_SPACING};
      top: 32px;
    }

    ${SliderWrapper} {
      display: none;
    }

    ${PlayerWrapper} {
      flex-direction: row;
    }

    ${LeftArrow},
    ${RightArrow} {
        display: flex;
      }
    }
  }
`;

export const ReelPlayerWrapper = styled(Flex)`
  flex-grow: 1;

  // When touch events are on, the <Header /> is hidden allowing for the mobile
  // positioning changes to take effect. File name and index move from above the
  // asset to below the asset in portrait mobile view. In mobile landscape view,
  // autoplay toggle moves into the right corner and file name and index move to
  // the lower left and right corners of the screen.
  //
  //TODO(Christi): split mobile styles into separate component
  ${({ hasTouchEvents }) => hasTouchEvents && mobileStyles};
`;

/**
 * ReelPlayer component.
 * @param {Object} props - Props ReelPlayer.
 * @returns {ReactElement} - ReelPlayer component.
 */
class ReelPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.intervalId = null;
    const {
      presentation: { autoplay },
    } = props;
    this.state = {
      hasPlayedOnceAlready: false,
      selectedIndex: 0,
      isAutoPlayEnabled: autoplay,
      timeRemaining: COUNTDOWN_DURATION,
      isTimerPaused: false,
      isPlayerPaused: true,
    };
  }

  componentDidMount() {
    const { selectedIndex } = this.state;
    this.startTimer();
    this.bindKeys();
    this.selectAsset(selectedIndex); // carousel always starts at 0
  }

  componentDidUpdate(prevProps) {
    const {
      presentation: { autoplay: autoPlayEditorSetting },
      readOnly: isPresentationEditor,
    } = this.props;
    const {
      presentation: { autoplay: prevAutoPlayEditorSetting },
    } = prevProps;

    if (
      isPresentationEditor &&
      autoPlayEditorSetting !== prevAutoPlayEditorSetting
    ) {
      this.overrideIsAutoPlayEnabledState(autoPlayEditorSetting);
    }
  }

  componentWillUnmount() {
    this.unbindKeys();
  }

  onVideoEnd = () => {
    /**
     * Exit full screen if there is an element in full screen
     * so that we don't get stuck on the last frame
     */
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    if (this.state.isAutoPlayEnabled && !this.isLastAssetSelected()) {
      this.selectNextAsset();
    }
  };

  onKeyDown = (e) => {
    const { assets } = this.props;
    const { selectedIndex } = this.state;
    const selectedAsset = assets[selectedIndex];
    switch (e.key) {
      case 'ArrowLeft':
        e.preventDefault();
        this.selectPreviousAsset();
        break;
      case 'ArrowRight':
        e.preventDefault();
        this.selectNextAsset();
        break;
      case ' ':
        e.preventDefault();
        if (isVideoType(selectedAsset) || isAudioType(selectedAsset)) {
          this.togglePlayerPause();
        } else {
          this.toggleTimerPause();
        }
        break;
      default:
        break;
    }
  };

  onTimerTick = () => {
    if (this.state.isTimerPaused) return;
    const { assets } = this.props;
    const { isAutoPlayEnabled, selectedIndex } = this.state;
    const selectedAsset = assets[selectedIndex];
    if (
      isAutoPlayEnabled &&
      (!isVideoType(selectedAsset) || !isAudioType(selectedAsset))
    ) {
      if (this.state.timeRemaining === 0 && !this.isLastAssetSelected()) {
        // timer ends, go to next asset
        clearInterval(this.intervalId);
        this.selectNextAsset();
      } else {
        this.setState({ timeRemaining: this.state.timeRemaining - 1 });
      }
    }
  };

  onAssetChange = () => {
    const { assets, presentation, onAssetChange } = this.props;
    const { selectedIndex, isAutoPlayEnabled } = this.state;
    const selectedAsset = assets[selectedIndex];
    onAssetChange(presentation.id, selectedAsset.id, isAutoPlayEnabled);
    this.setState({
      hasPlayedOnceAlready: false,
    });
  };

  onAutoPlayToggle = () => {
    const { isAutoPlayEnabled } = this.state;
    const { trackingPage } = this.props;
    this.setState({ isAutoPlayEnabled: !isAutoPlayEnabled }, this.onAutoPlay);
    const eventName = isAutoPlayEnabled ? 'autoplay off' : 'autoplay on';
    trackButtonClick(eventName, trackingPage, 'reel');
  };

  onAutoPlay = () => {
    const { isAutoPlayEnabled, selectedIndex } = this.state;
    const { assets } = this.props;
    const selectedAsset = assets[selectedIndex];
    if (isAutoPlayEnabled) {
      if (isVideoType(selectedAsset) || isAudioType(selectedAsset)) {
        this.setState({
          isPlayerPaused: false,
          isTimerPaused: true,
        });
      } else {
        this.startTimer();
      }
    } else {
      // prevent autoplay from running when toggle is off
      this.setState({
        isTimerPaused: true,
        isPlayerPaused: true,
      });
    }
  };

  overrideIsAutoPlayEnabledState = (autoPlayEditorSetting) => {
    this.setState({ isAutoPlayEnabled: autoPlayEditorSetting });
  };

  toggleTimerPause = () => {
    this.setState({ isTimerPaused: !this.state.isTimerPaused });
  };

  togglePlayerPause = () => {
    this.setState({ isPlayerPaused: !this.state.isPlayerPaused });
  };

  startTimer = () => {
    clearInterval(this.intervalId);
    this.setState(
      {
        timeRemaining: COUNTDOWN_DURATION,
        isTimerPaused: false,
        isPlayerPaused: true,
      },
      () => {
        this.intervalId = setInterval(this.onTimerTick, 100);
      }
    );
  };

  selectResolution = (resolution) => {
    this.setState({ resolution });
  };

  selectAsset = (assetIndex) => {
    this.setState(
      {
        selectedIndex: assetIndex,
      },
      this.onAutoPlay
    );
    this.onAssetChange();
  };

  selectPreviousAsset = () => {
    const { selectedIndex } = this.state;
    const { assets, onSelect } = this.props;
    const previousIndex =
      selectedIndex > 0 ? selectedIndex - 1 : assets.length - 1;
    this.selectAsset(previousIndex);
    onSelect(previousIndex); // sends index back to Presentation for download
  };

  selectNextAsset = () => {
    const { selectedIndex } = this.state;
    const { assets, onSelect } = this.props;
    const nextIndex = (selectedIndex + 1) % assets.length;
    this.selectAsset(nextIndex);
    onSelect(nextIndex); // sends index back to Presentation for download
  };

  /**
   * Sync ReelPlayer video state with PresentationPlayer media state.
   * @param {bool} isPlayerPaused - Media state from PresentationPlayer.
   */
  syncPlayPause = (isPlayerPaused) => {
    const { assets, isSessionWatermarked } = this.props;
    const { hasPlayedOnceAlready, selectedIndex } = this.state;
    const stateUpdates = { isPlayerPaused };
    const selectedAsset = assets[selectedIndex] || null;

    if (!isPlayerPaused && !hasPlayedOnceAlready && selectedAsset) {
      trackMediaStartPlayed(selectedAsset, {
        isSessionBasedWatermarked: isSessionWatermarked,
      });
      stateUpdates.hasPlayedOnceAlready = true;
    }
    this.setState(stateUpdates);
  };

  bindKeys = () => {
    if (!this.props.readOnly)
      window.addEventListener('keydown', this.onKeyDown);
  };

  unbindKeys = () => {
    if (!this.props.readOnly)
      window.removeEventListener('keydown', this.onKeyDown);
  };

  /**
   * Sometimes thumb does not exist, if not, do default images,
   * @param {Object} asset - React prop.
   * @returns {Object} - Object w/ the BaseThumb component & the onClick function.
   */
  transformAsset = (asset, index) => ({
    // TODO(Christi) RNC-815 -
    // refactor AssetSlider to just use children
    // instead of React.cloneElement
    component: (
      <AnimatingBaseThumb
        captionViewSpacing={0}
        view={
          <Media query={SMALL}>
            {(isSmallScreen) => (
              <WithTooltip
                disabled={isSmallScreen}
                message={asset.name}
                position="top"
              >
                {/** WithToolTip expects child to accept a ref. Using div here */}
                <div>
                  <AssetThumb
                    // dimensions needed for popover to calculate placement
                    // see: https://bit.ly/2pYhOir for explaination
                    // on magic numbers below
                    height={ASSET_THUMB_HEIGHT - HEIGHT_OFFSET - WIDTH_OFFSET}
                    width={ASSET_THUMB_WIDTH - WIDTH_OFFSET}
                    asset={asset}
                    backgroundColor={ASSET_THUMB_BACKGROUND}
                    // disable hoverscrub on smaller devices
                    scrubberBarWidth={isSmallScreen ? 0 : 1}
                  />
                </div>
              </WithTooltip>
            )}
          </Media>
        }
        index={index}
      />
    ),
    onClick: () => {
      this.selectAsset(index);
      trackButtonClick(
        'select from asset slider',
        this.props.trackingPage,
        'reel'
      );
      // TODO(Christi): hoist selectedIndex state entirely to presentation to minimize duplication
      this.props.onSelect(index); // sends index back to Presentation for download
    },
  });

  isLastAssetSelected = () => {
    const { selectedIndex } = this.state;
    const { assets } = this.props;
    const lastAsset = assets[assets.length - 1];
    const selectedAsset = assets[selectedIndex];
    return selectedAsset === lastAsset;
  };

  render() {
    const {
      accountId,
      assets,
      canUse4KPlayback,
      isAuthorized,
      presentation,
      displayNameForPresentationAsset,
      isSessionWatermarked,
      includeUploadDate,
      readOnly,
      team,
      trackingPage,
    } = this.props;
    const { layout } = presentation;
    const {
      selectedIndex,
      isAutoPlayEnabled,
      timeRemaining,
      isTimerPaused,
      isPlayerPaused,
    } = this.state;
    const selectedAsset = assets[selectedIndex];
    const isHlsRequired = shouldUseHlsHook(selectedAsset);
    const name = displayNameForPresentationAsset(selectedAsset);
    const calculatedTimeRemaining = 1 - timeRemaining / COUNTDOWN_DURATION;
    const insertedAt = get(assets[selectedIndex], 'inserted_at');
    const assetIds = assets.map((asset) => asset.id);
    const { accent, background, text } = presentationColorsWithFallback(
      presentation,
      team
    );
    const backgroundColor = `#${background}`;
    const textColor = `#${text}`;
    const accentColor = `#${accent}`;
    const transformedAssets = assets.map(this.transformAsset);
    const assetSliderProps = {
      imageWidth: ASSET_THUMB_WIDTH,
      imageHeight: ASSET_THUMB_HEIGHT,
      height: ASSET_SLIDER_HEIGHT,
      imageHorizontalPadding: ASSET_THUMB_PADDING,
      components: transformedAssets,
      selectedThumbIndex: selectedIndex,
      // {TODO} (Christi) - remove dependency on JTConstants.
      animationEngine: JTConstants.ANIMATION_ENGINE,
    };

    const assetNameOrLink = () =>
      isAuthorized ? (
        <AssetNameLink
          target="_blank"
          rel="noopener noreferrer"
          accentColor={accentColor}
          href={getPlayerUrl(selectedAsset.id)}
        >
          {name}
          <AssetIconContainer>
            <StyledGoToIcon height={16} width={16} color={textColor} />
          </AssetIconContainer>
        </AssetNameLink>
      ) : (
        <AssetName textColor={textColor}>{name}</AssetName>
      );

    const autoPlaySwitch = (
      <AutoPlayLabel>
        <AutoplayText textColor={textColor}>Autoplay</AutoplayText>
        <Switch
          data-test-id="autoplay-switch"
          onChange={this.onAutoPlayToggle}
          isOn={isAutoPlayEnabled}
          onColor={accentColor}
        />
      </AutoPlayLabel>
    );

    return (
      <ReelPlayerWrapper
        column
        hasTouchEvents={Modernizr.touchevents}
        readOnly={readOnly}
      >
        {Modernizr.touchevents ? (
          <Media query={MEDIUM_DOWN}>{autoPlaySwitch}</Media>
        ) : (
          <Header textColor={textColor}>
            {autoPlaySwitch}
            <Flex justifyBetween column alignCenter>
              <Truncate>{assetNameOrLink()}</Truncate>
              <FadeTransition in={includeUploadDate}>
                <UploadDate textColor={textColor}>
                  Uploaded on {formatLongDateTime(insertedAt)}
                </UploadDate>
              </FadeTransition>
            </Flex>
            <AssetIndex>{`${selectedIndex + 1} of ${
              assets.length
            }`}</AssetIndex>
          </Header>
        )}
        <ContentWrapper column>
          <PlayerWrapper justifyCenter>
            <StyledAspectContain ratio="16:9">
              <VirtualCarousel keys={assetIds} currentKey={selectedAsset.id}>
                {(assetId) => (
                  <AssetViewer
                    accountId={accountId}
                    canUse4KPlayback={canUse4KPlayback}
                    key={assetId}
                    asset={assets[assetIds.indexOf(assetId)]}
                    accentColor={accentColor}
                    layout={layout}
                    onVideoEnd={this.onVideoEnd}
                    timeRemaining={calculatedTimeRemaining}
                    toggleTimerPause={this.toggleTimerPause}
                    isAutoPlayEnabled={isAutoPlayEnabled}
                    isHlsPlayerEnabled={isHlsRequired}
                    isSessionWatermarked={isSessionWatermarked}
                    isTimerPaused={isTimerPaused}
                    isPlayerPaused={isPlayerPaused}
                    isActive={selectedAsset.id === assetId}
                    shouldAutoLoad
                    syncPlayPause={this.syncPlayPause}
                    trackingPage={trackingPage}
                    onPlayButtonClick={() =>
                      this.setState({ isPlayerPaused: !isPlayerPaused })
                    }
                  />
                )}
              </VirtualCarousel>
            </StyledAspectContain>
            <LeftArrow
              data-test-id="reel-player-left-arrow"
              color={textColor}
              onClick={() => {
                trackButtonClick('previous asset', trackingPage, 'reel');
                this.selectPreviousAsset();
              }}
            />
            <RightArrow
              data-test-id="reel-player-right-arrow"
              color={textColor}
              onClick={() => {
                trackButtonClick('next asset', trackingPage, 'reel');
                this.selectNextAsset();
              }}
            />
          </PlayerWrapper>
          {Modernizr.touchevents && (
            <Media query={MEDIUM_DOWN}>
              <InfoWrapper justifyBetween textColor={textColor}>
                <Flex column>
                  <Truncate>{assetNameOrLink()}</Truncate>
                  <FadeTransition in={includeUploadDate}>
                    <UploadDate textColor={textColor}>
                      Uploaded on {formatLongDateTime(insertedAt)}
                    </UploadDate>
                  </FadeTransition>
                </Flex>
                <AssetIndex>{`${selectedIndex + 1} of ${
                  assets.length
                }`}</AssetIndex>
              </InfoWrapper>
            </Media>
          )}
        </ContentWrapper>
        {Modernizr.touchevents && (
          <Media query={MEDIUM_DOWN}>{autoPlaySwitch}</Media>
        )}
        <SliderWrapper>
          <StyledPlayerAssetSlider
            accentColor={accentColor}
            backgroundColor={backgroundColor}
            {...assetSliderProps}
          />
        </SliderWrapper>
      </ReelPlayerWrapper>
    );
  }
}
ReelPlayer.defaultProps = {
  readOnly: false,
};

ReelPlayer.defaultProps = {
  assets: [],
  includeUploadDate: false,
  onAssetChange: () => {},
};

ReelPlayer.propTypes = {
  assets: PropTypes.array,
  includeUploadDate: PropTypes.bool,
  presentation: PropTypes.shape({
    id: PropTypes.string,
    layout: PropTypes.string,
    autoplay: PropTypes.bool,
    include_ext: PropTypes.bool,
    text_color: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isSessionWatermarked: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
  trackingPage: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  onAssetChange: PropTypes.func,
  displayNameForPresentationAsset: PropTypes.func.isRequired,
};

export default ReelPlayer;

export const testExports = {
  AssetName,
  AssetIndex,
  AssetNameLink,
  Header,
  InfoWrapper,
  StyledPlayerAssetSlider,
  RightArrow,
  LeftArrow,
  AutoplayText,
};
