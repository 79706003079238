import React from 'react';
import PropTypes from 'prop-types';
import PlanUpgradeCTA from 'components/AccountCTA/PlanUpgradeCTA';
import styled from 'styled-components';
import SetupAccountCTA from './SetupAccountCTA';
import TRACKING_LOCATIONS, { CALL_TO_ACTION_TYPE } from './constants';

const Padding = styled.div`
  ${(p) =>
    p.trackingLocation === TRACKING_LOCATIONS.ACCOUNT_DROPDOWN &&
    `
    padding: ${p.theme.spacing.units(1)};
    padding-bottom: 0;
    text-align: center;
    display: flex;
  `}
`;

export default function AccountCTA({ trackingLocation, cta }) {
  switch (cta) {
    case CALL_TO_ACTION_TYPE.setupAccount:
      return (
        <Padding trackingLocation={trackingLocation}>
          <SetupAccountCTA trackingLocation={trackingLocation} />
        </Padding>
      );
    case CALL_TO_ACTION_TYPE.planUpgrade:
      return <PlanUpgradeCTA trackingLocation={trackingLocation} />;
    default:
      return null;
  }
}

AccountCTA.defaultProps = {
  cta: null,
  trackingLocation: '',
};

AccountCTA.propTypes = {
  cta: PropTypes.string,

  // Location of the component. Used for tracking.
  trackingLocation: PropTypes.string,
};
