import { connect } from 'react-redux';
import { executeCustomAction } from '@frameio/core/src/customActions/actions';
import { customActionEntitySelector } from '@frameio/core/src/customActions/selectors';

import CustomAction from './CustomAction';

function mapStateToProps(state, { customActionId }) {
  return {
    action: customActionEntitySelector(state, { customActionId }),
  };
}

const mapDispatchToProps = {
  executeCustomAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomAction);

export const testExports = {
  mapDispatchToProps,
  mapStateToProps,
};
