import { connect } from 'react-redux';
import Success from './Success';
import { createProject, createDemoProject } from '../actions';

const mapDispatchToProps = {
  createProject,
  createDemoProject,
};

export default connect(
  null,
  mapDispatchToProps
)(Success);
