import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { size } from 'polished';

import { label as WORKFLOW_STATUSES } from '@frameio/core/src/assets/helpers/constants';

import { WORKFLOW_STATUS_DISPLAYS, WORKFLOW_COLORS } from './constants';

const EMPTY_RING_COLOR = '#636882';
const INDICATOR_SIZE = '8px';
const LARGE_INDICATOR_SIZE = '10px';

export const Indicator = styled.div.attrs(() => ({
  role: 'presentation',
}))`
  ${({ isLarge }) => size(isLarge ? LARGE_INDICATOR_SIZE : INDICATOR_SIZE)}
  box-shadow: ${({ status }) =>
    status === WORKFLOW_STATUSES.NONE && `inset 0 0 0 1px ${EMPTY_RING_COLOR}`};
  border-radius: ${(p) => p.theme.radius.circle};
  background-color: ${({ status }) => WORKFLOW_COLORS[status]};
  flex-shrink: 0;
`;

export const Label = styled.span.attrs(({ status }) => ({
  children: WORKFLOW_STATUS_DISPLAYS[status],
}))`
  ${Indicator} + & {
    margin-left: ${(p) => p.theme.spacing.tiny};
  }
`;

function AssetWorkflowStatusLabel({ status, useLargeIndicator }) {
  return (
    <React.Fragment>
      <Indicator status={status} isLarge={useLargeIndicator} />
      <Label status={status} />
    </React.Fragment>
  );
}

AssetWorkflowStatusLabel.defaultProps = {
  status: null,
  useLargeIndicator: false,
};

AssetWorkflowStatusLabel.propTypes = {
  status: PropTypes.oneOf(Object.values(WORKFLOW_STATUSES)),
  useLargeIndicator: PropTypes.bool,
};

export default AssetWorkflowStatusLabel;
