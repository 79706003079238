import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import CloseButton from '@frameio/components/src/components/Modal/CloseButton';
import Button from '@frameio/components/src/styled-components/Button';
import { CANCELLATION_CONFIRMATION_URL } from '../CancelAccountFlow';
import ProgressDots from '../ProgressDots';

const TEXT = {
  SECONDARY_BUTTON: {
    DONT_CANCEL: 'Don’t cancel',
  },
  PRIMARY_BUTTON: {
    NEXT: 'Next',
    CANCEL_SUBSCRIPTION: 'Cancel subscription',
  },
};

const CancelAccountFlowModal = ({
  currentStep,
  headerText,
  descriptionText,
  handleSubmit,
  submitDisabled,
  isDestructiveToProceed = false,
  children,
  onClose,
}) => {
  const isLastStep = currentStep === CANCELLATION_CONFIRMATION_URL;

  const primaryButtonText = isLastStep
    ? TEXT.PRIMARY_BUTTON.CANCEL_SUBSCRIPTION
    : TEXT.PRIMARY_BUTTON.NEXT;

  return (
    <Container column>
      <Header>{headerText}</Header>
      {descriptionText && <Description>{descriptionText}</Description>}
      <StyledCloseButton onClick={onClose} />
      {children}
      <BottomNav>
        <Flex justifyBetween>
          <ProgressDots currentStep={currentStep} />
          <div>
            <Button secondary text onClick={onClose}>
              {TEXT.SECONDARY_BUTTON.DONT_CANCEL}
            </Button>
            <Button
              primary={!isDestructiveToProceed}
              destructive={isDestructiveToProceed}
              type="submit"
              onClick={handleSubmit}
              disabled={submitDisabled}
              error={isLastStep}
            >
              {primaryButtonText}
            </Button>
          </div>
        </Flex>
      </BottomNav>
    </Container>
  );
};

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: ${(p) => p.theme.spacing.tiny};
  right: ${(p) => p.theme.spacing.tiny};
`;

const Header = styled.span`
  display: flex;
  font-weight: bold;
  font-size: ${(p) => p.theme.fontSize[3]};
  color: ${(p) => p.theme.color.coolBlack};
  margin-bottom: ${(p) => p.theme.spacing.units(2)};
`;

const Description = styled.p`
  ${(p) => p.theme.fontStyle.body};
  margin-bottom: ${(p) => p.theme.spacing.units(2)};
`;

const Container = styled(Flex)`
  height: ${(p) => p.theme.spacing.units(52)};
  width: ${(p) => p.theme.spacing.units(50)};
  padding: ${(p) => p.theme.spacing.medium};
`;

const BottomNav = styled.div`
  margin-top: auto;
`;

export default CancelAccountFlowModal;
