/* eslint-disable import/prefer-default-export */
import { label as WORKFLOW_STATUSES } from '@frameio/core/src/assets/helpers/constants';

/**
 * Human readable labels.
 */
export const WORKFLOW_STATUS_DISPLAYS = {
  [WORKFLOW_STATUSES.APPROVED]: 'Approved',
  [WORKFLOW_STATUSES.IN_PROGRESS]: 'In Progress',
  [WORKFLOW_STATUSES.NEEDS_REVIEW]: 'Needs Review',
  [WORKFLOW_STATUSES.NONE]: 'No Status',
};

export const WORKFLOW_STATUS_POPOVER_DISPLAYS = {
  [WORKFLOW_STATUSES.APPROVED]: 'Approved',
  [WORKFLOW_STATUSES.IN_PROGRESS]: 'In Progress',
  [WORKFLOW_STATUSES.NEEDS_REVIEW]: 'Needs Review',
  [WORKFLOW_STATUSES.NONE]: 'No Status',
};

export const WORKFLOW_COLORS = {
  [WORKFLOW_STATUSES.APPROVED]: '#1DC0A6',
  [WORKFLOW_STATUSES.IN_PROGRESS]: '#61CAFF',
  [WORKFLOW_STATUSES.NEEDS_REVIEW]: '#FF9659',
};
