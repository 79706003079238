import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  removeToastsBy,
  showActivityToast,
  showErrorToast,
} from 'actions/toasts';
import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from 'components/AssetActions/AdobeIntegration/integrationConstants';
import { activeToastsSelector } from 'selectors/toasts';
import { createAsyncSaga } from '@frameio/core/src/shared/sagas/helpers';
import { getFolders, getRepositories, sendAssets } from './services';
import {
  AEM,
  setAemChildFolders,
  setAemRepositories,
  setAemRootFolders,
} from './actions';

/**
 * @param {string} projectId - project id.
 * @param {string} aemRepositoryId - id of the aem repository
 * @param {string} aemPath - optional path of parent folder for fetching nested children
 */
export const getAemFolders = createAsyncSaga(AEM.GET_AEM_FOLDERS, getFolders);

/**
 * @param {string} projectId - project id.
 */
export const getAemRepositories = createAsyncSaga(
  AEM.GET_AEM_REPOSITORIES,
  getRepositories
);

/**
 *
 * @param {String} projectId - project id
 * @param {Array<String>} assetIds - selected assets to upload
 * @param {String} aemRepositoryId - selected AEM repository
 * @param {String} aemPath - the path of the selected AEM directory
 */
export const sendToAem = createAsyncSaga(AEM.SEND, sendAssets);

function* handleErrorToasts(header, currentToasts) {
  // if users are fetching repositories/folders and calls continue
  // to fail we want to avoid a cascade of error toasts with the same messaging
  // blocking the UI.
  const isErrorToastCurrentlyDisplayed = currentToasts.filter(
    (toast) => toast.header === header
  );
  if (isErrorToastCurrentlyDisplayed) yield put(removeToastsBy({ header }));
}

function* handleGetAemRepositoriesFailure(status) {
  const currentToasts = yield select(activeToastsSelector);
  switch (status) {
    case 403:
    case undefined:
      {
        const header = ERROR_MESSAGES.aemPermissionFailure;
        if (currentToasts.length) {
          yield call(handleErrorToasts, header, currentToasts);
        }
        yield put(showErrorToast({ header }));
      }
      break;
    case 417:
      {
        const header = ERROR_MESSAGES.fetchAemRepositoryFailure;
        if (currentToasts.length) {
          yield call(handleErrorToasts, header, currentToasts);
        }
        yield put(
          showErrorToast({
            header,
          })
        );
      }
      break;
    default:
      break;
  }
}

export function* handleGetAemRepositories(projectId) {
  const { success, failure } = yield call(getAemRepositories, projectId);

  if (failure) {
    const status = failure.payload?.error?.response?.status;
    return yield call(handleGetAemRepositoriesFailure, status);
  }
  const aemRepositoryData = success?.payload?.response?.data || [];
  if (!aemRepositoryData.length) return null;
  let aemRepositories = {};

  aemRepositoryData.forEach((aemRepository) => {
    aemRepositories = {
      ...aemRepositories,
      [aemRepository.repo_id]: aemRepository,
    };
  });
  return yield put(setAemRepositories(aemRepositories));
}

function* getAemFoldersByRepositoryId({ payload }) {
  const { aemPath, aemRepositoryId, projectId } = payload;
  if (!aemRepositoryId) return null;

  const currentToasts = yield select(activeToastsSelector);
  const header = ERROR_MESSAGES.fetchAemFolderFailure;
  const { success, failure } = yield call(
    getAemFolders,
    projectId,
    aemRepositoryId,
    aemPath
  );

  if (currentToasts.length) {
    yield call(handleErrorToasts, header, currentToasts);
  }

  if (failure) {
    return yield put(showErrorToast({ header }));
  }

  const { data } = success?.payload?.response || {};
  if (data.root) {
    const { root } = data;
    const rootId = root[0].id;
    const rootFolder = { [rootId]: [] };
    let aemFolders = { root };

    data[rootId].forEach((aemFolder) => {
      rootFolder[rootId].push(aemFolder);
      aemFolders = {
        ...aemFolders,
        ...rootFolder,
        [aemFolder.id]: [],
      };
    });
    return yield put(setAemRootFolders(root[0].repo_id, aemFolders, rootId));
  }

  const parentFolderId = Object.keys(data)[0];

  // handles when there is no child folders
  if (!data[parentFolderId].length) {
    return yield put(setAemChildFolders('', '', []));
  }
  const childFolders = data[parentFolderId] || [];

  return yield put(
    setAemChildFolders(aemRepositoryId, parentFolderId, childFolders)
  );
}

export function* sendAssetsToAemDestination(payload) {
  const { aemPath, aemRepositoryId, assetIds, projectId } = payload || {};
  const { failure, success } = yield call(
    sendToAem,
    projectId,
    assetIds,
    aemRepositoryId,
    aemPath
  );

  const isPartialFailure = !!success?.payload?.response?.failures?.length;
  // entire batch failed to upload
  if (failure) {
    return yield put(showErrorToast({ header: ERROR_MESSAGES.aemSendFailure }));
  }
  // some of the batch failed to upload
  if (success && isPartialFailure) {
    return yield put(
      showErrorToast({ header: ERROR_MESSAGES.aemPartialSendFailure })
    );
  }
  // all succeeded
  return yield put(
    showActivityToast({ header: SUCCESS_MESSAGES.aemSendSuccess })
  );
}

export default [
  takeEvery(AEM.SELECT_AEM_DESTINATION, getAemFoldersByRepositoryId),
];
