import { contextMenuPropsSelector } from 'components/ContextMenuManager/selectors';
import { createSelector } from 'reselect';
import { flow } from 'lodash';
import { userEntitySelector } from '@frameio/core/src/users/selectors';
import {
  paginatedListAllResultsSelector,
  paginatedListTotalCountSelector,
  paginatedListMetadataSelector,
  paginatedListIsFetchingPageOneSelector,
  shouldFetchListPageSelector,
} from '@frameio/core/src/shared/selectors';
import { presentationEntitySelector } from '@frameio/core/src/presentations/selectors';
import { formatShortName } from 'formatters/name';
import { projectContainerSelector } from '../selectors';

export const presentationsSelector = (state) =>
  projectContainerSelector(state).presentations;

const presentationsMetadataSelector = flow([
  presentationsSelector,
  paginatedListMetadataSelector,
]);

export const presentationIdsSelector = createSelector(
  presentationsSelector,
  paginatedListAllResultsSelector
);

export const totalPagesSelector = createSelector(
  presentationsMetadataSelector,
  ({ totalPages }) => totalPages
);

export const arePresentationsEmptySelector = createSelector(
  presentationsMetadataSelector,
  ({ total }) => total === 0
);

export const shouldFetchPresentationsSelector = (state, { page }) => {
  const slice = presentationsSelector(state);
  return shouldFetchListPageSelector(slice, { page });
};

export const isFirstPageFetchingSelector = (state) => {
  const slice = presentationsSelector(state);
  return paginatedListIsFetchingPageOneSelector(slice);
};

export const presentationTotalCountSelector = createSelector(
  presentationsSelector,
  paginatedListTotalCountSelector
);

export const presentationOptionsSelector = createSelector(
  presentationsSelector,
  ({ sortBy, isSortDescending, pageSize }) => ({
    sortBy,
    isDescending: isSortDescending,
    pageSize,
  })
);

export const sortBySelector = createSelector(
  presentationOptionsSelector,
  ({ sortBy }) => sortBy
);

export const isSortDescendingSelector = createSelector(
  presentationOptionsSelector,
  ({ isDescending }) => isDescending
);

export function creatorNameForPresentationSelector(state, { presentationId }) {
  const presentation = presentationEntitySelector(state, { presentationId });
  const userEntity = userEntitySelector(state, {
    userId: presentation.owner_id,
  });
  return formatShortName(userEntity);
}

export const presentationIdForContextMenuSelector = (state) => {
  const menuProps = contextMenuPropsSelector(state);

  return menuProps.presentationId;
};
