import { generateActionTypes } from 'shared/actions/helpers';

export const CREATE_PROJECT_FORM = generateActionTypes('CREATE_PROJECT_FORM', [
  'SET_TEAM_ID',
]);

/**
 * @param   {string} teamId - Team id in which the project will be created under.
 * @returns {Object} - Action object.
 */
export function setTeamId(teamId) {
  return {
    type: CREATE_PROJECT_FORM.SET_TEAM_ID,
    payload: { teamId },
  };
}
