import React from 'react';
import styled from 'styled-components';
import ModalHeader from '@frameio/components/src/styled-components/Modal/ModalHeader';
import IconCircle, {
  iconCircleColors,
} from '@frameio/components/src/styled-components/IconCircle';
import { TabSelect } from '@frameio/components';
import FeatureFlags from './FeatureFlags';
import Context from './Context';
import Data from './Data';

const FEATURE_FLAG_TAB = 'FEATURE_FLAG_TAB';
const CONTEXT_TAB = 'CONTEXT_TAB';
const DATA_TAB = 'DATA_TAB';
const tabs = [
  { key: CONTEXT_TAB, text: 'Context' },
  { key: FEATURE_FLAG_TAB, text: 'Feature Flags' },
  { key: DATA_TAB, text: 'Data' },
];
const MAX_WIDTH = '480px';

const Container = styled.div`
  max-width: ${MAX_WIDTH};
  width: ${MAX_WIDTH};
  padding: ${(p) => p.theme.spacing.medium};
`;

const TabSelectWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.medium};
  margin-bottom: ${(p) => p.theme.spacing.medium};

  button {
    margin-left: 0;
  }

  /* Measure is borked when used inside a modal. Overriding it to make it
     look presentable */
  .tabs-container {
    height: auto !important;
  }
  .accent-container {
    display: none;
  }
`;

export default class DevTools extends React.Component {
  state = {
    selectedTab: CONTEXT_TAB,
  };

  render() {
    const { selectedTab } = this.state;

    return (
      <Container>
        <ModalHeader
          icon={
            <IconCircle
              icon="circled_alert"
              color={iconCircleColors.PURPLE}
              shouldPulsate={false}
            />
          }
        >
          Development Tools
        </ModalHeader>

        <TabSelectWrapper>
          <TabSelect
            selectedTab={selectedTab}
            tabs={tabs}
            isDarkTheme={false}
            onTabChange={(newTab) => {
              this.setState({ selectedTab: newTab });
            }}
          />
        </TabSelectWrapper>

        {selectedTab === FEATURE_FLAG_TAB && <FeatureFlags />}
        {selectedTab === CONTEXT_TAB && <Context />}
        {selectedTab === DATA_TAB && <Data />}
      </Container>
    );
  }
}

export const testExports = {
  CONTEXT_TAB,
  FEATURE_FLAG_TAB,
  FeatureFlags,
};
