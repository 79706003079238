import React from 'react';
import PropTypes from 'prop-types';
import CancelIcon from '@frameio/components/src/svgs/icons/cancel.svg';
import { Spinner } from '@frameio/components';
import track from 'analytics';
import './RequestToJoinTeamCard.scss';

class RequestToJoinTeamCard extends React.Component {
  constructor(props) {
    super(props);
    // TODO(joel): convert this into a pure component and rely on state coming from redux.
    this.state = { isFetching: false };
  }

  UNSAFE_componentWillMount() {
    track('modal-shown', {
      account_collaborators_can_share: !this.props.shouldRestrictCollaborators,
      trigger: this.props.trackTrigger,
    });

    //  A small attempt to pre-load image before mounting lol
    const image = new Image();
    image.src =
      'https://static-assets.frame.io/onboarding/collab-cta-bg%402x.jpg';
    return image.src;
  }

  /**
   * Set onClick to take the requestFn function provided.
   * Set state of isFetching to false when done fetching.
   */
  onClick = () => {
    const {
      requestFn,
      project,
      shouldRestrictCollaborators,
      trackTrigger,
    } = this.props;

    track('clicked-join-request', {
      account_collaborators_can_share: !shouldRestrictCollaborators,
      trigger: trackTrigger,
    });

    this.setState({ isFetching: true });

    // TODO(joel): legacy usage passes a promise as requestFn but new version passes a redux action.
    // Wrapping it in a resolved promise to normalize the interface.
    Promise.resolve(requestFn(project.team_id || project.team)).finally(() =>
      this.setState({ isFetching: false })
    );
  };

  render() {
    const {
      closeFn,
      isRequestPending,
      project: { name },
    } = this.props;
    const { isFetching } = this.state;
    return (
      <div className="collaborator-cta-card-container">
        <div
          role="presentation"
          className="collaborator-cta-card-close"
          onClick={closeFn}
        >
          <CancelIcon width={24} height={24} />
        </div>
        <div className="collaborator-cta-card-inner">
          <div className="collaborator-cta-card-image">
            Switch to a Team Member role
          </div>
          <div className="collaborator-cta-card-action-container">
            <div className="collaborator-cta-card-text">
              You’re currently a collaborator on{' '}
              {name ? <strong>{name}</strong> : 'this project'}. To access
              sharing you’ll need to be added as a team member, which the
              project owner or team admin can do.
            </div>
            <button
              disabled={isRequestPending}
              className="collaborator-cta-card-button"
              onClick={this.onClick}
            >
              <span>
                {isRequestPending ? 'Request Pending' : 'Request to Join Team'}
              </span>
              <div className="collaborator-cta-card-spinner-container">
                <div className="collaborator-cta-card-spinner">
                  <Spinner spinning={isFetching} size="S" color="255,255,255" />
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

RequestToJoinTeamCard.defaultProps = {
  trackTrigger: '',
  isRequestPending: false,
};

RequestToJoinTeamCard.propTypes = {
  closeFn: PropTypes.func.isRequired,
  requestFn: PropTypes.func.isRequired,
  trackTrigger: PropTypes.string,
  isRequestPending: PropTypes.bool,
  project: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    team: PropTypes.string,
    team_id: PropTypes.string,
  }).isRequired,
  shouldRestrictCollaborators: PropTypes.bool.isRequired,
};

export default RequestToJoinTeamCard;
