import React from 'react';
import Media from 'react-media';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import Flex from 'styled-flex-component';
import track, { trackButtonClick } from 'analytics';
import Dialog, {
  dialogTypes,
} from '@frameio/components/src/styled-components/Dialog';
import Button from '@frameio/components/src/styled-components/Button';
import { MEDIUM_UP } from 'utils/mediaQueries';
import { ARCHIVED_PROJECTS_SUPPORT_URL } from 'URLs';
import ArchiveProjectIllustration from './archive-project.svg';
import initialAnimation from './initial-animation.mp4';

export const DEFAULT_TRANSITION_DURATION = 800;
const DEFAULT_EASE_OUT = 'cubic-bezier(0.4, 0, 0, 1)';

const slideDown = keyframes`
  0% { transform: translateY(0); }
  100% { transform: translateY(60px); }
`;

const slideAndScaleDown = keyframes`
  0% { transform: scale(1) translateY(0); }
  100% { transform: scale(0.9) translateY(150px); }
`;

const fadeTransition = css`
  transition: opacity 0.2s ${DEFAULT_EASE_OUT};
`;

const StyledDialog = styled(Dialog)`
  animation: ${slideDown} ${DEFAULT_TRANSITION_DURATION}ms
    cubic-bezier(0, 0, 0.3, 1);
  animation-play-state: ${({ isAnimating }) =>
    isAnimating ? 'running' : 'paused'};
  opacity: ${({ isAnimating }) => (isAnimating ? '0' : '1')};
  ${fadeTransition}
  padding-top: 0; // overwrite Dialog default padding top
`;

const IllustrationContainer = styled(Flex)`
  animation: ${slideDown} ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_EASE_OUT}
    forwards;
  animation-play-state: ${({ isAnimating }) =>
    isAnimating ? 'running' : 'paused'};
`;

const Header = styled.h1`
  text-align: center;
  font-size: ${(p) => p.theme.fontSize[3]};
  font-weight: bold;
  color: ${(p) => p.theme.color.coolBlack};
  margin: ${({ theme }) => `${theme.spacing.tiny} 0 0`};
  animation: ${slideAndScaleDown} ${DEFAULT_TRANSITION_DURATION}ms
    ${DEFAULT_EASE_OUT} forwards;
  animation-play-state: ${({ isAnimating }) =>
    isAnimating ? 'running' : 'paused'};
`;

const CopyContainer = styled.div`
  color: ${(p) => p.theme.color.gray};
  text-align: center;
  padding: 0 10px;
`;

const StyledParagraph = styled.p`
  margin-bottom: ${(p) => p.theme.spacing.units(3)};
`;

const LearnMoreLink = styled.a`
  display: block;
  width: 100%;
  text-align: center;
  color: ${(p) => p.theme.color.brand};
`;

const HeaderVideo = styled.video`
  width: 186px;
  height: 162px;
  animation: ${slideDown} ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_EASE_OUT}
    forwards;
  animation-play-state: ${({ isAnimating }) =>
    isAnimating ? 'running' : 'paused'};
`;

const HeaderImageContainer = styled.div`
  padding-top: ${(p) => p.theme.spacing.units(5)};
  animation: ${slideDown} ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_EASE_OUT}
    forwards;
  animation-play-state: ${({ isAnimating }) =>
    isAnimating ? 'running' : 'paused'};
`;

export default class ArchiveProject extends React.Component {
  state = { isAnimating: false };

  componentDidMount() {
    track('archive-project-modal-shown');
  }

  render() {
    const {
      onCancel,
      onContinue,
      isUpdatedArchivalMessagingEnabled,
    } = this.props;
    const { isAnimating } = this.state;
    return (
      <React.Fragment>
        <IllustrationContainer justifyCenter isAnimating={isAnimating}>
          <Media query={MEDIUM_UP}>
            {(isMediumUp) =>
              isMediumUp ? (
                <HeaderVideo isAnimating={isAnimating} autoPlay muted>
                  <source src={initialAnimation} type="video/mp4" />
                </HeaderVideo>
              ) : (
                <HeaderImageContainer isAnimating={isAnimating}>
                  <ArchiveProjectIllustration />
                </HeaderImageContainer>
              )
            }
          </Media>
        </IllustrationContainer>
        <Header data-test-id="header" isAnimating={isAnimating}>
          Archive project
        </Header>
        <StyledDialog
          data-test-id="dialog"
          isAnimating={isAnimating}
          type={dialogTypes.NONE}
          primaryButton={
            <Button
              data-test-id="archive-button"
              primary
              onClick={() => {
                this.setState({ isAnimating: true });
                onContinue();
                track(
                  'project-archive-started-client',
                  'archive modal',
                  'middle'
                );
                trackButtonClick('archive', 'archive modal', 'middle');
              }}
            >
              Archive
            </Button>
          }
          secondaryButton={
            <Button
              onClick={() => {
                onCancel();
                trackButtonClick('cancel', 'archive modal', 'middle');
              }}
            >
              Cancel
            </Button>
          }
        >
          {isUpdatedArchivalMessagingEnabled ? (
            <CopyContainer>
              <StyledParagraph>
                This project will be archived. You can unarchive this project
                anytime.
              </StyledParagraph>
              <p>
                <LearnMoreLink
                  href={ARCHIVED_PROJECTS_SUPPORT_URL}
                  target="_blank"
                  onClick={() =>
                    trackButtonClick('learn more', 'archive modal', 'middle')
                  }
                >
                  Learn more about archiving
                </LearnMoreLink>
              </p>
            </CopyContainer>
          ) : (
            <CopyContainer>
              <StyledParagraph>
                Assets will be sent to archival storage. We’ll keep a{' '}
                <strong>low resolution proxy available</strong> so you can view
                and share your archived content. Assets with copies in other
                projects will be kept in active storage.
              </StyledParagraph>
              <p>
                If you need the original file, you can unarchive this project.
                Archived projects usually take{' '}
                <strong>3 to 5 hours to be retrieved</strong>.
                <LearnMoreLink
                  href={ARCHIVED_PROJECTS_SUPPORT_URL}
                  target="_blank"
                  onClick={() =>
                    trackButtonClick('learn more', 'archive modal', 'middle')
                  }
                >
                  Learn more about archiving.
                </LearnMoreLink>
              </p>
            </CopyContainer>
          )}
        </StyledDialog>
      </React.Fragment>
    );
  }
}

ArchiveProject.propTypes = {
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
};

ArchiveProject.defaultProps = {
  onCancel: () => {},
  onContinue: () => {},
};

export const testExports = {
  StyledDialog,
};
