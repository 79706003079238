import React, { useEffect, useReducer, useRef, useState } from 'react';
import moment from 'moment';
import Proptypes from 'prop-types';
import styled, { css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { migrateAccountToV4 } from '@frameio/core/src/accounts/services';
import config from 'config';
import track from 'analytics';
import { Tooltip } from '@frameio/vapor';
import Avatar from '@frameio/components/src/styled-components/Avatar';
import { MEDIUM_MAX } from 'utils/breakpoints';
import PauseIcon from '@frameio/components/src/svgs/raw/ic-player-pause-16px.svg';
import PlayIcon from '@frameio/components/src/svgs/raw/ic-player-play-16px.svg';
import Spinner from '@frameio/components/src/components/Spinner';
import {
  SPINNER_COLORS,
  SPINNER_SIZES,
} from '@frameio/components/src/utils/spinnerUtils';
import Checkbox, {
  CheckCircle,
  CheckIcon,
} from '@frameio/components/src/styled-components/Checkbox';
import migrationModalBg from 'images/migrationmodalbg.jpg';
import { features } from './content';
import regularWoff2 from './fonts/Inter-Regular.woff2';
import mediumWoff2 from './fonts/Inter-Medium.woff2';
import frameGothicMediumWoff2 from './fonts/FrameGothic-Medium.woff2';
import { MODAL_VARIANT_V4 } from './variant';

export const LOCAL_STORAGE_MIGRATION_MODAL_KEY = 'hasSeenMigrationModal';
const TOAST_TIMEOUT = 5000;
const URL_V4_TERMS = 'https://frame.io/terms';
const FRAME_URL = 'https://frame.io';
const FAQ_URL =
  'https://help.frame.io/en/articles/9893008-what-to-expect-when-updating-to-v4';

const TEXT = {
  agreeToTerms: () => (
    <>
      I understand that updating to V4 cannot be undone. By selecting “Update
      Now”, I agree to the Frame.io{' '}
      <a href={URL_V4_TERMS} target="_blank" rel="noopener noreferrer">
        Terms & Conditions
      </a>
      .
    </>
  ),
  contactAdmin: 'Reach out to your admin to update to V4',
  ctaContinue: 'Continue',
  ctaNotNow: 'Not Now',
  ctaLearnMore: 'Learn More',
  ctaMobile: 'Update available on desktop',
  ctaWaitlist: 'Join the Waitlist',
  comingSoon: 'Coming soon',
  continueSubtitle:
    'Updating to V4 cannot be undone. If your workflow depends on any not available features, update to V4 at a later time.',
  ctaWaitlistSuccess: `You’ve been added to the waitlist`,
  dialogTitleEligible: (accountName) => `Update ${accountName} to V4`,
  dialogTitleIneligible: 'Coming soon for your account',
  discover: 'Discover all the ways V4 can elevate your workflow. ',
  moreInfo: () => (
    <>
      Need more information? Visit our{' '}
      <a href={FAQ_URL} target="_blank" rel="noopener noreferrer">
        support page
      </a>{' '}
      for assistance.
    </>
  ),
  titleMobile: 'Elevate your creative workflow with V4',
  subtitle:
    'Manage all of your media and assets — video, photos, design docs, PDFs, and more — on a single secure surface to create and deliver high-quality content faster.',
  title: () => (
    <>
      Elevate your creative <br />
      workflow with V4.
    </>
  ),
  titleMigrating: 'Ready to update to V4?',
  tooltipLater: 'You can update anytime from your account menu.',
  errorTitle: 'There was an issue updating your account.',
  errorTitlePrivate:
    'This account has private assets or folders which are not yet supported in V4.',
  errorDescription:
    'If the issue persists, please reach out to support@frame.io',
  errorDescriptionPrivate: 'Please reach out to support@frame.io to upgrade.',
  errorCta: 'Retry',
  migrationDetails: {
    ctaMigrate: 'Update Now',
    title: 'What to expect',
  },
};

const CloseIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="x">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7804 8.28033C21.0733 7.98744 21.0733 7.51256 20.7804 7.21967C20.4875 6.92678 20.0126 6.92678 19.7197 7.21967L14 12.9393L8.28033 7.21967C7.98744 6.92678 7.51256 6.92678 7.21967 7.21967C6.92678 7.51256 6.92678 7.98744 7.21967 8.28033L12.9393 14L7.21967 19.7197C6.92678 20.0126 6.92678 20.4874 7.21967 20.7803C7.51256 21.0732 7.98744 21.0732 8.28033 20.7803L14 15.0607L19.7197 20.7803C20.0126 21.0732 20.4874 21.0732 20.7803 20.7803C21.0732 20.4874 21.0732 20.0126 20.7803 19.7197L15.0607 14L20.7804 8.28033Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
    </g>
  </svg>
);

const CloseIconMobile = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.70711 3.79289C4.31658 3.40237 3.68342 3.40237 3.29289 3.79289C2.90237 4.18342 2.90237 4.81658 3.29289 5.20711L6.58579 8.5L3.29289 11.7929C2.90237 12.1834 2.90237 12.8166 3.29289 13.2071C3.68342 13.5976 4.31658 13.5976 4.70711 13.2071L8 9.91421L11.2929 13.2071C11.6834 13.5976 12.3166 13.5976 12.7071 13.2071C13.0976 12.8166 13.0976 12.1834 12.7071 11.7929L9.41421 8.5L12.7071 5.20711C13.0976 4.81658 13.0976 4.18342 12.7071 3.79289C12.3166 3.40237 11.6834 3.40237 11.2929 3.79289L8 7.08579L4.70711 3.79289Z"
      fill="#676A71"
    />
  </svg>
);

/**
 * The migration dialog uses a variety of design details and styles that do not match the existing design system of V3.
 * These designs are very specific and should never change without explicit direction, so we are intentionally not using
 * Vapor or styles from web-components in order to ensure the design does not unexpectedly shift due to side effects
 * from changes elsewhere.
 */

export const fontFaces = css`
  @font-face {
    font-family: 'Inter';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url(${regularWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${mediumWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Frame Gothic';
    font-style: normal;
    font-weight: 600;
    src: url(${frameGothicMediumWoff2}) format('woff2');
  }
`;

const MODAL_PADDING = '40px';

const StyledContainer = styled.div`
  ${fontFaces}
  -webkit-font-smoothing: antialiased;
  align-items: center;
  background: #151723;
  border-radius: 24px;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.36),
    0px 32px 28px 0px rgba(0, 0, 0, 0.32), 0px 1px 32px 0px rgba(0, 0, 0, 0.28);
  box-sizing: border-box;
  max-width: 900px;
  overflow: hidden;
  padding: ${MODAL_PADDING};
  position: relative;

  &:before {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 1065px;
    content: '';
    display: block;
    filter: blur(100px);
    height: 1065px;
    left: -382px;
    mix-blend-mode: overlay;
    opacity: 0.6;
    pointer-events: none;
    position: absolute;
    top: -357.5px;
    width: 1065px;
    z-index: 1;
  }

  @media (max-width: ${MEDIUM_MAX}px) {
    background: #ffffff;
    border-radius: 8px;
    margin: 0 16px;
    max-width: 468px;
    padding: 24px;

    &:before {
      display: none;
    }
  }

  @media (max-height: 800px) {
    transform: scale(0.85);
  }

  @media (max-height: 700px) {
    transform: scale(0.75);
  }
`;

const StyledMobileModalContent = styled.div`
  display: none;
  font-family: 'Avenir Next';

  @media (max-width: ${MEDIUM_MAX}px) {
    display: block;
  }
`;

const StyledMobileDialogTitle = styled.h3`
  color: #0f1013;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 16px;
`;

const StyledMobileTitle = styled.h2`
  color: #20222a;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 115.789% */
  padding-bottom: 16px;
`;

const StyledMobileCopy = styled.p`
  color: #7e829a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 16px;
`;

const StyledMobileCta = styled.div`
  border: 1px solid #d5d8e0;
  border-radius: 6px;
  color: #676a71;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  line-height: normal;
  padding: 10px 0px;
`;

const StyledPrimaryModalContent = styled.div`
  @media (max-width: ${MEDIUM_MAX}px) {
    display: none;
  }
`;

const StyledCloseButton = styled.button`
  background: none;
  border: none;
  color: rgba(189, 192, 212, 0.7);
  cursor: pointer;
  height: 28px;
  outline: none;
  position: absolute;
  right: ${MODAL_PADDING};
  top: ${MODAL_PADDING};
  width: 28px;

  &:hover {
    color: rgba(230, 232, 241, 0.8);
  }

  &:focus,
  &:focus-visible {
    box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.4);
    color: rgba(230, 232, 241, 0.8);
  }

  @media (max-width: ${MEDIUM_MAX}px) {
    position: absolute;
    right: 34px;
    top: 20px;
  }
`;

const StyledDialogTitleContainer = styled.div`
  align-items: center;
  display: flex;
`;

const StyledAvatar = styled(Avatar)`
  border-radius: 6px;
  cursor: default;
  margin-right: 12px;
`;

const StyledDialogTitle = styled.h3`
  color: #6972fb;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
`;

const StyledTitle = styled.h2`
  color: #fff;
  font-family: 'Frame Gothic', sans-serif;
  flex-shrink: 0;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.255px;
  line-height: 48px; /* 141.176% */
  padding-right: 89px;
`;

const StyledSubtitle = styled.p`
  color: rgba(212, 214, 230, 0.75);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const StyledFlexRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledVideoContainer = styled.div`
  background-image: url(${migrationModalBg});
  box-shadow: 6px 18px 41px 0px rgba(0, 0, 0, 0.1),
    24px 72px 75px 0px rgba(0, 0, 0, 0.09),
    53px 161px 102px 0px rgba(0, 0, 0, 0.05),
    95px 287px 121px 0px rgba(0, 0, 0, 0.01),
    148px 448px 132px 0px rgba(0, 0, 0, 0);
  height: 375px;
  margin: 36px -${MODAL_PADDING} ${MODAL_PADDING};
  position: relative;
  width: 900px;

  video {
    display: block;
    height: 100%;
    width: 100%;
  }
`;

const StyledScrim = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 1;
`;

const StyledPausePlayButton = styled.button`
  background: rgba(0, 0, 0, 0.5);
  border: none;
  cursor: pointer;
  height: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 2;

  &:hover,
  &:focus-visible {
    opacity: 1 !important;
  }
`;

const primaryButtonStyles = css`
  background: ${(p) =>
    p.variant === 'secondary' ? 'rgba(85, 88, 110, 0.40)' : '#5B53FF'};
  border: none;
  border-radius: 20px;
  color: #ffffff;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 40px;
  line-height: 20px; /* 142.857% */
  margin-left: 16px;
  padding: 4px 20px;
  text-align: center;

  &:hover,
  &:focus-visible {
    background: ${(p) =>
      p.variant === 'secondary' ? 'rgba(109,113,136,.5)' : '#6972fb'};
  }

  &:focus,
  &:focus-visible {
    box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.4);
    outline: none;
  }
`;

const StyledButtonGroup = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled.button`
  ${primaryButtonStyles}

  :disabled {
    background: rgba(57, 61, 79, 0.35);
    color: #6d718880;
    cursor: default;
  }
`;

const StyledAnchorAsButton = styled.a`
  ${primaryButtonStyles}

  line-height: 32px;
`;

const StyledTooltipForLater = styled(Tooltip)`
  max-width: 360px;
  top: -4px;
`;

const StyledTextForAdminRequest = styled.span`
  align-items: center;
  border-radius: 20px;
  background: rgba(44, 48, 65, 0.3);
  color: rgba(189, 192, 212, 0.7);
  display: flex;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 40px;
  line-height: 20px; /* 142.857% */
  padding: 0 20px;
  text-align: center;
`;

const FeatureSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding-bottom: 24px;
`;

const FeatureSection = styled.div`
  background-color: #1f2332;
  border-radius: 12px;
  color: rgba(212, 214, 230, 0.75);
  display: flex;
  flex-direction: column;
  flex: 50%;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding: 24px;
`;

const FeatureSectionHeading = styled.h3`
  color: #bdc0d4b5;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
  padding-bottom: 24px;
  text-transform: uppercase;
`;

const FeatureFooter = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: #d4d6e6bf;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  margin-top: auto;
  padding-top: 16px;

  a {
    color: #7e85ff;
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding-bottom: 24px;
`;

const FeatureListItem = styled.li`
  padding-bottom: 14px;
  padding-left: 36px;
  position: relative;

  :last-of-type {
    padding-bottom: 0;
  }

  svg {
    display: block;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
  }

  h4 {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }

  p {
    color: #d4d6e6bf;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
  }
`;

const StyledErrorButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  margin-left: 12px;
  padding: 0;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  padding: 0 0 32px 0;
`;

const StyledCheckBox = styled(Checkbox)`
  display: flex;
  align-items: center;
  input {
    cursor: pointer;
  }
  ${CheckCircle} {
    width: ${(p) => p.theme.spacing.units(2)};
    height: ${(p) => p.theme.spacing.units(2)};
    box-shadow: ${(p) => `inset 0 0 2px ${p.theme.color.dimGray}`};
    border-radius: ${(p) => p.theme.radius.medium};
  }
  ${CheckIcon} {
    border-radius: ${(p) => p.theme.radius.medium};
    width: ${(p) => p.theme.spacing.units(2)};
    height: ${(p) => p.theme.spacing.units(2)};
  }
`;

const StyledLabel = styled.label`
  align-items: center;
  color: #f3f3f7e5;
  cursor: pointer;
  display: flex;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 8px;

  a {
    color: #7e85ff;
    margin-left: 4px;
  }
`;

const V4MigrationModal = ({
  accountColor,
  accountId,
  accountImage,
  accountName,
  canUserInitiateAccountMigration,
  closeModal,
  isAccountEligibleForMigration,
  isEnterprise = false,
  showErrorToast,
  showSuccessToast,
  userEmail,
}) => {
  const primaryCtaRef = useRef();
  const videoRef = useRef();

  useEffect(() => {
    /**
     * The linter complains about the optional chaining here for some reason, but it's required to prevent a runtime
     * error
     */
    // eslint-disable-next-line no-unused-expressions
    primaryCtaRef.current?.focus();
  }, []);

  const canMigrate =
    canUserInitiateAccountMigration && isAccountEligibleForMigration;
  const [userWantsToContinue, setUserWantsToContinue] = useState(false);
  const [videoIsPaused, setVideoIsPaused] = useState(false);
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [videoHasLoaded, setVideoHasLoaded] = useReducer(() => true, false);
  const [isMigrating, setIsMigrating] = useState(false);

  const handleAgreeToTerms = (event) => {
    const isChecked = event.target.checked;
    setHasAgreedToTerms(isChecked);
    const trackingEventName = isChecked
      ? 'v4-upgrade-terms-checked'
      : 'v4-upgrade-terms-unchecked';

    track(trackingEventName, {
      variant: MODAL_VARIANT_V4,
    });
  };

  const playPauseVideo = () => {
    const video = videoRef.current;
    if (video?.paused) {
      video.play();
      setVideoIsPaused(false);
    } else {
      video.pause();
      setVideoIsPaused(true);
    }
  };

  const handleContinue = () => {
    track('v4-upgrade-disclaimers-modal-shown', { variant: MODAL_VARIANT_V4 });
    setUserWantsToContinue(true);
  };

  const joinWaitlist = () => {
    track('v4-upgrade-waitlist-joined', {
      email: userEmail,
      variant: MODAL_VARIANT_V4,
    });
    closeModal();

    showSuccessToast({
      autoCloseDelay: TOAST_TIMEOUT,
      header: TEXT.ctaWaitlistSuccess,
    });
  };

  const handleLearnMore = () => {
    track('v4-upgrade-learn-more-clicked', {
      variant: MODAL_VARIANT_V4,
    });
  };

  const handleClose = (buttonClicked) => {
    localStorage.setItem(
      `${LOCAL_STORAGE_MIGRATION_MODAL_KEY}-${accountId}`,
      moment()
    );

    const modalName = userWantsToContinue
      ? 'Migration Disclaimers'
      : 'Migration Awareness';

    track('v4-upgrade-modal-dismissed', {
      button_clicked: buttonClicked,
      modal_name: modalName,
      variant: MODAL_VARIANT_V4,
    });

    closeModal();
  };

  const handleMigrate = () => {
    setIsMigrating(true);
    track('v4-upgrade-initiated', { variant: MODAL_VARIANT_V4 });

    migrateAccountToV4({ accountId })
      .then(() => {
        /**
         * Once a migration has successfully been initiated, reloading the app will result in being redirected to V4.
         */
        window.location.assign(`${config.nextUrl}?a=${accountId}`);
      })
      .catch((error) => {
        setIsMigrating(false);
        const typeOfError = error?.response?.data?.status?.reason;
        track('v4-upgrade-error-shown', {
          type_of_error: typeOfError,
          variant: MODAL_VARIANT_V4,
        });

        closeModal();

        showErrorToast({
          autoCloseDelay: TOAST_TIMEOUT,
          header: (
            <>
              {typeOfError === 'private_assets'
                ? TEXT.errorTitlePrivate
                : TEXT.errorTitle}{' '}
            </>
          ),
          subHeader: (
            <>
              <span style={{ color: 'rgba(189, 192, 212, 0.7)' }}>
                {typeOfError === 'private_assets'
                  ? TEXT.errorDescriptionPrivate
                  : TEXT.errorDescription}
              </span>
              {typeOfError !== 'private_assets' && (
                <StyledErrorButton
                  disabled={isMigrating}
                  onClick={handleMigrate}
                >
                  {TEXT.errorCta}
                </StyledErrorButton>
              )}
            </>
          ),
          style: { maxWidth: 'none' },
        });
      });
  };

  const isIneligibleEnterpriseTier =
    isEnterprise && !isAccountEligibleForMigration;

  const dialogTitleIneligible = isIneligibleEnterpriseTier
    ? TEXT.comingSoon
    : TEXT.dialogTitleIneligible;

  const isMigrationButtonDisabled = !hasAgreedToTerms || isMigrating;

  return (
    <StyledContainer
      style={{ maxWidth: userWantsToContinue ? '976px' : '900px' }}
    >
      <StyledMobileModalContent>
        <StyledCloseButton onClick={() => handleClose('X')}>
          <CloseIconMobile />
        </StyledCloseButton>
        <StyledMobileDialogTitle>
          {isAccountEligibleForMigration
            ? TEXT.dialogTitleEligible(accountName)
            : dialogTitleIneligible}
        </StyledMobileDialogTitle>

        <StyledMobileTitle>{TEXT.titleMobile}</StyledMobileTitle>

        <StyledMobileCopy>{TEXT.subtitle}</StyledMobileCopy>

        <StyledMobileCta>{TEXT.ctaMobile}</StyledMobileCta>
      </StyledMobileModalContent>

      <StyledPrimaryModalContent>
        <StyledCloseButton onClick={() => handleClose('X')}>
          <CloseIcon />
        </StyledCloseButton>
        {!userWantsToContinue && (
          <StyledDialogTitleContainer>
            <StyledAvatar
              color={accountColor}
              image={accountImage}
              name={accountName ? accountName[0] : ''}
              size={32}
            />

            <StyledDialogTitle>
              {isAccountEligibleForMigration
                ? TEXT.dialogTitleEligible(accountName)
                : dialogTitleIneligible}
            </StyledDialogTitle>
          </StyledDialogTitleContainer>
        )}
        <AnimatePresence key={`continue-${userWantsToContinue}`}>
          {/**
           * The second modal screen
           * Mount if the user has clicked the Continue button
           */}
          {userWantsToContinue ? (
            <motion.div
              animate={{
                opacity: 1,
              }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.3,
              }}
            >
              <StyledTitle
                style={{ lineHeight: '28px', paddingBottom: '16px' }}
              >
                {TEXT.titleMigrating}
              </StyledTitle>
              <StyledSubtitle style={{ paddingBottom: '24px' }}>
                {TEXT.continueSubtitle}
              </StyledSubtitle>

              <FeatureSectionContainer>
                <FeatureSection>
                  <FeatureSectionHeading>
                    {features.newInV4.title}
                  </FeatureSectionHeading>
                  <FeatureList>
                    {features.newInV4.content.map((content) => {
                      return (
                        <FeatureListItem key={content.title}>
                          <content.icon />
                          <h4>{content.title}</h4>
                          <p>{content.description}</p>
                        </FeatureListItem>
                      );
                    })}
                  </FeatureList>
                  <FeatureFooter>
                    {TEXT.discover}
                    <a
                      href={FRAME_URL}
                      onClick={handleLearnMore}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {TEXT.ctaLearnMore}
                    </a>
                  </FeatureFooter>
                </FeatureSection>

                <FeatureSection>
                  <FeatureSectionHeading>
                    {features.comingSoon.title}
                  </FeatureSectionHeading>
                  <FeatureList>
                    {features.comingSoon.content.map((content) => (
                      <FeatureListItem key={content.title}>
                        <content.icon />
                        <h4>{content.title}</h4>
                        <p>{content.description}</p>
                      </FeatureListItem>
                    ))}
                  </FeatureList>
                  <FeatureSectionHeading>
                    {features.notSupported.title}
                  </FeatureSectionHeading>
                  <FeatureList>
                    {features.notSupported.content.map((content) => (
                      <FeatureListItem key={content.title}>
                        <content.icon />
                        <h4>{content.title}</h4>
                        <p>{content.description}</p>
                      </FeatureListItem>
                    ))}
                  </FeatureList>
                  <FeatureFooter>{TEXT.moreInfo()}</FeatureFooter>
                </FeatureSection>
              </FeatureSectionContainer>

              {canMigrate && (
                <CheckboxWrapper>
                  <StyledCheckBox
                    id="agree-to-terms"
                    checked={hasAgreedToTerms}
                    onChange={handleAgreeToTerms}
                  />
                  <StyledLabel htmlFor="agree-to-terms">
                    {TEXT.agreeToTerms()}
                  </StyledLabel>
                </CheckboxWrapper>
              )}

              {/** CTAs */}
              <StyledFlexRow style={{ justifyContent: 'flex-end' }}>
                {/**
                 * If an account is eligible for migration but the current user is not an admin of the account, provide a
                 * cta to contact their admin. Otherwise, render the main cta buttons.
                 */}
                {!canUserInitiateAccountMigration &&
                isAccountEligibleForMigration ? (
                  <StyledTextForAdminRequest>
                    {TEXT.contactAdmin}
                  </StyledTextForAdminRequest>
                ) : (
                  <StyledButtonGroup>
                    <StyledTooltipForLater
                      disabled={!isAccountEligibleForMigration}
                      title={TEXT.tooltipLater}
                      placement="top"
                      shouldUsePortal
                    >
                      <StyledButton
                        onClick={() => handleClose('Later')}
                        variant="secondary"
                      >
                        {TEXT.ctaNotNow}
                      </StyledButton>
                    </StyledTooltipForLater>

                    <StyledButton
                      disabled={isMigrationButtonDisabled}
                      onClick={handleMigrate}
                      variant="primary"
                    >
                      {TEXT.migrationDetails.ctaMigrate}
                    </StyledButton>
                  </StyledButtonGroup>
                )}
              </StyledFlexRow>
            </motion.div>
          ) : (
            <motion.div
              animate={{ opacity: 1 }}
              initial={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.3,
              }}
            >
              <StyledFlexRow style={{ paddingTop: '24px' }}>
                <StyledTitle>{TEXT.title()}</StyledTitle>

                <StyledSubtitle>{TEXT.subtitle}</StyledSubtitle>
              </StyledFlexRow>

              <StyledVideoContainer>
                <video
                  autoPlay
                  id="inline-upgrade-video"
                  loop
                  muted
                  name="media"
                  onCanPlayThrough={() => {
                    setVideoHasLoaded();
                    videoRef.current.play();
                  }}
                  playsInline
                  preload="metadata"
                  ref={videoRef}
                >
                  <source
                    src="https://static-assets.frame.io/videos/v4-migration-modal.mp4"
                    type="video/mp4"
                  />
                </video>

                <StyledScrim
                  style={{
                    opacity: !videoHasLoaded ? 1 : 0,
                  }}
                >
                  <Spinner
                    color={SPINNER_COLORS.white}
                    size={SPINNER_SIZES.S}
                    spinning
                  />
                </StyledScrim>

                {videoHasLoaded && (
                  <StyledPausePlayButton
                    aria-controls="inline-upgrade-video"
                    aria-label={videoIsPaused ? 'Play video' : 'Pause video'}
                    onClick={playPauseVideo}
                    show={videoIsPaused}
                    style={{ opacity: videoIsPaused ? 1 : 0 }}
                  >
                    {videoIsPaused ? <PlayIcon /> : <PauseIcon />}
                  </StyledPausePlayButton>
                )}
              </StyledVideoContainer>

              <StyledFlexRow style={{ justifyContent: 'flex-end' }}>
                <StyledButtonGroup>
                  <>
                    {!canMigrate && (
                      <StyledAnchorAsButton
                        href={FAQ_URL}
                        onClick={handleLearnMore}
                        rel="noopener noreferrer"
                        target="_blank"
                        variant={isEnterprise ? 'primary' : 'secondary'}
                      >
                        {TEXT.ctaLearnMore}
                      </StyledAnchorAsButton>
                    )}
                    {canMigrate && (
                      <StyledTooltipForLater
                        disabled={!isAccountEligibleForMigration}
                        title={TEXT.tooltipLater}
                        placement="top"
                        shouldUsePortal
                      >
                        <StyledButton
                          onClick={() => handleClose('Later')}
                          variant="secondary"
                        >
                          {TEXT.ctaNotNow}
                        </StyledButton>
                      </StyledTooltipForLater>
                    )}

                    {!isIneligibleEnterpriseTier && (
                      <StyledButton
                        ref={primaryCtaRef}
                        onClick={
                          isAccountEligibleForMigration
                            ? handleContinue
                            : joinWaitlist
                        }
                        variant="primary"
                      >
                        {/**
                         * If eligible for migration, render the Continue button. Otherwise if a user was presented with the
                         * modal even though the account is ineligible for migration, render the cta to join the waitlist.
                         */}
                        {isAccountEligibleForMigration
                          ? TEXT.ctaContinue
                          : TEXT.ctaWaitlist}
                      </StyledButton>
                    )}
                  </>
                </StyledButtonGroup>
              </StyledFlexRow>
            </motion.div>
          )}
        </AnimatePresence>
      </StyledPrimaryModalContent>
    </StyledContainer>
  );
};

export default V4MigrationModal;

V4MigrationModal.propTypes = {
  accountColor: Proptypes.string,
  accountId: Proptypes.string,
  accountImage: Proptypes.string,
  accountName: Proptypes.string,
  canUserInitiateAccountMigration: Proptypes.bool,
  closeModal: Proptypes.func.isRequired,
  isAccountEligibleForMigration: Proptypes.bool,
  isEnterprise: Proptypes.bool,
  showErrorToast: Proptypes.func.isRequired,
  showSuccessToast: Proptypes.func.isRequired,
  userEmail: Proptypes.string,
};
