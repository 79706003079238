import { IconCheckCircle, IconFolderClosed } from '@frameio/vapor-icons';
import {
  taskStatusDisplay,
  taskStatusLabels,
} from 'components/Dialog/AdobeIntegrationConnectDialog/helper';
import { isEmpty, noop } from 'lodash';
import { AEM, MENU_ITEMS, WORKFRONT } from './integrationConstants';
import WorkfrontTaskStatusIndicator from './WorkfrontTaskStatusIndicator';

export function getAssetLockedStatus(asset) {
  const { workfront_asset_locked_at } = asset || {};
  return !!workfront_asset_locked_at;
}

export function getIntegrationDataForCommenting(asset) {
  return {
    integration: asset?.workfront_connected ? 'workfront' : null,
  };
}

export function getIntegration(team) {
  const { workfront_enabled } = team || {};
  if (workfront_enabled) return WORKFRONT;

  return null;
}

export const getWorkfrontManageTaskMenuItems = (
  projectId,
  assetId,
  integration,
  update,
  { workfrontTaskId, workfrontTask } = {}
) => {
  if (isEmpty(workfrontTask)) return { name: WORKFRONT, integration };
  const handleSelect = (taskStatus) => {
    if (taskStatus === workfrontTask.status) return noop;
    return update(
      projectId,
      workfrontTaskId,
      taskStatus,
      assetId,
      workfrontTask
    );
  };

  const taskOptions = taskStatusDisplay.map((taskStatus) => ({
    details: ({ disabled }) =>
      WorkfrontTaskStatusIndicator({
        checked: taskStatus === workfrontTask.status,
        disabled,
        handleSelect,
        taskName: taskStatusLabels[taskStatus],
        taskStatus,
      }),
    canChangeStatus: workfrontTask.permissions?.can_change_status,
    status: taskStatusLabels[taskStatus],
  }));

  return {
    name: WORKFRONT,
    details: workfrontTask.title,
    submenu: taskOptions,
    integration,
  };
};

export const getConnectMenuItems = (integration, openModal, submit) => {
  switch (integration) {
    case WORKFRONT:
      return {
        name: MENU_ITEMS.ADD_TO_WORKFRONT,
        submenu: [
          {
            name: MENU_ITEMS.ADD_TO_TASK,
            icon: IconCheckCircle,
            onSelect: openModal,
          },
          {
            name: MENU_ITEMS.ADD_TO_PROJECT_DOCUMENTS,
            icon: IconFolderClosed,
            onSelect: submit,
          },
        ],
        integration,
      };
    case AEM:
      return {
        name: MENU_ITEMS.SEND_TO_AEM,
        onSelect: openModal,
        integration,
      };
    default:
      return null;
  }
};
