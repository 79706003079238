import { connect } from 'react-redux';
import {
  permittedActionsForAccountSelector,
  permittedActionsForProjectSelector,
} from 'selectors/permissions';
import { shouldAutoScaleTeamMembersAllocation } from 'selectors/subscriptions';
import {
  currentAccountWithSubscriptionAndPlanSelector,
  isAccountOnFreePlan,
  isOnPaidCollaboratorsPlanSelector,
  getIndividualSeatCostSelector,
  hasAccountReachedUserCountLimitSelector,
  getAvailableSeatsCountForPlansWithUserMaxSelector,
  isAccountOnPlanWithUserMaxSelector,
  isAccountEnterpriseSelector,
  seatsToAddToReachPlanMinUserLimitSelector,
} from 'selectors/accounts';
import {
  subscriptionEntityForAccountIdSelector,
  planEntityForAccountIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import { getLineItemsForPlan } from '@frameio/core/src/planLineItems/actions';
import { BILL_PERIOD } from 'components/SelectPlanFlow/PlanDisplay';
import { openSelectPlanFlowModal } from 'components/SelectPlanFlow/actions';
import ManagePeople from './ManagePeople';
import {
  inviteUsersToProject,
  resetResendInvites,
  setPeopleListScrollTop,
  setReduxTokens,
} from './actions';
import { projectIdSelector } from '../selectors';
import {
  isInvitingUsersSelector,
  totalCountSelector,
  newUserCountSelector,
} from './selectors';

function mapStateToProps(state) {
  const projectId = projectIdSelector(state);
  const { account } = currentAccountWithSubscriptionAndPlanSelector(state);
  const accountId = account.id;
  const { canCustomizeEmailMessages } = permittedActionsForAccountSelector(
    state,
    {
      accountId,
    }
  );
  const { id: planId, period } = planEntityForAccountIdSelector(state, {
    accountId,
  });
  const isAutoscalingSeats = shouldAutoScaleTeamMembersAllocation(state, {
    accountId,
  });
  const { canInviteCollaborators } = permittedActionsForProjectSelector(state, {
    projectId,
  });
  const isOnFreePlan = isAccountOnFreePlan(state, { accountId });
  const isPaidCollaboratorsPlan = isOnPaidCollaboratorsPlanSelector(state);
  const individualSeatCost = getIndividualSeatCostSelector(state, {
    accountId,
  });
  const subscription = subscriptionEntityForAccountIdSelector(state, {
    accountId,
  });
  const onTrialUntil =
    subscription.on_trial && subscription.promotion_expires_at
      ? subscription.promotion_expires_at
      : null;
  const isAccountOnPlanWithUserMax = isAccountOnPlanWithUserMaxSelector(state);
  const isEnterprise = isAccountEnterpriseSelector(state);
  const hasReachedUserCountLimit = hasAccountReachedUserCountLimitSelector(
    state,
    { accountId }
  );
  const availableSeatCount = getAvailableSeatsCountForPlansWithUserMaxSelector(
    state,
    {
      accountId,
    }
  );
  const isYearlyPlan = period === BILL_PERIOD.YEARLY;
  const seatsToAddToReachMinUserLimit = seatsToAddToReachPlanMinUserLimitSelector(
    state,
    { accountId }
  );
  const newUserTokenCount = newUserCountSelector(state);

  return {
    accountId,
    availableSeatCount,
    canCustomizeEmailMessages,
    canInviteCollaborators,
    hasReachedUserCountLimit,
    individualSeatCost,
    isAutoscalingSeats,
    seatsToAddToReachMinUserLimit,
    isInvitingUsers: isInvitingUsersSelector(state),
    isOnFreePlan,
    isPaidCollaboratorsPlan,
    isAccountOnPlanWithUserMax,
    isEnterprise,
    isYearlyPlan,
    onTrialUntil,
    planId,
    projectId,
    totalCount: totalCountSelector(state),
    newUserTokenCount,
  };
}

const mapDispatchToProps = {
  inviteUsersToProject,
  getLineItemsForPlan,
  openSelectPlanFlowModal,
  resetResendInvites,
  setPeopleListScrollTop,
  setReduxTokens,
};

export const testExports = { mapStateToProps, mapDispatchToProps };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagePeople);
