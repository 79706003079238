import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import track from 'analytics';
import ScrollContainer from '@frameio/components/src/styled-components/ScrollContainer';

import AccountLink from '../AccountLink';
import Account from '../Account';

const ACCENT_HEIGHT = 16;
const ACCOUNT_LOGO_SIZE = 32;
const ACCOUNT_LOGO_SPACING = 16;
const ACCOUNT_SWITCHER_WIDTH = '56px';

/*
  Calculating the active account accent top position is based on:
  1.  Logo height + logo top margin (outer offset)
  2.  Then, offset by half the difference of the logo height
      and accent height (inner offset)
*/
const ACCENT_TOP_OUTER_OFFSET = ACCOUNT_LOGO_SIZE + ACCOUNT_LOGO_SPACING;
const ACCENT_TOP_INNER_OFFSET = (ACCOUNT_LOGO_SIZE + ACCENT_HEIGHT) / 2;

const AccentLine = styled.div`
  background-color: ${(p) => p.theme.color.brand};
  width: 2px;
  height: ${ACCENT_HEIGHT}px;
  transform: ${({ translateY }) => `translateY(${translateY}px);`};
  transition: transform 0.4s cubic-bezier(0.4, 0, 0, 1) 0s;
`;

const StyledAccountLink = styled(({ isSelected, ...rest }) => (
  <AccountLink {...rest} />
))`
  display: flex;
  align-items: center;
  ${({ isSelected }) => `opacity: ${isSelected ? 1 : 0.38};`}
  transform: scale(1);
  transition: transform 0.1s ease-out 0s, opacity 0.2s ease-out 0s;

  &:hover {
    transform: scale(1.2);
  }
`;

const Wrapper = styled(ScrollContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(p) => p.theme.color.black};
  flex: 0 0 ${ACCOUNT_SWITCHER_WIDTH};
  height: 100vh;
  overflow-y: auto;
  padding: ${ACCOUNT_LOGO_SPACING}px 0;

  li {
    /*
      Setting display: block here to remove extra
      whitespace being added to li height
    */
    display: block;

    &:not(:first-child) {
      padding-top: ${ACCOUNT_LOGO_SPACING}px;
    }
  }

  ${StyledAccountLink} {
    transform: scale(1);
    transition: transform 0.1s ease-out 0s, opacity 0.2s ease-out 0s;
    &:hover {
      transform: scale(1.2);
    }
  }

  /*
    Hovering on the outermost wrapper of the Account Switcher should set the
    opacity of all Account Logos to 1.
  */
  &:hover {
    ${StyledAccountLink} {
      opacity: 1;
    }
  }

  ${AccentLine} {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default class AccountSwitcherBar extends React.Component {
  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (prevState.selectedAccountId !== nextProps.currentAccountId) {
      return {
        selectedAccountId: nextProps.currentAccountId,
      };
    }

    return null;
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedAccountId: '',
    };
  }

  /**
   * Calculate the y-position of the active account accent
   * line based on the index of the selected account id in
   * the accountIds prop
   * @returns {number} starting y-position of accent line.
   */
  getAccentTranslateY = () => {
    const { selectedAccountId } = this.state;
    const { accountIds } = this.props;

    if (!selectedAccountId) return 0;
    const activeAccountIndex = accountIds.indexOf(selectedAccountId);
    return activeAccountIndex > -1
      ? (activeAccountIndex + 1) * ACCENT_TOP_OUTER_OFFSET -
          ACCENT_TOP_INNER_OFFSET
      : 0;
  };

  updateSelectedAccount = (selectedAccountId) => {
    this.props.setAccountMigrationEligibility(undefined, undefined);

    track('account-switcher-account-switched');
    this.setState({
      selectedAccountId,
    });
  };

  render() {
    const { accountIds, currentAccountId, versions } = this.props;
    /*
      If a user has less than 2 accounts, do not render the component
    */
    if (accountIds.length < 2) return null;

    const translateY = this.getAccentTranslateY();

    return (
      <Wrapper as="ol">
        {accountIds.map((accountId) => (
          <li key={accountId}>
            <StyledAccountLink
              accountId={accountId}
              onClick={() => {
                this.updateSelectedAccount(accountId);
              }}
              isSelected={accountId === currentAccountId}
            >
              <Account
                accountId={accountId}
                size={ACCOUNT_LOGO_SIZE}
                version={versions[accountIds.indexOf(accountId)]}
              />
            </StyledAccountLink>
          </li>
        ))}
        {translateY > 0 && <AccentLine translateY={translateY} />}
      </Wrapper>
    );
  }
}

AccountSwitcherBar.propTypes = {
  /**
   * Array of a user accounts ids
   */
  accountIds: PropTypes.array,
  currentAccountId: PropTypes.string,
  setAccountMigrationEligibility: PropTypes.func.isRequired,
  versions: PropTypes.array,
};

AccountSwitcherBar.defaultProps = {
  accountIds: [],
  currentAccountId: null,
  versions: [],
};

export const testExports = {
  ACCENT_HEIGHT,
  AccentLine,
  Account,
  ACCOUNT_LOGO_SIZE,
  ACCOUNT_LOGO_SPACING,
  StyledAccountLink,
  Wrapper,
};
