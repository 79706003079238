import React from 'react';
import styled from 'styled-components';
import track from 'analytics';
import Flex from 'styled-flex-component';
import { Tooltip } from '@frameio/vapor';
import { buttonReset } from '@frameio/components/src/mixins';
import Button from '@frameio/components/src/styled-components/Button';
import InfoIcon from '@frameio/components/src/svgs/icons/24/support-filled.svg';
import { downloadUrlsAsFiles } from 'utils/downloads';
import DesktopAppModalBase from '../sharedComponents';
import AppleLogo from '../images/apple-logo.svg';
import WindowsLogo from '../images/windows-logo.svg';

const StyledTextButton = styled.button`
  ${buttonReset()}
  color: ${(p) => p.theme.color.brand};
  font-weight: bold;
`;

const StyledButton = styled(Button)`
  width: 214px;
  justify-content: left;
  margin-bottom: ${(p) => p.theme.spacing.tiny};

  svg {
    margin-right: ${(p) => p.theme.spacing.tiny};
  }
`;

const StyledWindowsButton = styled(StyledButton)`
  color: ${(p) => p.theme.color.brand};
`;

const BodyTextContainer = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.tiny};
`;

const SecondaryTextContainer = styled(Flex).attrs({
  center: true,
  alignCenter: true,
})`
  margin-top: ${(p) => p.theme.spacing.tiny};
`;

const IconContainer = styled(Flex)`
  color: ${(p) => p.theme.color.slateGray};
`;

const tooltipCopy =
  'Browser downloads files only and does not preserve folder structure';

function downloadApp(isMac) {
  // Download the latest version of the Frame.io Transfer app from Cloudfront
  const baseUrl = `https://transferapp.frame.io/Frame.io-Transfer/6ab0c27d61d7cf8793b0357e6533ed81/latest`;
  const url = isMac
    ? `${baseUrl}/darwin/x64/Frame.io+Transfer.dmg`
    : `${baseUrl}/win32/x64/Frame.io+Transfer.exe`;
  downloadUrlsAsFiles([url]);
}

export default function LaunchDesktopApp({
  onDownloadInBrowser,
  onClose,
  openURLInDesktopApp,
  url,
}) {
  return (
    <DesktopAppModalBase
      title="Launching Frame.io Transfer..."
      onClose={() => {
        onClose();
        track('launching-transfer-modal-closed');
      }}
    >
      <BodyTextContainer>
        If the browser dialog doesn't prompt you,&nbsp;
        <StyledTextButton
          onClick={(evt) => {
            evt.preventDefault();
            openURLInDesktopApp(url);
            track('button-clicked-try-launching-transfer-again');
          }}
        >
          try again
        </StyledTextButton>
        &nbsp;or&nbsp;
      </BodyTextContainer>
      <StyledButton
        primary
        onClick={() => {
          downloadApp(true);
          track('button-clicked-download-transfer-app');
        }}
      >
        <AppleLogo />
        Download macOS app
      </StyledButton>
      <StyledWindowsButton
        onClick={() => {
          downloadApp(false);
          track('button-clicked-download-transfer-app');
        }}
      >
        <WindowsLogo />
        Download Windows app
      </StyledWindowsButton>
      <SecondaryTextContainer>
        or download files with
        <StyledTextButton
          onClick={(evt) => {
            evt.preventDefault();
            onDownloadInBrowser();
            track('button-clicked-download-with-browser');
          }}
        >
          &nbsp;browser
        </StyledTextButton>
        <Tooltip title={tooltipCopy} placement="bottom" variant="dark">
          <IconContainer>
            <InfoIcon />
          </IconContainer>
        </Tooltip>
      </SecondaryTextContainer>
    </DesktopAppModalBase>
  );
}

export const testExports = {
  StyledButton,
  StyledWindowsButton,
  StyledTextButton,
};
