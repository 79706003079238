import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const EDIT_INVOICE_SETTINGS = generateActionTypes(
  'EDIT_INVOICE_SETTINGS',
  ['UPDATE']
);

export const updateInvoiceSettings = createAction(
  EDIT_INVOICE_SETTINGS.UPDATE,
  (accountId, values) => ({ accountId, values })
);
