import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ROLES from '@frameio/core/src/roles/helpers/constants';
import {
  currentAccountSelector,
  hasAccountWithTeamRoleAtMinimumSelector,
} from 'selectors/accounts';
import { highestRoleForProjectIdSelector } from 'selectors/roles';
import AccountCTA from './AccountCTA';
import { CALL_TO_ACTION_TYPE } from './constants';

const mapStateToProps = (
  state,
  {
    match: {
      params: { projectId },
    },
  }
) => {
  const { id: accountId } = currentAccountSelector(state);
  const role = highestRoleForProjectIdSelector(state, { projectId });
  const isTeamMemberOrCollaborator =
    role === ROLES.COLLABORATOR || role === ROLES.TEAM_MEMBER;

  // External collaborators that get invited, go through the sign up flow and
  // are not associated to any teams yet.

  // CORE-2120: This is a workaround for determining if a user has at least one account with
  // team(s) that the user belongs to. Prior to the project list refactor, all of a user's
  // accounts and teams were fetched, allowing us to derive a user's team membership across
  // all of their accounts. The `hasAccountWithTeamRoleAtMinimumSelector` derives this in a
  // similar way using the user's Account Switcher `accountIds` (which are already pre-filtered
  // to those accounts which contain at least one team), and determining that at least one of
  // those accounts has a TEAM_MEMBER role, at minimum.
  const hasTeams = hasAccountWithTeamRoleAtMinimumSelector(state);
  const isReviewer = role === ROLES.REVIEWER;

  if (role && isReviewer) {
    return { cta: null };
  }

  if (role && !isTeamMemberOrCollaborator && hasTeams) {
    return { cta: CALL_TO_ACTION_TYPE.planUpgrade };
  }

  if (accountId && !hasTeams) {
    return { cta: CALL_TO_ACTION_TYPE.setupAccount };
  }

  return { cta: null };
};

export default withRouter(connect(mapStateToProps)(AccountCTA));

export const testExports = { mapStateToProps };
