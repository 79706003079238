/* eslint-disable import/prefer-default-export */
export function formatShortName(user) {
  if (!user || !user.name) {
    return '';
  }
  const nameParts = user.name.split(' ');
  const firstName = nameParts[0] || '';
  let lastName = nameParts[Math.max(1, nameParts.length - 1)] || '';
  if (firstName) {
    lastName = `${lastName}`.slice(0, 1);
  }
  return `${firstName} ${lastName}`.trim();
}

/**
 * Format an possesive relationship based on the owner's name.
 * Specifically for English, it adds the apostrophe after names
 * with word-ending 's'.
 * @param {string} owner - The name ofthe owner.
 * @param {string} item - The name of the item being owned.
 * @param {string} fallback - If owner is falsy, will return this value.
 */
export function formatPossessive(owner, item, fallback) {
  return owner
    ? `${owner}’${owner.slice(-1) === 's' ? '' : 's'} ${item}`
    : fallback;
}
