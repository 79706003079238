import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import styled, { withTheme } from 'styled-components';
import Flex from 'styled-flex-component';
import { once } from 'lodash';
import track, { trackButtonClick } from 'analytics';
import Button from '@frameio/components/src/styled-components/Button';
import TextInput from '@frameio/components/src/styled-components/TextInput';
import ProgressDots from '../ProgressDots';
import { SETUP_ACCOUNT_FLOW } from '../SetupAccountFlow';

// analytics page name passed to segment
export const NAME_YOUR_TEAM_STEP = 'name-your-team-step';

const Container = styled(Flex)`
  height: ${(p) => p.theme.spacing.units(50)};
  width: ${(p) => p.theme.spacing.units(50)};
  padding: ${(p) => p.theme.spacing.medium};
`;

export const StyledTextInput = styled(TextInput)`
  margin-bottom: ${(p) => p.theme.spacing.tiny};
  &:focus {
    border-color: ${(p) => p.theme.color.brand};
  }
`;

const Header = styled.span`
  display: flex;
  font-weight: bold;
  font-size: ${(p) => p.theme.fontSize[3]};
  color: ${(p) => p.theme.color.coolBlack};
  margin-bottom: ${(p) => p.theme.spacing.units(2)};
`;

const Subheader = styled.p`
  font-size: ${(p) => p.theme.fontSize[2]};
  color: ${(p) => p.theme.color.gray};
  margin-bottom: ${(p) => p.theme.spacing.medium};
`;

const FormContainer = styled(Flex)`
  flex-grow: 1;

  form {
    width: 100%;
  }
`;

export class InnerForm extends React.Component {
  componentDidUpdate() {
    const { touched, errors } = this.props;
    if (touched && !!errors) {
      this.trackErrorEvent();
    }
  }

  trackErrorEvent = once(() => {
    track('form-field-errored', {
      page: NAME_YOUR_TEAM_STEP,
      field: 'team name',
    });
  });

  trackStartEvent = once(() => {
    track('form-field-started', {
      page: NAME_YOUR_TEAM_STEP,
      field: 'team name',
    });
  });

  render() {
    const {
      currentStep,
      errors,
      handleSubmit,
      handleChange,
      handleBlur,
      touched,
      values,
    } = this.props;

    return (
      <Container column>
        <Header>Name your team</Header>
        <Subheader>
          Every great team has a name. What do you call yours?
        </Subheader>
        <FormContainer>
          <form onSubmit={handleSubmit}>
            <StyledTextInput
              placeholder="Enter your team name"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={this.trackStartEvent}
              name="teamName"
              error={touched.teamName && errors.teamName}
              shouldErrorPreSubmit
              autoFocus
              // In Chrome, if the user auto-completes the email address in the first email field
              // it adds that email address in all other email fields in the modal.
              // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#Disabling_autocompletion
              autoComplete="nope"
            />
          </form>
        </FormContainer>
        <Flex justifyBetween>
          <ProgressDots currentStep={currentStep} />
          <Button
            primary
            type="submit"
            onClick={handleSubmit}
            disabled={!!errors.teamName || !values.teamName}
          >
            Next
          </Button>
        </Flex>
      </Container>
    );
  }
}

export const formikOptions = {
  mapPropsToValues: ({ teamName }) => ({ teamName }),
  handleSubmit: (values, { props }) => {
    trackButtonClick('next', SETUP_ACCOUNT_FLOW, NAME_YOUR_TEAM_STEP);
    props.changeTeamAndAccountName(values.teamName);
  },
  validateOnChange: true,
  validationSchema: Yup.object().shape({
    teamName: Yup.string().required('Team name should not be blank'),
  }),
};

InnerForm.propTypes = {
  // Component Props
  currentStep: PropTypes.string.isRequired,

  // Props from Formik
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.shape({
    teamName: PropTypes.string,
  }).isRequired,
};

export default withTheme(withFormik(formikOptions)(InnerForm));
