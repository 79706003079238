import { generateActionTypes } from 'shared/actions/helpers';

export const SPLIT_PLAYER_CONTAINER = generateActionTypes(
  'SPLIT_PLAYER_CONTAINER',
  ['COMPARE_ASSETS_OR_VERSION', 'COMPARE_VERSIONS', 'IS_FETCHING']
);

export const fetchSplitPlayerContainerAssets = (params) => {
  const { assetId, options } = params;
  return {
    type: SPLIT_PLAYER_CONTAINER.IS_FETCHING,
    payload: { assetId, options },
  };
};

export const compareVersions = ({ versionStackId, tracking }) => ({
  type: SPLIT_PLAYER_CONTAINER.COMPARE_VERSIONS,
  payload: { versionStackId, tracking },
});

export const compareAssetsOrVersion = ({ assetIds, source }) => ({
  type: SPLIT_PLAYER_CONTAINER.COMPARE_ASSETS_OR_VERSION,
  payload: { assetIds, source },
});
