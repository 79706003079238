import styled from 'styled-components';
import Flex from 'styled-flex-component';
import React from 'react';
import { rgba } from 'polished';
import moment from 'moment';
import { ExpandTransition } from 'react-transition-components';
import PropTypes from 'prop-types';
import ColorPicker from '@frameio/components/src/styled-components/ColorPicker';
import DatePicker from '@frameio/components/src/styled-components/DatePicker';
import Switch from '@frameio/components/src/styled-components/Switch';
import TextInput from '@frameio/components/src/styled-components/TextInput';
import WarningIcon from '@frameio/components/src/svgs/icons/warning.svg';
import ErrorIcon from '@frameio/components/src/svgs/raw/ic-warning-14px.svg';
import { BLOG_LAYOUT, REEL_LAYOUT } from 'components/Presentation/layouts';
import { presentationColorsWithFallback } from 'components/Presentation/utils';
import { debounce, camelCase, noop } from 'lodash';
import PremiumBadge from '@frameio/components/src/styled-components/PremiumBadge';
import { Tooltip } from '@frameio/vapor';
import { allFeatures } from 'planFeatures';
import track from 'analytics';
import Label from './Label';
import Row from './Row';
import Subheader from './Subheader';
import { TRANSITION_DURATION, TRANSITION_TIMING_SETTING } from './transition';
import LayoutPicker from './LayoutPicker';

const EXPAND_TIMEOUT = 150;

// TODO: Fix `componentWillUpdate` warning triggered by `ExpandTransition`.
//
// This gets reported as "Please update the following components: e", so now you
// know where `e` is coming from.

// colors come from prod color palette on presentation editor
const COLOR_PALETTE = [
  '#c9706e',
  '#cfa673',
  '#d1d679',
  '#86d482',
  '#7fbfd3',
  '#7087d0',
  '#836cd0',
  '#b56fd0',
  '#ffffff',
  '#000000',
];

export const StyledColorPicker = styled(ColorPicker)`
  height: 40px;
  align-items: center;
  margin: ${(p) => p.theme.spacing.small} 0;
`;

export const Panel = styled.div`
  position: absolute;
  background-color: ${(p) => p.theme.color.white};
  padding: 10px 30px;
  min-height: 100%;
  width: 100%;
`;

export const SubheaderContainer = styled.header`
  align-items: center;
  background-color: ${(p) => p.theme.color.white};
  display: flex;
  justify-content: space-between;

  ${Row} {
    margin-bottom: ${(p) => p.theme.spacing.small};
  }

  ${Subheader} {
    margin: ${({ theme }) => `${theme.spacing.medium} 0 0 0`};
  }
`;

export const RowDivider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => rgba(theme.color.silver, 0.5)};
  width: 100%;
`;

export const ColorPickerGroup = styled.div`
  border-bottom: 1px solid ${({ theme }) => rgba(theme.color.silver, 0.5)};
  margin-top: ${(p) => p.theme.spacing.small};
`;

export const InputContainer = styled.div`
  padding-top: 5px;
  margin-bottom: ${(p) => p.theme.spacing.small};
`;

// will converted to styled() syntax on upgrade of package
// otherwise breaks tests extending TextInput
export const PasswordInput = styled(TextInput)`
  color: ${(p) => p.theme.color.coolBlack};
  transition: outline ${TRANSITION_DURATION} ${TRANSITION_TIMING_SETTING};
`;

export const AutoplayRowWrapper = styled.div`
  margin-bottom: 20px;
`;

export const StyledWarningIcon = styled(WarningIcon).attrs(({ theme }) => ({
  width: 16,
  height: 16,
  color: theme.color.alert,
}))`
  // necessary for the icon to render at the right size
  min-width: 16px;
  min-height: 16px;
  margin-right: ${(p) => p.theme.spacing.tiny};
`;

export const ArchivedProjectDisclaimerContainer = styled(Flex)`
  border: ${({ theme }) => `1px solid ${theme.color.silver}`};
  border-radius: ${(p) => p.theme.radius.large};
  padding: ${(p) => p.theme.spacing.tiny};
`;

export const ArchivedProjectDisclaimer = styled.p`
  font-size: ${(p) => p.theme.fontSize[2]};
  color: ${(p) => p.theme.color.coolBlack};
  line-height: 1.5;
`;

const DateInput = styled(TextInput).attrs(({ isInvalid, theme }) => ({
  // eslint-disable-next-line react/prop-types
  icon: isInvalid ? <StyledErrorIcon /> : null,
  style: isInvalid ? { color: theme.color.error } : null,
}))`
  height: 40px;
  transition: 0.3s outline ease-in-out;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  path {
    fill: ${(p) => p.theme.color.error};
  }
`;

const AppearanceSubheader = styled(Flex).attrs({
  alignCenter: true,
  justifyBetween: true,
})`
  cursor: ${(p) =>
    p.canUseCustomBrandedPresentations ? 'default' : 'pointer'};
`;

const FeatureLockOverlay = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${rgba('#fff', 0.8)};
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const CustomBrandedPresentationContainer = styled.div`
  position: relative;
`;

const StyledPremiumBadge = styled(PremiumBadge)`
  cursor: pointer;
`;

/**
 * PresentationSidePanel Component for presentation page.
 * @param {Object} props - Props passed down from parent.
 * @returns {ReactComponent} - React component.
 */
class PresentationSidePanel extends React.Component {
  constructor(props) {
    super(props);
    const { presentation, team } = props;
    const {
      expires_at: initialExpiresAt,
      password: initialPassword,
      layout,
    } = presentation;

    const {
      accent,
      background: backgroundColor,
      text: textColor,
    } = presentationColorsWithFallback(presentation, team);

    // TODO(marvin): maybe do `getDerivedStateFromProps`? this state assigment
    // in the constructor is why some test cases have to remount.
    this.state = {
      isPasswordProtectOn: !!initialPassword,
      isLinkExpirationOn: !!initialExpiresAt,
      passphrase: initialPassword || '',
      // we rename `accent` to match the server's terminology and allow for a
      // camelCase implementation in handleColorChange
      color: accent,
      textColor,
      backgroundColor,
      layout,
    };

    this.debouncedOnSettingsChange = debounce(this.props.onSettingsChange, 300);
  }

  onLayoutChange = (layout) => {
    this.setState({ layout });
    this.props.onSettingsChange({ layout });
  };

  togglePassword = (e) => {
    const { checked } = e.target;
    const { onSettingsChange } = this.props;
    // clears password input when toggled off
    if (!checked) {
      onSettingsChange({ password: null });
      this.setState({ isPasswordProtectOn: false });
    } else {
      this.setState({ isPasswordProtectOn: true });
    }
  };

  toggleLinkExpiration = (e) => {
    const { checked } = e.target;
    const { onSettingsChange } = this.props;
    // clears password input when toggled off
    if (!checked) {
      onSettingsChange({ expires_at: null });
      this.setState({ isLinkExpirationOn: false });
    } else {
      this.setState({ isLinkExpirationOn: true });
    }
  };

  handlePasswordChange = (e) => {
    /* we need to call a debounced version of the function so that a user's
       input does not trigger a server call for each keystroke.
    */
    this.debouncedOnSettingsChange({ password: e.target.value });
    this.setState({ passphrase: e.target.value });
  };

  handleColorChange = (colorSetting) => (value) => {
    const { canUseCustomBrandedPresentations } = this.props;
    if (!canUseCustomBrandedPresentations) return;
    /* sets the color change in local state and maybe makes a server call.
       here, we check not for "is this value a valid hex color?" but rather
       for the length of the input. Thus values of 3 and 6 for valid hex values

      If the user inputs an invalid hex color we want this to propagate up to
      the server so that we see an error message.

      We also need to check for the empty string '' so that users who wipe the
      input field have the color change sent to the server
    */

    this.setState({
      [camelCase(colorSetting)]: value,
    });

    if (value.length === 6 || value.length === 3 || value === '') {
      this.props.onSettingsChange({
        [colorSetting]: value,
      });
    }
  };

  maybeStartUpgradePlanFlow = (feature, source) => {
    const { isAdmin, upgradePlan, presentationId } = this.props;
    if (!isAdmin) return;
    track('feature-gate-clicked-client', {
      _limit: feature,
    });
    upgradePlan(presentationId, feature, source);
  };

  renderUpgradeBadge = (feature, source) => {
    const { isAdmin } = this.props;
    const isShareLinkExpiration = feature === allFeatures.shareLinkExpiration;
    const plan = isShareLinkExpiration ? 'Team' : 'Pro';
    return (
      <Tooltip
        details={isAdmin && 'Click to learn more.'}
        offset={[0, 10]}
        placement="top"
        title={`Available on ${plan} ✨`}
        variant="dark"
      >
        <StyledPremiumBadge
          onClick={
            isAdmin && feature
              ? () => this.maybeStartUpgradePlanFlow(feature, source)
              : noop
          }
        >
          Upgrade
        </StyledPremiumBadge>
      </Tooltip>
    );
  };

  render() {
    const {
      isLinkExpirationOn,
      isPasswordProtectOn,
      passphrase,
      color,
      backgroundColor,
      textColor,
      layout,
    } = this.state;
    const {
      canUseCustomBrandedPresentations,
      canUsePasswordProtectedShares,
      canUseShareLinkExpiration,
      canUseReelPlayer,
      canEnableDownloads,
      isBlockingReelPlayer,
      isOnLegacyPlan,
      onSettingsChange,
      presentation,
      team,
      isProjectArchived,
      isAdmin,
    } = this.props;
    const {
      can_download: canDownload,
      include_ext: includeExt,
      include_upload_date: includeUploadDate,
      expires_at: expiresAt,
      autoplay,
    } = presentation;
    const {
      default_color: defaultAccentColor,
      default_background_color: defaultBackgroundColor,
      default_font_color: defaultTextColor,
    } = team;

    // Force select the reel layout option when the reel player feature block is
    // in effect.
    const selectedLayout = isBlockingReelPlayer ? REEL_LAYOUT : layout;
    const isReel = selectedLayout === REEL_LAYOUT;
    const isBlog = selectedLayout === BLOG_LAYOUT;

    return (
      <Panel>
        {(canUseReelPlayer || !isOnLegacyPlan) && (
          <React.Fragment>
            <Subheader>Layout Settings</Subheader>
            <LayoutPicker
              selectedLayout={selectedLayout}
              showPremiumBadge={!canUseReelPlayer}
              onChange={this.onLayoutChange}
            />
          </React.Fragment>
        )}
        <ExpandTransition
          in={isReel}
          direction="top"
          timeout={EXPAND_TIMEOUT}
          data-test-id="autoplay-switch-expand"
        >
          <AutoplayRowWrapper>
            <Row>
              <Label htmlFor="autoplay">Default Autoplay</Label>
              <Switch
                isOn={autoplay}
                onChange={(e) =>
                  onSettingsChange({ autoplay: e.target.checked })
                }
                id="autoplay"
                data-test-id="autoplay-switch"
              />
            </Row>
            <RowDivider />
          </AutoplayRowWrapper>
        </ExpandTransition>
        {isProjectArchived && (
          <ArchivedProjectDisclaimerContainer>
            <StyledWarningIcon />
            <ArchivedProjectDisclaimer>
              Only low resolution previews are available in archived projects.
            </ArchivedProjectDisclaimer>
          </ArchivedProjectDisclaimerContainer>
        )}
        <SubheaderContainer>
          <Subheader>Sharing Settings</Subheader>
        </SubheaderContainer>
        <Row>
          <Label data-test-id="password-protect" htmlFor="password-protect">
            Passphrase Protect
          </Label>
          {canUsePasswordProtectedShares ? (
            <Switch
              id="password-protect"
              onChange={this.togglePassword}
              isOn={isPasswordProtectOn}
            />
          ) : (
            this.renderUpgradeBadge(
              allFeatures.passwordProtectedShares,
              'pw-protected shares feature gate'
            )
          )}
        </Row>
        <ExpandTransition
          in={isPasswordProtectOn}
          direction="top"
          timeout={EXPAND_TIMEOUT}
        >
          <InputContainer>
            <PasswordInput
              data-test-id="password-input"
              placeholder="Create passphrase"
              autoFocus
              onChange={this.handlePasswordChange}
              value={passphrase}
            />
          </InputContainer>
        </ExpandTransition>
        <RowDivider />
        {canEnableDownloads && (
          <React.Fragment>
            <Row>
              <Label htmlFor="file-downloads">Enable Downloads</Label>
              <Switch
                id="file-downloads"
                data-test-id="file-downloads"
                onChange={(e) =>
                  onSettingsChange({ can_download: e.target.checked })
                }
                isOn={canDownload}
              />
            </Row>
            <RowDivider />
          </React.Fragment>
        )}
        <Row>
          <Label htmlFor="link-expiration">Link Expiration</Label>
          {canUseShareLinkExpiration ? (
            <Switch
              id="link-expiration"
              onChange={this.toggleLinkExpiration}
              isOn={isLinkExpirationOn}
              data-test-id="link-expiration"
            />
          ) : (
            this.renderUpgradeBadge(
              allFeatures.shareLinkExpiration,
              'share link expiration feature gate'
            )
          )}
        </Row>
        <ExpandTransition
          in={isLinkExpirationOn}
          direction="top"
          timeout={EXPAND_TIMEOUT}
        >
          <InputContainer>
            <DatePicker
              autoOpenDatePicker
              autoOpenTimeout={150}
              shouldCloseOnChange={false}
              onChange={(value) => onSettingsChange({ expires_at: value })}
              value={expiresAt || ''}
              minDate="today"
            >
              {({
                // Filter out these props to prevent SC warnings
                onRangeEndClick,
                onRangeStartClick,

                ...inputProps
              }) => (
                <DateInput
                  isInvalid={
                    expiresAt && moment(expiresAt).isBefore(new Date())
                  }
                  placeholder="Set expiration date"
                  autoFocus
                  readOnly
                  {...inputProps}
                />
              )}
            </DatePicker>
          </InputContainer>
        </ExpandTransition>
        <RowDivider />
        <AppearanceSubheader
          canUseCustomBrandedPresentations={canUseCustomBrandedPresentations}
          onClick={
            canUseCustomBrandedPresentations ||
            (!canUseCustomBrandedPresentations && !isAdmin)
              ? noop
              : () =>
                  this.maybeStartUpgradePlanFlow(
                    allFeatures.customBrandedPresentations
                  )
          }
        >
          <Subheader>Appearance</Subheader>
          {!canUseCustomBrandedPresentations &&
            this.renderUpgradeBadge(
              allFeatures.customBrandedPresentations,
              'custom-branded presentations feature gate'
            )}
        </AppearanceSubheader>
        <CustomBrandedPresentationContainer>
          {!canUseCustomBrandedPresentations && <FeatureLockOverlay />}
          <ColorPickerGroup>
            <Label>Accent Color</Label>
            <StyledColorPicker
              color={color}
              placeholderColor={defaultAccentColor}
              onChange={this.handleColorChange('color')}
              presetColors={COLOR_PALETTE}
            />
          </ColorPickerGroup>
          <ColorPickerGroup>
            <Label>Background Color</Label>
            <StyledColorPicker
              data-test-id="background-color"
              color={backgroundColor}
              placeholderColor={defaultBackgroundColor}
              onChange={this.handleColorChange('background_color')}
              presetColors={COLOR_PALETTE}
            />
          </ColorPickerGroup>
          <ColorPickerGroup>
            <Label>Text Color</Label>
            <StyledColorPicker
              color={textColor}
              placeholderColor={defaultTextColor}
              onChange={this.handleColorChange('text_color')}
              presetColors={COLOR_PALETTE}
            />
          </ColorPickerGroup>
        </CustomBrandedPresentationContainer>
        <ExpandTransition
          in={isBlog}
          direction="top"
          timeout={EXPAND_TIMEOUT}
          data-test-id="show-upload-date-transition"
        >
          <Row>
            <Label htmlFor="show-upload-date">Show Upload Date</Label>
            <Switch
              id="show-upload-date"
              onChange={(e) =>
                onSettingsChange({ include_upload_date: e.target.checked })
              }
              isOn={includeUploadDate}
            />
          </Row>
          <RowDivider />
        </ExpandTransition>
        <Row>
          <Label htmlFor="show-file-extension">Show File Extension</Label>
          <Switch
            data-test-id="show-file-extension"
            id="show-file-extension"
            onChange={(e) =>
              onSettingsChange({ include_ext: e.target.checked })
            }
            isOn={includeExt}
          />
        </Row>
        <RowDivider />
      </Panel>
    );
  }
}

PresentationSidePanel.propTypes = {
  canUseReelPlayer: PropTypes.bool.isRequired,
  canEnableDownloads: PropTypes.bool.isRequired,
  canUseCustomBrandedPresentations: PropTypes.bool.isRequired,
  canUsePasswordProtectedShares: PropTypes.bool.isRequired,
  canUseShareLinkExpiration: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isBlockingReelPlayer: PropTypes.bool.isRequired,
  isOnLegacyPlan: PropTypes.bool.isRequired,
  onSettingsChange: PropTypes.func.isRequired,
  presentation: PropTypes.object.isRequired,
  team: PropTypes.shape({
    background_color: PropTypes.string,
    color: PropTypes.string,
    font_color: PropTypes.string,
    default_color: PropTypes.string,
    default_background_color: PropTypes.string,
    default_font_color: PropTypes.string,
  }).isRequired,
  isProjectArchived: PropTypes.bool.isRequired,
};

export default PresentationSidePanel;

export const testExports = {
  AutoplayRowWrapper,
  PasswordInput,
  ArchivedProjectDisclaimerContainer,
  DateInput,
};
