import { connect } from 'react-redux';
import { incompleteUploadsByParentAssetSelector } from '@frameio/core/src/uploads/selectors';
import { assetOrPlaceholderAssetSelector } from 'pages/ProjectContainer/selectors';
import { isSharedFolderSelector } from 'selectors/folders';
import { isProjectArchivedSelector } from 'selectors/projects';

import { folderSharingEnabled } from 'utils/featureFlags';
import FolderCard from './FolderCard';

function mapStateToProps(state, { assetId }) {
  const isFolderSharingEnabled = folderSharingEnabled(state);
  const uploadingAssets = incompleteUploadsByParentAssetSelector(state, {
    assetId,
  });
  const assetOrPlaceholder = assetOrPlaceholderAssetSelector(state, {
    assetId,
  });
  const isProjectArchived = assetOrPlaceholder
    ? isProjectArchivedSelector(state, {
        projectId: assetOrPlaceholder.project,
      })
    : false;

  return {
    numUploadingAssets: uploadingAssets.length,
    asset: assetOrPlaceholder,
    isSharedFolder: isSharedFolderSelector(state, { folderId: assetId }),
    isFolderSharingEnabled,
    isProjectArchived,
  };
}

export default connect(mapStateToProps)(FolderCard);
export const testExports = { mapStateToProps };
