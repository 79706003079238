import { connect } from 'react-redux';
import { getRoleForTeam } from 'actions/roles';
import { closeModal } from 'components/Modal/actions';
import { setTeamId } from 'components/ProjectForms/CreateProject/actions';
import { currentUserEntitySelector } from 'selectors/users';
import { teamsByCurrentUserMembershipSelector } from 'selectors/teams';
import {
  canUserToggleLifecyclePolicy,
  permittedActionsForAccountSelector,
} from 'selectors/permissions';
import { newProjectInviteLinksEnabled } from 'utils/featureFlags';
import { createProject } from '../actions';
import CreateProjectForm from './CreateProject';
import {
  createProjectFormSelector,
  selectedTeamSelector,
  selectedAccountSelector,
  selectedRoleSelector,
} from './selectors';

function mapStateToProps(state) {
  const selectedAccount = selectedAccountSelector(state);

  const {
    canCreateProject,
    canToggleInviteCollaborators,
    canToggleSharePresentations,
    canUsePrivateProjects,
    c2cConnectionsFeatureEnabled,
  } = permittedActionsForAccountSelector(state, {
    accountId: selectedAccount.id,
  });
  const currentUser = currentUserEntitySelector(state);
  const projectForm = createProjectFormSelector(state);
  const role = selectedRoleSelector(state);
  const selectedTeam = selectedTeamSelector(state);
  const teams = teamsByCurrentUserMembershipSelector(state);

  return {
    canCreateProject,
    canToggleAutomaticallyDeletingAssets: canUserToggleLifecyclePolicy(state, {
      accountId: selectedAccount.id,
      teamId: selectedTeam.id,
    }),
    canToggleInviteCollaborators,
    canToggleSharePresentations,
    canUseC2CConnections: c2cConnectionsFeatureEnabled,
    canUsePrivateProjects,
    currentUser,
    isFetching: projectForm.isFetching,
    role,
    selectedAccount,
    selectedTeam,
    teams,
    newProjectInviteLinksEnabled: newProjectInviteLinksEnabled(state),
  };
}

export default connect(
  mapStateToProps,
  {
    createProject,
    closeModal,
    setTeamId,
    getRoleForTeam,
  }
)(CreateProjectForm);
