import React from 'react';
import { put, takeLatest } from 'redux-saga/effects';
import { openModal } from 'components/Modal/actions';
import { setProjectId } from 'components/ProjectForms/EditProject/actions';
import UnarchiveProjectFlow from 'components/UnarchiveProjectFlow';
import { UNARCHIVE_PROJECT } from './actions';

function* startUnarchiveProject(projectId) {
  yield put(setProjectId(projectId));

  // todo(gabrielmessager): use Yuval's work to not use canCloseModal
  yield put(
    openModal(<UnarchiveProjectFlow projectId={projectId} />, {
      canCloseModal: false,
    })
  );
}

export default [
  takeLatest(UNARCHIVE_PROJECT.START, ({ payload: { projectId } }) =>
    startUnarchiveProject(projectId)
  ),
];
