import React, { useCallback } from 'react';
import { connect } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { useDispatch } from 'react-redux';
import { rgba } from 'polished';
import { allFeatures } from 'planFeatures';
import { Tooltip } from '@frameio/vapor';
import { PopoverMenuSeparator } from '@frameio/components/src/styled-components/PopoverMenu';
import ColorPicker from '@frameio/components/src/styled-components/ColorPicker';
import PremiumBadge from '@frameio/components/src/styled-components/PremiumBadge';
import { BLOG_LAYOUT, REEL_LAYOUT } from 'components/Presentation/layouts';
import { upgradePlan } from 'components/PresentationEditor/actions';
import LayoutPicker from '../PresentationSidePanel/LayoutPicker';
import Label from '../PresentationSidePanel/Label';
import SharePanelSettingsSwitchRow from '../../SharePanel/SharePanelSettingsSwitchRow';

// colors come from prod color palette on presentation editor
const COLOR_PALETTE = [
  '#c9706e',
  '#cfa673',
  '#d1d679',
  '#86d482',
  '#7fbfd3',
  '#7087d0',
  '#836cd0',
  '#b56fd0',
  '#ffffff',
  '#000000',
];

const LayoutPickerContainer = styled.div`
  width: 200px;
  margin: ${(p) => p.theme.spacing.small} auto 0 auto;
`;

const StyledColorPicker = styled(ColorPicker)`
  text-transform: uppercase;
  height: 40px;
  align-items: center;
  margin: ${(p) => p.theme.spacing.small} 0;
`;

const ColorPickerGroup = styled(Flex).attrs({
  center: true,
})`
  justify-content: space-between;
`;

const ListItemSeparator = styled(PopoverMenuSeparator)`
  border-color: ${(p) => p.theme.color.coldWhite};
  margin: ${(p) => p.theme.spacing.micro} 0;
`;

const Container = styled.div`
  position: relative;
`;

const FeatureLockOverlay = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${rgba('#fff', 0.8)};
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const StyledFlex = styled(Flex)`
  ${(p) => p.theme.fontStyle.body};
  color: ${(p) => p.theme.color.coolBlack};
  padding-top: ${(p) => p.theme.spacing.units(2)};
  font-weight: bold;
`;

const StyledFlexItem = styled(FlexItem)`
  margin-left: auto;
`;

const StyledPremiumBadge = styled(PremiumBadge)`
  cursor: ${(p) => (p.enabled ? 'pointer' : 'default')};
`;

const SecureSidePanelLayoutForm = ({
  canUseCustomBrandedPresentations,
  canUseReelPlayer,
  formik: {
    setFieldValue,
    submitForm,
    values: {
      autoplay,
      backgroundColor,
      color,
      id,
      layout,
      showFileExtension,
      showUploadDate,
      textColor,
    },
  },
  isAdmin,
}) => {
  const dispatch = useDispatch();

  const handleChangeAndSubmit = (field, value) => {
    setFieldValue(field, value);
    setTimeout(() => submitForm());
  };

  const updateSwitchValueAndSubmit = (evt) => {
    setFieldValue(evt.target.name, evt.target.checked);
    setTimeout(() => submitForm());
  };

  const showFeatureBlock = !canUseCustomBrandedPresentations;

  const openUpgradePlanModal = useCallback(
    (feature) => {
      if (!isAdmin) {
        return;
      }
      dispatch(upgradePlan(id, feature, 'secure side panel', 'appearance'));
    },
    [dispatch, id, isAdmin]
  );

  return (
    <React.Fragment>
      <LayoutPickerContainer>
        <LayoutPicker
          isAdmin={isAdmin}
          selectedLayout={layout}
          showPremiumBadge={!canUseReelPlayer}
          hasHeader={false}
          onChange={(newLayout) => {
            if (newLayout === REEL_LAYOUT && !canUseReelPlayer) {
              openUpgradePlanModal(allFeatures.reelPlayer);
              return;
            }

            handleChangeAndSubmit('layout', newLayout);
          }}
        />
      </LayoutPickerContainer>
      {layout === REEL_LAYOUT && (
        <React.Fragment>
          <ListItemSeparator />
          <SharePanelSettingsSwitchRow
            checked={autoplay}
            label="Default autoplay"
            name="autoplay"
            onToggle={updateSwitchValueAndSubmit}
          />
        </React.Fragment>
      )}
      <SharePanelSettingsSwitchRow
        checked={showUploadDate}
        label="Show upload date"
        name="showUploadDate"
        onToggle={updateSwitchValueAndSubmit}
      />

      <SharePanelSettingsSwitchRow
        checked={showFileExtension}
        label="Show file extension"
        name="showFileExtension"
        onToggle={updateSwitchValueAndSubmit}
      />
      <StyledFlex>
        Branding
        {showFeatureBlock && (
          <StyledFlexItem>
            <Tooltip
              title="Available on Pro ✨"
              details={isAdmin && 'Click to learn more'}
              variant="dark"
              shouldUsePortal
            >
              <StyledPremiumBadge
                enabled={showFeatureBlock && isAdmin}
                onClick={() => {
                  openUpgradePlanModal(allFeatures.customBrandedPresentations);
                }}
              >
                Upgrade
              </StyledPremiumBadge>
            </Tooltip>
          </StyledFlexItem>
        )}
      </StyledFlex>
      <Container>
        {showFeatureBlock && <FeatureLockOverlay />}
        <ColorPickerGroup>
          <Label>Accent Color</Label>
          <StyledColorPicker
            color={color}
            placeholderColor={color}
            onChange={(newColor) => handleChangeAndSubmit('color', newColor)}
            presetColors={COLOR_PALETTE}
          />
        </ColorPickerGroup>
        <ListItemSeparator />
        <ColorPickerGroup>
          <Label>Background Color</Label>
          <StyledColorPicker
            data-test-id="background-color"
            color={backgroundColor}
            placeholderColor={backgroundColor}
            onChange={(newColor) =>
              handleChangeAndSubmit('backgroundColor', newColor)
            }
            presetColors={COLOR_PALETTE}
          />
        </ColorPickerGroup>
        <ListItemSeparator />
        <ColorPickerGroup>
          <Label>Text Color</Label>
          <StyledColorPicker
            color={textColor}
            placeholderColor={textColor}
            onChange={(newColor) =>
              handleChangeAndSubmit('textColor', newColor)
            }
            presetColors={COLOR_PALETTE}
          />
        </ColorPickerGroup>
      </Container>
    </React.Fragment>
  );
};

SecureSidePanelLayoutForm.propTypes = {
  formik: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    values: PropTypes.shape({
      autoplay: PropTypes.bool.isRequired,
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      layout: PropTypes.oneOf([BLOG_LAYOUT, REEL_LAYOUT]).isRequired,
      showFileExtension: PropTypes.bool.isRequired,
      showUploadDate: PropTypes.bool.isRequired,
      textColor: PropTypes.string,
    }).isRequired,
  }),
};

export default connect(SecureSidePanelLayoutForm);
