export default {
  ACCOUNT_SETTINGS: 'Account Settings',
  LOG_OUT: 'Log Out',
  PERSONAL_POLICY: 'Do not sell or share my personal information',
  PRIVACY_POLICY: 'Privacy Policy',
  PRODUCT_UPDATES: 'Product Updates',
  STATUS_UPDATES: 'Status Updates',
  SUPPORT_FAQ: 'Support / FAQ',
  TERMS_OF_SERVICE: 'Terms of Service',
};

// Use for segment tracking
export const ACCOUNT_NAVIGATION_VIEW = 'account-navigation-view';
