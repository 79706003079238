import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'styled-flex-component';
import styled from 'styled-components';
import Button from '@frameio/components/src/styled-components/Button';
import PulsingCircle from '@frameio/components/src/styled-components/PulsingCircle';
import Dialog, {
  dialogTypes,
} from '@frameio/components/src/styled-components/Dialog';
import {
  AEM,
  WORKFRONT,
} from 'components/AssetActions/AdobeIntegration/integrationConstants';
import WorkfrontTaskModalContent from './WorkfrontTaskModalContent';
import AemModalContent from './Aem/ConnectedAemModalContent';
import { TEXT } from './helper';
import useTaskSelection from './useTaskSelection';
import DialogModal from '../DialogModal';

const StyledDialogModal = styled(DialogModal)`
  width: ${(p) => p.theme.spacing.units(67)};
  display: flex;
  height: ${(p) => p.theme.spacing.units(75)};
`;

const StyledDialog = styled(Dialog)`
  width: ${(p) => p.theme.spacing.units(67)};
`;

const StyledLoadingContainer = styled(Flex)`
  width: ${(p) => p.theme.spacing.units(67)};
`;

const ModalContent = ({
  aemInterface,
  assetIds,
  integration,
  project,
  workfrontTaskInterface,
}) => {
  switch (integration) {
    case WORKFRONT:
      return (
        <WorkfrontTaskModalContent
          workfrontTaskInterface={workfrontTaskInterface}
        />
      );

    case AEM:
      return (
        <AemModalContent
          aemInterface={aemInterface}
          assetIds={assetIds}
          project={project}
        />
      );

    default:
      return null;
  }
};

const AdobeIntegrationConnectDialog = ({
  assetIds,
  closeModal,
  integration,
  isLoading,
  isOpen,
  project,
  selectAemDestination,
  selectedAemDestination,
  submit,
  workfrontAssignedTasks,
  workfrontTasks,
}) => {
  const [
    state,
    { setAllTasksSelected, setResetModal, setUpdateTaskSelected },
  ] = useTaskSelection();
  const { taskOptions } = state || {};

  const workfrontTaskInterface = {
    setAllTasksSelected,
    setUpdateTaskSelected,
    state,
    workfrontAssignedTasks,
    workfrontTasks,
  };

  const aemInterface = {
    closeModal,
    selectAemDestination,
  };

  const quantity = assetIds?.length;

  const noSelectionMade =
    !taskOptions.taskId && !selectedAemDestination?.repo_id;

  const { headerTitle, primaryButton, secondaryButton } =
    TEXT(integration) || {};

  const resetModalState = () => {
    closeModal();
    if (integration === WORKFRONT) setResetModal();
    if (integration === AEM) selectAemDestination({});
  };

  const onCancel = () => {
    resetModalState();
  };

  const onSubmit = () => {
    if (integration === AEM) {
      const { repo_id: aemRepositoryId, aemPath } =
        selectedAemDestination || {};
      const options = { aemPath, aemRepositoryId };
      submit({ options, assetIds, integration, projectId: project?.id });
    }
    if (integration === WORKFRONT) {
      const options = { taskOptions };
      submit({ options, assetIds, integration, projectId: project?.id });
    }
    return resetModalState();
  };

  if (isLoading) {
    return (
      <StyledDialogModal isOpen={isOpen} onCancel={onCancel} canBackdropCancel>
        <StyledLoadingContainer>
          <PulsingCircle />
        </StyledLoadingContainer>
      </StyledDialogModal>
    );
  }

  if (!integration) return null;

  return (
    <StyledDialogModal isOpen={isOpen} onCancel={onCancel} canBackdropCancel>
      <StyledDialog
        header={headerTitle(quantity)}
        type={dialogTypes.NONE}
        primaryButton={
          <Button primary disabled={noSelectionMade} onClick={onSubmit}>
            {primaryButton}
          </Button>
        }
        secondaryButton={
          integration === WORKFRONT && (
            <Button secondary onClick={onCancel}>
              {secondaryButton}
            </Button>
          )
        }
      >
        <ModalContent
          aemInterface={aemInterface}
          assetIds={assetIds}
          integration={integration}
          project={project}
          workfrontTaskInterface={workfrontTaskInterface}
        />
      </StyledDialog>
    </StyledDialogModal>
  );
};

AdobeIntegrationConnectDialog.propTypes = {
  assetIds: PropTypes.arrayOf(PropTypes.string),
  closeModal: PropTypes.func,
  integration: PropTypes.string,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onSetUserSelectedId: PropTypes.func,
  project: PropTypes.object,
  sendToAem: PropTypes.func,
  submit: PropTypes.func,
  workfrontAssignedTasks: PropTypes.arrayOf(PropTypes.object),
  workfrontTasks: PropTypes.arrayOf(PropTypes.object),
};

export const testExports = {
  AemModalContent,
};

export default AdobeIntegrationConnectDialog;
