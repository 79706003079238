import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { motion } from 'framer-motion';

import { MenuRadio, Select, Tooltip } from '@frameio/vapor';

import { TimeDisplay as TimeDisplayType } from '@frameio/components/src/components/PlayerSubcomponents/PlayerInterface';
import { buttonReset } from '@frameio/components/src/mixins';
import DropdownSVG from '@frameio/components/src/components/PlayerSubcomponents/svg/ic-player-dropdown.svg';

import { trackViewer } from 'analytics';
import useMediaRAF from 'pages/PlayerContainers/hooks/useMediaRAF';
import TimeDisplayText from 'components/PlayerControlBar/TimeDisplay/TimeDisplayText';

const FormatTimeDisplay = {
  [TimeDisplayType.FRAME]: 'Frames',
  [TimeDisplayType.TIMECODE]: 'Timecode',
  [TimeDisplayType.STANDARD]: 'Standard',
};

const TimeDisplayDropdownIcon = styled(DropdownSVG)`
  color: ${(p) => p.theme.color.graphiteGray};
  margin-left: 5px;
  transition: color 150ms ease, transform 125ms ease;
`;

const DisplayBtn = styled(motion.button)`
  ${buttonReset()}

  &:hover,
  &:focus {
    ${TimeDisplayDropdownIcon} {
      color: ${(p) => p.theme.color.white};
    }
  }

  ${TimeDisplayDropdownIcon} {
    ${(p) =>
      p.isFocused &&
      `
      color: ${p.theme.color.white};
      transform: rotate(-180deg);
    `}
  }
`;

const TimeDisplay = (props) => {
  const {
    audioCodec,
    displayType,
    frameRate,
    isHlsPlayerEnabled,
    media,
    mediaEl,
    setIsPopoverShowing,
    setTimeDisplay,
    timecodeIn,
    tooltipTitle,
    totalFrames,
  } = props;
  const [hasTransition, setHasTransition] = useState(true);
  const hasDuration = isFinite(media.duration);

  const { currentTime } = useMediaRAF(media, mediaEl);

  // We only want to transition the first time the component's duration is available.
  useEffect(() => {
    setHasTransition(false);
  }, [hasDuration]);

  return (
    <div>
      <Select
        shouldUsePortal
        placement="top-start"
        tooltip={
          <Tooltip
            className="playbar-tooltip"
            delayIn={500}
            title={tooltipTitle}
            placement="top"
          />
        }
        onRequestChange={(isShowing) => {
          setIsPopoverShowing(isShowing);
        }}
        defaultValue={displayType}
        value={displayType}
        label="Time Format"
        onChange={(timeDisplayValue) => {
          setTimeDisplay(timeDisplayValue);
          trackViewer('time-format-updated', { timeformat: timeDisplayValue });
        }}
        content={Object.values(TimeDisplayType).map((timeType) => (
          <MenuRadio key={timeType} name={timeType}>
            <Flex>{FormatTimeDisplay[timeType]}</Flex>
          </MenuRadio>
        ))}
      >
        {({ isShowing, bind }) =>
          hasDuration && (
            <DisplayBtn
              aria-label="Time display"
              aria-valuetext={`${displayType} format`}
              initial={{
                opacity: hasTransition ? 1 : 0,
              }}
              animate={{ opacity: 1 }}
              exit={{
                opacity: hasTransition ? 1 : 0,
              }}
              isFocused={isShowing}
              {...bind}
            >
              <Flex center>
                <TimeDisplayText
                  audioCodec={audioCodec}
                  currentTime={mediaEl ? currentTime : media.currentTime}
                  duration={media.duration}
                  displayType={displayType}
                  frameRate={frameRate}
                  isHlsPlayerEnabled={isHlsPlayerEnabled}
                  timecodeIn={timecodeIn}
                  totalFrames={totalFrames}
                />
                <TimeDisplayDropdownIcon />
              </Flex>
            </DisplayBtn>
          )
        }
      </Select>
    </div>
  );
};

TimeDisplay.defaultProps = {
  setIsPopoverShowing: () => {},
};

TimeDisplay.propTypes = {
  displayType: PropTypes.string.isRequired,
  frameRate: PropTypes.number.isRequired,
  isHlsPlayerEnabled: PropTypes.bool,
  media: PropTypes.shape({
    currentTime: PropTypes.number,
    duration: PropTypes.number,
  }).isRequired,
  mediaEl: PropTypes.instanceOf(Element),
  setIsPopoverShowing: PropTypes.func.isRequired,
  setTimeDisplay: PropTypes.func.isRequired,
  timecodeIn: PropTypes.string,
  tooltipTitle: PropTypes.string,
  totalFrames: PropTypes.number.isRequired,
};

export default TimeDisplay;
