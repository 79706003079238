import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const REVIEW_LINK_CONTAINER = generateActionTypes(
  'REVIEW_LINK_CONTAINER',
  [
    'AUTH_AND_ENTER',
    'CANCEL_ANON_USER_SIGNUP',
    'CONFIRM_DOWNLOAD_ALL_ASSETS',
    'ENFORCE_AUTHED_USER',
    'FETCH_ASSET_DATA',
    'FETCH_DATA',
    'FETCH_FAILED',
    'FETCH_VERSION_DATA',
    'HANDLE_AUTH_ERROR',
    'LOOKUP_ANON_USER_EMAIL',
    'REMOVE_AUTH_INTERCEPTOR',
    'SET_SELECTED_PAGE_NUMBER',
    'SHOW_NAME_INPUT_CHANGE',
    'TOGGLE_DETAIL_PANE',
    'UPDATE_ANON_USER_NAME',
  ]
);

/**
 * @param {string} reviewLinkId
 * @param {Object} assetParams
 */
export const authAndEnterReviewLink = createAction(
  REVIEW_LINK_CONTAINER.AUTH_AND_ENTER,
  (reviewLinkId, assetParams) => ({ reviewLinkId, assetParams })
);

/**
 * @param {Object} param - data from the auth interceptor
 * @param {Object} param.error - axios error
 * @param {Object} param.request - request that can be invoked after user has been authorized
 */
export const handleAuthError = createAction(
  REVIEW_LINK_CONTAINER.HANDLE_AUTH_ERROR,
  ({ error, request }) => ({ error, request })
);

export const removeAuthInterceptor = createAction(
  REVIEW_LINK_CONTAINER.REMOVE_AUTH_INTERCEPTOR,
  () => ({})
);

/**
 * Checks if user is authorized to take action and if not
 * registers them and then fires action
 * @param {func} authCallback
 * @param {string} modalHeaderText - Description of action being taken.
 */
export function enforceAuthedUser(authCallback, modalHeaderText) {
  return {
    type: REVIEW_LINK_CONTAINER.ENFORCE_AUTHED_USER,
    payload: {
      authCallback,
      modalHeaderText,
    },
  };
}

/**
 * Initiates the fetch of the review link page data
 * @param {string} reviewLinkId
 * @param {string} password
 */
export function fetchReviewLinkData(reviewLinkId, password, assetParams = {}) {
  return {
    type: REVIEW_LINK_CONTAINER.FETCH_DATA,
    payload: {
      reviewLinkId,
      password,
      assetParams,
    },
  };
}

/**
 * Marks the review link fetching as failed for the provided reason.
 * @param {string} reviewLinkId
 * @param {string} failureReason
 */
export function reviewLinkFetchFailed(reviewLinkId, failureReason, hasBundle) {
  return {
    type: REVIEW_LINK_CONTAINER.FETCH_FAILED,
    payload: {
      reviewLinkId,
      failureReason,
      hasBundle,
    },
  };
}

export function fetchAssetData(reviewLinkId, assetId, versionStackAssetId) {
  return {
    type: REVIEW_LINK_CONTAINER.FETCH_ASSET_DATA,
    payload: {
      reviewLinkId,
      assetId,
      versionStackAssetId,
    },
  };
}

export function fetchVersionData(reviewLinkId, versionStackId, assetId) {
  return {
    type: REVIEW_LINK_CONTAINER.FETCH_VERSION_DATA,
    payload: {
      reviewLinkId,
      versionStackId,
      assetId,
    },
  };
}

export function confirmDownloadAllAssets(assetIds) {
  return {
    type: REVIEW_LINK_CONTAINER.CONFIRM_DOWNLOAD_ALL_ASSETS,
    payload: {
      assetIds,
    },
  };
}

export function updateAnonUserName(user) {
  return {
    type: REVIEW_LINK_CONTAINER.UPDATE_ANON_USER_NAME,
    payload: {
      user,
    },
  };
}

export function lookUpAnonUserEmail(reviewLinkId, email) {
  return {
    type: REVIEW_LINK_CONTAINER.LOOKUP_ANON_USER_EMAIL,
    payload: {
      reviewLinkId,
      email,
    },
  };
}

export function cancelAnonUserSignup() {
  return {
    type: REVIEW_LINK_CONTAINER.CANCEL_ANON_USER_SIGNUP,
    payload: {},
  };
}

export function showNameInputChange(showNameInput) {
  return {
    type: REVIEW_LINK_CONTAINER.SHOW_NAME_INPUT_CHANGE,
    payload: {
      showNameInput,
    },
  };
}

export const toggleDetailPane = createAction(
  REVIEW_LINK_CONTAINER.TOGGLE_DETAIL_PANE,
  (isDetailPaneOpen) => ({ isDetailPaneOpen })
);

export const setSelectedPageNumber = createAction(
  REVIEW_LINK_CONTAINER.SET_SELECTED_PAGE_NUMBER,
  (page) => ({ page })
);
