import { connect } from 'react-redux';
import { groupEntitySelector } from '@frameio/core/src/groups/selectors';
import GroupToken from './GroupToken';

function mapStateToProps(state, { id: groupId }) {
  const { name, emoji, user_count: memberCount } = groupEntitySelector(state, {
    groupId,
  });
  return {
    emoji,
    message: `${memberCount} member${
      memberCount === 1 ? '' : 's'
    } in this group`,
    name,
  };
}

export const testExports = { mapStateToProps };
export default connect(mapStateToProps)(GroupToken);
