import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconCircle, {
  iconCircleColors,
} from '@frameio/components/src/styled-components/IconCircle';
import Button from '@frameio/components/src/styled-components/Button';
import {
  PromptTitleContainer,
  IconContainer,
  PromptTitle,
  PromptSubtext,
  PromptInput,
  SubmitContainer,
} from '../ReviewLink/ReviewLinkPromptContainer/promptComponents';

const TITLE = 'This link is private';
const SUBTEXT = 'Please enter the passphrase to view this link.';

const PasswordPromptContainer = styled.div`
  position: relative;
`;

class ReviewLinkPasswordPrompt extends React.Component {
  /**
   * ReviewLinkPromptPage constructor.
   * @param  {Object} props - Props for ReviewLinkPromptPage.
   */
  constructor(props) {
    super(props);
    this.state = { passwordText: '' };
  }

  /**
   * ComponentDidUpdate method.
   * @param  {Object} lastProps - Last props for component.
   */
  componentDidUpdate() {
    if (this.passwordField) {
      this.passwordField.focus();
    }
  }

  /**
   * OnPasswordChange method.
   * @param  {SyntheticEvent} evt - Change event.
   */
  onPasswordChange = (evt) => {
    this.setState({
      passwordText: evt.target.value,
    });
  };

  /**
   * onPasswordSubmit method.
   * @param  {SyntheticEvent} evt - Submit event.
   */
  onPasswordSubmit = (evt) => {
    evt.preventDefault();
    const { onPasswordSubmit } = this.props;
    const { passwordText } = this.state;
    onPasswordSubmit(passwordText);
  };

  render() {
    const { passwordText } = this.state;

    return (
      <PasswordPromptContainer>
        <PromptTitleContainer>
          <IconContainer>
            <IconCircle
              shouldPulsate
              icon="lock"
              color={iconCircleColors.PURPLE}
            />
          </IconContainer>
          <PromptTitle>{TITLE}</PromptTitle>
        </PromptTitleContainer>
        <PromptSubtext>{SUBTEXT}</PromptSubtext>
        <form onSubmit={this.onPasswordSubmit}>
          <PromptInput
            type="password"
            placeholder="Passphrase"
            value={passwordText}
            onChange={this.onPasswordChange}
            onKeyDown={(evt) => evt.stopPropagation()}
            ref={(el) => {
              this.passwordField = el;
            }}
          />
          <SubmitContainer>
            <Button primary disabled={passwordText.length === 0}>
              Continue
            </Button>
          </SubmitContainer>
        </form>
      </PasswordPromptContainer>
    );
  }
}

ReviewLinkPasswordPrompt.propTypes = {
  onPasswordSubmit: PropTypes.func.isRequired,
};

export default ReviewLinkPasswordPrompt;
