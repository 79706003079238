import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Tooltip } from '@frameio/vapor';
import Button from '@frameio/components/src/styled-components/Button';
import Plus from '@frameio/components/src/svgs/icons/plus.svg';
import Minus from '@frameio/components/src/svgs/icons/minus.svg';

const PlusMinus = styled(Button)`
  width: 50px;
  height: 50px;
  border: 1px solid ${(p) => p.theme.color.lightGray};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  &:focus:not(:active):not(:hover) {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  &:hover:not(:active):not(:disabled),
  :active:not(:disabled) {
    background-color: transparent;
  }
`;

function formatIcon(icon) {
  return styled(icon).attrs(() => ({
    height: 16,
    width: 16,
  }))`
    vertical-align: middle;
  `;
}

const PlusIcon = formatIcon(Plus);
const MinusIcon = formatIcon(Minus);

const AddSubtractButton = ({
  isPositive,
  onClick,
  canUpdateInput,
  toolTipText,
}) => (
  <Tooltip
    title={isPositive ? `Add ${toolTipText}` : `Remove ${toolTipText}`}
    placement="top"
  >
    <div>
      <PlusMinus
        icon="true"
        onClick={() => onClick(isPositive)}
        isDisabled={!canUpdateInput(isPositive)}
      >
        {isPositive ? <PlusIcon /> : <MinusIcon />}
      </PlusMinus>
    </div>
  </Tooltip>
);

AddSubtractButton.defaultProps = {
  isPositive: false,
  toolTipText: '',
};

AddSubtractButton.propTypes = {
  isPositive: PropTypes.bool,
  toolTipText: PropTypes.string,
  canUpdateInput: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AddSubtractButton;

export const testExports = {
  PlusMinus,
};
