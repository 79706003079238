import { generateActionTypes } from 'shared/actions/helpers';

export const SLACK = generateActionTypes('SLACK', [
  'AUTH',
  'RECEIVE_OAUTH_RESPONSE',
]);

export function authOnSlack(state) {
  return {
    type: SLACK.AUTH,
    payload: { state },
  };
}

export function receiveSlackOAuthResponse(code, state) {
  return {
    type: SLACK.RECEIVE_OAUTH_RESPONSE,
    payload: { code, state },
  };
}
