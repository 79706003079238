import { createAction } from 'redux-actions';
import {
  generateActionTypes,
  generateFetchActionTypes,
} from 'shared/actions/helpers';

const module = 'JUMP_TO';

const JUMP_TO = {
  ...generateActionTypes(module, ['RESET']),
  ...generateFetchActionTypes(module, ['TYPEAHEAD']),
};
export default JUMP_TO;

export const reset = createAction(JUMP_TO.RESET, () => ({}));

export const typeahead = createAction(
  JUMP_TO.TYPEAHEAD.BASE,
  (accountId, query, options) => ({ accountId, query, options })
);
