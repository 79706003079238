import React from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { size } from 'polished';
import WarningIcon from '@frameio/components/src/svgs/icons/warning.svg';
import Button from '@frameio/components/src/styled-components/Button';
import { alignCenter as alignCenterMixin } from '@frameio/components/src/mixins';

const ICON_GLOW = '#E4E3FF';
const ICON_GLOW_SIZE = '74px';
const ICON_SIZE = 24;

const OverLimitIcon = styled(WarningIcon).attrs(({ theme }) => ({
  width: 20,
  height: 20,
  color: theme.color.error,
}))`
  right: ${(p) => p.theme.spacing.medium};
  top: ${(p) => p.theme.spacing.micro};
  position: absolute;
`;

const Row = styled(Flex)`
  border-bottom: ${({ theme }) => `1px solid ${theme.color.coldWhite}`};
  padding: ${({ theme }) => `${theme.spacing.medium} 0`};
`;

const IconsContainer = styled.div`
  position: relative;
`;

const MainIconContainer = styled.span`
  ${alignCenterMixin()};
  ${size(ICON_GLOW_SIZE)};
  color: ${(p) => p.theme.color.brand};
  background-color: ${ICON_GLOW};
  border-radius: 100%;
  margin-right: ${(p) => p.theme.spacing.medium};
  box-shadow: ${(p) => p.theme.shadow.largeSubtle};
`;

const Header = styled.div`
  ${(p) => p.theme.fontStyle.heading};
  color: ${(p) => p.theme.color.darkGray};
  font-size: ${(p) => p.theme.fontSize[2]};
  padding-bottom: ${(p) => p.theme.spacing.tiny};
`;

const Subheader = styled.div`
  ${(p) => p.theme.fontStyle.body};
  color: ${(p) => p.theme.color.gray};
`;

const LeftSection = styled.div`
  flex-grow: 1;
`;

const RightSection = styled(Flex).attrs(() => ({
  column: true,
  justifyEnd: true,
}))`
  width: 30%;
  align-self: flex-end;
  text-align: right;

  ${Button} {
    margin-left: auto;
  }
`;

const CostText = styled.div`
  color: ${(p) => p.theme.color.graphiteGray};
  font-size: ${(p) => p.theme.fontSize[1]};
  padding-right: ${(p) => p.theme.spacing.small};
  font-weight: ${(p) => p.theme.fontWeight.bold};
`;

/**
 * @param {Object} props - React props.
 * @returns {ReactElement} - LeftIconRow.
 */
const BillingItemRow = ({
  button,
  Icon,
  header,
  subheader,
  shouldShowWarning,
  costInfo,
  alignCenter,
}) => (
  <Row alignCenter={alignCenter}>
    <IconsContainer>
      <MainIconContainer>
        <Icon height={ICON_SIZE} width={ICON_SIZE} />
      </MainIconContainer>
      {shouldShowWarning && <OverLimitIcon />}
    </IconsContainer>
    <LeftSection>
      <Header>{header}</Header>
      <Subheader>{subheader}</Subheader>
    </LeftSection>
    {button && (
      <RightSection>
        {costInfo && <CostText>{costInfo}</CostText>}
        {button}
      </RightSection>
    )}
  </Row>
);

BillingItemRow.defaultProps = {
  subheader: '',
  shouldShowWarning: false,
  costInfo: null,
  button: null,
};

BillingItemRow.propTypes = {
  button: Proptypes.node,
  header: Proptypes.node.isRequired,
  Icon: Proptypes.func.isRequired,
  subheader: Proptypes.node,
  shouldShowWarning: Proptypes.bool,
  costInfo: Proptypes.string,
};

export default BillingItemRow;

export const testExports = {
  OverLimitIcon,
  CostText,
};
