import { takeLatest, put } from 'redux-saga/effects';
import { CUSTOM_ACTION as CORE_CUSTOM_ACTION } from '@frameio/core/src/customActions/actions';
import { showErrorToast } from 'actions/toasts';

export function* onExecuteCustomActionFailure() {
  yield put(
    showErrorToast({
      header: 'Error',
    })
  );
}

export default [
  takeLatest(CORE_CUSTOM_ACTION.EXECUTE.FAILURE, onExecuteCustomActionFailure),
];
