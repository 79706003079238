import React from 'react';
import PropTypes from 'prop-types';
import { DynamicColorIcon } from '@frameio/components';

/**
 * Modal Header Component.
 * @param   {Object} props - The props for the modal header.
 * @returns {ReactElement} Modal header component.
 */
export default function ModalHeader(props) {
  return (
    <div className="z-4 w-100 white bg-fio-purple truncate">
      <div className="flex items-center justify-between">
        <div className="f4 pl4">{props.text}</div>
        <div
          className="reset-container pointer button-reset bg-black-20 modal-header-padding"
          role="button"
          tabIndex="0"
          onClick={(evt) => {
            evt.stopPropagation();
            props.onClickClose();
          }}
          data-modal-header-x
        >
          <DynamicColorIcon kind="x" size={18} color="white" />
        </div>
      </div>
    </div>
  );
}

ModalHeader.propTypes = {
  text: PropTypes.string,
  onClickClose: PropTypes.func,
};

ModalHeader.defaultProps = {
  text: '',
  onClickClose: () => {},
};
