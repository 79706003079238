import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import track, { trackButtonClick } from 'analytics';
import Dialog, {
  dialogTypes,
} from '@frameio/components/src/styled-components/Dialog';
import Button from '@frameio/components/src/styled-components/Button';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import { PROJECT_ACCESS_SUPPORT_URL } from 'URLs';
import { ENTITY_TYPE } from '../../AuthGate/AuthGate';

const SUPPORT_LINK = PROJECT_ACCESS_SUPPORT_URL;

export const Copy = styled.p`
  color: ${({ theme }) => theme.color.gray};
  padding: 0 10px;

  strong {
    word-break: break-word;
  }
`;

export const StyledDialog = styled(Dialog)`
  padding: ${({ theme }) => theme.spacing.units(2)};

  ${Copy} + ${Copy} {
    margin-top: ${({ theme }) => theme.spacing.units(2)};
  }
`;

const LearnMoreLink = styled.a`
  color: ${(p) => p.theme.color.brand};
`;

export default function InitialScreen({
  closeModal,
  email,
  logOut,
  entityType,
  entityId,
  requestAccessToEntity,
  toggleHasRequestedAccessToEntity,
}) {
  const buttonRef = useRef(null);

  useEffect(() => {
    buttonRef.current.focus();
  }, []);

  return (
    <React.Fragment>
      <ModalCloseButton onClick={closeModal} />
      <StyledDialog
        type={dialogTypes.NEUTRAL}
        header={`Request access to ${entityType}`}
        primaryButton={
          <Button
            ref={buttonRef}
            primary
            onClick={() => {
              requestAccessToEntity(entityType, entityId);
              toggleHasRequestedAccessToEntity();
              track(`${entityType}-access-requested`);
              trackButtonClick(
                'request access',
                `request access ${entityType}`,
                'middle'
              );
            }}
          >
            Request access
          </Button>
        }
        secondaryButton={<Button onClick={logOut}>Log out</Button>}
      >
        <Copy>
          Want in? Request access or log in with an approved email.{' '}
          <LearnMoreLink
            href={SUPPORT_LINK}
            target="_blank"
            onClick={() =>
              trackButtonClick(
                'learn more',
                `request access ${entityType}`,
                'middle'
              )
            }
          >
            Learn more
          </LearnMoreLink>
        </Copy>
        <Copy>
          You are signed in as <strong>{email}</strong>.
        </Copy>
      </StyledDialog>
    </React.Fragment>
  );
}

InitialScreen.propTypes = {
  closeModal: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired,
  entityType: PropTypes.oneOf(Object.values(ENTITY_TYPE)).isRequired,
  entityId: PropTypes.string.isRequired,
  requestAccessToEntity: PropTypes.func.isRequired,
  toggleHasRequestedAccessToEntity: PropTypes.func.isRequired,
};
