import React from 'react';
import styled from 'styled-components';
import CancelAccountFlowModal from '../CancelAccountFlowModal';

const TEXT = {
  HEADER: 'Confirm your cancellation',
};

const Confirmation = ({
  currentStep,
  accountId,
  closeModal,
  confirmCancellation,
  isAdobeBridgeOfferEligible,
  source,
  trackingFeedback,
  trackingReason,
  onClose,
}) => {
  const handleClick = (e) => {
    closeModal();
    confirmCancellation({
      accountId,
      feedback: trackingFeedback,
      reason: trackingReason,
      source,
    });
    e.preventDefault();
  };

  const displayStorageLimit = isAdobeBridgeOfferEligible ? '100GB' : '2GB';

  return (
    <CancelAccountFlowModal
      currentStep={currentStep}
      headerText={TEXT.HEADER}
      handleSubmit={handleClick}
      isDestructiveToProceed
      source={source}
      onClose={onClose}
    >
      <DescriptionContainer>
        <Description>
          When your billing period ends, you will be downgraded to Free and{' '}
          <strong>will lose access to premium features and storage.</strong>
        </Description>
        <Description>
          Please ensure your storage usage is below the {displayStorageLimit}{' '}
          Active Storage limit of the Free plan.{' '}
          <strong>
            Any assets exceeding this limit will be deleted after 90 days.
          </strong>
        </Description>
        <Description>
          If you would like to keep your assets, contact{' '}
          <a href="mailto:support@frame.io?subject=Pause my Frame.io subscription">
            support@frame.io
          </a>{' '}
          to pause your subscription instead.
        </Description>
        <Description>
          Are you sure you want to cancel your subscription?
        </Description>
      </DescriptionContainer>
    </CancelAccountFlowModal>
  );
};

const DescriptionContainer = styled.div`
  p:not(:last-child) {
    margin-bottom: ${(p) => p.theme.spacing.units(2)};
  }
`;

const Description = styled.p`
  ${(p) => p.theme.fontStyle.body};
`;

export default Confirmation;
