import { connect } from 'react-redux';

import { respond } from './actions';
import SimpleDialog from './SimpleDialog';

/**
 * Maps state to props for the SimpleDialog component.
 * @param   {Object} state - The state object.
 * @returns {Object} An object with state mapped as props.
 */
const mapStateToProps = (state) => state.simpleDialog;
const mapDispatchToProps = {
  respond,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleDialog);
