import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TreeItem } from '@frameio/components/src/styled-components/TreeView';
import TeamIcon from './TeamIcon';
import ConnectedTreeProjectItem from './ConnectedTreeProjectItem';
import TreeItemLoadingIndicator from './TreeItemLoadingIndicator';

const StyledTreeItem = styled(TreeItem)`
  padding-left: ${(p) => p.theme.spacing.units(0.5)};
`;

/**
 * Render a team as a TreeItem
 */

function TreeTeamItem({
  team,
  customLabel,
  onFetchProjects,
  projects = [],
  isLoading,
  ...other
}) {
  const { id, name, project_count: projectCount } = team;

  /**
   * Render a loading indicator if loading takes
   * a bit of time.
   */

  const iconAfter = useCallback(
    ({ isExpanded }) => (
      <TreeItemLoadingIndicator isLoading={isLoading && isExpanded} />
    ),
    [isLoading]
  );

  const handleFetchProjects = useCallback(() => {
    if (projectCount > 0) {
      onFetchProjects(id);
    }
  }, [id, projectCount, onFetchProjects]);

  const teamIcon = useMemo(() => {
    return <TeamIcon />;
  }, []);

  return (
    <StyledTreeItem
      iconBefore={teamIcon}
      nodeId={id}
      label={customLabel || name}
      isSelectable={false}
      onMouseDown={handleFetchProjects}
      onExpand={handleFetchProjects} // in case user is using keyboard
      showChevron={projectCount > 0}
      iconAfter={iconAfter}
      {...other}
    >
      {projects.map((project) => (
        <ConnectedTreeProjectItem
          nodeId={project.root_asset_id}
          key={project.id}
          project={project}
        />
      ))}
    </StyledTreeItem>
  );
}

TreeTeamItem.propTypes = {
  team: PropTypes.object.isRequired,
  customLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onFetchProjects: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      root_asset_id: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      folder_count: PropTypes.number.isRequired,
    })
  ),
  isLoading: PropTypes.bool,
};

export default TreeTeamItem;
