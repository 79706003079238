import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  containsMultipleAssetsSelector,
  areAssetsVersionStacks as areAssetsVersionStacksSelector,
  assetEntitySelector,
} from '@frameio/core/src/assets/selectors';
import { confirmDeleteAssets } from 'actions/assets';
import DeleteAssets from './DeleteAssets';

function mapStateToProps(state, { assetIds }) {
  const areAssetsVersionStacks = areAssetsVersionStacksSelector(state, {
    assetIds,
  });
  const isSingleAsset = !containsMultipleAssetsSelector(state, { assetIds });
  const singleAssetVersionStack =
    isSingleAsset &&
    areAssetsVersionStacks &&
    assetEntitySelector(state, { assetId: assetIds[0] });
  return {
    areAssetsVersionStacks,
    singleVersionStackVersions: get(singleAssetVersionStack, 'versions', 0),
  };
}

const mapDispatchToProps = {
  confirmDeleteAssets,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteAssets);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
