import configAttr from 'config';
import { datadogRum } from '@datadog/browser-rum';
import getRumOptions from './telemetry/datadog/getRumOptions';

export default function configureDatadogRUM() {
  if (!configAttr.datadogRum) return;

  const { datadogRum: datadogConfig, isDatadogRumEnabled } = configAttr;

  try {
    const mergedOptions = {
      ...datadogConfig,
      ...getRumOptions(isDatadogRumEnabled),
    };
    datadogRum.init(mergedOptions);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
    // eslint-disable-next-line no-console
    console.warn('Unable to initialize Datadog RUM.');
  }
}
