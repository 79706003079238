/**
 * @param   {Array} impressions - An array of comment impressions.
 * @param   {Object} userEntities - Redux state.
 * @returns {Array} - An array of user names.
 */
export default function transformImpressions(
  impressions = [],
  userEntities,
  currentUser
) {
  return impressions
    .map((impression) => {
      if (currentUser.id === impression.user_id) {
        return 'You';
      }
      return (
        userEntities[impression.user_id] ||
        userEntities[impression.anonymous_user_id] ||
        {}
      ).name;
    })
    .filter((impressionName) => !!impressionName);
}
