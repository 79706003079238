import transitionFactory, { opacity } from 'react-transition-factory';

const ScaleFadeTransition = transitionFactory(opacity, {
  transition: 'transform',
  getStartStyle: (start = 0.7) => `scale(${start})`,
  getEndStyle: (end = 1) => `scale(${end})`,
});
ScaleFadeTransition.defaultProps = {
  ...ScaleFadeTransition.defaultProps,
  timeout: 150,
  style: {
    display: 'flex',
  },
};

export default ScaleFadeTransition;
