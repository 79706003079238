import React from 'react';
import PropTypes from 'prop-types';
import JTCurve from 'core/JTCurve';
import { throttle } from 'lodash';

// Extracted from AssetSlider
//
function range(left, right, inclusive) {
  const currentRange = [];
  const ascending = left < right;
  let end;
  if (!inclusive) {
    end = right;
  } else if (ascending) {
    end = right + 1;
  } else {
    end = right - 1;
  }
  for (
    let i = left;
    ascending ? i < end : i > end;
    ascending ? (i += 1) : (i -= 1)
  ) {
    currentRange.push(i);
  }
  return currentRange;
}

const VELOCITY = 2.2; // pixels/ms

class HorizontalScroller extends React.Component {
  constructor(props) {
    super(props);
    this.state = { leftPointer: 0 };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Only re-render when one of these critical values changes
    return (
      this.props.width !== nextProps.width ||
      this.props.components !== nextProps.components ||
      this.state.leftPointer !== nextState.leftPointer ||
      this.props.selectedThumbIndex !== nextProps.selectedThumbIndex
    );
  }

  getImageWidth() {
    return this.props.imageWidth + this.props.imageHorizontalPadding;
  }

  getNumberPerPage() {
    return Math.floor(this.props.width / this.getImageWidth());
  }

  getNumberToRender() {
    return this.getNumberPerPage() + this.props.overscan;
  }

  // TODO (christianevans214): Redo asset slider components so that
  // this method isn't called by parent component (AssetSliderFactory).
  isIndexInWindow = (idx) => {
    const begin = this.slider.scrollLeft;
    const end = begin + this.props.width;
    const idxPos = this.positionOfImageAtIndex(idx);
    return idxPos >= begin && idxPos + this.getImageWidth() <= end;
  };

  handleScroll = throttle(() => {
    const newLeftPointer = Math.floor(
      this.slider.scrollLeft / this.getImageWidth()
    );
    if (newLeftPointer !== this.state.leftPointer) {
      this.setState({ leftPointer: newLeftPointer });
      if (this.props.setCurrentIndex) {
        this.props.setCurrentIndex(newLeftPointer);
      }
    }
  }, 50);

  calculateTotalWidthOfRunner() {
    const total = this.props.components.length;
    const perPage = this.getNumberPerPage();
    const imageWidth = this.getImageWidth();

    if (!perPage) return 0;

    const perPageWidth = perPage * imageWidth;
    const totalWidth = total * imageWidth;
    const overridge = total % perPage;
    const overridgeWidth =
      overridge === 0 ? perPageWidth : overridge * imageWidth;
    // If the number of items * image width is larger than the total size
    // then we need to add some overridge (so we can scroll to the last page)
    // otherwise we just set it as the items size (so we can center it)
    if (totalWidth > this.props.width) {
      return totalWidth + (this.props.width - overridgeWidth);
    }
    return totalWidth;
  }

  positionOfImageAtIndex(idx) {
    return idx * this.getImageWidth() + this.props.imageHorizontalPadding / 2;
  }

  scrollTo = (scrollLeft, { animate = true } = {}) => {
    if (!animate) {
      this.slider.scrollLeft = scrollLeft;
      return null;
    }
    const animateFunction = (poses) => {
      this.slider.scrollLeft = poses.scrollLeft;
    };
    const beginningPos = this.slider.scrollLeft;
    const endPos = scrollLeft;
    const duration = Math.abs(endPos - beginningPos) / VELOCITY;
    return this.props.animationEngine.animate_custom(
      'some-horizontal-scroller-id',
      { scrollLeft: this.slider.scrollLeft },
      { scrollLeft },
      animateFunction,
      duration,
      JTCurve.easeandwizz
    );
  };

  renderAsset = (idx) => {
    const child = this.props.components[idx + this.state.leftPointer];
    if (!child) {
      return null;
    }

    const style = {
      transform: `translateX(${this.positionOfImageAtIndex(
        idx + this.state.leftPointer
      )}px)`,
    };
    const children = React.cloneElement(child.component, {
      selected: idx + this.state.leftPointer === this.props.selectedThumbIndex,
      width: this.props.imageWidth,
      height: this.props.imageHeight,
      onClick: child.onClick,
      isReady: this.props.isReady,
    });
    return (
      <div
        className="fior-asset-slider-thumb-container"
        key={idx + this.state.leftPointer}
        style={style}
      >
        {children}
      </div>
    );
  };

  render() {
    const style = { width: this.calculateTotalWidthOfRunner() };

    return (
      <div
        className={`fior-asset-slider-container ${this.props.className}`}
        style={{ width: this.props.width, height: this.props.height }}
        ref={(slider) => {
          this.slider = slider;
        }}
        onScroll={this.handleScroll}
      >
        <div
          className="fior-asset-slider-inner"
          style={style}
          data-test-id="thumbs"
        >
          {range(0, this.getNumberToRender(), true).map(this.renderAsset)}
        </div>
      </div>
    );
  }
}

HorizontalScroller.defaultProps = {
  overscan: 3,
  imageHorizontalPadding: 0,
  components: [],
  height: 'inherit',
  className: '',
  width: 0,
};

HorizontalScroller.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number.isRequired,
  overscan: PropTypes.number,
  imageWidth: PropTypes.number.isRequired,
  imageHeight: PropTypes.number.isRequired,
  imageHorizontalPadding: PropTypes.number,
  components: PropTypes.array,
  className: PropTypes.string,
  selectedThumbIndex: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
  animationEngine: PropTypes.object.isRequired,
  isReady: PropTypes.bool.isRequired,
};

export default HorizontalScroller;
