import { get } from 'lodash';
import { createSelector } from 'reselect';
import {
  planEntityForAccountIdSelector,
  subscriptionEntityForAccountIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import {
  isDrmEnabledForAccountSelector,
  isAccountEnterpriseSelector,
  isAccountOnLegacyPlanSelector,
} from 'selectors/accounts';
import {
  archivalStorageEnabled,
  drmSettingsUIEnabled,
  reviewLinkCommentsToggleEnabled,
} from 'utils/featureFlags';
import { allFeatures } from 'planFeatures';
import { PLAN_TIERS } from 'components/SelectPlanFlow/PlanDisplay';
import shouldRestrictCollaborators from './shouldRestrictCollaborators';

const {
  allowedDomains,
  archivalStorage,
  customBrandedPresentations,
  customBrandedEmails,
  customSort,
  devices,
  disableShareComments,
  disableShareDownloads,
  disableSharePreviousVersions,
  forensicWatermarking,
  passwordProtectedShares,
  privateProjects,
  reelPlayer,
  secureSharing,
  sessionBasedWatermarking,
  shareLinkExpiration,
  teamOnlyComments,
} = allFeatures;

function isPaidOrBridgeAccount(subscription, plan) {
  if (!plan) return false;

  const { tier, eligibility } = plan;

  const onTrial = !!subscription.on_trial;

  return (
    !(onTrial || tier === PLAN_TIERS.FREE) ||
    eligibility === 'adobe_bridge_offer'
  );
}

function canUsePlanFeature(account, planFeatureId) {
  if (!account) return false;

  const { available_features: features } = account;
  return !!features && features[planFeatureId];
}

function isBelowProjectLimit(account, plan) {
  if (!plan) return false;

  return !plan.project_limit || plan.project_limit > account.project_count;
}

const permittedActionsForAccountSelector = createSelector(
  accountEntitySelector,
  subscriptionEntityForAccountIdSelector,
  planEntityForAccountIdSelector,
  isAccountOnLegacyPlanSelector,
  isAccountEnterpriseSelector,
  isDrmEnabledForAccountSelector,
  (
    account,
    subscription,
    plan,
    isOnLegacyPlan,
    isAccountEnterprise,
    isDrmEnabled
  ) => {
    const shouldRestrict = !account || shouldRestrictCollaborators(account);
    const canUseTeamOnlyComments = canUsePlanFeature(account, teamOnlyComments);
    const canCreateProject =
      isAccountEnterprise || isBelowProjectLimit(account, plan);

    const canCollaboratorsDownloadFromProjectGlobal =
      account?.account_settings?.download_min_role === 'collaborators';

    const areTeamMemberInvitesAllowed =
      account?.account_settings?.allow_team_member_invites;

    const isDrmSettingsEnabled = isDrmEnabled && drmSettingsUIEnabled();

    const isForensicWatermarkingEnabled = get(account, [
      'available_features',
      forensicWatermarking,
    ]);

    const isSessionBasedWatermarkingEnabled = get(account, [
      'available_features',
      sessionBasedWatermarking,
    ]);

    const isForensicWatermarkingRequiredInternally = get(account, [
      'account_settings',
      'fwm_required_internally',
    ]);

    const isSessionBasedWatermarkingRequiredInternally = get(account, [
      'account_settings',
      'sbwm_require_internally',
    ]);

    const minRoleForAssetsWithoutWatermarking = get(account, [
      'account_settings',
      'unwatermarked_internal_access_min_role',
    ]);

    const minRoleForAssetsWithoutForensicWatermarking = get(account, [
      'account_settings',
      'fwm_not_required_internal_access_min_role',
    ]);

    const isForensicFallbackEnabled = get(account, [
      'account_settings',
      'fwm_fallback',
    ]);

    const roleRankings = {
      admins: ['admins', 'team_managers', 'team_members', 'collaborators'],
      teamManagers: ['team_managers', 'team_members', 'collaborators'],
      teamMembers: ['team_members', 'collaborators'],
      collaborators: ['collaborators'],
    };

    const withForensicWatermarking = (role) =>
      isForensicWatermarkingEnabled && isForensicWatermarkingRequiredInternally
        ? roleRankings[role].includes(
            minRoleForAssetsWithoutForensicWatermarking
          )
        : true;

    const withSessionBasedWatermarking = (role) =>
      isSessionBasedWatermarkingEnabled &&
      isSessionBasedWatermarkingRequiredInternally
        ? roleRankings[role].includes(minRoleForAssetsWithoutWatermarking)
        : true;

    const canAccessCleanAssets = (role, fwmEnabled, sbwmEnabled) => {
      if (fwmEnabled && sbwmEnabled) {
        return (
          withForensicWatermarking(role) && withSessionBasedWatermarking(role)
        );
      }
      if (fwmEnabled) {
        return withForensicWatermarking(role);
      }
      if (sbwmEnabled) {
        return withSessionBasedWatermarking(role);
      }
      return true;
    };

    const canAdminsAccessCleanAssets = (fwmEnabled, sbwmEnabled) =>
      canAccessCleanAssets('admins', fwmEnabled, sbwmEnabled);

    const canTeamManagersAccessCleanAssets = (fwmEnabled, sbwmEnabled) =>
      canAccessCleanAssets('teamManagers', fwmEnabled, sbwmEnabled);

    const canTeamMembersAccessCleanAssets = (fwmEnabled, sbwmEnabled) =>
      canAccessCleanAssets('teamMembers', fwmEnabled, sbwmEnabled);

    const canCollaboratorsAccessCleanAssets = (fwmEnabled, sbwmEnabled) =>
      canAccessCleanAssets('collaborators', fwmEnabled, sbwmEnabled);

    return {
      c2cConnectionsFeatureEnabled: get(account, [
        'available_features',
        devices,
      ]),
      disableTeamMemberInvitesFeatureEnabled: get(account, [
        'available_features',
        'disable_team_member_invites',
      ]),
      isAllowedDomainsEnabled: get(account, [
        'available_features',
        allowedDomains,
      ]),
      canAdminsAccessCleanAssets,
      canCollaboratorsAccessCleanAssets,
      canCollaboratorsDownloadFromProjectGlobal,
      canCreateProject,
      canTeamManagersAccessCleanAssets,
      canTeamMembersAccessCleanAssets,
      canInviteCollaborators: !shouldRestrict,
      canSharePresentation: !shouldRestrict,
      canTeamMembersInvite: areTeamMemberInvitesAllowed,
      canToggleInviteCollaborators: !shouldRestrict,
      canToggleReviewLinkComments: reviewLinkCommentsToggleEnabled(),
      canToggleSharePresentations: !shouldRestrict,
      canToggleTeamOnlyComments: canUseTeamOnlyComments || !isOnLegacyPlan,

      // TODO(marvin): stop exposing this once we move the tracking in
      // `RequestToJoinTeamCard` to happen at the call sites instead.
      shouldRestrictCollaborators: shouldRestrict,

      // Plan features
      canCustomizeEmailMessages: isPaidOrBridgeAccount(subscription, plan),
      canUseTeamOnlyComments,
      canUseCustomBrandedEmails: canUsePlanFeature(
        account,
        customBrandedEmails
      ),
      canUseCustomBrandedPresentations: canUsePlanFeature(
        account,
        customBrandedPresentations
      ),
      canUseReelPlayer:
        isAccountEnterprise || canUsePlanFeature(account, reelPlayer),
      canUseArchivalStorage:
        canUsePlanFeature(account, archivalStorage) || archivalStorageEnabled(),
      canUseCustomSort:
        isAccountEnterprise || canUsePlanFeature(account, customSort),
      canDisableShareComments:
        isAccountEnterprise || canUsePlanFeature(account, disableShareComments),
      canDisableShareDownloads:
        isAccountEnterprise ||
        canUsePlanFeature(account, disableShareDownloads),
      canDisableSharePreviousVersions:
        isAccountEnterprise ||
        canUsePlanFeature(account, disableSharePreviousVersions),
      fwm_fallback_preference: get(account, [
        'account_settings',
        'fwm_fallback_preference',
      ]),
      isSessionBasedWatermarkingEnabled: get(account, [
        'available_features',
        sessionBasedWatermarking,
      ]),
      isSessionBasedWatermarkingRequiredInternally: get(account, [
        'account_settings',
        'sbwm_require_internally',
      ]),
      isDrmSettingsEnabled,
      isForensicFallbackEnabled,
      isForensicWatermarkingEnabled,
      isForensicWatermarkingRequiredInternally: get(account, [
        'account_settings',
        'fwm_required_internally',
      ]),
      isSecureSharingEnabled: get(account, [
        'available_features',
        secureSharing,
      ]),
      canUseShareLinkExpiration: canUsePlanFeature(
        account,
        shareLinkExpiration
      ),
      canUsePasswordProtectedShares: canUsePlanFeature(
        account,
        passwordProtectedShares
      ),
      canUsePrivateProjects: canUsePlanFeature(account, privateProjects),
    };
  }
);

export default permittedActionsForAccountSelector;
