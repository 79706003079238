import React from 'react';
import PropTypes from 'prop-types';
import { MenuButton } from '@frameio/vapor';
import LightningIcon from '@frameio/components/src/svgs/icons/lightning.svg';
import track, { trackButtonClick } from 'analytics';

export default function CustomAction({
  executeCustomAction,
  trackingPage,
  trackingPosition,
  assetId,
  action,
  ...rest
}) {
  const { id, name } = action;

  const handleClick = () => {
    const data = {
      resource: {
        id: assetId,
        type: 'asset',
      },
    };
    executeCustomAction(id, data);
    trackButtonClick('custom action asset', trackingPage, trackingPosition);
    track('custom-action-clicked', { action_ID: id });
  };
  const icon = <LightningIcon width={16} height={16} />;

  return (
    <MenuButton onSelect={handleClick} iconBefore={icon} {...rest}>
      {name}
    </MenuButton>
  );
}

CustomAction.propTypes = {
  assetId: PropTypes.string.isRequired,
  action: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  executeCustomAction: PropTypes.func.isRequired,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};
