import React from 'react';
import Media from 'react-media';
import { size, rgba } from 'polished';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import PropTypes from 'prop-types';

import { PlaceholderText } from '@frameio/components/src/styled-components/LoadingCard';

import SmallAssetThumb from 'components/SmallAssetThumb';
import Cell from 'components/InfiniteGrid/ListCell';
import Row from 'components/InfiniteGrid/ListRow';
import ThumbContainer from 'components/InfiniteGrid/ListThumbContainer';
import { MEDIUM_UP } from 'utils/mediaQueries';

const ThumbPlaceholder = styled.figure`
  background-color: ${({ theme }) => rgba(theme.color.graphiteGray, 0.2)};
  ${size(
    `${SmallAssetThumb.defaultProps.height}px`,
    `${SmallAssetThumb.defaultProps.width}px`
  )};
  border-radius: ${(p) => p.theme.radius.medium};
  margin: 0;
`;

const RowPlaceholderText = styled(PlaceholderText)`
  border-radius: ${(p) => p.theme.radius.medium};
  height: ${({ height }) => height};
`;

function LoadingRow({ height, listColumnWidths }) {
  return (
    <Row height={height}>
      <Media query={MEDIUM_UP}>
        {(isMediumUp) => (
          <React.Fragment>
            <Cell width={isMediumUp ? listColumnWidths[0] : '100%'}>
              <Flex alignCenter>
                <ThumbContainer>
                  <ThumbPlaceholder />
                </ThumbContainer>
                <RowPlaceholderText height="12px" width="50%" />
              </Flex>
            </Cell>
            {isMediumUp &&
              listColumnWidths.slice(1).map((width, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Cell width={width} key={index}>
                  <RowPlaceholderText height="8px" width="100%" />
                </Cell>
              ))}
          </React.Fragment>
        )}
      </Media>
    </Row>
  );
}

export default React.memo(LoadingRow);

LoadingRow.propTypes = {
  height: PropTypes.number.isRequired,
  listColumnWidths: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export const testExports = {
  ThumbPlaceholder,
};
