import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import LoopIcon from '@frameio/components/src/svgs/icons/16/loop.svg';
import { trackViewer } from 'analytics';
import PlayerBarButton from 'components/PlayerControlBar/PlayerBarButton';

const LoopButton = ({ isLooping, toggleLoop, tooltipTitle }) => {
  // Hack to fix re-rendering issue in iOS Safari (re-render is not happening on the call of toggleLoop)
  const [isLoopingActive, setIsLoopingActive] = useState(isLooping);

  const handleClick = useCallback(() => {
    toggleLoop(!isLoopingActive);
    setIsLoopingActive(!isLoopingActive);
    trackViewer('loop-button-clicked', {
      state: isLoopingActive ? 'off' : 'on',
    });
  }, [isLoopingActive, toggleLoop]);

  return (
    <PlayerBarButton
      aria-label="Loop video"
      aria-pressed={isLoopingActive}
      isActive={isLoopingActive}
      onClick={handleClick}
      icon={<LoopIcon />}
      tooltipTitle={tooltipTitle}
    />
  );
};

LoopButton.defaultProps = {
  isLooping: false,
};

LoopButton.propTypes = {
  isLooping: PropTypes.bool,
  toggleLoop: PropTypes.func.isRequired,
  tooltipTitle: PropTypes.string,
};

export default LoopButton;
