import { PROJECT } from '@frameio/core/src/projects/actions';
import { CREATE_PROJECT_FORM } from './actions';

export default function createProjectFormReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_PROJECT_FORM.SET_TEAM_ID: {
      return {
        ...state,
        teamId: action.payload.teamId,
      };
    }
    case PROJECT.CREATE.PENDING: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case PROJECT.CREATE.SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
