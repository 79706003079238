import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@frameio/components';

/**
 * Footer for ProjectSettingsModal when creating a new project.
 * @param {Object} props - React props.
 */
const CreateProjectFooter = ({ isWaiting, onCreateButtonClick, disabled }) => (
  <div
    className="absolute bottom-0 w-100 pa3 flex items-center justify-center bt b--fio-gray bg-white"
    data-buttons
  >
    <Button
      isWaiting={isWaiting}
      isDisabled={disabled}
      className="w-100 pv3 bg-white bg-hover-fio-purple b--fio-gray dark-gray white-children-hover"
      onClick={onCreateButtonClick}
    >
      Create Project
    </Button>
  </div>
);

CreateProjectFooter.defaultProps = {
  onCreateButtonClick: () => {},
  disabled: false,
  isWaiting: false,
};

CreateProjectFooter.propTypes = {
  onCreateButtonClick: PropTypes.func,
  disabled: PropTypes.bool,
  isWaiting: PropTypes.bool,
};

export default CreateProjectFooter;
