import React, { useEffect } from 'react';
import track from 'analytics';
import { useSelector } from 'react-redux';
import { currentAccountSelector } from 'selectors/accounts';
import SelectPlanFlow from 'components/SelectPlanFlow';
import { MAX_HEIGHT as MAX_SELECT_PLAN_MODAL_HEIGHT } from 'components/SelectPlanFlow/SelectPlanFlow';
import DialogModal from 'components/Dialog/DialogModal';

export default function SelectPlanModal({ isOpen, onClose }) {
  const currentAccount = useSelector(currentAccountSelector);

  useEffect(() => {
    if (isOpen) {
      track('plan-selection-modal-shown', { source: 'watermark id' });
    }
  }, [isOpen]);

  return (
    <DialogModal
      data-test-id="select-plan-modal"
      isOpen={isOpen}
      onCancel={onClose}
      canBackdropCancel
      maxHeight={MAX_SELECT_PLAN_MODAL_HEIGHT}
    >
      <SelectPlanFlow accountId={currentAccount.id} />
    </DialogModal>
  );
}
