import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MenuButton } from '@frameio/vapor';
import ShareReviewIcon from '@frameio/components//src/svgs/raw/ic-sharing-review-16px.svg';
import { trackButtonClick } from 'analytics';

const Icon = styled(ShareReviewIcon)`
  path {
    fill: currentColor;
  }
`;

export default function ShareAssetsAsReview({
  assetIds,
  canSkipSelection,
  createReviewLinkWithAssets,
  hasExplanation,
  hasPermission,
  isEnterprise,
  legacyShareItemsAsNewReviewLink,
  projectId,
  redirectToProject,
  shouldRedirectToProject,
  selectReviewLinkItems,
  showBlockedCollaboratorMessage,
  toggleReviewLinkSelectionMode,
  trackingPage,
  trackingPosition,
  ...rest
}) {
  return (
    <MenuButton
      data-test-id="share-as-review-link-button"
      onSelect={() => {
        if (hasPermission) {
          if (assetIds.length > 0) {
            /*
                If assets are already pre-selected, or if sharing is initiated from
                the player page, immediately create a review link with the asset ids.
                This will bypass selection mode, the display of the Share Footer, and
                instead will automatically launch the Review Link modal.
              */
            createReviewLinkWithAssets(assetIds);
          } else {
            toggleReviewLinkSelectionMode([]);
          }
          shouldRedirectToProject && redirectToProject(projectId);

          // directly go to the share modal
          if (canSkipSelection) {
            trackButtonClick('share review', trackingPage, trackingPosition);
          } else {
            trackButtonClick(
              'enter sharing mode',
              trackingPage,
              trackingPosition
            );
          }
        } else {
          showBlockedCollaboratorMessage(projectId, 'review_page');
          if (isEnterprise) {
            trackButtonClick(
              'enterprise collaborator attempted to share review link',
              trackingPage,
              trackingPosition
            );
          } else {
            trackButtonClick(
              'non-enterprise collaborator attempted to share review link',
              trackingPage,
              trackingPosition
            );
          }
        }
      }}
      iconBefore={<Icon />}
      details={hasExplanation ? 'Viewers can leave comments' : null}
      {...rest}
    >
      Share for Review
    </MenuButton>
  );
}

ShareAssetsAsReview.propTypes = {
  assetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  canSkipSelection: PropTypes.bool,
  createReviewLinkWithAssets: PropTypes.func.isRequired,
  hasExplanation: PropTypes.bool,
  hasPermission: PropTypes.bool,
  isEnterprise: PropTypes.bool,
  legacyShareItemsAsNewReviewLink: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  redirectToProject: PropTypes.func.isRequired,
  shouldRedirectToProject: PropTypes.bool,
  selectReviewLinkItems: PropTypes.func.isRequired,
  showBlockedCollaboratorMessage: PropTypes.func.isRequired,
  toggleReviewLinkSelectionMode: PropTypes.func.isRequired,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};

ShareAssetsAsReview.defaultProps = {
  canSkipSelection: false,
  hasExplanation: false,
  hasPermission: false,
  isEnterprise: false,
  shouldRedirectToProject: false,
};
