/* eslint-disable import/prefer-default-export */

import { subscriptionEntitiesSelector } from '@frameio/core/src/subscriptions/selectors';
import { find, get } from 'lodash';
import { isAutoScalingPlanSelector } from 'selectors/plans';

/**
 * @param   {Object} state - Redux store state.
 * @returns {Object} - subscription data.
 */
export const subscriptionEntityByAccountIdSelector = (state, accountId) => {
  const subscriptionEntities = subscriptionEntitiesSelector(state);
  return find(subscriptionEntities, { account_id: accountId });
};

export const isAutoScalingPromotionSelector = (state, { accountId }) => {
  if (!accountId) return false;
  const subscription =
    subscriptionEntityByAccountIdSelector(state, accountId) || {};
  // TODO: this check for subscription.promotion_id is a hack which we should remove,
  // and instead we should normalize promotion in core!
  // https://frame-io.atlassian.net/browse/GROW-833
  return (
    !!subscription.promotion_id &&
    get(subscription, 'promotion.autoscaling', false)
  );
};

export const shouldAutoScaleTeamMembersAllocation = (state, { accountId }) => {
  if (!accountId) return false;
  const isAutoscalePlan = isAutoScalingPlanSelector(state, { accountId });
  const isAutoscalePromo = isAutoScalingPromotionSelector(state, { accountId });
  return isAutoscalePlan || isAutoscalePromo;
};
