import { connect } from 'react-redux';
import { teamEntitySelector } from '@frameio/core/src/teams/selectors';
import { handleAddProject } from './actions';
import ListTeamRow from './ListTeamRow';

const mapStateToProps = (state, { teamId }) => {
  const { name } = teamEntitySelector(state, { teamId }) || {};

  return {
    name,
  };
};

export const mapDispatchToProps = {
  handleAddProject,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListTeamRow);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
