import React from 'react';
import { MenuButton, MenuRadio, Select } from '@frameio/vapor';
import { FIXED_PLAYBACK_RATES } from '../CustomPlayback/utils';

const TEXT = {
  labelPlaybackSpeed: 'Playback Speed',
  playbackSpeed: (playbackRate) => `Playback Speed: ${playbackRate}x`,
};

export const PlaybackPicker = ({ customPlayback, setIsPopoverShowing }) => {
  const {
    defaultPlaybackRate,
    effectivePlaybackRate,
    onDefaultPlaybackRateChange,
  } = customPlayback;

  const handleRateChange = (value) => () => {
    onDefaultPlaybackRateChange(value);
  };

  const hasNonDefaultRate =
    defaultPlaybackRate !== effectivePlaybackRate &&
    isFinite(effectivePlaybackRate);

  const playbackRate = hasNonDefaultRate
    ? effectivePlaybackRate
    : defaultPlaybackRate;

  const hasNonFixedRate =
    playbackRate && !FIXED_PLAYBACK_RATES.includes(playbackRate);

  const playbackRates = hasNonFixedRate
    ? [...FIXED_PLAYBACK_RATES, playbackRate]
    : FIXED_PLAYBACK_RATES;

  const content = playbackRates.map((rate) => (
    <MenuRadio
      key={`playback-${rate}`}
      checked={rate === playbackRate}
      name={rate}
      onSelect={handleRateChange(rate)}
    >
      {rate}
    </MenuRadio>
  ));

  return (
    <Select
      content={content}
      defaultValue={playbackRate}
      label={TEXT.labelPlaybackSpeed}
      onRequestChange={setIsPopoverShowing}
      placement="bottom"
      shouldUsePortal
    >
      {({ bind: { onClick, ref, ...rest } }) => (
        <MenuButton
          {...rest}
          shouldCloseMenuOnSelect={false}
          onSelect={onClick}
        >
          <div ref={ref}>{TEXT.playbackSpeed(playbackRate)}</div>
        </MenuButton>
      )}
    </Select>
  );
};
