import {
  isAudio,
  isImage,
  isPDF,
  isStream,
} from '@frameio/core/src/assets/helpers/mediaTypes';

export function canAnnotateComment(asset) {
  return (
    asset &&
    (isStream(asset) || isImage(asset) || isPDF(asset)) &&
    !isAudio(asset)
  );
}

export function canTimestampComment(asset) {
  return asset && isStream(asset);
}

export function canAnnotateAspectRatio(asset) {
  return asset && isImage(asset);
}
