import { LINK_ACCESS_OPTIONS } from 'components/LinkAccess';

export default function addInviteOnlyAccessControl(
  currentMode,
  accessControl = null
) {
  switch (currentMode) {
    case LINK_ACCESS_OPTIONS.PUBLIC:
      return { ...accessControl, invite_only: false };
    case LINK_ACCESS_OPTIONS.PRIVATE:
      return { ...accessControl, invite_only: true };
    default:
      return accessControl;
  }
}
