import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { get, identity, isEmpty, isEqual } from 'lodash';
import moment from 'moment-timezone';
import { patchProjectDevice } from '@frameio/core/src/projectDevices/actions';
import { projectDeviceEntitySelector } from '@frameio/core/src/projectDevices/selectors';
import {
  listProjectDevicesForProject,
  listProjectDeviceChannelsForProjectDevice,
  getProjectDevice as getProjectDeviceCoreSaga,
  updateProjectDevice as updateProjectDeviceCoreSaga,
  updateProjectDeviceChannel as updateProjectDeviceChannelCoreSaga,
  targetProjectDevices as targetProjectDevicesCoreSaga,
  updateDeviceChannelInput as updateDeviceChannelInputCoreSaga,
  deleteProjectDevice as deleteProjectDeviceCoreSaga,
  deleteProjectDevicesForProject,
  pauseProjectDevice,
  pauseProjectDevicesForProject,
  resumeProjectDevice,
  resumeProjectDevicesForProject,
} from '@frameio/core/src/projectDevices/sagas';
import {
  createDelegatedSaga,
  createPaginatedListSaga,
} from '@frameio/core/src/shared/sagas/helpers';

import { dialogTypes } from '@frameio/components/src/styled-components/Dialog';

import { showErrorToast } from 'actions/toasts';
import { confirmDelete, prompt } from 'components/Dialog/SimpleDialog/sagas';

import {
  PROJECT_DEVICES,
  PROJECT_DEVICE_CHANNELS,
  DEVICE_CHANNEL_INPUTS,
  deleteProjectDevice as deleteProjectDeviceAction,
  deleteAllProjectDevices as deleteAllProjectDevicesAction,
  failureDeleteProjectDevice as failureDeleteProjectDeviceAction,
  failureDeleteAllProjectDevices as failureDeleteAllProjectDevicesAction,
  targetProjectDevices as targetProjectDevicesAction,
  updateProjectDevice as updateProjectDeviceAction,
  updateProjectDeviceChannel as updateProjectDeviceChannelAction,
  updateDeviceChannelInput as updateDeviceChannelInputAction,
} from './actions';
import { idsSelector, shouldFetchSelector } from './selectors';

const DELETE_DIALOG_HEADER = 'Delete Connection?';
const DELETE_DIALOG_BODY = 'This Connection will be unable to upload media.';
const DELETE_ALL_DIALOG_HEADER = 'Delete all Connections?';
const DELETE_ALL_DEVICE_DIALOG_BODY =
  'All Connections will be unable to upload media in this project.';
const RENAME_HEADER = 'Rename Connection';

/**
 * When un-pausing a device(s), the status goes from paused to online or
 * offline. The status is based on whether the device has been in contact with
 * the backend within the last 15 minutes. This is a utility function to use for
 * optimistic state updates in the UI and predict the backend response.
 */
const getPredictedStatus = (lastSeenAt) => {
  const threshold = moment(Date.now()).utc() - 15 * 60000;
  return moment(new Date(lastSeenAt)).utc() > threshold ? 'online' : 'offline';
};

/**
 * When using this utility or changing an implementation, ensure that the action
 * does not create a nonsensical string.
 */
const getFailureHeader = (action, plural = false) => {
  const adjective = plural ? 'all' : 'the';
  const noun = `Connection${plural ? 's' : ''}`;
  return `An error occurred while ${action} ${adjective} ${noun}`;
};

function* confirmDeleteAllProjectDevices({ payload: { projectId } }) {
  const isConfirmed = yield call(
    confirmDelete,
    DELETE_ALL_DIALOG_HEADER,
    DELETE_ALL_DEVICE_DIALOG_BODY
  );

  if (!isConfirmed) {
    return;
  }

  // Get all the Ids in case of failure.
  const projectDeviceIds = yield select(idsSelector);

  // Optimistically update the UI.
  yield put(deleteAllProjectDevicesAction(projectId));

  // Dispatch the actual deletion call
  const { failure } = yield call(deleteProjectDevicesForProject, projectId);

  if (failure) {
    yield put(failureDeleteAllProjectDevicesAction(projectDeviceIds));
  }
}

function* confirmDeleteProjectDevice({ payload: { id } }) {
  const isConfirmed = yield call(
    confirmDelete,
    DELETE_DIALOG_HEADER,
    DELETE_DIALOG_BODY
  );

  if (!isConfirmed) {
    return;
  }

  const { device_id: deviceId, project_id: projectId } = yield select(
    projectDeviceEntitySelector,
    { projectDeviceId: id }
  );

  yield put(deleteProjectDeviceAction(id));

  const { failure } = yield call(
    deleteProjectDeviceCoreSaga,
    deviceId,
    projectId
  );

  if (failure) {
    yield put(failureDeleteProjectDeviceAction(id));
  }
}

function* failureDeleteProjectDevice() {
  yield put(
    showErrorToast({
      header: getFailureHeader('deleting'),
    })
  );
}

function* failureDeleteAllProjectDevices() {
  yield put(
    showErrorToast({
      header: getFailureHeader('deleting', true),
    })
  );
}

const fetchPaginatedProjectDevices = createPaginatedListSaga(
  PROJECT_DEVICES.FETCH,
  listProjectDevicesForProject
);

function* fetchProjectDevices({ payload: { projectId, page, force = false } }) {
  const shouldFetch = yield select(shouldFetchSelector, { page });

  if (!force && !shouldFetch) {
    return;
  }

  yield call(fetchPaginatedProjectDevices, projectId, {
    page,
    pageSize: 50,
  });
}

function* fetchProjectDeviceChannels({ payload: { projectDeviceId } }) {
  yield call(listProjectDeviceChannelsForProjectDevice, projectDeviceId);
}

function* rename({ payload: { id } }) {
  const { name } = yield select(projectDeviceEntitySelector, {
    projectDeviceId: id,
  });

  const newName = yield call(prompt, RENAME_HEADER, identity, name, {
    type: dialogTypes.NONE,
  });

  if (newName) {
    yield put(updateProjectDeviceAction(id, { name: newName }));
  }
}

const getProjectDeviceDelegate = createDelegatedSaga(
  PROJECT_DEVICES.GET,
  getProjectDeviceCoreSaga
);

function* getProjectDevice({ payload: { deviceId, projectId } }) {
  // Dispatch the BE pause/resume request
  const { failure } = yield call(getProjectDeviceDelegate, deviceId, projectId);

  if (failure) {
    yield put(
      showErrorToast({
        header: getFailureHeader('retrieving'),
      })
    );
  }
}

function* togglePause({ payload: { id, isPausing } }) {
  const projectDevice = yield select(projectDeviceEntitySelector, {
    projectDeviceId: id,
  });
  const { device_id: deviceId, project_id: projectId } = projectDevice;
  const status = isPausing
    ? 'paused'
    : getPredictedStatus(projectDevice.last_seen_at);

  // Optimistally update the project device
  yield put(patchProjectDevice({ ...projectDevice, status }));

  // Dispatch the BE project device pause/resume request
  const { failure } = isPausing
    ? yield call(pauseProjectDevice, deviceId, projectId)
    : yield call(resumeProjectDevice, deviceId, projectId);

  if (failure) {
    // revert the active state and display an error in failure cases
    yield put(patchProjectDevice(projectDevice));
    yield put(
      showErrorToast({
        header: getFailureHeader(isPausing ? 'pausing' : 'resuming'),
      })
    );
  }
}

function* togglePauseAll({ payload: { projectId, isPausing } }) {
  // Dispatch the BE pause/resume request
  const { failure } = isPausing
    ? yield call(pauseProjectDevicesForProject, projectId)
    : yield call(resumeProjectDevicesForProject, projectId);

  if (failure) {
    yield put(
      showErrorToast({
        header: getFailureHeader(isPausing ? 'pausing' : 'resuming', true),
      })
    );
  }
}

function* updateProjectDevice({ payload: { id, newParams } }) {
  const projectDevice = yield select(projectDeviceEntitySelector, {
    projectDeviceId: id,
  });
  const { device_id: deviceId, project_id: projectId } = projectDevice;

  // Optimistically update the cloud device name
  yield put(patchProjectDevice({ ...projectDevice, ...newParams }));

  // Dispatch the remote request
  const { failure } = yield call(
    updateProjectDeviceCoreSaga,
    deviceId,
    projectId,
    newParams
  );

  if (failure) {
    yield put(patchProjectDevice(projectDevice));
    yield put(
      showErrorToast({
        header: getFailureHeader('updating'),
      })
    );
  }
}

function* updateProjectDeviceChannel({
  payload: { projectDeviceId, projectDeviceChannelId, newParams },
}) {
  yield call(
    updateProjectDeviceChannelCoreSaga,
    projectDeviceId,
    projectDeviceChannelId,
    newParams
  );
}

const sanitizeEventType = (params) => {
  if (params.event_type === 'none') return { ...params, event_type: null };
  return params;
};

function* targetProjectDevices({
  payload: { projectDeviceId, projectDeviceChannelId, projectDeviceIds },
}) {
  yield call(
    targetProjectDevicesCoreSaga,
    projectDeviceId,
    projectDeviceChannelId,
    projectDeviceIds
  );
}

function* updateDeviceChannelInput({
  payload: { projectDeviceId, deviceChannelInputId, newParams },
}) {
  yield call(
    updateDeviceChannelInputCoreSaga,
    projectDeviceId,
    deviceChannelInputId,
    sanitizeEventType(newParams)
  );
}

function getChanges(prev, next) {
  return Object.keys(next).reduce((acc, key) => {
    if (typeof next[key] === 'object') return acc;
    if (isEqual(get(next, key), get(prev, key))) return acc;
    if (next[key] === '' && prev[key] === null) return acc;
    acc[key] = next[key];
    return acc;
  }, {});
}

function* submitEditInputLogging({
  payload: { projectDeviceId, originalChannels, updatedChannels },
}) {
  for (let i = 0; i < updatedChannels.length; i += 1) {
    const updatedChannel = updatedChannels[i];
    const originalChannel = originalChannels.find(
      (channel) => channel.id === updatedChannel.id
    );
    const channelChanges = getChanges(originalChannel, updatedChannel);
    if (!isEmpty(channelChanges)) {
      yield put(
        updateProjectDeviceChannelAction(
          projectDeviceId,
          updatedChannel.id,
          channelChanges
        )
      );
    }
    const updatedInputs = updatedChannel.device_channel_inputs;
    for (let j = 0; j < updatedInputs.length; j += 1) {
      const updatedInput = updatedInputs[j];
      const originalInput = originalChannel.device_channel_inputs.find(
        (input) => input.id === updatedInput.id
      );
      const inputChanges = getChanges(originalInput, updatedInput);
      if (!isEmpty(inputChanges)) {
        yield put(
          updateDeviceChannelInputAction(
            projectDeviceId,
            updatedInput.id,
            inputChanges
          )
        );
      }
    }

    const updatedTargets = updatedChannel.targets.map((target) => target.id);

    yield put(
      targetProjectDevicesAction(
        projectDeviceId,
        updatedChannel.id,
        updatedTargets
      )
    );
  }
}

export default [
  takeEvery(PROJECT_DEVICES.CONFIRM_DELETE_ALL, confirmDeleteAllProjectDevices),
  takeEvery(PROJECT_DEVICES.CONFIRM_DELETE, confirmDeleteProjectDevice),
  takeEvery(PROJECT_DEVICES.DELETE.FAILURE, failureDeleteProjectDevice),
  takeEvery(PROJECT_DEVICES.DELETE_ALL.FAILURE, failureDeleteAllProjectDevices),
  takeEvery(PROJECT_DEVICES.GET.BASE, getProjectDevice),
  takeLatest(PROJECT_DEVICES.FETCH.BASE, fetchProjectDevices),
  takeLatest(PROJECT_DEVICES.RENAME, rename),
  takeEvery(PROJECT_DEVICES.TOGGLE_PAUSE, togglePause),
  takeEvery(PROJECT_DEVICES.TOGGLE_PAUSE_ALL, togglePauseAll),
  takeEvery(PROJECT_DEVICES.UPDATE_SETTINGS, updateProjectDevice),
  takeLatest(PROJECT_DEVICE_CHANNELS.FETCH.BASE, fetchProjectDeviceChannels),
  takeEvery(PROJECT_DEVICE_CHANNELS.UPDATE, updateProjectDeviceChannel),
  takeLatest(PROJECT_DEVICE_CHANNELS.SUBMIT, submitEditInputLogging),
  takeEvery(
    PROJECT_DEVICE_CHANNELS.TARGET_PROJECT_DEVICES,
    targetProjectDevices
  ),
  takeEvery(DEVICE_CHANNEL_INPUTS.UPDATE, updateDeviceChannelInput),
];

export const testExports = {
  DELETE_DIALOG_HEADER,
  DELETE_DIALOG_BODY,
  DELETE_ALL_DIALOG_HEADER,
  DELETE_ALL_DEVICE_DIALOG_BODY,
  RENAME_HEADER,
  getFailureHeader,
  getPredictedStatus,
  confirmDeleteAllProjectDevices,
  confirmDeleteProjectDevice,
  failureDeleteProjectDevice,
  fetchPaginatedProjectDevices,
  fetchProjectDevices,
  fetchProjectDeviceChannels,
  rename,
  togglePause,
  updateProjectDevice,
  submitEditInputLogging,
  updateProjectDeviceChannel,
  targetProjectDevices,
  updateDeviceChannelInput,
};
