import PropTypes from 'prop-types';

const SORT_OPTIONS = {
  RELEVANCE: {
    value: 'score',
    label: 'Relevance',
    sortDescendingByDefault: true,
  },
  RECENCY: {
    value: 'inserted_at',
    canSortDescending: true,
    sortDescendingByDefault: true,
    label: 'Date uploaded',
    asc: 'Oldest first',
    desc: 'Newest first',
  },
  NAME: {
    value: 'name',
    canSortDescending: true,
    label: 'Name',
    sortDescendingByDefault: false,
  },
  SIZE: {
    value: 'filesize',
    canSortDescending: true,
    label: 'Size',
    asc: 'Smallest first',
    desc: 'Largest first',
    sortDescendingByDefault: false,
  },
  UPLOADER: {
    value: 'creator.name',
    canSortDescending: true,
    label: 'Uploader',
    sortDescendingByDefault: false,
  },
};

export const propType = PropTypes.oneOf(
  Object.values(SORT_OPTIONS).map((option) => option.value)
);

export default SORT_OPTIONS;
