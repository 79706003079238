import React from 'react';
import PropTypes from 'prop-types';
import { uniq, isEqual, pick } from 'lodash';
import { shade } from 'polished';
import styled, { css } from 'styled-components';
import Flex from 'styled-flex-component';
import transitionFactory, { opacity } from 'react-transition-factory';
import { FadeTransition } from 'react-transition-components';

import CrossFadeTransitionGroup from '@frameio/components/src/styled-components/CrossFadeTransitionGroup';
import { spacing } from '@frameio/components/src/theme/darkTheme';
import { alignCenter } from '@frameio/components/src/mixins';

import FileAssetThumb from 'components/FileCard/FileAssetThumb';

import DropZoneIndicator, {
  DROP_ZONE_INDICATOR_ASPECT_RATIO,
  DROP_PRIMARY_SCALE_EASING,
  DROP_ZONE_TIMEOUT,
  DROP_ZONE_DELAY,
} from './DropZoneIndicator';
import EmptyDropZoneIndicator, {
  DROP_ZONE_EASING,
} from './EmptyDropZoneIndicator';
import PrimaryToSecondaryThumb from './PrimaryToSecondaryThumb';

export const THUMB_SPACING = spacing.micro;
export const NUM_SLOTS = 3;
export const DROP_PRIMARY_TIMEOUT = 600;
export const DROP_PRIMARY_POSITION_EASING = 'cubic-bezier(0.23, 0.02, 0, 1)';
export const PRIMARY_THUMB_FLEX_GROW = 2;

const ADDITIONAL_COUNT_FONT_SIZE = '16px';
const ADDITIONAL_COUNT_PLUS_OFFSET = '-10px';
const DROP_SECONDARY_TIMEOUT = 530;
const DROP_SECONDARY_EASING = 'cubic-bezier(0.17, 0.43, 0, 1)';
const FlexTransition = transitionFactory('flex');
const PrimaryHidingTransition = transitionFactory(opacity, 'flex', 'max-width');
const LastSlotDroppingTransition = transitionFactory(
  'transform',
  'opacity',
  'flex',
  'margin-top'
);

const roundedRectangle = css`
  border-radius: ${(p) => p.theme.radius.medium};
  overflow: hidden;
`;

export const FolderAssetThumb = styled(FileAssetThumb)`
  ${roundedRectangle};
  background-size: ${({ backgroundSize }) =>
    backgroundSize && `${backgroundSize} !important`};
  transition-property: background-position, background-size;
  transition-delay: ${({ offsetX }) => `${offsetX ? DROP_ZONE_DELAY : 0}ms`};
  transition-duration: ${DROP_ZONE_TIMEOUT - DROP_ZONE_DELAY}ms;
  transition-timing-function: ${DROP_ZONE_EASING};

  ${({ offsetX }) =>
    offsetX &&
    `background-position: calc((100% - ${offsetX}px) / 2) 50% !important;`}
`;

const AdditionalCount = styled.div`
  ${alignCenter()};
  ${roundedRectangle};
  background-color: ${({ theme }) => shade(0.85, theme.color.purpleBlack)};
  color: ${(p) => p.theme.color.coldWhite};
  height: 100%;
  width: 100%;

  span {
    position: relative;
    font-size: ${ADDITIONAL_COUNT_FONT_SIZE};

    &:before {
      content: '+';
      position: absolute;
      color: ${(p) => p.theme.color.graphiteGray};
      left: ${ADDITIONAL_COUNT_PLUS_OFFSET};
    }
  }
`;

export const PrimaryThumbContainer = styled.div`
  ${roundedRectangle};
  background: ${(p) => p.theme.color.coolBlack};
`;

export const SecondaryThumbGroup = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: column;

  > ${CrossFadeTransitionGroup} {
    flex: 1;
  }

  > div:not(:first-child) {
    margin-top: ${THUMB_SPACING};
  }
`;

export const FolderThumbWrapper = styled(Flex)`
  height: ${({ height }) =>
    typeof height === 'number' ? `${height}px` : height};
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  position: relative;
  overflow: hidden;
  border-radius: 4px;

  ${SecondaryThumbGroup} {
    flex: 1 0 0;
    height: 100%;
  }

  > div:nth-child(2) {
    margin-left: ${THUMB_SPACING};
  }
`;

class FolderThumb extends React.Component {
  shouldComponentUpdate(nextProps) {
    const propsToCompare = [
      'isDropZone',
      'height',
      'width',
      'isDropping',
      'isDropComplete',
    ];

    const folderPropsToCompare = ['children', 'item_count', 'usage'];

    return (
      !isEqual(
        pick(this.props, propsToCompare),
        pick(nextProps, propsToCompare)
      ) ||
      !isEqual(
        pick(this.props.asset, folderPropsToCompare),
        pick(nextProps.asset, folderPropsToCompare)
      ) ||
      this.props.droppedAssetIds.length !== nextProps.droppedAssetIds.length
    );
  }

  render() {
    const {
      droppedAssetIds,
      asset,
      height,
      isDropComplete,
      isDropping,
      isDropZone,
      onDropComplete,
      width,
    } = this.props;

    const { children, item_count: itemCount } = asset;

    // assetsToMove is in order of pick up: [first picked up (bottom), last picked up (top)]
    // so let's create a reversed copy to render the eventual thumblist.
    const assetIdsToMove = [...droppedAssetIds].reverse();

    if (!itemCount) {
      return (
        <FolderThumbWrapper height={height} width={width}>
          <EmptyDropZoneIndicator
            droppedAssetIds={assetIdsToMove}
            height={height}
            isDropZone={isDropZone}
            isDropping={isDropping}
            isDropComplete={isDropComplete}
            onDropComplete={onDropComplete}
            width={width}
          />
        </FolderThumbWrapper>
      );
    }

    // CORE-1786: In the event that children data is unavailable for a given
    // folder asset, ensure that a FolderCard can still be successfully
    // rendered by intializing the minimum required variables to render an
    // `AdditionalCount` thumb indicating the item_count for the Folder.
    let primaryThumbAssetId = '';
    let remainingSlots = NUM_SLOTS;
    let secondaryThumbs = [];
    let visibleCount = 0;

    const dropIndicatorWidth = DROP_ZONE_INDICATOR_ASPECT_RATIO * height;

    if (children) {
      const thumbList = isDropComplete
        ? uniq([assetIdsToMove, ...children])
        : children;

      primaryThumbAssetId = thumbList[0];
      remainingSlots = NUM_SLOTS - Number(!!primaryThumbAssetId);
      secondaryThumbs = thumbList
        .filter((child) => child !== primaryThumbAssetId)
        .slice(0, NUM_SLOTS - 1)
        .map((childId) => (
          <FolderAssetThumb
            key={childId}
            assetId={childId}
            height="100%"
            width="100%"
          />
        ));

      remainingSlots -= secondaryThumbs.length;
      visibleCount = Number(!!primaryThumbAssetId) + secondaryThumbs.length;
    }

    let additionalCount =
      itemCount + (isDropComplete ? assetIdsToMove.length : 0) - visibleCount;

    if (additionalCount > 0) {
      if (remainingSlots === 0) {
        secondaryThumbs.splice(-1);
        additionalCount += 1;
        remainingSlots += 1;
      }

      secondaryThumbs.push(
        <AdditionalCount key="additional-count">
          <span>{additionalCount}</span>
        </AdditionalCount>
      );
      remainingSlots -= 1;
    }

    const hasSecondaryThumbs = secondaryThumbs.length > 0;

    // When there are no remaining slots, dropping any number of assets will necessitate pushing
    // the last item down and away, meaning the 2nd last one will be the new last slot.
    // If there are remaining slots, we can reuse the existing last item, cross fading its contents.
    const indexForNextLastSlot =
      remainingSlots === 0
        ? secondaryThumbs.length - 2
        : secondaryThumbs.length - 1;

    // Fill up the remaining slots first, and if there's an additionalCount, add that.
    // If there isnt, and there are more dropped assets than slots, we need to add 1 to the count
    // since we need to splice of the last thumb in order to create a space for the new count.
    // This is similar to the logic for additionalCount above, except we're just working with counts
    // rather than the full array of dropped assets.
    const nextAdditionalCount =
      // the `max` here does the work of splicing
      Math.max(0, assetIdsToMove.length - remainingSlots) +
      (additionalCount || remainingSlots < assetIdsToMove.length);

    // If we have no existing thumbs that will crossfade into the new count, we need to grow it
    // into existence.
    const shouldCreateNextAdditionalCount =
      indexForNextLastSlot < 0 && nextAdditionalCount > 0;

    // In most cases, the primary thumb moves into the first secondary slot. However, when there is
    // only one item and exactly enough slots to insert the dropped items, we can push the primary
    // item into the last slot.
    // We only need to consider this for > 1 assets being dropped since the first dropped
    // asset will always be taken care of by the DropZoneIndicator.
    const shouldPrimaryMoveToLast =
      remainingSlots === assetIdsToMove.length &&
      assetIdsToMove.length > 1 &&
      additionalCount === 0;

    if (!isDropComplete) {
      secondaryThumbs = secondaryThumbs.map((child, index) => {
        const shouldCrossFade =
          index === indexForNextLastSlot && nextAdditionalCount > 0;
        return (
          <CrossFadeTransitionGroup
            /* eslint-disable-next-line react/no-array-index-key */
            key={`secondary-slot-${index}`}
            in={isDropping && shouldCrossFade}
            timeout={DROP_SECONDARY_TIMEOUT}
            easing={DROP_SECONDARY_EASING}
            from={child}
            to={
              <AdditionalCount>
                <span>{nextAdditionalCount}</span>
              </AdditionalCount>
            }
          />
        );
      });

      if (remainingSlots === 0) {
        secondaryThumbs[secondaryThumbs.length - 1] = (
          <LastSlotDroppingTransition
            key="dropping-secondary-slot"
            in={isDropping}
            start={['translateY(0)', 1, 1, THUMB_SPACING]}
            end={['translateY(100%)', 0, 0, 0]}
            mountOnEnter={false}
            unmountOnExit={false}
            exit={!isDropComplete}
            timeout={DROP_SECONDARY_TIMEOUT}
            easing={DROP_SECONDARY_EASING}
          >
            {secondaryThumbs[secondaryThumbs.length - 1]}
          </LastSlotDroppingTransition>
        );
      }
    }

    return (
      <FolderThumbWrapper height={height} width={width}>
        <DropZoneIndicator
          height={height}
          width={dropIndicatorWidth}
          isDropZone={isDropZone}
          isDropping={isDropping}
          isDropComplete={isDropComplete}
          droppedAssetId={assetIdsToMove[0]}
          onDropComplete={onDropComplete}
        >
          {primaryThumbAssetId && (
            <PrimaryHidingTransition
              in={isDropping}
              mountOnEnter={false}
              unmountOnExit={false}
              start={[1, PRIMARY_THUMB_FLEX_GROW, '100%']}
              end={[0, 0, 0]}
              timeout={[
                0,
                hasSecondaryThumbs ? DROP_PRIMARY_TIMEOUT : 0,
                DROP_PRIMARY_TIMEOUT,
              ]}
              easing={DROP_PRIMARY_SCALE_EASING}
              style={{ overflow: 'hidden' }}
            >
              <PrimaryThumbContainer>
                <FolderAssetThumb
                  assetId={
                    isDropComplete ? assetIdsToMove[0] : primaryThumbAssetId
                  }
                  height="100%"
                  width="100%"
                  offsetX={
                    isDropZone
                      ? dropIndicatorWidth * (hasSecondaryThumbs ? 1 : 0.5)
                      : 0
                  }
                  backgroundSize={
                    !hasSecondaryThumbs &&
                    (isDropZone
                      ? `calc(100% + ${dropIndicatorWidth}px) auto`
                      : '100% auto')
                  }
                />
              </PrimaryThumbContainer>
            </PrimaryHidingTransition>
          )}
          {(hasSecondaryThumbs || isDropping) && (
            <SecondaryThumbGroup>
              {isDropping && (
                <React.Fragment>
                  {primaryThumbAssetId && !shouldPrimaryMoveToLast && (
                    <PrimaryToSecondaryThumb
                      key="primary-clone"
                      assetId={primaryThumbAssetId}
                      assetIdsToMove={assetIdsToMove}
                      hasSecondaryThumbs={hasSecondaryThumbs}
                      remainingSlots={remainingSlots}
                      thumbOffsetX={
                        isDropZone && hasSecondaryThumbs
                          ? dropIndicatorWidth
                          : 0
                      }
                    />
                  )}
                  {/*
                    If we have remaining slots, that means the static display did not have render
                    any thumbs to morph from, so we need to grow them out of thin air here.
                    We slice from 1 because the first dropped asset will be taken care of by
                    DropZoneIndicator already.
                  */}
                  {assetIdsToMove
                    .slice(
                      1,
                      Math.max(
                        0,
                        remainingSlots - shouldCreateNextAdditionalCount
                      )
                    )
                    .map((assetIdToMove, i) => (
                      <FlexTransition
                        start="0 0 0"
                        end="1 0 0"
                        timeout={DROP_PRIMARY_TIMEOUT}
                        easing={DROP_PRIMARY_SCALE_EASING}
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={`new-slot-${i}`}
                      >
                        <div>
                          <FadeTransition
                            timeout={DROP_PRIMARY_TIMEOUT}
                            easing={DROP_PRIMARY_SCALE_EASING}
                          >
                            <FolderAssetThumb
                              assetId={assetIdToMove}
                              height="100%"
                              width="100%"
                            />
                          </FadeTransition>
                        </div>
                      </FlexTransition>
                    ))}
                  {shouldCreateNextAdditionalCount && (
                    <LastSlotDroppingTransition
                      start={[null, 0, 0, 0]}
                      end={[null, 1, 1, THUMB_SPACING]}
                      timeout={DROP_PRIMARY_TIMEOUT}
                      easing={DROP_PRIMARY_SCALE_EASING}
                      key="new-count"
                    >
                      <AdditionalCount>
                        <span>{nextAdditionalCount}</span>
                      </AdditionalCount>
                    </LastSlotDroppingTransition>
                  )}
                </React.Fragment>
              )}
              {secondaryThumbs}
              {isDropping && primaryThumbAssetId && shouldPrimaryMoveToLast && (
                <PrimaryToSecondaryThumb
                  key="primary-clone"
                  assetId={primaryThumbAssetId}
                  assetIdsToMove={assetIdsToMove}
                  hasSecondaryThumbs={hasSecondaryThumbs}
                  isMovingToLastSlot
                  remainingSlots={remainingSlots}
                  thumbOffsetX={isDropZone ? dropIndicatorWidth : 0}
                />
              )}
            </SecondaryThumbGroup>
          )}
        </DropZoneIndicator>
      </FolderThumbWrapper>
    );
  }
}

FolderThumb.propTypes = {
  droppedAssetIds: PropTypes.arrayOf(PropTypes.string),
  asset: PropTypes.shape({
    item_count: PropTypes.number,
    children: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  height: PropTypes.number,
  isDropComplete: PropTypes.bool,
  isDropping: PropTypes.bool,
  isDropZone: PropTypes.bool,
  onDropComplete: PropTypes.func,
  width: PropTypes.number,
};

FolderThumb.defaultProps = {
  droppedAssetIds: [],
  height: 0,
  width: 0,
  isDropping: false,
  isDropComplete: false,
  isDropZone: false,
  onDropComplete: () => {},
};

export default styled(FolderThumb)``;

export const testExports = {
  FolderThumb,
  AdditionalCount,
  FlexTransition,
  LastSlotDroppingTransition,
  PRIMARY_THUMB_FLEX_GROW,
  PrimaryHidingTransition,
  SecondaryThumbGroup,
};
