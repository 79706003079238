import styled from 'styled-components';
import { FlexItem } from 'styled-flex-component';

const Cell = styled(FlexItem).attrs(() => ({
  role: 'gridcell',
}))`
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  color: ${(p) => p.theme.color.slateGray};
`;

export default Cell;
