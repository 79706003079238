import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';
import { useReducer } from 'react';
import { defaultTaskOptions } from './helper';

export const initialState = {
  isUpdateTaskSelected: false,
  isAllTasksSelected: false,
  hasPermissionToUpdate: false,
  taskOptions: defaultTaskOptions,
};

const MODAL = generateActionTypes('MODAL', [
  'RESET',
  'SET_ALL_TASKS_SELECTED',
  'SET_UPDATE_TASK_SELECTED',
]);

const updateTask = createAction(
  MODAL.SET_UPDATE_TASK_SELECTED,
  (payload) => payload
);

const allTasksSelected = createAction(
  MODAL.SET_ALL_TASKS_SELECTED,
  (payload) => payload
);

const resetModal = createAction(MODAL.RESET, () => ({}));

const taskSelectionReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case MODAL.SET_UPDATE_TASK_SELECTED: {
      const {
        hasPermissionToUpdate,
        isUpdateTaskSelected,
        taskOptions,
      } = payload;
      return {
        ...state,
        hasPermissionToUpdate,
        isUpdateTaskSelected,
        taskOptions,
      };
    }
    case MODAL.SET_ALL_TASKS_SELECTED: {
      return { ...state, isAllTasksSelected: payload };
    }
    case MODAL.RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

const useTaskSelection = () => {
  const [state, dispatch] = useReducer(taskSelectionReducer, initialState);

  const setResetModal = () => dispatch(resetModal());

  const setAllTasksSelected = (payload) => {
    dispatch(allTasksSelected(payload));
  };

  const setUpdateTaskSelected = (payload) => {
    dispatch(updateTask(payload));
  };

  return [state, { setResetModal, setAllTasksSelected, setUpdateTaskSelected }];
};

export default useTaskSelection;
