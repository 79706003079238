import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import queryString from 'query-string';
import CreditCardIcon from '@frameio/components/src/svgs/icons/credit-card.svg';
import InfoIcon from '@frameio/components/src/svgs/icons/info.svg';
import { Spinner } from '@frameio/components';
import sizes from '@frameio/components/src/utils/sizes';
import { SPINNER_COLORS } from '@frameio/components/src/utils/spinnerUtils';
import Button from '@frameio/components/src/styled-components/Button';
import track from 'analytics';
import { countryCodeMap } from 'components/CreditCardFormElements/helpers';
import EditInvoiceSettings from '../EditInvoiceSettings';
import BillingItemRow from '../BillingItemRow';
import AddCreditCardWithPlanSummary from '../AddCreditCardWithPlanSummary';
import AddCreditCard from '../AddCreditCard';

export const PAYMENT_METHODS = {
  STRIPE: 'stripe',
  CHECK: 'check',
};

const PaymentContainer = styled.div`
  margin: ${({ theme }) => `0 ${theme.spacing.large}`};
`;

const MiddleDotContainer = styled.p`
  margin: ${({ theme }) => `0 ${theme.spacing.tiny}`};
`;

class Payment extends Component {
  componentDidMount() {
    const {
      getSubscriptionByAccount,
      getCardsByAccount,
      getAccountWithVat,
      account,
    } = this.props;
    if (!account.vat) {
      // Make sure we have the account's vat number if it exists
      getAccountWithVat(account.id);
    }

    track('page-viewed-billing-payment-method');
    getSubscriptionByAccount(account.id);
    getCardsByAccount(account.id);

    if (this.shouldOpenCreditCardForm()) {
      this.openCreditCardForm();
    }
  }

  getSpinner = () => (
    <Flex center>
      <Spinner spinning size={sizes.S} color={SPINNER_COLORS.purple} />
    </Flex>
  );

  formatAddress = (address) => {
    let formattedAddress = '';
    if (address?.line1 && address?.line2) {
      formattedAddress += `${address?.line1} ${address?.line2},`;
    }
    if (address?.line1 && !address?.line2) {
      formattedAddress += `${address?.line1},`;
    }
    if (address?.city) {
      formattedAddress += ` ${address?.city},`;
    }
    if (address?.state) {
      formattedAddress += ` ${address?.state}`;
    }
    if (address?.postal_code) {
      formattedAddress += ` ${address?.postal_code},`;
    }
    if (address?.country) {
      // `address.country` stores the country code. `countryCodeMap` helps us
      // retrieve the country's name from the country code (e.g. CA => Canada).
      const formattedCountry = countryCodeMap[(address?.country)];
      formattedAddress += ` ${formattedCountry}`;
    }
    return formattedAddress;
  };

  getCreditCardSubheader = () => {
    const {
      account: { address },
      card: { exp_month: expMonth, exp_year: expYear, last4, brand },
      areCardsFetching,
      isTaxLocationDetermined,
    } = this.props;

    if (areCardsFetching) return this.getSpinner();

    const formattedAddress = this.formatAddress(address);

    if (last4 && brand) {
      return (
        <React.Fragment>
          <Flex spaceBetween>
            <p>
              {brand} ending in {last4}
            </p>
            <MiddleDotContainer>&#183;</MiddleDotContainer>
            <p>
              {expMonth}/{expYear}
            </p>
          </Flex>
          {isTaxLocationDetermined && <p>{formattedAddress}</p>}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <p>No card on file</p>
        <p>{formattedAddress}</p>
      </React.Fragment>
    );
  };

  getInvoiceSubheader = () => {
    const {
      account: { company_name: companyName },
    } = this.props;

    const name = companyName || 'Update your company name';
    return (
      <React.Fragment>
        <p>{name}</p>
      </React.Fragment>
    );
  };

  openCreditCardForm = () => {
    const AddCreditCardComponent = this.props.card.id
      ? AddCreditCard
      : AddCreditCardWithPlanSummary;
    this.props.openModal(<AddCreditCardComponent />, {
      canBackdropClose: false,
      maxHeight: 750,
    });
  };

  generatePaymentMethodSection = () => {
    const { paymentMethod } = this.props;
    if (paymentMethod === PAYMENT_METHODS.STRIPE) {
      return (
        <BillingItemRow
          header="Card Information"
          subheader={this.getCreditCardSubheader()}
          button={
            <Button text primary onClick={this.openCreditCardForm}>
              Update
            </Button>
          }
          Icon={CreditCardIcon}
          data-payment-row
        />
      );
    }

    return (
      <BillingItemRow
        header="Custom payment method"
        subheader={
          <span>
            <p>You have a custom payment method configured.</p>
            <p>Please reach out to finance@frame.io with any questions.</p>
          </span>
        }
        Icon={CreditCardIcon}
        data-payment-row
      />
    );
  };

  shouldOpenCreditCardForm = () => {
    const { search } = window.location;
    const { openCCform } = queryString.parse(search);

    return openCCform;
  };

  render() {
    const { openModal } = this.props;

    return (
      <PaymentContainer>
        {this.generatePaymentMethodSection()}
        <BillingItemRow
          header="Invoice Settings"
          subheader={this.getInvoiceSubheader()}
          button={
            <Button
              text
              primary
              onClick={() => openModal(<EditInvoiceSettings />)}
            >
              Edit invoice information
            </Button>
          }
          Icon={InfoIcon}
          data-invoice-row
        />
      </PaymentContainer>
    );
  }
}

Payment.defaultProps = {
  card: {},
};

Payment.propTypes = {
  paymentMethod: PropTypes.oneOf(Object.values(PAYMENT_METHODS)).isRequired,
  getSubscriptionByAccount: PropTypes.func.isRequired,
  getCardsByAccount: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  areCardsFetching: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  getAccountWithVat: PropTypes.func.isRequired,
  card: PropTypes.object,
};

export default Payment;
