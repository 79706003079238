import React, { Component } from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { DropTarget, DragSource } from 'react-dnd';
import UnversionSVG from '@frameio/components/src/svgs/icons/24/cancel.svg';
import { Tooltip } from '@frameio/vapor';
import { Flipped } from 'react-flip-toolkit';
import { types as dragAndDropTypes } from 'utils/dragAndDrop';
import { LARGE } from 'utils/mediaQueries';
import { noop, flow } from 'lodash';
import PropTypes from 'prop-types';
import DragSVG from '@frameio/components/src/svgs/icons/24/drag-handle.svg';
import Flex from 'styled-flex-component';
import styled from 'styled-components';
import { rgba } from 'polished';
import Badge from '@frameio/components/src/styled-components/Badge';
import Button from '@frameio/components/src/styled-components/Button';
import {
  TitleText,
  DetailsText,
} from '@frameio/components/src/styled-components/ListCell';
import Spinner from '@frameio/components/src/styled-components/SpinnerBase';
import { AssetWrapper } from './Asset';
import {
  mapDropStateToProps,
  dropTargetSpec,
  mapDragStateToProps,
  dragSourceSpec,
} from './dragAndDrop';

export const ROW_HEIGHT = 56;
const HOVER_TRANSITION_DURATION = 0.2; // seconds

const ButtonContainer = styled(Flex).attrs(() => ({
  alignCenter: true,
}))``;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  right: ${(p) => p.theme.spacing.units(1)};
  transform: translateY(-50%);
`;

export const VersionBadge = styled(Badge).attrs(() => ({
  inverted: true,
}))`
  /* These rules enforce consistent alignment and spacing of the
     text inside of VersionBadge across browsers. */
  display: inline-block;
  line-height: ${(p) => p.theme.spacing.medium};
`;

const VersionNumber = styled.span`
  color: ${(p) => p.theme.color.coolBlack};
`;

const getBrandColorWhenSelected = ({ isSelected, theme }) =>
  isSelected && theme.color.brand;

const FlipWrapper = styled.div`
  flex-grow: 1;
  min-width: 0;
  height: 100%;
  position: relative;
`;

export const VersionWrapper = styled(Flex).attrs(() => ({
  alignCenter: true,
}))`
  padding: 0 ${(p) => p.theme.spacing.small};
  height: ${ROW_HEIGHT}px;
  cursor: ${({ onClick }) => onClick && 'pointer'};

  ${Button} {
    color: ${(p) => p.theme.color.graphiteGray};
  }

  ${VersionBadge},
  ${VersionNumber},
  ${TitleText},
  ${DetailsText} {
    color: ${(p) =>
      p.isLoading ? p.theme.color.slateGray : getBrandColorWhenSelected};
  }

  ${VersionBadge} {
    margin-right: ${(p) => p.theme.spacing.small};
    width: ${(p) => p.theme.spacing.medium};
  }

  ${FlipWrapper} ${ButtonContainer} {
    right: -${(p) => p.theme.spacing.tiny};
  }

  ${ButtonContainer} {
    position: absolute;
    /* The <ButtonContainer />'s overlay should end one pixel below the top
       of it's parent element so that it doesn't visually cover the 1px
       dividing line above each <Version /> element */
    top: 1px;
    bottom: 0;
    padding: 0 ${(p) => p.theme.spacing.small};
    opacity: ${({ isDraggingInProgress }) => (isDraggingInProgress ? 0 : 1)};
    transition: background-color ${HOVER_TRANSITION_DURATION}s ease-in-out;
    background-image: linear-gradient(
      90deg,
      ${({ theme }) => rgba(theme.color.white, 0)},
      ${({ theme }) => rgba(theme.color.white, 1)} 10%,
      ${({ theme }) => rgba(theme.color.white, 1)} 100%
    )
  }

  ${AssetWrapper} {
    ${({ hasDivider, theme }) =>
      hasDivider &&
      `
      box-shadow: 0 1px 0 0 ${theme.color.accentGrayLight} inset;
    `}
  }

  @media ${LARGE} {
    ${ButtonContainer} {
      opacity: 0;
    }

    &:hover {
      /* Only add a background-color to the hover state when an item is clickable and no asset is being dragged */
      background-color: ${({ theme, onClick, isDraggingInProgress }) =>
        onClick && !isDraggingInProgress && theme.color.coldWhite};

      ${ButtonContainer} {
        transition: opacity ${HOVER_TRANSITION_DURATION}s ease-in-out;
        opacity: ${({ isDraggingInProgress, isLoading }) =>
          isDraggingInProgress || isLoading ? 0 : 1};
        background-image: linear-gradient(
          90deg,
          ${({ onClick, theme }) =>
            rgba(onClick ? theme.color.coldWhite : theme.color.white, 0)},
          ${({ onClick, theme }) =>
            rgba(onClick ? theme.color.coldWhite : theme.color.white, 1)} 20%,
          ${({ onClick, theme }) =>
            rgba(onClick ? theme.color.coldWhite : theme.color.white, 1)} 100%
        );
      }

      ${AssetWrapper} {
        /* When a user hovers over a <Version /> item with clickable behavior associated
          with it, the background color transitions and visually clashes with the horizontal
          divider placed between <Version />s. Therefore, don't hide the horizontal divider
          on <Version /> elements that have no clickable behavior */
        box-shadow: ${({ onClick }) => onClick && 'none'};
      }
    }

    ${FlipWrapper} ${ButtonContainer} {
      /* Compensate for VersionWrapper's spacing to force
        ButtonContainer to be flush to the edge */
      right: -${(p) => p.theme.spacing.small};
    }
  }
`;

class Version extends Component {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage());
  }

  render() {
    const {
      children,
      asset,
      versionNumber,
      isLoading,
      isSelected,
      onAssetDropped,
      isDraggingInProgress,
      isCurrentAssetDragging,
      connectDropTarget,
      connectDragSource,
      onRemove,
      onClick,
      index,
    } = this.props;

    return (
      <VersionWrapper
        hasDivider={index !== 0}
        onClick={onClick}
        isDraggingInProgress={isDraggingInProgress}
        isLoading={isLoading}
        ref={connectDropTarget}
        isSelected={isSelected}
      >
        <VersionBadge>
          v<VersionNumber>{versionNumber}</VersionNumber>
        </VersionBadge>
        <Flipped flipId={`version-id-${asset.id}`}>
          <FlipWrapper>
            {children(isCurrentAssetDragging)}
            <ButtonContainer>
              {onRemove && (
                <Tooltip
                  shouldUsePortal
                  variant="dark"
                  title="Remove from version stack"
                  disabled={isDraggingInProgress}
                >
                  <Button
                    text
                    icon="true"
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemove();
                    }}
                  >
                    <UnversionSVG />
                  </Button>
                </Tooltip>
              )}
              {/* CORE-1135 the tabIndex value below overrides the tabIndex applied by the <Modal />
                  component and allows the drag source below to become focusable in Safari. Defining
                  the button as an anchor tag prevents the component from breaking in Firefox, since
                  it disallows buttons from being draggable. */}
              {onAssetDropped && (
                <Button
                  onClick={(e) => e.stopPropagation()}
                  tabIndex={0}
                  as="a"
                  role="button"
                  ref={connectDragSource}
                  text
                  icon="true"
                >
                  <DragSVG />
                </Button>
              )}
            </ButtonContainer>
            {isLoading && (
              <StyledSpinner
                spinning
                radius={10}
                color="125,130,156"
                stroke={2}
              />
            )}
          </FlipWrapper>
        </Flipped>
      </VersionWrapper>
    );
  }
}

export default flow(
  React.memo,
  DropTarget(dragAndDropTypes.VERSIONS, dropTargetSpec, mapDropStateToProps),
  DragSource(dragAndDropTypes.VERSIONS, dragSourceSpec, mapDragStateToProps)
)(Version);

Version.defaultProps = {
  children: noop,
  onAssetDropped: noop,
  connectDropTarget: noop,
  isDraggingInProgress: false,
  isCurrentAssetDragging: false,
  onClick: null,
  connectDragSource: noop,
  connectDragPreview: noop,
  onRemove: noop,
  index: 0,
};

Version.propTypes = {
  asset: PropTypes.shape({ id: PropTypes.string }).isRequired,
  versionNumber: PropTypes.number.isRequired,
  connectDragSource: PropTypes.func,
  connectDragPreview: PropTypes.func,
  children: PropTypes.func,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onAssetDropped: PropTypes.func,
  isDraggingInProgress: PropTypes.bool,
  isCurrentAssetDragging: PropTypes.bool,
  connectDropTarget: PropTypes.func,
  onRemove: PropTypes.func,
  index: PropTypes.number,
};

export const testExports = {
  StyledSpinner,
  Version,
  VersionNumber,
};
