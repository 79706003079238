import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { iconSizes } from '@frameio/components/src/utils/sizes';
import { Wrapper } from '@frameio/components/src/styled-components/Avatar';

// TODO(Scott): https://frame-io.atlassian.net/browse/WK-149
// This component needs a rethink once we address the inherent duplication between Wrapper and
// IconCircle

const EmojiContainer = styled.span`
  font-size: ${(p) => p.theme.fontSize[3]};
  position: relative;
  top: 2px;
  left: 2px;
  cursor: default; // the emoji is text. don't show the edit text cursor
`;

const EmojiBadge = withTheme(({ emoji, theme, hideBackground }) => (
  <Wrapper
    size={iconSizes.XL}
    color={hideBackground ? theme.color.white : theme.color.coldWhite}
  >
    <EmojiContainer role="img" aria-label="group emoji">
      {emoji}
    </EmojiContainer>
  </Wrapper>
));

EmojiBadge.propTypes = {
  emoji: PropTypes.string.isRequired,
  hideBackground: PropTypes.bool,
};

EmojiBadge.defaultProps = {
  hideBackground: false,
};

export default EmojiBadge;
