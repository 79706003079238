import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { noop, clamp } from 'lodash';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { MEDIUM_UP } from 'utils/mediaQueries';
import { ENTER_DURATION } from '@frameio/components/src/components/Modal';
import Button from '@frameio/components/src/styled-components/Button';
import MessageIcon from '@frameio/components/src/svgs/icons/16/message.svg';
import TextArea from '@frameio/components/src/styled-components/TextArea';
import { Filter } from 'components/PeopleList';
import { GridCell } from 'components/InfiniteGrid';
import UserSearch from 'components/UserSearch';
import {
  searchUsersStrategy,
  searchGroupsStrategy,
  searchTeamsStrategy,
} from 'components/UserSearch/strategies';
import { TEAM_MEMBER_VS_COLLABORATOR_SUPPORT_URL } from 'URLs';
import ProjectInviteLink from './ProjectInviteLink';
import ManagePeopleList from './ManagePeopleList';
import ManagePerson from './ManagePeopleList/ManagePerson';
import {
  trackClick,
  event as trackingEvent,
  page as trackingPage,
  position as trackingPosition,
  title as trackingTitle,
} from './tracking';

const MAX_SINGLE_PAGE_SIZE = 50;

const Title = styled.h3`
  ${(p) => p.theme.fontStyle.heading};
  color: ${(p) => p.theme.color.coolBlack};
`;

const Container = styled.div`
  color: ${(p) => p.theme.color.gray};
  width: 343px;

  @media ${MEDIUM_UP} {
    width: 500px;
  }

  .manage-people {
    ${Filter} {
      padding: ${({ theme }) =>
        `0 ${theme.spacing.medium} ${theme.spacing.tiny}`};
    }

    ${GridCell} {
      padding: ${({ theme }) => `0 ${theme.spacing.medium}`};

      @media ${MEDIUM_UP} {
        padding: ${({ theme }) =>
          `0 ${theme.spacing.tiny} 0 ${theme.spacing.large}`};
      }
    }
  }
`;

const Header = styled(Flex)`
  padding: ${({ theme: { spacing } }) =>
    `${spacing.medium} ${spacing.medium} calc(2 * ${spacing.tiny})`};

  ${Title} {
    flex-shrink: 0;
    margin-bottom: ${(p) => p.theme.spacing.small};

    @media ${MEDIUM_UP} {
      margin-bottom: 0;
    }
  }
`;

const StyledMessageIcon = styled(MessageIcon).attrs(() => ({
  width: 16,
  height: 16,
}))`
  color: ${(p) => p.theme.color.graphiteGray};
  margin-right: ${(p) => p.theme.spacing.micro};
`;

const StyledTextArea = styled(TextArea)`
  resize: vertical;
  padding: ${({ theme: { spacing } }) =>
    `calc(${spacing.small}/2) ${spacing.tiny}`};
  line-height: 1.3;
  min-height: ${(p) => p.theme.spacing.large};
`;

const Footer = styled(Flex).attrs(() => ({
  alignStart: true,
}))`
  padding: ${({ theme: { spacing } }) =>
    `${spacing.tiny} ${spacing.medium} ${spacing.medium}`};
  justify-content: flex-end;

  @media ${MEDIUM_UP} {
    justify-content: space-between;
  }

  ${StyledTextArea} {
    margin-right: ${(p) => p.theme.spacing.tiny};
  }
`;

const SearchWrapper = styled.div`
  padding: ${({ theme: { spacing } }) => `0 ${spacing.medium} ${spacing.tiny}`};
`;

const LearnMoreLink = styled.a`
  cursor: pointer;
  font-size: ${(p) => p.theme.fontSize[0]};
  color: ${(p) => p.theme.color.brand};
`;

const SeatsPurchasedSubText = styled.span`
  font-size: ${(p) => p.theme.fontSize[0]};
  color: ${(p) => p.theme.color.gray};
`;

class ManagePeople extends React.Component {
  state = {
    tokens: [],
    inviteMessage: undefined,
    showMessageField: false,
    canRenderPeopleList: false,
  };

  componentDidMount() {
    const { getLineItemsForPlan, planId, resetResendInvites } = this.props;

    setTimeout(
      () => this.setState({ canRenderPeopleList: true }),
      ENTER_DURATION
    );
    resetResendInvites();
    getLineItemsForPlan(planId);
  }

  onInviteUsers = () => {
    const { tokens, inviteMessage } = this.state;
    if (tokens.length === 0 || this.shouldShowUserMaxLimitReached()) return;
    const { projectId, inviteUsersToProject } = this.props;
    inviteUsersToProject(projectId, tokens, inviteMessage);
  };

  shouldShowSeatsDisclaimer = () => {
    const {
      hasReachedUserCountLimit,
      isAutoscalingSeats,
      isOnFreePlan,
      isPaidCollaboratorsPlan,
      isAccountOnPlanWithUserMax,
    } = this.props;
    const { newUserTokenCount } = this.props;

    return (
      (hasReachedUserCountLimit || isAccountOnPlanWithUserMax) &&
      isAutoscalingSeats &&
      !isOnFreePlan &&
      isPaidCollaboratorsPlan &&
      !!newUserTokenCount &&
      this.hasReachedMinUserUsage() &&
      !this.shouldShowUserMaxLimitReached()
    );
  };

  // V5 and V6 plans' `user_limit` represents the minimum user count included in a given v5 plan. Before
  // showing any disclaimer, we need to make sure that the account reached its plan's minimum user
  // count.
  hasReachedMinUserUsage = () => {
    const {
      seatsToAddToReachMinUserLimit,
      isAccountOnPlanWithUserMax,
      isEnterprise,
    } = this.props;
    const { newUserTokenCount } = this.props;
    return isAccountOnPlanWithUserMax && !isEnterprise
      ? newUserTokenCount > seatsToAddToReachMinUserLimit
      : true;
  };

  // V5 and V6 plans have a `user_max` attribute. An account on a V5 or V6 plan cannot add more new users than
  // its `user_max` allows.
  shouldShowUserMaxLimitReached = () => {
    const { availableSeatCount, isAccountOnPlanWithUserMax } = this.props;
    const { newUserTokenCount } = this.props;

    const hasPlanWithUserMaxReachedUserLimit =
      newUserTokenCount && newUserTokenCount > availableSeatCount;

    return isAccountOnPlanWithUserMax
      ? !!hasPlanWithUserMaxReachedUserLimit
      : false;
  };

  renderSeatsDisclaimerCopy = () => {
    const { individualSeatCost, isYearlyPlan, onTrialUntil } = this.props;
    if (onTrialUntil) {
      return (
        <SeatsPurchasedSubText data-test-id="seats-purchased-subtext">
          New users will be added for{' '}
          <strong>
            ${individualSeatCost}/user/month (plus tax)
            {isYearlyPlan && ' billed annually'}{' '}
          </strong>
          but are free during your trial.
        </SeatsPurchasedSubText>
      );
    }

    return (
      <SeatsPurchasedSubText data-test-id="seats-purchased-subtext">
        New users will be added for{' '}
        <strong>
          ${individualSeatCost}/user/month (plus tax)
          {isYearlyPlan && ' billed annually'}.
        </strong>
      </SeatsPurchasedSubText>
    );
  };

  render() {
    const {
      accountId,
      canCustomizeEmailMessages,
      canInviteCollaborators,
      isInvitingUsers,
      openAndScrollTo,
      openSelectPlanFlowModal,
      projectId,
      setPeopleListScrollTop,
      totalCount,
      setReduxTokens,
    } = this.props;

    const { inviteMessage, showMessageField, canRenderPeopleList } = this.state;
    return (
      <Container>
        <Media data-test-id="header-media" query={MEDIUM_UP}>
          {(isMediumUp) => (
            <Header
              alignCenter={isMediumUp}
              justifyBetween
              column={!isMediumUp}
            >
              <Title>Project members</Title>
              {canInviteCollaborators && <ProjectInviteLink />}
            </Header>
          )}
        </Media>

        {canInviteCollaborators && (
          <React.Fragment>
            <SearchWrapper>
              <UserSearch
                strategies={[
                  searchUsersStrategy(accountId, projectId),
                  searchGroupsStrategy(accountId),
                  searchTeamsStrategy(accountId),
                ]}
                newUserPrompt={(user) => `Invite ${user} to this project`}
                placeholderText="Invite by email or search for name"
                setTokens={(tokens) => {
                  this.setState({ tokens });
                  // This adds the tokens to the redux store for a specific
                  // selector for BUGS-1965.
                  // This and the above local state are redundant, but
                  // leaving both in an effort to not change working v3
                  // functionality.
                  setReduxTokens(tokens);
                }}
                tokens={this.state.tokens}
              />
              {this.shouldShowSeatsDisclaimer() && (
                <React.Fragment>
                  {this.renderSeatsDisclaimerCopy()}{' '}
                  <LearnMoreLink
                    href={TEAM_MEMBER_VS_COLLABORATOR_SUPPORT_URL}
                    target="_blank"
                    onClick={() => trackClick('learn more')}
                  >
                    Learn more
                  </LearnMoreLink>
                </React.Fragment>
              )}
              {this.shouldShowUserMaxLimitReached() && (
                <React.Fragment>
                  <SeatsPurchasedSubText data-test-id="seats-purchased-subtext">
                    You’ve reached your plan’s user limit.{' '}
                    <LearnMoreLink
                      onClick={() => {
                        openSelectPlanFlowModal(accountId, {
                          source: 'add people modal',
                        });
                        trackClick('upgrade for more users');
                      }}
                    >
                      Upgrade for more users
                    </LearnMoreLink>
                  </SeatsPurchasedSubText>
                </React.Fragment>
              )}
            </SearchWrapper>

            <Footer>
              <Media query={MEDIUM_UP}>
                {showMessageField ? (
                  <StyledTextArea
                    rows="1"
                    autoFocus
                    value={inviteMessage || ''}
                    onChange={(event) =>
                      this.setState({ inviteMessage: event.target.value })
                    }
                    onBlur={(event) =>
                      this.setState({
                        showMessageField: event.target.value.length > 0,
                      })
                    }
                  />
                ) : (
                  canCustomizeEmailMessages && (
                    <Button
                      text
                      data-test-id="add-message-button"
                      onClick={() => {
                        this.setState({ showMessageField: true });
                        trackClick('add_a_message');
                      }}
                    >
                      <StyledMessageIcon />
                      Add a message
                    </Button>
                  )
                )}
              </Media>

              <Button
                primary
                onClick={() => {
                  this.onInviteUsers();
                  trackClick('invite');
                }}
                test-data-id="invite-button"
                showSpinner={isInvitingUsers}
                disabled={this.shouldShowUserMaxLimitReached()}
              >
                Invite
              </Button>
            </Footer>
          </React.Fragment>
        )}
        <ManagePeopleList
          renderHeader={() => (
            <h4>
              {totalCount} {totalCount === 1 ? 'person' : 'people'} in this
              project
            </h4>
          )}
          totalCount={
            totalCount === undefined
              ? undefined
              : clamp(totalCount, 0, MAX_SINGLE_PAGE_SIZE)
          }
          className="manage-people"
          projectId={projectId}
          canCollapse={canInviteCollaborators}
          scrollTop={openAndScrollTo}
          canRenderList={canRenderPeopleList}
          trackingEvent={trackingEvent}
          trackingTitle={trackingTitle}
          trackingPage={trackingPage}
          trackingPosition={trackingPosition}
          setScrollTop={setPeopleListScrollTop}
        >
          {(personId) => <ManagePerson personId={personId} />}
        </ManagePeopleList>
      </Container>
    );
  }
}

ManagePeople.propTypes = {
  accountId: PropTypes.string,
  canInviteCollaborators: PropTypes.bool,
  hasReachedUserCountLimit: PropTypes.bool,
  inviteUsersToProject: PropTypes.func.isRequired,
  isAutoscalingSeats: PropTypes.bool,
  isInvitingUsers: PropTypes.bool,
  isOnFreePlan: PropTypes.bool,
  getLineItemsForPlan: PropTypes.func.isRequired,
  openAndScrollTo: PropTypes.number,
  planId: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  resetResendInvites: PropTypes.func.isRequired,
  setPeopleListScrollTop: PropTypes.func,
  isPaidCollaboratorsPlan: PropTypes.bool,
  onTrialUntil: PropTypes.string,
  individualSeatCost: PropTypes.number,
};

ManagePeople.defaultProps = {
  accountId: '',
  canInviteCollaborators: false,
  hasReachedUserCountLimit: false,
  isAutoscalingSeats: false,
  isInvitingUsers: false,
  isOnFreePlan: false,
  openAndScrollTo: undefined,
  projectId: '',
  setPeopleListScrollTop: noop,
  isPaidCollaboratorsPlan: false,
  onTrialUntil: null,
  individualSeatCost: 0,
};

export const testExports = {
  Container,
  Header,
  SeatsPurchasedSubText,
  StyledTextArea,
};

export default ManagePeople;
