import { connect } from 'react-redux';

import { respond } from './actions';
import CustomActionFormDialog from './CustomActionFormDialog';

/**
 * Maps state to props for the CustomActionFormDialog component.
 * @param   {Object} state - The state object.
 * @returns {Object} An object with state mapped as props.
 */
function mapStateToProps(state) {
  return {
    ...state.customActionFormDialog,
  };
}

const mapDispatchToProps = {
  respond,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomActionFormDialog);
