import React from 'react';
import styled from 'styled-components';
import { type as assetType } from '@frameio/core/src/assets/helpers/constants';
import { color } from '@frameio/components/src/theme/darkTheme';

import AssetThumb from 'components/AssetThumb';

import FolderIcon from '@frameio/components/src/svgs/raw/ic-folder-small-54px.svg';

const SmallFileThumb = styled(AssetThumb)`
  border-radius: ${(p) => p.theme.radius.medium};
  overflow: hidden;
  font-weight: bold;

  svg {
    width: 14px;
    height: 12px;
  }
`;

export default function SmallAssetThumb({
  asset,
  folderColor,
  backgroundColor,
  ...rest
}) {
  return asset.type === assetType.FOLDER ? (
    <FolderIcon color={folderColor} {...rest} />
  ) : (
    <SmallFileThumb {...{ backgroundColor, asset, ...rest }} fontSize="12px" />
  );
}

SmallAssetThumb.propTypes = AssetThumb.propTypes;

SmallAssetThumb.defaultProps = {
  ...AssetThumb.defaultProps,
  width: 56,
  height: 32,
  folderColor: color.dimGray,
};
