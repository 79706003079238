import { get } from 'lodash';
import { currentUserEntitySelector } from 'selectors/users';
import { currentAccountEntitySelector } from 'selectors/accounts';
import { planEntityForAccountIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { isAccountAdminSelector } from 'selectors/roles';
import { permittedActionsForAccountSelector } from 'selectors/permissions';
import { watermarkUserFlowVariants } from '../constants';

const {
  nonEnterpriseUser,
  nonEnterpriseAdmin,
  enterpriseWithoutSbw,
} = watermarkUserFlowVariants;

export default function watermarkUpsellVariantSelector(state) {
  const currentAccountEntity = currentAccountEntitySelector(state);
  const currentAccountPlanEntity = planEntityForAccountIdSelector(state, {
    accountId: currentAccountEntity.id,
  });
  const currentUserEntity = currentUserEntitySelector(state);
  const {
    isSessionBasedWatermarkingEnabled,
  } = permittedActionsForAccountSelector(state, {
    accountId: currentAccountEntity.id,
  });

  const isEnterprise = get(currentAccountPlanEntity, 'enterprise');
  const isOwner = currentUserEntity.id === currentAccountEntity.owner_id;
  const currentUserHasBillingAccess =
    isOwner ||
    isAccountAdminSelector(state, { accountId: currentAccountEntity.id });

  if (isSessionBasedWatermarkingEnabled) return null;
  if (isEnterprise) return enterpriseWithoutSbw;
  if (!currentUserHasBillingAccess) return nonEnterpriseUser;
  if (currentUserHasBillingAccess) return nonEnterpriseAdmin;

  return null;
}
