import { SMALL_MAX, MEDIUM_MIN, MEDIUM_MAX, LARGE_MIN } from './breakpoints';

export const SMALL = `screen and (max-width: ${SMALL_MAX}px)`;
export const MEDIUM_UP = `screen and (min-width: ${MEDIUM_MIN}px)`;
export const MEDIUM = `screen and (min-width: ${MEDIUM_MIN}px) and (max-width: ${MEDIUM_MAX}px)`;
export const MEDIUM_DOWN = `screen and (max-width: ${MEDIUM_MAX}px)`;
export const LARGE = `screen and (min-width: ${LARGE_MIN}px)`;

export const getMediaSize = () => {
  if (window.matchMedia(LARGE).matches) {
    return LARGE;
  }

  if (window.matchMedia(MEDIUM).matches) {
    return MEDIUM;
  }

  return SMALL;
};
