import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import JTSVG from 'core/JTSVG';
import HorizontalScroller from 'components/AssetSlider/HorizontalScroller';
import AssetSliderPaginator from 'components/AssetSlider/Paginator';
import AssetSliderPageNavigation from 'components/AssetSlider/PageNavigation';
import AssetSliderFactory from 'components/AssetSlider/AssetSlider';
import { TRANSFORMER_ANIMATION_DURATION } from 'components/AssetSlider/SliderThumbs/AnimatingBaseThumb/AnimatingBaseThumb';
import './PlayerAssetSlider.scss';

class PlayerAssetSliderView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasMounted: false,
    };
  }

  componentDidMount() {
    // Delay hasMounted state to wait for the thumbs to animate on load.
    // Hack, just need to wait a tick after the component did mount to change the
    // prop.  The length in time is arbitrary.
    this.timeout = setTimeout(() => {
      this.setState({ hasMounted: true });
    }, TRANSFORMER_ANIMATION_DURATION);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const {
      currentPage,
      goToPage,
      getTotalPages,
      sliderRef,
      className,
    } = this.props;
    const totalPages = Math.min(getTotalPages(), 100); // max 100 pages
    const pageNavigationClassNames =
      'flex items-center absolute top-0 content-center pointer o-100 player-asset__page-navigation';
    return (
      <div className={`${className} player-asset-slider__container`}>
        <HorizontalScroller
          {...this.props}
          ref={sliderRef}
          isReady={this.state.hasMounted}
        />
        <AssetSliderPaginator
          className={`absolute flex justify-center items-center w-100 player-asset-slider__paginator ${className}`}
          page={currentPage}
          number={totalPages}
          onClick={goToPage}
        />
        <AssetSliderPageNavigation
          onClick={() => goToPage(currentPage - 1)}
          svgIcon={JTSVG.previous_comment('#fff')}
          visible={currentPage > 1}
          direction={AssetSliderPageNavigation.LEFT}
          className={pageNavigationClassNames}
        />
        <AssetSliderPageNavigation
          onClick={() => goToPage(currentPage + 1)}
          svgIcon={JTSVG.next_comment('#fff')}
          visible={totalPages > 1 && currentPage < totalPages}
          direction={AssetSliderPageNavigation.RIGHT}
          className={pageNavigationClassNames}
        />
      </div>
    );
  }
}

const PlayerAssetSlider = AssetSliderFactory(PlayerAssetSliderView);

PlayerAssetSlider.defaultProps = {
  overscan: 3,
  imageHorizontalPadding: 0,
  components: [],
  height: 'inherit',
  className: '',
};

PlayerAssetSliderView.defaultProps = {
  className: '',
};

PlayerAssetSlider.propTypes = {
  height: PropTypes.number.isRequired,
  overscan: PropTypes.number,
  imageWidth: PropTypes.number.isRequired,
  imageHeight: PropTypes.number.isRequired,
  imageHorizontalPadding: PropTypes.number,
  components: PropTypes.arrayOf(PropTypes.shape).isRequired,
  className: PropTypes.string,
  selectedThumbIndex: PropTypes.number,
};

PlayerAssetSliderView.propTypes = {
  currentPage: PropTypes.number.isRequired,
  goToPage: PropTypes.func.isRequired,
  components: PropTypes.arrayOf(PropTypes.shape).isRequired,
  getTotalPages: PropTypes.func.isRequired,
  sliderRef: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default styled(PlayerAssetSlider)``;
