import React, { memo } from 'react';
import styled from 'styled-components';
import Pin from '@frameio/components/src/svgs/icons/24/pin.svg';
import Container from '../shared/Container';
import ErrorMessage from '../shared/ErrorMessage';
import Input from '../shared/Input';

const PinContainer = styled.div`
  position: absolute;
  top: ${(p) => p.theme.spacing.tiny};
  left: ${(p) => p.theme.spacing.tiny};
  color: ${(p) => p.theme.color.slateGray};
  z-index: 1;
`;

const PostalCode = ({ error, handleBlur, handleChange, touched }) => {
  const showError = touched && error;
  return (
    <>
      <Container>
        <PinContainer>
          <Pin />
        </PinContainer>
        <Input
          error={!!touched && !!error}
          name="postalCode"
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="90210"
        />
      </Container>
      <ErrorMessage isVisible={!!showError} marginTop>
        {error}
      </ErrorMessage>
    </>
  );
};

export default memo(PostalCode);
