// @flow

import { generateFetchActionTypes } from '@frameio/core/src/shared/actions/helpers';
import type { Action } from '@frameio/core/src/shared/flowTypes/redux';

export const SUBSCRIPTION: Object = generateFetchActionTypes('SUBSCRIPTION', [
  'GET',
]);

/**
 * Get the subscription for a given account.
 * @param   {String} accountId - The current account Id.
 * @returns {Object} - Object with type and payload.
 */
export function getSubscriptionByAccount(accountId: String): Action {
  return {
    type: SUBSCRIPTION.GET.BASE,
    payload: { accountId },
  };
}
