import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

const module = 'ACCOUNT_CONTAINER';
const ACCOUNT_CONTAINER = generateActionTypes(module, [
  'CREATE_PROJECT',
  'ENTER_ACCOUNT',
  'REDIRECT_TO_ACCOUNT',
]);

export default ACCOUNT_CONTAINER;

export const createProject = createAction(
  ACCOUNT_CONTAINER.CREATE_PROJECT,
  (teamId) => ({ teamId })
);

export const enterAccount = createAction(
  ACCOUNT_CONTAINER.ENTER_ACCOUNT,
  (accountId) => ({ accountId })
);

export const redirectToAccount = createAction(
  ACCOUNT_CONTAINER.REDIRECT_TO_ACCOUNT,
  (accountId, queryParams) => ({ accountId, queryParams })
);
