import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const CONTEXT_MENU = {
  ...generateActionTypes('CONTEXT_MENU', ['OPEN', 'CLOSE']),
};

const DEFAULT_MENU_PROPS = {
  parentHeight: window.innerHeight,
  parentWidth: window.innerWidth,
  parentLeft: 0,
  parentTop: 0,
};

export const openContextMenu = createAction(
  CONTEXT_MENU.OPEN,
  (contextMenuType, x, y, menuProps = DEFAULT_MENU_PROPS) => ({
    contextMenuType,
    x,
    y,
    menuProps,
  })
);

export const closeContextMenu = createAction(CONTEXT_MENU.CLOSE, () => ({}));
