import { put, takeLatest, select, call, race, take } from 'redux-saga/effects';
import {
  openSelectPlanFlowModal,
  SELECT_PLAN_FLOW,
} from 'components/SelectPlanFlow/actions';
import {
  currentAccountSelector,
  isAccountEnterpriseSelector,
} from 'selectors/accounts';
import { currentTeamEntitySelector } from 'selectors/teams';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import {
  planEntityForAccountIdSelector,
  subscriptionEntityForAccountIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import { getAccountLimits, limitTypes } from 'selectors/limits';
import { openCreateProjectForm } from 'components/DashboardSidebar/ListTeamRow/sagas';
import { HARD_BLOCK } from './actions';

export function* isAccountAtLimit(limitType) {
  const isEnterprise = yield select(isAccountEnterpriseSelector);
  if (isEnterprise) return false;
  const { id: accountId } = yield select(currentAccountSelector);
  const currentAccount = yield select(accountEntitySelector, {
    accountId,
  });
  const plan = yield select(planEntityForAccountIdSelector, { accountId });
  const subscription = yield select(subscriptionEntityForAccountIdSelector, {
    accountId,
  });

  const accountLimits = yield call(
    getAccountLimits,
    currentAccount,
    plan,
    subscription
  );

  const limitForType = accountLimits.find((limit) => limit.type === limitType);
  const usage = limitForType.usage;

  return limitForType.limit && usage >= limitForType.limit;
}

function* restorePlaceAfterUpgrade(limitType) {
  switch (limitType) {
    case limitTypes.PROJECTS: {
      const { id: teamId } = yield select(currentTeamEntitySelector);
      yield call(openCreateProjectForm, teamId);
      break;
    }
    default:
  }
}

function* upgradePlan(limitType) {
  const { id: accountId } = yield select(currentAccountSelector);

  const source = `${limitType} hard block`;
  yield put(openSelectPlanFlowModal(accountId, { source }));

  const { success, cancelled } = yield race({
    success: take(SELECT_PLAN_FLOW.REPORT_PLAN_CHANGE_SUCCESS),
    cancelled: take(SELECT_PLAN_FLOW.END),
  });

  if (cancelled) return;

  // For storage and archival storage, there isn't a good place in the flow to pick back up,
  // so if a user successfully upgrades they can resume uploading or archiving on their own.
  if (
    limitType === limitTypes.ARCHIVAL_STORAGE ||
    limitType === limitTypes.STORAGE
  )
    return;

  const willGoOverLimit = yield call(isAccountAtLimit, limitType);

  if (!willGoOverLimit && success) {
    yield call(restorePlaceAfterUpgrade, limitType);
  }
}

export const testExports = {
  isAccountAtLimit,
  upgradePlan,
  restorePlaceAfterUpgrade,
};

export default [
  takeLatest(HARD_BLOCK.UPGRADE_PLAN, ({ payload: { limitType } }) =>
    upgradePlan(limitType)
  ),
];
