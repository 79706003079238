import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import Task from './Task';

const NoTaskContainer = styled.div`
  text-align: center;
  padding: ${(p) => p.theme.spacing.units(12.5)} 0;
  color: ${(p) => p.theme.color.lightGray};
`;

const TaskList = ({ tasks, ...rest }) => {
  if (isEmpty(tasks)) {
    return <NoTaskContainer disabled>No tasks available</NoTaskContainer>;
  }

  return tasks.map((task) => {
    const { permissions, subtasks } = task || {};
    const hasSubtasks = subtasks?.length > 0;
    const canUpload = !!permissions?.can_upload;
    const canUpdate = !!permissions?.can_change_status;

    return (
      <Task
        key={task.id}
        canUpdate={canUpdate}
        canUpload={canUpload}
        hasSubtasks={hasSubtasks}
        subtasks={subtasks}
        task={task}
        {...rest}
      />
    );
  });
};

TaskList.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object),
};

export default TaskList;
