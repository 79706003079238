import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';
import transitionFactory from 'react-transition-factory';
import ForensicWatermarkFingerprint from '@frameio/components/src/components/PlayerSubcomponents/ForensicWatermarkFingerprint';
import { Modal } from '@frameio/components';
import { MARGIN as MODAL_MARGIN } from '@frameio/components/src/components/Modal';
import { getAspectContain } from '@frameio/components/src/components/AspectContain';
import {
  lineHeight,
  spacing,
  easing,
} from '@frameio/components/src/theme/darkTheme';
import { absoluteFill } from '@frameio/components/src/mixins';
import { type as assetType } from '@frameio/core/src/assets/helpers/constants';
import { isAssetThumbReady } from '@frameio/core/src/assets/helpers/utils';
import {
  getMediaType,
  mediaTypes,
} from '@frameio/core/src/assets/helpers/mediaTypes';

import { trackMediaStartPlayed } from 'analytics';
import ScaleFadeTransition from 'components/ScaleFadeTransition';

import QuicklookAudio from './QuicklookAudio';
import QuicklookFolder from './QuicklookFolder';
import QuicklookVideo from './QuicklookVideo';
import QuicklookFile from './QuicklookFile';
import QuicklookUploading from './QuicklookUploading';

// TODO(Joel): This is the page background color, it should probably go into the theme?
const BACKGROUND_COLOR = '#14161C';
const TITLE_PADDING = spacing.tiny;
const BORDER_SIZE = '1px';
const TITLE_HEIGHT =
  parseInt(lineHeight[2], 10) + 2 * parseInt(TITLE_PADDING, 10);
const MODAL_MARGINS = 2 * parseInt(MODAL_MARGIN, 10);

const TranslateXY = transitionFactory({
  transition: 'transform',
  getStartStyle: (start) => `translate3d(${start}, 0)`,
  getEndStyle: (end) => `translate3d(${end}, 0)`,
});

const ModalContainer = styled.div`
  ${absoluteFill()};
  position: fixed;
  transform-origin: 0;
`;

const ModalWithShadow = styled(Modal)`
  box-shadow: ${({ theme }) =>
    `0 5px 40px 8px ${rgba(theme.color.black, 0.3)}`};
  background-color: ${rgba(BACKGROUND_COLOR, 0.95)};
  backdrop-filter: blur(10px);
  border-radius: ${(p) => p.theme.radius.medium};
  border: ${({ theme }) => `${BORDER_SIZE} ${theme.color.black} solid`};
  transform-origin: center;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.color.white};
`;

const Title = styled.h2`
  ${(p) => p.theme.fontStyle.body};
  padding: ${TITLE_PADDING} 0;
  color: ${(p) => p.theme.color.white};
  text-align: center;
  line-height: normal;
`;

const HAS_QUICKLOOKED_KEY = 'userHasQuicklooked';

function setHasUserQuicklooked() {
  return window.localStorage.setItem(HAS_QUICKLOOKED_KEY, true);
}

export function getHasUserQuicklooked() {
  return window.localStorage.getItem(HAS_QUICKLOOKED_KEY);
}

/**
 * Gets the style object with aspect-contained maximum width and height for the window.
 * @param {Asset} asset - An Asset.
 */
function getFullWidthStyle(asset) {
  const {
    transcodes: { original_height: height, original_width: width } = {},
  } = asset;

  const maxWidth = window.innerWidth - MODAL_MARGINS;
  const maxHeight = window.innerHeight - MODAL_MARGINS - TITLE_HEIGHT;
  const ratio = width / height;
  const dimensions =
    width > maxWidth || height > maxHeight
      ? getAspectContain(maxWidth, maxHeight, ratio)
      : {
          width,
          height,
        };
  return {
    display: 'block',
    width: Math.round(dimensions.width),
    height: Math.round(dimensions.height),
  };
}

function AnimatingModal({ children, isOpen, onClose, origin, setIsReady }) {
  // We can't have both scale and translate in the same transition since the
  // translated values are also scaled.
  return (
    <TranslateXY
      in={isOpen}
      start={`
        ${origin.length ? `calc(${origin[0]}px - 50vw)` : '0'},
        ${origin.length ? `calc(${origin[1]}px - 50vh)` : '0'}
      `}
      end="0, 0"
      timeout={300}
      easing={easing.easeOutQuint}
      onEntered={() => setIsReady(true)}
      onExit={() => setIsReady(false)}
    >
      <ModalContainer>
        <ScaleFadeTransition
          in={isOpen}
          timeout={300}
          easing={easing.easeOutQuint}
          start={[0, 0.1]}
          end={[1, 1]}
          style={{ display: 'block' }}
        >
          <ModalWithShadow
            isOpen
            hasBackdrop={false}
            closeOnEsc
            canCloseModal
            onClose={onClose}
            animate={false}
            enableFocusLock={false}
            maxHeight={`calc(100vh - 2 * (${MODAL_MARGIN} + ${BORDER_SIZE}))`}
          >
            {children}
          </ModalWithShadow>
        </ScaleFadeTransition>
      </ModalContainer>
    </TranslateXY>
  );
}

AnimatingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  origin: PropTypes.arrayOf(PropTypes.number).isRequired,
  setIsReady: PropTypes.func.isRequired,
};

export default class QuicklookModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      isFallbackOccurringTop: false,
      isFrameRateSupported: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { asset, getAssetSize, isOpen, isCalculatingFolderSize } = this.props;
    const { isReady } = this.state;

    if (
      isOpen &&
      !isCalculatingFolderSize &&
      asset &&
      asset.type === assetType.FOLDER &&
      !asset.usage
    ) {
      getAssetSize(asset.id);
    }

    if (!prevProps.isOpen) {
      this.setIsFrameRateSupported(true);
    }

    const mediaType = asset ? getMediaType(asset) : null;

    if (
      isOpen &&
      isReady &&
      asset &&
      (mediaType === mediaTypes.AUDIO || mediaType === mediaTypes.VIDEO)
    ) {
      /*
       * quicklook autoplays so trigger the media-start-played.
       * there is a small degree of inaccuracy here.
       * But regardless of if opening was a mistake, the intended result is that it will play (very soon after this)
       */
      const hasVersionStack = 'na';
      trackMediaStartPlayed(asset, {
        isVersionStack: hasVersionStack,
      });
    }
  }

  setIsFrameRateSupported = (isFrameRateSupported) => {
    this.setState({ isFrameRateSupported });
  };

  render() {
    const {
      asset,
      assetId,
      creator,
      fwmFallbackPreference,
      isOpen,
      origin,
      onClose,
    } = this.props;

    if (!asset) return null;

    if (isOpen) setHasUserQuicklooked();

    const modalProps = {
      isOpen,
      onClose,
      origin,
      setIsReady: (isReady) => this.setState({ isReady }),
    };

    if (asset.type === assetType.FOLDER) {
      return (
        <AnimatingModal {...modalProps}>
          <QuicklookFolder assetId={assetId} asset={asset} creator={creator} />
        </AnimatingModal>
      );
    }

    if (!isAssetThumbReady(asset)) {
      return (
        <AnimatingModal {...modalProps}>
          <Title>{asset.name}</Title>
          <QuicklookUploading />
        </AnimatingModal>
      );
    }

    switch (getMediaType(asset)) {
      case mediaTypes.AUDIO: {
        return (
          <AnimatingModal {...modalProps}>
            <QuicklookAudio key={assetId} asset={asset} creator={creator} />
          </AnimatingModal>
        );
      }
      case mediaTypes.VIDEO: {
        return (
          <AnimatingModal {...modalProps}>
            <TitleContainer>
              <ForensicWatermarkFingerprint
                isShowing={
                  asset.is_forensically_watermarked &&
                  this.state.isFrameRateSupported &&
                  !this.state.isFallbackOccurringTop
                }
              />
              <Title>{asset.name}</Title>
            </TitleContainer>
            <QuicklookVideo
              fwmFallbackPreference={fwmFallbackPreference}
              isReady={this.state.isReady}
              key={assetId}
              asset={asset}
              isForensicallyWatermarked={asset.is_forensically_watermarked}
              setIsFallbackOccurringTop={(isFallbackOccurringTop) => {
                this.setState({ isFallbackOccurringTop });
              }}
              setIsFrameRateSupported={this.setIsFrameRateSupported}
              style={getFullWidthStyle(asset)}
            />
          </AnimatingModal>
        );
      }
      case mediaTypes.PDF:
      case mediaTypes.IMAGE: {
        return (
          <AnimatingModal {...modalProps}>
            <Title>{asset.name}</Title>
            <img
              key={asset.id}
              src={asset.image_full}
              alt={asset.name}
              style={getFullWidthStyle(asset)}
            />
          </AnimatingModal>
        );
      }
      default:
        return (
          <AnimatingModal {...modalProps}>
            <QuicklookFile key={asset.id} creator={creator} asset={asset} />
          </AnimatingModal>
        );
    }
  }
}

QuicklookModal.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    image_full: PropTypes.string,
    usage: PropTypes.string,
    transcodes: PropTypes.shape({
      original_height: PropTypes.number,
      original_width: PropTypes.number,
    }),
  }),
  assetId: PropTypes.string,
  creator: PropTypes.object,
  getAssetSize: PropTypes.func.isRequired,
  isCalculatingFolderSize: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  origin: PropTypes.arrayOf(PropTypes.number).isRequired,
};

QuicklookModal.defaultProps = {
  asset: undefined,
  assetId: undefined,
  coverAsset: undefined,
  creator: undefined,
  isOpen: false,
};

export const testExports = {
  TITLE_HEIGHT,
  MODAL_MARGINS,
  getFullWidthStyle,
  HAS_QUICKLOOKED_KEY,
  TranslateXY,
};
