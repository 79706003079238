import React, { memo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import Lock from '@frameio/components/src/svgs/icons/lock-filled.svg';
import BillingAddress from './components/BillingAddress';
import CardElement from './components/CardElement';
import CardExpirationElement from './components/CardExpirationElement';
import CardCVCElement from './components/CardCVCElement';
import CityInput from './components/CityInput';
import PostalCode from './components/PostalCode';
import StateInput from './components/StateInput';
import CountryInput from './components/CountryInput';
import ErrorMessage from './components/shared/ErrorMessage';

const Container = styled(Flex)`
  margin-bottom: ${(p) => (p.marginBottom ? '16px' : 0)};
  margin-top: ${({ marginTop, theme }) =>
    marginTop ? `${theme.spacing.tiny}` : 0};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.tiny};

  > div {
    width: 50%;
  }
`;

const SecurityTextContainer = styled(Flex)`
  ${(p) => p.theme.fontStyle.body};
  font-size: ${(p) => p.theme.fontSize[1]};
  color: ${(p) => p.theme.color.graphiteGray};
  align-items: center;
  margin-bottom: 16px;
`;

const SecurityText = styled.span`
  padding-left: ${(p) => p.theme.spacing.micro};
`;

const CreditCardFormElements = ({
  collapsed,
  errors,
  handleBlur,
  handleChange,
  isValid,
  onValidate,
  saveAddress,
  setErrors,
  setFieldTouched,
  setFieldValue,
  submissionError,
  touched,
  validateForm,
  values,
}) => {
  const cityError = touched?.city && errors?.city;
  const streetAddressLine1Error =
    touched?.streetAddressLine1 && errors?.streetAddressLine1;
  const countryError = touched?.country && errors?.country;

  const cardNumberComplete = values?.cardNumber?.complete;
  const cardExpiryComplete = values?.cardExpiry?.complete;
  const cardCvcComplete = values?.cardCvc?.complete;

  const ref = useRef();

  useEffect(() => {
    let blurTimeout;
    if (ref && ref.current && !collapsed) {
      // Prevents `Country` field to be focused while Stripe Elements are
      // instantiated.
      blurTimeout = setTimeout(() => {
        ref.current.select.blur();
        setFieldTouched('country', false);
      }, 25);
    } else {
      ref.current.select.focus();
    }
    return () => clearTimeout(blurTimeout);
  }, [collapsed, ref, setFieldTouched]);

  useEffect(() => {
    const isCreditCardCompleted = !collapsed
      ? cardNumberComplete && cardExpiryComplete && cardCvcComplete
      : true;

    if (!isCreditCardCompleted) {
      setErrors({ ...errors, creditCardCompleted: false });
    } else {
      validateForm();
    }
    onValidate(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cardNumberComplete,
    cardExpiryComplete,
    cardCvcComplete,
    isValid,
    collapsed,
  ]);

  useEffect(() => {
    const address = {
      country: values.country,
      city: values.city,
      line1: values.streetAddressLine1,
      line2: values.streetAddressLine2,
      postal_code: values.postalCode,
      state: values.stateOrProvince,
    };
    saveAddress(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.country,
    values.city,
    values.streetAddressLine1,
    values.streetAddressLine2,
    values.postalCode,
    values.stateOrProvince,
  ]);

  return (
    <div>
      {!collapsed && (
        <>
          <CardElement
            error={values?.cardNumber?.error}
            isEmpty={values?.cardNumber?.empty}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            touched={touched?.cardNumber}
          />
          <Container marginTop>
            <div>
              <CardExpirationElement
                error={values?.cardExpiry?.error}
                isEmpty={values?.cardExpiry?.empty}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched?.cardExpiry}
              />
            </div>
            <div>
              <CardCVCElement
                error={values?.cardCvc?.error}
                isEmpty={values?.cardCvc?.empty}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched?.cardCvc}
              />
            </div>
          </Container>
        </>
      )}

      <CountryInput
        ref={ref}
        country={values?.country}
        error={errors?.country}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched?.country}
      />
      <ErrorMessage isVisible={!!countryError} marginBottom>
        {countryError}
      </ErrorMessage>
      <BillingAddress
        error={errors?.streetAddressLine1}
        handleBlur={handleBlur}
        handleChange={handleChange}
        touched={touched?.streetAddressLine1}
      />
      <ErrorMessage isVisible={!!streetAddressLine1Error} marginBottom>
        {streetAddressLine1Error}
      </ErrorMessage>
      <CityInput
        error={errors?.city}
        handleBlur={handleBlur}
        handleChange={handleChange}
        touched={touched?.city}
      />
      <ErrorMessage isVisible={!!cityError} marginBottom>
        {cityError}
      </ErrorMessage>
      <Container marginBottom>
        <div>
          <StateInput
            country={values?.country}
            error={errors?.stateOrProvince}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            stateOrProvince={values?.stateOrProvince}
            touched={touched?.stateOrProvince}
          />
        </div>
        <div>
          <PostalCode
            error={errors?.postalCode}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched?.postalCode}
          />
        </div>
      </Container>
      {!collapsed && !submissionError && (
        <SecurityTextContainer>
          <Lock width={12} height={14} />
          <SecurityText>This form is secure and encrypted.</SecurityText>
        </SecurityTextContainer>
      )}
      <ErrorMessage isVisible={!!submissionError} marginBottom>
        {submissionError}
      </ErrorMessage>
    </div>
  );
};

export default memo(CreditCardFormElements);
