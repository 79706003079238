import { call, put, select, spawn, takeLatest } from 'redux-saga/effects';
import track, { trackButtonClick } from 'analytics';
import { openSelectPlanFlowModal } from 'components/SelectPlanFlow/actions';
import { shouldSeePlanSelectionModalDuringTrial } from 'components/TrialCountdown/sagas';
import { PLAN_UPGRADE } from 'components/AccountCTA/PlanUpgradeCTA/actions';
import convertCountdownToDays from 'utils/plans/convertCountdownToDays';
import { currentAccountWithSubscriptionAndPlanSelector } from 'selectors/accounts';
import trialCountdownTriggeredByClickSelector from './selectors';

function* trackTrialEndingModalShown(accountId) {
  const { plan, subscription } = yield select(
    currentAccountWithSubscriptionAndPlanSelector,
    accountId
  );
  const countdown = yield call(
    convertCountdownToDays,
    subscription.promotion_expires_at
  ) || 0;
  const triggeredByClick = yield select(trialCountdownTriggeredByClickSelector);
  const promoCode = (subscription.promotion || {}).promo_code;

  const properties = {
    days_remaining: countdown,
    trial_id: promoCode,
    trial_plan_id: plan.name,
    is_user_initiated: triggeredByClick,
  };

  yield spawn(track, 'trial-ending-modal-shown', properties);
}

function* addPayment({ payload: { accountId, trackingLocation } }) {
  yield spawn(trackButtonClick, 'add payment method', 'main', trackingLocation);

  if (!(yield call(shouldSeePlanSelectionModalDuringTrial, accountId))) {
    return;
  }
  yield put(openSelectPlanFlowModal(accountId, { source: 'in-app upgrade' }));
  yield spawn(trackTrialEndingModalShown, accountId);
}

function* upgradeAccount({ payload: { accountId, trackingLocation } }) {
  yield spawn(trackButtonClick, 'upgrade account', 'main', trackingLocation);
  yield put(openSelectPlanFlowModal(accountId, { source: 'in-app upgrade' }));
}

export default [
  takeLatest(PLAN_UPGRADE.UPGRADE_ACCOUNT, upgradeAccount),
  takeLatest(PLAN_UPGRADE.ADD_PAYMENT, addPayment),
];

export const testExports = {
  addPayment,
  upgradeAccount,
  trackTrialEndingModalShown,
};
