import React from 'react';
import { openModal, closeModal } from 'components/Modal/actions';
import { put, takeLatest } from 'redux-saga/effects';
import LegalDialog from './LegalDialog';
import { LEGAL_MODAL } from './actions';

function* openLegalModal() {
  yield put(openModal(() => <LegalDialog closeModal={closeModal} />));
}

export default [takeLatest(LEGAL_MODAL.OPEN, openLegalModal)];
