import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'styled-flex-component';
import { capitalize } from 'lodash';
import { color as themeColor } from '@frameio/components/src/theme/darkTheme';
import Avatar from '@frameio/components/src/styled-components/Avatar';
import { daysAgo } from '@frameio/components/src/utils/datetimeHelpers';
import TruncateWithTooltip from '@frameio/components/src/styled-components/TruncateWithTooltip';
import ListCell from '@frameio/components/src/styled-components/ListCell';

function appendRelativeTimeSuffix(relativeTime) {
  const suffix = relativeTime.toLowerCase() === 'just now' ? '' : ' ago';

  return [relativeTime, suffix].join('');
}

export default function User({
  email,
  image,
  color,
  name = '',
  lastActivityDate,
  showLastActivity = true,
  lastActivityPrefix = 'last seen',
  lastActivityFallback,
}) {
  const relativeTime = daysAgo(new Date(lastActivityDate));
  const lastActivity = `${lastActivityPrefix} ${appendRelativeTimeSuffix(
    relativeTime
  )}`;

  return (
    <ListCell
      title={name}
      secondaryTitle={
        showLastActivity
          ? capitalize(lastActivityDate ? lastActivity : lastActivityFallback)
          : null
      }
      details={
        <Flex full>
          <TruncateWithTooltip>{email}</TruncateWithTooltip>
        </Flex>
      }
      image={
        <Avatar
          image={image}
          size={32}
          color={color || `${themeColor.lightGray}`}
          name={name || email}
        />
      }
    />
  );
}

User.propTypes = {
  email: PropTypes.string.isRequired,
  image: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
  lastActivityDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showLastActivity: PropTypes.bool,
  lastActivityPrefix: PropTypes.string,
  lastActivityFallback: PropTypes.string,
};
