/* eslint-disable no-param-reassign */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop, partial } from 'lodash';
import { Tooltip } from '@frameio/vapor';

import { trackViewer } from 'analytics';
import VolumeIcon from '@frameio/components/src/svgs/icons/16/volume.svg';
import VolumeMutedIcon from '@frameio/components/src/svgs/icons/16/volume-muted.svg';
import VolumeMidIcon from '@frameio/components/src/svgs/icons/16/volume-mid.svg';

import {
  ActiveSliderTrack,
  InactiveSliderTrack,
  SliderContainer,
  SliderThumb,
  VolumeControlButton,
  InputSlider,
  SliderTrackWrapper,
} from 'components/PlayerControlBar/VolumeControl/styles';
import usePersistance from 'components/PlayerControlBar/VolumeControl/usePersistance';
import useHotKeys, { HOTKEY } from 'pages/PlayerContainers/hooks/useHotKeys';

const ZERO = 0;
const MID = 0.5;
const MAX = 1;
const STEP = 0.03;
const STEP_KEYBOARD = 0.05;
const INITIAL_VALUE = 0.7;
const MAX_INTERVALS = 5;

/**
 * Renders the Volume button in the player page player.
 * @param {Object} props - React props.
 * @returns {ReactElement} React element.
 */
const VolumeControl = ({ changeVolume, isMuted, mute, tooltips, unmute }) => {
  const [currentValue, setCurrentValue] = useState(INITIAL_VALUE);
  const [storedValue, setStoredValue] = useState(INITIAL_VALUE);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isMuting, setIsMuting] = useState(false);

  // Delay the slider from closing when hover is removed
  const isSliderOpen = usePersistance(isButtonHovered, 1000);

  const setVolume = useCallback(
    (value, isInTransition) => {
      if (isNaN(value)) return;
      changeVolume(value);

      if (!isInTransition) {
        setCurrentValue(value);
      }

      if (value <= ZERO) {
        mute();
      } else {
        if (isMuted) unmute();
        if (!isInTransition) {
          setStoredValue(value);
          setIsMuting(false);
        }
      }
    },
    [changeVolume, isMuted, mute, unmute]
  );

  const getMutingCurveValue = useCallback(
    (index) => {
      return (storedValue * index) / MAX_INTERVALS;
    },
    [storedValue]
  );

  const fadeVolume = useCallback(
    ({ index, diff }) => {
      if (index === 0 && diff < 0) {
        setVolume(ZERO);
        return;
      }

      const nextVolumeValue = getMutingCurveValue(index);
      if (nextVolumeValue > storedValue) return;

      setVolume(nextVolumeValue, nextVolumeValue !== storedValue);
      index += diff;

      requestAnimationFrame(() => {
        fadeVolume({ index, diff });
      });
    },
    [getMutingCurveValue, setVolume, storedValue]
  );

  const toggleMute = useCallback(() => {
    if (isMuted) {
      setIsMuting(false);
      fadeVolume({ index: 1, diff: 1 });
    } else if (!isMuting) {
      setIsMuting(true);
      fadeVolume({ index: MAX_INTERVALS, diff: -1 });
    }
    trackViewer('mute-unmute-button-clicked', {
      state: !isMuted ? 'mute' : 'unmute',
    });
  }, [fadeVolume, isMuted, isMuting]);

  useHotKeys({ [HOTKEY.M]: toggleMute });

  const handleVolumeChange = useCallback(
    (event) => {
      const value = parseFloat(event.target.value);
      setVolume(value);
    },
    [setVolume]
  );

  const handleMouseLeave = useCallback(() => {
    setIsButtonHovered(false);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setIsButtonHovered(true);
  }, []);

  const setIsButtonHoveredWhenOpen = useCallback(
    (value) => {
      isSliderOpen && setIsButtonHovered(value);
    },
    [isSliderOpen]
  );

  const handleInputMouseDown = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleInputMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleKeyDown = useCallback(
    (event) => {
      setIsDragging(false);

      switch (event.key) {
        case HOTKEY.ENTER:
          toggleMute();
          break;

        case HOTKEY.LEFT_ARROW:
        case HOTKEY.RIGHT_ARROW: {
          event.stopPropagation();
          setIsDragging(true);
          // Customizing the next/prev values to use a difference of STEP_KEYBOARD=0.05
          const value = parseFloat(event.target.value);
          const nextValue =
            event.key === HOTKEY.LEFT_ARROW
              ? Math.max(value - STEP_KEYBOARD, ZERO)
              : Math.min(value + STEP_KEYBOARD, MAX);
          setVolume(nextValue);
          break;
        }

        case HOTKEY.TAB:
          // when navigating away, close the slider
          setIsButtonHovered(false);
          break;
        default:
          break;
      }
    },
    [setVolume, toggleMute]
  );

  let Icon = VolumeIcon;
  if (isMuted || isMuting) {
    Icon = VolumeMutedIcon;
  } else if (storedValue <= MID) {
    Icon = VolumeMidIcon;
  }

  return (
    <>
      <Tooltip
        className="playbar-tooltip"
        delayIn={500}
        title={isMuted || isMuting ? tooltips.MUTED : tooltips.UNMUTED}
        placement="top"
      >
        <VolumeControlButton
          aria-label="Volume mute control"
          aria-pressed={isMuted}
          isSliderOpen={isSliderOpen}
          onClick={() => {
            toggleMute();
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Icon />
        </VolumeControlButton>
      </Tooltip>
      <SliderContainer
        isSliderOpen={isSliderOpen}
        onFocus={partial(setIsButtonHovered, true)}
        onKeyDown={handleKeyDown}
        onMouseEnter={partial(setIsButtonHoveredWhenOpen, true)}
        onMouseLeave={partial(setIsButtonHoveredWhenOpen, false)}
      >
        <SliderTrackWrapper>
          <InactiveSliderTrack isSliderOpen={isSliderOpen} />
          <ActiveSliderTrack
            currentValue={currentValue}
            isDragging={isDragging}
            isSliderOpen={isSliderOpen}
          />
        </SliderTrackWrapper>
        <SliderThumb
          currentValue={currentValue}
          isDragging={isDragging}
          isSliderOpen={isSliderOpen}
        />
        <InputSlider
          role="slider"
          aria-label="Volume control slider"
          aria-valuemax={MAX}
          aria-valuemin={ZERO}
          isDragging={isDragging}
          max={MAX}
          min={ZERO}
          onChange={handleVolumeChange}
          onMouseDown={handleInputMouseDown}
          onMouseUp={handleInputMouseUp}
          step={STEP}
          type="range"
          value={currentValue}
        />
      </SliderContainer>
    </>
  );
};

VolumeControl.defaultProps = {
  isMuted: false,
  changeVolume: noop,
  mute: noop,
  tooltips: {},
  unmute: noop,
};

VolumeControl.propTypes = {
  changeVolume: PropTypes.func,
  isMuted: PropTypes.bool,
  mute: PropTypes.func,
  unmute: PropTypes.func,
  tooltips: PropTypes.shape({
    MUTED: PropTypes.string,
    UNMUTED: PropTypes.string,
  }),
};

export default VolumeControl;
