import React, { useCallback, useRef } from 'react';
import { isEmpty, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { TreeItem } from '@frameio/components/src/styled-components/TreeView';
import TreeFolderItem from 'components/AssetActions/MoveTo/TreeFolderItem';
import TreeItemLoadingIndicator from 'components/AssetActions/MoveTo/TreeItemLoadingIndicator';

function TreeAemRepositoryItem(props) {
  const {
    isLoading,
    onFetchFolders,
    onSelect,
    projectId,
    selectedAemRepositoryTree,
    selectedFolderId,
    selectedRepoId,
    ...other
  } = props;

  const { folders, repo_id, rootFolderId, title } =
    selectedAemRepositoryTree || {};

  const selectedRefTreeView = useRef();

  /**
   * Recursively render through our tree to render
   * our various subfolders.
   * @param {*} aemRepository children to render
   * @param {*} aemFolderTree our entire asset tree (linked list)
   */
  const renderTree = (aemRepository, aemFolderTree) => {
    if (!isEmpty(aemRepository)) {
      return orderBy(aemRepository, (entry) => {
        entry.name.toLowerCase();
      }).map((child) => {
        const { id, name, path } = child;
        const children = aemFolderTree[id];
        const isSelected = id === selectedFolderId;
        const treeItemProps = {
          isSelected,
          showChevron: true,
          isSelectable: true,
        };

        return (
          <TreeFolderItem
            assetFolderTree={aemFolderTree}
            childName={name}
            folderId={id}
            isSelected={isSelected}
            isDoubleClickActionDisabled
            key={id}
            onSelect={() =>
              onFetchFolders({
                projectId,
                aemRepositoryId: selectedRepoId,
                aemRepositoryTitle: title,
                aemPath: path,
                aemFolderId: id,
              })
            }
            renderTree={renderTree}
            selectedRefTreeView={selectedRefTreeView}
            {...treeItemProps}
          >
            {children}
          </TreeFolderItem>
        );
      });
    }
    return null;
  };

  /**
   * Render a loading indicator if loading takes
   * a bit of time.
   */

  const iconAfter = useCallback(
    ({ isExpanded }) => (
      <TreeItemLoadingIndicator isLoading={isLoading && isExpanded} />
    ),
    [isLoading]
  );

  const sharedProps = {
    showChevron: true,
    nodeId: rootFolderId || repo_id,
    iconAfter,
  };

  const renderedFolderTree =
    folders && renderTree(folders[rootFolderId], folders);

  return (
    <TreeItem
      label={title}
      isDoubleClickActionDisabled
      {...other}
      {...sharedProps}
    >
      {renderedFolderTree}
    </TreeItem>
  );
}

TreeAemRepositoryItem.propTypes = {
  fetchedFolderData: PropTypes.shape({
    isLoadeding: PropTypes.bool,
    folders: PropTypes.object,
  }),
  onFetchFolders: PropTypes.func,
  onSelect: PropTypes.func,
  projectId: PropTypes.string,
  selectedAemRepositoryTree: PropTypes.object,
  selectedFolderId: PropTypes.string,
  selectedRepoId: PropTypes.string,
  showDetails: PropTypes.bool,
};

export default React.memo(TreeAemRepositoryItem);
