import { DropTarget } from 'react-dnd';
import { types as dragAndDropTypes } from 'utils/dragAndDrop';
import ListProjectRow from './ListProjectRow';

const dropTargetSpec = {
  drop({ copyAssetsToProject, projectId }, monitor) {
    const { draggedAssetIds } = monitor.getItem();
    copyAssetsToProject(draggedAssetIds, projectId);
  },
  canDrop({ isProjectArchived }, monitor) {
    const isDraggedAssetProjectArchived = monitor.getItem().assetProjectData
      .isProjectArchived;
    return !isProjectArchived && !isDraggedAssetProjectArchived;
  },
};

const mapDropStateToProps = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isDropZone:
    monitor.getItemType() === dragAndDropTypes.ASSETS &&
    monitor.isOver() &&
    monitor.canDrop(),
});

export const testExports = {
  dropTargetSpec,
  mapDropStateToProps,
};

export default DropTarget(
  dragAndDropTypes.ASSETS,
  dropTargetSpec,
  mapDropStateToProps
)(ListProjectRow);
