import React from 'react';
import { noop } from 'lodash';
import styled from 'styled-components';
import Button from '@frameio/components/src/styled-components/Button/Button';
import SearchingSpinner from './SearchingSpinner';

const GroupContainer = styled.div`
  margin-top: ${(p) => p.theme.spacing.units(1)};
`;

const Label = styled.div`
  color: ${(p) => p.theme.color.gray};
  font-size: 12px;
`;

function SearchAllResultsGroup({
  label,
  hasNextPage,
  isFetchingMore,
  onRequestNextPage,
  children,
  onKeyDown,
}) {
  return (
    <GroupContainer>
      <Label>{label}</Label>
      <div onKeyDown={onKeyDown}>{children}</div>
      <div>
        {hasNextPage && !isFetchingMore && (
          <Button text onClick={onRequestNextPage}>
            Show more
          </Button>
        )}
        {isFetchingMore && <SearchingSpinner label="Loading" />}
      </div>
    </GroupContainer>
  );
}

SearchAllResultsGroup.defaultProps = {
  hasNextPage: false,
  isFetchingMore: false,
  onRequestNextPage: noop,
};

export default SearchAllResultsGroup;
