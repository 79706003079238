import { PROJECT_LIST } from './actions';

const INITIAL_STATE = {
  teamToggles: { SHARED: true },
  jumpToSelected: false,
};

export default function projectListReducer(
  state = INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case PROJECT_LIST.TEAM_TOGGLE: {
      return {
        ...state,
        teamToggles: { ...state.teamToggles, ...payload.teamToggles },
      };
    }
    case PROJECT_LIST.JUMP_TO_SELECTED: {
      return {
        ...state,
        jumpToSelected: payload.jumpToSelected,
      };
    }
    default: {
      return state;
    }
  }
}
