export const shareTabs = {
  sharing: 'sharing',
  appearance: 'appearance',
  settings: 'settings',
  watermark: 'watermark',
};

export const watermarkUserFlowVariants = {
  nonEnterpriseUser: 'non-enterprise-user',
  nonEnterpriseAdmin: 'non-enterprise-admin',
  enterpriseWithoutSbw: 'enterprise-without-sbw',
};
