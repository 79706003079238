import PEOPLE_CIRCLE_STACK from './actions';

export const INITIAL_STATE = {
  totalCount: 0,
  collaborators: [],
};

export default function peopleCircleStackReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case PEOPLE_CIRCLE_STACK.GET_COLLABORATORS.BASE: {
      return INITIAL_STATE;
    }

    case PEOPLE_CIRCLE_STACK.GET_COLLABORATORS.SUCCESS: {
      // `totalCount` is the sum of total collaborators
      // and total pendingCollaborators
      const { response, totalCount } = action.payload;
      return {
        ...state,
        totalCount,
        collaborators: response.result || [],
      };
    }

    default:
      return state;
  }
}
