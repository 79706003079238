import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import createThumb from './createThumb';
import { ThumbBox } from '../sharedComponents';

const Name = styled.div`
  ${(p) => p.theme.fontStyle.bodyS}
  color: ${(p) => p.theme.color.gray};
  overflow-wrap: break-word;
  word-wrap: break-word;
  padding: 0px ${(p) => p.theme.spacing.micro};
`;

export const Container = styled.div`
  width: 220px;

  ${Name} {
    margin-top: ${(p) => p.theme.spacing.tiny};
  }
`;

class AssetThumbView extends React.Component {
  state = {
    thumb: undefined,
  };

  static getDerivedStateFromProps = (props) => {
    if (props.thumb) return { thumb: props.thumb };
    return null;
  };

  componentDidMount() {
    const { file } = this.props;
    if (!this.state.thumb && file) {
      this.generateThumbFromLocalFile(file);
    }
  }

  generateThumbFromLocalFile = (file) => {
    createThumb(file)
      .then((imgData) => {
        this.setState({ thumb: imgData });
      })
      // we expect createThumb to fail which is due to
      // the browser's video engine not being able
      // to read all video codecs.
      // In that case we ignore the error and move on
      // without a thumb.
      .catch(noop); // supresses uncaught Promise errors in the console
  };

  render() {
    const { name } = this.props;
    return (
      <Container>
        <ThumbBox name={name} thumb={this.state.thumb} />
        <Name>{name}</Name>
      </Container>
    );
  }
}

AssetThumbView.propTypes = {
  name: PropTypes.string.isRequired,
  thumb: PropTypes.string,
  // a File object
  file: PropTypes.shape(),
};

AssetThumbView.defaultProps = {
  thumb: undefined,
  file: undefined,
};

export default AssetThumbView;
export const testExports = { Name };
