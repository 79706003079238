import React from 'react';
import PropTypes from 'prop-types';
import { trackButtonClick } from 'analytics';
import { MenuButton } from '@frameio/vapor';
import MoveToFolderIcon from '@frameio/components/src/svgs/icons/16/move-to-folder.svg';

/**
 * Move assets contextual menu list item
 */
export default function MoveAssets({
  assetIds,
  projectId,
  promptMoveToFolder,
  fetchTree,
  ...rest
}) {
  return (
    <MenuButton
      onSelect={() => {
        promptMoveToFolder(assetIds);
        trackButtonClick('move to folder', 'project arena', 'middle');
      }}
      iconBefore={<MoveToFolderIcon width={16} height={16} />}
      {...rest}
    >
      Move to&hellip;
    </MenuButton>
  );
}

MoveAssets.propTypes = {
  assetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  promptMoveToFolder: PropTypes.func.isRequired,
};
