import { connect } from 'react-redux';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import AssetRow from './AssetRow';

function mapStateToProps(state, { assetId }) {
  return {
    asset: assetEntitySelector(state, { assetId }),
  };
}

export default connect(mapStateToProps)(AssetRow);
