import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import PropTypes from 'prop-types';
import Button from '@frameio/components/src/styled-components/Button';
import Dialog, {
  dialogTypes,
} from '@frameio/components/src/styled-components/Dialog';
import Checkmark from '@frameio/components/src/svgs/icons/16/checkmark.svg';

const CopyContainer = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.huge};
  text-align: center;
`;

const CheckmarkBackground = styled(Flex)`
  background-color: ${(p) => p.theme.color.lightSuccess};
  width: 38px;
  height: 38px;
  border-radius: ${(p) => p.theme.radius.circle};
  margin: 0 auto;
  margin-top: 92px;
  margin-bottom: ${(p) => p.theme.spacing.medium};
`;

const GreenCheckmark = styled(Checkmark)`
  color: ${(p) => p.theme.color.success};
`;

const SubscriptionChangedConfirmation = ({ onDoneClick, children }) => (
  <Dialog
    type={dialogTypes.NONE}
    header=""
    primaryButton={
      <Button primary onClick={onDoneClick}>
        Done
      </Button>
    }
    secondaryButton=""
  >
    <CheckmarkBackground justifyCenter alignCenter>
      <GreenCheckmark />
    </CheckmarkBackground>
    <CopyContainer>{children}</CopyContainer>
  </Dialog>
);

SubscriptionChangedConfirmation.propTypes = {
  onDoneClick: PropTypes.func,
  children: PropTypes.node,
};

SubscriptionChangedConfirmation.defaultProps = {
  onDoneClick: undefined,
  children: null,
};

export default SubscriptionChangedConfirmation;
