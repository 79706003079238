import React from 'react';
import PropTypes from 'prop-types';
import Flex, { FlexItem } from 'styled-flex-component';
import styled, { withTheme } from 'styled-components';

import { TabSelect } from '@frameio/components';
import {
  ENTER_DURATION,
  MARGIN,
} from '@frameio/components/src/components/Modal';
import ModalHeader from '@frameio/components/src/styled-components/Modal/ModalHeader';
import Button from '@frameio/components/src/styled-components/Button';
import IconCircle, {
  iconCircleColors,
} from '@frameio/components/src/styled-components/IconCircle';
import PulsingCircle from '@frameio/components/src/styled-components/PulsingCircle';
import SearchInput from '@frameio/components/src/styled-components/SearchInput';
import { alignCenter, buttonReset } from '@frameio/components/src/mixins';
import SubmitSVG from '@frameio/components/src/svgs/raw/ic-right-arrow-14px.svg';
import EmptyAssetSVG from '@frameio/components/src/svgs/raw/ic-no-assets-82px.svg';
import SearchAcrossTeamSVG from '@frameio/components/src/svgs/raw/ic-search-83px.svg';
import WarningIcon from '@frameio/components/src/svgs/icons/warning.svg';

import InfiniteGrid from 'components/InfiniteGrid';
import { SMALL } from 'utils/mediaQueries';

import DeletedAsset from './ConnectedDeletedAsset';

const TABS = {
  BY_TEAM: 'team',
  BY_PROJECT: 'project',
};

const INPUT_HEIGHT = '34px';

const Container = styled.div`
  width: calc(100vw - (2 * ${MARGIN}));
  max-width: 780px;
  height: 670px;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  padding: ${(p) => p.theme.spacing.medium};
  flex: 1 0 auto;
`;

const Search = styled(SearchInput)`
  background-color: ${(p) => p.theme.color.coldWhite};
  width: 200px;
  border-radius: 4px 0 0 4px;
  height: 100%;
`;

const SearchAndFilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing.small};
  margin-right: ${(p) => p.theme.spacing.tiny};

  @media ${SMALL} {
    margin-right: 0;
    justify-content: center;
  }
`;

const SubheaderContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 ${(p) => p.theme.spacing.small};

  @media ${SMALL} {
    flex-direction: column;
    padding: 0;

    ${SearchAndFilterContainer} {
      order: -1;
    }

    .tabs {
      /* TabSelect dynamically calculates the rendered height of the tab
       * based on its parent, so we need to fix it here.
       */
      height: ${INPUT_HEIGHT};
    }
  }
`;

const SubmitSearchButton = styled.button`
  ${buttonReset()}
  color: white;
  background-color: ${(p) => p.theme.color.brand};
  padding: ${(p) => p.theme.spacing.small};
  border-radius: 0 4px 4px 0;
`;

const SearchForm = styled.form`
  display: flex;
  height: ${INPUT_HEIGHT};
`;

const SearchIcon = styled(SubmitSVG)`
  display: block;
`;

const AssetsContainer = styled.div`
  border-top: 1px solid ${(p) => p.theme.color.silver};
  flex: 1 1 100%;
  color: ${(p) => p.theme.color.lightGray};
  background-color: ${(p) => p.theme.color.coldWhite};
`;

const Footer = styled.div`
  ${(p) => p.theme.fontStyle.body};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(p) => p.theme.spacing.small};
  bottom: 0;
  width: 100%;
  border-top: 1px solid ${(p) => p.theme.color.silver};
  color: ${(p) => p.theme.color.gray};
  flex: 1 0 auto;
`;

const NoAssets = styled.div`
  ${alignCenter()};
  color: ${(p) => p.theme.color.gray};
  flex-direction: column;
  height: 100%;
`;

const NoAssetsHeader = styled.div`
  ${(p) => p.theme.fontStyle.heading};
  padding: ${(p) => p.theme.spacing.medium} 0 ${(p) => p.theme.spacing.micro} 0;
`;

const NoAssetsBody = styled.div`
  ${(p) => p.theme.fontStyle.body};
  text-align: center;
  color: ${(p) => p.theme.color.graphiteGray};
`;

const SearchInTeamButton = styled.button`
  ${buttonReset()};
  color: ${(p) => p.theme.color.brand};
`;

const NoAssetsIcon = styled(EmptyAssetSVG)`
  color: #a2a9ba;
  display: block;
`;

export const StyledWarningIcon = styled(WarningIcon).attrs(({ theme }) => ({
  width: 16,
  height: 16,
  color: theme.color.alert,
}))`
  margin-right: ${(p) => p.theme.spacing.tiny};
  margin-bottom: 1px; // center icon with disclaimer copy
`;

const NoAssetsFound = ({ children }) => (
  <NoAssets>
    <NoAssetsIcon />
    <NoAssetsHeader>No deleted files found</NoAssetsHeader>
    <NoAssetsBody>{children}</NoAssetsBody>
  </NoAssets>
);

NoAssetsFound.propTypes = {
  children: PropTypes.node.isRequired,
};

const NoAssetsByTeam = ({ teamName }) => (
  <NoAssets>
    <SearchAcrossTeamSVG />
    <NoAssetsHeader>Search for deleted files</NoAssetsHeader>
    <NoAssetsBody>
      <p>Search deleted files across all your</p>
      <p>
        projects within the <strong>{teamName}</strong> team.
      </p>
    </NoAssetsBody>
  </NoAssets>
);

NoAssetsByTeam.propTypes = {
  teamName: PropTypes.string.isRequired,
};

class DeletedAssetsMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBody: false,
      selectedTab: TABS.BY_PROJECT,
      projectSearchQuery: '',
      teamSearchQuery: '',
    };

    this.showBodyTimeout = null;
  }

  UNSAFE_componentWillMount() {
    /**
     * Hack to force InfiniteGrid to re-render. InfiniteGrid gets its height and
     * width from Measured.  But Measured breaks bc the modal grows in size. The
     * timeout forces a re-render after the modal is already at its max size.
     */
    this.showBodyTimeout = setTimeout(() => {
      this.setState({ showBody: true });
    }, ENTER_DURATION);
  }

  componentDidMount() {
    this.fetchData(1);
  }

  componentWillUnmount() {
    clearTimeout(this.showBodyTimeout);
  }

  getSearchQuery = () =>
    this.isTeamTab()
      ? this.state.teamSearchQuery
      : this.state.projectSearchQuery;

  getAssetIds = () => {
    const { assetIdsInProject, assetIdsInTeam } = this.props;

    if (this.isTeamTab()) {
      return assetIdsInTeam;
    }
    return assetIdsInProject;
  };

  getPaginationData = () => {
    const {
      assetsInProjectPaginationData,
      assetsInTeamPaginationData,
    } = this.props;

    if (this.isTeamTab()) {
      return assetsInTeamPaginationData;
    }
    return assetsInProjectPaginationData;
  };

  getIsFetchingFirstPage = () => {
    const { isFetchingFirstProjectPage, isFetchingFirstTeamPage } = this.props;

    if (this.isTeamTab()) {
      return isFetchingFirstTeamPage;
    }
    return isFetchingFirstProjectPage;
  };

  isTeamTab = () => this.state.selectedTab === TABS.BY_TEAM;

  fetchData = (page) => {
    const {
      getDeletedAssetsInProject,
      project,
      searchDeletedAssetsInProject,
      searchDeletedAssetsInTeam,
      selectAssets,
      team,
    } = this.props;

    const { projectSearchQuery, teamSearchQuery } = this.state;

    if (page === 1) {
      selectAssets([]);
    }

    if (this.isTeamTab()) {
      return searchDeletedAssetsInTeam(teamSearchQuery, team.id, page);
    }

    if (projectSearchQuery) {
      return searchDeletedAssetsInProject(projectSearchQuery, project.id, page);
    }

    return getDeletedAssetsInProject(project.id, page);
  };

  renderSearch = () => {
    const { resetSearchAssetsInProject, resetSearchAssetsInTeam } = this.props;
    const isTeam = this.isTeamTab();
    const queryKey = isTeam ? 'teamSearchQuery' : 'projectSearchQuery';
    const resetSearch = isTeam
      ? resetSearchAssetsInTeam
      : resetSearchAssetsInProject;
    return (
      <SearchForm
        onSubmit={(evt) => {
          evt.preventDefault();
          resetSearch();
          this.fetchData(1);
        }}
      >
        <Search
          inline
          placeholder={isTeam ? 'Search team' : 'Search project'}
          value={this.getSearchQuery()}
          autoFocus={isTeam}
          onChange={(evt) => {
            const {
              target: { value },
            } = evt;
            const wasCleared = !value;
            this.setState({ [queryKey]: value }, () => {
              if (!wasCleared) return;
              this.fetchData(1);
            });
          }}
        />
        <SubmitSearchButton type="submit">
          <SearchIcon height="10px" width="14px" />
        </SubmitSearchButton>
      </SearchForm>
    );
  };

  renderAssets = () => {
    const { team, theme, selectedAssetIds, selectAssets } = this.props;

    const { perPage, total } = this.getPaginationData();

    const { showBody } = this.state;
    const isSearching = this.getSearchQuery().length > 0;

    if (!showBody) return <div />;

    if (!total) {
      if (this.getIsFetchingFirstPage()) {
        return <PulsingCircle />;
      }

      if (this.isTeamTab()) {
        if (isSearching) {
          return (
            <NoAssetsFound>
              <p>Try refining your search.</p>
            </NoAssetsFound>
          );
        }

        return <NoAssetsByTeam teamName={team.name} />;
      }

      return (
        <NoAssetsFound>
          <p>No files in this project match your search.</p>
          <p>
            Try searching across{' '}
            <SearchInTeamButton
              onClick={() => {
                selectAssets([]);
                this.setState(
                  {
                    selectedTab: TABS.BY_TEAM,
                    teamSearchQuery: this.state.projectSearchQuery,
                  },
                  () => this.fetchData(1)
                );
              }}
            >
              {team.name}
            </SearchInTeamButton>
            .
          </p>
        </NoAssetsFound>
      );
    }
    return (
      <InfiniteGrid
        itemIds={this.getAssetIds()}
        backgroundColor={theme.color.coldWhite}
        fetchItemsForPage={this.fetchData}
        pageSize={perPage}
        totalItemCount={total}
        selectedIds={selectedAssetIds}
        setSelectedIds={selectAssets}
      >
        {({ itemId, cellIndex, thumbHeight, thumbWidth, toggleSelection }) => (
          <DeletedAsset
            assetId={itemId}
            onClick={(evt) => toggleSelection(cellIndex, evt)}
            thumbHeight={thumbHeight}
            thumbWidth={thumbWidth}
          />
        )}
      </InfiniteGrid>
    );
  };

  render() {
    const { showBody, selectedTab } = this.state;
    const {
      selectedAssetIds,
      selectAssets,
      undeleteAsset,
      team,
      project,
      isProjectArchived,
    } = this.props;

    const { total } = this.getPaginationData();

    const paginationTotalResults =
      total > 1 ? `${total} results` : `${total} result`;

    let restoreButtonCopy = 'Restore';
    const numSelected = selectedAssetIds.length;
    if (numSelected === 1) {
      restoreButtonCopy = 'Restore 1 item';
    } else if (numSelected) {
      restoreButtonCopy = `Restore ${numSelected} items`;
    }

    return (
      <Container>
        <HeaderContainer>
          <ModalHeader
            icon={
              <IconCircle
                icon="open_trash"
                color={iconCircleColors.PURPLE}
                shouldPulsate={false}
              />
            }
          >
            Deleted Files
          </ModalHeader>
        </HeaderContainer>
        <SubheaderContainer>
          <FlexItem className="tabs">
            {showBody && (
              <TabSelect
                selectedTab={selectedTab}
                onTabChange={(tab) => {
                  this.setState(
                    {
                      selectedTab: tab,
                    },
                    () => selectAssets([])
                  );
                }}
                tabs={[
                  { key: TABS.BY_TEAM, text: team.name },
                  { key: TABS.BY_PROJECT, text: project.name },
                ]}
                isDarkTheme={false}
              />
            )}
          </FlexItem>
          <SearchAndFilterContainer>
            {this.renderSearch()}
          </SearchAndFilterContainer>
        </SubheaderContainer>
        <AssetsContainer>{this.renderAssets()}</AssetsContainer>
        <Footer>
          <p>{total > 0 && paginationTotalResults}</p>
          {isProjectArchived ? (
            <Flex alignCenter>
              <StyledWarningIcon />
              <span>
                Deleted files cannot be retrieved in an archived project.
              </span>
            </Flex>
          ) : (
            <Button
              disabled={!selectedAssetIds.length}
              onClick={() =>
                selectedAssetIds.forEach((id) => undeleteAsset(id))
              }
              primary
            >
              {restoreButtonCopy}
            </Button>
          )}
        </Footer>
      </Container>
    );
  }
}

DeletedAssetsMenu.defaultProps = {
  isFetchingFirstProjectPage: false,
  isFetchingFirstTeamPage: false,
  isProjectArchived: false,
};

DeletedAssetsMenu.propTypes = {
  assetIdsInProject: PropTypes.arrayOf(PropTypes.string).isRequired,
  assetsInProjectPaginationData: PropTypes.shape({
    perPage: PropTypes.number.isRequired,
    total: PropTypes.number,
    totalPages: PropTypes.number,
  }).isRequired,
  assetIdsInTeam: PropTypes.arrayOf(PropTypes.string).isRequired,
  assetsInTeamPaginationData: PropTypes.shape({
    perPage: PropTypes.number.isRequired,
    total: PropTypes.number,
    totalPages: PropTypes.number,
  }).isRequired,
  getDeletedAssetsInProject: PropTypes.func.isRequired,
  isFetchingFirstProjectPage: PropTypes.bool,
  isFetchingFirstTeamPage: PropTypes.bool,
  isProjectArchived: PropTypes.bool,
  project: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  searchDeletedAssetsInProject: PropTypes.func.isRequired,
  searchDeletedAssetsInTeam: PropTypes.func.isRequired,
  selectedAssetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectAssets: PropTypes.func.isRequired,
  team: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  undeleteAsset: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    color: PropTypes.shape({
      coldWhite: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  resetSearchAssetsInProject: PropTypes.func.isRequired,
  resetSearchAssetsInTeam: PropTypes.func.isRequired,
};

export default withTheme(DeletedAssetsMenu);

export const testExports = {
  DeletedAssetsMenu,
  Footer,
  NoAssetsFound,
  NoAssetsByTeam,
  Search,
  SearchForm,
  SearchInTeamButton,
  StyledWarningIcon,
  TABS,
};
