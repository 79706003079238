import { generateActionTypes } from 'shared/actions/helpers';
import { createAction } from 'redux-actions';

const actions = [
  'FETCH_DATA',
  'UPDATE_TEAM',
  'CREATE_TEAM',
  'CANCEL_SUBSCRIPTION',
  'TRANSFER_OWNERSHIP',
  'GET_SUBSCRIPTION_LINE_ITEMS',
  'FETCH_CARDS_FOR_ACCOUNT',
];

export const SETTINGS_CONTAINER: Object = generateActionTypes(
  'SETTINGS_CONTAINER',
  actions
);

/**
 * @param {string} teamId - Team id.
 * @param {Object} data - Data to update a team with.
 * @returns {Action} - Action object.
 */
export const updateTeam = createAction(
  SETTINGS_CONTAINER.UPDATE_TEAM,
  (teamId, data) => ({ teamId, data })
);

/**
 * @param {string} accountId - Account id.
 * @param {Team} team - Data to create a team entity.
 * @returns {Action} - Action object.
 */
export const createTeamByAccount = createAction(
  SETTINGS_CONTAINER.CREATE_TEAM,
  (accountId, team) => ({ accountId, team })
);

/**
 * @returns {Action} - Action object.
 */
export const cancelSubscription = createAction(
  SETTINGS_CONTAINER.CANCEL_SUBSCRIPTION,
  (accountId) => ({ accountId })
);

/**
 * @returns {Action} - Action object.
 */
export const getSubscriptionLineItemsForAccount = createAction(
  SETTINGS_CONTAINER.GET_SUBSCRIPTION_LINE_ITEMS,
  (accountId) => ({ accountId })
);

export function fetchSettingsContainerData(userId) {
  return {
    type: SETTINGS_CONTAINER.FETCH_DATA,
    payload: {
      userId,
    },
  };
}

export const fetchCardsForAccount = createAction(
  SETTINGS_CONTAINER.FETCH_CARDS_FOR_ACCOUNT,
  (accountId) => ({ accountId })
);
