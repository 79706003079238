import { combineReducers } from 'redux';
import { without } from 'lodash';
import { generatePaginatedListReducer } from '@frameio/core/src/shared/reducers/factories';
import { paginatedListPageMutationReducer } from '@frameio/core/src/shared/reducers/helpers';
import COLLABORATORS from '@frameio/core/src/collaborators/actions';
import REVIEWERS from '@frameio/core/src/reviewers/actions';
import PENDING_COLLABORATORS from '@frameio/core/src/pendingCollaborators/actions';
import PENDING_REVIEWERS from '@frameio/core/src/pendingReviewers/actions';
import { getEntitiesFromNormalizedResponse } from '@frameio/core/src/shared/utils/entities';

import PEOPLE_SETTINGS from './actions';

const collaboratorsListReducer = generatePaginatedListReducer(
  PEOPLE_SETTINGS.GET_COLLABORATORS
);

function collaborators(state, action) {
  switch (action.type) {
    case COLLABORATORS.REMOVE_FROM_ACCOUNT_AS_ADMIN.SUCCESS: {
      const removedUserIds = getEntitiesFromNormalizedResponse(
        action.payload.response
      ).map(({ user_id }) => user_id);
      return paginatedListPageMutationReducer(state, (page, userIds) =>
        without(userIds, ...removedUserIds)
      );
    }
    default:
      return collaboratorsListReducer(state, action);
  }
}

const reviewersListReducer = generatePaginatedListReducer(
  PEOPLE_SETTINGS.GET_REVIEWERS
);

function reviewers(state, action) {
  switch (action.type) {
    case REVIEWERS.REMOVE_FROM_ACCOUNT_AS_ADMIN.SUCCESS: {
      const removedUserIds = getEntitiesFromNormalizedResponse(
        action.payload.response
      ).map(({ user_id }) => user_id);
      return paginatedListPageMutationReducer(state, (page, userIds) =>
        without(userIds, ...removedUserIds)
      );
    }
    default:
      return reviewersListReducer(state, action);
  }
}

const pendingCollaboratorListReducer = generatePaginatedListReducer(
  PEOPLE_SETTINGS.GET_PENDING_COLLABORATORS
);

function pendingCollaborators(state, action) {
  switch (action.type) {
    case PENDING_COLLABORATORS.REMOVE_FROM_ACCOUNT_AS_ADMIN.SUCCESS: {
      const removedPendingCollaboratorIds = action.payload.response.result;
      return paginatedListPageMutationReducer(
        state,
        (page, pendingCollaboratorIds) =>
          without(pendingCollaboratorIds, ...removedPendingCollaboratorIds)
      );
    }
    default:
      return pendingCollaboratorListReducer(state, action);
  }
}

const pendingReviewerListReducer = generatePaginatedListReducer(
  PEOPLE_SETTINGS.GET_PENDING_REVIEWERS
);

function pendingReviewers(state, action) {
  switch (action.type) {
    case PENDING_REVIEWERS.REMOVE_FROM_ACCOUNT_AS_ADMIN.SUCCESS: {
      const removedPendingCollaboratorIds = action.payload.response.result;
      return paginatedListPageMutationReducer(
        state,
        (page, pendingCollaboratorIds) =>
          without(pendingCollaboratorIds, ...removedPendingCollaboratorIds)
      );
    }
    default:
      return pendingReviewerListReducer(state, action);
  }
}

export default combineReducers({
  collaborators,
  reviewers,
  pendingCollaborators,
  pendingReviewers,
});
