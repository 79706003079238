import { connect } from 'react-redux';
import {
  aemFoldersByRepositoryIdSelector,
  isFetchingAemRepositoryTreeSelector,
  selectedAemFolderSelector,
} from './selectors';
import { selectAemDestination as fetchTree } from './actions';
import TreeAemRepositoryItem from './TreeAemRepositoryItem';

function mapStateToProps(state, { projectId, selectedAemDestination }) {
  const { repo_id } = selectedAemDestination || {};

  const selectedFolderId = selectedAemFolderSelector(state);

  const selectedAemRepositoryTree = aemFoldersByRepositoryIdSelector(
    state,
    repo_id
  );

  return {
    isLoading: isFetchingAemRepositoryTreeSelector(state),
    projectId,
    selectedAemRepositoryTree,
    selectedFolderId,
    selectedRepoId: repo_id,
  };
}

const mapDispatchToProps = {
  onFetchFolders: fetchTree,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TreeAemRepositoryItem);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
