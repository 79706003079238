import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';
import ROLES from '@frameio/core/src/roles/helpers/constants';
import { iconSizes } from '@frameio/components/src/utils/sizes';
import { alignCenter, absoluteFill } from '@frameio/components/src/mixins';
import Button from '@frameio/components/src/styled-components/Button';
import BlockProjectIcon from 'images/block-project.svg';
import BlockStorageIcon from 'images/block-storage.svg';
import BlockUploadIcon from 'images/block-upload.svg';
import BlockTeamMemberIcon from 'images/block-team-member.svg';
import CircledAlert from '@frameio/components/src/svgs/dynamic_color/circled_alert.svg';
import track, { trackButtonClick } from 'analytics';
import { getCorrectPlan, planData, plans } from 'utils/plans/plansHelpers';
import { canSelfService } from 'utils/roles';
import { limitTypes } from 'selectors/limits';
import copy, { getMailTo, getSalesMailTo } from './copy';

const LimitBlockContainer = styled.div`
  ${absoluteFill()};
  ${alignCenter()};
  background-color: ${({ theme }) => rgba(theme.color.white, 0.95)};
  flex-direction: column;
  padding: 0 15%;
  border-radius: ${(p) => p.theme.spacing.tiny};
  justify-content: center;
  text-align: center;
  z-index: 1;
`;

const StyledH3 = styled.h3`
  margin-bottom: ${(p) => p.theme.spacing.small};
  color: ${(p) => p.theme.color.almostBlack};
  ${(p) => p.theme.fontStyle.headingText};
`;

const StyledP = styled.p`
  margin-bottom: ${(p) => p.theme.spacing.large};
  color: ${(p) => p.theme.color.graphiteGray};
  ${(p) => p.theme.fontStyle.body};
`;

const DisclaimerCopy = styled(StyledP)`
  font-size: ${(p) => p.theme.fontSize[1]};
  margin-top: ${(p) => p.theme.spacing.small};
  margin-bottom: 0;
  font-style: italic;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledLink = styled.a`
  width: 100%;
  padding: ${(p) => p.theme.spacing.small};
  margin: ${(p) => p.theme.spacing.small};
  margin-bottom: 0;
  line-height: ${(p) => p.theme.lineHeight[2]};
  font-size: ${(p) => p.theme.fontSize[2]};
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: inline-block;
  position: relative;
  padding: ${(p) => p.theme.spacing.medium};
`;

const CircledAlertIcon = styled(CircledAlert)`
  color: ${(p) => p.theme.color.error};
  position: absolute;
  top: 0;
  right: 0;
  fill: currentColor;
  width: ${iconSizes.XL}px;
  height: ${iconSizes.XL}px;

  // this is needed to make sure the icon doesn't jitter
  // due to some issues with flex children positioning
  z-index: 0;
`;

const icon = {
  [limitTypes.PROJECTS]: BlockProjectIcon,
  [limitTypes.STORAGE]: BlockStorageIcon,
  [limitTypes.TEAM_MEMBERS]: BlockTeamMemberIcon,
  [limitTypes.LIFETIME_UPLOADS]: BlockUploadIcon,
};

class LimitBlock extends React.Component {
  UNSAFE_componentWillMount() {
    const { getCardsByAccount, account, hasFetchedCards } = this.props;

    if (!hasFetchedCards && account && account.id) {
      getCardsByAccount(account.id);
    }
  }

  componentDidMount() {
    const { type, eventProperties } = this.props;

    track('action-blocked', { limit: type, ...eventProperties });
  }

  getMailTo = () => {
    const { accountOwner, currentUser, type } = this.props;
    return getMailTo({ accountOwner, currentUser, type });
  };

  getSalesMailTo = () => {
    const { account, currentUser, type } = this.props;
    const nextPlan = this.getNextPlan();

    return getSalesMailTo({ account, currentUser, type, nextPlan });
  };

  getNextPlan = () => {
    const { newUsageData } = this.props;
    const planName = getCorrectPlan(newUsageData);
    const plan = planData[planName];

    return { ...plan, name: planName };
  };

  getHeaderCopy = () => {
    const { type, userRole } = this.props;

    return copy[type][userRole].header();
  };

  getBodyCopy = () => {
    const {
      account,
      accountOwner,
      canAutoChargeCard,
      currentPlan,
      userRole,
      type,
    } = this.props;
    const nextPlan = this.getNextPlan();

    return copy[type][userRole].body({
      account,
      accountOwner,
      canAutoChargeCard,
      currentPlan,
      nextPlan,
    });
  };

  getButtonCopy = () => {
    const {
      type,
      userRole,
      accountOwner,
      account,
      canAutoChargeCard,
    } = this.props;
    const nextPlan = this.getNextPlan();

    if (canSelfService(userRole) && nextPlan.name === plans.ENTERPRISE) {
      return 'Contact Sales';
    }

    return copy[type][userRole].button({
      nextPlan,
      accountOwner,
      account,
      canAutoChargeCard,
    });
  };

  getDisclaimerCopy = () => {
    const {
      type,
      userRole,
      accountOwner,
      account,
      canAutoChargeCard,
    } = this.props;
    const nextPlan = this.getNextPlan();

    if (nextPlan.name === plans.ENTERPRISE) {
      return null;
    }

    return copy[type][userRole].disclaimer({
      nextPlan,
      accountOwner,
      account,
      canAutoChargeCard,
    });
  };

  handleOnClick = (evt) => {
    trackButtonClick('block-upgrade-accepted', 'block modal', 'bottom');

    const { account, onClose, openSelectPlanFlowModal, userRole } = this.props;

    const nextPlan = this.getNextPlan();

    if (canSelfService(userRole) && nextPlan.name === plans.ENTERPRISE) {
      window.location.assign(this.getSalesMailTo());
      onClose && onClose(evt);
    } else if (canSelfService(userRole)) {
      openSelectPlanFlowModal(account.id);
    } else {
      window.location.assign(this.getMailTo());
      onClose && onClose(evt);
    }
  };

  handleOnClose = (evt) => {
    if (!this.props.onClose) return;

    trackButtonClick('block-upgrade-declined', 'block modal', 'bottom');

    this.props.onClose(evt);
  };

  render = () => {
    const {
      account,
      currentPlan,
      hasFetchedCards,
      newUsageData,
      onClose,
      userRole,
      type,
      ...rest
    } = this.props;
    const Icon = icon[type];
    return (
      <LimitBlockContainer {...rest}>
        <IconContainer>
          <Icon />
          <CircledAlertIcon />
        </IconContainer>
        <StyledH3>{this.getHeaderCopy()}</StyledH3>
        <StyledP>{this.getBodyCopy()}</StyledP>
        <StyledButton
          onClick={this.handleOnClick}
          disabled={!hasFetchedCards}
          primary
        >
          {this.getButtonCopy()}
        </StyledButton>
        {onClose && (
          <StyledLink onClick={this.handleOnClose}>No Thanks</StyledLink>
        )}
        {this.getDisclaimerCopy() && (
          <DisclaimerCopy>{this.getDisclaimerCopy()}</DisclaimerCopy>
        )}
      </LimitBlockContainer>
    );
  };
}

LimitBlock.propTypes = {
  account: PropTypes.object.isRequired,
  accountOwner: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  canAutoChargeCard: PropTypes.bool,
  currentPlan: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  eventProperties: PropTypes.object,
  getCardsByAccount: PropTypes.func.isRequired,
  hasFetchedCards: PropTypes.bool.isRequired,
  newUsageData: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  openSelectPlanFlowModal: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(limitTypes)).isRequired,
  userRole: PropTypes.oneOf(Object.values(ROLES)),
};

LimitBlock.defaultProps = {
  accountOwner: {},
  canAutoChargeCard: false,
  eventProperties: {},
  onClose: null,
  userRole: undefined,
};

export default LimitBlock;
