import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Flex, { FlexItem } from 'styled-flex-component';
import styled from 'styled-components';

const Slider = styled(({ className, transform, children, ...rest }) => (
  <Flex className={className} {...rest}>
    <FlexItem basis="100%" style={{ transform }}>
      {children}
    </FlexItem>
  </Flex>
))`
  ${FlexItem} {
    height: 100%;
  }
`;

const StyledDivForTrackSlider = styled.div`
  align-content: stretch;
  align-items center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: auto;
  height: ${(p) => p.theme.spacing.micro};
  background-color: ${(p) => p.theme.color[p.inactiveColor]};
  border-radius: ${(p) => p.theme.radius.pill};
  overflow: hidden;
  width: 100%;
`;

const StyledDivForTrackSliderChild = styled.div`
  background-color: ${(p) => p.theme.color[p.activeColor]};
  flex-grow: 0;
  flex-shrink: 1;
  height: 100%;
  transform-origin: left;
  width: 100%;
`;

const TrackSlider = ({ children, transform, ...rest }) => (
  <StyledDivForTrackSlider {...rest}>
    <StyledDivForTrackSliderChild style={{ transform }} {...rest}>
      {children}
    </StyledDivForTrackSliderChild>
  </StyledDivForTrackSlider>
);

// *NOTE* The styles below are almost all copy-paste from `InputSlider` at
// components/PlayerControlBar/VolumeControl/styles.js
const StyledInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    cursor: grabbing;
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: ${(p) => p.theme.spacing.units(2)};
    width: ${(p) => p.theme.spacing.units(2)};
    opacity: 0;
  }

  /* outline removed https://stackoverflow.com/a/21568066 (ENT-2241) */
  ::-moz-focus-outer {
    border: 0;
  }

  ::-moz-range-thumb {
    background: transparent;
    border: 0;
    height: ${(p) => p.theme.spacing.units(2)};
    width: ${(p) => p.theme.spacing.units(2)};
    opacity: 0;
  }
`;

const Handle = styled.label`
  background-color: ${(p) => p.theme.color[p.backgroundColor]};
  border-radius: ${(p) => p.theme.radius.circle};
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(91, 83, 255, 0.15),
    -3px 0 0 ${(p) => p.theme.color.white};
  display: block;
  height: ${(p) => p.theme.spacing.units(2)};
  width: ${(p) => p.theme.spacing.units(2)};
`;

const Control = styled(Slider)`
  width: calc(100% - ${(p) => p.theme.spacing.small});
`;

const Container = styled(Flex)`
  position: relative;
  width: 100%;

  /* HACK: reduce height by the parent's border width */
  height: calc(${(p) => p.theme.spacing.large} - 2px);

  ${Handle} {
    transform: scale(1);
    transition: transform 100ms ${(p) => p.theme.easing.easeInOutQuint};
  }

  &:active ${Handle} {
    transform: scale(0.85);
  }
`;

const Range = ({
  activeColor,
  value,
  handleColor,
  inactiveColor,
  max,
  min,
  onChange,
  step,
}) => {
  const asPercent = ((value - min) * 100) / (max - min);

  return (
    <Container justifyStart contentCenter alignCenter>
      <TrackSlider
        inactiveColor={inactiveColor}
        activeColor={activeColor}
        controlColor={handleColor}
        transform={`scaleX(${asPercent / 100})`}
      />
      <Control justifyStart transform={`translateX(${asPercent}%)`}>
        <Handle backgroundColor={handleColor} />
      </Control>
      <StyledInput
        aria-hidden="true"
        role="slider"
        aria-label="Slider"
        aria-valuemax={max}
        aria-valuemin={min}
        data-test-id="input-range"
        min={min}
        max={max}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        step={step}
        type="range"
        value={value}
      />
    </Container>
  );
};

Range.defaultProps = {
  activeColor: 'brand',
  handleColor: 'brand',
  inactiveColor: 'accentGrayLight',
  max: 100,
  min: 0,
  onChange: noop,
  step: 1,
};

Range.propTypes = {
  activeColor: PropTypes.string,
  handleColor: PropTypes.string,
  inactiveColor: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  step: PropTypes.number,
  value: PropTypes.number.isRequired,
};

export default Range;
