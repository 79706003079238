import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloseButton from '@frameio/components/src/components/Modal/CloseButton';
import archiveStatus from '@frameio/core/src/projects/helpers';
import preloadVideoFile from './helpers';
import initialAnimation from './ArchiveProject/initial-animation.mp4';
import InProgressAnimationPartOne from './InProgress/in-progress-pt1.mp4';
import InProgressAnimationPartTwo from './InProgress/in-progress-pt2.mp4';
import SuccesssAnimation from './Success/success.mp4';
import ArchiveProject from './ArchiveProject';
import InProgress from './InProgress';
import Success from './Success';

// these video files are used by each component in the flow
const videoFilesToPreload = [
  initialAnimation,
  InProgressAnimationPartOne,
  InProgressAnimationPartTwo,
  SuccesssAnimation,
];

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: ${(p) => p.theme.spacing.tiny};
  right: ${(p) => p.theme.spacing.tiny};
`;

const Container = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export default class ArchiveProjectFlow extends React.Component {
  state = { currentScreen: archiveStatus.STANDARD };

  componentDidMount() {
    const {
      projectId,
      isUpdatedArchivalMessagingEnabled,
      startFlow,
    } = this.props;

    videoFilesToPreload.forEach((videoFile) => preloadVideoFile(videoFile));
    startFlow(projectId, isUpdatedArchivalMessagingEnabled);
  }

  componentWillUnmount() {
    this.props.endFlow();
  }

  render() {
    const {
      closeModal,
      currentScreen,
      isUpdatedArchivalMessagingEnabled,
    } = this.props;

    return (
      <React.Fragment>
        <Container show={currentScreen === archiveStatus.STANDARD}>
          <ArchiveProject />
        </Container>
        {!isUpdatedArchivalMessagingEnabled && (
          <div>
            <Container show={currentScreen === archiveStatus.ARCHIVING}>
              {/* isAnimating props is used to start the animation when the component mounts  */}
              <InProgress
                isAnimating={currentScreen === archiveStatus.ARCHIVING}
              />
            </Container>
            <Container show={currentScreen === archiveStatus.ARCHIVED}>
              <Success closeModal={closeModal} />
            </Container>
          </div>
        )}
        {currentScreen === archiveStatus.STANDARD && (
          <StyledCloseButton onClick={closeModal} />
        )}
      </React.Fragment>
    );
  }
}

ArchiveProjectFlow.propTypes = {
  projectId: PropTypes.string.isRequired,
  startFlow: PropTypes.func.isRequired,
  endFlow: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentScreen: PropTypes.string.isRequired,
};
