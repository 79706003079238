/* eslint-disable import/prefer-default-export */
import { currentAccountWithSubscriptionAndPlanSelector } from 'selectors/accounts';
import { getLimitOverages, getAccountLimits } from 'selectors/limits';
import { call, select } from 'redux-saga/effects';
import { selectedPlanSelector } from './selectors';

export const enterpriseBackgroundUrl =
  'https://static-assets.frame.io/enterprise-card-bg.jpg';

export const isMovingToACheaperOrEqualPlan = (newPlan, originalPlan) =>
  newPlan.cost <= originalPlan.cost;

export function* getAccountOveragesWhenFreePlanWithUserMaxSelected() {
  const selectedPlan = yield select(selectedPlanSelector);
  if (!selectedPlan || !selectedPlan.storage_limit) return [];
  const { account, subscription } = yield select(
    currentAccountWithSubscriptionAndPlanSelector
  );

  const accountLimits = yield call(
    getAccountLimits,
    account,
    selectedPlan,
    subscription
  );

  const accountLimitOverages = yield call(getLimitOverages, accountLimits);

  return accountLimitOverages;
}

export const testExports = {
  isMovingToACheaperOrEqualPlan,
  getAccountOveragesWhenFreePlanWithUserMaxSelected,
};
