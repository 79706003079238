import reduceReducers from 'reduce-reducers';
import { identity } from 'lodash';

import {
  generatePaginatedListReducer,
  INITIAL_PAGINATED_LIST_STATE,
} from '@frameio/core/src/shared/reducers/factories';
import { paginatedListPageMutationReducer } from '@frameio/core/src/shared/reducers/helpers';
import { paginatedListAllResultsSelector } from '@frameio/core/src/shared/selectors';
import { reviewLinkEntitiesSelector } from '@frameio/core/src/reviewLinks/selectors';
import { PROJECT_LINKS } from './actions';
import PROJECT_LINKS_SORT_OPTIONS, {
  sortReviewLinkIds as sortReviewLinkIdsHelper,
} from './sortOptions';

export const INITIAL_STATE = {
  ...INITIAL_PAGINATED_LIST_STATE,
  isSortDescending: true,
  sortBy: PROJECT_LINKS_SORT_OPTIONS.CREATED_AT.value,
  projectId: null,
};

const linksListReducer = generatePaginatedListReducer(PROJECT_LINKS.FETCH);

// This projectId refers to the projectId that the last fetch for project links
// was made with. Note that this can be different than the projectId in
// `state.projectContainer.projectId`, which refers to the projectId that the
// last fetch for project assets was made with. This is so when we stay on the
// links tab while changing projects and hence not fetching the assets for the
// new projectId.
function projectIdReducer(state, { type, payload }) {
  if (type !== PROJECT_LINKS.FETCH.BASE) return state;

  return {
    ...state,
    projectId: payload.projectId,
  };
}

function linksSortReducer(state, { type, payload }) {
  if (type !== PROJECT_LINKS.SORT) {
    return state;
  }

  const {
    option: { value: sortBy },
    isDescending: isSortDescending,
  } = payload;

  return {
    ...state,
    sortBy,
    isSortDescending,
  };
}

function removeDeletedLinkById(state, id) {
  return paginatedListPageMutationReducer(state, (page, links) =>
    links.filter((linkId) => linkId !== id)
  );
}

function sortReviewLinkIds(state, rootState, reviewLinkIds) {
  const { isSortDescending, sortBy } = state;
  const reviewLinkIdsToSort =
    reviewLinkIds || paginatedListAllResultsSelector(state).filter(identity);
  const reviewLinkEntities = reviewLinkEntitiesSelector(rootState);

  const sortedReviewLinkIds = sortReviewLinkIdsHelper(
    reviewLinkIdsToSort,
    reviewLinkEntities,
    sortBy,
    isSortDescending,
    rootState
  );

  return paginatedListPageMutationReducer(state, (page) =>
    page === 1 ? sortedReviewLinkIds : []
  );
}

function insertLink(state, rootState, id) {
  const reviewLinkIds = paginatedListAllResultsSelector(state).filter(identity);
  const reviewLinkIdsToSort = [...reviewLinkIds, id];
  return sortReviewLinkIds(state, rootState, reviewLinkIdsToSort);
}

function linksMutationReducer(state, action, rootState) {
  switch (action.type) {
    // optimistically remove deleted links
    case PROJECT_LINKS.DELETE.BASE: {
      const { id } = action.payload;
      return removeDeletedLinkById(state, id);
    }

    // rollback on delete failure by re-inserting optimistically removed assets
    case PROJECT_LINKS.DELETE.FAILURE: {
      const { entityId: id } = action.payload;
      return insertLink(state, rootState, id);
    }

    case PROJECT_LINKS.DUPLICATE.SUCCESS: {
      const { result: id } = action.payload.response;
      return insertLink(state, rootState, id);
    }

    case PROJECT_LINKS.PATCH_SORT: {
      return sortReviewLinkIds(state, rootState);
    }

    default:
      return state;
  }
}

export default reduceReducers(
  projectIdReducer,
  linksListReducer,
  linksSortReducer,
  linksMutationReducer
);
