import { getCardsByAccount } from '@frameio/core/src/cards/sagas';
import { takeLatest, put, select, call } from 'redux-saga/effects';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import {
  currentAccountSelector,
  isAccountEnterpriseSelector,
} from 'selectors/accounts';
import updateStripeCustomer from 'components/CreditCardFormElements/sagas';
import { updateModal, closeModal } from 'components/Modal/actions';
import { TRIAL_COUNTDOWN, isFetching } from 'components/TrialCountdown/actions';
import { firstCardEntityByAccountIdSelector } from '@frameio/core/src/cards/selectors';
import { isAccountAdminSelector } from 'selectors/roles';
import { subscriptionEntityByAccountIdSelector } from 'selectors/subscriptions';
import { shouldFetchCardsByAccount } from 'sagas/accounts';

export function* shouldSeePlanSelectionModalDuringTrial(accountId) {
  const isAdmin = yield select(isAccountAdminSelector, { accountId });
  const isEnterprise = yield select(isAccountEnterpriseSelector);
  const subscription =
    (yield select(subscriptionEntityByAccountIdSelector, accountId)) || {};
  const onTrial = !!subscription.on_trial;
  yield call(shouldFetchCardsByAccount, accountId);
  const card = yield select(firstCardEntityByAccountIdSelector, { accountId });
  const hasCard = !!card;
  const isTargetUser = isAdmin && onTrial && !isEnterprise;

  return !hasCard && isTargetUser;
}

function* addCreditCardToTrialCountdown(stripeResponse) {
  const { token } = stripeResponse;
  yield put(isFetching(true));
  yield put(updateModal({ canCloseModal: false }));
  const account = yield select(currentAccountSelector);
  const accountId = account.id;
  const { success } = yield call(updateStripeCustomer, accountId, token?.id);
  if (success) {
    yield call(getCardsByAccount, accountId);
    yield put(showSuccessToast({ header: 'Card successfully updated!' }));
    yield put(closeModal());
  } else {
    yield put(
      showErrorToast({
        header: 'Card did not update. Please try again',
      })
    );
  }

  yield put(isFetching(false));
  yield put(updateModal({ canCloseModal: true }));
}

export const testExports = {
  addCreditCardToTrialCountdown,
  shouldSeePlanSelectionModalDuringTrial,
};

export default [
  takeLatest(TRIAL_COUNTDOWN.ADD_CARD, ({ payload: { stripeResponse } }) =>
    addCreditCardToTrialCountdown(stripeResponse)
  ),
];
