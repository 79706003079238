import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

const module = 'REQUEST_ACCESS_TO_ENTITY';
export const REQUEST_ACCESS_TO_ENTITY: Object = {
  ...generateActionTypes(module, ['REQUEST_ACCESS', 'RETURN_TO_ACCOUNT']),
};

export const requestAccessToEntity = createAction(
  REQUEST_ACCESS_TO_ENTITY.REQUEST_ACCESS,
  (entityType, entityId) => ({ entityType, entityId })
);

export const returnToAccount = createAction(
  REQUEST_ACCESS_TO_ENTITY.RETURN_TO_ACCOUNT,
  () => ({})
);
