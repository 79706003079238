import PropTypes from 'prop-types';
import { filter, get } from 'lodash';
import {
  label as WORKFLOW_STATUSES,
  type as AssetType,
} from '@frameio/core/src/assets/helpers/constants';

const SORT_OPTIONS = {
  CUSTOM: {
    value: 'index',
    canSortDescending: false,
    sortDescendingByDefault: false,
    label: 'Custom',
  },
  RECENCY: {
    value: 'uploaded_at',
    canSortDescending: true,
    sortDescendingByDefault: true,
    label: 'Date uploaded',
    asc: 'Oldest first',
    desc: 'Newest first',
  },
  NAME: {
    value: 'name',
    canSortDescending: true,
    sortDescendingByDefault: false,
    label: 'Name',
  },
  TYPE: {
    value: 'filetype',
    canSortDescending: true,
    sortDescendingByDefault: false,
    label: 'Type',
  },
  SIZE: {
    value: 'filesize',
    canSortDescending: true,
    sortDescendingByDefault: true,
    label: 'Size',
    asc: 'Smallest first',
    desc: 'Largest first',
  },
  WORKFLOW_STATUS: {
    value: 'label',
    canSortDescending: true,
    sortDescendingByDefault: false,
    label: 'Status',
    // The server sorts in reverse order for the status labels because they are represented
    // as an enum and stored as integers, with `approved` being the highest value (3) and
    // `none` being the lowest (0).
    asc: 'No status first',
    desc: 'Approved first',
  },
  COMMENTS: {
    value: 'comment_count',
    canSortDescending: true,
    sortDescendingByDefault: false,
    label: 'Comments',
    asc: 'Fewest comments',
    desc: 'Most comments',
  },
};

export const propType = PropTypes.oneOf(
  Object.values(SORT_OPTIONS).map((option) => option.value)
);

// TODO: This should ideally come from massdriver. This enum is useful for
// client-side sorting since the text representations cannot be sorted in reverse order.
// See https://github.com/Frameio/massdriver/blob/develop/apps/core/lib/core/projects/schema/asset.ex#L20
const WORKFLOW_STATUS_ENUM = {
  [WORKFLOW_STATUSES.NONE]: 0,
  [WORKFLOW_STATUSES.IN_PROGRESS]: 1,
  [WORKFLOW_STATUSES.NEEDS_REVIEW]: 2,
  [WORKFLOW_STATUSES.APPROVED]: 3,
};

/**
 * Gets the value of the asset to sort by.
 * @param {Asset} - Hydrated asset entitiy.
 * @param {string} - Name of the field to sort by.
 * @return {*}
 */
function getAssetSortValue(asset, sortByField) {
  const isCustomSort = sortByField === SORT_OPTIONS.CUSTOM.value;
  const sortValue =
    asset.type === AssetType.VERSION_STACK && !isCustomSort
      ? get(asset, `cover_asset.${sortByField}`, asset[sortByField])
      : asset[sortByField];
  if (sortByField === SORT_OPTIONS.WORKFLOW_STATUS.value) {
    return WORKFLOW_STATUS_ENUM[sortValue];
  }
  return typeof sortValue === 'string' ? sortValue.toLowerCase() : sortValue;
}

/**
 * Sorts an array of assets given a field to sort by and whether or not to sort descending.
 * @param {Object[]} assets - Array of asset ids.
 * @param {Object} entitiesMap - Map of ids to asset, placeholders or other asset-like entities.
 * @param {string} sortByField - Key of the object to sort by.
 * @param {boolean} sortDescending - Whether or not to sort descending.
 * @returns {string[]} Returns sorted ids.
 */
export function sortAssetIds(
  assetIds,
  entitiesMap,
  sortByField = SORT_OPTIONS.CUSTOM.value,
  sortDescending
) {
  const sortedAssets = filter(assetIds)
    .map((id) => entitiesMap[id])
    .sort((a, b) => {
      const aValue = getAssetSortValue(a, sortByField);
      const bValue = getAssetSortValue(b, sortByField);

      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return 0;
    });
  if (sortDescending) {
    sortedAssets.reverse();
  }
  // This preserves any undefined/holes in the sparse array, sorting the ids and then
  // placing them back into indices that previously had ids in them.
  return assetIds.map((asset) => asset && sortedAssets.shift().id);
}

export const testExports = {
  getAssetSortValue,
  WORKFLOW_STATUS_ENUM,
};

export default SORT_OPTIONS;
