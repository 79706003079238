import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Flex from 'styled-flex-component';
import PulsingCircle from '@frameio/components/src/styled-components/PulsingCircle';
import DropZone from './DropZone';

const RemoveButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  ${(p) => p.theme.fontStyle.body}
  color: ${(p) => p.theme.color.white};
  border-radius: ${(p) => p.theme.radius.circle};
  outline: none;
  transition: opacity .2s ease-in-out;
  opacity: 0;
`;

const Copy = styled.p`
  font-size: ${(p) => p.theme.fontSize[2]};
  color: ${(p) => p.theme.color.gray};
  text-align: center;
  margin-top: 20px;

  a {
    cursor: pointer;
    color: ${(p) => p.theme.color.gray};
    text-decoration: underline;
  }
`;

const PulingCircleContainer = styled(Flex)`
  height: 140px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  margin: 0 auto;

  &:hover ${RemoveButton} {
    opacity: 1;
  }
`;

const TeamImage = styled.img`
  border-radius: ${(p) => p.theme.radius.circle};
  width: 100%;
  height: 100%;
`;

const SelectFileOption = styled.a``;

const Container = styled(Flex).attrs(() => ({
  column: true,
  justifyCenter: true,
}))`
  flex-grow: 1;
`;

const UploadImage = ({
  isUploading,
  imageUrl,
  removeImage,
  uploadDroppedItem,
  uploadSelectedItem,
  imageSize,
}) => (
  <React.Fragment>
    {isUploading && (
      <PulingCircleContainer center full>
        <PulsingCircle size={25} />
      </PulingCircleContainer>
    )}
    {!isUploading && !imageUrl && (
      <Container>
        <DropZone
          onDrop={(dataTransfer) => {
            const { files } = dataTransfer;
            uploadDroppedItem(files[0]);
          }}
          imageSize={imageSize}
        />
        <Copy>
          Drop or{' '}
          <SelectFileOption
            tabIndex="0"
            role="button"
            onClick={uploadSelectedItem}
          >
            Select a file
          </SelectFileOption>
        </Copy>
      </Container>
    )}
    {!isUploading && imageUrl && (
      <Container>
        <ImageContainer width={imageSize} height={imageSize}>
          <RemoveButton center onClick={removeImage}>
            Remove
          </RemoveButton>
          <TeamImage src={imageUrl} alt="team logo" />
        </ImageContainer>
      </Container>
    )}
  </React.Fragment>
);

export default UploadImage;

UploadImage.propTypes = {
  isUploading: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string,
  removeImage: PropTypes.func.isRequired,
  uploadDroppedItem: PropTypes.func.isRequired,
  uploadSelectedItem: PropTypes.func.isRequired,

  // The image is square so only one value is needed instead of passing imageWidth and imageHeight.
  imageSize: PropTypes.number,
};

UploadImage.defaultProps = {
  imageUrl: null,
  imageSize: 120,
};

export const testExports = {
  RemoveButton,
  SelectFileOption,
  TeamImage,
};
