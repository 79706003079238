import React from 'react';
import PropTypes from 'prop-types';
import { MenuButton } from '@frameio/vapor';
import PencilSVG from '@frameio/components/src/svgs/icons/pencil.svg';

export default function RenameAsset({
  assetId,
  promptRenameAsset,
  trackingPage,
  trackingPosition,
  ...rest
}) {
  return (
    <MenuButton
      onSelect={() => {
        promptRenameAsset(assetId, trackingPage, trackingPosition);
      }}
      iconBefore={<PencilSVG width={16} height={16} />}
      {...rest}
    >
      Rename
    </MenuButton>
  );
}

RenameAsset.propTypes = {
  promptRenameAsset: PropTypes.func.isRequired,
  assetId: PropTypes.string.isRequired,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};
