// import history from 'router/history';
import { call, select, spawn } from 'redux-saga/effects';
import axios from 'axios';
import config from 'config';
import { get } from 'lodash';
import track from 'analytics';
import { currentUserEntitySelector } from 'selectors/users';

// These are helpers to identify and track if a user signing up is a Marketing Qualified Lead.
// Copied from auth-portal and modified slightly for this specific tracking criteria

/**
 * Fetch data from Clearbit Reveal (https://clearbit.com/docs#reveal-api-client-side-endpoint)
 * @returns {Object} - Company info based on the user's IP address.
 */
function getClearbitData() {
  return axios({
    method: 'get',
    url: `https://reveal.clearbit.com/v1/companies/reveal?authorization=${config.clearbitRevealKey}`,
  }).then((response) => response.data);
}

function* isUserQualifiedLead() {
  const primaryIndustryGroup = [
    'Media',
    'Movies & Entertainment',
    'Broadcasting',
  ];
  const secondaryIndustryGroup = [
    'Publishing',
    'Advertising',
    'Education Services',
    'Public Relations',
    'Internet',
    'Hotels, Restaurants & Leisure',
    'Consulting',
    'Communications',
    'Integrated Telecommunication Services',
    'Wireless Telecommunication Services',
  ];

  let companyData;

  try {
    companyData = yield call(getClearbitData);
  } catch (error) {
    return false;
  }
  const employeeCount = yield call(
    get,
    companyData,
    'company.metrics.employees',
    0
  );
  const industry = yield call(get, companyData, 'company.category.subIndustry');

  return (
    employeeCount > 1000 ||
    (primaryIndustryGroup.includes(industry) && employeeCount > 50) ||
    (secondaryIndustryGroup.includes(industry) && employeeCount > 250)
  );
}

const genericDomains = [
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'aol.com',
  'hotmail.com',
];

function* maybeTrackMQLOrMGLEmail() {
  const isQualifiedLead = yield call(isUserQualifiedLead);
  const { email: currentUserEmail } = yield select(currentUserEntitySelector);
  const hasWebMail = genericDomains.find((domain) =>
    currentUserEmail.includes(domain)
  );

  if (isQualifiedLead && !hasWebMail) {
    yield spawn(track, 'mql-first-app-page-viewed');
  }

  if (!isQualifiedLead && !hasWebMail) {
    yield spawn(track, 'mgl-first-app-page-viewed');
  }
}

export const testExports = {
  getClearbitData,
  isUserQualifiedLead,
  maybeTrackMQLOrMGLEmail,
};

export default maybeTrackMQLOrMGLEmail;
