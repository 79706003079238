import React from 'react';
import PropTypes from 'prop-types';
import { get, keys, isEmpty } from 'lodash';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { Helmet } from 'react-helmet-async';
import Button from '@frameio/components/src/styled-components/Button';
import CancelSVG from '@frameio/components/src/svgs/raw/ic-universal-close-12px.svg';
import TextInput, {
  ErrorMessage,
} from '@frameio/components/src/styled-components/TextInput';
import { Select, MenuRadio } from '@frameio/vapor';
import track from 'analytics';
import { SMALL } from 'utils/mediaQueries';
import useGetElementSize from 'utils/useGetElementSize';
import ProvideElementSize from 'utils/ProvideElementSize';
import SessionWatermarkTemplateBlockDropTarget from './SessionWatermarkTemplateBlockDropTarget';
import SessionWatermarkTemplateDotBackground from './SessionWatermarkTemplateDotBackground';
import WatermarkBlockSelector from './WatermarkBlockSelector';
import WatermarkTemplateSidebar from './WatermarkTemplateSidebar/WatermarkTemplateSidebar';
import SessionWatermarkTemplateAddButton from './SessionWatermarkTemplateAddButton';
import {
  Text,
  StyledCheckbox,
  StyledIcon,
  StyledButton,
  StyledFlex,
  VaporSelectChildContent,
} from './styles';
import {
  PREVIEW_IMAGE_UPDATE_TIMEOUT,
  placeholdersByAspectRatio,
  aspectRatioOrder,
} from './SessionWatermarkTemplateEditor';
import { RESTRICTED_EDITOR_MESSAGE } from './constants';
import useCloseHotkey from './useCloseHotkey';

// SMALL VIEWPORT STYLES
const LayoutWrapper = styled.div`
  width: 100vw;
  height: 100%;
  overflow-y: auto;
`;

const PreviewImageLayout = styled.div`
  height: 25%;
  min-height: 275px; // Determined by design in ENT-2472
  position: relative;
`;

const StyledPreviewImageLayoutFlex = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  transition: opacity 250ms 250ms ease;
`;

const LoadingScrim = styled.div`
  background: linear-gradient(
      86.91deg,
      #e5e6ed 1.31%,
      #eeeff2 34.61%,
      rgba(255, 255, 255, 0.657) 53%,
      rgba(255, 255, 255, 0) 98.65%
    ),
    linear-gradient(0deg, #e3e6ec, #e3e6ec);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  pointer-events: none;
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
  transition: opacity 500ms ease;
`;

const StyledTopFlex = styled(Flex)`
  margin-bottom: ${(p) => (p.bottomBuffer ? p.theme.spacing.units(2) : '0')};
  padding: ${(p) => p.theme.spacing.units(1)} ${(p) => p.theme.spacing.units(1)}
    ${(p) => p.theme.spacing.units(1)} ${(p) => p.theme.spacing.units(2)};
`;

const StyledControlsFlex = styled(Flex)`
  padding: 0 ${(p) => p.theme.spacing.units(3)}
    ${(p) => p.theme.spacing.units(2)} 0;

  @media ${SMALL} {
    display: block;
  }
`;

const AspectRatioContainer = styled(Flex)`
  @media ${SMALL} {
    margin: ${(p) => p.theme.spacing.units(2)} 0 0
      ${(p) => p.theme.spacing.units(2)};
  }
`;

const StyledBlockDetailsFlex = styled(Flex)`
  border-bottom: 1px solid ${(p) => p.theme.color.silver};
  position: relative;
  padding: ${(p) => p.theme.spacing.units(3)} ${(p) => p.theme.spacing.units(4)}
    ${(p) => p.theme.spacing.units(2)} ${(p) => p.theme.spacing.units(4)};
`;

const AddBlockWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: ${(p) => p.theme.spacing.units(4)};
  height: 100%;
`;

const SaveButtonEventWrapper = styled.div`
  display: block;
  padding: 0 ${(p) => p.theme.spacing.units(4)};
  margin: ${(p) => p.theme.spacing.units(-2)} 0
    ${(p) => p.theme.spacing.units(4)};
`;

const FullWidthStyledButton = styled(StyledButton)`
  display: block;
  width: 100%;
`;

const GridPreviewImageWrapper = styled.div`
  position: relative;
`;

/* HACK: band aid for ENT-2265 */
const StyledTextInputContainer = styled.div`
  height: 52px;
  overflow-y: visible;

  ${ErrorMessage} {
    margin-top: ${(p) => p.theme.spacing.units(1)};
  }
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
  padding-left: ${(p) => (p.error ? undefined : 0)};
  border-color: ${(p) => (p.error ? undefined : 'white')};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: all 25ms ease-out, padding 150ms ease;

  ${(p) =>
    !p.disabled &&
    `
  &:active,
  &:focus,
  &:hover {
    padding-left: ${p.theme.spacing.tiny};
  }

  &:hover {
    border-color: ${p.error ? undefined : p.theme.color.silver};
  }

  &:active,
  &:focus {
    border-color: ${p.error ? undefined : p.theme.color.brand};
    color: ${p.theme.color.black};
  }
  `}

  /* HACK: this specificity is required to override base TextInput */
  && {
    ${(p) => p.theme.fontStyle.headerS}
    background-color: ${(p) => p.disabled && 'transparent'};
  }
  &::placeholder {
    color: ${(p) => p.theme.color.lightGray};
    ${(p) => p.theme.fontStyle.headerS}
  }
`;

const StyledSelect = styled(Select)`
  min-width: ${(p) => p.theme.spacing.units(21)};
`;

const StyledVaporSelectChildContent = styled(VaporSelectChildContent)`
  width: ${(p) => p.theme.spacing.units(11)};
`;

const StyledMenuRadio = styled(MenuRadio)`
  height: ${(p) => p.theme.spacing.units(3.85)};
`;

const StyledFlexItem = styled(FlexItem)`
  position: relative;
`;

const StyledText = styled(Text)`
  display: inline-block;
  padding: 0 ${(p) => p.theme.spacing.micro} ${(p) => p.theme.spacing.micro};
`;

function SessionWatermarkTemplateColumnLayout(props) {
  const {
    aspectRatio,
    aspectRatioChange,
    currentViewportSize,
    dirty,
    effectiveInitialValues,
    errors,
    handleSubmit,
    isAdmin,
    isDebugModeEnabled,
    isDevToolsAllowed,
    isFrameGuideEnabled,
    isShareOnlyTemplate,
    nameFieldRef,
    onAspectRatioChange,
    onClose,
    onDebugModeChange,
    onFrameGuideChange,
    onPreviewImageUpdate,
    previewImageIsUpdating,
    setAspectRatio,
    setFieldValue,
    values,
  } = props;
  const [gridPreviewImageRef, previewImageBounds] = useGetElementSize([
    aspectRatioChange,
    ...currentViewportSize,
  ]);

  const [previewImageHasLoaded, setPreviewImageHasLoaded] = React.useState(
    false
  );

  const setWatermarkFieldValue = React.useCallback(
    (field, value) => {
      const prefix = `watermark_blocks[${values.activeBlockId}]`;
      setFieldValue(`${prefix}.${field}`, value);
      if (field === 'rotation' && value !== 0) {
        setFieldValue(`${prefix}.scroll_text`, 'none');
      }
      if (field === 'scroll_text' && value !== 'none') {
        setFieldValue(`${prefix}.rotation`, 0);
      }
    },
    [setFieldValue, values.activeBlockId]
  );
  // Delaying this state change helps prevent a flash of empty content when the preview loads immediately
  const handlePreviewImageLoaded = () => {
    setTimeout(() => {
      setPreviewImageHasLoaded(true);
    }, PREVIEW_IMAGE_UPDATE_TIMEOUT);
  };

  useCloseHotkey(dirty);

  // This effect is used to help define the exact dimensions of where the background grid should draw by waiting until the asset has time to render.
  // It accomplishes this by waiting a brief period of time before telling the local state of the parent via callback that the asset is done updating its bounds, after which the grid will finish rendering.
  // A useEffect instead of useLayoutEffect fires too soon, and causes minor inaccuracies.
  React.useLayoutEffect(() => {
    setTimeout(() => {
      onPreviewImageUpdate(false);
    }, PREVIEW_IMAGE_UPDATE_TIMEOUT);
  }, [previewImageBounds, onPreviewImageUpdate]);

  return (
    <LayoutWrapper>
      <StyledTopFlex alignCenter justifyBetween bottomBuffer={!!errors.name}>
        <FlexItem grow={1}>
          <StyledTextInputContainer>
            <StyledTextInput
              data-test-id="template-name-input"
              disabled={isShareOnlyTemplate}
              error={errors.name}
              onBlur={(evt) => {
                if (!evt.target.value) {
                  setFieldValue('name', effectiveInitialValues.name);
                }
              }}
              onChange={(e) => {
                setFieldValue('name', e.target.value);
              }}
              onFocus={(evt) => {
                evt.target.select();
              }}
              placeholder="Untitled watermark template"
              ref={nameFieldRef}
              type="text"
              value={values.name}
            />
          </StyledTextInputContainer>
        </FlexItem>
        {isShareOnlyTemplate && (
          <FlexItem noShrink>
            <StyledText variant="headerS" color="brand">
              [Modifying template]
            </StyledText>
          </FlexItem>
        )}
        <FlexItem>
          <Button text icon onClick={onClose}>
            <StyledIcon height={16} width={16} as={CancelSVG} />
          </Button>
        </FlexItem>
      </StyledTopFlex>
      <StyledControlsFlex justifyBetween>
        <Flex column>
          {!isShareOnlyTemplate && (
            <Text as="label" variant="bodyM" gutter={[0, 0, 0, 2]}>
              <StyledCheckbox
                size="16px"
                onChange={() => onFrameGuideChange(!isFrameGuideEnabled)}
                checked={isFrameGuideEnabled}
              />{' '}
              Show safe zone
            </Text>
          )}
          {isDevToolsAllowed && (
            <Text as="label" variant="bodyM" gutter={[0, 0, 0, 2]}>
              <StyledCheckbox
                size="16px"
                onChange={() => onDebugModeChange(!isDebugModeEnabled)}
                checked={isDebugModeEnabled}
              />{' '}
              Show debugger
            </Text>
          )}
        </Flex>

        <AspectRatioContainer alignCenter>
          <StyledFlexItem grow={0}>
            <StyledSelect
              shouldUsePortal
              value={aspectRatio}
              offset={[0, 8]}
              onChange={(value) => {
                track(
                  'session-watermark-template-editor-aspect-ratio-updated',
                  { value, watermark_template_id: values.id }
                );

                onPreviewImageUpdate(true);
                // The timeout lets the grid fade out before the asset shape changes
                setTimeout(() => {
                  setAspectRatio(value);
                  onAspectRatioChange();
                }, PREVIEW_IMAGE_UPDATE_TIMEOUT / 2);
              }}
              placement="bottom-end"
              content={aspectRatioOrder.map((ratio) => (
                <StyledMenuRadio
                  iconBefore={
                    <StyledIcon
                      as={get(placeholdersByAspectRatio, [ratio, 'icon'])}
                      color="graphiteGray"
                      height={16}
                      width={16}
                    />
                  }
                  key={ratio}
                  name={ratio}
                >
                  <Text gutter={[0, 0, 0, 0.5]}>{ratio}</Text>
                </StyledMenuRadio>
              ))}
            >
              <StyledVaporSelectChildContent height={4}>
                {aspectRatio}
              </StyledVaporSelectChildContent>
            </StyledSelect>
          </StyledFlexItem>
        </AspectRatioContainer>
      </StyledControlsFlex>

      <PreviewImageLayout>
        <ProvideElementSize>
          {({ width, height }) => (
            <StyledPreviewImageLayoutFlex>
              <LoadingScrim isVisible={!previewImageHasLoaded} />
              <SessionWatermarkTemplateDotBackground
                isVisible={!previewImageIsUpdating}
                previewImageBounds={previewImageBounds}
                transitionLength={PREVIEW_IMAGE_UPDATE_TIMEOUT}
                width={width}
                height={height}
                density={20}
              />
              <GridPreviewImageWrapper ref={gridPreviewImageRef}>
                <SessionWatermarkTemplateBlockDropTarget
                  maxWidth={width}
                  maxHeight={height}
                  blockIds={keys(values.watermark_blocks)}
                  imgNaturalHeight={get(placeholdersByAspectRatio, [
                    aspectRatio,
                    'naturalHeight',
                  ])}
                  imgNaturalWidth={get(placeholdersByAspectRatio, [
                    aspectRatio,
                    'naturalWidth',
                  ])}
                  imgSrc={get(placeholdersByAspectRatio, [aspectRatio, 'src'])}
                  isDebugModeEnabled={isDebugModeEnabled}
                  isFrameGuideEnabled={isFrameGuideEnabled}
                  updateBlock={(id, value) =>
                    setFieldValue(`watermark_blocks[${id}]`, {
                      ...values.watermark_blocks[id],
                      ...value,
                    })
                  }
                  onLoad={handlePreviewImageLoaded}
                />
                <Helmet>
                  {aspectRatioOrder.map((entry) => (
                    <link
                      as="image"
                      href={get(placeholdersByAspectRatio, [entry, 'src'])}
                      key={entry}
                      rel="preload"
                    />
                  ))}
                </Helmet>
              </GridPreviewImageWrapper>
            </StyledPreviewImageLayoutFlex>
          )}
        </ProvideElementSize>
      </PreviewImageLayout>
      {!isShareOnlyTemplate && (
        <StyledBlockDetailsFlex justifyCenter alignCenter>
          <WatermarkBlockSelector />
          <AddBlockWrapper>
            <SessionWatermarkTemplateAddButton
              disabled={!previewImageHasLoaded}
              aria-label="Create new text block"
            />
          </AddBlockWrapper>
        </StyledBlockDetailsFlex>
      )}

      <StyledFlex
        justifyBetween
        alignCenter
        padding={[2, 3, 0, 4]}
        gutter={[0, 0, 1, 0]}
      >
        <FlexItem>
          <Text as="h2" variant="headerXS" gutter={[1 / 8, 0, 0]}>
            Edit selected block
          </Text>
        </FlexItem>
      </StyledFlex>

      {isShareOnlyTemplate && (
        <Text
          as="div"
          backgroundColor="coldWhite"
          borderRadius="default"
          color="gray"
          padding={[1, 2]}
          gutter={[2, 4, 2]}
          data-test-id="restricted-message"
        >
          {RESTRICTED_EDITOR_MESSAGE}
        </Text>
      )}
      <WatermarkTemplateSidebar
        isAdmin={isAdmin}
        activeBlock={get(values.watermark_blocks, [values.activeBlockId])}
        errors={get(errors, ['watermark_blocks', values.activeBlockId])}
        isDebugModeEnabled={isDebugModeEnabled}
        isShareOnlyTemplate={isShareOnlyTemplate}
        totalBlocksCount={Object.keys(values.watermark_blocks).length}
        setBlockValue={setWatermarkFieldValue}
        watermarkTemplateId={values.id}
      />

      <SaveButtonEventWrapper data-test-id="save-btn-evt-wrapper">
        <FullWidthStyledButton
          primary
          data-test-id="save-btn"
          onClick={handleSubmit}
          disabled={!isEmpty(errors) || !dirty || !previewImageHasLoaded}
          type="submit"
        >
          Save changes
        </FullWidthStyledButton>
      </SaveButtonEventWrapper>
    </LayoutWrapper>
  );
}

SessionWatermarkTemplateColumnLayout.propTypes = {
  isDevToolsAllowed: PropTypes.bool,
  effectiveInitialValues: PropTypes.object,
  currentViewportSize: PropTypes.array,
  onPreviewImageUpdate: PropTypes.func,
  previewImageIsUpdating: PropTypes.bool,
  onClose: PropTypes.func,
  aspectRatioChange: PropTypes.bool,
  onAspectRatioChange: PropTypes.func,
  isFrameGuideEnabled: PropTypes.bool,
  onFrameGuideChange: PropTypes.func,
  isDebugModeEnabled: PropTypes.bool,
  onDebugModeChange: PropTypes.func,
  // nameFieldRef: PropTypes.any, // React.ref
  dirty: PropTypes.bool,
  // errors: PropTypes.any, // Formik<any>
  handleSubmit: PropTypes.func,
  isValid: PropTypes.bool,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
};

export default SessionWatermarkTemplateColumnLayout;
