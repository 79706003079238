import { connect } from 'react-redux';
import { getPublicReviewLinkUrl } from 'URLs';
import { copyShortUrlToClipboard } from 'pages/ProjectContainer/ProjectLinks/actions';
import { currentAccountSelector } from 'selectors/accounts';
import { permittedActionsForAccountSelector } from 'selectors/permissions';
import { isAccountAdminSelector } from 'selectors/roles';
import { projectIdSelector } from 'pages/ProjectContainer/selectors';
import { sendReviewLinkEmails, upgradePlan } from '../actions';
import { reviewLinkEntityForEditorSelector } from '../selectors';
import Settings from './Settings';

const mapStateToProps = (state) => {
  const { id, short_url, items: itemIds } = reviewLinkEntityForEditorSelector(
    state
  );
  const reviewLinkUrl = getPublicReviewLinkUrl(id, short_url);
  const { id: accountId } = currentAccountSelector(state);
  const projectId = projectIdSelector(state);
  const {
    canToggleReviewLinkComments,
    canUsePasswordProtectedShares,
    canUseShareLinkExpiration,
  } = permittedActionsForAccountSelector(state, { accountId });
  const isAdmin = isAccountAdminSelector(state, { accountId });

  return {
    accountId,
    canToggleComments: canToggleReviewLinkComments,
    canUsePasswordProtectedShares,
    canUseShareLinkExpiration,
    id,
    isAdmin,
    itemIds,
    projectId,
    reviewLinkUrl,
  };
};

const mapDispatchToProps = {
  sendReviewLinkEmails,
  copyShortUrlToClipboard,
  upgradePlan,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);

export const testExports = { mapStateToProps, mapDispatchToProps };
