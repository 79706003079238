import Button from '@frameio/components/src/styled-components/Button';
import Dialog, {
  dialogTypes,
} from '@frameio/components/src/styled-components/Dialog';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { removeAuthCookies } from 'utils/auth';
import { redirectToAuth, reloadCurrentRoute } from 'utils/router';
import { obfuscateEmail } from 'utils/email';

/**
 * Logs the user out and reloads the current route.
 *
 * @returns {void}
 */
const continueWithoutLoggingIn = () => {
  removeAuthCookies();
  reloadCurrentRoute();
};

/**
 * Redirects the user to the auth portal.
 * The user will be redirected back to the current page after logging in.
 *
 * @returns {void}
 */
const redirectToLogin = () => {
  redirectToAuth({}, true, {
    details: 'Review link user clicked "Log in" on session expired modal',
    location: window?.location?.href,
  });
};

/**
 * Calls focus on the given node if it exists.
 *
 * @param {HTMLElement} node - The node to focus.
 * @returns {void}
 */
const focus = (node) => node?.focus();

const StyledDialog = styled(Dialog)`
  padding: ${({ theme }) => theme.spacing.units(2)};
`;

const Text = styled.p`
  color: ${({ theme }) => theme.color.gray};
  padding: 0 10px;
`;

const loginButton = (
  <Button ref={focus} onClick={redirectToLogin}>
    Log in
  </Button>
);

const continueButton = (
  <Button onClick={continueWithoutLoggingIn}>
    Continue without logging in
  </Button>
);

/**
 * This component is used for public review links where a user's
 * auth has expired and is unrefreshable. It allows the user a choice
 * to log in or continue without logging in.
 */
const SessionExpiredModal = ({ email }) => (
  <StyledDialog
    header="Session Expired"
    primaryButton={loginButton}
    secondaryButton={continueButton}
    type={dialogTypes.NEUTRAL}
  >
    {email && (
      <Text>
        You were signed in as <strong>{obfuscateEmail(email)}</strong>.
      </Text>
    )}
  </StyledDialog>
);

SessionExpiredModal.propTypes = { email: PropTypes.string };

export default SessionExpiredModal;
