import React from 'react';
import PropTypes from 'prop-types';
import { map, find } from 'lodash';
import Media from 'react-media';
import { MEDIUM_UP } from 'utils/mediaQueries';
import { MenuRadio, Menu, MenuGroup, useInitialValue } from '@frameio/vapor';
import DropdownButton from '@frameio/components/src/styled-components/Button/DropdownButton';
import Button from '@frameio/components/src/styled-components/Button';
import SortIcon from '@frameio/components/src/svgs/icons/24/sort.svg';
import Flex from 'styled-flex-component';
import SharePanelDropdownButton from '../SharePanel/DropDown/DropdownButton';
import FeatureGateTooltip from './FeatureGateTooltip';

const DEFAULT_SORT_ORDER_TEXT = {
  asc: 'A to Z',
  desc: 'Z to A',
};
export default function SortMenu({
  options,
  setSort,
  sortBy,
  isDescending,
  isSharing,
  featureGatedSortOptions,
}) {
  const sortByOption = find(options, { value: sortBy });
  const CurrentDropdownButton = isSharing
    ? SharePanelDropdownButton
    : DropdownButton;

  return (
    <Menu
      shouldUsePortal
      content={
        <React.Fragment>
          <OrderOptions
            sortByOption={sortByOption}
            isDescending={isDescending}
            setSort={setSort}
          />
          <MenuGroup label="Sort by">
            {map(options, (option) => {
              const featureGate = featureGatedSortOptions?.[option.value];
              const isFeatureGated = !!featureGate?.isFeatureGated;
              const isSelected = option === sortByOption;
              return (
                <MenuRadio
                  key={option.value}
                  checked={!isFeatureGated && isSelected}
                  onSelect={() => {
                    if (isFeatureGated) {
                      featureGate.handleSelect();
                      return;
                    }

                    !isSelected &&
                      setSort(option, option.featureGatedSortOptions);
                  }}
                >
                  <Flex justifyBetween>
                    {option.label} {isFeatureGated && <FeatureGateTooltip />}
                  </Flex>
                </MenuRadio>
              );
            })}
          </MenuGroup>
        </React.Fragment>
      }
    >
      {({ bind }) => {
        return (
          <Media query={MEDIUM_UP}>
            {(isMediumUp) =>
              isMediumUp || isSharing ? (
                <CurrentDropdownButton dark text compact {...bind}>
                  {sortByOption.label}
                </CurrentDropdownButton>
              ) : (
                <Button dark icon text {...bind}>
                  <SortIcon />
                </Button>
              )
            }
          </Media>
        );
      }}
    </Menu>
  );
}

SortMenu.propTypes = {
  setSort: PropTypes.func.isRequired,
  isDescending: PropTypes.bool,
  options: PropTypes.object.isRequired,
  sortBy: PropTypes.string.isRequired,
};

function OrderOptions({ sortByOption, isDescending, setSort }) {
  // This is necessary because we only want to render the order options
  // when the component actually mounts. Otherwise, we can have a quick
  // flash of the ordered options (which aren't always rendered) during
  // the unmount phase.
  const initialSortOption = useInitialValue(sortByOption);

  if (initialSortOption.canSortDescending) {
    return (
      <MenuGroup label="Order">
        <MenuRadio
          checked={!isDescending}
          onSelect={() => isDescending && setSort(initialSortOption, false)}
        >
          {initialSortOption.asc || DEFAULT_SORT_ORDER_TEXT.asc}
        </MenuRadio>
        <MenuRadio
          checked={isDescending}
          onSelect={() => !isDescending && setSort(initialSortOption, true)}
        >
          {initialSortOption.desc || DEFAULT_SORT_ORDER_TEXT.desc}
        </MenuRadio>
      </MenuGroup>
    );
  }

  return null;
}

OrderOptions.propTypes = {
  sortByOption: PropTypes.object.isRequired,
  isDescending: PropTypes.bool,
  setSort: PropTypes.func.isRequired,
};
