import { generateActionTypes } from 'shared/actions/helpers';
import { generateFetchActionType } from '@frameio/core/src/shared/actions/helpers';
import { createAction } from 'redux-actions';

export const ACCOUNT = generateActionTypes('ACCOUNT', [
  'SET_CURRENT',
  'SET_LAST_VIEWED',
  'TRACK_CANCELLATION_STARTED',
  'IDENTIFY_FOR_ENTERPRISE_PLAN',
  'TRACK_TRANSFER_OWNERSHIP',
  'GET_WITH_VAT',
  'SET_MIGRATION_ELIGIBILITY',
]);

/**
 * Set's redux store's currentAccount.
 * @param {string} accountId - Account Id.
 */
export const setCurrentAccount = createAction(
  ACCOUNT.SET_CURRENT,
  (accountId, prevAccountId, lastViewedProjectId) => ({
    accountId,
    prevAccountId,
    lastViewedProjectId,
  })
);

/**
 * Set the last viewed account id, and store it in localStorage (see configureStore)
 * So that when a user hard refrehes onto `/` we know where they left off.
 */
export const setLastViewedAccount = createAction(
  ACCOUNT.SET_LAST_VIEWED,
  (accountId) => ({ accountId })
);

/**
 * Trigger action when user starts cancellation
 */
export const trackCancellationStarted = createAction(
  ACCOUNT.TRACK_CANCELLATION_STARTED,
  () => ({})
);

/**
 * Trigger action to identify the user when interested or already on an enterprise plan
 */
export const identifyAccountForEnterprisePlan = createAction(
  ACCOUNT.IDENTIFY_FOR_ENTERPRISE_PLAN,
  () => ({})
);

export const getAccountWithVat = createAction(
  ACCOUNT.GET_WITH_VAT,
  (accountId) => ({ accountId })
);

/**
 * Sets the state of whether a user and account can migrate to V4
 *
 * @param {boolean} canUserInitiateAccountMigration - Whether a user has permissions to initiate a migration
 * @param {boolean} isAccountEligibleForMigration - Whether the current account is eligible for migration
 */
export const setAccountMigrationEligibility = createAction(
  ACCOUNT.SET_MIGRATION_ELIGIBILITY,
  (canUserInitiateAccountMigration, isAccountEligibleForMigration) => ({
    canUserInitiateAccountMigration,
    isAccountEligibleForMigration,
  })
);

export const NEW_SETTINGS_APP_PERMISSIONS = generateFetchActionType(
  'NEW_SETTINGS_APP_PERMISSIONS',
  'GET'
);
