import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import { isAccountAdminSelector } from 'selectors/roles';
import { currentAccountSelector } from 'selectors/accounts';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import { userEntitySelector } from '@frameio/core/src/users/selectors';
import { chatWithOurTeam } from 'components/SelectPlanFlow/actions';
import { upgradePlan } from './actions';
import hardBlockCopySelector from './selectors';
import HardBlock from './HardBlock';

function mapStateToProps(state, { limitType }) {
  const { copy, upgradePlanName, upgradePlanFeatures } = hardBlockCopySelector(
    state
  );
  const limitCopy = copy[limitType];
  const { id: accountId } = currentAccountSelector(state);
  const isAdmin = isAccountAdminSelector(state, { accountId });
  const { owner: ownerId } = accountEntitySelector(state, { accountId });
  const { email: accountOwnerEmail } = userEntitySelector(state, {
    userId: ownerId,
  });

  let mailToLink = '';
  if (!isAdmin) {
    mailToLink = `mailto:${accountOwnerEmail}`;
  }

  return {
    limitCopy,
    upgradePlanName,
    upgradePlanFeatures,
    isAdmin,
    mailToLink,
  };
}

const mapDispatchToProps = {
  closeModal,
  upgradePlan,
  chatWithOurTeam,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HardBlock);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
