/**
 * Creates a generic action creator for isFetching states for different action
 * types.
 * @param {string} actionType - Redux action type.
 * @returns {Function} - Redux action object.
 */
export function isFetchingActionFactory(actionType) {
  return function isFetchingAction(isFetching) {
    return {
      type: actionType,
      payload: { isFetching },
    };
  };
}

/**
 * Creates a generic reducer for isFetching states for different action types.
 * @param {string} actionType - Redux action type.
 * @param {string} [property='isFetching'] - Name of the object property to be used in redux.
 * @returns {Function} - Redux action object.
 */
export function isFetchingReducerFactory(actionType, property = 'isFetching') {
  return function isFetchingReducer(
    state = {
      [property]: false,
    },
    action
  ) {
    switch (action.type) {
      case actionType:
        return { ...state, [property]: action.payload.isFetching };
      default:
        return state;
    }
  };
}
