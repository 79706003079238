import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import track from 'analytics';
import { buttonReset } from '@frameio/components/src/mixins';
import DropArrowIcon from '@frameio/components/src/svgs/icons/caron.svg';
import CheckIcon from '@frameio/components/src/svgs/icons/check.svg';
import Select, {
  DefaultDropdown,
} from '@frameio/components/src/styled-components/Select';
import Truncate from '@frameio/components/src/styled-components/Truncate';

import AccountLink from '../AccountLink';
import Account, { StyledAvatar } from '../Account';

const SELECTED_LOGO_SIZE = 32;
const DROPDOWN_LOGO_SIZE = 24;
const ICON_SIZE = 18;

const Check = styled(CheckIcon).attrs(() => ({
  width: ICON_SIZE,
  height: ICON_SIZE,
}))`
  color: ${(p) => p.theme.color.brand};
`;

const DropArrow = styled(DropArrowIcon).attrs(() => ({
  width: ICON_SIZE,
  height: ICON_SIZE,
}))`
  margin-top: ${(p) => p.theme.spacing.micro};
  color: ${(p) => p.theme.color.graphiteGray};
`;

const SelectedAccountName = styled(Truncate)`
  font-size: 20px;
  color: ${(p) => p.theme.color.coldWhite};
`;

const SelectedAccount = styled.button`
  ${buttonReset()}
  display: flex;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: ${(p) => p.theme.spacing.units(2)};

  ${SelectedAccountName} {
    margin-left: ${(p) => p.theme.spacing.small};
    margin-right: ${(p) => p.theme.spacing.tiny};
  }

  // Don't resize logo and drop arrow within flex parent
  ${StyledAvatar},
  ${DropArrow} {
    flex: 0 0 auto;
  }
`;

const StyledAccountLink = styled(({ isSelected, ...rest }) => (
  <AccountLink {...rest} />
))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${(p) => p.theme.spacing.tiny};
  border-radius: ${(p) => p.theme.radius.large};
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.color.coldWhite : 'transparent'};

  ${StyledAvatar} {
    border-radius: ${(p) => p.theme.radius.medium};
    font-size: ${(p) => p.theme.fontSize[2]};
  }

  ${Check} {
    flex: 0 0 auto;
  }
`;

const StyledSelect = styled(Select)`
  ${DefaultDropdown} {
    left: ${(p) => p.theme.spacing.tiny};
    padding: ${(p) => p.theme.spacing.tiny};
    border-radius: ${(p) => p.theme.radius.large};
    width: ${8 * 28}px;
    max-height: 75vh;
    overflow-y: auto;
  }
`;

export default function AccountSwitcherDropdown({
  accountIds,
  currentAccount,
  currentAccountId,
  versions,
}) {
  const {
    display_name: name,
    image_64: image,
    account_default_color: color,
  } = currentAccount;
  /*
    If a user has less than 2 accounts, do not render the component
  */
  if (accountIds.length < 2) return null;

  return (
    <StyledSelect
      options={accountIds}
      renderSelected={(isOpen, selectOption, valueRef) => (
        <SelectedAccount ref={valueRef}>
          <StyledAvatar
            color={color}
            image={image}
            name={name ? name[0] : ''}
            size={SELECTED_LOGO_SIZE}
          />
          <SelectedAccountName>{name}</SelectedAccountName>
          <DropArrow />
        </SelectedAccount>
      )}
    >
      {(option) => {
        const accountId = option;
        return (
          <StyledAccountLink
            accountId={accountId}
            isSelected={accountId === currentAccountId}
            onClick={() => track('account-switcher-account-switched')}
          >
            <Account
              accountId={accountId}
              size={DROPDOWN_LOGO_SIZE}
              version={versions[accountIds.indexOf(accountId)]}
            />
            {accountId === currentAccountId && <Check />}
          </StyledAccountLink>
        );
      }}
    </StyledSelect>
  );
}

AccountSwitcherDropdown.propTypes = {
  /**
   * Array of a user accounts ids
   */
  accountIds: PropTypes.array,
  currentAccountId: PropTypes.string,
  currentAccount: PropTypes.shape({
    display_name: PropTypes.string,
    image_64: PropTypes.string,
    account_default_color: PropTypes.string,
  }),
  versions: PropTypes.array,
};

AccountSwitcherDropdown.defaultProps = {
  accountIds: [],
  currentAccountId: null,
  currentAccount: PropTypes.shape({
    display_namee: null,
    image_64: null,
    account_default_color: null,
  }),
  versions: [],
};

export const testExports = {
  StyledSelect,
};
