/* eslint-disable no-param-reassign */
import REDUX_PAUSE from './actions';

// This middleware was originally implemented to fix the issue of trashing
// animations when moving asset cards into another folder/versioning by dragging
// and dropping in the project view.
//
// The idea here is that Redux can be paused from processing any actions until
// the HTTP response and the socket events associated with the move is done, so
// that state changes associated with those server responses wouldn't trigger
// rerenders that will cause drag 'n drop animations to trash before state
// settles.
//
// However, this implementation is problematic because it can get into a state
// where an async saga might still be running when Redux is paused, such that
// any additional actions triggered by this saga won't actually get processed.
// This puts the saga in an inconsistent state where a saga might return without
// actually processing any of its internal actions.
//
// See https://github.com/Frameio/web-client/pull/8034 for more details.
//
// TODO: Either fix this middleware to somehow guarantees sagas to finish
// executing in non-paused mode, or perhaps a better idea is to get rid of this
// middleware and solve the animation problems some other way, probably by
// making the project asset cards less directly coupled to the asset entities.

export default function reduxPauseMiddleware(maxQueueLength = 20) {
  return (store) => {
    const queuedActions = [];
    store.queuedActions = queuedActions;

    return (next) => {
      function flushActionQueue() {
        while (queuedActions.length) {
          const enqueuedAction = queuedActions.shift();
          next(enqueuedAction);
        }
      }
      return (action) => {
        switch (action.type) {
          case REDUX_PAUSE.PAUSE:
            if (!store.isPaused) {
              store.isPaused = true;
              next(action);
            }
            return flushActionQueue;
          case REDUX_PAUSE.RESUME:
            if (store.isPaused) {
              store.isPaused = false;
              next(action);
              flushActionQueue();
            }
            return flushActionQueue;
          default:
            if (store.isPaused) {
              queuedActions.push(action);
              return action;
            }
            if (queuedActions.length >= maxQueueLength) {
              flushActionQueue();
            }
            return next(action);
        }
      };
    };
  };
}
