import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Flex from 'styled-flex-component';
import { MenuButton, StatusIndicator } from '@frameio/vapor';
import styled from 'styled-components';
import DropArrowIcon from '@frameio/components/src/svgs/icons/carot-thin.svg';
import TaskTitleWithToolTip from 'components/PlayerContainers/TopBar/AssetTitleWithToolTip';
import { CURRENT_STATUS_LABEL_COLORS, taskStatusLabels } from './helper';

const StyledMenuButtonWrapper = styled.div.attrs({
  className: 'StyledMenuButton',
})`
  .task {
    ${(p) => p.$isTaskSelected && `background: ${p.theme.color.coldWhite};`}
    &:active {
      background-color: ${(p) => p.theme.color.coldWhite};
    }
  }
  .menu {
    &:active:not([aria-expanded='true']) {
      background-color: ${(p) => p.theme.color.white};
    }
    padding: 0 0 0 ${(p) => p.theme.spacing.tiny};
  }
`;

const StyledMenuButton = styled(MenuButton)`
  display: flex;
  min-height: ${(p) => p.theme.spacing.large};
  height: fit-content;
  div[class^='MenuItem'] {
    padding: 0 0 0 ${(p) => p.theme.spacing.tiny};
  }
`;

const StyledTaskTitleContainer = styled.div`
  display: flex;
  max-width: ${(p) => p.theme.spacing.units(40)};
  align-items: center;
  justify-content: space-between;
  ${(p) => !p.$hasSubtasks && `margin-left: ${p.theme.spacing.micro};`}
  ${(p) => p.$isSubtaskRow && `margin-left: 20px;`}
`;

const StyledTruncateWithTooltip = styled(TaskTitleWithToolTip)`
  z-index: 5;
`;

const DropArrow = styled(({ isCollapsed, ...rest }) => (
  <DropArrowIcon {...rest} />
))`
  height: 14px;
  width: 14px;
  ${(p) => `
    transform: rotate(${p.isCollapsed ? '180deg' : '-90deg'});`}
  margin-right: -${(p) => p.theme.spacing.tiny};
`;

const StyledStatusIndicator = styled(StatusIndicator)`
  height: ${(p) => p.theme.spacing.tiny};
  width: ${(p) => p.theme.spacing.tiny};
  margin-right: ${(p) => p.theme.spacing.micro};
`;

const TaskStatus = ({ status }) => (
  <Flex center>
    <StyledStatusIndicator
      variant="checked"
      color={CURRENT_STATUS_LABEL_COLORS[status]}
    />
    {taskStatusLabels[status]}
  </Flex>
);

const Task = ({
  canUpdate,
  canUpload,
  dispatch,
  hasSubtasks,
  isUpdateTaskSelected,
  setUpdateTaskSelected,
  subtasks,
  task,
  taskOptions = {},
}) => {
  const [isClosed, setIsClosed] = useState(true);
  const canShowSubtasks = hasSubtasks && !isClosed;

  const isSelected = (taskId) => taskOptions.taskId === taskId;

  const handleDropArrowClick = (e) => {
    e.stopPropagation();
    setIsClosed(!isClosed);
  };

  const handleOnSelect = (taskId, taskStatus, taskTitle, canUpdateStatus) => {
    // resets update task on new task selection.
    setUpdateTaskSelected({
      hasPermissionToUpdate: canUpdateStatus,
      isUpdateTaskSelected: false,
      taskOptions: { taskId, taskTitle, taskStatus, updateStatus: '' },
    });
  };

  return (
    <>
      <StyledMenuButtonWrapper $isTaskSelected={isSelected(task.id)}>
        <StyledMenuButton
          className="task"
          disabled={!canUpload}
          onSelect={() => {
            handleOnSelect(task.id, task.status, task.title, canUpdate);
          }}
          iconAfter={<TaskStatus status={task.status} />}
          iconBefore={
            hasSubtasks && (
              <DropArrow
                onClick={(e) => handleDropArrowClick(e, task.id)}
                isCollapsed={isClosed}
              />
            )
          }
        >
          <StyledTaskTitleContainer $hasSubtasks={hasSubtasks}>
            <StyledTruncateWithTooltip title={task.title}>
              {task.title}
            </StyledTruncateWithTooltip>
          </StyledTaskTitleContainer>
        </StyledMenuButton>
      </StyledMenuButtonWrapper>
      {canShowSubtasks &&
        subtasks?.map((subtask) => (
          <StyledMenuButtonWrapper
            $isTaskSelected={
              isSelected(subtask.id) && !subtask?.subtasks?.length
            }
            key={subtask.id}
          >
            <StyledMenuButton
              className={subtask?.subtasks?.length === 0 ? 'task' : 'menu'}
              iconAfter={
                !subtask?.subtasks?.length && (
                  <TaskStatus status={subtask.status} />
                )
              }
              onClick={(e) => e.stopPropagation()}
              onSelect={() => {
                handleOnSelect(
                  subtask.id,
                  subtask.status,
                  subtask.title,
                  subtask.permissions?.can_change_status
                );
              }}
            >
              {subtask?.subtasks?.length === 0 ? (
                <StyledTaskTitleContainer $isSubtaskRow>
                  <StyledTruncateWithTooltip title={subtask.title}>
                    {subtask.title}
                  </StyledTruncateWithTooltip>
                </StyledTaskTitleContainer>
              ) : (
                <Task
                  key={subtask.id}
                  canUpdate={subtask.permissions?.can_change_status}
                  canUpload={subtask.permissions?.can_upload}
                  dispatch={dispatch}
                  hasSubtasks={subtask.subtasks?.length > 0}
                  isUpdateTaskSelected={isUpdateTaskSelected}
                  setUpdateTaskSelected={setUpdateTaskSelected}
                  subtasks={subtask.subtasks}
                  task={subtask}
                  taskOptions={taskOptions}
                />
              )}
            </StyledMenuButton>
          </StyledMenuButtonWrapper>
        ))}
    </>
  );
};

Task.propTypes = {
  canUpdate: PropTypes.bool,
  canUpload: PropTypes.bool,
  hasSubtasks: PropTypes.bool,
  isUpdateTaskSelected: PropTypes.bool,
  setUpdateTaskSelected: PropTypes.func,
  subtasks: PropTypes.arrayOf(PropTypes.object),
  task: PropTypes.object,
  taskOptions: PropTypes.object,
};

export default Task;
