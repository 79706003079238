const findAssignedTasks = (task, parentTaskId, assignedTaskIds) => {
  if (task.permissions.is_assigned) {
    assignedTaskIds.push(parentTaskId);
  }
  if (task.subtasks.length) {
    task.subtasks.forEach((subtask) =>
      findAssignedTasks(subtask, parentTaskId, assignedTaskIds)
    );
  }
};

// eslint-disable-next-line import/prefer-default-export
export const getAssignedTasksArray = (tasks) => {
  const assignedTaskIds = [];
  // recursively search thru tasks & their nested subtasks and create an array
  // of parent task ids if any of the tasks/subtasks are assigned to the user.
  tasks.forEach((task) => findAssignedTasks(task, task.id, assignedTaskIds));

  // if any are assigned we need to build the assigned task list from the
  // array of matching parent ids. We want the entire task object to be included
  // so that the user has context of a nested subtask they may be assigned.
  const assignedTasks = [];
  if (assignedTaskIds.length) {
    tasks.forEach((task) => {
      if (assignedTaskIds.includes(task.id)) {
        assignedTasks.push(task);
      }
    });
  }
  return assignedTasks;
};
