import React from 'react';
import styled from 'styled-components';
import { buttonReset } from '@frameio/components/src/mixins';
import { call, put, race, select, take, delay } from 'redux-saga/effects';
import {
  AUTHED_USER,
  confirmEmail as confirmEmailAction,
  sendConfirmationEmail,
} from '@frameio/core/src/users/actions';
import { isAnonymousUser } from '@frameio/core/src/users/utils';
import { ROOT_URL } from 'URLs';
import history from 'browserHistory';
import { showEmailToast } from 'actions/toasts';
import { configureCore, hasAuthCookies } from 'utils/auth';
import { redirectToAuth } from 'utils/router';
import {
  currentUserEntitySelector,
  hasCurrentUserConfirmedEmailAddress,
} from 'selectors/users';
import store from 'configureStore';
import Raven from 'raven-js';

const CONFIRMATION_EMAIL_TOAST_DELAY = 20000;

// NOTE: This cannot use `AuthedRoute` because `getAuthedUser` will always
// return an error response if the email isn't confirmed 24 hrs after signing up.
export default function* confirmEmail(action) {
  const { email, tokenId } = action.payload;

  // Make sure user is authed before confirming the email. Do a hard page load
  // just so that the `e` param gets redacted on /welcome.
  if (!hasAuthCookies()) {
    redirectToAuth({ e: email }, true, {
      details:
        'Navigated to app.frame.io/confirm-email without both authToken and refreshToken',
      location: window?.location?.href,
    });
  }

  // If authed, confirm the email using the secret
  configureCore();
  yield put(confirmEmailAction(tokenId));
  const { success } = yield race({
    success: take(AUTHED_USER.CONFIRM_EMAIL.SUCCESS),
    failure: take(AUTHED_USER.CONFIRM_EMAIL.FAILURE),
  });

  if (success) {
    yield put(
      showEmailToast({
        header: 'Successfully confirmed email',
      })
    );
  }

  return yield call(history.replace, ROOT_URL);
}

export function* handleConfirmEmailFailure({ payload }) {
  const { error } = payload || {};

  if (error?.message === 'Invalid token id') {
    yield Raven.captureMessage('Invalid token id used for email confirmation', {
      level: 'error',
      extra: { error },
      tags: {
        jira_ticket: 'V3FRAME-1596',
      },
    });
  }
}

const ResendEmailConfirmationButton = styled.button`
  ${buttonReset()};
  color: ${({ theme }) => theme.color.white};
`;

export function* shouldShowconfirmEmailAddressToast() {
  const user = yield select(currentUserEntitySelector);
  const isUserAnonymous = yield call(isAnonymousUser, user);
  const hasConfirmedEmailAddress = yield select(
    hasCurrentUserConfirmedEmailAddress
  );

  if (!isUserAnonymous && !hasConfirmedEmailAddress) {
    // We set a delay before showing the email confirmation toast so the project view has time to
    // load.
    yield delay(CONFIRMATION_EMAIL_TOAST_DELAY);
    yield put(
      showEmailToast({
        header: (
          <ResendEmailConfirmationButton
            onClick={() => {
              store.dispatch(sendConfirmationEmail(user.email));
            }}
          >
            Resend confirmation email
          </ResendEmailConfirmationButton>
        ),
        autoCloseDelay: 10000,
      })
    );
  }
}
