import { AEM } from './actions';
/**
 * AEM repository tree state reducer
 *
 * @param {Object} state - The current state.
 * @param {Object} action - The action describing how to change the state
 * @returns {Object} - The new state.
 */
export function aemRepositoriesReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case AEM.GET_AEM_REPOSITORIES.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case AEM.GET_AEM_REPOSITORIES.FAILURE:
      return { ...state, isLoading: false, isOpen: false };
    case AEM.GET_AEM_REPOSITORIES.SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default function aemRepositoryTreesReducer(state = {}, action) {
  const { type, payload } = action;

  const {
    aemFolderId,
    aemPath,
    aemRepositories,
    aemRepositoryId,
    aemRepositoryTitle,
    childFolders,
    parentFolderId,
    rootFolderId,
    rootFolders,
  } = payload || {};
  switch (type) {
    case AEM.SET_AEM_REPOSITORIES: {
      return { ...state, aemRepositories };
    }
    case AEM.GET_AEM_FOLDERS.PENDING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AEM.SELECT_AEM_REPOSITORY: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AEM.GET_AEM_FOLDERS.FAILURE: {
      return { ...state, isLoading: false };
    }
    case AEM.SET_AEM_ROOT_FOLDERS: {
      return {
        ...state,
        selectedAemDestination: {
          ...state.selectedAemDestination,
          aemFolderId: rootFolderId,
          aemPath: rootFolders?.root[0]?.path,
        },
        aemRepositories: {
          ...state.aemRepositories,
          [aemRepositoryId]: {
            ...state.aemRepositories[aemRepositoryId],
            rootFolderId,
            folders: rootFolders,
          },
        },
        isLoading: false,
      };
    }
    case AEM.SET_AEM_CHILD_FOLDERS: {
      if (!aemRepositoryId || !parentFolderId) {
        return {
          ...state,
          isLoading: false,
        };
      }
      return {
        ...state,
        aemRepositories: {
          ...state.aemRepositories,
          [aemRepositoryId]: {
            ...state.aemRepositories[aemRepositoryId],
            folders: {
              ...state.aemRepositories[aemRepositoryId].folders,
              [parentFolderId]: childFolders,
            },
          },
        },
        isLoading: false,
      };
    }
    case AEM.SELECT_AEM_DESTINATION:
      return {
        ...state,
        selectedAemDestination: {
          repo_id: aemRepositoryId,
          repo_title: aemRepositoryTitle,
          aemFolderId,
          aemPath,
        },
      };
    default:
      return state;
  }
}
