import { connect } from 'react-redux';
import {
  assetEntityInclDeletedSelector,
  coverAssetEntityInclDeletedSelector,
} from '@frameio/core/src/assets/selectors';
import { creatorEntityForAssetIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import FileCard from 'components/FileCard/FileCard';
import { selectedAssetIdsSelector } from './selectors';

function mapStateToProps(state, { assetId }) {
  return {
    asset: assetEntityInclDeletedSelector(state, { assetId }),
    coverAsset: coverAssetEntityInclDeletedSelector(state, { assetId }),
    creator: creatorEntityForAssetIdSelector(state, { assetId }),
    isSelected: selectedAssetIdsSelector(state).includes(assetId),
  };
}

export default connect(mapStateToProps)(FileCard);

export const testExports = {
  mapStateToProps,
};
