import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import { currentAccountEntitySelector } from 'selectors/accounts';
import { firstCardEntityByAccountIdSelector } from '@frameio/core/src/cards/selectors';
import { addCardToAccount } from './actions';
import { paymentMethodFetchingStatusSelector } from './selectors';
import AddCreditCard from './AddCreditCard';

const mapDispatchToProps = {
  onSubmit: addCardToAccount,
  onCancel: closeModal,
};

function mapStateToProps(state) {
  const { id: accountId } = currentAccountEntitySelector(state);
  const isFetching = paymentMethodFetchingStatusSelector(state);
  const card = firstCardEntityByAccountIdSelector(state, { accountId });
  return {
    card,
    isFetching,
  };
}

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCreditCard);
