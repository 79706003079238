import React, { memo } from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  position: relative;
  height: 42px;
  margin-bottom: ${({ marginBottom, theme }) =>
    marginBottom ? theme.spacing.tiny : 0};
`;

const Container = ({ children, marginBottom }) => (
  <StyledDiv marginBottom={marginBottom}>{children}</StyledDiv>
);

export default memo(Container);
