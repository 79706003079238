import React from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Switch } from 'react-router-dom';
import {
  STORAGE_LIMIT,
  ARCHIVED_STORAGE_LIMIT,
} from '@frameio/core/src/subscriptionLineItems/utils/modifierTypes';
import AddStorageFlow from 'components/AddStorageFlow';
import SelectPlanFlow from 'components/SelectPlanFlow';
import history from './history';
import OutOfSpace from './OutOfSpace';
import OutOfArchivedSpace from './OutOfArchivedSpace';

export const OUT_OF_SPACE_URL = '/OutOfSpace';
export const OUT_OF_ARCHIVED_SPACE_URL = '/OutOfArchivedSpace';
export const SELECT_PLAN_FLOW_URL = '/SelectPlanFlow';
export const REQUEST_ADMIN_TO_ADD_STORAGE_URL = '/RequestAdminToAddStorage';
export const ADD_STORAGE_FLOW_URL = '/AddStorageFlow';
export const ADD_ARCHIVED_STORAGE_FLOW_URL = '/AddArchivedStorageFlow';

export default class StorageLimitReachedFlow extends React.Component {
  componentDidMount() {
    const {
      accountId,
      startFlow,
      getSubscriptionByAccount,
      getLineItemsForPlan,
      planId,
      type,
    } = this.props;

    getSubscriptionByAccount(accountId);
    getLineItemsForPlan(planId);
    startFlow(accountId, type);
  }

  componentWillUnmount() {
    this.props.endFlow();
  }

  render() {
    const { accountId, closeModal, isEnterprise } = this.props;
    if (isEnterprise) return null;
    return (
      <Router history={history}>
        <Switch>
          <Route
            path={OUT_OF_SPACE_URL}
            render={() => <OutOfSpace accountId={accountId} />}
          />
          <Route
            path={SELECT_PLAN_FLOW_URL}
            render={() => (
              <SelectPlanFlow
                accountId={accountId}
                onComplete={closeModal}
                onCancel={closeModal}
              />
            )}
          />
          <Route
            path={REQUEST_ADMIN_TO_ADD_STORAGE_URL}
            render={() => <OutOfSpace accountId={accountId} />}
          />
          <Route
            path={ADD_STORAGE_FLOW_URL}
            component={() => (
              <AddStorageFlow
                type={STORAGE_LIMIT}
                accountId={accountId}
                onComplete={closeModal}
                trackingProps={{ source: 'hard block' }}
                showTotalLineItem
              />
            )}
          />
          <Route
            path={OUT_OF_ARCHIVED_SPACE_URL}
            render={() => <OutOfArchivedSpace accountId={accountId} />}
          />
          <Route
            path={ADD_ARCHIVED_STORAGE_FLOW_URL}
            component={() => (
              <AddStorageFlow
                type={ARCHIVED_STORAGE_LIMIT}
                accountId={accountId}
                onComplete={closeModal}
                trackingProps={{ source: 'hard block' }}
              />
            )}
          />
        </Switch>
      </Router>
    );
  }
}

StorageLimitReachedFlow.propTypes = {
  accountId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  startFlow: PropTypes.func.isRequired,
  endFlow: PropTypes.func.isRequired,
  getSubscriptionByAccount: PropTypes.func.isRequired,
  getLineItemsForPlan: PropTypes.func.isRequired,
  planId: PropTypes.string.isRequired,
  type: PropTypes.string,
};

StorageLimitReachedFlow.defaultProps = {
  type: STORAGE_LIMIT,
};
