import React from 'react';
import LoadingIndicator from '@frameio/components/src/styled-components/LoadingIndicator';
import Spinner from '@frameio/components/src/styled-components/SpinnerBase';
import PropTypes from 'prop-types';

/**
 * Show a loading indicator in a TreeItem if loading takes > 200ms
 */

function TreeItemLoadingIndicator({ isLoading, delay = 200 }) {
  return (
    <LoadingIndicator isLoading={isLoading} delay={delay}>
      <Spinner spinning radius={10} color="125,130,156" stroke={2} />
    </LoadingIndicator>
  );
}

TreeItemLoadingIndicator.propTypes = {
  /** The loading status for the children of the tree item */
  isLoading: PropTypes.bool,
  /** The delay in ms before the indicator appears. Generally should be > 200. */
  delay: PropTypes.number,
};

export default TreeItemLoadingIndicator;
