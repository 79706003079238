import {
  call,
  spawn,
  debounce,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import {
  listPresentationsForProject,
  updatePresentation,
} from '@frameio/core/src/presentations/sagas';
import { createPaginatedListSaga } from '@frameio/core/src/shared/sagas/helpers';
import { patchPresentation } from '@frameio/core/src/presentations/actions';
import track from 'analytics';
import { writeText } from 'clipboard-polyfill';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import {
  PROJECT_PRESENTATIONS,
  fetchPresentationsForProject as fetchPresentationsAction,
  resetPresentations,
  patchSort,
} from './actions';
import {
  totalPagesSelector,
  shouldFetchPresentationsSelector,
  presentationOptionsSelector,
} from './selectors';

const fetchPaginatedPresentationsForProject = createPaginatedListSaga(
  PROJECT_PRESENTATIONS.FETCH,
  listPresentationsForProject,
  { extractPage: (projectId, { page }) => page }
);

function* sortPresentations({ payload: { projectId } }) {
  const totalPages = yield select(totalPagesSelector);

  if (totalPages === 1) {
    yield put(patchSort());
    return;
  }

  yield put(resetPresentations(true));
  yield put(fetchPresentationsAction(projectId, 1));
}

function* fetchPresentationsForProject({ payload: { projectId, page = 1 } }) {
  const shouldFetch = yield select(shouldFetchPresentationsSelector, { page });
  const options = yield select(presentationOptionsSelector);

  if (!shouldFetch) {
    return;
  }

  if (page > 1) {
    yield spawn(track, 'project-links-page-results-paginated', {
      page,
      sort_by: options.sortBy,
      type: 'presentations',
    });
  }

  yield call(fetchPaginatedPresentationsForProject, projectId, {
    page,
    page_size: options.pageSize,
    sort: `${options.isDescending ? '-' : ''}${options.sortBy}`,
  });
}

function* copyShortUrlToClipboard({ payload: { url } }) {
  yield call(writeText, url);
  yield put(
    showSuccessToast({ header: 'Presentation link copied to clipboard!' })
  );
}

function* toggleIsActive({ payload: { id, isActive } }) {
  // Dispatch the BE presentation update request
  const { failure } = yield call(updatePresentation, id, { enabled: isActive });

  if (failure) {
    // revert the active state and display an error in failure cases
    yield put(patchPresentation({ id, enabled: !isActive }));
    yield put(
      showErrorToast({
        header: `An error occurred while ${
          isActive ? 'activating' : 'disabling'
        } this presentation`,
      })
    );
  }
}

function* patchToggleIsActive({ payload: { id, isActive } }) {
  // optimistically update the status in the UI
  yield put(patchPresentation({ id, enabled: isActive }));
}

export default [
  takeEvery(PROJECT_PRESENTATIONS.COPY_SHORT_URL, copyShortUrlToClipboard),
  takeEvery(PROJECT_PRESENTATIONS.PATCH_TOGGLE_IS_ACTIVE, patchToggleIsActive),
  debounce(200, PROJECT_PRESENTATIONS.PATCH_TOGGLE_IS_ACTIVE, toggleIsActive),
  takeEvery(PROJECT_PRESENTATIONS.SORT, sortPresentations),
  takeEvery(PROJECT_PRESENTATIONS.FETCH.BASE, fetchPresentationsForProject),
];

export const testExports = {
  fetchPresentationsForProject,
  fetchPaginatedPresentationsForProject,
  sortPresentations,
};
