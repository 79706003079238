import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { SMALL } from 'utils/mediaQueries';

const StyledFlex = styled(Flex)`
  background-color: ${(p) => (p.isToggled ? '#F1F3F6' : p.theme.color.white)};
  color: ${(p) => p.theme.color.black};
  padding: ${(p) => `${p.theme.spacing.units(0.5)} ${p.theme.spacing.medium}`};
  border-top: 0px solid #e1e4e6;
  border-top-width: 1px;

  ${(p) => p.theme.fontStyle.bodyL};

  @media ${SMALL} {
    ${(p) => p.theme.fontStyle.bodyM};
  }
`;

const ContentContainer = styled.div`
  margin-left: ${(p) => p.theme.spacing.small};
`;

const StyledFlexChild = styled(Flex)`
  flex: 1;
`;

const StyledFlexForLabel = styled(Flex)`
  padding: ${(p) => p.theme.spacing.small} 0;
`;

const StyledP = styled.p`
  color: ${(p) => p.theme.color.graphiteGray};

  ${(p) => p.theme.fontStyle.bodyM};

  @media ${SMALL} {
    ${(p) => p.theme.fontStyle.bodyS};
  }
`;

const StickyLinkFooter = (props) => {
  const { content, description, label, isToggled } = props;

  return (
    <StyledFlex alignCenter isToggled={isToggled} nowrap>
      <StyledFlexChild justifyBetween alignCenter nowrap grow={1}>
        <StyledFlexForLabel column alignStart justifyStart>
          {label}
          {description && <StyledP>{description}</StyledP>}
        </StyledFlexForLabel>
        <ContentContainer>{content}</ContentContainer>
      </StyledFlexChild>
    </StyledFlex>
  );
};

StickyLinkFooter.propTypes = {
  content: PropTypes.node,
  description: PropTypes.node,
  isToggled: PropTypes.bool,
  label: PropTypes.node,
};

export default StickyLinkFooter;
