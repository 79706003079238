import moment from 'moment-timezone';
import { isProduction } from 'config';
import { TAX_COLLECTION_DEADLINE, TAX_COLLECTION_PHASES } from './constants';

export const getTaxDeadlineOverride = () => {
  const urlParams = new URLSearchParams(location.search);
  return urlParams.get('taxcollectiondeadline')
    ? urlParams.get('taxcollectiondeadline')
    : TAX_COLLECTION_DEADLINE;
};

/*
 * return tax collection phase when given a deadline
 * @param {String} deadline
 * @return {String} phase || undefined
 */

export const getTaxCollectionPhase = (deadline) => {
  const daysUntilDeadline = Math.ceil(
    moment(deadline, 'MMDDYY').diff(moment(), 'days', true)
  );
  const { ONE, TWO } = TAX_COLLECTION_PHASES;

  // number of days before the deadline to start the phase
  const phaseOneStart = isProduction ? 38 : 14;
  const phaseTwoStart = isProduction ? 17 : 7;

  const withinPhaseOne = (days) =>
    days > phaseTwoStart && days <= phaseOneStart;
  const withinPhaseTwo = (days) =>
    (days >= 1 && days <= phaseTwoStart) || days === 0 || days < 0;

  const urlParams = new URLSearchParams(location.search);
  const taxCollectionPhaseOverride = urlParams.get('taxphase');
  if (taxCollectionPhaseOverride) return taxCollectionPhaseOverride;

  switch (true) {
    case withinPhaseOne(daysUntilDeadline):
      return ONE;
    case withinPhaseTwo(daysUntilDeadline):
      return TWO;
    default:
      return undefined;
  }
};

export const hasSeenTaxInfo = (type, { accountId }) => {
  return !!localStorage.getItem(`tax-info-${type}-${accountId}-seen`);
};

export const setSeenTaxInfo = (type, { accountId }) => {
  const taxphase = getTaxCollectionPhase(TAX_COLLECTION_DEADLINE);
  // don't save to local storage when in phase 1 so the modal can be shown in phase 2
  if (taxphase === TAX_COLLECTION_PHASES.ONE) return;
  localStorage.setItem(`tax-info-${type}-${accountId}-seen`, true);
};

// this helper overrides an existing tax location status when given 'true' or 'false'
export const getTaxLocationDeterminedOverride = () => {
  const urlParams = new URLSearchParams(location.search);
  switch (urlParams.get('taxlocationdetermined')) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return undefined;
  }
};
