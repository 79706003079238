import React from 'react';

export const DIALOG_PORTAL_ROOT_ID = 'dialog-portal-root';

/* Place anywhere in your React app to determine where the DialogModal will render */
const DialogPortalRoot = (props) => (
  <div id={DIALOG_PORTAL_ROOT_ID} {...props} />
);

export default DialogPortalRoot;
