import {
  call,
  cancel,
  fork,
  take,
  takeLatest,
  put,
  select,
} from 'redux-saga/effects';
import track from 'analytics';
import { MODAL } from 'components/Modal/actions';
import { seatLimitPropertyName } from 'selectors/accounts';
import { PAYMENT_FLOW } from 'components/PaymentFlow/actions';
import {
  updateSubscriptionLineItem,
  fetchPlanAndSubscriptionLineItemsForModifier,
} from 'components/ManageSubscription/sagas';
import { getSubscriptionByAccount } from '@frameio/core/src/subscriptions/sagas';
import { isUpdatingSubscription } from 'components/ManageSubscription/actions';
import { ADD_SEATS_FLOW, reportAddSeatsFlowSuccess } from './actions';
import history from './history';
import {
  ADD_SEATS_URL,
  PAYMENT_FLOW_URL,
  CONFIRM_SEATS_ADDED_URL,
} from './AddSeatsFlow';

export function* watchSeatsFlow(accountId, trackingProps) {
  // Step 1: Show limit reached info screen
  yield call(history.push, ADD_SEATS_URL);

  // Step 2: User has selected number of seats they want to add
  const {
    payload: { updatedLimit, totalSeatsAdded },
  } = yield take(ADD_SEATS_FLOW.CONTINUE);
  track('line-item-modal-submitted', {
    ...trackingProps,
    quantity: totalSeatsAdded,
    type: 'members',
  });

  const seatLimitType = yield select(seatLimitPropertyName);

  // Step 3: Take the user to the payment flow
  yield call(history.push, PAYMENT_FLOW_URL, {
    onPaymentMethodReady: () =>
      updateSubscriptionLineItem(seatLimitType, updatedLimit),
  });

  // Step 4: Payment is succesful
  yield take(PAYMENT_FLOW.REPORT_PAYMENT_SUCCESS);
  yield put(reportAddSeatsFlowSuccess());

  // Step 5: Show confirmation screen with number of seats added
  yield call(history.push, CONFIRM_SEATS_ADDED_URL, { totalSeatsAdded });

  // Step 6: Get the updated subscription for the account
  yield call(getSubscriptionByAccount, accountId);
  yield put(isUpdatingSubscription(false));
}

export function* startAddSeatsFlow(accountId, trackingProps) {
  const seatLimitType = yield select(seatLimitPropertyName);
  // Setup: make sure we have access to the plan and subscription line items
  yield call(
    fetchPlanAndSubscriptionLineItemsForModifier,
    accountId,
    seatLimitType
  );

  const task = yield fork(watchSeatsFlow, accountId);
  track('line-item-modal-shown', {
    ...trackingProps,
    type: 'members',
  });

  yield take([ADD_SEATS_FLOW.END, MODAL.CLOSE]);
  yield cancel(task);

  // Prevent the last screen from showing up when the flow restarts
  yield call(history.push, '/');
}

export default [
  takeLatest(
    ADD_SEATS_FLOW.START,
    ({ payload: { accountId, trackingProps } }) =>
      startAddSeatsFlow(accountId, trackingProps)
  ),
];
