// prettier-ignore
class JTEventManager {

  constructor() {

    this.bind_fio_state_listener = this.bind_fio_state_listener.bind(this);
    this.emit_updated_fio_state_value = this.emit_updated_fio_state_value.bind(this);
    this.unbind_fio_state_value = this.unbind_fio_state_value.bind(this);
    this.emit_updated_jt_dynamo_value = this.emit_updated_jt_dynamo_value.bind(this);
    this.bind_jt_dynamo_listener = this.bind_jt_dynamo_listener.bind(this);
    this.unbind_jt_dynamo_listener = this.unbind_jt_dynamo_listener.bind(this);
    this.register_tap = this.register_tap.bind(this);
    this.bind_window_resize_callback = this.bind_window_resize_callback.bind(this);
    this.register_event_path = this.register_event_path.bind(this);
    this.unregister_event_path = this.unregister_event_path.bind(this);
    this.bind_to_event_path = this.bind_to_event_path.bind(this);
    this.run_event_path = this.run_event_path.bind(this);
    this.window_resize_event = this.window_resize_event.bind(this);
    this.window_hash_change = this.window_hash_change.bind(this);
    this.window_pop_state = this.window_pop_state.bind(this);
    this.add_history_event = this.add_history_event.bind(this);
    this.push_next_event = this.push_next_event.bind(this);
    this.pop_next_event = this.pop_next_event.bind(this);
    this.trigger_all_next_events = this.trigger_all_next_events.bind(this);
    this.trigger_forward_event = this.trigger_forward_event.bind(this);
    this.trigger_history_event = this.trigger_history_event.bind(this);
    this.register_master_id = this.register_master_id.bind(this);
    this.bind_non_event = this.bind_non_event.bind(this);
    this.unbind_non_event = this.unbind_non_event.bind(this);
    this.bind_unload_event = this.bind_unload_event.bind(this);
    this.unbind_unload_event = this.unbind_unload_event.bind(this);
    this.bind_before_unload_event = this.bind_before_unload_event.bind(this);
    this.unbind_before_unload_event = this.unbind_before_unload_event.bind(this);
    this.bind_master_start_event = this.bind_master_start_event.bind(this);
    this.bind_master_move_event = this.bind_master_move_event.bind(this);
    this.bind_master_end_event = this.bind_master_end_event.bind(this);
    this.bind_always_move_event = this.bind_always_move_event.bind(this);
    this.unbind_always_move_event = this.unbind_always_move_event.bind(this);
    this.bind_drop_event = this.bind_drop_event.bind(this);
    this.bind_invisible_end_event = this.bind_invisible_end_event.bind(this);
    this.bind_multi_event = this.bind_multi_event.bind(this);
    this.unbind_multi_event = this.unbind_multi_event.bind(this);
    this.clear_multi_event = this.clear_multi_event.bind(this);
    this.ping_multi_event = this.ping_multi_event.bind(this);
    this.ping_multi_event_old = this.ping_multi_event_old.bind(this);
    this.bind_holding_event = this.bind_holding_event.bind(this);
    this.unbind_holding_event = this.unbind_holding_event.bind(this);
    this.bind_bubble_end_event = this.bind_bubble_end_event.bind(this);
    this.bind_hovering_event = this.bind_hovering_event.bind(this);
    this.unbind_hovering_event = this.unbind_hovering_event.bind(this);
    this.bind_command_key_event = this.bind_command_key_event.bind(this);
    this.unbind_command_key_event = this.unbind_command_key_event.bind(this);
    this.bind_command_shift_key_event = this.bind_command_shift_key_event.bind(this);
    this.bind_targeted_key_down_events = this.bind_targeted_key_down_events.bind(this);
    this.unbind_targeted_key_down_events = this.unbind_targeted_key_down_events.bind(this);
    this.bind_targeted_key_up_events = this.bind_targeted_key_up_events.bind(this);
    this.unbind_targeted_key_up_events = this.unbind_targeted_key_up_events.bind(this);
    this.bind_key = this.bind_key.bind(this);
    this.unbind_key = this.unbind_key.bind(this);
    this.bind_key_down = this.bind_key_down.bind(this);
    this.unbind_key_down = this.unbind_key_down.bind(this);
    this.bind_start_event = this.bind_start_event.bind(this);
    this.bind_move_event = this.bind_move_event.bind(this);
    this.bind_end_event = this.bind_end_event.bind(this);
    this.bind_contextmenu_event = this.bind_contextmenu_event.bind(this);
    this.unbind_end_event = this.unbind_end_event.bind(this);
    this.bind_scroll_event = this.bind_scroll_event.bind(this);
    this.bind_mouseover_event = this.bind_mouseover_event.bind(this);
    this.bind_mouseout_event = this.bind_mouseout_event.bind(this);
    this.unbind_mouseout_event = this.unbind_mouseout_event.bind(this);
    this.bind_absolute_mouseout_event = this.bind_absolute_mouseout_event.bind(this);
    this.bind_absolute_mouseover_event = this.bind_absolute_mouseover_event.bind(this);
    this.handle_before_unload = this.handle_before_unload.bind(this);
    this.handle_unload = this.handle_unload.bind(this);
    this.handle_event = this.handle_event.bind(this);
    this.start_event = this.start_event.bind(this);
    this.move_event = this.move_event.bind(this);
    this.end_event = this.end_event.bind(this);
    this.window_drop_event = this.window_drop_event.bind(this);
    this.key_down = this.key_down.bind(this);
    this.key_up = this.key_up.bind(this);
    this.window_mouseover_event = this.window_mouseover_event.bind(this);
    this.window_mouseout_event = this.window_mouseout_event.bind(this);
    this.window_start_event = this.window_start_event.bind(this);
    this.window_move_event = this.window_move_event.bind(this);
    this.window_end_event = this.window_end_event.bind(this);
    this.window_contextmenu = this.window_contextmenu.bind(this);
    this.window_scroll_event = this.window_scroll_event.bind(this);
    this.bubble_event = this.bubble_event.bind(this);
    this.callbacks = {};
    this.mouse_is_down = false;
    this.move_counter = 0;

    this.window_mouse_is_down = false;
    this.window_move_counter = 0;

    this.window_move_position_counter = 0;
    this.window_most_last_position_x = null;
    this.window_most_last_position_y = null;

    this.window_holding_position_counter = 0;
    this.holding_reset = true;

    this.ignore_mouse_events = false;

    // Object storing callbacks for JTDynamoObject updates
    this.jt_dynamo_events = {};

    // Object storing events for FIOAppState updates
    this.fio_state_events = {};

    // Trigger these in non blocking fashion eg. for orientation queues
    this.invisible_end_events = {};

    this.always_move_events = {};

    // Mouse ups that lead to no action
    this.non_events = {};

    this.window_events = {};

    // The idea of a master event is that no matter the target
    // the event will be triggered
    // eg. when you grab a slider handle, you may move all over the window and still have control of the handle
    this.master_ids = [];
    this.master_id = null;
    this.master_events_start = {};
    this.master_events_move = {};
    this.master_events_end = {};

    this.master_move_thresholds = {};

    // Next Events are when the next interaction call an event
    this.next_events = [];

    // Standard events that respond trigger functions based on the target id
    this.window_events_start = {};
    this.window_events_move = {};
    this.window_events_end = {};

    this.window_events_scroll = {};
    this.window_events_mouseover = {};
    this.window_events_mouseout = {};

    this.window_drop_events = {};

    // Bind context menu clicks
    this.contextmenu_events = {};

    // Holding Events ie. tooltips
    this.holding_events = {};
    this.holding_events_cancel = {};
    this.holding_event_timeout = null;
    this.holding_events_cancel_callback = null;
    this.holding_events_duration = {};

    // unload events

    this.window_before_unload_events = {};
    this.window_unload_events = {};

    // Multi-events
    // Bind a function to happen when two things happen one after the other or vise versa

    // em.bind_multi_event('event-key', ['test-button','test-button'], {'order':false}, callback )

    this.multi_events = {};

    // Bubble Events
    this.bubble_end_events = {};

    // Allows yypu to ignore move counter

    this.window_ignore_move_count = {};

    // Hovering is a continuous non-mousedown move event
    this.hovering_events = {};

    // These events for the window
    this.absolute_mouseout_events = [];
    this.absolute_mouseover_events = [];

    // These events will all trigger when a window is resized
    this.window_events_resize = {};

    // These events will fire in succession and then fire a callback if breakpoint changes
    this.event_paths = {};

    // These events are for command / control + Key events

    this.command_key_events = {};
    this.command_key_down_events = {};
    this.command_key_down = false;

    this.command_shift_key_events = {};
    this.shift_key_down = false;

    // These events will be for the key up
    this.key_up_events = {};

    this.key_down_events = {};

    // Targeted key events, binded to element id
    this.targeted_key_down_events = {};
    this.targeted_key_up_events = {};

    this.history_stack = [];
    this.history_added = false;

    // $(window).bind window.cross_platform.startEvent, @window_start_event
    // $(window).bind window.cross_platform.moveEvent, @window_move_event
    // $(window).bind window.cross_platform.endEvent, @window_end_event

    $(window)
      .bind('mousedown', this.window_start_event);
    $(window)
      .bind('touchstart', this.window_start_event);

    $(window)
      .bind('mousemove', this.window_move_event);
    $(window)
      .bind('touchmove', this.window_move_event);

    $(window)
      .bind('mouseup', this.window_end_event);
    $(window)
      .bind('touchend', this.window_end_event);
    $(document)
      .bind('dragend', this.window_end_event);

    $(window)
      .bind('beforeunload', this.handle_before_unload);

    $(window)
      .bind('unload', this.handle_unload);

    $(window)
      .bind("keyup", this.key_up);
    $(window)
      .bind("keydown", this.key_down);

    $(window)
      .bind('resize', this.window_resize_event);

    $(window)
      .bind('mouseover', this.window_mouseover_event);
    $(window)
      .bind('mouseout', this.window_mouseout_event);

    $(window)
      .bind('wheel', this.window_scroll_event);

    $(window)
      .bind('hashchange', this.window_hash_change);

    $(window)
      .bind('popstate', this.window_pop_state);

    $(window)
      .bind('contextmenu', this.window_contextmenu);

    $(window)
      .bind('drop', this.window_drop_event);

    $(window)
      .bind('focus', () => {
        this.command_key_down = false;
        return this.shift_key_down = false;
      });
  }

  // binds a callback function with a unique ID to a state value change.
  bind_fio_state_listener(state_key, id, callback) {
    if (!this.fio_state_events[state_key]) { this.fio_state_events[state_key] = {}; }
    // throw "ID #{id} is not unique to state value: #{state_key}" if @fio_state_events[state_key][id]
    this.fio_state_events[state_key][id] = callback;
    return id;
  }

  // updates FIOAppState value, and fires appropriate functions.
  emit_updated_fio_state_value(state_key, old_value, new_value) {
    if (!this.fio_state_events[state_key]) { return; } //if there's no bound events for the state_key being updated, just quit.
    return Object.keys(this.fio_state_events[state_key])
      .forEach(callback_id => {
        return (typeof this.fio_state_events[state_key][callback_id] === 'function' ? this.fio_state_events[state_key][callback_id](old_value, new_value) : undefined);
      });
  }

  // removes an ID'd callback for specific state_key from event registry
  unbind_fio_state_value(state_key, id) {
    if (!this.fio_state_events[state_key]) { return; }
    return delete this.fio_state_events[state_key][id];
  }

  // emits an event to callback functions registered to JTDynamo events.
  emit_updated_jt_dynamo_value(jt_dynamo_model, instance_updated) {
    let instance_hash_key = instance_updated.hash_key_value();
    if (__guard__(this.jt_dynamo_events != null ? this.jt_dynamo_events[jt_dynamo_model] : undefined, x => x[instance_hash_key])) {
      return Object.keys(this.jt_dynamo_events[jt_dynamo_model][instance_hash_key])
        .forEach(callback_id => {
          return this.jt_dynamo_events[jt_dynamo_model][instance_hash_key][callback_id](instance_hash_key);
        });
    }
  }

  // binds a callback that's fired everytime a specific instance of a Model is changed.
  bind_jt_dynamo_listener(jt_dynamo_model, instance_hash_key, function_id, callback) {
    if (!this.jt_dynamo_events[jt_dynamo_model]) { this.jt_dynamo_events[jt_dynamo_model] = {}; }
    if (!this.jt_dynamo_events[jt_dynamo_model][instance_hash_key]) { this.jt_dynamo_events[jt_dynamo_model][instance_hash_key] = {}; }
    this.jt_dynamo_events[jt_dynamo_model][instance_hash_key][function_id] = callback;
    return function_id;
  }

  unbind_jt_dynamo_listener(jt_dynamo_model, instance_id, function_id) {
    if (__guard__(this.jt_dynamo_events != null ? this.jt_dynamo_events[jt_dynamo_model] : undefined, x => x[instance_id])) {
      return delete this.jt_dynamo_events[jt_dynamo_model][instance_id][function_id];
    }
  }

  register_tap(id, callback) {

    this.callbacks[id] = callback;

    let j_elem = $(`#${id}`);

    j_elem.bind(window.cross_platform.startEvent, this.start_event);
    j_elem.bind(window.cross_platform.moveEvent, this.move_event);
    return j_elem.bind(window.cross_platform.endEvent, this.end_event);
  }

  bind_window_resize_callback(id, callback) {
    return this.window_events_resize[id] = callback;
  }

  unbind_window_resize_event(id) {
    return delete this.window_events_resize[id];
  }

  register_event_path(key, callback) {
    return this.event_paths[key] = { key, callback };
  }

  unregister_event_path(key) {
    return delete this.event_paths[key];
  }

  bind_to_event_path(key, subkey, callback) {
    let event_path = this.event_paths[key];
    if (!event_path.path) { event_path.path = []; }
    return event_path.path.push({ key: subkey, callback });
  }

  run_event_path(key, params) {
    if (params == null) { params = {}; }
    let event_path = this.event_paths[key];
    let { last_key } = event_path;
    let new_key = last_key;
    for (let subevent of Array.from(event_path.path)) {
      if (subevent.callback(last_key)) {
        new_key = subevent.key;
        break;
      }
    }
    if (!last_key || (last_key !== new_key)) {
      event_path.last_key = new_key;
      return event_path.callback(last_key, new_key, params);
    }
  }

  window_resize_event(evt) {
    return (() => {
      let result = [];
      for (let id in this.window_events_resize) {
        let callback = this.window_events_resize[id];
        result.push(callback());
      }
      return result;
    })();
  }

  window_hash_change(evt) {}
  // @trigger_history_event()

  window_pop_state(evt) {
    return this.trigger_history_event();
  }

  add_history_event(state, callback) {
    return this.history_stack.push({ state, callback });
  }

  push_next_event(evt, f, ex, carry_on) {
    if (ex == null) { ex = []; }
    if (carry_on == null) { carry_on = false; }
    return this.next_events.push([evt, f, ex, carry_on]);
  }

  pop_next_event() {
    return this.next_events.pop();
  }

  trigger_all_next_events(evt) {

    let num_events = this.next_events.length;

    if (num_events > 0) {

      return (() => {
        let result = [];
        for (let i = 0, end = num_events - 1, asc = 0 <= end; asc ? i <= end : i >= end; asc ? i++ : i--) {

          let item;
          let next_event = this.next_events.pop();

          if (next_event) {

            item = next_event[1](next_event[0]);
          }
          result.push(item);
        }
        return result;
      })();
    }
  }

  trigger_forward_event() {}

  trigger_history_event() {
    if (this.history_stack.length > 0) {
      let state = this.history_stack[this.history_stack.length - 1]['state'];
      this.history_stack[this.history_stack.length - 1]['callback'](state);
      return this.history_stack.pop();
    }
  }

  // @history_added = false

  register_master_id(id) {
    if (!(Array.from(this.master_ids)
        .includes(id))) { return this.master_ids.push(id); }
  }

  bind_non_event(id, f) {
    return this.non_events[id] = f;
  }

  unbind_non_event(id) {
    return delete this.non_events[id];
  }

  bind_unload_event(id, f) {
    return this.window_unload_events[id] = f;
  }

  unbind_unload_event(id) {
    return delete this.window_unload_events[id];
  }

  bind_before_unload_event(id, f) {
    return this.window_before_unload_events[id] = f;
  }

  unbind_before_unload_event(id) {
    return delete this.window_before_unload_events[id];
  }

  bind_master_start_event(id, callback) {
    this.register_master_id(id);
    return this.master_events_start[id] = callback;
  }

  bind_master_move_event(id, callback, ignore, move_threshold) {
    if (ignore == null) { ignore = false; }
    if (move_threshold == null) { move_threshold = 0; }
    this.register_master_id(id);
    this.window_ignore_move_count[id] = ignore ? true : false;
    this.master_events_move[id] = callback;
    return this.master_move_thresholds[id] = move_threshold;
  }

  bind_master_end_event(id, callback) {
    this.register_master_id(id);
    return this.master_events_end[id] = callback;
  }

  bind_always_move_event(id, callback) {
    return this.always_move_events[id] = callback;
  }

  unbind_always_move_event(id) {
    return delete this.always_move_events[id];
  }

  bind_drop_event(id, callback) {
    return this.window_drop_events[id] = callback;
  }

  bind_invisible_end_event(id, subid, callback) {

    if (!this.invisible_end_events[id]) { this.invisible_end_events[id] = {}; }
    return this.invisible_end_events[id][subid] = callback;
  }

  // em.bind_multi_event('event-key', ['test-button','test-button'], {'order':false}, callback )

  bind_multi_event(id, keys, opts, callback) {

    this.multi_events[id] = { id, keys, current_keys: [] };

    // Allow opts to be missing
    if (!callback) {
      return this.multi_events[id]['callback'] = opts;
    } else {
      this.multi_events[id]['opts'] = opts;
      return this.multi_events[id]['callback'] = callback;
    }
  }

  unbind_multi_event(id) {
    return delete this.multi_events[id];
  }

  clear_multi_event(id) {

    let multi_event = this.multi_events[id];
    if (multi_event) { return multi_event['current_keys'] = []; }
  }

  ping_multi_event(id, k) {

    let multi_event = this.multi_events[id];

    if (multi_event) {

      let key_index;
      let all_keys = multi_event['keys'];
      let current_keys = multi_event['current_keys'];

      let all_keys_length = all_keys.length;

      let reset = false;

      if (multi_event['opts'] && multi_event['opts']['order']) {

        key_index = all_keys.indexOf(k);

        if (current_keys.length === key_index) {
          current_keys.push(k);
        } else {
          reset = true;
        }

      } else {

        key_index = all_keys.indexOf(k);
        let current_key_index = current_keys.indexOf(k);

        if ((key_index !== (-1)) && (current_key_index === (-1))) {
          current_keys.push(k);
        }
      }

      if (reset) {
        multi_event['current_keys'] = [k];
        return;

      } else if (current_keys.length === all_keys_length) {

        multi_event['callback']();
        multi_event['current_keys'] = [];
        return;

      } else {

        return multi_event['current_keys'] = current_keys;
      }
    }
  }

  ping_multi_event_old(id, k) {

    let multi_event = this.multi_events[id];

    if (multi_event) {

      let key;
      let temp_keys = multi_event['keys'].reduce(((a, x) => (a.push(x), a)), []);
      let current_keys = multi_event['current_keys'];

      current_keys.push(k);

      let keys_length = temp_keys.length;
      let reset = false;

      if (multi_event['opts'] && multi_event['opts']['order']) {
        for (let i in current_keys) {
          key = current_keys[i];
          if (temp_keys[i] !== key) {
            reset = true;
            break;
          }
        }
      } else {
        for (key of Array.from(current_keys)) {
          if (Array.from(temp_keys)
            .includes(key)) {
            temp_keys.splice(temp_keys.indexOf(key), 1);
          } else {
            reset = true;
            break;
          }
        }
      }
      if (reset) {
        multi_event['current_keys'] = [k];
        return;

      } else if (current_keys.length === keys_length) {
        multi_event['callback']();
        multi_event['current_keys'] = [];
        return;

      } else {

        return multi_event['current_keys'] = current_keys;
      }
    }
  }

  bind_holding_event(id, callback, uncallback, duration) {
    if (duration == null) { duration = 400; }
    this.holding_events[id] = callback;
    this.holding_events_cancel[id] = uncallback;
    return this.holding_events_duration[id] = duration;
  }

  unbind_holding_event(id, callback) {
    delete this.holding_events[id];
    delete this.holding_events_cancel[id];
    return delete this.holding_events_duration[id];
  }

  bind_bubble_end_event(id) {
    return this.bubble_end_events[id] = true;
  }

  bind_hovering_event(id, callback) {
    return this.hovering_events[id] = callback;
  }

  unbind_hovering_event(id) {
    if (this.hovering_events[id]) { return delete this.hovering_events[id]; }
  }

  bind_command_key_event(key, callback, command_callback) {
    if (command_callback == null) { command_callback = null; }
    this.command_key_events[key] = callback;
    if (command_callback) { return this.command_key_down_events[key] = command_callback; }
  }

  unbind_command_key_event(key) {
    delete this.command_key_events[key];
    return delete this.command_key_down_events[key];
  }

  bind_command_shift_key_event(key, callback) {
    return this.command_shift_key_events[key] = callback;
  }

  bind_targeted_key_down_events(id, callback) {
    return this.targeted_key_down_events[id] = callback;
  }

  unbind_targeted_key_down_events(id) {
    return delete this.targeted_key_down_events[id];
  }

  bind_targeted_key_up_events(id, callback) {
    return this.targeted_key_up_events[id] = callback;
  }

  unbind_targeted_key_up_events(id) {
    return delete this.targeted_key_up_events[id];
  }

  bind_key(key, callback) {
    key = String(key);
    return this.key_up_events[key] = callback;
  }

  unbind_key(key) {
    return delete this.key_up_events[key];
  }

  bind_key_down(key, callback) {
    key = String(key);
    return this.key_down_events[key] = callback;
  }

  unbind_key_down(key) {
    key = String(key);
    return delete this.key_down_events[key];
  }

  bind_start_event(id, callback) {
    return this.window_events_start[id] = callback;
  }

  bind_move_event(id, callback, ignore) {
    if (ignore == null) { ignore = null; }
    this.window_events_move[id] = callback;
    return this.window_ignore_move_count[id] = ignore ? true : false;
  }

  bind_end_event(id, callback, check) {
    this.window_events_end[id] = callback;
    if (check) { return this.window_check_events_end[id] = check; }
  }

  bind_contextmenu_event(id, callback) {
    return this.contextmenu_events[id] = callback;
  }

  unbind_end_event(id) {
    delete this.window_events_end[id];
    if (this.window_check_events_end && this.window_check_events_end[id]) { return delete this.window_check_events_end[id]; }
  }

  bind_scroll_event(id, callback) {
    return this.window_events_scroll[id] = callback;
  }

  bind_mouseover_event(id, callback) {
    return this.window_events_mouseover[id] = callback;
  }

  bind_mouseout_event(id, callback) {
    return this.window_events_mouseout[id] = callback;
  }

  unbind_mouseout_event(id) {
    return delete this.window_events_mouseout[id];
  }

  bind_absolute_mouseout_event(callback) {
    return this.absolute_mouseout_events.push(callback);
  }

  bind_absolute_mouseover_event(callback) {
    return this.absolute_mouseover_events.push(callback);
  }

  handle_before_unload(evt) {
    let keys = Object.keys(this.window_before_unload_events);
    if (keys.length > 0) {
      return this.window_before_unload_events[keys[0]](evt);
    }
  }

  handle_unload(evt) {
    let keys = Object.keys(this.window_before_unload_events);
    if (keys.length > 0) {
      return (() => {
        let result = [];
        for (let key in this.window_unload_events) {
          let callback = this.window_unload_events[key];
          result.push(callback(evt));
        }
        return result;
      })();
    }
  }

  handle_event(evt) {
    let { id } = evt.target;
    if (this.window_events_end[id]) { return this.window_events_end[id](evt); }
  }

  start_event(evt) {

    if (!this.ignore_mouse_events) {

      this.mouse_is_down = true;
      return this.move_counter = 0;
    }
  }

  move_event(evt) {

    if (!this.ignore_mouse_events) {

      if (this.mouse_is_down) {
        return this.move_counter += 1;
      }
    }
  }

  end_event(evt) {

    if (!this.ignore_mouse_events) {

      let { id } = evt.target;

      if (this.mouse_is_down && (this.move_counter < 1)) {
        if (this.callbacks[id]) { this.callbacks[id](evt); }
      }

      this.mouse_is_down = false;

      return true;
    }
  }

  window_drop_event(evt) {
    let { id } = evt.target;

    if (this.window_drop_events[id]) {
      return this.window_drop_events[id](evt, id);
    } else {
      let node = evt.target;
      return (() => {
        let result = [];
        while ((node = node.parentNode)) {
          let item;
          ({ id } = node);
          if (this.window_drop_events[id]) { item = this.window_drop_events[id](evt, id); }
          result.push(item);
        }
        return result;
      })();
    }
  }

  key_down(evt) {

    // Command and Control Keys

    let key;
    let key_code = evt.keyCode;

    if ((key_code === 91) || (key_code === 17) || (key_code === 93) || (key_code === 224)) {
      this.command_key_down = true;

      for (key in this.command_key_down_events) {
        let callback = this.command_key_down_events[key];
        this.command_key_down = callback(evt);
      }

    } else if (key_code === 16) { // Shift Key
      this.shift_key_down = true;
    }

    let { id } = evt.target;
    if (this.targeted_key_down_events[id]) {
      return this.targeted_key_down_events[id](evt);
    } else {

      key = String(evt.keyCode);
      let key2 = String.fromCharCode(evt.keyCode);

      if (this.key_down_events[key]) {

        if (this.command_key_down) {

          if (this.shift_key_down) {
            if (this.command_shift_key_events[key]) {
              return this.command_shift_key_events[key](evt);
            } else if (this.command_shift_key_events[key2]) {
              return this.command_shift_key_events[key2](evt);
            }
          } else {
            if (this.command_key_down) {
              if (this.command_key_events[key]) {
                return this.command_key_events[key](evt);
              } else if (this.command_key_events[key2]) {
                return this.command_key_events[key2](evt);
              }
            }
          }
        } else {
          return this.key_down_events[key](evt);
        }
      } else {

        if (this.command_key_down) {
          if (this.shift_key_down) {
            if (this.command_shift_key_events[key2]) { return this.command_shift_key_events[key2](evt); }
          } else {
            if (this.command_key_events[key2] && this.command_key_down) { return this.command_key_events[key2](evt); }
          }
        } else {
          if (this.key_down_events[key2]) { return this.key_down_events[key2](evt); }
        }
      }
    }
  }

  key_up(evt) {

    let key_code = evt.keyCode;

    this.command_key_down = false;

    if (evt.keyCode === 16) {
      this.shift_key_down = false;
    }

    let { id } = evt.target;

    if (this.targeted_key_up_events[id]) {
      return this.targeted_key_up_events[id](evt);
    } else {
      let key = String(evt.keyCode);
      if (this.key_up_events[key]) {
        return this.key_up_events[key](evt);
      } else {
        key = String.fromCharCode(evt.keyCode);
        if (this.key_up_events[key]) { return this.key_up_events[key](evt); }
      }
    }
  }

  window_mouseover_event(evt) {

    if (!this.ignore_mouse_events) {

      let e = evt.fromElement || evt.relatedTarget;
      if (!e) { for (let callback of Array.from(this.absolute_mouseover_events)) { callback(); } }
      let { id } = evt.target;

      if (this.window_events_mouseover[id]) {
        return this.window_events_mouseover[id](evt, id);
      } else {
        let node = evt.target;
        return (() => {
          let result = [];
          while ((node = node.parentNode)) {
            let item;
            ({ id } = node);
            if (this.window_events_mouseover[id]) { item = this.window_events_mouseover[id](evt, id); }
            result.push(item);
          }
          return result;
        })();
      }
    }
  }

  window_mouseout_event(evt) {

    if (!this.ignore_mouse_events) {

      let e = evt.toElement || evt.relatedTarget;
      if (!e) { for (let callback of Array.from(this.absolute_mouseout_events)) { callback(); } }
      let { id } = evt.target;

      if (this.window_events_mouseout[id]) {
        return this.window_events_mouseout[id](evt, id);
      } else {
        let node = evt.target;
        return (() => {
          let result = [];
          while ((node = node.parentNode)) {
            let item;
            ({ id } = node);
            if (this.window_events_mouseout[id]) { item = this.window_events_mouseout[id](evt, id); }
            result.push(item);
          }
          return result;
        })();
      }
    }
  }

  window_start_event(evt) {
    if (!this.ignore_mouse_events) {

      this.touch_moved = false;

      if (this.holding_event_timeout) { clearTimeout(this.holding_event_timeout); }
      if (this.holding_events_cancel_callback) { this.holding_events_cancel_callback(); }

      this.window_most_last_position_x = null;
      this.window_most_last_position_y = null;

      this.window_move_position_counter = 0;
      this.window_holding_position_counter = 0;

      this.holding_reset = true;

      let next_event = this.next_events.pop();

      if (next_event) {
        let accepted;
        if (evt) {
          let ignored_ids = next_event[2];

          accepted = !Array.from(ignored_ids)
            .includes(evt.target.id);

          let next_evt_node = evt.target;
          while ((next_evt_node = next_evt_node.parentNode)) {
            let next_evt_id = next_evt_node.id;
            if (Array.from(ignored_ids)
              .includes(next_evt_id)) {
              accepted = false;
              break;
            }
          }
        }

        let carry_on = next_event[3];

        if (accepted) {
          next_event[1](next_event[0]);
        } else {
          if (!carry_on) {
            this.next_events.push(next_event);
          }
        }
      }

      let accept = ((evt.which === 1) && !evt.ctrlKey) || window.cross_platform.touchSupported;

      if (!this.window_mouse_is_down && accept) {
        this.window_mouse_is_down = true;
        this.window_move_counter = 0;
        let { id } = evt.target;

        this.master_id = (Array.from(this.master_ids)
          .includes(id)) ? id : null;
        if (this.master_id && this.master_events_start[id]) {
          return this.master_events_start[id](evt);
        } else if (this.window_events_start[id]) {
          return this.window_events_start[id](evt);
        } else {

          if (evt) {
            let node = evt.target;
            while ((node = node.parentNode)) {
              ({ id } = node);
              if (this.window_events_start[id]) {
                this.window_events_start[id](evt);
                let called = true;
              }
            }
          }

          return true;
        }
      } else {
        return false;
      }
    }
  }

  window_move_event(evt) {

    if (!this.ignore_mouse_events) {

      let delta_pos;
      let { id } = evt.target;

      let pos_x = evt.pageX;
      let pos_y = evt.pageY;

      if (evt.type === 'touchmove') { this.touch_moved = true; }

      for (let always_id in this.always_move_events) { let always_callback = this.always_move_events[always_id];
        always_callback(evt, pos_x, pos_y); }

      //# Calculate delta position instead of move event triggers

      if (this.window_most_last_position_x && this.window_most_last_position_y) {

        delta_pos = Math.abs(pos_x - this.window_most_last_position_x) + Math.abs(pos_y - this.window_most_last_position_y);

        this.window_move_position_counter += delta_pos;
        this.window_holding_position_counter += delta_pos;

      } else {
        this.window_move_position_counter += 1;
        this.window_holding_position_counter += 1;
      }

      this.window_most_last_position_x = pos_x;
      this.window_most_last_position_y = pos_y;

      if (this.window_holding_position_counter > (20 - delta_pos)) {

        if (this.holding_events_cancel_callback) { this.holding_events_cancel_callback(); }
        if (this.holding_event_timeout) { clearTimeout(this.holding_event_timeout); }

        this.window_holding_position_counter = 0;
        this.holding_reset = true;

      } else {

        if (this.holding_events[id] && this.holding_reset) {

          this.holding_reset = false;

          if (this.holding_event_timeout) { clearTimeout(this.holding_event_timeout); }

          (evt => {

            let execute_callback = () => {
              ({ id } = evt.target);
              if (this.holding_events[id]) { this.holding_events[id](evt); }
              return this.holding_events_cancel_callback = () => {
                if (this.holding_events_cancel[id]) { this.holding_events_cancel[id](evt); }
                return this.holding_events_cancel_callback = null;
              };
            };

            return this.holding_event_timeout = setTimeout(execute_callback, this.holding_events_duration[id]);
          })(evt);
        }
      }

      let accept = ((evt.which === 1) && !evt.ctrlKey) || window.cross_platform.touchSupported;

      if (this.window_mouse_is_down && accept) {
        this.window_move_counter += 1;

        if (this.master_id && this.master_events_move[this.master_id]) {

          if (this.master_move_thresholds[this.master_id]) {
            if (this.window_move_position_counter > this.master_move_thresholds[this.master_id]) {
              return this.master_events_move[this.master_id](evt);
            } else {
              return true;
            }
          } else {
            return this.master_events_move[this.master_id](evt);
          }

        } else if (this.window_events_move[id]) {
          return this.window_events_move[id](evt);
        } else {

          if (evt) {

            let node = evt.target;
            while ((node = node.parentNode)) {
              ({ id } = node);
              if (this.window_events_move[id]) {
                this.window_events_move[id](evt);
                let called = true;
              }
            }
          }

          return true;
        }
      } else {
        if (this.hovering_events[id]) { return this.hovering_events[id](evt); }
      }
    }
  }

  window_end_event(evt) {

    if (!this.ignore_mouse_events) {

      let { id } = evt.target;

      // always execute invisible events
      if (this.invisible_end_events[id]) {
        for (let subid in this.invisible_end_events[id]) {
          let callback = this.invisible_end_events[id][subid];
          callback(evt);
        }
      }

      // Track non-events
      let called = false;

      let accept = ((evt.which === 1) && !evt.ctrlKey) || window.cross_platform.touchSupported;

      if (this.window_mouse_is_down && accept && ((this.window_move_position_counter < 10) || (this.window_ignore_move_count[this.master_id] === true) || (this.window_ignore_move_count[id] === true))) {

        if (this.master_id && this.master_events_end[this.master_id]) {
          this.master_events_end[this.master_id](evt);
          called = true;

        } else if (this.window_events_end[id]) {
          this.window_events_end[id](evt);
          called = true;
        } else {
          let node = evt.target;
          while ((node = node.parentNode)) {
            ({ id } = node);
            if (this.window_events_end[id]) {
              this.window_events_end[id](evt);
              called = true;
            }
          }
        }
      }

      if (!called && evt.target.id) {
        for (let k in this.non_events) { let f = this.non_events[k];
          f(evt.target.id); }
      }

      this.window_move_counter = 0;
      this.window_mouse_is_down = false;

      this.window_move_position_counter = 0;
      this.window_most_last_position_x = null;
      this.window_most_last_position_y = null;

      this.window_holding_position_counter = 0;
      this.holding_reset = true;

      return true;
    }
  }

  window_contextmenu(evt) {

    let { id } = evt.target;
    if (this.contextmenu_events[id]) {
      evt.preventDefault();
      evt.stopPropagation();
      return this.contextmenu_events[id](evt);
    }
  }

  window_scroll_event(evt) {

    if (this.holding_event_timeout) { clearTimeout(this.holding_event_timeout); }
    if (this.holding_events_cancel_callback) { this.holding_events_cancel_callback(); }

    let { id } = evt.target;
    if (this.window_events_scroll[id]) {
      return this.window_events_scroll[id](evt);
    } else {
      let node = evt.target;
      return (() => {
        let result = [];
        while ((node = node.parentNode)) {
          let item;
          ({ id } = node);
          if (this.window_events_scroll[id]) { item = this.window_events_scroll[id](evt); }
          result.push(item);
        }
        return result;
      })();
    }
  }

  bubble_event(elem) {
    if (elem) {
      if (this.window_events_end[elem.id]) {
        return this.window_events_end[elem.id](null, elem.id);
      } else {
        return this.bubble_event(elem.offsetParent);
      }
    }
  }
}

export default JTEventManager;

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined;
}
