import React from 'react';
import styled, { withTheme } from 'styled-components';
import { shade } from 'polished';
import PropTypes from 'prop-types';
import { FETCH_ACTION_TYPES } from '@frameio/core/src/shared/actions/helpers';
import Avatar from '@frameio/components/src/styled-components/Avatar';
import buttonReset from '@frameio/components/src/mixins/buttonReset';
import ListCell from '@frameio/components/src/styled-components/ListCell';
import { trackButtonClick } from 'analytics';

const Resend = styled.button`
  ${buttonReset()}
  color: ${({ theme, failed }) =>
    failed ? theme.color.error : theme.color.brand};

  &:hover {
    color: ${({ theme, failed }) =>
      shade(0.95, failed ? theme.color.error : theme.color.brand)};
  }
`;

const ResendSuccess = styled.span`
  color: ${(p) => p.theme.color.success};
`;

function PendingCollaborator({
  resendInvite,
  pendingCollaborator,
  resendInviteStatus,
  trackingPage,
  trackingPosition,
  theme,
}) {
  const { email, project_id: projectId } = pendingCollaborator;

  const trackClick = (eventName) => {
    if (trackingPosition && trackingPage) {
      trackButtonClick(eventName, trackingPage, trackingPosition);
    }
  };

  const onResendInvite = () => {
    resendInvite(projectId, email);
    trackClick('resend_invite');
  };

  const renderResendInvite = () => {
    switch (resendInviteStatus) {
      case FETCH_ACTION_TYPES.PENDING:
        return <span data-test-id="sending-state">Sending…</span>;

      case FETCH_ACTION_TYPES.SUCCESS:
        return <ResendSuccess>Sent!</ResendSuccess>;

      case FETCH_ACTION_TYPES.FAILURE:
        return (
          <Resend failed onClick={onResendInvite}>
            Failed. Click to try again.
          </Resend>
        );

      default:
        return <Resend onClick={onResendInvite}>Resend invite</Resend>;
    }
  };

  return (
    <ListCell
      title={email}
      details={
        <React.Fragment>
          Pending
          {resendInvite && (
            <React.Fragment>
              &nbsp;·&nbsp;
              {renderResendInvite()}
            </React.Fragment>
          )}
        </React.Fragment>
      }
      image={<Avatar size={32} name={email} color={theme.color.lightGray} />}
    />
  );
}

export default withTheme(PendingCollaborator);

PendingCollaborator.propTypes = {
  resendInvite: PropTypes.func,
  pendingCollaborator: PropTypes.shape({
    email: PropTypes.string.isRequired,
    project_id: PropTypes.string.isRequired,
  }).isRequired,
  resendInviteStatus: PropTypes.string,
  trackingPage: PropTypes.string,
  trackingPosition: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

PendingCollaborator.defaultProps = {
  resendInvite: undefined,
  pendingCollaborator: undefined,
  resendInviteStatus: undefined,
  trackingPage: undefined,
  trackingPosition: undefined,
};

export const testExports = { Resend, ResendSuccess };
