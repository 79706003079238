import { cancel, fork, select, take, takeLatest } from 'redux-saga/effects';
import { MODAL } from 'components/Modal/actions';
import { currentAccountSelector } from 'selectors/accounts';
import { isAccountAdminSelector } from 'selectors/roles';
import { LIFETIME_FILE_LIMIT_REACHED_FLOW } from './actions';
import history from './history';
import {
  OUT_OF_UPLOADS_URL,
  SELF_SERVE_UPGRADE_PLAN_URL,
  REQUEST_ADMIN_TO_UPGRADE_URL,
} from './LifetimeFileLimitReachedFlow';

function* watchLifetimeFileLimitReachedFlow() {
  // Step 1: Show limit reached info screen
  history.push(OUT_OF_UPLOADS_URL);

  // Step 2: User has decided to proceed with the upgrade
  yield take(LIFETIME_FILE_LIMIT_REACHED_FLOW.CONTINUE);
  const { id: accountId } = yield select(currentAccountSelector);
  const isAccountAdmin = yield select(isAccountAdminSelector, { accountId });

  // eslint-disable-next-line no-lonely-if
  if (isAccountAdmin) {
    history.replace(SELF_SERVE_UPGRADE_PLAN_URL);
  } else {
    history.replace(REQUEST_ADMIN_TO_UPGRADE_URL);
  }
}

// https://redux-saga.js.org/docs/advanced/TaskCancellation.html
export function* startLifetimeFileLimitReachedFlow() {
  const flow = yield fork(watchLifetimeFileLimitReachedFlow);
  yield take([MODAL.CLOSE, LIFETIME_FILE_LIMIT_REACHED_FLOW.END]);
  yield cancel(flow);
}

export default [
  takeLatest(
    LIFETIME_FILE_LIMIT_REACHED_FLOW.START,
    startLifetimeFileLimitReachedFlow
  ),
];
