import { connect } from 'react-redux';
import { hydratedAssetEntitiesByAssetIdsSelector } from '@frameio/core/src/assets/selectors';

import { enforceAuthedUser } from 'pages/ReviewLinkContainer/actions';
import SetAssetsWorkflowStatus from './SetAssetsWorkflowStatus';

function mapStateToProps(state, { assetIds }) {
  return {
    assets: hydratedAssetEntitiesByAssetIdsSelector(state, { assetIds }),
  };
}

const mapDispatchToProps = {
  enforceAuthedUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetAssetsWorkflowStatus);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
