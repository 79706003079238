import React from 'react';
import {
  takeEvery,
  put,
  call,
  all,
  race,
  take,
  select,
} from 'redux-saga/effects';
import { batchCopyAssets } from '@frameio/core/src/assets/actions';
import { getProjectTree } from '@frameio/core/src/projects/sagas';
import { openModal, closeModal } from 'components/Modal/actions';
import { type as AssetType } from '@frameio/core/src/assets/helpers/constants';
import { hydratedAssetEntitiesByAssetIdsSelector } from '@frameio/core/src/assets/selectors';
import {
  takeSuccessWithEntityId,
  takeFailureWithEntityId,
} from '@frameio/core/src/shared/sagas/helpers';
import { isCollaboratorOnlySelector } from 'selectors/roles';
import { folderSharingEnabled } from 'utils/featureFlags';
import { renameAsset } from 'pages/ProjectContainer/actions';
import {
  MOVE_TO,
  fetchSuccess,
  fetchError,
  cancelPendingRequests,
  clearAllComments,
  fetchStart,
  isSaving,
  reset,
} from './actions';
import MoveAssetsDialog from './ConnectedMoveAssetsDialog';
import { moveAssets } from '../../../pages/ProjectContainer/sagas';
import { showSuccessToast, showErrorToast } from '../../../actions/toasts';
import { moveToFolderSelector } from './selectors';

/**
 * Show our tree view in a modal dialog
 * @param {string[]} selectedAssets selected assets to move
 */

function* promptMoveToFolder({
  payload: { selectedAssets, trackingPage, trackingPosition },
}) {
  yield put(reset());
  yield put(
    openModal(
      <MoveAssetsDialog
        assetsToMove={selectedAssets}
        title="Move to"
        trackingPage={trackingPage}
        trackingPosition={trackingPosition}
      />
    )
  );
}

function* promptCopyToFolder({
  payload: { selectedAssets, trackingPage, trackingPosition },
}) {
  yield put(reset());
  yield put(
    openModal(
      <MoveAssetsDialog
        assetsToMove={selectedAssets}
        shouldCopy
        title="Copy to"
        trackingPage={trackingPage}
        trackingPosition={trackingPosition}
      />
    )
  );
}

/**
 * Throw an error if the api responds with a failure
 * @param {Generator} fetch the fetch function to call
 * @param {*} params
 */

function* tryFetch(fetch, ...params) {
  const res = yield call(fetch, ...params);
  if (res.failure) {
    throw new Error('Failed to fetch page');
  }
  return res;
}

/**
 * Initialize our tree with expanded ancestors, and fetch
 * the tree for the project. Use forceNewFetch as true to force a new call.
 * regardless if tree was already loaded. This is particularly useful if the
 * frontend knows an update is required.
 * @param {*} projectId
 * @param {*} forceNewFetch
 */

function* loadProjectFolderTree({ payload: { projectId, forceNewFetch } }) {
  try {
    const moveTo = yield select(moveToFolderSelector);
    const existingTree = moveTo.assetFolderTrees[projectId];

    // prevent refetches, unless an error has occurred
    if (existingTree && existingTree.error !== true && !forceNewFetch) {
      return {};
    }

    // indicate loading
    yield put(fetchStart(projectId));

    const isCollaboratorOnly = yield select(isCollaboratorOnlySelector, {
      projectId,
    });

    const filters = isCollaboratorOnly ? { 'filter[private]': false } : {};
    const isFolderSharingEnabled = yield select(folderSharingEnabled);
    // fetch our asset tree
    const { tree, cancel } = yield race({
      tree: call(tryFetch, getProjectTree, projectId, {
        depth: 20,
        ...filters,
        include: isFolderSharingEnabled ? 'review_links' : undefined,
      }),
      cancel: take(MOVE_TO.CANCEL_PENDING_REQUESTS),
    });

    // cancel should only be called when closing the modal
    if (cancel) {
      return {};
    }

    const treeList = tree.success.payload.response.data;
    yield put(fetchSuccess(projectId, treeList));
    return treeList;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    // indicate an error to allow for retries
    yield put(fetchError(projectId));
    return {};
  }
}

/**
 * Close our modal and perform cleanup
 */

function* close() {
  yield put(closeModal());
  yield put(cancelPendingRequests());
}

/**
 * Actually move the assets and close the modal
 * @param {string[]} assetIds array of asset ids to move
 * @param {string} folderId destination folder
 */

function* performAssetMove({ payload: { destinationFolder, selectedAssets } }) {
  yield put(isSaving(true));
  const failedAssets = yield call(
    moveAssets,
    selectedAssets,
    destinationFolder
  );
  if (failedAssets.length === 0) {
    yield put(
      showSuccessToast({
        header: `Your ${
          selectedAssets.length > 1 ? 'files were' : 'file was'
        } successfully moved.`,
      })
    );
    yield put(clearAllComments());
    yield call(close);
  } else {
    yield put(isSaving(false));
  }
}

/**
 * Copy assets (instead of moving)
 */

function* performAssetCopy({
  payload: { destinationFolder, selectedAssets, shouldIncludeComments },
}) {
  yield put(isSaving(true));

  const action = batchCopyAssets(selectedAssets, destinationFolder, {
    copyComments: shouldIncludeComments ? 'all' : 'none',
  });

  yield put(action);

  const { success } = yield race({
    success: takeSuccessWithEntityId(action, selectedAssets, destinationFolder),
    failure: takeFailureWithEntityId(action, selectedAssets, destinationFolder),
  });

  let failedAssetIds;

  if (success) {
    const {
      payload: {
        response: { error: responseError },
      },
    } = success;
    failedAssetIds = Object.keys(responseError);
  } else {
    failedAssetIds = selectedAssets;
  }

  const successCount = selectedAssets.length - failedAssetIds.length;

  if (!successCount) {
    yield put(
      showErrorToast({
        header: 'Whoops, something went wrong while copying these assets.',
        subHeader: 'Please try again.',
      })
    );
    yield put(isSaving(false));
    return;
  }

  if (failedAssetIds.length) {
    const assets = yield select(hydratedAssetEntitiesByAssetIdsSelector, {
      assetIds: failedAssetIds,
    });

    yield all(
      assets.map((asset) =>
        put(
          showErrorToast({
            header: (
              <span>
                Whoops, something went wrong while copying{' '}
                <strong>
                  {asset.type === AssetType.VERSION_STACK
                    ? asset.cover_asset.name
                    : asset.name}
                </strong>
                .
              </span>
            ),
            subHeader: 'Please try again.',
          })
        )
      )
    );

    yield put(isSaving(false));
    return;
  }

  yield put(
    showSuccessToast({
      header: `Your ${
        selectedAssets.length > 1 ? 'files were' : 'file was'
      } successfully copied.`,
    })
  );
  yield put(isSaving(false));
  yield call(close);
}

function* performAssetDuplicate({
  payload: { destinationFolder, selectedAssets },
}) {
  yield put(isSaving(true));

  const action = batchCopyAssets(selectedAssets, destinationFolder, {
    copyComments: 'none',
  });

  yield put(action);

  const { success } = yield race({
    success: takeSuccessWithEntityId(action, selectedAssets, destinationFolder),
    failure: takeFailureWithEntityId(action, selectedAssets, destinationFolder),
  });

  let failedAssetIds;
  let duplicatedIds;

  if (success) {
    const {
      payload: {
        response: { error: responseError, entities },
      },
    } = success;

    duplicatedIds = Object.keys(entities.asset);
    failedAssetIds = Object.keys(responseError);
  } else {
    failedAssetIds = selectedAssets;
  }

  const successCount = selectedAssets.length - failedAssetIds.length;

  if (!successCount) {
    yield put(
      showErrorToast({
        header: 'Whoops, something went wrong while duplicating these assets.',
        subHeader: 'Please try again.',
      })
    );
    yield put(isSaving(false));
    return;
  }

  if (failedAssetIds.length) {
    const assets = yield select(hydratedAssetEntitiesByAssetIdsSelector, {
      assetIds: failedAssetIds,
    });

    yield all(
      assets.map((asset) =>
        put(
          showErrorToast({
            header: (
              <span>
                Whoops, something went wrong while duplicating{' '}
                <strong>
                  {asset.type === AssetType.VERSION_STACK
                    ? asset.cover_asset.name
                    : asset.name}
                </strong>
                .
              </span>
            ),
            subHeader: 'Please try again.',
          })
        )
      )
    );

    yield put(isSaving(false));
    return;
  }

  const assets = yield select(hydratedAssetEntitiesByAssetIdsSelector, {
    assetIds: duplicatedIds,
  });

  yield all(
    assets.map((asset) => put(renameAsset(asset.id, `${asset.name} [COPY]`)))
  );

  yield put(
    showSuccessToast({
      header: `Your ${
        selectedAssets.length > 1 ? 'files were' : 'file was'
      } successfully duplicated.`,
    })
  );
  yield put(isSaving(false));
  yield call(close);
}

export default [
  takeEvery(MOVE_TO.PROMPT_COPY, promptCopyToFolder),
  takeEvery(MOVE_TO.FETCH, loadProjectFolderTree),
  takeEvery(MOVE_TO.PROMPT, promptMoveToFolder),
  takeEvery(MOVE_TO.MOVE_ASSETS, performAssetMove),
  takeEvery(MOVE_TO.COPY_ASSETS, performAssetCopy),
  takeEvery(MOVE_TO.DUPLICATE_ASSETS, performAssetDuplicate),
  takeEvery(MOVE_TO.CANCEL, close),
];

export const testExports = {
  promptCopyToFolder,
  promptMoveToFolder,
  performAssetMove,
  close,
  tryFetch,
  loadProjectFolderTree,
};
