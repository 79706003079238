import { createSelector } from 'reselect';
import { get } from 'lodash';
import { userEntitiesSelector } from '@frameio/core/src/users/selectors';
import { isAnonymousUser } from '@frameio/core/src/users/utils';

export const userEntitySelector = (state, { userId }) =>
  (userEntitiesSelector(state) || {})[userId];

export const currentUserSelector = (state) => state.currentUser;

/**
 * @param   {Object} state - Redux store state.
 * @returns {Object} - CurrentUser data.
 */

export const currentUserEntitySelector = createSelector(
  [userEntitiesSelector, currentUserSelector],
  (userEntities, currentUser) => userEntities[currentUser.id]
);

export const hasCurrentUserSeenPlayerSelector = createSelector(
  currentUserEntitySelector,
  (currentUser) => get(currentUser, 'features_seen.player_page')
);

export const isCurrentUserAnonymousSelector = createSelector(
  currentUserEntitySelector,
  (currentUser) => !!currentUser && isAnonymousUser(currentUser)
);

export const isAuthedUserSelector = createSelector(
  currentUserSelector,
  (currentUser) => !!currentUser && currentUser.isAuthed
);

// When the user has confirmed its email address, email_confirm_by is set to null. Otherwise it is
// set to a date (the date to confirm the email by).
export const hasCurrentUserConfirmedEmailAddress = createSelector(
  currentUserEntitySelector,
  (currentUser) => !!currentUser && !currentUser.email_confirm_by
);
