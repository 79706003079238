import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { Menu, MenuButton } from '@frameio/vapor';
import DropdownButton from 'components/SharePanel/DropDown/DropdownButton';
import {
  AEM,
  ERROR_MESSAGES,
  MENU_ITEMS,
} from 'components/AssetActions/AdobeIntegration/integrationConstants';
import PulsingCircle from '@frameio/components/src/styled-components/PulsingCircle';
import { isEmpty, noop } from 'lodash';
import MoveAssetsDialog from 'components/AssetActions/MoveTo/ConnectedMoveAssetsDialog';
import { TEXT } from '../helper';

const StyledEmptyRepositoryContainer = styled(Flex)`
  margin-top: 43%;
  justify-content: center;
`;

const StyledFlex = styled(Flex)`
  flex-direction: column;
  gap: 14px;
  margin-top: 20px;
`;

const StyledLoadingContainer = styled(Flex)`
  width: ${(p) => p.theme.spacing.units(67)};
  margin-left: -20px;
  margin-top: 25%;
`;

const StyledDropdownButton = styled(DropdownButton)`
  color: ${(p) => p.$noSelectionMade && p.theme.color.gray};
`;

const AemModalContent = (props) => {
  const {
    aemInterface: { closeModal, selectAemDestination },
    aemFolders,
    aemFoldersLoading,
    aemRepositories,
    assetIds,
    project,
    selectedAemDestination,
  } = props;
  const { selectRepository } = TEXT(AEM) || {};

  const {
    repo_id: selectedAemDestinationRepoId,
    repo_title: selectedAemRepositoryTitle,
  } = selectedAemDestination || {};

  const handleRepositorySelection = (aemRepository) => {
    if (selectedAemDestinationRepoId === aemRepository.repo_id) return;
    const { path, repo_id, rootFolderId, title } = aemRepository || {};

    selectAemDestination({
      aemFolderId: rootFolderId,
      aemPath: path,
      aemRepositoryId: repo_id,
      aemRepositoryTitle: title,
      projectId: project.id,
    });
  };

  const hasAuthorTierRepositories = Object.keys(aemRepositories).some((key) =>
    key.startsWith('author')
  );

  const isAemRepositoriesAvailable =
    !isEmpty(aemRepositories) && hasAuthorTierRepositories;

  const isAemFolders = !isEmpty(aemFolders);

  return isAemRepositoriesAvailable ? (
    <StyledFlex>
      {selectRepository}
      <Menu
        placement="bottom-start"
        content={Object.entries(aemRepositories).map(([index, repo]) => {
          if (repo.tier === 'author') {
            return (
              <MenuButton
                key={index}
                disabled={!repo}
                onSelect={() => handleRepositorySelection(repo)}
              >
                {repo.title}
              </MenuButton>
            );
          }
          return null;
        })}
      >
        <StyledDropdownButton
          disabled={isEmpty(aemRepositories)}
          $noSelectionMade={!selectedAemDestinationRepoId}
          dropdown
          text
        >
          {selectedAemRepositoryTitle || MENU_ITEMS.AEM_DROPDOWN_PROMPT}
        </StyledDropdownButton>
      </Menu>
      {isAemFolders && (
        <MoveAssetsDialog
          assetsToMove={assetIds}
          closeModal={closeModal}
          copyAssets={noop}
          currentProject={project}
          moveAssets={noop}
          onSetUserSelectedId={noop}
          selectedAemDestination={selectedAemDestination}
          title="Send to"
        />
      )}
      {aemFoldersLoading && !isAemFolders && (
        <StyledLoadingContainer>
          <PulsingCircle />
        </StyledLoadingContainer>
      )}
    </StyledFlex>
  ) : (
    <StyledEmptyRepositoryContainer>
      {ERROR_MESSAGES.aemRepositoryEmpty}
    </StyledEmptyRepositoryContainer>
  );
};

AemModalContent.propTypes = {
  aemInterface: PropTypes.object,
  aemRepositories: PropTypes.object,
};

export default AemModalContent;
