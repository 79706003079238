import {
  put,
  takeLatest,
  select,
  spawn,
  call,
  race,
  take,
} from 'redux-saga/effects';
import {
  openSelectPlanFlowModal,
  SELECT_PLAN_FLOW,
} from 'components/SelectPlanFlow/actions';
import track from 'analytics';
import { currentAccountSelector } from 'selectors/accounts';
import { permittedActionsForAccountSelector } from 'selectors/permissions';
import { RESOLUTION_PICKER } from './actions';

function* upgradePlan({ payload: { onSuccesfulPlanUpgradeCallback } }) {
  const { id: accountId } = yield select(currentAccountSelector);

  yield put(
    openSelectPlanFlowModal(accountId, {
      source: '4k playback feature gate',
      feature: '4K_playback',
    })
  );

  const { success, cancelled } = yield race({
    success: take(SELECT_PLAN_FLOW.REPORT_PLAN_CHANGE_SUCCESS),
    cancelled: take(SELECT_PLAN_FLOW.END),
  });

  if (cancelled) return;

  const { canUse4KPlayback } = yield select(
    permittedActionsForAccountSelector,
    {
      accountId,
    }
  );

  // If the plan upgrade is successful, then `onSuccesfulPlanUpgradeCallback` will automatically
  // change the player's resolution to 4K.
  if (canUse4KPlayback && success) {
    yield spawn(track, 'feature-unlocked', { feature: '4K_playback' });
    yield call(onSuccesfulPlanUpgradeCallback);
  }
}

export default [takeLatest(RESOLUTION_PICKER.UPGRADE_PLAN, upgradePlan)];

export const testExports = {
  upgradePlan,
};
