/* eslint-disable prefer-default-export */
import { generateActionType } from 'shared/actions/helpers';

export const SHOW_BLOCKED_COLLABORATOR_MESSAGE = generateActionType(
  'REQUEST_TO_JOIN_TEAM',
  'SHOW_BLOCKED_COLLABORATOR_MESSAGE'
);

export function showBlockedCollaboratorMessage(projectId, trackTrigger) {
  return {
    type: SHOW_BLOCKED_COLLABORATOR_MESSAGE,
    payload: { projectId, trackTrigger },
  };
}
