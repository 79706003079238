import { createAction } from 'redux-actions';
import {
  generateFetchActionTypes,
  generateActionTypes,
} from 'shared/actions/helpers';

const MODULE = 'DELETED_ASSETS';
const DELETED_ASSETS = {
  ...generateFetchActionTypes(
    MODULE,
    ['GET_IN_PROJECT', 'SEARCH_IN_PROJECT', 'SEARCH_IN_TEAM'],
    true
  ),
  ...generateFetchActionTypes(MODULE, ['UNDELETE_ASSET']),
  ...generateActionTypes(MODULE, ['SELECT_ASSETS']),
};

export default DELETED_ASSETS;

export const getDeletedAssetsInProject = createAction(
  DELETED_ASSETS.GET_IN_PROJECT.BASE,
  (projectId, page) => ({ projectId, page })
);

export const searchDeletedAssetsInProject = createAction(
  DELETED_ASSETS.SEARCH_IN_PROJECT.BASE,
  (query, projectId, page) => ({ query, projectId, page })
);

export const resetSearchAssetsInProject = createAction(
  DELETED_ASSETS.SEARCH_IN_PROJECT.RESET,
  () => ({})
);

export const searchDeletedAssetsInTeam = createAction(
  DELETED_ASSETS.SEARCH_IN_TEAM.BASE,
  (query, teamId, page) => ({ query, teamId, page })
);

export const resetSearchAssetsInTeam = createAction(
  DELETED_ASSETS.SEARCH_IN_TEAM.RESET,
  () => ({})
);

export const undeleteAsset = createAction(
  DELETED_ASSETS.UNDELETE_ASSET.BASE,
  (assetId) => ({ assetId })
);

export const selectAssets = createAction(
  DELETED_ASSETS.SELECT_ASSETS,
  (assetIds) => ({ assetIds })
);
