export default (extension) => {
  if (typeof extension !== 'string') return '#616A8B';

  switch (extension.toLowerCase()) {
    case 'zip':
    case 'rar':
      return '#616A8B';
    case 'pdf':
      return '#FF3A6D';
    case 'html':
    case 'htm':
    case 'psd':
      return '#5167D7';
    case 'ai':
      return '#D59400';
    case 'prproj':
    case 'mogrt':
      return '#EA77FF';
    case 'aeproj':
    case 'aep':
    case 'aecap':
    case 'aepx':
      return '#D291FF';
    case 'r3d':
      return '#FF0000';
    default:
      return '#616A8B';
  }
};
