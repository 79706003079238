import { isPathNameReviewPage, isPathNamePresentationPage } from 'URLs';
import loadDriftScript from './loadDriftScript';

let isDriftLoaded = false;

function initializeDrift() {
  if (!window.drift) return;
  window.drift.on('ready', (api) => {
    const driftApi = api;

    // hide welcome message on load
    driftApi.hideWelcomeMessage();

    // hide chat widget on load
    driftApi.widget.hide();

    window.drift.on('chatClose', () => {
      // hide chat widget when a User closes it
      driftApi.widget.hide();
    });
  });
}

export function loadDrift({ identifyProperties, user, driftToken }) {
  const { pathname } = window.location;
  const isPresentationPage = isPathNamePresentationPage(pathname);
  const isReviewLinkPage = isPathNameReviewPage(pathname);
  const shouldLoadDrift =
    !isDriftLoaded && !isPresentationPage && !isReviewLinkPage;

  if (shouldLoadDrift) {
    isDriftLoaded = true;
    loadDriftScript({ identifyProperties, user, driftToken });
    initializeDrift();
  }
}

export function openDrift(playbookId) {
  if (!window.drift) {
    // eslint-disable-next-line no-alert
    return alert('Drift is not initialized in dev.');
  }
  return window.drift.api.startInteraction({ interactionId: playbookId });
}
