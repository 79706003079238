import React from 'react';
import PropTypes from 'prop-types';
import { MenuButton, MenuGroup } from '@frameio/vapor';
import ShareIcon from '@frameio/components/src/svgs/icons/share.svg';
import ShareAssetsAsReview from './ShareAssetsAsReview';
import ShareAssetsAsPresentation from './ShareAssetsAsPresentation';

export default function ShareAssets({
  assetIds,
  canShareAsPresentation,
  canShareAsReview,
  folderId,
  projectId,
  trackingPage,
  trackingPosition,
  ...rest
}) {
  if (canShareAsReview && canShareAsPresentation) {
    const trackingProps = {
      trackingPage,
      trackingPosition,
    };

    return (
      <MenuButton
        iconBefore={<ShareIcon width={16} height={16} />}
        submenu={
          <MenuGroup>
            {canShareAsReview && (
              <ShareAssetsAsReview
                assetIds={assetIds}
                projectId={projectId}
                {...trackingProps}
              />
            )}
            {canShareAsPresentation && (
              <ShareAssetsAsPresentation
                assetIds={assetIds}
                folderId={folderId}
                projectId={projectId}
                {...trackingProps}
              />
            )}
          </MenuGroup>
        }
        {...rest}
      >
        Share
      </MenuButton>
    );
  }

  if (canShareAsReview) {
    return (
      <ShareAssetsAsReview
        assetIds={assetIds}
        projectId={projectId}
        trackingPage={trackingPage}
        trackingPosition={trackingPosition}
      />
    );
  }
  if (canShareAsPresentation) {
    return (
      <ShareAssetsAsPresentation
        assetIds={assetIds}
        folderId={folderId}
        projectId={projectId}
        trackingPage={trackingPage}
        trackingPosition={trackingPosition}
      />
    );
  }
  return null;
}

ShareAssets.propTypes = {
  assetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  canShareAsPresentation: PropTypes.bool,
  canShareAsReview: PropTypes.bool,
  folderId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};

ShareAssets.defaultProps = {
  canShareAsPresentation: false,
  canShareAsReview: false,
  folderId: undefined,
  project: undefined,
};
