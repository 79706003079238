import { useLayoutEffect, useRef, useState } from 'react';

const DEFAULT_UPDATE_TIMEOUT = 500;

/**
 * @param {*[]} depArray the array of values to watch for updates
 * @return {[React.ref, BoundingClientRect]} returns the ref of the child, and its bounds
 */
const useGetElementSize = (depArray) => {
  const ref = useRef(null);
  const [childBounds, setChildBounds] = useState(null);

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    // This timeout gives us time for the asset ref's bounds to finish updating
    setTimeout(() => {
      if (!ref.current) {
        return;
      }

      setChildBounds(ref.current.getBoundingClientRect());
    }, DEFAULT_UPDATE_TIMEOUT);

    // The linter doesn't recognize that this is an array literal
    /* eslint-disable react-hooks/exhaustive-deps */
  }, depArray);
  /* eslint-enable react-hooks/exhaustive-deps */

  return [ref, childBounds];
};

export default useGetElementSize;
