import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import {
  call,
  spawn,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { accountEntityForAssetIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import { dialogTypes } from '@frameio/components/src/styled-components/Dialog';
import { currentAccountWithSubscriptionAndPlanSelector } from 'selectors/accounts';
import track from 'analytics';
import { allFeatures, getLowestTierForFeature } from 'planFeatures';
import {
  openSelectPlanFlowModal,
  SELECT_PLAN_FLOW,
} from 'components/SelectPlanFlow/actions';
import {
  permittedActionsForAccountSelector,
  permittedActionsForProjectSelector,
} from 'selectors/permissions';
import { isAccountAdminSelector } from 'selectors/roles';
import { alert, confirm } from 'components/Dialog/SimpleDialog/sagas';
import AccountOwnerPill from 'components/AccountOwnerPill';
import basePlansByTier, {
  defaultPlanVersion,
} from 'components/SelectPlanFlow/PlanDisplay';

import { v8PricingOnlyAndMigrationFlowsUpdatesEnabled } from 'utils/featureFlags';
import { COMMENT_COMPOSER, setCommentPrivacy } from './actions';
import SpeechBubbles from './speech-bubbles.svg';

const { teamOnlyComments } = allFeatures;

const UpgradeContainer = styled.div`
  margin: ${({ theme }) => `${theme.spacing.large} ${theme.spacing.tiny}`};
  text-align: center;
`;

const UpgradeExplainer = styled.p`
  color: ${(p) => p.theme.color.gray};
  margin-bottom: ${(p) => p.theme.spacing.tiny};

  strong {
    color: inherit;
  }
`;

const UserPillContainer = styled(Flex)`
  margin-top: ${(p) => p.theme.spacing.tiny};
`;

const AccountOwnerCopyContainer = styled.div`
  margin: ${({ theme }) => `${theme.spacing.small} 0`};
`;

const AccountOwnerSubcopy = styled.p`
  color: ${(p) => p.theme.color.gray};
  text-align: center;
  font-size: ${(p) => p.theme.fontSize[1]};
`;

function* showAdminBlock(accountId) {
  const areV8PricingOnlyAndMigrationFlowsUpdatesEnabled = yield select(
    v8PricingOnlyAndMigrationFlowsUpdatesEnabled
  );
  const {
    plan: { version },
  } = yield select(currentAccountWithSubscriptionAndPlanSelector);
  const versionForPlanSelector = areV8PricingOnlyAndMigrationFlowsUpdatesEnabled
    ? defaultPlanVersion
    : version;
  const tier = getLowestTierForFeature(allFeatures.teamOnlyComments);
  const plan = basePlansByTier({ version: versionForPlanSelector, tier });

  const response = yield call(
    confirm,
    'Unlock team-only comments',
    <UpgradeContainer>
      <SpeechBubbles />
      <UpgradeExplainer>
        Upgrade to the <strong>{plan.title} plan</strong> to enable team-only
        comments for you and your team.
      </UpgradeExplainer>
    </UpgradeContainer>,
    {
      primaryText: 'Upgrade',
      secondaryText: 'No thanks',
      type: dialogTypes.NONE,
    }
  );
  if (!response) return;

  // Start plan upgrade flow
  yield put(
    openSelectPlanFlowModal(accountId, {
      source: 'hard block',
      feature: teamOnlyComments,
    })
  );
  const { success } = yield race({
    success: take(SELECT_PLAN_FLOW.REPORT_PLAN_CHANGE_SUCCESS),
    cancelled: take(SELECT_PLAN_FLOW.END),
  });

  const { canUseTeamOnlyComments } = yield select(
    permittedActionsForAccountSelector,
    {
      accountId,
    }
  );

  // When plan is successfully upgraded, select "team-only" option
  if (success && canUseTeamOnlyComments) {
    yield put(setCommentPrivacy(true));
    yield spawn(track, 'feature-unlocked', { feature: teamOnlyComments });
    return;
  }

  if (success && !canUseTeamOnlyComments) {
    yield put(setCommentPrivacy(false));
  }
}

function* showNonAdminBlock(accountId) {
  yield call(
    alert,
    'Unlock team-only comments',
    <UpgradeContainer>
      <SpeechBubbles />
      <UpgradeExplainer>
        Please notify your account owner that you’d like this upgrade.
      </UpgradeExplainer>
      <AccountOwnerCopyContainer>
        <AccountOwnerSubcopy>Account owner</AccountOwnerSubcopy>
        <UserPillContainer justifyCenter>
          <AccountOwnerPill accountId={accountId} />
        </UserPillContainer>
      </AccountOwnerCopyContainer>
    </UpgradeContainer>,
    {
      primaryText: 'Got it',
      type: dialogTypes.NONE,
    }
  );
}

function* toggleCommentPrivacy(assetId, isTeamOnlySelected) {
  const asset = yield select(assetEntitySelector, { assetId });
  const projectId = asset.project_id;
  const { id: accountId } = yield select(accountEntityForAssetIdSelector, {
    assetId,
  });
  const { canUseTeamComments } = yield select(
    permittedActionsForProjectSelector,
    { projectId }
  );
  const isAccountAdmin = yield select(isAccountAdminSelector, { accountId });

  // When team-only comments feature is blocked
  if (isTeamOnlySelected && !canUseTeamComments) {
    yield spawn(track, 'action-blocked', { limit: teamOnlyComments });
    yield spawn(track, 'feature-gate-clicked-client', {
      _limit: teamOnlyComments,
    });
    if (isAccountAdmin) {
      yield call(showAdminBlock, accountId);
    } else {
      yield call(showNonAdminBlock, accountId);
    }
    return;
  }

  yield put(setCommentPrivacy(isTeamOnlySelected));
}

export const testExports = {
  showAdminBlock,
  showNonAdminBlock,
  toggleCommentPrivacy,
};

export default [
  takeEvery(
    COMMENT_COMPOSER.TOGGLE_PRIVACY,
    ({ payload: { assetId, isTeamOnlySelected } }) =>
      toggleCommentPrivacy(assetId, isTeamOnlySelected)
  ),
];
