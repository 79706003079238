import {
  takeLatest,
  takeEvery,
  select,
  put,
  race,
  call,
  take,
  delay,
  fork,
  spawn,
} from 'redux-saga/effects';
import React from 'react';
import { Route } from 'react-router-dom';
import { get } from 'lodash';
import { putFetchActionAndWait } from '@frameio/core/src/shared/sagas/helpers';
import fetchAllPages from '@frameio/core/src/shared/sagas/fetchAllPages';
import {
  updateUser,
  registerAnonymousUser,
  AUTHED_USER,
  ANON_USER,
} from '@frameio/core/src/users/actions';
import { isAnonymousUser } from '@frameio/core/src/users/utils';
import { getSharedReviewLink } from '@frameio/core/src/reviewLinks/sagas';
import { getSharedReviewLinkItems } from '@frameio/core/src/reviewLinkItems/sagas';
import {
  getAsset,
  getAssetChildren,
  getAssetAncestors,
} from '@frameio/core/src/assets/sagas';
import {
  setReviewSeenForReviewLink,
  listReviewersForReviewLinkId,
} from '@frameio/core/src/reviewers/sagas';
import {
  assetEntitySelector,
  hydratedAssetEntitySelector,
  shouldFetchAssetSelector,
} from '@frameio/core/src/assets/selectors';
import { getCommentsByAsset } from '@frameio/core/src/comments/actions';
import { createViewAssetImpression } from '@frameio/core/src/assetImpressions/actions';
import { reviewLinkEntitySelector } from '@frameio/core/src/reviewLinks/selectors';
import {
  getRoleForAccount as getRoleForAccountCoreSaga,
  getRoleForProject as getRoleForProjectCoreSaga,
} from '@frameio/core/src/roles/sagas';
import { teamEntitySelector } from '@frameio/core/src/teams/selectors';
import { accountEntityForProjectIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { getAnonymousUserForReviewLink } from '@frameio/core/src/users/services';

import {
  isPDF,
  getMediaType,
} from '@frameio/core/src/assets/helpers/mediaTypes';
import { type as assetType } from '@frameio/core/src/assets/helpers/constants';
import { ENTER_DURATION } from '@frameio/components/src/components/Modal';
import { confirm } from 'components/Dialog/SimpleDialog/sagas';
import getFailureReason, {
  FAILURE_REASONS,
} from 'components/AuthGate/failureReasons';
import RegistrationForm from 'pages/ReviewLinkContainer/RegistrationForm';
import { seriallyDownloadBatchFiles } from 'sagas/assets';
import {
  MODAL,
  openModal,
  closeModal,
  updateModal,
} from 'components/Modal/actions';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import {
  configureCore,
  getIsAnonToken,
  hasAuthCookies,
  removeAuthCookies,
} from 'utils/auth';
import { initSocketService } from 'sagas/sockets';
import { authenticate } from 'sagas/authedUser';
import { commentCountsByAssetSelector } from 'selectors/comments';
import {
  isAuthedUserSelector,
  currentUserEntitySelector,
  hasCurrentUserConfirmedEmailAddress,
} from 'selectors/users';
import track from 'analytics';
import { getGDPRCookieCategories } from '@frameio/segment-ot';
import { REVIEW_LINK_URL } from 'URLs';
import RequestAccessToEntity from 'components/RequestAccessToEntity';
import { ENTITY_TYPE } from 'components/AuthGate/AuthGate';
import { childAssetIdsSelector } from 'selectors/assets';
import { requireLogin } from 'sagas/shared';
import configureAuthRequiredInterceptor from './auth/reviewLinkInterceptor';
import SessionExpiredModal from './auth/SessionExpiredModal';
import { setNumOfComments } from './CommentsToggle/actions';
import commentsToggleSagas from './CommentsToggle/sagas';
import {
  REVIEW_LINK_CONTAINER,
  reviewLinkFetchFailed,
  showNameInputChange,
  removeAuthInterceptor as removeAuthInterceptorAction,
} from './actions';
import {
  navigateToReviewLink,
  navigateToAsset,
  setReviewLinkHeaders,
} from './utils';

const REVIEW_LINKS_ITEMS_PAGE_SIZE = 50;

/**
 * Saga that will remove the auth interceptor when triggered
 *
 * @param {function} removeInterceptor - function that will remove the auth interceptor
 */
function* removeAuthInterceptorOnAction(removeInterceptor) {
  yield take(REVIEW_LINK_CONTAINER.REMOVE_AUTH_INTERCEPTOR);
  yield call(removeInterceptor);
}

/**
 * Checks if user is authenticated before performing an action.
 * If the user isn't, show an interstitial modal before calling the callback.
 * If the user is authenticated, proceed as normal and call the callback.
 *
 * TODO(RNC-887) when the review link/player page is refactored into Redux,
 * this flow should happen in a saga.
 * @param {func} authCallback
 * @param {string} modalHeaderText

 */
function* enforceAuthedUser({
  payload: { authCallback, modalHeaderText, ignoreAuthed, reject },
}) {
  // check if we're authed. If we are, proceed. If we're not, throw up a modal
  // and then perform the desired action after the user successfully registers
  const isAuthed = yield select(isAuthedUserSelector);

  /**
   * When ignoreAuthed is true, we know that the user was authed at one point
   * but now the auth token is expired. We want to give the user the option to register again.
   */
  if (isAuthed && !ignoreAuthed) {
    yield call(authCallback);
    return;
  }

  const cookieCategories = getGDPRCookieCategories(true);

  // We need to wrap our component in a Route so that we have access to React
  // Router's match params API to grab the id of the relevant review link.
  // This route's path matches the path of the page that spawned the modal.
  yield put(
    openModal(
      <Route
        path={REVIEW_LINK_URL}
        render={() => (
          <RegistrationForm
            modalHeaderText={modalHeaderText}
            cookieCategories={cookieCategories}
          />
        )}
      />,
      { enableFocusLock: false }
    )
  );

  // When `enableFocusLock` is enabled, the email field gets focused immediately
  // before the modal even animates in. In mobile view, this causes the page to
  // jump up as the modal is rendering, which looks janky.
  //
  // To prevent this jank, delay setting the focus lock until after the modal
  // transitions in.
  yield delay(ENTER_DURATION);
  yield put(updateModal({ enableFocusLock: true }));

  const { cancel, success } = yield race({
    success: take(ANON_USER.REGISTER.SUCCESS),
    failure: take(ANON_USER.REGISTER.FAILURE),
    cancel: take(MODAL.CLOSE),
  });

  if (cancel) {
    /**
     * reject will exist if the user was registered before, but now the session is expired.
     * A promise was created to retry the users failed request after they re-register.
     * If the user decides to cancel the registration, we need to reject the promise
     */
    if (typeof reject === 'function') reject();
    sessionStorage.removeItem('unauthedCommentPayload');
  }

  if (success) {
    yield put(closeModal());
    // Remove the previous auth interceptor if it exists
    yield put(removeAuthInterceptorAction());
    // Add a new auth interceptor
    const removeAuthInterceptor = yield call(configureAuthRequiredInterceptor);
    // Spawn a saga that will remove the auth interceptor when triggered
    yield spawn(removeAuthInterceptorOnAction, removeAuthInterceptor);

    yield call(authCallback);
  }
}

function* trackMediaViewed(reviewLinkId, assetId, hideVersions) {
  const reviewLink = yield select(reviewLinkEntitySelector, { reviewLinkId });
  const team = yield select(teamEntitySelector, { teamId: reviewLink.team });
  const asset = yield select(hydratedAssetEntitySelector, { assetId });

  // Checking if the asset is part of a version stack.
  // Note that if it is part of a version stack, we are tracking the id of the specific asset
  // being viewed in the properties, not the id of the version stack,
  // and then just a boolean indicating that this asset is part of a version stack
  const parentAsset = yield select(assetEntitySelector, {
    assetId: asset.parent_id,
  });

  // hideVersions is accounting for when 'show all versions' is toggled off
  const isVersionStack =
    !!parentAsset && (parentAsset.type === 'version_stack' && !hideVersions);

  yield spawn(track, 'media-viewed-client', {
    file_id: assetId,
    filesize: asset.filesize,
    file_format: asset.filetype,
    account_id: team.account_id,
    team_id: team.id,
    project_id: reviewLink.project,
    review_link_id: reviewLinkId,
    page_type: 'review page',
    media_type: getMediaType(asset),
    view_count: asset.view_count,
    is_version_stack: isVersionStack,
  });
}

function* fetchComments(reviewLinkId, assetId) {
  yield call(
    putFetchActionAndWait,
    getCommentsByAsset(assetId, reviewLinkId),
    assetId
  );

  // Sync up comments count
  const countsByAsset = yield select(commentCountsByAssetSelector);
  yield put(setNumOfComments(countsByAsset[assetId] || 0));
}

function* fetchVersionData({
  payload: { reviewLinkId, versionStackId, assetId },
}) {
  // Reset count when selected version changes
  yield put(setNumOfComments());

  const reviewLink = yield select(reviewLinkEntitySelector, { reviewLinkId });
  const versionStack = yield select(hydratedAssetEntitySelector, {
    assetId: versionStackId,
  });
  const coverAssetId = versionStack.cover_asset_id;

  if (reviewLink.current_version_only && assetId !== coverAssetId) {
    // For review links that don't support many versions, make sure we're only
    // ever showing the most recent version
    yield call(navigateToAsset, reviewLinkId, versionStackId, coverAssetId);
    return;
  }

  if (versionStackId && !assetId) {
    // We have the version stack but no version. Redirect to the latest.
    yield call(navigateToAsset, reviewLinkId, versionStackId, coverAssetId);
    return;
  }

  // For PDFs, fetch the asset again to get the `metadata` field expected by
  // `DocumentPane`.
  const asset = yield select(assetEntitySelector, { assetId });
  if (yield call(isPDF, asset)) {
    yield fork(getAsset, assetId, reviewLinkId);
  }

  // Fetch the comments for the selected version in the review link
  yield fork(fetchComments, reviewLinkId, assetId);

  // Mark the selected version in the review link as seen by current reviewer
  yield put(createViewAssetImpression(assetId));

  // Track the view event for the media being displayed
  yield spawn(
    trackMediaViewed,
    reviewLinkId,
    assetId,
    !!reviewLink.current_version_only
  );
}

function* fetchAssetData({
  payload: { reviewLinkId, assetId, versionStackAssetId },
}) {
  yield put(setNumOfComments());

  // Only try and fetch the asset if another fetch does not come in within
  // 300ms. This effectively debounces the saga when quickly navigating
  // between assets to avoid excessive HTTP requests.
  const { fetchAsset } = yield race({
    fetchAsset: take(REVIEW_LINK_CONTAINER.FETCH_ASSET_DATA),
    timeout: delay(300),
  });

  if (fetchAsset) return;

  // Asset data is used for rendering metadata and the creator
  if (yield select(shouldFetchAssetSelector, { assetId })) {
    yield call(getAsset, assetId, {
      includeFolderPreview: true,
      reviewLinkId,
    });
  }

  const asset = yield select(hydratedAssetEntitySelector, { assetId });

  // If there's no asset, redirect to the review link page
  if (!asset) {
    yield call(navigateToReviewLink, reviewLinkId);
    return;
  }

  if (asset.type === assetType.FOLDER) {
    yield call(getAssetChildren, assetId, {
      includeFolderPreview: true,
      reviewLinkId,
    });
    yield call(getAssetAncestors, assetId);
  }

  // Fetch bundle children
  if (asset.bundle) {
    yield call(getAssetChildren, assetId);
  }

  if (asset.type === assetType.VERSION_STACK) {
    yield call(getAssetChildren, assetId);
    const versionIds = yield select(childAssetIdsSelector, { assetId });
    const doesVersionExistOnStack = versionIds.includes(versionStackAssetId);
    if (!doesVersionExistOnStack) {
      /**
       * If the version we are trying to view does not exist on the version stack,
       * we need to redirect to the version stack's cover asset instead.
       */
      yield call(navigateToAsset, reviewLinkId, assetId, asset.cover_asset_id);
      return;
    }
    yield call(fetchVersionData, {
      payload: {
        reviewLinkId,
        assetId: versionStackAssetId,
        versionStackId: assetId,
      },
    });
    return;
  }

  // Fetch all associated comments with the asset.
  yield call(fetchComments, reviewLinkId, assetId);

  // Mark the asset in the review link as seen by current reviewer
  yield put(createViewAssetImpression(assetId));

  // Track the view event for the media being displayed
  yield spawn(trackMediaViewed, reviewLinkId, assetId, false);
}

/**
 *  Fetches review records associated with a given review link, filtered
 * by the current user's email address. This allows us to check if the current
 * user is assigned as a reviewer to a review link
 * @param {string} reviewLinkId
 */
function* fetchReviewersForReviewLinkByUserEmail(reviewLinkId) {
  const currentUser = yield select(currentUserEntitySelector);

  if (currentUser) {
    yield call(listReviewersForReviewLinkId, reviewLinkId, {
      'filter[user.email]': currentUser.email,
    });
  }
}

/**
 *
 * @param {string} reviewLinkId - Id of the review link to request
 * @returns {Object} Object with failureReason:string (false if access succeeded) and hasBundle:boolean
 */
function* attemptReviewLinkAccess(reviewLinkId, password) {
  const { success, failure } = yield call(
    getSharedReviewLink,
    reviewLinkId,
    password
  );
  if (success) {
    return { failureReason: false, hasBundle: null };
  }

  const errorCode = get(failure, 'payload.error.response.status');
  const hasBundle =
    get(
      failure,
      'payload.error.response.data.errors[0].detail.bundle_count',
      0
    ) > 0;

  const hasCurrentUserConfirmedEmail = yield select(
    hasCurrentUserConfirmedEmailAddress
  );
  // if:
  // 1) the user is forbidden to access a review link, and
  // 2) they have confirmed their email address
  // they should have the option to request access to that review link.
  if (errorCode === 403 && hasCurrentUserConfirmedEmail) {
    yield put(
      openModal(
        <RequestAccessToEntity
          entityType={ENTITY_TYPE.REVIEW_LINK}
          entityId={reviewLinkId}
        />,
        { canCloseModal: false }
      )
    );
  }
  const failureReason = yield call(getFailureReason, failure);
  return { failureReason, hasBundle };
}

function* enterReviewLink({
  isRetry = false,
  payload: { reviewLinkId, password, assetParams },
}) {
  yield call(setReviewLinkHeaders, reviewLinkId, password);

  // Get review link and prompt to request access if failure reason is forbidden
  // the error network response returns with a bundle count we use to determine to show a prompt before auth.
  const { failureReason, hasBundle } = yield call(
    attemptReviewLinkAccess,
    reviewLinkId,
    password
  );

  // If the reason for failure is NOT_LOGGED_IN,
  // Clear the auth cookies and reattempt to enter the review link
  // The backend will return a 401 error if we are sending an unrefreshable
  // anonymous user auth token.
  // BUGS-1151
  if (
    // only attempt a retry once to avoid an infinite loading loop
    !isRetry &&
    failureReason &&
    failureReason === FAILURE_REASONS.NOT_LOGGED_IN &&
    getIsAnonToken()
  ) {
    yield call(removeAuthCookies);
    yield call(configureCore);
    yield call(enterReviewLink, {
      isRetry: true,
      payload: { reviewLinkId, password, assetParams },
    });
    return;
  }

  if (failureReason) {
    if (failureReason !== FAILURE_REASONS.FORBIDDEN) {
      yield put(reviewLinkFetchFailed(reviewLinkId, failureReason, hasBundle));
    }
    return;
  }

  // This should now always exist. Yay!
  const reviewLink = yield select(reviewLinkEntitySelector, { reviewLinkId });
  const isAuthed = yield select(isAuthedUserSelector);

  if (isAuthed) {
    const currentUser = yield select(currentUserEntitySelector);
    const projectId = reviewLink.project_id;

    // For correct permissions, we need to know the role the current user
    // has for the account that owns the review link
    // To get this, we need the account id of the account that owns the project
    const account = yield select(accountEntityForProjectIdSelector, {
      projectId,
    });
    const accountId = account?.id;

    if (accountId) {
      yield call(getRoleForAccountCoreSaga, accountId);
    }

    // For returning users that are anonymous, we want to guarantee that they are 'registered' to
    // access this review link. To do this we register them again, using their email and name
    // stored on the user.
    const isAnonymous = yield call(isAnonymousUser, currentUser);

    if (isAnonymous) {
      yield call(
        putFetchActionAndWait,
        registerAnonymousUser(reviewLinkId, currentUser),
        reviewLinkId
      );
    } else {
      // For regular users block on getting the user's role on the project,
      // because we need this for all of the things relating to permissions.
      yield call(getRoleForProjectCoreSaga, projectId);
    }
  }

  // Fetches review records associated with the current review link and current user
  // by email address. The results of this fetch are used to determine if the user
  // is a reviewer on the current review link.
  yield call(fetchReviewersForReviewLinkByUserEmail, reviewLinkId);

  // Make a request to the backend to mark any associated reviewer records as seen
  // We don't care about the response and no data is returned, so prefer spawn.
  yield spawn(setReviewSeenForReviewLink, reviewLinkId);

  // Otherwise, load all review link items
  try {
    yield call(
      fetchAllPages,
      (params) =>
        getSharedReviewLinkItems(reviewLinkId, { password, ...params }),
      REVIEW_LINKS_ITEMS_PAGE_SIZE
    );
  } catch (error) {
    yield put(
      showErrorToast({
        header: 'Failed to load the page. Please try again later.',
        autoCloseDelay: null,
      })
    );
  }

  // If we've been initiated with params, kick off the data fetching
  const { assetId, versionStackAssetId } = assetParams;

  if (assetId) {
    yield call(fetchAssetData, {
      payload: {
        reviewLinkId,
        assetId,
        versionStackAssetId,
      },
    });
  }
}

/**
 * Saga that attempts to authenticate the user before entering the review link
 *
 * @param {Object} payload - payload for the enterReviewLink saga
 * @returns {void}
 */
function* authAndEnterReviewLink({ payload }) {
  // Configures the axios instances with the auth token from the cookies
  yield call(configureCore);
  // Connect to the socket service. Joining rooms happen in `ReviewLinks`.
  yield call(initSocketService);
  /**
   * Review links can accept unauthenticated users.
   * If there are auth cookies, try to authenticate the user.
   * Otherwise, attempt to enter the review link as unauthenticated.
   */
  if (yield call(hasAuthCookies)) {
    /**
     * Starts an auth interceptor that will listen for 401s
     * The interceptor will attempt to refresh the auth token if it is expired
     */
    const removeAuthInterceptor = yield call(configureAuthRequiredInterceptor);

    const { success } = yield call(authenticate);

    if (success) {
      // Spawn a saga that will remove the auth interceptor when triggered
      yield spawn(removeAuthInterceptorOnAction, removeAuthInterceptor);
    } else {
      /**
       * At this point the user is not authenticated so we do not need to
       * listen for 401s and attempt to refresh the auth token
       */
      yield call(removeAuthInterceptor);
    }
  }

  yield call(enterReviewLink, { payload });
}

/**
 * This saga is dispatched from the authInterceptor when the auth token
 * is missing or expired and cannot be refreshed.
 *
 * @param {Object} payload - action payload
 * @param {Object} payload.error - axios error
 * @param {function} payload.request - callback to be invoked after user has been authorized
 * @returns {void}
 */
function* handleAuthError({ payload }) {
  const { error, request } = payload;

  const reviewLinkId = get(error, 'config.headers.x-review-link-id');
  /**
   * If there is no review link id, then this is the first load of the review link.
   * We are doing nothing here to let the enterReviewLink saga handle deleting the auth cookies
   * and retrying as unauthenticated.
   */
  if (!reviewLinkId) return;

  const currentUser = yield select(currentUserEntitySelector);
  const isAnonymous = isAnonymousUser(currentUser);

  /**
   * If the user is anonymous try to re-register with enforceAuthedUser.
   * After the user registers, the users failed request will be retried.
   */
  if (isAnonymous) {
    const { retry: authCallback, cancel: reject } = request || {};

    const params = {
      authCallback,
      reject,
      ignoreAuthed: true,
      modalHeaderText: 'Want to leave a comment?',
    };

    yield call(enforceAuthedUser, { payload: params });

    return;
  }

  const reviewLink = yield select(reviewLinkEntitySelector, { reviewLinkId });
  const isInviteOnly = get(reviewLink, 'access_control.invite_only', false);

  /**
   * If the review link is invite only, we have to require the user to login again.
   */
  if (isInviteOnly) {
    yield call(requireLogin);
  } else {
    /**
     * The review link is public, so we can give the user a choice
     * to login again or continue without logging in.
     */
    yield put(openModal(<SessionExpiredModal />, { canCloseModal: false }));
  }
}

function* confirmDownloadAllAssets({ payload: { assetIds } }) {
  const shouldDownload = yield call(
    confirm,
    'Download all?',
    'Are you sure you want to download all files?'
  );
  if (shouldDownload) {
    yield call(seriallyDownloadBatchFiles, assetIds, shouldDownload);
  }
}

function* updateAnonUserName({ payload: { user } }) {
  yield put(updateUser(user));

  const { failure } = yield race({
    success: take(AUTHED_USER.UPDATE.SUCCESS),
    failure: take(AUTHED_USER.UPDATE.FAILURE),
  });

  if (failure) {
    yield put(
      showErrorToast({
        header: 'Could not update name. Please try again.',
      })
    );
    return;
  }

  yield put(
    showSuccessToast({
      header: 'Name successfully updated',
    })
  );
}

function* lookUpAnonUserEmail({ payload: { reviewLinkId, email } }) {
  const response = yield call(
    getAnonymousUserForReviewLink,
    reviewLinkId,
    email
  );

  if (response.status === 200) {
    yield put(
      registerAnonymousUser(reviewLinkId, {
        email,
        name: response.data.name,
        cookie_categories: getGDPRCookieCategories(),
      })
    );

    yield put(
      showSuccessToast({
        header: `Welcome back ${response.data.name}`,
      })
    );
  } else if (response.status === 204) {
    yield put(showNameInputChange(true));
  } else {
    yield put(
      showErrorToast({
        header: 'Something went wrong',
        subHeader: 'Please try again.',
      })
    );
    yield put(closeModal());
  }
}

function* cancelAnonUserSignup() {
  yield put(showNameInputChange(false));
  yield put(closeModal());
}

function* trackReviewLinkFetchFailure({
  payload: { reviewLinkId, failureReason },
}) {
  if (failureReason === FAILURE_REASONS.EXPIRED) {
    yield spawn(track, 'expired-modal-shown', {
      page_type: 'review page',
      page_id: reviewLinkId,
    });
  }
}

export default [
  ...commentsToggleSagas,
  takeLatest(REVIEW_LINK_CONTAINER.AUTH_AND_ENTER, authAndEnterReviewLink),
  takeEvery(REVIEW_LINK_CONTAINER.HANDLE_AUTH_ERROR, handleAuthError),
  takeEvery(REVIEW_LINK_CONTAINER.ENFORCE_AUTHED_USER, enforceAuthedUser),
  takeLatest(REVIEW_LINK_CONTAINER.FETCH_DATA, enterReviewLink),
  takeLatest(REVIEW_LINK_CONTAINER.FETCH_VERSION_DATA, fetchVersionData),
  takeEvery(REVIEW_LINK_CONTAINER.FETCH_ASSET_DATA, fetchAssetData),
  takeLatest(REVIEW_LINK_CONTAINER.FETCH_FAILED, trackReviewLinkFetchFailure),
  takeLatest(
    REVIEW_LINK_CONTAINER.CONFIRM_DOWNLOAD_ALL_ASSETS,
    confirmDownloadAllAssets
  ),
  takeLatest(REVIEW_LINK_CONTAINER.UPDATE_ANON_USER_NAME, updateAnonUserName),
  takeEvery(REVIEW_LINK_CONTAINER.LOOKUP_ANON_USER_EMAIL, lookUpAnonUserEmail),
  takeEvery(
    REVIEW_LINK_CONTAINER.CANCEL_ANON_USER_SIGNUP,
    cancelAnonUserSignup
  ),
];

export const testExports = {
  authAndEnterReviewLink,
  enforceAuthedUser,
  ENTER_DURATION,
  fetchAssetData,
  fetchComments,
  enterReviewLink,
  fetchVersionData,
  attemptReviewLinkAccess,
  fetchReviewersForReviewLinkByUserEmail,
  REVIEW_LINKS_ITEMS_PAGE_SIZE,
  trackMediaViewed,
};
