import { v4 as uuid } from 'uuid';

const hostname = window.location.hostname;

export const isLocal = [
  /^lvh.me/,
  /^app.local.frame.io/,
  /^app-local.dev.frame.io/,
  /^localhost/,
  /^192\.168\./,
  /^10\.16\./,
].some((regex) => regex.test(hostname));

export const isTest = process.env.NODE_ENV === 'test';
export const isDevelopment = /dev.frame.io/.test(hostname);
export const isEvaluation = /eval.frame.io/.test(hostname);
export const isStaging = /staging.frame.io/.test(hostname);
export const isProduction = /app.frame.io/.test(hostname);

const localClientHost = window.location.origin;
const evalClientHost = 'https://app.eval.frame.io';
const stagingClientHost = 'https://app.staging.frame.io';
const prodClientHost = 'https://app.frame.io';

const baseConfig = {
  apiHost: 'https://api.dev.frame.io',
  betterAccountSettingsUrl: 'https://settings.dev.frame.io',
  mungerHost: 'https://api.dev.frame.io', // used to override the config to access account settings when running local fullstack
  instanceId: uuid(), // Identifies specific browser instances
  segmentId: 'iuwUzRjCbX2iKgf5qvHhM4FfMJOZN4tQ',
  slackClientId: '2521166657.82265841285',
  socketHost: 'https://api.dev.frame.io',
  socketV2Host: 'wss://api.dev.frame.io/socket',
  staticUrl: 'https://static-assets.frame.io',
  stripeKey: 'pk_test_LYtIxzgIuKODoBgayNaOUaqA',
  supportToolUrl: 'https://support-tool.dev.frame.io',
  vimeoClientId: 'c4b3123550fe997bd6c4976456f88af87fe2e6e2',
  authClientHost: 'https://accounts.dev.frame.io',
  uploadProgressBroadcastInterval: 2000,
  driftId: '89aw44efbrim',
  driftUnder2GBCancellationId: 48022,
  driftEnterpriseUpgradeId: 48023,
  driftEnterpriseExpansionId: 48024,
  driftOver2GBCancellationId: 48030,
  driftClearedStorageCancellationId: 48057,
  driftBlockDowngradeToFreePlanWithUserMax: 215838,
  userCommentingBroadcastInterval: 5000,
  demoProjectId: 'a98e4e51-9261-4859-bea7-c6166dd82cd0',
  muxBeaconCollectionDomain: 'playback.frame.io',
  muxEnvKey: 'qr1md2jg7r3b6o4mf4jg6vvjq',
  clearbitRevealKey: 'pk_406b4600d4a87540a25cb13277076511',
  intercomAppId: 'ypuhktck',
};

const local = {
  accountIdKey: 'localCurrentAccountId',
  clientHost: localClientHost,

  // Prefix cookie domain with dot only if it isn't an IP address
  domain: /^[a-z]/.test(hostname) ? `.${hostname}` : hostname,

  authCallback: `${localClientHost}/auth/callback`,
  segmentId: 'vwIfa1aJ2UemvamybCVWrLhk7NTZVwiu',
  nextUrl: 'https://next.dev.frame.io',

  // Switch to debug datadog locally
  datadogRum:
    process.env.DEBUG_DATADOG_RUM === 'true'
      ? {
          env: 'local',
          service: 'web-client',
          applicationId: 'd4c7fefc-096b-4748-b3b3-2fb6f45b461c',
          clientToken: 'pub8d32b195d7ef486269e5ffd2f1a85fe3',
          silentMultipleInit: true,
          useSecureSessionCookie: false,
          trackInteractions: false,
          defaultPrivacyLevel: 'mask',
          sampleRate: 100, // 100% of sessions
          premiumSampleRate: 100,
          resourceSampleRate: 100,
        }
      : null,
  isDatadogRumEnabled: process.env.DEBUG_DATADOG_RUM === 'true',
};

const development = {
  accountIdKey: 'devCurrentAccountId',
  // WK-158: See web-client PRs #8421, #8422
  clientHost: `https://${hostname}`,
  domain: '.dev.frame.io',
  authCallback: `https://${hostname}/auth/callback`,
  nextUrl: 'https://next.dev.frame.io',

  datadogRum: {
    env: 'dev',
    service: 'web-client',
    applicationId: 'd4c7fefc-096b-4748-b3b3-2fb6f45b461c',
    clientToken: 'pub8d32b195d7ef486269e5ffd2f1a85fe3',
    silentMultipleInit: true,
    useSecureSessionCookie: true,
    trackInteractions: false,
    defaultPrivacyLevel: 'mask',
    sampleRate: 100, // 100% of sessions
    premiumSampleRate: 100,
    resourceSampleRate: 100,
  },
  isDatadogRumEnabled: true,
};

const evaluation = {
  accountIdKey: 'evalCurrentAccountId',
  apiHost: 'https://api.eval.frame.io',
  betterAccountSettingsUrl: 'https://settings.eval.frame.io',
  mungerHost: 'https://api.eval.frame.io',
  clientHost: evalClientHost,
  demoProjectId: '2b2924c2-f8a8-47db-a8b8-5d99686968d7',
  stripeKey:
    'pk_test_51KS3krDJWaQ8WnNJm0oHQw4QMbdWIa6080yrCWvkaLo34pKg50mp6rZwULq8X4TBS9XGcUZijNFLWXOyq6u5jbeu00Fc7c11xc',
  socketHost: 'https://api.eval.frame.io',
  socketV2Host: 'wss://api.eval.frame.io/socket',
  supportToolUrl: 'https://support-tool.eval.frame.io',
  domain: '.eval.frame.io',
  authClientHost: 'https://accounts.eval.frame.io',
  authCallback: `${evalClientHost}/auth/callback`,
  nextUrl: 'https://next.eval.frame.io',
};

const staging = {
  accountIdKey: 'stagingCurrentAccountId',
  apiHost: 'https://api.staging.frame.io',
  betterAccountSettingsUrl: 'https://settings.staging.frame.io',
  mungerHost: 'https://api.staging.frame.io',
  clientHost: stagingClientHost,
  demoProjectId: '2b2924c2-f8a8-47db-a8b8-5d99686968d7',
  stripeKey:
    'pk_test_51J3L6WG7MaoM6k2wQMi6WtxgnRqEWgJsdNPHVjTGbAiFBczA8NlJfpjf6TALFUOjmeaMBY3IFgrfZcg359ircM8k00GylliTpx',
  // slackClientId: '',
  socketHost: 'https://api.staging.frame.io',
  socketV2Host: 'wss://api.staging.frame.io/socket',
  supportToolUrl: 'https://support-tool.staging.frame.io',
  segmentId: 'Y5nJtS0fV2uJGJCXALLJzAwazmHZRXFH',
  muxEnvKey: '33ketj9khad714sf44tr7s45l',
  domain: '.staging.frame.io',
  authClientHost: 'https://accounts.staging.frame.io',
  authCallback: `${stagingClientHost}/auth/callback`,
  nextUrl: 'https://next.staging.frame.io',
  datadogRum: {
    env: 'staging',
    service: 'web-client',
    applicationId: 'd4c7fefc-096b-4748-b3b3-2fb6f45b461c',
    clientToken: 'pub8d32b195d7ef486269e5ffd2f1a85fe3',
    silentMultipleInit: true,
    useSecureSessionCookie: true,
    trackInteractions: false,
    defaultPrivacyLevel: 'mask',
    sampleRate: 100, // 100% of sessions
    premiumSampleRate: 100,
    resourceSampleRate: 100,
  },
  isDatadogRumEnabled: true,
};

const production = {
  accountIdKey: 'currentAccountId',
  apiHost: 'https://api.frame.io',
  betterAccountSettingsUrl: 'https://settings.frame.io',
  mungerHost: 'https://api.frame.io',
  clientHost: prodClientHost,
  demoProjectId: 'f6df747a-5976-4580-a724-a912bdb4f667',
  driftId: 'ha47fsix4v4e',
  stripeKey: 'pk_live_YVwciqj6lrNFyEuQtDxaFkC4',
  slackClientId: '2521166657.38009474357',
  socketHost: 'https://api.frame.io',
  socketV2Host: 'wss://api.frame.io/socket',
  supportToolUrl: 'https://support-tool.frame.io',
  segmentId: 'JlMEiYCRWaIYGWJCYULjsuTVO7hCsVfo',
  muxEnvKey: 'efhk2t8a9m6sgkaie52pqst6o',
  intercomAppId: 'mbz4oxgz',

  // Disabled for security issue: CORE-2779
  // Ported security amendments from V4 V3FRAME-144
  // Keep off unless we really need to use in prod, ask #eng-sre
  // datadogRum: {
  //   env: 'prod',
  //   service: 'web-client',
  //   applicationId: 'd4c7fefc-096b-4748-b3b3-2fb6f45b461c',
  //   clientToken: 'pub8d32b195d7ef486269e5ffd2f1a85fe3',
  //   silentMultipleInit: true,
  //   useSecureSessionCookie: true,
  //   trackInteractions: false,
  //   defaultPrivacyLevel: 'mask',
  //   sampleRate: 1, // 1% of sessions
  //   premiumSampleRate: 0,
  //   resourceSampleRate: 1, //alert: double check this
  // },
  // isDatadogRumEnabled: true,
  // NOTE: Because of this, cookies set in app.frameio will end up in
  // dev/staging too
  domain: '.frame.io',
  authClientHost: 'https://accounts.frame.io',
  authCallback: `${prodClientHost}/auth/callback`,
  nextUrl: 'https://next.frame.io',
};

export const LATEST_BILLING_VERSION = 2;

export const CURRENT_VERSION_NUMBER = 3;
export const NEXT_VERSION_NUMBER = 4;

let config; // eslint-disable-line import/no-mutable-exports

if (isLocal) config = local;
else if (isDevelopment) config = development;
else if (isStaging) config = staging;
else if (isEvaluation) config = evaluation;
else config = production;

// Override default config locally if `configOverrides.js` is present.
let overrides = {};
if (process.env.NODE_ENV !== 'production') {
  try {
    // eslint-disable-next-line global-require, import/no-unresolved
    overrides = require('./configOverrides').default;
  } catch (error) {} // eslint-disable-line no-empty
}

// Merge base configuration with env specific configuration
export default Object.assign({}, baseConfig, config, overrides);
