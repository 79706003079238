import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import { confirmPublishToVimeo } from './actions';
import VimeoShareEditor from './VimeoShareEditor';

const mapDispatchToProps = {
  closeModal,
  confirmPublishToVimeo,
};

export default connect(
  null,
  mapDispatchToProps
)(VimeoShareEditor);

export const testExports = {
  mapDispatchToProps,
};
