class JT {

  static get(hash, key, default_val) {
    let val = hash[key];
    return val || (val === 0) ? val : default_val;
  }

  static guid() {
    let r = () => Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
    return `${r()}${r()}-${r()}-${r()}-${r()}-${r()}${r()}${r()}`;
  }

  static includes(array, key) { return array.indexOf(key) !== -1; }

  static splice(s, i, l, ss) { if (ss == null) { ss = ""; } return `${s.substring(0, i)}${ss}${s.substring(i + l)}`; }

  static intersperse(a, s) {
    a = a.reduce(function (acc, x) {
      acc.push(x);
      acc.push(s);
      return acc;
    }, []);
    a.pop();
    return a;
  }

  static respond_to(object, function_name) {
    return (typeof object[function_name]) !== 'undefined';
  }

  static exists(object) {
    return (object || (object === 0)) && ((typeof object) !== 'undefined') && (object !== "JTNullValue");
  }

  static exists_string(object) { return this.exists(object) && !this.blank(object); }

  static exists_object(object) {
    return this.exists(object) && ((typeof object) === 'object') && (Object.keys(object)
      .length !== 0);
  }

  static exists_number(object) { return this.exists(object); }

  static exists_nonempty(object) {
    let object_exists;
    switch (true) {
      case (typeof object) === 'string':
        object_exists = this.exists_string(object);
        break;
      case (typeof object) === 'object':
        object_exists = this.exists_object(object);
        break;
      case (typeof object) === 'number':
        object_exists = this.exists_number(object);
        break;
    }

    return object_exists ? object_exists : false;
  }

  static swap_html_with_fade(element, html, duration) {
    if (element.length === 0) { return; }
    return element.animate({ opacity: 0.1 }, duration, () => element.html(html))
      .animate({ opacity: 1 }, duration);
  }

  static pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : (new Array((width - n.length) + 1)
      .join(z) + n);
  }

  static sig_digits(num, digits) {
    if (digits == null) { digits = 2; }
    let sig_multiply = Math.pow(10, digits);
    return (Math.round(num * sig_multiply) / sig_multiply);
  }

  static strip(string) {
    string = string.replace(/(^\s*)|(\s*$)/g, '');
    string = this.remove_excess_space(string);
    return string;
  }

  static remove_excess_space(string) {
    return string.replace(/\s+/g, ' ');
  }

  static remove_all_space(string) {
    return string.replace(/\s+/g, '');
  }

  static blank(string) {
    return /^\s*$/.test(string);
  }

  static escape(v) {
    if (v && ((typeof v) === 'string')) {
      v = v.replace(/</g, "&lt;");
      v = v.replace(/>/g, "&gt;");
    }
    return v;
  }

  static is_number(x) {
    return !isNaN(x) && (typeof (x) === 'number');
  }

  static is_hex(input) {
    input = this.strip(input)
      .toLowerCase();
    let valid = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
    return (input.length === 6) && input.split('')
      .reduce((function (a, x) {
        if (a && (Array.from(valid)
            .includes(x))) { return true; } else { return false; }
      }), true);
  }

  static email_format(email) {
    return /.+@.+\.\w+/.test(email);
  }

  static array_equal(a1, a2) {
    if (!a1 || !a2) { return false; }
    if (a1.length !== a2.length) { return false; }
    return __range__(0, a1.length - 1, true)
      .reduce((function (a, x) { if (a1[x] !== a2[x]) { a = false; } return a; }), true);
  }

  static merge(o1, o2) {
    if (o1 == null) { o1 = {}; }
    if (o2 == null) { o2 = {}; }
    return Object.keys(o2)
      .reduce(function (a, x) {
          a[x] = o2[x];
          return a;
        }, Object.keys(o1)
        .reduce((function (a, x) { a[x] = o1[x]; return a; }), {}));
  }

  static window_or_max_width(max_width, padding) {
    if (padding == null) { padding = 0; }
    let window_width = $(window)
      .width();
    let window_margin = window_width - (padding * 2);
    let width = window_margin < max_width ? window_margin : max_width;
    return width;
  }

  static window_or_max_height(max_height, padding) {
    if (padding == null) { padding = 0; }
    let window_height = $(window)
      .height();
    let window_margin = window_height - (padding * 2);
    let height = window_margin < max_height ? window_margin : max_height;
    return height;
  }

  static pass_through_or_max_fit(width, height, max_width, max_height) {
    if ((width > max_width) || (height > max_height)) {
      let a = this.contain(width, height, max_width, max_height);
      width = a[0];
      height = a[1];
    }

    width = Math.round(width);
    height = Math.round(height);
    return [width, height];
  }

  static contain(width, height, target_width, target_height) {
    let new_height, new_width;
    let source_aspect = (parseFloat(width) / parseFloat(height));
    let target_aspect = (parseFloat(target_width) / parseFloat(target_height));

    if (source_aspect <= target_aspect) {
      new_height = target_height;
      new_width = (width * new_height) / height;
    } else {
      new_width = target_width;
      new_height = (height * new_width) / width;
    }

    new_width = Math.round(new_width);
    new_height = Math.round(new_height);
    return [new_width, new_height];
  }

  static cover(width, height, target_width, target_height) {

    let new_height, new_width;
    let source_aspect = (parseFloat(width) / parseFloat(height));
    let target_aspect = (parseFloat(target_width) / parseFloat(target_height));

    if (source_aspect >= target_aspect) {
      new_height = target_height;
      new_width = (width * new_height) / height;
    } else {
      new_width = target_width;
      new_height = (height * new_width) / width;
    }

    new_width = Math.round(new_width);
    new_height = Math.round(new_height);

    return [new_width, new_height];
  }

  static is_OSX() {
    if (navigator.platform) {
      return /mac/i.test(navigator.platform.toLowerCase());
    } else {
      return false;
    }
  }

  static is_chrome() {
    return !!window.chrome;
  }

  static is_safari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  static is_safari_version_or_greater(version) {
    let match;
    if (!JT.is_number(version)) { return false; }
    if (JT.is_safari() !== true) { return false; }
    let user_agent = navigator.userAgent;
    if (match = user_agent.match(/version\/(\d+\.*\d*)/i)) {
      let app_version = parseFloat(match[1]);
      if (JT.is_number(app_version)) {
        return app_version >= version;
      }
    }
    return false;
  }

  static is_newer_firefox() {
    let is_firefox = navigator.userAgent.toLowerCase()
      .indexOf('firefox') !== -1;
    let new_version = false;
    let has_version = /firefox\/(\d|\.)+/.test(navigator.userAgent.toLowerCase());

    if (has_version) {
      let browser_and_version = /firefox\/(\d|\.)+/.exec(navigator.userAgent.toLowerCase())[0];
      let version = parseInt(browser_and_version.split("/")[1]);

      // Version 35 support for MP4
      new_version = version >= 35;
    }
    return (is_firefox && new_version);
  }

  static is_chrome_version_or_greater(version_num) {
    let is_chrome = JT.is_chrome();
    let greater_than_version = false;
    let has_version = /chrome\/(\d|\.)+/.test(navigator.userAgent.toLowerCase());
    if (is_chrome && has_version) {
      let browser_and_version = /chrome\/(\d|\.)+/.exec(navigator.userAgent.toLowerCase())[0];
      let version = parseInt(browser_and_version.split("/")[1]);
      greater_than_version = version >= version_num;
    }
    return (is_chrome && greater_than_version);
  }

  static is_mobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  static is_android() {
    return navigator.userAgent.toLowerCase()
      .indexOf('android') !== -1;
  }

  static is_iphone() {
    return /iPhone|iPad/i.test(navigator.userAgent);
  }

  static monospace(string, classname) {
    return string.split("")
      .map(x => `<div class='${classname}'>${x}</div>`)
      .join("");
  }

  static multi_parse(parser, string) {
    let tokens = [{ type: null, string }];

    for (let rule of Array.from(parser.rules)) {
      let new_tokens = [];
      let splitters = [];
      let { start } = rule;
      let { end } = rule;
      let { key } = rule;
      let { pattern } = rule;
      let { callback } = rule;
      let match_regex = null;

      switch (true) {
        case JT.exists(pattern):
          match_regex = pattern;
          break;
        case JT.exists(start) && JT.exists(end):
          match_regex = new RegExp(`${start}[^\\s${start}]+${end}`, 'g');
          break;
        case JT.exists(start):
          match_regex = new RegExp(`${start}[^\\s${start}]+`, 'g');
          break;
      }

      if (match_regex !== null) {
        let last_token = null;
        for (let token of Array.from(tokens)) {

          // token_string may be redefined for parsing purposes, keep original for final parsing
          let token_string = token.string;
          let original_token_string = token.string;

          if (!JT.exists(token.key) && (token_string !== "")) {
            let match_results = [];
            let regex_cache = new RegExp(match_regex);
            var match = match_regex.exec(token_string);

            if (match && match[0]) {
              if (match_regex.global) {
                while (match !== null) {
                  if (rule.test && (rule.test(match[0]) === false)) {
                    match_regex = regex_cache;
                    token_string = token_string.substring(match.index + 1);
                  } else {
                    match_results.push(match[0]);
                  }

                  regex_cache = new RegExp(match_regex);
                  match = match_regex.exec(token_string);
                }
              } else {
                match_results.push(match[0]);
              }
            }

            if (match_results.length > 0) {
              let token_split = [original_token_string];
              let new_token_split = [];

              for (match of Array.from(match_results)) {
                token_split = token_split.reduce((function (acc, t) { acc = acc.concat(t.split(match)); return acc; }), []);
              }

              let null_tokens = token_split.map(token_substring => ({ type: null, string: token_substring }));
              for (let i = 0, end1 = null_tokens.length - 1, asc = 0 <= end1; asc ? i <= end1 : i >= end1; asc ? i++ : i--) {
                i = parseInt(i);
                if (i !== 0) {
                  let new_string = callback(match_results[i - 1], last_token);
                  new_tokens.push({ key, string: new_string });
                }
                new_tokens.push(null_tokens[i]);
              }
            } else {
              new_tokens.push(token);
            }
          } else {
            new_tokens.push(token);
          }
          last_token = token;
        }
        tokens = new_tokens;
      }
    }

    string = tokens.reduce((function (acc, x) { acc += x.string; return acc; }), "");
    return string;
  }

  static test_multi_parse() {
    // input = "#yo#son&#emoji;&#emoji;frame.io#yo&#emoji;:no-emoji:smiley:"
    // output = "<hash>#yo</hash><hash>#son</hash><emoji>&#emoji;</emoji><emoji>&#emoji;</emoji><link>frame.io#yo</link><emoji>&#emoji;</emoji>:no-emoji<emoji>:smiley:</emoji>"
    let input = ":no-emoji:emoji:";
    let output = ":no-emoji<emoji>:emoji:</emoji>";
    let link_pattern = /(([^\s#;\."'])+\.)+[^\s\."']{2,}/;
    let break_pattern = /\s/;

    let parser = {
      rules: [
        { key: 'emoji', start: "&", end: ";", callback(token) { return `<emoji>${token}</emoji>`; } },
        {
          key: 'emoji-old',
          start: ":",
          end: ":",
          callback(token) { return `<emoji>${token}</emoji>`; },
          test(token) { return token === ':emoji:'; }
        },
        { key: 'link', pattern: link_pattern, callback(token) { return `<link>${token}</link>`; } },
        { key: 'hash', start: "#", callback(token) { return `<hash>${token}</hash>`; } }
      ],
      default_callback(text) { return text; }
    };
    let parsed = JT.multi_parse(parser, input);
    return console.log(parsed === output ? "PASSED" : "FAILED");
  }

  static simple_parse(parser, string, callback) {
    let chars = string.split("");
    let open = false;
    let query = "";
    let result = "";
    let begin_limiter = parser.start;
    let end_limiter = parser.end;

    for (let char of Array.from(chars)) {
      switch (true) {
        case (char === begin_limiter) && !open:
          open = true;
          result += query;
          query = "";
          break;
        case (char === begin_limiter) && open && !end_limiter:
          result += callback(query);
          query = "";
          break;
        case end_limiter && (char === end_limiter) && open:
          result += callback(query);
          open = false;
          query = "";
          break;
        case open:
          query += char;
          break;
        default:
          result += char;
      }
    }

    if (open && !end_limiter) {
      result += callback(query);
      query = "";
    }

    result += query;
    return result;
  }
}

export default JT;

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}
