import React from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Switch } from 'react-router-dom';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import PaymentFlow from 'components/PaymentFlow';
import { ManageTeamMemberModal } from 'components/ManageSubscription';
import history from './history';
import ConfirmSeatsAdded from './ConfirmSeatsAdded';

export const ADD_SEATS_URL = '/AddSeats';
export const PAYMENT_FLOW_URL = '/PaymentFlow';
export const CONFIRM_SEATS_ADDED_URL = '/ConfirmSeatsAdded';

export default class AddSeatsFlow extends React.Component {
  componentDidMount() {
    const { accountId, startFlow, trackingProps } = this.props;

    startFlow(accountId, trackingProps);
  }

  componentWillUnmount() {
    this.props.endFlow();
  }

  render() {
    const {
      accountId,
      closeModal,
      continueFlow,
      seatUnit,
      showTotalLineItem,
      trackingProps,
    } = this.props;

    return (
      <React.Fragment>
        <ModalCloseButton onClick={closeModal} />
        <Router history={history}>
          <Switch>
            <Route
              path={ADD_SEATS_URL}
              render={() => (
                <ManageTeamMemberModal
                  accountId={accountId}
                  onCancel={closeModal}
                  continueFlow={continueFlow}
                  showTotalLineItem={showTotalLineItem}
                />
              )}
            />
            <Route
              path={PAYMENT_FLOW_URL}
              render={(props) => (
                <PaymentFlow
                  accountId={accountId}
                  onCancel={closeModal}
                  trackingProps={trackingProps}
                  {...props}
                />
              )}
            />
            <Route
              path={CONFIRM_SEATS_ADDED_URL}
              render={(props) => (
                <ConfirmSeatsAdded
                  seatUnit={seatUnit}
                  accountId={accountId}
                  onDoneClick={closeModal}
                  {...props}
                />
              )}
            />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

AddSeatsFlow.propTypes = {
  accountId: PropTypes.string,
  closeModal: PropTypes.func,
  continueFlow: PropTypes.func,
  endFlow: PropTypes.func,
  seatUnit: PropTypes.string.isRequired,
  showTotalLineItem: PropTypes.bool,
  startFlow: PropTypes.func,
  trackingProps: PropTypes.object,
};

AddSeatsFlow.defaultProps = {
  accountId: undefined,
  closeModal: undefined,
  continueFlow: undefined,
  endFlow: undefined,
  showTotalLineItem: true,
  startFlow: undefined,
  trackingProps: {},
};
