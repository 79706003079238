import { createSelector } from 'reselect';
import { merge } from 'lodash';
import {
  commentEntitiesSelector,
  commentEntitySelector,
} from '@frameio/core/src/comments/selectors';
import { isAnonymousCommentFromCurrentSession } from '@frameio/core/src/comments/utils';
import { isAnonymousUser } from '@frameio/core/src/users/utils';
import { userEntitiesSelector } from '@frameio/core/src/users/selectors';
import { assetEntitiesSelector } from '@frameio/core/src/assets/selectors';
import { impressionEntitiesSelector } from '@frameio/core/src/impressions/selectors';
import { currentUserEntitySelector } from 'selectors/users';
import { canTimestampComment } from 'selectors/permissions/media';
import transformImpressions from './helpers';

/**
 *  @returns - Comment entities transformed for the player.
 */
export const commentsForPlayerSelector = createSelector(
  [
    commentEntitiesSelector,
    userEntitiesSelector,
    currentUserEntitySelector,
    assetEntitiesSelector,
    impressionEntitiesSelector,
  ],
  (comments, users, currentUser = {}, assets, impressions) =>
    Object.values(comments).reduce(
      (
        acc,
        {
          id,
          completer_id: completerId,
          inserted_at,
          asset_id,
          timestamp,
          read_count,
          like_count,
          annotation,
          owner_id,
          anonymous_user_id,
          ...rest
        }
      ) => {
        let completerName;
        // eslint-disable-next-line camelcase
        const ownerId = owner_id || anonymous_user_id;
        if (completerId && completerId === currentUser.id) {
          completerName = 'you';
        } else if (completerId && users[completerId]) {
          completerName = users[completerId].name;
        }
        const hydratedImpressions = Object.values(impressions || {}).filter(
          (impression) => impression.comment_id === id
        );
        const readByImpressions = hydratedImpressions.filter((impression) =>
          impression.type.includes('read')
        );
        const upvoteImpressions = hydratedImpressions.filter((impression) =>
          impression.type.includes('like')
        );
        const hasUpvoted = upvoteImpressions.some(
          (impression) => impression.user_id === currentUser.id
        );

        const isOwnerCurrentUser = ownerId === currentUser.id;
        const commentAsset = assets[asset_id] || {};
        const owner = users[ownerId] || {};
        const hasUserReadComment = readByImpressions.some(
          (impression) => impression.user_id === currentUser.id
        );

        // Only `stream` assets can have a timestamp – this is left because of incorrect legacy data
        // where some image assets also have timestamps present :upside-down-face:
        const commentTimestamp = canTimestampComment(commentAsset)
          ? timestamp
          : null;

        acc[id] = {
          id,
          asset_id,
          annotation,
          completer: completerName,
          image: owner.image_32,
          userName: owner.name,
          user: owner,
          createdAt: inserted_at,
          userDefaultColor: owner.user_default_color,
          timestamp: commentTimestamp,
          readByCount: read_count,
          readBy: transformImpressions(readByImpressions, users, currentUser),
          upVoteCount: like_count,
          upVoters: transformImpressions(upvoteImpressions, users, currentUser),
          hasUpvoted,
          assetFramePerSecond: commentAsset.fps,
          assetStartTimecode: commentAsset.timecode,
          isAnnotation: !!annotation,
          hasRead: isOwnerCurrentUser || hasUserReadComment,
          ...rest,
        };

        return acc;
      },
      {}
    )
);

/**
 * @returns {Object} - All the comments with their nested replies.
 */
export const commentsByThreadSelector = createSelector(
  [commentsForPlayerSelector],
  (comments) => {
    const newCommentsState = merge({}, comments);
    Object.values(newCommentsState).forEach((comment) => {
      if (comment.parent_id) {
        const parentComment = newCommentsState[comment.parent_id];
        if (!parentComment) {
          delete newCommentsState[comment.id];
        } else {
          const commentReplies =
            newCommentsState[comment.parent_id].replies || [];
          // copy over the timestamp so that replies have knowledge of their place on the timeline
          // see: https://github.com/Frameio/web-client/pull/6877
          comment.timestamp = parentComment.timestamp; // eslint-disable-line no-param-reassign
          // copy over the target_asset_id so that replies have knowledge of their target asset
          // eslint-disable-next-line no-param-reassign
          comment.target_asset_id = parentComment.target_asset_id;
          const mergedReplies = commentReplies.concat(comment);
          newCommentsState[comment.parent_id].replies = mergedReplies;
          delete newCommentsState[comment.id];
        }
      }
    });
    return newCommentsState;
  }
);

/**
 * @returns {Object} - An object of asset id's with an array of comments belonging
 * to that asset.
 */
export const commentThreadsByAssetSelector = createSelector(
  [commentsByThreadSelector],
  (comments) =>
    Object.values(comments || {}).reduce((acc, comment) => {
      acc[comment.asset_id] = acc[comment.asset_id] || [];
      acc[comment.asset_id].push(comment);
      return acc;
    }, {})
);

/**
 * @returns {Object} - An object of asset id's with total comment counts of each asset.
 */
export const commentCountsByAssetSelector = createSelector(
  [commentThreadsByAssetSelector],
  (commentThreads) =>
    Object.keys(commentThreads || {}).reduce((acc, assetId) => {
      const commentThread = commentThreads[assetId];
      acc[assetId] = commentThread.reduce(
        (sum, comment) => sum + 1 + (comment.replies || []).length,
        0
      );
      return acc;
    }, {})
);

/**
 * Given a commentId, will return the user associated (either a reg user or anon user).
 * @returns {Object} - A user entity for the comment owner.
 */
export const commentOwnerEntitySelector = createSelector(
  commentEntitySelector,
  userEntitiesSelector,
  (comment, users) =>
    comment && users[comment.owner_id || comment.anonymous_user_id]
);

/**
 * @returns {boolean} - Whether or not the current user owns the comment.
 */
export const isCommentOwnedByCurrentUserSelector = createSelector(
  commentEntitySelector,
  commentOwnerEntitySelector,
  currentUserEntitySelector,
  (comment, commentOwner, currentUser) => {
    if (!commentOwner || !currentUser) return false;

    let isOwner = commentOwner.id === currentUser.id;

    // if the current user is the owner AND is an anonymous user
    if (isOwner && isAnonymousUser(currentUser)) {
      // check session storage to see if this comment was also created in the
      // current session
      isOwner = isOwner && isAnonymousCommentFromCurrentSession(comment.id);
    }

    return isOwner;
  }
);
