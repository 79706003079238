import { connect } from 'react-redux';
import { openManageVersionStackInModal } from '../../../pages/ProjectContainer/ManageVersionStackModal/actions';
import ManageVersionStackInModal from './ManageVersionStackInModal';

const mapDispatchToProps = { openManageVersionStackInModal };

export default connect(
  null,
  mapDispatchToProps
)(ManageVersionStackInModal);

export const testExports = {
  mapDispatchToProps,
};
