import { takeEvery, put, call, select } from 'redux-saga/effects';
import {
  undeleteAsset as coreUndeleteAsset,
  getDeletedAssetsInProject as coreGetDeletedAssetsInProject,
  searchAssetsInProject,
  searchAssetsInTeams,
} from '@frameio/core/src/assets/sagas';
import { createPaginatedListSaga } from '@frameio/core/src/shared/sagas/helpers';
import { closeModal } from 'components/Modal/actions';
import { showErrorToast } from 'actions/toasts';
import {
  shouldFetchProjectAssetsSelector,
  shouldFetchTeamAssetsSelector,
} from './selectors';
import DELETED_ASSETS from './actions';

const getDeletedAssetsInProject = createPaginatedListSaga(
  DELETED_ASSETS.GET_IN_PROJECT,
  coreGetDeletedAssetsInProject,
  { extractPage: (projectId, page) => page }
);

const paginatedSearchInProject = createPaginatedListSaga(
  DELETED_ASSETS.SEARCH_IN_PROJECT,
  searchAssetsInProject,
  { extractPage: (query, projectId, options) => options.page }
);

const searchOptions = {
  pageSize: 20,
  includeDeleted: true,
  filters: { deleted: true },
};

function* searchDeletedAssetsInProject(query, projectId, page) {
  const shouldFetch = yield select(shouldFetchProjectAssetsSelector, { page });
  if (!shouldFetch) return;
  yield call(paginatedSearchInProject, query, projectId, {
    ...searchOptions,
    page,
  });
}

const paginatedSearchInTeam = createPaginatedListSaga(
  DELETED_ASSETS.SEARCH_IN_TEAM,
  searchAssetsInTeams,
  { extractPage: (query, teamIds, options) => options.page }
);

function* searchDeletedAssetsInTeam(query, teamId, page) {
  const shouldFetch = yield select(shouldFetchTeamAssetsSelector, { page });
  if (!shouldFetch) return;
  yield call(paginatedSearchInTeam, query, [teamId], {
    ...searchOptions,
    page,
  });
}

function* undeleteAsset(assetId) {
  const { success } = yield call(coreUndeleteAsset, assetId);
  if (success) {
    yield put(closeModal());
    return;
  }
  yield put(
    showErrorToast({
      header: 'Something went wrong while restoring this asset.',
      subHeader: 'Please try again.',
    })
  );
}

export default [
  takeEvery(
    DELETED_ASSETS.GET_IN_PROJECT.BASE,
    ({ payload: { projectId, page } }) =>
      getDeletedAssetsInProject(projectId, page)
  ),
  takeEvery(
    DELETED_ASSETS.SEARCH_IN_PROJECT.BASE,
    ({ payload: { query, projectId, page } }) =>
      searchDeletedAssetsInProject(query, projectId, page)
  ),
  takeEvery(
    DELETED_ASSETS.SEARCH_IN_TEAM.BASE,
    ({ payload: { query, teamId, page } }) =>
      searchDeletedAssetsInTeam(query, teamId, page)
  ),
  takeEvery(DELETED_ASSETS.UNDELETE_ASSET.BASE, ({ payload: { assetId } }) =>
    undeleteAsset(assetId)
  ),
];

export const testExports = {
  getDeletedAssetsInProject,
  paginatedSearchInProject,
  paginatedSearchInTeam,
  searchDeletedAssetsInProject,
  searchDeletedAssetsInTeam,
  searchOptions,
  undeleteAsset,
};
