import React, { useCallback, useState } from 'react';
import { set, pipe } from 'lodash/fp';
import { useFormikContext } from 'formik';
import styled, { css } from 'styled-components';
import { Tooltip } from '@frameio/vapor';
import AddSVG from '@frameio/components/src/svgs/icons/16/plus.svg';
import { DEFAULT_NEW_BLOCK_STATE, MAX_BLOCK_COUNT } from './constants';

const StyledAddSvg = styled(AddSVG)`
  margin-left: ${(p) => p.theme.spacing.small};
  margin-bottom: 1px;
  padding: ${(p) => p.theme.spacing.tiny};
  color: ${(p) => p.theme.color.graphiteGray};
  background-color: ${(p) => p.theme.color.silver};
  border-radius: ${(p) => p.theme.radius.circle};
  transition: background-color 150ms ease-out, transform 250ms ease;
`;

const enabledStyles = css`
  :active,
  :hover {
    color: ${(p) => p.theme.color.brand};

    ${StyledAddSvg} {
      color: ${(p) => p.theme.color.coldWhite};
      background-color: ${(p) => p.theme.color.brand};
    }
  }

  :hover ${StyledAddSvg} {
    transform: scale(1.1);
  }

  :active ${StyledAddSvg} {
    transform: scale(0.9);
  }
`;

const disabledStyles = css`
  color: ${(p) => p.theme.color.slateGray};
  cursor: not-allowed;
`;

const StyledA = styled.a`
  display: flex;
  align-items: center;
  margin-left: ${(p) => p.theme.spacing.small};
  ${(p) => p.theme.fontStyle.bodyM}
  text-align: right;
  color: ${(p) => p.theme.color.coolBlack};
  cursor: pointer;
  transition: color 150ms ease-out;

  ${(p) => !p.disabled && enabledStyles}
  ${(p) => p.disabled && disabledStyles}
`;

const SessionWatermarkTemplateAddButton = (props) => {
  const { disabled, label, ...rest } = props;
  const { values, setValues } = useFormikContext();
  const [tempBlockCount, setTempBlockCount] = useState(1);
  const totalBlocksCount = Object.keys(values.watermark_blocks).length;
  const isDisabled = disabled || totalBlocksCount + 1 > MAX_BLOCK_COUNT;

  const onClick = useCallback(() => {
    const blockId = `client-created-block-${tempBlockCount}`;
    const addBlock = pipe(
      set(['watermark_blocks', blockId], DEFAULT_NEW_BLOCK_STATE),
      set(['watermark_blocks', blockId, 'id'], blockId),
      set('activeBlockId', blockId),
      setValues
    );
    setTempBlockCount(tempBlockCount + 1);
    return addBlock(values);
  }, [tempBlockCount, setValues, values]);

  return (
    <Tooltip
      disabled={!isDisabled}
      title={
        <React.Fragment>
          Sorry, templates can’t have <br />
          more than {MAX_BLOCK_COUNT} blocks
        </React.Fragment>
      }
      variant="dark"
      placement="bottom-end"
    >
      <StyledA
        onClick={isDisabled ? null : onClick}
        disabled={isDisabled}
        {...rest}
      >
        {label}
        <StyledAddSvg height={16} width={16} />
      </StyledA>
    </Tooltip>
  );
};

export default SessionWatermarkTemplateAddButton;
