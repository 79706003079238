import { useState, useEffect } from 'react';

/**
 * This implements the twitter deferred mount
 * strategy, which allows you to delay the mounting
 * of expensive react components for better perceived
 * performance.
 */

function useDeferredMount() {
  const [shouldMount, setShouldMount] = useState(false);

  useEffect(() => {
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        setShouldMount(true);
      });
    });
  });

  return shouldMount;
}

export default useDeferredMount;
