import React from 'react';
import PropTypes from 'prop-types';
import { MenuButton } from '@frameio/vapor';
import TrashcanIcon from '@frameio/components/src/svgs/icons/trashcan.svg';

export default function DeleteAssets({
  areAssetsVersionStacks,
  singleVersionStackVersions,
  assetIds,
  confirmDeleteAssets,
  trackingPage,
  trackingPosition,
  ...rest
}) {
  let body = 'Delete';
  if (singleVersionStackVersions) {
    body = `Delete (${singleVersionStackVersions} versions)`;
  } else if (areAssetsVersionStacks) {
    body = 'Delete all versions';
  }
  return (
    <MenuButton
      destructive
      onSelect={() => {
        confirmDeleteAssets(assetIds, trackingPage, trackingPosition);
      }}
      iconBefore={<TrashcanIcon width={16} height={16} />}
      {...rest}
    >
      {body}
    </MenuButton>
  );
}

DeleteAssets.propTypes = {
  areAssetsVersionStacks: PropTypes.bool.isRequired,
  singleVersionStackVersions: PropTypes.number.isRequired,
  assetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  confirmDeleteAssets: PropTypes.func.isRequired,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};
