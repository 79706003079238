import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import PropTypes from 'prop-types';
import { trackButtonClick } from 'analytics';
import CloseButton from '@frameio/components/src/components/Modal/CloseButton';
import Button from '@frameio/components/src/styled-components/Button';
import ModalHeader from '@frameio/components/src/styled-components/Modal/ModalHeader';
import { ModalFooter } from '@frameio/components/src/styled-components/Modal/ModalContent';
import Switch from '@frameio/components/src/styled-components/Switch';
import AssetThumbView, {
  Container as AssetThumbViewContainer,
} from './AssetThumbView';
import { Head, Wrapper } from '../sharedComponents';

export const DUAL_VIEW_MODE = {
  UPLOAD: 'Upload',
  CREATE: 'Create',
};

const trackingPage = 'turnstyle modal';
const trackingPosition = 'bottom';

const ThumbViews = styled(Flex)`
  ${(p) =>
    p.needsBottomBorder &&
    `border-bottom: 1px solid ${p.theme.color.coldWhite};`}
  padding-bottom: ${(p) =>
    `calc(${p.theme.spacing.small} + ${p.theme.spacing.tiny})`};
`;

const Body = styled.div`
  padding: ${(p) => `${p.theme.spacing.medium} ${p.theme.spacing.large}`};

  ${AssetThumbViewContainer} {
    margin: 0px ${(p) => p.theme.spacing.small};
  }
`;

const MergeOption = styled(Flex).attrs({
  justifyBetween: true,
})`
  ${(p) => p.theme.fontStyle.bodyM}
  color: ${(p) => p.theme.color.darkGray};
  padding-top: ${(p) =>
    `calc(${p.theme.spacing.small} + ${p.theme.spacing.tiny})`};
`;

class CreateDualView extends React.Component {
  state = {
    shouldMerge: true,
  };

  render() {
    const {
      confirmDualViewModal,
      cancelModal,
      items,
      title,
      mode,
    } = this.props;

    const { shouldMerge } = this.state;

    return (
      <Wrapper>
        <Head>
          <ModalHeader>{title}</ModalHeader>
          <CloseButton onClick={cancelModal} />
        </Head>
        <Body>
          <ThumbViews needsBottomBorder={mode === DUAL_VIEW_MODE.UPLOAD}>
            {items.map(({ name, thumb, file }) => (
              <AssetThumbView
                key={name}
                name={name}
                thumb={thumb}
                file={file}
              />
            ))}
          </ThumbViews>
          {mode === DUAL_VIEW_MODE.UPLOAD && (
            <MergeOption>
              <div>Merge files into Turnstyle Asset</div>
              <Switch
                onChange={() => {
                  this.setState({ shouldMerge: !shouldMerge });
                  trackButtonClick(
                    'merge toggle',
                    trackingPage,
                    trackingPosition
                  );
                }}
                isOn={shouldMerge}
              />
            </MergeOption>
          )}
        </Body>

        <ModalFooter>
          <Button
            primary
            onClick={() => {
              confirmDualViewModal(shouldMerge);
              const trackingTitle =
                mode === DUAL_VIEW_MODE.UPLOAD
                  ? 'upload turnstyle modal'
                  : 'create turnstyle modal';
              trackButtonClick(trackingTitle, trackingPage, trackingPosition);
            }}
          >
            {mode === DUAL_VIEW_MODE.UPLOAD && shouldMerge
              ? `${mode} & Merge`
              : mode}
          </Button>
        </ModalFooter>
      </Wrapper>
    );
  }
}

CreateDualView.propTypes = {
  confirmDualViewModal: PropTypes.func.isRequired,
  cancelModal: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
};

export default CreateDualView;

export const testExports = {
  MergeOption,
  trackingPage,
  trackingPosition,
};
