import { connect } from 'react-redux';
import DuplicateAssetsToFolder from './DuplicateAssetsToFolder';
import { duplicateAssets } from './actions';
import { moveAssetMenuItemSelector } from './selectors';

function mapStateToProps(state, props) {
  const { folderId } = props;

  const { folder, projectId } =
    moveAssetMenuItemSelector(state, {
      assetId: folderId,
    }) || {};

  return {
    projectId,
    folderParentId: folder && folder.parent_id,
  };
}

const mapDispatchToProps = {
  duplicateAssets,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DuplicateAssetsToFolder);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
