import React from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import queryString from 'query-string';
import track, { trackPage } from 'analytics';
import store from 'configureStore';
import { updateTrackingIdentiferInfo } from 'actions/users';

export function trackedRoute(RouteComponent) {
  class TrackedRoute extends React.Component {
    componentDidMount() {
      const { email_id, email_type } = queryString.parse(
        window.location.search
      );
      if (email_id && email_type) {
        track('transactional-email-clicked-client', {
          transactional_email_id: email_id,
          email_type,
        });
      }

      const { name, computedMatch: match } = this.props;

      store.dispatch(
        updateTrackingIdentiferInfo({
          asset_id: match.params.assetId || undefined,
        })
      );

      if (!name) return;

      trackPage(name);
    }

    componentDidUpdate({
      computedMatch: prevMatch,
      path: prevPath,
      name: prevName,
    }) {
      const { path, name, computedMatch: match } = this.props;
      if (!name) return;
      if (
        prevPath !== path ||
        prevName !== name ||
        !isEqual(prevMatch.params, match.params)
      ) {
        store.dispatch(
          updateTrackingIdentiferInfo({
            asset_id: match.params.assetId || undefined,
          })
        );

        trackPage(name || path);
      }
    }

    render() {
      const { name, ...props } = this.props;
      return <RouteComponent {...props} />;
    }
  }
  TrackedRoute.defaultProps = {
    name: undefined,
    computedMatch: {},
  };
  TrackedRoute.propTypes = {
    computedMatch: PropTypes.shape({
      params: PropTypes.object,
      path: PropTypes.string,
    }),
    name: PropTypes.string,
    path: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]).isRequired,
  };
  const RouteComponentName =
    RouteComponent.displayName || RouteComponent.name || 'Route';
  TrackedRoute.displayName = `tracked(${RouteComponentName})`;
  return TrackedRoute;
}

export default trackedRoute(Route);
