import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const MODAL = generateActionTypes('MODAL', ['OPEN', 'CLOSE', 'UPDATE']);

/**
 * Returns the redux action to open the modal.
 * @param   {Object} children - The children to render in the modal.
 * @returns {Object} Action object.
 */
export function openModal(children = {}, modalProps = {}) {
  const vc = window.vc;
  vc && vc.unbind_keys && vc.unbind_keys();
  return {
    type: MODAL.OPEN,
    payload: { children, ...modalProps },
  };
}

/**
 * Returns the redux action to update an open modal.
 * @param   {Object} children - The children to render in the modal.
 * @returns {Object} Action object.
 */
export const updateModal = createAction(MODAL.UPDATE, (modalProps) => ({
  ...modalProps,
}));

/**
 * Returns the redux action to close the modal.
 * @returns {Object} Action object.
 */
export function closeModal() {
  const vc = window.vc;
  vc && vc.bind_keys && vc.bind_keys();
  return {
    type: MODAL.CLOSE,
    payload: {},
  };
}
