import { connect } from 'react-redux';
import ListTeamGroup from './ListTeamGroup';
import { fetchProjects } from './actions';

const mapDispatchToProps = {
  fetchProjects,
};

export default connect(
  null,
  mapDispatchToProps
)(ListTeamGroup);
