import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const ADD_TEAM_MEMBERS_MODAL = generateActionTypes(
  'ADD_TEAM_MEMBERS_MODAL',
  ['OPEN', 'ADD_TEAM_MEMBERS']
);

export const openAddTeamMembersModal = createAction(
  ADD_TEAM_MEMBERS_MODAL.OPEN,
  (teamId) => ({ teamId })
);

export const batchAddTeamMembers = createAction(
  ADD_TEAM_MEMBERS_MODAL.ADD_TEAM_MEMBERS,
  (teamId, emails, onTeamMembersAdded) => ({
    teamId,
    emails,
    onTeamMembersAdded,
  })
);
