import { COMMENT_COMPOSER } from './actions';

export default function commentComposerReducer(
  state = {
    isPrivate: false,
  },
  action
) {
  const { type, payload } = action;
  switch (type) {
    case COMMENT_COMPOSER.SET_PRIVACY:
      return {
        ...state,
        isTeamOnlySelected: payload.isTeamOnlySelected,
      };
    default:
      return state;
  }
}
