import React from 'react';
import styled from 'styled-components';
import { alignCenter, buttonReset } from '@frameio/components/src/mixins';
import Button from '@frameio/components/src/styled-components/Button';
import { STEPS } from '../../constants';

const StyledSpan = styled.span`
  margin-right: 16px;
`;

const StyledButton = styled(Button)`
  ${alignCenter()};
  ${buttonReset()};
  box-shadow: none;
  color: ${(p) => p.theme.color.brand};
  font-weight: lighter;
  height: 21px;
  min-width: 32px;
  text-decoration: underline;
`;

const CardPreviewHeader = ({ card, setStep, onEdit }) => {
  const { exp_month: expMonth, exp_year: expYear, last4, brand } = card;
  if (last4 && brand) {
    return (
      <>
        <StyledSpan>
          {brand} ending in {last4}
        </StyledSpan>
        <StyledSpan>
          {expMonth}/{expYear}
        </StyledSpan>
        <StyledButton
          onClick={() => {
            setStep(STEPS.THREE);
            onEdit(false);
          }}
        >
          edit
        </StyledButton>
      </>
    );
  }
  return 'Please enter your payment information.';
};

CardPreviewHeader.defaultProps = {
  setStep: () => {},
  onEdit: () => {},
  card: {},
};

export default CardPreviewHeader;
