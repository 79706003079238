import { createAction } from 'redux-actions';
import {
  generateFetchActionTypes,
  generateActionTypes,
} from 'shared/actions/helpers';

const module = 'SEARCH_CONTAINER';
const SEARCH_CONTAINER = {
  ...generateFetchActionTypes(module, ['SEARCH'], true),
  ...generateFetchActionTypes(module, ['TYPEAHEAD']),
  ...generateActionTypes(module, [
    'SET_SORT',
    'SET_VIEW_TYPE',
    'SET_SELECTION',
  ]),
};

export default SEARCH_CONTAINER;

export const search = createAction(
  SEARCH_CONTAINER.SEARCH.BASE,
  (accountId, query, page, options) => ({ accountId, query, page, options })
);

export const setSort = createAction(
  SEARCH_CONTAINER.SET_SORT,
  (option, isDescending, prevSortBy, wasDescending) => ({
    option,
    isDescending,
    prevSortBy,
    wasDescending,
  })
);

export const setViewType = createAction(
  SEARCH_CONTAINER.SET_VIEW_TYPE,
  (viewType) => ({ viewType })
);

export const selectResults = createAction(
  SEARCH_CONTAINER.SET_SELECTION,
  (assetIds) => ({ assetIds })
);

export const resetSearch = createAction(
  SEARCH_CONTAINER.SEARCH.RESET,
  (preserveMetadata) => ({ preserveMetadata })
);
