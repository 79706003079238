import ROLES from '@frameio/core/src/roles/helpers/constants';
import queryString from 'query-string';
import { plans, trialLength } from 'utils/plans/plansHelpers';
import { limitTypes } from 'selectors/limits';
import { formatBytes } from 'shared/filesizeHelpers';

// todo(setsun): remove this after we have proper i18n
const getPluralText = (count) => (count !== 1 ? 's' : '');

export const getMailTo = ({ accountOwner, currentUser, type }) => {
  const subject = `Hit our Frame.io ${type} limit`;
  const body = [
    `Hey ${accountOwner.name},`,
    `It looks like we've hit our ${type} limit and need to upgrade our account's plan to add more.`,
    'Can you please visit Frame.io today and upgrade?',
    `Thanks in advance, \r\n ${currentUser.name}`,
  ].join('\r\n \r\n');
  const query = queryString.stringify({ subject, body });

  return `mailto:${accountOwner.email}?${query}`;
};

export const getSalesMailTo = ({
  currentPlan,
  currentUser,
  type,
  nextPlan,
}) => {
  let subject = '';
  let body = '';

  if (
    nextPlan.name === plans.ENTERPRISE &&
    (type === limitTypes.TEAM_MEMBERS || type === limitTypes.STORAGE)
  ) {
    subject = `Additional ${type} request`;
    body = [
      'Hey there,',
      `We've hit the ${currentPlan.team_limit} ${type} limit on the ${currentPlan.title} Plan and need to add more.`,
      `Could you please suggest a time today or tomorrow to discuss unlocking more ${
        type === limitTypes.TEAM_MEMBERS ? 'seats' : 'storage'
      } with Frame.io Enterprise?`,
      `Thanks in advance, \r\n ${currentUser.name}`,
    ].join('\r\n \r\n');
  } else {
    subject = `Hit our Frame.io ${type} limit`;
    body = [
      'Hey there,',
      `It looks like we've hit our ${type} limit and need to upgrade our account's plan to add more.`,
      'Can you upgrade our account today?',
      `Thanks in advance, \r\n ${currentUser.name}`,
    ].join('\r\n \r\n');
  }

  const query = queryString.stringify({ subject, body });

  return `mailto:sales@frame.io?${query}`;
};

const TRIAL_OFFER = ` How about a free ${trialLength}-day trial?`;

const projectBlockUpgradeCopyFunctions = {
  header: () => 'You’ve reached your project limit',
  body: ({ currentPlan, nextPlan }) =>
    `The ${currentPlan.title} Plan includes ${
      currentPlan.project_limit
    } project${getPluralText(
      currentPlan.project_limit
    )}. To add another, please remove an existing project or upgrade to the ${
      nextPlan.name
    } Plan.`,
  button: () => 'Get more projects',
  disclaimer: ({ nextPlan }) =>
    `The ${nextPlan.name} Plan costs $${nextPlan.price.monthly}/mo`,
};

const projectBlockTriageCopyFunctions = {
  header: () => 'You’ve reached your project limit',
  body: ({ accountOwner }) =>
    `To add another project, you'll need to remove an existing project or ask ${accountOwner.name} to upgrade this account's plan.`,
  button: ({ accountOwner }) => `Ask ${accountOwner.name} to Upgrade`,
  disclaimer: () => null,
};

const storageBlockUpgradeCopyFunctions = {
  header: () => 'You’re out of space',
  body: ({ currentPlan, nextPlan, canAutoChargeCard }) =>
    `The ${currentPlan.title} Plan includes ${formatBytes(
      currentPlan.storage_limit
    )} of storage. Based on current usage, the ${
      nextPlan.name
    } Plan looks like a better fit.${
      !canAutoChargeCard && nextPlan.name !== plans.ENTERPRISE
        ? TRIAL_OFFER
        : ''
    }`,
  button: () => 'Get more storage',
  disclaimer: ({ nextPlan }) =>
    `You'll be charged $${nextPlan.price.monthly}/mo once your trial is over. Cancel anytime.`,
};

const storageBlockTriageCopyFunctions = {
  header: () => 'You’re out of space',
  body: ({ accountOwner }) =>
    `To finish uploading this file, you'll need to remove existing media or ask ${accountOwner.name} to upgrade this account's plan.`,
  button: ({ accountOwner }) => `Ask ${accountOwner.name} to upgrade`,
  disclaimer: () => null,
};

const teamMemberUpgradeCopyFunctions = {
  header: () => 'You’ve hit your team member limit',
  body: ({ currentPlan, nextPlan, canAutoChargeCard }) =>
    `The ${currentPlan.title} Plan includes ${
      currentPlan.member_limit
    } team member${getPluralText(
      currentPlan.member_limit
    )}. Based on current usage, the ${
      nextPlan.name
    } Plan looks like a better fit.${
      !canAutoChargeCard && nextPlan.name !== plans.ENTERPRISE
        ? TRIAL_OFFER
        : ''
    }`,
  button: () => 'Get more team members',
  disclaimer: ({ nextPlan }) =>
    `The ${nextPlan.name} Plan costs $${nextPlan.price.monthly}/mo`,
};

const teamMemberBlockTriageCopyFunctions = {
  header: () => 'You’ve hit your team member limit',
  body: ({ accountOwner }) =>
    `To add another team member, please ask ${accountOwner.name} to upgrade this account's plan.`,
  button: ({ accountOwner }) => `Ask ${accountOwner.name} to Upgrade`,
  disclaimer: () => null,
};

const fileCountUpgradeCopyFunctions = {
  header: () => 'You’ve hit your upload limit',
  body: ({ currentPlan, nextPlan, canAutoChargeCard }) =>
    `The ${currentPlan.title} Plan includes ${
      currentPlan.lifetime_file_limit
    } upload${getPluralText(
      currentPlan.lifetime_file_limit
    )} to get you started. ${
      !canAutoChargeCard && nextPlan.name !== plans.ENTERPRISE
        ? `How about a free ${trialLength}-day trial of the ${nextPlan.name} Plan with unlimited uploads?`
        : `Based on current usage, the ${nextPlan.name} Plan looks like a better fit.`
    }`,
  button: () => 'Get unlimited uploads',
  disclaimer: ({ nextPlan }) =>
    `The ${nextPlan.name} Plan costs $${nextPlan.price.monthly}/mo`,
};

const fileCountBlockTriageCopyFunctions = {
  header: () => 'You’ve hit your upload limit',
  body: ({ accountOwner }) =>
    `To get unlimited uploads, please ask ${accountOwner.name} to upgrade this account's plan.`,
  button: ({ accountOwner }) => `Ask ${accountOwner.name} to Upgrade`,
  disclaimer: () => null,
};

export default {
  [limitTypes.PROJECTS]: {
    [ROLES.OWNER]: projectBlockUpgradeCopyFunctions,
    [ROLES.ADMIN]: projectBlockUpgradeCopyFunctions,
    [ROLES.TEAM_MANAGER]: projectBlockTriageCopyFunctions,
    [ROLES.TEAM_MEMBER]: projectBlockTriageCopyFunctions,
    [ROLES.COLLABORATOR]: projectBlockTriageCopyFunctions,
  },
  [limitTypes.STORAGE]: {
    [ROLES.OWNER]: storageBlockUpgradeCopyFunctions,
    [ROLES.ADMIN]: storageBlockUpgradeCopyFunctions,
    [ROLES.TEAM_MANAGER]: storageBlockTriageCopyFunctions,
    [ROLES.TEAM_MEMBER]: storageBlockTriageCopyFunctions,
    [ROLES.COLLABORATOR]: {
      header: () => "You're out of space",
      body: ({ accountOwner }) =>
        `To finish uploading this file, you'll need to ask ${accountOwner.name} to upgrade this account's plan.`,
      button: ({ accountOwner }) => `Ask ${accountOwner.name} to upgrade`,
      disclaimer: () => null,
    },
  },
  [limitTypes.TEAM_MEMBERS]: {
    [ROLES.OWNER]: teamMemberUpgradeCopyFunctions,
    [ROLES.ADMIN]: teamMemberUpgradeCopyFunctions,
    [ROLES.TEAM_MANAGER]: teamMemberBlockTriageCopyFunctions,
    [ROLES.TEAM_MEMBER]: teamMemberBlockTriageCopyFunctions,
    [ROLES.COLLABORATOR]: teamMemberBlockTriageCopyFunctions,
  },
  [limitTypes.LIFETIME_UPLOADS]: {
    [ROLES.OWNER]: fileCountUpgradeCopyFunctions,
    [ROLES.ADMIN]: fileCountUpgradeCopyFunctions,
    [ROLES.TEAM_MANAGER]: fileCountBlockTriageCopyFunctions,
    [ROLES.TEAM_MEMBER]: fileCountBlockTriageCopyFunctions,
    [ROLES.COLLABORATOR]: fileCountBlockTriageCopyFunctions,
  },
};
