import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const ADD_SEATS_FLOW = generateActionTypes('ADD_SEATS_FLOW', [
  'IS_FETCHING',
  'START',
  'CONTINUE',
  'END',
  'REPORT_SUCCESS',
]);

export const startFlow = createAction(
  ADD_SEATS_FLOW.START,
  (accountId, trackingProps) => ({ accountId, trackingProps })
);

export const continueFlow = createAction(
  ADD_SEATS_FLOW.CONTINUE,
  (updatedLimit, totalSeatsAdded) => ({ updatedLimit, totalSeatsAdded })
);

export const endFlow = createAction(ADD_SEATS_FLOW.END, () => ({}));

export const reportAddSeatsFlowSuccess = createAction(
  ADD_SEATS_FLOW.REPORT_SUCCESS,
  () => ({})
);
