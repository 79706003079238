/* eslint-disable import/prefer-default-export */

import { bytesByValueAndSize } from 'shared/filesizeHelpers';

export const generateTotalAndUnit = (amount) => {
  const { value, size } = bytesByValueAndSize(amount);
  const total = value;
  const unit = size;
  return { total, unit };
};
