import { createAction } from 'redux-actions';
import {
  generateActionTypes,
  generateFetchActionTypes,
} from 'shared/actions/helpers';

const module = 'PROJECT_LINKS';
export const PROJECT_LINKS = {
  ...generateActionTypes(module, [
    'CONFIRM_DELETE',
    'COPY_SHORT_URL',
    'EDIT_CONTENT',
    'EDIT_SETTINGS',
    'PATCH_SORT',
    'PATCH_TOGGLE_IS_ACTIVE',
    'SORT',
    'SELECT_LINKS_TAB',
  ]),
  ...generateFetchActionTypes(module, ['DELETE', 'DUPLICATE', 'RENAME']),
  ...generateFetchActionTypes(module, ['FETCH'], true),
};

export const confirmDeleteReviewLink = createAction(
  PROJECT_LINKS.CONFIRM_DELETE,
  (id) => ({ id })
);
export const deleteReviewLink = createAction(
  PROJECT_LINKS.DELETE.BASE,
  (id) => ({ id })
);
export const duplicateReviewLink = createAction(
  PROJECT_LINKS.DUPLICATE.BASE,
  (id) => ({ id })
);
export const editReviewLinkContent = createAction(
  PROJECT_LINKS.EDIT_CONTENT,
  (id) => ({ id })
);
export const editReviewLinkSettings = createAction(
  PROJECT_LINKS.EDIT_SETTINGS,
  (id) => ({ id })
);
export const patchSort = createAction(PROJECT_LINKS.PATCH_SORT, () => ({}));
export const patchToggleIsActive = createAction(
  PROJECT_LINKS.PATCH_TOGGLE_IS_ACTIVE,
  (id, isActive) => ({ id, isActive })
);

export const copyShortUrlToClipboard = createAction(
  PROJECT_LINKS.COPY_SHORT_URL,
  (url) => ({ url })
);

export const fetchLinks = createAction(
  PROJECT_LINKS.FETCH.BASE,
  (projectId, page) => ({ projectId, page })
);

export const resetLinks = createAction(
  PROJECT_LINKS.FETCH.RESET,
  (preserveMetadata) => ({ preserveMetadata })
);

export const sortLinks = createAction(
  PROJECT_LINKS.SORT,
  (option, isDescending) => ({ option, isDescending })
);
