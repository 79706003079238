import React, { memo } from 'react';
import styled from 'styled-components';
import City from '@frameio/components/src/svgs/icons/24/building.svg';
import Container from '../shared/Container';
import Input from '../shared/Input';

const IconContainer = styled.div`
  position: absolute;
  height: ${(p) => p.theme.spacing.medium};
  left: 6px;
  top: 9px;
  color: ${(p) => p.theme.color.slateGray};
`;

const CityInput = ({ error, handleBlur, handleChange, touched }) => {
  return (
    <Container marginBottom>
      <IconContainer>
        <City />
      </IconContainer>
      <Input
        error={!!touched && !!error}
        name="city"
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="City"
      />
    </Container>
  );
};

export default memo(CityInput);
