import React from 'react';
import { get } from 'lodash';
import { call, put, select } from 'redux-saga/effects';
import { updateStripeCustomer as updateStripeCustomerCoreSaga } from '@frameio/core/src/subscriptions/sagas';
import { isTaxLocationDeterminedSelector } from 'selectors/accounts';
import { setPaymentError } from 'components/PaymentFlow/actions';
import { clearSavedAddress } from './actions';
import ContactSupport from './components/ContactSupport';
import { billingAddressForAccountSelector } from './selectors';

/**
 * When `taxLocationDetermined: false`, the API will return a `200` since Stripe
 * was able to update the billing address attached to the Stripe customer's
 * profile. In that case, we still want to display an error message to the User
 * asking them to enter a valid billing address hence the 422 code.
 */
const TAX_LOCATION_NOT_DETERMINED_ERROR_CODE = '422';

export const ERROR_MESSAGES = {
  '400': <ContactSupport />,
  '402':
    'Invalid payment info. Please double check your card info before trying again.',
  '403': <ContactSupport />,
  '422':
    'Invalid billing address. Please double check your billing address before trying again.',
  '500': <ContactSupport />,
  GENERIC: <ContactSupport />,
};

function* updateStripeCustomer(accountId, stripeToken) {
  yield put(setPaymentError(null));
  const billingAddress = yield select(billingAddressForAccountSelector);
  const params = {
    ...billingAddress,
    source: stripeToken,
  };
  const { failure, success } = yield call(
    updateStripeCustomerCoreSaga,
    accountId,
    params
  );
  const taxLocationDetermined = yield select(isTaxLocationDeterminedSelector, {
    accountId,
  });

  if (failure) {
    const errorCode = get(failure, 'payload.error.response.status');
    const errorMessage = ERROR_MESSAGES[errorCode] || ERROR_MESSAGES.GENERIC;
    yield put(setPaymentError(errorMessage));
    return { failure };
  }

  if (success && !taxLocationDetermined) {
    yield put(
      setPaymentError(ERROR_MESSAGES[TAX_LOCATION_NOT_DETERMINED_ERROR_CODE])
    );
    return { failure: true };
  }
  yield put(setPaymentError(null));
  yield put(clearSavedAddress());

  return { success };
}

export default updateStripeCustomer;
