/*eslint-disable*/
import { connect } from 'react-redux';
import { canCloseModalSelector } from 'components/Modal/selectors';
import { isFreePlanWithUserMaxSelectedSelector } from 'components/SelectPlanFlow/selectors';
import PaymentFlow from './PaymentFlow';
import { startFlow, continueFlow, endFlow, setPaymentError } from './actions';

function mapStateToProps(state) {
  return {
    canCloseModal: canCloseModalSelector(state),
    isFreePlanWithUserMaxSelected: isFreePlanWithUserMaxSelectedSelector(state),
  };
}

const mapDispatchToProps = {
  startFlow,
  endFlow,
  continueFlow,
  setPaymentError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentFlow);

export const testExports = {
  mapDispatchToProps,
};
