import { status } from '@frameio/core/src/shared/reducers/factories';
import { USER_SEARCH } from './actions';

export const INITIAL_STATE = {
  userSearch: {
    ids: [],
    fetchStatus: null,
  },
  pendingReviewerSearch: {
    ids: [],
    fetchStatus: null,
  },
  teamSearch: {
    ids: [],
    fetchStatus: null,
  },
  groupSearch: {
    ids: [],
    fetchStatus: null,
  },
};

function userSearchReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_SEARCH.SEARCH_USER.PENDING: {
      return {
        ...state,
        userSearch: { ...state.userSearch, fetchStatus: status.PENDING },
      };
    }

    case USER_SEARCH.SEARCH_USER.FAILURE: {
      return {
        ...state,
        userSearch: {
          ...INITIAL_STATE.userSearch,
          fetchStatus: status.FAILURE,
        },
      };
    }

    case USER_SEARCH.SEARCH_USER.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        userSearch: {
          ids: response.result || [],
          fetchStatus: status.SUCCESS,
        },
      };
    }

    case USER_SEARCH.SEARCH_PENDING_REVIEWER.PENDING: {
      return {
        ...state,
        pendingReviewerSearch: {
          ...state.pendingReviewerSearch,
          fetchStatus: status.PENDING,
        },
      };
    }

    case USER_SEARCH.SEARCH_PENDING_REVIEWER.FAILURE: {
      return {
        ...state,
        pendingReviewerSearch: {
          ...INITIAL_STATE.pendingReviewerSearch,
          fetchStatus: status.FAILURE,
        },
      };
    }

    case USER_SEARCH.SEARCH_PENDING_REVIEWER.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        pendingReviewerSearch: {
          ids: response.result || [],
          fetchStatus: status.SUCCESS,
        },
      };
    }

    case USER_SEARCH.SEARCH_TEAM.PENDING: {
      return {
        ...state,
        teamSearch: {
          ...state.teamSearch,
          fetchStatus: status.PENDING,
        },
      };
    }

    case USER_SEARCH.SEARCH_TEAM.FAILURE: {
      return {
        ...state,
        teamSearch: {
          ...INITIAL_STATE.teamSearch,
          fetchStatus: status.FAILURE,
        },
      };
    }

    case USER_SEARCH.SEARCH_TEAM.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        teamSearch: {
          ids: response.result || [],
          fetchStatus: status.SUCCESS,
        },
      };
    }

    case USER_SEARCH.SEARCH_GROUP.PENDING: {
      return {
        ...state,
        groupSearch: {
          ...state.groupSearch,
          fetchStatus: status.PENDING,
        },
      };
    }

    case USER_SEARCH.SEARCH_GROUP.FAILURE: {
      return {
        ...state,
        groupSearch: {
          ...INITIAL_STATE.groupSearch,
          fetchStatus: status.FAILURE,
        },
      };
    }

    case USER_SEARCH.SEARCH_GROUP.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        groupSearch: {
          ids: response.result || [],
          fetchStatus: status.SUCCESS,
        },
      };
    }

    case USER_SEARCH.RESET: {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }

    default:
      return state;
  }
}

export default userSearchReducer;
export const testExports = {
  INITIAL_STATE,
};
