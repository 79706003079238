import React from 'react';
import PropTypes from 'prop-types';
import Badge from '@frameio/components/src/styled-components/Badge';
import styled from 'styled-components';
import { noop } from 'lodash';

export const BadgeButton = styled(Badge)`
  pointer-events: auto;
`;

const TextContext = styled.span`
  color: ${(p) => p.theme.color.graphiteGray};
`;

export default function VersionBadge({ versions, assetId, onClick, ...rest }) {
  if (!versions) {
    return null;
  }

  return (
    <BadgeButton
      {...rest}
      onClick={(e) => {
        e.preventDefault();
        onClick(assetId);
      }}
    >
      <TextContext>v</TextContext>
      {versions}
    </BadgeButton>
  );
}

VersionBadge.defaultProps = {
  assetId: null,
  versions: 0,
  onClick: noop,
};

VersionBadge.propTypes = {
  assetId: PropTypes.string,
  versions: PropTypes.number,
  onClick: PropTypes.func,
};
