import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { buttonReset } from '@frameio/components/src/mixins';
import { trackButtonClick } from 'analytics';

const Button = styled.button`
  ${buttonReset()}
  padding: ${(p) => p.theme.spacing.micro};
  color: ${(p) => p.theme.color.progress};
`;

export default function OpenDevTools({
  canPerformAction,
  openDevTools,
  children,
}) {
  if (!canPerformAction) return null;

  const onSelect = () => {
    openDevTools();
    trackButtonClick('open dev tools');
  };

  if (typeof children === 'function') {
    return children({ onSelect });
  }

  return <Button onClick={onSelect}>Open Dev Tools</Button>;
}

OpenDevTools.propTypes = {
  canPerformAction: PropTypes.bool.isRequired,
  openDevTools: PropTypes.func.isRequired,
};
