import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import track, { trackButtonClick } from 'analytics';
import Dialog, {
  dialogTypes,
} from '@frameio/components/src/styled-components/Dialog';
import Button from '@frameio/components/src/styled-components/Button';
import { ARCHIVED_PROJECTS_SUPPORT_URL } from 'URLs';
import UnarchiveProjectIllustration from './unarchive-project.svg';

const IllustrationContainer = styled(Flex)`
  padding: ${({ theme }) =>
    `${theme.spacing.large} 0 ${theme.spacing.small} 0`};
`;

const Header = styled(Flex)`
  font-size: ${(p) => p.theme.fontSize[3]};
  color: ${(p) => p.theme.color.coolBlack};
  margin: ${({ theme }) => `${theme.spacing.tiny} 0 ${theme.spacing.small} 0`};
`;

const CopyContainer = styled.div`
  color: ${(p) => p.theme.color.gray};
  text-align: center;
  padding: ${({ theme }) => `0 ${theme.spacing.tiny}`};
`;

const LearnMoreLink = styled.a`
  display: block;
  width: 100%;
  text-align: center;
  color: ${(p) => p.theme.color.brand};
`;

export default class UnarchiveProject extends React.Component {
  componentDidMount() {
    track('unarchive-project-modal-shown');
  }

  render() {
    const {
      onCancel,
      onContinue,
      projectName,
      isUpdatedArchivalMessagingEnabled,
    } = this.props;
    return (
      <Dialog
        type={dialogTypes.NONE}
        primaryButton={
          <Button
            primary
            onClick={() => {
              onContinue();
              track(
                'project-unarchive-started-client',
                'unarchive modal',
                'middle'
              );
              trackButtonClick('unarchive', 'unarchive modal', 'middle');
            }}
          >
            Unarchive
          </Button>
        }
        secondaryButton={
          <Button
            onClick={() => {
              onCancel();
              trackButtonClick('cancel', 'unarchive modal', 'middle');
            }}
          >
            Cancel
          </Button>
        }
      >
        <IllustrationContainer justifyCenter>
          <UnarchiveProjectIllustration />
        </IllustrationContainer>

        <Header justifyCenter>
          <strong>Unarchive project</strong>
        </Header>

        <CopyContainer>
          {isUpdatedArchivalMessagingEnabled ? (
            <p />
          ) : (
            <p>
              <strong>{projectName}</strong> will take approximately{' '}
              <strong>3 to 5 hours</strong> to be unarchived. You will be
              notified via in-app notification and via email when unarchiving is
              complete.
            </p>
          )}
          <LearnMoreLink
            href={ARCHIVED_PROJECTS_SUPPORT_URL}
            target="_blank"
            onClick={() =>
              trackButtonClick('learn more', 'unarchive modal', 'middle')
            }
          >
            Learn more about archiving
          </LearnMoreLink>
        </CopyContainer>
      </Dialog>
    );
  }
}

UnarchiveProject.propTypes = {
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
  projectName: PropTypes.string,
};

UnarchiveProject.defaultProps = {
  onCancel: noop,
  onContinue: noop,
  projectName: 'Your project',
};
