import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function range(left, right, inclusive) {
  const currentRange = [];
  const ascending = left < right;
  // const end = !inclusive ? right : ascending ? right + 1 : right - 1;
  let end;
  if (!inclusive) {
    end = right;
  } else if (ascending) {
    end = right + 1;
  } else {
    end = right - 1;
  }
  for (
    let i = left;
    ascending ? i < end : i > end;
    ascending ? (i += 1) : (i -= 1)
  ) {
    currentRange.push(i);
  }
  return currentRange;
}

function PaginatorDot(props) {
  const unSelectedClass = props.selected ? '' : 'paginator-dot-unselected';
  return (
    <div
      className="flex justify-center items-center paginator-dot-container"
      role="presentation"
      onClick={props.onClick}
    >
      <div className="w-100">
        <div
          className={`flex items-center br-100 fio-player-header-paginator-dot ${unSelectedClass}`}
        />
      </div>
    </div>
  );
}

function Paginator(props) {
  const { page, number, className, onClick } = props;
  return (
    <div className={className}>
      {range(1, number, true).map((idx) => (
        <PaginatorDot
          key={idx}
          idx={idx}
          selected={idx === page}
          onClick={() => onClick(idx)}
        />
      ))}
    </div>
  );
}

export default styled(Paginator)``;

PaginatorDot.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

Paginator.defaultProps = {
  className: '',
};

Paginator.propTypes = {
  number: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
