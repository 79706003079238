import { isFetchingReducerFactory } from 'factories/isFetching';
import { UPDATE_BILLING_INFO } from './actions';
import { STEPS } from './constants';

const isFetchingReducer = isFetchingReducerFactory(
  UPDATE_BILLING_INFO.IS_FETCHING
);

const DEFAULT_STATE = {
  step: STEPS.ONE,
  isOpen: false,
};

export default (state = DEFAULT_STATE, action) => {
  const { type } = action;
  switch (type) {
    case UPDATE_BILLING_INFO.IS_FETCHING:
      return isFetchingReducer(state, action);

    case UPDATE_BILLING_INFO.SET_STEP:
      return {
        ...state,
        step: action.payload.step,
      };

    case UPDATE_BILLING_INFO.RESET_FLOW:
      return DEFAULT_STATE;

    case UPDATE_BILLING_INFO.OPEN_MODAL:
      return {
        ...state,
        isOpen: true,
      };

    default:
      return state;
  }
};
