import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sessionWatermarkTemplateEntitySelector } from '@frameio/core/src/sessionWatermarkTemplate/selectors';
import { flow, partialRight } from 'lodash';
import { deleteSessionWatermarkTemplate } from 'pages/WatermarkTemplateEditor/actions';
import { Menu, MenuButton, MenuGroup, Tooltip } from '@frameio/vapor';
import PencilSVG from '@frameio/components/src/svgs/icons/pencil.svg';
import StarIcon from '@frameio/components/src/svgs/icons/star.svg';
import TextIcon from '@frameio/components/src/svgs/icons/16/text-without-shadow.svg';
import TrashCanIcon from '@frameio/components/src/svgs/icons/trashcan.svg';
import ApplySVG from '@frameio/components/src/svgs/icons/16/apply.svg';
import RevertSVG from '@frameio/components/src/svgs/icons/16/revert.svg';

export default function TemplateContextMenu({
  children,
  isDefaultTemplate,
  isDeleteButtonEnabled,
  isEndUserOverrideEnabled,
  isInternalPlaybackTemplate,
  isOpen,
  onRevert,
  openSessionWatermarkTemplateEditor,
  sessionWatermarkEntity,
  setTemplateAsDefault,
  setTemplateAsInternalPlaybackTemplate,
  showAdvancedAdminOptions,
  toggleMenu,
}) {
  const isShareOnly = Boolean(
    sessionWatermarkEntity.original_session_watermark_template_id
  );

  const dispatch = useDispatch();
  const onClickDelete = useCallback(
    () => dispatch(deleteSessionWatermarkTemplate(sessionWatermarkEntity.id)),
    [dispatch, sessionWatermarkEntity.id]
  );

  const isOriginalTemplateDeleted = useSelector(
    flow([
      partialRight(
        sessionWatermarkTemplateEntitySelector,
        sessionWatermarkEntity.original_session_watermark_template_id
      ),
      (entity) => (entity ? Boolean(entity.deleted_at) : true),
    ])
  );

  return (
    <Menu
      placement="right"
      offset={[28, 0]}
      shouldUsePortal
      isShowing={isOpen}
      onRequestChange={toggleMenu}
      content={
        <React.Fragment>
          <MenuGroup>
            <MenuButton
              onSelect={() =>
                openSessionWatermarkTemplateEditor(
                  sessionWatermarkEntity.id,
                  isShareOnly
                )
              }
              iconBefore={<PencilSVG width={14} height={14} />}
            >
              Edit template
            </MenuButton>

            {showAdvancedAdminOptions && (
              <React.Fragment>
                <MenuButton
                  disabled={
                    !setTemplateAsInternalPlaybackTemplate ||
                    isInternalPlaybackTemplate
                  }
                  onSelect={() =>
                    setTemplateAsInternalPlaybackTemplate(
                      sessionWatermarkEntity.id
                    )
                  }
                  iconBefore={<TextIcon width={14} height={14} />}
                >
                  Set as internal playback template
                </MenuButton>

                <MenuButton
                  disabled={isDefaultTemplate}
                  onSelect={() =>
                    setTemplateAsDefault(sessionWatermarkEntity.id)
                  }
                  iconBefore={<StarIcon width={14} height={14} />}
                >
                  Set as share default template
                </MenuButton>
              </React.Fragment>
            )}
            {isEndUserOverrideEnabled &&
              !isShareOnly &&
              !showAdvancedAdminOptions && (
                <MenuButton
                  onSelect={() =>
                    openSessionWatermarkTemplateEditor(
                      sessionWatermarkEntity.id,
                      true // open the editor to create a new share only template
                    )
                  }
                  iconBefore={<ApplySVG width={14} height={14} />}
                >
                  Modify and apply
                </MenuButton>
              )}
            {isEndUserOverrideEnabled &&
              isShareOnly &&
              !showAdvancedAdminOptions && (
                <React.Fragment>
                  <Tooltip
                    disabled={!isOriginalTemplateDeleted}
                    title="You cannot revert this template because the original has been deleted."
                    variant="dark"
                    placement="top"
                    shouldUsePortal
                  >
                    <div>
                      <MenuButton
                        disabled={isOriginalTemplateDeleted}
                        onSelect={() => onRevert(sessionWatermarkEntity)}
                        iconBefore={<RevertSVG width={16} height={16} />}
                      >
                        Revert to original
                      </MenuButton>
                    </div>
                  </Tooltip>
                </React.Fragment>
              )}
          </MenuGroup>
          {isDeleteButtonEnabled && (
            <React.Fragment>
              {!isShareOnly && (
                <MenuButton
                  disabled={isDefaultTemplate || isInternalPlaybackTemplate}
                  onSelect={onClickDelete}
                  destructive
                  iconBefore={<TrashCanIcon width={14} height={14} />}
                >
                  Delete
                </MenuButton>
              )}
              {isShareOnly && isOriginalTemplateDeleted && (
                <MenuButton
                  onSelect={() => onRevert(sessionWatermarkEntity)}
                  destructive
                  iconBefore={<TrashCanIcon width={14} height={14} />}
                >
                  Delete
                </MenuButton>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      }
    >
      {children}
    </Menu>
  );
}
