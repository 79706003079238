import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import Dialog, {
  dialogTypes,
} from '@frameio/components/src/styled-components/Dialog';
import Button from '@frameio/components/src/styled-components/Button';
import AccountOwnerPill from 'components/AccountOwnerPill';
import UploadLimitIllustration from '@frameio/components/src/svgs/illustrations/upload-limit.svg';

const UploadLimitIllustrationContainer = styled(Flex)`
  padding-top: ${(p) => p.theme.spacing.small};
`;

const Copy = styled.p`
  color: ${(p) => p.theme.color.gray};
  text-align: center;
  padding: 0 10px;
`;

const AccountOwnerCopyContainer = styled.div`
  margin-top: ${(p) => p.theme.spacing.small};
`;

const UserPillContainer = styled(Flex)`
  margin-top: ${(p) => p.theme.spacing.tiny};
`;

function renderAccountOwnerCopy(accountId) {
  return (
    <AccountOwnerCopyContainer>
      <Copy>Account owner</Copy>
      <UserPillContainer justifyCenter>
        <AccountOwnerPill accountId={accountId} />
      </UserPillContainer>
    </AccountOwnerCopyContainer>
  );
}

export default function OutOfUploads({
  isAccountAdmin,
  onCancel,
  onContinue,
  accountId,
  lifetimeFileLimit,
}) {
  return (
    <Dialog
      type={dialogTypes.NONE}
      header="Upgrade for unlimited uploads"
      primaryButton={
        <Button primary onClick={isAccountAdmin ? onContinue : onCancel}>
          {isAccountAdmin ? 'See our plans' : 'Got it'}
        </Button>
      }
      secondaryButton={
        isAccountAdmin && <Button onClick={onCancel}>No thanks</Button>
      }
    >
      <UploadLimitIllustrationContainer justifyCenter>
        <UploadLimitIllustration />
      </UploadLimitIllustrationContainer>

      <Copy>
        Your free plan includes {lifetimeFileLimit} uploads to get you started.
        Upgrade now for unlimited uploads.
      </Copy>
      {!isAccountAdmin && renderAccountOwnerCopy(accountId)}
    </Dialog>
  );
}

OutOfUploads.propTypes = {
  isAccountAdmin: PropTypes.bool,
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
  accountId: PropTypes.string,
  lifetimeFileLimit: PropTypes.number.isRequired,
};

OutOfUploads.defaultProps = {
  isAccountAdmin: null,
  onCancel: () => {},
  onContinue: () => {},
  accountId: '',
};
