import React from 'react';
import PropTypes from 'prop-types';
import { MenuButton } from '@frameio/vapor';
import FolderSVG from '@frameio/components/src/svgs/icons/16/folder2.svg';
import PrivateFolderSVG from '@frameio/components/src/svgs/icons/private-folder.svg';
import { trackButtonClick } from 'analytics';

export default function NewFolder({
  index,
  isPrivate,
  parentId,
  promptNewFolderName,
  trackingPage,
  trackingPosition,
  ...rest
}) {
  const FolderIcon = isPrivate ? PrivateFolderSVG : FolderSVG;
  return (
    <MenuButton
      onSelect={() => {
        promptNewFolderName(parentId, isPrivate, index);
        trackButtonClick(
          isPrivate ? 'new-private-folder' : 'new-folder',
          trackingPage,
          trackingPosition
        );
      }}
      iconBefore={<FolderIcon width={16} height={16} />}
      {...rest}
    >
      {isPrivate ? 'New private folder' : 'New folder'}
    </MenuButton>
  );
}

NewFolder.propTypes = {
  index: PropTypes.number.isRequired,
  isPrivate: PropTypes.bool,
  parentId: PropTypes.string.isRequired,
  promptNewFolderName: PropTypes.func.isRequired,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};

NewFolder.defaultProps = {
  isPrivate: false,
};
