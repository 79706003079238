import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import { updatedArchivalMessagingEnabled } from 'utils/featureFlags';
import UnarchiveProjectFlow from './UnarchiveProjectFlow';
import { startFlow, endFlow } from './actions';

const mapStateToProps = (state) => ({
  isUpdatedArchivalMessagingEnabled: updatedArchivalMessagingEnabled(state),
});

const mapDispatchToProps = {
  startFlow,
  endFlow,
  closeModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnarchiveProjectFlow);

export const testExports = {
  mapDispatchToProps,
};
