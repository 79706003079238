import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import videoConnect from 'react-html5video';
import { FadeTransition } from 'react-transition-components';
import { rgba } from 'polished';
import { noop, reverse } from 'lodash';
import transitionFactory, { opacity } from 'react-transition-factory';
import useHls from '@frameio/components/src/hooks/useHls';
import PlayButton from '@frameio/components/src/styled-components/PlayButton';
import Player360 from '@frameio/components/src/components/Player360';
import { alignCenter, absoluteFill } from '@frameio/components/src/mixins';
import PlayerInterface, {
  getStartTimecode,
  getTimeDisplayValue,
  setTimeDisplayValue,
  AUTO,
  Resolution,
} from '@frameio/components/src/components/PlayerSubcomponents/PlayerInterface';
import {
  formatResolutionOptions,
  getClosestMatchingAssetResolution,
  formatAllResolutionOptions,
  resolutionOptionToAssetTranscodeKeyName,
} from 'components/PlayerContainers/utils/videoProxyUtils';
import AssetThumb from 'components/AssetThumb';
import CompositeView from '@frameio/components/src/components/CompletePlayer/CompositeView';
import BufferingSpinner from '@frameio/components/src/components/PlayerSubcomponents/BufferingSpinner';
import FrameRateNotSupported from '@frameio/components/src/components/PlayerSubcomponents/FrameRateNotSupported';
import { color } from '@frameio/components/src/theme/darkTheme';
import track, { trackButtonClick } from 'analytics';
import { initMuxMonitor, monitorAsset } from 'components/mux/actions';
import store from 'configureStore';
import PlayerControlBar from 'components/PlayerControlBar';
import { TOOLTIP_TITLES } from 'components/PlayerControlBar/PlayerControlBar';
import SeekBar from 'components/PlayerControlBar/SeekBar';
import { shouldUseHlsHook } from 'utils/media';

export const AUTO_HIDE_DURATION_MS = 3000;
export const AUDIO_THUMB_SIZE = '100%';
export const AUDIO_THUMB_BG_COLOR = 'transparent';

const CONTROL_BAR_HEIGHT = '40px';
const PlayButtonContainer = styled.div`
  ${absoluteFill()}
  ${alignCenter()}
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  video::-webkit-media-controls-overlay-play-button {
    display: none;
  }
`;

// Adding a container around the CompositeView to create a
// clickable area and to prevent clicks from being triggered
// on mouseup when doing something like scrubbing the playbar.
const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const AudioAssetThumb = styled(AssetThumb)`
  position: absolute;
  top: 0;
  padding-bottom: ${CONTROL_BAR_HEIGHT}; // offset to visually center thumb
  background-color: ${({ theme }) => rgba(theme.color.black, 0.04)};

  svg {
    height: 60px;
    width: 60px;

    path {
      fill: ${({ accentColor }) => accentColor};
    }
  }
`;

export const Controls = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

/* eslint-disable jsx-a11y/media-has-caption */
const ControlsTransition = transitionFactory(opacity);

const TRACKING_PLAYER_TYPE = 'Presentation';

/**
 * Component to quickly preview a video.
 */
class InnerPresentationPlayer extends React.Component {
  /**
   * Presentation player constructor.
   * @param {Object} props - Props to render Presentation.
   */
  constructor(props) {
    super(props);
    this.state = {
      areControlsVisible: false,
      is360: false,
      timeDisplay: getTimeDisplayValue(),
    };
    this.videoRef = React.createRef();
    this.playerContainer = React.createRef();
    this.lastPlaybackTime = null;
    this.wasPlaying = false;
  }

  /**
   * React hook fired after DOM has mounted.
   */
  componentDidMount() {
    const {
      autoPlay,
      accountId,
      asset,
      isSessionWatermarked,
      resolution,
      setHlsMediaElement,
      shouldAutoLoad,
      trackingPage,
    } = this.props;

    const assetId = asset?.id;
    const isHlsRequired = shouldUseHlsHook(asset);
    const matchingResolution = getClosestMatchingAssetResolution(
      resolution,
      asset
    );
    const initialEncodingVariant = resolution
      ? resolutionOptionToAssetTranscodeKeyName[matchingResolution]
      : undefined;

    if (setHlsMediaElement) {
      setHlsMediaElement(this.videoRef.current);
    }

    store.dispatch(
      initMuxMonitor({
        autoplay: autoPlay,
        accountId,
        assetId,
        playerName: 'Presentation',
        isSessionWatermarked,
        trackingPage,
        videoEl: this.videoRef.current,
        initialEncodingVariant,
      })
    );

    this.shouldManuallyStartHlsLoad =
      (isHlsRequired || isSessionWatermarked) && !shouldAutoLoad;

    // force update to component rerenders with refs available.
    this.forceUpdate();
  }

  /**
   *
   * @param {Object} prevProps - Previous props.
   */
  componentDidUpdate(prevProps) {
    const {
      asset: { id: assetId },
      fwmFrameRateSupported,
      src,
      resolution,
      setCurrentTime,
      isPlayerPaused,
      isActive,
      isFullscreen,
      syncPlayPause,
      media,
      hlsInstance,
    } = this.props;

    // Close fullscreen if forensically watermarked asset has an unsupported frame rate
    // and that asset is currently opened in fullscreen
    if (
      isFullscreen &&
      prevProps.fwmFrameRateSupported &&
      !fwmFrameRateSupported
    ) {
      document.fullscreenElement === this.videoRef.current &&
        document.exitFullscreen();
    }

    // Set video to lastPlaybackTime time if it exists.
    if (this.videoRef.current && prevProps.src !== src) {
      if (!media.paused) {
        this.wasPlaying = true;
      }
      this.lastPlaybackTime = media.currentTime;
      this.videoRef.current.load();
      setCurrentTime(this.lastPlaybackTime);

      store.dispatch(monitorAsset(this.videoRef.current, assetId));
    }

    // We wait for the video ready state to be 4 (is playable) before triggering playback.
    if (
      this.videoRef &&
      this.lastPlaybackTime &&
      media.readyState === 4 &&
      this.wasPlaying
    ) {
      // Let time set, then start playing.
      setTimeout(() => this.videoRef.current.play(), 0);
      this.wasPlaying = false;
    }

    // issue with React not reloading media with new src strings
    // see https://github.com/facebook/react/issues/9447;
    if (this.videoRef && prevProps.resolution !== resolution && !hlsInstance) {
      /*
       * BUGS-1329 changing resolution in safari presentation player causes video to start over
       * Safari ignores our setCurrentTime just after load.
       * In fact, safari ignores setCurrentTime if we set it on the `loadeddata` event
       * We have to wait until the `canplay` event to seek to the current time in the new resolution.
       */
      const onCanPlay = () => {
        setCurrentTime(this.lastPlaybackTime);
        syncPlayPause(this.videoRef.current.paused);

        this.videoRef.current.removeEventListener('canplay', onCanPlay);
      };
      this.videoRef.current.addEventListener('canplay', onCanPlay);
      /*
       * end BUGS-1329
       */

      this.videoRef.current.load();

      // Set previous seek time for new resolution
      setCurrentTime(this.lastPlaybackTime);
    }

    if (this.videoRef && !isActive) this.videoRef.current.pause();

    // for allowing play/pause by pressing spacebar on active video
    if (isActive) {
      if (media.paused !== prevProps.media.paused) {
        // sync media state coming from react-html5video with parent state
        // when media state changes
        syncPlayPause(media.paused);
      } else if (!isPlayerPaused) {
        if (hlsInstance && this.shouldManuallyStartHlsLoad) {
          hlsInstance.startLoad();
          this.shouldManuallyStartHlsLoad = false;
        }
        const playPromise = this.videoRef.current.paused
          ? this.videoRef.current.play()
          : null;

        // Catch and silence thrown errors, likely when interrupted by `load()`.
        //
        // See the following article for details:
        // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
        if (playPromise) {
          playPromise.catch(noop);
        }
      } else {
        this.videoRef.current.pause();
      }
    }
  }

  onContextMenu = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
  };

  /**
   * Ensures the controls are always visible when hovered on.
   */
  onControlBarMouseEnter = () => {
    this.setState({
      areControlsHovered: true,
      areControlsVisible: true,
    });
  };

  onControlBarMouseLeave = () => {
    this.setState({ areControlsHovered: false });
  };

  onDefaultPlaybackRateChange = (rate) => {
    const { setPlaybackRate, type } = this.props;
    // Set the video element's playback rate
    setPlaybackRate(rate);
    // track the playback update:
    track('default-playback-speed-updated', {
      default_playback_speed: rate,
      player_type: TRACKING_PLAYER_TYPE,
      media_type: type === 'audio' ? `Audio` : `Video`,
    });
  };

  showControls = () => {
    if (!this.state.areControlsVisible)
      this.setState({ areControlsVisible: true });
    clearTimeout(this.timer);
    if (!this.state.areControlsHovered) {
      this.timer = setTimeout(() => {
        this.hideControls();
      }, AUTO_HIDE_DURATION_MS);
    }
  };

  setTimeDisplay = (timeDisplay) => {
    setTimeDisplayValue(timeDisplay); // set timeDisplay value in local storage
    this.setState({ timeDisplay });
    trackButtonClick(`opened ${timeDisplay}`, 'player', 'player controls');
  };

  /*
   * Handle mouse leave.
   */
  hideControls = () => {
    this.setState({ areControlsVisible: false });
  };

  /**
   * Render the Presentation component.
   * @returns {ReactElement} - Presentation component.
   */
  render() {
    const {
      accentColor,
      amountBuffered,
      asset,
      assetLayout,
      autoPlay,
      canPlay360,
      currentTime,
      fwmFrameRateSupported,
      id,
      isForensicallyWatermarked,
      isPlayerPaused,
      isSessionWatermarked,
      didMediaLoad,
      media,
      onEnded,
      onPlayButtonClick,
      poster,
      preload,
      selectedViewType,
      setCurrentTime,
      src,
      style,
      syncPlayPause,
      trackingPage,
      type,
    } = this.props;
    const { is360 } = this.state;
    const isGeneratingForensicWatermark =
      !didMediaLoad && isForensicallyWatermarked;
    const isGeneratingSessionWatermark = !didMediaLoad && isSessionWatermarked;
    const isPlayButtonVisible =
      isPlayerPaused && !currentTime > 0 && didMediaLoad;
    const isAudioPlayer = type === 'audio';

    const seekProps = {
      accentColor,
      onChange: (value) => setCurrentTime(value * media.duration),
      amountPlayed: currentTime / media.duration,
      amountBuffered,
      knobIsVisible: isPlayerPaused,
    };

    const controls = (
      <Controls
        onMouseEnter={this.onControlBarMouseEnter}
        onMouseLeave={this.onControlBarMouseLeave}
      >
        {this.renderSeekBar(seekProps)}
        {this.renderPlayerControlBar()}
      </Controls>
    );

    if (isAudioPlayer) {
      return (
        <Wrapper>
          <AudioAssetThumb
            asset={asset}
            accentColor={accentColor}
            height={AUDIO_THUMB_SIZE}
            width={AUDIO_THUMB_SIZE}
            backgroundColor={AUDIO_THUMB_BG_COLOR}
          />
          <video
            autoPlay={autoPlay}
            controlsList="nodownload"
            data-test-id="audio"
            height="100%"
            id={id}
            onClick={() => {
              trackButtonClick('play pause', trackingPage, 'player controls');
              syncPlayPause(!media.paused);
            }}
            onContextMenu={this.onContextMenu}
            onEnded={onEnded}
            playsInline
            poster={null}
            preload={preload}
            ref={this.videoRef}
            src={src}
            width="100%"
          />
          {controls}
        </Wrapper>
      );
    }

    return (
      <Wrapper
        role="presentation"
        data-test-id="video"
        onMouseEnter={this.showControls}
        onMouseLeave={this.hideControls}
        onMouseMove={this.showControls}
        style={style}
        ref={this.playerContainer}
        onContextMenu={this.onContextMenu}
      >
        <VideoContainer
          onClick={() => {
            trackButtonClick('play pause', trackingPage, 'player controls');
            syncPlayPause(!media.paused);
          }}
        >
          <CompositeView
            selectedViewType={selectedViewType}
            assetLayout={assetLayout}
          >
            <video
              autoPlay={autoPlay}
              controls={document.fullscreenElement != null}
              controlsList="nodownload"
              height="100%"
              id={id}
              onContextMenu={this.onContextMenu}
              onEnded={onEnded}
              playsInline
              poster={poster}
              preload={preload}
              ref={this.videoRef}
              src={src}
              width="100%"
            />
          </CompositeView>
          {this.videoRef && this.playerContainer && is360 && canPlay360 && (
            <div className="absolute absolute--fill">
              <Player360
                videoEl={this.videoRef}
                srcHeight={this.playerContainer.current.offsetHeight}
                srcWidth={this.playerContainer.current.offsetWidth}
              />
            </div>
          )}
          {fwmFrameRateSupported ? (
            <BufferingSpinner
              isShare
              isGeneratingSessionWatermark={isGeneratingSessionWatermark}
              isGeneratingForensicWatermark={isGeneratingForensicWatermark}
              paused={media.paused}
              readyState={media.readyState}
            />
          ) : (
            <FrameRateNotSupported />
          )}
        </VideoContainer>
        <FadeTransition in={isPlayButtonVisible}>
          <PlayButtonContainer
            onContextMenu={this.onContextMenu}
            onClick={onPlayButtonClick}
          >
            <PlayButton
              isVisible={isPlayButtonVisible}
              accentColor={accentColor}
              onClick={onPlayButtonClick}
            />
          </PlayButtonContainer>
        </FadeTransition>
        <ControlsTransition
          timeout={100}
          mountOnEnter={false}
          unmountOnExit={false}
          in={this.state.areControlsVisible}
        >
          {controls}
        </ControlsTransition>
      </Wrapper>
    );
  }

  renderSeekBar = (seekProps) => {
    const { media } = this.props;

    return (
      <SeekBar media={media} mediaEl={this.videoRef.current} {...seekProps} />
    );
  };

  renderPlayerControlBar = () => {
    const {
      asset,
      canUse4KPlayback,
      effectivePlaybackRate,
      fwmFrameRateSupported,
      getIsInFullscreen,
      hlsInstance,
      media,
      mute,
      onViewTypeChange,
      resolution,
      selectResolution,
      selectedViewType,
      setVolume,
      syncPlayPause,
      toggleFullscreen,
      toggleLoop,
      type,
      unmute,
    } = this.props;

    const { timeDisplay } = this.state;
    const isHlsRequired = shouldUseHlsHook(asset);

    const isAudioPlayer = type === 'audio';

    const customPlaybackProps = {
      defaultPlaybackRate: isFinite(effectivePlaybackRate)
        ? effectivePlaybackRate
        : 1,
      effectivePlaybackRate,
      onDefaultPlaybackRateChange: this.onDefaultPlaybackRateChange,
    };

    const fullScreenProps = isAudioPlayer
      ? undefined
      : {
          toggleFullscreen,
          getIsInFullscreen,
        };

    const loopProps = {
      toggleLoop,
    };

    const playPauseProps = {
      togglePlaying: () => syncPlayPause(!media.paused),
    };

    const resolutionPickerProps = isAudioPlayer
      ? undefined
      : {
          selectedResolution: getClosestMatchingAssetResolution(
            resolution,
            asset
          ),
          selectResolution,
          assetResolutions: formatAllResolutionOptions(asset, false),
          isHlsPlayerEnabled: isHlsRequired,
        };

    const timeDisplayProps = {
      currentTimeDisplayType: timeDisplay,
      frameRate: asset.fps,
      isHlsPlayerEnabled: isHlsRequired,
      setTimeDisplay: this.setTimeDisplay,
      startTimecode: getStartTimecode(asset),
      totalFrames: asset.frames,
      audioCodec: asset.metadata?.blob?.audio_codec,
    };

    const tooltips = {
      ...TOOLTIP_TITLES,
      PLAYING: 'Pause',
      PAUSED: 'Play',
      FULLSCREEN: 'Fullscreen',
      TURNSTYLE: 'Turnstyle Orientation',
    };

    const turnStyleProps = {
      assetLayout: asset.layout,
      onViewTypeChange,
      selectedViewType,
    };

    const volumeProps = {
      changeVolume: setVolume,
      mute,
      unmute,
    };

    return (
      <PlayerControlBar
        canUse4KPlayback={canUse4KPlayback}
        customPlayback={customPlaybackProps}
        fwmFrameRateSupported={fwmFrameRateSupported}
        fullScreen={fullScreenProps}
        hlsInstance={hlsInstance}
        loop={loopProps}
        media={media}
        mediaEl={this.videoRef.current}
        playPause={playPauseProps}
        resolutionPicker={resolutionPickerProps}
        timeDisplay={timeDisplayProps}
        toggleLoop={toggleLoop}
        tooltips={tooltips}
        turnStyle={turnStyleProps}
        volume={volumeProps}
        trackingPlayerType={TRACKING_PLAYER_TYPE}
      />
    );
  };
}

/**
 * Take video state and add additional props.
 * @param {Object} videoState - Current video state.
 * @returns {Object} - New video props.
 */
const mapStateToProps = (mediaState) => ({
  media: {
    ...mediaState,
  },
});

/**
 * @param {Element} videoEl - HTML5 Video element.
 * @returns {Object} - New video props.
 */
const playerInterface = new PlayerInterface();
const mapVideoElToProps = (videoEl) => {
  playerInterface.setMediaEl(videoEl);
  return {
    amountBuffered: playerInterface.getAmountBuffered(),
    effectivePlaybackRate: playerInterface.getPlaybackRate(),
    getIsInFullscreen: playerInterface.isInFullscreen,
    mute: playerInterface.mute,
    setCurrentTime: playerInterface.setCurrentTime,
    setPlaybackRate: playerInterface.setPlaybackRate,
    setTimeDisplay: playerInterface.setTimeDisplay,
    setVolume: playerInterface.setVolume,
    toggleFullscreen: playerInterface.toggleFullscreen,
    togglePause: playerInterface.togglePause,
    toggleLoop: playerInterface.toggleLooping,
    unmute: playerInterface.unmute,
  };
};

InnerPresentationPlayer.defaultProps = {
  accentColor: color.brand,
  amountBuffered: null,
  asset: undefined,
  assetLayout: null,
  canPlay360: true,
  currentTime: 0,
  didMediaLoad: true,
  isActive: false,
  isPlayerPaused: true,
  onPlayButtonClick: () => {},
  onViewTypeChange: () => {},
  poster: '',
  resolution: '720p',
  selectedViewType: null,
  selectResolution: () => {},
  setPlaybackRate: () => {},
  src: null,
  style: undefined,
  syncPlayPause: () => {},
  toggleFullscreen: undefined,
  type: 'video',
};

InnerPresentationPlayer.propTypes = {
  accountId: PropTypes.string.isRequired,
  accentColor: PropTypes.string,
  amountBuffered: PropTypes.number,
  asset: PropTypes.object,
  assetLayout: PropTypes.object,
  canPlay360: PropTypes.bool,
  currentTime: PropTypes.number,
  hlsInstance: PropTypes.object,
  isActive: PropTypes.bool,
  isForensicallyWatermarked: PropTypes.bool,
  getIsInFullscreen: PropTypes.func,
  isPlayerPaused: PropTypes.bool,
  isSessionWatermarked: PropTypes.bool,
  media: PropTypes.shape({
    paused: PropTypes.bool,
    duration: PropTypes.number,
    currentTime: PropTypes.number,
    readyState: PropTypes.number,
  }).isRequired,
  onPlayButtonClick: PropTypes.func,
  onViewTypeChange: PropTypes.func,
  poster: PropTypes.string,
  renderPlayerControlBar: PropTypes.func,
  resolution: PropTypes.string,
  selectedViewType: PropTypes.string,
  selectResolution: PropTypes.func,
  setCurrentTime: PropTypes.func.isRequired,
  setHlsMediaElement: PropTypes.func,
  setPlaybackRate: PropTypes.func,
  setVolume: PropTypes.func.isRequired,
  src: PropTypes.string,
  style: PropTypes.object,
  syncPlayPause: PropTypes.func,
  toggleFullscreen: PropTypes.func,
  toggleLoop: PropTypes.func,
  togglePause: PropTypes.func.isRequired,
  trackingPage: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['audio', 'video']),
};

const PresentationPlayer = (props) => {
  const {
    asset,
    autoPlay,
    handleError,
    isForensicallyWatermarked,
    isSessionWatermarked,
    poster,
    resolution,
    shouldAutoLoad: autoStartLoad,
    src,
  } = props;
  const { id: assetId, hls_manifest: hlsManifest, drm: drmInfo } = asset;

  const isHlsRequired = shouldUseHlsHook(asset);

  const isHlsPlayerRequired = isHlsRequired || isSessionWatermarked;
  const effectiveHlsManifest = isHlsPlayerRequired ? hlsManifest : null;
  /**
   * @note: Same comment from web-components CompletePlayerView
   * creates a list of resolutions that *should* map directly to the hls manifest levels,
   * allowing us to pass a startLevel to the hls.js config so that it will start with the
   * resolution that is selected
   * - does not include the Original option
   * - is in the order of lowest -> highest quality
   *
   * example: [{resolution: '360p'}, {resolution: '540p'}, {resolution: '720p'}]
   */
  const assetResolutions = formatResolutionOptions(asset);
  const resolutionsLowToHighQuality = reverse(
    assetResolutions.filter((each) => each.resolution !== Resolution.RORIG)
  );

  const { didMediaLoad, hlsInstance, setHlsMediaElement } = useHls(
    effectiveHlsManifest,
    {
      handleError,
      isForensicallyWatermarked,
      defaultHlsConfig: {
        autoStartLoad,
        startLevel:
          resolution === AUTO
            ? -1
            : resolutionsLowToHighQuality.findIndex(
                (each) => each.resolution === resolution
              ),
      },
      resolution,
      shouldPlayOnLoad: autoPlay,
      assetId,
      shouldForceReload: true,
      drmInfo,
    }
  );

  return (
    <InnerPresentationPlayer
      {...props}
      didMediaLoad={didMediaLoad || !effectiveHlsManifest}
      hlsInstance={hlsInstance}
      isHlsPlayerEnabled={isHlsRequired}
      poster={isHlsPlayerRequired ? null : poster}
      setHlsMediaElement={setHlsMediaElement}
      src={isHlsPlayerRequired ? null : src}
    />
  );
};

export default videoConnect(
  PresentationPlayer,
  mapStateToProps,
  mapVideoElToProps
);

export const testExports = {
  InnerPresentationPlayer,
  VideoContainer,
  ControlsTransition,
  Wrapper,
};
