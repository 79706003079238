/**
 * Make the browser download the video first, so that it's (hopefully) available
 * for render by the time `ArchiveProjectFlow` is mounted.
 * https://developers.google.com/web/fundamentals/media/fast-playback-with-video-preload#preload_full_video
 */
export default function preloadVideoFile(videoFile) {
  const link = document.createElement('link');
  link.as = 'video';
  link.rel = 'preload';
  link.href = videoFile;
}
