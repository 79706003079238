import { connect } from 'react-redux';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import { copyAssetsToProject } from 'actions/assets';
import ListProjectRowDropTarget from './ListProjectRowDropTarget';
import { hide as hideSidebar } from '../actions';

const mapStateToProps = (state, { projectId }) => {
  const { archived_at, name, private: isPrivate, deleted_at: deletedAt } =
    projectEntitySelector(state, { projectId }) || {};

  return {
    deletedAt,
    isPrivate,
    isProjectArchived: !!archived_at,
    name,
  };
};

const mapDispatchToProps = {
  copyAssetsToProject,
  onClick: hideSidebar,
};

export const testExports = {
  mapDispatchToProps,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListProjectRowDropTarget);
