import React from 'react';
import PropTypes from 'prop-types';
import { MenuSeparator } from '@frameio/vapor';
import AdobeIntegrationMenuItem from 'components/AssetActions/AdobeIntegration/AdobeIntegrationMenuItem';
import {
  CompareAssets,
  CompareVersions,
  CopyLinkAddress,
  CopyAssetsToFolder,
  CreateDualView,
  CustomAction,
  DeleteAssets,
  DownloadAssets,
  DuplicateAssetsToFolder,
  ManageVersionStackInModal,
  MoveAssetsToFolder,
  MoveAssetsToDestinationFolder as MoveToParentFolder,
  PublishAssetsToDropbox,
  PublishAssetToVimeo,
  RenameAsset,
  ResetLifecycle,
  SetAssetsWorkflowStatus,
  ShareAssets,
  ToggleAssetsPrivacy,
} from 'components/AssetActions';
import { assetPermissionsPropTypes } from 'selectors/permissions';
import {
  getConnectMenuItems,
  getWorkfrontManageTaskMenuItems,
} from 'components/AssetActions/AdobeIntegration/utils';
import { AEM } from 'components/AssetActions/AdobeIntegration/integrationConstants';

function AssetContextMenu({
  assetIds,
  assetPublishingEnabled,
  customActionIds,
  dualViewEnabled,
  folderId,
  integration,
  isAemIntegrationEnabled,
  isPresentationsDisabled,
  isTeamWorkfrontEnabled,
  isWorkfrontConnected,
  openModal,
  parentFolderId,
  permissions,
  projectId,
  submit,
  trackingPage,
  trackingPosition,
  updateWorkfrontTask,
  workfrontTaskInterface,
}) {
  const hasNoPermissions = Object.values(permissions).every(
    (permission) => !permission
  );
  if (hasNoPermissions) {
    return null;
  }

  const {
    canAccessAemIntegration,
    canConnectToAdobeIntegration,
    canCreateDualView,
    canDelete,
    canDownload,
    canHaveCustomActions,
    canMoveToFolder,
    canPublishToDropbox,
    canPublishToVimeo,
    canRename,
    canSetStatus,
    canSharePresentation: canSharePresentationPermissions,
    canShareReview,
    canTogglePrivacy,
    canManageVersions,
    canResetAssetLifecycle,
    canUpdateWorkfrontConnectedTask,
  } = permissions;

  const canSharePresentation =
    canSharePresentationPermissions && !isPresentationsDisabled;
  const canShare = canSharePresentation || canShareReview;

  const showCopyLink = dualViewEnabled && assetIds.length === 1;

  const showBottomSeparator =
    (canDelete || canManageVersions) &&
    (canSetStatus || canMoveToFolder || canTogglePrivacy || canDownload);

  const canConnectAssetToWorkfront =
    canConnectToAdobeIntegration && isTeamWorkfrontEnabled;

  const onAddToWorkfrontProjectSelect = () =>
    submit({ projectId, assetIds, integration });
  const onOpenModalSelect = () =>
    openModal({ projectId, assetIds, integration });

  const menuItems =
    canUpdateWorkfrontConnectedTask || isWorkfrontConnected
      ? getWorkfrontManageTaskMenuItems(
          projectId,
          assetIds[0],
          integration,
          updateWorkfrontTask,
          workfrontTaskInterface
        )
      : getConnectMenuItems(
          integration,
          onOpenModalSelect,
          onAddToWorkfrontProjectSelect
        );

  const aemMenuItems = getConnectMenuItems(AEM, openModal);

  const hasCustomActions = canHaveCustomActions && customActionIds.length > 0;

  const showCreateDualView = dualViewEnabled && canCreateDualView;

  const trackingProps = {
    trackingPage,
    trackingPosition,
    source: 'asset_panel_popover_menu',
  };

  return (
    <React.Fragment>
      {assetIds?.length === 1 && (
        <CompareVersions assetId={assetIds[0]} tracking={trackingProps} />
      )}
      {assetIds?.length === 2 && (
        <CompareAssets assetIds={assetIds} tracking={trackingProps} />
      )}
      {canShare && (
        <ShareAssets
          assetIds={assetIds}
          projectId={projectId}
          folderId={folderId}
          canSharePresentation={canSharePresentation}
          canShareReview={canShareReview}
          {...trackingProps}
        />
      )}

      {canRename && <RenameAsset {...trackingProps} assetId={assetIds[0]} />}

      {assetPublishingEnabled && (
        <React.Fragment>
          {canPublishToVimeo && (
            <PublishAssetToVimeo assetId={assetIds[0]} {...trackingProps} />
          )}
          {canPublishToDropbox && (
            <PublishAssetsToDropbox assetIds={assetIds} {...trackingProps} />
          )}
        </React.Fragment>
      )}
      {/* TODO: PLATFORM-967 show/hide aem via user entitlements */}
      {isAemIntegrationEnabled && canAccessAemIntegration && (
        <AdobeIntegrationMenuItem
          assetIds={assetIds}
          canConnectAsset={isAemIntegrationEnabled}
          isTeamAdobeIntegrationEnabled={isAemIntegrationEnabled}
          menuItems={aemMenuItems}
          projectId={projectId}
        />
      )}

      {(canShare || canRename || canPublishToVimeo || canPublishToDropbox) && (
        <MenuSeparator />
      )}
      {isTeamWorkfrontEnabled && (
        <AdobeIntegrationMenuItem
          assetIds={assetIds}
          canConnectAsset={canConnectAssetToWorkfront}
          canUpdateWorkfrontConnectedTask={canUpdateWorkfrontConnectedTask}
          isTeamAdobeIntegrationEnabled={isTeamWorkfrontEnabled}
          isWorkfrontConnected={isWorkfrontConnected}
          menuItems={menuItems}
          projectId={projectId}
          workfrontTaskInterface={workfrontTaskInterface}
        />
      )}
      {!isWorkfrontConnected && canSetStatus && (
        <SetAssetsWorkflowStatus
          assetIds={assetIds}
          tracking={trackingProps}
          useLargeIndicator
        />
      )}

      {showCopyLink && (
        <CopyLinkAddress
          {...trackingProps}
          assetId={assetIds[0]}
          projectId={projectId}
        />
      )}
      {canMoveToFolder && parentFolderId && (
        <MoveToParentFolder
          {...trackingProps}
          destinationId={parentFolderId}
          assetIds={assetIds}
          title="Move Up One Folder"
          trackingTitle="move up one folder"
        />
      )}

      {canMoveToFolder && (
        <React.Fragment>
          <MoveAssetsToFolder
            assetIds={assetIds}
            folderId={folderId}
            {...trackingProps}
          />
          <CopyAssetsToFolder
            assetIds={assetIds}
            folderId={folderId}
            {...trackingProps}
          />
          <DuplicateAssetsToFolder
            assetIds={assetIds}
            folderId={folderId}
            {...trackingProps}
          />
        </React.Fragment>
      )}
      {canTogglePrivacy && (
        <ToggleAssetsPrivacy {...trackingProps} assetIds={assetIds} />
      )}
      {canDownload && (
        <DownloadAssets
          {...trackingProps}
          assetIds={assetIds}
          projectId={projectId}
        />
      )}

      {showBottomSeparator && <MenuSeparator />}

      {canManageVersions && (
        <ManageVersionStackInModal {...trackingProps} assetId={assetIds[0]} />
      )}

      {canResetAssetLifecycle && (
        <ResetLifecycle {...trackingProps} assetIds={assetIds} />
      )}
      {canDelete && <DeleteAssets {...trackingProps} assetIds={assetIds} />}
      {showCreateDualView && <MenuSeparator />}
      {showCreateDualView && (
        <CreateDualView assetIds={assetIds} {...trackingProps} />
      )}

      {hasCustomActions && <MenuSeparator />}
      {hasCustomActions &&
        customActionIds.map((customActionId) => (
          <CustomAction
            key={`customAction-${customActionId}`}
            assetId={assetIds[0]}
            customActionId={customActionId}
            {...trackingProps}
          />
        ))}
    </React.Fragment>
  );
}

AssetContextMenu.propTypes = {
  assetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  assetPublishingEnabled: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  folderId: PropTypes.string.isRequired,
  customActionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  isTeamWorkfrontEnabled: PropTypes.bool,
  isWorkfrontConnected: PropTypes.bool,
  permissions: PropTypes.shape(assetPermissionsPropTypes).isRequired,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};

function areEqual(prevProps, nextProps) {
  // Don't update our menu if an asset isn't actually
  // supplied as a prop.This allows us to have an unmounting
  // animation when the context menu is closed by clicking
  // on a non-asset.
  if (!nextProps.assetIds.length) {
    return true;
  }

  return false;
}

export default React.memo(AssetContextMenu, areEqual);
