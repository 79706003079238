import { isProduction } from 'config';

export const TAX_COLLECTION_DEADLINE = isProduction ? '040122' : '020322';

export const TAX_COLLECTION_PHASES = {
  ONE: 'PHASE_1',
  TWO: 'PHASE_2',
  THREE: 'PHASE_3',
};

export const STEPS = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
};
