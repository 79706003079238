import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const FOLDER = generateActionTypes('FOLDER', [
  'SET_CURRENT',
  'SET_ANCESTORS',
]);

/**
 * Sets state.currentFolder with the folder object.
 * @param {Object} folder - Folder object.
 */
export const setCurrentFolder = createAction(
  FOLDER.SET_CURRENT,
  (folderId, prevFolderId) => ({ folderId, prevFolderId })
);

/**
 * Sets state.folderAncestors with a given folder id and an array
 * of its ancestor ids.
 * @param {string} folderId - Folder id.
 * @param {string[]} ancestorIds - Array of a given folder asset's
 * ancestor ids.
 */
export const setFolderAncestors = createAction(
  FOLDER.SET_ANCESTORS,
  (folderId, ancestorIds) => ({ folderId, ancestorIds })
);
