import React from 'react';
import { get } from 'lodash';
import {
  takeEvery,
  call,
  put,
  select,
  take,
  race,
  takeLatest,
} from 'redux-saga/effects';
import queryString from 'query-string';
import history from 'browserHistory';
import { ASSET, patchAssets } from '@frameio/core/src/assets/actions';
import {
  type as assetType,
  status as assetStatus,
} from '@frameio/core/src/assets/helpers/constants';
import { createBundle } from '@frameio/core/src/assets/sagas';
import { error } from 'components/Dialog/SimpleDialog/sagas';
import { assetEntitiesByAssetIdsSelector } from '@frameio/core/src/assets/selectors';

import { pathnameSelector, searchQuerySelector } from 'selectors/router';
import {
  getPlaceholderAssetId,
  assetOrPlaceholderAssetSelector,
  indexToCreateAssetsSelector,
  projectIdSelector,
  folderIdSelector,
} from 'pages/ProjectContainer/selectors';
import {
  createAssetPlaceholders,
  setPlaceholderBundleAssetId,
  PROJECT_CONTAINER,
} from 'pages/ProjectContainer/actions';
import { currentUserEntitySelector } from 'selectors/users';
import { openModal, closeModal } from 'components/Modal/actions';

import MergeFailed from './MergeFailed';
import CreateDualView from './CreateDualView';
import { DUAL_VIEW_MODE } from './CreateDualView/CreateDualView';
import { DUAL_VIEW } from './actions';
import { validateAssetsAndGetErrors, generateName } from './helper';

/**
 * UPLOADS is just a map to get the bundleId by the fileId. Example:
 *
 * UPLOADS = {
 *    // this one has a temp placeholder id, which is replaced once we have the assetId
 *   'placeholder-1': "bundle-id-1",
 *   'asset-id-2': "bundle-id-1",
 * }
 */
const UPLOADS = {};

function* createBundlePlaceholder(name, parentId, indexInput) {
  let index = indexInput;
  if (!index) index = yield select(indexToCreateAssetsSelector);
  const creator = yield select(currentUserEntitySelector);
  const projectId = yield select(projectIdSelector);
  const placeholderId = getPlaceholderAssetId(parentId, index);
  const bundleAsset = {
    name,
    index,
    type: assetType.FILE,
    project_id: projectId,
    comment_count: 0,
    creator_id: creator.id,
    id: placeholderId,
    filesize: 0,
    isPlaceholder: true,
    item_count: 0,
    parent_id: parentId,
    inserted_at: new Date().toISOString(),
    uploaded_at: new Date().toISOString(),
    status: assetStatus.CREATED,
    assetsUploadProgress: {},
    childAssetIds: [], // used to track if both assets are created
  };
  yield put(createAssetPlaceholders({ [placeholderId]: bundleAsset }));
  return bundleAsset;
}

/**
 * Creates a new bundle asset on the APIs and tags the child assets
 * with the bundleId.
 * @param {string} bundlePlaceholderId - The bundle's placeholderId
 * @param {[]string} assetIds - The bundle's placeholderId
 */
function* createBundleAsset(bundlePlaceholderId, assetIds) {
  const bundle = yield select(assetOrPlaceholderAssetSelector, {
    assetId: bundlePlaceholderId,
  });
  if (!bundle) return;
  // create an actual asset on the DB
  const { success } = yield call(
    createBundle,
    bundle.parent_id,
    bundle,
    assetIds
  ) || {};
  if (success) {
    const bundleId = get(success, 'payload.response.result');
    if (!bundleId) return;

    // tag each child asset with the bundleId. Required for progress.
    const assetsPatch = assetIds.map((assetId) => ({ id: assetId, bundleId }));
    yield put(patchAssets(assetsPatch));

    // add the assetIds to the bundle so we can collectively
    // cancel/pause/resume the upload of all child assets
    const assets = yield select(assetEntitiesByAssetIdsSelector, { assetIds });
    const [asset1, asset2] = assets;
    const initialProgress = {
      bytesSent: 0,
      bytesPerSecond: 0,
      remainingTime: 0,
    };
    yield put(
      patchAssets([
        {
          id: bundleId,
          childAssetIds: [...assetIds],
          assetsUploadProgress: {
            [asset1.id]: { ...initialProgress, bytesTotal: asset1.filesize },
            [asset2.id]: { ...initialProgress, bytesTotal: asset2.filesize },
          },
        },
      ])
    );
    return;
  }
  yield call(
    error,
    'Something went wrong',
    'There was an error creating the new bundle'
  );
}

/**
 * Opens the DualViewSettings modal, waits for the
 * user to cancel or confirm the settings and closes the Modal.
 * @returns {Object} The payload of the sumbit action: { name }
 */
function* showDualViewModal(modal) {
  yield put(openModal(modal));

  const { confirmed } = yield race({
    canceled: take(DUAL_VIEW.CANCEL_MODAL),
    confirmed: take(DUAL_VIEW.CONFIRM_MODAL),
  });

  yield put(closeModal());
  return confirmed;
}

/**
 * This is called from `uploadDroppedItems()`. It checks all
 * to-be-uploaded files (items) if they are potential dual-view uploads.
 * If so, it opens a modal that the user confirms or cancels.
 * @param {string} parentId - The folder id where we upload the files into
 * @param {[]item} items - The items
 * @returns {boolean} - Whether to cancel the upload process.
 */
export function* showDualViewModalIfRequired(parentId, items) {
  const confirmed = yield call(
    showDualViewModal,
    <CreateDualView title="Upload" mode={DUAL_VIEW_MODE.UPLOAD} items={items} />
  );
  // user canceled the modal, so we cancel the upload
  if (!confirmed) return { cancel: true };
  const {
    payload: { wantsMerge },
  } = confirmed;

  // user opted out of creating an dual view asset,
  // we'll continue with a regular upload
  if (!wantsMerge) return { cancel: false, isDualViewMerge: false };

  // Derive the index from the items because they do not exist
  // as placeholders in redux yet. If we derive the index from existing
  // redux placeholders using indexToCreateAssetsSelector then we'll have duplicate indices.
  const bundleIndex = Math.min(items[0].index, items[1].index) - 1;
  const name = generateName(items[0].name);
  const bundle = yield call(
    createBundlePlaceholder,
    name,
    parentId,
    bundleIndex
  );

  // add each file to UPLOADS with the associated bundle
  items.forEach((item) => {
    const placeholderId = getPlaceholderAssetId(parentId, item.index);
    UPLOADS[placeholderId] = bundle.id;
  });
  return { cancel: false, isDualViewMerge: true };
}

/**
 * Removes all files from UPLOADS that are associated with the bundleId.
 * @param {string} bundleId - The bundle's temporary id
 */
function removeBundleAndFiles(bundleId) {
  Object.keys(UPLOADS).forEach((fileId) => {
    if (UPLOADS[fileId] !== bundleId) return;
    delete UPLOADS[fileId];
  });
}

/**
 * The ASSET.CREATE.SUCCESS action calls this function.
 * The file has an assetId now, so we can swap the file's
 * placeholderId with the assetId in the UPLOADS and continue
 * tracking the upload status.
 */
function* assetCreatedForFileUpload({
  payload: {
    response: {
      entities: { asset: assets },
      result,
    },
  },
}) {
  const { id: assetId, parent_id: parentId, index } = assets[result];
  const placeholderId = getPlaceholderAssetId(parentId, index);
  const bundleId = UPLOADS[placeholderId];
  // We need to check if the bundle placeholder still exists.
  // Chances are given that the user deleted the bundle placeholder
  // in the UI.
  // The bundle is only created when both child assets got their
  // ids, which can take a couple seconds.
  if (!bundleId) return;

  // swap the placeholderId with the new assetId
  UPLOADS[assetId] = bundleId;
  delete UPLOADS[placeholderId];

  // associate the assetId with the bundle
  yield put(setPlaceholderBundleAssetId(bundleId, assetId));
  const bundle = yield select(assetOrPlaceholderAssetSelector, {
    assetId: bundleId,
  });

  // check if both assets have been created and associated with the bundle
  if (!bundle || bundle.childAssetIds.length !== 2) return;

  // since we have the assetIds of both files, let's create the actual bundle in the DB
  yield call(createBundleAsset, bundleId, bundle.childAssetIds);
  removeBundleAndFiles(bundleId);
}

/**
 * Checks if the given assets fail the quality check (QC).
 * If they do, it will open the MergeFailed modal for infos.
 * @param {string[]} assetIds - The asset's ids.
 * @returns {string[]} - An array of asset properties that mismatch.
 */
function* dualViewMergingErrors(assetIds) {
  const assets = yield select(assetEntitiesByAssetIdsSelector, { assetIds });

  const errors = validateAssetsAndGetErrors(assets);
  if (errors) {
    if (typeof errors === 'string') {
      yield call(error, 'Failed', errors);
    } else {
      yield put(
        openModal(<MergeFailed assetIds={assetIds} mismatchingProps={errors} />)
      );
    }
  }
  return errors;
}

/**
 * Saga is triggered by a right-click context menu call.
 * Takes two video assetIds, runs some checks to make
 * sure they are "merge-able", and calls the API to create
 * a shiny new bundle.
 */
function* createDualView({ payload: { assetIds } }) {
  const errors = yield call(dualViewMergingErrors, assetIds);
  if (errors) return;

  const assets = yield select(assetEntitiesByAssetIdsSelector, { assetIds });
  const confirmed = yield call(
    showDualViewModal,
    <CreateDualView
      items={assets.map((asset) => ({
        name: asset.name,
        thumb: asset.thumb_orig_ar_540 || asset.image_small,
      }))}
      title="Create Turnstyle Asset"
      mode={DUAL_VIEW_MODE.CREATE}
    />
  );

  if (!confirmed) return;
  const name = generateName(assets[0].name);
  const parentId = yield select(folderIdSelector);
  const bundleAsset = yield call(createBundlePlaceholder, name, parentId);
  yield call(createBundleAsset, bundleAsset.id, assetIds);
}

function* onAssetsLoaded() {
  const search = yield select(searchQuerySelector);
  const { email_type, asset_ids } = queryString.parse(search) || {};
  if (email_type !== 'bundle-failure' || !asset_ids) return;

  const assetIds = asset_ids.split(',');
  yield call(dualViewMergingErrors, assetIds);
  const pathName = yield select(pathnameSelector);
  history.replace(pathName);
}

export default [
  takeEvery(DUAL_VIEW.CONFIRM_CREATE, createDualView),
  takeEvery(ASSET.CREATE.SUCCESS, assetCreatedForFileUpload),
  takeLatest(PROJECT_CONTAINER.ASSETS_LOADED, onAssetsLoaded),
];

export const testExports = {
  UPLOADS,
  createBundlePlaceholder,
  createBundleAsset,
  showDualViewModal,
  showDualViewModalIfRequired,
  assetCreatedForFileUpload,
  createDualView,
  dualViewMergingErrors,
  onAssetsLoaded,
};
