import { connect } from 'react-redux';
import { batchUpdateAssets } from '@frameio/core/src/assets/actions';
import { assetEntitiesByAssetIdsSelector } from '@frameio/core/src/assets/selectors';

import ToggleAssetsPrivacy from './ToggleAssetsPrivacy';

function mapStateToProps(state, { assetIds }) {
  return {
    assets: assetEntitiesByAssetIdsSelector(state, { assetIds }),
  };
}

const mapDispatchToProps = {
  batchUpdateAssets,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToggleAssetsPrivacy);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
