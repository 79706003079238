import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { Tooltip } from '@frameio/vapor';
import Flex from 'styled-flex-component';
import SessionWatermarkTemplateRow, {
  TemplateTitle,
} from 'components/SessionWatermarkTemplateList/SessionWatermarkTemplateRow';
import InfoIcon from '@frameio/components/src/svgs/icons/24/info-filled.svg';

const TemplateList = styled.div`
  margin: ${(p) => p.theme.spacing.tiny} 0
    ${(p) => (p.hasHeading ? '6px' : p.theme.spacing.units(2))};
`;

const StyledTemplateTitle = styled(TemplateTitle)`
  padding: ${(p) => p.theme.spacing.micro} ${(p) => p.theme.spacing.units(2)};
`;

const StyledButton = styled.button`
  display: inline-block;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
`;

const StyledInfoIcon = styled(InfoIcon)`
  fill: ${(p) => p.theme.color.brand};
  display: inline-block;
  opacity: 0.4;
  position: relative;
  transform: translate3d(0, 0, 1px); /* Fixes rendering issue in safari */
  transition: opacity 250ms ease;

  &:hover {
    opacity: 1;
  }
`;

const ListHeading = styled.span`
  display: block;
  ${(p) => p.theme.fontStyle.bodyS}
  font-weight: 400;
  color: ${(p) => p.theme.color.graphiteGray};
  margin-top: ${(p) => p.theme.spacing.tiny};
  padding: ${(p) => p.theme.spacing.micro} ${(p) => p.theme.spacing.units(2)};
  text-transform: uppercase;
`;

const fadeInAndTranslateDown = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, -16px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const StyledLi = styled.li`
  animation: ${fadeInAndTranslateDown} 200ms cubic-bezier(0.19, 1, 0.22, 1);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  will-change: transform;
  transform: translate3d(0, -16px, 0);
  opacity: 0;
`;

const SessionWatermarkTemplateList = ({
  defaultSessionWatermarkTemplateId,
  fallbackHeading = 'No templates have been created',
  isAdmin,
  internalPlaybackTemplateId,
  listHeading,
  onChange,
  onRevert,
  onSelectForPreview,
  openSessionWatermarkTemplateEditor,
  setTemplateAsDefault,
  setTemplateAsInternalPlaybackTemplate,
  selectedSessionWatermarkTemplateId,
  sessionWatermarkTemplates,
  showAdvancedAdminOptions,
  showToasts,
  tooltipTextForEmptyTemplatesList,
}) => {
  const sessionWatermarkTemplatesExist =
    sessionWatermarkTemplates && sessionWatermarkTemplates.length > 0;

  return (
    <TemplateList hasHeading={Boolean(listHeading)}>
      {listHeading && (
        <ListHeading variant="bodyM" color="graphiteGray">
          {listHeading}
        </ListHeading>
      )}
      {!sessionWatermarkTemplatesExist && (
        <Flex>
          <StyledTemplateTitle active>{fallbackHeading}</StyledTemplateTitle>
          {tooltipTextForEmptyTemplatesList && (
            <Tooltip
              title={tooltipTextForEmptyTemplatesList}
              shouldUsePortal
              variant="dark"
              placement="top"
            >
              <StyledButton>
                <StyledInfoIcon width={24} height={24} />
              </StyledButton>
            </Tooltip>
          )}
        </Flex>
      )}
      {sessionWatermarkTemplatesExist && (
        <ul>
          {sessionWatermarkTemplates.map((template, index) => (
            <StyledLi
              // 30 creates a delay that feels closely matched to the motion in ENT-2537
              style={{ animationDelay: `${index * 30}ms` }}
              key={template.id}
            >
              <SessionWatermarkTemplateRow
                isDeleteButtonEnabled={isAdmin}
                onRevert={onRevert}
                isDeletedEntity={Boolean(template.deleted_at)}
                isAdmin={isAdmin && Boolean(template.deleted_at) === false}
                isDefaultTemplate={
                  defaultSessionWatermarkTemplateId === template.id
                }
                isInternalPlaybackTemplate={
                  internalPlaybackTemplateId === template.id
                }
                isSelected={template.id === selectedSessionWatermarkTemplateId}
                onChange={onChange}
                onSelectForPreview={onSelectForPreview}
                openSessionWatermarkTemplateEditor={
                  openSessionWatermarkTemplateEditor
                }
                setTemplateAsDefault={setTemplateAsDefault}
                setTemplateAsInternalPlaybackTemplate={
                  setTemplateAsInternalPlaybackTemplate
                }
                showToasts={showToasts}
                showAdvancedAdminOptions={showAdvancedAdminOptions}
                sessionWatermarkEntity={template}
              />
            </StyledLi>
          ))}
        </ul>
      )}
    </TemplateList>
  );
};

SessionWatermarkTemplateList.propTypes = {
  defaultSessionWatermarkTemplateId: PropTypes.string,
  fallbackHeading: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  internalPlaybackTemplateId: PropTypes.string,
  listHeading: PropTypes.string,
  openSessionWatermarkTemplateEditor: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onSelectForPreview: PropTypes.func,
  selectedSessionWatermarkTemplateId: PropTypes.string,
  sessionWatermarkTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      creator_id: PropTypes.string,
      deleted_at: PropTypes.string,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  setTemplateAsDefault: PropTypes.func.isRequired,
  setTemplateAsInternalPlaybackTemplate: PropTypes.func,
  shouldDisplayInternalTemplate: PropTypes.bool,
  showAdvancedAdminOptions: PropTypes.bool,
  showToasts: PropTypes.bool,
  tooltipTextForEmptyTemplatesList: PropTypes.string,
};

SessionWatermarkTemplateList.defaultProps = {
  sessionWatermarkTemplates: [],
};

export default SessionWatermarkTemplateList;
