import { call, put, takeLatest } from 'redux-saga/effects';
import { updateAccount } from '@frameio/core/src/accounts/sagas';
import { closeModal } from 'components/Modal/actions';
import { showErrorToast } from 'actions/toasts';
import { EDIT_INVOICE_SETTINGS } from './actions';

function* updateInvoiceSettings(accountId, values) {
  const { name, valueAddedTax, setSubmitting } = values;
  const payload = {
    company_name: name,
    vat: valueAddedTax,
  };
  const { failure } = yield call(updateAccount, accountId, payload);

  if (failure) {
    yield call(setSubmitting, false);
    yield put(
      showErrorToast({
        header: 'Something went wrong when updating your account details.',
      })
    );
    return;
  }
  yield put(closeModal());
}

export default [
  takeLatest(
    EDIT_INVOICE_SETTINGS.UPDATE,
    ({ payload: { accountId, values } }) =>
      updateInvoiceSettings(accountId, values)
  ),
];

export const testExports = { updateInvoiceSettings };
