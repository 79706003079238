import { connect } from 'react-redux';
import { fetchUnseenReviewsTotal } from 'pages/AccountContainer/InboxContainer/actions';
import { totalUnseenReviewsSelector } from 'pages/AccountContainer/InboxContainer/selectors';
import InboxRow from './InboxRow';

const mapStateToProps = (state, { accountId }) => ({
  totalUnseenReviews: totalUnseenReviewsSelector(state, { accountId }),
});

const mapDispatchToProps = { fetchUnseenReviewsTotal };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InboxRow);
export const testExports = { mapStateToProps, mapDispatchToProps };
