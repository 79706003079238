import { connect } from 'react-redux';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import { v4MigrationModal } from 'utils/featureFlags';
import StartAccountOrGoToV4 from './StartAccountOrGoToV4';
import { startFlow } from '../actions';

function mapStateToProps(state, { accountId }) {
  const account = accountEntitySelector(state, { accountId }) || {};
  const isV4MigrationModalEnabled = v4MigrationModal(state);
  return {
    displayNameForAccount: account.display_name,
    accountId,
    isV4MigrationModalEnabled,
  };
}

const mapDispatchToProps = {
  startFlow,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StartAccountOrGoToV4);
