import { isFetchingReducerFactory } from 'factories/isFetching';
import { REACTIVATE_SUBSCRIPTION } from './actions';

const isFetchingReducer = isFetchingReducerFactory(
  REACTIVATE_SUBSCRIPTION.IS_FETCHING
);

const initialState = {
  isFetching: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case REACTIVATE_SUBSCRIPTION.IS_FETCHING:
      return isFetchingReducer(state, action);
    default:
      return state;
  }
}
