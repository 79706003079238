import React from 'react';
import PropTypes from 'prop-types';

function AssetSliderFactory(Component) {
  class AssetSlider extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        currentPage: 1,
      };
    }

    componentDidMount() {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ width: this.node.clientWidth }, () => {
        const { selectedThumbIndex } = this.props;
        const page = this.getPageForIdx(selectedThumbIndex);
        this.goToPage(page, { animate: false });
      });
      return window.addEventListener('resize', this.handleResize);
    }

    UNSAFE_componentWillUpdate(nextProps) {
      const { selectedThumbIndex } = nextProps;
      if (
        typeof selectedThumbIndex === 'number' &&
        selectedThumbIndex !== this.props.selectedThumbIndex &&
        !this.slider.isIndexInWindow(selectedThumbIndex)
      ) {
        // TODO(christianevans214) WK-40: Remove this once React's low-priority
        // setState moves into production. That's really what I want.
        // also this has a polyfill associated with it that needs to be removed.
        requestIdleCallback &&
          requestIdleCallback(() => {
            const page = this.getPageForIdx(selectedThumbIndex);
            this.goToPage(page);
          });
      }
    }

    componentWillUnmount() {
      return window.removeEventListener('resize', this.handleResize);
    }

    setWidth = () => {
      this.setState({ width: this.node.clientWidth });
    };

    getPageForIdx = (idx) => {
      const numPerPage = this.getNumberPerPage();
      return Math.floor(idx / numPerPage) + 1;
    };

    getNumberPerPage() {
      return Math.floor(this.state.width / this.getImageWidth());
    }

    getImageWidth() {
      return this.props.imageWidth + this.props.imageHorizontalPadding;
    }

    setCurrentPage = (idx) => {
      const index = idx + 1;
      const page = Math.ceil(index / this.getNumberPerPage());
      return this.setState({ currentPage: page });
    };

    getTotalPages = () =>
      Math.ceil(this.props.components.length / this.getNumberPerPage());

    handleResize = () => {
      this.setState({ width: this.node.clientWidth });
    };

    goToPage = (page, options = {}) => {
      const scrollLeft =
        this.getNumberPerPage() * this.getImageWidth() * (page - 1);
      return this.slider.scrollTo(scrollLeft, options);
    };

    constructChildProps() {
      return Object.assign({}, this.props, {
        currentPage: this.state.currentPage,
        getTotalPages: this.getTotalPages,
        width: this.state.width,
        setCurrentIndex: this.setCurrentPage,
        sliderRef: (slider) => {
          this.slider = slider;
        },
        goToPage: this.goToPage,
      });
    }

    render() {
      const props = this.constructChildProps();
      return (
        <div
          ref={(ref) => {
            this.node = ref;
          }}
        >
          <Component {...props} />
        </div>
      );
    }
  }

  AssetSlider.defaultProps = {
    containerWidthOffset: null,
  };

  AssetSlider.propTypes = {
    imageWidth: PropTypes.number.isRequired,
    imageHorizontalPadding: PropTypes.number.isRequired,
    components: PropTypes.arrayOf(PropTypes.shape).isRequired,
    selectedThumbIndex: PropTypes.number.isRequired,
    containerWidthOffset: PropTypes.number,
  };
  return AssetSlider;
}

export default AssetSliderFactory;
