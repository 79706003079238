import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ProjectSettingsItem from 'components/ProjectActions/ProjectSettingsItem';
import JoinLeaveProjectItem from 'components/ProjectActions/JoinLeaveProject';
import DeleteProjectItem from 'components/ProjectActions/DeleteProjectItem';
import ArchiveProject from 'components/ProjectActions/ArchiveProject';
import UnarchiveProject from 'components/ProjectActions/UnarchiveProject';
import { versionsThatSupportProjectArchiving } from 'components/SelectPlanFlow/PlanDisplay';

export default function OptionsMenu({
  canArchiveProject,
  canUnarchiveProject,
  currentPlanVersion,
  getRoleForProject,
  getProjectUserPreferences,
  isProjectArchived,
  projectId,
  projectName,
  isAccountOnFreePlanWithUserMax,
  isCollaboratorOnly,
}) {
  // This is... not ideal. Previously we were calling these functions from the render
  // function. Now we are calling them on mount. The problem is that this can briefly
  // display the wrong menu options.
  useEffect(() => {
    /*
    Because project role-specific data can change based on a given context menu
    action (e.g. when a user joins or leaves a given project), we need to fetch
    the most recent project roles data to ensure we reflect the correct project
    context menu options.
  */
    getRoleForProject(projectId, true);

    /*
   We optimistically fetch the user_preferences of the project upon opening
   this menu. We need these preferences for the project settings modal.
   NOTE: an assumption is made here that this request is resolved before
   the user opens the modal.
 */
    getProjectUserPreferences(projectId);
  }, [getProjectUserPreferences, getRoleForProject, projectId]);

  const trackingProps = {
    trackingPage: 'dashboard-sidebar',
    trackingPosition: 'options-button',
  };

  // Team members and Admins on a V5 free plan should see the option to upgrade when clicking on the
  // archive button.
  const shouldSeeUpgradeOption =
    !canArchiveProject &&
    !isProjectArchived &&
    isAccountOnFreePlanWithUserMax &&
    !isCollaboratorOnly &&
    versionsThatSupportProjectArchiving.has(currentPlanVersion);

  const shouldShowArchiveProjectOption =
    canArchiveProject || shouldSeeUpgradeOption;

  return (
    <React.Fragment>
      <ProjectSettingsItem {...trackingProps} projectId={projectId} />

      {shouldShowArchiveProjectOption && (
        <ArchiveProject {...trackingProps} projectId={projectId} />
      )}
      {canUnarchiveProject && (
        <UnarchiveProject {...trackingProps} projectId={projectId} />
      )}

      <JoinLeaveProjectItem
        isLeaveProjectEnabled
        {...trackingProps}
        projectId={projectId}
      />
      <DeleteProjectItem {...trackingProps} {...{ projectId, projectName }} />
    </React.Fragment>
  );
}

OptionsMenu.defaultProps = {
  canArchiveProject: false,
  canUnarchiveProject: false,
  isCollaboratorOnly: false,
  isProjectArchived: false,
  isAccountOnFreePlanWithUserMax: false,
  projectId: '',
  projectName: '',
};

OptionsMenu.propTypes = {
  canArchiveProject: PropTypes.bool,
  canUnarchiveProject: PropTypes.bool,
  getRoleForProject: PropTypes.func.isRequired,
  getProjectUserPreferences: PropTypes.func.isRequired,
  isCollaboratorOnly: PropTypes.bool,
  isProjectArchived: PropTypes.bool,
  isAccountOnFreePlanWithUserMax: PropTypes.bool,
  projectId: PropTypes.string,
  projectName: PropTypes.string,
};

export const testExports = {
  ArchiveProject,
  UnarchiveProject,
};
