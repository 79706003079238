import React from 'react';
import { call, put, spawn, select, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import {
  getSharedProjectByInvite,
  joinProjectAsCollaboratorFromInvite,
} from '@frameio/core/src/projects/sagas';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import { getProjectUrl, ROOT_URL } from 'URLs';
import track from 'analytics';
import { redirectTo } from 'utils/router';
import { PROJECT } from 'actions/projects';
import { isUserCollaboratorOfProjectSelector } from 'selectors/roles';
import { error, priority } from 'components/Dialog/SimpleDialog/sagas';
import RequestAccessToEntity from 'components/RequestAccessToEntity';
import { ENTITY_TYPE } from 'components/AuthGate/AuthGate';
import { openModal } from 'components/Modal/actions';

function* handleGetSharedProjectFailure(failureResponse) {
  // The project_id is only ever exposed in the case where the token
  // was valid but the invite link has expired
  const projectId = get(
    failureResponse,
    'payload.error.response.data.errors[0].project_id'
  );

  if (projectId) {
    yield put(
      openModal(
        <RequestAccessToEntity
          entityType={ENTITY_TYPE.PROJECT}
          entityId={projectId}
        />,
        { canCloseModal: false }
      )
    );
    return;
  }

  yield call(
    error,
    'Whoops!',
    'Looks like that project is no longer available.',
    {
      priority: priority.PROJECT_NOT_FOUND,
    }
  );
  yield call(redirectTo, ROOT_URL);
}

function* joinSharedProjectByInvite(action) {
  const { inviteToken } = action.payload;

  const {
    failure: getSharedProjectFailure,
    success: getSharedProjectSuccess,
  } = yield call(getSharedProjectByInvite, inviteToken);

  // If we can't get the project it's likely because the link was disabled
  // and is no longer valid so we should notify the user and send them elsewhere
  if (getSharedProjectFailure) {
    yield call(handleGetSharedProjectFailure, getSharedProjectFailure);
    return;
  }

  const { result: projectId } = getSharedProjectSuccess.payload.response;
  const project = yield select(projectEntitySelector, { projectId });

  const isCollaborator = yield select(isUserCollaboratorOfProjectSelector, {
    projectId,
  });
  const isJoininableProject = project.shared && !isCollaborator;

  // If user is authed, project is joinable and user is not yet a collaborator,
  // call the api to join the project
  if (isJoininableProject) {
    yield call(joinProjectAsCollaboratorFromInvite, inviteToken);
    yield spawn(track, 'project-joined-client', { via: 'new token url' });
  }

  yield call(redirectTo, getProjectUrl(projectId));
}

export const testExports = {
  joinSharedProjectByInvite,
  handleGetSharedProjectFailure,
};

export default [
  takeLatest(PROJECT.JOIN_SHARED_BY_INVITE, joinSharedProjectByInvite),
];
