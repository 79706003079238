import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const ROLE = generateActionTypes('ROLE', [
  'GET_FOR_ACCOUNT',
  'GET_FOR_PROJECT',
  'GET_FOR_TEAM',
]);

export const getRoleForProject = createAction(
  ROLE.GET_FOR_PROJECT,
  (projectId, shouldFetch) => ({ projectId, shouldFetch })
);

export const getRoleForTeam = createAction(ROLE.GET_FOR_TEAM, (teamId) => ({
  teamId,
}));
