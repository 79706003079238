import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import styled, { keyframes } from 'styled-components';
import { shallowReflectiveEqual } from 'reflective-bind';
import { type as assetType } from '@frameio/core/src/assets/helpers/constants';
import { Tooltip } from '@frameio/vapor';
import TruncateWithTooltip from '@frameio/components/src/styled-components/TruncateWithTooltip';
import CrossFader from '@frameio/components/src/styled-components/CrossFader';
import { formatAbsoluteTime } from '@frameio/components/src/utils/datetimeHelpers';

import { isVideo } from '@frameio/core/src/assets/helpers/mediaTypes';
import { isAssetTranscoded } from '@frameio/core/src/assets/helpers/utils';

import SpeechBubbleIcon from '@frameio/components/src/svgs/icons/speech-bubble.svg';
import WarningIcon from '@frameio/components/src/svgs/icons/warning.svg';
import {
  CardChin,
  CardTitle,
  CardSubtitle,
  CardMeta,
  CardMetaPrimary,
  CardMetaSecondary,
  CardMetaTertiary,
  CardContextMenuButton,
  CardTooltip,
} from '@frameio/components/src/styled-components/Card';

import SORT_OPTIONS, {
  propType as sortOptionsPropType,
} from 'pages/ProjectContainer/ProjectAssets/sortOptions';
import { formatBytes } from 'shared/filesizeHelpers';
import { DROP_CARD_FACE_TRANSITION_DURATION_MS } from './DropZoneIndicator';

const LifecycleIcon = styled(WarningIcon).attrs(({ theme }) => ({
  width: 16,
  height: 16,
  color: theme.color.alert,
}))`
  margin-right: ${(p) => p.theme.spacing.micro};
  padding: 2px ${(p) => p.theme.spacing.micro};
`;

const COMMENT_BUBBLE_SIZE = '11px';
const COMMENT_BUBBLE_CLASSNAME = 'CardMetaTertiary__CommentBubble';

const StyledCardChin = styled(CardChin)`
  ${CardTitle} {
    display: flex;

    ${LifecycleIcon} {
      flex-shrink: 0;
    }

    .file-title {
      flex: 1 0 auto;
      max-width: 100%;
    }
  }

  ${CardMetaSecondary} {
    white-space: nowrap;
  }

  ${CardMetaTertiary} .${COMMENT_BUBBLE_CLASSNAME} {
    margin-right: 2px;
    margin-bottom: 1px;
  }
`;

const glow = keyframes`
  from { opacity: .5 }
  to { opacity: 1 }
`;
const GlowAnimation = styled.span`
  animation: ${glow} 1300ms linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;

function FileCardChin({
  asset,
  assetToVersion,
  assetToVersionCreatorName,
  coverAsset,
  creatorName,
  daysLeftBeforeDeletedOnLifecyclePolicy,
  hasContextMenu,
  isAssetLifecyclePolicyExpiring,
  isDropComplete,
  isDropZone,
  isSharingMode,
  isTouch,
  onContextMenuButtonClick,
  showAssetThumb,
  showQuicklookTooltip,
  sortedBy,
}) {
  const coverOrAsset = coverAsset || asset;
  const assetForMetadata = isDropComplete ? assetToVersion : coverOrAsset;
  const {
    filesize,
    comment_count: commentCount,
    uploaded_at: uploadedAt,
    name,
  } = assetForMetadata;

  const nextVersion = asset.versions > 1 ? asset.versions + 1 : 2;
  const isVersionStack = asset.type === assetType.VERSION_STACK;
  const isTranscoded = coverAsset || isAssetTranscoded(assetForMetadata);
  const isTranscodingProxies =
    !isTranscoded && showAssetThumb && isVideo(assetForMetadata);

  let secondaryMeta;

  switch (sortedBy) {
    case SORT_OPTIONS.SIZE.value:
      secondaryMeta = formatBytes(filesize);
      break;
    default:
      secondaryMeta = formatAbsoluteTime(uploadedAt);
  }

  return (
    <StyledCardChin isTouch={isTouch} isSharingMode={isSharingMode}>
      {/* State 2 -> 3: Swap old and new titles */}

      <CardTitle>
        {isAssetLifecyclePolicyExpiring && (
          <Tooltip
            data-test-id="lifecycle-icon-tooltip"
            title={`This ${isVersionStack ? 'version stack' : 'file'} will be
              deleted in ${daysLeftBeforeDeletedOnLifecyclePolicy}
              ${daysLeftBeforeDeletedOnLifecyclePolicy === 1 ? 'day' : 'days'}`}
          >
            <div>
              <LifecycleIcon />
            </div>
          </Tooltip>
        )}
        <div className="file-title">
          <CrossFader timeout={DROP_CARD_FACE_TRANSITION_DURATION_MS}>
            <TruncateWithTooltip key={assetToVersion ? 'versioning' : 'name'}>
              {assetToVersion ? assetToVersion.name : name}
            </TruncateWithTooltip>
          </CrossFader>
        </div>
      </CardTitle>
      <CardSubtitle>
        {showAssetThumb &&
          !isTranscodingProxies &&
          !showQuicklookTooltip &&
          !isDropZone && (
            <CardMeta>
              {isTouch ? (
                <CardMetaPrimary>{secondaryMeta}</CardMetaPrimary>
              ) : (
                <React.Fragment>
                  <CardMetaPrimary>
                    {assetToVersionCreatorName || creatorName}
                  </CardMetaPrimary>
                  <CardMetaSecondary>{secondaryMeta}</CardMetaSecondary>
                </React.Fragment>
              )}
              {commentCount > 0 && !isTouch && !isSharingMode && (
                <CardMetaTertiary>
                  <SpeechBubbleIcon
                    className={COMMENT_BUBBLE_CLASSNAME}
                    width={COMMENT_BUBBLE_SIZE}
                    height={COMMENT_BUBBLE_SIZE}
                  />
                  {commentCount}
                </CardMetaTertiary>
              )}
            </CardMeta>
          )}
        {!showAssetThumb && (
          <CardMeta>
            <CardMetaPrimary>Generating thumbnails</CardMetaPrimary>
          </CardMeta>
        )}
        {isTranscodingProxies && (
          <CardMeta>
            <CardMetaPrimary>
              <GlowAnimation>Preparing for playback…</GlowAnimation>
            </CardMetaPrimary>
          </CardMeta>
        )}
        {showQuicklookTooltip && (
          <CardMeta>
            <CardTooltip>Press spacebar to quicklook</CardTooltip>
          </CardMeta>
        )}
        {isDropZone && (
          <CardMeta>
            <CardTooltip>Add as version {nextVersion}</CardTooltip>
          </CardMeta>
        )}
      </CardSubtitle>
      {hasContextMenu && (
        <CardContextMenuButton
          onClick={onContextMenuButtonClick}
          aria-label="asset-context-menu"
        />
      )}
    </StyledCardChin>
  );
}

export default React.memo(FileCardChin, shallowReflectiveEqual);

export const assetPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  comment_count: PropTypes.number,
  versions: PropTypes.number,
  filesize: PropTypes.number,
  type: PropTypes.oneOf(Object.values(assetType)).isRequired,
  uploaded_at: PropTypes.string.isRequuired,
});

FileCardChin.propTypes = {
  asset: assetPropType.isRequired,
  assetToVersion: assetPropType,
  assetToVersionCreatorName: PropTypes.string,
  coverAsset: assetPropType,
  creatorName: PropTypes.string.isRequired,
  daysLeftBeforeDeletedOnLifecyclePolicy: PropTypes.number,
  hasContextMenu: PropTypes.bool,
  isAssetLifecyclePolicyExpiring: PropTypes.bool,
  isDropComplete: PropTypes.bool,
  isDropZone: PropTypes.bool,
  isSharingMode: PropTypes.bool,
  isTouch: PropTypes.bool,
  onContextMenuButtonClick: PropTypes.func,
  showAssetThumb: PropTypes.bool,
  showQuicklookTooltip: PropTypes.bool,
  sortedBy: sortOptionsPropType,
};

FileCardChin.defaultProps = {
  assetToVersion: undefined,
  assetToVersionCreatorName: undefined,
  coverAsset: undefined,
  daysLeftBeforeDeletedOnLifecyclePolicy: 0,
  hasContextMenu: false,
  isAssetLifecyclePolicyExpiring: false,
  isDropComplete: false,
  isDropZone: false,
  isSharingMode: false,
  isTouch: false,
  onContextMenuButtonClick: noop,
  showAssetThumb: true,
  showQuicklookTooltip: false,
  sortedBy: undefined,
};

export const testExports = {
  StyledCardChin,
  LifecycleIcon,
};
