import { call, takeLatest, select } from 'redux-saga/effects';
import { noop } from 'lodash';
import {
  updateAccountSettings as updateAccountSettingsCoreSaga,
  createAllowedDomainForAccount,
  removeAllowedDomainForAccount,
} from '@frameio/core/src/accounts/sagas';
import { currentAccountSelector } from 'selectors/accounts';
import { ALLOWED_DOMAINS } from '@frameio/core/src/accounts/actions';
import { listSessionWatermarkTemplatesForAccount as listSessionWatermarkTemplatesForAccountCoreSaga } from '@frameio/core/src/sessionWatermarkTemplate/sagas';

export const ACCOUNT_SETTINGS_CONTAINER_FETCH_TEMPLATES =
  'ACCOUNT_SETTINGS_CONTAINER_FETCH_TEMPLATES';

export const ACCOUNT_SETTINGS_CONTAINER_SUBMIT =
  'ACCOUNT_SETTINGS_CONTAINER_SUBMIT';

function* updateAccountSettings(args) {
  const {
    payload: { accountId, data, onSuccess = noop, onError = noop },
  } = args;

  const { failure, success } = yield call(
    updateAccountSettingsCoreSaga,
    accountId,
    data
  );

  if (failure) {
    onError(failure?.payload?.error?.response?.data);
  } else {
    onSuccess(success.payload);
  }
}

function* createAllowedDomain({ payload }) {
  const { name } = payload;
  const { id: accountId } = yield select(currentAccountSelector);

  yield call(createAllowedDomainForAccount, {
    accountId,
    name,
  });
}

function* removeAllowedDomain({ payload }) {
  const { domainId } = payload;
  const { id: accountId } = yield select(currentAccountSelector);

  yield call(removeAllowedDomainForAccount, {
    accountId,
    domainId,
  });
}

export default [
  takeLatest(
    ACCOUNT_SETTINGS_CONTAINER_FETCH_TEMPLATES,
    ({ payload: { accountId } }) =>
      listSessionWatermarkTemplatesForAccountCoreSaga(accountId)
  ),
  takeLatest(ACCOUNT_SETTINGS_CONTAINER_SUBMIT, updateAccountSettings),
  takeLatest(ALLOWED_DOMAINS.CREATE.BASE, createAllowedDomain),
  takeLatest(ALLOWED_DOMAINS.REMOVE.BASE, removeAllowedDomain),
];
