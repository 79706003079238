/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react';
import config from 'config';
import PurpleWhiteTextLogo from '@frameio/components/src/svgs/logos/logo-purple-light.svg';
import './NotFound.scss';

const NotFound = () => (
  <div className="not-found">
    <PurpleWhiteTextLogo
      className="logo"
      width="102"
      height="20"
      alt="Frame.io logo"
    />
    <div className="copy">
      <h1>Page not found</h1>
      <h2>
        The link you clicked may be broken or the page may have been removed.
      </h2>
      <p>
        <a href="mailto:support@frame.io">Contact us</a> about the problem or
        return to <a href={config.clientHost}>Frame.io</a>
      </p>
    </div>
  </div>
);

export default NotFound;
