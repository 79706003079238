import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Raven from 'raven-js';

const FormPlaceholder = styled.div`
  height: ${({ collapsed }) => (collapsed ? '344px' : '526px')};
`;

const DynamicCreditCardForm = ({ collapsed, ...rest }) => {
  const [CreditCardComponent, setCreditCardComponent] = useState(null);

  useEffect(() => {
    async function importCreditCardComponent() {
      /* [GROW-3317] - Loading `CreditCardForm` dynamically prevents the "Failed
      to load Stripe" error from being thrown when a User is redirected to
      `auth-portal`. See https://github.com/stripe/stripe-js/issues/26 for more
      details. */
      const Component = await import('./ConnectedCreditCardForm')
        .then((component) => setCreditCardComponent(() => component.default))
        .catch((error) => Raven.captureException(error));
      return Component;
    }
    importCreditCardComponent();
  }, []);

  if (!CreditCardComponent) {
    return <FormPlaceholder collapsed={collapsed} />;
  }

  return <CreditCardComponent collapsed={collapsed} {...rest} />;
};

export default DynamicCreditCardForm;
