import React from 'react';
import PropTypes from 'prop-types';
import Button from '@frameio/components/src/styled-components/Button';
import GridViewIcon from '@frameio/components/src/svgs/icons/grid-view.svg';
import ListViewIcon from '@frameio/components/src/svgs/icons/list-view.svg';
import VIEW_TYPES, { propType as viewTypePropType } from './viewTypes';

export default function ToggleViewType({ viewType, setViewType }) {
  const isListView = viewType === VIEW_TYPES.LIST;
  return (
    <Button
      icon="true"
      dark
      text
      onClick={() =>
        setViewType(isListView ? VIEW_TYPES.GRID : VIEW_TYPES.LIST)
      }
    >
      {isListView ? (
        <GridViewIcon height={18} width={18} />
      ) : (
        <ListViewIcon height={18} width={18} />
      )}
    </Button>
  );
}

ToggleViewType.propTypes = {
  setViewType: PropTypes.func.isRequired,
  viewType: viewTypePropType.isRequired,
};
