import JTCurve from 'core/JTCurve';

String.prototype.to_params = function () {
  let reduceFunc = function (a, x) {
    let h = x.split("=");
    a[h[0]] = h[1];
    return a;
  };
  return this.split("&")
    .reduce(reduceFunc, {});
};

String.prototype.pluralize = function (n) {
  if (n == null) { n = 0; }
  if (n === 1) {
    return this;
  } else {
    if (this[this.length - 1] === 'y') {
      return `${this.slice(0, +(this.length-2) + 1 || undefined)}ies`;
    } else {
      return `${this}s`;
    }
  }
};

String.prototype.singularize = function () {

  if (this.slice(this.length - 3, +this.length - 1 + 1 || undefined) === 'ies') {
    return `${this.slice(0, +(this.length-4) + 1 || undefined)}y`;
  } else if (this[this.length - 1] === 's') {
    return `${this.slice(0, +(this.length-2) + 1 || undefined)}`;
  } else {
    return this;
  }
};

String.prototype.kabob = function () {
  return this.replace(/[\s|_]+/g, "-");
};

Date.prototype.yyyymmdd = function () {
  let yyyy = this.getFullYear()
    .toString();
  let mm = (this.getMonth() + 1)
    .toString();
  let dd = this.getDate()
    .toString();
  return `${yyyy}-${(mm[1] ? mm : `0${mm[0]}`)}-${( dd[1] ? dd : `0${dd[0]}` )}`;
};

Element.prototype.triangularize = function (dir) {
  let clip_string;
  let elem = $(this);
  let width = elem.width();
  let height = elem.height();

  switch (dir) {
    case 'up':
      clip_string = `polygon(0px ${height}px, ${Math.round(width/ 2.0)}px 0px, ${width}px ${height}px)`;
      break;
    case 'down':
      clip_string = `polygon(0px 0px, ${Math.round(width/ 2.0)}px ${height}px, ${width}px 0px)`;
      break;
    case 'left':
      clip_string = `polygon(0px ${Math.round(height/ 2.0)}px, ${width}px 0px, ${width}px ${height}px)`;
      break;
    case 'right':
      clip_string = `polygon(0px 0px, ${width}px ${Math.round(height/ 2.0)}px, 0px ${height}px)`;
      break;
  }

  return elem.css({ '-webkit-clip-path': clip_string, 'clip-path': clip_string, overflow: 'hidden', 'user-select': 'none', 'pointer-events': 'pointer-events', 'none': 'none' });
};

$.easing.damp = (x, t, b, c, d) => JTCurve.damp(t, b, c, d);
