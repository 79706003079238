import { createSelector } from 'reselect';
import { accountEntitiesSelector } from '@frameio/core/src/accounts/selectors';
import { teamEntitiesSelector } from '@frameio/core/src/teams/selectors';
import ROLES from '@frameio/core/src/roles/helpers/constants';
import {
  highestScopedAccountRoleSelector,
  highestScopedTeamRoleSelector,
} from 'selectors/roles';

/**
 * @param   {Object} state - Redux store state.
 * @returns {Object} - Create project form's data.
 */
export const createProjectFormSelector = (state) => state.createProjectForm;

export const selectedTeamSelector = createSelector(
  createProjectFormSelector,
  teamEntitiesSelector,
  ({ teamId }, teams) => (teams || {})[teamId]
);

export const selectedAccountSelector = createSelector(
  selectedTeamSelector,
  accountEntitiesSelector,
  ({ account_id }, accountEntities) => accountEntities[account_id] || {}
);

export const selectedRoleSelector = createSelector(
  selectedAccountSelector,
  selectedTeamSelector,
  (state) => state,
  (account = {}, team = {}, state) => {
    const { id: accountId } = account;
    const { id: teamId } = team;
    const accountRole = highestScopedAccountRoleSelector(state, { accountId });
    const teamRole = highestScopedTeamRoleSelector(state, { teamId });

    return accountRole || teamRole || ROLES.COLLABORATOR;
  }
);
