import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AnimateExpansionToggle from '@frameio/components/src/styled-components/TreeView/AnimateExpansionToggle';
import Flex from 'styled-flex-component';
import Truncate from '@frameio/components/src/styled-components/Truncate';
import DropArrowIcon from '@frameio/components/src/svgs/icons/caron.svg';

const Header = styled(Truncate)`
  font-size: ${(p) => p.theme.fontSize[2]};
  line-height: 24px;
  user-select: none;
  padding-right: ${(p) => p.theme.spacing.tiny};
`.withComponent('h3');

const DropArrow = styled(({ isPointingDown, ...rest }) => (
  <DropArrowIcon {...rest} />
))`
  width: 18px;
  height: 18px;
  transform: rotate(
    ${({ isPointingDown }) => (isPointingDown ? '0deg' : '-90deg')}
  );
  transition: transform 0.2s ease-in-out;
`;

const ProjectExpansionToggle = styled.div.attrs({
  tabIndex: -1,
  role: 'button',
})`
  display: block;
  cursor: pointer;
  outline: none;
  height: 40px;
  opacity: 1;
  transition: opacity 0.12s linear;
  &:active {
    opacity: 0.7;
  }
`;

const Wrapper = styled(Flex)`
  color: ${(p) => p.theme.color.gray};
  padding: ${({ theme: { spacing } }) => spacing.tiny};
  /*
     -4px on the left because the icon should be 16px, not the current 18px.
     Then, -4px again so that the center of the icon's center aligns with the
     other leading edge of the ProjectListRow's project name.
  */
  padding-left: ${({ theme: { spacing } }) =>
    `calc(${spacing.large} - 4px - 4px)`};

  ${Header} {
    flex: 1;
    margin-left: ${(p) => p.theme.spacing.tiny};
  }

  ${DropArrow} {
    flex: 0 0 auto;
  }
`;

function ArchivedProjectsHeader({
  className,
  isArchivedProjectsTabOpen,
  onTabClick,
  teamId,
  style,
}) {
  return (
    <div className={className} style={style}>
      <ProjectExpansionToggle
        onClick={() => {
          onTabClick(
            `archivedProjectsTab-${[teamId]}`,
            !isArchivedProjectsTabOpen
          );
        }}
      >
        <Wrapper alignCenter justifyBetween>
          <DropArrow isPointingDown={isArchivedProjectsTabOpen} />
          <Header>Archived projects</Header>
        </Wrapper>
      </ProjectExpansionToggle>
      <AnimateExpansionToggle
        animateOnMount={false}
        isVisible={isArchivedProjectsTabOpen}
      />
    </div>
  );
}

ArchivedProjectsHeader.defaultProps = {
  className: undefined,
  isArchivedProjectsTabOpen: false,
  style: {},
  teamId: '',
};

ArchivedProjectsHeader.propTypes = {
  /** The className for the archived projects header */
  className: PropTypes.string,
  /** Should the archived projects tab be open. */
  isArchivedProjectsTabOpen: PropTypes.bool,
  /** Function fired when tab is clicked. */
  onTabClick: PropTypes.func.isRequired,
  /** The styling required for the virtual list */
  style: PropTypes.object,
  /** Team id. */
  teamId: PropTypes.string,
};

export default ArchivedProjectsHeader;
