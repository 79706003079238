import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import Pill, {
  RemovePill,
} from '@frameio/components/src/styled-components/Pill';
import Avatar from '@frameio/components/src/styled-components/Avatar';
import { Tooltip } from '@frameio/vapor';

const Text = styled.span`
  margin: 0 ${(p) => p.theme.spacing.micro};
`;

const bounceInAnimation = keyframes`
  0% { transform: scale(1.0); opacity: 0.7; }
  6% { transform: scale(1.07); opacity: 1; }
  100% { transform: scale(1); }
`;

const StyledPill = styled(Pill)`
  animation: ${bounceInAnimation} 300ms ease-out;
  padding-right: ${(p) => p.theme.spacing.micro};
  margin: 2px;
`;

const EmojiIcon = styled.span`
  height: 20px;
  position: relative;
  top: ${(p) => p.theme.spacing.micro};
  margin-left: ${(p) => p.theme.spacing.micro};
`;

function GroupToken({ message, name, emoji, onRemove }) {
  return (
    <Tooltip title={message}>
      <StyledPill>
        {emoji ? (
          <EmojiIcon>{emoji}</EmojiIcon>
        ) : (
          <Avatar size={20} name={name} />
        )}
        <Text>{name}</Text>
        <RemovePill tabIndex="-1" onClick={onRemove} />
      </StyledPill>
    </Tooltip>
  );
}

GroupToken.propTypes = {
  onRemove: PropTypes.func,
  name: PropTypes.string,
  message: PropTypes.string,
  emoji: PropTypes.string,
};

GroupToken.defaultProps = {
  onRemove: () => {},
  name: '',
  message: '',
  emoji: '',
};

export default GroupToken;
