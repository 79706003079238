import React from 'react';
import styled, { keyframes } from 'styled-components';
import { VERTICAL_BORDER_PX } from './constants';

const getTextScrollKeyframes = (startCoordinate, endCoordinate) => keyframes`
  from { transform: translateX(${startCoordinate}px); }
  to   { transform: translateX(${endCoordinate}px); }
`;

const AnimatedDiv = styled.div`
  position: absolute;
  top: ${VERTICAL_BORDER_PX}px;
  animation-delay: -${(p) => p.duration * p.initialPlayPosition}s;
  animation-duration: ${(p) => p.duration}s;
  animation-iteration-count: infinite;
  animation-name: ${(p) =>
    p.isReverse
      ? getTextScrollKeyframes(p.targetPosition, -p.blockWidth)
      : getTextScrollKeyframes(-p.blockWidth, p.targetPosition)};
  animation-play-state: ${(p) => (p.isAnimationPaused ? 'paused' : 'running')};
  animation-timing-function: linear;
`;

const ScaledSpan = styled.span`
  display: inline-block;
  transform: ${(p) => `scaleX(${p.scaleX})`};
  transform-origin: ${(p) => (p.isReverse ? 'left' : 'right')};
`;

const SessionWatermarkTemplateBlockMarquee = (props) => {
  const {
    children,
    isAnimationPaused,
    isReverse,
    scaleX,
    targetPosition,
  } = props;
  const clientRectRef = React.useRef();
  const [blockWidth, setBlockWidth] = React.useState(0);
  React.useLayoutEffect(() => {
    if (clientRectRef.current) {
      const { width } = clientRectRef.current.getBoundingClientRect();
      setBlockWidth(Math.round(width));
    }
  }, [children, scaleX]);

  return (
    <React.Fragment>
      <ScaledSpan scaleX={scaleX} isReverse={isReverse}>
        {'\u00A0'}
      </ScaledSpan>

      <AnimatedDiv
        blockWidth={blockWidth}
        duration={20}
        initialPlayPosition={isAnimationPaused ? 0.5 : 0.25}
        isAnimationPaused={isAnimationPaused}
        isReverse={isReverse}
        targetPosition={Math.round(targetPosition)}
      >
        <ScaledSpan scaleX={scaleX} isReverse={isReverse}>
          <span ref={clientRectRef}>{children}</span>
        </ScaledSpan>
      </AnimatedDiv>
    </React.Fragment>
  );
};

export default SessionWatermarkTemplateBlockMarquee;
