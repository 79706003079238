import { connect } from 'react-redux';
import queryString from 'query-string';
import { confirmEmail } from './actions';

function ConfirmEmailContainer({
  confirmEmail: dispatchConfirmEmail,
  location,
}) {
  const { e: email, token: tokenId } = queryString.parse(location.search);
  dispatchConfirmEmail(tokenId, email);

  return null;
}

export default connect(
  null,
  { confirmEmail }
)(ConfirmEmailContainer);
