import React from 'react';
import Raven from 'raven-js';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { color } from '@frameio/components/src/theme/darkTheme';
import { type as assetType } from '@frameio/core/src/assets/helpers/constants';
import AudioIcon from '@frameio/components/src/svgs/icons/audio.svg';
import FileIcon from '@frameio/components/src/svgs/icons/file.svg';
import FolderIcon from '@frameio/components/src/svgs/icons/folder.svg';
import ImageIcon from '@frameio/components/src/svgs/icons/image.svg';
import VideoIcon from '@frameio/components/src/svgs/icons/video.svg';
import { alignCenter } from '@frameio/components/src/mixins';
import WarningIcon from '@frameio/components/src/svgs/icons/warning.svg';
import WithTooltip from '@frameio/components/src/components/WithTooltip';

import ErrorBoundary from 'components/ErrorBoundary';
import ScrubImage from 'components/AssetSlider/SliderThumbs/ScrubImage';
import ThumbImage from 'components/AssetSlider/SliderThumbs/ThumbImage';
import {
  isVideo as isVideoType,
  isAudio as isAudioType,
  isImage as isImageType,
} from '@frameio/core/src/assets/helpers/mediaTypes';
import getColorForExtension from './colors';

const StyledWarningIcon = styled(WarningIcon)`
  color: ${(p) => p.theme.color.error};
`;
const ThumblessContainer = styled.div`
  ${alignCenter()};
  text-transform: capitalize;
  line-height: 1;

  ${({ width, height, backgroundColor, color: fontColor }) => `
    width: ${typeof width === 'number' ? `${width}px` : width};
    height: ${typeof height === 'number' ? `${height}px` : height};
    background-color: ${backgroundColor};
    color: ${fontColor};
  `}
`;

export const FOLDER_SHARING_ICON_CLASSNAME = 'Thumb__FolderSharingIcon';

export function getAssetIcon(asset) {
  const isFolder = asset.type === assetType.FOLDER;
  if (isFolder) {
    return <FolderIcon color={color.graphiteGray} width={18} height={18} />;
  }
  if (isVideoType(asset)) {
    return <VideoIcon width={22} height={16} color="#756FFF" />;
  }
  if (isAudioType(asset)) {
    return <AudioIcon width={22} height={16} color="#FF7373" />;
  }
  if (isImageType(asset)) {
    return <ImageIcon width={16} height={16} color="#4CDBBC" />;
  }
  return <FileIcon width={20} height={20} color="#6CC8FF" />;
}

/**
 * Renders a thumbnail for an asset based on its type.
 * TODO(joel): Migrate the constituent thumbs as well.
 * @param {Object} props - Props for the commponent.
 * @return {ReactElement} The generated AssetThumb.
 */
function AssetThumb({
  asset,
  backgroundColor,
  folderIcon,
  folderSharingEnabled,
  height,
  width,
  className,
  scrubberBarWidth,
  style,
}) {
  const {
    cover_asset: coverAsset,
    thumb,
    thumb_scrub: scrubImageUrl,
    type,
  } = asset;

  const isVersionStack = type === assetType.VERSION_STACK;
  const showFolderSharingIcon =
    folderSharingEnabled && folderIcon && type === assetType.FOLDER;

  /*
    BUGS-1239: This is an interim fix to prevent deleted version stack cover assets
    from erroring out the entire app. Once the root cause for the data inconsistency
    is identified and fixed, this can potentially be removed.
  */
  const renderErrorTooltip = () => {
    return (
      <ThumblessContainer height={height} width={width}>
        <WithTooltip message="There was an issue rendering this asset thumb">
          <div>
            <StyledWarningIcon />
          </div>
        </WithTooltip>
      </ThumblessContainer>
    );
  };

  const onError = () => {
    Raven.captureMessage(`AssetThumb rendering error`, {
      level: 'error',
      extra: { asset, coverAsset },
    });
  };

  if (isVersionStack && coverAsset) {
    return (
      <ErrorBoundary onError={onError} fallback={renderErrorTooltip}>
        <AssetThumb
          asset={coverAsset}
          backgroundColor={backgroundColor}
          height={height}
          width={width}
          className={className}
          scrubberBarWidth={scrubberBarWidth}
          style={style}
        />
      </ErrorBoundary>
    );
  }

  const isVideo = isVideoType(asset);

  if (thumb) {
    if (isVideo && scrubberBarWidth && scrubImageUrl) {
      return (
        <ScrubImage
          numFrames={asset.transcodes.thumbs}
          coverImageUrl={thumb}
          scrubImageUrl={scrubImageUrl}
          height={height}
          width={width}
          className={className}
          scrubberBarWidth={scrubberBarWidth}
          style={style}
        />
      );
    }
    return (
      <ThumbImage
        imageUrl={thumb}
        height={height}
        width={width}
        className={className}
        style={style}
      />
    );
  }

  const extension = asset.name
    ? asset.name.slice(asset.name.lastIndexOf('.') + 1)
    : '';
  const extensionColor = getColorForExtension(extension);

  return (
    <ThumblessContainer
      height={height}
      width={width}
      backgroundColor={showFolderSharingIcon ? undefined : backgroundColor}
      className={classNames(className, {
        [FOLDER_SHARING_ICON_CLASSNAME]: showFolderSharingIcon,
      })}
      style={style}
      color={extensionColor}
    >
      {showFolderSharingIcon ? folderIcon : getAssetIcon(asset)}
    </ThumblessContainer>
  );
}

AssetThumb.propTypes = {
  asset: PropTypes.shape({
    name: PropTypes.string,
    thumb: PropTypes.string,
    thumb_scrub: PropTypes.string,
    transcodes: PropTypes.shape({
      thumbs: PropTypes.number,
    }),
    asset_type: PropTypes.string,
    includes: PropTypes.arrayOf(PropTypes.string),
    cover_asset: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        type: PropTypes.oneOf(Object.values(assetType)),
      }),
    ]),
    type: PropTypes.oneOf(Object.values(assetType)),
  }).isRequired,
  backgroundColor: PropTypes.string,
  scrubberBarWidth: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  style: PropTypes.object,
};

AssetThumb.defaultProps = {
  className: '',
  backgroundColor: color.dimGray,
  height: 0,
  width: 0,
  scrubberBarWidth: 1,
  style: undefined,
};

export default styled(AssetThumb)``;

export const testExports = {
  AudioIcon,
  FileIcon,
  FolderIcon,
  ImageIcon,
  ThumblessContainer,
  VideoIcon,
};
