import React from 'react';
import PropTypes from 'prop-types';

/**
 * ProjectIcon
 * Note: This icon svg is directly embedded so that I can control
 * its selected / non-selected state transitions a bit easier.
 */
function ProjectIcon({ isSelected }) {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="project-icon-copy"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M0.760888695,5.89510235 L1.67515385,12.9110683 C1.77064179,13.6438319 2.46325417,14.25 3.2003514,14.25 L12.7993129,14.25 C13.5360229,14.25 14.2295221,13.6399981 14.3245105,12.9110683 L15.2387756,5.89510235 C15.3244784,5.23742936 14.8956751,4.75 14.2372312,4.75 L1.76243316,4.75 C1.101667,4.75 0.675360817,5.23877122 0.760888695,5.89510235 Z"
          id="Project-icon"
          stroke="#7D829C"
          strokeWidth="1.5"
          fill={isSelected ? '#7D829C' : null}
        />
        <path
          d="M7.18801652,10.542433 L8.64712134,9.5 L7.18801652,8.45756698 L7.18801652,10.542433 Z"
          id="Triangle-11"
          stroke={isSelected ? 'white' : '#7D829C'}
          strokeWidth="1.5"
          fill="#7D829C"
        />
        <line
          x1="4"
          y1="0.517716577"
          x2="12"
          y2="0.517716577"
          id="Line-2-Copy"
          stroke="#7D829C"
          strokeLinecap="round"
        />
        <line
          x1="2.25"
          y1="2.56063967"
          x2="13.75"
          y2="2.56063967"
          id="Line-2"
          stroke="#7D829C"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}

ProjectIcon.propTypes = {
  isSelected: PropTypes.bool,
};

export default ProjectIcon;
