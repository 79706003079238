import { connect } from 'react-redux';
import { currentAccountSelector } from 'selectors/accounts';
import { isAccountAdminSelector } from 'selectors/roles';
import { upgradePlan } from './actions';
import ResolutionPickerButton from './ResolutionPickerButton';

function mapStateToProps(state) {
  const { id: accountId } = currentAccountSelector(state);
  const isAdmin = isAccountAdminSelector(state, {
    accountId,
  });

  return {
    isAdmin,
  };
}

const mapDispatchToProps = {
  upgradePlan,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResolutionPickerButton);
