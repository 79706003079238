import { createSelector } from 'reselect';
import { status } from '@frameio/core/src/shared/reducers/factories';
import { userEntitiesByUserIdsSelector } from '@frameio/core/src/users/selectors';
import { TYPE } from 'components/UserSearch/UserSearch';
import { projectContainerSelector } from '../selectors';

const { PENDING } = status;

const managePeopleSelector = (state) =>
  projectContainerSelector(state).managePeople;

export const isOpenSelector = (state) => managePeopleSelector(state).isOpen;

const manageUsersSelector = (state) => managePeopleSelector(state).manageUsers;

export const collaboratorsFetchStatusSelector = (state) =>
  manageUsersSelector(state).fetchStatus.collaborators;

export const pendingCollaboratorsFetchStatusSelector = (state) =>
  manageUsersSelector(state).fetchStatus.pendingCollaborators;

const isFetchingSelector = createSelector(
  collaboratorsFetchStatusSelector,
  pendingCollaboratorsFetchStatusSelector,
  (collabStatus, pendingCollabStatus) =>
    collabStatus === PENDING || pendingCollabStatus === PENDING
);

export const collaboratorsIdsSelector = (state) =>
  manageUsersSelector(state).collaborators;

export const pendingCollaboratorsIdsSelector = (state) =>
  manageUsersSelector(state).pendingCollaborators;

export const personIdsSelector = createSelector(
  collaboratorsIdsSelector,
  pendingCollaboratorsIdsSelector,
  (collaboratorIds, pendingCollaboratorIds) => [
    ...pendingCollaboratorIds,
    ...collaboratorIds,
  ]
);

export const resendInviteStatusSelector = (state, { email }) =>
  managePeopleSelector(state).resendInviteEmails[email];

export const isInvitingUsersSelector = (state) =>
  managePeopleSelector(state).isInvitingUsers;

export const isUpdatingShareLinkSelector = (state) =>
  managePeopleSelector(state).isUpdatingShareLink;

export const totalCountSelector = createSelector(
  isFetchingSelector,
  manageUsersSelector,
  (isFetching, { totalCollaborators, totalPendingCollaborators }) =>
    isFetching ? undefined : totalCollaborators + totalPendingCollaborators
);

export const peopleListScrollTopSelector = (state) =>
  managePeopleSelector(state).peopleListScrollTop;

export const newUserCountSelector = (state) => {
  const tokens = managePeopleSelector(state).tokens;

  const userTokenIds = tokens
    .filter((token) => token?.type === TYPE.USER)
    .map((token) => token.id);

  const userEntities = userEntitiesByUserIdsSelector(state, {
    userIds: userTokenIds,
  });
  const usersWithReviewerAsHighestAccountRole = userEntities.filter(
    (user) => user.highest_account_role === 'reviewer'
  );

  const emailTokens = tokens.filter((token) => token?.type === TYPE.EMAIL);

  return emailTokens.length + usersWithReviewerAsHighestAccountRole.length;
};
