import { Formik } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { debounce, keys, partial } from 'lodash';
import Aspect11SVG from '@frameio/components/src/svgs/icons/16/aspect-ratio_1-1.svg';
import Aspect43SVG from '@frameio/components/src/svgs/icons/16/aspect-ratio_4-3.svg';
import Aspect916SVG from '@frameio/components/src/svgs/icons/16/aspect-ratio_9-16.svg';
import Aspect169SVG from '@frameio/components/src/svgs/icons/16/aspect-ratio_16-9.svg';
import Aspect2391SVG from '@frameio/components/src/svgs/icons/16/aspect-ratio_239-1.svg';
import { MEDIUM_DOWN } from 'utils/mediaQueries';
import img01x01 from 'images/placeholder-01x01.jpg';
import img04x03 from 'images/placeholder-04x03.jpg';
import img09x16 from 'images/placeholder-09x16.jpg';
import img16x09 from 'images/placeholder-16x09.jpg';
import img239 from 'images/placeholder-239.jpg';
import SessionWatermarkTemplateColumnLayout from './SessionWatermarkTemplateColumnLayout';
import SessionWatermarkTemplateGridLayout from './SessionWatermarkTemplateGridLayout';
import useWatermarkEntity from './useWatermarkEntity';
import useSubmit from './useSubmit';
import validationSchemaFactory from './validationSchema';
import { DEFAULT_NEW_BLOCK_STATE } from './constants';
import useCloseConfirmationDialog from './useCloseConfirmationDialog';

export const PREVIEW_IMAGE_UPDATE_TIMEOUT = 250;

const ASPECT_RATIO_1_1 = '1:1';
const ASPECT_RATIO_16_9 = '16:9';
const ASPECT_RATIO_239 = '2.39:1';
const ASPECT_RATIO_4_3 = '4:3';
const ASPECT_RATIO_9_16 = '9:16';

export const placeholdersByAspectRatio = {
  [ASPECT_RATIO_1_1]: {
    icon: Aspect11SVG,
    naturalHeight: 650,
    naturalWidth: 650,
    src: img01x01,
  },
  [ASPECT_RATIO_4_3]: {
    icon: Aspect43SVG,
    naturalHeight: 431,
    naturalWidth: 574,
    src: img04x03,
  },
  [ASPECT_RATIO_9_16]: {
    icon: Aspect916SVG,
    naturalHeight: 650,
    naturalWidth: 365,
    src: img09x16,
  },
  [ASPECT_RATIO_16_9]: {
    icon: Aspect169SVG,
    naturalHeight: 431,
    naturalWidth: 765,
    src: img16x09,
  },
  [ASPECT_RATIO_239]: {
    icon: Aspect2391SVG,
    naturalHeight: 430,
    naturalWidth: 1029,
    src: img239,
  },
};

export const aspectRatioOrder = [
  ASPECT_RATIO_239,
  ASPECT_RATIO_16_9,
  ASPECT_RATIO_4_3,
  ASPECT_RATIO_1_1,
  ASPECT_RATIO_9_16,
];

function SessionWatermarkTemplateEditor(props) {
  const {
    initialValues,
    isDevToolsAllowed,
    watermarkTemplateId,
    isShareOnlyTemplate,
    isAdmin,
  } = props;
  const [isFrameGuideEnabled, setIsFrameGuideEnabled] = React.useState(
    isShareOnlyTemplate
  );
  const [isDebugModeEnabled, setIsDebugModeEnabled] = React.useState(false);
  const [aspectRatio, setAspectRatio] = React.useState(ASPECT_RATIO_16_9);
  const openCloseConfirmationDialog = useCloseConfirmationDialog();

  const sessionWatermarkTemplateEntity =
    useWatermarkEntity(watermarkTemplateId) || {};

  const effectiveInitialValues = {
    activeBlockId: keys(sessionWatermarkTemplateEntity.watermark_blocks)[0],
    watermark_blocks: {},
    ...sessionWatermarkTemplateEntity,
    ...initialValues,
    name:
      sessionWatermarkTemplateEntity.name ||
      initialValues.name ||
      'My custom watermark',
  };

  if (!watermarkTemplateId) {
    const initialBlockId = 'client-created-block-0';
    effectiveInitialValues.watermark_blocks[
      initialBlockId
    ] = DEFAULT_NEW_BLOCK_STATE;
    effectiveInitialValues.activeBlockId = initialBlockId;
  }

  const [viewportSize, setCurrentViewportSize] = React.useState([0, 0]);
  const nameFieldRef = React.useRef();

  // Store the initial aspect ratio state and image dimensions as local state to use for state hooks
  // We only need to track when the aspect ratio changes, not the actual value. This prevents issues with updates failing due to unexpected data shapes.
  const [aspectRatioChange, setAspectRatioChange] = React.useState(false);

  // Due to needing some buffer time for DOM calculations, we want to use some local state to set/get when the preview image is updating.
  // This state callback gets passed to the reusable hook to calculate image dimensions
  const [previewImageIsUpdating, setPreviewImageIsUpdating] = React.useState(
    true
  );

  // Watch window resize and set the current viewport size on first render
  React.useLayoutEffect(() => {
    const onResize = () => {
      setPreviewImageIsUpdating(true);
      setCurrentViewportSize([window.innerWidth, window.innerHeight]);
    };

    const debouncedOnResize = debounce(
      onResize,
      PREVIEW_IMAGE_UPDATE_TIMEOUT / 2,
      {
        leading: true,
      }
    );

    window.addEventListener('resize', debouncedOnResize);

    setTimeout(() => {
      setCurrentViewportSize([window.innerWidth, window.innerHeight]);
    }, PREVIEW_IMAGE_UPDATE_TIMEOUT);

    return () =>
      window.removeEventListener('resize', onResize, { passive: true });
  }, []);

  const getMessage = React.useCallback((args) => {
    const { isGenericError, isNewTemplate } = args;
    let message = 'Watermark ID template updated';
    if (isGenericError) {
      message = "Sorry, couldn't save this template due to an error";
    } else if (isNewTemplate) {
      message = 'Saved new Watermark ID template';
    }
    return message;
  }, []);

  const onNameTakenError = React.useCallback((__values, formikHelpers) => {
    const { setFieldError } = formikHelpers;
    setFieldError('name', 'Template name already exists');
    nameFieldRef.current.focus();
  }, []);

  const onSubmit = useSubmit({
    getMessage,
    onNameTakenError,
    watermarkTemplateId,
  });

  // The small and large layouts get the same props, so reduce duplicate code by setting them once
  const initialPropsForComponents = {
    aspectRatio,
    setAspectRatio,
    isDevToolsAllowed,
    currentViewportSize: viewportSize,
    onPreviewImageUpdate: (isPreviewImageUpdating) => {
      setPreviewImageIsUpdating(isPreviewImageUpdating);
    },
    previewImageIsUpdating,
    onAspectRatioChange: () => {
      setAspectRatioChange(!aspectRatioChange);
    },
    aspectRatioChange,
    effectiveInitialValues,
    isFrameGuideEnabled,
    onFrameGuideChange: (frameGuideShouldBeEnabled) => {
      setIsFrameGuideEnabled(frameGuideShouldBeEnabled);
    },
    isDebugModeEnabled,
    onDebugModeChange: (debugModeShouldBeEnabled) =>
      setIsDebugModeEnabled(debugModeShouldBeEnabled),
    nameFieldRef,
  };

  /** TODO: Reduce code duplication further by passing down some sub-components as props */
  return (
    <Formik
      validateOnMount
      initialValues={effectiveInitialValues}
      validationSchema={() =>
        validationSchemaFactory({
          shouldValidateSafeZone: isShareOnlyTemplate,
        })
      }
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        dirty,
        errors,
        handleSubmit,
        isValid,
        setFieldValue,
        values,
        setValues,
      }) => {
        // Pass along Formik args to the component as props along with the initial set of props
        const composedPropsForComponents = {
          ...initialPropsForComponents,
          dirty,
          errors,
          handleSubmit,
          isAdmin,
          isShareOnlyTemplate,
          isValid,
          onClose: partial(
            openCloseConfirmationDialog,
            watermarkTemplateId,
            dirty
          ),
          setFieldValue,
          setValues,
          values,
        };

        return (
          <Media query={MEDIUM_DOWN}>
            {(matches) =>
              matches ? (
                <SessionWatermarkTemplateColumnLayout
                  {...composedPropsForComponents}
                />
              ) : (
                <SessionWatermarkTemplateGridLayout
                  {...composedPropsForComponents}
                />
              )
            }
          </Media>
        );
      }}
    </Formik>
  );
}

SessionWatermarkTemplateEditor.defaultProps = {
  initialValues: {},
  isDevToolsAllowed: false,
};

SessionWatermarkTemplateEditor.propTypes = {
  initialValues: PropTypes.object,
  isDevToolsAllowed: PropTypes.bool,
  watermarkTemplateId: PropTypes.string,
  isShareOnlyTemplate: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default SessionWatermarkTemplateEditor;
