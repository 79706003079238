import { connect } from 'react-redux';
import {
  accountIdForPresentationIdSelector,
  teamEntityForAssetIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import { showSuccessToast } from 'actions/toasts';
import { closeModal } from 'components/Modal/actions';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import PresentationEditor from './PresentationEditor';
import { updatePresentation } from './actions';
import {
  isBlockingReelPlayerSelector,
  presentationForEditorSelector,
} from './selectors';

const mapDispatchToProps = {
  updatePresentation,
  closeModal,
  showLinkCopiedToast: showSuccessToast,
};

function mapStateToProps(state, { assetId }) {
  const presentation = presentationForEditorSelector(state, { assetId });
  const accountId = accountIdForPresentationIdSelector(state, {
    presentationId: presentation.id,
  });
  const selectedAsset = assetEntitySelector(state, { assetId });

  return {
    accountId,
    isBlockingReelPlayer: isBlockingReelPlayerSelector(state),
    team: teamEntityForAssetIdSelector(state, { assetId }),
    presentation,
    selectedAsset,
    title: presentation.title,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PresentationEditor);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
