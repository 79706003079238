import { generateActionTypes } from 'shared/actions/helpers';
import { createAction } from 'redux-actions';

const actions = [
  'FETCH_DATA',
  'UPDATE_GROUP',
  'CREATE_GROUP',
  'CREATE_OR_UPDATE_GROUP_ERROR',
  'DELETE_GROUP',
  'OPEN_CREATE_MODAL',
  'OPEN_EDIT_GROUP_MODAL',
];

export const GROUPS_MODAL = generateActionTypes('GROUPS_MODAL', actions);

export const fetchUserGroups = createAction(
  GROUPS_MODAL.FETCH_DATA,
  () => ({})
);

export const openGroupModal = createAction(
  GROUPS_MODAL.OPEN_CREATE_MODAL,
  () => ({})
);

export const createUserGroup = createAction(
  GROUPS_MODAL.CREATE_GROUP,
  (params) => ({ params })
);

export const createOrUpdateGroupError = createAction(
  GROUPS_MODAL.CREATE_OR_UPDATE_GROUP_ERROR,
  (error) => ({ error })
);

export const updateUserGroup = createAction(
  GROUPS_MODAL.UPDATE_GROUP,
  (params) => ({ params })
);

export const openEditGroupModal = createAction(
  GROUPS_MODAL.OPEN_EDIT_GROUP_MODAL,
  (groupId) => ({ groupId })
);

export const deleteUserGroup = createAction(
  GROUPS_MODAL.DELETE_GROUP,
  (groupId) => ({ groupId })
);
