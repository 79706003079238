import { connect } from 'react-redux';
import { get } from 'lodash';
import { hydratedAssetEntitySelector } from '@frameio/core/src/assets/selectors';
import {
  permittedActionsForAssetsSelector,
  permittedActionsForProjectSelector,
} from 'selectors/permissions';
import { isProjectArchivedSelector } from 'selectors/projects';

import { downloadAssets, downloadAsset } from 'actions/assets';
import { isVideo as isAssetVideo } from '@frameio/core/src/assets/helpers/mediaTypes';

import DownloadAssets from './DownloadAssets';

function mapStateToProps(state, { assetIds, projectId }) {
  let assetWithProxies;

  if (assetIds.length === 1) {
    const hydratedAsset = hydratedAssetEntitySelector(state, {
      assetId: assetIds[0],
    });
    assetWithProxies = hydratedAsset.cover_asset || hydratedAsset;
    const hasProxies =
      get(assetWithProxies, 'transcodes.resolutions.length', 0) > 0;
    if (!isAssetVideo(assetWithProxies) || !hasProxies) {
      assetWithProxies = undefined;
    }
  }

  const isProjectArchived = isProjectArchivedSelector(state, { projectId });
  const { isForensicFallbackEnabled } = permittedActionsForProjectSelector(
    state,
    { projectId }
  );
  const permissions = permittedActionsForAssetsSelector(state, {
    projectId,
    assetIds,
  });

  return {
    canDownloadOriginalCleanAsset: permissions.canDownloadOriginalCleanAsset,
    isForensicFallbackEnabled,
    projectId,
    assetWithProxies,
    isProjectArchived,
  };
}

const mapDispatchToProps = {
  downloadAssets,
  downloadAsset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadAssets);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
