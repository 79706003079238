import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import Raven from 'raven-js';
import { loadAnalytics } from 'analytics';
import {
  formatOneTrustElements,
  getGDPRCookieCategories,
  addHooksToOneTrustButtons,
  configureSegmentOt,
} from '@frameio/segment-ot';
import config from 'config';
import { currentUserEntitySelector } from 'selectors/users';
import { updateCookieCategories } from '@frameio/core/src/users/actions';
import { useIsSegmentLoaded } from 'shared/context';
import { identifyUser } from './actions';

const useLoadSegment = () => {
  const { isSegmentLoaded, setSegmentLoaded } = useIsSegmentLoaded();
  const dispatch = useDispatch();

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://www.adobe.com/etc.clientlibs/globalnav/clientlibs/base/privacy-standalone.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = async () => {
      formatOneTrustElements();
      try {
        await loadAnalytics();
        addHooksToOneTrustButtons();
        window.analytics.ready(() => {
          setSegmentLoaded(true);
          dispatch(identifyUser());
        });
      } catch (e) {
        Raven.captureMessage('segment script failed to load', {
          level: 'warn',
          tags: { jira_ticket: 'V3FRAME-1189' },
        });
      }
    };

    script.onerror = () => {
      Raven.captureMessage('adobe privacy script failed to load', {
        level: 'warn',
        tags: { jira_ticket: 'V3FRAME-1189' },
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [dispatch, setSegmentLoaded]);

  return isSegmentLoaded;
};

const Analytics = () => {
  const dispatch = useDispatch();

  // Set up adobe one trust script
  window.fedsConfig = window.fedsConfig || {};
  window.fedsConfig.privacy = window.fedsConfig.privacy || {};
  window.fedsConfig.privacy.otDomainId = '42bd8eaa-3d2a-4607-bd4d-1746a6d7dc0c';
  window.fedsConfig.privacy.footerLinkSelector = '#manage-cookies';

  // Set Intercom settings before loading Segment
  window.intercomSettings = {
    app_id: config.intercomAppId,
    hide_default_launcher: true,
    custom_launcher_selector: '#intercom-launcher',
    fio_version: 3,
  };

  const isSegmentLoaded = useLoadSegment();
  const user = useSelector(currentUserEntitySelector);
  const isUserLoaded = Boolean(user);
  const isUserAnon = user?._type === 'anonymous_user';
  const massdriverCategories = user?.cookie_categories;
  const isEdu = user?.is_edu || false;

  useEffect(() => {
    function fetchData() {
      // we only want to run this useEffect after the adobe privacy script, segment script and
      // user have all loaded
      if (!isSegmentLoaded || !isUserLoaded || isUserAnon) {
        return;
      }

      configureSegmentOt({ isAdobeEdu: isEdu });
      addHooksToOneTrustButtons(updateCookieCategories);

      const cookieCategories = getGDPRCookieCategories(true);

      // V3FRAME-254 - Handle updating massdriver if things are out of sync.
      // There's an issue that isn't related to this. When the site reloads, the adobe privacy script
      // fires off `adobePrivacy:PrivacyConsent` event which causes it to update the massdriver
      // integrations. This comparison does work as intended.
      if (!isEqual(massdriverCategories, cookieCategories)) {
        dispatch(updateCookieCategories(cookieCategories));
      }
    }

    fetchData();
  }, [
    dispatch,
    isEdu,
    isUserAnon,
    isUserLoaded,
    isSegmentLoaded,
    massdriverCategories,
  ]);

  return null;
};

export const testExports = {
  Analytics,
};

export default Analytics;
