import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

import { generatePaginatedListReducer } from '@frameio/core/src/shared/reducers/factories';
import DELETED_ASSETS from './actions';

const assetsInTeam = generatePaginatedListReducer(
  DELETED_ASSETS.SEARCH_IN_TEAM
);
const assetsInProject = reduceReducers(
  generatePaginatedListReducer(DELETED_ASSETS.GET_IN_PROJECT),
  generatePaginatedListReducer(DELETED_ASSETS.SEARCH_IN_PROJECT)
);

function selectedAssetIds(state = [], { type, payload }) {
  return type === DELETED_ASSETS.SELECT_ASSETS ? payload.assetIds : state;
}

export default combineReducers({
  assetsInTeam,
  assetsInProject,
  selectedAssetIds,
});
