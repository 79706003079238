import { types as dragAndDropTypes } from 'utils/dragAndDrop';

export const dragSourceSpec = {
  beginDrag({ asset, index }) {
    return { asset, index };
  },
};

export const mapDragStateToProps = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isCurrentAssetDragging: monitor.isDragging(),
});

export const dropTargetSpec = {
  hover({ asset, index: targetIndex, onDragOver }, monitor) {
    const { asset: source } = monitor.getItem();

    if (!monitor.isOver() && asset.id !== source.id) {
      onDragOver(source.id, targetIndex);
    }
  },

  drop({ asset, index: targetIndex, onAssetDropped }) {
    onAssetDropped(asset.id, targetIndex);
  },
};

export const mapDropStateToProps = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isDropZone:
    monitor.getItemType() === dragAndDropTypes.VERSIONS &&
    monitor.isOver({ shallow: true }),

  // if monitor is attatched to any item then an item is being dragged
  // use this to let <Version /> disable styles on :hover state. we do
  // this to work around a browser bug in the native drag and drop API
  // that causes :hover state be incorrectly applied to elements during
  // a drag event.
  // see: https://bugs.chromium.org/p/chromium/issues/detail?id=410328
  // and https://bugs.webkit.org/show_bug.cgi?id=134555 for more info
  isDraggingInProgress: !!monitor.getItem(),
});
