import React from 'react';
import PropTypes from 'prop-types';
import { trackButtonClick } from 'analytics';
import { MenuButton } from '@frameio/vapor';
import CopyToFolderIcon from '@frameio/components/src/svgs/icons/16/copy.svg';

export default function CopyAssets({
  assetIds,
  projectId,
  promptCopyToFolder,
  ...rest
}) {
  return (
    <MenuButton
      onSelect={() => {
        promptCopyToFolder(assetIds);
        trackButtonClick('copy to folder', 'project arena', 'middle');
      }}
      iconBefore={<CopyToFolderIcon width={16} height={16} />}
      {...rest}
    >
      Copy to&hellip;
    </MenuButton>
  );
}

CopyAssets.propTypes = {
  assetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  promptCopyToFolder: PropTypes.func.isRequired,
};
