import { useEffect } from 'react';
import useCloseConfirmationDialog from './useCloseConfirmationDialog';

export default function useCloseHotkey(isDirty) {
  const openCloseConfirmationDialog = useCloseConfirmationDialog();

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopImmediatePropagation();

        openCloseConfirmationDialog(undefined, isDirty);
      }
    }

    window.addEventListener('keydown', handleKeyDown, { capture: true });

    return () =>
      window.removeEventListener('keydown', handleKeyDown, { capture: true });
  }, [openCloseConfirmationDialog, isDirty]);
}
