import { connect } from 'react-redux';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { registerAnonymousUser } from '@frameio/core/src/users/actions';
import { userEntitySelector } from '@frameio/core/src/users/selectors';
import { reviewLinkEntitySelector } from '@frameio/core/src/reviewLinks/selectors';
import RegistrationForm from './RegistrationForm';
import { cancelAnonUserSignup, lookUpAnonUserEmail } from '../actions';
import { reviewLinkShowNameInputSelector } from '../selectors';

export const mapDispatchToProps = (dispatch, { match }) => ({
  onSubmitEmail: (email) => {
    dispatch(lookUpAnonUserEmail(match.params.reviewLinkId, email));
  },
  onSubmit: (values) => {
    const { reviewLinkId } = match.params;
    dispatch(registerAnonymousUser(reviewLinkId, values));
  },
  onCancel: () => dispatch(cancelAnonUserSignup()),
});

export function mapStateToProps(state, { match }) {
  const { reviewLinkId } = match.params;
  const reviewLink = reviewLinkEntitySelector(state, { reviewLinkId });
  const userId = get(reviewLink, 'owner_id');
  const user = userEntitySelector(state, { userId });

  const ownerName = get(user, 'name');
  return {
    ownerName,
    showNameInput: reviewLinkShowNameInputSelector(state),
    reviewLinkId,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegistrationForm)
);
