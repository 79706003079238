import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { size } from 'polished';
import { Transformer } from '@frameio/components';
import { absoluteFill, alignCenter } from '@frameio/components/src/mixins';
import FrameioLogo from '@frameio/components/src/svgs/logos/logo-light.svg';
import { MARKETING_URL } from 'URLs';

const TEAM_IMAGE_SIZE = '80px';
const LOGO_WIDTH = '93px';
const LOGO_HEIGHT = '22px';
const SPACING = '10px';

const Container = styled.div`
  ${absoluteFill()}
  ${alignCenter()}
  background-color: ${(p) => p.theme.color.almostBlack};
  padding: ${SPACING};
`;

const LogoContainer = styled.a`
  position: absolute;
  left: ${SPACING};
  top: 9px;
  ${size(LOGO_HEIGHT, LOGO_WIDTH)}
`;

const TeamImageContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  ${size(TEAM_IMAGE_SIZE)}
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px;
`;

const ReviewLinkPromptPage = ({ team, children }) => (
  <Transformer className="absolute absolute--fill" isVisible>
    <Container>
      <LogoContainer
        href={MARKETING_URL}
        rel="noopener noreferrer"
        target="_blank"
      >
        <FrameioLogo width={93} height={22} />
      </LogoContainer>
      {team && (
        <TeamImageContainer style={{ backgroundImage: `url(${team.image})` }} />
      )}
      <div>{children}</div>
    </Container>
  </Transformer>
);

ReviewLinkPromptPage.defaultProps = {
  team: null,
};

ReviewLinkPromptPage.propTypes = {
  team: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default ReviewLinkPromptPage;
