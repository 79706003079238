import { createSelector } from 'reselect';
import { teamEntitiesSelector } from '@frameio/core/src/teams/selectors';
import { projectEntitiesSelector } from '@frameio/core/src/projects/selectors';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';

export const backUrlSelector = (state) => state.playerContainer.backUrl;

export const copyCommentsSelector = (state) => state.playerContainer.copyFileId;
/**
 * @description - Selector to get showAllAnnotations value from state. (Synced with local storage).
 * @param   {Object} state - Redux store state.
 * @returns {boolean} - showAllAnnotations value in state.
 */
export const showAllAnnotationsSelector = (state) =>
  state.playerContainer.showAllAnnotations;

/**
 * @description - Selector to get the value of whether comments should be displayed. (Synced with local storage).
 * @param   {Object} state - Redux store state.
 * @returns {boolean} - showComments value in state.
 */
export const shouldShowCommentsSelector = (state) =>
  state.playerContainer.showComments;

export const defaultPlaybackRateSelector = (state) =>
  state.playerContainer.defaultPlaybackRate;

export const newestCommentIdSelector = (state) =>
  state.playerContainer.newestCommentCreatedId;

export const preloadMediaTimeSelector = (state) =>
  state.playerContainer.initialMediaTime;

export const playerContainerAccountIdSelector = (state) =>
  state.playerContainer.accountId;

export const playerContainerProjectIdSelector = (state) =>
  state.playerContainer.projectId;
export const playerContainerProjectEntitySelector = createSelector(
  projectEntitiesSelector,
  playerContainerProjectIdSelector,
  (projects, projectId) => projects[projectId]
);

export const playerContainerTeamIdSelector = (state) =>
  state.playerContainer.teamId;
export const playerContainerTeamEntitySelector = createSelector(
  teamEntitiesSelector,
  playerContainerTeamIdSelector,
  (teams, teamId) => teams[teamId]
);

export const playerContainerFolderIdSelector = (state) =>
  state.playerContainer.folderId;

/**
 * Gets parent folder name of the PlayerContainer asset.
 * TODO(Anna): Move this into core if there is more of a use case
 * for retrieving the parent folder name/entity.
 * @param {Object} state - Redux store state.
 * @returns {string} Parent folder name.
 */
export const parentFolderNameSelector = createSelector(
  (state) => state,
  playerContainerProjectEntitySelector,
  playerContainerFolderIdSelector,
  (state, project, folderId) => {
    if (!project) return undefined;
    return project.root_asset_id === folderId
      ? project.name
      : (assetEntitySelector(state, { assetId: folderId }) || {}).name;
  }
);
