import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const DESKTOP_APP = generateActionTypes('DESKTOP_APP', [
  'CANCEL_MODAL',
  'CONFIRM_DOWNLOAD_IN_APP',
  'CONFIRM_DOWNLOAD_IN_BROWSER',
  'OPEN_URL_IN_DESKTOP_APP',
]);

export const cancelModal = createAction(DESKTOP_APP.CANCEL_MODAL, () => ({}));

export const confirmDownloadInDesktopApp = createAction(
  DESKTOP_APP.CONFIRM_DOWNLOAD_IN_APP,
  () => ({})
);

export const confirmDownloadInBrowser = createAction(
  DESKTOP_APP.CONFIRM_DOWNLOAD_IN_BROWSER,
  () => ({})
);

export const openURLInDesktopApp = createAction(
  DESKTOP_APP.OPEN_URL_IN_DESKTOP_APP,
  (url) => ({ url })
);
