import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Select, MenuRadio } from '@frameio/vapor';
import { Input } from '@frameio/components/src/styled-components/TextInput';
import Button from '@frameio/components/src/styled-components/Button';
import DropdownButton from '@frameio/components/src/styled-components/Button/DropdownButton';
import CloseSVG from '@frameio/components/src/svgs/raw/ic-circled-x-18px.svg';
import SearchSVG from '@frameio/components/src/svgs/icons/16/search-thin.svg';

const StyledSearchSVG = styled(SearchSVG)`
  color: ${(p) => p.theme.color.graphiteGray};
  display: block;
`;

const StyledInput = styled(Input)`
  padding-left: ${(p) => p.theme.spacing.units(4)};
  border-radius: ${(p) => p.theme.radius.large};
`;

const Wrapper = styled.div`
  position: relative;

  ${StyledSearchSVG} {
    position: absolute;
    left: 8px;
    top: 11px;
  }
`;

const PopoverFilterButton = styled(DropdownButton)`
  position: absolute;
  top: 1px;
  height: 37px;
  right: 0;
  width: 100px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid;
  border-color: ${(p) => p.theme.color.silver};
`;

const CloseIcon = styled(CloseSVG)`
  display: block;
  fill: ${(p) => p.theme.color.slateGray};
`;

const CloseButton = styled(Button)`
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  right: 110px;
  top: 50%;
  transform: translateY(-50%);
`;

export const FILTER_OPTIONS = {
  all: 'All',
  folders: 'Folders',
  projects: 'Projects',
  teams: 'Teams',
};

export const COLLABORATOR_MOVE_FILTER_OPTIONS = {
  folders: 'Folders',
};

const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
  window.HTMLInputElement.prototype,
  'value'
).set;

/**
 * Search input component with a filter dropdown menu.
 */

function Search({ onSelectFilter, filter, filterOptions, ...other }) {
  const ref = useRef();

  useEffect(() => {
    ref.current.focus();
  }, []);

  return (
    <Wrapper>
      <StyledInput ref={ref} {...other} />

      <StyledSearchSVG />

      {other.value && (
        <CloseButton
          icon
          text
          type="button"
          onClick={(event) => {
            event.stopPropagation();
            const input = event.currentTarget.parentElement.querySelector(
              'input'
            );
            nativeInputValueSetter.call(input, '');
            const evt = new Event('input', { bubbles: true });
            input.dispatchEvent(evt);
            input.focus();
          }}
        >
          <CloseIcon height="18px" width="18px" />
        </CloseButton>
      )}
      <Select
        value={filter}
        onChange={onSelectFilter}
        label="Filter by…"
        content={Object.keys(filterOptions).map((key) => (
          <MenuRadio name={key} key={key}>
            {filterOptions[key]}
          </MenuRadio>
        ))}
      >
        <PopoverFilterButton dropdown text>
          {FILTER_OPTIONS[filter]}
        </PopoverFilterButton>
      </Select>
    </Wrapper>
  );
}

Search.propTypes = {
  onSelectFilter: PropTypes.func.isRequired,
  filter: PropTypes.oneOf(Object.keys(FILTER_OPTIONS)).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default Search;
