import { map } from 'lodash';
import { padding, margin } from 'polished';
import styled from 'styled-components';

export const StyledBox = styled.div`
  ${(p) => padding(...map(p.padding, p.theme.spacing.units))}
  ${(p) => margin(...map(p.gutter, p.theme.spacing.units))}
`;

export const Text = styled(StyledBox)`
  ${(p) => p.theme.fontStyle[p.variant]};
  color: ${(p) => p.theme.color[p.color]};
  text-align: ${(p) => p.textAlign || 'left'};
`;
