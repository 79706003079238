import { takeLatest, put, call } from 'redux-saga/effects';
import Raven from 'raven-js';
import {
  createJoinRequestForTeam,
  getJoinRequestsByAccount as getJoinRequestsByAccountFromCore,
} from '@frameio/core/src/joinRequests/sagas';
import { TEAM } from 'actions/teams';
import { ACCOUNT as CLIENT_ACCOUNT } from 'actions/accounts';
import { closeModal } from 'components/Modal/actions';
import { showSuccessToast, showErrorToast } from 'actions/toasts/index';

export function* getJoinRequestsForAccount(action) {
  const { accountId } = action.payload;
  const config = {
    params: {
      'filter[type]': 'pending',
      include: 'review_link,presentation,project',
    },
  };
  if (!accountId) return;
  yield call(getJoinRequestsByAccountFromCore, accountId, config);
}

export function* requestToJoinTeam(action) {
  const { teamId } = action.payload;

  const { success, failure } = yield call(createJoinRequestForTeam, teamId);

  if (success) {
    yield put(closeModal());
    yield put(
      showSuccessToast({
        header: 'Successfully requested to join team',
      })
    );
  } else if (failure) {
    yield put(
      showErrorToast({
        header: 'Request to join team failed, please try again.',
      })
    );

    yield call([Raven, Raven.captureMessage], failure.payload.error);
  }
}

export default [
  takeLatest(CLIENT_ACCOUNT.SET_CURRENT, getJoinRequestsForAccount),
  takeLatest(TEAM.REQUEST_TO_JOIN, requestToJoinTeam),
];
