/**
 * @file Helper functions/enums for working with Limits/Plans Frame.io
 */

import { omit } from 'lodash';
import { BILL_PERIOD } from 'components/SelectPlanFlow/PlanDisplay';
import { LATEST_BILLING_VERSION } from 'config';
import { giga } from 'shared/filesizeHelpers';

// Default all trials to be of this length (at least in the copy displayed)
// TODO: move this value to be read from the API and a default promotion.
export const trialLength = 7;

export const plans = {
  FREE: 'Free',
  STARTER: 'Starter',
  PRO: 'Professional',
  PROFESSIONAL: 'Professional',
  TEAM: 'Team',
  BUSINESS: 'Business',
  ENTERPRISE: 'Enterprise',
  SPECIAL: 'Special',
};

const freePlanIds = {
  FREE_LEGACY: '20150306-01-free',
  FREE_LIFETIME_FILE_LIMIT: 'free-plan-with-lifetime-file-limit',
  FREE_PRICING_V2: '20190125-free-mo-one-seat-2GB',
  FREE_PRICING_V3: 'v3-free',
  FREE_PRICING_V4: 'v4-free',
  FREE_PRICING_V5: 'v5-free',
  FREE_YEARLY: 'free',
};

export const planIds = {
  ...freePlanIds,
  STARTER: '20181119-01-starter',
  PRO: '20181030-01-pro',
  PROFESSIONAL: '20181030-01-pro',
  TEAM: '20181030-01-team',
  BUSINESS: '20181030-01-business',
};

export const paidPlansIds = { ...planIds };
delete paidPlansIds.FREE_LEGACY;
delete paidPlansIds.FREE_LIFETIME_FILE_LIMIT;
delete paidPlansIds.FREE_PRICING_V2;
delete paidPlansIds.FREE_PRICING_V3;
delete paidPlansIds.FREE_PRICING_V4;
delete paidPlansIds.FREE_YEARLY;

export const planData = {
  [plans.FREE]: {
    collaborators: 5,
    projects: 1,
    storage: 2,
    members: 1,
    lifetime_file_count: 15,
    price: {
      monthly: 0,
      yearly: 0,
    },
  },
  [plans.STARTER]: {
    collaborators: 10,
    projects: 3,
    storage: 10,
    members: 1,
    lifetime_file_count: Infinity,
    price: {
      monthly: 19,
      yearly: 9,
    },
  },
  [plans.PRO]: {
    collaborators: 25,
    projects: Infinity,
    storage: 50,
    members: 1,
    lifetime_file_count: Infinity,
    price: {
      monthly: 29,
      yearly: 26,
    },
  },
  [plans.TEAM]: {
    collaborators: 50,
    projects: Infinity,
    storage: 100,
    members: 5,
    lifetime_file_count: Infinity,
    price: {
      monthly: 99,
      yearly: 89,
    },
  },
  [plans.BUSINESS]: {
    collaborators: Infinity,
    projects: Infinity,
    storage: 400,
    members: 15,
    lifetime_file_count: Infinity,
    price: {
      monthly: 299,
      yearly: 269,
    },
  },
  [plans.ENTERPRISE]: {
    collaborators: Infinity,
    projects: Infinity,
    storage: Infinity,
    members: Infinity,
    lifetime_file_count: Infinity,
    price: {
      monthly: null,
      yearly: null,
    },
  },
};

/**
 * Retrieves usage-specific fields for a given account
 * @param {Object} account - Account entity.
 */
export function getUsageDataFromAccountEntity(accountEntity) {
  const {
    collaborator_role_count,
    lifetime_file_count,
    member_count,
    project_count,
    storage,
  } = accountEntity;

  return {
    collaborator_role_count,
    lifetime_file_count,
    member_count,
    project_count,
    storage,
  };
}

/**
 * Determines whether usageData has the correct data to determine correct plan.
 * @param {Object} usageData - Corresponds to JTAccount.serialize().usage_data.
 */
function hasCorrectUsageData(usageData) {
  return (
    typeof usageData.collaborator_role_count === 'number' &&
    typeof usageData.member_count === 'number' &&
    typeof usageData.project_count === 'number' &&
    typeof usageData.storage === 'number'
  );
}

/**
 * Normalizes JTAccount usage data into a format matching planData above.
 * @param {Object} usageData - Corresponds to JTAccount.serialize().usage_data.
 * @returns {Object} Data with keys matching plan data above.
 */
function normalizeUsageData(usageData) {
  const {
    collaborator_role_count,
    lifetime_file_count,
    member_count,
    project_count,
    storage,
  } = usageData;
  const normalizedData = {
    collaborators: collaborator_role_count,
    lifetime_file_count,
    members: member_count,
    projects: project_count,
    storage: storage / giga,
  };
  return normalizedData;
}

/**
 * Calculates correct plan based on current overage limits.
 * @param {Object} usageData - Corresponds to <JTAccount>.serialize().usage_data
 * @returns {string} plans Enum.
 */
export function getCorrectPlan(usageData) {
  if (!hasCorrectUsageData(usageData))
    throw new Error('usage data missing correct keys');
  const normalizedUsageData = normalizeUsageData(usageData);

  // We never want to have the starter plan appear as an upgrade option
  const plansToCompare = omit(planData, [plans.STARTER]);

  return Object.keys(plansToCompare).reduce((closestPlan, planDataKey) => {
    const currentPlanValues = planData[planDataKey];
    const closestPlanValues = planData[closestPlan];
    const planWorks = Object.keys(normalizedUsageData).every(
      (usageKey) =>
        normalizedUsageData[usageKey] <= currentPlanValues[usageKey] &&
        (!closestPlanValues ||
          currentPlanValues[usageKey] <= closestPlanValues[usageKey])
    );

    return planWorks ? planDataKey : closestPlan;
  }, null);
}

export const monthlyItemCost = (planPeriod, totalCost) => {
  if (planPeriod === BILL_PERIOD.MONTHLY) {
    return totalCost;
  }
  return Math.round(totalCost / 12);
};

const legacyPlanIdsToPausedPromoIds = {
  // 20150306-01-starter-yr
  'c0137090-dfde-4630-b2a6-5fffef4ed4cb': 'PAUSED20150306STMO1',

  // 20150306-01-starter-mo
  '808bb0ce-41ba-4355-ab05-1b894375d467': 'PAUSED20150306STMO1',

  // 20181119-01-starter-mo
  '71137001-598c-4a88-b399-15206e76c58f': 'PAUSED20181119STMO1',

  // 20181030-01-pro-yr
  'b9d4e67c-b503-41cd-a657-915defcbfa74': 'PAUSED20181030PROMO1',

  // 20181030-01-pro-mo
  'f67ba21a-d560-4d95-af76-a38800cf1b20': 'PAUSED20181030PROMO1',

  // 20150306-01-pro-yr
  '79fd51b7-67eb-4d4b-bf73-67b0f95a6a2c': 'PAUSED20150306PROMO1',

  // 20150306-01-pro-mo
  '69833475-a59f-4f65-9428-4413ea9c6a3f': 'PAUSED20150306PROMO1',

  // 20181030-01-team-yr
  '877e0c27-be47-44fe-841b-f0d385eacd37': 'PAUSED20181030TMMO1',

  // 20181030-01-team-mo
  '600ab2a4-e78e-4639-bbb9-542fa32879be': 'PAUSED20181030TMMO1',

  // 20180827-01-team-mo
  '58ac1f4f-561f-4248-9cb0-cc9964a0881a': 'PAUSED20180827TMMO1',

  // 20181030-01-business-yr
  '5780cc0d-6b53-4784-9e48-cb5af5eb89b4': 'PAUSED20181030BUSMO1',

  // 20181030-01-business-mo
  '3b93eec3-7302-4e14-8b6b-7c62c399377f': 'PAUSED20181030BUSMO1',

  // 20150306-01-business-yr
  '6ce3aabc-d190-4987-8bc8-efd6d17ce32a': 'PAUSED20150306BUSMO1',

  // 20150306-01-business-mo
  '1507fc3b-c0b7-45c1-b1ae-eaa5e3f42165': 'PAUSED20150306BUSMO1',

  // 20181030-01-legacy-starter-5-tm-mo
  '6ad94901-a2b7-49c3-a209-9b876ec6a174': 'PAUSEDSTARTERSPECIAL',
};

const planIdsToPausedPromoIds = {
  // 20190125-pro-mo-one-seat-250GB
  '6d28c641-fd81-4c8d-a811-e7cd7a161604': 'PAUSED20190125PROMO2',

  // 20190125-pro-yr-one-seat-250GB
  'fa3d2427-eb46-47f5-80b6-426eb292fe40': 'PAUSED20190125PROYR2',

  // 20190125-team-mo-one-seat-250GB
  '65fabb1a-da80-41e5-b3d8-edb338603251': 'PAUSED20190125TMMO2',

  // 20190125-team-yr-one-seat-250GB
  'd212faea-cd01-4389-ad02-28884b154f27': 'PAUSED20190125TMYR2',

  // 20190204-legacy-starter-mo-one-seat-250GB
  'e81579f3-3241-4f0e-a7dd-4235380bec77': 'PAUSED20190204LEGACYSTSP',

  // v3-pro-mo
  '5bd4f331-8b90-4e59-bfb3-5dc583e7a4eb': 'PAUSED20190125PROMOV3',

  // v3-pro-yr
  'd76a2261-9dd4-427e-bee5-43b081d4d865': 'PAUSED20190125PROYRV3',

  // v3-team-mo
  '200a87b6-2bfa-4ec4-bc4d-735b20355d8f': 'PAUSED20190125TMMOV3',

  // v3-team-yr
  '10ea01e1-b80a-4627-a199-3839ebbb5e6c': 'PAUSED20190125TMYRV3',

  // v3-starter-special
  'fe2b382c-0709-4e43-9a44-f246aded8a68': 'PAUSEDSTARTERSPECIALV3',

  // v4-pro-mo
  '8944936e-6441-4628-bb8f-3cf73dcc5663': 'PAUSEDPROMOV4',

  // v4-pro-yr
  '31790267-3666-4934-ba3c-7a3a27f946c6': 'PAUSEDPROYRV4',

  // v4-team-mo
  '87a31135-2602-4ec9-b318-175a20bd1a7e': 'PAUSEDTMMOV4',

  // v4-team-yr
  '1c1daee1-f618-4dc7-95d6-90b822f33e1c': 'PAUSEDTMYRV4',

  // v4-starter-special
  '1daf8969-27df-4b65-bd75-31c7f9eb203c': 'PAUSEDSTARTERSPECIALV4',
};

export function getPausedPromosIdsForPlanIds(planId, planVersion) {
  let promoIdForPausedPlan;
  const isLegacyPlan = planVersion < LATEST_BILLING_VERSION;

  if (isLegacyPlan) {
    // PAUSEDLEGACYOTHERMO is the default paused promo for legacy plans not listed
    // in legacyPlanIdsToPausedPromoIds
    promoIdForPausedPlan =
      legacyPlanIdsToPausedPromoIds[planId] || 'PAUSEDLEGACYOTHERMO';
  } else {
    promoIdForPausedPlan = planIdsToPausedPromoIds[planId];
  }
  return promoIdForPausedPlan;
}
