/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { get, filter, map } from 'lodash';
import { assetEntitiesByAssetIdsSelector } from '@frameio/core/src/assets/selectors';
import { userEntitySelector } from '@frameio/core/src/users/selectors';
import { reviewLinkEntitySelector } from '@frameio/core/src/reviewLinks/selectors';
import { pendingReviewerEntitySelector } from '@frameio/core/src/pendingReviewers/selectors';
import { reviewerEntitySelector } from '@frameio/core/src/reviewers/selectors';
import { sessionWatermarkTemplateEntitiesSelector } from '@frameio/core/src/sessionWatermarkTemplate/selectors';
import { persistedSessionWatermarkTemplatesSelector } from 'components/SharePanel/selectors';

import { folderAncestorsForIdSelector } from 'selectors/folders';
import {
  currentProjectSelector,
  folderTreeForProjectIdSelector,
} from 'selectors/projects';
import { folderSelectionStatus } from './reducer';

/**
 * Gets the state of store relevant to the Review Link Editor
 * @param {Object} state - Redux store state.
 * @returns {Object} The reviewLinkEditor slice.
 */
export const reviewLinkEditorSelector = (state) => state.reviewLinkEditor;

/**
 * Gets the id of the review link that is currently being edited.
 * @param {Object} state - Redux store state.
 * @returns {?string} Id of the review link, or undefined if none is being edited.
 */
export const reviewLinkEditorIdSelector = (state) =>
  reviewLinkEditorSelector(state).id;

/**
 * @param {Object} state - Redux store state.
 * @param {string[]} assetIds - Asset ids.
 * @returns {?Object[]} Array of review link asset data objects.
 * NOTE: This selector is used in the review link editor reducer to store only the required
 * asset attributes to the `reviewLinkEditor.assets` slice instead of the full asset entities.
 */
export const getReviewLinkAssetDataSelector = (state, { assetIds }) => {
  const assetEntities = assetEntitiesByAssetIdsSelector(state, {
    assetIds,
  });
  return assetEntities.map(({ id, parent_id: parentId, type }) => ({
    id,
    parentId,
    type,
  }));
};

/**
 * Gets the assets data array of the review link that is currently being edited.
 * @param {Object} state - Redux store state.
 * @returns {?Object[]} Array of asset data objects, or undefined if no review link is being edited.
 */
export const reviewLinkEditorAssetsSelector = (state) => {
  const { assets } = reviewLinkEditorSelector(state);
  return assets;
};

/**
 * Gets the asset ids array of the review link that is currently being edited.
 * @param {Object} state - Redux store state.
 * @returns {?string[]} Array of assetIds, or undefined if no review link is being edited.
 */
export const reviewLinkEditorAssetIdsSelector = createSelector(
  reviewLinkEditorAssetsSelector,
  (assets) => (assets ? assets.map((asset) => asset.id) : undefined)
);
/**
 * Gets the map of folderIds and their selection statuses for the review link being edited.
 * @param {Object} state - Redux store state.
 * @returns {Object} folderIds (key-value pairs of folderId and selection status).
 */
export const reviewLinkEditorFolderIdsSelector = (state) => {
  const { folderIds } = reviewLinkEditorSelector(state);
  return folderIds;
};

/**
 * Returns whether or not items are being selected for a review link
 * @param {Object} state - Redux store state.
 * @returns {boolean} Whether or items are being selected
 */
export const isSelectingItemsForReviewLinkSelector = createSelector(
  reviewLinkEditorAssetsSelector,
  (assetIds) => !!assetIds
);

// We need a non-falsy value to trigger link editing mode.
export const NEW_LINK_ID = 'new-link';

export const isReviewLinkEditorNewSelector = createSelector(
  reviewLinkEditorIdSelector,
  (id) => id === NEW_LINK_ID
);

/**
 * Gets the review link _entity_ for the current review link editor id
 * @returns {Object} The reviewLink entity slice.
 */
export const reviewLinkEntityForEditorSelector = createSelector(
  reviewLinkEditorIdSelector,
  (state) => state,
  (reviewLinkId, state) => reviewLinkEntitySelector(state, { reviewLinkId })
);

/**
 * Gets the item ids for a given review link _entity_.
 * @returns {?string[]} Array of item ids for a review link entity slice.
 */
export const reviewLinkEntityItemIdsSelector = createSelector(
  reviewLinkEntityForEditorSelector,
  (reviewLinkEntityForEditor) => get(reviewLinkEntityForEditor, 'items')
);

export const invitedEntitiesForItemIdSelector = (state) =>
  state.reviewLinkEditor.invitedIds;

// TODO: Move out of ReviewLinkEditor
export const invitedEntityAndTypeForId = (state, { entityId }) => {
  const pendingReviewer = pendingReviewerEntitySelector(state, {
    pendingReviewerId: entityId,
  });
  if (pendingReviewer) {
    return { entity: pendingReviewer, type: 'pending_reviewer' };
  }

  const { user_id: userId, seen_at } = reviewerEntitySelector(state, {
    reviewerId: entityId,
  });
  const user = { ...userEntitySelector(state, { userId }), seen_at };

  return { entity: user, type: 'reviewer' };
};

/**
 * Gets the selection status for a given folder id in a review link being edited.
 * @returns {string} Folder id selection status.
 */
export const selectionStatusForFolderIdSelector = createSelector(
  reviewLinkEditorFolderIdsSelector,
  (state, { folderId }) => folderId,
  (reviewLinkFolderIds, folderId) => get(reviewLinkFolderIds, folderId)
);

/**
 * @param {string} folderId - Folder id.
 * @returns {bool} Whether or not the folder is selected to be shared in a review link.
 */
export const isFolderSelectedForReviewSelector = createSelector(
  reviewLinkEditorAssetIdsSelector,
  (state, { folderId }) => folderId,
  (reviewLinkAssetIds = [], folderId) => reviewLinkAssetIds.includes(folderId)
);

/**
 * @param {string} folderId - Folder id.
 * @returns {bool} Whether or not the folder is selected _indeterminately_ (i.e. one or
 * more of its nested children are selected, but not the folder itself) in a review link.
 */
export const isFolderSelectedIndeterminatelySelector = createSelector(
  selectionStatusForFolderIdSelector,
  (selectionStatus) => selectionStatus === folderSelectionStatus.INDETERMINATE
);

/**
 * Helper function to recursively derive folder ancestor data if that data hasn't yet
 * been fetched. NOTE: Folder ancestors are fetched upon project folder entry.
 * @param {string} tree - Project folder tree.
 * @param {Object[]} folderId - Folder id to obtain ancestor data for.
 * @returns {string[]} Array of ancestor ids for the given folder id.
 */
export function getAncestorsFromProjectTree(
  tree,
  folderId,
  ancestors = [folderId]
) {
  const ancestorId = Object.keys(tree).find((key) =>
    tree[key].map(({ id }) => id).includes(folderId)
  );
  // Let's not include 'root' in the array of ancestor folder ids
  if (ancestorId && ancestorId !== 'root') {
    /*
      We add ancestors to the front of the array to mirror the order of project root
      asset id to lower-level folder descendant leaves returned from the ancestors API
      endpoint:
      ```
      {
        'folder-level-2': ['root-asset-id', 'folder-level-1', 'folder-level-2']
      }
      ```
    */
    ancestors.unshift(ancestorId);
    getAncestorsFromProjectTree(tree, ancestorId, ancestors);
  }
  return ancestors;
}

/**
 * @param {string} folderId - Folder id.
 * @returns {?string} Ancestor id of the given folder id (or the folder id itself)
 * that is selected for review.
 */
export const ancestorIdSelectedForReviewSelector = (state, { folderId }) => {
  const isCurrentFolderSelected = isFolderSelectedForReviewSelector(state, {
    folderId,
  });
  if (isCurrentFolderSelected) return folderId;

  let folderAncestors = folderAncestorsForIdSelector(state, { folderId });

  /*
    If folder ancestor data isn't present in either the `folderAncestors` redux
    slice or in the local `folderAncestorsCache`, recursively derive its ancestors
    using the associated project folder tree.
  */
  if (!folderAncestors) {
    const { id: projectId } = currentProjectSelector(state);
    const { tree } = folderTreeForProjectIdSelector(state, { projectId }) || {};
    folderAncestors = tree ? getAncestorsFromProjectTree(tree, folderId) : [];
  }

  return folderAncestors.length > 0
    ? folderAncestors.find((ancestorId) =>
        isFolderSelectedForReviewSelector(state, {
          folderId: ancestorId,
        })
      )
    : undefined;
};

const sessionWatermarkTemplateEntitiesForReviewLinkIdSelector = createSelector(
  (state, { reviewLinkId }) => reviewLinkId,
  sessionWatermarkTemplateEntitiesSelector,
  (reviewLinkId, sessionWatermarkTemplateEntities) =>
    filter(sessionWatermarkTemplateEntities, ['review_link_id', reviewLinkId])
);

export const mergedSessionWatermarkTemplateEntitiesForTeamAndReviewLink = createSelector(
  sessionWatermarkTemplateEntitiesForReviewLinkIdSelector,
  persistedSessionWatermarkTemplatesSelector,
  (templatesForReviewLink, templatesForTeam) => {
    // In order to appropriately sort templates for review links that replace
    // "parent" templates at the account level, we need to persist some knowledge
    // of the original template's `inserted_at` value.
    const transformedReviewLinkTemplates = templatesForReviewLink.map(
      (templateEntityForReviewLink) => {
        // For each review link-only template, find an account level template with an id
        // that matches the given `original_session_watermark_template_id`
        const { inserted_at } =
          templatesForTeam.find(
            ({ id }) =>
              templateEntityForReviewLink.original_session_watermark_template_id ===
              id
          ) || {};

        return {
          ...templateEntityForReviewLink,
          original_inserted_at: inserted_at,
        };
      }
    );

    const originalTemplateIds = map(
      transformedReviewLinkTemplates,
      'original_session_watermark_template_id'
    );
    // Generate a subset of templatesForTeam of templates with any matching "parent"
    // templates removed
    return [
      ...templatesForTeam.filter(({ id }) => !originalTemplateIds.includes(id)),
      ...transformedReviewLinkTemplates,
    ].sort((a, b) =>
      get(a, 'original_inserted_at', a.inserted_at) >
      get(b, 'original_inserted_at', b.inserted_at)
        ? -1
        : 1
    );
  }
);
