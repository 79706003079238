import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CrossFadeTransitionGroup from '@frameio/components/src/styled-components/CrossFadeTransitionGroup';
import transitionFactory from 'react-transition-factory';
import { Transition } from 'react-transition-group';
import Animation from '@frameio/components/src/components/Animation';
import ScaleFadeTransition from 'components/ScaleFadeTransition';
import { PlaceholderPlus } from 'components/FileCard';
import DropZoneIndicatorData from './DropZoneIndicatorData.json';
import { DROP_ZONE_EASING, EmptyPlaceholder } from './EmptyDropZoneIndicator';

import {
  DROP_PRIMARY_POSITION_EASING,
  DROP_PRIMARY_TIMEOUT,
  PRIMARY_THUMB_FLEX_GROW,
  FolderAssetThumb,
} from './FolderThumb';

export const DROP_ZONE_TIMEOUT = 460;
export const DROP_ZONE_DELAY = 350;
export const DROP_ZONE_INDICATOR_ASPECT_RATIO =
  DropZoneIndicatorData.w / DropZoneIndicatorData.h;
export const DROP_PRIMARY_SCALE_EASING = 'cubic-bezier(0.17, -0.33, 0, 1)';
const FlexTransition = transitionFactory('flex');

const DropPlaceholder = styled(EmptyPlaceholder)`
  position: absolute;
  left: 0;
  top: 0;

  ${({ height, width, theme }) => `
    height: ${typeof height === 'number' ? `${height}px` : height};
    width: ${typeof width === 'number' ? `${width}px` : width};
    border-radius: ${theme.radius.medium};
  `}
`;

const AnimationWrapper = styled.div`
  position: absolute;
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  left: 0;
  bottom: 0;
`;

export default function DropZoneIndicator({
  children,
  droppedAssetId,
  height,
  isDropComplete,
  isDropping,
  isDropZone,
  onDropComplete,
  width,
}) {
  if (isDropComplete) return children;

  // The spacer <path> inside the lottie data file is 8px wide, this gives us its scaled width.
  const svgSpacing = (8 / DropZoneIndicatorData.w) * width;
  return (
    <React.Fragment>
      {/*
        This placeholder group does two things:
        1) It grows in width (flex) so that it pushes the primary thumbnail aside by the width
           of the placeholder.
        2) When assets are dropped, it morphs the placeholder into the new primary thumb.
      */}
      <FlexTransition
        in={isDropZone || isDropping}
        timeout={
          isDropping
            ? DROP_PRIMARY_TIMEOUT
            : DROP_ZONE_TIMEOUT - DROP_ZONE_DELAY
        }
        start={0}
        end={isDropping ? PRIMARY_THUMB_FLEX_GROW : `0 0 ${width}px`}
        easing={isDropping ? DROP_PRIMARY_POSITION_EASING : DROP_ZONE_EASING}
        delay={isDropZone && !isDropping ? DROP_ZONE_DELAY : 0}
      >
        <CrossFadeTransitionGroup
          in={isDropping}
          timeout={DROP_PRIMARY_TIMEOUT}
          easing={DROP_PRIMARY_POSITION_EASING}
          from={
            <DropPlaceholder
              alignCenter
              justifyCenter
              height="100%"
              width="100%"
            />
          }
          to={
            <FolderAssetThumb
              assetId={droppedAssetId}
              height="100%"
              width="100%"
            />
          }
          toProps={{
            onEntered: onDropComplete,
          }}
        />
      </FlexTransition>
      {children}
      {/*
        This is the lottie animation that we use for entering/leaving the dropzone. Once we have
        dropped the asset, we don't need it anymore since the `<CrossFadeTransitionGroup>` above
        takes the role of it
      */}
      {!isDropping && (
        <Transition
          in={isDropZone}
          timeout={isDropZone ? DROP_ZONE_TIMEOUT : DROP_ZONE_TIMEOUT / 2}
          easing={DROP_ZONE_EASING}
          mountOnEnter
          unmountOnExit
        >
          {(state) => (
            <AnimationWrapper height={height} width={width}>
              <Animation
                isPlaying={state !== 'entered' && state !== 'exited'}
                speed={isDropZone ? 1 : 2}
                direction={isDropZone ? 1 : -1}
                data={DropZoneIndicatorData}
                loop={false}
                rendererSettings={{
                  preserveAspectRatio: 'none',
                }}
              />
            </AnimationWrapper>
          )}
        </Transition>
      )}
      {/* This is the plus that we scale in near the end of the lottie animation */}
      <ScaleFadeTransition
        in={isDropZone}
        timeout={DROP_ZONE_TIMEOUT - DROP_ZONE_DELAY}
        delay={isDropZone ? DROP_ZONE_DELAY : 0}
        easing={DROP_ZONE_EASING}
      >
        <DropPlaceholder
          alignCenter
          justifyCenter
          height={height}
          width={width - svgSpacing}
        >
          <PlaceholderPlus />
        </DropPlaceholder>
      </ScaleFadeTransition>
    </React.Fragment>
  );
}

DropZoneIndicator.propTypes = {
  children: PropTypes.node.isRequired,
  droppedAssetId: PropTypes.string,
  height: PropTypes.number.isRequired,
  isDropping: PropTypes.bool,
  isDropZone: PropTypes.bool,
  isDropComplete: PropTypes.bool,
  onDropComplete: PropTypes.func,
  width: PropTypes.number.isRequired,
};

DropZoneIndicator.defaultProps = {
  droppedAssetId: undefined,
  isDropping: false,
  isDropZone: false,
  isDropComplete: false,
  onDropComplete: () => {},
};

export const testExports = {
  FlexTransition,
  DropPlaceholder,
};
