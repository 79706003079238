import React from 'react';
import { connect } from 'react-redux';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { MenuButton } from '@frameio/vapor';
import TrashCanIcon from '@frameio/components/src/svgs/icons/trashcan.svg';
import { trackButtonClick } from 'analytics';
import { confirmDeleteProject } from 'actions/projects';
import { permittedActionsForProjectSelector } from 'selectors/permissions';

function mapStateToProps(state, { projectId }) {
  const { canDeleteProject } = permittedActionsForProjectSelector(state, {
    projectId,
  });
  return { canDeleteProject };
}

const mapDispatchToProps = {
  onDeleteProject: confirmDeleteProject,
};

function DeleteProjectItem({
  projectId,
  projectName,
  canDeleteProject,
  onDeleteProject,
  trackingPage,
  trackingPosition,
  ...rest
}) {
  return (
    canDeleteProject && (
      <MenuButton
        destructive
        onSelect={() => {
          onDeleteProject(projectId, projectName);
          trackButtonClick('delete-project', trackingPage, trackingPosition);
        }}
        iconBefore={<TrashCanIcon height={16} width={16} />}
        {...rest}
      >
        Delete project
      </MenuButton>
    )
  );
}

DeleteProjectItem.defaultProps = {
  projectId: '',
  projectName: '',
  canDeleteProject: false,
  onDeleteProject: noop,
};

DeleteProjectItem.propTypes = {
  projectId: PropTypes.string,
  projectName: PropTypes.string,
  canDeleteProject: PropTypes.bool,
  onDeleteProject: PropTypes.func,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteProjectItem);
