import { createSelector } from 'reselect';
import {
  assetEntitiesByAssetIdsSelector,
  assetEntitiesSelector,
  areAssetsInSameFolderSelector,
  childAssetEntitiesSortedByIndexSelector,
  getChildrenMap,
  hydratedChildAssetsSelector,
} from '@frameio/core/src/assets/selectors';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import { isVideo as isAssetVideo } from '@frameio/core/src/assets/helpers/mediaTypes';
import { type as assetType } from '@frameio/core/src/assets/helpers/constants';
import { currentUserSelector } from '../users';
/**
 * Whether any assets are in the root folder of a project.
 * @param {Object} state - Redux state.
 * @param {Object} ownProps - Must contain an `assetIds` key that is an array of asset ids, and a
 * `projectId` key.
 * @returns {boolean}
 */
export const isAnyAssetInProjectRoot = createSelector(
  assetEntitiesByAssetIdsSelector,
  projectEntitySelector,
  (assets, project) =>
    assets.some(
      ({ parent_id: parentId }) => parentId === (project || {}).root_asset_id
    )
);

/**
 * Whether the assets being selected can be moved to a different folder.
 * @param {Object} state - Redux state.
 * @param {Object} ownProps - Must contain an `assetIds` key that is an array of asset ids, and a
 * `projectId` key.
 * @returns {boolean}
 */
export const canAssetsBeMovedToFolderSelector = createSelector(
  areAssetsInSameFolderSelector,
  (inSameFolder) => inSameFolder
);

/**
 * Whether the assets being selected can be moved up one folder.
 * @param {Object} state - Redux state.
 * @param {Object} ownProps - Must contain an `assetIds` key that is an array of asset ids, and a
 * `projectId` key.
 * @returns {boolean}
 */

export const canAssetsBeMovedUpOneFolderSelector = createSelector(
  isAnyAssetInProjectRoot,
  areAssetsInSameFolderSelector,
  (inRoot, inSameFolder) => !inRoot && inSameFolder
);

/**
 * Recursively tests if every asset in the array and all its descendents belong to a user.
 * @param {Array<Object>} - Asset entities to test.
 * @param {string} - User id to test against.
 * @param {Record<string, Object>} - Map of asset id to asset entity
 * @returns {boolean}
 */
function doAllYourAssetsAreBelongToMe(
  assets = [],
  userId = null,
  allAssets = {}
) {
  /** @type Map<string, Set<string>> */
  const childrenMap = getChildrenMap(allAssets);

  const areBelongToMe = (assetIds) =>
    assetIds.every((assetId) => {
      const asset = allAssets[assetId];
      return (
        asset &&
        asset.creator_id === userId &&
        areBelongToMe(Array.from(childrenMap.get(asset.id) || []))
      );
    });

  return assets.every((asset) => areBelongToMe([asset.id]));
}

/**
 * Whether the current user owns all the selected assets.
 * @param {Object} state - Redux state.
 * @param {Object} ownProps - Must contain an `assetIds` key that is an array of asset ids, and a
 * `projectId` key.
 * @returns {boolean}
 */
export const doesCurrentUserOwnAllAssetsSelector = createSelector(
  assetEntitiesByAssetIdsSelector,
  currentUserSelector,
  assetEntitiesSelector,
  (assets, currentUser, allAssets) => {
    const result = doAllYourAssetsAreBelongToMe(
      assets,
      currentUser.id,
      allAssets
    );
    return result;
  }
);

/**
 * Whether the selected assets can be used to create a dual view bundle.
 * @param {Object} state - Redux state.
 * @param {Object} ownProps - Must contain an `assetIds` key that is an array of asset ids.
 * @returns {boolean}
 */
export const canCreateDualViewSelector = createSelector(
  assetEntitiesByAssetIdsSelector,
  (assets) =>
    assets.length === 2 &&
    assets.every(
      (asset) =>
        isAssetVideo(asset) && asset.type === assetType.FILE && !asset.bundle
    )
);

export const childAssetIdsSortedByIndexSelector = createSelector(
  childAssetEntitiesSortedByIndexSelector,
  (childEntities) => childEntities.map(({ id }) => id)
);

/**
 * Returns a list of ids for an assets children for the given `assetId`.
 * @param {Object} state - redux state tree
 * @param {Object} props - outside props, must include `assetId`
 */
export const childAssetIdsSelector = createSelector(
  hydratedChildAssetsSelector,
  (children) => children.map(({ id }) => id)
);

/**
 * Returns the true/false value for supported frame rates on forensically watermarked assets
 * @param {Object} state
 * @returns {boolean}
 */
export const fwmFrameRateSupportedSelector = (state) => {
  return state.asset.fwmFrameRateSupported;
};
