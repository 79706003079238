/* eslint-disable */
import Raven from 'raven-js';
import config from 'config';

const loadDriftScript = ({ identifyProperties = {}, user, driftToken }) => {
  // see `JS Install` section in https://app.drift.com/settings/widget
  const executeDriftScript = () => {
    /* prettier-ignore */
    var t = window.driftt = window.drift = window.driftt || [];
    if (!t.init) {
      if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
      t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ],
      t.factory = function(e) {
        return function() {
          var n = Array.prototype.slice.call(arguments);
          return n.unshift(e), t.push(n), t;
        };
      }, t.methods.forEach(function(e) {
        t[e] = t.factory(e);
      }), t.load = function(t) {
        var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
        o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
        var i = document.getElementsByTagName("script")[0];
        i.parentNode.insertBefore(o, i);
      };
    }
    /* prettier-ignore */
  };

  try {
    executeDriftScript();
    window.drift.SNIPPET_VERSION = '0.3.1';

    // Drift recommends calling `drift.identify` as early as possible, before
    // `drift.load`, to prevent race conditions.
    //
    // See https://devdocs.drift.com/docs/methods-and-installing-with-javascript-sdk
    window.drift.identify(
      user?.id,
      {
        ...identifyProperties,
        account_id: user?.account_id,
      },
      // The `driftToken` JWT must have a sub matching the user ID passed as
      // first arg above. It also must be signed by the key provided in Drift
      // Widget security settings: https://app.drift.com/settings2/widget/security
      //
      // See also: https://devdocs.drift.com/docs/securing-drift-on-your-site-using-signed-identities
      {
        userJwt: driftToken,
      }
    );
    window.drift.load(config.driftId);
  } catch (error) {
    Raven.captureException('Drift: Failed to load drift script', {
      level: 'error',
      extra: {
        error,
      },
      tags: {
        jira_ticket: 'BUGS-1569',
      },
    });
  }
};

export default loadDriftScript;
