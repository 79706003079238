import React from 'react';
import {
  call,
  spawn,
  cancel,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { get } from 'lodash';
import track from 'analytics';
import updateStripeCustomer from 'components/CreditCardFormElements/sagas';
import { firstCardEntityByAccountIdSelector } from '@frameio/core/src/cards/selectors';
import { getCardsByAccount } from '@frameio/core/src/cards/sagas';
import { MODAL, updateModal } from 'components/Modal/actions';
import { isFreePlanWithUserMaxSelectedSelector } from 'components/SelectPlanFlow/selectors';
import ContactSupport from 'components/CreditCardFormElements/components/ContactSupport';
import { PAYMENT_FLOW, reportPaymentSuccess, setPaymentError } from './actions';
import history from './history';
import { UPDATE_CARD_URL, PAYMENT_PROCESSING_URL } from './PaymentFlow';

function getErrorMessage(error) {
  const errors = get(error, 'payload.error.response.data.errors') || [];
  return errors[0]
    ? errors[0].detail
    : get(error, 'payload.error.response.data.message');
}

function trackPaymentFailed(failure) {
  track('payment-failed-client', { error: getErrorMessage(failure) });
}

function* isWaiting() {
  yield put(updateModal({ canCloseModal: false }));
  yield call(history.push, PAYMENT_PROCESSING_URL);
}

function* showUpdateCardForm(accountId, trackingProps) {
  yield put(
    updateModal({
      canBackdropClose: false,
      canCloseModal: true,
    })
  );
  let hasAddedCard = false;
  while (!hasAddedCard) {
    yield call(history.push, UPDATE_CARD_URL);
    const {
      payload: {
        stripeResponse: { token },
      },
    } = yield take(PAYMENT_FLOW.CONTINUE);
    yield call(isWaiting);
    const { failure } = yield call(updateStripeCustomer, accountId, token?.id);

    if (failure) {
      yield spawn(trackPaymentFailed, failure);
      yield put(setPaymentError(<ContactSupport />));
    } else {
      track('payment-method-updated-client', trackingProps);
      hasAddedCard = true;
    }
  }
}

// In the case of a stripe error this will just direct the user to input a new credit card,
// but if we get another error from calling onPaymentMethodReady, this will also put
// the REPORT_CALLBACK_FAILURE action for the call site to listen to if it needs special handling
function* handleChargeFailed(accountId, failure, trackingProps) {
  yield spawn(trackPaymentFailed, failure);
  yield call(showUpdateCardForm, accountId, trackingProps);
}

function* watchPaymentFlow(accountId, onPaymentMethodReady, trackingProps) {
  yield call(isWaiting);

  // If there are no credit cards associated with the account, ask them to add
  // one.
  const { success } = yield call(getCardsByAccount, accountId);
  const card = yield select(firstCardEntityByAccountIdSelector, { accountId });
  const isFreePlanWithUserMaxSelected = yield select(
    isFreePlanWithUserMaxSelectedSelector
  );

  if (!success || (!card && !isFreePlanWithUserMaxSelected)) {
    yield call(showUpdateCardForm, accountId, trackingProps);
  }

  // Charge the credit card
  let isCharged = false;
  while (!isCharged) {
    const { failure } = yield call(onPaymentMethodReady);
    if (failure) {
      yield call(handleChargeFailed, accountId, failure, trackingProps);
    } else {
      isCharged = true;
    }
  }
  track('purchased');
  yield put(
    updateModal({
      canBackdropClose: false,
      canCloseModal: true,
    })
  );
  yield put(reportPaymentSuccess());
}

function* startPaymentFlow(accountId, onPaymentMethodReady, trackingProps) {
  const flow = yield fork(
    watchPaymentFlow,
    accountId,
    onPaymentMethodReady,
    trackingProps
  );
  yield take([PAYMENT_FLOW.END, MODAL.CLOSE]);
  yield cancel(flow);
}

export const testExports = {
  isWaiting,
  showUpdateCardForm,
  handleChargeFailed,
  watchPaymentFlow,
  startPaymentFlow,
  trackPaymentFailed,
};

export default [
  takeLatest(
    PAYMENT_FLOW.START,
    ({ payload: { accountId, onPaymentMethodReady, trackingProps } }) =>
      startPaymentFlow(accountId, onPaymentMethodReady, trackingProps)
  ),
];
