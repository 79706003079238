import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  projectByTeamFetchStatusSelector,
  sharedProjectsFetchStatusSelector,
} from '@frameio/core/src/projects/selectors';
import { teamEntityForProjectIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { currentUserSelector } from 'selectors/users';
import {
  currentAccountSelector,
  teamIdsWithAdminOrMemberRoleFromEntitySelector,
} from 'selectors/accounts';
import { sharedProjectIdsForAccountSelector } from 'pages/RootContainer/selectors';
import {
  makeUnarchivedProjectIdsForTeamIdSelector,
  makeArchivedProjectIdsForTeamIdSelector,
} from 'selectors/projects';
import { status } from '@frameio/core/src/shared/reducers/factories';
import { toggleTeam, jumpToSelected } from './actions';
import { SHARED_PROJECT_ID } from '../constants';

import ProjectList from './ProjectList';

/**
 * @param   {Object} state - State from the redux store.
 * @returns {Object} - Props for ProjectList component.
 */
/*
  CORE-2246: When sharing selectors across lists using a cacheKey from props (e.g. `teamId` or
  `accountId`), any invocations of mSTP on a list item will bust the cache, effectively re-running
  the selectors needlessly. As suggested in `re-select` docs, below we create a unique instance of
  the unarchived and archived projectId selectors so that each selector will be memoized based
  on a consistent set of inputs.

  See https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
*/
export function mapStateToProps(state, { selectedProjectId }) {
  const accountId = get(currentAccountSelector(state), 'id', null);
  const { id: selectedTeamId } =
    teamEntityForProjectIdSelector(state, { projectId: selectedProjectId }) ||
    {};
  const projectByTeamFetchStatus = projectByTeamFetchStatusSelector(state);
  const sharedProjectsFetchStatus = sharedProjectsFetchStatusSelector(state);
  const isLoadingSharedProjects =
    sharedProjectsFetchStatus[accountId] === status.PENDING;
  const sharedProjectsFetched =
    sharedProjectsFetchStatus[accountId] === status.SUCCESS;

  const sharedProjectIds = isLoadingSharedProjects
    ? []
    : sharedProjectIdsForAccountSelector(state, {
        accountId,
      });

  const areSharedProjectsVisible = sharedProjectIds?.length > 0;
  const archivedProjectIdsSelector = makeArchivedProjectIdsForTeamIdSelector();
  const unarchivedProjectIdsSelector = makeUnarchivedProjectIdsForTeamIdSelector();
  const teamIds = teamIdsWithAdminOrMemberRoleFromEntitySelector(state, {
    accountId,
  });

  const teamProjects = teamIds.reduce((acc, teamId) => {
    const isLoadingProjects =
      projectByTeamFetchStatus[teamId] === status.PENDING;
    const projectIds = isLoadingProjects
      ? []
      : unarchivedProjectIdsSelector(state, { teamId });
    const archivedProjectIds = isLoadingProjects
      ? []
      : archivedProjectIdsSelector(state, { teamId });

    acc[teamId] = {
      projectIds,
      archivedProjectIds,
      isLoadingProjects,
    };
    return acc;
  }, {});

  const listToggles = state.projectList.teamToggles;
  const jumpToSelectedValue = state.projectList.jumpToSelected;
  const teamProjectList = teamIds.reduce((acc, teamId) => {
    const projects = teamProjects[teamId].projectIds || [];
    const archivedProjects = teamProjects[teamId].archivedProjectIds || [];
    const isSelected = teamId === selectedTeamId;
    const archivedProjectIsSelected = archivedProjects.includes(
      selectedProjectId
    );

    acc.push({
      type: 'TEAM',
      id: teamId,
      isLoadingProjects: teamProjects[teamId].isLoadingProjects,
      isSelected,
    });

    if (listToggles[teamId]) {
      acc.push(
        ...projects.map((project) => ({
          type: 'PROJECT',
          id: project,
          isSelected: selectedProjectId === project,
          projectSelected: selectedProjectId === project,
          archivedProjectIsSelected,
        }))
      );
      if (archivedProjects.length > 0) {
        acc.push({ type: 'ARCHIVE_HEADER', id: teamId });
        if (listToggles[`archivedProjectsTab-${teamId}`]) {
          acc.push(
            ...archivedProjects.map((project) => ({
              type: 'PROJECT',
              id: project,
              isSelected: selectedProjectId === project,
              projectSelected: selectedProjectId === project,
            }))
          );
        }
      }
    }
    return acc;
  }, []);

  if (areSharedProjectsVisible || isLoadingSharedProjects) {
    teamProjectList.push({
      type: 'TEAM',
      id: SHARED_PROJECT_ID,
      isLoadingProjects: isLoadingSharedProjects,
      isSelected: selectedTeamId === SHARED_PROJECT_ID,
    });
    if (state.projectList.teamToggles.SHARED) {
      teamProjectList.push(
        ...sharedProjectIds.map((project) => ({
          type: 'PROJECT',
          id: project,
          isSelected: selectedProjectId === project,
          projectSelected: selectedProjectId === project,
        }))
      );
    }
    teamProjects[SHARED_PROJECT_ID] = {
      projectIds: sharedProjectIds,
      archivedProjectIds: [],
      isLoadingProjects: isLoadingSharedProjects,
    };
  }

  const selectedProjectIndex = teamProjectList.findIndex(
    (x) => x.projectSelected === true
  );

  return {
    currentUserId: currentUserSelector(state).id,
    teamProjectListLength: teamProjectList.length,
    accountId,
    jumpToSelectedValue,
    listToggles,
    selectedProjectIndex,
    selectedTeamId,
    sharedProjectsFetched,
    teamIds,
    teamProjects,
    teamProjectList,
  };
}

const mapDispatchToProps = { jumpToSelected, toggleTeam };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectList);

export const testExports = {
  mapStateToProps,
};
