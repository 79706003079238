import countries from './data/countries.json';

export const countryNameMap = {};
export const countryCodeMap = {};

function mapCodeAndName(entityNameMap, entityCodeMap, entity) {
  // eslint-disable-next-line no-param-reassign
  entityNameMap[entity.name] = entity.code;
  // eslint-disable-next-line no-param-reassign
  entityCodeMap[entity.code] = entity.name;
}

countries.forEach((country) =>
  mapCodeAndName(countryNameMap, countryCodeMap, country)
);

export const isCountryCanada = (country) => country === 'CA';
export const isCountryUS = (country) => country === 'US';
export const isCountryUSorCanada = (country) =>
  isCountryCanada(country) || isCountryUS(country);
