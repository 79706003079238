import { generateActionTypes } from 'shared/actions/helpers';

export const EDIT_PROJECT_FORM = generateActionTypes('EDIT_PROJECT_FORM', [
  'SET_PROJECT_ID',
]);

/**
 * @param {string} projectId - Project id to edit a project.
 * @returns {Object} - Action object.
 */
export function setProjectId(projectId) {
  return {
    type: EDIT_PROJECT_FORM.SET_PROJECT_ID,
    payload: { projectId },
  };
}
