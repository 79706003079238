import React, { useRef } from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import TextInput from '@frameio/components/src/styled-components/TextInput';
import hideVisually from '@frameio/components/src/mixins/hideVisually';

export const USER_CODE_LENGTH = 6;

const IntegerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  // Ensure numeric value is centered in container.
  width: 46px;
  height: 67px;
  font-size: 38px;
  padding-top: ${(p) => p.theme.spacing.units(0.75)};
  margin: ${(p) => p.theme.spacing.micro};

  background-color: #20212d;
  border: solid;
  border-radius: ${(p) => p.theme.radius.large};
  border-color: ${(p) => (p.hasBorder ? p.theme.color.brand : 'transparent')};
  border-width: ${(p) => p.theme.spacing.units(0.25)};
  color: ${(p) => p.theme.color.white};
  box-shadow: ${(p) => p.theme.shadow.medium};
`;

const Input = styled(TextInput)`
  ${hideVisually()};
`;

const UserCode = ({ currentValue, newValCallback }) => {
  const inputRef = useRef(null);
  const currentIntegerIndex = currentValue.length + 1;
  const arrayVals = currentValue.split('');

  return (
    <>
      <Flex onClick={() => inputRef.current?.focus()}>
        <IntegerContainer
          key="integer-container-1"
          hasBorder={currentIntegerIndex === 1}
        >
          {arrayVals[0]}
        </IntegerContainer>
        <IntegerContainer
          key="integer-container-2"
          hasBorder={currentIntegerIndex === 2}
        >
          {arrayVals[1]}
        </IntegerContainer>
        <IntegerContainer
          key="integer-container-3"
          hasBorder={currentIntegerIndex === 3}
        >
          {arrayVals[2]}
        </IntegerContainer>
        <IntegerContainer
          key="integer-container-4"
          hasBorder={currentIntegerIndex === 4}
        >
          {arrayVals[3]}
        </IntegerContainer>
        <IntegerContainer
          key="integer-container-5"
          hasBorder={currentIntegerIndex === 5}
        >
          {arrayVals[4]}
        </IntegerContainer>
        <IntegerContainer
          key="integer-container-6"
          hasBorder={currentIntegerIndex === 6}
        >
          {arrayVals[5]}
        </IntegerContainer>
      </Flex>
      <Input
        ref={(ref) => {
          if (ref) {
            ref.focus();
            inputRef.current = ref;
          }
        }}
        autoComplete="off"
        inputMode="numeric"
        maxLength={USER_CODE_LENGTH}
        minLength={USER_CODE_LENGTH}
        onChange={(event) => {
          // Filter out non-numeric inputs. This needs to work for pasting also.
          const newValue = event.target.value.replace(/[^0-9]*/g, '');

          newValCallback(newValue);
        }}
        pattern="[0-9]*"
        type="text"
        value={currentValue}
      />
    </>
  );
};

export default UserCode;
