import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import track from 'analytics';
import Button from '@frameio/components/src/styled-components/Button';
import Flex from 'styled-flex-component';
import CardPreviewHeader from 'components/UpdateBillingInfo/components/CardPreviewHeader';
import CreditCardForm from 'components/CreditCardForm';
import CreditCardFormElements from 'components/CreditCardFormElements';

const trackingProps = {
  is_modal_for_purchase: false,
  is_modal_for_trial: false,
  source: 'billing page',
};

const Container = styled.div`
  width: 400px;
  padding: 16px;
  max-height: 100vh;
  overflow-y: scroll;
`;

const Header = styled.h3`
  ${(p) => p.theme.fontStyle.heading};
`;

const SubHeader = styled.h4`
  ${(p) => p.theme.fontStyle.body};
  color: ${(p) => p.theme.color.graphiteGray};
  margin-top: ${(p) => p.theme.spacing.small};
  font-weight: normal;
`;

const CancelButton = styled(Button)`
  margin-right: ${(p) => p.theme.spacing.small};
`;

const Footer = styled(Flex)`
  justify-content: flex-end;
`;

export const ModalFooter = ({
  dirty,
  isFetching,
  isSubmitting,
  isValid,
  onCancel,
}) => (
  <Footer>
    {typeof onCancel === 'function' && (
      <CancelButton
        disabled={isFetching || isSubmitting}
        onClick={onCancel}
        data-test-id="cancel-btn"
        type="button"
      >
        Cancel
      </CancelButton>
    )}
    <Button
      primary
      disabled={!isValid || isFetching || isSubmitting || !dirty}
      showSpinner={isFetching || isSubmitting}
      data-test-id="submit-btn"
      type="submit"
    >
      Confirm
    </Button>
  </Footer>
);

class AddCreditCard extends React.Component {
  state = {
    isCollapsed: !!this.props.card && this.props.collapsed,
    isValid: false,
  };

  componentDidMount() {
    track('credit-card-modal-shown', trackingProps);
  }

  onValidate = (isValid) => {
    this.setState({ isValid });
  };

  onEdit = (isCollapsed) => {
    this.setState({ isCollapsed });
  };

  render() {
    const {
      card,
      isFetching,
      isSubmitting,
      onCancel,
      onSubmit,
      source,
    } = this.props;
    const { isCollapsed, isValid } = this.state;
    return (
      <Container>
        <Header>Payment Details</Header>
        {isCollapsed && (
          <SubHeader>
            <Flex>
              <CardPreviewHeader card={card} onEdit={this.onEdit} />
            </Flex>
          </SubHeader>
        )}
        <CreditCardForm
          onSubmit={(e) => {
            track('credit-card-modal-submitted', trackingProps);
            return onSubmit(e, source);
          }}
          collapsed={isCollapsed}
        >
          <CreditCardFormElements
            trackingPage="billing-container"
            onValidate={this.onValidate}
            collapsed={isCollapsed}
          />
          <ModalFooter
            onCancel={onCancel}
            isFetching={isFetching}
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        </CreditCardForm>
      </Container>
    );
  }
}

AddCreditCard.defaultProps = {
  collapsed: true,
  isFetching: false,
  onCancel: null,
  source: 'accountSettings',
};

AddCreditCard.propTypes = {
  collapsed: PropTypes.bool,
  isFetching: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
};

ModalFooter.defaultProps = {
  isFetching: false,
  isValid: false,
  onCancel: null,
};

ModalFooter.propTypes = {
  onCancel: PropTypes.func,
  isFetching: PropTypes.bool,
  isValid: PropTypes.bool,
};

export default AddCreditCard;
