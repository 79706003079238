import React, { memo } from 'react';
import Home from '@frameio/components/src/svgs/icons/house.svg';
import Container from '../shared/Container';
import IconContainer from '../shared/IconContainer';
import Input from '../shared/Input';

const BillingAddress = ({ error, handleBlur, handleChange, touched }) => {
  return (
    <>
      <Container marginBottom>
        <IconContainer>
          <Home height={12} width={12} />
        </IconContainer>
        <Input
          error={!!touched && !!error}
          name="streetAddressLine1"
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Address Line 1"
        />
      </Container>
      <Container marginBottom>
        <IconContainer>
          <Home height={12} width={12} />
        </IconContainer>
        <Input
          name="streetAddressLine2"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Address Line 2 - optional"
        />
      </Container>
    </>
  );
};

export default memo(BillingAddress);
