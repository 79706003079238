import { takeLatest, select, all, call, put } from 'redux-saga/effects';
import { get } from 'lodash';
import { searchAssetsInAccount } from '@frameio/core/src/assets/sagas';
import { searchProjects } from '@frameio/core/src/projects/sagas';
import { teamEntitiesForAccountIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import {
  createPendingAction,
  createSuccessAction,
  createFailureAction,
} from '@frameio/core/src/shared/actions/helpers';
import JUMP_TO from './actions';

const page = 1;
const pageSize = 5;

function* typeahead({ payload: { accountId, query, options } }) {
  yield put(createPendingAction(JUMP_TO.TYPEAHEAD.PENDING));

  const teamIds = (yield select(teamEntitiesForAccountIdSelector, {
    accountId,
  })).map((team) => team.id);

  const [assets, projects] = yield all([
    call(searchAssetsInAccount, query, accountId, {
      ...options,
      page,
      pageSize,
    }),
    call(searchProjects, query, accountId, teamIds, {
      page,
      pageSize,
      includeSharedProjects: true,
    }),
  ]);

  if (assets.success || projects.success) {
    yield put(
      createSuccessAction(JUMP_TO.TYPEAHEAD.SUCCESS, {
        assets: get(assets, 'success.payload.response'),
        projects: get(projects, 'success.payload.response'),
      })
    );
  } else {
    yield put(
      createFailureAction(JUMP_TO.TYPEAHEAD.FAILURE, {
        assets: get(assets, 'failure.payload.error'),
        projects: get(projects, 'failure.payload.error'),
      })
    );
  }
}

export default [takeLatest(JUMP_TO.TYPEAHEAD.BASE, typeahead)];

export const testExports = {
  typeahead,
};
