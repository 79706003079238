import React from 'react';
import styled from 'styled-components';
import AemSendIcon from '@frameio/components/src/svgs/icons/AEM-icon.svg';
import WorkfrontConnectIcon from '@frameio/components/src/svgs/icons/16/link.svg';
import { AEM, WORKFRONT } from './integrationConstants';

const StyledWorkfrontIconContainer = styled.div`
  transform: rotate(45deg);
`;

const AdobeIntegrationConnectIcon = ({ integration }) => {
  switch (integration) {
    case AEM:
      return <AemSendIcon />;
    case WORKFRONT:
      return (
        <StyledWorkfrontIconContainer>
          <WorkfrontConnectIcon />
        </StyledWorkfrontIconContainer>
      );
    default:
      return null;
  }
};

export default AdobeIntegrationConnectIcon;
