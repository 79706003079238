import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@frameio/components';

/**
 * Footer for ProjectSettingsModal when editing a project.
 * @param {Object} props - React props.
 */
const EditProjectFooter = ({
  onDeleteButtonClick,
  isWaiting,
  onSaveButtonClick,
}) => (
  <div
    className="absolute z-4 bottom-0 w-100 pa3 flex flex-row items-center justify-around bt b--fio-gray bg-white"
    data-buttons
  >
    <Button
      className="pv3 bg-white fio-red bg-hover-fio-red b--fio-gray truncate mr2 dark-gray white-children-hover w-50"
      onClick={onDeleteButtonClick}
      isDisabled={isWaiting}
    >
      Delete Project
    </Button>
    <Button
      isWaiting={isWaiting}
      className="pv3 bg-white bg-hover-fio-purple b--fio-gray truncate ml2 dark-gray white-children-hover w-50"
      onClick={onSaveButtonClick}
      isDisabled={isWaiting}
    >
      Save Settings
    </Button>
  </div>
);

EditProjectFooter.defaultProps = {
  onSaveButtonClick: () => {},
  onDeleteButtonClick: () => {},
  isWaiting: false,
};

EditProjectFooter.propTypes = {
  isWaiting: PropTypes.bool,
  onSaveButtonClick: PropTypes.func,
  onDeleteButtonClick: PropTypes.func,
};

export default EditProjectFooter;
