import React from 'react';
import Proptypes from 'prop-types';
import { monthlyItemCost } from 'utils/plans/plansHelpers';
import { formatMoney } from 'formatters/money';
import ManageSubscriptionModal from './ManageSubscriptionModal';

const MAX_SEAT = 9999;

const generateChargedTodaySummary = (lineItemQuantity) =>
  `You'll be charged today for ${lineItemQuantity} new ${
    lineItemQuantity === 1 ? 'seat' : 'seats'
  }, prorated for the current billing period.`;

function generateAddOnUnit(addOnCount, shouldShowTotalLineItem) {
  if (addOnCount === 1) {
    return shouldShowTotalLineItem ? 'seat' : 'new seat';
  }
  return shouldShowTotalLineItem ? 'seats' : 'new seats';
}

function generateTotalAndUnit(amount, showTotalLineItem) {
  const total = amount;
  const unit = generateAddOnUnit(total, showTotalLineItem);
  return { total, unit };
}

const ManageTeamMemberModal = ({
  paymentPeriod,
  planLimit,
  planLineItem,
  planTitle,
  seatLimitType,
  seatCount,
  seatUnit,
  showTotalLineItem,
  updateItem,
  ...rest
}) => {
  const rate = planLineItem.cost;
  const header = `Manage ${seatUnit}s`;
  const costPerMonth = formatMoney(monthlyItemCost(paymentPeriod, rate), {
    decimal: true,
  });

  /* eslint-disable max-len */
  const subHeader = showTotalLineItem ? (
    <span>
      You currently have <strong>{seatCount}</strong> {planTitle}-plan{' '}
      {seatCount === 1 ? seatUnit : `${seatUnit}s`} at{' '}
      <strong>{costPerMonth} per seat monthly</strong>
    </span>
  ) : (
    <span>
      <strong>You are out of seats.</strong> To invite more members, please add
      additional seats.
    </span>
  );

  const renderLimitWarning = (selectedNumOfSeats) => {
    if (selectedNumOfSeats === 0) {
      return <p>You must have at least one seat on your plan.</p>;
    }
    return (
      <p>
        The {planTitle} comes with {planLimit} plan seats, so you cannot select
        less than {planLimit} seats.
      </p>
    );
  };
  /* eslint-enable */

  return (
    <ManageSubscriptionModal
      subHeader={subHeader}
      header={header}
      generateChargedTodaySummary={generateChargedTodaySummary}
      currentLimit={seatCount}
      renderLimitWarning={renderLimitWarning}
      planLineItem={planLineItem}
      planLimit={planLimit}
      updateItem={(amount) => updateItem(seatLimitType, amount)}
      maxValue={MAX_SEAT}
      toolTipText="a seat"
      generateItemText={(plural) => (plural ? `${seatUnit}s` : 'seat')}
      howToUseModalText="Click on the controls to add or remove seats to your account."
      minWidth={116}
      showTotalLineItem={showTotalLineItem}
      generateTotalAndUnit={generateTotalAndUnit}
      {...rest}
    />
  );
};

ManageTeamMemberModal.propTypes = {
  closeModal: Proptypes.func.isRequired,
  paymentPeriod: Proptypes.string.isRequired,
  planLimit: Proptypes.number.isRequired,
  planLineItem: Proptypes.shape({
    cost: Proptypes.number.isRequired,
    increment: Proptypes.number.isRequired,
  }).isRequired,
  planTitle: Proptypes.string.isRequired,
  seatLimitType: Proptypes.string.isRequired,
  seatCount: Proptypes.number.isRequired,
  seatUnit: Proptypes.string.isRequired,
  showTotalLineItem: Proptypes.bool.isRequired,
  updateItem: Proptypes.func.isRequired,
};

export default ManageTeamMemberModal;
