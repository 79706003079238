import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { tint } from 'polished';
import { Tooltip } from '@frameio/vapor';
import { motion } from 'framer-motion';
import LoadingIndicator from '@frameio/components/src/styled-components/LoadingIndicator';
import Spinner from '@frameio/components/src/styled-components/SpinnerBase';
import Truncate from '@frameio/components/src/styled-components/Truncate';
import AddProjectIcon from '@frameio/components/src/svgs/icons/plus.svg';
import Button from '@frameio/components/src/styled-components/Button';
import DropArrowIcon from '@frameio/components/src/svgs/icons/caron.svg';
import useDelayedState from '@frameio/components/src/hooks/useDelayedState';
import usePrevious from '@frameio/components/src/hooks/usePrevious';
import { SHARED_PROJECT_ID } from '../constants';

const strings = {
  ADD_PROJECT: 'Add Project',
};

const TeamHeader = styled(Truncate.withComponent('h3'))`
  font-size: ${(p) => p.theme.fontSize[2]};
  line-height: 24px;
  user-select: none;
  padding-right: ${(p) => p.theme.spacing.tiny};
`;

const DropArrow = styled(({ isPointingDown, ...rest }) => (
  <DropArrowIcon {...rest} />
))`
  width: 18px;
  height: 18px;
  transform: rotate(
    ${({ isPointingDown }) => (isPointingDown ? '0deg' : '-90deg')}
  );
  transition: transform 0.2s ease-in-out;
`;

const Wrapper = styled(Flex)`
  color: ${(p) => p.theme.color.graphiteGray};
  padding: ${({ theme: { spacing } }) => spacing.tiny};
  /* -2px on the left because the icon should be 16px, not the current 18px */
  padding-left: ${({ theme }) => `calc(${theme.spacing.tiny} - 2px)`};
  position: relative;

  ${TeamHeader} {
    flex: 1;
    margin-left: ${(p) => p.theme.spacing.tiny};
  }

  ${DropArrow} {
    flex: 0 0 auto;
  }
`;

const AddProjectButton = styled(Button).attrs(() => ({
  dark: true,
  text: true,
  compact: true,
  icon: 'true',
}))`
  width: ${(p) => p.theme.spacing.medium};
  height: ${(p) => p.theme.spacing.medium};
  color: inherit;
  background-color: ${(p) => p.theme.color.coolBlack};

  &:hover:not(:active):not(:disabled) {
    background-color: ${({ theme }) => tint(0.97, theme.color.coolBlack)};
  }
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  position: absolute;
  top: 50%;
  right: ${(p) => p.theme.spacing.units(1)};
  transform: translateY(-50%);
  /*
    This padding gets us closer to the appearance of smaller icons,
    which typically has a small amount of padding.
  */
  padding: 2px;
`;

const VisibilityToggle = styled(motion.div)`
  pointer-events: ${(p) => (p.isHidden ? 'none' : 'auto')};
`;

function ListTeamRow({
  name,
  teamId,
  isOpen,
  isLoadingProjects,
  loadingDelay = 300,
  handleAddProject,
}) {
  const teamName = teamId === SHARED_PROJECT_ID ? 'Shared Projects' : name;

  // Only show the spinner after 300ms of loading. This is effectively
  // the same logic implemented in the LoadingIndicator component,
  // but we need this state within this component to hide our AddProjectButton
  // when deferred loading has started.
  const isLoading = isLoadingProjects && isOpen;
  const previouslyLoading = usePrevious(isLoading);
  const showingSpinner = useDelayedState(
    previouslyLoading && !isLoading ? 0 : loadingDelay,
    isLoading
  );

  return (
    <Wrapper alignCenter justifyBetween>
      <DropArrow isPointingDown={isOpen} />
      <TeamHeader>{teamName}</TeamHeader>
      {teamId !== SHARED_PROJECT_ID && (
        <VisibilityToggle
          aria-hidden={showingSpinner}
          isHidden={showingSpinner}
          transition={{ duration: 0.3 }}
          animate={{ opacity: showingSpinner ? 0 : 1 }}
        >
          <Tooltip
            title={strings.ADD_PROJECT}
            placement="top"
            shouldUsePortal
            disabled={teamId === SHARED_PROJECT_ID}
          >
            <AddProjectButton
              className="ListTeamRow__AddProjectButton"
              onClick={(event) => {
                event.stopPropagation();
                handleAddProject(teamId);
              }}
            >
              <AddProjectIcon width={12} height={12} />
            </AddProjectButton>
          </Tooltip>
        </VisibilityToggle>
      )}
      <StyledLoadingIndicator isLoading={isLoading} delay={loadingDelay}>
        <Spinner spinning radius={10} color="125,130,156" stroke={2} />
      </StyledLoadingIndicator>
    </Wrapper>
  );
}

ListTeamRow.defaultProps = {
  isOpen: false,
  name: undefined,
  teamId: '',
  isLoadingProjects: false,
  handleAddProject: noop,
};

ListTeamRow.propTypes = {
  /** Team name. */
  name: PropTypes.string,
  /** If the team row is collapsed */
  isOpen: PropTypes.bool,
  /** Team id. */
  teamId: PropTypes.string,
  /** Whether we are currently loading the team project list. */
  isLoadingProjects: PropTypes.bool,
  /** When AddProjectButton is clicked. */
  handleAddProject: PropTypes.func,
};

export default React.memo(ListTeamRow);

export const testExports = {
  AddProjectButton,
  TeamHeader,
  StyledLoadingIndicator,
  VisibilityToggle,
};
