import styled from 'styled-components';
import { FlexItem } from 'styled-flex-component';

const ThumbContainer = styled(FlexItem).attrs(() => ({
  noShrink: true,
}))`
  position: relative;
  line-height: 1;
`;

export default ThumbContainer;
