import { createAction } from 'redux-actions';
import {
  generateActionTypes,
  generateFetchActionTypes,
} from 'shared/actions/helpers';

const module = 'PROJECT';

export const PROJECT = {
  ...generateActionTypes(module, [
    'CONFIRM_DELETE',
    'CONFIRM_LEAVE',
    'JOIN_SHARED',
    'JOIN_SHARED_BY_INVITE',
    'SET_CURRENT',
    'JOIN_AS_COLLABORATOR',
    'GET_USER_PREFS',
  ]),
  ...generateFetchActionTypes(module, ['FETCH_TREE']),
};

/**
 * Set's redux store currentProject to projectId, makes a request to the core,
 * updates the URL to reflect the currentProject id.
 * @param   {string} projectId - Project's Backend Id.
 * @param {string} prevProjectId - Previous project id to do any necessary cleanup
 * @returns {Object} - Action object.
 */
export const setCurrentProject = createAction(
  PROJECT.SET_CURRENT,
  (projectId, prevProjectId) => ({ projectId, prevProjectId })
);

/**
 * @param   {string} projectId - Project's id.
 * @param   {string} projectName - Project's name.
 * @returns {Object} - Action object.
 */
export const confirmDeleteProject = createAction(
  PROJECT.CONFIRM_DELETE,
  (projectId, projectName) => ({ projectId, projectName })
);

/**
 * @param   {string} projectId - Project's Id.
 * @param   {string} projectName - Project's name.
 * @returns {Object} - Action object.
 */
export const confirmLeaveProject = createAction(
  PROJECT.CONFIRM_LEAVE,
  (projectId, trackingPage, trackingPosition) => ({
    projectId,
    trackingPage,
    trackingPosition,
  })
);

/**
 * Handle joining a shared project as an authed/unauthed user.
 * @param {string} projectId - Project id.
 * @returns {Object} - Action object.
 */
export const joinSharedProject = createAction(
  PROJECT.JOIN_SHARED,
  (projectId) => ({ projectId })
);

/**
 * Handle joining a shared project by an invite as an authed user.
 * @param {string} projectId - Project id.
 * @returns {Object} - Action object.
 */
export const joinSharedProjectByInvite = createAction(
  PROJECT.JOIN_SHARED_BY_INVITE,
  (inviteToken) => ({ inviteToken })
);

export const LEGACY_PROJECT = generateActionTypes('LEGACY_PROJECT', [
  'SET_CURRENT',
]);

/**
 * Legacy AppViewController does not work with entities. So this is a temp thing
 * that simply sets the whole project object in the state tree. Since entity
 * selectors only care about currentProject.id, setting other properties on the
 * currentProject will not affect them.
 * @param {Object} project - Project object.
 */
export const setLegacyCurrentProject = createAction(
  LEGACY_PROJECT.SET_CURRENT,
  (project) => ({ project })
);

/**
 * @param {string} projectId - Project id.
 * @param {string} userId - User id.
 * @returns {Object} - Action object with projectId.
 */
export const joinProjectAsCollaborator = createAction(
  PROJECT.JOIN_AS_COLLABORATOR,
  (projectId, trackingPage, trackingPosition) => ({
    projectId,
    trackingPage,
    trackingPosition,
  })
);

export const getProjectUserPreferences = createAction(
  PROJECT.GET_USER_PREFS,
  (projectId) => ({ projectId })
);

/**
 * Fetch folder tree for a given project id.
 * NOTE: Response does not include files within those folders.
 * @param {string} projectId - Project id.
 */
export const fetchTree = createAction(PROJECT.FETCH_TREE.BASE, (projectId) => ({
  projectId,
}));
