import { without, keyBy, difference, identity, get } from 'lodash';
import reduceReducers from 'reduce-reducers';
import { ASSET, AssetEvents } from '@frameio/core/src/assets/actions';
import {
  assetEntitiesByAssetIdsSelector,
  childrenAssetIdsSelector,
} from '@frameio/core/src/assets/selectors';
import {
  generatePaginatedListReducer,
  INITIAL_PAGINATED_LIST_STATE,
} from '@frameio/core/src/shared/reducers/factories';
import { paginatedListPageMutationReducer } from '@frameio/core/src/shared/reducers/helpers';
import { BATCH_RECEIVE_EVENTS_TYPE } from '@frameio/core/src/shared/actions/helpers';
import { getEntityFromNormalizedResponse } from '@frameio/core/src/shared/utils/entities';
import { paginatedListAllResultsSelector } from '@frameio/core/src/shared/selectors';
import { currentFolderOrProjectRootIdSelector } from 'selectors/folders';
import { isCollaboratorOnlySelector } from 'selectors/roles';
import { PROJECT_CONTAINER } from '../actions';
import { sortAssetIds } from '../ProjectAssets/sortOptions';

import {
  assetsSortBySelector,
  assetsSortDescendingSelector,
  getPlaceholderAssetId,
  isPlaceholderInFolder,
  assetEntitiesInFolderSelector,
} from '../selectors';

export const INITIAL_STATE = {
  ...INITIAL_PAGINATED_LIST_STATE,
  flipKey: 0,
};

/**
 * Reducer to remove an array of asset ids from the adjacency list.
 * @param {Object} state - The current state of the adjacency list.
 * @param {string[]} assetIdsToRemove - The array of asset ids to remove.
 * @returns {Object} New state.
 */
function removeAssetIds(state, assetIdsToRemove) {
  return paginatedListPageMutationReducer(state, (pageNum, result) =>
    without(result, ...assetIdsToRemove)
  );
}

/**
 * Reducer to replace an asset id with another in the adjacency list.
 * @param {Object} state - The current state of the adjacency list.
 * @param {string} from - The id of the asset to replace.
 * @param {string} to - The id of the asset to replace it with.
 * @returns {Object} New state.
 */
function replaceAssetId(state, from, to) {
  return paginatedListPageMutationReducer(state, (pageNum, result) =>
    result.map((assetId) => (assetId === from ? to : assetId))
  );
}

/**
 * Reducer to add an array of asset entities into the adjacency list.
 *
 * NOTE:
 * Because this reducer uses rootState, you should not reduce an array using
 * this reducer since this will end up being the same as calling the reducer
 * only on the last assetToInsert as rootState is only updated when your reducer
 * returns. Instead, collect all the asset to inserts and then call this reducer
 * once.
 * @param {Object} state - The current state of the adjacency list.
 * @param {Asset[]} assetsToInsert[] - The asset entity to insert.
 * @param {boolean} forceInsert - Inserts the assets into the list even though they are not
   the children of the current folder. This is useful in unversioning mostly.
 * @returns {Object} New State.
 */
function insertAssets(state, assetsToInsert, rootState, forceInsert = false) {
  const assetsInCurrentFolder = forceInsert
    ? assetsToInsert
    : assetsToInsert.filter(
        (asset) =>
          asset.parent_id === currentFolderOrProjectRootIdSelector(rootState)
      );

  if (!assetsInCurrentFolder.length) {
    return state;
  }

  // Note: this *should* select the results from `state` and not `rootState`
  // since this function can be chained with other mutation reducers and thus we
  // always want the most recent state that has yet to be commited to redux.

  // Filter out the padded `undefineds` since we just want the values--when the
  // selector runs again after we commit the mutation, it will pad the pages
  // accordingly.
  const assetIds = paginatedListAllResultsSelector(state).filter(identity);
  const assetIdsToInsert = difference(
    assetsInCurrentFolder.map((asset) => asset.id),
    assetIds
  );

  // No need to insert since the assetsToInsert already exist in the list so we
  // return early.
  if (!assetIdsToInsert.length) {
    return state;
  }

  const assetsSortBy = assetsSortBySelector(rootState);
  const sortDescending = assetsSortDescendingSelector(rootState);

  const newAssetIds = [...assetIds, ...assetIdsToInsert];
  // These include placeholder entities as well, so we cannot simply use the
  // asset entities selector from core.
  const entities = assetEntitiesInFolderSelector(rootState);
  const sortedAssetIds = sortAssetIds(
    newAssetIds,
    keyBy([...entities, ...assetsInCurrentFolder], 'id'),
    assetsSortBy,
    sortDescending
  );

  return paginatedListPageMutationReducer(state, (pageNum) =>
    pageNum === 1 ? sortedAssetIds : []
  );
}

/**
 * Check if the asset has the `properties.is_bundle_child` set.
 * This applies to assets that have been created as children
 * of a bundle and need to be hidden from the project view.
 * @param {Object} asset
 */
function isBundleChild(asset) {
  return get(asset, 'properties.is_bundle_child', false);
}

/**
 * Reducer to insert an array of asset ids into the adjacency list.
 * @param {Object} state - The current state of the adjacency list.
 * @param {string[]} assetIds - The array of asset ids to insert.
 * @param {boolean} forceInsert - Inserts the assets into the list even though they are not
   the children of the current folder. This is useful in unversioning mostly.
 * @returns {Object} New state.
 */
function insertAssetIds(state, assetIds, rootState, forceInsert = false) {
  const assets = assetEntitiesByAssetIdsSelector(rootState, { assetIds });
  return insertAssets(state, assets, rootState, forceInsert);
}

/**
 * Reducer to reposition an array of assets into the list based on their `index` values.
 * @param {Object} state - The current state of the adjacency list.
 * @param {Asset[]} assetsToMove - The asset entities to move.
 * @returns {Object} New state.
 */
function moveAssets(state, assetsToMove, rootState) {
  if (!assetsToMove.length) return state;

  const stateWithoutAssets = removeAssetIds(
    state,
    assetsToMove.map((asset) => asset.id)
  );
  // assetsToMove won't have a parent_id, and since we only update the adjacency list for moves
  // within a given folder, we can force insert them into their new indexes.
  return insertAssets(stateWithoutAssets, assetsToMove, rootState, true);
}

const assetsPaginatedListReducer = generatePaginatedListReducer(
  PROJECT_CONTAINER.FETCH_ASSETS
);

function assetsOptimisticUpdatesReducer(state, action, rootState) {
  switch (action.type) {
    case ASSET.VERSION.BASE: {
      const { assetId } = action.payload;
      return {
        ...removeAssetIds(state, [assetId]),
        flipKey: state.flipKey + 1,
      };
    }
    case ASSET.VERSION.SUCCESS: {
      const {
        response: { result: versionStackId },
        secondaryEntityId: targetAssetId,
      } = action.payload;
      // Dropping a file into an existing version stack is a no-op since that's already handled by
      // the `PROJECT_CONTAINER.VERSION_ASSET` case above.
      if (versionStackId === targetAssetId) return state;

      // Creating a new version stack;
      return replaceAssetId(state, targetAssetId, versionStackId);
    }
    case ASSET.VERSION.FAILURE: {
      const { entityId: assetId } = action.payload;
      return insertAssetIds(state, [assetId], rootState);
    }
    case ASSET.UNVERSION.PENDING: {
      const { entityId: assetId } = action.payload;
      const stateWithoutVersionStack = removeAssetIds(state, [assetId]);
      const childIds = childrenAssetIdsSelector(rootState, { assetId });
      return {
        // Because we're optimistically adding the files in the stack into the
        // folder, their parent is still the versionStack, and so we have to
        // force insert here.
        ...insertAssetIds(stateWithoutVersionStack, childIds, rootState, true),
        flipKey: state.flipKey + 1,
      };
    }
    case ASSET.UNVERSION.FAILURE: {
      const { entityId: assetId } = action.payload;
      const childIds = childrenAssetIdsSelector(rootState, { assetId });
      return insertAssetIds(
        removeAssetIds(state, childIds),
        [assetId],
        rootState
      );
    }
    // For cancelling a single upload
    case ASSET.DELETE.BASE: {
      const { assetId } = action.payload;
      return {
        ...removeAssetIds(state, [assetId]),
        flipKey: state.flipKey + 1,
      };
    }
    case ASSET.DELETE.FAILURE: {
      const { entityId: assetId } = action.payload;
      return insertAssetIds(state, [assetId], rootState);
    }
    case ASSET.DELETE.SUCCESS: {
      const { entityId: assetId } = action.payload;
      return {
        ...removeAssetIds(state, [assetId]),
        flipKey: state.flipKey + 1,
      };
    }
    case ASSET.BATCH_UPDATE.BASE: {
      const { updates } = action.payload;
      const updatesWithMoves = updates.filter(
        (update) => update.index !== undefined
      );
      return {
        ...moveAssets(state, updatesWithMoves, rootState),
        flipKey: state.flipKey + 1,
      };
    }
    case ASSET.BATCH_UPDATE.SUCCESS: {
      const {
        response: { error },
      } = action.payload;
      const assetIds = Object.keys(error);
      const assets = assetEntitiesByAssetIdsSelector(rootState, { assetIds });
      return moveAssets(state, assets, rootState);
    }
    case ASSET.BATCH_UPDATE.FAILURE: {
      const { entityId: assetIds } = action.payload;
      const assets = assetEntitiesByAssetIdsSelector(rootState, { assetIds });
      return moveAssets(state, assets, rootState);
    }
    // Batch removal
    case ASSET.BATCH_DELETE.BASE:
    case ASSET.BATCH_MOVE.BASE: {
      const { assetIds } = action.payload;
      return {
        ...removeAssetIds(state, assetIds),
        flipKey: state.flipKey + 1,
      };
    }
    // Batch removal partial rollbacks:
    case ASSET.BATCH_DELETE.SUCCESS:
    case ASSET.BATCH_MOVE.SUCCESS: {
      const {
        response: { error },
      } = action.payload;
      const assetIds = Object.keys(error);
      return insertAssetIds(state, assetIds, rootState);
    }
    // Batch removal complete rollbacks:
    case ASSET.BATCH_DELETE.FAILURE:
    case ASSET.BATCH_MOVE.FAILURE: {
      const { entityId: assetIds } = action.payload;
      return insertAssetIds(state, assetIds, rootState);
    }
    // Optimistic asset creation (uploads and new folders)
    case PROJECT_CONTAINER.CREATE_ASSET_PLACEHOLDERS: {
      const { assets } = action.payload;
      const placeholderAssets = Object.values(assets);
      const numTopLevel = placeholderAssets.filter((asset) =>
        isPlaceholderInFolder(asset.id, placeholderAssets[0].parent_id)
      ).length;
      return {
        ...insertAssets(state, placeholderAssets, rootState),
        flipKey: numTopLevel === 1 ? state.flipKey + 1 : state.flipKey,
      };
    }
    case ASSET.CREATE_BUNDLE.SUCCESS:
    case ASSET.CREATE.SUCCESS: {
      const {
        response: {
          entities: { asset: assets },
          result,
        },
      } = action.payload;
      const { id: assetId, parent_id, index } = assets[result];
      // Don't show bundle asset children in the project view
      if (isBundleChild(assets[result])) return state;
      const placeholderId = getPlaceholderAssetId(parent_id, index);
      return replaceAssetId(state, placeholderId, assetId);
    }
    default:
      return state;
  }
}

/**
 * This reducer updates the adjacency list when any action mutates the list of assets in the
 * dashboard after it has been fetched. It optimisitically updates the list where possible.
 * Otherwise it'll wait for the corresponding SUCCESS action of and update the list then.
 * @param {Object} state - The state of the paginated list of assets.
 * @param {Object} action - The action that mutates the list.
 * @return {Object} Returns the updated paginated list data.
 */
function assetsMutationReducer(state = INITIAL_STATE, action, rootState) {
  switch (action.type) {
    case ASSET.UNVERSION.SUCCESS:
    case ASSET.BATCH_COPY.SUCCESS: {
      const {
        response: {
          entities: { asset: assets },
        },
      } = action.payload;
      return {
        ...insertAssets(state, Object.values(assets), rootState),
        flipKey: state.flipKey + 1,
      };
    }
    case ASSET[BATCH_RECEIVE_EVENTS_TYPE]: {
      const { events } = action.payload;

      // As noted in the docs for insertAssets above, we should not chain calls to insertAssets
      // since the partial state will not have been committed to the store, and so successive calls
      // will not get the new entities that are being added.

      // So, we simply reduce all the events into 2 arrays and call insertAssets and removeAssetIds
      // once at the end.
      const { assetsToAdd, assetIdsToRemove } = events.reduce(
        (acc, { data, type }) => {
          const asset = getEntityFromNormalizedResponse(data);
          switch (type) {
            case AssetEvents.AssetCreated: {
              const { parent_id, index } = asset;
              // Don't show bundle asset children in the project view
              if (isBundleChild(asset)) return acc;
              const placeholderId = getPlaceholderAssetId(parent_id, index);
              return {
                ...acc,
                assetsToAdd: [...acc.assetsToAdd, asset],
                assetIdsToRemove: [...acc.assetIdsToRemove, placeholderId],
              };
            }
            case AssetEvents.AssetCopied:
            case AssetEvents.AssetRestored:
            case AssetEvents.AssetPublicized:
              return {
                ...acc,
                assetsToAdd: [...acc.assetsToAdd, asset],
              };
            case AssetEvents.AssetDeleted:
              return {
                ...acc,
                assetIdsToRemove: [...acc.assetIdsToRemove, asset.id],
              };
            case AssetEvents.AssetMoved:
              return {
                assetsToAdd: [...acc.assetsToAdd, asset],
                assetIdsToRemove: [...acc.assetIdsToRemove, asset.id],
              };
            case AssetEvents.AssetVersioned: {
              return {
                assetsToAdd: [...acc.assetsToAdd, asset],
                assetIdsToRemove: [...acc.assetIdsToRemove, ...asset.children],
              };
            }
            case AssetEvents.AssetUnversioned: {
              const childAssets =
                asset.children?.map((assetId) => ({
                  ...data.entities.asset[assetId],
                })) || [];

              return {
                assetsToAdd: [...acc.assetsToAdd, ...childAssets],
                assetIdsToRemove: [...acc.assetIdsToRemove, asset.id],
              };
            }
            case AssetEvents.AssetPrivatized: {
              const { project_id: projectId } = asset;
              const isCollaborator = isCollaboratorOnlySelector(rootState, {
                projectId,
              });
              if (!isCollaborator) return acc;

              return {
                ...acc,
                assetIdsToRemove: [...acc.assetIdsToRemove, asset.id],
              };
            }

            default:
              return acc;
          }
        },
        {
          assetsToAdd: [],
          assetIdsToRemove: [],
        }
      );

      return {
        ...insertAssets(
          removeAssetIds(state, assetIdsToRemove),
          assetsToAdd,
          rootState
        ),
        flipKey: state.flipKey + 1,
      };
    }
    default:
      return assetsPaginatedListReducer(state, action);
  }
}

const assetsListReducer = reduceReducers(
  assetsMutationReducer,
  assetsOptimisticUpdatesReducer
);

export default assetsListReducer;

export const testExports = {
  assetsMutationReducer,
  assetsOptimisticUpdatesReducer,
  sortAssetIds,
};
