import React from 'react';
import styled from 'styled-components';
import { LARGE } from 'utils/mediaQueries';
import Media from 'react-media';
import Vice from '../svgs/vice-logo.svg';
import BBC from '../svgs/bbc-logo.svg';
import CBS from '../svgs/cbs-logo.svg';
import Buzzfeed from '../svgs/buzzfeed-logo.svg';

const Footer = styled.div`
  background-color: ${(p) => p.theme.color.silver};
  color: ${(p) => p.theme.color.graphiteGray};
  font-size: ${(p) => p.theme.fontSize[2]};
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.medium}`};
  bottom: 0;
  width: 100%;
`;

const LogosContainer = styled.div`
  display: flex;
  svg {
    margin-left: ${(p) => p.theme.spacing.medium};
  }
`;

export default function PlanSummaryFooter() {
  return (
    <Media query={LARGE}>
      <Footer>
        <p>
          Trusted by over a million video pros in organizations big and small.
        </p>
        <LogosContainer>
          <Vice />
          <BBC />
          <CBS />
          <Buzzfeed />
        </LogosContainer>
      </Footer>
    </Media>
  );
}
