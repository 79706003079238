import { connect } from 'react-redux';
import { openDevToolsModal } from '../actions';
import OpenDevTools from './OpenDevTools';
import { canSeeDevToolsSelector } from './selectors';

function mapStateToProps(state) {
  return {
    canPerformAction: canSeeDevToolsSelector(state),
  };
}

const mapDispatchToProps = { openDevTools: openDevToolsModal };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenDevTools);
