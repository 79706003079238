import React from 'react';
import PropTypes from 'prop-types';
import { label as WORKFLOW_STATUSES } from '@frameio/core/src/assets/helpers/constants';
import Badge from '@frameio/components/src/styled-components/Badge';
import { Indicator as StatusIndicator } from 'components/AssetWorkflowStatus/AssetWorkflowStatusLabel';
import './BaseThumb.scss';

export const HEIGHT_OFFSET = 16;
export const WIDTH_OFFSET = 4;
function BaseThumb(props) {
  const {
    height,
    width,
    selected,
    view,
    caption,
    onClick,
    captionViewSpacing,
    status,
    versions,
  } = props;
  const viewHeight = height - captionViewSpacing - HEIGHT_OFFSET - WIDTH_OFFSET;
  const viewWidth = width - WIDTH_OFFSET;
  const children = React.cloneElement(view, {
    height: viewHeight,
    width: viewWidth,
  });
  return (
    <div
      className="base-thumb-container"
      role="presentation"
      style={{
        height,
        width,
      }}
      onClick={onClick}
    >
      <div
        className="base-thumb-container-inner"
        style={{
          height: height - captionViewSpacing - HEIGHT_OFFSET,
          width,
        }}
      >
        <div
          className={`base-thumb-image-container relative ${
            selected
              ? 'base-thumb-image-container-selected'
              : 'base-thumb-image-container-unselected'
          }`}
          style={{
            height: viewHeight,
            width: viewWidth,
          }}
        >
          {children}
          <div className="absolute base-thumb__versions-badge">
            {status !== WORKFLOW_STATUSES.NONE && (
              <Badge>
                <StatusIndicator status={status} isLarge />
              </Badge>
            )}
            {versions > 0 && (
              <Badge className="pa-fio1 ml1">
                <span className="base-thumb_v">v</span>
                {versions}
              </Badge>
            )}
          </div>
        </div>
      </div>
      {caption && (
        <div
          className="base-thumb-caption-container"
          style={{
            marginTop: captionViewSpacing,
          }}
        >
          {caption}
        </div>
      )}
    </div>
  );
}

BaseThumb.defaultProps = {
  captionViewSpacing: 3,
  selected: false,
  onClick: () => {},
  status: WORKFLOW_STATUSES.NONE,
  versions: null,
  caption: null,
};

BaseThumb.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  captionViewSpacing: PropTypes.number,
  caption: PropTypes.string,
  onClick: PropTypes.func,
  view: PropTypes.element.isRequired,
  selected: PropTypes.bool,
  status: PropTypes.oneOf(Object.values(WORKFLOW_STATUSES)),
  versions: PropTypes.number,
};

export default BaseThumb;
