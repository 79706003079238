import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useMotionValue } from 'framer-motion';

import {
  HALF_THUMB_WIDTH,
  MIN,
  MAX,
  SLIDER_WIDTH,
  STEP,
  X_TRANSITION_DURATION,
  convertRangeValueToOffset,
  getThumbAnimateProps,
  mapToRange,
} from '../utils';

import {
  SliderWrapper,
  SliderInput,
  SliderInputGhost,
  SliderThumb,
  SliderValuePopover,
  SliderNativeInput,
} from './styles';

const Slider = ({
  storedValue,
  onChangeStoredValue,
  isDragging,
  onSetIsDragging,
}) => {
  const [thumbOffset, setThumbOffset] = useState(() =>
    convertRangeValueToOffset(storedValue)
  );
  const [roundedValue, setRoundedValue] = useState(0);
  const [currentValue, setCurrentValue] = useState(0);
  const [xTransitionDuration, setXTransitionDuration] = useState(0);
  const [thumbTransitionDelay, setThumbTransitionDelay] = useState(0.2);
  const constraintsRef = useRef(null);

  const initialOffset = mapToRange(storedValue, MIN, MAX, 0, SLIDER_WIDTH);
  const thumbX = useMotionValue(initialOffset - HALF_THUMB_WIDTH);

  // Take an x offset and map it to MIN and MAX.
  // Also map this number to a rounded value (similar to input steps)
  const mapAndSetValuesFromOffset = useCallback((offset, roundToNum = STEP) => {
    const pct = offset / SLIDER_WIDTH;
    const newRelatedInputValue = mapToRange(pct, 0, 1, MIN, MAX);
    setCurrentValue(newRelatedInputValue);

    const newRoundedValue =
      Math.round(newRelatedInputValue / roundToNum) * roundToNum;
    const roundedToDecimal = Math.round(newRoundedValue * 100) / 100; // round to a max of 2 decimal places
    setRoundedValue(roundedToDecimal);

    return roundedToDecimal;
  }, []);

  // Update x of the slider to the stored value.
  useEffect(() => {
    const stepOffset = mapToRange(storedValue, MIN, MAX, 0, SLIDER_WIDTH);
    setThumbOffset(stepOffset - HALF_THUMB_WIDTH);
    thumbX.set(stepOffset - HALF_THUMB_WIDTH);
    setXTransitionDuration(0);
    setThumbTransitionDelay(0);

    // We only want to run this when the stored value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedValue]);

  // When the offset changes, we need to convert it to a
  // rounded value (STEP) between the MIN and MAX.
  useEffect(() => {
    const offset = thumbOffset + HALF_THUMB_WIDTH;
    mapAndSetValuesFromOffset(offset);
  }, [thumbOffset, thumbX, mapAndSetValuesFromOffset]);

  const handleInputTapStart = useCallback(
    (evt, info) => {
      const rect = evt.target.getBoundingClientRect();
      const pointX = info.point.x;
      const offset = pointX - rect.left + HALF_THUMB_WIDTH;
      const roundedTapValue = mapAndSetValuesFromOffset(offset, 0.25);

      onChangeStoredValue(roundedTapValue);
      setThumbOffset(convertRangeValueToOffset(roundedTapValue));
    },
    [onChangeStoredValue, mapAndSetValuesFromOffset]
  );

  const handleDrag = useCallback((evt, info) => setThumbOffset(info.point.x), [
    setThumbOffset,
  ]);

  const handleDragEnd = useCallback(() => {
    onSetIsDragging(false);
    onChangeStoredValue(roundedValue);
    setXTransitionDuration(X_TRANSITION_DURATION);
  }, [onSetIsDragging, roundedValue, onChangeStoredValue]);

  const handleTapStart = useCallback(
    (evt) => {
      evt.stopPropagation();
      onSetIsDragging(true);
      setXTransitionDuration(0);
    },
    [onSetIsDragging]
  );

  const handleTapEnd = useCallback(
    (evt) => {
      evt.stopPropagation();
      onSetIsDragging(false);
      setXTransitionDuration(X_TRANSITION_DURATION);
    },
    [onSetIsDragging]
  );

  return (
    <SliderWrapper isDragging={isDragging} onTapStart={handleInputTapStart}>
      <SliderInputGhost
        initial={{ opacity: 0 }}
        animate={{ opacity: isDragging ? 1 : 0 }}
        transition={{
          opacity: {
            type: 'tween',
            duration: 0.2,
          },
        }}
      />
      <SliderInput
        ref={constraintsRef}
        initial={{ opacity: 0, height: 0 }}
        animate={{
          height: '6px',
          opacity: 1,
          borderRadius: isDragging ? '5px' : '3px',
        }}
        transition={{
          opacity: {
            type: 'tween',
            duration: 0.2,
            delay: 0.2,
          },
        }}
      >
        <SliderThumb
          initial={{
            scaleX: 0,
            scaleY: 0,
          }}
          animate={{
            ...getThumbAnimateProps({ isDragging, currentValue }),
            x: thumbX.get(),
          }}
          drag="x"
          dragConstraints={{
            left: HALF_THUMB_WIDTH * -1,
            right: SLIDER_WIDTH - HALF_THUMB_WIDTH,
          }}
          dragElastic={0}
          dragMomentum={false}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          onTapStart={handleTapStart}
          onTap={handleTapEnd}
          onTapCancel={handleTapEnd}
          transition={{
            scaleX: { type: 'spring', damping: 11, mass: 0.2, stiffness: 130 },
            scaleY: { type: 'spring', damping: 11, mass: 0.2, stiffness: 130 },
            x: { type: 'tween', duration: xTransitionDuration },
            delay: thumbTransitionDelay,
          }}
        />
      </SliderInput>
      <SliderValuePopover
        style={{ transform: `translate(${thumbOffset - 8}px, -100%)` }}
      >
        {roundedValue}
      </SliderValuePopover>
      <SliderNativeInput
        max={MAX}
        min={MIN}
        step={STEP}
        type="range"
        onChange={onChangeStoredValue}
        value={storedValue}
      />
    </SliderWrapper>
  );
};

Slider.propTypes = {
  storedValue: PropTypes.number.isRequired,
  onChangeStoredValue: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onSetIsDragging: PropTypes.func.isRequired,
};

export default Slider;
