class JTCurve {

  static elastic(t, b, c, d) {
    let s = 0.01;
    let p = 0;
    let a = c;
    if (t === 0) {
      return b;
    }
    if ((t /= d) === 1) {
      return b + c;
    }
    if (!p) {
      p = d * .3;
    }
    if (a < Math.abs(c)) {
      a = c;
      s = p / 4;
    } else {
      s = (p / (2 * Math.PI)) * Math.asin((c / a));
    }
    return (a * Math.pow(2, -10 * t) * Math.sin((((t * d) - s) * (2 * Math.PI)) / p)) + c + b;
  }

  static elastic2(t, b, c, d) {
    let ts = (t /= d) * t;
    let tc = ts * t;
    return b + (c * ((-8.1525 * tc * ts) + (28.5075 * ts * ts) + (-35.105 * tc) + (16 * ts) + (-0.25 * t)));
  }

  static easeOutBounce(t, b, c, d) {
    if ((t /= d) < (1 / 2.75)) {
      return (c * (7.5625 * t * t)) + b;
    } else if (t < (2 / 2.75)) {
      return (c * ((7.5625 * (t -= (1.5 / 2.75)) * t) + .75)) + b;
    } else if (t < (2.5 / 2.75)) {
      return (c * ((7.5625 * (t -= (2.25 / 2.75)) * t) + .9375)) + b;
    } else {
      return (c * ((7.5625 * (t -= (2.625 / 2.75)) * t) + .984375)) + b;
    }
  }

  static backInOut(time, begin, change, duration, overshoot) {
    if (overshoot == null) { overshoot = 1.70158; }
    if ((time = time / (duration / 2)) < 1) {
      return ((change / 2) * (time * time * ((((overshoot *= (1.525)) + 1) * time) - overshoot))) + begin;
    } else {
      return ((change / 2) * (((time -= 2) * time * ((((overshoot *= (1.525)) + 1) * time) + overshoot)) + 2)) + begin;
    }
  }

  static accel(time, begin, change, duration) {
    let a = 4;
    if ((time = time / duration) < (0.5)) {
      return ((0.5) * (a) * time * time) + begin;
    } else {
      time -= (0.5);
      return ((0.5) * (-a) * time * time) + ((a) * (0.5) * time) + (0.5) + begin;
    }
  }

  static damp(time, begin, change, duration) {
    time = ((time * 1.0) / duration) * 4;
    // l1 = -1.1
    let l1 = -3;
    let l2 = 3;
    return (change - ((Math.exp(l1 * time) * Math.cos(l2 * time)) + (1.48 * Math.exp(l1 * time) * Math.sin(l2 * time)))) + begin;
  }

  static damp2(time, begin, change, duration) {
    time = ((time * 1.0) / duration) * 4;
    // l1 = -1.1
    let l1 = -3;
    let l2 = 3;
    return (change - ((Math.exp(l1 * time) * Math.cos(l2 * time)) + (1.48 * Math.exp(l1 * time) * Math.sin(l2 * time)))) + begin;
  }

  static easeandwizz(t, b, c, d) {
    return c * Math.sqrt(1 - (t=t/d-1)*t) + b;
}
}

export default JTCurve;
