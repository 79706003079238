import styled from 'styled-components';

const Subheader = styled.h3`
  margin: ${({ theme }) => `20px 0 ${theme.spacing.small}`};
  color: ${(p) => p.theme.color.slateGray};
  text-transform: uppercase;
  font-size: ${(p) => p.theme.fontSize[1]};
`;

export default Subheader;
