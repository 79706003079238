import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  accountIdForPresentationIdSelector,
  teamEntityForAssetIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import { showSuccessToast } from 'actions/toasts';
import { closeModal } from 'components/Modal/actions';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import { fetchPlayerContainerAssetsWithoutTouchingData } from 'pages/PlayerContainer/actions';
import { permittedActionsForAccountSelector } from 'selectors/permissions';
import SecurePresentationEditor from './SecurePresentationEditor';
import { updatePresentation } from '../actions';
import {
  assetsForPresentationSelector,
  isBlockingReelPlayerSelector,
  presentationForEditorSelector,
} from '../selectors';

const mapDispatchToProps = (dispatch, { assetId }) => ({
  updatePresentation: (...args) => dispatch(updatePresentation(...args)),
  closeModal: () => {
    // HACK: ENT-2945 Refetch the player assets on close to make sure watermarked
    // assets are correctly shown/not shown in the player rather than using presentation settings
    dispatch(fetchPlayerContainerAssetsWithoutTouchingData(assetId));
    dispatch(closeModal());
  },
  showLinkCopiedToast: (...args) => dispatch(showSuccessToast(...args)),
});

function mapStateToProps(state, { assetId }) {
  const presentation = presentationForEditorSelector(state, { assetId });
  const accountId = accountIdForPresentationIdSelector(state, {
    presentationId: presentation.id,
  });
  const selectedAsset = assetEntitySelector(state, { assetId });
  const { isForensicFallbackEnabled } = permittedActionsForAccountSelector(
    state,
    {
      accountId,
    }
  );

  return {
    accountId,
    assets: assetsForPresentationSelector(state, {
      assetIds: get(presentation, 'assets'),
    }),
    isBlockingReelPlayer: isBlockingReelPlayerSelector(state),
    isForensicFallbackEnabled,
    team: teamEntityForAssetIdSelector(state, { assetId }),
    presentation,
    selectedAsset,
    title: get(presentation, 'title'),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurePresentationEditor);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
