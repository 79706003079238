import React from 'react';
import PropTypes from 'prop-types';
import { safariOnly } from '@frameio/components/src/mixins';
import { FixedSizeList as List } from 'react-window';
import styled from 'styled-components';
import AutoSizer from 'react-virtualized-auto-sizer';
import { SHARED_PROJECT_ID } from '../constants';
import ArchivedProjectsHeader from '../ArchivedProjectsHeader';
import ListProjectRow from '../ListProjectRow';
import ListTeamGroup from '../ListTeamGroup';

import './ProjectList.scss';

const FixedWidthContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: ${(p) =>
    `${p.theme.spacing.units(1)} ${p.theme.spacing.units(1)} 0`};
  /* Add some space to visually clear the bottom, specifically on iOS Safari */
  .ProjectList__Team:last-child {
    padding-bottom: 18px;
    ${safariOnly(() => `padding-bottom: 30px;`)}
  }
  ${({ isDisabled }) =>
    isDisabled &&
    `opacity: 0.5;
    * {
      pointer-events: none;
    }
  `}
`;

/**
 * Dashbaord Sidebar Project/Team according list.
 */
export default class ProjectList extends React.Component {
  /**
   * Project List constructor.
   * @param  {Object} props - React props passed from parent.
   */
  constructor(props) {
    super(props);
    this.state = {
      SHARED: true,
      shouldHoistSelectedProject: true,
    };
  }

  listRef = React.createRef();

  componentDidMount() {
    // TODO: This is only required because the project list is unmounted on player
    // page entry. We can potentially keep ProjectList mounted by exploring how to
    // remove LayoutWithNav from AppRouter.
    const { listToggles, selectedTeamId } = this.props;

    if (selectedTeamId && !listToggles[selectedTeamId]) {
      this.openTab(selectedTeamId);
    }
  }

  componentDidUpdate({
    accountId: prevAccountId,
    selectedTeamId: prevSelectedTeamId,
  }) {
    const {
      accountId,
      jumpToSelected,
      jumpToSelectedValue,
      listToggles,
      selectedTeamId,
      selectedProjectIndex,
      sharedProjectsFetched,
      teamIds,
    } = this.props;
    // logic for opening selected team when page loads and hoisting into view
    // complicated by JumpTo on Top Bar and with Shared projects
    // for shared projects, the selectedTeamId is not SHARED_PROJECT_ID
    const selectedTeamInTeamIds = teamIds?.indexOf(selectedTeamId) >= 0;
    const shouldOpenCurrentlySelectedTeam =
      prevSelectedTeamId !== selectedTeamId &&
      selectedTeamId &&
      (!listToggles[selectedTeamId] || !listToggles[SHARED_PROJECT_ID]);

    if (
      jumpToSelectedValue &&
      (!listToggles[selectedTeamId] || !listToggles[SHARED_PROJECT_ID])
    ) {
      this.openTab(selectedTeamId);
      if (!listToggles[SHARED_PROJECT_ID] && !selectedTeamInTeamIds)
        this.openTab(SHARED_PROJECT_ID);
    }
    if (jumpToSelectedValue && selectedProjectIndex > 0) {
      this.hoistSelectedProject(selectedProjectIndex);
      jumpToSelected(false);
    }
    if (shouldOpenCurrentlySelectedTeam) {
      this.openTab(selectedTeamId);
      if (!listToggles[SHARED_PROJECT_ID] && !selectedTeamInTeamIds)
        this.openTab(SHARED_PROJECT_ID);
    }
    // if you change accounts, hoist to the selected project
    if (prevAccountId !== accountId && !this.state.shouldHoistSelectedProject) {
      this.setShouldHoistSelectedProject();
    }
    if (
      this.state.shouldHoistSelectedProject &&
      sharedProjectsFetched &&
      selectedProjectIndex > 0
    ) {
      this.hoistSelectedProject(selectedProjectIndex);
    }
  }

  /**
   * Opens a specific team tab.
   * @param  {string} tabId - Id for tab.
   */
  openTab = (tabId) => {
    this.props.toggleTeam(tabId, false);
  };

  /**
   * Closes a specific team tab.
   * @param  {string} tabId - Id for tab.
   */
  closeTab = (tabId) => {
    this.props.toggleTeam(tabId, true);
  };

  /**
   * Toggles a tab open or closed.
   * @param  {string} tabId  - Id for tab.
   * @param  {boolean} toggle - Whether to toggle open or close.
   */
  toggleTab = (tabId, toggle) => {
    if (toggle) this.openTab(tabId);
    else this.closeTab(tabId);
  };

  hoistSelectedProject = (selectedProjectIndex) => {
    if (this.listRef?.current) {
      this.listRef.current.scrollToItem(selectedProjectIndex, 'center');
    }
    this.setState({ shouldHoistSelectedProject: false });
  };

  setShouldHoistSelectedProject() {
    this.setState({ shouldHoistSelectedProject: true });
  }

  Row = ({ index, style }) => {
    const { listToggles, selectedProjectId, teamProjectList } = this.props;
    const { id, isLoadingProjects, isSelected, type } = teamProjectList[index];

    switch (type) {
      case 'TEAM':
        return (
          <ListTeamGroup
            className="ProjectList__Team"
            style={style}
            isArchivedProjectsTabOpen={
              listToggles[`archivedProjectsTab-${[id]}`]
            }
            archivedProjectIds={this.props.teamProjects[id].archivedProjectIds}
            isLoadingProjects={isLoadingProjects}
            isSelected={isSelected}
            isTabOpen={listToggles[id]}
            key={id}
            listToggles={listToggles}
            onTabClick={this.toggleTab}
            selectedProjectId={selectedProjectId}
            teamId={id}
          />
        );
      case 'PROJECT':
        return (
          <ListProjectRow
            key={id}
            style={style}
            isSelected={isSelected}
            projectId={id}
            enableTruncateWithTooltip={false}
          />
        );
      case 'ARCHIVE_HEADER':
        return (
          <ArchivedProjectsHeader
            className="archiveProjectsTab"
            key={id}
            style={style}
            teamId={id}
            onTabClick={this.toggleTab}
            isArchivedProjectsTabOpen={
              listToggles[`archivedProjectsTab-${[id]}`]
            }
          />
        );
      default:
        return <div />;
    }
  };

  /**
   * Renders React Element.
   * @returns {ReactElement} React Element.
   */
  render() {
    const { isDisabled, teamProjectListLength } = this.props;

    return (
      <FixedWidthContainer isDisabled={isDisabled}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="customScrollBar"
              height={height}
              width={width}
              itemCount={teamProjectListLength}
              itemSize={40}
              ref={this.listRef}
              style={{ marginTop: 0 }}
            >
              {this.Row}
            </List>
          )}
        </AutoSizer>
      </FixedWidthContainer>
    );
  }
}

ProjectList.defaultProps = {
  isDisabled: false,
  teamIds: [],
  teamProjects: {},
  areSharedProjectsVisible: false,
  selectedProjectId: undefined,
  selectedTeamId: undefined,
  onTeamButtonClick: () => {},
};

ProjectList.propTypes = {
  /**
   * Disable the ability to interact with the project list. (for example if a user has an unpaid account.)
   */
  isDisabled: PropTypes.bool.isRequired,
  /**
   * List of teams with nested projects.
   */
  teamIds: PropTypes.array,
  /**
   * List of a user's shared projects.
   */
  teamProjects: PropTypes.object,
  /**
   * Object with teams ids each containing an object with list of projects,
   *  and list of archived projects.
   */
  areSharedProjectsVisible: PropTypes.bool,
  /**
   * Unique API ID for selected project.
   */
  selectedProjectId: PropTypes.string,
  /**
   * Unique API ID for selected team.
   */
  selectedTeamId: PropTypes.string,
};

export const testExports = {
  ListTeamGroup,
  FixedWidthContainer,
};
