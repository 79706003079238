import { useFormikContext } from 'formik';
import { pickBy, keys, last } from 'lodash';

export default function useRemoveWatermarkBlock() {
  const {
    setValues,
    values,
    values: { activeBlockId, watermark_blocks: watermarkBlocks },
  } = useFormikContext();

  return () => {
    const isNotActiveBlockId = (_block, blockId) => blockId !== activeBlockId;
    const filteredBlocks = pickBy(watermarkBlocks, isNotActiveBlockId);

    const blockCount = Object.keys(watermarkBlocks).length;
    if (blockCount > 1) {
      setValues({
        ...values,
        activeBlockId: last(keys(filteredBlocks)),
        watermark_blocks: filteredBlocks,
      });
    }
  };
}
