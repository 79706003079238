import { combineReducers } from 'redux';
import { generatePaginatedListReducer } from '@frameio/core/src/shared/reducers/factories';
import { isPathNameSearchPage } from 'URLs';
import { VIEW_TYPES } from 'components/InfiniteGrid';
import { LOCATION_CHANGE } from 'connected-react-router';
import SORT_OPTIONS from './sortOptions';
import SEARCH_CONTAINER from './actions';

const results = generatePaginatedListReducer(SEARCH_CONTAINER.SEARCH);

function sortOptions(
  state = {
    sortBy: SORT_OPTIONS.RELEVANCE.value,
    isDescending: false,
  },
  action
) {
  switch (action.type) {
    case SEARCH_CONTAINER.SET_SORT: {
      const {
        option: { value: sortBy },
        isDescending,
      } = action.payload;
      return {
        ...state,
        sortBy,
        isDescending,
      };
    }

    case LOCATION_CHANGE: {
      // When the user navigates away from the search page we want to reset the sort option.
      // However the app fires a Location Change event when the user is typing to refine a search.
      // We need to check if they are (still) on the search page before deciding whether or not to reset.
      const isUserOnSearchPage = isPathNameSearchPage(
        action.payload.location.pathname
      );

      if (isUserOnSearchPage) {
        return state;
      }

      return {
        ...state,
        sortBy: SORT_OPTIONS.RELEVANCE.value,
      };
    }

    default:
      return state;
  }
}

function viewType(state = VIEW_TYPES.GRID, { type, payload }) {
  return type === SEARCH_CONTAINER.SET_VIEW_TYPE ? payload.viewType : state;
}

function selectedIds(state = [], { type, payload }) {
  return type === SEARCH_CONTAINER.SET_SELECTION ? payload.assetIds : state;
}

export default combineReducers({
  results,
  sortOptions,
  viewType,
  selectedIds,
});
