import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import track, { trackButtonClick } from 'analytics';
import Dialog, {
  dialogTypes,
} from '@frameio/components/src/styled-components/Dialog';
import Button from '@frameio/components/src/styled-components/Button';
import CheckmarkIcon from '@frameio/components/src/svgs/icons/16/checkmark.svg';
import CloseButton from '@frameio/components/src/components/Modal/CloseButton';
import { SETUP_ACCOUNT_FLOW } from '../SetupAccountFlow';

// analytics page name passed to segment
export const SETUP_ACCOUNT_SUCCESS_STEP = 'setup-account-success-step';

const StyledDialog = styled(Dialog)`
  height: ${(p) => p.theme.spacing.units(50)};
  padding: ${(p) => p.theme.spacing.medium};
`;

const Container = styled(Flex)`
  margin-top: ${(p) => p.theme.spacing.units(8)};
  margin-bottom: ${(p) => p.theme.spacing.medium};
`;

const CheckmarkIconContainer = styled(Flex)`
  width: 38px;
  height: 38px;
  background-color: #e0f5dc;
  border-radius: ${(p) => p.theme.radius.circle};
`;

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  path {
    stroke: ${(p) => p.theme.color.success};
  }
`;

const Header = styled.span`
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: ${(p) => p.theme.fontSize[3]};
  color: ${(p) => p.theme.color.coolBlack};
  margin-bottom: ${(p) => p.theme.spacing.units(2)};
`;

const Copy = styled.p`
  color: ${(p) => p.theme.color.gray};
  text-align: center;
  padding: 0 10px;
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: ${(p) => p.theme.spacing.tiny};
  right: ${(p) => p.theme.spacing.tiny};
`;

export default function Success({ createProject, createDemoProject }) {
  return (
    <StyledDialog
      type={dialogTypes.NONE}
      primaryButton={
        <Button
          primary
          onClick={() => {
            trackButtonClick(
              'start project',
              SETUP_ACCOUNT_FLOW,
              SETUP_ACCOUNT_SUCCESS_STEP
            );
            createProject();
          }}
        >
          Start my first project
        </Button>
      }
      secondaryButton={
        <Button
          text
          onClick={() => {
            trackButtonClick(
              'maybe later',
              SETUP_ACCOUNT_FLOW,
              SETUP_ACCOUNT_SUCCESS_STEP
            );
            createDemoProject();
          }}
        >
          Maybe later
        </Button>
      }
    >
      <StyledCloseButton
        onClick={() => {
          trackButtonClick('close', SETUP_ACCOUNT_FLOW, 'middle');
          track('setup-account-flow-closed');
          createDemoProject();
        }}
      />
      <Container justifyCenter alignCenter>
        <CheckmarkIconContainer justifyCenter alignCenter>
          <StyledCheckmarkIcon />
        </CheckmarkIconContainer>
      </Container>
      <Header>Account setup complete</Header>
      <Copy>Your account is all set! Let’s start your first project.</Copy>
    </StyledDialog>
  );
}

Success.propTypes = {
  createProject: PropTypes.func.isRequired,
  createDemoProject: PropTypes.func.isRequired,
};
