import React from 'react';
import styled from 'styled-components';

import UploadAnimation from '@frameio/components/src/components/Animation/UploadAnimation';
import { antialiasedAvenirHeavy } from '@frameio/components/src/mixins';

import { Container, Panel } from './QuicklookFolder';

const CaptionPanel = styled(Panel)`
  ${antialiasedAvenirHeavy()}
  ${(p) => p.theme.fontStyle.caption};
  color: ${(p) => p.theme.color.slateGray};
  margin-top: ${(p) => p.theme.spacing.small};
  text-align: center;
  margin-bottom: 40px;
`;

const IconPanel = styled(Panel)`
  height: 225px;
  width: 100%;

  svg path:first-child {
    fill: none;
  }
`;

export default function QuicklookUploading() {
  return (
    <Container column full alignCenter>
      <IconPanel>
        <UploadAnimation />
      </IconPanel>
      <CaptionPanel>Uploading&hellip;</CaptionPanel>
    </Container>
  );
}
