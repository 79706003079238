import { createAction } from 'redux-actions';
import {
  generateActionTypes,
  generateFetchActionTypes,
} from 'shared/actions/helpers';

const module = 'PROJECT_DEVICES';
export const PROJECT_DEVICES = {
  ...generateActionTypes(module, [
    'CONFIRM_DELETE',
    'CONFIRM_DELETE_ALL',
    'RENAME',
    'UPDATE_SETTINGS',
    'TOGGLE_PAUSE',
    'TOGGLE_PAUSE_ALL',
    'AUTHORIZATION_CLEAR',
  ]),
  ...generateFetchActionTypes(module, ['DELETE', 'DELETE_ALL', 'GET']),
  ...generateFetchActionTypes(module, ['FETCH'], true),
};

export const PROJECT_DEVICE_CHANNELS = {
  ...generateActionTypes('PROJECT_DEVICE_CHANNELS', [
    'UPDATE',
    'SUBMIT',
    'TARGET_PROJECT_DEVICES',
  ]),
  ...generateFetchActionTypes('PROJECT_DEVICE_CHANNELS', ['FETCH'], true),
};

export const DEVICE_CHANNEL_INPUTS = {
  ...generateActionTypes('DEVICE_CHANNEL_INPUTS', ['UPDATE']),
};
/**
 * Will prompt the user to confirm before deleting the device(s).
 */
export const confirmDeleteProjectDevice = createAction(
  PROJECT_DEVICES.CONFIRM_DELETE,
  (id) => ({ id })
);

export const confirmDeleteAllProjectDevices = createAction(
  PROJECT_DEVICES.CONFIRM_DELETE_ALL,
  (projectId) => ({ projectId })
);

/**
 * Will remove the device(s) from the current project state by a reducer consumer.
 */
export const deleteProjectDevice = createAction(
  PROJECT_DEVICES.DELETE.BASE,
  (id) => ({ id })
);

export const deleteAllProjectDevices = createAction(
  PROJECT_DEVICES.DELETE_ALL.BASE,
  (projectId) => ({ projectId })
);

/**
 * Will put back the device(s) into the current project state in case of failure.
 */
export const failureDeleteProjectDevice = createAction(
  PROJECT_DEVICES.DELETE.FAILURE,
  (id) => ({ id })
);

export const failureDeleteAllProjectDevices = createAction(
  PROJECT_DEVICES.DELETE_ALL.FAILURE,
  (projectDeviceIds) => ({ projectDeviceIds })
);

export const fetchProjectDevices = createAction(
  PROJECT_DEVICES.FETCH.BASE,
  (projectId, page, force) => ({ projectId, page, force })
);

export const fetchProjectDeviceChannels = createAction(
  PROJECT_DEVICE_CHANNELS.FETCH.BASE,
  (projectDeviceId) => ({ projectDeviceId })
);

export const renameProjectDevice = createAction(
  PROJECT_DEVICES.RENAME,
  (id) => ({ id })
);

export const getProjectDevice = createAction(
  PROJECT_DEVICES.GET.BASE,
  (deviceId, projectId) => ({ deviceId, projectId })
);

export const resetProjectDevices = createAction(
  PROJECT_DEVICES.FETCH.RESET,
  (preserveMetadata) => ({ preserveMetadata })
);

/**
 * Pauses ALL project devices for a given project. Accepts a boolean operator as
 * the `isPausing` param. With true meaning the intention to pause, and false
 * meaning the intention to resume.
 */
export const togglePauseAllProjectDevices = createAction(
  PROJECT_DEVICES.TOGGLE_PAUSE_ALL,
  (projectId, isPausing) => ({ projectId, isPausing })
);

/**
 * Clears the existing authorization id stored.
 * Particularly important if a user begins to add multiple devices in one go.
 */
export const resetProjectDeviceAuthorization = createAction(
  PROJECT_DEVICES.AUTHORIZATION_CLEAR,
  (currentAuthorizationId) => ({ currentAuthorizationId })
);

/**
 * Accepts a boolean operator as the `isPausing` param. With true meaning the
 * intention to pause, and false meaning the intention to resume.
 */
export const togglePauseProjectDevice = createAction(
  PROJECT_DEVICES.TOGGLE_PAUSE,
  (id, isPausing) => ({ id, isPausing })
);

export const updateProjectDevice = createAction(
  PROJECT_DEVICES.UPDATE_SETTINGS,
  (id, newParams) => ({ id, newParams })
);

export const updateProjectDeviceChannel = createAction(
  PROJECT_DEVICE_CHANNELS.UPDATE,
  (projectDeviceId, projectDeviceChannelId, newParams) => ({
    projectDeviceId,
    projectDeviceChannelId,
    newParams,
  })
);

export const targetProjectDevices = createAction(
  PROJECT_DEVICE_CHANNELS.TARGET_PROJECT_DEVICES,
  (projectDeviceId, projectDeviceChannelId, projectDeviceIds) => ({
    projectDeviceId,
    projectDeviceChannelId,
    projectDeviceIds,
  })
);

export const updateDeviceChannelInput = createAction(
  DEVICE_CHANNEL_INPUTS.UPDATE,
  (projectDeviceId, deviceChannelInputId, newParams) => ({
    projectDeviceId,
    deviceChannelInputId,
    newParams,
  })
);

export const submitEditInputLogging = createAction(
  PROJECT_DEVICE_CHANNELS.SUBMIT,
  (projectDeviceId, originalChannels, updatedChannels) => ({
    projectDeviceId,
    originalChannels,
    updatedChannels,
  })
);
