import React from 'react';
import AssetThumb from 'components/AssetThumb';

import { shade } from 'polished';
import { withTheme } from 'styled-components';

const FileAssetThumb = withTheme(({ theme, ...rest }) => (
  <AssetThumb
    {...rest}
    backgroundColor={shade(0.85, theme.color.purpleBlack)}
  />
));

export default FileAssetThumb;
