import React, { useEffect } from 'react';
import track from 'analytics';
import Button from '@frameio/components/src/styled-components/Button';
import { useSelector } from 'react-redux';
import { currentAccountSelector } from 'selectors/accounts';
import AccountOwnerPill from 'components/AccountOwnerPill';
import Flex from 'styled-flex-component';
import DialogModal from 'components/Dialog/DialogModal';
import watermarkIllustration from './illustration.png';
import { StyledBox, Text } from './styles';

export default function UnlockDialog({ isOpen, onClose }) {
  const currentAccount = useSelector(currentAccountSelector);

  useEffect(() => {
    if (isOpen) {
      track('action-blocked', { _limit: 'watermark id' });
    }
  }, [isOpen]);

  return (
    <DialogModal
      data-test-id="unlock-sbw-dialog"
      isOpen={isOpen}
      onClose={onClose}
      canBackdropCancel
    >
      <StyledBox padding={[3]}>
        <StyledBox padding={[0, 5]}>
          <Text variant="headerS" textAlign="center" gutter={[0, 0, 3, 0]}>
            Unlock Watermark ID
          </Text>
          <StyledBox as={Flex} center>
            <img src={watermarkIllustration} alt="session based watermarking" />
          </StyledBox>
          <Text
            variant="bodyM"
            color="slateGray"
            textAlign="center"
            gutter={[3, 0, 0, 0]}
          >
            Please notify your account owner that you’d
            <br />
            like this upgrade.
          </Text>
          <Text
            as={Flex}
            variant="bodyM"
            color="slateGray"
            center
            gutter={[2, 0, 0, 0]}
          >
            Account owner
            <StyledBox gutter={[0, 0, 0, 1]}>
              <AccountOwnerPill
                data-test-id="account-owner-pill"
                accountId={currentAccount.id}
              />
            </StyledBox>
          </Text>
        </StyledBox>
        <StyledBox as={Flex} full justifyEnd gutter={[3, 0, 0, 0]}>
          <Button primary onClick={onClose}>
            Got it!
          </Button>
        </StyledBox>
      </StyledBox>
    </DialogModal>
  );
}
