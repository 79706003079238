import React from 'react';
import { get } from 'lodash';
import {
  call,
  cancel,
  fork,
  put,
  select,
  spawn,
  take,
  takeLatest,
  all,
  race,
} from 'redux-saga/effects';
import track from 'analytics';
import { putFetchActionAndWait } from '@frameio/core/src/shared/sagas/helpers';
import { getLineItemsForPlan } from '@frameio/core/src/planLineItems/actions';
import { getSubscriptionLineItemsForAccount } from '@frameio/core/src/subscriptionLineItems/sagas';
import { updateSubscriptionPlan } from '@frameio/core/src/subscriptions/sagas';
import { planLineItemsForPlanSelector } from '@frameio/core/src/planLineItems/selectors';
import { getDefaultPlansForPlan } from '@frameio/core/src/plans/sagas';
import { defaultPlansForPlanSelector } from '@frameio/core/src/plans/selectors';
import { trackCancellationStarted } from 'sagas/accounts';
import {
  accountPlanIdSelector,
  currentAccountWithSubscriptionAndPlanSelector,
  isAccountOnLegacyPlanSelector,
  isAccountOnPlanWithUserMaxSelector,
} from 'selectors/accounts';
import { showErrorToast } from 'actions/toasts/index';
import { identifyAccountForEnterprisePlan } from 'actions/accounts';
import { MODAL, openModal, closeModal } from 'components/Modal/actions';
import { PAYMENT_FLOW } from 'components/PaymentFlow/actions';
import { CANCEL_ACCOUNT_FLOW } from 'components/CancelAccountFlow/actions';
import { isFreePlanWithUserMaxSelectedSelector } from 'components/SelectPlanFlow/selectors';
import config from 'config';
import { PROJECT_CONTAINER } from 'pages/ProjectContainer/actions';
import loadImage from 'components/withImageLoad/loadImage';
import { openDrift } from 'utils/drift';
import CancelAccountFlow from 'components/CancelAccountFlow';
import { v8PricingOnlyAndMigrationFlowsUpdatesEnabled } from 'utils/featureFlags';
import {
  isFetching,
  reportPlanChangeSuccess,
  SELECT_PLAN_FLOW,
} from './actions';
import history from './history';
import {
  SELECT_PLAN_URL,
  PLAN_SUMMARY_URL,
  PAYMENT_FLOW_URL,
  PLAN_CONFIRMATION_URL,
  MAX_HEIGHT,
} from './SelectPlanFlow';
import { enterpriseBackgroundUrl } from './utils';
import SelectPlanFlow from '.';

function* handleFail() {
  yield put(
    showErrorToast({
      header: 'An error occured',
    })
  );
}

function* onSelectPlan(selectedPlan) {
  if (!selectedPlan) return;
  const { id: planId } = selectedPlan;

  yield put(isFetching(true));
  const planLineItems = yield select(planLineItemsForPlanSelector, { planId });

  if (!planLineItems.length) {
    const action = getLineItemsForPlan(planId);
    const { failure } = yield call(putFetchActionAndWait, action, planId);
    if (failure) {
      yield call(handleFail);
      return;
    }
  }

  yield put(isFetching(false));
}

function* confirmPlanChange(accountId, newPlan) {
  yield put(isFetching(true));
  return yield call(updateSubscriptionPlan, accountId, newPlan.id);
}

function* startDriftFlow(playbookId) {
  yield call(trackCancellationStarted);
  yield call(openDrift, playbookId);
  yield put(closeModal());
}

function* watchManagePlanFlow(accountId, trackingProps) {
  const areV8PricingOnlyAndMigrationFlowsUpdatesEnabled = yield select(
    v8PricingOnlyAndMigrationFlowsUpdatesEnabled
  );
  const isLegacy = areV8PricingOnlyAndMigrationFlowsUpdatesEnabled
    ? false
    : yield select(isAccountOnLegacyPlanSelector, { accountId });
  // Step 1: Select plan
  yield call(history.push, SELECT_PLAN_URL);

  // Step 2: Plan selected - get change summary
  const {
    payload: { selectedPlan },
  } = yield take(SELECT_PLAN_FLOW.CONTINUE);

  const isFreePlanWithUserMaxSelected = yield select(
    isFreePlanWithUserMaxSelectedSelector
  );
  // When the plan is NOT legacy, we need to get all the plan line items for the plan before
  // redirecting to `PLAN_SUMMARY_URL`.
  // Legacy plans do NOT have any plan line items so we can bypass getting the plan line items and
  // instead redirect to `PLAN_SUMMARY_URL`.
  if (!isLegacy) {
    if (isFreePlanWithUserMaxSelected) {
      const source = 'Choose free plan';
      yield put(
        openModal(
          (onClose) => <CancelAccountFlow source={source} onClose={onClose} />,
          {
            canCloseModal: true,
            onClose: () => {
              track('cancellation-modal-closed', {
                cancellation_source: source,
              });
            },
          }
        )
      );
      yield spawn(track, 'cancellation-started');
      return;
    }

    yield call(onSelectPlan, selectedPlan);
  }
  yield call(history.push, PLAN_SUMMARY_URL);

  // Step 3: Start payment flow
  yield take(SELECT_PLAN_FLOW.CONTINUE);

  track('plan-selection-modal-submitted', trackingProps);
  yield call(history.push, PAYMENT_FLOW_URL, {
    onPaymentMethodReady: () => confirmPlanChange(accountId, selectedPlan),
  });
  yield take(PAYMENT_FLOW.REPORT_PAYMENT_SUCCESS);
  yield put(isFetching(false));
  yield put(reportPlanChangeSuccess());

  // Get updated subscription and plan line items after plan changed
  yield call(getSubscriptionLineItemsForAccount, accountId);
  yield call(
    putFetchActionAndWait,
    getLineItemsForPlan(selectedPlan.id),
    selectedPlan.id
  );

  yield call(history.push, PLAN_CONFIRMATION_URL, {
    newPlanTitle: selectedPlan.title,
    newPlanPeriod: selectedPlan.period,
    isFreePlanWithUserMaxSelected,
  });
}

function* startManagePlanFlow(accountId, trackingProps) {
  const areV8PricingOnlyAndMigrationFlowsUpdatesEnabled = yield select(
    v8PricingOnlyAndMigrationFlowsUpdatesEnabled
  );
  // Setup: get default plans and current subscription line items
  const id = yield select(accountPlanIdSelector, { accountId });
  const isLegacy = areV8PricingOnlyAndMigrationFlowsUpdatesEnabled
    ? false
    : yield select(isAccountOnLegacyPlanSelector, { accountId });

  // Only fetch the subscription and plan line items for v2 plans and above.
  if (!isLegacy) {
    const [lineItemsForAccountResult, defaultPlansForPlanResult] = yield all([
      call(getSubscriptionLineItemsForAccount, accountId),
      call(
        getDefaultPlansForPlan,
        id,
        areV8PricingOnlyAndMigrationFlowsUpdatesEnabled ? accountId : undefined
      ),
    ]);

    const lineItemsForAccountFailure = get(
      lineItemsForAccountResult,
      'failure.payload.error',
      false
    );
    const defaultPlansForPlanFailure = get(
      defaultPlansForPlanResult,
      'failure.payload.error',
      false
    );

    if (lineItemsForAccountFailure || defaultPlansForPlanFailure) {
      yield put(closeModal());
      yield call(handleFail);
      // Reset the flow so we don't try to go to a later stage when the component remounts
      yield call(history.push, '');
      return;
    }

    const plans = yield select(defaultPlansForPlanSelector);

    yield all(
      plans.map((plan) =>
        call(putFetchActionAndWait, getLineItemsForPlan(plan.id), plan.id)
      )
    );
  }

  const task = yield fork(watchManagePlanFlow, accountId, trackingProps);

  // wait for the user cancel action and then close modal,
  // cancel the background task, and update history
  yield take([SELECT_PLAN_FLOW.END, MODAL.CLOSE]);
  yield cancel(task);
  // Reset the flow so we don't try to go to a later stage when the component remounts
  yield call(history.push, '');
}

function* onChatWithOurTeamClick() {
  const { plan } = yield select(currentAccountWithSubscriptionAndPlanSelector);
  const { enterprise: isEnterprise } = plan;
  const playbookId = isEnterprise
    ? config.driftEnterpriseExpansionId
    : config.driftEnterpriseUpgradeId;

  yield put(identifyAccountForEnterprisePlan());
  yield spawn(track, 'sales-chat-opened-client');
  yield call(openDrift, playbookId);
}

function* openSelectPlanFlowModal(accountId, trackingProps) {
  yield put(
    openModal(
      <SelectPlanFlow accountId={accountId} trackingProps={trackingProps} />,
      {
        canBackdropClose: false,
        maxHeight: MAX_HEIGHT,
      }
    )
  );

  track('plan-selection-modal-shown', trackingProps);
}

function* preloadEnterpriseBackground() {
  yield call(loadImage, enterpriseBackgroundUrl);
}

/*
 * Determines whether a user successfully upgrades or not after the SelectPlanFlow
 * @returns {Boolean} Whether or not the plan was successfully updated
 */
export function* didUpgradePlan() {
  // This is a workaround to make sure the presentation editor only opens after
  // the new CancelAccount modal is closed, which is
  // triggered if a v5 or v6 user selects a free plan from the SelectPlan modal.
  // Selecting the free plan closes the SelectPlan modal and triggers the
  // SELECT_PLAN_FLOW.END action, which is also triggered when the user closes
  // the SelectPlan modal on their own. Since selecting the free plan will
  // trigger SELECT_PLAN_FLOW.CONTINUE before SELECT_PLAN_FLOW.END, we can catch
  // this case here.
  const { continueAction, cancelled } = yield race({
    cancelled: take(SELECT_PLAN_FLOW.END),
    continueAction: take(SELECT_PLAN_FLOW.CONTINUE),
  });

  // If cancelled won the race, we know that a free plan wasn't selected and
  // the user exited the flow on their own.
  if (cancelled) {
    return false;
  }

  const isV5orV6PricingPlan = yield select(isAccountOnPlanWithUserMaxSelector);
  const freePlanSelected = continueAction?.payload.selectedPlan.tier === 'free';

  // If continueAction won the race above and the v5/v6 pricing plan user DID
  // select a free plan, we wait for the CancelAccountFlow to end to reopen the editor.
  if (isV5orV6PricingPlan && freePlanSelected) {
    yield take(CANCEL_ACCOUNT_FLOW.END);
    return false;
  }

  // If continueAction won and the user was not on a v5/v6 plan and didn't
  // select the free plan, we wait for the user to successfully upgrade or close
  // the modal on their own.
  const { success, secondCancelled } = yield race({
    success: take(SELECT_PLAN_FLOW.REPORT_PLAN_CHANGE_SUCCESS),
    secondCancelled: take(SELECT_PLAN_FLOW.END),
  });

  if (secondCancelled) {
    return false;
  }

  return !!success;
}

export const testExports = {
  confirmPlanChange,
  handleFail,
  onChatWithOurTeamClick,
  onSelectPlan,
  openSelectPlanFlowModal,
  startManagePlanFlow,
  watchManagePlanFlow,
  preloadEnterpriseBackground,
  startDriftFlow,
  didUpgradePlan,
};

export default [
  takeLatest(
    SELECT_PLAN_FLOW.START,
    ({ payload: { accountId, trackingProps } }) =>
      startManagePlanFlow(accountId, trackingProps)
  ),
  takeLatest(SELECT_PLAN_FLOW.CHAT_WITH_OUR_TEAM, onChatWithOurTeamClick),
  takeLatest(
    SELECT_PLAN_FLOW.OPEN_MODAL,
    ({ payload: { accountId, trackingProps, recommendedTier } }) =>
      openSelectPlanFlowModal(accountId, trackingProps, recommendedTier)
  ),
  takeLatest(PROJECT_CONTAINER.ASSETS_LOADED, preloadEnterpriseBackground),
];
