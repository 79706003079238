import React from 'react';
import { MenuButton, Menu } from '@frameio/vapor';
import SettingsIcon from '@frameio/components/src/svgs/icons/settings.svg';
import { Button } from '../PlayerBarButton';
import { PlaybackPicker } from '../PlaybackPicker';
import ResolutionPickerButton from '../ResolutionPickerButton';

const TEXT = {
  resolution: 'Quality: ',
};

const ResolutionInnerContent = ({
  bind: { onClick, ref, ...bind },
  selectedResolution,
  selectedAutoResolution,
  selectedAssetIcon,
}) => (
  <MenuButton {...bind} onSelect={onClick} shouldCloseMenuOnSelect={false}>
    <div ref={ref}>
      {TEXT.resolution}
      {selectedResolution}
      {selectedAutoResolution && `\u00A0(${selectedAutoResolution}p)`}
      {selectedAssetIcon}
    </div>
  </MenuButton>
);

const ResolutionPicker = ({
  canUse4KPlayback,
  resolutionPicker,
  selectedAutoResolution,
  setIsPopoverShowing,
}) => (
  <ResolutionPickerButton
    assetResolutions={resolutionPicker.assetResolutions}
    canUse4KPlayback={canUse4KPlayback}
    isHlsPlayerEnabled={resolutionPicker.isHlsPlayerEnabled}
    selectedAutoResolution={selectedAutoResolution}
    selectedResolution={resolutionPicker.selectedResolution}
    selectResolution={resolutionPicker.selectResolution}
    setIsPopoverShowing={setIsPopoverShowing}
  >
    {ResolutionInnerContent}
  </ResolutionPickerButton>
);

/** A mobile-only menu to display the playback speed and resolution controls. */
export const MobilePlaybackResolution = ({
  canUse4KPlayback,
  customPlayback,
  resolutionPicker,
  selectedAutoResolution,
  setIsPopoverShowing,
}) => (
  <Menu
    content={
      <>
        <PlaybackPicker
          customPlayback={customPlayback}
          setIsPopoverShowing={setIsPopoverShowing}
        />

        <ResolutionPicker
          canUse4KPlayback={canUse4KPlayback}
          resolutionPicker={resolutionPicker}
          selectedAutoResolution={selectedAutoResolution}
          setIsPopoverShowing={setIsPopoverShowing}
        />
      </>
    }
    placement="top"
    shouldUsePortal
  >
    <Button dark icon="true" text>
      <SettingsIcon width={18} height={18} />
    </Button>
  </Menu>
);
