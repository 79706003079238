import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

const module = 'TEAM_CONTAINER';
const TEAM_CONTAINER = generateActionTypes(module, ['ENTER_TEAM']);

export default TEAM_CONTAINER;

export const enterTeam = createAction(TEAM_CONTAINER.ENTER_TEAM, (teamId) => ({
  teamId,
}));
