import { planEntityForAccountIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { seatLimitPropertyName } from 'selectors/accounts';

export const isAutoScalingPlanSelector = (state, { accountId }) => {
  if (!accountId) return false;
  const plan = planEntityForAccountIdSelector(state, { accountId });
  return !!plan.autoscaling;
};

export const isUnlimitedSeatsPlanSelector = (state, { accountId }) => {
  const plan = planEntityForAccountIdSelector(state, { accountId });
  const seatLimitType = seatLimitPropertyName(state);

  return plan[seatLimitType] === null;
};
