export class JTBoxConstraint {

  constructor(data_func, radius, pad) {

    this.update = this.update.bind(this);
    this.point = this.point.bind(this);
    this.corner_start = this.corner_start.bind(this);
    this.corner_end = this.corner_end.bind(this);
    this.corner = this.corner.bind(this);
    this.data_func = data_func;
    if (radius == null) { radius = 6; }
    if (pad == null) { pad = 0; }
    this.data = this.data_func();

    this.radius = radius;

    this.pad = pad;
  }

  update() {
    return this.data = this.data_func();
  }

  point(point_id) {

    this.data = this.data_func();

    switch (point_id) {
      case 'tl':
      case 'top-left':
        return { x: this.data.x + this.pad, y: this.data.y + this.pad };

      case 'tr':
      case 'top-right':
        return { x: (this.data.x + this.data.w) - this.pad, y: this.data.y + this.pad };

      case 'br':
      case 'bottom-right':
        return { x: (this.data.x + this.data.w) - this.pad, y: (this.data.y + this.data.h) - this.pad };

      case 'bl':
      case 'bottom-left':
        return { x: this.data.x + this.pad, y: (this.data.y + this.data.h) - this.pad };
    }
  }

  corner_start(corner_id, radius) {

    if (radius == null) { radius = null; }
    if (!radius) {
      ({ radius } = this); }

    let point = this.point(corner_id);
    let handle_length = radius / 2.0;

    switch (corner_id) {
      case 'tl':
      case 'top-left':
        return {
          x: point.x,
          y: point.y + radius
        };

      case 'tr':
      case 'top-right':
        return {
          x: point.x - radius,
          y: point.y
        };

      case 'br':
      case 'bottom-right':
        return {
          x: point.x,
          y: point.y - radius
        };

      case 'bl':
      case 'bottom-left':
        return {
          x: point.x + radius,
          y: point.y
        };
    }
  }

  corner_end(corner_id, radius) {

    if (!radius) {
      ({ radius } = this); }

    let point = this.point(corner_id);
    let handle_length = radius / 2.0;

    switch (corner_id) {
      case 'tl':
      case 'top-left':
        return {
          x: point.x + radius,
          y: point.y
        };

      case 'tr':
      case 'top-right':
        return {
          x: point.x,
          y: point.y + radius
        };

      case 'br':
      case 'bottom-right':
        return {
          x: point.x - radius,
          y: point.y
        };

      case 'bl':
      case 'bottom-left':
        return {
          x: point.x,
          y: point.y - radius
        };
    }
  }

  corner(corner_id, radius) {

    if (!radius) {
      ({ radius } = this); }

    let point = this.point(corner_id);
    let handle_length = radius / 2.0;

    switch (corner_id) {
      case 'tl':
      case 'top-left':
        return {
          x0: point.x,
          y0: point.y + radius,
          x1: point.x,
          y1: point.y + handle_length,
          x2: point.x + handle_length,
          y2: point.y,
          x3: point.x + radius,
          y3: point.y
        };

      case 'tr':
      case 'top-right':
        return {
          x0: point.x - radius,
          y0: point.y,
          x1: point.x - handle_length,
          y1: point.y,
          x2: point.x,
          y2: point.y + handle_length,
          x3: point.x,
          y3: point.y + radius
        };

      case 'br':
      case 'bottom-right':
        return {
          x0: point.x,
          y0: point.y - radius,
          x1: point.x,
          y1: point.y - handle_length,
          x2: point.x - handle_length,
          y2: point.y,
          x3: point.x - radius,
          y3: point.y
        };

      case 'bl':
      case 'bottom-left':
        return {
          x0: point.x + radius,
          y0: point.y,
          x1: point.x + handle_length,
          y1: point.y,
          x2: point.x,
          y2: point.y - handle_length,
          x3: point.x,
          y3: point.y - radius
        };
    }
  }
}

export class JTPointerConstraint {

  constructor(direction, data_func, radius, pad) {

    this.origin = this.origin.bind(this);
    this.start = this.start.bind(this);
    this.end = this.end.bind(this);
    this.left_edge = this.left_edge.bind(this);
    this.right_edge = this.right_edge.bind(this);
    this.top_edge = this.top_edge.bind(this);
    this.bottom_edge = this.bottom_edge.bind(this);
    if (direction == null) { direction = 'top'; }
    this.data_func = data_func;
    if (radius == null) { radius = 8; }
    if (pad == null) { pad = 0; }
    this.direction = direction;
    this.box = new JTBoxConstraint(this.data_func);
    this.radius = radius;
    this.pad = pad;
  }

  origin() {

    this.box.update();

    switch (this.direction) {
      case 'top':
        return { x: this.box.data.x + (this.box.data.w / 2.0), y: this.box.data.y };

      case 'bottom':
        return { x: this.box.data.x + (this.box.data.w / 2.0), y: this.box.data.y + this.box.data.h };

      case 'left':
        return { x: this.box.data.x, y: this.box.data.y + (this.box.data.h / 2.0) };

      case 'right':
        return { x: this.box.data.x + this.box.data.w, y: this.box.data.y + (this.box.data.h / 2.0) };
    }
  }

  start() {

    switch (this.direction) {
      case 'top':
        return this.box.point('bottom-left');

      case 'bottom':
        return this.box.point('top-right');

      case 'left':
        return this.box.point('bottom-right');

      case 'right':
        return this.box.point('top-left');
    }
  }

  end() {

    switch (this.direction) {
      case 'top':
        return this.box.point('bottom-right');

      case 'bottom':
        return this.box.point('top-left');

      case 'left':
        return this.box.point('top-right');

      case 'right':
        return this.box.point('bottom-left');
    }
  }

  left_edge() {

    let handle_length = this.radius / 2.0;
    let origin;

    switch (this.direction) {

      case 'top':
        {

          let start_point = this.box.point('bottom-left');
          origin = this.origin();
          return {
            x0: start_point.x,
            y0: start_point.y,
            x1: start_point.x + (handle_length * 2),
            y1: start_point.y,
            x2: origin.x - handle_length,
            y2: origin.y,
            x3: origin.x,
            y3: origin.y
          };
        }

      case 'bottom':
        {

          let end_point = this.box.point('top-left');
          origin = this.origin();
          return {
            x0: origin.x,
            y0: origin.y,
            x1: origin.x - handle_length,
            y1: origin.y,
            x2: end_point.x + (handle_length * 2),
            y2: end_point.y,
            x3: end_point.x,
            y3: end_point.y
          };
        }
    }
  }

  right_edge() {

    let handle_length = this.radius / 2.0;
    let origin;

    switch (this.direction) {

      case 'top':
        {

          let end_point = this.box.point('bottom-right');
          origin = this.origin();
          return {
            x0: origin.x,
            y0: origin.y,
            x1: origin.x + handle_length,
            y1: origin.y,
            x2: end_point.x - (handle_length * 2),
            y2: end_point.y,
            x3: end_point.x,
            y3: end_point.y
          };
        }

      case 'bottom':
        {
          let start_point = this.box.point('top-right');
          origin = this.origin();
          return {
            x0: start_point.x,
            y0: start_point.y,
            x1: start_point.x - (handle_length * 2),
            y1: start_point.y,
            x2: origin.x + handle_length,
            y2: origin.y,
            x3: origin.x,
            y3: origin.y
          };
        }
    }
  }

  top_edge() {

    let handle_length = this.radius / 2.0;
    let origin;

    switch (this.direction) {

      case 'left':
        {

          let end_point = this.box.point('top-right');
          origin = this.origin();
          return {
            x0: origin.x,
            y0: origin.y,
            x1: origin.x,
            y1: origin.y - handle_length,
            x2: end_point.x,
            y2: end_point.y + (handle_length * 2),
            x3: end_point.x,
            y3: end_point.y
          };
        }

      case 'right':
        {
          let start_point = this.box.point('top-left');
          origin = this.origin();
          return {
            x0: start_point.x,
            y0: start_point.y,
            x1: start_point.x,
            y1: start_point.y + (handle_length * 2),
            x2: origin.x,
            y2: origin.y - handle_length,
            x3: origin.x,
            y3: origin.y
          };
        }
    }
  }

  bottom_edge() {

    let handle_length = this.radius / 2.0;
    let origin;

    switch (this.direction) {

      case 'left':
        {

          let start_point = this.box.point('bottom-right');
          origin = this.origin();
          return {
            x0: start_point.x,
            y0: start_point.y,
            x1: start_point.x,
            y1: start_point.y - (handle_length * 2),
            x2: origin.x,
            y2: origin.y + handle_length,
            x3: origin.x,
            y3: origin.y
          };
        }

      case 'right':
        {

          let end_point = this.box.point('bottom-left');
          origin = this.origin();
          return {
            x0: origin.x,
            y0: origin.y,
            x1: origin.x,
            y1: origin.y + handle_length,
            x2: end_point.x,
            y2: end_point.y - (handle_length * 2),
            x3: end_point.x,
            y3: end_point.y
          };
        }
    }
  }
}
