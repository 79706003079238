/* global process */
import Raven from 'raven-js';
import config, { isLocal, isProduction, isStaging, isEvaluation } from 'config';

export default function configureRaven() {
  // Monkey-patch Raven to log exceptions to console so that these errors don't
  // silently disappear in local environments.
  if (isLocal) {
    Raven.Client.prototype.captureException = (...args) => {
      // eslint-disable-next-line no-console
      console.error('Raven.captureException', ...args);
    };
    return;
  }

  let environment = 'development';
  if (isEvaluation) environment = 'evaluation';
  if (isStaging) environment = 'staging';
  if (isProduction) environment = 'production';

  Raven.config('https://0704cdf2f00d419a8f02030e59f49378@sentry.io/142547', {
    environment,

    ignoreUrls: [
      'appcues.com',
      'cdn.segment.com',
      'mbsy.co',
      /js\.intercomcdn\.com/,
      /\/frame\.[0-9a-f]{8}\.js/,
      /js\.driftt\.com/,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    ignoreErrors: [
      /NS_ERROR_[A-Z]+/,

      // Ignore Stripe error:
      // https://sentry.io/frameio/web-client/issues/556873449/
      'InvalidAccessError: The object does not support the operation or argument.',

      // This is benign according to
      // https://stackoverflow.com/a/50387233/457519
      'ResizeObserver loop limit exceeded',
    ],
    sampleRate: 0.5,

    release: `web-client@${process.env.RELEASE_VERSION}`,

    tags: {
      build: process.env.BUILD_NUMBER,
      // Enable correlating different event logs to a single session
      instance_id: config.instanceId,
    },
  }).install();
}
