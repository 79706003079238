import styled from 'styled-components';
import { Button } from 'components/PlayerControlBar/PlayerBarButton';
import { easing, color } from '@frameio/components/src/theme/darkTheme';

const trackHeightPx = 4;
const sliderThumbHeightPx = 10;
const maxWidthPx = 64;
const timingFunction = easing.easeInOutQuint;
const duration = 0.2;
const durationDelayed = duration + 0.1;
const defaultActiveTransformTransition = `${durationDelayed}s transform ${timingFunction}`;

const SliderTrack = styled.div`
  width: ${maxWidthPx}px;
  height: ${trackHeightPx}px;
  border-radius: ${trackHeightPx / 2}px;
`;

export const SliderTrackWrapper = styled(SliderTrack)`
  overflow: hidden;
  position: relative;

  // This is Safari fix so square edges do not show when transforming in and out
  // ref: https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
  // Add on element with overflow
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`;

export const InactiveSliderTrack = styled(SliderTrack)`
  background-color: ${color.dimGray};
  transform: ${(p) =>
    p.isSliderOpen ? `translateX(0)` : `translateX(-${maxWidthPx}px)`};
  transition: ${duration}s transform ${timingFunction};
  position: absolute;
`;

export const ActiveSliderTrack = styled(SliderTrack)`
  opacity: ${(p) => (p.isSliderOpen ? 1 : 0)};
  transform: ${(p) =>
    p.isSliderOpen
      ? `translateX(${-maxWidthPx + p.currentValue * maxWidthPx}px)`
      : `translateX(-${maxWidthPx}px)`};
  will-change: opacity;
  transition: ${(p) => {
    if (p.isDragging) return 'none';

    if (p.isSliderOpen) {
      return defaultActiveTransformTransition;
    }

    return `${durationDelayed +
      0.3}s opacity ${timingFunction}, ${defaultActiveTransformTransition}`;
  }};
  background-color: ${color.slateGray};
  position: absolute;
`;

export const SliderThumb = styled.div`
  width: ${sliderThumbHeightPx}px;
  height: ${sliderThumbHeightPx}px;
  opacity: ${(p) => (p.isSliderOpen ? 1 : 0)};
  transform: ${(p) =>
    p.isSliderOpen
      ? `translateX(${p.currentValue * maxWidthPx -
          sliderThumbHeightPx / 2}px) scale(1)`
      : `translateX(-${sliderThumbHeightPx / 2}px) scale(.3)`};
  transition: ${(p) => {
    if (p.isDragging) return 'none';

    if (p.isSliderOpen) {
      return `${duration}s opacity ${timingFunction}, ${defaultActiveTransformTransition}`;
    }

    return `${durationDelayed}s opacity ${timingFunction}, ${defaultActiveTransformTransition}`;
  }};
  transition-origin: 0 50%;
  border-radius: 50%;
  background-color: ${color.coldWhite};
  position: absolute;
  z-index: 1;
`;

export const SliderContainer = styled.div`
  width: ${maxWidthPx}px;
  position: relative;
  display: flex;
  align-items: center;
  ${(p) => !p.isSliderOpen && `pointer-events: none;`}
`;

export const InputSlider = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;

  cursor: ${(p) => (p.isDragging ? 'grabbing' : 'pointer')};
  padding: 4px 0;

  width: 100%;
  background: transparent;
  height: 12px;
  position: absolute;
  z-index: 3;

  &:focus {
    outline: 0;
  }

  ::-moz-focus-outer {
    border: 0;
  }

  ::-webkit-slider-thumb {
    width: 0;
    height: 0;
    -webkit-appearance: none;
  }
  ::-moz-range-thumb {
    background: transparent;
    border: 0;
    width: 0;
    height: 0;
  }
`;

export const VolumeControlButton = styled(Button)`
  ${(p) => p.isSliderOpen && `color: ${color.white}`}
`;
