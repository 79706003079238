import { connect } from 'react-redux';
import { planEntityForAccountIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { closeModal } from 'components/Modal/actions';
import { isAccountAdminSelector } from 'selectors/roles';
import OutOfUploads from './OutOfUploads';
import { continueFlow } from '../actions';

function mapStateToProps(state, { accountId }) {
  const plan = planEntityForAccountIdSelector(state, { accountId });
  return {
    isAccountAdmin: isAccountAdminSelector(state, { accountId }),
    lifetimeFileLimit: plan.lifetime_file_limit,
  };
}

const mapDispatchToProps = {
  onCancel: closeModal,
  onContinue: continueFlow,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutOfUploads);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
