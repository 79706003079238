import React from 'react';
import styled from 'styled-components';
import { margin, padding } from 'polished';
import { map } from 'lodash';
import Button from '@frameio/components/src/styled-components/Button';
import Flex, { FlexItem } from 'styled-flex-component';
import Checkbox, {
  CheckCircle,
  CheckIcon as CheckboxCheckIcon,
} from '@frameio/components/src/styled-components/Checkbox';
import DropArrow from '@frameio/components/src/svgs/icons/caron.svg';

export const Text = styled.span`
  ${(p) => p.theme.fontStyle[p.variant || 'body']}
  ${(p) => margin(...map(p.gutter, p.theme.spacing.units))}
  ${(p) => padding(...map(p.padding, p.theme.spacing.units))}
  color: ${(p) => p.theme.color[p.color]};
  background-color: ${(p) => p.theme.color[p.backgroundColor]};
  border-radius: ${(p) => p.theme.radius[p.borderRadius]};
`;

// TODO: replace with Vapor checkbox
// import { Checkbox as VaporCheckbox__UNSTABLE } from 'vapor';
export const StyledCheckbox = styled(Checkbox)`
  ${(p) => margin(...map(p.gutter, p.theme.spacing.units))}

  ${CheckCircle} {
    position: relative;
    top: 5px;
    box-shadow: inset 0 0 0 1px ${(p) => p.theme.color.accentGrayMedium};
  }

  ${CheckboxCheckIcon} {
    height: ${(p) => p.theme.spacing.units(2)};
    padding: ${(p) => `calc(${p.theme.spacing.micro} / 2)`};
    width: ${(p) => p.theme.spacing.units(2)};
  }

  ${CheckCircle},
  ${CheckboxCheckIcon} {
    border-radius: 5px;
  }

  /* Unfortunately, the specificity operators below are a necessary evil
  for overwriting the background-color rule defined in the Checkbox component */
  &&& {
    ${CheckboxCheckIcon} {
      background-color: ${(p) =>
        p.checked && p.disabled && p.theme.color.accentGrayMedium};
    }
  }
`;

export const StyledIcon = styled.svg`
  ${(p) => padding(...map(p.padding, p.theme.spacing.units))}
  ${(p) => margin(...map(p.gutter, p.theme.spacing.units))}
  background-color: ${(p) => p.theme.color[p.background]};
  color: ${(p) => p.theme.color[p.color]};
  transform: rotate(${(p) => p.rotate || 0}deg);
`;

export const StyledFlex = styled(Flex)`
  ${(p) => padding(...map(p.padding, p.theme.spacing.units))}
  ${(p) => margin(...map(p.gutter, p.theme.spacing.units))}
  border: ${(p) =>
    p.borderColor && `1px solid ${p.theme.color[p.borderColor]}`};
  border-radius: ${(p) => p.borderColor && p.theme.radius.default};

  > ${FlexItem} + ${FlexItem} {
    margin-left: ${(p) => p.theme.spacing.units(p.gutterColumn || 0)};
  }
`;

export const StyledButton = styled(Button)`
  ${(p) => margin(...map(p.gutter, p.theme.spacing.units))};
  width: ${(p) => p.full && '100%'};
`;

const VaporSelectChildStyledButton = styled(StyledButton)`
  height: ${(p) => (p.height ? p.theme.spacing.units(p.height) : 'auto')};
  border: 1px solid ${(p) => p.theme.color.silver};
  box-shadow: none;
  color: ${(p) => p.theme.color.coolBlack};
  font-weight: ${(p) => p.theme.fontWeight.normal};

  /* We need to target the inner Flex component of the <Button /> in order
  to define an explicit 100% width so that we can align the text label and
  DownArrow on the far left and right of the button. Otherwise, the text and
  arrow will be aligned next to each other in the center of the button. */
  > ${Flex} {
    justify-content: space-between;
    width: 100%;
  }
`;

// In order to trigger the popover menu of the <Select /> component, we
// need to forward the button's ref and other props to root button
export const VaporSelectChildContent = React.forwardRef(
  ({ children, ...rest }, ref) => (
    <VaporSelectChildStyledButton full ref={ref} {...rest}>
      <Text variant="bodyM">{children}</Text>
      <StyledIcon as={DropArrow} />
    </VaporSelectChildStyledButton>
  )
);
