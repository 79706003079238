import React from 'react';
import lifecycle from 'page-lifecycle';
import { eventChannel } from 'redux-saga';
import { get, sumBy } from 'lodash';
import {
  all,
  call,
  cancel,
  fork,
  put,
  select,
  spawn,
  take,
  takeEvery,
  throttle,
} from 'redux-saga/effects';
import { type as assetType } from '@frameio/core/src/assets/helpers/constants';
import {
  accountEntityForAssetIdSelector,
  planEntityForAccountIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import {
  assetEntitySelector,
  assetEntityInclDeletedSelector,
} from '@frameio/core/src/assets/selectors';
import {
  UPLOAD,
  UploadEvents,
  resumeUpload,
  reportUploadProgress,
} from '@frameio/core/src/uploads/actions';
import { incompleteUploadsSelector } from '@frameio/core/src/uploads/selectors';
import { pushToProjectRoom } from '@frameio/core/src/sockets/actions';
import { patchAssets } from '@frameio/core/src/assets/actions';

import config from 'config';
import track from 'analytics';
import {
  hasFileCountToUploadSelector,
  shouldShowStorageHardBlock,
} from 'selectors/accounts';
import { openModal } from 'components/Modal/actions';
import StorageLimitReachedFlow from 'components/StorageLimitReachedFlow';
import LifetimeFileLimitReachedFlow from 'components/LifetimeFileLimitReachedFlow';
import { hasSpaceToAddAssets } from 'utils/planLimits';
import HardBlock from 'components/HardBlock';
import { limitTypes } from 'selectors/limits';
import triggerLimitBlockModal from './triggerUploadLimitBlock';

/**
 * Broadcast asset upload progress to the project socket room.
 *
 * @param {string} assetId - Asset id.
 * @param {Object} progress - Progress object.
 */
function* broadcastUploadProgressForAsset(assetId, progress) {
  const asset = yield select(assetEntitySelector, { assetId });

  // The asset can be deleted right before the progress callback is done.
  if (!asset) return;

  const { project_id: projectId } = asset;
  const { bytesSent, bytesTotal } = progress;
  yield put(
    pushToProjectRoom(projectId, UploadEvents.UploadProgressClient, {
      asset_id: assetId,
      sent_bytes: bytesSent,
      total_bytes: bytesTotal,
      version: '1.0.0',
    })
  );
}

const BROADCAST_COMPLETE = 'BROADCAST_COMPLETE';
function broadcastComplete(assetId) {
  return {
    type: BROADCAST_COMPLETE,
    payload: { assetId },
  };
}
function isBroadcastCompleteActionForAsset({ type, payload }, assetId) {
  return type === BROADCAST_COMPLETE && assetId === payload.assetId;
}

function isUploadProgressActionForAsset({ type, payload }, assetId) {
  return type === UPLOAD.REPORT_PROGRESS && assetId === payload.assetId;
}

/**
 * Combines the progress of a bundle's children file uploads
 * and reports the progress of the bundle.
 * @param {string} bundleId - The bundle's id
 * @param {string} assetId - The asset's id
 * @param {Object} progress - Must contain `bytesSent` and `bytesTotal`
 */
function* onBundleUploadProgress(bundleId, assetId, progress) {
  const bundle = yield select(assetEntitySelector, { assetId: bundleId });
  const assetsUploadProgress = {
    ...bundle.assetsUploadProgress,
    [assetId]: { ...progress },
  };
  yield put(patchAssets([{ id: bundle.id, assetsUploadProgress }]));

  const bundleProgress = Object.values(assetsUploadProgress).reduce(
    (acc, assetProgress) => ({
      bytesSent: acc.bytesSent + assetProgress.bytesSent,
      bytesTotal: acc.bytesTotal + assetProgress.bytesTotal,
      bytesPerSecond: acc.bytesPerSecond + assetProgress.bytesPerSecond,
      remainingTime: acc.remainingTime + assetProgress.remainingTime,
    }),
    { bytesSent: 0, bytesTotal: 0, bytesPerSecond: 0, remainingTime: 0 }
  );

  yield put(reportUploadProgress(bundle.id, bundleProgress));
  yield call(broadcastUploadProgressForAsset, bundleId, bundleProgress);
  if (bundleProgress.bytesSent === bundleProgress.bytesTotal) {
    yield put(broadcastComplete(bundleId));
  }
}

function* onAssetUploadProgress(assetId, progress) {
  const asset = yield select(assetEntitySelector, { assetId });
  const { bundleId } = asset;
  if (bundleId) {
    yield call(onBundleUploadProgress, bundleId, assetId, progress);
    return;
  }
  yield call(broadcastUploadProgressForAsset, assetId, progress);

  const { bytesSent, bytesTotal } = progress;
  if (bytesSent === bytesTotal) {
    yield put(broadcastComplete(assetId));
  }
}

function* watchUploadProgress(assetId) {
  yield throttle(
    config.uploadProgressBroadcastInterval,
    (action) => isUploadProgressActionForAsset(action, assetId),
    ({ payload: { progress } }) => onAssetUploadProgress(assetId, progress)
  );
}

const onBeforeUnload = (event) => {
  // This message doesn't actually show up on most browsers. See
  // https://developer.mozilla.org/en-US/docs/Web/Events/beforeunload
  const message = 'There are uploads in progress.';
  event.returnValue = message; // eslint-disable-line no-param-reassign
  return message;
};

function* preventUnloadIfNeeded() {
  const incompleteUploads = yield select(incompleteUploadsSelector);
  if (incompleteUploads.length) {
    // Bind once only
    yield call(window.removeEventListener, 'beforeunload', onBeforeUnload);
    yield call(window.addEventListener, 'beforeunload', onBeforeUnload);
  }
}

function* unpreventUnloadIfNeeded() {
  const incompleteUploads = yield select(incompleteUploadsSelector);
  if (!incompleteUploads.length) {
    yield call(window.removeEventListener, 'beforeunload', onBeforeUnload);
  }
}

function onLifecycleStateChange({ oldState }, emit) {
  const isBecomingVisible = oldState === 'hidden';

  // Note that the action type doesn't actually matter here, cos the `take`
  // below accepts anything
  if (isBecomingVisible) emit({ type: 'UNHIDING' });
}

/**
 * When the computer wakes up from sleep, resume any pending uploads that were
 * not manually paused.
 *
 * That's the intention anyway. However, since browsers don't actually surface a
 * sleep/wake event, the next closest thing is to attempt a resume when the page
 * transitions out of a "hidden" state, which can happen either when:
 *
 * 1. switching tabs out of frame.io, and switching back to it;
 * 2. putting the computer to sleep and waking it up, and activating the browser
 *    window.
 *
 * This means that a resume might be attemped even though the upload is actually
 * still going on, but that's fine as long as the uploader instance simply
 * ignores the attempt.
 *
 * Either way, the upload will only resume when the browser tab/window regains
 * focus.
 */
function* resumeUploadsOnWake() {
  // Listen to lifecycle change events
  const stateChangeChannel = yield call(eventChannel, (emit) => {
    const listener = (event) => onLifecycleStateChange(event, emit);
    lifecycle.addEventListener('statechange', listener);

    // This doesn't actually do anything useful because this listener will
    // persist throughout the lifetime of the app
    return () => {
      lifecycle.removeEventListener(listener);
    };
  });

  // When it _might_ be a wake event, attempt to resume uploads.
  while (true) {
    yield take(stateChangeChannel);
    const incompleteUploads = yield select(incompleteUploadsSelector);

    // Resume only uploads that haven't been retried and not paused by the user
    yield all(
      incompleteUploads
        .filter(({ error, isPaused }) => !error && !isPaused)
        .map((upload) => put(resumeUpload(upload.id)))
    );
  }
}

export function* hasBlockedUpload(items, folderId) {
  const account = yield select(accountEntityForAssetIdSelector, {
    assetId: folderId,
  });
  const numOfItemsToUpload = items.filter(
    (item) => item.type === assetType.FILE
  ).length;
  const bytesToUpload = yield call(sumBy, items, (item) =>
    get(item, 'file.size', 0)
  );
  const { id: accountId } = account;
  const { enterprise: isEnterprise } = yield select(
    planEntityForAccountIdSelector,
    { accountId }
  );
  const doesAccountHaveSpaceToUpload = yield call(
    hasSpaceToAddAssets,
    accountId,
    bytesToUpload
  );

  const isAccountUnderFileCountLimit = yield select(
    hasFileCountToUploadSelector,
    { accountId, numOfItemsToUpload }
  );

  if (
    isEnterprise ||
    (doesAccountHaveSpaceToUpload && isAccountUnderFileCountLimit)
  ) {
    return false;
  }

  if (!isAccountUnderFileCountLimit) {
    yield put(
      openModal(<LifetimeFileLimitReachedFlow accountId={accountId} />)
    );
  } else if (!doesAccountHaveSpaceToUpload) {
    const shouldShowHardBlock = yield select(shouldShowStorageHardBlock);
    if (shouldShowHardBlock) {
      yield put(openModal(<HardBlock limitType={limitTypes.STORAGE} />));
      yield spawn(track, 'action-blocked', { _limit: limitTypes.STORAGE });
    } else {
      yield put(
        openModal(<StorageLimitReachedFlow accountId={accountId} />, {
          canCloseModal: false,
        })
      );
    }
  }

  return true;
}

export function* legacyHasBlockedUpload(items, folderId) {
  const numOfItemsToUpload = items.filter(
    (item) => item.type === assetType.FILE
  ).length;
  const bytesToUpload = yield call(sumBy, items, (item) =>
    get(item, 'file.size', 0)
  );
  const account = yield select(accountEntityForAssetIdSelector, {
    assetId: folderId,
  });
  const { id: accountId } = account;
  const { enterprise: isEnterprise } = yield select(
    planEntityForAccountIdSelector,
    { accountId }
  );
  const doesAccountHaveSpaceToUpload = yield call(
    hasSpaceToAddAssets,
    accountId,
    bytesToUpload
  );
  const isAccountUnderFileCountLimit = yield select(
    hasFileCountToUploadSelector,
    { accountId, numOfItemsToUpload }
  );

  if (
    isEnterprise ||
    (doesAccountHaveSpaceToUpload && isAccountUnderFileCountLimit)
  ) {
    return false;
  }

  yield call(
    triggerLimitBlockModal,
    accountId,
    bytesToUpload,
    numOfItemsToUpload,
    doesAccountHaveSpaceToUpload,
    isAccountUnderFileCountLimit
  );

  return true;
}

function* trackUpload(name, assetId, otherProps = {}, options = {}) {
  // The asset may have been optimistically deleted before all the track events have fired.
  const { filetype, filesize } = yield select(assetEntityInclDeletedSelector, {
    assetId,
  });
  yield spawn(
    track,
    name,
    {
      asset_id: assetId,
      filetype,
      filesize,
      ...otherProps,
    },
    options
  );
}

function* trackFirstUpload(assetId) {
  // For first ever uploads we want to track an additional event so that it can be used
  // as a proxy for a conversion metric. Note: this must be done client-side because
  // we need to send the result to integrations that only run in the client (Google / FB)
  const { lifetime_file_count: fileCount } = yield select(
    accountEntityForAssetIdSelector,
    {
      assetId,
    }
  );

  // Count is 1 because the upload finish happens after the file count has already
  // been incremented and sent back to the client over a socket usage update
  if (fileCount === 1) {
    yield spawn(track, 'first-upload-completed-client');
  }
}

function* onPendingUpload(assetId) {
  yield spawn(trackUpload, 'upload-started', assetId);
  yield call(preventUnloadIfNeeded);

  // The uploader instance may fire UPLOAD.SUCCESS before the trailing action is
  // processed by `throttle`. To prevent cancelling the watcher prematurely,
  // instead of taking UPLOAD.SUCCESS here, take a different action that fires
  // only when 100% progress is broadcasted.
  const watcher = yield fork(watchUploadProgress, assetId);
  yield take([
    UPLOAD.CANCEL,
    UPLOAD.FAILURE,
    (action) => isBroadcastCompleteActionForAsset(action, assetId),
  ]);
  yield cancel(watcher);
}

function* trackUploadSuccess({ payload: { assetId, getMetrics } }) {
  const { parts, totalUploadSpeed, wasPaused } = getMetrics();

  // This will count retry attempts (`upload-part-retry` events) that might not
  // have made it to Segment because of problems like network failures.
  const retryCounts = parts.map((part) => part.retryCount);

  yield spawn(trackUpload, 'upload-completed-client', assetId, {
    // A CSV of retry attempts that correspond to each part, so that we can
    // track which particular part was failing
    part_retry_counts: retryCounts.toString(),

    // The total count as a number so that we can make queries like "give me all
    // uploads that had to retry before succeeding"
    total_retry_counts: retryCounts.reduce((total, part) => total + part, 0),

    upload_speed: totalUploadSpeed,
    was_paused: wasPaused,
  });
  yield spawn(trackFirstUpload, assetId);
}

const getRetryCount = (error) => error.config?.['axios-retry']?.retryCount;

// Get AWS request id when failed response is available
const getAwsReqId = (error) =>
  error.response?.headers['x-amz-request-id'] || '';

// Note that this is just the first failed part of the file. Errors for other
// failed parts will be ignored because the uploaderChannel saga would've been
// terminated by the first error.
function* trackPartUploadFailure({ payload: { assetId, error } }) {
  yield call(trackUpload, 'upload-failed', assetId, {
    error: error.message,
    part_retry_count: getRetryCount(error),
    aws_req_id: getAwsReqId(error),
  });
}

function* trackPartRetry({ payload: { assetId, error } }) {
  const counts = error.config.frameio;

  yield call(trackUpload, 'upload-part-retry', assetId, {
    error: error.message,
    part_retry_count: getRetryCount(error),
    incompletebody_retry_count: counts?.incompleteBodyCount ?? 0,
    requesttimeout_retry_count: counts?.requestTimeoutCount ?? 0,
    aws_req_id: getAwsReqId(error),
  });
}

export default [
  takeEvery(UPLOAD.PENDING, ({ payload: { assetId } }) =>
    onPendingUpload(assetId)
  ),
  takeEvery(
    [UPLOAD.CANCEL, UPLOAD.FAILURE, UPLOAD.SUCCESS],
    unpreventUnloadIfNeeded
  ),
  spawn(resumeUploadsOnWake),

  // Tracking
  takeEvery(UPLOAD.CANCEL, ({ payload: { assetId } }) =>
    trackUpload('upload-canceled', assetId)
  ),
  takeEvery(UPLOAD.PAUSE, ({ payload: { assetId } }) =>
    trackUpload('upload-paused', assetId)
  ),
  takeEvery(UPLOAD.RESUME, ({ payload: { assetId } }) =>
    trackUpload('upload-resumed', assetId)
  ),
  takeEvery(UPLOAD.PART_RETRY, trackPartRetry),
  takeEvery(UPLOAD.FAILURE, trackPartUploadFailure),
  takeEvery(UPLOAD.SUCCESS, trackUploadSuccess),
];

export const testExports = {
  broadcastComplete,
  broadcastUploadProgressForAsset,
  resumeUploadsOnWake,
  onBundleUploadProgress,
  onAssetUploadProgress,
  onLifecycleStateChange,
  onPendingUpload,
  trackUploadSuccess,
  preventUnloadIfNeeded,
  trackUpload,
  trackFirstUpload,
  unpreventUnloadIfNeeded,
  watchUploadProgress,
};
