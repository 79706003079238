import React from 'react';
import PropTypes from 'prop-types';
import { MenuSeparator } from '@frameio/vapor';
import NewFolder from 'components/FolderActions/NewFolder';
import ProjectSettings from 'components/ProjectActions/ProjectSettingsItem';
import JoinLeaveProject from 'components/ProjectActions/JoinLeaveProject';
import RecentlyDeleted from 'components/ProjectActions/RecentlyDeleted';
import DownloadAll from 'components/ProjectActions/DownloadAllItem';
import ArchiveProject from 'components/ProjectActions/ArchiveProject';
import UnarchiveProject from 'components/ProjectActions/UnarchiveProject';
import DeleteProjectItem from 'components/ProjectActions/DeleteProjectItem';
import ProjectDevicesContextMenu from '../ProjectDevices/ContextMenu';

export default function ProjectContextMenu({
  canArchiveProject,
  canDeleteProject,
  canDownload,
  canRestoreDeletedAssets,
  canTogglePrivacy,
  canUnarchiveProject,
  canUseC2CConnections,
  devicesEnabled,
  isDeleteProjectEnabled,
  isLeaveProjectEnabled,
  isFilesTab,
  isC2CConnectionsTab,
  folderId,
  hasFolderActions,
  insertAssetAtIndex,
  projectId,
  projectName,
  trackingPage,
  trackingPosition,
}) {
  const showFolderActions = hasFolderActions && folderId;
  const showProjectDeviceContextMenuOptions =
    isC2CConnectionsTab && canUseC2CConnections && devicesEnabled;

  return (
    <React.Fragment>
      {isFilesTab && (
        <React.Fragment>
          {showFolderActions && (
            <NewFolder
              parentId={folderId}
              index={insertAssetAtIndex}
              trackingPage={trackingPage}
              trackingPosition={trackingPosition}
            />
          )}
          {showFolderActions && canTogglePrivacy && (
            <NewFolder
              parentId={folderId}
              index={insertAssetAtIndex}
              isPrivate
              trackingPage={trackingPage}
              trackingPosition={trackingPosition}
            />
          )}
          {showFolderActions && <MenuSeparator />}

          {canDownload && folderId && (
            <DownloadAll
              projectId={projectId}
              folderId={folderId}
              trackingPage={trackingPage}
              trackingPosition={trackingPosition}
            />
          )}
          {canRestoreDeletedAssets && (
            <RecentlyDeleted
              projectId={projectId}
              trackingPage={trackingPage}
              trackingPosition={trackingPosition}
            />
          )}

          {(canDownload || canRestoreDeletedAssets) && <MenuSeparator />}
        </React.Fragment>
      )}
      {showProjectDeviceContextMenuOptions && (
        <ProjectDevicesContextMenu
          data-test-id="project-device-context-menu-options"
          projectId={projectId}
          projectName={projectName}
        />
      )}
      <ProjectSettings
        trackingPage={trackingPage}
        trackingPosition={trackingPosition}
        projectId={projectId}
      />
      <JoinLeaveProject
        trackingPage={trackingPage}
        trackingPosition={trackingPosition}
        projectId={projectId}
        isLeaveProjectEnabled={isLeaveProjectEnabled}
      />
      {canArchiveProject && (
        <ArchiveProject
          trackingPage={trackingPage}
          trackingPosition={trackingPosition}
          projectId={projectId}
        />
      )}
      {canUnarchiveProject && (
        <UnarchiveProject
          trackingPage={trackingPage}
          trackingPosition={trackingPosition}
          projectId={projectId}
        />
      )}
      {canDeleteProject && isDeleteProjectEnabled && (
        <DeleteProjectItem
          trackingPage={trackingPage}
          trackingPosition={trackingPosition}
          projectId={projectId}
          projectName={projectName}
        />
      )}
    </React.Fragment>
  );
}

ProjectContextMenu.propTypes = {
  canArchiveProject: PropTypes.bool,
  canDeleteProject: PropTypes.bool,
  canDownload: PropTypes.bool,
  canRestoreDeletedAssets: PropTypes.bool,
  canTogglePrivacy: PropTypes.bool,
  canUnarchiveProject: PropTypes.bool,
  folderId: PropTypes.string,
  hasFolderActions: PropTypes.bool,
  insertAssetAtIndex: PropTypes.number.isRequired,
  isFilesTab: PropTypes.bool,
  projectId: PropTypes.string,
  projectName: PropTypes.string,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};

ProjectContextMenu.defaultProps = {
  canArchiveProject: undefined,
  canDeleteProject: undefined,
  canDownload: undefined,
  canRestoreDeletedAssets: undefined,
  canTogglePrivacy: undefined,
  canUnarchiveProject: undefined,
  folderId: undefined,
  hasFolderActions: false,
  isFilesTab: false,
  projectId: undefined,
  projectName: '',
};

export const testExports = {
  ArchiveProject,
  UnarchiveProject,
};
