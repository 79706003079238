import { createAction } from 'redux-actions';
import {
  generateActionTypes,
  generateFetchActionTypes,
} from 'shared/actions/helpers';

const module = 'PEOPLE_SETTINGS';

const PEOPLE_SETTINGS = {
  ...generateFetchActionTypes(
    module,
    [
      'GET_COLLABORATORS',
      'GET_REVIEWERS',
      'GET_PENDING_COLLABORATORS',
      'GET_PENDING_REVIEWERS',
    ],
    true
  ),
  ...generateActionTypes(module, [
    'REMOVE_COLLABORATOR',
    'REMOVE_REVIEWER',
    'REMOVE_PENDING_COLLABORATOR',
    'REMOVE_PENDING_REVIEWER',
  ]),
};

export default PEOPLE_SETTINGS;

export const getCollaborators = createAction(
  PEOPLE_SETTINGS.GET_COLLABORATORS.BASE,
  (accountId, query, page) => ({ accountId, query, page })
);

export const resetCollaborators = createAction(
  PEOPLE_SETTINGS.GET_COLLABORATORS.RESET,
  () => ({})
);

export const getReviewers = createAction(
  PEOPLE_SETTINGS.GET_REVIEWERS.BASE,
  (accountId, query, page) => ({ accountId, query, page })
);

export const resetReviewers = createAction(
  PEOPLE_SETTINGS.GET_REVIEWERS.RESET,
  () => ({})
);

export const getPendingCollaborators = createAction(
  PEOPLE_SETTINGS.GET_PENDING_COLLABORATORS.BASE,
  (accountId, query, page) => ({ accountId, query, page })
);

export const getPendingReviewers = createAction(
  PEOPLE_SETTINGS.GET_PENDING_REVIEWERS.BASE,
  (accountId, query, page) => ({ accountId, query, page })
);

export const resetPendingCollaborators = createAction(
  PEOPLE_SETTINGS.GET_PENDING_COLLABORATORS.RESET,
  () => ({})
);

export const resetPendingReviewers = createAction(
  PEOPLE_SETTINGS.GET_PENDING_REVIEWERS.RESET,
  () => ({})
);

export const removeCollaborator = createAction(
  PEOPLE_SETTINGS.REMOVE_COLLABORATOR,
  (accountId, email, name) => ({ accountId, email, name })
);

export const removeReviewer = createAction(
  PEOPLE_SETTINGS.REMOVE_REVIEWER,
  (accountId, userId, name) => ({ accountId, userId, name })
);

export const removePendingCollaborator = createAction(
  PEOPLE_SETTINGS.REMOVE_PENDING_COLLABORATOR,
  (accountId, email) => ({ accountId, email })
);

export const removePendingReviewer = createAction(
  PEOPLE_SETTINGS.REMOVE_PENDING_REVIEWER,
  (accountId, email) => ({ accountId, email })
);
