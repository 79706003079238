import React, { useReducer, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import TreeView from '@frameio/components/src/styled-components/TreeView';
import { queryFolders, queryProjects, queryTeams } from './queries';
import SearchingSpinner from './SearchingSpinner';
import ConnectedTreeTeamItem from './ConnectedTreeTeamItem';
import SearchAllResultsGroup from './SearchAllResultsGroup';
import HighlightedName from './HighlightedName';
import ConnectedTreeProjectItem from './ConnectedTreeProjectItem';
import { FolderListItem } from './FolderSearchResults';
import NoResults from './NoResults';
import useKeyboardNav from './useKeyboardNav';
import useUpdateShadowContainer from './useUpdateShadowContainer';

const ScrollContainer = styled.div`
  overflow-y: auto;
  -webkit-overflow-scroll: touch;
`;

const SearchResults = styled.div`
  padding: ${(p) => `0 ${p.theme.spacing.units(2)}`};
`;

const StyledSearchingSpinner = styled(SearchingSpinner)`
  margin-left: ${(p) => p.theme.spacing.units(1.5)};
`;

const ListPadding = styled.div`
  padding-bottom: 1px;
`;

/**
 * Handle fetch results for a specific type (folder, projects, teams)
 * @param {Object} state paginated fetch results by type
 * @param {Object} action
 */

const DEFAULT_FETCH_MORE_STATE = {
  filter: null,
  results: [],
  isFetchingMore: false,
  hasNextPage: false,
  hasError: false,
  currentPage: 0,
  expanded: [],
};

function filteredResultsReducer(state, action) {
  switch (action.type) {
    case 'toggle-expansion':
      return {
        ...state,
        expanded: action.payload.expanded,
      };

    case 'fetch-success': {
      const response = action.payload[state.filter];
      return {
        ...state,
        isFetchingMore: false,
        hasError: false,
        hasNextPage: response.hasNextPage,
        currentPage: response.currentPage,
        results: response.results,
      };
    }
    case 'fetch-more-error':
      return {
        ...state,
        hasError: true,
        isFetchingMore: false,
      };

    case 'fetch-more':
      return {
        ...state,
        isFetchingMore: true,
        hasError: false,
      };

    case 'fetch-more-success':
      return {
        ...state,
        isFetchingMore: false,
        hasError: false,
        hasNextPage: action.payload.hasNextPage,
        currentPage: action.payload.currentPage,
        results: [...state.results, ...action.payload.results],
      };
    default:
      throw new Error();
  }
}

const FILTER_OPTIONS = ['folders', 'projects', 'teams'];

const DEFAULT_SEARCH_ALL_STATE = {
  isSearching: false,
  hasError: false,
};

FILTER_OPTIONS.forEach((option) => {
  DEFAULT_SEARCH_ALL_STATE[option] = {
    ...DEFAULT_FETCH_MORE_STATE,
    filter: option,
  };
});

/**
 * Our primary reducer which holds:
 *  - Fetched results for all of our filter types
 *  - 'fetch more' status for each filter type
 *  - expanded treeview state for each filter type
 *  - searching status for all filters (executed in parallel)
 * @param {Object} state search all state
 * @param {Object} action
 */

function searchAllReducer(state, action) {
  switch (action.type) {
    case 'fetch-start':
      return {
        ...state,
        isSearching: true,
        hasError: false,
      };

    case 'fetch-success':
      return {
        ...state,
        isSearching: false,
        hasError: false,
        folders: filteredResultsReducer(state.folders, action),
        projects: filteredResultsReducer(state.projects, action),
        teams: filteredResultsReducer(state.teams, action),
      };

    case 'fetch-error':
      return {
        ...state,
        isSearching: false,
        hasError: true,
      };

    case 'fetch-more':
    case 'fetch-more-success':
    case 'fetch-more-error':
    case 'toggle-expansion':
      return {
        ...state,
        [action.payload.filter]: filteredResultsReducer(
          state[action.payload.filter],
          action
        ),
      };

    default:
      throw new Error();
  }
}

function SearchAllResults({
  query,
  teams,
  accountId,
  selectedId,
  limitToProjectId,
  onDoubleClick,
  onSelect,
  onScroll,
}) {
  const [state, dispatch] = useReducer(
    searchAllReducer,
    DEFAULT_SEARCH_ALL_STATE
  );

  const { ref } = useUpdateShadowContainer(onScroll);

  /**
   * Whenever our query changes, we query all three filtered
   * searches (teams, projects, and folders) in parallel and
   * save the results for each in our store.
   */
  useEffect(() => {
    dispatch({ type: 'fetch-start' });

    // Perform our search
    async function search() {
      try {
        // fetch all in parallel
        const [projectResults, folderResults, teamResults] = await Promise.all([
          queryProjects(query, accountId, teams),
          queryFolders(query, accountId, limitToProjectId),
          queryTeams(teams, query),
        ]);

        dispatch({
          type: 'fetch-success',
          payload: {
            projects: projectResults,
            folders: folderResults,
            teams: teamResults,
          },
        });
      } catch (err) {
        // currently any of the fetches which produce an error
        // will render the entire results with an error message.
        // eslint-disable-next-line no-console
        console.error(err);
        dispatch({ type: 'fetch-error' });
      }
    }

    // debounce the search
    const handler = setTimeout(search, 300);

    // clear the debounce upon unmounting
    return () => {
      clearTimeout(handler);
    };
  }, [query, accountId, teams, limitToProjectId]);

  // toggle team tree expansion
  const handleTeamTreeExpansion = useCallback((exp) => {
    dispatch({
      type: 'toggle-expansion',
      payload: {
        filter: 'teams',
        expanded: exp,
      },
    });
  }, []);

  // toggle projects tree expansiion
  const handleProjectTreeExpansion = useCallback((exp) => {
    dispatch({
      type: 'toggle-expansion',
      payload: {
        filter: 'projects',
        expanded: exp,
      },
    });
  }, []);

  /**
   * Fetch the next page of results for a particular
   * filter type
   */

  const handleRequestNextPage = useCallback(
    async (filter) => {
      dispatch({
        type: 'fetch-more',
        payload: {
          filter,
        },
      });

      const filterState = state[filter];
      const { currentPage } = filterState;

      async function fetchMoreForFilter(filterType, nextPage) {
        switch (filterType) {
          case 'folders':
            return queryFolders(query, accountId, limitToProjectId, nextPage);
          case 'projects':
            return queryProjects(query, accountId, teams, nextPage);
          case 'teams':
            return queryTeams(teams, query, nextPage);
          default:
            throw Error();
        }
      }

      try {
        const results = await fetchMoreForFilter(filter, currentPage + 1);
        dispatch({
          type: 'fetch-more-success',
          payload: {
            filter,
            ...results,
          },
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        dispatch({
          type: 'fetch-more-error',
          payload: { filter },
        });
      }
    },
    [state, accountId, limitToProjectId, query, teams]
  );

  const { teams: filteredTeams, projects, folders } = state;

  const hasSomeResults =
    filteredTeams.results.length > 0 ||
    projects.results.length > 0 ||
    folders.results.length > 0;

  const folderIds = useMemo(() => folders.results.map((folder) => folder.id), [
    folders,
  ]);

  // used for keyboard nav in the folder list view
  const { handleKeyDown, setFocusedId, focusedId } = useKeyboardNav(
    folderIds,
    onDoubleClick,
    onSelect
  );

  return (
    <ScrollContainer ref={ref} onScroll={onScroll}>
      {state.isSearching ? (
        <StyledSearchingSpinner />
      ) : (
        <SearchResults>
          {!hasSomeResults && <NoResults>No results</NoResults>}
          {filteredTeams.results.length > 0 && (
            <SearchAllResultsGroup
              label="Teams"
              hasNextPage={filteredTeams.hasNextPage}
              isFetchingMore={filteredTeams.isFetchingMore}
              onRequestNextPage={() => handleRequestNextPage('teams')}
            >
              <TreeView
                onDoubleClick={onDoubleClick}
                onSelect={onSelect}
                selected={selectedId}
                expanded={filteredTeams.expanded}
                onExpand={handleTeamTreeExpansion}
              >
                {filteredTeams.results.map((team) => (
                  <ConnectedTreeTeamItem
                    label={<HighlightedName query={query} name={team.name} />}
                    team={team}
                    nodeId={team.id}
                    key={team.id}
                  />
                ))}
              </TreeView>
            </SearchAllResultsGroup>
          )}
          {projects.results.length > 0 && (
            <SearchAllResultsGroup
              label="Projects"
              hasNextPage={projects.hasNextPage}
              isFetchingMore={projects.isFetchingMore}
              onRequestNextPage={() => handleRequestNextPage('projects')}
            >
              <TreeView
                onExpand={handleProjectTreeExpansion}
                expanded={projects.expanded}
                selected={selectedId}
                onDoubleClick={onDoubleClick}
                onSelect={onSelect}
              >
                {projects.results.map((project) => {
                  return (
                    <ConnectedTreeProjectItem
                      nodeId={project.root_asset_id}
                      key={project.id}
                      project={project}
                      showDetails
                      label={
                        <HighlightedName query={query} name={project.name} />
                      }
                    />
                  );
                })}
              </TreeView>
            </SearchAllResultsGroup>
          )}

          {folders.results.length > 0 && (
            <SearchAllResultsGroup
              label="Folders"
              hasNextPage={folders.hasNextPage}
              isFetchingMore={folders.isFetchingMore}
              onRequestNextPage={() => handleRequestNextPage('folders')}
            >
              {folders.results.map((folder) => {
                return (
                  <ListPadding key={folder.id}>
                    <FolderListItem
                      query={query}
                      isFocused={focusedId === folder.id}
                      onKeyDown={handleKeyDown}
                      onFocus={() => setFocusedId(folder.id)}
                      isSelected={folder.id === selectedId}
                      onSelect={onSelect}
                      item={folder}
                      onDoubleClick={() => onDoubleClick(folder.id)}
                    />
                  </ListPadding>
                );
              })}
            </SearchAllResultsGroup>
          )}
        </SearchResults>
      )}
    </ScrollContainer>
  );
}

export default SearchAllResults;

export const testExports = {
  searchAllReducer,
  filteredResultsReducer,
  DEFAULT_FETCH_MORE_STATE,
  DEFAULT_SEARCH_ALL_STATE,
};
