import { connect } from 'react-redux';
import { currentAccountWithSubscriptionAndPlanSelector } from 'selectors/accounts';
import ReactivateSubscription from './ReactivateSubscription';

function mapStateToProps(state) {
  const { subscription } = currentAccountWithSubscriptionAndPlanSelector(state);
  const subscriptionEndAt = subscription.subscription_end_at;

  return {
    subscriptionEndAt,
  };
}

export const testExports = {
  mapStateToProps,
};

export default connect(mapStateToProps)(ReactivateSubscription);
