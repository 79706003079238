import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import Raven from 'raven-js';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ error }, () => {
      this.props.onError(error);
    });
    Raven.captureMessage(`${error.message}${info.componentStack}`, {
      level: 'error',
    });
  }

  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;

    if (error) {
      return typeof fallback === 'function' ? fallback(error) : fallback;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  onError: PropTypes.func,
};
ErrorBoundary.defaultProps = {
  fallback: <p>Whoops, something went wrong!</p>,
  onError: noop,
};
