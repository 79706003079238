import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@frameio/components/src/styled-components/Button';
import { configureCore, removeRefreshToken } from 'utils/auth';

const Label = styled.span`
  margin-left: ${(p) => p.theme.spacing.small};
  vertical-align: middle;
  ${(p) => p.theme.fontStyle.bodyS};
`;

export default function DeleteRefreshToken() {
  const [isDone, setIsDone] = useState(false);

  function configureCoreWithDeletedAuthToken() {
    removeRefreshToken();
    configureCore();

    setIsDone(true);
  }

  return (
    <div>
      <Button primary type="button" onClick={configureCoreWithDeletedAuthToken}>
        Force delete refresh token
      </Button>

      {isDone && (
        // eslint-disable-next-line jsx-a11y/accessible-emoji
        <Label>Refresh token is deleted ✅</Label>
      )}
    </div>
  );
}
