import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import {
  type as assetType,
  label as WORKFLOW_STATUSES,
} from '@frameio/core/src/assets/helpers/constants';
import { MenuButton } from '@frameio/vapor';
import {
  WORKFLOW_COLORS,
  WORKFLOW_STATUS_DISPLAYS,
} from 'components/AssetWorkflowStatus/constants';
import AssetWorkflowStatusDropdown, {
  StyledStatusIndicator,
} from '../../AssetWorkflowStatus/AssetWorkflowStatusDropdown';

export default function SetAssetsWorkflowStatus({ assets, tracking, ...rest }) {
  const firstAssetLabel = get(
    assets,
    '0.cover_asset.label',
    get(assets, '0.label', WORKFLOW_STATUSES.NONE)
  );
  const allShareStatuses = assets.every(
    ({ cover_asset: coverAsset, label }) =>
      (coverAsset ? coverAsset.label : label) === firstAssetLabel
  );
  const status = allShareStatuses ? firstAssetLabel : WORKFLOW_STATUSES.NONE;

  return (
    <MenuButton
      iconBefore={({ isSelected }) => {
        return (
          <StyledStatusIndicator
            isSelected={isSelected}
            variant="checked"
            color={WORKFLOW_COLORS[status]}
          />
        );
      }}
      submenu={
        <AssetWorkflowStatusDropdown assets={assets} tracking={tracking} />
      }
      {...rest}
    >
      {WORKFLOW_STATUS_DISPLAYS[status]}
    </MenuButton>
  );
}

SetAssetsWorkflowStatus.propTypes = {
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      cover_asset: PropTypes.shape({
        label: PropTypes.oneOf(Object.values(WORKFLOW_STATUSES)),
      }),
      type: PropTypes.oneOf(Object.values(assetType)),
      label: PropTypes.oneOf(Object.values(WORKFLOW_STATUSES)),
      id: PropTypes.string,
    })
  ).isRequired,
};
