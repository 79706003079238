import { ASSET } from '../../actions/assets';

export const INITIAL_STATE = {
  fwmFrameRateSupported: true,
};

const assetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ASSET.SET_FWM_FRAME_RATE_SUPPORTED: {
      const { fwmFrameRateSupported } = action.payload;
      return { ...state, fwmFrameRateSupported };
    }
    default:
      return state;
  }
};

export default assetReducer;
