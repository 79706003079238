import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@frameio/components/src/styled-components/Button';
import { configureCore, setAuthCookies } from 'utils/auth';

const Label = styled.span`
  margin-left: ${(p) => p.theme.spacing.small};
  vertical-align: middle;
  ${(p) => p.theme.fontStyle.bodyS};
`;

const StyledButton = styled(Button)`
  margin-bottom: ${(p) => p.theme.spacing.micro};
`;

export default function InvalidAuthToken() {
  const [isDone, setIsDone] = useState(false);

  function configureCoreWithGarbageToken() {
    const garbageAuthToken =
      'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJfVsYXRpb25faWQiOiI3OGI5Mzg3YS1hM2RiLTQ1YTItODdiNy02NTZjODFmZGE4NTUiLCJfdmVyc2lvbiI6MTM5LCJhdWQiOiJpby5mcmFtZS5hcGkiLCJleHAiOjE3MDY1NjEyMzUsImlhdCI6MTcwNjU2MTIzNSwiaXNzIjoiaW8uZnJhbWUuYXBpIiwianRpIjoiZmViMjFhNzctZTU5Yy00NmFlLWFhOTUtMmY2NTQyY2MwZjI2IiwibmJmIjoxNzA2NTYxMjM0LCJzdWIiOiJVc2VyOmFjYWNhYzYyLTFhMGQtNDcxYy04OGM4LTM5NzEyNGRhMjJhZSIsInR5cCI6ImFjY2VzcyJ9.NroracroD_6Wffysnims86TwhK9fgCuZApml0Ibpm9R3dAB76pqUvXcRlz1_spfL5ZGnq1vB4rxG2iARXisfDA';
    setAuthCookies(garbageAuthToken);
    configureCore();

    setIsDone(true);
  }

  return (
    <div>
      <StyledButton
        primary
        type="button"
        onClick={configureCoreWithGarbageToken}
      >
        Force invalid auth token
      </StyledButton>

      {isDone && (
        // eslint-disable-next-line jsx-a11y/accessible-emoji
        <Label>Token is invalid ✅</Label>
      )}
    </div>
  );
}
