import React from 'react';
import PropTypes from 'prop-types';
import { MenuButton } from '@frameio/vapor';
import ResetIcon from '@frameio/components/src/svgs/raw/ic-reset-circled-arrow-20px.svg';

export default function ResetLifecycle(props) {
  const {
    assetIds,
    confirmExtendDeletionDate,
    daysLeftBeforeDeleted,
    trackingPage,
    trackingPosition,
    ...rest
  } = props;

  const daysLeftText =
    assetIds.length < 2 && daysLeftBeforeDeleted > 0
      ? `(${daysLeftBeforeDeleted} ${
          daysLeftBeforeDeleted === 1 ? 'day' : 'days'
        })`
      : undefined;

  return (
    <MenuButton
      onSelect={() =>
        confirmExtendDeletionDate(assetIds, trackingPage, trackingPosition)
      }
      iconBefore={<ResetIcon width={16} height={16} />}
      {...rest}
    >
      Reset lifecycle {daysLeftText}
    </MenuButton>
  );
}

ResetLifecycle.propTypes = {
  assetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  daysLeftBeforeDeleted: PropTypes.number,
  confirmExtendDeletionDate: PropTypes.func,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};

ResetLifecycle.defaultProps = {
  daysLeftBeforeDeleted: 0,
  confirmExtendDeletionDate: () => {},
};
