/* global Dropbox */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Raven from 'raven-js';
import { trackButtonClick } from 'analytics';
import { MenuButton } from '@frameio/vapor';
import { getHighestDownloadUrlForAsset } from '@frameio/core/src/assets/helpers/utils';
import { getAsset as getAssetService } from '@frameio/core/src/assets/services';
import DropboxIcon from '@frameio/components/src/svgs/icons/dropbox.svg';
import { type as assetType } from '@frameio/core/src/assets/helpers/constants';

export default function PublishAssetToDropbox({
  assetIds,
  showErrorToast: errorToast,
  showDropboxToast: successToast,
  trackingPage,
  trackingPosition,
  ...rest
}) {
  /**
   * Asyncronously get asset information for the Dropbox integration, using a
   * new network request which includes a special header and doesn't store the
   * response data in the redux store. The special header indicates a request
   * for non-edge-cache-auth urls. For details see SERV-2702.
   */
  const getDropboxFiles = useCallback(async (ids) => {
    const assetRequests = ids.map((assetId) =>
      getAssetService(
        assetId,
        {},
        { 'x-frameio-client': 'legacy-third-party-integration-from-web-v3' }
      )
    );
    const assetServiceDataArr = await Promise.all(assetRequests);
    const nextFiles = assetServiceDataArr
      .map((serviceData) => {
        const normalizedAsset =
          serviceData.entities?.asset?.[serviceData.result];
        return {
          ...normalizedAsset,
          cover_asset:
            serviceData.entities?.asset?.[normalizedAsset?.cover_asset_id],
        };
      })
      .filter((asset) => asset.type !== assetType.FOLDER)
      .map((asset) => ({
        url: getHighestDownloadUrlForAsset(asset),
        filename: asset.cover_asset ? asset.cover_asset.name : asset.name,
      }));
    return nextFiles;
  }, []);

  return (
    <MenuButton
      onSelect={() => {
        trackButtonClick(
          'publish asset to dropbox',
          trackingPage,
          trackingPosition
        );

        getDropboxFiles(assetIds).then((files) => {
          const fileNames =
            files.length === 1 ? files[0].filename : `${files.length} files`;
          const options = {
            files,
            error: (errorMessage) => {
              // note that this arg is empty in current usage, it appears that
              // `dropbox.com/static/api/2/dropins.js` may have a bug about
              // passing the correct api response attribute
              // eslint-disable-next-line no-console
              console.error(errorMessage);
              Raven.captureMessage(
                'Failed to publish to Dropbox, possibly because of SERV-2702',
                {
                  level: 'error',
                  extra: { assetIds, errorMessage },
                  tags: { jira_ticket: 'SERV-2702' },
                }
              );
              errorToast({
                header: `Failed to send ${fileNames} to Dropbox`,
              });
            },
            success: () => {
              successToast({
                header: `Successfully sent ${fileNames} to Dropbox`,
                subHeader: 'https://www.dropbox.com/home',
              });
            },
          };

          Dropbox.save(options);
        });
      }}
      iconBefore={<DropboxIcon width={16} height={16} />}
      {...rest}
    >
      Publish to Dropbox
    </MenuButton>
  );
}

PublishAssetToDropbox.propTypes = {
  assetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  showErrorToast: PropTypes.func.isRequired,
  showDropboxToast: PropTypes.func.isRequired,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};
