import createCachedSelector from 're-reselect';
import {
  canAssetsBeMovedToFolderSelector,
  canAssetsBeMovedUpOneFolderSelector,
  doesCurrentUserOwnAllAssetsSelector,
  canCreateDualViewSelector,
} from 'selectors/assets';
import {
  areAssetsUploaded,
  areAssetsVersionStacks,
  canAssetsBeDownloadedSelector,
  containsMultipleAssetsSelector,
  doAssetsContainFoldersSelector,
} from '@frameio/core/src/assets/selectors';
import { containsAdobeIntegratedAssetsSelector } from '@frameio/core/src/adobeIntegrations/selectors';
import { containsWorkfrontTaskIdSelector } from '@frameio/core/src/workfront/selectors';
import { isCollaboratorOnlySelector } from 'selectors/roles';
import { isAccountEnterpriseSelector } from 'selectors/accounts';
import permittedActionsForProjectSelector from './permittedActionsForProjectSelector';

/**
 * Gets the permissions for the current user acting on a list of asset ids
 * in a given project.
 * @param {Object} state - Redux state.
 * @param {Object} ownProps - Must contain an `assetIds` key that is an array of asset ids, and a
 * `projectId` key.
 * @returns {Object} Permissions hash.
 */
const permittedActionsForAssetsSelector = createCachedSelector(
  permittedActionsForProjectSelector,
  doesCurrentUserOwnAllAssetsSelector,
  canAssetsBeMovedToFolderSelector,
  canAssetsBeMovedUpOneFolderSelector,
  containsAdobeIntegratedAssetsSelector,
  containsMultipleAssetsSelector,
  containsWorkfrontTaskIdSelector,
  doAssetsContainFoldersSelector,
  areAssetsVersionStacks,
  areAssetsUploaded,
  isCollaboratorOnlySelector,
  canAssetsBeDownloadedSelector,
  canCreateDualViewSelector,
  isAccountEnterpriseSelector,
  (
    permissions = {},
    ownsAllAssets,
    canBeMovedToFolder,
    canBeMovedUpOneFolder,
    containsAdobeIntegratedAssets,
    isMultiple,
    containsWorkfrontTaskId,
    containsFolders,
    areVersionStacks,
    areUploaded,
    isCollaboratorOnly,
    canAssetsBeDownloaded,
    canCreateDualView,
    isAccountEnterprise
  ) => {
    const isSingleFolderOnly = !isMultiple && containsFolders;
    const isSingleNonFolderOnly = !isMultiple && !containsFolders;

    let canDownload =
      permissions.canDownload && areUploaded && canAssetsBeDownloaded;

    // Without the zip service, the client cannot serially download a folder
    // because the assets in the folder might not be fetched yet. So disable
    // the download menu item for now.
    //
    // TODO(marvin): remove this once zip download service is fixed
    canDownload = permissions.canDownloadFolders
      ? canDownload
      : !isSingleFolderOnly && canDownload;

    return {
      ...permissions,
      canConnectToAdobeIntegration:
        !containsFolders && !containsAdobeIntegratedAssets,
      canAccessAemIntegration: isAccountEnterprise && !containsFolders,
      canCreateDualView,
      canDelete: permissions.canDelete || ownsAllAssets,
      canDownload,
      canEditDescription: !isCollaboratorOnly,
      canHaveCustomActions: isSingleNonFolderOnly,
      canSelectFolders: containsFolders,
      canManageConnection: isSingleNonFolderOnly,
      canMoveToFolder:
        areUploaded && canBeMovedToFolder && permissions.canMoveToFolder,
      canPublishToDropbox:
        areUploaded && permissions.canPublishToDropbox && !containsFolders,
      canPublishToVimeo:
        areUploaded && permissions.canPublishToVimeo && isSingleNonFolderOnly,
      canRename:
        (permissions.canRename || ownsAllAssets) && !isMultiple && areUploaded,
      canResetAssetLifecycle:
        permissions.canResetAssetLifecycle && !containsFolders && areUploaded,
      canSetStatus: !containsFolders && areUploaded,
      canSharePresentation: permissions.canSharePresentation,
      canShareReview: permissions.canShareReview,
      canTogglePrivacy: permissions.canTogglePrivacy && areUploaded,
      canUpdateWorkfrontConnectedTask:
        isSingleNonFolderOnly && containsWorkfrontTaskId,
      canManageVersions:
        (permissions.canManageVersions || ownsAllAssets) &&
        !isMultiple &&
        areVersionStacks &&
        areUploaded,
    };
  }
)(
  (state, { projectId, assetIds }) =>
    `${projectId}${[...assetIds].sort().join(',')}`
);

export default permittedActionsForAssetsSelector;
