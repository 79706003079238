import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuRadio } from '@frameio/vapor';
import LockIcon from '@frameio/components/src/svgs/icons/lock.svg';
import { trackButtonClick } from 'analytics';

export default function ToggleAssetsPrivacy({
  assets,
  batchUpdateAssets,
  trackingPage,
  trackingPosition,
  ...rest
}) {
  const numPrivateAssets = assets.reduce(
    (numPrivate, { private: isPrivate }) => numPrivate + isPrivate,
    0
  );
  const numNonPrivateAssets = assets.length - numPrivateAssets;
  const targetPrivacy = numNonPrivateAssets >= numPrivateAssets;
  const trackingCount =
    assets.length === 1 ? 'single asset' : 'multiple assets';
  const trackingTargetPrivacy = targetPrivacy ? 'private' : 'non-private';

  const [isChecked, setIsChecked] = useState(!targetPrivacy);

  return (
    <MenuRadio
      {...rest}
      checked={isChecked}
      onSelect={() => {
        setIsChecked(!isChecked);
        const updates = assets.map(({ id }) => ({
          id,
          private: targetPrivacy,
        }));
        batchUpdateAssets(updates);
        trackButtonClick(
          `toggle ${trackingCount} to ${trackingTargetPrivacy}`,
          trackingPage,
          trackingPosition
        );
      }}
      iconBefore={<LockIcon width={16} height={16} />}
    >
      {isChecked ? 'Private' : 'Make Private'}
    </MenuRadio>
  );
}

ToggleAssetsPrivacy.propTypes = {
  batchUpdateAssets: PropTypes.func.isRequired,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      private: PropTypes.bool,
      id: PropTypes.string,
    })
  ).isRequired,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
};
