import CompareSVG from '@frameio/components/src/svgs/icons/24/compare.svg';
import { getMediaType } from '@frameio/core/src/assets/helpers/mediaTypes';
import { type as assetTypes } from '@frameio/core/src/assets/helpers/constants';
import { supportedComparisonMediaTypes } from 'components/SplitPlayerContainers/shared/constants';
import { MenuButton } from '@frameio/vapor';
import PropTypes from 'prop-types';
import React from 'react';
import { COMPARE_URL } from 'URLs';
import { goToRoute } from 'utils/router';
import track from 'analytics';

const canCompareAssets = (assetOne, assetTwo) => {
  const assetOneType = getMediaType(assetOne);
  const assetTwoType = getMediaType(assetTwo);

  if (assetOneType !== assetTwoType) return false;
  return supportedComparisonMediaTypes.includes(assetOneType);
};

const CompareAssets = ({ hydratedAssets, tracking: { source }, ...rest }) => {
  const getAssetRoot = (asset) =>
    asset.type === 'version_stack' ? asset?.cover_asset : asset;

  const assetOne = getAssetRoot(hydratedAssets[0]);
  const assetTwo = getAssetRoot(hydratedAssets[1]);

  const areAnyAssetsVersionStack =
    hydratedAssets[0].type === assetTypes.VERSION_STACK ||
    hydratedAssets[1].type === assetTypes.VERSION_STACK;

  const trackCompare = () => {
    track('assets-compared-client', {
      source,
      media_type: getMediaType(assetOne),
      is_version_stack: areAnyAssetsVersionStack,
    });
  };

  const compareAssets = () => {
    const queryParams = {
      a1: assetOne.id,
      a2: assetTwo.id,
    };
    goToRoute(COMPARE_URL, {}, queryParams);
    trackCompare();
  };

  const shouldShowCompareButton = canCompareAssets(assetOne, assetTwo);

  return (
    <React.Fragment>
      {shouldShowCompareButton && (
        <MenuButton
          onSelect={compareAssets}
          iconBefore={<CompareSVG width={16} height={16} />}
          {...rest}
        >
          Compare assets
        </MenuButton>
      )}
    </React.Fragment>
  );
};

CompareAssets.propTypes = {
  hydratedAssets: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CompareAssets;
