import { createSelector } from 'reselect';
import { getPublicPresentationUrl, getPublicReviewLinkUrl } from 'URLs';
import {
  reviewerEntitySelector,
  reviewLinkEntityForReviewerSelector,
  presentationEntityForReviewerSelector,
} from '@frameio/core/src/reviewers/selectors';
import { userEntitySelector } from '@frameio/core/src/users/selectors';
import {
  paginatedListAllResultsSelector,
  paginatedListTotalCountSelector,
} from '@frameio/core/src/shared/selectors';

export const inboxContainerSelector = (state) => state.inboxContainer;
export const reviewersSelector = (state) =>
  inboxContainerSelector(state).reviewers;
export const totalUnseenReviewsSelector = (state) =>
  inboxContainerSelector(state).totalUnseenReviews;

export const reviewerIdsSelector = createSelector(
  reviewersSelector,
  paginatedListAllResultsSelector
);
export const reviewerTotalCountSelector = createSelector(
  reviewersSelector,
  paginatedListTotalCountSelector
);

export const reviewerCreatorNameSelector = (state, { reviewerId }) => {
  const { creator_id: userId } = reviewerEntitySelector(state, { reviewerId });
  const userEntity = userEntitySelector(state, { userId });

  return userEntity.name;
};

export const isInboxEmptySelector = createSelector(
  reviewersSelector,
  (slice) => paginatedListTotalCountSelector(slice) === 0
);

// NOTE: A `sharedEntity` is not the name of any entity type. Rather, it is a name given to an
// entity either of type `presentation` or `review_link` that is associated with a `reviewer`
// entity (i.e., it is an entity that has been _shared_ by a user with another user) and exists
// in a state where either, 1) We are not concerned about the particular type of entity we're
// working with (e.g., we only want to know if an entity exists, etc.), or 2) We only have
// access to a `reviewer` entity, and have not yet derived which type of entity it is
// associated with.

// Given a `reviewerId`, determine the entity shared by attempt to select either an associated
// `review_link` or `presentation`
export const sharedEntityForReviewerSelector = createSelector(
  reviewLinkEntityForReviewerSelector,
  presentationEntityForReviewerSelector,
  (reviewLinkEntity, presentationEntity) =>
    reviewLinkEntity || presentationEntity
);

export const reviewerIdsExclDeletedSharedEntitySelector = (state) => {
  const reviewerIds = reviewerIdsSelector(state);

  return reviewerIds.filter(
    (reviewerId) =>
      reviewerId && sharedEntityForReviewerSelector(state, { reviewerId })
  );
};

export const sharedEntityAssetIdsForReviewerSelector = createSelector(
  sharedEntityForReviewerSelector,
  (sharedEntity) => sharedEntity.assets
);

export const sharedEntityTitleSelector = (state, { reviewerId }) => {
  const { _type: entityType, name, title } = sharedEntityForReviewerSelector(
    state,
    { reviewerId }
  );

  if (entityType === 'presentation') {
    return title;
  }

  // if the entity associated with the given `reviewer` is not a presentation, we can
  // safely assume it is a `review_link` and return the `name` which will always have
  // a value
  return name;
};

export const publicUrlForSharedEntitySelector = createSelector(
  sharedEntityForReviewerSelector,
  (sharedEntity) => {
    // NOTE: The `sharedEntityId` here is used only in the case that the sharedEntity is
    // a `review_link`, but could technically belong to either a `presentation` or a `review_link`
    const {
      vanity: vanityId,
      _type: entityType,
      id: sharedEntityId,
      short_url: shortUrl,
    } = sharedEntity;

    return entityType === 'review_link'
      ? getPublicReviewLinkUrl(sharedEntityId, shortUrl)
      : getPublicPresentationUrl(vanityId, shortUrl);
  }
);

export const sharedEntityItemCountSelector = createSelector(
  sharedEntityForReviewerSelector,
  sharedEntityAssetIdsForReviewerSelector,
  // Only `review_link`s have an `item_count` property provided to them, which is a
  // more canonical way of deriving thee total number of associated assets than checking
  // the length of the `assets` array property. So here we prefer to return the value of
  // `item_count` when its available.
  (sharedEntity, assetIds) => sharedEntity.item_count || assetIds.length
);
