import { FOLDER } from 'actions/folders';

// todo(marvin): this will be replaced by generic "assets"

export const currentFolder = (state = {}, action) => {
  switch (action.type) {
    case FOLDER.SET_CURRENT: {
      const { folderId } = action.payload;
      return { ...state, id: folderId, lastSet: Date.now() };
    }
    default:
      return state;
  }
};

export const folderAncestors = (state = {}, action) => {
  switch (action.type) {
    case FOLDER.SET_ANCESTORS: {
      const { folderId, ancestorIds } = action.payload;
      return {
        ...state,
        [folderId]: ancestorIds,
      };
    }
    default:
      return state;
  }
};
