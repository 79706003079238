/**
 * Formats dollars into a string with commas
 * @param {Number} value - a dollar value
 * @param {Object} options - optional formatting options
 * @param {Boolean} options.decimal - whether to include two decimal points
 */
export const formatMoney = (value, options = {}) => {
  const { decimal } = options;
  const opts = {};
  if (decimal) {
    opts.minimumFractionDigits = 2;
    opts.maximumFractionDigits = 2;
  }
  return `$${value.toLocaleString('en', opts)}`;
};

export const formatCents = (cents) =>
  formatMoney(cents / 100, { decimal: true });
