import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Flex from 'styled-flex-component';
import { Link } from 'react-router-dom';
import { rgba, ellipsis } from 'polished';
import { trackButtonClick } from 'analytics';

import { Avatar } from '@frameio/components';
import sizes from '@frameio/components/src/utils/sizes';
import { LOGOUT_URL, SUPPORT_URL, STATUS_URL } from 'URLs';
import OpenDevTools from 'components/DevelopmentTools/OpenDevTools';
import AccountSwitcherDropdown from 'pages/LayoutWithNav/AccountSwitcher/AccountSwitcherDropdown';
import { openLegalModal } from 'components/LegalDialog/actions';

import strings, { ACCOUNT_NAVIGATION_VIEW } from './AccountNavigationStrings';

const withSeparator = css`
  border-bottom: 1px solid ${({ theme }) => rgba(theme.color.graphiteGray, 0.2)};
`;

const Nav = styled.nav`
  color: ${(p) => p.theme.color.coldWhite};
  text-align: left;
`;

const UserDetails = styled(Flex)`
  margin-left: ${(p) => p.theme.spacing.small};

  h3 {
    font-size: 16px;
    ${ellipsis()}
  }
`;

const User = styled(Flex)`
  padding: ${({ theme }) => `calc(${theme.spacing.tiny} * 2)`};
  ${withSeparator};

  ${UserDetails} {
    /*
      Re: min-width, see https://github.com/philipwalton/flexbugs#flexbug-1:
      By default, flex items won’t shrink below their minimum content size,
      which is why ‘UserDetails‘ text grows outside of its flex parent.
      Setting a min-width ensures that UserDetails will shrink, proportionally,
      according to its flex-shrink property.
    */
    min-width: 0;
  }
`;

const Email = styled.div`
  padding-top: ${(p) => p.theme.spacing.micro};
  color: ${(p) => p.theme.color.graphiteGray};
  font-size: ${(p) => p.theme.fontSize[1]};
  ${ellipsis()}
`;

const List = styled.ul`
  ${withSeparator};
  padding: ${({ theme: { spacing } }) => `${spacing.small} ${spacing.medium}`};
  list-style-type: none;
  font-size: ${(p) => p.theme.fontSize[2]};

  a,
  button {
    color: inherit;
    padding: 0;
  }

  li {
    padding: ${(p) => p.theme.spacing.small} 0;
  }
`;

function AccountNavigationMobile({
  currentAccountId,
  currentUser,
  isShowingDevTools,
}) {
  const { image_128, name, email, user_default_color } = currentUser;
  const dispatch = useDispatch();

  const handleOpenLegalModal = () => {
    dispatch(openLegalModal());
  };

  return (
    <Nav>
      <User justifyStart alignCenter>
        <Avatar
          image={image_128}
          name={name}
          color={user_default_color}
          size={sizes.XL}
        />
        <UserDetails column>
          <h3>{name}</h3>
          <Email>{email}</Email>
        </UserDetails>
      </User>
      <AccountSwitcherDropdown currentAccountId={currentAccountId} />
      <List>
        <li>
          <a
            href={STATUS_URL}
            onClick={() => {
              trackButtonClick(
                'status updates',
                ACCOUNT_NAVIGATION_VIEW,
                'top'
              );
            }}
          >
            {strings.STATUS_UPDATES}
          </a>
        </li>
        <li>
          <a
            href={SUPPORT_URL}
            onClick={() => {
              trackButtonClick('support faq', ACCOUNT_NAVIGATION_VIEW, 'top');
            }}
          >
            {strings.SUPPORT_FAQ}
          </a>
        </li>
        <li>
          <a onClick={handleOpenLegalModal}>Legal notices</a>
        </li>
        {isShowingDevTools && (
          <li>
            <OpenDevTools />
          </li>
        )}
        <li>
          <Link to={LOGOUT_URL}>{strings.LOG_OUT}</Link>
        </li>
      </List>
    </Nav>
  );
}

AccountNavigationMobile.defaultProps = {
  currentAccountId: null,
  currentUser: { name: '', email: '', image_128: '', user_default_color: '' },
  isShowingDevTools: false,
};

AccountNavigationMobile.propTypes = {
  currentAccountId: PropTypes.string,
  currentUser: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    image_128: PropTypes.string,
    user_default_color: PropTypes.string,
  }).isRequired,
  isShowingDevTools: PropTypes.bool,
};

export default AccountNavigationMobile;
