import { takeLatest, call, put } from 'redux-saga/effects';
import { deleteComment } from '@frameio/core/src/comments/actions';
import { confirmDelete } from 'components/Dialog/SimpleDialog/sagas';
import { COMMENT } from 'actions/comments';

export function* confirmDeleteComment({ payload }) {
  const { id, onDeleteCallback, options } = payload;
  const header = 'Delete this comment?';
  const message = 'Are you sure you want to delete this comment?';

  const shouldDelete = yield call(confirmDelete, header, message);
  if (shouldDelete) {
    if (onDeleteCallback) onDeleteCallback();
    yield put(deleteComment(id, options));
  }
}

export default [takeLatest(COMMENT.CONFIRM_DELETE, confirmDeleteComment)];
