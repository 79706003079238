import Cookies from 'js-cookie';
import React from 'react';
import PropTypes from 'prop-types';
import {
  BETTER_ACCOUNT_SETTINGS_PAGE_URL,
  SETTINGS_PAGE_URL as LEGACY_URL_MAP,
  V2_SETTINGS_PAGE_URL,
} from 'URLs';
import { mergeQueryParams } from 'utils/router';
import OPTS from 'utils/betterUserMgmtCookieOptions';
import config from 'config';

const PERSONAL_SETTINGS_URLS = [
  BETTER_ACCOUNT_SETTINGS_PAGE_URL.MANAGE_PROFILE,
  BETTER_ACCOUNT_SETTINGS_PAGE_URL.MANAGE_NOTIFICATIONS,
  LEGACY_URL_MAP.ACCOUNT,
  LEGACY_URL_MAP.PROFILE,
  LEGACY_URL_MAP.NOTIFICATION,
  LEGACY_URL_MAP.APPS_INTEGRATIONS,
];

// Map of pageKey to Url
const URL_MAP = {
  ...LEGACY_URL_MAP,
  ...V2_SETTINGS_PAGE_URL,
  ...BETTER_ACCOUNT_SETTINGS_PAGE_URL,
};

// This is a pageKey role map for Better User Management (non-collaborators)
const ROLE_PAGES_MAPPING = {
  admin: [
    'MANAGE_USERS',
    'MANAGE_TEAMS',
    'MANAGE_PROJECTS',
    'BILLING',
    'MANAGE_TEAM_DIRECTORY',
  ],
  member: ['MANAGE_USERS', 'MANAGE_TEAM_DIRECTORY'],
  owner: [
    'MANAGE_USERS',
    'MANAGE_TEAMS',
    'MANAGE_PROJECTS',
    'BILLING',
    'MANAGE_TEAM_DIRECTORY',
  ],
  team_manager: [
    'MANAGE_USERS',
    'MANAGE_TEAMS',
    'MANAGE_PROJECTS',
    'BRANDING',
    'MANAGE_TEAM_DIRECTORY',
  ],
};

export default function SettingsLink({
  isCollaboratorOnly,
  isOnLegacyPlan,
  accountId,
  currentUserAccountId,
  // dispatch is not used--it's here just to prevent rest from passing it down to the component.
  // eslint-disable-next-line react/prop-types
  dispatch,
  pageKey,
  role,
  viewSettingsApp,
  asComponent: Component,
  ...rest
}) {
  const isBilling = pageKey === 'BILLING';
  const isReviewer = role === 'reviewer';

  const isBetterAccountSettingsPage = Boolean(
    BETTER_ACCOUNT_SETTINGS_PAGE_URL[pageKey]
  );

  const isBetterAccountSettingsPersonalPage =
    pageKey === 'MANAGE_PROFILE' || pageKey === 'MANAGE_NOTIFICATIONS';

  const shouldUseLegacyUrls =
    isOnLegacyPlan && !isBilling && !isBetterAccountSettingsPage;
  const url = (shouldUseLegacyUrls ? LEGACY_URL_MAP : URL_MAP)[pageKey];

  // TODO: these are really permissions.
  // Collaborators and Reviewers only see Personal Links
  if (
    (isCollaboratorOnly || isReviewer) &&
    !PERSONAL_SETTINGS_URLS.includes(url)
  ) {
    return null;
  }

  // Non-Collaborators in better user management see links according to role mapping
  const rolePages = ROLE_PAGES_MAPPING[role];

  if (
    viewSettingsApp &&
    !isBetterAccountSettingsPersonalPage &&
    !rolePages.includes(pageKey)
  ) {
    return null;
  }

  // This function is used if betterUserManagement feature flag is enabled:
  // New account settings app uses cookies for tracking the current account id instead of query params.
  const handleClick = () => {
    Cookies.set(config.accountIdKey, accountId, OPTS);
  };

  // No need to set query params for new account settings' urls
  const href = isBetterAccountSettingsPage
    ? url
    : mergeQueryParams(url, { accountId });
  // Support both anchors and Buttons with an as="a" prop
  const isComponentAnchor = Component === 'a' || rest.as === 'a';
  let props = isComponentAnchor ? { href } : { 'data-href': href };

  // For better account settings pages, they will need a handleClick fn to set the cookie
  if (isBetterAccountSettingsPage) {
    props = { ...props, handleClick };
  }

  return typeof rest.children === 'function' ? (
    rest.children(props)
  ) : (
    <Component {...rest} {...props} />
  );
}

SettingsLink.propTypes = {
  asComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  pageKey: PropTypes.oneOf(Object.keys(URL_MAP)),
  isCollaboratorOnly: PropTypes.bool.isRequired,
  isOnLegacyPlan: PropTypes.bool.isRequired,
  accountId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};
SettingsLink.defaultProps = {
  asComponent: 'a',
  pageKey: 'BILLING',
};

export const testExports = {
  URL_MAP,
  PERSONAL_SETTINGS_URLS,
};
