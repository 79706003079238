import { round } from 'lodash';

export const kilo = 1e3;
export const mega = 1e6;
export const giga = 1e9;
export const tera = 1e12;
export const peta = 1e15;

export const PREFIX_UNIT_MAP = {
  P: peta,
  T: tera,
  G: giga,
  M: mega,
  k: kilo,
  '': 1,
};

export function parseSIPrefix(value, precision = 0) {
  let unit;
  const prefix = Object.keys(PREFIX_UNIT_MAP).find((currentPrefix) => {
    unit = PREFIX_UNIT_MAP[currentPrefix];
    return value / unit >= 1;
  });
  return {
    prefix: prefix || '',
    value: unit ? round(value / unit, precision) : value,
  };
}

export function formatBits(value) {
  const { prefix, value: prefixedValue } = parseSIPrefix(value);
  return `${prefixedValue} ${prefix}b`;
}

export function bytesByValueAndSize(bytes) {
  const { prefix, value } = parseSIPrefix(bytes, 2);
  return { value, size: `${prefix}B` };
}

export function formatBytes(bytes) {
  const { value, size } = bytesByValueAndSize(bytes);
  return `${value} ${size}`;
}

// TODO(marvin): these should be more generic than putting the SI unit in the
// function names, like `format(value, 'M')`.
export function bytesToMB(value) {
  return round(value / mega, 2);
}

export const testExports = { parseSIPrefix };
