const SIDEBAR_OFFSET = 'panel_sizing_'; // session storage

export const getPersistedPanelWidth = ({ side }) => {
  let width;
  try {
    width = localStorage.getItem(`${SIDEBAR_OFFSET}_${side}`);
  } catch (e) {
    // do nothing
  }

  return width ? JSON.parse(width).offset : null;
};

export const savePersistedPanelWidth = ({ side, offset }) => {
  try {
    const valildatedOffsetValue = isNaN(offset) ? 0 : offset;

    localStorage.setItem(
      `${SIDEBAR_OFFSET}_${side}`,
      JSON.stringify({ offset: valildatedOffsetValue })
    );
  } catch (e) {
    // do nothing
  }
};
