import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TreeView from '@frameio/components/src/styled-components/TreeView';
import ConnectedTreeProjectItem from './ConnectedTreeProjectItem';
import { RESULTS_HEIGHT } from './constants';
import SearchingSpinner from './SearchingSpinner';
import HighlightedName from './HighlightedName';

const ScrollContainer = styled.div`
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  height: ${RESULTS_HEIGHT}px;
  position: relative;
  padding: ${(p) => `0 ${p.theme.spacing.units(2)}`};
`;

const LoadingContainer = styled.div`
  height: 56px;
  margin-left: ${(p) => p.theme.spacing.units(2)};
  display: flex;
  align-items: center;
`;

/**
 * Render search results for projects
 */

function ProjectSearchResults({
  hasNextPage,
  isLoading,
  query,
  items,
  onSelect,
  onScroll,
  loadNextPage,
  onDoubleClick,
  showDetails = true,
  enablePagination = true, // We also want to support disabling pagination
  onExpand,
  expanded,
  selectedId,
  scrollRef,
}) {
  /**
   * Potentially load more results when
   * scrolling to the bottom
   */
  const handleScroll = useCallback(
    (e) => {
      // for now, let's cap our max items at 200. We don't use windowing
      // for the TreeView so perf could become an issue once we load
      // any more than that.
      const hasScrolledToBottom =
        e.target.scrollTop + RESULTS_HEIGHT === e.target.scrollHeight;
      const shouldLoadNextPage =
        enablePagination &&
        hasNextPage &&
        !isLoading &&
        items.length < 200 &&
        loadNextPage;
      if (shouldLoadNextPage && hasScrolledToBottom) {
        loadNextPage();
      }

      onScroll(e);
    },
    [hasNextPage, isLoading, items, enablePagination, loadNextPage, onScroll]
  );

  return (
    <ScrollContainer ref={scrollRef} onScroll={handleScroll}>
      <TreeView
        onExpand={onExpand}
        expanded={expanded}
        selected={selectedId}
        onDoubleClick={onDoubleClick}
        onSelect={onSelect}
      >
        {items.map((project) => {
          return (
            <ConnectedTreeProjectItem
              nodeId={project.root_asset_id}
              key={project.id}
              project={project}
              showDetails={showDetails}
              label={<HighlightedName query={query} name={project.name} />}
            />
          );
        })}
      </TreeView>

      {isLoading && (
        <LoadingContainer>
          <SearchingSpinner label="Loading" />
        </LoadingContainer>
      )}
    </ScrollContainer>
  );
}

ProjectSearchResults.propTypes = {
  hasNextPage: PropTypes.bool,
  isLoading: PropTypes.bool,
  query: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
  loadNextPage: PropTypes.func,
  onDoubleClick: PropTypes.func.isRequired,
  showDetails: PropTypes.bool,
  enablePagination: PropTypes.bool,
};

export default ProjectSearchResults;
