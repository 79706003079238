import {
  put,
  takeEvery,
  takeLatest,
  call,
  spawn,
  race,
  take,
  select,
} from 'redux-saga/effects';
import { find } from 'lodash';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import { updateAccount } from '@frameio/core/src/accounts/sagas';
import { updateTeam as updateTeamSaga } from '@frameio/core/src/teams/sagas';
import { getEntityFromNormalizedResponse } from '@frameio/core/src/shared/utils/entities';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import { dialogTypes } from '@frameio/components/src/styled-components/Dialog';
import { teamsByCurrentUserAsAdminSelector } from 'selectors/teams';
import { selectFilesFromInput } from 'sagas/shared';

import track from 'analytics';
import {
  openSelectPlanFlowModal,
  SELECT_PLAN_FLOW,
} from 'components/SelectPlanFlow/actions';
import { prompt } from 'components/Dialog/SimpleDialog/sagas';

import { selectAndUploadImage } from './ImageWithUpload/sagas';
import { BRANDING_CONTAINER } from './actions';

function* uploadAccountLogo(accountId) {
  const [file] = yield call(selectFilesFromInput);
  yield put({
    type: BRANDING_CONTAINER.UPLOAD_ACCOUNT_LOGO.PENDING,
    payload: {},
  });

  const { failure, success } = yield call(updateAccount, accountId, {
    image: {
      type: file.type,
      uploaded: true,
    },
  });

  if (failure) {
    yield put(
      showErrorToast({
        header: 'Image failed to upload',
      })
    );
    yield put({
      type: BRANDING_CONTAINER.UPLOAD_ACCOUNT_LOGO.FAILURE,
      payload: {},
    });
  }

  const account = yield call(
    getEntityFromNormalizedResponse,
    success.payload.response
  );
  const uploadUrl = account.upload_url;
  const newImageUrl = account.image_64;
  const uploadSuccessful = yield call(
    selectAndUploadImage,
    uploadUrl,
    file,
    newImageUrl
  );
  yield put({
    type: uploadSuccessful
      ? BRANDING_CONTAINER.UPLOAD_ACCOUNT_LOGO.SUCCESS
      : BRANDING_CONTAINER.UPLOAD_ACCOUNT_LOGO.FAILURE,
    payload: {},
  });
}

function* uploadTeamLogo(teamId) {
  const [file] = yield call(selectFilesFromInput);
  yield put({ type: BRANDING_CONTAINER.UPLOAD_TEAM_LOGO.PENDING, payload: {} });

  const { failure, success } = yield call(updateTeamSaga, teamId, {
    image: {
      type: file.type,
      uploaded: true,
    },
  });

  if (failure) {
    yield put(
      showErrorToast({
        header: 'Image failed to upload',
      })
    );
    yield put({
      type: BRANDING_CONTAINER.UPLOAD_TEAM_LOGO.FAILURE,
      payload: {},
    });
    return;
  }

  const team = yield call(
    getEntityFromNormalizedResponse,
    success.payload.response
  );
  const uploadUrl = team.upload_url;
  const newImageUrl = team.image_64;
  const uploadSuccessful = yield call(
    selectAndUploadImage,
    uploadUrl,
    file,
    newImageUrl
  );
  yield put({
    type: uploadSuccessful
      ? BRANDING_CONTAINER.UPLOAD_TEAM_LOGO.SUCCESS
      : BRANDING_CONTAINER.UPLOAD_TEAM_LOGO.FAILURE,
    payload: {},
  });
}

function* uploadEmailBranding(teamId) {
  const [file] = yield call(selectFilesFromInput);
  yield put({
    type: BRANDING_CONTAINER.UPLOAD_EMAIL_BRANDING.PENDING,
    payload: {},
  });

  const { failure, success } = yield call(updateTeamSaga, teamId, {
    email_branding: {
      image: {
        type: file.type,
        uploaded: true,
      },
    },
  });

  if (failure) {
    yield put(
      showErrorToast({
        header: 'Image failed to upload',
      })
    );
    yield put({
      type: BRANDING_CONTAINER.UPLOAD_EMAIL_BRANDING.FAILURE,
      payload: {},
    });
    return;
  }

  const team = yield call(
    getEntityFromNormalizedResponse,
    success.payload.response
  );
  const uploadUrl = team.email_branding.upload_url;
  const newImageUrl = team.email_branding.image;
  const uploadSuccessful = yield call(
    selectAndUploadImage,
    uploadUrl,
    file,
    newImageUrl
  );
  yield put({
    type: uploadSuccessful
      ? BRANDING_CONTAINER.UPLOAD_EMAIL_BRANDING.SUCCESS
      : BRANDING_CONTAINER.UPLOAD_EMAIL_BRANDING.FAILURE,
    payload: {},
  });
}

function* removeAccountLogo(entityId) {
  const { failure } = yield call(updateAccount, entityId, {
    image: {
      uploaded: false,
    },
  });
  if (failure) {
    yield put(
      showErrorToast({
        header: 'Image failed to delete',
      })
    );
    return;
  }

  yield put(
    showSuccessToast({
      header: 'Image successfully deleted',
    })
  );
}

function* removeEmailBranding(entityId) {
  const { failure } = yield call(updateTeamSaga, entityId, {
    email_branding: {
      image: {
        uploaded: false,
      },
    },
  });

  if (failure) {
    yield put(
      showErrorToast({
        header: 'Image failed to delete',
      })
    );
    return;
  }

  yield put(
    showSuccessToast({
      header: 'Image successfully deleted',
    })
  );
}

function* removeTeamLogo(entityId) {
  const { failure } = yield call(updateTeamSaga, entityId, {
    image: {
      uploaded: false,
    },
  });

  if (failure) {
    yield put(
      showErrorToast({
        header: 'Image failed to delete',
      })
    );
    return;
  }

  yield put(
    showSuccessToast({
      header: 'Image successfully deleted',
    })
  );
}

function* upgradePlan(accountId, trackingProps) {
  yield put(openSelectPlanFlowModal(accountId, trackingProps));

  const { success, cancelled } = yield race({
    success: take(SELECT_PLAN_FLOW.REPORT_PLAN_CHANGE_SUCCESS),
    cancelled: take(SELECT_PLAN_FLOW.END),
  });

  if (cancelled) return;

  if (success) {
    const { feature } = trackingProps;
    yield spawn(track, 'feature-unlocked', { feature });
  }
}

function* promptRenameAccount(accountId) {
  const account = yield select(accountEntitySelector, { accountId });
  if (!account) return;

  const name = yield call(
    prompt,
    'Edit account name',
    (input) => input,
    account.display_name || '',
    { type: dialogTypes.NONE, primaryText: 'Save' }
  );

  if (name) {
    const { failure } = yield call(updateAccount, accountId, {
      // Trim leading spaces because we don't want 'em.
      display_name: name.trim(),
    });

    if (failure) {
      yield put(showErrorToast({ header: 'Updating account name failed' }));
    }
  }
}

function* promptRenameTeam(teamId) {
  const teams = yield select(teamsByCurrentUserAsAdminSelector);
  const selectedTeam = find(teams, { id: teamId });
  if (!selectedTeam) {
    return;
  }

  const name = yield call(
    prompt,
    'Edit team name',
    (input) => input,
    selectedTeam.name,
    { type: dialogTypes.NONE, primaryText: 'Save' }
  );

  if (name) {
    const { failure } = yield call(updateTeamSaga, teamId, { name });
    if (failure) {
      yield put(showErrorToast({ header: 'Updating team name failed' }));
    }
  }
}

/**
 * @param {string} teamId - Team id.
 * @param {Object} data - Data to update a team with.
 */
function* updateTeam(teamId, data) {
  const { failure } = yield call(updateTeamSaga, teamId, data);

  if (failure) {
    yield put(showErrorToast({ header: 'Updating team failed' }));
  }
}

export const testExports = {
  promptRenameAccount,
  promptRenameTeam,
  upgradePlan,
  updateTeam,
};

export default [
  takeLatest(
    BRANDING_CONTAINER.PROMPT_RENAME_ACCOUNT,
    ({ payload: { accountId } }) => promptRenameAccount(accountId)
  ),
  takeLatest(
    BRANDING_CONTAINER.UPGRADE_PLAN,
    ({ payload: { accountId, trackingProps } }) =>
      upgradePlan(accountId, trackingProps)
  ),
  takeLatest(BRANDING_CONTAINER.PROMPT_RENAME_TEAM, ({ payload: { teamId } }) =>
    promptRenameTeam(teamId)
  ),
  takeEvery(
    BRANDING_CONTAINER.REMOVE_ACCOUNT_LOGO.BASE,
    ({ payload: { accountId } }) => removeAccountLogo(accountId)
  ),
  takeEvery(
    BRANDING_CONTAINER.REMOVE_EMAIL_BRANDING.BASE,
    ({ payload: { teamId } }) => removeEmailBranding(teamId)
  ),
  takeEvery(
    BRANDING_CONTAINER.REMOVE_TEAM_LOGO.BASE,
    ({ payload: { teamId } }) => removeTeamLogo(teamId)
  ),
  takeEvery(
    BRANDING_CONTAINER.UPLOAD_ACCOUNT_LOGO.BASE,
    ({ payload: { accountId } }) => uploadAccountLogo(accountId)
  ),
  takeEvery(
    BRANDING_CONTAINER.UPLOAD_EMAIL_BRANDING.BASE,
    ({ payload: { teamId } }) => uploadEmailBranding(teamId)
  ),
  takeEvery(
    BRANDING_CONTAINER.UPLOAD_TEAM_LOGO.BASE,
    ({ payload: { teamId } }) => uploadTeamLogo(teamId)
  ),
  takeEvery(BRANDING_CONTAINER.UPDATE_TEAM, ({ payload: { teamId, data } }) =>
    updateTeam(teamId, data)
  ),
];
