import { connect } from 'react-redux';
import { teamEntitySelector } from '@frameio/core/src/teams/selectors';
import UploadImageStep from '../UploadImageStep';
import {
  continueFlow,
  removeTeamImage,
  uploadDroppedTeamImage,
  uploadSelectedTeamImage,
} from '../actions';
import { isUploadingTeamImageSelector } from '../selectors';

export const UPLOAD_TEAM_IMAGE_STEP = 'upload-team-image-step';

function mapStateToProps(state, { teamId }) {
  const team = teamEntitySelector(state, { teamId });
  const isUploading = isUploadingTeamImageSelector(state);

  return {
    entityId: teamId,
    header: 'Add your team logo',
    isUploading,
    imageUrl: team.image_128,
    subheader: 'Help your team stand out!',
    trackingPageName: UPLOAD_TEAM_IMAGE_STEP,
  };
}

const mapDispatchToProps = {
  onContinue: continueFlow,
  removeImage: removeTeamImage,
  uploadDroppedItem: uploadDroppedTeamImage,
  uploadSelectedItem: uploadSelectedTeamImage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadImageStep);
