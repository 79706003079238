import { useEffect, useState } from 'react';
import { times } from 'lodash';

const START = 0;
const END = 1;

/**
 * Casts Video Element TimeRange ADT to nested arrays for easier use.
 * @param  {Array}  [timeRange] - Video TimeRange intance.
 * @returns {Array} - Nested arrays of time ranges e.g. [[0, 0.4], [1.2, 1.4]].
 */
const castTimeRangeToArray = (timeRange = []) =>
  times(timeRange.length, Number).map((idx) => [
    timeRange.start(idx),
    timeRange.end(idx),
  ]);

/**
 * useMediaBuffer hook listens for a media elements buffer changes, and provides
 * the amount buffered at any time. This is useful for keeping data load updates
 * local to the buffer bar, and not causing rerenders elsewhere in player.
 *
 * @param {HTMLMediaElement} mediaEl - media element
 */

const useMediaBuffer = (mediaEl) => {
  const [amountBuffered, setAmountBuffered] = useState(0);

  const getBufferRangeForCurrentTime = (el) => {
    // Added 0.1 to currentTime below because bufferRange is inaccurate while video is paused.
    return (
      castTimeRangeToArray(el.buffered).find(
        (bufferRange) =>
          el.currentTime + 0.1 >= bufferRange[START] &&
          el.currentTime <= bufferRange[END]
      ) || []
    );
  };

  const getAmountBuffered = (el) => {
    const rangeOfCurrentTime = getBufferRangeForCurrentTime(el);
    return el.duration ? rangeOfCurrentTime[END] / el.duration || 0 : 0;
  };

  const onLoadedData = (evt = {}) => {
    setAmountBuffered(getAmountBuffered(evt.target));
  };

  const mediaId = mediaEl && mediaEl.id;

  useEffect(() => {
    if (!mediaEl) return;
    mediaEl.addEventListener('progress', onLoadedData);
    // eslint-disable-next-line consistent-return
    return () => mediaEl.removeEventListener('progress', onLoadedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaEl]);

  useEffect(() => {
    setAmountBuffered(0);
  }, [mediaId]);

  return amountBuffered;
};

export default useMediaBuffer;
