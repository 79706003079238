export default {
  ACCOUNT_DROPDOWN: 'account_dropdown',
  ACCOUNT_DROPDOWN_SECONDARY: 'account_dropdown_secondary',
  PROJECT_HEADER: 'project_header',
  C2C_TAB: 'c2c_tab',
};
export const CALL_TO_ACTION_TYPE = {
  setupAccount: 'setup-account',
  planUpgrade: 'plan-upgrade',
};
