import { trackButtonClick } from 'analytics';

export const event = 'button-clicked';
export const page = 'add_people_modal';
export const position = 'middle';
export const title = 'project';

export const trackClick = (eventName) =>
  trackButtonClick(eventName, page, position);

export const testButtonClickTracking = (eventName) => {
  it(`should track event "${eventName}"`, () => {
    expect(trackButtonClick).toHaveBeenCalledWith(eventName, page, position);
    trackButtonClick.mockClear();
  });
};
