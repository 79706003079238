import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

const LIST_TEAM_GROUP = {
  ...generateActionTypes('LIST_TEAM_GROUP', ['FETCH_PROJECTS']),
};

export default LIST_TEAM_GROUP;

export const fetchProjects = createAction(
  LIST_TEAM_GROUP.FETCH_PROJECTS,
  (teamId) => ({
    teamId,
  })
);
