// Initialize any shared behavior
import 'initializers';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'connected-react-router';
import { LastLocationProvider } from 'react-router-last-location';
import history from 'browserHistory';
import * as theme from '@frameio/components/src/theme/darkTheme';
import PopoverPortalRoot from '@frameio/components/src/components/Popover/PopoverPortalRoot';
import ModalRoot from 'components/Modal';
import ContextMenuManager from 'components/ContextMenuManager';
import ConnectedSimpleDialog from 'components/Dialog/SimpleDialog';
import ConnectedCustomActionFormDialog from 'components/Dialog/CustomActionFormDialog';
import DragPreview from 'components/DragPreview';
import DialogPortalRoot from 'components/Dialog/DialogPortalRoot';
import ErrorBoundary from 'components/ErrorBoundary';
import ToastPortal from 'components/ToastPortal';
import QuicklookModal from 'components/Quicklook';
import { openDevToolsModal } from 'components/DevelopmentTools/actions';
import store from 'configureStore';
import Application from 'components/Application';
import Analytics from 'components/Analytics';
import { SegmentContextProvider } from 'shared/context';
import AdobeIntegrationConnectDialog from 'components/Dialog/AdobeIntegrationConnectDialog/';
import ConnectedAppRoutes from './ConnectedAppRoutes';
import 'application.scss';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Application>
        <ConnectedRouter history={history}>
          <LastLocationProvider>
            <SegmentContextProvider>
              {/*
                Enforces that the App will always be below modals, popovers, etc..., even
                if items within the App are crazy high z-indices.
                BUG: https://frame-io.atlassian.net/browse/COLLAB-119
              */}
              <div className="z-0 absolute absolute--fill">
                <ConnectedAppRoutes />
              </div>
              <QuicklookModal />
              <ModalRoot />
              <PopoverPortalRoot />
              <ContextMenuManager />
              <ErrorBoundary fallback={null}>
                <DragPreview />
              </ErrorBoundary>
              <ToastPortal />
              <DialogPortalRoot />
              <ConnectedSimpleDialog />
              <ConnectedCustomActionFormDialog />
              <AdobeIntegrationConnectDialog />
              <ErrorBoundary fallback={null}>
                <Analytics />
              </ErrorBoundary>
            </SegmentContextProvider>
          </LastLocationProvider>
        </ConnectedRouter>
      </Application>
    </ThemeProvider>
  </Provider>,
  document.getElementById('body-wrapper')
);

// Enable `ctrl + cmd + t` to trigger the DevTools modal.
//
// Using `onKeyDown` on the React DOM tree doesn't trigger the modal reliably,
// so attaching directly to window instead.
//
// TODO(marvin): figure out what's stopping event propagation.
window.addEventListener('keydown', (event) => {
  if (event.key === 't' && event.metaKey && event.ctrlKey) {
    store.dispatch(openDevToolsModal());
  }
});
