import React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import PropTypes from 'prop-types';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getFavicon, pageTitle, FAVICON_KEY } from 'utils/page';
import AdBlockDetector from 'components/AdBlockDetector';

// Base Application component that can be overriden (using `react-helmet`)
const DESCRIPTION =
  'Privately upload, review, and share video with your entire team, anywhere in the world.';

export default function Application({ children }) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{pageTitle()}</title>
        {/* Note: we set the `helmetKey` so that these can be dynamically overriden */}
        <link
          rel="icon"
          type="image/png"
          href={getFavicon()}
          helmetKey={FAVICON_KEY}
        />
        <meta name="description" content={DESCRIPTION} />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      </Helmet>
      <React.Fragment>
        <div id="notification-banner-popover-point" />
        <div id="ruler" />
        <span id="width-ruler" />
        <div id="thumb-ruler" />
        <div id="image-ruler" />
        <AdBlockDetector />

        <DndProvider
          backend={
            // HACK: we rely on a Modernizr bug in order to provide touch
            // support only in specific circumstances, and so in order to avoid
            // unwanted customer changes we must use Modernizr < v3.7.1 so that
            // this bugfix isn't included:
            // https://github.com/Modernizr/Modernizr/pull/2409
            Modernizr.touchevents ? TouchBackend : HTML5Backend
          }
        >
          {children}
        </DndProvider>
      </React.Fragment>
    </HelmetProvider>
  );
}

Application.propTypes = {
  children: PropTypes.node.isRequired,
};
