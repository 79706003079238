import React from 'react';
import PropTypes from 'prop-types';
import { MenuButton } from '@frameio/vapor';
import CopySVG from '@frameio/components/src/svgs/icons/link.svg';

const COPY_LINK_ADDRESS_TEXT = 'Copy link address';

// TODO(Charlton): abstract into copy-to-clipboard saga
export const copyToClipboardHelper = (text) => {
  // To copy, you have to use an actual input element.
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

/**
 * An action to copy the destination link address of an asset.
 * This mimics the native browser right+click > Copy Link Address action
 * @param {Object} props
 * @param {string} linkAddress - The address that will be copied
 * @returns {Component}
 */
export default function CopyLinkAddress({
  linkAddress,
  showSuccessToast,
  copyToClipboard = copyToClipboardHelper,
  ...rest
}) {
  // Copies linkAddress to the clipboard
  function onCopyToClipboard() {
    copyToClipboard(linkAddress);
    showSuccessToast({
      header: 'Copied!',
    });
  }

  return (
    <MenuButton
      onSelect={onCopyToClipboard}
      iconBefore={<CopySVG width={16} height={16} />}
      {...rest}
    >
      {COPY_LINK_ADDRESS_TEXT}
    </MenuButton>
  );
}

CopyLinkAddress.propTypes = {
  linkAddress: PropTypes.string.isRequired,
};
