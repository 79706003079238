/* eslint-disable no-shadow */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
/**
 * If you're trying to add an <InfiniteGrid /> to your layout and you find that its `height`
 * property is growing forever, check to make sure you've wrapped it in a DOM element with
 * fixed dimensions, e.g., <div style={{ height: '100%', width: '100%' }} />. This is needed
 * to properly measure the dimensons of the virtual list that `InfiniteGrid` renders.
 */
import React from 'react';
import Media from 'react-media';
import {
  isFinite,
  noop,
  debounce,
  merge,
  range,
  union,
  without,
  isEqual,
  sumBy,
  identity,
} from 'lodash';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { shouldComponentUpdate, shallowReflectiveEqual } from 'reflective-bind';
import { FixedSizeGrid as Grid, areEqual } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import withScrolling from '@frameio/react-dnd-scrollzone';
import styled, { css } from 'styled-components';

import { Measured } from '@frameio/components';
import ShadowContainer from '@frameio/components/src/styled-components/ShadowContainer';
import { alignCenter, buttonReset } from '@frameio/components/src/mixins';
import { CARD_CHIN_HEIGHT } from '@frameio/components/src/styled-components/Card/CardChin';
import LoadingCard from '@frameio/components/src/styled-components/LoadingCard';
import ScrollContainer from '@frameio/components/src/styled-components/ScrollContainer';

import { MEDIUM_UP } from 'utils/mediaQueries';
import { isPC } from 'utils/devices';
import LoadingRow from 'components/LoadingRow';
import VIEW_TYPES, { propType as viewTypePropType } from './viewTypes';
import ListCell from './ListCell';
import ListHeader from './ListHeader';

const MIN_COLUMN_COUNT = 3;
// Since the grid renders each GridCell contiguously, we add padding to each
// GridCell to create the gutter.
export const CELL_SPACING = 8;
// The gutter size is the distance between 2 cards.
export const GUTTER_SIZE = CELL_SPACING * 2;
// The margin around the whole grid
const MARGIN = GUTTER_SIZE;
// For GridCells on the boundary, we want them to have 16px margin to their
// corresponding edges, so we add, in addition to the CELL_SPACING on each side,
// some additional BOUNDARY_PADDING.
const BOUNDARY_PADDING = MARGIN - CELL_SPACING;
const THUMB_ASPECT_RATIO = 16 / 9;
const MIN_CARD_WIDTH = 210;
const MIN_CELL_WIDTH = MIN_CARD_WIDTH + GUTTER_SIZE;
// CORE-1638/CORE-1583: On safari we draw a special scrollbar, which screws with
// the width calculation, causing a horizontal scrollbar. Since overflow: auto
// is set internally by FixedSizeGrid, we can override it by passing this
// overflow-x into the style.
const GRID_STYLE_DEFAULT = { overflowX: 'hidden' };
// All Rows Visible should not have a scrollbar at this level.
// Instead it is managed by a parent further up. This styling override
// is a result of Safari behavior not always measuring the correct height
// at this level when makeAllRowsVisible is set
const GRID_STYLE_ALL_ROWS = {
  overflow: 'hidden',
  width: '100%',
  height: `100%`,
};

const createItemData = memoize(
  (
    children,
    columnCount,
    isSmallBreakpoint,
    itemIds,
    listColumnWidths,
    rowCount,
    thumbHeight,
    thumbWidth
  ) => ({
    children,
    columnCount,
    isSmallBreakpoint,
    itemIds,
    listColumnWidths,
    rowCount,
    thumbHeight,
    thumbWidth,
  })
);

const withRowSeparator = css`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    width: calc(100% - (2 * ${MARGIN}px));
    left: ${MARGIN}px;
    background-color: ${(p) => p.theme.color.coolBlack};
  }
`;

export const ListHeaderCell = styled(ListHeader).attrs(() => ({
  role: 'columnheader',
}))`
  /* Instead of setting horizontal margins to be CELL_SPACING, we add it to the
  * padding so that the header cells are contiguous, and thus if we need to make
  * body cells span multiple columns, it's simply a sum of those measured widths.
  */
  padding: ${`${CELL_SPACING}px`};
`;

const ListHeaderRow = styled.div.attrs(() => ({
  role: 'row',
}))`
  ${withRowSeparator}
  display: flex;
  width: 100%;
`;

function getColumnCount(width) {
  let columnCount = MIN_COLUMN_COUNT;
  let remainingWidth = width - columnCount * MIN_CELL_WIDTH;
  while (remainingWidth > 0 && remainingWidth >= MIN_CELL_WIDTH) {
    columnCount += 1;
    remainingWidth -= MIN_CELL_WIDTH;
  }
  return columnCount;
}

export const GridCell = styled.div.attrs(({ isListView }) => ({
  role: isListView ? 'row' : 'gridcell',
}))`
  will-change: top, scroll-position;
  ${({ isListView, hasRowSeparator }) =>
    isListView
      ? css`
          ${hasRowSeparator && withRowSeparator};
          padding: 0;

          @media ${MEDIUM_UP} {
            padding: 0 ${BOUNDARY_PADDING}px;
          }
        `
      : `padding: ${GUTTER_SIZE}px 0 0 ${GUTTER_SIZE}px`};
`;

const GridStyledScrollContainer = styled(ScrollContainer).attrs(() => ({
  tabIndex: -1,
}))`
  &:focus {
    outline: none;
  }
`;

const Wrapper = styled.div.attrs(({ isListView }) => ({
  role: isListView ? 'grid' : 'presentation',
}))`
  width: 100%;
  height: 100%;
  /*
    This component is frequently placed inside a Flex box. Since it's Measured
    anyway, it takes care to resize itself when its container changes, so let's
    add 0 min-width and -height to get around this Flexbox bug:
    https://github.com/philipwalton/flexbugs#flexbug-1
  */
  min-height: 0;
  min-width: 0;

  /* eslint-disable-next-line indent */
  ${ListHeaderRow} {
    padding: ${BOUNDARY_PADDING}px ${BOUNDARY_PADDING}px 0;
  }
  ${ListCell} {
    padding: 0 ${CELL_SPACING}px;
  }
`;
const WrapperWithShadow = Wrapper.withComponent(ShadowContainer);

const StyledGrid = styled(Grid)`
  ${buttonReset('default')};
  ${({ rowCount }) => rowCount === 0 && alignCenter()}
  -webkit-tap-highlight-color: transparent;
  /*
    Make this the same background as the page so that firefox knows to change
    the color of the scroll thumb to be a lighter colour if it is dark.
  */
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${({ bottomClearanceUnits, theme }) => css`
    /*
      Render some empty space after the last row to indicate that the user has
      scrolled to the bottom of the grid.
    */
    padding-bottom: ${theme.spacing.units(bottomClearanceUnits)};
  `};

  overflow: hidden;
`;
const MemoizedStyledGrid = React.memo(StyledGrid, shallowReflectiveEqual);
const ScrollingGrid = React.memo(
  withScrolling(StyledGrid),
  shallowReflectiveEqual
);

function defaultLoadingItemRenderer({
  height,
  listColumnWidths,
  thumbHeight,
  thumbWidth,
}) {
  return listColumnWidths ? (
    <LoadingRow height={height} listColumnWidths={listColumnWidths} />
  ) : (
    <LoadingCard faceHeight={thumbHeight} faceWidth={thumbWidth} />
  );
}

defaultLoadingItemRenderer.propTypes = {
  height: PropTypes.number,
  listColumnWidths: PropTypes.arrayOf(PropTypes.number),
  thumbHeight: PropTypes.number,
  thumbWidth: PropTypes.number,
};

defaultLoadingItemRenderer.defaultProps = {
  height: 0,
  listColumnWidths: undefined,
  thumbHeight: 0,
  thumbWidth: 0,
};

class InfiniteGrid extends React.Component {
  static getDerivedStateFromProps(
    { itemIds, selectedIds, totalItemCount },
    { lastSelectedIndex, listHeaderHeight }
  ) {
    if (lastSelectedIndex > -1 && (!itemIds.length || !selectedIds.length)) {
      return {
        lastSelectedIndex: -1,
      };
    }

    // When there are no results don't show the list headers, so clear out
    // any cached measurements.
    if (listHeaderHeight > 0 && totalItemCount === 0) {
      return {
        listHeaderHeight: 0,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      listColumnWidths: [],
      listHeaderHeight: 0,
      lastSelectedIndex: -1,
      // When contiguously selecting, which cell is the fixed point
      contiguousSelectionPivotIndex: -1,
    };
    this.listColumnWidthsBuffer = [];
    this.infiniteLoaderRef = React.createRef();
    this.innerRef = React.createRef();
    this.gridRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
    if (!this.gridRef.current) return;
    const { scrollTop } = this.props;
    this.gridRef.current.scrollTo({ scrollLeft: 0, scrollTop });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shouldComponentUpdate(this, nextProps, nextState);
  }

  componentDidUpdate(
    { itemIds: prevItemIds },
    { lastSelectedIndex: prevLastSelectedIndex }
  ) {
    const { itemIds, onSelectionChange } = this.props;
    const { lastSelectedIndex } = this.state;
    const { columnCount, rowCount } = this.gridRef.current.props;

    if (prevLastSelectedIndex !== lastSelectedIndex) {
      // We don't use the scrollToRow and scrollToColumn props for the Grid becauase that
      // doesn't trigger a scroll when the the row is the same number, but the column is different.
      if (lastSelectedIndex > -1) {
        const selectedRow = Math.floor(lastSelectedIndex / columnCount);
        const selectedColumn = lastSelectedIndex % columnCount;

        // (CORE-1698) Determine if the selected item is in the last row of the grid,
        // and if so, do not scroll to the item.
        const isLastRow = selectedRow === rowCount - 1;
        if (!isLastRow) {
          this.gridRef.current.scrollToItem({
            align: 'smart',
            columnIndex: selectedColumn,
            rowIndex: selectedRow,
          });
        }
      }
      onSelectionChange(lastSelectedIndex);
      this.focusGrid();
    }

    // When the paginated list RESET action is dispatched clear the cache and
    // scroll to the top.
    if (
      prevItemIds.filter(identity).length &&
      !itemIds.filter(identity).length
    ) {
      this.infiniteLoaderRef.current.resetloadMoreItemsCache();
      this.gridRef.current.scrollTo({ scrollLeft: 0, scrollTop: 0 });
    }
  }

  componentWillUnmount() {
    const { setScrollTop } = this.props;
    setScrollTop(this.gridRef.current.state.scrollTop);
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onItemsRendered = ({
    columnCount,
    infiniteLoaderOnItemsRendered,
    overscanRowStartIndex,
    overscanRowStopIndex,
    visibleColumnStartIndex,
    visibleColumnStopIndex,
    visibleRowStartIndex,
    visibleRowStopIndex,
  }) => {
    const { lastSelectedIndex } = this.state;
    const lastSelectedRow = Math.floor(lastSelectedIndex / columnCount);

    // The currently selected row has scrolled outside of the virtual window,
    // and so if focus was on a child element, it's now returned to the body
    // since that child has been unmounted. In order to still trap keydown
    // events, we focus the grid.
    const hasRowScrolledOut =
      lastSelectedRow < overscanRowStartIndex ||
      lastSelectedRow > overscanRowStopIndex;
    const hasLastSelection = lastSelectedIndex > -1;
    if (hasRowScrolledOut && hasLastSelection) {
      this.focusGrid();
    }
    const visibleStartIndex =
      visibleRowStartIndex * columnCount + visibleColumnStartIndex;
    const visibleStopIndex =
      visibleRowStopIndex * columnCount + visibleColumnStopIndex;

    infiniteLoaderOnItemsRendered({
      visibleStartIndex,
      visibleStopIndex,
    });
  };

  /**
   * Because setState is asynchronous, when headers are resized very quickly in succession
   * and calls to this method all read `this.state.listColumnWidths`, they will receive
   * the same value since each prior call's setState will not have had a chance to propagate.
   * So we maintain a separate measurement cache on the component instance so that we flush this
   * cache into state, every so often, causing only a single rerender with the new columns widths.
   */
  onListHeaderResize = (index, width, height) => {
    this.listColumnWidthsBuffer[index] = width;
    this.commitHeaderSizes(
      merge([], this.state.listColumnWidths, this.listColumnWidthsBuffer),
      height
    );
  };

  // eslint-disable-next-line react/sort-comp
  commitHeaderSizes = debounce(
    (listColumnWidths, listHeaderHeight) => {
      this.setState(
        {
          listColumnWidths,
          // note: this assumes all the headers have `white-space: none` and thus all
          // have the same height. If this changes we can add more logic here to
          // find the max height of all the header cells.
          listHeaderHeight,
        },
        () => {
          this.listColumnWidthsBuffer = [];
        }
      );
    },
    200,
    {
      trailing: true,
      leading: false,
    }
  );

  toggleSelection = (
    targetIndex,
    { shiftKey: isContiguous, metaKey, ctrlKey } = {}
  ) => {
    const { itemIds, selectedIds, setSelectedIds } = this.props;

    const { contiguousSelectionPivotIndex } = this.state;

    let newSelectedIds = selectedIds;
    const itemId = itemIds[targetIndex];
    const isSelected = selectedIds.includes(itemId);
    const isBulk = isPC ? ctrlKey : metaKey;

    if (isContiguous) {
      const minIndex = Math.min(contiguousSelectionPivotIndex, targetIndex);
      const maxIndex = Math.max(contiguousSelectionPivotIndex, targetIndex);
      newSelectedIds = itemIds.slice(minIndex, maxIndex + 1);
    } else if (isBulk) {
      if (isSelected) {
        newSelectedIds = without(selectedIds, itemId);
      } else {
        newSelectedIds = union(selectedIds, [itemId]);
      }
    } else {
      newSelectedIds = [itemId];
    }

    if (!isEqual(newSelectedIds, selectedIds)) {
      setSelectedIds(newSelectedIds);
    }

    this.setState({
      lastSelectedIndex: targetIndex,
      contiguousSelectionPivotIndex: isContiguous
        ? contiguousSelectionPivotIndex
        : targetIndex,
    });
  };

  onKeyDown = (evt) => {
    const { itemIds, canToggleSelection } = this.props;
    const { lastSelectedIndex } = this.state;

    // We want to prevent toggling when Modals and Prompts are up.
    if (!canToggleSelection) return;

    const { columnCount } = this.gridRef.current.props;
    let indexToSelect = lastSelectedIndex;

    switch (evt.key) {
      case 'ArrowRight': {
        indexToSelect += 1;
        break;
      }
      case 'ArrowLeft': {
        indexToSelect -= 1;
        break;
      }
      case 'ArrowUp': {
        indexToSelect -= columnCount;
        break;
      }
      case 'ArrowDown': {
        indexToSelect += columnCount;
        break;
      }
      default:
        return;
    }

    const itemId = itemIds[indexToSelect];
    if (indexToSelect === lastSelectedIndex || !itemId) {
      return;
    }

    evt.preventDefault();
    this.toggleSelection(indexToSelect, evt);
  };

  focusGrid = () => {
    // CORE-868: Don't focus the grid on touch devices so that it doesnt scroll the document.
    if (!this.innerRef.current || Modernizr.touchevents) return;
    const hasFocusWithin = this.innerRef.current.contains(
      document.activeElement
    );
    if (hasFocusWithin) return;
    this.innerRef.current.focus({ preventScroll: true });
  };

  loadMoreItems = (startIndex, stopIndex) => {
    const { fetchItemsForPage, pageSize } = this.props;
    const pagesStart = Math.floor(startIndex / pageSize) + 1;
    const pagesStop = Math.floor(stopIndex / pageSize) + 1;
    const pages = range(pagesStart, pagesStop + 1);
    pages.forEach((page) => fetchItemsForPage(page));
  };

  itemKey = ({ columnIndex, rowIndex, data }) => {
    const { itemIds, columnCount } = data;
    const itemIndex = rowIndex * columnCount + columnIndex;
    const key = itemIds[itemIndex];
    return key ? `${key}` : `${rowIndex}, ${columnIndex}`;
  };

  renderItem = React.memo(({ data, columnIndex, rowIndex, style }) => {
    const {
      children,
      columnCount,
      isSmallBreakpoint,
      itemIds,
      listColumnWidths,
      rowCount,
      thumbHeight,
      thumbWidth,
    } = data;
    const {
      hasRowSeparator,
      listRowHeight,
      listColumns,
      renderLoadingItem,
      shouldShowLoadingItem,
      viewType,
    } = this.props;

    const isListView = viewType === VIEW_TYPES.LIST;
    const hasListColumns =
      !isSmallBreakpoint && !Modernizr.touchevents && listColumns.length > 0;
    const listColumnsAreMeasured = listColumnWidths.length > 0;

    if (isListView && hasListColumns && !listColumnsAreMeasured) return null;

    const cellIndex = rowIndex * columnCount + columnIndex;
    const isFirstColumn = columnIndex === 0;
    const isFirstRow = rowIndex === 0;
    const isLastColumn = columnIndex === columnCount - 1;
    const isLastCell = cellIndex === itemIds.length - 1;
    const isLastRow = rowIndex === rowCount - 1;
    const itemId = itemIds[cellIndex];

    const { height } = style;
    const cellStyle = {
      ...style,
      height: isListView ? listRowHeight : style.height,
    };
    let viewHeight = 0;
    if (isFinite(height)) {
      if (isListView) {
        // +1 so that when multiple rows are selected the borders between them collapse.
        viewHeight = listRowHeight + (hasRowSeparator ? 1 : 0);
      } else {
        viewHeight = height - GUTTER_SIZE;
      }
    }

    const isLoading = cellIndex < this.props.totalItemCount;

    if (!itemId || shouldShowLoadingItem) {
      // Say you have a 4x5 grid but only 18 items, the last 2 cells will not
      // have items.
      if (!isLoading) return null;

      return (
        <GridCell
          style={cellStyle}
          isListView={isListView}
          hasRowSeparator={hasRowSeparator}
          data-test-id="loading-cell"
        >
          {renderLoadingItem({
            height: viewHeight,
            listColumnWidths: isListView ? listColumnWidths : undefined,
            thumbWidth,
            thumbHeight,
          })}
        </GridCell>
      );
    }

    return (
      <GridCell
        style={cellStyle}
        isListView={isListView}
        hasRowSeparator={hasRowSeparator}
      >
        {children({
          cellIndex,
          cellStyle,
          columnCount,
          columnIndex,
          isFirstColumn,
          isFirstRow,
          isLastCell,
          isLastColumn,
          isLastRow,
          isSmallBreakpoint,
          itemId,
          listColumnWidths: isListView ? listColumnWidths : undefined,
          rowCount,
          rowIndex,
          thumbHeight,
          thumbWidth,
          toggleSelection: this.toggleSelection,
          viewHeight,
        })}
      </GridCell>
    );
  }, areEqual);

  render() {
    const {
      itemIds,
      children,
      className,
      backgroundColor,
      bottomClearanceUnits,
      pageSize,
      hasShadowOnScroll,
      isSortDescending,
      listColumns,
      listRowHeight,
      overscanRowCount,
      measured: { width, height },
      onRef,
      onScroll,
      setSort,
      sortBy,
      // shouldScrollWhenDragging,
      totalItemCount,
      viewType,
      makeAllRowsVisible,
    } = this.props;
    const { listColumnWidths } = this.state;

    const isListView = viewType === VIEW_TYPES.LIST;
    const maxSpan = sumBy(listColumns, 'colspan');
    const WrapperComponent = hasShadowOnScroll ? WrapperWithShadow : Wrapper;

    return (
      <Media query={MEDIUM_UP}>
        {(isMediumUp) => (
          <InfiniteLoader
            minimumBatchSize={pageSize}
            isItemLoaded={(index) => !!itemIds[index]}
            loadMoreItems={this.loadMoreItems}
            itemCount={totalItemCount}
            /**
             * This is a little arbitrary for now--what it does is tell the
             * infinite loader how many remaining items are below the viewport
             * before we make a fetch for the next page. So if we have 40 rows x
             * 5 columns = 200 items, and page size is 20, when we scroll down
             * to row 38, we will have 2 remaining rows x 5 columns = 10 = page
             * size / 2 items below the view port. At that point, a fetch is
             * made.
             */
            threshold={Math.floor(pageSize / 2)}
            ref={this.infiniteLoaderRef}
          >
            {({ onItemsRendered: infiniteLoaderOnItemsRendered, ref }) => {
              const isSmallBreakpoint = !isMediumUp;
              const hasListHeaders =
                isListView &&
                (isMediumUp && !Modernizr.touchevents) &&
                listColumns.length > 0 &&
                totalItemCount > 0;

              let columnCount;
              if (isListView) {
                columnCount = 1;
              } else if (isSmallBreakpoint) {
                columnCount = 2;
              } else {
                columnCount = getColumnCount(width);
              }

              // The column width includes the GUTTER_SIZE on its left side.
              const columnWidth = isListView
                ? width
                : Math.floor((width - 2 * BOUNDARY_PADDING) / columnCount);
              const rowCount = Math.ceil(totalItemCount / columnCount);
              const thumbWidth = columnWidth - GUTTER_SIZE;
              const thumbHeight = Math.floor(thumbWidth / THUMB_ASPECT_RATIO);
              // The row height includes the GUTTER_SIZE on its top side.
              const rowHeight = isListView
                ? listRowHeight
                : thumbHeight + CARD_CHIN_HEIGHT + GUTTER_SIZE;

              return (
                <WrapperComponent
                  className={className}
                  isListView={isListView}
                  ref={onRef}
                >
                  {hasListHeaders && (
                    <ListHeaderRow>
                      {/* eslint-disable-next-line no-shadow */}
                      {listColumns.map(
                        ({ label, className, colspan, sortOption }, index) => {
                          const isSorted = sortOption
                            ? sortBy === sortOption.value
                            : false;
                          let sortDescending;

                          if (isSorted) {
                            sortDescending = !isSortDescending;
                          } else if (sortOption) {
                            sortDescending = sortOption.sortDescendingByDefault;
                          } else {
                            sortDescending = false;
                          }

                          return (
                            <ListHeaderCell
                              key={label}
                              className={className}
                              colspan={colspan}
                              isSortable={!!sortOption}
                              isSorted={isSorted}
                              isSortDescending={isSortDescending}
                              maxSpan={maxSpan}
                              onMeasure={({
                                width: headerWidth,
                                height: headerHeight,
                              }) =>
                                this.onListHeaderResize(
                                  index,
                                  headerWidth,
                                  headerHeight
                                )
                              }
                              onClick={
                                sortOption
                                  ? () => setSort(sortOption, sortDescending)
                                  : undefined
                              }
                            >
                              {label}
                            </ListHeaderCell>
                          );
                        }
                      )}
                    </ListHeaderRow>
                  )}
                  <StyledGrid
                    backgroundColor={backgroundColor}
                    bottomClearanceUnits={bottomClearanceUnits}
                    itemData={createItemData(
                      children,
                      columnCount,
                      isSmallBreakpoint,
                      itemIds,
                      listColumnWidths,
                      rowCount,
                      thumbHeight,
                      thumbWidth
                    )}
                    horizontalStrength={() => 0}
                    itemKey={this.itemKey}
                    outerElementType={
                      !makeAllRowsVisible && GridStyledScrollContainer
                    }
                    ref={(component) => {
                      /**
                       * This is a little gross, but because we need to store a
                       * ref to the grid for multiple components, some of which
                       * are functional refs, and others React.createRefs(),
                       * we have to do this gross thing.
                       */
                      ref(component);
                      this.gridRef.current = component;
                      // eslint-disable-next-line no-underscore-dangle
                      this.innerRef.current =
                        component && component._outerRef.firstElementChild;
                    }}
                    onScroll={onScroll}
                    onItemsRendered={(props) =>
                      this.onItemsRendered({
                        ...props,
                        columnCount,
                        infiniteLoaderOnItemsRendered,
                      })
                    }
                    height={
                      height - (isListView ? this.state.listHeaderHeight : 0)
                    }
                    width={width}
                    rowCount={rowCount}
                    columnCount={columnCount}
                    rowHeight={rowHeight}
                    columnWidth={columnWidth}
                    estimatedColumnWidth={columnWidth}
                    estimatedRowHeight={rowHeight}
                    overscanRowCount={overscanRowCount}
                    overscanColumnCount={0}
                    role={isListView ? 'rowgroup' : 'grid'}
                    style={
                      makeAllRowsVisible
                        ? GRID_STYLE_ALL_ROWS
                        : GRID_STYLE_DEFAULT
                    }
                  >
                    {this.renderItem}
                  </StyledGrid>
                </WrapperComponent>
              );
            }}
          </InfiniteLoader>
        )}
      </Media>
    );
  }

  // Select first cell, if any. This method is useful for arrow hotkeys to start focusing on
  // the grid.
  selectFirstItem = () => {
    const { itemIds } = this.props;
    if (!itemIds.length) return;
    this.toggleSelection(0);
    this.focusGrid();
  };
}

export default Measured(InfiniteGrid, true);

export const listColumnPropType = PropTypes.shape({
  // The string to display in the list header for this column
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  // The relative size of the column; essentially the flex-grow value.
  colspan: PropTypes.number.isRequired,
  // One of the object values from sortOptions
  sortOption: PropTypes.object,
});

InfiniteGrid.propTypes = {
  // Background color of the grid container
  backgroundColor: PropTypes.string,
  // Height of empty space at the bottom of the grid/list, in units of 8px
  bottomClearanceUnits: PropTypes.number,
  // allows us to disable toggling selected cell
  canToggleSelection: PropTypes.bool,
  // RenderProp used to render a child.
  children: PropTypes.func.isRequired,
  // css class. styled-components uses the className prop to apply styles to
  // wrapped components. we might consider simply spreading `...rest` from props
  // to the root element in the future, to allow callsites to apply other global
  // html attributes
  className: PropTypes.string,
  // Function that accepts a page number and fetches the data for that page.
  fetchItemsForPage: PropTypes.func.isRequired,
  // Whether to render a row separator in list view
  hasRowSeparator: PropTypes.bool,
  // Whether to show a shadow on the top of the grid as the user scrolls down.
  hasShadowOnScroll: PropTypes.bool,
  // Whether to tell the list headers that the sort is currently descending.
  isSortDescending: PropTypes.bool,
  // Array of ids backing the grid.
  itemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  // Array of column definitions for list view.
  listColumns: PropTypes.arrayOf(listColumnPropType),
  // Height of a list view row.
  listRowHeight: PropTypes.number,
  // Callback for when scroll happens.
  onScroll: PropTypes.func,
  // Callback for when selection has changed.
  onSelectionChange: PropTypes.func,
  // Number of additional rows to render in the direction of scroll.
  overscanRowCount: PropTypes.number,
  // Size of each page of items. This should be the same number sent to the API so that the logic
  // for when to call fetchItemsForPage is correct.
  pageSize: PropTypes.number,
  // RenderProp used to render a loading item
  renderLoadingItem: PropTypes.func,
  // Value to restore the scroll to on mount.
  scrollTop: PropTypes.number,
  // Array of item ids that are currently selected.
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  // Function to set the scrollTop value on unmount.
  setScrollTop: PropTypes.func,
  // Function to set the selected item ids.
  setSelectedIds: PropTypes.func,
  // Function to set the sort when list headers are used to sort.
  setSort: PropTypes.func,
  // Whether this is true, dragging near the edge of the grid scrolls it down. Should only be
  // true if dragging within the grid is necessary as this is a performance hit.
  // shouldScrollWhenDragging: PropTypes.bool,
  // Value to inform the list view headers of the current sort column.
  sortBy: PropTypes.string,
  // Optionally require loading item to be displayed
  shouldShowLoadingItem: PropTypes.bool,
  // Total number of items in the entire dataset, regardless of whether the items are in the
  // itemIds array or not.
  totalItemCount: PropTypes.number,
  // View type to render the cells as: `list` or `grid`.
  viewType: viewTypePropType,
  // Provided by measured.
  measured: Measured.propType.isRequired,
  // Provided by measured.
  onRef: PropTypes.func.isRequired,
};

InfiniteGrid.defaultProps = {
  backgroundColor: '#15151C',
  bottomClearanceUnits: 6,
  canToggleSelection: true,
  className: undefined,
  hasRowSeparator: true,
  hasShadowOnScroll: false,
  isListView: false,
  isSortDescending: false,
  listColumns: [],
  listRowHeight: 55,
  onScroll: noop,
  onSelectionChange: noop,
  overscanRowCount: 10,
  pageSize: 20,
  renderLoadingItem: defaultLoadingItemRenderer,
  scrollTop: 0,
  selectedIds: [],
  setScrollTop: noop,
  setSelectedIds: noop,
  setSort: noop,
  shouldScrollWhenDragging: false,
  shouldShowLoadingItem: false,
  sortBy: undefined,
  totalItemCount: 0,
  viewType: VIEW_TYPES.GRID,
};

export const testExports = {
  BOUNDARY_PADDING,
  CELL_SPACING,
  getColumnCount,
  GridCell,
  GridStyledScrollContainer,
  GUTTER_SIZE,
  InfiniteGrid,
  ListHeaderCell,
  MemoizedStyledGrid,
  MIN_CELL_WIDTH,
  MIN_COLUMN_COUNT,
  ScrollingGrid,
  THUMB_ASPECT_RATIO,
  Wrapper,
  WrapperWithShadow,
};
