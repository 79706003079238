import { COMMENTS_TOGGLE } from './actions';

export default function commentsToggleReducer(
  state = {
    isHighlighted: false,
    numOfComments: null,
  },
  action
) {
  const { type, payload } = action;
  switch (type) {
    case COMMENTS_TOGGLE.SET_NUM_OF_COMMENTS:
      return {
        ...state,
        numOfComments: payload.numOfComments,
      };
    case COMMENTS_TOGGLE.TOGGLE_HIGHLIGHT:
      return {
        ...state,
        isHighlighted: payload.isHighlighted,
      };

    default:
      return state;
  }
}
