import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FadeTransition } from 'react-transition-components';
import { get } from 'lodash';
import { ellipsis, shade } from 'polished';
import { color } from '@frameio/components/src/theme/darkTheme';
import Button from '@frameio/components/src/styled-components/Button';
import DownloadIcon from '@frameio/components/src/svgs/icons/download.svg';
import AssetViewer from 'components/Presentation/AssetViewer';
import { formatLongDateTime } from '@frameio/components/src/utils/datetimeHelpers';
import { SanitizedInnerHTML } from '@frameio/components';
import {
  highestDownloadResolution,
  isDownloadDisabledCheck,
} from 'utils/downloads';
import linkText from 'utils/linkText';
import { MEDIUM_DOWN } from 'utils/mediaQueries';
import { trackButtonClick, trackMediaStartPlayed } from 'analytics';
import { TRANSITION_SETTINGS } from 'components/Presentation/Layouts/shared';
import { shouldUseHlsHook } from 'utils/media';

const DOWNLOAD_ICON_SIZE = 16;
const TEXT_OPACITY = 0.7;

const assetTextStyles = css`
  font-size: ${(p) => p.theme.fontSize[2]};
  font-weight: bold;
`;

const AssetDescription = styled.div`
  ${assetTextStyles};
  opacity: 0.9;
  color: ${({ textColor }) => textColor};
  transition: color ${TRANSITION_SETTINGS};
  ${ellipsis()};
  white-space: normal;

  // Long titles get cut off in presentations, so this truncates after 2 lines if line clamp is supported
  // If line clamp is not supported, the title will wrap without truncation
  @supports (-webkit-line-clamp: 2) and (display: -webkit-box) and
    (-webkit-box-orient: vertical) {
    & {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  .presentation-link {
    color: ${({ textColor }) => textColor};
    text-decoration: none;

    &:hover {
      color: ${({ textHoverColor }) => textHoverColor};
    }
  }
`;

export const DownloadButton = styled(Button)`
  background-color: ${({ accentColor }) => accentColor};
  box-shadow: none;
  transition: background-color ${TRANSITION_SETTINGS};

  // needed to override hover styles
  &:hover:not(:active):not(:disabled) {
    background-color: ${({ accentColor }) => shade(0.9, accentColor)};
  }

  // needed to override active styles
  &:active,
  &:active:not(:disabled) {
    background-color: ${({ accentColor }) => shade(0.8, accentColor)};
  }

  svg {
    margin-right: ${(p) => p.theme.spacing.small};
  }
`;

const UploadDate = styled.div`
  color: ${({ textColor }) => textColor};
  font-size: ${(p) => p.theme.fontSize[1]};
  opacity: ${TEXT_OPACITY};
  transition: color ${TRANSITION_SETTINGS};
`;

const NameWrapper = styled.div``;

const TextWrapper = styled.div`
  max-width: 640px;
  text-align: center;
  user-select: text;
  width: 100%;
  margin: ${(p) => p.theme.spacing.medium} auto 56px;

  ${UploadDate} {
    margin-top: ${(p) => p.theme.spacing.small};
  }

  ${AssetDescription}, ${NameWrapper} {
    margin-top: ${(p) => p.theme.spacing.medium};
  }

  @media ${MEDIUM_DOWN} {
    padding: 0 ${(p) => p.theme.spacing.small};
  }
`;

const AssetWrapper = styled.div`
  padding-top: ${(p) => p.theme.spacing.large};
`;

class Blog extends React.Component {
  state = {
    isPlayerPaused: true,
    hasPlayedOnceAlready: false,
  };

  /**
   * Sync Blog video state with PresentationPlayer media state.
   * @param {bool} isPlayerPaused - Media state from PresentationPlayer.
   */
  syncPlayPause = (isPlayerPaused) => {
    const { asset, isSessionWatermarked } = this.props;
    const { hasPlayedOnceAlready } = this.state;
    const stateUpdates = { isPlayerPaused };

    if (!isPlayerPaused && !hasPlayedOnceAlready) {
      trackMediaStartPlayed(asset, {
        isSessionBasedWatermarked: isSessionWatermarked,
      });
      stateUpdates.hasPlayedOnceAlready = true;
    }
    this.setState(stateUpdates);
  };

  render() {
    const {
      accountId,
      asset,
      assetNameOrLink,
      canDownload,
      canUse4KPlayback,
      colors: { textColor, accentColor },
      displayNameForPresentationAsset,
      downloadAsset,
      includeUploadDate,
      isForensicFallbackEnabled,
      isSessionWatermarked,
      layout,
      shouldAutoLoad,
      trackingPage,
    } = this.props;
    const { isPlayerPaused } = this.state;
    const insertedAt = get(asset, 'inserted_at');
    const id = get(asset, 'id');
    const description = get(asset, 'description');
    const isHlsRequired = shouldUseHlsHook(asset);
    const isDownloadDisabled = isDownloadDisabledCheck(
      asset,
      isForensicFallbackEnabled,
      highestDownloadResolution(asset)
    );

    const formatDescription = (sanitizedDescription) => {
      const styledWrapLinkText = (match) => {
        return `<a class="presentation-link" target="_blank" href="${match.url}">${match.raw}</a>`;
      };

      return linkText(sanitizedDescription, styledWrapLinkText);
    };

    return (
      <AssetWrapper key={id}>
        <AssetViewer
          accentColor={accentColor}
          accountId={accountId}
          asset={asset}
          canUse4KPlayback={canUse4KPlayback}
          isActive
          isHlsPlayerEnabled={isHlsRequired}
          isPlayerPaused={isPlayerPaused}
          isSessionWatermarked={isSessionWatermarked}
          layout={layout}
          onPlayButtonClick={() =>
            this.setState({ isPlayerPaused: !isPlayerPaused })
          }
          shouldAutoLoad={shouldAutoLoad}
          syncPlayPause={this.syncPlayPause}
          trackingPage={trackingPage}
        />
        <TextWrapper>
          <FadeTransition in={canDownload}>
            <DownloadButton
              dark
              disabled={isDownloadDisabled}
              accentColor={isDownloadDisabled ? color.lightGray : accentColor}
              onClick={() => {
                trackButtonClick('download', trackingPage, 'bottom');
                downloadAsset(asset.id);
              }}
            >
              <DownloadIcon
                height={DOWNLOAD_ICON_SIZE}
                width={DOWNLOAD_ICON_SIZE}
                color={color.white}
              />
              Download
            </DownloadButton>
          </FadeTransition>
          <NameWrapper>
            {assetNameOrLink(displayNameForPresentationAsset, asset)}
          </NameWrapper>
          <FadeTransition in={includeUploadDate}>
            <UploadDate textColor={textColor}>
              Uploaded on {formatLongDateTime(insertedAt)}
            </UploadDate>
          </FadeTransition>
          {description && (
            <AssetDescription
              textColor={textColor}
              textHoverColor={accentColor}
            >
              <SanitizedInnerHTML
                innerHTML={description}
                config={{ ALLOWED_TAGS: [] }}
                onAfterSanitize={formatDescription}
              />
            </AssetDescription>
          )}
        </TextWrapper>
      </AssetWrapper>
    );
  }
}

Blog.defaultProps = {
  asset: undefined,
  includeExt: false,
  includeUploadDate: false,
  canDownload: false,
  layout: undefined,
  trackingPage: 'presentation page',
  displayNameForPresentationAsset: '',
};

Blog.propTypes = {
  accountId: PropTypes.string.isRequired,
  asset: PropTypes.object,
  assetNameOrLink: PropTypes.func.isRequired,
  canDownload: PropTypes.bool,
  colors: PropTypes.shape({
    textColor: PropTypes.string,
    accentColor: PropTypes.string,
  }).isRequired,
  displayNameForPresentationAsset: PropTypes.string,
  downloadAsset: PropTypes.func.isRequired,
  includeUploadDate: PropTypes.bool,
  isSessionWatermarked: PropTypes.bool,
  layout: PropTypes.string,
  trackingPage: PropTypes.string.isRequired,
};

export default Blog;

export const testExports = {
  DownloadButton,
  NameWrapper,
  UploadDate,
  AssetDescription,
};
