import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import TextInput from '@frameio/components/src/styled-components/TextInput';
import Button from '@frameio/components/src/styled-components/Button';

const FORM_WIDTH = '470px';

const StyledForm = styled.form`
  min-width: ${FORM_WIDTH};
`;

const HeaderContainer = styled.div`
  ${(p) => p.theme.fontStyle.heading};
  margin: ${(p) => p.theme.spacing.medium};
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${(p) => p.theme.spacing.small};
`;

const Row = styled.div`
  margin: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.medium}`};
  padding-bottom: ${(p) => p.theme.spacing.small};
`;

const Title = styled.div`
  ${(p) => p.theme.fontStyle.caption};
  margin-bottom: ${(p) => p.theme.spacing.small};
`;

const CancelButton = styled(Button)`
  margin-right: ${(p) => p.theme.spacing.small};
`;

export function InnerForm(props) {
  const {
    values: { name, emails, valueAddedTax },
    errors,
    closeModal,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    touched,
  } = props;

  const emailsString = emails.join(', ');
  const hasErrors = !!(errors.name || errors.valueAddedTax);
  const disabled = hasErrors || isSubmitting;

  return (
    <StyledForm onSubmit={handleSubmit}>
      <HeaderContainer>Invoice Settings</HeaderContainer>
      <Row>
        <Title>Company Name:</Title>
        <TextInput
          placeholder="Enter Company Name"
          value={name}
          onChange={handleChange}
          onBlur={handleBlur}
          name="name"
          error={touched.name && errors.name}
          data-invoice-name
        />
      </Row>
      <Row>
        <Title>Billing Email:</Title>
        <TextInput
          placeholder="email@example.com"
          value={emailsString}
          name="email"
          disabled
          data-invoice-emails
        />
      </Row>
      <Row>
        <Title>Tax ID:</Title>
        <TextInput
          placeholder="Enter Tax ID Number"
          value={valueAddedTax}
          name="valueAddedTax"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.valueAddedTax && errors.valueAddedTax}
          data-tax-id
        />
      </Row>
      <Footer>
        <CancelButton type="button" onClick={closeModal}>
          Cancel
        </CancelButton>
        <Button
          type="submit"
          primary
          disabled={disabled}
          showSpinner={isSubmitting}
        >
          Save
        </Button>
      </Footer>
    </StyledForm>
  );
}

export default withFormik({
  mapPropsToValues: ({ name, address, emails, valueAddedTax }) => ({
    name,
    address,
    emails,
    valueAddedTax,
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(props.accountId, { ...values, setSubmitting });
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().max(255, 'Company name must be at most 255 characters'),
    valueAddedTax: Yup.string().max(
      255,
      'Tax ID must be at most 255 characters'
    ),
  }),
})(InnerForm);

InnerForm.propTypes = {
  // Props from Formik
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    emails: PropTypes.array.isRequired,
    valueAddedTax: PropTypes.string,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

export const testExports = {
  CancelButton,
};
