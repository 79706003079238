import moment from 'moment-timezone';

export const DATA_POINTS = {
  USERNAME: 'username',
  IP_ADDRESS: 'ip_address',
  EMAIL: 'email',
  TIMESTAMP: 'timestamp',
  CUSTOM_TEXT: 'custom_text',
  USER_INPUT: 'user_input',
};

export const DATA_POINT_DEFAULT_VALUES = {
  [DATA_POINTS.USERNAME]: '[Viewer’s name]',
  [DATA_POINTS.EMAIL]: '[Viewer’s email address]',
  [DATA_POINTS.IP_ADDRESS]: '192.168.41.202',
  [DATA_POINTS.TIMESTAMP]: () =>
    `${moment(+new Date()).format('M-D-YYYY, h:mma')} UTC`,
  [DATA_POINTS.USER_INPUT]: '[Link creator custom text]',
  [DATA_POINTS.CUSTOM_TEXT]: 'New text block',
};

export const TEXT_SIZE_LABELS = {
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
  huge: 'Huge',
};

export const TEXT_SIZE_FACTOR = {
  small: 15,
  medium: 27,
  large: 45,
  huge: 90,
};

export const LINE_HEIGHT_FACTOR = {
  small: 18,
  medium: 33,
  large: 54,
  huge: 102,
};

export const DEFAULT_NEW_BLOCK_STATE = {
  text_alignment: 'center',
  text_shadow: false,
  scroll_text: 'none',
  position_reference_point: 'middle_center',
  position_x: 0.5,
  position_y: 0.5,
  font_size: 'medium',
  data_points: {
    custom_text: {
      value: DATA_POINT_DEFAULT_VALUES[DATA_POINTS.CUSTOM_TEXT],
      type: 'custom_text',
      order: 5,
      enabled: true,
    },
  },
  text_color: '#f1f1f1',
  alpha: 1,
  rotation: 0,
};

// the padding specification for the streaming service provides us with these
// values for defining padding on watermark text blocks. we divide the values for
// padding in half on the client side to get a more appropriate and accurate feel
// for the browser experience
export const VERTICAL_BORDER_PX = 15;
export const HORIZONTAL_BORDER_PX = 21;

export const MODAL_HEIGHT = '800px';
export const MODAL_MAX_WIDTH = '1460px';
export const SIDEPANEL_WIDTH_MIN = '320px';
export const SIDEPANEL_WIDTH_MAX = '384px';

// the backend enforces a 255 character limit on name, user override,
// and custom text field inputs. let's catch that on the client sid
// and inform the user.
export const WATERMARK_STRING_CHAR_LIMIT = 255;

// exclude the most common non-printable unicode characters. stream service
// will escape special characters appropriately
export const WATERMARK_STRING_ALLOWED_CHARS_REGEX = /^[\u{0020}-\u{007F}\u{00A1}-\u{10FFFF}]+$/u;

export const MAX_BLOCK_COUNT = 10;

export const RESTRICTED_EDITOR_MESSAGE =
  'Note: You can adjust the styling and positioning of existing watermark text blocks for this share.';

export const RESTRICTED_EDITOR_MESSAGE_NON_ADMIN = `Note: You can adjust the styling and positioning of existing watermark text blocks for this share. To edit the template for the account, please contact an account admin.`;
