import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { CardNumberElement as CardNumberStripeElement } from '@stripe/react-stripe-js';
import Card from '@frameio/components/src/svgs/icons/credit-card-filled.svg';
import Title from '../shared/Title';
import Container from '../shared/Container';
import IconContainer from '../shared/IconContainer';
import ErrorMessage from '../shared/ErrorMessage';
import { BASE_STYLES, stripeElementStyle } from '../styles';

const CardNumberElement = styled(CardNumberStripeElement)`
  ${({ theme, hasError, focused }) =>
    stripeElementStyle(theme, hasError, focused)}
`;

const CardElement = ({
  error,
  setFieldValue,
  setFieldTouched,
  touched,
  isEmpty,
}) => {
  const [focused, setFocused] = useState(false);
  const hasError = error?.code || (touched && isEmpty);
  const showError = !focused && hasError;
  return (
    <>
      <Title>Card Information</Title>
      <Container>
        <IconContainer>
          <Card height={12} width={12} />
        </IconContainer>
        <CardNumberElement
          name="cardNumber"
          onBlur={() => {
            setFieldTouched('cardNumber', true);
            setFocused(false);
          }}
          onFocus={() => setFocused(true)}
          onChange={(values) => {
            setFieldValue('cardNumber', values, false);
          }}
          onReady={(el) => el.focus()}
          options={BASE_STYLES}
          hasError={hasError}
          focused={focused}
        />
      </Container>
      <ErrorMessage isVisible={!!showError} marginTop>
        Invalid card number
      </ErrorMessage>
    </>
  );
};

export default memo(CardElement);
