import { Modal } from '@frameio/components';
import { MODAL } from './actions';

const defaultState = {
  ...Modal.defaultProps,
  isOpen: false,
};

/**
 * Modal state reducer.
 *
 * Note that this reducer is not merging in the previous state so that the next
 * modal instance (which might not be the same modal) does not inherit props
 * from the previous instance.
 *
 * @param   {Object} state - The current state.
 * @param   {Object} action - The action describing how to change the state.
 * @returns {Object} - The new state.
 */
export default function modalReducer(state = defaultState, action) {
  switch (action.type) {
    case MODAL.OPEN:
      return { ...action.payload, isOpen: true };
    case MODAL.UPDATE:
      return { ...state, ...action.payload };
    case MODAL.CLOSE:
      return defaultState;
    default:
      return state;
  }
}
