import { GROUPS_MODAL } from './actions';

export const initialState = {
  error: '',
  groupId: null,
};

const groupsContainerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GROUPS_MODAL.OPEN_CREATE_MODAL: {
      return initialState;
    }
    case GROUPS_MODAL.OPEN_EDIT_GROUP_MODAL: {
      return { ...initialState, groupId: action.payload.groupId };
    }
    case GROUPS_MODAL.CREATE_OR_UPDATE_GROUP_ERROR: {
      return { ...state, error: action.payload.error };
    }
    default: {
      return state;
    }
  }
};

export default groupsContainerReducer;
