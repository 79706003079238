import React from 'react';
import { isEqual, pick } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ListTeamRow from '../ListTeamRow';

const ProjectExpansionToggle = styled.div.attrs({
  tabIndex: -1,
  role: 'button',
})`
  display: block;
  cursor: pointer;
  outline: none;
  height: 40px;
  opacity: 1;
  transition: opacity 0.12s linear;
  &:active {
    opacity: 0.7;
  }
`;

/**
 * Dashboard Sidebar Team/Project list group.
 */
export default class ListTeamGroup extends React.Component {
  shouldComponentUpdate(nextProps) {
    // TODO(Anna): Need to determine why the archivedProjectIdsForTeamIdSelector is
    // re-calculating values more often than expected causing a number of unneeded
    // re-renders. This is a tactical fix for now:
    const propsToCompare = [
      'archivedProjectIds',
      'isArchivedProjectsTabOpen',
      'isSelected',
      'isTabOpen',
      'isLoadingProjects',
    ];

    const selectedProjectInCurrentTeamHasChanged =
      nextProps.isSelected &&
      this.props.selectedProjectId !== nextProps.selectedProjectId;

    return (
      !isEqual(
        pick(this.props, propsToCompare),
        pick(nextProps, propsToCompare)
      ) || selectedProjectInCurrentTeamHasChanged
    );
  }

  componentDidMount() {
    const { isTabOpen, fetchProjects, teamId } = this.props;

    // Perform fetchProjects effect if the tab is expanded upon mount.
    if (isTabOpen) {
      fetchProjects(teamId);
    }
  }

  componentDidUpdate({
    isTabOpen: wasTabOpen,
    isArchivedProjectsTabOpen: wasArchivedProjectsTabOpen,
  }) {
    const {
      archivedProjectIds,
      onTabClick,
      selectedProjectId,
      teamId,
      isTabOpen,
      fetchProjects,
    } = this.props;

    if (
      archivedProjectIds.includes(selectedProjectId) &&
      !wasArchivedProjectsTabOpen
    ) {
      onTabClick(`archivedProjectsTab-${[teamId]}`, true);
    }

    // Perform fetchProjects effect if the tab is somehow
    // opened without using mouseDown.
    if (isTabOpen && !wasTabOpen) {
      fetchProjects(teamId);
    }
  }

  /**
   * React render method.
   * @returns {ReactElement} - React virtual DOM element.
   */
  render() {
    const {
      className,
      isTabOpen,
      onTabClick,
      teamId,
      fetchProjects,
      isLoadingProjects,
      style,
    } = this.props;

    return (
      <div className={className} style={style}>
        <ProjectExpansionToggle
          onMouseDown={() => {
            // optimistically preload our projects upon mouseDown
            // with the assumption that the user is expanding
            // the tab. This will also perform a fetchProjects
            // effect in componentDidUpdate which will be
            // ignored.
            if (!isTabOpen) {
              fetchProjects(teamId);
            }
          }}
          onClick={() => {
            onTabClick(teamId, !isTabOpen);
          }}
        >
          <ListTeamRow
            teamId={teamId}
            isOpen={isTabOpen}
            isLoadingProjects={isLoadingProjects}
            enableTruncateWithTooltip={false}
          />
        </ProjectExpansionToggle>
      </div>
    );
  }
}

ListTeamGroup.defaultProps = {
  archivedProjectIds: [],
  className: undefined,
  isArchivedProjectsTabOpen: false,
  isSelected: false,
  isTabOpen: false,
  selectedProjectId: null,
  teamId: '',
};

ListTeamGroup.propTypes = {
  className: PropTypes.string,
  /**
   * Team's id.
   */
  teamId: PropTypes.string,
  /**
   * List of archived project ids.
   */
  archivedProjectIds: PropTypes.array,
  /**
   * Backend API Id for chosen project.
   */
  selectedProjectId: PropTypes.string,
  /**
   * Boolean for whether the tab should be open for group.
   */
  isTabOpen: PropTypes.bool,
  /**
   * Function fired when tab is clicked.
   */
  onTabClick: PropTypes.func.isRequired,
  /**
   * Boolean for whether the archived projects tab should be open for group.
   */
  isArchivedProjectsTabOpen: PropTypes.bool,
  /**
   * Boolean for whether the current team is associated with the selected project.
   */
  isSelected: PropTypes.bool,
  /**
   * Fetch the projects for a specified team.
   */
  fetchProjects: PropTypes.func,
  /**
   * Boolean for whether the team project list is currently being fetched.
   */
  isLoadingProjects: PropTypes.bool,
};

export const testExports = {
  ProjectExpansionToggle,
};
