import React from 'react';
import PropTypes from 'prop-types';
import Scrubber from '../Scrubber';
import ThumbImage from '../ThumbImage';

class ScrubImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
    };
  }

  componentDidMount() {
    return this.preloadImage(this.props.scrubImageUrl);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.scrubImageUrl !== nextProps.scrubImageUrl) {
      this.preloadImage(nextProps.scrubImageUrl);
    }
  }

  onMouseLeaveToggleOffScrub = () => {
    this.setState({ hovered: false });
  };

  onMouseEnterToggleOnScrub = () => {
    this.setState({ hovered: true });
  };

  preloadImage = (url) => {
    const img = new Image();
    img.src = url;
  };

  render() {
    const {
      className,
      height,
      width,
      coverImageUrl,
      scrubImageUrl,
      numFrames,
      scrubberBarWidth,
      style,
    } = this.props;
    return (
      <div
        className={`scrub-image-container ${className}`}
        style={{
          ...style,
          height,
          width,
          position: 'relative',
        }}
        onMouseLeave={this.onMouseLeaveToggleOffScrub}
        onMouseEnter={this.onMouseEnterToggleOnScrub}
      >
        <ThumbImage height={height} width={width} imageUrl={coverImageUrl} />
        {this.state.hovered && (
          <Scrubber
            height={height}
            width={width}
            barWidth={scrubberBarWidth}
            scrubImage={scrubImageUrl}
            numFrames={numFrames}
          />
        )}
      </div>
    );
  }
}

ScrubImage.defaultProps = {
  height: 'auto',
  width: 'auto',
  scrubberBarWidth: 1,
  className: '',
  style: {},
};

ScrubImage.propTypes = {
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scrubberBarWidth: PropTypes.number,
  coverImageUrl: PropTypes.string.isRequired,
  scrubImageUrl: PropTypes.string.isRequired,
  numFrames: PropTypes.number.isRequired,
  style: PropTypes.object,
};

export default ScrubImage;
