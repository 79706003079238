import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import Shadow from '@frameio/components/src/styled-components/ShadowContainer';

const StyledFlex = styled(Flex)`
  border-bottom: 1px solid
    ${(p) => (p.isScrollable ? p.theme.color.coldWhite : 'transparent')};
  border-top: 1px solid
    ${(p) => (p.hideTopShadow ? 'transparent' : p.theme.color.coldWhite)};
  margin-left: ${(p) => p.theme.spacing.units(2)};
  overflow-x: hidden;
  overflow-y: scroll;
  padding: ${(p) => p.theme.spacing.tiny} ${(p) => p.theme.spacing.units(2)}
    ${(p) => p.theme.spacing.tiny} 0;

  /* for longer lists, scroll performance can be a bit choppy
  and some elements won't appear to the user as they scroll through
  the list. we can try to squeeze out a little performance enhancement
  by pushing rendering onto the GPU by adding a "translate3d" rule. */
  transform: translate3d(0, 0, 0);

  transition: border-bottom 150ms ease-in-out;
`;

const StyledShadow = styled(Shadow)`
  height: 100%;
  width: 100%;
  margin-top: ${(p) => (p.moveUp ? '-20px' : '0px')};
  max-width: 100%;
  min-width: 0;
  min-height: 0;
`;

const SessionWatermarkTemplateListScrollContainer = ({
  children,
  hideTopShadow,
  moveUp,
}) => {
  const [isScrollable, setIsScrollable] = React.useState();
  const measureIsScrollable = (node) => {
    if (node) {
      const isScrollBottom =
        node.scrollTop + node.clientHeight >= node.scrollHeight;
      const hasOverflow = node.scrollHeight > node.clientHeight;

      if (isScrollBottom) {
        setIsScrollable(false);
      } else if (hasOverflow) {
        setIsScrollable(true);
      } else {
        setIsScrollable(false);
      }
    }
  };

  return (
    <StyledShadow moveUp={moveUp}>
      {({ onScroll: shadowCallback }) => (
        <StyledFlex
          column
          onScroll={(evt) => {
            measureIsScrollable(evt.target);
            shadowCallback(evt);
          }}
          ref={measureIsScrollable}
          isScrollable={isScrollable}
          hideTopShadow={hideTopShadow}
        >
          {children}
        </StyledFlex>
      )}
    </StyledShadow>
  );
};

export default SessionWatermarkTemplateListScrollContainer;
