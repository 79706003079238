import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Popover, Tooltip } from '@frameio/vapor';

import Slider from './Slider';
import {
  FIXED_PLAYBACK_RATES,
  playbackRateLabelsVariants,
  playbackRateVariants,
  rangeTickWrapperVariants,
  rangeTickVariants,
} from './utils';
import {
  CustomPlaybackPopoverWrapper,
  PopoverTitle,
  PlaybackRateLabels,
  PlaybackRate,
  PlaybackRateLabelWrapper,
  PlaybackButtonLabel,
  PlaybackRateInputWrapper,
  RangeTickWrapper,
  RangeTick,
  RangeSliderWrapper,
  PlaybackButton,
} from './styles';

const CustomPlaybackPopover = ({
  isDragging,
  setIsDragging,
  setStoredValue,
  storedValue,
}) => (
  <CustomPlaybackPopoverWrapper isDragging={isDragging}>
    <PopoverTitle
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 0.15,
        damping: 12,
        mass: 2,
      }}
    >
      Playback Speed
    </PopoverTitle>
    <PlaybackRateLabels
      initial="hidden"
      animate={isDragging ? 'faded' : 'visible'}
      variants={playbackRateLabelsVariants}
    >
      {FIXED_PLAYBACK_RATES.map((rate) => (
        <PlaybackRateLabelWrapper key={`playback-${rate}`}>
          <PlaybackRate
            key={rate}
            onClick={() => setStoredValue(rate)}
            transition={{ type: 'tween', duration: 0.2 }}
            variants={playbackRateVariants}
          >
            {rate === 1 ? 'Normal' : rate}
          </PlaybackRate>
        </PlaybackRateLabelWrapper>
      ))}
    </PlaybackRateLabels>
    <PlaybackRateInputWrapper>
      <RangeTickWrapper
        initial="hidden"
        animate="visible"
        variants={rangeTickWrapperVariants}
      >
        {FIXED_PLAYBACK_RATES.map((rate) => (
          <RangeTick key={rate} variants={rangeTickVariants} />
        ))}
      </RangeTickWrapper>
      <RangeSliderWrapper>
        <Slider
          onChangeStoredValue={setStoredValue}
          storedValue={storedValue}
          isDragging={isDragging}
          onSetIsDragging={setIsDragging}
        />
      </RangeSliderWrapper>
    </PlaybackRateInputWrapper>
  </CustomPlaybackPopoverWrapper>
);

const CustomPlayback = ({
  defaultPlaybackRate,
  effectivePlaybackRate,
  onDefaultPlaybackRateChange,
  setIsPopoverShowing,
  tooltipTitle,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [tempPlaybackRate, setTempPlaybackRate] = useState(defaultPlaybackRate);

  const hasNonDefaultRate =
    defaultPlaybackRate !== effectivePlaybackRate &&
    isFinite(effectivePlaybackRate);

  const handleRateChange = (value) => {
    setTempPlaybackRate(value);

    // Delay setting the real playback to prevent jank on the slider animation.
    setTimeout(() => onDefaultPlaybackRateChange(value), 200);
  };

  return (
    <Popover
      placement="top"
      tooltip={
        <Tooltip
          className="playbar-tooltip"
          delayIn={500}
          title={tooltipTitle}
          placement="top"
        />
      }
      onRequestChange={(isShowing) => {
        setIsPopoverShowing(isShowing);
      }}
      content={
        <CustomPlaybackPopover
          isDragging={isDragging}
          setIsDragging={setIsDragging}
          storedValue={tempPlaybackRate}
          setStoredValue={handleRateChange}
        />
      }
    >
      {({ isShowing, bind }) => (
        <PlaybackButton
          aria-label="Customize playback"
          aria-valuenow={tempPlaybackRate}
          isActive={hasNonDefaultRate}
          isFocused={isShowing}
          {...bind}
        >
          <PlaybackButtonLabel center>
            {`${hasNonDefaultRate ? effectivePlaybackRate : tempPlaybackRate}x`}
          </PlaybackButtonLabel>
        </PlaybackButton>
      )}
    </Popover>
  );
};

CustomPlayback.propTypes = {
  defaultPlaybackRate: PropTypes.number.isRequired,
  effectivePlaybackRate: PropTypes.number.isRequired,
  onDefaultPlaybackRateChange: PropTypes.func.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
};

export default CustomPlayback;
