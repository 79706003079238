import { get, includes } from 'lodash';
import Hls from '@frameio/components/src/lib/hlsjs';
import { SMALL_MAX } from 'utils/breakpoints';

const CLOUDFRONT = 'cloudfront';
const ACCELERATE = 'accelerate';

// https://html.spec.whatwg.org/multipage/media.html#media-element
export const HAVE_NOTHING = 0;
export const HAVE_METADATA = 1;
export const HAVE_CURRENT_DATA = 2;
export const HAVE_FUTURE_DATA = 3;
export const HAVE_ENOUGH_DATA = 4;

export const DEFAULT_ASPECT_RATIO = 16 / 9;

export const READY_STATE = {
  LOADING: 'LOADING',
  READY: 'READY',
  UNSUPPORTED: 'UNSUPPORTED',
  UPLOADING: 'UPLOADING',
};

export function getMediaProvider(mediaSrc) {
  return includes(mediaSrc, CLOUDFRONT) ? CLOUDFRONT : ACCELERATE;
}

// Image high too slow on mobile. Just load image_full
export const getImageSource = (file, width) =>
  width <= SMALL_MAX
    ? get(file, 'image_full')
    : get(file, 'image_high') || get(file, 'image_full');

/**
 * Util to determine whether the media has hls required and therefore should be piped through
 * the useHls hook in web-components
 *
 * Note: `useHls` does not always pipe the media through hls.js, rather the hook has device
 * and browser checking in place to handle several different use cases if hls.js is not
 * supported or the device supported hls natively
 * @param {*} asset - returned from the backend
 * @returns boolean
 */
export function shouldUseHlsHook(asset) {
  return Boolean(asset?.is_hls_required && asset?.hls_manifest);
}

/**
 * Util to determine if the media is indeed using hls.js (needed to adjust current time calibration)
 * @param {*} asset - asset returned from the backend
 * @returns boolean
 */
export function isUsingHlsJs(asset) {
  return shouldUseHlsHook(asset) && Hls.isSupported();
}
