import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const ARCHIVE_PROJECT_FLOW = generateActionTypes(
  'ARCHIVE_PROJECT_FLOW',
  [
    'START',
    'CONTINUE',
    'END',
    'SHOW_INITIAL_SCREEN',
    'SHOW_IN_PROGRESS_SCREEN',
    'SHOW_SUCCESS_SCREEN',
  ]
);

export const startFlow = createAction(
  ARCHIVE_PROJECT_FLOW.START,
  (projectId, isUpdatedArchivalMessagingEnabled) => ({
    projectId,
    isUpdatedArchivalMessagingEnabled,
  })
);

export const continueFlow = createAction(
  ARCHIVE_PROJECT_FLOW.CONTINUE,
  () => ({})
);

export const endFlow = createAction(ARCHIVE_PROJECT_FLOW.END, () => ({}));

export const showInitialScreen = createAction(
  ARCHIVE_PROJECT_FLOW.SHOW_INITIAL_SCREEN,
  () => ({})
);

export const showInProgressScreen = createAction(
  ARCHIVE_PROJECT_FLOW.SHOW_IN_PROGRESS_SCREEN,
  () => ({})
);

export const showSuccessScreen = createAction(
  ARCHIVE_PROJECT_FLOW.SHOW_SUCCESS_SCREEN,
  () => ({})
);
