import { isFetchingReducerFactory } from 'factories/isFetching';
import { TRIAL_COUNTDOWN } from 'components/TrialCountdown/actions';

const isFetchingReducer = isFetchingReducerFactory(TRIAL_COUNTDOWN.IS_FETCHING);

const initialState = {
  isFetching: false,
  triggeredByClick: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TRIAL_COUNTDOWN.IS_FETCHING:
      return isFetchingReducer(state, action);
    default:
      return state;
  }
}
