export default function moveArrayElement(
  array = [],
  sourceIndex = 0,
  targetIndex = 0
) {
  const clone = [...array]; // clone the array passed in to quarantine mutation

  // just return the cloned array if given an invalid index
  if (!clone[sourceIndex]) {
    return clone;
  }

  const el = clone.splice(sourceIndex, 1)[0];
  clone.splice(targetIndex, 0, el);

  return clone;
}
