import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { motion } from 'framer-motion';

import { buttonReset } from '@frameio/components/src/mixins';

import { Button } from 'components/PlayerControlBar/PlayerBarButton';

export const PlaybackButton = styled(Button)`
  ${(p) => p.theme.fontStyle.button};

  font-weight: ${(p) => p.theme.fontWeight.normal};
  width: ${(p) => p.theme.spacing.units(6)};
`;

export const PlaybackButtonLabel = styled(Flex)`
  width: 100%;
`;

export const PopoverTitle = styled(motion.div)`
  ${(p) => p.theme.fontStyle.bodyS};

  color: ${(p) => p.theme.color.graphiteGray};
  margin: 0 0 ${(p) => p.theme.spacing.small};
  text-align: left;
`;

export const PlaybackRateLabels = styled(motion.div)`
  display: grid;
  grid-template-columns: 33px 45px 52px 45px 57px 41px 1fr;
`;

export const PlaybackRate = styled(motion.button)`
  ${buttonReset()}
  ${(p) => p.theme.fontStyle.bodyS};

  color: ${(p) => p.theme.color.dimGray};
  padding: 0 ${(p) => p.theme.spacing.micro} ${(p) => p.theme.spacing.tiny};
  transition: color 150ms ease, opacity 150ms ease;

  &:hover {
    color: ${(p) => p.theme.color.gray};
  }
`;

export const PlaybackRateLabelWrapper = styled(Flex)`
  justify-content: center;

  &:first-child {
    justify-content: flex-start;

    > ${PlaybackRate} {
      left: calc(${(p) => p.theme.spacing.micro} * -1);
      position: relative;
    }
  }

  &:last-child {
    justify-content: flex-end;

    > ${PlaybackRate} {
      position: relative;
      right: calc(${(p) => p.theme.spacing.micro} * -1);
    }
  }
`;

export const RangeTickWrapper = styled(motion.div)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 6px;
  pointer-events: none;
  width: 100%;
  z-index: 1;
`;

export const RangeTick = styled(motion.div)`
  background-color: ${(p) => p.theme.color.graphiteGray};
  height: 8px;
  width: 1px;
`;

export const PlaybackRateInputWrapper = styled.div`
  height: 8px;
  display: flex;
  align-items: center;
  position: relative;
  transition: height 200ms ease;

  > ${RangeTickWrapper} {
    position: absolute;
    left: 0;
    top: 0;
  }
`;

export const RangeSliderWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const CustomPlaybackPopoverWrapper = styled.div`
  padding: ${(p) => p.theme.spacing.small} ${(p) => p.theme.spacing.units(2)};
  width: 340px;

  ${(p) =>
    p.isDragging &&
    `
    cursor: grabbing;

    ${PlaybackRate} {
      color: ${p.theme.color.gray};
      transition: color 200ms ease;
    }
  `};
`;
