/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import {
  isAccountOnLegacyPlanSelector,
  getAvailableSeatsCountSelector,
  shouldShowUserHardBlockSelector,
  isAccountEnterpriseSelector,
} from 'selectors/accounts';
import { shouldAutoScaleTeamMembersAllocation } from 'selectors/subscriptions';

function hasEnoughSeatsToAddTeamMember(state, { accountId }) {
  return getAvailableSeatsCountSelector(state, { accountId }) > 0;
}

/**
 * Checks if the user's account has enough seats available
 * or if the plan allows for autoscaled addition of new team members.
 * For legacy and Enterprise plans, we let users automatically go over their limits.
 * We then show the upgrade banner for 6 days before locking their account.
 * @param {Object} state - Redux state object.
 * @param {Object} props - Object that must contain accountId.
 * @param {string} props.accountId - User's account id.
 * @returns {bool} - Whether the user can add a new member to a team.
 */
export const canAddTeamMemberSelector = createSelector(
  hasEnoughSeatsToAddTeamMember,
  isAccountOnLegacyPlanSelector,
  shouldAutoScaleTeamMembersAllocation,
  shouldShowUserHardBlockSelector,
  isAccountEnterpriseSelector,
  (
    hasEnoughSeats,
    isLegacyPlan,
    shouldAutoScaleTeamMembersUsage,
    shouldShowUserHardBlock,
    isEnterprise
  ) =>
    isEnterprise ||
    ((isLegacyPlan || hasEnoughSeats || shouldAutoScaleTeamMembersUsage) &&
      !shouldShowUserHardBlock)
);
