import React from 'react';
import PropTypes from 'prop-types';
import { shallowReflectiveEqual } from 'reflective-bind';
import styled from 'styled-components';
import { Measured } from '@frameio/components';
import Button from '@frameio/components/src/styled-components/Button';
import Truncate from '@frameio/components/src/styled-components/Truncate';
import ArrowUpIcon from '@frameio/components/src/svgs/icons/arrow-up.svg';

export const SortDirectionIcon = styled(({ isDescending, ...rest }) => (
  <ArrowUpIcon height={12} width={12} {...rest} />
))`
  ${({ isDescending }) => isDescending && 'transform: rotate(180deg)'};
  margin: 0 ${(p) => p.theme.spacing.micro};
`;

const HeaderCell = styled.div`
  ${({ colspan, maxSpan }) => `
    flex: ${colspan} 1 ${(colspan / maxSpan) * 100}%;
  `}
  justify-content: flex-start;
  font-weight: ${(p) => p.theme.fontWeight.bold};
  font-size: ${(p) => p.theme.fontSize[2]};
  color: ${(p) => p.theme.color.slateGray};
`;

const SortableHeaderCell = styled(HeaderCell.withComponent(Button)).attrs(
  () => ({
    dark: true,
    text: true,
  })
)`
  ${SortDirectionIcon} {
    flex: 0 0 auto;
  }
`;

function ListHeader({
  children,
  isSortable,
  isSorted,
  isSortDescending,
  onRef,
  onMeasure,
  ...rest
}) {
  return isSortable ? (
    <SortableHeaderCell ref={onRef} {...rest}>
      <Truncate>{children}</Truncate>
      {isSorted && <SortDirectionIcon isDescending={isSortDescending} />}
    </SortableHeaderCell>
  ) : (
    <HeaderCell ref={onRef} {...rest}>
      <Truncate>{children}</Truncate>
    </HeaderCell>
  );
}

ListHeader.propTypes = {
  children: PropTypes.node.isRequired,
  isSortable: PropTypes.bool.isRequired,
  isSorted: PropTypes.bool.isRequired,
  isSortDescending: PropTypes.bool.isRequired,
  maxSpan: PropTypes.number,
  onRef: PropTypes.func.isRequired,
  onMeasure: PropTypes.func.isRequired,
};

ListHeader.defaultProps = {
  maxSpan: 1,
};

const MemoizedListHeader = React.memo(ListHeader, shallowReflectiveEqual);

export default Measured(MemoizedListHeader, true);

export const testExports = {
  HeaderCell,
  ListHeader,
  SortableHeaderCell,
  SortDirectionIcon,
};
