import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import styled, { css } from 'styled-components';
import { readableColor } from 'polished';
import Flex, { FlexItem } from 'styled-flex-component';

import CancelSVG from '@frameio/components/src/svgs/icons/16/cancel.svg';
import { ENTER_DURATION } from '@frameio/components/src/components/Modal';
import { absoluteFill, touchScrollingY } from '@frameio/components/src/mixins';
import CrossFader from '@frameio/components/src/styled-components/CrossFader';
import PulsingCircle from '@frameio/components/src/styled-components/PulsingCircle';
import { getMediaType } from '@frameio/core/src/assets/helpers/mediaTypes';
import Caret from '@frameio/components/src/svgs/icons/carot-thin.svg';
import Button from '@frameio/components/src/styled-components/Button';

import track from 'analytics';
import { writeText } from 'clipboard-polyfill';
import { MEDIUM_UP } from 'utils/mediaQueries';
import Presentation from 'components/Presentation';
import { REEL_LAYOUT } from 'components/Presentation/layouts';
import { presentationColorsWithFallback } from 'components/Presentation/utils';

import EmptyPresentationPlaceholder from 'components/Presentation/EmptyPresentationPlaceholder';
import ReelPlayerBlock from '../ReelPlayerBlock';
import SecureSidePanel from '../SecureSidePanel';

export const SIDE_PANEL_WIDTH = '375px';

export const TRANSITION_EASING = 'cubic-bezier(0.25, 0, 0, 1)';
export const TRANSITION_DURATION = 350;
export const Container = styled.div`
  height: 100%;
  width: 100%;
  background: ${(p) => p.theme.color.white};
`;

// We want the standard gray whenever possible, including when the background is white/default
// Otherwise use the best contrast available.
const readableColorFill = css`
  fill: ${(p) =>
    p.contrastcolor === '#ffffff'
      ? p.theme.color.gray
      : readableColor(p.contrastcolor, p.theme.color.gray)};
`;

const StyledCaret = styled(Caret).attrs(() => ({
  // NOTE: Safari does not accept calculated values for SVG `height` and
  // `width` attributes; `theme.spacing.units` cannot be set here:
  width: 16,
  height: 16,
}))`
  transform: ${({ angle }) => `rotate(${angle}deg)`};
  transition: 0.3s ease-in-out;
  transition-property: left, transform;

  path {
    width: ${(p) => p.theme.spacing.units(2)};
    height: ${(p) => p.theme.spacing.units(2)};
    ${readableColorFill}
  }
`;

const StyledButton = styled(Button)`
  height: ${(p) => p.theme.spacing.units(4)};
  width: ${(p) => p.theme.spacing.units(4)};
`;

const MobileCloseButtonContainer = styled.div`
  margin-top: ${(p) => p.theme.spacing.tiny};
  margin-right: ${(p) => p.theme.spacing.tiny};
`;

const StyledClose = styled(CancelSVG)`
  path {
    ${readableColorFill}
  }
`;

export const StyledCrossFader = styled(CrossFader)`
  ${touchScrollingY()};
  width: 100%;
`;

export const PanelPresentationContainer = styled(Flex)`
  position: relative;
  height: 100%;
`;

const fullWidthOnSmallScreens = css`
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
`;

export const PanelOuterContainer = styled(Flex)`
  position: relative;
  width: ${({ isVisible }) => (!isVisible ? 0 : SIDE_PANEL_WIDTH)};
  transition: width 0.3s ease-in-out;
  height: 100%;
  overflow: hidden;
  ${fullWidthOnSmallScreens};
`;

export const PanelContentContainer = styled.div`
  transform: ${({ isVisible }) =>
    !isVisible
      ? `translate3d(-${SIDE_PANEL_WIDTH}, 0, 0)`
      : 'translate3d(0, 0, 0)'};
  transition: transform 0.3s ease-in-out;
  width: ${SIDE_PANEL_WIDTH};
  height: 100%;
  ${fullWidthOnSmallScreens};

  ${MobileCloseButtonContainer} {
    position: absolute;
    right: 0;
  }
`;

const PresentationModalButtonContainer = styled(Flex)``;

export const PresentationPreview = styled(FlexItem)`
  ${({ isReelLayout }) => !isReelLayout && touchScrollingY()};
  height: ${({ isReelLayout }) => isReelLayout && '100%'};
  overflow-y: ${({ isReelLayout }) => isReelLayout && 'hidden'};
  overflow-x: hidden;
  flex: 1;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 30px 5px rga(0, 0, 0, 0.06);

  ${PresentationModalButtonContainer} {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    padding: ${(p) => p.theme.spacing.units(2)};
  }
`;

const StyledPresentation = styled(Presentation)`
  min-height: 100%;
  padding-top: ${(p) => p.theme.spacing.units(4)};
`;

// Overlays the block right on top of the preview presentation page, minus the
// margins around it.
const ReelPlayerBlockWrapper = styled.div`
  position: relative;
  ${absoluteFill()};
  border-radius: ${(p) => p.theme.radius.large};
  overflow: hidden;
`;

export default class SecurePresentationEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPanelOpen: true,
      showBody: false,
    };
  }

  componentDidMount() {
    // prevents elements rendering before modal animates to full size
    this.showBodyTimeout = setTimeout(() => {
      this.setState({ showBody: true });
    }, ENTER_DURATION);
  }

  componentDidUpdate(prevProps) {
    const { presentation, selectedAsset } = this.props;
    if (presentation && prevProps.presentation !== presentation) {
      track('media-sharing-settings-changed', {
        client: 'web',
        presentation_id: presentation.id,
        presentation_page_layout: presentation.layout,
        item_type: selectedAsset ? selectedAsset.type : null,
        media_type: selectedAsset ? getMediaType(selectedAsset) : null,
        sharing_settings: presentation.enabled,
        sharing_settings_download: presentation.can_download,
        sharing_settings_password: !!presentation.password,
        sharing_settings_expiration_date: presentation.expires_at,
        sharing_settings_show_upload_date: presentation.include_upload_date,
        sharing_settings_show_file_ext: presentation.include_ext,
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.showBodyTimeout);
  }

  copyLink = (url) => {
    const { showLinkCopiedToast, presentation } = this.props;
    writeText(url) &&
      presentation.enabled &&
      showLinkCopiedToast({ header: 'Copied!' });
  };

  render() {
    const {
      accountId,
      assets,
      closeModal,
      defaultTab,
      isAuthorized,
      isBlockingReelPlayer,
      isForensicFallbackEnabled,
      isEnterprise,
      presentation,
      team,
      title,
    } = this.props;
    const { isPanelOpen, showBody } = this.state;
    const isReelLayout =
      presentation.layout === REEL_LAYOUT || isBlockingReelPlayer;

    const { background } = presentationColorsWithFallback(presentation, team);

    return (
      <Container>
        {presentation && showBody ? (
          <Media query={MEDIUM_UP}>
            {(isLargeScreen) => (
              <PanelPresentationContainer
                alignStretch
                isPanelOpen={isPanelOpen}
                isLargeScreen={isLargeScreen}
              >
                <PanelOuterContainer
                  as={FlexItem}
                  isVisible={isPanelOpen}
                  noShrink
                  fullWidth={!isLargeScreen}
                >
                  <PanelContentContainer
                    isVisible={isPanelOpen}
                    fullWidth={!isLargeScreen}
                  >
                    {!isLargeScreen && (
                      <MobileCloseButtonContainer>
                        <Button compact dark icon text onClick={closeModal}>
                          <StyledClose contrastcolor={`#${background}`} />
                        </Button>
                      </MobileCloseButtonContainer>
                    )}
                    <SecureSidePanel
                      defaultTab={defaultTab}
                      isEnterprise={isEnterprise}
                      presentationId={presentation.id}
                      presentationTitle={title}
                      team={team}
                    />
                  </PanelContentContainer>
                </PanelOuterContainer>

                {isLargeScreen && (
                  <StyledCrossFader
                    timeout={TRANSITION_DURATION}
                    easing={TRANSITION_EASING}
                  >
                    <PresentationPreview
                      key={isReelLayout}
                      isReelLayout={isReelLayout}
                    >
                      {assets.length ? (
                        <StyledPresentation
                          accountId={accountId}
                          assets={assets}
                          isAuthorized={isAuthorized}
                          isForensicFallbackEnabled={isForensicFallbackEnabled}
                          logo={team && team.team_image}
                          presentation={presentation}
                          readOnly
                          team={team}
                          title={title}
                          trackingPage="presentation modal"
                        />
                      ) : (
                        <EmptyPresentationPlaceholder />
                      )}

                      <PresentationModalButtonContainer justifyBetween>
                        <StyledButton
                          compact
                          icon
                          text
                          onClick={() =>
                            this.setState({
                              isPanelOpen: !this.state.isPanelOpen,
                            })
                          }
                        >
                          <StyledCaret
                            angle={this.state.isPanelOpen ? 0 : 180}
                            contrastcolor={`#${background}`}
                          />
                        </StyledButton>
                        <StyledButton compact icon text onClick={closeModal}>
                          <StyledClose contrastcolor={`#${background}`} />
                        </StyledButton>
                      </PresentationModalButtonContainer>
                      {isBlockingReelPlayer && (
                        <ReelPlayerBlockWrapper>
                          <ReelPlayerBlock presentationId={presentation.id} />
                        </ReelPlayerBlockWrapper>
                      )}
                    </PresentationPreview>
                  </StyledCrossFader>
                )}
              </PanelPresentationContainer>
            )}
          </Media>
        ) : (
          <PulsingCircle />
        )}
      </Container>
    );
  }
}

SecurePresentationEditor.propTypes = {
  accountId: PropTypes.string.isRequired,
  assets: PropTypes.array.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isBlockingReelPlayer: PropTypes.bool.isRequired,
  presentation: PropTypes.object,
  selectedAsset: PropTypes.object.isRequired, // only used for tracking
  showLinkCopiedToast: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  team: PropTypes.shape({
    background_color: PropTypes.string,
    color: PropTypes.string,
    font_color: PropTypes.string,
    team_image: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

SecurePresentationEditor.defaultProps = {
  assets: [],
  isAuthorized: true,
  presentation: {},
  selectedAsset: null,
  title: '',
};
