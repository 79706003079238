import { generateActionTypes } from 'shared/actions/helpers';

export const PROJECT_LIST = generateActionTypes('PROJECT_LIST', [
  'TEAM_TOGGLE',
  'JUMP_TO_SELECTED',
]);

export function toggleTeam(team_id, previousToggleValue) {
  const teamToggles = {};
  teamToggles[team_id] = !previousToggleValue;
  return {
    type: PROJECT_LIST.TEAM_TOGGLE,
    payload: { teamToggles },
  };
}

export function jumpToSelected(jumpToValue) {
  return {
    type: PROJECT_LIST.JUMP_TO_SELECTED,
    payload: { jumpToSelected: jumpToValue },
  };
}
