import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const AUTHED_ROUTE = generateActionTypes('AUTHED_ROUTE', [
  'AUTHENTICATE',
  'SET_LOCKED_OUT_EMAIL',
  'UNMOUNT',
]);

export const authenticate = createAction(AUTHED_ROUTE.AUTHENTICATE, () => ({}));

export const setLockedOutEmail = createAction(
  AUTHED_ROUTE.SET_LOCKED_OUT_EMAIL,
  (email) => ({ email })
);

export const unmount = createAction(AUTHED_ROUTE.UNMOUNT, () => ({}));
