import { connect } from 'react-redux';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import { closeModal as closeManageVersionStack } from 'components/Modal/actions';
import { isManageVersionStackModalFetchingSelector } from '../selectors';
import ManageVersionStackModal from './ManageVersionStackModal';
import {
  removeVersionFromStackInModal as removeVersionFromStack,
  reorderVersionStackInModal as reorderVersionInStack,
} from './actions';

const mapStateToProps = (state, { assetId }) => {
  const { versions } = assetEntitySelector(state, { assetId });

  return {
    isFetching: isManageVersionStackModalFetchingSelector(state),

    // `assetId` can be the id of any type of asset. All assets, including non-
    // version stack assets, have a `versions` property associated with them. All
    // assets have a `versions` value of 0, except for version stacks. We can derive
    // the value of `totalVersions` by checking the value of `versions` below and
    // return any "truthy" (i.e., > 0) value or fall back to a value of 1, to represent
    // the single asset being passed into the component.
    totalVersions: versions || 1,
  };
};

const mapDispatchToProps = {
  closeManageVersionStack,
  removeVersionFromStack,
  reorderVersionInStack,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageVersionStackModal);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
