import {
  call,
  debounce,
  spawn,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import track from 'analytics';
import {
  listReviewLinksForProject,
  updateReviewLink,
  deleteReviewLink as deleteReviewLinkCoreSaga,
  duplicateReviewLink as duplicateReviewLinkCoreSaga,
} from '@frameio/core/src/reviewLinks/sagas';
import {
  createPaginatedListSaga,
  createDelegatedSaga,
} from '@frameio/core/src/shared/sagas/helpers';
import { getReviewLinkItems } from '@frameio/core/src/reviewLinkItems/sagas';
import { reviewLinkEntitySelector } from '@frameio/core/src/reviewLinks/selectors';
import { assetIdsForReviewLinkSelector } from '@frameio/core/src/shared/selectors/relationships';
import { patchReviewLink } from '@frameio/core/src/reviewLinks/actions';
import { writeText } from 'clipboard-polyfill';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import {
  setReviewLinkHeaders,
  unsetReviewLinkHeaders,
} from 'pages/ReviewLinkContainer/utils';
import { getProjectUrl } from 'URLs';
import {
  setReviewLinkEditorId,
  openEditor,
  toggleReviewLinkSelectionMode,
} from 'components/ReviewLinkEditor/actions';
import { confirmDelete } from 'components/Dialog/SimpleDialog/sagas';
import { push } from 'connected-react-router';
import { newSharingModalEnabled } from 'utils/featureFlags';

import {
  shouldFetchLinksSelector,
  linksOptionsSelector,
  projectIdForLinksSelector,
  totalPagesSelector,
} from './selectors';
import {
  PROJECT_LINKS,
  fetchLinks as fetchLinksAction,
  resetLinks,
  deleteReviewLink as deleteReviewLinkAction,
  patchSort,
} from './actions';
import SORT_OPTIONS from './sortOptions';

const fetchPaginatedReviewLinks = createPaginatedListSaga(
  PROJECT_LINKS.FETCH,
  listReviewLinksForProject
);

function* fetchLinks({ payload: { projectId, page } }) {
  const shouldFetch = yield select(shouldFetchLinksSelector, { page });
  const options = yield select(linksOptionsSelector);

  if (!shouldFetch) {
    return;
  }

  if (page > 1) {
    yield spawn(track, 'project-links-page-results-paginated', {
      page,
      sort_by: options.sortBy,
      type: 'review_pages',
    });
  }

  yield call(fetchPaginatedReviewLinks, projectId, {
    page,
    ...options,
    sortBy: `${options.sortBy},-${SORT_OPTIONS.CREATED_AT.value}`,
    excludedFields: ['session_watermark_templates'],
  });
}

function* sortLinks() {
  const projectId = yield select(projectIdForLinksSelector);
  const totalPages = yield select(totalPagesSelector);

  if (totalPages === 1) {
    yield put(patchSort());
    return;
  }

  yield put(resetLinks(true));
  yield put(fetchLinksAction(projectId, 1));
}

function* copyShortUrlToClipboard({ payload: { url } }) {
  yield call(writeText, url);
  yield put(showSuccessToast({ header: 'Link copied to clipboard!' }));
}

function* toggleIsActive({ payload: { id, isActive } }) {
  // Dispatch the BE review link update request
  const { failure } = yield call(updateReviewLink, id, { is_active: isActive });

  if (failure) {
    // revert the active state and display an error in failure cases
    yield put(patchReviewLink({ id, is_active: !isActive }));
    yield put(
      showErrorToast({
        header: `An error occurred while ${
          isActive ? 'activating' : 'disabling'
        } this link`,
      })
    );
  }
}

function* patchToggleIsActive({ payload: { id, isActive } }) {
  // optimistically update the status in the UI
  yield put(patchReviewLink({ id, is_active: isActive }));
}

function* renameLink({ payload: { id, name, prevName } }) {
  // optimistically update the review link name
  yield put(patchReviewLink({ id, name }));

  // then dispatch the remote request
  const { failure } = yield call(updateReviewLink, id, { name });

  if (failure) {
    yield put(patchReviewLink({ id, name: prevName }));
    yield put(
      showErrorToast({
        header: 'An error occurred while renaming this link',
      })
    );
  }
}

const DELETE_REVIEW_LINK_DIALOG_HEADER = 'Delete review link?';
const DELETE_REVIEW_LINK_DIALOG_BODY =
  'Are you sure you want to delete this review link?';
const deleteReviewLink = createDelegatedSaga(
  PROJECT_LINKS.DELETE,
  ({ payload: { id } }) => deleteReviewLinkCoreSaga(id)
);

function* confirmDeleteReviewLink({ payload: { id } }) {
  const response = yield call(
    confirmDelete,
    DELETE_REVIEW_LINK_DIALOG_HEADER,
    DELETE_REVIEW_LINK_DIALOG_BODY
  );

  if (!response) {
    return;
  }

  yield put(deleteReviewLinkAction(id));
}

function* editReviewLinkSettings({ payload: { id } }) {
  const isNewSharingModalEnabled = yield select(newSharingModalEnabled);
  yield spawn(track, 'review-link-modal-shown', {
    modal_version: isNewSharingModalEnabled
      ? '2020_01_tabs'
      : '2019_07_no_tabs',
    source: 'links_tab',
  });
  yield put(openEditor(id));
}

function* editReviewLinkContent({ payload: { id } }) {
  const { password } = yield select(reviewLinkEntitySelector, {
    reviewLinkId: id,
  });

  // set headers for review link request if it has a password  set
  if (password) {
    yield call(setReviewLinkHeaders, id, password);
  }

  // TODO(Aaron): we'll need to add pagination here
  // fetch all review link items and hydrate them in the store
  const { failure } = yield call(getReviewLinkItems, id);

  // clear review link headers after request is made
  if (password) {
    yield call(unsetReviewLinkHeaders);
  }

  if (failure) {
    yield put(showErrorToast({ header: 'Something went wrong' }));
    return;
  }

  const projectId = yield select(projectIdForLinksSelector);
  const assetIds = yield select(assetIdsForReviewLinkSelector, {
    reviewLinkId: id,
  });

  // Route to `/projects/project-id` to edit asset selection
  yield put(push(getProjectUrl(projectId)));

  yield put(setReviewLinkEditorId(id));
  yield put(toggleReviewLinkSelectionMode(assetIds));
}

const duplicateReviewLink = createDelegatedSaga(
  PROJECT_LINKS.DUPLICATE,
  ({ payload: { id } }) => duplicateReviewLinkCoreSaga(id)
);

function* onFailure(header) {
  yield put(
    showErrorToast({
      header,
    })
  );
}

export default [
  takeEvery(PROJECT_LINKS.COPY_SHORT_URL, copyShortUrlToClipboard),
  takeEvery(PROJECT_LINKS.CONFIRM_DELETE, confirmDeleteReviewLink),
  takeEvery(PROJECT_LINKS.DELETE.BASE, deleteReviewLink),
  takeEvery(PROJECT_LINKS.DUPLICATE.BASE, duplicateReviewLink),
  takeEvery(PROJECT_LINKS.EDIT_CONTENT, editReviewLinkContent),
  takeEvery(PROJECT_LINKS.EDIT_SETTINGS, editReviewLinkSettings),
  takeEvery(PROJECT_LINKS.FETCH.BASE, fetchLinks),
  takeEvery(PROJECT_LINKS.PATCH_TOGGLE_IS_ACTIVE, patchToggleIsActive),
  debounce(200, PROJECT_LINKS.PATCH_TOGGLE_IS_ACTIVE, toggleIsActive),
  takeEvery(PROJECT_LINKS.RENAME.BASE, renameLink),
  takeEvery(PROJECT_LINKS.SORT, sortLinks),
  takeEvery(PROJECT_LINKS.DELETE.FAILURE, () =>
    onFailure('An error occurred while deleting this link')
  ),
  takeEvery(PROJECT_LINKS.DUPLICATE.FAILURE, () =>
    onFailure('An error occurred while duplicating this link')
  ),
];

export const testExports = {
  confirmDeleteReviewLink,
  copyShortUrlToClipboard,
  deleteReviewLink,
  editReviewLinkContent,
  editReviewLinkSettings,
  fetchLinks,
  fetchPaginatedReviewLinks,
  onFailure,
  patchToggleIsActive,
  renameLink,
  sortLinks,
  toggleIsActive,
  DELETE_REVIEW_LINK_DIALOG_HEADER,
  DELETE_REVIEW_LINK_DIALOG_BODY,
};
