import { connect } from 'react-redux';
import { v4IsBetaEnabled } from 'utils/featureFlags';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import { newNotificationsCountSelector } from 'components/NotificationsPopover/selectors';
import Account from './Account';

/**
 * @param   {Object} state - Redux store state.
 * @returns {Object} - Maps the redux store state to populate props for the AccountSwitcher.
 */
const mapStateToProps = (state, { accountId }) => {
  const account = accountEntitySelector(state, { accountId }) || {};
  const newNotificationsCount = newNotificationsCountSelector(state, {
    accountId,
  });

  const {
    display_name: name,
    image_64: image,
    account_default_color: color,
  } = account;

  return {
    name,
    image,
    color,
    shouldShowPip: newNotificationsCount > 0,
    v4IsBetaEnabled: v4IsBetaEnabled(state),
  };
};

export default connect(mapStateToProps)(Account);

export const testExports = {
  mapStateToProps,
};
