import React from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import StartAccount from './StartAccount';
import NameTeam from './NameTeam';
import AddMembers from './AddMembers';
import Success from './Success';
import UploadTeamImage from './UploadTeamImage';
import UploadImageForMe from './UploadImageForMe';
import StartAccountOrGoToV4 from './StartAccountOrGoToV4';

// analytics page name passed to segment
export const SETUP_ACCOUNT_FLOW = 'setup-account-flow';

export const START_SETUP_ACCOUNT_URL = '/StartAccount';
export const START_SETUP_ACCOUNT_OR_GO_TO_V4_URL = '/StartAccountOrGoToV4';
export const NAME_TEAM_URL = '/NameTeam';
export const ADD_TEAM_MEMBERS_URL = '/AddTeamMembers';
export const UPLOAD_TEAM_IMAGE_URL = '/UploadTeamImage';
export const UPLOAD_IMAGE_FOR_ME_URL = '/UploadImageForMe';
export const SUCCESS_URL = '/Success';

// The steps array is used in `<ProgressDots />` to show the user's progress in the flow.
// `<ProgressDots />` is not shown when the flow starts or when the flow is completed which is why
// we don't include START_SETUP_ACCOUNT_URL and SUCCESS_URL.
export const steps = [
  NAME_TEAM_URL,
  ADD_TEAM_MEMBERS_URL,
  UPLOAD_TEAM_IMAGE_URL,
  UPLOAD_IMAGE_FOR_ME_URL,
];

const ADD_MEMBERS_STEP = 'add-members-step';

export default class SetupAccountFlow extends React.Component {
  componentDidMount() {
    const { accountId, startFlow, redirectFlow, showLinkToV4 } = this.props;
    if (showLinkToV4) {
      redirectFlow(accountId);
    } else {
      startFlow(accountId);
    }
  }

  componentWillUnmount() {
    this.props.endFlow();
  }

  render() {
    const {
      accountId,
      continueFlow,
      hasDefaultTeam,
      teamId,
      showLinktoV4,
    } = this.props;
    return (
      <Router history={history}>
        <Switch>
          <Route
            path={START_SETUP_ACCOUNT_URL}
            render={() => (
              <StartAccount
                accountId={accountId}
                hasDefaultTeam={hasDefaultTeam}
                showLinktoV4={showLinktoV4}
              />
            )}
          />
          <Route
            path={START_SETUP_ACCOUNT_OR_GO_TO_V4_URL}
            render={() => (
              <StartAccountOrGoToV4
                accountId={accountId}
                showLinktoV4={showLinktoV4}
              />
            )}
          />
          <Route
            path={NAME_TEAM_URL}
            render={() => <NameTeam currentStep={history.location.pathname} />}
          />
          <Route
            path={ADD_TEAM_MEMBERS_URL}
            render={() => (
              <AddMembers
                currentStep={history.location.pathname}
                onContinue={continueFlow}
                teamId={teamId}
                trackingPage={ADD_MEMBERS_STEP}
              />
            )}
          />
          <Route
            path={UPLOAD_TEAM_IMAGE_URL}
            render={() => (
              <UploadTeamImage
                teamId={teamId}
                currentStep={history.location.pathname}
              />
            )}
          />
          <Route
            path={UPLOAD_IMAGE_FOR_ME_URL}
            render={() => (
              <UploadImageForMe currentStep={history.location.pathname} />
            )}
          />
          <Route path={SUCCESS_URL} component={Success} />
        </Switch>
      </Router>
    );
  }
}

SetupAccountFlow.propTypes = {
  accountId: PropTypes.string.isRequired,
  startFlow: PropTypes.func.isRequired,
  endFlow: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
  continueFlow: PropTypes.func.isRequired,
  hasDefaultTeam: PropTypes.bool,
  showLinktoV4: PropTypes.bool,
};

SetupAccountFlow.defaultProps = {
  hasDefaultTeam: true,
};
