import { indexOf } from 'lodash';

export const getAssetIdForViewer = () => {
  const { pathname, search } = window.location;
  const params = new URLSearchParams(search);
  const version = params.get('version');
  const a1 = params.get('a1');
  const urlArray = pathname.split('/');
  const basePath = urlArray[1] || '';

  if (version) {
    return version;
  }
  if (a1) {
    return a1;
  }
  if (basePath === 'reviews') {
    return urlArray[3];
  }
  if (basePath === 'player') {
    return urlArray[2];
  }

  return null;
};

const isVersionedUrl = () => {
  const { pathname } = window.location;
  const urlArray = pathname.split('/');
  const basePath = urlArray[1] || '';

  // check case that it's on the compare route
  if (basePath === 'compare') {
    return !!urlArray[2];
  }

  // check case of review link that's on the compare route
  // as only version stacks can do this
  if (indexOf(urlArray, 'reviews') > -1 && indexOf(urlArray, 'compare') > -1) {
    return true;
  }

  return false;
};

export const isVersionStack = () => {
  const { pathname, search } = window.location;
  const params = new URLSearchParams(search);
  const version = params.get('version');

  return !!version || isVersionedUrl(pathname);
};

export const determinePlayerType = () => {
  const { pathname } = window.location;
  const review = pathname.includes('reviews') && 'review link';
  const player =
    (pathname.includes('player') || pathname.includes('compare')) && 'project';
  return review || player;
};

export const getReviewLinkId = () => {
  const { pathname } = window.location;
  const urlArray = pathname.split('/');
  const basePath = urlArray[1] || '';

  if (basePath === 'reviews') {
    return urlArray[2];
  }

  return null;
};
