import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import PropTypes from 'prop-types';
import Button from '@frameio/components/src/styled-components/Button';
import ErrorExlamation from '@frameio/components/src/svgs/icons/16/error-exclamation.svg';
import ModalHeader from '@frameio/components/src/styled-components/Modal/ModalHeader';
import { ModalFooter } from '@frameio/components/src/styled-components/Modal/ModalContent';
import { Head, Wrapper } from '../sharedComponents';
import AssetRow from './AssetRow';

const StyledAssetRow = styled(AssetRow)`
  padding: ${(p) => `0 0 ${p.theme.spacing.units(2)}`};
  border-bottom: 1px solid #f1f3f6;

  .thumb-box {
    margin-right: ${(p) => p.theme.spacing.units(2)};
  }
`;

const Body = styled.div`
  padding: ${(p) => p.theme.spacing.medium};

  ${StyledAssetRow} {
    margin: 0 0 ${(p) => p.theme.spacing.units(2)};
  }
`;

const StyledWrapper = styled(Wrapper)`
  width: 560px;
`;

const Summary = styled(Flex)`
  ${(p) => p.theme.fontStyle.bodyM}
  color: ${(p) => p.theme.color.red500};
`;

function MergeFailed({ closeModal, assetIds, mismatchingProps }) {
  const hasFramerateError = mismatchingProps.includes('fps');
  const hasFramesError = mismatchingProps.includes('frames');
  let summary = '';
  if (hasFramerateError) summary += 'frame rates ';
  if (hasFramerateError && hasFramesError) summary += 'and ';
  if (hasFramesError) summary += 'frame counts ';
  summary += 'don‘t match';
  summary = summary.charAt(0).toUpperCase() + summary.slice(1);

  return (
    <StyledWrapper>
      <Head>
        <ModalHeader>Merge Failed</ModalHeader>
      </Head>
      <Body>
        {assetIds.map((assetId) => (
          <StyledAssetRow
            key={assetId}
            assetId={assetId}
            mismatchingProps={mismatchingProps}
          />
        ))}
        <Summary alignCenter>
          {summary}&nbsp;&nbsp;
          <ErrorExlamation />
        </Summary>
      </Body>
      <ModalFooter>
        <Button data-test-id="confirm-button" primary onClick={closeModal}>
          Ok
        </Button>
      </ModalFooter>
    </StyledWrapper>
  );
}

MergeFailed.propTypes = {
  mismatchingProps: PropTypes.arrayOf(PropTypes.string).isRequired,
  assetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default MergeFailed;
export const testExports = {
  StyledAssetRow,
  AssetRow,
  Summary,
};
