import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Truncate from '@frameio/components/src/styled-components/Truncate';
import Measured from '@frameio/components/src/components/Measured';
import { Tooltip } from '@frameio/vapor';

const AssetTooltipTitle = styled.span`
  overflow-wrap: break-word;
`;

const Ruler = styled.span`
  visibility: hidden;
  pointer-events: none;
  position: fixed;
  left: 0;
`;

const StyledTruncate = styled(Truncate)`
  line-height: normal;
`;

const AssetTitleWithToolTip = ({ measured, onRef, title }) => {
  const [rulerWidth, setRulerWidth] = useState(null);

  const rulerRef = useCallback(
    (rulerNode) => setRulerWidth(rulerNode?.getBoundingClientRect().width),
    // We want a new callback when the title changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [title]
  );

  const isTruncated = useMemo(() => measured.width < rulerWidth, [
    measured.width,
    rulerWidth,
  ]);

  return (
    <>
      <Tooltip
        title={<AssetTooltipTitle>{title}</AssetTooltipTitle>}
        position="bottom"
        shouldUsePortal
        disabled={!isTruncated}
      >
        <StyledTruncate className="pr-fio2" ref={onRef}>
          {title}
        </StyledTruncate>
      </Tooltip>
      <Ruler ref={rulerRef}>{title}</Ruler>
    </>
  );
};

AssetTitleWithToolTip.propTypes = {
  measured: Measured.propType.isRequired,
  onRef: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default Measured(AssetTitleWithToolTip, true);
