import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import styled, { css } from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';

import { ENTER_DURATION } from '@frameio/components/src/components/Modal';
import { absoluteFill, touchScrollingY } from '@frameio/components/src/mixins';
import CrossFader from '@frameio/components/src/styled-components/CrossFader';
import PulsingCircle from '@frameio/components/src/styled-components/PulsingCircle';
import Caret from '@frameio/components/src/svgs/raw/ic-arrow-16px.svg';
import { getMediaType } from '@frameio/core/src/assets/helpers/mediaTypes';

import track from 'analytics';
import { writeText } from 'clipboard-polyfill';
import { MEDIUM_UP } from 'utils/mediaQueries';
import Presentation from 'components/Presentation';
import { REEL_LAYOUT } from 'components/Presentation/layouts';
import PresentationSidePanel from 'components/PresentationEditor/PresentationSidePanel';
import EmptyPresentationPlaceholder from 'components/Presentation/EmptyPresentationPlaceholder';

import PresentationEditorHeader from './PresentationEditorHeader';
import ReelPlayerBlock from './ReelPlayerBlock';
import Toggle from './Toggle';

export const SIDE_PANEL_WIDTH = '280px';
export const TRANSITION_EASING = 'cubic-bezier(0.25, 0, 0, 1)';
export const TRANSITION_DURATION = 350;
export const Container = styled.div`
  height: 100%;
  width: 100%;
  background: ${(p) => p.theme.color.white};
`;

export const StyledCrossFader = styled(CrossFader)`
  ${touchScrollingY()};
  width: 100%;
`;

const Wrapper = styled(Flex)`
  background-color: ${(p) => p.theme.color.silver};
`;

export const PanelPresentationContainer = styled(Flex)`
  ${({ isLargeScreen }) =>
    isLargeScreen ? 'position: relative; height: 100%;' : touchScrollingY()}
`;

const fullWidthOnSmallScreens = css`
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
`;

export const PanelOuterContainer = styled(Flex)`
  position: relative;
  width: ${({ isVisible }) => (!isVisible ? 0 : SIDE_PANEL_WIDTH)};
  transition: width 0.3s ease-in-out;
  height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  ${fullWidthOnSmallScreens};
`;

export const PanelContentContainer = styled.div`
  ${touchScrollingY()};
  border-right: 1px solid ${(p) => p.theme.color.silver};
  transform: ${({ isVisible }) =>
    !isVisible
      ? `translate3d(-${SIDE_PANEL_WIDTH}, 0, 0)`
      : 'translate3d(0, 0, 0)'};
  transition: transform 0.3s ease-in-out;
  width: ${SIDE_PANEL_WIDTH};
  height: 100%;
  ${fullWidthOnSmallScreens};
`;

export const StyledSidePanel = styled(PresentationSidePanel)`
  width: ${SIDE_PANEL_WIDTH};
`;

export const PresentationPreview = styled(FlexItem)`
  ${({ isReelLayout }) => !isReelLayout && touchScrollingY()};
  height: ${({ isReelLayout }) => isReelLayout && '100%'};
  overflow-y: ${({ isReelLayout }) => isReelLayout && 'hidden'};
  overflow-x: hidden;
  padding: ${(p) => p.theme.spacing.medium};
  flex: 1;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 30px 5px rga(0, 0, 0, 0.06);
`;

const StyledPresentation = styled(Presentation)`
  border-radius: ${(p) => p.theme.radius.large};
  min-height: 100%;
`;

const StyledCaret = styled(Caret)`
  width: 8px;
  height: 13px;

  use {
    fill: ${(p) => p.theme.color.slateGray};
  }
`;

export const CaretToggle = styled(Toggle)`
  position: absolute;
  left: ${({ isOn }) => (isOn ? '250px' : '10px')};
  transform: ${({ isOn }) => (isOn ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: 0.3s ease-in-out;
  transition-property: left, transform;
  top: ${(p) => p.theme.spacing.medium};
`;

// Overlays the block right on top of the preview presentation page, minus the
// margins around it.
const ReelPlayerBlockWrapper = styled.div`
  position: relative;
  ${({ theme }) => absoluteFill(theme.spacing.medium)};
  border-radius: ${(p) => p.theme.radius.large};
  overflow: hidden;
`;

/**
 * PresentationEditor
 * @param {any} props - Props.
 */

export default class PresentationEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPanelOpen: true,
      showBody: false,
    };
  }

  componentDidMount() {
    // prevents elements rendering before modal animates to full size
    this.showBodyTimeout = setTimeout(() => {
      this.setState({ showBody: true });
    }, ENTER_DURATION);
  }

  componentDidUpdate(prevProps) {
    const { presentation, selectedAsset } = this.props;
    if (presentation && prevProps.presentation !== presentation) {
      track('media-sharing-settings-changed', {
        client: 'web',
        presentation_id: presentation.id,
        presentation_page_layout: presentation.layout,
        item_type: selectedAsset ? selectedAsset.type : null,
        media_type: selectedAsset ? getMediaType(selectedAsset) : null,
        sharing_settings: presentation.enabled,
        sharing_settings_download: presentation.can_download,
        sharing_settings_password: !!presentation.password,
        sharing_settings_expiration_date: presentation.expires_at,
        sharing_settings_show_upload_date: presentation.include_upload_date,
        sharing_settings_show_file_ext: presentation.include_ext,
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.showBodyTimeout);
  }

  copyLink = (url) => {
    const { showLinkCopiedToast, presentation } = this.props;
    writeText(url) &&
      presentation.enabled &&
      showLinkCopiedToast({ header: 'Copied!' });
  };

  handleSettingsChange = (changes) => {
    const { presentation, updatePresentation } = this.props;
    updatePresentation(presentation.id, changes);
  };

  /**
   * @returns {ReactElement} - PresentationEditor.
   */
  render() {
    const {
      accountId,
      assets,
      closeModal,
      isAuthorized,
      isBlockingReelPlayer,
      presentation,
      team,
      title,
      vanityId,
    } = this.props;
    const { isPanelOpen, showBody } = this.state;
    const isReelLayout =
      presentation.layout === REEL_LAYOUT || isBlockingReelPlayer;

    return (
      <Container>
        {presentation && showBody ? (
          <Media query={MEDIUM_UP}>
            {(isLargeScreen) => (
              <Wrapper column full>
                <PresentationEditorHeader
                  enabled={presentation.enabled}
                  onCloseButtonClick={closeModal}
                  onSettingsChange={this.handleSettingsChange}
                  onLinkCopy={this.copyLink}
                  shortUrl={presentation.short_url}
                  vanityId={vanityId}
                />
                <PanelPresentationContainer
                  alignStretch
                  isLargeScreen={isLargeScreen}
                >
                  <PanelOuterContainer
                    isVisible={isPanelOpen}
                    noShrink
                    fullWidth={!isLargeScreen}
                  >
                    <PanelContentContainer
                      isVisible={isPanelOpen}
                      fullWidth={!isLargeScreen}
                    >
                      <StyledSidePanel
                        onSettingsChange={this.handleSettingsChange}
                        presentationId={presentation.id}
                      />
                    </PanelContentContainer>
                  </PanelOuterContainer>

                  {isLargeScreen && (
                    <React.Fragment>
                      <StyledCrossFader
                        timeout={TRANSITION_DURATION}
                        easing={TRANSITION_EASING}
                      >
                        <PresentationPreview
                          key={isReelLayout}
                          isReelLayout={isReelLayout}
                        >
                          {assets.length ? (
                            <StyledPresentation
                              accountId={accountId}
                              readOnly
                              trackingPage="presentation modal"
                              logo={team && team.team_image}
                              title={title}
                              presentation={presentation}
                              assets={assets}
                              team={team}
                              isAuthorized={isAuthorized}
                            />
                          ) : (
                            <EmptyPresentationPlaceholder />
                          )}
                          {isBlockingReelPlayer && (
                            <ReelPlayerBlockWrapper>
                              <ReelPlayerBlock
                                presentationId={presentation.id}
                              />
                            </ReelPlayerBlockWrapper>
                          )}
                        </PresentationPreview>
                      </StyledCrossFader>
                      <CaretToggle
                        isOn={isPanelOpen}
                        onClick={(e) =>
                          this.setState({ isPanelOpen: e.target.checked })
                        }
                      >
                        <StyledCaret height="13px" width="8px" />
                      </CaretToggle>
                    </React.Fragment>
                  )}
                </PanelPresentationContainer>
              </Wrapper>
            )}
          </Media>
        ) : (
          <PulsingCircle />
        )}
      </Container>
    );
  }
}

PresentationEditor.defaultProps = {
  assets: [],
  isAuthorized: true,
  presentation: {},
  title: '',
  selectedAsset: null,
};

PresentationEditor.propTypes = {
  accountId: PropTypes.string.isRequired,
  assets: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isBlockingReelPlayer: PropTypes.bool.isRequired,
  presentation: PropTypes.object,
  selectedAsset: PropTypes.object.isRequired, // only used for tracking
  showLinkCopiedToast: PropTypes.func.isRequired,
  team: PropTypes.shape({
    background_color: PropTypes.string,
    color: PropTypes.string,
    font_color: PropTypes.string,
    team_image: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  updatePresentation: PropTypes.func.isRequired,
  vanityId: PropTypes.string.isRequired,
};

export const testExports = {
  CaretToggle,
  PresentationPreview,
  PulsingCircle,
  StyledSidePanel,
  Wrapper,
};
