import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const MUX = generateActionTypes('MUX', [
  'INIT_MONITOR',
  'MONITOR_ASSET',
]);

/**
 * Initialize Mux on the <video>. This should happen as soon as possible, as
 * soon as the <video> is mounted.
 *
 * - `videoEl`, `accountId`, `trackingPage`, `initialEncodingVariant`, and `isSessionWatermarked` must be
 *   made available and passed in immediately.
 * - `assetId` can be deferred to `monitorAsset()` later.
 *
 * @param {string} accountId - The id of the account that the video belongs to.
 * @param {boolean} autoplay - Whether autoplay is attempted.
 * @param {string} assetId - Pass in the asset id for <video>s with `src` attrs
 *   that don't change.
 * @param {HLS} hlsInstance - The HLS.js instance, if available.
 * @param {string} trackingPage - The player type, e.g.
 *   player/presentation/review page etc.
 * @param {HTMLVideoElement} videoEl - The <video> element.
 */
export const initMuxMonitor = createAction(
  MUX.INIT_MONITOR,
  ({
    accountId,
    assetId,
    autoplay,
    isSessionWatermarked,
    initialEncodingVariant,
    playerName,
    trackingPage,
    videoEl,
  }) => ({
    accountId,
    assetId,
    autoplay,
    initialEncodingVariant,
    isSessionWatermarked,
    playerName,
    trackingPage,
    videoEl,
  })
);

/**
 * Monitor the asset. Usually called when a new asset results in a new video
 * source.
 *
 * @param {HTMLVideoElement} videoEl - The <video> element.
 * @param {string} assetId - Asset id.
 */
export const monitorAsset = createAction(
  MUX.MONITOR_ASSET,
  (videoEl, assetId) => ({ videoEl, assetId })
);

// Silence warnings emitted by Redux Chrome extension when it tries to serialize
// the video element and HLS.js instance
export function actionSanitizer(action) {
  const { type, payload } = action;
  if (type === MUX.INIT_MONITOR || type === MUX.MONITOR_ASSET) {
    const sanitizedPayload = { ...payload };
    sanitizedPayload.videoEl = '<video> element';
    if (sanitizedPayload.hlsInstance) {
      sanitizedPayload.hlsInstance = 'HLS.js instance';
    }
    return {
      ...action,
      payload: sanitizedPayload,
      sanitized: true,
    };
  }
  return action;
}
