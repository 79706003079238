// dimensions of a rotated rectangle inside a bounding box
export default function getTrueDimensions([bboxW, bboxH], rotation = 0) {
  if (!rotation) return [bboxW, bboxH];
  const theta = (Math.abs(rotation) * Math.PI) / 180;

  const x =
    (bboxW * Math.cos(theta) - bboxH * Math.sin(theta)) /
    (Math.cos(theta) * Math.cos(theta) - Math.sin(theta) * Math.sin(theta));
  const y =
    (bboxW * Math.sin(theta) - bboxH * Math.cos(theta)) /
    (Math.sin(theta) * Math.sin(theta) - Math.cos(theta) * Math.cos(theta));
  return [x, y];
}
