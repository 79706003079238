import React from 'react';
import { select, put, takeLatest, spawn } from 'redux-saga/effects';
import { openModal } from 'components/Modal/actions';
import { setProjectId } from 'components/ProjectForms/EditProject/actions';
import ArchiveProjectFlow from 'components/ArchiveProjectFlow';
import { permittedActionsForProjectSelector } from 'selectors/permissions';
import { currentAccountEntitySelector } from 'selectors/accounts';
import { isAccountAdminSelector } from 'selectors/roles';
import { openSelectPlanFlowModal } from 'components/SelectPlanFlow/actions';
import { allFeatures } from 'planFeatures';
import track from 'analytics';
import { ARCHIVE_PROJECT } from './actions';

function* startArchiveProject(projectId) {
  yield put(setProjectId(projectId));
  const { canArchiveProject } = yield select(
    permittedActionsForProjectSelector,
    { projectId }
  );

  if (canArchiveProject) {
    yield put(
      openModal(<ArchiveProjectFlow projectId={projectId} />, {
        canCloseModal: false,
      })
    );
    return;
  }

  const account = yield select(currentAccountEntitySelector);
  const { id: accountId } = account;
  const isAdmin = yield select(isAccountAdminSelector, { accountId });
  if (!isAdmin) return;

  yield spawn(track, 'feature-gate-clicked-client', {
    _limit: allFeatures.archivalStorage,
  });

  yield put(
    openSelectPlanFlowModal(accountId, {
      source: 'archival project feature gate',
    })
  );
}

export default [
  takeLatest(ARCHIVE_PROJECT.START, ({ payload: { projectId } }) =>
    startArchiveProject(projectId)
  ),
];
