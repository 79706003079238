import React from 'react';
import PropTypes from 'prop-types';
import transitionFactory from 'react-transition-factory';
import CrossFadeTransitionGroup from '@frameio/components/src/styled-components/CrossFadeTransitionGroup';

import {
  DROP_PRIMARY_TIMEOUT,
  DROP_PRIMARY_POSITION_EASING,
  THUMB_SPACING,
  FolderAssetThumb,
} from './FolderThumb';

const FlexTransition = transitionFactory('flex');
const Transition = transitionFactory('width', 'height', 'transform');

export default function PrimaryToSecondaryThumb({
  assetId,
  assetIdsToMove,
  hasSecondaryThumbs,
  isMovingToLastSlot,
  remainingSlots,
  thumbOffsetX,
}) {
  return (
    <FlexTransition
      start="0 0 0"
      end="1 0 0"
      timeout={DROP_PRIMARY_TIMEOUT}
      easing={DROP_PRIMARY_POSITION_EASING}
    >
      {/*
        This intermediary <div> is necessary to figure out what the end location / size is
        since we set position: absolute on the actual thumb to animate it.
      */}
      <div>
        <Transition
          start={
            hasSecondaryThumbs
              ? [
                  `calc(200% - ${thumbOffsetX / 2}px)`,
                  '100%',
                  `translateX(calc(-100% - ${THUMB_SPACING}))`,
                ]
              : ['100%', '100%', 'translate(0)']
          }
          end={[
            '100%',
            remainingSlots <= assetIdsToMove.length
              ? `calc((100% - ${THUMB_SPACING}) / 2)`
              : '100%',
            'translate(0)',
          ]}
          timeout={DROP_PRIMARY_TIMEOUT}
          easing={DROP_PRIMARY_POSITION_EASING}
        >
          {/*
              If we're dragging multiple assets, what ends up in the 2nd position is not
              the previous primary asset, since the dropped assets would have pushed the
              original primary out of the way:
                Dropping 1 items: [primary, secondary, count] -> [new, primary, count + 1]
                Dropping 3 items : [primary, secondary, count] -> [new1, new2, count + 3]
              So we need to cross fade the primary and new2, if it exists.
            */}
          <CrossFadeTransitionGroup
            position="absolute"
            in={assetIdsToMove.length > 1 && !isMovingToLastSlot}
            timeout={DROP_PRIMARY_TIMEOUT}
            easing={DROP_PRIMARY_POSITION_EASING}
            style={{ bottom: isMovingToLastSlot ? 0 : undefined }}
            from={
              <FolderAssetThumb
                assetId={assetId}
                height="100%"
                width="100%"
                offsetX={thumbOffsetX}
              />
            }
            to={
              <FolderAssetThumb
                assetId={assetIdsToMove[1]}
                height="100%"
                width="100%"
              />
            }
          />
        </Transition>
      </div>
    </FlexTransition>
  );
}

PrimaryToSecondaryThumb.propTypes = {
  assetId: PropTypes.string.isRequired,
  assetIdsToMove: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasSecondaryThumbs: PropTypes.bool.isRequired,
  isMovingToLastSlot: PropTypes.bool,
  remainingSlots: PropTypes.number.isRequired,
  thumbOffsetX: PropTypes.number.isRequired,
};

PrimaryToSecondaryThumb.defaultProps = {
  isMovingToLastSlot: false,
};
