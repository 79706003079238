import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import track, { trackButtonClick } from 'analytics';
import Dialog, {
  dialogTypes,
} from '@frameio/components/src/styled-components/Dialog';
import Button from '@frameio/components/src/styled-components/Button';
import CloseButton from '@frameio/components/src/components/Modal/CloseButton';
import StartAccountIllustration from './start-account-setup.svg';
import { SETUP_ACCOUNT_FLOW } from '../SetupAccountFlow';

// analytics page name passed to segment
export const START_ACCOUNT_STEP = 'start-account-step';

const StyledDialog = styled(Dialog)`
  height: ${(p) => p.theme.spacing.units(50)};
  padding: ${(p) => p.theme.spacing.medium};
`;

const StartAccountIllustrationContainer = styled(Flex)`
  margin-top: ${(p) => p.theme.spacing.tiny};
  margin-bottom: ${(p) => p.theme.spacing.medium};
`;

const Header = styled.span`
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: ${(p) => p.theme.fontSize[3]};
  color: ${(p) => p.theme.color.coolBlack};
  margin-bottom: ${(p) => p.theme.spacing.units(2)};
`;

const Copy = styled.p`
  color: ${(p) => p.theme.color.gray};
  text-align: center;
  padding: 0 10px;
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: ${(p) => p.theme.spacing.tiny};
  right: ${(p) => p.theme.spacing.tiny};
`;

export default function StartAccount({
  onCancel,
  applyStarterTrial,
  displayNameForAccount,
  hasDefaultTeam,
  isApplyingPromotion,
  logOut,
}) {
  const onClick = () => {
    if (hasDefaultTeam) {
      trackButtonClick('maybe later', SETUP_ACCOUNT_FLOW, START_ACCOUNT_STEP);
      track('setup-account-flow-closed', { step: START_ACCOUNT_STEP });
      onCancel();
    } else {
      trackButtonClick('log out', SETUP_ACCOUNT_FLOW, START_ACCOUNT_STEP);
      track('setup-account-flow-closed', { step: START_ACCOUNT_STEP });
      logOut();
    }
  };

  return (
    <StyledDialog
      type={dialogTypes.NONE}
      primaryButton={
        <Button
          primary
          onClick={() => {
            trackButtonClick('start', SETUP_ACCOUNT_FLOW, START_ACCOUNT_STEP);
            applyStarterTrial();
          }}
          disabled={isApplyingPromotion}
          showSpinner={isApplyingPromotion}
        >
          Start my free account
        </Button>
      }
      secondaryButton={
        <Button text onClick={onClick}>
          {hasDefaultTeam ? 'Maybe later' : 'Log out'}
        </Button>
      }
    >
      {hasDefaultTeam && (
        <StyledCloseButton
          onClick={() => {
            trackButtonClick('close', SETUP_ACCOUNT_FLOW, 'middle');
            track('setup-account-flow-closed');
            onCancel();
          }}
        />
      )}
      <StartAccountIllustrationContainer justifyCenter>
        <StartAccountIllustration />
      </StartAccountIllustrationContainer>
      <Header>Set up your own account</Header>
      <Copy>
        {hasDefaultTeam
          ? `Right now you’re a collaborator on ${displayNameForAccount}’s account. Create your own account to speed up work with your own team and clients.`
          : 'It looks like you’re not currently a member of any accounts. Start your own account to accelerate feedback and review with your team and clients.'}
      </Copy>
    </StyledDialog>
  );
}

StartAccount.propTypes = {
  onCancel: PropTypes.func,
  applyStarterTrial: PropTypes.func,
  displayNameForAccount: PropTypes.string,
  isApplyingPromotion: PropTypes.bool,
  hasDefaultTeam: PropTypes.bool.isRequired,
};

StartAccount.defaultProps = {
  onCancel: noop,
  applyStarterTrial: noop,
  displayNameForAccount: 'someone',
  isApplyingPromotion: false,
};
