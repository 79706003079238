import { connect } from 'react-redux';
import { currentAccountSelector } from 'selectors/accounts';
import { closeModal } from 'components/Modal/actions';
import CancelAccountFlow from './CancelAccountFlow';
import { startFlow, endFlow, continueFlow } from './actions';

const mapDispatchToProps = {
  startFlow,
  endFlow,
  continueFlow,
  onCancel: closeModal,
};

function mapStateToProps(state) {
  const { id: accountId } = currentAccountSelector(state) || {};

  return {
    accountId,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelAccountFlow);

export const testExports = {
  mapDispatchToProps,
};
