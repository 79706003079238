import React from 'react';
import { openModal, updateModal } from 'components/Modal/actions';
import { getAssetChildren } from '@frameio/core/src/assets/sagas';
import { createDelegatedSaga } from '@frameio/core/src/shared/sagas/helpers';
import { call, spawn, put, takeEvery } from 'redux-saga/effects';
import { showErrorToast } from 'actions/toasts';
import track from 'analytics';
import {
  removeVersionFromStack,
  reorderVersionInStack,
  TRACKING_EVENTS,
} from 'components/ManageVersionStack/sagas';
import ManageVersionStackModal from '.';
import { MANAGE_VERSION_STACK_MODAL } from './actions';
import { selectAssets } from '../actions';

const getVersionStackAssets = createDelegatedSaga(
  MANAGE_VERSION_STACK_MODAL.FETCH,
  getAssetChildren
);

function* openManageVersionStack(assetId) {
  const page = 'project_view';
  yield put(selectAssets([]));
  yield put(openModal(<ManageVersionStackModal assetId={assetId} />));

  const { failure } = yield call(getVersionStackAssets, assetId);

  if (failure) {
    yield put(
      showErrorToast({
        header: 'An error occurred while fetching the version stack',
      })
    );
  }

  yield spawn(track, TRACKING_EVENTS.COMPONENT_SHOWN, { page });
}

function* optimisticOnBeforeRemove(versionStackId, lastChildId) {
  if (lastChildId) {
    yield put(
      updateModal({
        children: <ManageVersionStackModal assetId={lastChildId} />,
      })
    );
  }
}

function* removeVersionFromStackInModal(childAssetId, totalVersions) {
  // update the manage version stack modal optimistically with the id of the last remaining
  // child asset. the unversion action has not been issued yet, but it will appear that the
  // asset the user clicked on has been removed from the version stack. the optimistic update
  // avoids a "glitchy" or laggy interaction while waiting on the unversion request to respond
  yield call(
    removeVersionFromStack,
    childAssetId,
    totalVersions,
    optimisticOnBeforeRemove
  );
}

function* reorderVersionStackInModal(childAssetId, prevAssetId, nextAssetId) {
  const location = 'project_view';
  yield call(
    reorderVersionInStack,
    childAssetId,
    prevAssetId,
    nextAssetId,
    location
  );
}

export default [
  takeEvery(
    MANAGE_VERSION_STACK_MODAL.REMOVE_FROM_STACK,
    ({ payload: { childAssetId, totalVersions } }) =>
      removeVersionFromStackInModal(childAssetId, totalVersions)
  ),
  takeEvery(MANAGE_VERSION_STACK_MODAL.OPEN, ({ payload: { assetId } }) =>
    openManageVersionStack(assetId)
  ),
  takeEvery(
    MANAGE_VERSION_STACK_MODAL.REORDER_VERSION_ASSET,
    ({ payload: { childAssetId, prevAssetId, nextAssetId } }) =>
      reorderVersionStackInModal(childAssetId, prevAssetId, nextAssetId)
  ),
];

export const testExports = {
  openManageVersionStack,
  optimisticOnBeforeRemove,
  removeVersionFromStackInModal,
  reorderVersionStackInModal,
  getVersionStackAssets,
};
