import { connect } from 'react-redux';
import { confirmCreateDualView } from 'pages/ProjectContainer/DualView/actions';
import CreateDualView from './CreateDualView';

const mapDispatchToProps = {
  confirmCreateDualView,
};

export default connect(
  null,
  mapDispatchToProps
)(CreateDualView);
export const testExports = { mapDispatchToProps };
