import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MEDIUM_UP } from 'utils/mediaQueries';
import Media from 'react-media';
import track from 'analytics';
import transitionFactory from 'react-transition-factory';
import CancelIcon from '@frameio/components/src/svgs/icons/cancel-small.svg';
import User from '../../PeopleList/User';

import {
  RemoveButton,
  MobileRemoveButton,
  Wrapper,
  StyledRemovePopover,
} from '../../../pages/ProjectContainer/ManagePeople/ManagePeopleList/ManagePerson/sharedStyles';

const MODAL_BUTTON_POSITION = 'invite_people';

const SwipeTransition = transitionFactory('width');

const StyledWrapper = styled(Wrapper)`
  && {
    ${MobileRemoveButton} {
      right: 0;
    }
  }
`;

function PersonRow({ entity, type, deleteInvitee, entityId }) {
  const [isRemoveButtonVisible, setIsRemoveButtonVisible] = useState(false);

  const removeButton = (
    <RemoveButton
      onClick={() => {
        setIsRemoveButtonVisible(true);
        track('review-link-modal-button-clicked', {
          title: 'remove_existing_share_link_user',
          page: 'review_link_modal',
          position: MODAL_BUTTON_POSITION,
        });
      }}
    >
      <CancelIcon width={16} height={16} />
    </RemoveButton>
  );

  return (
    <StyledWrapper
      onClick={() => {
        if (isRemoveButtonVisible) {
          setIsRemoveButtonVisible(false);
        }
      }}
    >
      <User
        email={type === 'reviewer' ? entity?.email : 'Link sent'}
        image={entity?.image_64}
        color={entity?.user_default_color}
        name={type === 'reviewer' ? entity?.name : entity?.email}
        lastActivityDate={entity?.seen_at}
        lastActivityPrefix="last viewed"
        lastActivityFallback="never viewed"
      />

      <Media query={MEDIUM_UP} data-test-id="media-query-remove">
        {(isMediumUp) =>
          isMediumUp ? (
            <StyledRemovePopover
              onCancel={() => {
                track('review-link-modal-button-clicked', {
                  title: 'cancel_remove_existing_share_link_user',
                  page: 'review_link_modal',
                  position: MODAL_BUTTON_POSITION,
                });
              }}
              onConfirm={() => {
                deleteInvitee(entityId);

                track('review-link-modal-button-clicked', {
                  title: 'confirm_remove_existing_share_link_user',
                  page: 'review_link_modal',
                  position: MODAL_BUTTON_POSITION,
                });
              }}
              title={
                type === 'pending_reviewer'
                  ? 'Remove pending reviewer?'
                  : 'Remove reviewer?'
              }
              confirmButtonText="Remove"
              position="right"
            >
              {removeButton}
            </StyledRemovePopover>
          ) : (
            <React.Fragment>
              {removeButton}
              <SwipeTransition
                in={isRemoveButtonVisible}
                start={0}
                end={80}
                timeout={150}
              >
                <MobileRemoveButton
                  onClick={() => {
                    deleteInvitee(entityId);
                  }}
                >
                  Remove
                </MobileRemoveButton>
              </SwipeTransition>
            </React.Fragment>
          )
        }
      </Media>
    </StyledWrapper>
  );
}

PersonRow.propTypes = {
  entity: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  deleteInvitee: PropTypes.func.isRequired,
  entityId: PropTypes.string.isRequired,
};

export default PersonRow;
