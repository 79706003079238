import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { size, rgba } from 'polished';
import { StaggeredMotion, spring } from 'react-motion';
import transitionFactory, { opacity } from 'react-transition-factory';
import { alignCenter } from '@frameio/components/src/mixins';
import { ScaleTransition } from 'react-transition-components';
import { iconSizes } from '@frameio/components/src/utils/sizes';
import Preview from './Preview';

const PREVIEW_OFFSET = 10;
const MAX_THUMB_COUNT = 3;
const TRANSLATE_ON_PICKUP = 20;
const PICKUP_TRANSITION_PROPS = {
  timeout: 350,
  easing: 'ease-in-out',
};
const TRANSITION_DELAY = 50;
const PREVIEW_WIDTH = 130;
const PREVIEW_HEIGHT = PREVIEW_WIDTH / (16 / 9);
const DRAGGING_SPRING = { stiffness: 580, damping: 50 };

const OnPickUpTransition = transitionFactory(opacity, 'transform');

const Count = styled.div`
  ${alignCenter()};
  ${size(`${iconSizes.L}px`)}
  ${(p) => p.theme.fontStyle.body};
  background-color: ${(p) => p.theme.color.progress};
  border-radius: ${(p) => p.theme.radius.circle};
  box-shadow: 0 2px 8px 0 ${({ theme }) => rgba(theme.color.black, 0.6)};
  color: ${(p) => p.theme.color.white};
  padding: ${(p) => p.theme.spacing.small};
`;

export default class AssetsPreview extends React.Component {
  state = {
    hasCollected: false,
  };

  getStartStyles = (previewTranslations, mouseX, mouseY) => {
    const topIndex = previewTranslations.length - 1;
    return previewTranslations.map((locationData, index) => {
      const indexFromTop = topIndex - index;
      const offset = indexFromTop * PREVIEW_OFFSET;

      return {
        x: mouseX + offset,
        y: mouseY + offset,
      };
    });
  };

  getStaggeredStyles = (mouseX, mouseY) => (prevStyles) =>
    prevStyles.map((prevStyle, index) => {
      const topIndex = prevStyles.length - 1;
      if (index === topIndex) {
        return {
          x: mouseX,
          y: mouseY,
        };
      }
      const indexFromTop = topIndex - index;
      const { x, y } = this.state.hasCollected
        ? {
            x: prevStyles[index + 1].x + PREVIEW_OFFSET,
            y: prevStyles[index + 1].y + PREVIEW_OFFSET,
          }
        : {
            x: mouseX + indexFromTop * PREVIEW_OFFSET,
            y: mouseY + indexFromTop * PREVIEW_OFFSET,
          };

      return {
        x: spring(x, DRAGGING_SPRING),
        y: spring(y, DRAGGING_SPRING),
      };
    });

  getPreviewRenderer = (previewTranslations) => (interpolatedStyles) => (
    <div>
      {interpolatedStyles.map(({ x, y }, index) => {
        const { assetId, translateX, translateY } = previewTranslations[index];
        const topIndex = interpolatedStyles.length - 1;
        const indexFromTop = topIndex - index;

        return (
          <div
            key={`${assetId}-${indexFromTop}`}
            style={{
              transform: `translate(${x}px, ${y}px)`,
            }}
          >
            <OnPickUpTransition
              start={[
                0,
                `scale(1.5) translate(${translateX}px, ${translateY}px)`,
              ]}
              end={[1, 'scale(1) translate(0, 0)']}
              {...PICKUP_TRANSITION_PROPS}
              delay={TRANSITION_DELAY * indexFromTop}
              onEntered={
                index === 0
                  ? () => this.setState({ hasCollected: true })
                  : undefined
              }
            >
              <Preview
                assetId={assetId}
                width={PREVIEW_WIDTH}
                height={PREVIEW_HEIGHT}
              />
            </OnPickUpTransition>
          </div>
        );
      })}
    </div>
  );

  mapToTranslations = ({ assetId, rowDiff, columnDiff }) => {
    let translateX = 0;
    if (columnDiff < 0) translateX = -TRANSLATE_ON_PICKUP;
    if (columnDiff > 0) translateX = TRANSLATE_ON_PICKUP;

    let translateY = 0;
    if (rowDiff < 0) translateY = -TRANSLATE_ON_PICKUP;
    if (rowDiff > 0) translateY = TRANSLATE_ON_PICKUP;

    return {
      assetId,
      translateX,
      translateY,
    };
  };

  render() {
    const {
      item: { previewData },
      currentOffset: { x: mouseX, y: mouseY },
    } = this.props;

    // Magic offsets to position the count around the mouse
    const countX = mouseX - iconSizes.L * 0.25;
    const countY = mouseY - iconSizes.L * 0.75;

    const previewTranslations = previewData
      // Prioritise the asset under the mouse when the drag started to the
      // visible top (which is the last DOM child, or last item in the array).
      // The asser under the mouse will have rowDiff and columnDiff of 0, and
      // everything else is relative to it.
      .sort(
        (
          { rowDiff: rowDiffA, columnDiff: columnDiffA },
          { rowDiff: rowDiffB, columnDiff: columnDiffB }
        ) => {
          // If A is the asset under the mouse
          if (!rowDiffA && !columnDiffA) return 1;
          // If B is the asset under the mouse
          if (!rowDiffB && !columnDiffB) return -1;
          return 0;
        }
      )
      .slice(-MAX_THUMB_COUNT)
      .map(this.mapToTranslations);

    return (
      <React.Fragment>
        <StaggeredMotion
          defaultStyles={this.getStartStyles(
            previewTranslations,
            mouseX,
            mouseY
          )}
          styles={this.getStaggeredStyles(mouseX, mouseY)}
        >
          {this.getPreviewRenderer(previewTranslations)}
        </StaggeredMotion>
        {previewData.length > 1 && (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              transform: `translate3d(${countX}px, ${countY}px, 0)`,
            }}
          >
            <ScaleTransition {...PICKUP_TRANSITION_PROPS}>
              <Count>{previewData.length}</Count>
            </ScaleTransition>
          </div>
        )}
      </React.Fragment>
    );
  }
}

AssetsPreview.propTypes = {
  item: PropTypes.shape({
    previewData: PropTypes.arrayOf(
      PropTypes.shape({
        assetId: PropTypes.string.isRequired,
        rowDiff: PropTypes.number.isRequired,
        columnDiff: PropTypes.number.isRequired,
      })
    ),
  }).isRequired,
  currentOffset: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
};

export const testExports = {
  Count,
  DRAGGING_SPRING,
  PREVIEW_HEIGHT,
  PREVIEW_OFFSET,
  PREVIEW_WIDTH,
  TRANSITION_DELAY,
  TRANSLATE_ON_PICKUP,
};
