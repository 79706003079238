import React from 'react';
import { connect } from 'react-redux';
import { openModal } from 'components/Modal/actions';
import DeletedAssetsMenu from 'components/DeletedAssetsMenu';
import RecentlyDeleted from './RecentlyDeleted';

function mapDispatchToProps(dispatch) {
  return {
    onRecentlyDeleted: (projectId) => {
      dispatch(openModal(<DeletedAssetsMenu projectId={projectId} />));
    },
  };
}

export default connect(
  null,
  mapDispatchToProps
)(RecentlyDeleted);

export const testExports = {
  mapDispatchToProps,
};
