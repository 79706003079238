import { createSelector } from 'reselect';
import { get, pick, cloneDeep } from 'lodash';
import ROLES from '@frameio/core/src/roles/helpers/constants';
import {
  accountIdForReviewLinkIdSelector,
  planEntityForAccountIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import { accountIdsForAccountSwitcherSelector } from 'pages/LayoutWithNav/AccountSwitcher/selectors';
import {
  currentAccountEntitySelector,
  currentAccountSelector,
} from 'selectors/accounts';
import { currentFolderSelector } from 'selectors/folders';
import { currentTeamSelector } from 'selectors/teams';
import { currentProjectSelector } from 'selectors/projects';
import {
  currentUserSelector,
  currentUserEntitySelector,
} from 'selectors/users';
import { highestAccountOrTeamRoleForAccountIdSelector } from 'selectors/roles';
import { showAllAnnotationsSelector } from 'pages/PlayerContainer/selectors';
import { subscriptionEntityByAccountIdSelector } from 'selectors/subscriptions';
import { formatBytes } from 'shared/filesizeHelpers';
import {
  enabledFeatureFlagsSelector,
  enterpriseDataRestrictionsEnabled,
  trackingDebugEnabled,
} from 'utils/featureFlags';
import Cookies from 'js-cookie';
import store from 'configureStore';
import config, { isProduction } from 'config';
import { isPathNamePresentationPage, isPathNameReviewPage } from 'URLs';
import {
  ARCHIVED_STORAGE,
  COLLABORATOR_COUNT,
  MEMBER_COUNT,
  PROJECT_COUNT,
  STORAGE,
  USER_COUNT,
} from '@frameio/core/src/accounts/utils/usageTypes';
import {
  ARCHIVED_STORAGE_LIMIT,
  STORAGE_LIMIT,
  TOTAL_MEMBER_LIMIT,
  TOTAL_PROJECT_LIMIT,
  TOTAL_USER_LIMIT,
} from '@frameio/core/src/subscriptionLineItems/utils/modifierTypes';
import { updateTrackingIdentiferInfo } from 'actions/users';
import {
  loadSegment,
  processQueue,
  enqueueOrRun,
  configureSegmentOt,
  getGDPRCookieCategories,
} from '@frameio/segment-ot';
import Raven from 'raven-js';
import { getMediaType } from '@frameio/core/src/assets/helpers/mediaTypes';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import {
  getAuthTokenExpiry,
  getAuthTokenExpired,
  getAuthedUserId,
  getAuthToken,
  getRefreshTokenId,
} from 'utils/auth';
import {
  determinePlayerType,
  getAssetIdForViewer,
  getReviewLinkId,
  isVersionStack,
} from './helpers';

// These selectors are here so we can use them in corePropertiesSelector
function planEntitySelector(state) {
  const accountId = currentAccountSelector(state).id;
  return planEntityForAccountIdSelector(state, { accountId });
}

function accountV2EntitySelector(state) {
  const accountId = currentAccountSelector(state).id;
  return accountEntitySelector(state, { accountId });
}

function subscriptionEntitySelector(state) {
  const accountId = currentAccountSelector(state).id;
  return subscriptionEntityByAccountIdSelector(state, accountId);
}

function getAccountId(currentAccount, state) {
  const currentAccountId = get(currentAccount, 'id', null);
  if (currentAccountId) return currentAccountId;

  const reviewLinkId = getReviewLinkId();
  const accountForAsset = accountIdForReviewLinkIdSelector(state, {
    reviewLinkId,
  });

  return accountForAsset;
}

const isTrackingDebugEnabled = trackingDebugEnabled(store.getState());
export const RESTRICT_DATA_KEY = 'fio-flag-rd';

/**
 * Check if the restrict data cookie is set.
 * @returns {Boolean} True if valid.
 */
export function hasRestrictDataCookie() {
  return Cookies.get(RESTRICT_DATA_KEY) === 'true';
}

/**
 * Remove the restrict data cookie.
 */
export function removeRestrictDataCookie() {
  Cookies.remove(RESTRICT_DATA_KEY, { domain: config.domain });
}

export const isEnterpriseDataRestrictionsEnabled =
  enterpriseDataRestrictionsEnabled(store.getState()) ||
  Cookies.get(RESTRICT_DATA_KEY) === 'true';

const ANALYTICS_ROLES = {
  [ROLES.ADMIN]: ROLES.ADMIN,
  [ROLES.OWNER]: ROLES.OWNER,
  [ROLES.REVIEWER]: ROLES.REVIEWER,
  [ROLES.TEAM_MANAGER]: 'team-manager',
  [ROLES.TEAM_MEMBER]: 'team-member',
  [ROLES.COLLABORATOR]: ROLES.COLLABORATOR,
};

/**
 * Manage the keyboard hotkeys that are tracked here.
 * This is an array list value of keys formatted to send in tracking events.
 */

const ANALYTICS_KEYBOARD_HOTKEYS = [
  'C', // Activate Composer in player view.
  'D', // details
  'F', // fullscreen
  'G', // guides
  'I', // In point
  'J', // play backwards stepPlaybackRate -
  'K', // toggle play
  'L', // toggle play, play forward stepPlayBackRate +
  'M', // Mute / UnMute
  'O', // Out point
  'R', // Range on player comment
  'S', // Split Screen compare view
  'V', // Toggle views for dual view
  'Z', // MoveTo
  'spacebar', // toggle play / quicklook view
];

/**
 * Define the pages allowed to be tracked for keyboard events.
 * This list may shift to have it's own specific keyboard events per page.
 * But for now we are just mapping
 * the name desired in tracking (value) with the actual pathname (key)
 */
const ANALYTICS_KEYBOARD_PAGES = {
  projects: 'project dashboard',
  player: 'player page',
  reviews: 'review page',
  presentations: 'presentation',
  compare: 'player split page',
};

export const IDENTIFY_USER_ATTRS = [
  'id',
  'email',
  'name',
  'first_name',
  'last_name',
  'profile_image',
  'joined_via',
  'timezone_value',
  'user_default_color',
  'from_adobe',
  'is_edu',
];

// Minimal integrations required for Enterprise customers who have data restrictions
const CLIENT_CONTROLLED_DESTINATIONS = {
  Appcues: true,
  AutopilotHQ: true,
  Intercom: true,
  Drift: false,
  'Ortto Sandbox': true,
  'Ortto Event Merging from V3 Prod': true,
  'Ortto Event Merging from V4 Prod': true,
  'Enrichment (Sign Ups)': true,
};

export const corePropertiesSelector = createSelector(
  currentAccountEntitySelector,
  currentFolderSelector,
  currentProjectSelector,
  currentTeamSelector,
  currentUserSelector,
  enabledFeatureFlagsSelector,
  planEntitySelector,
  subscriptionEntitySelector,
  accountV2EntitySelector,
  (state) => state,
  (
    currentAccount,
    currentFolder,
    currentProject,
    currentTeam,
    currentUser,
    enabledFeatureFlags,
    plan,
    subscription,
    account,
    state
  ) => {
    const accountId = getAccountId(currentAccount, state);
    const role =
      highestAccountOrTeamRoleForAccountIdSelector(state, { accountId }) ||
      ROLES.COLLABORATOR;

    return {
      account_id: accountId,
      client: 'web',
      client_load_id: config.instanceId,
      account_role: role ? ANALYTICS_ROLES[role] : '',
      current_account_starting_promo: get(
        subscription,
        'promotion.promo_code',
        null
      ),
      plan_id: get(plan, 'id', null),
      plan_name: get(plan, 'title', null),
      plan_tier: get(plan, 'tier', null),
      plan_version: get(plan, 'version', null),
      team_id: get(currentTeam, 'id', null),
      project_id: get(currentProject, 'id', null),
      folder_id: get(currentFolder, 'id', null),
      limit_storage: get(subscription, STORAGE_LIMIT, null),
      limit_archived_storage: get(subscription, ARCHIVED_STORAGE_LIMIT, null),
      limit_projects: get(subscription, TOTAL_PROJECT_LIMIT, null),
      limit_team_members: get(subscription, TOTAL_MEMBER_LIMIT, null),
      limit_collaborators: get(plan, 'collaborator_limit', null),
      limit_users: get(subscription, TOTAL_USER_LIMIT, null),
      usage_archived_storage: get(account, ARCHIVED_STORAGE, null),
      usage_storage: formatBytes(get(account, STORAGE, null)),
      usage_projects: get(account, PROJECT_COUNT, null),
      usage_team_members: get(account, MEMBER_COUNT, null),
      usage_users: get(account, USER_COUNT, null),
      usage_collaborators: get(account, COLLABORATOR_COUNT, null),
      is_over_limit: !!get(account, 'delinquent_at', null),
      is_on_trial: !!get(subscription, 'on_trial', false),
      enabled_feature_flags:
        enabledFeatureFlags && enabledFeatureFlags.join(','),
    };
  }
);

export const identifyPropertiesSelector = createSelector(
  currentUserEntitySelector,
  enabledFeatureFlagsSelector,
  currentAccountSelector,
  accountIdsForAccountSwitcherSelector,
  (currentUserEntity, enabledFeatureFlags, currentAccount, accountIds) => {
    // user.inserted_at is formatted like this `2019-11-25T21:18:27.444Z` but in Segment we want it
    // formatted in UNIX timestamp (ex: 1574716926932).
    const userCreatedAt =
      currentUserEntity && new Date(currentUserEntity.inserted_at).getTime();

    return {
      ...pick(currentUserEntity, IDENTIFY_USER_ATTRS),

      // TODO(marvin): deprecate legacy `has_logged_in` flag and just use
      // `first_login_at` instead
      has_logged_in: !!currentUserEntity.first_login_at,
      user_id: currentUserEntity.id,
      google_authentication_enabled: !!currentUserEntity.from_google,
      created_at_integer: userCreatedAt,
      current_account_id: currentAccount.id,
      frontend_feature_flags_enabled:
        enabledFeatureFlags?.length > 0
          ? enabledFeatureFlags.join(',')
          : undefined,
      all_account_ids:
        accountIds?.length > 0 ? accountIds.join(',') : undefined, // [NOTE] These accountIds exclude those that do not have any teams
    };
  }
);

// [SBC-360] - To avoid sending sensitive review link and presentation link
// information to destinations that do not need to receive it, we should prevent
// all Segment integrations from being loaded on the presentation and review
// pages. This will prevent Segment from loading any third-party scripts (like
// AppCues and Intercom) on those pages.
export function isPresentationOrReviewLinkPage(pathname) {
  const isPresentationPage = isPathNamePresentationPage(pathname);
  const isReviewPage = isPathNameReviewPage(pathname);
  return isPresentationPage || isReviewPage;
}

export function maybeClearExistingIdentityTraits() {
  /*
  OK, so this is a weird bug – if the segment cache was not cleared, e.g. if the user
  never really logged out of the app, or logs in again on the same machine basically what we
  end up doing is reading their old traits (which get cached in localstorage).

  This would be OK except for the fact that we have some ancient traits that cached user tokens
  inside of them – (they look like: `tokens: { uuid: unix_timestamp }`).

  What ends up happening as a result is these super old traits get merged as the current users
  traits and then synced into our data warehouse which causes all kinds of problems.

  To mitigate, we're ditching the old traits if there are tokens present.
  */
  try {
    const userTraits = window.analytics.user().traits();

    if (userTraits && userTraits.tokens) {
      window.analytics.user().traits({});
    }
  } catch (error) {
    // Do nothing
  }
}

/**
 * Generic tracking function.
 * @param {string} eventName - Event name.
 * @param {Object} properties - Properties in addition to the core properties.
 * @returns { void }
 */
export default function track(eventName, properties, options) {
  if (!eventName || !eventName.length) {
    throw new Error('track() must be passed at least an event name');
  }

  const coreProperties = corePropertiesSelector(store.getState());
  const cookieCategories = getGDPRCookieCategories();
  const integrations = {
    ...options?.integrations,
    ...CLIENT_CONTROLLED_DESTINATIONS,
    'Amazon S3': cookieCategories.C0002,
  };

  const mergedProps = { ...coreProperties, ...properties };

  try {
    enqueueOrRun({
      event: () =>
        window.analytics.track(
          eventName,
          mergedProps,
          // GROW-2564 - do not call `Drift.track` when making a tracking call with Segment
          {
            integrations,
            consent: {
              categoryPreferences: getGDPRCookieCategories(),
            },
          }
        ),
    });

    if (isTrackingDebugEnabled) {
      // eslint-disable-next-line no-console
      console.log(
        '%c%s - %c%s %o \n',
        'padding: 1px; font-weight: bold; color: #52bd95;',
        'Segment Tracking Event',
        'padding: 1px; font-weight: bold; color:#5B53FF',
        eventName,
        properties
      );
    }
  } catch (error) {
    Raven.captureMessage('Segment track call failed', {
      level: 'error',
      extra: { error: error.message },
    });
  }

  return null;
}

/**
 * Tracking function for areas of the app inside of a viewer
 * @param {string} eventName - Event name.
 * @param {Object} properties - Properties in addition to the core properties.
 * @param {Object} options - Options related to segment such as integrations
 * @returns { void }
 */
export function trackViewer(eventName, properties, options) {
  try {
    const { pathname } = window.location;
    const playerType = determinePlayerType();
    const isComparison = pathname.includes('compare');

    const assetId = getAssetIdForViewer();
    const asset = assetEntitySelector(store.getState(), { assetId });
    const mediaType = getMediaType(asset);
    const isShowAllAnnotationsOn = showAllAnnotationsSelector(store.getState());

    const viewerProperties = {
      file_id: assetId,
      is_comparison: isComparison,
      is_show_all_annotations_on: isShowAllAnnotationsOn,
      is_version_stack: isVersionStack(),
      media_type: mediaType,
      player_type: playerType,
    };

    if (isComparison) {
      delete viewerProperties.file_id;
    }

    const mergedProps = { ...properties, ...viewerProperties };

    return track(eventName, mergedProps, options);
  } catch (error) {
    Raven.captureMessage('Segment track call failed', {
      level: 'error',
      extra: { error: error.message },
    });
    return null;
  }
}

/**
 * https://segment.com/docs/sources/website/analytics.js/quickstart/
 */

export async function loadAnalytics() {
  configureSegmentOt({
    isEnterpriseDataRestrictionsEnabled,
    track,
    dispatch: store.dispatch,
    groupRestrictions: [],
    segmentWriteKey: config.segmentId,
  });

  try {
    await window.adobePrivacy.loadOneTrust();
    loadSegment();
    processQueue();
  } catch (error) {
    Raven.captureMessage('loadAnalytics failed', {
      level: 'error',
      extra: { error: error.message },
      tags: { jira_ticket: 'V3FRAME-1189' },
    });
  }
}

const ANALYTICS_PLAYER_TYPES = {
  projects: 'Quicklook',
  player: 'Project',
  reviews: 'Review',
  presentations: 'Presentation',
  compare: 'Split',
};

/**
 * tracking function for start of any media played (for first time.).
 * @param {Object} properties - Properties in addition to the core properties.
 * @returns {Promise}
 */
export function trackMediaStartPlayed(
  file,
  { isSessionBasedWatermarked = false }
) {
  const eventName = 'media-start-played';
  const basePath = window.location.pathname.split('/')[1] || '';
  const playerType = ANALYTICS_PLAYER_TYPES[basePath];

  // fileType format "should" be: 'video/mp4'. But let's have a fallback just in case.
  const fileType = file.filetype ? file.filetype.split('/') : ['na', 'na'];

  const properties = {
    asset_id: file.id,
    comment_count: file.comment_count,
    context_traits_client: 'web',
    drm_encrypted_media: !!file.drm,
    file_format: fileType[1],
    file_id: file.id,
    filesize: file.filesize,
    // session based watermarking is not shown on project player pages.
    includes_SBW: isSessionBasedWatermarked,
    is_turnstyle: file.bundle ? file.bundle : false,
    // is_version_stack is used on `media-viewed-client`
    is_versioned: isVersionStack(),
    media_type: fileType[0],
    player_type: playerType,
    view_count: file.view_count,
  };

  store.dispatch(
    updateTrackingIdentiferInfo({
      media_start_played: 1,
    })
  );
  return track(eventName, properties);
}

/**
 * Tracking sugar function keyboard events. We use the full event to determine
 * if there is a modifier key pressed. This function operate successfully at the window level,
 * but because a lot of events stopPropagation, this needs to be included often in deeper logic.
 * Looks through the keyboard list to validate this is a legitmate key we want to track.
 * Confirms the page is a page we are tracking on.
 * @param {event} event - The Keyboard Event
 * @returns { void } generated from track()
 */
export const trackKeyDown = (event) => {
  const hasModifier =
    event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;
  if (hasModifier) return false;
  const key = event.key === ' ' ? 'spacebar' : event.key.toUpperCase();
  if (ANALYTICS_KEYBOARD_HOTKEYS.indexOf(key) >= 0) {
    const basePath = window.location.pathname.split('/')[1] || '';
    const pageType = ANALYTICS_KEYBOARD_PAGES[basePath];
    if (pageType) {
      return track('keyboard-shortcut-triggered-client', {
        page_type: pageType,
        shortcut: key,
      });
    }
  }
  return false;
};

/**
 * [LEGACY] Button click now (4/2020) has an updated format this is a legacy format
 * Tracking sugar function for `button-click` event.
 * @param {string} title - The button content.
 * @param {string} page - The page the button is on.
 * @param {string} position - The position on the page the button is on.
 * @returns {Promise}
 */
export function trackButtonClick(title, page, position, properties = {}) {
  return track('button-clicked', { title, page, position, ...properties });
}

/**
 * tracking function for third-party integrations on Segment
 * that can only read the event name, but not params.
 * @param {string} eventName - Event name.
 * @returns {Promise}
 */
export function trackForIntegrations(eventName) {
  if (!eventName || !eventName.length) {
    throw new Error(
      'trackForIntegrations() must be passed at least an event name'
    );
  }
  return track(eventName);
}

/**
 * Tracking function for clicking on external link.
 * @param {Object} clickEvent - Click event object.
 * @param {string} eventName - The name of the event being tracked.
 * @param {Object} properties - Properties in addition to the core properties.
 * @returns {void}
 */
export function trackExternalLinkClick(
  clickEvent = {},
  eventName,
  properties = {}
) {
  const {
    target: { href, target },
    preventDefault,
  } = clickEvent;
  // Call tracking immediately if link opens in new tab
  if (target === '_blank') {
    track(eventName, { ...properties });
    return;
  }
  // Allow time for the tracking event to get called before page gets unloaded
  preventDefault();
  track(eventName, { ...properties });
  setTimeout(() => {
    window.location.assign(href);
  }, 150);
}

export function trackIdentify(userId, traits, options) {
  const cookieCategories = getGDPRCookieCategories();
  const integrations = {
    ...CLIENT_CONTROLLED_DESTINATIONS,
    ...options?.integrations,
    'Amazon S3': cookieCategories.C0002,
  };
  const newOptions = {
    ...options,
    ...integrations,
  };

  // Prod is using Intercom destination that is now only in maintenance mode.
  // The new destinations were renamed. Dev is now using Intercom Web(Actions).
  // The piece below modifies new destination name so that it can be treat as
  // Intercom in dev
  if (!isProduction && newOptions.integrations['Intercom Web (Actions)']) {
    newOptions.integrations.Intercom = options.integrations.Intercom;
  }
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
  try {
    window.analytics.identify(userId, traits, newOptions);

    if (isTrackingDebugEnabled) {
      const modifiedTraits = (() => {
        // [NOTE] per request in regards to the logging of the feature flag being formatted, this modifiedTraits replaces the trait object ( console.log only )

        const frontendFeatureFlagsEnabled =
          traits.frontend_feature_flags_enabled;

        if (!frontendFeatureFlagsEnabled) return traits;

        const modifiedFrontendFeatureFlagsEnabled = {
          frontend_feature_flags_enabled: frontendFeatureFlagsEnabled.split(
            ','
          ),
        };

        return Object.assign(
          cloneDeep(traits),
          modifiedFrontendFeatureFlagsEnabled
        );
      })();

      // eslint-disable-next-line no-console
      console.log(
        '%c%s - %c%s %o \n',
        'padding: 1px; font-weight: bold; color: #52bd95;',
        'Segment Identify Event',
        'padding: 1px; font-weight: bold; color:#5B53FF',
        userId,
        modifiedTraits,
        newOptions
      );
    }
  } catch (error) {
    Raven.captureMessage('Segment identify call failed', {
      level: 'error',
      extra: { error: error.message },
    });
  }

  return null;
}

export function trackPage(path) {
  enqueueOrRun({ event: () => window.analytics.page(path) });
}

export function getRedirectTrackingOpts(opts = {}) {
  return {
    hasRefreshToken: Boolean(getRefreshTokenId()),
    hasAuthToken: Boolean(getAuthToken()),
    authTokenExpiry: getAuthTokenExpiry(),
    authTokenExpired: getAuthTokenExpired(),
    userId: getAuthedUserId(),
    location: window?.location?.href,
    ...opts,
  };
}

export const testExports = {
  planEntitySelector,
  accountV2EntitySelector,
  subscriptionEntitySelector,
};
