import { noop } from 'lodash';
import track from 'analytics';
import {
  assetEntitySelector,
  childAssetEntitiesSortedByIndexSelector,
} from '@frameio/core/src/assets/selectors';
import { call, spawn, put, select } from 'redux-saga/effects';
import { showErrorToast } from 'actions/toasts';
import {
  reorderAsset,
  unversionAsset as unversionAllAssetsCoreSaga,
  getAsset,
  batchMoveAssets,
} from '@frameio/core/src/assets/sagas';

export const TRACKING_EVENTS = {
  VERSION_REMOVED: 'version-management-version-removed',
  VERSION_REORDERED: 'version-management-version-reordered',
  COMPONENT_SHOWN: 'version-management-component-shown',
};

/**
 * Behavior to be invoked when a user chooses to remove an asset from a version stack. The
 * asset will either be moved to the parent directory of the version stack or the version
 * stack will be deleted and its remaining child assets splatted into the parent directory.
 * This latter case is intended to prevent creating non-sensical or confusing states (e.g.,
 * a version stack with 1 or 0 assets).
 *
 * optimisticOnBeforeRemove will be called optimistically before an any asset is removed
 * from the version stack. it will be called with the id of the version stack and, if one
 * of the last two children of a stack are being removed, the id of the asset opposite to
 * the asset that triggered the action
 *
 * *NOTE*: This saga isn't invoked directly by <ManageVersionStack /> but is instead imported
 * elsewhere where the callback can be defined
 * @param {string} assetId
 * @param {function} optimisticOnBeforeRemove
 */
export function* removeVersionFromStack(
  childAssetId,
  totalVersions,
  optimisticOnBeforeRemove = noop,
  page
) {
  const { parent_id: versionStackId } = yield select(assetEntitySelector, {
    assetId: childAssetId,
  });
  const { parent_id: parentFolder } = yield select(assetEntitySelector, {
    assetId: versionStackId,
  });

  yield spawn(track, TRACKING_EVENTS.VERSION_REMOVED, { page });

  // when a version stack has more than 2 versions, move the given asset out of
  // the version stack and into a parent directory.
  if (totalVersions > 2) {
    yield call(optimisticOnBeforeRemove, versionStackId);
    yield call(batchMoveAssets, [childAssetId], parentFolder);
    yield call(getAsset, versionStackId);
    return;
  }

  // when a version stack has two children left, we issue a request that splats all the
  // remaining children of the version stack into the parent directory and deletes the version
  // stack asset from the project. we do this to avoid creating a number of inconsistent or
  // non-sensical states, e.g., a version stack with 1 or 0 versions, as well as an empty
  // version stack asset appearing in the project dashboard, for example.

  // since the manageversionstack component can exist in any number of contexts, e.g., on
  // the player page or on the project dashboard, we want to discretely determine how the
  // component should handle that case by passing down the id of the last remaining child
  // in the version stack to a callback provided by the parent component. if no callback
  // is provided, it will default to a noop.

  // select the version stack's children and derive the last remaining child asset by
  // finding the child  whose id doesn't match `childAssetId` (and whose parent_id
  // matches the id of the version stack, more on that below), that is, the id of the
  // asset that the user clicked to trigger the unversion action.
  const childAssets = yield select(childAssetEntitiesSortedByIndexSelector, {
    assetId: versionStackId,
  });
  const lastRemainingChild = childAssets.find(
    (v) =>
      // the selector used to assign childAssets may erroneously return assets that've been moved
      // out of the version stack, so we double check below that the element we're returning in
      // our find function is actually a child of the version stack.
      v.parent_id === versionStackId && v.id !== childAssetId
  );

  const lastRemainingChildId = lastRemainingChild?.id;

  yield call(optimisticOnBeforeRemove, versionStackId, lastRemainingChildId);

  // after the callback has completed, issue the request to remove the remaining child assets
  // from the version stack, and to delete the version stack asset completely, include the id
  // of the last remaining child asset so that any shares created of the version stack will
  // still have reference to the remaining asset after the version stack is deleted.
  yield call(unversionAllAssetsCoreSaga, {
    payload: {
      assetId: versionStackId,
      assetIdToReplace: lastRemainingChildId,
    },
  });
}

export function* reorderVersionInStack(
  childAssetId,
  prevAssetId,
  nextAssetId,
  page
) {
  const errorMessage =
    'Oops. Something went wrong while reordering. Please try again';
  const { success } = yield call(reorderAsset, childAssetId, {
    prevAssetId,
    nextAssetId,
  });

  const childAsset = yield select(assetEntitySelector, {
    assetId: childAssetId,
  });

  const parentId = childAsset?.parent_id;
  if (parentId) {
    yield call(getAsset, parentId);
  }

  yield spawn(track, TRACKING_EVENTS.VERSION_REORDERED, { page });

  if (!success) {
    yield put(showErrorToast({ header: errorMessage }));
  }
}

export const testExports = { TRACKING_EVENTS };
