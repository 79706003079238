import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import UploadIcon from '@frameio/components/src/svgs/icons/24/upload.svg';
import PlusIcon from '@frameio/components/src/svgs/icons/16/plus.svg';

const DROPZONE_WIDTH = '100%';

const ImagePlaceholder = styled(Flex).attrs(() => ({
  justifyCenter: true,
  alignCenter: true,
}))`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  border-radius: ${(p) => p.theme.radius.circle};

  border: ${({ isOver, theme }) =>
    isOver
      ? `dashed 1px ${theme.color.brand}`
      : `dashed 1px ${theme.color.slateGray}`};

  background-color: ${({ isOver, theme }) =>
    isOver ? `${rgba(theme.color.brand, 0.05)}` : 'transparent'};
`;

const StyledUploadIcon = styled(UploadIcon)`
  path {
    fill: ${(p) => p.theme.color.graphiteGray};
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
  path {
    fill: ${(p) => p.theme.color.brand};
  }
`;

// NOTE: connectDropTarget rejects styled components
const DropZone = ({ connectDropTarget, imageSize, isOver }) =>
  connectDropTarget(
    <div style={{ width: `${DROPZONE_WIDTH}`, height: `${imageSize}px` }}>
      <Flex justifyCenter alignCenter>
        <ImagePlaceholder width={imageSize} height={imageSize} isOver={isOver}>
          {isOver ? <StyledPlusIcon /> : <StyledUploadIcon />}
        </ImagePlaceholder>
      </Flex>
    </div>
  );

export default DropZone;

DropZone.propTypes = {
  connectDropTarget: PropTypes.func,
};

DropZone.defaultProps = {
  connectDropTarget: noop,
};
