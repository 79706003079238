import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { rgba } from 'polished';
import { shallowReflectiveEqual } from 'reflective-bind';
import transitionFactory, { opacity, scale } from 'react-transition-factory';

import { FadeTransition } from 'react-transition-components';
import { alignCenter } from '@frameio/components/src/mixins';
import { CARD_CHIN_HEIGHT } from '@frameio/components/src/styled-components/Card';
import FileAssetThumb from './FileAssetThumb/FileAssetThumb';
import PlaceholderPlus from './PlaceholderPlus';

export const DROP_CARD_FACE_TRANSITION_DURATION_MS = 450;
export const DEFAULT_EASE_OUT = 'cubic-bezier(0.17, 0.17, 0, 1)';
const DROP_INDICATOR_OPACITY_EASING = 'linear';
const DROP_INDICATOR_OPACITY_DURATION_MS = 270;
const DROP_INDICATOR_DURATION_MS = 330;

const Indicator = styled.div`
  ${alignCenter(true)};
  position: absolute;
  bottom: ${CARD_CHIN_HEIGHT}px;
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow: hidden;
  background-color: ${(p) => p.theme.color.brand};

  ${PlaceholderPlus} {
    position: absolute;
  }
`;

const DropZoneTransition = transitionFactory(
  opacity,
  scale.all,
  'width',
  'height',
  'box-shadow',
  'border-radius'
);

function DropZoneIndicator({
  assetToVersion,
  isDropComplete,
  isDropZone,
  onDropEnd,
  theme,
  thumbHeight,
  thumbWidth,
}) {
  if (isDropComplete) return null;

  const dropIndicatorShadow = `0 4px 13px 0 ${rgba(theme.color.black, 0.2)}`;
  const isDropping = !!assetToVersion && !isDropComplete;
  const isDropTarget = isDropZone || isDropping;
  const dropZoneIndicatorWidth = Math.round(thumbWidth / 4);
  const dropZoneIndicatorHeight = Math.round(dropZoneIndicatorWidth * 0.7);

  return (
    <DropZoneTransition
      start={[
        // State 1
        0,
        1.3,
        dropZoneIndicatorWidth,
        dropZoneIndicatorHeight,
        dropIndicatorShadow,
        theme.radius.medium,
      ]}
      end={
        !isDropping
          ? [
              // State 2
              1,
              1,
              dropZoneIndicatorWidth,
              dropZoneIndicatorHeight,
              dropIndicatorShadow,
              theme.radius.medium,
            ]
          : [
              // State 3
              1,
              1,
              thumbWidth,
              thumbHeight,
              'none',
              0,
            ]
      }
      in={isDropTarget}
      timeout={
        !isDropping
          ? [
              // State 2
              DROP_INDICATOR_OPACITY_DURATION_MS,
              DROP_INDICATOR_DURATION_MS,
              DROP_INDICATOR_DURATION_MS,
              DROP_INDICATOR_DURATION_MS,
              DROP_INDICATOR_DURATION_MS,
              DROP_INDICATOR_DURATION_MS,
            ]
          : DROP_CARD_FACE_TRANSITION_DURATION_MS // State 3
      }
      easing={[
        DROP_INDICATOR_OPACITY_EASING,
        DEFAULT_EASE_OUT,
        DEFAULT_EASE_OUT,
        DEFAULT_EASE_OUT,
        DEFAULT_EASE_OUT,
        DEFAULT_EASE_OUT,
      ]}
    >
      <Indicator
        isDropping={isDropping}
        // We can't use the onEntered prop of the Transtion since state 2 is already in the
        // "entered" state, so it won't trigger another onEntered event even though we swap
        // out the "end" values for state 3.
        onTransitionEnd={(e) => {
          if (e.target === e.currentTarget && isDropping) onDropEnd();
        }}
      >
        <PlaceholderPlus />
        {isDropping && (
          <FadeTransition
            timeout={DROP_CARD_FACE_TRANSITION_DURATION_MS}
            easing={DEFAULT_EASE_OUT}
          >
            <FileAssetThumb asset={assetToVersion} height="100%" width="100%" />
          </FadeTransition>
        )}
      </Indicator>
    </DropZoneTransition>
  );
}

export default React.memo(withTheme(DropZoneIndicator), shallowReflectiveEqual);

DropZoneIndicator.propTypes = {
  assetToVersion: PropTypes.object,
  isDropComplete: PropTypes.bool,
  isDropZone: PropTypes.bool,
  onDropEnd: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  thumbHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thumbWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DropZoneIndicator.defaultProps = {
  assetToVersion: undefined,
  isDropComplete: false,
  isDropZone: false,
  thumbHeight: 0,
  thumbWidth: 0,
};

export const testExports = {
  DropZoneTransition,
  DropZoneIndicator,
  Indicator,
};
