import React from 'react';
import PropTypes from 'prop-types';
import { trackButtonClick } from 'analytics';
import { MenuButton } from '@frameio/vapor';
import DuplicateFolderIcon from '@frameio/components/src/svgs/icons/16/duplicate.svg';

export default function DuplicateAssets({
  assetIds,
  folderId,
  duplicateAssets,
  ...rest
}) {
  return (
    <MenuButton
      onSelect={() => {
        duplicateAssets(assetIds, folderId);
        trackButtonClick('duplicate to folder', 'project arena', 'middle');
      }}
      iconBefore={<DuplicateFolderIcon width={16} height={16} />}
      {...rest}
    >
      Duplicate
    </MenuButton>
  );
}

DuplicateAssets.propTypes = {
  assetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  duplicateAssets: PropTypes.func.isRequired,
};
