import { generateActionTypes } from 'shared/actions/helpers';

export const COMMENT = generateActionTypes('COMMENT', ['CONFIRM_DELETE']);

export function confirmDeleteComment({ id, onDeleteCallback }, options) {
  return {
    type: COMMENT.CONFIRM_DELETE,
    payload: { id, onDeleteCallback, options },
  };
}
