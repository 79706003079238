import { createSelector } from 'reselect';
import { get, groupBy, matches, partition, sortBy } from 'lodash';
import { teamEntityForProjectIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { CURRENT_VERSION_NUMBER } from 'config';

import {
  archivedProjectIdsForTeamIdSelector,
  sharedProjectsSelector,
  sortedProjectsEntitiesForTeamIdSelector,
  unarchivedProjectIdsForTeamIdSelector,
} from 'selectors/projects';
import {
  currentAccountSelector,
  defaultTeamForAccountIdSelector,
  lastViewedAccountIdSelector,
} from 'selectors/accounts';
import {
  currentUserSelector,
  currentUserEntitySelector,
} from 'selectors/users';
import { accountsWithTeamsSelector } from 'pages/LayoutWithNav/AccountSwitcher/selectors';

export const sortedSharedProjectsSelector = createSelector(
  currentUserSelector,
  sharedProjectsSelector,
  (currentUser, sharedProjects) =>
    sharedProjects.map((project) => ({
      userId: currentUser.id,
      id: project.id,
      name: project.name,
      isPrivate: project.private,
      account_id: project.account_id,
    }))
);

/**
 * Gets shared project data sorted by account id
 * @returns {Object} - Aggregate object composed of account id keys
 * and array of project object values
 */
export const sortedSharedProjectsForAccountSelector = createSelector(
  sortedSharedProjectsSelector,
  (sortedSharedProjects) =>
    groupBy(sortedSharedProjects, (project) => get(project, 'account_id'))
);

export const sharedProjectsForAccountSelector = createSelector(
  sortedSharedProjectsForAccountSelector,
  (state, { accountId }) => accountId,
  (sortedSharedProjectsByAccount = {}, accountId) =>
    sortedSharedProjectsByAccount[accountId]
);

export const sharedProjectIdsForAccountSelector = createSelector(
  sharedProjectsForAccountSelector,
  (sharedProjectsByAccount = []) =>
    sharedProjectsByAccount.map((project) => project.id)
);

export const defaultProjectIdSelector = createSelector(
  currentAccountSelector,
  (state) => state,
  ({ id: accountId }, state) => {
    const { id: teamId } =
      defaultTeamForAccountIdSelector(state, { accountId }) || {};
    if (teamId) {
      const sortedProjects = sortedProjectsEntitiesForTeamIdSelector(state, {
        teamId,
      });
      const [unarchivedProjects, archivedProjectIds] = partition(
        sortedProjects,
        (project) => !project.archived_at
      );

      return (
        (unarchivedProjects[0] && unarchivedProjects[0].id) ||
        (archivedProjectIds[0] && archivedProjectIds[0].id)
      );
    }

    return (sharedProjectIdsForAccountSelector(state, { accountId }) || [])[0];
  }
);

/**
 * Helper function to return the sibling project id for the current
 * project id value, from a sorted list of project ids.
 */
export function getNextProjectId(currProjectId, projectIds) {
  if (!projectIds || projectIds.length === 0) return undefined;

  const index = projectIds.indexOf(currProjectId);
  const isLastProject = index === projectIds.length - 1;

  return projectIds[index + (isLastProject ? -1 : 1)];
}

/**
 * @param {string} projectId - Project id.
 * @returns {string} - The sibling project id of the provided project id
 * with its account/team context.
 */
export const getNextProjectIdInTeamSelector = createSelector(
  teamEntityForProjectIdSelector,
  (state, { projectId }) => projectId,
  (state) => state,
  (teamEntity, projectId, state) => {
    const { id: teamId } = teamEntity || {};
    if (!teamId) return undefined;

    const projectIds = unarchivedProjectIdsForTeamIdSelector(state, {
      teamId,
      filterDeleted: true,
    });
    const archivedProjectIds = archivedProjectIdsForTeamIdSelector(state, {
      teamId,
      filterDeleted: true,
    });

    return getNextProjectId(
      projectId,
      (projectIds.includes(projectId) && projectIds) ||
        (archivedProjectIds.includes(projectId) && archivedProjectIds)
    );
  }
);

export const accountIdToRedirectToSelector = createSelector(
  lastViewedAccountIdSelector,
  accountsWithTeamsSelector,
  currentUserEntitySelector,
  (state) => state,
  (lastViewedAccountId, accounts = [], user = {}) => {
    // Only rely on the lastViewedAccount if the user can actually access it
    const lastViewedAccount =
      lastViewedAccountId &&
      accounts.find(matches({ id: lastViewedAccountId }));

    if (lastViewedAccount) return lastViewedAccount.id;

    // If no lastViewedAccount, pick the first account from the account picker,
    // giving preference to v3 accounts to prevent potential redirect loops
    // between v3 and v4.
    const firstAccount = sortBy(accounts, (a) =>
      a.version === CURRENT_VERSION_NUMBER ? 0 : 1
    )[0];

    if (firstAccount) return firstAccount.id;

    // The user is no longer part of any other account and their personal
    // account is not set up (i.e. has no team). The account container will
    // prompt the user to set up their account.
    return user.account_id;
  }
);
