import { createAction } from 'redux-actions';

import {
  generateActionTypes,
  generateFetchActionTypes,
} from '@frameio/core/src/shared/actions/helpers';

const module = 'AEM';

export const AEM = {
  ...generateFetchActionTypes(module, [
    'GET_AEM_REPOSITORIES',
    'GET_AEM_FOLDERS',
    'SEND',
  ]),
  ...generateActionTypes(module, [
    'SELECT_AEM_DESTINATION',
    'SET_AEM_CHILD_FOLDERS',
    'SET_AEM_REPOSITORIES',
    'SET_AEM_ROOT_FOLDERS',
  ]),
};

export const setAemRepositories = createAction(
  AEM.SET_AEM_REPOSITORIES,
  (aemRepositories) => ({ aemRepositories })
);

export const setAemRootFolders = createAction(
  AEM.SET_AEM_ROOT_FOLDERS,
  (aemRepositoryId, rootFolders, rootFolderId) => ({
    aemRepositoryId,
    rootFolders,
    rootFolderId,
  })
);

export const setAemChildFolders = createAction(
  AEM.SET_AEM_CHILD_FOLDERS,
  (aemRepositoryId, parentFolderId, childFolders) => ({
    aemRepositoryId,
    parentFolderId,
    childFolders,
  })
);

export const selectAemDestination = createAction(
  AEM.SELECT_AEM_DESTINATION,
  ({
    aemPath,
    aemRepositoryId,
    aemFolderId,
    projectId,
    aemRepositoryTitle,
  }) => ({
    aemPath,
    aemRepositoryId,
    aemRepositoryTitle,
    aemFolderId,
    projectId,
  })
);
