import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SeekWithPreviewThumb from '@frameio/components/src/components/CompletePlayer/SeekWithPreviewThumb';
import Seek from '@frameio/components/src/components/PlayerSubcomponents/Seek';

import useMediaRAF from 'pages/PlayerContainers/hooks/useMediaRAF';
import useMediaBuffer from 'pages/PlayerContainers/hooks/useMediaBuffer';

const SeekWrapper = styled.div`
  position: relative;
`;

const SeekBar = (props) => {
  const { media, mediaEl, isScrubbing, renderPreviewThumb } = props;

  // When scrubbing, scrubVideo IS the media prop passed
  // from CompletePlayerView.
  const scrubMediaEl = isScrubbing && media;

  // We want the currentTime to update based on the scrubElement
  // if scrubbing, else the actual media element.
  const currentMediaElement = isScrubbing ? scrubMediaEl : mediaEl;
  const { currentTime } = useMediaRAF(media, currentMediaElement);

  // We only want to buffer the actual media element so
  // we disregard the isScrubbing check and just use mediaEl.
  const mediaBuffer = useMediaBuffer(mediaEl);

  // Make sure that the seek bar does not overshoot its container
  const amountPlayed = media.duration
    ? Math.min(1, currentTime / media.duration)
    : 0;
  const amountBuffered = media.duration ? mediaBuffer : 0;

  const seekProps = {
    ...props,
    amountBuffered,
    amountPlayed,
  };

  return renderPreviewThumb ? (
    <SeekWrapper>
      <SeekWithPreviewThumb {...seekProps} />
    </SeekWrapper>
  ) : (
    <SeekWrapper>
      <Seek {...seekProps} />
    </SeekWrapper>
  );
};

SeekBar.propTypes = {
  renderPreviewThumb: PropTypes.bool,
  media: PropTypes.object,
  mediaEl: PropTypes.object,
};

export default SeekBar;
