import { PLAN_TIERS } from 'components/SelectPlanFlow/PlanDisplay';

/**
 * Enum of `account.subscription.plan.available_features` that are actually
 * used.
 */
export const allFeatures = {
  allowedDomains: 'allowed_domains',
  archivalStorage: 'archival_storage',
  customBrandedEmails: 'custom_branded_emails',
  customBrandedPresentations: 'custom_branded_presentations',
  customSort: 'custom_sort',
  devices: 'devices',
  disableShareComments: 'disable_share_comments',
  disableShareDownloads: 'disable_share_downloads',
  disableSharePreviousVersions: 'disable_share_previous_versions',
  forensicWatermarking: 'forensic_watermarking',
  passwordProtectedShares: 'password_protected_shares',
  playback4k: '4k_playback',
  privateProjects: 'private_projects',
  reelPlayer: 'reel_player',
  secureSharing: 'secure_sharing',
  sessionBasedWatermarking: 'session_based_watermarking',
  shareLinkExpiration: 'share_link_expiration',
  teamOnlyComments: 'team_only_comments',
};

// TODO(marvin): fill in pro/enterprise features
const proFeatures = [allFeatures.devices, allFeatures.archivalStorage];
const teamFeatures = [
  ...proFeatures,
  allFeatures.customBrandedEmails,
  allFeatures.reelPlayer,
  allFeatures.teamOnlyComments,
];
const enterpriseFeatures = [...teamFeatures];

/**
 * Get the lowest plan tier that a plan feature is available on.
 *
 * @param {string} planFeatureId - `plan.available_features`.
 * @returns {string} - Plan tier.
 */
export function getLowestTierForFeature(planFeatureId) {
  if (proFeatures.indexOf(planFeatureId) > -1) {
    return PLAN_TIERS.PRO;
  }
  if (teamFeatures.indexOf(planFeatureId) > -1) {
    return PLAN_TIERS.TEAM;
  }
  if (enterpriseFeatures.indexOf(planFeatureId) > -1) {
    return PLAN_TIERS.ENTERPRISE;
  }

  return null;
}
