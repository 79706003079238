import track, { trackViewer } from 'analytics';
import { COMPARE_URL } from 'URLs';
import { goToRoute } from 'utils/router';
import { updateLastViewedProjectForAccount } from '@frameio/core/src/accounts/services';
import {
  createViewAssetImpression,
  getImpressionsByAsset,
} from '@frameio/core/src/assetImpressions/actions';
import {
  hydratedAssetEntitySelector,
  hydratedAssetEntitiesByAssetIdsSelector,
} from '@frameio/core/src/assets/selectors';
import { getMediaType } from '@frameio/core/src/assets/helpers/mediaTypes';
import { type as assetTypes } from '@frameio/core/src/assets/helpers/constants';
import { getAssetChildren } from '@frameio/core/src/assets/sagas';
import { getCommentsByAsset } from '@frameio/core/src/comments/actions';
import { getProject } from '@frameio/core/src/projects/sagas';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import { getTeam } from '@frameio/core/src/teams/sagas';
import { setCurrentAccount } from 'actions/accounts';
import { setCurrentProject } from 'actions/projects';
import { error } from 'components/Dialog/SimpleDialog/sagas';
import { showErrorToast } from 'actions/toasts';
import { call, put, select, spawn, takeEvery } from 'redux-saga/effects';
import { redirectToProject } from 'sagas/projects';
import { currentAccountSelector } from 'selectors/accounts';
import { currentProjectSelector } from 'selectors/projects';
import { teamEntitySelector } from '@frameio/core/src/teams/selectors';
import { supportedComparisonMediaTypes } from 'components/SplitPlayerContainers/shared/constants';
import { getAssetEntity } from '../PlayerContainer/sagas';
import { SPLIT_PLAYER_CONTAINER } from './actions';

export function* errorAndRedirect() {
  const header = 'These items cannot be compared';
  const message =
    'Sorry, these items cannot be compared in the split player. Please click on the button below to return to your projects.';
  yield call(error, header, message);
  yield call(redirectToProject);
}

function* getOrganization(projectId) {
  const { failure } = yield call(getProject, projectId);
  if (failure) return;

  const projectEntity = yield select(projectEntitySelector, { projectId });
  const teamId = projectEntity.team_id;
  yield call(getTeam, teamId);
  const teamEntity = yield select(teamEntitySelector, { teamId });
  const accountId = teamEntity.account_id;
  const lastViewedProjectId = projectId;

  const { id: prevProjectId } = (yield select(currentProjectSelector)) || {};
  const { id: prevAccountId } = (yield select(currentAccountSelector)) || {};

  if (accountId !== prevAccountId) {
    yield put(setCurrentAccount(accountId, prevAccountId, lastViewedProjectId));
  }
  if (projectId !== prevProjectId) {
    yield put(setCurrentProject(projectId, prevProjectId));
    yield spawn(updateLastViewedProjectForAccount, accountId, projectId);
  }
}

function* getParentAsset(assetParentId) {
  yield call(getAssetEntity, assetParentId);
  yield call(getAssetChildren, assetParentId);
}

function* getCommentingData(assetId) {
  yield put(getCommentsByAsset(assetId));
  yield put(getImpressionsByAsset(assetId));
  yield put(createViewAssetImpression(assetId));
}

export function* fetchSplitPlayerContainerAssets({ payload }) {
  const { assetId, options, versionStackId } = payload;
  const { fetchComments, fetchParent, fetchProject } = options;

  const assetEntity = yield call(getAssetEntity, assetId);

  if (!assetEntity) {
    yield call(errorAndRedirect);
    return;
  }

  if (fetchComments) yield call(getCommentingData, assetId);
  if (fetchParent) yield call(getParentAsset, assetEntity.parent_id);
  if (fetchProject) yield call(getOrganization, assetEntity.project_id);

  yield spawn(trackViewer, 'media-viewed-client', {
    filesize: assetEntity.filesize,
    file_format: assetEntity.filetype,
    is_version_stack: !!versionStackId,
    view_count: assetEntity.view_count,
  });
}

/**
 * @param {Object} action
 * @param {Object} action.payload.versionStackId - Version stack id
 * Takes in a version stack id and attempts to compare the first two versions.
 * If there are less than two versions, show an error toast.
 * If there are two or more versions, redirect to the compare page.
 */
export function* compareVersions({ payload }) {
  const { tracking, versionStackId } = payload;

  yield call(getAssetChildren, versionStackId);

  const { children } = yield select(hydratedAssetEntitySelector, {
    assetId: versionStackId,
  }) || {};

  if (children?.length < 2) {
    const header = 'Whoops!';
    const subHeader = 'There was an issue comparing versions';
    yield put(showErrorToast({ header, subHeader }));
    return;
  }

  const params = { versionId: versionStackId };

  const queryParams = {
    a1: children[0].id,
    a2: children[1].id,
  };

  goToRoute(COMPARE_URL, params, queryParams);
  track('assets-compared-client', { ...tracking, is_version_stack: true });
}

/**
 * @param {Object} action
 * @param {Object} action.payload.assetIds - Array of asset ids
 * @param {Object} action.payload.tracking - Tracking data
 * Takes in an array of asset ids and attempts to compare them.
 * If there is only one asset and it is a version stack, compare versions.
 * If there are two assets and they are of the same type, compare assets.
 * Behind a feature flag.
 */
export function* compareAssetsOrVersion({ payload }) {
  const { assetIds, source } = payload;

  const assets = yield select(hydratedAssetEntitiesByAssetIdsSelector, {
    assetIds,
  });

  // Compare versions if there is only one asset and it is a version stack
  if (assets.length === 1) {
    const asset = assets[0];
    const isVersionStack = asset.type === assetTypes.VERSION_STACK;
    const rootAsset = isVersionStack ? asset.cover_asset : asset;
    const mediaType = getMediaType(rootAsset);
    const canCompare = supportedComparisonMediaTypes.includes(mediaType);

    if (isVersionStack && canCompare) {
      const tracking = { source, media_typed: mediaType };
      const data = { tracking, versionStackId: asset.id };
      yield call(compareVersions, { payload: data });
    }
  }

  // Compare assets if there are two assets and they are the same type
  else if (assets.length === 2) {
    const getAssetRoot = (asset) =>
      asset.type === assetTypes.VERSION_STACK ? asset.cover_asset : asset;

    const assetOne = getAssetRoot(assets[0]);
    const assetTwo = getAssetRoot(assets[1]);

    const assetOneMediaType = getMediaType(assetOne);
    const assetTwoMediaType = getMediaType(assetTwo);

    const areAnyAssetsVersionStack =
      assets[0].type === assetTypes.VERSION_STACK ||
      assets[1].type === assetTypes.VERSION_STACK;

    const canCompare = supportedComparisonMediaTypes.includes(
      assetOneMediaType
    );

    if (assetOneMediaType === assetTwoMediaType && canCompare) {
      const queryParams = {
        a1: assetOne.id,
        a2: assetTwo.id,
      };

      goToRoute(COMPARE_URL, {}, queryParams);
      track('assets-compared-client', {
        source,
        media_type: assetOneMediaType,
        is_version_stack: areAnyAssetsVersionStack,
      });
    }
  }
}

export default [
  takeEvery(
    SPLIT_PLAYER_CONTAINER.IS_FETCHING,
    fetchSplitPlayerContainerAssets
  ),
  takeEvery(
    SPLIT_PLAYER_CONTAINER.COMPARE_ASSETS_OR_VERSION,
    compareAssetsOrVersion
  ),
  takeEvery(SPLIT_PLAYER_CONTAINER.COMPARE_VERSIONS, compareVersions),
];

export const testExports = {
  getOrganization,
};
