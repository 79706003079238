import { connect } from 'react-redux';
import {
  assetEntitySelector,
  coverAssetEntitySelector,
} from '@frameio/core/src/assets/selectors';
import Preview from './Preview';

function mapStateToProps(state, { assetId }) {
  return {
    asset:
      coverAssetEntitySelector(state, { assetId }) ||
      assetEntitySelector(state, { assetId }),
  };
}

export default connect(mapStateToProps)(Preview);

export const testExports = {
  mapStateToProps,
};
