import { connect } from 'react-redux';

import { getRoleForProject } from 'actions/roles';
import { getProjectUserPreferences } from 'actions/projects';
import { permittedActionsForProjectSelector } from 'selectors/permissions';
import {
  isAccountOnFreePlanWithUserMaxSelector,
  accountPlanVersionSelector,
  currentAccountSelector,
} from 'selectors/accounts';
import { isProjectArchivedSelector } from 'selectors/projects';
import { isCollaboratorOnlySelector } from 'selectors/roles';
import OptionsMenu from './OptionsMenu';

function mapStateToProps(state, { projectId }) {
  const {
    canArchiveProject,
    canUnarchiveProject,
  } = permittedActionsForProjectSelector(state, { projectId });
  const isProjectArchived = isProjectArchivedSelector(state, { projectId });
  const isAccountOnFreePlanWithUserMax = isAccountOnFreePlanWithUserMaxSelector(
    state
  );
  const isCollaboratorOnly = isCollaboratorOnlySelector(state, { projectId });
  const accountId = (currentAccountSelector(state) || {}).id;
  const currentPlanVersion = accountPlanVersionSelector(state, { accountId });

  return {
    isAccountOnFreePlanWithUserMax,
    isCollaboratorOnly,
    isProjectArchived,
    canArchiveProject,
    canUnarchiveProject,
    currentPlanVersion,
  };
}

const mapDispatchToProps = {
  getRoleForProject,
  getProjectUserPreferences,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptionsMenu);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
