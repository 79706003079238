import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import Feedback from './Feedback';
import { submitFeedback } from '../actions';

const mapDispatchToProps = {
  submitFeedback,
  closeModal,
};

export default connect(
  null,
  mapDispatchToProps
)(Feedback);
