import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  connectWorkfrontAssets,
  getWorkfrontTasks,
} from '@frameio/core/src/workfront/sagas';
import { closeContextMenu } from 'components/ContextMenuManager/actions';
import { workfrontTaskSelector } from '@frameio/core/src/workfront/selectors';
import {
  manageWorkfrontTask,
  setAssignedTasks,
  setWorkfrontConnected,
} from '@frameio/core/src/workfront/actions';
import { showErrorToast } from 'actions/toasts';
import {
  AEM,
  ERROR_MESSAGES,
  DEFAULT_TASK_ID,
  DEFAULT_TASK_TITLE,
  WORKFRONT,
} from 'components/AssetActions/AdobeIntegration/integrationConstants';
import {
  handleGetAemRepositories,
  sendAssetsToAemDestination,
} from './Aem/sagas';
import ADOBE_INTEGRATION_CONNECT_DIALOG, { closeModal } from './actions';
import { getAssignedTasksArray } from './utils';

const TITLE = {
  PARTIAL_SUCCESS: 'Partial Success',
  ALL_ASSETS_FAILED: 'All Assets Failed',
};

function* handleWorkfrontConnectAssets(results, title) {
  let updates = [];

  switch (title) {
    case TITLE.PARTIAL_SUCCESS: {
      updates = Object.entries(results).map(([key, value]) => {
        if (!value.error) return null;
        return put(
          setWorkfrontConnected(key, DEFAULT_TASK_ID, DEFAULT_TASK_TITLE, false)
        );
      });
      yield put(
        showErrorToast({
          header: ERROR_MESSAGES.connectionPartialSuccess,
        })
      );
      return yield all(updates);
    }
    case TITLE.ALL_ASSETS_FAILED: {
      updates = Object.keys(results).map((key) =>
        put(
          setWorkfrontConnected(key, DEFAULT_TASK_ID, DEFAULT_TASK_TITLE, false)
        )
      );
      yield put(showErrorToast({ header: ERROR_MESSAGES.connectionFailure }));
      return yield all(updates);
    }
    default:
      return null;
  }
}

function* updateTaskStatus(projectId, assetIds, taskId, updatedTaskStatus) {
  const workfrontTask = yield select(workfrontTaskSelector, {
    projectId,
    workfrontTaskId: taskId,
  });

  const updateAssets = assetIds.map((assetId) =>
    put(
      manageWorkfrontTask(
        projectId,
        taskId,
        updatedTaskStatus,
        assetId,
        workfrontTask
      )
    )
  );
  return yield all(updateAssets);
}

function* connectAssetsToWorkfront(payload) {
  yield put(closeContextMenu());
  const { projectId, assetIds, taskOptions } = payload || {};
  const { taskId, taskTitle, updateStatus } = taskOptions || {};
  // optimistically update connection on submit
  const updates = assetIds.map((assetId) =>
    put(setWorkfrontConnected(assetId, taskId, taskTitle, true))
  );

  yield all(updates);

  const { success, failure } = yield call(
    connectWorkfrontAssets,
    projectId,
    assetIds,
    taskOptions
  );

  if (failure) {
    yield put(showErrorToast({ header: ERROR_MESSAGES.connectionFailure }));
    const removeOptimisticConnections = assetIds.map((assetId) =>
      put(
        setWorkfrontConnected(
          assetId,
          DEFAULT_TASK_ID,
          DEFAULT_TASK_TITLE,
          false
        )
      )
    );
    yield all(removeOptimisticConnections);
  } else {
    const { results, title } = success.payload?.response?.data || {};

    yield call(handleWorkfrontConnectAssets, results, title);
    // if user is uploading to project root there will be no taskId to update.
    if (taskId && updateStatus) {
      yield call(updateTaskStatus, projectId, assetIds, taskId, updateStatus);
    }
  }
}

function* getTasksForAssetConnection({ projectId }) {
  yield put(closeContextMenu());
  const { success, failure } = yield call(getWorkfrontTasks, projectId);
  const { tasks } = success?.payload?.response || [];

  if (failure) {
    yield put(closeModal());
    yield put(showErrorToast({ header: ERROR_MESSAGES.fetchTaskFailure }));
  } else if (tasks.length) {
    const assignedTasks = getAssignedTasksArray(tasks);

    yield put(setAssignedTasks(projectId, assignedTasks));
  }
}

function* openModal({ payload }) {
  const { integration, projectId } = payload || {};
  if (integration === WORKFRONT) {
    yield call(getTasksForAssetConnection, payload);
  }

  if (integration === AEM) {
    yield call(handleGetAemRepositories, projectId);
  }
}

function* submitModal({ payload }) {
  const { options, integration, projectId, assetIds } = payload || {};

  switch (integration) {
    case AEM:
      {
        const { aemRepositoryId, aemPath } = options || {};
        yield call(sendAssetsToAemDestination, {
          projectId,
          assetIds,
          aemRepositoryId,
          aemPath,
        });
      }
      break;
    case WORKFRONT:
      {
        const { taskOptions } = options || {};
        yield call(connectAssetsToWorkfront, {
          projectId,
          assetIds,
          taskOptions,
        });
      }
      break;
    default:
      break;
  }
}

export default [
  takeEvery(ADOBE_INTEGRATION_CONNECT_DIALOG.OPEN, openModal),
  takeEvery(ADOBE_INTEGRATION_CONNECT_DIALOG.SUBMIT, submitModal),
];
