import React from 'react';
import PropTypes from 'prop-types';
import { formatShortName } from 'formatters/name';
import { formatAbsoluteTime } from '@frameio/components/src/utils/datetimeHelpers';
import AssetThumb from 'components/AssetThumb';
import Flex from 'styled-flex-component';
import styled from 'styled-components';
import ListCell, {
  TitleText,
} from '@frameio/components/src/styled-components/ListCell';

const THUMB_WIDTH = 72;
const THUMB_HEIGHT = 40;

const Thumb = styled(AssetThumb).attrs(() => ({
  height: THUMB_HEIGHT,
  width: THUMB_WIDTH,
}))`
  border-radius: ${(p) => p.theme.radius.medium};
  overflow: hidden;
`;

export const AssetWrapper = styled(Flex).attrs(() => ({
  full: true,
  alignCenter: true,
}))`
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};

  ${TitleText} {
    font-size: ${(p) => p.theme.fontSize[1]};
    color: ${(p) => p.theme.color.coolBlack};
  }
`;

export default function Asset({
  asset,
  isDragging,
  // <Asset /> has animated behavior in the <ManageVersionStack /> component controlled
  // by properties passed down to it by context provided by react-flip-toolkit.
  // It's not important to us what they all are, so we just pass down all extra
  // properties given to <Asset /> below.
  ...rest
}) {
  const { creator, uploaded_at: uploadedAt, name } = asset;

  return (
    <AssetWrapper isDragging={isDragging} {...rest}>
      <ListCell
        image={<Thumb asset={asset} />}
        title={name}
        details={`${formatShortName(creator)} · ${formatAbsoluteTime(
          uploadedAt
        )}`}
      />
    </AssetWrapper>
  );
}

Asset.defaultProps = {
  isDragging: false,
};

Asset.propTypes = {
  isDragging: PropTypes.bool,
  asset: PropTypes.shape({
    id: PropTypes.string,
    uploaded_at: PropTypes.string,
    name: PropTypes.string,
    creator: PropTypes.object,
  }).isRequired,
};

export const testExports = { AssetWrapper, Thumb };
