import React from 'react';
import { takeEvery, takeLatest, call, put, select } from 'redux-saga/effects';
import { get } from 'lodash';
import { getLastViewedProjectForAccount } from '@frameio/core/src/accounts/services';
import { createTeamByAccount } from '@frameio/core/src/teams/sagas';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';

import {
  setAccountMigrationEligibility,
  setCurrentAccount,
  setLastViewedAccount,
} from 'actions/accounts';
import { getAccountMigrationEligibility } from '@frameio/core/src/accounts/sagas';
import { openModal } from 'components/Modal/actions';
import ConnectedCreateProjectForm from 'components/ProjectForms/CreateProject';
import { setTeamId } from 'components/ProjectForms/CreateProject/actions';
import { error } from 'components/Dialog/SimpleDialog/sagas';
import { formatPossessive } from 'formatters/name';
import { sharedProjectIdsForAccountSelector } from 'pages/RootContainer/selectors';
import { redirectToProject, redirectToRoot } from 'sagas/projects';
import {
  currentAccountSelector,
  teamIdsWithAdminOrMemberRoleFromEntitySelector,
} from 'selectors/accounts';
import { isReviewerHighestRoleSelector } from 'selectors/roles';
import { currentUserEntitySelector } from 'selectors/users';
import { getTeamUrl, getPath, INBOX_URL } from 'URLs';
import { redirectTo } from 'utils/router';
import { NEXT_VERSION_NUMBER } from 'config';

import ACCOUNT_CONTAINER from './actions';
import SearchContainerSagas from './SearchContainer/sagas';
import TeamContainerSagas from './TeamContainer/sagas';
import { getProjectsForTeam } from '../../components/DashboardSidebar/ListTeamGroup/sagas';

/**
 * Set account migration eligibility state when entering an account
 */
function* setAccountEligibilityStatus(accountId) {
  const eligibilityResponse = yield call(getAccountMigrationEligibility, {
    accountId,
  });

  const canUserMigrate =
    eligibilityResponse?.success?.payload?.response?.permissions.can_migrate ??
    false;
  const isAccountEligible =
    eligibilityResponse?.success?.payload?.response?.status.type ===
      'eligible' ?? false;

  yield put(setAccountMigrationEligibility(canUserMigrate, isAccountEligible));
}

/**
 * Redirect to to the first team of the account, or the first shared project.
 */
export function* redirectToFirstTeamOrSharedProject(accountId) {
  const teamIdsForAccount = yield select(
    teamIdsWithAdminOrMemberRoleFromEntitySelector,
    { accountId }
  );
  const teamId = (teamIdsForAccount || [])[0];

  if (teamId) {
    // ensure that the team has loaded its projects before
    // redirecting to that team
    yield call(getProjectsForTeam, { payload: { teamId } });
    yield call(redirectTo, getTeamUrl(accountId, teamId));
  } else {
    const sharedProjectIds = yield select(sharedProjectIdsForAccountSelector, {
      accountId,
    });
    const projectId = (sharedProjectIds || [])[0];
    yield call(redirectToProject, projectId);
  }
}

function* enterAccount(accountId) {
  const { id: prevAccountId } = yield select(currentAccountSelector);
  const { version: fioVersion } = yield select(accountEntitySelector, {
    accountId,
  });

  if (prevAccountId !== accountId) {
    // Retrieve the last viewed project id from the API, and save this to the redux store
    let lastViewedProjectId;

    // TODO(Anna): Replace this try catch block by making this service call a concern of core
    // createAsyncSaga; this way we can consume the associated SUCCESS or FAILURE action here
    try {
      const response = yield call(getLastViewedProjectForAccount, accountId);
      lastViewedProjectId = get(response, '0.project_id');
    } catch (err) {
      // Here we catch any failures resulting from the last viewed project id fetch, e.g.
      // on network connective issues, this may error out as `Cannot read property 'status'
      // of undefined`. This try-catch block will eventually be removed once an associated
      // async saga is created in web-core.
    }

    // If no lastViewedProjectId is retrieved, we simply pass in undefined to setCurrentAccount,
    // and AccountContainer will be responsible for deriving how to redirect a given user based on
    // its fetched team and project data.
    yield put(setCurrentAccount(accountId, prevAccountId, lastViewedProjectId));

    /**
     * Do not set lastViewedAccountId if account is a v4 account - this prevents
     * locking the user out of v3 with continual re-directs to next.frame.io
     */
    if (fioVersion === NEXT_VERSION_NUMBER) return;

    yield put(setLastViewedAccount(accountId));
    yield call(setAccountEligibilityStatus, accountId);
  }
}

/**
 * Handles redirect behavior for an account by first retrieving the last viewed
 * project id from core. If no last project id is obtained, redirect to the
 * first team of the account, the first shared project, or if the user is a reviewer
 * to their account inbox.
 */
export function* redirectToAccount(accountId, queryParams) {
  if (!accountId) {
    yield call(redirectToRoot);
    return;
  }

  // Check if the user is a reviewer
  const isReviewer = yield select(isReviewerHighestRoleSelector, { accountId });
  const { lastViewedProjectId } = yield select(currentAccountSelector);

  // If the user is a reviewer *only*, redirect them to their account inbox
  if (isReviewer) {
    yield call(redirectTo, getPath(INBOX_URL, { accountId }));
  } else if (lastViewedProjectId) {
    // Otherwise if the current account has a last viewed project id set, redirect the user
    // to that project
    yield call(redirectToProject, lastViewedProjectId, queryParams);
  } else {
    // In the final case, redirect them to the first team or shared project listed for that account
    // NOTE: This redirect saga has several redirect cases of its own. In the final case of this
    // saga, it will redirect to the ROOT_URL `/`, which can call the `enterAccount` saga again,
    // potentially causing an infinite loop if no exit case exists.
    yield call(redirectToFirstTeamOrSharedProject, accountId, queryParams);
  }
}

export function* createProject(maybeTeamId) {
  let teamId = maybeTeamId;

  if (!teamId) {
    const user = yield select(currentUserEntitySelector) || {};
    const [firstName] = user.name.split(' ');
    const team = {
      name: formatPossessive(firstName, 'Team', 'My Team'),
    };
    const { success } = yield call(createTeamByAccount, user.account_id, team);
    if (success) {
      teamId = success.payload.response.result;
    } else {
      yield call(
        error,
        'Oops, something with wrong!',
        'We couldn’t create a team for you, please contact support for assistance.'
      );
      if (window.Intercom) {
        yield call(window.Intercom, 'show');
      }
      return;
    }
  }
  yield put(setTeamId(teamId));
  yield put(openModal(<ConnectedCreateProjectForm />));
}

export default [
  ...TeamContainerSagas,
  ...SearchContainerSagas,
  takeLatest(ACCOUNT_CONTAINER.CREATE_PROJECT, ({ payload: { teamId } }) =>
    createProject(teamId)
  ),
  takeEvery(ACCOUNT_CONTAINER.ENTER_ACCOUNT, ({ payload: { accountId } }) =>
    enterAccount(accountId)
  ),
  takeEvery(
    ACCOUNT_CONTAINER.REDIRECT_TO_ACCOUNT,
    ({ payload: { accountId, queryParams } }) =>
      redirectToAccount(accountId, queryParams)
  ),
];

export const testExports = {
  createProject,
  enterAccount,
  redirectToFirstTeamOrSharedProject,
  redirectToAccount,
};
