import { TEAM } from 'actions/teams';

const setCurrentTeam = (state = {}, action) => {
  switch (action.type) {
    case TEAM.SET_CURRENT: {
      const { teamId } = action.payload;
      return { ...state, id: teamId };
    }
    default:
      return state;
  }
};

export default setCurrentTeam;
