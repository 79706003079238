import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import InfoIcon from '@frameio/components/src/svgs/icons/24/info-filled.svg';

const StyledInfoIcon = styled(InfoIcon).attrs(() => ({
  height: 20,
  width: 20,
}))`
  color: ${(p) => p.theme.color.graphiteGray};
  margin-right: 6px;
`;

const Container = styled(Flex)`
  background-color: ${(p) => p.theme.color.coldWhite};
  border-radius: ${(p) => p.theme.radius.large};
  padding: ${(p) => p.theme.spacing.units(1)} 14px;
  margin-top: ${(p) => p.theme.spacing.units(1)};
`;

const BannerText = styled(Flex)`
  ${(p) => p.theme.fontStyle.bodyS};
  color: ${(p) => p.theme.color.coolBlack};
  width: 95%;
`;

export default function WatermarkDisclaimerBanner() {
  return (
    <Container alignCenter>
      <StyledInfoIcon />
      <BannerText>
        When Public access is enabled, logged out users will not see their name
        and email included in the watermark.
      </BannerText>
    </Container>
  );
}
