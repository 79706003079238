import React from 'react';
import PropTypes from 'prop-types';
import { MenuButton } from '@frameio/vapor';
import UpArrowIcon from '@frameio/components/src/svgs/icons/arrow-up.svg';
import { trackButtonClick } from 'analytics';

export default function MoveAssetsToDestinationFolder({
  assetIds,
  destinationId,
  moveAssets,
  trackingPage,
  trackingPosition,
  trackingTitle,
  title,
  ...rest
}) {
  return (
    <MenuButton
      iconBefore={<UpArrowIcon width={16} height={16} />}
      onSelect={() => {
        moveAssets(assetIds, destinationId);
        trackButtonClick(trackingTitle, trackingPage, trackingPosition);
      }}
      {...rest}
    >
      {title}
    </MenuButton>
  );
}

MoveAssetsToDestinationFolder.propTypes = {
  assetIds: PropTypes.array.isRequired,
  destinationId: PropTypes.string.isRequired,
  moveAssets: PropTypes.func.isRequired,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
  trackingTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
