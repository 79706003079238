import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import Flex from 'styled-flex-component';
import track from 'analytics';
import { constant, noop } from 'lodash';
import Button from '@frameio/components/src/styled-components/Button';
import TextArea from '@frameio/components/src/styled-components/TextArea';
import AnimateHeight from '@frameio/components/src/styled-components/AnimateHeight';
import MessageIcon from '@frameio/components/src/svgs/icons/16/message.svg';
import UserSearch from 'components/UserSearch/';
import LinkAccess, { LINK_ACCESS_OPTIONS } from 'components/LinkAccess';
import {
  searchUsersStrategy,
  searchGroupsStrategy,
  searchTeamsStrategy,
  searchPendingReviewersStrategy,
} from 'components/UserSearch/strategies';
import { getPublicPresentationUrl } from 'URLs';
import { SettingsSubHeader } from 'components/ReviewLinkEditor/ReviewLinkEditor';
import WatermarkDisclaimerBanner from 'components/WatermarkDisclaimerBanner';
import PresentationPersonRow from './PresentationPersonRow/PresentationPersonRow';
import ManagePeopleList from './ConnectedSecurePresentationManagePeopleList';

const StyledMessageIcon = styled(MessageIcon)`
  color: ${(p) => p.theme.color.graphiteGray};
  margin-right: ${(p) => p.theme.spacing.micro};
`;

const StyledManagePeopleList = styled(ManagePeopleList)`
  padding-top: ${(p) => p.theme.spacing.tiny};
`;

const AddEmailMessageButton = styled(Button)`
  ${(p) => p.theme.fontStyle.body};
  border: 1px solid transparent;
`;

const EmailMessageStyledTextArea = styled(TextArea)`
  // Don't allow the text area to be resized below 32px:
  min-height: ${(p) => p.theme.spacing.large};
  height: ${(p) => p.theme.spacing.large};
  margin-right: ${(p) => p.theme.spacing.small};
  padding: ${(p) => `${p.theme.spacing.micro} ${p.theme.spacing.tiny}`};
  resize: vertical;
`;

const StyledFlex = styled(Flex)`
  min-height: ${(p) => p.theme.spacing.units(7)};
  padding-top: ${(p) => p.theme.spacing.tiny};
`;

// Enterprise accounts are allowed a higher message limit on shares
const MAX_MESSAGE_LENGTH = '255';
const ENTERPRISE_MAX_MESSAGE_LENGTH_FOR_PRESENTATIONS = '2500';

const SecureSidePanelShareForm = ({
  accountId,
  canCustomizeEmailMessages,
  canSharePublicly,
  copyShortUrlToClipboard,
  formik: {
    errors,
    handleChange,
    setFieldValue,
    values: {
      currentMode,
      emailMessage,
      isExpired,
      projectId,
      sessionWatermarkId,
      shortUrl,
      showEmailMessage,
      userTokens,
    },
  },
  secureSharingEnabled,
  inviteUsersToPresentation,
  isEnterprise,
  onChange,
  presentationId,
  vanityId,
  totalInviteCount,
}) => {
  const handleAccessOptionChangeAndSubmit = React.useCallback(
    (nextValue) => {
      track('presentation-modal-button-clicked', {
        title: nextValue,
        page: 'presentation_modal',
        position: 'link_access',
        modal_version: '2020_01_tabs',
      });
      onChange({ target: { name: 'currentMode', value: nextValue } });
    },
    [onChange]
  );
  const handleCopyShortUrlToClipboard = React.useCallback(
    (url) => {
      copyShortUrlToClipboard(url);
      track('project-links-page-url-copied', {
        type: 'presentation_modal',
        modal_version: '2020_01_tabs',
        presentation_id: presentationId,
      });
    },
    [copyShortUrlToClipboard, presentationId]
  );

  return (
    <div>
      <SettingsSubHeader>Link access</SettingsSubHeader>
      <LinkAccess
        canSharePublicly={canSharePublicly}
        copyShortUrlToClipboard={handleCopyShortUrlToClipboard}
        currentMode={currentMode}
        secureSharingEnabled={secureSharingEnabled}
        isExpired={!!isExpired}
        onAccessModeSelect={handleAccessOptionChangeAndSubmit}
        // WK-158: https://github.com/Frameio/web-client/pull/8426
        shortUrl={getPublicPresentationUrl(vanityId, shortUrl)}
        showSecureSharingTooltip={
          currentMode === LINK_ACCESS_OPTIONS.PRIVATE &&
          (!totalInviteCount || totalInviteCount === 0)
        }
      />
      {currentMode === LINK_ACCESS_OPTIONS.PUBLIC &&
        Boolean(sessionWatermarkId) && <WatermarkDisclaimerBanner />}

      {currentMode !== LINK_ACCESS_OPTIONS.DISABLED && (
        <React.Fragment>
          <SettingsSubHeader>Invite by email</SettingsSubHeader>

          <UserSearch
            newUserPrompt={(user) => `Invite ${user} to this presentation`}
            placeholderText="Enter name or email address"
            setTokens={(tokens) => setFieldValue('userTokens', tokens)}
            tokens={userTokens}
            strategies={[
              searchUsersStrategy(accountId, projectId),
              searchGroupsStrategy(accountId),
              searchTeamsStrategy(accountId),
              searchPendingReviewersStrategy(projectId),
            ]}
          />

          <AnimateHeight
            isVisible={userTokens.length > 0}
            duration={200}
            easing="cubic-bezier(0.25, 0, 0, 1)"
          >
            <StyledFlex justifyBetween>
              {showEmailMessage ? (
                /* TODO [https://frame-io.atlassian.net/browse/RNC-1445] Ideally we'd tell the user
              why their input is invalid along with putting the component into an error state and
              disabling the send button. We could add an error message to the <TextArea /> base
              component, using <TextInput /> as a model. */
                <EmailMessageStyledTextArea
                  autoFocus
                  name="emailMessage"
                  onBlur={() => {
                    if (!emailMessage)
                      setFieldValue('showEmailMessage', !showEmailMessage);
                  }}
                  onChange={handleChange}
                  maxLength={
                    isEnterprise
                      ? ENTERPRISE_MAX_MESSAGE_LENGTH_FOR_PRESENTATIONS
                      : MAX_MESSAGE_LENGTH
                  }
                  rows="1"
                  error={errors.emailMessage}
                  value={emailMessage}
                />
              ) : (
                canCustomizeEmailMessages && (
                  <AddEmailMessageButton
                    name="showEmailMessage"
                    onClick={() => {
                      setFieldValue('showEmailMessage', !showEmailMessage);
                      track('presentation-modal-button-clicked', {
                        title: 'add_a_message',
                        page: 'presentation_modal',
                        position: 'middle',
                        modal_version: '2020_01_tabs',
                      });
                    }}
                    text
                    type="button"
                  >
                    <React.Fragment>
                      <StyledMessageIcon />
                      Add a message
                    </React.Fragment>
                  </AddEmailMessageButton>
                )
              )}
              <Button
                disabled={!!errors.emailMessage}
                onClick={() => {
                  inviteUsersToPresentation(
                    userTokens,
                    emailMessage,
                    presentationId
                  );
                  track('presentation-modal-button-clicked', {
                    title: 'send',
                    page: 'presentation_modal',
                    position: 'middle',
                    modal_version: '2020_01_tabs',
                  });
                }}
                primary
                type="button"
              >
                Send
              </Button>
            </StyledFlex>
          </AnimateHeight>

          <StyledManagePeopleList
            className="manage-people"
            presentationId={presentationId}
            projectId={projectId}
            makeAllRowsVisible
            renderHeader={(personCount) => (
              <h4>
                Shared with {personCount}{' '}
                {personCount === 1 ? 'person' : 'people'}
              </h4>
            )}
            renderEmptyList={constant(<React.Fragment />)}
            scrollTop={0}
            setScrollTop={noop}
            shouldShowFilter={false}
            trackingEvent="presentation-modal-button-clicked"
            trackingPage="presentation_modal"
            trackingPosition="invite_people"
            trackingTitle="share_link"
          >
            {(entityId) => <PresentationPersonRow entityId={entityId} />}
          </StyledManagePeopleList>
        </React.Fragment>
      )}
    </div>
  );
};

SecureSidePanelShareForm.propTypes = {
  accountId: PropTypes.string.isRequired,
  canCustomizeEmailMessages: PropTypes.bool.isRequired,
  canSharePublicly: PropTypes.bool.isRequired,
  copyShortUrlToClipboard: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    errors: PropTypes.shape({
      emailMessage: PropTypes.string,
    }),
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.shape({
      currentMode: PropTypes.oneOf(Object.values(LINK_ACCESS_OPTIONS)),
      emailMessage: PropTypes.string.isRequired,
      isExpired: PropTypes.bool.isRequired,
      // projectId: PropTypes.string.isRequired, // TODO: determine whether this is the same as `values.id`
      sessionWatermarkId: PropTypes.string,
      shortUrl: PropTypes.string.isRequired,
      showEmailMessage: PropTypes.bool.isRequired,
      userTokens: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
  }).isRequired,
  secureSharingEnabled: PropTypes.bool.isRequired,
  inviteUsersToPresentation: PropTypes.func.isRequired,
  presentationId: PropTypes.string.isRequired,
  vanityId: PropTypes.string,
  totalInviteCount: PropTypes.number,
};

export default connect(SecureSidePanelShareForm);
