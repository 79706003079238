import { connect } from 'react-redux';
import { currentAccountSelector } from 'selectors/accounts';
import LayoutWithNav from './LayoutWithNav';

/**
 * LayoutWithNav is loaded across the Dashboard and Player pages. We're placing these actions in
 * here so that we can load across the old and the new dashboard.
 *
 * TODO (cjbell): re-think this data loading approach post dashboard v2.
 */

export const mapStateToProps = (state) => ({
  currentAccount: currentAccountSelector(state),
});

export default connect(mapStateToProps)(LayoutWithNav);
