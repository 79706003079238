import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import track, { trackButtonClick } from 'analytics';
import Button from '@frameio/components/src/styled-components/Button';
import UploadImage from '../UploadImage';
import ProgressDots from '../ProgressDots';
import {
  SETUP_ACCOUNT_FLOW,
  UPLOAD_IMAGE_FOR_ME_URL,
} from '../SetupAccountFlow';

const Container = styled(Flex).attrs(() => ({
  column: true,
}))`
  height: ${(p) => p.theme.spacing.units(50)};
  width: ${(p) => p.theme.spacing.units(50)};
  padding: ${(p) => p.theme.spacing.medium};
`;

const Header = styled.h2`
  font-weight: bold;
  font-size: ${(p) => p.theme.fontSize[3]};
  color: ${(p) => p.theme.color.coolBlack};
  margin-bottom: ${(p) => p.theme.spacing.units(2)};
`;

const Subheader = styled.p`
  font-size: ${(p) => p.theme.fontSize[2]};
  color: ${(p) => p.theme.color.gray};
`;

const ButtonsContainer = styled(Flex).attrs(() => ({ justifyBetween: true }))`
  ${Button} + ${Button} {
    margin-left:  ${(p) => p.theme.spacing.tiny};
  }
`;

const SkipButton = styled(Button).attrs(() => ({ text: true }))``;

export default function UploadTeamImage({
  currentStep,
  entityId,
  isUploading,
  onContinue,
  header,
  imageUrl,
  subheader,
  removeImage,
  trackingPageName,
  uploadDroppedItem,
  uploadSelectedItem,
}) {
  return (
    <Container>
      <Header>{header}</Header>
      <Subheader>{subheader}</Subheader>
      <UploadImage
        currentStep={currentStep}
        entityId={entityId}
        isUploading={isUploading}
        onContinue={onContinue}
        imageUrl={imageUrl}
        removeImage={() => {
          trackButtonClick(
            'remove image',
            SETUP_ACCOUNT_FLOW,
            trackingPageName
          );
          removeImage(entityId);
        }}
        uploadDroppedItem={(file) => {
          track('upload image', { step: trackingPageName });
          uploadDroppedItem(file, entityId);
        }}
        uploadSelectedItem={(file) => {
          trackButtonClick(
            'select image',
            SETUP_ACCOUNT_FLOW,
            trackingPageName
          );
          uploadSelectedItem(file, entityId);
        }}
      />
      <ButtonsContainer>
        <ProgressDots currentStep={currentStep} />
        <div>
          <SkipButton
            onClick={() => {
              trackButtonClick('skip', SETUP_ACCOUNT_FLOW, trackingPageName);
              onContinue();
            }}
          >
            Skip
          </SkipButton>
          <Button
            primary
            onClick={() => {
              trackButtonClick('next', SETUP_ACCOUNT_FLOW, trackingPageName);
              track(`${trackingPageName}-submitted`);
              onContinue();
            }}
          >
            {currentStep === UPLOAD_IMAGE_FOR_ME_URL ? 'Finish' : 'Next'}
          </Button>
        </div>
      </ButtonsContainer>
    </Container>
  );
}

UploadTeamImage.propTypes = {
  currentStep: PropTypes.string.isRequired,
  entityId: PropTypes.string,
  isUploading: PropTypes.bool.isRequired,
  onContinue: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  subheader: PropTypes.string.isRequired,
  removeImage: PropTypes.func.isRequired,
  trackingPageName: PropTypes.string.isRequired,
  uploadDroppedItem: PropTypes.func.isRequired,
  uploadSelectedItem: PropTypes.func.isRequired,
};

UploadTeamImage.defaultProps = {
  entityId: null,
  imageUrl: null,
};
