import reduceReducers from 'reduce-reducers';
import { get } from 'lodash';
import { generatePaginatedListReducer } from '@frameio/core/src/shared/reducers/factories';
import { isFetchingReducerFactory as isFetchingReducerFactoryHelper } from 'factories/isFetching';
import { NOTIFICATIONS } from './actions';
import { NOTIFICATIONS_FILTER_OPTIONS } from './constants';

const isFetchingReducer = isFetchingReducerFactoryHelper(
  NOTIFICATIONS.IS_FETCHING
);

const INITIAL_STATE = {
  isFetching: false,
};

function notificationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case NOTIFICATIONS.SET_RESULTS_FOR_ACCOUNT: {
      const {
        accountId,
        filter,
        pageInfo,
        results,
        mergeResults,
      } = action.payload;
      const currentResults = get(state, `${accountId}.${filter}.results`) ?? [];

      return {
        ...state,
        [accountId]: {
          ...state[accountId],
          [filter]: {
            stale: false,
            pageInfo,
            results: mergeResults ? [...currentResults, ...results] : results,
          },
        },
      };
    }

    case NOTIFICATIONS.UPDATE_UNREAD_RESULTS_FOR_ACCOUNT: {
      const { accountId, notificationId, results } = action.payload;
      const currentResults = get(
        state,
        `${accountId}.${NOTIFICATIONS_FILTER_OPTIONS.UNREAD}.results`,
        []
      );
      const newResults =
        results !== undefined
          ? results
          : currentResults.filter((id) => id !== notificationId);
      return {
        ...state,
        [accountId]: {
          ...state[accountId],
          [NOTIFICATIONS_FILTER_OPTIONS.UNREAD]: {
            ...state[accountId][NOTIFICATIONS_FILTER_OPTIONS.UNREAD],
            stale: true, // indicating we have made an optimistic update, which will trigger a refetch when switching filters
            results: newResults,
          },
        },
      };
    }

    case NOTIFICATIONS.SET_HAS_OPENED_POPOVER: {
      const { accountId } = action.payload;
      return {
        ...state,
        [accountId]: {
          ...state[accountId],
          hasOpenedPopover: true,
        },
      };
    }

    case NOTIFICATIONS.SET_NEW_NOTIFICATIONS_COUNT_FOR_ACCOUNT: {
      const { accountId, newNotificationsCount } = action.payload;
      return {
        ...state,
        [accountId]: {
          ...state[accountId],
          newNotificationsCount,
        },
      };
    }

    case NOTIFICATIONS.IS_FETCHING_MORE: {
      const { accountId, isFetchingMore } = action.payload;
      return {
        ...state,
        [accountId]: {
          ...state[accountId],
          isFetchingMore,
        },
      };
    }

    case NOTIFICATIONS.SET_FILTER: {
      const { filter } = action.payload;
      return {
        ...state,
        filter,
      };
    }

    default:
      return isFetchingReducer(state, action);
  }
}

const results = generatePaginatedListReducer(NOTIFICATIONS.FETCH);
const paginatedResults = generatePaginatedListReducer(NOTIFICATIONS.FETCH_MORE);

export default reduceReducers(
  INITIAL_STATE,
  results,
  paginatedResults,
  notificationsReducer
);
