import { connect } from 'react-redux';
import { permittedActionsForProjectSelector } from 'selectors/permissions';
import {
  currentAccountEntitySelector,
  isAccountOnFreePlanWithUserMaxSelector,
} from 'selectors/accounts';
import { isAccountAdminSelector } from 'selectors/roles';
import { startArchiveProject } from './actions';
import ArchiveProject from './ArchiveProject';

function mapStateToProps(state, { projectId }) {
  const account = currentAccountEntitySelector(state) || {};
  const { id: accountId } = account;
  const { canArchiveProject } = permittedActionsForProjectSelector(state, {
    projectId,
  });
  const isAdmin = isAccountAdminSelector(state, { accountId });
  const isAccountOnFreePlanWithUserMax = isAccountOnFreePlanWithUserMaxSelector(
    state
  );

  return {
    isAccountOnFreePlanWithUserMax,
    accountId,
    canArchiveProject,
    isAdmin,
  };
}

export const mapDispatchToProps = {
  onArchiveProjectClick: startArchiveProject,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchiveProject);
