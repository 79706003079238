class JTAnimationEngine {

  constructor(hash) {

    this.bind_animation = this.bind_animation.bind(this);
    this.unbind_animation = this.unbind_animation.bind(this);
    this.bind_surface = this.bind_surface.bind(this);
    this.unbind_surface = this.unbind_surface.bind(this);
    this.start = this.start.bind(this);
    this.next_frame = this.next_frame.bind(this);
    this.stop = this.stop.bind(this);
    this.pause = this.pause.bind(this);
    this.animate_custom = this.animate_custom.bind(this);
    this.animate_frame_custom = this.animate_frame_custom.bind(this);
    this.get_pos = this.get_pos.bind(this);
    this.hash = hash;
    this.data = {};

    this.running = false;

    this.animate_frame = null;

    this.frame = 0;

    this.custom_animations = {};
  }

  bind_animation(key, func) {

    if (!this.data[key]) { this.data[key] = {}; }

    this.data[key]['function'] = func;

    this.data[key]['frame'] = 0;

    if (!this.running) { return this.start(); }
  }

  unbind_animation(key) {

    return delete this.data[key];
  }

  bind_surface(surface_key, surface) {

    if (!this.data[surface_key]) { this.data[surface_key] = {}; }

    this.data[surface_key]['function'] = t => surface.determine(t);

    this.data[surface_key]['frame'] = 0;

    if (!this.running) { return this.start(); }
  }

  unbind_surface(surface_key) {

    return this.unbind_animation(surface_key);
  }

  start() {

    this.running = true;

    return this.animate_frame = requestAnimationFrame(this.next_frame);
  }

  next_frame() {

    let keys = Object.keys(this.data);

    this.frame += 1;

    if (keys.length > 0) {

      for (let key in this.data) {

        let data = this.data[key];
        ((key, data) => {

          let current_frame = data['frame'];

          let func = data['function'];

          func(current_frame);

          return data['frame'] = current_frame + 1;
        })(key, data);
      }

      return this.animate_frame = requestAnimationFrame(this.next_frame);

    } else {

      return this.stop();
    }
  }

  stop() {

    cancelAnimationFrame(this.animate_frame);

    this.animate_frame = null;

    return this.running = false;
  }

  pause(animation_key) {

    let animation = this.custom_animations[animation_key];

    if (animation && animation.animating) {

      animation.animating = false;

      cancelAnimationFrame(animation.loop);

      for (let key in animation.poses) {
        let val = animation.poses[key];
        animation.poses[key] = Math.round(val);
      }

      if (animation.callback) { animation.callback(); }
      return animation.callback = null;
    }
  }

  animate_custom(animation_key, initial, final, custom_function, duration, animation_function, callback) {

    if (animation_function == null) { animation_function = null; }
    if (callback == null) { callback = null; }
    this.pause(animation_key);

    let animation = this.custom_animations[animation_key];
    if (!animation) { animation = {}; }

    if (!animation.animating) {

      animation.animating = true;

      animation.last_frame = (new Date)
        .getTime() - 16;

      if (callback) { animation.callback = callback; }

      animation.initial = initial;
      animation.final = final;
      animation.diffs = {};
      animation.poses = {};

      for (let key in animation.initial) {

        let val = animation.initial[key];
        if (!animation.initial[key]) { animation.initial[key] = 0; }
        let end_val = final[key] || 0;
        animation.diffs[key] = end_val - animation.initial[key];
      }

      animation.time = 0;
      animation.custom_function = custom_function;
      animation.duration = duration;
      animation.animation_function = animation_function;

      this.custom_animations[animation_key] = animation;
      return animation.loop = requestAnimationFrame(() => this.animate_frame_custom(animation));
    }
  }

  animate_frame_custom(animation) {

    let { time } = animation;
    let { duration } = animation;
    let { animation_function } = animation;

    if (time <= duration) {

      let frame = (new Date)
        .getTime();
      let diff = frame - animation.last_frame;

      if ((duration - time) < 16) { time = duration; }

      if ((diff > 15) || (time === duration)) {

        let pos = this.get_pos(time, duration, animation_function);

        for (let key in animation.diffs) {

          let val = animation.diffs[key];
          animation.poses[key] = animation.initial[key] + (animation.diffs[key] * pos);
        }

        animation.custom_function(animation.poses);

        animation.last_frame = frame;

        animation.time = time + diff;
        return animation.loop = requestAnimationFrame(() => this.animate_frame_custom(animation));

      } else {
        animation.time = time;
        return animation.loop = requestAnimationFrame(() => this.animate_frame_custom(animation));
      }

    } else {

      animation.poses = animation.final;
      animation.custom_function(animation.poses);

      animation.animating = false;

      if (this.callback) { animation.callback(); }
      return animation.callback = null;
    }
  }

  get_pos(time, duration, animation_function) {

    let pos;
    if (animation_function == null) { animation_function = null; }
    let b = 0; // Begin
    let d = duration; // Duration
    let c = 1; // Change
    let t = time; // Current time

    if (animation_function) {
      pos = animation_function(t, b, c, d);
    } else {
      pos = ((t /= d / 2) < 1) ? (((c / 2) * t * t * t) + b) : (((c / 2) * (((t -= 2) * t * t) + 2)) + b);
    }

    return pos;
  }
}

export default JTAnimationEngine;
