import { Tooltip } from '@frameio/vapor';
import { connect } from 'react-redux';
import React from 'react';
import { currentAccountSelector } from 'selectors/accounts';
import { isAccountAdminSelector } from 'selectors/roles';
import PremiumBadge from '@frameio/components/src/styled-components/PremiumBadge';

function FeatureGateTooltip({ isAdmin }) {
  return (
    <Tooltip
      details={isAdmin && 'Click to learn more.'}
      offset={[0, 10]}
      placement="top"
      title="Available on Pro ✨"
      variant="dark"
    >
      <PremiumBadge>Upgrade</PremiumBadge>
    </Tooltip>
  );
}

function mapStateToProps(state) {
  const { id: accountId } = currentAccountSelector(state);

  return {
    isAdmin: isAccountAdminSelector(state, { accountId }),
  };
}

export default connect(mapStateToProps)(FeatureGateTooltip);
