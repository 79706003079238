import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Router, Route, Switch } from 'react-router-dom';
import CloseButton from '@frameio/components/src/components/Modal/CloseButton';
import history from './history';
import UnarchiveProject from './UnarchiveProject';

export const UNARCHIVE_PROJECT_URL = '/UnarchiveProject';

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: ${(p) => p.theme.spacing.tiny};
  right: ${(p) => p.theme.spacing.tiny};
`;

export default class UnarchiveProjectFlow extends React.Component {
  componentDidMount() {
    const {
      projectId,
      isUpdatedArchivalMessagingEnabled,
      startFlow,
    } = this.props;

    startFlow(projectId, isUpdatedArchivalMessagingEnabled);
  }

  componentWillUnmount() {
    this.props.endFlow();
  }

  render() {
    const { projectId, closeModal } = this.props;

    return (
      <React.Fragment>
        <StyledCloseButton onClick={closeModal} />
        <Router history={history}>
          <Switch>
            <Route
              path={UNARCHIVE_PROJECT_URL}
              render={() => <UnarchiveProject projectId={projectId} />}
            />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

UnarchiveProjectFlow.propTypes = {
  projectId: PropTypes.string.isRequired,
  startFlow: PropTypes.func.isRequired,
  endFlow: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};
