import AssetThumb from 'components/AssetThumb';
import styled from 'styled-components';

const DialogAssetThumb = styled(AssetThumb).attrs(() => ({
  width: 80,
  height: 45,
}))`
  margin: ${({ theme }) => `0 ${theme.spacing.small} 0 0`};
  box-shadow: ${(p) => p.theme.shadow.medium};
  border-radius: ${(p) => p.theme.radius.medium};
  overflow: hidden;
`;

DialogAssetThumb.propTypes = AssetThumb.propTypes;
DialogAssetThumb.defaultProps = AssetThumb.defaultProps;

export default DialogAssetThumb;
