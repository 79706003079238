import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const PLAN_UPGRADE = generateActionTypes('PLAN_UPGRADE', [
  'UPGRADE_ACCOUNT',
  'ADD_PAYMENT',
]);

export const upgradeAccount = createAction(
  PLAN_UPGRADE.UPGRADE_ACCOUNT,
  (accountId, trackingLocation) => ({ accountId, trackingLocation })
);

export const addPayment = createAction(
  PLAN_UPGRADE.ADD_PAYMENT,
  (accountId, trackingLocation) => ({ accountId, trackingLocation })
);
