import { connect } from 'react-redux';
import { downloadAsset } from 'actions/assets';
import PresentationHeader from './PresentationHeader';

const mapDispatchToProps = {
  downloadAsset,
};

export default connect(
  null,
  mapDispatchToProps
)(PresentationHeader);
