import { status } from '@frameio/core/src/shared/reducers/factories';
import { LEGACY_PROJECT, PROJECT } from 'actions/projects';

const { PENDING, SUCCESS, FAILURE } = status;

export const currentProject = (state = {}, action) => {
  switch (action.type) {
    case PROJECT.SET_CURRENT: {
      const { projectId } = action.payload;
      return { ...state, id: projectId };
    }
    case LEGACY_PROJECT.SET_CURRENT: {
      const { project } = action.payload;
      return { ...state, ...project };
    }
    default:
      return state;
  }
};

const INITIAL_TREE_STATE = {
  tree: {},
  fetchStatus: null,
};

export const projectFolderTrees = (state = {}, action) => {
  switch (action.type) {
    case PROJECT.FETCH_TREE.SUCCESS: {
      const {
        payload: { response: tree, entityId: projectId },
      } = action;
      return {
        ...state,
        [projectId]: {
          tree,
          fetchStatus: SUCCESS,
        },
      };
    }

    case PROJECT.FETCH_TREE.PENDING: {
      const {
        payload: { entityId: projectId },
      } = action;
      return {
        ...state,
        [projectId]: {
          ...INITIAL_TREE_STATE,
          fetchStatus: PENDING,
        },
      };
    }

    case PROJECT.FETCH_TREE.FAILURE: {
      const {
        payload: { entityId: projectId },
      } = action;
      return {
        ...state,
        [projectId]: {
          ...INITIAL_TREE_STATE,
          fetchStatus: FAILURE,
        },
      };
    }

    default:
      return state;
  }
};
