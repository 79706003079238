import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import Dialog, {
  dialogTypes,
} from '@frameio/components/src/styled-components/Dialog';
import Button from '@frameio/components/src/styled-components/Button';
import AccountOwnerPill from 'components/AccountOwnerPill';
import { formatBytes } from 'shared/filesizeHelpers';
import StorageLimitIllustration from '@frameio/components/src/svgs/illustrations/storage-limit.svg';

const StorageLimitIllustrationContainer = styled(Flex)`
  margin-top: ${(p) => p.theme.spacing.tiny};
  margin-bottom: ${(p) => p.theme.spacing.medium};
`;

const Copy = styled.p`
  color: ${(p) => p.theme.color.gray};
  text-align: center;
  padding: 0 10px;
`;

const AccountOwnerCopyContainer = styled.div`
  margin-top: ${(p) => p.theme.spacing.small};
`;

const UserPillContainer = styled(Flex)`
  margin-top: ${(p) => p.theme.spacing.tiny};
`;

function renderAccountOwnerCopy(accountId) {
  return (
    <AccountOwnerCopyContainer>
      <Copy>Account owner</Copy>
      <UserPillContainer justifyCenter>
        <AccountOwnerPill accountId={accountId} />
      </UserPillContainer>
    </AccountOwnerCopyContainer>
  );
}

function renderButtonCTA(isAccountAdmin, isAccountFree) {
  if (isAccountAdmin && isAccountFree) {
    return 'Upgrade';
  }
  if (isAccountAdmin && !isAccountFree) {
    return 'Add more storage';
  }
  return 'Got it';
}

function renderCopy(isAccountAdmin, isAccountFree, totalStorageLimit) {
  if (isAccountAdmin && isAccountFree) {
    return `Looks like this account has reached its ${formatBytes(
      totalStorageLimit
    )} storage limit. Upgrade now to get more storage.`;
  }
  if (isAccountAdmin && !isAccountFree) {
    return `Glad to see you’re enjoying Frame.io! You’ve used all ${formatBytes(
      totalStorageLimit
    )} of storage on your plan, but you can keep uploading with storage add-ons.`;
  }
  return `Looks like this account has reached its ${formatBytes(
    totalStorageLimit
  )} storage limit. Notify the account owner to upgrade the plan or add more storage.`;
}

export default function OutOfSpace({
  isAccountAdmin,
  onCancel,
  onContinue,
  accountId,
  isAccountFree,
  subscription,
}) {
  return (
    <Dialog
      type={dialogTypes.NONE}
      header="Upgrade for more storage"
      primaryButton={
        <Button primary onClick={isAccountAdmin ? onContinue : onCancel}>
          {renderButtonCTA(isAccountAdmin, isAccountFree)}
        </Button>
      }
      secondaryButton={
        isAccountAdmin && <Button onClick={onCancel}>No thanks</Button>
      }
    >
      <StorageLimitIllustrationContainer justifyCenter>
        <StorageLimitIllustration />
      </StorageLimitIllustrationContainer>

      <Copy>
        {renderCopy(
          isAccountAdmin,
          isAccountFree,
          subscription.total_storage_limit
        )}
      </Copy>
      {!isAccountAdmin && renderAccountOwnerCopy(accountId)}
    </Dialog>
  );
}

OutOfSpace.propTypes = {
  isAccountAdmin: PropTypes.bool,
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
  accountId: PropTypes.string,
  isAccountFree: PropTypes.bool,
  subscription: PropTypes.shape({
    total_storage_limit: PropTypes.number,
  }).isRequired,
};

OutOfSpace.defaultProps = {
  isAccountAdmin: null,
  onCancel: () => {},
  onContinue: () => {},
  accountId: '',
  isAccountFree: null,
};

export const testExports = {
  renderButtonCTA,
  renderCopy,
};
