import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TreeView from '@frameio/components/src/styled-components/TreeView';
import { motion } from 'framer-motion';
import ConnectedTreeTeamItem from './ConnectedTreeTeamItem';
import HighlightedName from './HighlightedName';
import useDeferredMount from './useDeferredMount';

const TreeViewContainer = styled(motion.div)`
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  height: 100%;
  padding: ${(p) => `0 ${p.theme.spacing.units(2)}`};
`;

/**
 * TreeNavigator
 *
 * The main view for displaying trees with teams
 */

function TreeNavigator({
  teams,
  query,
  onSelect,
  onDoubleClick,
  selectedId,
  expanded,
  onExpand,
  onScroll,
  deferMount,
  scrollRef,
}) {
  // we use deferred mount to delay mounting of the initial
  // treeview for perf reasons. it generally makes the initial
  // mount seem smoother.
  const shouldMount = useDeferredMount();

  if (deferMount && !shouldMount) {
    return null;
  }

  const isSearching = !!query;

  return (
    <TreeViewContainer
      animate={{ opacity: 1, transition: { duration: 0.2 } }}
      initial={{
        opacity: deferMount ? 0 : 1,
      }}
      ref={scrollRef}
      onScroll={onScroll}
    >
      <TreeView
        onDoubleClick={onDoubleClick}
        selectOnFocus={false}
        onSelect={onSelect}
        selected={selectedId}
        expanded={expanded}
        onExpand={onExpand}
      >
        {teams.map((team) => (
          <ConnectedTreeTeamItem
            label={
              isSearching ? (
                <HighlightedName query={query} name={team.name} />
              ) : (
                team.name
              )
            }
            team={team}
            nodeId={team.id}
            key={team.id}
          />
        ))}
      </TreeView>
    </TreeViewContainer>
  );
}

TreeNavigator.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.object),
  query: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
};

export default React.memo(TreeNavigator);
