import { subscriptionLineItemForAccountAndModifierSelector } from '@frameio/core/src/subscriptionLineItems/selectors';
import { subscriptionTotalCostSelector } from '@frameio/core/src/subscriptions/selectors';
import { seatLimitPropertyName } from 'selectors/accounts';
import {
  ARCHIVED_STORAGE_LIMIT,
  STORAGE_LIMIT,
} from '@frameio/core/src/subscriptionLineItems/utils/modifierTypes';

export const subscriptionLineItemSelector = (
  state,
  { accountId, plan, modifier }
) => {
  const lineItem = subscriptionLineItemForAccountAndModifierSelector(state, {
    accountId,
    modifier,
  });
  return {
    totalCost: lineItem.totalCost,
    totalCount: lineItem.quantity * lineItem.increment + plan[modifier],
    lineItemCount: lineItem.quantity,
  };
};

export const isUpdatingSubscription = (state) =>
  state.manageSubscription.isFetching;

export const subscriptionAndLineItemsCostSelector = (
  state,
  { accountId, plan }
) => {
  const seatLimitType = seatLimitPropertyName(state);
  const lineItems = [seatLimitType, STORAGE_LIMIT, ARCHIVED_STORAGE_LIMIT].map(
    (modifier) =>
      subscriptionLineItemSelector(state, { accountId, plan, modifier })
  );
  return subscriptionTotalCostSelector(plan.cost, lineItems);
};
