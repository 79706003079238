import { WORKFRONT } from '@frameio/core/src/workfront/actions';
import reduceReducers from 'reduce-reducers';
import ADOBE_INTEGRATION_CONNECT_DIALOG from './actions';
import { aemRepositoriesReducer } from './Aem/reducer';
/**
 * Adobe Integration Connect Dialog state reducer
 *
 * @param {Object} state - The current state.
 * @param {Object} action - The action describing how to change the state
 * @returns {Object} - The new state.
 */
function adobeIntegrationConnectDialogReducer(
  state = { isOpen: false },
  action
) {
  switch (action.type) {
    case ADOBE_INTEGRATION_CONNECT_DIALOG.OPEN:
      return { ...action.payload, isOpen: true };
    case ADOBE_INTEGRATION_CONNECT_DIALOG.CLOSE:
      return { isOpen: false };
    case WORKFRONT.GET_TASKS.PENDING:
      return { ...state, isLoading: true };
    case WORKFRONT.GET_TASKS.SUCCESS:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}

export default reduceReducers(
  adobeIntegrationConnectDialogReducer,
  aemRepositoriesReducer
);
