import { createAction } from 'redux-actions';
import {
  generateActionTypes,
  generateFetchActionTypes,
} from 'shared/actions/helpers';

const module = 'PROJECT_PRESENTATIONS';
export const PROJECT_PRESENTATIONS = {
  ...generateActionTypes(module, [
    'CONFIRM_DELETE',
    'COPY_SHORT_URL',
    'EDIT_SETTINGS',
    'PATCH_SORT',
    'PATCH_TOGGLE_IS_ACTIVE',
    'SORT',
  ]),
  ...generateFetchActionTypes(module, ['DELETE', 'RENAME']),
  ...generateFetchActionTypes(module, ['FETCH'], true),
};

export const confirmDeletePresentation = createAction(
  PROJECT_PRESENTATIONS.CONFIRM_DELETE,
  (id) => ({ id })
);

export const copyShortUrlToClipboard = createAction(
  PROJECT_PRESENTATIONS.COPY_SHORT_URL,
  (url) => ({ url })
);

export const editPresentationSettings = createAction(
  PROJECT_PRESENTATIONS.EDIT_SETTINGS,
  (id) => ({ id })
);

export const patchSort = createAction(
  PROJECT_PRESENTATIONS.PATCH_SORT,
  () => ({})
);

export const patchToggleIsActive = createAction(
  PROJECT_PRESENTATIONS.PATCH_TOGGLE_IS_ACTIVE,
  (id, isActive) => ({ id, isActive })
);

export const fetchPresentationsForProject = createAction(
  PROJECT_PRESENTATIONS.FETCH.BASE,
  (projectId, page) => ({ projectId, page })
);

export const sortPresentations = createAction(
  PROJECT_PRESENTATIONS.SORT,
  (projectId, option, isDescending) => ({ projectId, option, isDescending })
);

export const resetPresentations = createAction(
  PROJECT_PRESENTATIONS.FETCH.RESET,
  (preserveMetadata) => ({ preserveMetadata })
);
