import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { Tooltip } from '@frameio/vapor';
import InfoIcon from '@frameio/components/src/svgs/icons/24/info-filled.svg';

const headerCopy = 'Frame.io is better with others 👯‍';
const subHeaderCopy = 'Invite your team to collaborate with you in Frame.io.';
const tooltipCopy = 'Team members are free during your trial!';

const StyledHeader = styled.h3`
  margin-bottom: ${(p) => p.theme.spacing.small};
  font-size: 18px;
`;

const SubHeader = styled(Flex).attrs({
  alignCenter: true,
  justifyCenter: true,
})`
  margin-bottom: 18px;
  font-size: ${(p) => p.theme.fontSize[2]};
  color: ${(p) => p.theme.color.gray};
  line-height: ${(p) => p.theme.lineHeight[2]};
`;

const IconContainer = styled.div`
  // nudge <GreyInfoIcon /> to be visually centered
  position: relative;
  top: 2px;
`;

const GreyInfoIcon = styled(InfoIcon).attrs(({ theme }) => ({
  color: theme.color.slateGray,
}))``;

function renderInfoIcon() {
  return (
    <Tooltip title={tooltipCopy} shouldUsePortal>
      <IconContainer>
        <GreyInfoIcon width={16} height={16} />
      </IconContainer>
    </Tooltip>
  );
}

function Header() {
  return (
    <React.Fragment>
      <StyledHeader>{headerCopy}</StyledHeader>
      <SubHeader data-test-id="subheader">
        {subHeaderCopy}
        {renderInfoIcon()}
      </SubHeader>
    </React.Fragment>
  );
}

export default Header;
