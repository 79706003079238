import React from 'react';
import styled from 'styled-components';

const SurveyWrapper = styled.div`
  height: 110%;
  margin-top: -90px;
  margin-bottom: -90px;
  overflow-y: auto;
  text-align: center;
`;

export function DoNotSellPrivacyForm() {
  return (
    <SurveyWrapper>
      <iframe
        height="1150"
        scrolling="no"
        src="https://survey.adobe.com/jfe/form/SV_0ponaaWpzamd8Sa"
        title="Do Not Share Or Sell Privacy Information"
        width="725"
      />
    </SurveyWrapper>
  );
}

export default DoNotSellPrivacyForm;
