import { select, call, put, takeEvery, spawn } from 'redux-saga/effects';
import Raven from 'raven-js';
import {
  getNotifications,
  markNotificationsRead as markNotificationsReadFromCore,
  markNotificationsUnread as markNotificationsUnreadFromCore,
  markNotificationsAllRead as markNotificationsAllReadFromCore,
} from '@frameio/core/src/notificationGroups/sagas';
import {
  setNotificationGroupReadStatus,
  markAllNotificationGroupsAsRead,
} from '@frameio/core/src/notificationGroups/actions';
import track from 'analytics';
import { currentAccountEntitySelector } from 'selectors/accounts';
import { showErrorToast } from 'actions/toasts';
import {
  setResultsForAccount,
  NOTIFICATIONS,
  isFetching,
  setIsFetchingMore,
  updateUnreadResultsForAccount,
} from './actions';
import {
  notificationEntitySelector,
  trackingPropertiesForNotificationSelector,
  notificationsFilterSelector,
  getPageInfoForAccountNotifications,
} from './selectors';

import {
  NOTIFICATION_STATUS,
  NOTIFICATIONS_FILTER_OPTIONS,
  numNotificationsToFetch,
  supportedNotificationTypes,
} from './constants';

function* trackNotificationReadStatusChange(notificationId, status) {
  const trackParams = yield select(trackingPropertiesForNotificationSelector, {
    notificationId,
  });
  yield call(track, 'notification-status-updated-client', {
    ...trackParams,
    updated_to: status,
  });
}

function* getDefaultOptionsForFetch() {
  const filter = yield select(notificationsFilterSelector);
  const status =
    filter === NOTIFICATIONS_FILTER_OPTIONS.UNREAD
      ? NOTIFICATION_STATUS.UNREAD
      : null;
  return {
    filters: {
      notification_types: supportedNotificationTypes,
      status,
    },
    page: {
      first: numNotificationsToFetch,
    },
  };
}

function* handleFetchError(error) {
  yield put(
    showErrorToast({
      header: 'Something went wrong while fetching your notifications',
    })
  );
  yield call([Raven, Raven.captureMessage], 'Failed to fetch notifications', {
    level: 'error',
    extra: { error },
  });
}

function* fetchMoreNotifications() {
  const { id: accountId } = yield select(currentAccountEntitySelector);

  const defaultOptions = yield call(getDefaultOptionsForFetch);
  const filter = yield select(notificationsFilterSelector);

  yield put(setIsFetchingMore(accountId, true));

  const previousPage = yield select(getPageInfoForAccountNotifications, {
    accountId,
  });

  const { success, failure } = yield call(getNotifications, accountId, {
    ...defaultOptions,
    page: {
      ...defaultOptions.page,
      after: previousPage ? previousPage.endCursor : undefined,
    },
  });

  if (success) {
    const { pageInfo, result: results } = success.payload.response;
    yield put(
      setResultsForAccount({
        accountId,
        filter,
        pageInfo,
        results,
        mergeResults: true,
      })
    );
  }

  if (failure) {
    yield call(handleFetchError, failure.payload.error);
  }

  yield put(setIsFetchingMore(accountId, false));
}

function* fetchNotifications() {
  const { id: accountId } = yield select(currentAccountEntitySelector);
  const filter = yield select(notificationsFilterSelector);
  const defaultOptions = yield call(getDefaultOptionsForFetch);

  yield put(isFetching(true));

  const { success, failure } = yield call(
    getNotifications,
    accountId,
    defaultOptions
  );

  if (success) {
    const { pageInfo, result: results } = success.payload.response;
    yield put(setResultsForAccount({ accountId, filter, pageInfo, results }));
  }

  if (failure) {
    yield call(handleFetchError, failure.payload.error);
  }

  yield put(isFetching(false));
}

function* markNotificationsRead(notificationId) {
  const { id: accountId } = yield select(currentAccountEntitySelector);
  const { notification_ids: notificationIds } = yield select(
    notificationEntitySelector,
    { notificationId }
  );
  // optimistic
  yield put(
    setNotificationGroupReadStatus(notificationId, NOTIFICATION_STATUS.READ)
  );

  // optimistic removal from results
  yield put(updateUnreadResultsForAccount({ accountId, notificationId }));

  const { failure } = yield call(
    markNotificationsReadFromCore,
    accountId,
    notificationIds
  );
  if (failure) {
    yield put(
      setNotificationGroupReadStatus(notificationId, NOTIFICATION_STATUS.UNREAD)
    );
    yield call(
      [Raven, Raven.captureMessage],
      'Failed to mark notification as read',
      {
        level: 'error',
        extra: { error: failure.payload.error },
      }
    );
    return;
  }
  yield spawn(
    trackNotificationReadStatusChange,
    notificationId,
    NOTIFICATION_STATUS.READ
  );
}

function* markNotificationsUnread(notificationId) {
  const { id: accountId } = yield select(currentAccountEntitySelector);
  const { notification_ids: notificationIds } = yield select(
    notificationEntitySelector,
    { notificationId }
  );
  // optimistic
  yield put(
    setNotificationGroupReadStatus(notificationId, NOTIFICATION_STATUS.UNREAD)
  );
  const { failure } = yield call(
    markNotificationsUnreadFromCore,
    accountId,
    notificationIds
  );
  if (failure) {
    yield put(
      setNotificationGroupReadStatus(notificationId, NOTIFICATION_STATUS.READ)
    );
    yield call(
      [Raven, Raven.captureMessage],
      'Failed to mark notification as unread',
      {
        level: 'error',
        extra: { error: failure.payload.error },
      }
    );
    return;
  }
  yield spawn(
    trackNotificationReadStatusChange,
    notificationId,
    NOTIFICATION_STATUS.UNREAD
  );
}

function* markNotificationsAllRead(mostRecentNotificationId) {
  const { id: accountId } = yield select(currentAccountEntitySelector);

  const { failure } = yield call(
    markNotificationsAllReadFromCore,
    accountId,
    mostRecentNotificationId
  );

  if (failure) {
    yield call(
      [Raven, Raven.captureMessage],
      'Failed to mark all notifications as read',
      {
        level: 'error',
        extra: { error: failure.payload.error },
      }
    );
    return;
  }

  // optimistic removal from results
  yield put(
    updateUnreadResultsForAccount({
      accountId,
      results: [],
    })
  );
  yield put(markAllNotificationGroupsAsRead(accountId));
}

export const testExports = {
  fetchNotifications,
  fetchMoreNotifications,
  getDefaultOptionsForFetch,
  handleFetchError,
  markNotificationsRead,
  markNotificationsUnread,
  markNotificationsAllRead,
  trackNotificationReadStatusChange,
};

export default [
  takeEvery(NOTIFICATIONS.FETCH.BASE, fetchNotifications),
  takeEvery(NOTIFICATIONS.FETCH_MORE.BASE, fetchMoreNotifications),
  takeEvery(NOTIFICATIONS.READ.BASE, ({ payload: { notificationId } }) =>
    markNotificationsRead(notificationId)
  ),
  takeEvery(NOTIFICATIONS.UNREAD.BASE, ({ payload: { notificationId } }) =>
    markNotificationsUnread(notificationId)
  ),
  takeEvery(NOTIFICATIONS.SET_FILTER, fetchNotifications),
  takeEvery(
    NOTIFICATIONS.READ_ALL.BASE,
    ({ payload: { mostRecentNotificationId } }) =>
      markNotificationsAllRead(mostRecentNotificationId)
  ),
];
