import { connect } from 'react-redux';
import { planLineItemForModifierAndPlanSelector } from '@frameio/core/src/planLineItems/selectors';
import {
  currentAccountWithSubscriptionAndPlanSelector,
  getSeatUnit,
  seatLimitPropertyName,
} from 'selectors/accounts';
import { closeModal } from 'components/Modal/actions';
import { updateSubscriptionLineItem } from './actions';
import {
  isUpdatingSubscription,
  subscriptionLineItemSelector,
} from './selectors';
import ManageTeamMemberModal from './ManageTeamMemberModal';

export const mapDispatchToProps = {
  closeModal,
  updateItem: updateSubscriptionLineItem,
};

export function mapStateToProps(state) {
  const { account, plan } = currentAccountWithSubscriptionAndPlanSelector(
    state
  );
  const accountId = account.id;
  const seatLimitType = seatLimitPropertyName(state);
  const seatUnit = getSeatUnit(state);
  const seatLineItem = subscriptionLineItemSelector(state, {
    accountId,
    plan,
    modifier: seatLimitType,
  });
  const planLineItem = planLineItemForModifierAndPlanSelector(state, {
    modifier: seatLimitType,
    planId: plan.id,
  });

  return {
    isSubmitting: isUpdatingSubscription(state),
    planTitle: plan.title,
    planLineItem,
    planLimit: plan[seatLimitType],
    planPeriod: plan.period,
    seatCount: seatLineItem.totalCount,
    paymentPeriod: plan.period,
    seatLimitType,
    seatUnit,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageTeamMemberModal);
