// TODO(marvin): Remove this after moving confirmEmail to AuthedRoute

import { generateActionType } from 'shared/actions/helpers';

export const CONFIRM_EMAIL = generateActionType('USER', 'CONFIRM_EMAIL');

export function confirmEmail(tokenId: string, email: string) {
  return {
    type: CONFIRM_EMAIL,
    payload: { tokenId, email },
  };
}
