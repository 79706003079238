import { green, purple, yellow } from '@frameio/components/src/theme/colors';
import {
  WORKFRONT,
  AEM,
} from 'components/AssetActions/AdobeIntegration/integrationConstants';

export const TEXT = (integration) => {
  switch (integration) {
    case WORKFRONT:
      return {
        headerTitle: (quantity) =>
          `Add ${quantity} Asset${quantity === 1 ? '' : 's'} to Task`,
        allTasks: 'All Tasks',
        assignedToYou: 'Assigned to You',
        noChange: 'No change',
        primaryButton: 'Add',
        secondaryButton: 'Cancel',
        updateTask: 'Change task status to…',
      };
    case AEM:
      return {
        headerTitle: (quantity) =>
          `Send ${quantity} Asset${quantity === 1 ? '' : 's'} to AEM Assets`,
        primaryButton: 'Upload',
        selectRepository: 'Select Repository',
      };
    default:
      return null;
  }
};

export const defaultTaskOptions = {
  taskId: '',
  taskTitle: '',
  taskStatus: '',
  updateStatus: '',
};

export const taskStatuses = ['NO_CHANGE', 'INP', 'CPL'];

export const taskStatusDisplay = ['NEW', 'INP', 'CPL'];

// The task status label keys (INP, CPL) are matching on the status codes that we
// receive from workfront and are the values they require for updating.
export const taskStatusLabels = {
  NO_CHANGE: 'No change',
  NEW: 'New',
  INP: 'In progress',
  CPL: 'Completed',
};

export const CURRENT_STATUS_LABEL_COLORS = {
  NEW: yellow,
  INP: purple,
  CPL: green,
};
