import { createSelector } from 'reselect';

import { get, intersectionBy, union } from 'lodash';
import { teamEntitiesForAccountIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { teamEntitiesSelector } from '@frameio/core/src/teams/selectors';

import { currentAccountSelector } from 'selectors/accounts';
import {
  isAccountAdminSelector,
  isTeamMemberForTeamSelector,
  isTeamManagerForTeamSelector,
} from 'selectors/roles';

// TODO(WK-79): once we migrate to v2 api endpoints, these selectors should no
// longer be checking `team.members` or `team.team_managers`. Team permissions
// should be determined using team roles (from `/teams/:id/membership`) instead.

/**
 * @param   {Object} state - Redux store state.
 * @returns {Object} - Current Team.
 */
export const currentTeamSelector = (state) => state.currentTeam;

/**
 * @returns {Object} - Current team's entity.
 */
export const currentTeamEntitySelector = createSelector(
  [teamEntitiesSelector, currentTeamSelector],
  (teamEntities, currentTeam) => teamEntities[currentTeam && currentTeam.id]
);

/**
 * @returns {Array} -  An array of team objects where the user is a team member.
 */
export const teamsByCurrentUserMembershipSelector = createSelector(
  teamEntitiesSelector,
  (state) => state,
  (teamEntities, state) => {
    const teams = Object.values(teamEntities);
    return teams.filter((team) =>
      isTeamMemberForTeamSelector(state, { teamId: team.id })
    );
  }
);

/**
 * @returns {Array} - An array of team objects where the user is a team manager.
 */
export const teamsByCurrentUserAsAdminSelector = createSelector(
  teamEntitiesSelector,
  (state) => state,
  (teamEntities, state) => {
    const teams = Object.values(teamEntities);
    return teams.filter(
      ({ id: teamId, account_id: accountId }) =>
        isTeamManagerForTeamSelector(state, { teamId }) ||
        isAccountAdminSelector(state, { accountId })
    );
  }
);

/**
 * @returns {Array} - An array of team objects where the user is
 * a team manager, for a given account id.
 */
export function teamsByCurrentUserAsAdminForAccountIdSelector(
  state,
  { accountId }
) {
  const teams = teamsByCurrentUserAsAdminSelector(state);
  return teams.filter((team) => team.account_id === accountId);
}

/**
 * @returns {Array} - An array of team objects where the user is a team manager or member.
 */
export const teamsByCurrentUserManagerOrMemberSelector = createSelector(
  [teamsByCurrentUserAsAdminSelector, teamsByCurrentUserMembershipSelector],
  (adminTeams, memberTeams) => union(adminTeams, memberTeams)
);

/**
 * Gets all teams in the current account, regardless of whether the current user
 * has access to them or not.
 */
export const teamsInCurrentAccountSelector = createSelector(
  currentAccountSelector,
  (state) => state,
  ({ id: accountId }, state) =>
    teamEntitiesForAccountIdSelector(state, { accountId })
);

/**
 * Gets the teams in the current acacount for which the current user is a
 * team member.
 */
export const teamsInCurrentAccountWhereCurrentUserIsMemberSelector = createSelector(
  teamsByCurrentUserManagerOrMemberSelector,
  teamsInCurrentAccountSelector,
  (teamsUserIsAdminOrMemberOf, teamsInCurrentAccount) =>
    intersectionBy(teamsUserIsAdminOrMemberOf, teamsInCurrentAccount, 'id')
);

/**
 * returns a list of ids for session watermark template entities that share the
 * same account id as the current team
 * @param {*} state
 */
export const teamsSessionWatermarkTemplateEntitiesSelector = createSelector(
  currentTeamEntitySelector,
  (state) => state.entities.sessionWatermarkTemplate.entities,
  (currentTeam, sessionWatermarkTemplates) =>
    Object.values(sessionWatermarkTemplates).filter(
      (template) => template.account_id === currentTeam.account_id
    )
);

// the defaultSessionWatermarkTemplateId should be sourced from the account and not from the team
export const defaultSessionWatermarkTemplateIdSelector = createSelector(
  (state) => state,
  teamsSessionWatermarkTemplateEntitiesSelector,
  (state, sessionWatermarkTemplateIds) => {
    const {
      currentAccount: { id: currentAccountId },
      entities: {
        accounts: { entities: accounts },
      },
    } = state;

    const accountDefaultWatermarkTemplateId =
      accounts[currentAccountId].account_settings.share_sbwm_template_id;

    const fallbackValue = get(sessionWatermarkTemplateIds, '[0].id') || 'none';
    return accountDefaultWatermarkTemplateId || fallbackValue;
  }
);
