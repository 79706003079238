import { connect } from 'react-redux';
import { joinRequestsByTeamEntitySelector } from '@frameio/core/src/joinRequests/selectors';
import { requestToJoinTeam } from 'actions/teams';
import { permittedActionsForAccountSelector } from 'selectors/permissions';
import { closeModal } from 'components/Modal/actions';
import RequestToJoinTeamCard from './RequestToJoinTeamCard';

function mapStateToProps(state, { project }) {
  // TODO(WK-79): remove legacy fallbacks after migrating to v2
  const { id: projectId, team, team_id } = project;
  const teamId = team_id || team;
  const { shouldRestrictCollaborators } = permittedActionsForAccountSelector(
    state,
    { projectId }
  );

  return {
    isRequestPending: !!joinRequestsByTeamEntitySelector(state, { teamId }),
    shouldRestrictCollaborators,
  };
}

const mapDispatchToProps = {
  closeFn: closeModal,
  requestFn: requestToJoinTeam,
};

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestToJoinTeamCard);
