import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import PropTypes from 'prop-types';
import {
  MenuButton,
  MenuSeparator,
  StatusIndicator,
  Tooltip,
} from '@frameio/vapor';
import { CURRENT_STATUS_LABEL_COLORS } from 'components/Dialog/AdobeIntegrationConnectDialog/helper';
import AdobeIntegrationConnectIcon from './AdobeIntegrationConnectIcon';
import WorkfrontTaskSubmenu from './WorkfrontTaskSubmenu';
import { WORKFRONT } from './integrationConstants';

const StyledMenuButton = styled(MenuButton)`
  & div[class*='IconAfter'] {
    margin-left: 0px;
    margin-top: ${(p) => p.theme.spacing.units(0.25)};
  }
`;

export const StyledStatusIndicator = styled(StatusIndicator)`
  padding: ${(p) => p.theme.radius.small};
`;

const selectionLimitMessage = 'Limit of 10 assets';

const DisabledMenuTooltip = ({
  canConnectAsset,
  children,
  isSelectionTenOrLess,
}) =>
  !isSelectionTenOrLess && canConnectAsset ? (
    <Tooltip
      shouldUsePortal
      placement="bottom"
      title={selectionLimitMessage}
      variant="light"
    >
      <Flex>{children}</Flex>
    </Tooltip>
  ) : (
    children
  );

const AdobeIntegrationMenuItem = ({
  assetIds,
  canConnectAsset,
  canUpdateWorkfrontConnectedTask,
  isTeamAdobeIntegrationEnabled,
  isWorkfrontConnected,
  menuItems,
  projectId,
  workfrontTaskInterface: { workfrontTask },
}) => {
  // the current workfront integration can only safely connect 10 assets at a time without
  // timing out, once wf switches over to the v2 integration it is expected to be increased.
  const isSelectionLimit = assetIds.length <= 10;
  const isConnectMenuItemEnabled = canConnectAsset && isSelectionLimit;
  const isMenuItemEnabled = canConnectAsset || canUpdateWorkfrontConnectedTask;

  const { integration, name, onSelect, submenu } = menuItems || {};
  const isSubmenu = !!submenu?.length;

  const handleOnClick = () => onSelect({ assetIds, projectId, integration });

  return isTeamAdobeIntegrationEnabled ? (
    <>
      <DisabledMenuTooltip
        isSelectionTenOrLess={isSelectionLimit}
        canConnectAsset={canConnectAsset}
      >
        <StyledMenuButton
          disabled={
            !isConnectMenuItemEnabled && !canUpdateWorkfrontConnectedTask
          }
          iconBefore={<AdobeIntegrationConnectIcon integration={integration} />}
          onClick={!isSubmenu && handleOnClick}
          submenu={
            isSubmenu &&
            (isConnectMenuItemEnabled || canUpdateWorkfrontConnectedTask) ? (
              <WorkfrontTaskSubmenu
                assetIds={assetIds}
                isWorkfrontConnected={isWorkfrontConnected}
                menuItems={menuItems}
                projectId={projectId}
                submenu={submenu}
                integration={integration}
                workfrontTask={workfrontTask}
              />
            ) : null
          }
        >
          <Flex justifyBetween>
            {name}
            {isMenuItemEnabled && isWorkfrontConnected && isSubmenu && (
              <StyledStatusIndicator
                isSelected
                variant="checked"
                color={CURRENT_STATUS_LABEL_COLORS[workfrontTask.status]}
              />
            )}
          </Flex>
        </StyledMenuButton>
      </DisabledMenuTooltip>
      {integration === WORKFRONT && <MenuSeparator />}
    </>
  ) : null;
};

AdobeIntegrationMenuItem.propTypes = {
  assetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  canConnectToAdobeIntegration: PropTypes.bool,
  canUpdateWorkfrontConnectedTask: PropTypes.bool,
  isTeamAdobeIntegrationEnabled: PropTypes.bool,
  menuItems: PropTypes.object,
  workfrontTaskInterface: PropTypes.object,
  projectId: PropTypes.string,
};

AdobeIntegrationMenuItem.defaultProps = {
  workfrontTaskInterface: { workfrontTask: {} },
};

export default AdobeIntegrationMenuItem;
