import { createSelector } from 'reselect';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import {
  planEntityForAccountIdSelector,
  teamEntityForProjectIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import ROLES from '@frameio/core/src/roles/helpers/constants';

import {
  currentAccountEntitySelector,
  currentAccountCardEntitiesSelector,
  isAccountEnterpriseSelector,
} from 'selectors/accounts';
import { isAccountAdminSelector } from 'selectors/roles';
import { subscriptionEntityByAccountIdSelector } from 'selectors/subscriptions';
import { plans } from 'utils/plans/plansHelpers';

import {
  MEMBER_COUNT,
  PROJECT_COUNT,
  LIFETIME_FILE_COUNT,
  STORAGE,
  USER_COUNT,
} from '@frameio/core/src/accounts/utils/usageTypes';
import {
  TOTAL_MEMBER_LIMIT,
  TOTAL_USER_LIMIT,
} from '@frameio/core/src/subscriptionLineItems/utils/modifierTypes';

export const limitTypes = {
  PROJECTS: 'projects',
  STORAGE: 'storage',
  TEAM_MEMBERS: 'team members',
  COLLABORATORS: 'collaborators',
  LIFETIME_UPLOADS: 'lifetime uploads',
  USERS: 'users',
  ARCHIVAL_STORAGE: 'archival storage',
};

export function getLimitOverages(limits) {
  return limits.reduce((acc, { type, limit, usage }) => {
    if (limit !== null && usage > limit) {
      return [...acc, { type, usage, limit }];
    }

    return acc;
  }, []);
}

export function getLimitWarnings(limits) {
  return limits.reduce((acc, { type, warning, usage, limit }) => {
    if (warning && warning !== null && usage >= warning && usage <= limit) {
      return [...acc, { type, usage, limit }];
    }

    return acc;
  }, []);
}

/*
 * Takes an account entity and munges the data to a list of {type, limit, usage}
 * for limits that have been exceeded.
 *
 * Currently the server does some of this work but it's based on the V1 API
 * whereas V2 does not. This function is here so that there's a single place
 * where we can modify the munging of data instead of doing it all over the
 * components.
 */
export function getAccountLimits(accountEntity, plan, subscription) {
  if (!accountEntity || !plan || !subscription) {
    return [];
  }
  const { title: planTitle, version } = plan;
  const isAccountOnPlanWithUserMax = version >= 5;
  const isAccountOnFreePlanWithUserMax =
    isAccountOnPlanWithUserMax && planTitle === 'Free';

  const archivalStorageLimit = isAccountOnFreePlanWithUserMax
    ? plan.archived_storage_limit
    : subscription.total_archived_storage_limit;

  const activeStorageLimit = isAccountOnFreePlanWithUserMax
    ? plan.storage_limit
    : subscription.total_storage_limit;

  const userLimit = isAccountOnPlanWithUserMax
    ? plan.user_max
    : subscription[TOTAL_USER_LIMIT];

  return [
    {
      type: limitTypes.PROJECTS,
      limit: plan.project_limit,
      usage: accountEntity[PROJECT_COUNT],
    },
    {
      type: limitTypes.STORAGE,
      warning:
        subscription.total_storage_limit * 0.9 ||
        subscription.total_storage_limit,
      limit: activeStorageLimit,
      usage: accountEntity[STORAGE],
    },
    {
      type: limitTypes.COLLABORATORS,
      limit: plan.collaborator_limit,
      usage: accountEntity.collaborator_role_count,
    },
    {
      type: limitTypes.TEAM_MEMBERS,
      limit: subscription[TOTAL_MEMBER_LIMIT],
      usage: accountEntity[MEMBER_COUNT],
    },
    {
      type: limitTypes.USERS,
      limit: userLimit,
      usage: accountEntity[USER_COUNT],
    },
    {
      type: limitTypes.LIFETIME_UPLOADS,
      warning: plan.lifetime_file_limit * 0.8 || plan.lifetime_file_limit,
      limit: plan.lifetime_file_limit,
      usage: accountEntity[LIFETIME_FILE_COUNT],
    },
    {
      type: limitTypes.ARCHIVAL_STORAGE,
      limit: archivalStorageLimit,
      usage: accountEntity.archived_storage,
    },
  ];
}

/*
 * Takes a team entity and returns a list of {type, limit, usage} for limits
 * that have been exceeded for a team and it's allocations. Keep in mind that
 * only enterprise accounts have multiple teams.
 */
function getTeamLimits(teamEntity) {
  if (!teamEntity) {
    return [];
  }

  const {
    member_count: memberCount,
    member_limit: memberLimit,
    storage: storageUsage,
    storage_limit: storageLimit,
  } = teamEntity;

  return [
    {
      type: limitTypes.TEAM_MEMBERS,
      limit: memberLimit,
      usage: memberCount,
    },
    {
      type: limitTypes.STORAGE,
      warning: storageLimit ? storageLimit * 0.9 : undefined,
      limit: storageLimit,
      usage: storageUsage,
    },
  ];
}

export const canSelfServiceSelector = createSelector(
  currentAccountEntitySelector,
  (state) => state,
  (currentAccountEntity = {}, state) => {
    const { id: accountId } = currentAccountEntity;
    const isAdminLevel = isAccountAdminSelector(state, { accountId });
    const plan = planEntityForAccountIdSelector(state, { accountId });

    return isAdminLevel && !plan.enterprise && plan.title !== plans.BUSINESS;
  }
);

/**
 * @param {string} role - A user's role.
 * @returns {boolean} - Whether or not a user's card can be auto-charged
 */
export const canAutoChargeCardSelector = createSelector(
  currentAccountEntitySelector,
  currentAccountCardEntitiesSelector,
  (state) => state,
  (state, { role }) => role,
  (currentAccount = {}, currentCards, state, role) => {
    const plan = planEntityForAccountIdSelector(state, {
      accountId: currentAccount.id,
    });
    const planTitle = plan.title;
    const isEnterprise = plan.enterprise;

    return (
      Object.keys(currentCards).length > 0 &&
      role === ROLES.OWNER &&
      !isEnterprise &&
      planTitle !== plans.BUSINESS
    );
  }
);

/**
 * @param {string} role - A user's role.
 * @returns {boolean} - Whether or not a user can see account-level limits.
 */
export const canSeeAccountLimitsSelector = createSelector(
  isAccountEnterpriseSelector,
  (state, { role }) => role,
  (isEnterprise, role) =>
    isEnterprise
      ? [ROLES.OWNER, ROLES.ADMIN].includes(role)
      : [
          ROLES.OWNER,
          ROLES.ADMIN,
          ROLES.TEAM_MANAGER,
          ROLES.TEAM_MEMBER,
        ].includes(role)
);

function relatedProjectDataSelector(state, { projectId }) {
  const team = teamEntityForProjectIdSelector(state, { projectId });
  if (!team) return {};

  // For collaborator-role projects, v2 account data is not fetched the same
  // way as for regular projects, and may therefore not yet exist when this
  // selector is called. See `enterProject` in `ProjectContainer/sagas.js` where
  // `getAccount` is called for shared projects.
  //
  // TODO(WK-79): remove this check when v2 accounts data for shared projects
  // is fetched the same way as regular projects.
  const account = accountEntitySelector(state, { accountId: team.account_id });
  if (!account) return {};

  const subscription = subscriptionEntityByAccountIdSelector(state, account.id);
  const plan = planEntityForAccountIdSelector(state, { accountId: account.id });

  return { team, account, subscription, plan };
}

export function limitOveragesSelector(state, { projectId }) {
  const { team, account, subscription, plan } = relatedProjectDataSelector(
    state,
    { projectId }
  );
  return {
    team: getLimitOverages(getTeamLimits(team)),
    account: getLimitOverages(getAccountLimits(account, plan, subscription)),
  };
}

export function limitWarningsSelector(state, { projectId }) {
  const { team, account, subscription, plan } = relatedProjectDataSelector(
    state,
    { projectId }
  );
  return {
    team: getLimitWarnings(getTeamLimits(team)),
    account: getLimitWarnings(getAccountLimits(account, plan, subscription)),
  };
}
