import React, { memo, useState } from 'react';
import * as theme from '@frameio/components/src/theme/darkTheme';
import styled from 'styled-components';
import WindowedSelect, {
  components as CustomComponents,
} from 'react-windowed-select';
import { antialiasedAvenirHeavy } from '@frameio/components/src/mixins';
import Arrow from '@frameio/components/src/svgs/icons/16/arrow.svg';
import Checkmark from '@frameio/components/src/svgs/icons/16/checkmark.svg';

const { Input, ValueContainer, DropdownIndicator, Option } = CustomComponents;

const IconContainer = styled.div`
  position: relative;
  height: ${(p) => p.theme.spacing.medium};
  color: ${(p) => p.theme.color.slateGray};
`;

const ChevronContainer = styled.div`
  position: absolute;
  top: 13px;
  right: 6px;
  color: ${(p) => p.theme.color.dimGray};
`;

const Container = styled.div`
  margin-left: 2px;
`;

const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CheckmarkIcon = styled(Checkmark)`
  width: 12px;
  height: 12px;
  color: ${(p) => p.theme.color.brand};
`;

const components = {
  DropdownIndicator: (props) => (
    <DropdownIndicator {...props}>
      <ChevronContainer>
        <Arrow />
      </ChevronContainer>
    </DropdownIndicator>
  ),
  Input: (props) => <Input {...props} isHidden={false} />,
  Option: (props) => (
    <Option {...props}>
      <OptionContainer>
        <span>{props.children}</span>
        {props.isSelected && <CheckmarkIcon />}
      </OptionContainer>
    </Option>
  ),
  ValueContainer: ({ children, ...props }) => (
    <ValueContainer {...props}>
      <IconContainer>{props.selectProps.icon()}</IconContainer>
      <Container>{children}</Container>
    </ValueContainer>
  ),
};

const getBorderStyle = ({ error, isFocused }) => {
  if (isFocused) {
    return { border: `1px solid ${theme.color.brand}` };
  }
  if (error) {
    return { border: `1px solid ${theme.color.error}` };
  }
  return { border: `1px solid ${theme.color.slateGray}` };
};

const getFontStyle = ({ colorToken }) => ({
  color: `${theme.color[colorToken]}`,
  fontSize: '14px',
});

const Select = React.forwardRef(
  (
    {
      defaultValue,
      error,
      maxInputWidth = 300,
      name,
      onBlur,
      onChange,
      options,
      placeholder,
      touched,
      icon,
    },
    ref
  ) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const onKeyDown = (evt) => {
      if (evt.keyCode === 13 && !menuIsOpen) {
        evt.preventDefault();
        setMenuIsOpen(true);
      }
    };
    const selectedOption =
      defaultValue && options.find((option) => option.value === defaultValue);

    // Setting `value` to an empty string allow us to show the placeholder instead
    // of the previously selected value in the dropdown.
    // Useful when we need to reset the state / province dropdown.
    const value = defaultValue ? selectedOption : '';

    return (
      <>
        <WindowedSelect
          components={components}
          defaultValue={selectedOption}
          icon={icon}
          maxMenuHeight={200}
          menuIsOpen={menuIsOpen}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          menuShouldBlockScroll
          name={name}
          onBlur={() => {
            onBlur();
            setMenuIsOpen(false);
          }}
          onChange={({ value: val }) => {
            onChange(val);
          }}
          onKeyDown={onKeyDown}
          onMenuClose={() => setMenuIsOpen(false)}
          onMenuOpen={() => setMenuIsOpen(true)}
          options={options}
          placeholder={placeholder}
          ref={ref}
          tabSelectsValue={false}
          value={value}
          styles={{
            control: (base, { isFocused, isSelected }) => ({
              ...base,
              ...getBorderStyle({
                error: error && touched,
                isFocused: isFocused || isSelected,
              }),
              borderRadius: `${theme.radius.large}`,
              boxShadow: 'none',
              cursor: 'pointer',
              height: '42px',

              '&:hover': {
                ...getBorderStyle({
                  error: error && touched,
                  isFocused: isFocused || isSelected,
                }),
              },
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            input: (base) => ({
              ...base,
              ...getFontStyle({ colorToken: 'dimGray' }),
              input: {
                maxWidth: `${maxInputWidth}px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }),
            menu: (base) => ({
              ...base,
              padding: `${theme.spacing.micro} ${theme.spacing.tiny}`,
              zIndex: '2',
            }),
            noOptionsMessage: (base) => ({
              ...base,
              ...getFontStyle({ colorToken: 'dimGray' }),
            }),
            option: (base, { isFocused, isSelected }) => ({
              ...base,
              backgroundColor: isFocused && `${theme.color.coldWhite}`,
              borderRadius: `${theme.radius.medium}`,
              ...getFontStyle({ colorToken: 'dimGray' }),
              fontWeight: isSelected ? '900' : 'bold',
              ...antialiasedAvenirHeavy(),
              cursor: 'pointer',

              '&:active': {
                backgroundColor: `${theme.color.coldWhite}`,
              },
            }),
            placeholder: (base) => ({
              ...base,
              ...getFontStyle({ colorToken: 'slateGray' }),
            }),
            singleValue: (base) => ({
              ...base,
              ...getFontStyle({ colorToken: 'graphiteGray' }),
              fontWeight: 'bold',
              ...antialiasedAvenirHeavy(),
              maxWidth: `${maxInputWidth}px`,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }),
            valueContainer: (base) => ({
              ...base,
              display: 'flex',
              padding: `${theme.spacing.micro}`,
            }),
          }}
        />
      </>
    );
  }
);

export default memo(Select);
