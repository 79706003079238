import { call, put, select, take, takeLatest, delay } from 'redux-saga/effects';
import { COMMENT } from '@frameio/core/src/comments/actions';
import { commentCountsByAssetSelector } from 'selectors/comments';
import { COMMENTS_TOGGLE, setNumOfComments, toggleHighlight } from './actions';

function* setCount(assetId) {
  const countsByAsset = yield select(commentCountsByAssetSelector);
  yield put(setNumOfComments(countsByAsset[assetId]));
}

function* watchComments({ payload: { assetId } }) {
  // Draw attention to `CommentsToggle` by making it glow in purple when a
  // comment comes in. Note that `CommentsToggle` prevents glowing when the
  // comments pane is open.
  while (true) {
    // Wait for comments to come through socket events
    yield take(COMMENT.RECEIVE_EVENT);

    // Choreographed background flash of `<CommentsToggle />`
    yield put(toggleHighlight(true));
    yield delay(300);
    yield call(setCount, assetId);
    yield delay(300);
    yield put(toggleHighlight(false));
  }
}

export default [takeLatest(COMMENTS_TOGGLE.WATCH_COMMENTS, watchComments)];
