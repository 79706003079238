import FileSaver from 'file-saver';

const MAX_DOWNLOAD_SIZE = 5 * 1000000000;

const DOWNLOAD_TIMEOUT_OFFSET = 1000;

const SUPPORTED_FPS_FOR_FORENSIC_LOWER_LIMIT = 10;
const SUPPORTED_FPS_FOR_FORENSIC_UPPER_LIMIT = 120;
const SUPPORTED_WIDTH_FOR_FORENSIC_LOWER_LIMIT = 256;
const SUPPORTED_WIDTH_FOR_FORENSIC_UPPER_LIMIT = 8192;
const SUPPORTED_HEIGHT_FOR_FORENSIC_LOWER_LIMIT = 144;
const SUPPORTED_HEIGHT_FOR_FORENSIC_UPPER_LIMIT = 4320;

export const resolutionToResolutionField = {
  360: 'h264_360',
  540: 'h264_540',
  720: 'h264_720',
  1080: 'h264_1080_best',
  2160: 'h264_2160',
};

/**
 * Downloads the given string as a file
 * @param {string} string - The text blob to save
 * @param {string} fileName - The name of the file to save
 * @param {string} fileType - The mime type of the file
 */
export function download(
  string,
  fileName,
  fileType = 'text/plain; charset=utf-8'
) {
  const blob = new Blob([string], { type: fileType });
  FileSaver.saveAs(blob, fileName);
}

/**
 * Download the url as a file.
 * @param {string} url - Url to download from
 * @param {string} fileName - The name of the file to save
 */
export function downloadUrlAsFile(url, fileName) {
  const link = document.createElement('a');
  link.href = url;

  if (link.download) {
    link.download = fileName || url.split('/').pop();
  }

  // We use a MouseEvent here rather than `link.click()`
  // because otherwise you see a flash from opening the new window
  if (document.createEvent) {
    const evt = document.createEvent('MouseEvents');
    evt.initEvent('click', true, true);
    link.dispatchEvent(evt);
  } else {
    window.open(url, '_blank');
  }
}

/**
 * Will Serially Download individual files with included filename.
 * @param {object[]} downloads - Array of objects to represent each download.
 */
export const downloadSerially = (downloads = []) =>
  // Each download object should contian a url and a name.
  downloads.forEach((dl, index) => {
    if (!dl || !dl.url || !dl.name) return;
    setTimeout(() => {
      downloadUrlAsFile(dl.url, dl.name);
    }, index * DOWNLOAD_TIMEOUT_OFFSET);
  });

/**
 * Download a set of urls as individual files.
 * @param {string[]} urls - Array of urls to download from.
 */
export function downloadUrlsAsFiles(urls) {
  const fragment = document.createDocumentFragment();
  const iframes = [];
  urls.forEach((url) => {
    const iframe = document.createElement('iframe');
    iframe.src = url;
    iframe.style.display = 'none';
    iframes.push(iframe);
    fragment.appendChild(iframe);
  });
  document.body.appendChild(fragment);
  // Overly simplistic garbage collection
  window.setTimeout(() => {
    iframes.forEach((iframe) => iframe.remove());
  }, 60 * 1000);
}

/**
 *
 * @param {Number} size - Size in bytes.
 * @returns {Boolean} - The boolean result.
 */
export function exceedsMaxDownloadSize(size) {
  return size > MAX_DOWNLOAD_SIZE;
}

/**
 *
 * @param {Object} asset - Asset to check.
 * @param {Boolean} isForensicFallbackEnabled - Account setting.
 * @param {Number} resolution - Asset download resolution (height.)
 * @returns {Boolean} - The boolean result.
 * Download will be disabled for non-audio assets that are unsupported for
 * forensic watermark with fallback off. These will error in stream download.
 */
export function isDownloadDisabledCheck(
  asset = {},
  isForensicFallbackEnabled,
  resolution
) {
  const { transcodes = {}, is_forensically_watermarked, filetype, fps } = asset;
  if (
    !is_forensically_watermarked ||
    isForensicFallbackEnabled ||
    (filetype && filetype.includes('audio'))
  )
    return false;
  const { original_width: width, original_height: height } = transcodes;
  const aspectRatio = width / height;
  const resolutionWidth = resolution * aspectRatio;
  const supportedWidth =
    resolutionWidth >= SUPPORTED_WIDTH_FOR_FORENSIC_LOWER_LIMIT &&
    resolutionWidth <= SUPPORTED_WIDTH_FOR_FORENSIC_UPPER_LIMIT;
  const supportedHeight =
    resolution >= SUPPORTED_HEIGHT_FOR_FORENSIC_LOWER_LIMIT &&
    resolution <= SUPPORTED_HEIGHT_FOR_FORENSIC_UPPER_LIMIT;
  const supportedFps =
    Math.ceil(fps) >= SUPPORTED_FPS_FOR_FORENSIC_LOWER_LIMIT &&
    Math.ceil(fps) <= SUPPORTED_FPS_FOR_FORENSIC_UPPER_LIMIT;

  return !supportedWidth || !supportedHeight || !supportedFps;
}

/**
 *
 * @param {Object} asset - Asset downloads to check.
 * @returns {Number} - The resolution result (height).
 */
export function highestDownloadResolution(asset = {}) {
  const { downloads: targetDownloads = {} } = asset;
  const validDownloads = Object.keys(targetDownloads).filter(
    (targetDownload) => targetDownloads[targetDownload]
  );
  // Ignore `image*` transcodes
  const videoDownloads = validDownloads.filter(
    (validDownload) => validDownload.indexOf('image') !== 0
  );

  return videoDownloads.reduce((accumulator, currentValue) => {
    const parsedCurrentValue = parseInt(currentValue.split('_')[1], 10);
    if (accumulator >= parsedCurrentValue) {
      return accumulator;
    }
    return parsedCurrentValue;
  }, 0);
}
