import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  assetEntitySelector,
  coverAssetEntitySelector,
  parentAssetEntitySelector,
} from '@frameio/core/src/assets/selectors';
import { creatorEntityForAssetIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { formatShortName } from 'formatters/name';

import FileCard from './FileCard';

function mapStateToProps(state, { droppedAssetIds, assetId }) {
  const assetIdToVersion = get(droppedAssetIds, '0');
  const creator = creatorEntityForAssetIdSelector(state, { assetId });
  const assetToVersionCreator = creatorEntityForAssetIdSelector(state, {
    assetId: assetIdToVersion,
  });

  const assetToVersionCreatorName = formatShortName(assetToVersionCreator);
  const creatorName = formatShortName(creator);

  return {
    asset: assetEntitySelector(state, { assetId }),
    assetToVersion: assetEntitySelector(state, { assetId: assetIdToVersion }),
    assetToVersionCreatorName,
    coverAsset: coverAssetEntitySelector(state, { assetId }),
    creatorName,
    parentAssetType: (parentAssetEntitySelector(state, { assetId }) || {}).type,
  };
}

export default connect(mapStateToProps)(FileCard);

export const testExports = {
  mapStateToProps,
};
