import { call, spawn, select, take, takeLatest } from 'redux-saga/effects';
import { AUTHED_USER } from '@frameio/core/src/users/actions';
import { currentUserEntitySelector } from 'selectors/users';
import {
  trackIdentify,
  maybeClearExistingIdentityTraits,
  identifyPropertiesSelector,
  isPresentationOrReviewLinkPage,
} from 'analytics';
import { getAuthToken } from 'utils/auth';
import { pathnameSelector } from 'selectors/router';
import { loadDrift } from 'utils/drift';
import { getDriftToken } from '@frameio/core/src/tokens/sagas';
import { ANALYTICS } from './actions';

function* identifyUser() {
  let user = yield select(currentUserEntitySelector);
  if (!user) {
    yield take(AUTHED_USER.GET.SUCCESS);
    user = yield select(currentUserEntitySelector);
  }

  const { id: userId, user_hash: intercomUserHash } = user;
  const identifyProperties = yield select(identifyPropertiesSelector);
  const fullIdentifyProperties = {
    ...identifyProperties,
    identify_action: 'frameio-access',
  };
  const authToken = yield call(getAuthToken);

  const pathname = yield select(pathnameSelector);
  const isPresentationOrReviewLink = yield call(
    isPresentationOrReviewLinkPage,
    pathname
  );
  const integrations = {
    All: true,
    Intercom: !isPresentationOrReviewLink && { user_hash: intercomUserHash },
    Drift: !isPresentationOrReviewLink && {
      // SBC-150 - https://segment.com/docs/connections/destinations/catalog/drift/#identify
      userJwt: authToken,
    },
  };

  const { success } = yield call(getDriftToken);
  // only load drift upon successfully getting the token since it is required
  if (success) {
    const driftToken = success.payload.response.token;
    yield call(loadDrift, { identifyProperties, user, driftToken });
  }

  // Clear any existing identity traits that are spurious
  yield call(maybeClearExistingIdentityTraits);

  yield spawn(trackIdentify, userId, fullIdentifyProperties, {
    integrations,
  });
}

export const testExports = {
  identifyUser,
};

export default [takeLatest(ANALYTICS.IDENTIFY_USER, identifyUser)];
