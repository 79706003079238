import React from 'react';
import PropTypes from 'prop-types';

// As of October 2019, this component works for AdBlock, AdBlock Plus, and AdLock.
class AdBlockDetector extends React.Component {
  // eslint-disable-next-line react/sort-comp
  adBlockDetectorRef = React.createRef();

  componentDidMount() {
    const adBlockDetectorHeight = this.adBlockDetectorRef.current.offsetHeight;

    if (adBlockDetectorHeight === 0) {
      this.props.showAdBlockDisclaimer();
    }
  }

  render() {
    return (
      <div
        className="ads ad adsbox"
        ref={this.adBlockDetectorRef}
        style={{ position: 'absolute', height: '1px' }}
      />
    );
  }
}

AdBlockDetector.propTypes = {
  showAdBlockDisclaimer: PropTypes.func.isRequired,
};

export default AdBlockDetector;
