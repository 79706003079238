import { CLIENT } from '@frameio/core/src/client/actions';
import { incompleteUploadsSelector } from '@frameio/core/src/uploads/selectors';
import { LOCATION_CHANGE } from 'connected-react-router';
import { isEmpty } from 'lodash';
import { call, select, take, takeLatest } from 'redux-saga/effects';
import { pathnameSelector } from 'selectors/router';
import { forcedVersioningEnabled } from 'utils/featureFlags';
import { reloadCurrentRoute } from 'utils/router';

/**
 * Saga to handle client version updates on location change.
 *
 * We want the client update to be as un-interruptive as possible, so we
 * wait for the user to navigate away from the current page before
 * reloading the client. Additionally, we only want to reload the client
 * if the user is not currently uploading any files.
 *
 * @param {string} prevPathName - The previous pathname
 * @returns {void}
 */
function* updateOnLocationChange(prevPathName) {
  // Wait for a location change before continuing
  yield take(LOCATION_CHANGE);

  const isUploading = !isEmpty(yield select(incompleteUploadsSelector));
  const pathname = yield select(pathnameSelector);

  if (isUploading || prevPathName === pathname) {
    yield call(updateOnLocationChange, pathname);
    return;
  }

  reloadCurrentRoute(true);
}

/**
 * Saga to handle client version updates coming from the socket.
 *
 * @param {Object} payload - The payload of the CLIENT.RECEIVE_VERSION action
 * @param {string} payload.version - The latest client version
 * @returns {void}
 */
function* receiveClientVersion({ payload }) {
  const isForcedVersioningEnabled = yield select(forcedVersioningEnabled);
  if (!isForcedVersioningEnabled) return;

  const currentClientVersion = process.env.RELEASE_VERSION;
  const latestClientVersion = payload?.version;

  if (currentClientVersion === latestClientVersion) return;

  const pathname = yield select(pathnameSelector);
  yield call(updateOnLocationChange, pathname);
}

export default [takeLatest(CLIENT.RECEIVE_VERSION, receiveClientVersion)];

export { receiveClientVersion, updateOnLocationChange };
