import React from 'react';
import styled from 'styled-components';
import Button from '@frameio/components/src/styled-components/Button';

const OptionButton = ({ updateValue, isSelected, text, value }) => {
  const handleClick = (e) => {
    updateValue(value);
    e.preventDefault();
  };
  return (
    <StyledButton $isSelected={isSelected} text onClick={handleClick}>
      {text}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  border: 1px solid
    ${({ $isSelected, theme }) =>
      $isSelected ? theme.color.brand : theme.color.slateGray};
  color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.color.brand : theme.color.gray};
  width: 100%;
  justify-content: start;
`;

export default OptionButton;
