import React from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import CarotThin from '@frameio/components/src/svgs/icons/carot-thin.svg';
import { noop } from 'lodash';
import Button from '@frameio/components/src/styled-components/Button';
import { formatLongDate } from '@frameio/components/src/utils/datetimeHelpers';
import ModalHeader from 'components/Modal/FullScreenModal/Header';
import { formatMoney } from 'formatters/money';
import { LARGE } from 'utils/mediaQueries';
import { limitTypes } from 'selectors/limits';
import { TERMS_URL, PRIVACY_URL } from 'URLs';
import { BILL_PERIOD } from '../PlanDisplay';

const BackButton = styled(Button)`
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: space-between;
`;

const BackArrow = styled(CarotThin)`
  color: ${({ theme }) => theme.color.gray};
  width: ${(p) => p.theme.spacing.small};
  padding-right: ${(p) => p.theme.spacing.micro};
  padding-bottom: 1px;
  vertical-align: bottom;
`;

const Container = styled.div`
  font-size: ${(p) => p.theme.fontSize[2]};
  color: ${(p) => p.theme.color.darkGray};
  min-height: 550px;
  ${BackButton} {
    // Here we absolutely position the back button to avoid pushing down the modal title
    position: absolute;
    top: ${(p) => p.theme.spacing.medium};
    left: ${(p) => p.theme.spacing.medium};
  }
`;

const ConfirmButton = styled(Button)`
  align-self: flex-end;
`;

const PlanSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  min-height: 200px;
  padding: 0 50px ${(p) => p.theme.spacing.tiny};

  @media ${LARGE} {
    width: 500px;
  }
`;

const PlanBreakdown = styled.div`
  padding: ${(p) => p.theme.spacing.medium} 0;
  border-bottom: 1px solid ${(p) => p.theme.color.coldWhite};
`;

const PlanTitle = styled.div`
  font-weight: bold;
  color: ${(p) => p.theme.color.coolBlack};
  margin-bottom: ${(p) => p.theme.spacing.small};
`;

const BreakdownLine = styled.div`
  margin-top: ${(p) => p.theme.spacing.small};
  display: flex;
  justify-content: space-between;
  color: ${(p) => p.theme.color.gray};
`;

const Amount = styled.span`
  font-weight: bold;
  color: ${(p) => p.theme.color.coolBlack};
`;

const BottomLine = styled.div`
  color: ${(p) => p.theme.color.gray};
  font-size: ${(p) => p.theme.fontSize[2]};
  margin-top: ${(p) => p.theme.spacing.medium};
  line-height: ${(p) => p.theme.lineHeight[2]};
`;

const DowngradeDisclaimer = styled.p`
  ${(p) => p.theme.fontStyle.bodyS};
  color: ${(p) => p.theme.color.error};
  margin-top: ${(p) => p.theme.spacing.tiny};
`;

const ConfirmPurchaseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(p) => p.theme.spacing.medium};
`;

const DisclaimerCopy = styled.p`
  color: ${(p) => p.theme.color.gray};
  font-size: ${(p) => p.theme.fontSize[1]};
  line-height: ${(p) => p.theme.lineHeight[2]};
  width: calc(100% - 223px);

  a {
    color: ${(p) => p.theme.color.gray};
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      color: ${(p) => p.theme.color.coolBlack};
    }
  }

  em {
    color: ${(p) => p.theme.color.coolBlack};
    font-style: normal;
  }

  @media ${LARGE} {
    bottom: 96px;
  }
`;

const CustomerSupportButton = styled.button`
  background: none;
  border: none;
  color: ${(p) => p.theme.color.gray};
  cursor: pointer;
  font-size: ${(p) => p.theme.fontSize[1]};
  line-height: ${(p) => p.theme.lineHeight[2]};
  text-decoration: underline;
`;

const DueDateLabel = styled.strong`
  width: 50%;
`;

const Total = styled.strong`
  flex-shrink: 0;
`;

function Totals({
  children,
  isOnTrial = false,
  nextBillingDate,
  periodCostLabel,
  total,
}) {
  const formattedCurrentDate = formatLongDate(Date.now());
  const formattedNextBillingDate = formatLongDate(nextBillingDate);
  const isCurrentDateBillingDate =
    formattedCurrentDate === formattedNextBillingDate;
  const dateDisplay = isCurrentDateBillingDate
    ? ' now'
    : ` on ${formattedNextBillingDate}`;
  const atEndOfTrial =
    isOnTrial && !isCurrentDateBillingDate ? ' at the end of your trial' : '';
  return (
    <PlanBreakdown>
      <BreakdownLine>
        <span>Subtotal</span>
        <span>{`${total}${periodCostLabel}`}</span>
      </BreakdownLine>
      <BreakdownLine>
        <span>Tax</span>
        <span>Local tax rates apply</span>
      </BreakdownLine>
      <BreakdownLine>
        <DueDateLabel>{`Due${atEndOfTrial}${dateDisplay}`}</DueDateLabel>
        <Total>{`${total}${periodCostLabel}`}</Total>
      </BreakdownLine>
      {children}
    </PlanBreakdown>
  );
}

function handleCustomerSupportClick() {
  if (window.Intercom) {
    window.Intercom('show');
  }
}

const PlanSummary = ({
  accountId,
  accountLimitOverages,
  continueFlow,
  isAccountOnPlanWithUserMax,
  isLegacy,
  isOnTrial,
  isSubmitting,
  memberInfo,
  movingToCheaperPlan,
  nextBillingDate,
  onCtaClick = noop,
  seatUnit,
  selectedPlan,
  storageInfo,
  archivalStorageInfo,
  restartFlow,
  shouldDisplayCurrentPlan,
}) => {
  // only used for v5 and v6 plans
  const shouldShowDowngradeDisclaimer =
    isAccountOnPlanWithUserMax &&
    movingToCheaperPlan &&
    accountLimitOverages.length > 0;

  const isYearly = selectedPlan.period === BILL_PERIOD.YEARLY;
  const periodCostLabel = isYearly ? '/year (+ tax)' : '/month (+ tax)';

  const confirmButtonCopy = 'Confirm purchase';

  function getAccountLimitOveragesCopy() {
    const limitOveragesTypes = accountLimitOverages.map((limitOverage) => {
      return limitOverage.type;
    });

    if (
      limitOveragesTypes.includes(limitTypes.USERS) &&
      limitOveragesTypes.includes(limitTypes.STORAGE) &&
      limitOveragesTypes.includes(limitTypes.ARCHIVAL_STORAGE)
    ) {
      return 'user, active and archival storage';
    }

    if (
      limitOveragesTypes.includes(limitTypes.USERS) &&
      limitOveragesTypes.includes(limitTypes.STORAGE)
    ) {
      return 'user and active storage';
    }

    if (
      limitOveragesTypes.includes(limitTypes.USERS) &&
      limitOveragesTypes.includes(limitTypes.ARCHIVAL_STORAGE)
    ) {
      return 'user and archival storage';
    }

    if (
      limitOveragesTypes.includes(limitTypes.STORAGE) &&
      limitOveragesTypes.includes(limitTypes.ARCHIVAL_STORAGE)
    ) {
      return 'active and archival storage';
    }

    if (limitOveragesTypes.includes(limitTypes.USERS)) {
      return 'user';
    }

    if (limitOveragesTypes.includes(limitTypes.STORAGE)) {
      return 'active storage';
    }

    if (limitOveragesTypes.includes(limitTypes.ARCHIVAL_STORAGE)) {
      return 'archival storage';
    }
    return '';
  }

  // legacyPlans only offer add-ons for archival storage.
  // Team Members, Collaborators and active storage are all packaged into the plan.
  function getLegacyPlanTotalCost() {
    const { cost: planCost, period } = selectedPlan;
    const { cost: archivalStorageCost } = archivalStorageInfo;

    const totalCost = archivalStorageCost
      ? planCost + archivalStorageCost
      : planCost;

    if (period === BILL_PERIOD.YEARLY) {
      return totalCost * 12;
    }

    return formatMoney(totalCost);
  }

  function getBottomLine() {
    if (movingToCheaperPlan) {
      return (
        <span>
          Note: A prorated credit for time remaining on your existing
          subscription will be applied to the amount due today. Any remaining
          credits will be applied to future invoices.
        </span>
      );
    }

    return (
      <span>
        Note: A prorated credit for time remaining on your existing subscription
        will be applied to the amount due today.
      </span>
    );
  }

  function renderConfirmPurchase() {
    return (
      <ConfirmPurchaseContainer>
        <DisclaimerCopy>
          By clicking “{confirmButtonCopy}”,{' '}
          <em>
            you agree you will be charged the amount listed above and your
            subscription will automatically renew {selectedPlan.period} until
            you cancel (price subject to change).
          </em>{' '}
          Cancel anytime via Account Settings or{' '}
          <CustomerSupportButton onClick={handleCustomerSupportClick}>
            Customer Support
          </CustomerSupportButton>
          . You also agree to the Frame.io{' '}
          <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
            Terms of Service
          </a>{' '}
          and{' '}
          <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
          .
        </DisclaimerCopy>
        <ConfirmButton
          disabled={isSubmitting}
          primary
          onClick={() => {
            onCtaClick();
            continueFlow(selectedPlan);
          }}
        >
          {confirmButtonCopy}
        </ConfirmButton>
      </ConfirmPurchaseContainer>
    );
  }

  function renderLegacyPlanSummary() {
    const { members, collaborators, period, title } = selectedPlan;
    return (
      <>
        <ModalHeader>Summary</ModalHeader>
        <PlanSummaryContainer>
          <PlanBreakdown>
            <BreakdownLine>
              <PlanTitle>{`${title} plan`}</PlanTitle>
              <PlanTitle>{`Billed ${period}`}</PlanTitle>
            </BreakdownLine>
            <BreakdownLine>
              <span>
                {members} {members === 1 ? 'team member' : 'team members'}
              </span>
              <Amount>{`${formatMoney(0)}${periodCostLabel}`}</Amount>
            </BreakdownLine>
            <BreakdownLine>
              <span>
                {collaborators === Infinity ? 'Unlimited' : collaborators}{' '}
                collaborators
              </span>
              <Amount>{`${formatMoney(0)}${periodCostLabel}`}</Amount>
            </BreakdownLine>
          </PlanBreakdown>
          <Totals
            isOnTrial={isOnTrial}
            nextBillingDate={nextBillingDate}
            periodCostLabel={periodCostLabel}
            total={getLegacyPlanTotalCost()}
          >
            <BottomLine data-test-id="bottom-line">
              {getBottomLine()}
            </BottomLine>
          </Totals>
        </PlanSummaryContainer>
        {renderConfirmPurchase()}
      </>
    );
  }

  function renderPlanSummary() {
    const totalCost = formatMoney(
      [memberInfo, storageInfo, archivalStorageInfo].reduce(
        (sum, { cost = 0 } = {}) => sum + cost,
        0
      )
    );

    const memberCost = formatMoney(memberInfo.cost || 0);

    return (
      <div>
        <ModalHeader>Summary</ModalHeader>
        <PlanSummaryContainer>
          <PlanBreakdown>
            <BreakdownLine>
              <PlanTitle>{`${selectedPlan.title} plan`}</PlanTitle>
              <PlanTitle>{`Billed ${selectedPlan.period}`}</PlanTitle>
            </BreakdownLine>
            <BreakdownLine>
              <span>
                {memberInfo.quantity}{' '}
                {memberInfo.quantity === 1 ? seatUnit : `${seatUnit}s`}
              </span>
              <span>{`${memberCost}${periodCostLabel}`}</span>
            </BreakdownLine>
          </PlanBreakdown>
          <Totals
            isOnTrial={isOnTrial}
            nextBillingDate={nextBillingDate}
            periodCostLabel={periodCostLabel}
            total={totalCost}
          >
            {shouldShowDowngradeDisclaimer && (
              <DowngradeDisclaimer data-test-id="downgrade-disclaimer">
                Downgrading will put you over your{' '}
                {getAccountLimitOveragesCopy()} limits. Adjust your usage within
                6 days or your account will be locked.
              </DowngradeDisclaimer>
            )}
            <BottomLine data-test-id="bottom-line">
              {getBottomLine()}
            </BottomLine>
          </Totals>
        </PlanSummaryContainer>
        {renderConfirmPurchase()}
      </div>
    );
  }
  return (
    <Container>
      {!shouldDisplayCurrentPlan && (
        <BackButton text onClick={() => restartFlow(accountId)}>
          <BackArrow />
          Back to all plans
        </BackButton>
      )}
      {isLegacy ? renderLegacyPlanSummary() : renderPlanSummary()}
    </Container>
  );
};

PlanSummary.defaultProps = {
  accountId: undefined,
  existingStorageLineItem: {},
  archivalStorageInfo: {},
  isLegacy: false,
  isOnTrial: false,
  isSubmitting: false,
  memberInfo: {},
  movingToCheaperPlan: false,
  storageInfo: {},
};

PlanSummary.propTypes = {
  accountId: Proptypes.string,
  continueFlow: Proptypes.func.isRequired,
  isLegacy: Proptypes.bool,
  isOnTrial: Proptypes.bool,
  isSubmitting: Proptypes.bool,
  memberInfo: Proptypes.object,
  movingToCheaperPlan: Proptypes.bool,
  nextBillingDate: Proptypes.string.isRequired,
  seatUnit: Proptypes.string.isRequired,
  selectedPlan: Proptypes.object.isRequired,
  storageInfo: Proptypes.object,
  archivalStorageInfo: Proptypes.object,
  restartFlow: Proptypes.func.isRequired,
};

export const testExports = { BackButton };
export default PlanSummary;
