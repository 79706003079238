import Cookies from 'js-cookie';

/**
 * Prevent forgery attacks using a cryptographically-strong random number as a
 * CSRF token. See:
 * https://developers.google.com/identity/protocols/OpenIDConnect#createxsrftoken
 */
const KEY = 'csrf-token';
const TOKEN_EXPIRY = 5 * 60 * 1000; // 5m
let generatedAt;

function generateCSRFToken() {
  const values = window.crypto.getRandomValues(new Uint32Array(1));
  generatedAt = new Date();
  return `${values[0]}`;
}

function storeCSRFToken(token) {
  try {
    window.localStorage.setItem(KEY, token);
  } catch (e) {
    Cookies.set(KEY, token);
  }
}

function getStoredCSRFToken() {
  try {
    return window.localStorage.getItem(KEY);
  } catch (e) {
    return Cookies.get(KEY);
  }
}

/**
 * Return the same randomly-generated token for the next 5 mins, which should be
 * more than enough time to send a request with this token and validate the
 * response.
 * @returns {string} - A string token.
 */
export default function getCSRFToken() {
  let token = getStoredCSRFToken();
  const hasExpired = !!generatedAt && new Date() - generatedAt > TOKEN_EXPIRY;
  if (!token || hasExpired) {
    token = generateCSRFToken();
    storeCSRFToken(token);
  }
  return token;
}

export const testExports = { KEY };
