import { connect } from 'react-redux';
import { pickBy } from 'lodash';
import {
  STORAGE_LIMIT,
  ARCHIVED_STORAGE_LIMIT,
} from '@frameio/core/src/subscriptionLineItems/utils/modifierTypes';
import {
  currentAccountWithSubscriptionAndPlanSelector,
  getSeatUnit,
  seatLimitPropertyName,
  isAccountOnLegacyPlanSelector,
  isAccountOnV5Plan,
  isAccountOnPlanWithUserMaxSelector,
} from 'selectors/accounts';
import { getLimitOverages, getAccountLimits } from 'selectors/limits';
import moment from 'moment-timezone';
import { BILL_PERIOD } from '../PlanDisplay';
import {
  isFetchingPlanSelector,
  selectedPlanSelector,
  updatedLineItemInfoByModifierSelector,
  archivalStorageAddOnForLegacyPlanSelector,
} from '../selectors';
import { isMovingToACheaperOrEqualPlan } from '../utils';
import { continueFlow, startFlow } from '../actions';
import PlanSummary from './PlanSummary';

// reusing startFlow rather than option where user can go infinitely back-and-forth w/in same flow
const mapDispatchToProps = {
  continueFlow,
  restartFlow: startFlow,
};

/*
 * shouldDisplayCurrentPlan: we will set this to true when we want to display the current plan
 * in the plan summary before the user enters their credit card info. When credit card into is
 * entered, it automatically sets them up to be billed at the end of the trial, so we need to
 * make sure they are aware of that.
 */
function mapStateToProps(state, { shouldDisplayCurrentPlan }) {
  const {
    account,
    subscription,
    plan,
  } = currentAccountWithSubscriptionAndPlanSelector(state);
  const accountId = account.id;
  const selectedPlan = shouldDisplayCurrentPlan
    ? plan
    : selectedPlanSelector(state);
  const seatLimitType = seatLimitPropertyName(state);
  const isV5Plan = isAccountOnV5Plan(state);
  const isAccountOnPlanWithUserMax = isAccountOnPlanWithUserMaxSelector(state);
  const isSelectedPlanFreeWithUserMax =
    isAccountOnPlanWithUserMax && selectedPlan.title === 'Free';
  const modifiers = isV5Plan
    ? [seatLimitType]
    : [STORAGE_LIMIT, seatLimitType, ARCHIVED_STORAGE_LIMIT];

  const lineItemInfo = pickBy(
    updatedLineItemInfoByModifierSelector(state, {
      modifiers,
      plan: selectedPlan,
      account,
    }),
    ({ quantity }) => quantity > 0
  );

  const switchingMonthlyToAnnually =
    plan.period === BILL_PERIOD.MONTHLY &&
    selectedPlan.period === BILL_PERIOD.YEARLY;

  const movingToCheaperPlan =
    shouldDisplayCurrentPlan ||
    isMovingToACheaperOrEqualPlan(selectedPlan, plan);

  // if you come from the free plan
  // OR the billing date is in the past (canceled subscription)
  // OR are on a trial (which will end immediately if we switch)
  // OR are switching to annual plan - default to now
  const showNextBillAtDate =
    plan.tier !== 'free' &&
    subscription.next_bill_at &&
    moment(subscription.next_bill_at).isAfter(moment()) &&
    !switchingMonthlyToAnnually &&
    !subscription.on_trial;

  const nextBillingDate =
    showNextBillAtDate || shouldDisplayCurrentPlan
      ? subscription.next_bill_at
      : new Date().toISOString();
  const seatUnit = getSeatUnit(state);
  const isLegacy = isAccountOnLegacyPlanSelector(state, { accountId });

  const accountLimitOverages = getLimitOverages(
    getAccountLimits(account, selectedPlan, {
      ...subscription,
      // In order to get the right limits for v5 and v6 free plans, we need to use the
      // `plan.storage_limit` instead of `subscription.total_storage_limit` since an Account can't
      // have add-ons on a v5 or v6 free plan.
      total_storage_limit: isSelectedPlanFreeWithUserMax
        ? selectedPlan.storage_limit
        : subscription.total_storage_limit,
    })
  );

  return {
    accountId,
    accountLimitOverages,
    seatUnit,
    selectedPlan,
    isSubmitting: isFetchingPlanSelector(state),
    nextBillingDate,
    storageInfo: lineItemInfo[STORAGE_LIMIT],
    memberInfo: lineItemInfo[seatLimitType],
    archivalStorageInfo: isLegacy
      ? archivalStorageAddOnForLegacyPlanSelector(state)
      : lineItemInfo[ARCHIVED_STORAGE_LIMIT],
    movingToCheaperPlan,
    isLegacy,
    isAccountOnPlanWithUserMax,
    isOnTrial: subscription.on_trial,
    shouldDisplayCurrentPlan,
  };
}

export const testExports = {
  mapDispatchToProps,
  mapStateToProps,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanSummary);
