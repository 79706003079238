import { call, select, takeEvery } from 'redux-saga/effects';
import { getProjectsForTeam as getProjectsForTeamCoreSaga } from '@frameio/core/src/projects/sagas';
import fetchAllPages from '@frameio/core/src/shared/sagas/fetchAllPages';
import { projectByTeamFetchStatusSelector } from '@frameio/core/src/projects/selectors';
import { status } from '@frameio/core/src/shared/reducers/factories';
import { apiV3Enabled } from 'utils/featureFlags';
import LIST_TEAM_GROUP from './actions';
import { SHARED_PROJECT_ID } from '../constants';

export function* getProjectsForTeam({ payload: { teamId } }) {
  const projectsFetchStatus = yield select(projectByTeamFetchStatusSelector);
  const currentStatus = projectsFetchStatus[teamId];
  const isApiV3Enabled = yield select(apiV3Enabled);

  // for now we are still bulk loading shared projects in
  // src/sagas/accounts/index.js -> getProjectsForAccount
  if (teamId === SHARED_PROJECT_ID) {
    return;
  }

  if (currentStatus === status.SUCCESS || currentStatus === status.PENDING) {
    return;
  }

  try {
    yield call(
      fetchAllPages,
      (params) =>
        getProjectsForTeamCoreSaga(teamId, {
          isApiV3Enabled,
          filters: { archived: 'all' },
          hardDropFields: ['a.cover_asset'],
          ...params,
        }),
      isApiV3Enabled ? 10000 : 50
    );
  } catch (err) {
    // Errors should be handled on the individual team
    // entity level. I.e., We should check the status of
    // the entity when rendering, and if it's an error we
    // can enable the user to fetch again.
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export default [takeEvery(LIST_TEAM_GROUP.FETCH_PROJECTS, getProjectsForTeam)];

export const testExports = {
  getProjectsForTeam,
};
