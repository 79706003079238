import React from 'react';
import { get } from 'lodash';
import track, { trackButtonClick } from 'analytics';
import {
  takeLatest,
  takeEvery,
  put,
  select,
  call,
  spawn,
} from 'redux-saga/effects';
import {
  accountEntityForProjectIdSelector,
  teamEntityForProjectIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import { getAssetSize } from '@frameio/core/src/assets/sagas';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import { PROJECT as CORE_PROJECT } from '@frameio/core/src/projects/actions';
import CORE_COLLABORATOR, {
  CollaboratorEvents,
} from '@frameio/core/src/collaborators/actions';
import {
  deleteProject,
  getProject,
  removeCollaboratorFromProject,
  joinProjectAsCollaborator,
  getProjectUserPreferences as coreSagaGetProjectUserPreferences,
  getProjectTree,
} from '@frameio/core/src/projects/sagas';
import { deleteLastViewedProjectForAccount } from '@frameio/core/src/accounts/services';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import { teamEntitySelector } from '@frameio/core/src/teams/selectors';
import {
  joinProjectRoom,
  leaveProjectRoom,
} from '@frameio/core/src/sockets/actions';
import {
  begin as beginAction,
  failure as failureAction,
  success as successAction,
} from '@frameio/core/src/shared/sagas/helpers';

import {
  fetchTree,
  PROJECT,
  setCurrentProject as setCurrentProjectAction,
} from 'actions/projects';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import { setCurrentAccount } from 'actions/accounts';
import { setCurrentTeam } from 'actions/teams';
import {
  confirmDelete,
  confirm,
  alert,
  error,
  priority,
} from 'components/Dialog/SimpleDialog/sagas';
import { redirectToFirstTeamOrSharedProject } from 'pages/AccountContainer/sagas';
import {
  defaultProjectIdSelector,
  getNextProjectIdInTeamSelector,
} from 'pages/RootContainer/selectors';
import { currentAccountSelector } from 'selectors/accounts';
import {
  teamsByCurrentUserMembershipSelector,
  currentTeamSelector,
} from 'selectors/teams';
import { currentUserSelector } from 'selectors/users';
import { permittedActionsForProjectSelector } from 'selectors/permissions';
import {
  currentProjectSelector,
  userPreferencesForProjectIdSelector,
} from 'selectors/projects';
import { isCollaboratorOnlySelector } from 'selectors/roles';
import { bytesToMB } from 'shared/filesizeHelpers';
import { folderSharingEnabled } from 'utils/featureFlags';
import { redirectTo } from 'utils/router';
import { getProjectUrl, ROOT_URL } from 'URLs';
import { startArchiveProject } from 'components/ProjectActions/ArchiveProject/actions';
import { getRoleForProject } from 'sagas/roles';

export function* redirectToRoot(queryParams) {
  yield put(setCurrentProjectAction());
  yield call(redirectTo, ROOT_URL, queryParams);
}

/**
 * Redirects to an (optionally) provided project id.
 * Otherwise, selects a default project from the user's current projects.
 * If no default project is found, redirect to the root url.
 */
export function* redirectToProject(projectId, queryParams) {
  const defaultProjectId = yield select(defaultProjectIdSelector);

  if (projectId) {
    yield call(redirectTo, getProjectUrl(projectId), queryParams);
  } else if (defaultProjectId) {
    yield call(redirectTo, getProjectUrl(defaultProjectId), queryParams);
  } else {
    yield call(redirectToRoot, queryParams);
  }
}

/**
 * Fetch the folder tree for a given project id
 */
function* fetchProjectFolderTree({ payload: { projectId } }) {
  // TODO(Anna): Move this default value to web-core
  const DEFAULT_TREE_DEPTH = 20;
  yield beginAction(PROJECT.FETCH_TREE.PENDING, projectId);
  const isFolderSharingEnabled = yield select(folderSharingEnabled);

  try {
    const { success } = yield call(getProjectTree, projectId, {
      depth: DEFAULT_TREE_DEPTH,
      include: isFolderSharingEnabled ? 'review_links' : undefined,
    });
    if (success) {
      yield successAction(
        PROJECT.FETCH_TREE.SUCCESS,
        get(success, 'payload.response.data'),
        projectId
      );
    }
  } catch (err) {
    yield failureAction(PROJECT.FETCH_TREE.FAILURE, projectId);
  }
}

/**
 * We've coupled the actions for setCurrentProject w/ setCurrentTeam and setCurrentAccount
 * because in the legacy app, the context derived for the current team & the
 * current account are all based on the current project.
 * @param   {Object}    action - Action object.
 */
function* setCurrentProject({ payload: { projectId, prevProjectId } }) {
  if (prevProjectId) {
    yield put(leaveProjectRoom(prevProjectId));
    // always leave the private project room, even if we didn't join it (it will no-op)
    yield put(leaveProjectRoom(prevProjectId, true));
  }

  // TODO: Now that root container always redirect, this should never get called.
  if (!projectId) {
    yield put(setCurrentTeam());
    yield put(setCurrentAccount());
    return;
  }

  yield call(getRoleForProject, { payload: { projectId } });
  yield put(joinProjectRoom(projectId));

  const isCollaboratorOnly = yield select(isCollaboratorOnlySelector, {
    projectId,
  });
  if (!isCollaboratorOnly) {
    // join the private project room if the user isn't only a collaborator
    yield put(joinProjectRoom(projectId, true));
  }

  let projectEntity = yield select(projectEntitySelector, { projectId });
  const { id: prevAccountId } = yield select(currentAccountSelector);
  const { id: prevTeamId } = yield select(currentTeamSelector);

  // The team entity is incomplete when sideloaded by legacy /projects/shared
  // api. When so, refetch the project, which then sideloads the team entity.
  //
  // Not doing getTeam() because that fetches v2 data, but things like
  // `planHelpers.js` depend on legacy team data.
  //
  // TODO(marvin): this should not be needed in v2 when data is sideloaded more
  // consistently.
  let teamEntity = yield select(teamEntityForProjectIdSelector, { projectId });
  const hasCompleteTeam =
    teamEntity && (teamEntity.account_id || teamEntity.account);

  // In some cases (Player Page / Dashboard v2), we call setCurrentProject before all legacy
  // user data has been fetched. Since we only care about this one project regardless of
  // whether the other projects have been fetched, let's block on this fetch.
  if (!projectEntity || !prevAccountId || !hasCompleteTeam) {
    const { success } = yield call(getProject, projectId);
    if (success) {
      projectEntity = yield select(projectEntitySelector, { projectId });
    }
  }

  if (!projectEntity) {
    // Trying to set a project entity that doesn't exist...no thank you, plz redirect.
    yield call(redirectToProject);
    return;
  }

  const teamId = projectEntity.team || projectEntity.team_id;
  teamEntity = yield select(teamEntitySelector, { teamId });

  if (prevTeamId !== teamId) {
    yield put(setCurrentTeam(teamId, prevTeamId));
  }

  yield put(fetchTree(projectId));
}

/**
 * Confirms when the user wants to leave a project.
 * @param   {Object}    action - Action object.
 */
function* confirmLeaveProject({
  payload: { projectId, trackingPage, trackingPosition },
}) {
  const currentUser = yield select(currentUserSelector);
  const ownTeams = yield select(teamsByCurrentUserMembershipSelector);
  const project = yield select(projectEntitySelector, { projectId });
  const isOwnProject = ownTeams
    .map((team) => team.id)
    .includes(project.team_id);

  let message;

  // Leaving a project on your team:
  if (isOwnProject && !project.private) {
    message =
      'This project will remain visible to you, but you will no longer receive notifications.';

    // Leaving a private project on your team:
  } else if (isOwnProject && project.private) {
    message = 'This project is private and will not be visible once you leave.';

    // Leaving a shared project:
  } else if (!isOwnProject) {
    message =
      'This project is shared with you and will not be visible once you leave.';
  }

  message = (
    <span>
      Are you sure you want to leave <strong>{project.name}</strong>? {message}
    </span>
  );

  const shouldLeave = yield call(confirm, 'Leave the project?', message, {
    primaryText: 'Leave',
  });
  if (shouldLeave) {
    yield call(removeCollaboratorFromProject, {
      projectId,
      collaboratorId: currentUser.id,
    });
  }

  yield spawn(
    trackButtonClick,
    'leave-project',
    trackingPage,
    trackingPosition
  );
}

/**
 * When the core successfully deletes a project, show a toast.
 * @param {Object} action - Action from core.
 */
function* onDeleteProjectSuccess(
  projectId,
  projectName,
  nextProjectId,
  accountId
) {
  const currentProject = yield select(currentProjectSelector);

  // Show confirmation message
  yield put(
    showSuccessToast({
      header: (
        <span>
          Successfully deleted <strong>{projectName}</strong>
        </span>
      ),
    })
  );

  /*
    If the current project is not the same as the deleted
    project, do not redirect user to a different project
  */
  if (currentProject.id !== projectId) return;

  /*
    To maintain account context, route to the next project id for a
    given team, or, if a next project doesn't exist but its associated
    account id has been derived (in the case that the last project of
    a team has been deleted), route to an empty team route.

    If no next project id or account id is derived, this logic will
    fall back to default project id routing.
  */
  if (nextProjectId || !accountId) {
    /*
      If nextProjectId is null, redirectToProject will attempt to route
      to the default project id
    */
    yield call(redirectToProject, nextProjectId);
  } else {
    yield call(redirectToFirstTeamOrSharedProject, accountId);
  }
}

/**
 * When the core fails to delete a project, show the toast.
 */
function* onDeleteProjectFailure() {
  yield put(
    showErrorToast({
      header: 'Deleting failed',
    })
  );
}

export function* redirectUnavailableProject(projectId) {
  const { id: accountId } = yield select(currentAccountSelector);

  /*
    When an unavailable project url is accessed directly, the Project Container cannot
    derive its account context. However, if the project is redirected to from
    account entry (e.g. through the Account Switcher), the current account would have
    been set, and account context can be maintained. In this case, redirect to appropriate
    `/accounts/:accountId/:teamId` route which will handle:
    the appropriate routing to either:
      1. The first team project, if it exists
      2. The first team  without a project
      3. The first shared project

    AND delete the last viewed project for that account.
  */
  if (accountId) {
    yield call(redirectToFirstTeamOrSharedProject, accountId);
    yield spawn(deleteLastViewedProjectForAccount, accountId);
    return;
  }

  yield spawn(track, 'project-error-modal-shown', {
    access_attempt_project_id: projectId,
  });
  yield call(
    error,
    'Whoops!',
    'Looks like that project is no longer available.',
    {
      priority: priority.PROJECT_NOT_FOUND,
    }
  );

  yield call(redirectToProject);
}

function* onJoinAsCollaborator(projectId) {
  const project = yield select(projectEntitySelector, { projectId });
  const message = `Successfully joined ${project.name || 'the project'}`;
  yield put(showSuccessToast({ header: message }));
}

function* trackDeleteProject(projectId) {
  const project = yield select(projectEntitySelector, { projectId });
  const rootFolderId = project.root_asset_id;

  // Fetch root folder size, which isn't guaranteed to be there
  yield call(getAssetSize, rootFolderId);

  const {
    usage: { item_count: itemCount, filesize },
  } = yield select(assetEntitySelector, { assetId: rootFolderId });

  yield spawn(track, 'delete-project', {
    project_id: projectId,
    deleted_project_media_count: itemCount,
    deleted_project_storage: bytesToMB(filesize),
  });
}

function* onDeleteConfirmed(projectId, projectName) {
  yield spawn(trackButtonClick, 'delete', 'delete project modal', 'middle');
  /*
    Prior to project deletion, determine the next project id within its
    account/team context, and fire a delete project tracking event
  */
  const nextProjectId = yield select(getNextProjectIdInTeamSelector, {
    projectId,
  });

  /*
    If the project to be deleted is the last project for a given team, no nextProjectId
    will be derived. As a fallback (and to maintain account context), derive the associated
    account id of the project to rallow redirect to the empty team route within the account.
  */
  const { id: accountId } = yield select(accountEntityForProjectIdSelector, {
    projectId,
  }) || {};
  yield call(trackDeleteProject, projectId);
  const { success, failure } = yield call(deleteProject, projectId);

  if (success) {
    yield call(
      onDeleteProjectSuccess,
      projectId,
      projectName,
      nextProjectId,
      accountId
    );
    return success;
  }

  yield call(onDeleteProjectFailure, projectId);
  return failure;
}

function* onArchiveConfirmed(projectId) {
  yield put(startArchiveProject(projectId));
  yield spawn(trackButtonClick, 'archive', 'delete project modal', 'middle');
}

const CONFIRM_DELETE_EXPECTED_VALUE = 'DELETE';

/**
 * Confirms when a user wants to delete a project
 * @param   {Object}    action - Action object.
 * @returns {string|boolean} Returns the response from the confirmation dialog.
 */
export function* confirmDeleteProject({ payload: { projectId, projectName } }) {
  yield spawn(track, 'delete-project-modal-shown');
  const secondaryButtonResponse = 'archive';
  const project = yield select(projectEntitySelector, { projectId });
  const isProjectArchived = !!project.archived_at;
  const { canArchiveProject } = yield select(
    permittedActionsForProjectSelector,
    { projectId }
  );

  const warningMessage = (input) => (
    <>
      <p>
        Are you sure you want to delete <strong>{projectName}</strong>? All
        associated media will be permanently deleted and this CANNOT be undone.
      </p>

      <br />

      <p>Please type {CONFIRM_DELETE_EXPECTED_VALUE} to confirm</p>

      {input}
    </>
  );

  const confirmArgs = {
    confirmBeforeResponding: true,
    expectedPromptValue: CONFIRM_DELETE_EXPECTED_VALUE,
    promptValue: '',
  };

  if (!isProjectArchived && canArchiveProject) {
    confirmArgs.secondaryText = 'Archive instead';
    confirmArgs.secondaryButtonResponse = secondaryButtonResponse;
  }

  const response = yield call(
    confirmDelete,
    'Delete the project?',
    warningMessage,
    confirmArgs
  );
  if (response === secondaryButtonResponse) {
    yield spawn(onArchiveConfirmed, projectId, projectName);
  } else if (response) {
    yield spawn(onDeleteConfirmed, projectId, projectName);
  }
  return response;
}

export function* onJoinProjectAsCollaboratorRequest({
  payload: { projectId, trackingPage, trackingPosition },
}) {
  const currentUser = yield select(currentUserSelector);
  yield call(joinProjectAsCollaborator, projectId, currentUser.id);

  yield spawn(trackButtonClick, 'join-project', trackingPage, trackingPosition);
}

export function* getProjectUserPreferences({ payload: { projectId } }) {
  const projectUserPrefs = yield select(userPreferencesForProjectIdSelector, {
    projectId,
  });

  // If the user prefs don't already exist on the project, fetch them.
  if (!projectUserPrefs) {
    yield spawn(coreSagaGetProjectUserPreferences, projectId);
  }
}

function* onCollaboratorDeletedSocketEvent({ data: { entities, result } }) {
  // In the case where by we're a collaborator that has been removed
  // from a project, then we want to kick them out of the project room
  // we only do this for the following cases:
  // 1. the project is private, regardless of the users role
  // 2. the user is only a collaborator in the project
  const { project_id: projectId, user_id: userId } = entities.collaborator[
    result
  ];

  const currentUser = yield select(currentUserSelector);
  // Only target when the current user is the collaborator being removed
  if (currentUser.id !== userId) return;

  const { id: accountId } = yield select(currentAccountSelector);
  const project = yield select(projectEntitySelector, { projectId });
  const isCollaboratorOnly = yield select(isCollaboratorOnlySelector, {
    projectId,
  });
  /*
   * Return and do not display the modal on:
   *
   * Public project for non-collab only users (e.g. on account).
   * They are OK for the user to stay in that's because they can technically
   * be re-joined at anytime. Kicking them out is moot
   * if (false && false) return;
   *
   * OR
   *
   * Delete of a project. The project will be undefined
   * make sure to return at this point and not call the alert modal.
   * if (undefined && false) return;
   */
  if (!project?.private && !isCollaboratorOnly) return;

  // We want to make sure there are no more socket events coming through for this
  // project as this can potentially leak information the user should not be privvy to
  yield put(leaveProjectRoom(projectId, false));

  if (!isCollaboratorOnly) {
    yield put(leaveProjectRoom(projectId, true));
  }

  yield call(
    alert,
    'Project unavailable',
    'You no longer have access to this project.',
    {
      primaryText: 'Got it',
      priority: priority.PROJECT_NOT_AVAILABLE,
      modalProps: {
        canCloseModal: false,
      },
    }
  );

  // We're deliberately forcing a hard refresh of the browser here so that we
  // can avoid some of the nasty edge-cases associated with having the clear out
  // the relevant entities that the user can no longer see from the store
  // Additionally we want to clear the current project so we don't end up back here
  yield spawn(deleteLastViewedProjectForAccount, accountId);
  yield call([window.location, 'assign'], ROOT_URL);
}

function* onCollaboratorSocketEventReceived(event) {
  switch (event.type) {
    case CollaboratorEvents.CollaboratorDeleted:
      yield call(onCollaboratorDeletedSocketEvent, event);
      break;
    default:
      yield;
  }
}

export const testExports = {
  confirmDeleteProject,
  onDeleteProjectSuccess,
  onJoinAsCollaborator,
  redirectToProject,
  redirectUnavailableProject,
  setCurrentProject,
  trackDeleteProject,
  onJoinProjectAsCollaboratorRequest,
  getProjectUserPreferences,
  onDeleteConfirmed,
  onArchiveConfirmed,
  onCollaboratorSocketEventReceived,
  onCollaboratorDeletedSocketEvent,
};

export default [
  takeLatest(PROJECT.SET_CURRENT, setCurrentProject),
  takeLatest(PROJECT.CONFIRM_DELETE, confirmDeleteProject),
  takeLatest(PROJECT.CONFIRM_LEAVE, confirmLeaveProject),
  takeLatest(
    CORE_PROJECT.JOIN_AS_COLLABORATOR.SUCCESS,
    ({ payload: { entityId } }) => onJoinAsCollaborator(entityId)
  ),
  takeLatest(
    CORE_PROJECT.JOIN_AS_COLLABORATOR_FROM_INVITE.SUCCESS,
    ({ payload: { response } }) => onJoinAsCollaborator(response.projectId)
  ),
  takeEvery(CORE_COLLABORATOR.RECEIVE_EVENT, ({ payload: { event } }) =>
    onCollaboratorSocketEventReceived(event)
  ),
  takeLatest(PROJECT.JOIN_AS_COLLABORATOR, onJoinProjectAsCollaboratorRequest),
  takeLatest(PROJECT.GET_USER_PREFS, getProjectUserPreferences),
  takeLatest(PROJECT.FETCH_TREE.BASE, fetchProjectFolderTree),
];
