import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import absoluteFill from '@frameio/components/src/mixins/absoluteFill';
import Button from '@frameio/components/src/styled-components/Button';
import Spinner from '@frameio/components/src/styled-components/SpinnerBase';
import ShadowContainer from '@frameio/components/src/styled-components/ShadowContainer';
import Checkbox, {
  CheckCircle,
  CheckIcon,
} from '@frameio/components/src/styled-components/Checkbox';
import { fontStyle } from '@frameio/components/src/theme/darkTheme';
import CreateFolderSvg from '@frameio/components/src/svgs/icons/16/create-folder.svg';
import { Tooltip, usePrevious } from '@frameio/vapor';
import TreeAemNavigator from 'components/Dialog/AdobeIntegrationConnectDialog/Aem/TreeAemNavigator';
import { FolderCreationEvents } from './actions';
import Search, {
  FILTER_OPTIONS,
  COLLABORATOR_MOVE_FILTER_OPTIONS,
} from './Search';
import ConnectedSearchResults from './ConnectedSearchResults';
import ConnectedSearchAllResults from './ConnectedSearchAllResults';
import TreeNavigator from './TreeTeamNavigator';
import ProjectSearchResults from './ProjectSearchResults';
import MoveAssetsDialogContext from './MoveAssetsDialogContext';

const StyledShadowContainer = styled(ShadowContainer)`
  ${absoluteFill()};
`;

const StyledCheckBox = styled(Checkbox)`
  display: flex;
  align-items: center;
  input {
    cursor: pointer;
  }
  ${CheckCircle} {
    width: ${(p) => p.theme.spacing.units(2)};
    height: ${(p) => p.theme.spacing.units(2)};
    box-shadow: ${(p) => `inset 0 0 2px ${p.theme.color.dimGray}`};
    border-radius: ${(p) => p.theme.radius.medium};
  }
  ${CheckIcon} {
    border-radius: ${(p) => p.theme.radius.medium};
    width: ${(p) => p.theme.spacing.units(2)};
    height: ${(p) => p.theme.spacing.units(2)};
  }
`;

const Modal = styled.div`
  width: ${(p) => p.theme.spacing.units(70)};
  display: flex;
  max-width: 100%;
  flex-direction: column;
  border-radius: ${(p) => p.theme.radius.medium};
  ${(p) => p.theme.fontStyle.body};
`;

const StyledModalContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${(p) =>
    `${p.theme.spacing.units(2)} 0 ${p.theme.spacing.units(2)} 0`};
`;

const MoveButton = styled(Button)`
  display: flex;
  align-items: center;
  margin-left: ${(p) => p.theme.spacing.units(1)};
  ${(p) =>
    p.isSaving &&
    `
    &:disabled {
      opacity: 1;
      background-color: #8c86ff;
    }
  `}
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${(p) => p.theme.spacing.units(2)};
  margin-bottom: 0;
`;

const TreeViewBorder = styled(motion.div)`
  position: relative;
  height: ${(p) => p.theme.spacing.units(32)};
  ${(p) => !p.$selectedAemDestination && `border-bottom: 1px solid`};
  border-color: ${(p) => p.theme.color.silver};
  display: flex;
  flex-direction: column;
  pointer-events: ${(p) => (p.isSaving ? 'none' : 'auto')};
`;

const SpinnerContainer = styled.div`
  margin-right: ${(p) => p.theme.spacing.units(1)};
`;

const Header = styled.div`
  padding: ${(p) => p.theme.spacing.units(2)};
  padding-top: ${(p) => p.theme.spacing.units(1)};
  padding-bottom: ${(p) => p.theme.spacing.units(1)};
`;

const Title = styled.h2`
  margin: 0;
  ${fontStyle.headerXS};
  color: ${(p) => p.theme.color.coolBlack};
  ${(p) => p.theme.fontStyle.headerXS};
  margin-bottom: ${(p) => p.theme.spacing.units(2)};
  flex: 1;
`;

const ButtonAndCheckBoxContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const CopyCommentsLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.color.dimGray};
  // Set to padding (not margin) so cursor:pointer and clicks events happen between the checkbox[padding]label
  padding-left: ${(p) => p.theme.spacing.units(1)};
  // exception to create a bit more space between copy comments toggle and the buttons.
  margin-right: ${(p) => p.theme.spacing.units(2)};
`;

// All svg styling is attached to the Button because of hover events and synced animations.
const CreateFolderIcon = styled(CreateFolderSvg)``;

const CreateFolderButton = styled.button`
  border: none;
  background: none;
  border-radius: 100%;
  overflow: hidden;
  padding: ${(p) => p.theme.spacing.units(1)};
  display: flex;
  position: relative;
  cursor: ${(p) => (p.disabled ? `default` : `pointer`)};

  &:before {
    content: ' ';
    position: absolute;
    border-radius: 100%;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;

    ${(p) =>
      !p.disabled &&
      `
        background: ${p.theme.color.coldWhite};
        transform: scale(0.3, 0.3);
        transition: all 350ms ${p.theme.easing.primary};
        transition-property: transform, opacity;
      `}
  }

  svg {
    color: ${(p) => p.theme.color.graphiteGray};
  }

  :focus,
  :hover {
    &:before {
      transform: scale(1.0,1.0);
      opacity: 1.0;
    }
  }

  ${(p) =>
    p.disabled &&
    `
      cursor: default;

      svg,
      :focus svg,
      :hover svg {
        animation-name: none;
        animation: none;
        color: ${p.theme.color.accentGrayMedium};
      }
    `}
  }
`;
/**
 * Move Dialog
 *
 * This is the main parent component which renders
 * everything in the move-to dialog
 */

function MoveToDialog({
  isSaving,
  closeModal,
  assetsToMove,
  moveAssets,
  currentProject,
  currentLocationId,
  isCollaboratorOnly,
  copyAssets,
  selectedAemDestination,
  shouldCopy,
  teams,
  title,
  isNoNewFolderCreation,
  isNamingNewFolder,
  isNewFolderSaving,
  isNewFolderCreated,
  selectedId,
  canCreateFolder,
  onSetFolderCreationStatus,
  onSetUserSelectedId,
}) {
  const [expanded, setExpanded] = useState([]);
  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState(
    isCollaboratorOnly && !shouldCopy ? 'folders' : 'all'
  );
  const [includeComments, setIncludeComments] = useState(false);
  const [postponePerformMove, setPostponePerformMove] = useState(false);
  // When a newly folder is created we want to expand the parentFolder:
  const parentFolderId = usePrevious(selectedId);

  /**
   * Set selected nodeId
   */
  const onSelect = useCallback(
    (nodeId) => {
      onSetUserSelectedId(nodeId, true);
    },
    [onSetUserSelectedId]
  );

  /**
   * Perform an asset move or copy to a destination
   * folder or project
   */

  const performMove = useCallback(
    (nodeId) => {
      if (isNewFolderSaving) {
        /**
         * a user has set to move to a folder which doesn't exist yet.
         * postpone the move, wait for the save to be completed,
         * and on useEffect() once folder is created and we have the id.
         * trigger the performMove operation again.
         */
        setPostponePerformMove(true);
        return;
      }

      if (shouldCopy) {
        copyAssets(assetsToMove, nodeId, includeComments);
      } else {
        moveAssets(assetsToMove, nodeId);
      }
      onSetUserSelectedId(null, false);
    },
    [
      moveAssets,
      shouldCopy,
      copyAssets,
      assetsToMove,
      includeComments,
      onSetUserSelectedId,
      isNewFolderSaving,
    ]
  );

  /**
   * disambiguate this function from performMove so that
   * we don't rerender our entire tree any time selected
   * changes. DoubleClick doesn't need to know about which
   * entity is currently selected.
   */
  const onMove = useCallback(() => {
    performMove(selectedId);
  }, [performMove, selectedId]);

  /**
   * Close modal on cancel
   */

  const onCancel = useCallback(() => {
    onSetUserSelectedId(null, false);
    closeModal();
  }, [closeModal, onSetUserSelectedId]);

  /**
   * Handle search query changes
   */

  const onQueryChange = useCallback(
    (e) => {
      if (selectedId) {
        onSetUserSelectedId(null, false);
      }

      // whenever a query changes collapse any
      // expanded tree items
      if (expanded.length > 0) {
        setExpanded([]);
      }

      setQuery(e.target.value);
    },
    [selectedId, onSetUserSelectedId, setQuery, expanded]
  );

  /**
   * Handle when our search filter changes
   */
  const onSelectFilter = useCallback(
    (name) => {
      if (selectedId && query) {
        onSetUserSelectedId(null, false);
      }
      setFilter(name);
    },
    [query, onSetUserSelectedId, setFilter, selectedId]
  );

  /**
   * Perform move on double clicks
   */

  const onDoubleClick = useCallback(
    (nodeId) => {
      performMove(nodeId);
    },
    [performMove]
  );

  /**
   *  On Click of Create/New Folder icon.
   */
  const onClickCreateFolder = useCallback(() => {
    // Limit the ability of folder creation in this modal:
    // Do not allow users to create multiple 'Untitled Folder' folders
    // In one selected folder.
    if (isNoNewFolderCreation) {
      onSetFolderCreationStatus(FolderCreationEvents.IS_BEING_NAMED);
    }
  }, [onSetFolderCreationStatus, isNoNewFolderCreation]);

  const onExpand = useCallback(
    (newExpandedList) => {
      /*
       * if a user tries to expand something in the tree and we are have
       * an active folder in creation. DON'T expand. maintain the existing state.
       * Otherwise a user could collapse a folder that WILL have a new created folder in it.
       */
      if (isNamingNewFolder) return;
      setExpanded(newExpandedList);
    },
    [isNamingNewFolder]
  );

  /**
   * Render our main content depending
   * upon the filter and query state
   */

  const mainContent = useMemo(() => {
    return (
      <MoveAssetsDialogContext.Provider value={{ assetsToMove }}>
        <StyledShadowContainer>
          {({ onScroll }) => {
            const sharedTreeViewProps = {
              expanded,
              onExpand,
              selectedId,
              onSelect,
              onDoubleClick,
              onScroll,
            };
            /**
             * When querying and filtering by 'all', render
             * our unified search results which include folders,
             * teams, and projects.
             */

            if (query && filter === 'all') {
              return (
                <ConnectedSearchAllResults
                  query={query}
                  limitToProjectId={
                    isCollaboratorOnly && !shouldCopy ? currentProject.id : null
                  }
                  {...sharedTreeViewProps}
                />
              );
            }

            /**
             * When querying and using any filter other than
             * 'all' render the specific search results
             */
            if (query && filter !== 'all') {
              return (
                <ConnectedSearchResults
                  key={filter}
                  {...sharedTreeViewProps}
                  limitToProjectId={
                    isCollaboratorOnly && !shouldCopy ? currentProject.id : null
                  }
                  filter={filter}
                  query={query}
                />
              );
            }

            /**
             * If you're a collaborator and trying to move an asset,
             * we limit you to moving assets within the same project. We
             * reuse the project search results component here.
             */
            if (isCollaboratorOnly && !shouldCopy) {
              return (
                <ProjectSearchResults
                  showDetails={false}
                  items={[currentProject]}
                  {...sharedTreeViewProps}
                />
              );
            }

            if (selectedAemDestination) {
              return (
                <TreeAemNavigator
                  deferMount
                  projectId={currentProject.id}
                  selectedAemDestination={selectedAemDestination}
                  {...sharedTreeViewProps}
                />
              );
            }

            /**
             * If you're not making a query, we render a tree with
             * all available teams. This is the default view.
             */
            return (
              <TreeNavigator
                deferMount
                projectId={currentProject.id}
                teams={teams}
                {...sharedTreeViewProps}
              />
            );
          }}
        </StyledShadowContainer>
      </MoveAssetsDialogContext.Provider>
    );
  }, [
    assetsToMove,
    currentProject,
    expanded,
    filter,
    isCollaboratorOnly,
    onDoubleClick,
    onSelect,
    onExpand,
    query,
    selectedAemDestination,
    selectedId,
    shouldCopy,
    teams,
  ]);

  useEffect(() => {
    if (postponePerformMove && isNewFolderCreated) {
      setPostponePerformMove(false);
      performMove(selectedId);
    }

    if (isNewFolderCreated) {
      // update the expanded list if the list does not have the parent:
      if (
        expanded.filter((existing) => existing === parentFolderId).length === 0
      ) {
        onExpand([parentFolderId, ...expanded]);
      }
    }
  }, [
    expanded,
    selectedId,
    parentFolderId,
    isNewFolderCreated,
    performMove,
    postponePerformMove,
    onExpand,
  ]);

  // repurposed on more than one button:
  const canBeActive = selectedId && !isSaving;
  const canBeDisabledCreateFolderCTA =
    !canBeActive || isNamingNewFolder || !canCreateFolder;
  return (
    <Modal>
      <StyledModalContent>
        <Header>
          <Title>{title}</Title>
          {!selectedAemDestination && (
            <Search
              onSelectFilter={onSelectFilter}
              filter={filter}
              value={query}
              onChange={onQueryChange}
              filterOptions={
                !isCollaboratorOnly || shouldCopy
                  ? FILTER_OPTIONS
                  : COLLABORATOR_MOVE_FILTER_OPTIONS
              }
              placeholder="Search"
            />
          )}
        </Header>

        <TreeViewBorder
          isSaving={isSaving}
          animate={{ opacity: isSaving ? 0.6 : 1 }}
          $selectedAemDestination={!!selectedAemDestination}
        >
          {mainContent}
        </TreeViewBorder>
        {!selectedAemDestination && (
          <ModalFooter>
            <Tooltip
              title="Create New Folder"
              position="top"
              variant="dark"
              disabled={canBeDisabledCreateFolderCTA}
            >
              <CreateFolderButton
                disabled={canBeDisabledCreateFolderCTA}
                onClick={
                  !canBeDisabledCreateFolderCTA ? onClickCreateFolder : null
                }
              >
                <CreateFolderIcon />
              </CreateFolderButton>
            </Tooltip>
            <ButtonAndCheckBoxContainer>
              {shouldCopy && (
                <React.Fragment>
                  <StyledCheckBox
                    id="include-comments"
                    checked={includeComments}
                    onChange={(e) => {
                      setIncludeComments(e.target.checked);
                    }}
                  />
                  <CopyCommentsLabel htmlFor="include-comments">
                    Copy with comments
                  </CopyCommentsLabel>
                </React.Fragment>
              )}
              {/*
              data-modalbuttoncancel is needed by the phantom child
              so on defocus it will prevent saving a new folder when cancel is called.
            */}
              <Button data-modalbuttoncancel="1" onClick={onCancel}>
                Cancel
              </Button>
              {/* A custom button which animates its width upon loading.
            This is probably a candidate for a design system component
            going forward */}
              <MoveButton
                disabled={
                  // disable if there's no selection, or saving, or selected is the
                  // current location (when moving), or if a teamId is selected.
                  !canBeActive ||
                  (selectedId === currentLocationId && !shouldCopy)
                }
                onClick={onMove}
                primary
              >
                <AnimatePresence>
                  {isSaving && (
                    <motion.div
                      initial={{ opacity: 0, scale: 0.8, width: 0 }}
                      animate={{ opacity: 1, scale: 1, width: 'auto' }}
                      exit={{ opacity: 0, scale: 0.8, width: 0 }}
                    >
                      <SpinnerContainer>
                        <Spinner
                          spinning
                          radius={10}
                          color="255,255,255"
                          stroke={2}
                        />
                      </SpinnerContainer>
                    </motion.div>
                  )}
                </AnimatePresence>
                {shouldCopy ? 'Copy' : 'Move'}
              </MoveButton>
            </ButtonAndCheckBoxContainer>
          </ModalFooter>
        )}
      </StyledModalContent>
    </Modal>
  );
}

MoveToDialog.propTypes = {
  isSaving: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  assetsToMove: PropTypes.array.isRequired,
  moveAssets: PropTypes.func.isRequired,
  currentProject: PropTypes.object.isRequired,
  currentLocationId: PropTypes.string,
  isCollaboratorOnly: PropTypes.bool,
  copyAssets: PropTypes.func.isRequired,
  shouldCopy: PropTypes.bool,
  teams: PropTypes.arrayOf(PropTypes.object),
  isNoNewFolderCreation: PropTypes.bool,
  isNamingNewFolder: PropTypes.bool,
  isNewFolderSaving: PropTypes.bool,
  isNewFolderCreated: PropTypes.bool,
  selectedAemDestination: PropTypes.object,
  selectedId: PropTypes.string,
  title: PropTypes.string,
  canCreateFolder: PropTypes.bool,
  onSetFolderCreationStatus: PropTypes.func,
  onSetUserSelectedId: PropTypes.func,
};

export default React.memo(MoveToDialog);

export const testExports = {
  MoveButton,
};
