import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { debounce, isEqual, noop, pick } from 'lodash';

import DatePicker from '@frameio/components/src/styled-components/DatePicker';
import Password from '@frameio/components/src/styled-components/Password';
import Switch from '@frameio/components/src/styled-components/Switch';
import TextInput, {
  Input,
  IconContainer,
} from '@frameio/components/src/styled-components/TextInput';
import PremiumBadge from '@frameio/components/src/styled-components/PremiumBadge';
import { allFeatures } from 'planFeatures';
import { Tooltip } from '@frameio/vapor';
import track from 'analytics';

import { SettingLabel, SettingSwitch, SettingSwitchRow } from './Settings';

const INPUT_TRANSITION_DURATION = 200;

const InputContainer = styled.div`
  // max height is Input height + 8px Input top margin
  max-height: ${({ isVisible, theme: { spacing } }) =>
    isVisible ? `calc(${spacing.units(5)} + ${spacing.tiny})` : '0'};
  overflow: hidden;
  transition: max-height ${INPUT_TRANSITION_DURATION}ms ease-in-out;
`;

const InputFieldPlaceholder = styled.div`
  height: ${(p) => p.theme.spacing.units(6)};
`;

const Container = styled.div`
  max-height: ${({ isVisible }) => (isVisible ? '100vh' : '0')};
  overflow: hidden;
  transition: max-height
    ${({ numExpandedFields }) => (numExpandedFields > 0 ? '0.3s' : '0.35s')}
    cubic-bezier(
      ${({ isVisible }) => (isVisible ? '0.3, 0, 1, 0' : '0.3, 0, 0, 1')}
    );

  ${Input} {
    margin-top: ${(p) => p.theme.spacing.tiny};
  }

  ${IconContainer} {
    margin-top: ${(p) => p.theme.spacing.micro};
  }
`;

const StyledPremiumBadge = styled(PremiumBadge)`
  cursor: pointer;
`;

export class AdvancedSettingsForm extends React.Component {
  shouldComponentUpdate(nextProps) {
    const propsToCompare = [
      'expiresAt',
      'password',
      'showAdvancedSettings',
      'showLinkExpiration',
      'showPassword',
    ];

    return !isEqual(
      pick(this.props.formik.values, propsToCompare),
      pick(nextProps.formik.values, propsToCompare)
    );
  }

  debouncedPasswordInput = debounce(() => {
    const { submitForm } = this.props.formik;
    submitForm();
  }, 200);

  unsetSwitchFieldValue = (fieldName, currentFieldVal, isSwitchOn) => {
    const {
      formik: { setFieldValue, submitForm },
    } = this.props;

    if (isSwitchOn || !currentFieldVal) return;

    setFieldValue(fieldName, '');
    /*
      Unset input field immediately after transition duration to prevent
      jerky/'catch' in max-height transition to 0px on the the input field
    */
    setTimeout(() => submitForm(), INPUT_TRANSITION_DURATION);
  };

  renderUpgradeBadge = (feature) => {
    const { isAdmin, reviewLinkId, upgradePlan } = this.props;
    const isShareLinkExpiration = feature === allFeatures.shareLinkExpiration;
    const plan = isShareLinkExpiration ? 'Team' : 'Pro';
    const maybeStartUpgradePlanFlow = () => {
      if (!isAdmin) return;
      track('feature-gate-clicked-client', {
        _limit: feature,
      });
      upgradePlan(reviewLinkId, feature);
    };
    return (
      <Tooltip
        details={isAdmin && 'Click to learn more.'}
        offset={[0, 10]}
        placement="top"
        title={`Available on ${plan} ✨`}
        variant="dark"
      >
        <StyledPremiumBadge
          onClick={isAdmin ? maybeStartUpgradePlanFlow : noop}
        >
          Upgrade
        </StyledPremiumBadge>
      </Tooltip>
    );
  };

  render() {
    const {
      formik: {
        handleChange,
        setFieldValue,
        submitForm,
        values: {
          expiresAt,
          password,
          showAdvancedSettings,
          showLinkExpiration,
          showPassword,
        },
      },
      canUsePasswordProtectedShares,
      canUseShareLinkExpiration,
    } = this.props;

    const numExpandedFields = showLinkExpiration + showPassword;
    const shouldShowPassword = canUsePasswordProtectedShares && showPassword;

    return (
      <Container
        isVisible={showAdvancedSettings}
        numExpandedFields={numExpandedFields}
      >
        <SettingSwitchRow>
          <SettingLabel>
            Passphrase protect
            <SettingSwitch>
              {canUsePasswordProtectedShares ? (
                <Switch
                  isOn={shouldShowPassword}
                  name="showPassword"
                  onChange={(evt) => {
                    handleChange(evt);
                    this.unsetSwitchFieldValue(
                      'password',
                      password,
                      evt.target.checked
                    );
                  }}
                />
              ) : (
                this.renderUpgradeBadge(allFeatures.passwordProtectedShares)
              )}
            </SettingSwitch>
          </SettingLabel>
          {/* TODO(Anna): Confirm AnimateHeight settings with design */}
          <InputContainer isVisible={shouldShowPassword}>
            {shouldShowPassword ? (
              <Password
                autoComplete="new-password"
                name="password"
                value={password || ''}
                onChange={(evt) => {
                  handleChange(evt);
                  this.debouncedPasswordInput(evt.target.value);
                }}
              />
            ) : (
              <InputFieldPlaceholder />
            )}
          </InputContainer>
        </SettingSwitchRow>
        <SettingSwitchRow>
          <SettingLabel>
            Link expiration
            <SettingSwitch>
              {canUseShareLinkExpiration ? (
                <Switch
                  isOn={showLinkExpiration}
                  name="showLinkExpiration"
                  onChange={(evt) => {
                    handleChange(evt);
                    this.unsetSwitchFieldValue(
                      'expiresAt',
                      expiresAt,
                      evt.target.checked
                    );
                  }}
                />
              ) : (
                this.renderUpgradeBadge(allFeatures.shareLinkExpiration)
              )}
            </SettingSwitch>
          </SettingLabel>
          {/* TODO(Anna): Confirm AnimateHeight settings with design */}
          <InputContainer isVisible={showLinkExpiration}>
            <DatePicker
              shouldCloseOnChange={false}
              name="expiresAt"
              onChange={(isoDate) => {
                setFieldValue('expiresAt', isoDate);
                setTimeout(() => submitForm(), 0);
              }}
              value={expiresAt || ''}
              minDate="today"
            >
              {(inputProps) => (
                <TextInput
                  autoComplete="off"
                  placeholder="Set expiration date"
                  readOnly
                  value={expiresAt}
                  {...inputProps}
                />
              )}
            </DatePicker>
          </InputContainer>
        </SettingSwitchRow>
      </Container>
    );
  }
}

AdvancedSettingsForm.propTypes = {
  // Provided by Formik connect
  formik: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    values: PropTypes.shape({
      expiresAt: PropTypes.string,
      password: PropTypes.string,
      showAdvancedSettings: PropTypes.bool.isRequired,
      showLinkExpiration: PropTypes.bool.isRequired,
      showPassword: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  canUsePasswordProtectedShares: PropTypes.bool.isRequired,
  canUseShareLinkExpiration: PropTypes.bool.isRequired,
  reviewLinkId: PropTypes.string.isRequired,
  upgradePlan: PropTypes.func.isRequired,
};

export default connect(AdvancedSettingsForm);

export const testExports = {
  InputContainer,
  InputFieldPlaceholder,
  StyledPremiumBadge,
};
