import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconWorkfront } from '@frameio/vapor-icons';
import { Tooltip } from '@frameio/vapor';
import TruncateWithTooltip from '@frameio/components/src/styled-components/TruncateWithTooltip';

/*
In order for the tooltips to display on the card overlay in project view we need to enable point-events on the child element
*/
const StyledContainer = styled.div`
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(p) =>
    p.$workfrontApprovalStatus && `gap: ${p.theme.spacing.units(0.75)};`};
`;

const StyledIconContainer = styled.span`
  width: ${(p) => p.$size};
  color: ${(p) => p.$iconColor};
  background-color: ${(p) => p.theme.color.white};
  border-radius: ${(p) => p.theme.radius.medium};
  display: flex;
`;

function WorkfrontApprovalStatusLabel({
  size,
  width,
  workfrontApprovalStatus,
  iconColor,
}) {
  return (
    <StyledContainer
      $workfrontApprovalStatus={workfrontApprovalStatus}
      $width={width}
    >
      <Tooltip
        title="Linked to Workfront"
        shouldUsePortal
        variant="dark"
        disabled={!!workfrontApprovalStatus}
      >
        <StyledIconContainer
          $size={size}
          $iconColor={iconColor}
          $workfrontApprovalStatus={workfrontApprovalStatus}
        >
          <IconWorkfront height={size} />
        </StyledIconContainer>
      </Tooltip>
      {workfrontApprovalStatus && (
        <TruncateWithTooltip position="bottom">
          {workfrontApprovalStatus}
        </TruncateWithTooltip>
      )}
    </StyledContainer>
  );
}

WorkfrontApprovalStatusLabel.defaultProps = {
  size: null,
};

WorkfrontApprovalStatusLabel.propTypes = {
  size: PropTypes.string,
};

export default WorkfrontApprovalStatusLabel;
