import React from 'react';
import Loadable from 'react-loadable';
import styled from 'styled-components';

import Avatar from '@frameio/components/src/styled-components/Avatar';
import ProjectSVG from '@frameio/components/src/svgs/icons/project-logo.svg';
import LazyImage from '@frameio/components/src/components/LazyImage';
import { variants as arrowAnimationVariants } from '@frameio/components/src/components/Animation/ArrowAnimation';

const Container = styled.div`
  display: flex;
`;

const ProfileColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  margin: ${(p) => p.theme.spacing.tiny};
  min-width: 102px;
`;

const LogoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 84px;
`;

const LogoCircle = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
  width: 100px;
  background-color: #20212d;
  border-radius: 50%;
`;

const LogoContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

const Names = styled.div`
  text-align: center;
  overflow-wrap: break-word;
  margin-top: ${(p) => p.theme.spacing.small};
  margin-bottom: ${(p) => p.theme.spacing.small};
  max-width: 100px;
  color: ${(p) => p.theme.color.white};
  ${(p) => p.theme.fontStyle.bodyS}
`;

const LazyAnimationContainer = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "arrowAnimation" */ '@frameio/components/src/components/Animation/ArrowAnimation'
    ),
  loading: () => null,
});

export const AUTH_GRAPHIC_VARIANTS = {
  AUTHORIZE: 'authorize',
  SUCCESS: 'success',
};

const AuthenticationGraphic = ({
  deviceVerificationResponse,
  projectName,
  variant,
}) => {
  const {
    authorizing_user: { name: userName, profile_image: profileImage },
    device: {
      model: { image_128: deviceLogo },
      name: deviceName,
    },
  } = deviceVerificationResponse;

  if (variant === AUTH_GRAPHIC_VARIANTS.AUTHORIZE) {
    return (
      <Container>
        <LogoColumn>
          <LogoCircle>
            <LogoContainer style={{ width: '75%' }}>
              <LazyImage src={deviceLogo} alt="logo" />
            </LogoContainer>
          </LogoCircle>
          <Names>{deviceName}</Names>
        </LogoColumn>
        <ProfileColumn>
          <Avatar name={userName} image={profileImage} size={64} />
          <LazyAnimationContainer style={{ height: '70px', width: '102px' }} />
        </ProfileColumn>
        <LogoColumn>
          <LogoCircle>
            <LogoContainer>
              <ProjectSVG />
            </LogoContainer>
          </LogoCircle>
          <Names>{projectName}</Names>
        </LogoColumn>
      </Container>
    );
  }
  return (
    <Container>
      <LogoColumn style={{ marginTop: '0px' }}>
        <LogoCircle>
          <LogoContainer style={{ width: '75%' }}>
            <LazyImage src={deviceLogo} alt="logo" />
          </LogoContainer>
        </LogoCircle>
        <Names>{deviceName}</Names>
      </LogoColumn>
      <ProfileColumn>
        <LazyAnimationContainer
          style={{
            height: '16px',
            marginTop: '34px',
            width: '88px',
          }}
          variant={arrowAnimationVariants.DIRECT}
        />
      </ProfileColumn>
      <LogoColumn style={{ marginTop: '0px' }}>
        <LogoCircle>
          <LogoContainer>
            <ProjectSVG />
          </LogoContainer>
        </LogoCircle>
        <Names>{projectName}</Names>
      </LogoColumn>
    </Container>
  );
};

export default AuthenticationGraphic;
