import React from 'react';
import PropTypes from 'prop-types';
import { MenuButton } from '@frameio/vapor';
import DownloadIcon from '@frameio/components/src/svgs/icons/download.svg';

import { trackButtonClick } from 'analytics';

import DownloadAssetAs from './DownloadAssetAs';

export default function DownloadAssets({
  assetIds,
  assetWithProxies,
  canDownloadOriginalCleanAsset,
  downloadAssets,
  downloadAsset,
  isForensicFallbackEnabled,
  isProjectArchived,
  projectId,
  trackingPage,
  trackingPosition,
  ...rest
}) {
  const renderLabel = () => {
    if (assetWithProxies) {
      return 'Download as…';
    }

    return assetIds.length > 1 ? 'Download all' : 'Download';
  };

  return (
    <MenuButton
      data-testid="download-assets-option"
      onSelect={() => {
        downloadAssets(assetIds);
        return isProjectArchived
          ? trackButtonClick(
              'downloaded archived',
              trackingPage,
              trackingPosition
            )
          : trackButtonClick(
              'downloaded original',
              trackingPage,
              trackingPosition
            );
      }}
      iconBefore={<DownloadIcon width={16} height={16} />}
      submenu={
        assetWithProxies && (
          <DownloadAssetAs
            asset={assetWithProxies}
            canDownloadOriginalCleanAsset={canDownloadOriginalCleanAsset}
            downloadAsset={downloadAsset}
            id="asset-action-download-as"
            isForensicFallbackEnabled={isForensicFallbackEnabled}
            trackingPage={trackingPage}
            trackingPosition={trackingPosition}
            projectId={projectId}
          />
        )
      }
      {...rest}
    >
      {renderLabel()}
    </MenuButton>
  );
}

DownloadAssets.propTypes = {
  assetWithProxies: PropTypes.object,
  assetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  canDownloadOriginalCleanAsset: PropTypes.bool.isRequired,
  downloadAssets: PropTypes.func.isRequired,
  downloadAsset: PropTypes.func.isRequired,
  trackingPage: PropTypes.string.isRequired,
  trackingPosition: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  isProjectArchived: PropTypes.bool.isRequired,
};

DownloadAssets.defaultProps = {
  assetWithProxies: undefined,
};
