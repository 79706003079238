import React from 'react';
import Raven from 'raven-js';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getHighestDownloadUrlForAsset } from '@frameio/core/src/assets/helpers/utils';
import { getAsset } from '@frameio/core/src/assets/services';
import { showErrorToast, showSuccessToast } from 'actions/toasts';
import { closeModal, openModal } from 'components/Modal/actions';
import { formatBytes } from 'shared/filesizeHelpers';
import history from 'browserHistory';
import getCSRFToken from 'utils/csrf';
import { goToRoute } from 'utils/router';
import {
  addVideoTags,
  clearVimeoToken,
  configureAuth,
  getAuthenticatedVimeoUser,
  generateVimeoAccessToken,
  getOAuthUrl,
  getVimeoToken,
  patchVideoMetadata,
  publishToVimeo,
  setVimeoToken,
} from 'utils/vimeo';
import VIMEO from './actions';
import VimeoShareEditor from './ConnectedVimeoShareEditor';

const TOAST_DELAY = 2000;

function* authOnVimeo(assetId) {
  clearVimeoToken(); // Delete any existing token

  const { pathname, search } = history.location;
  const state = {
    assetId,
    csrfToken: yield call(getCSRFToken),
    originRoute: `${pathname}${search}`,
  };

  yield call([window.location, 'assign'], getOAuthUrl(state));
}

function* showPublishToVimeoModal(assetId) {
  // Get authed Vimeo user
  let vimeoUser;
  const token = yield call(getVimeoToken);
  if (!token) yield call(authOnVimeo, assetId);

  yield call(configureAuth, token);
  try {
    vimeoUser = yield call(getAuthenticatedVimeoUser);
  } catch (err) {
    yield call(authOnVimeo, assetId);
    return;
  }

  // Get asset information for the Vimeo share flow, using a new network
  // request which includes a special header and doesn't store the response
  // data in the redux store. The special header indicates a request for
  // non-edge-cache-auth urls. For details see SERV-2702.
  const serviceData = yield call(
    getAsset,
    assetId,
    {},
    { 'x-frameio-client': 'legacy-third-party-integration-from-web-v3' }
  );
  const normalizedAsset = serviceData.entities?.asset?.[serviceData.result];
  const asset = {
    ...normalizedAsset,
    cover_asset: serviceData.entities?.asset?.[normalizedAsset.cover_asset_id],
  };

  let assetUrlForVimeo;
  try {
    const assetUrl = getHighestDownloadUrlForAsset(asset);
    assetUrlForVimeo = assetUrl;
  } catch (error) {
    yield call([Raven, Raven.captureException], error, {
      level: 'error',
      extra: { assetId },
      tags: { jira_ticket: 'SERV-2702' },
    });
    showErrorToast({
      header: 'Sorry, something went wrong.',
    });
    return;
  }

  if (!asset || assetUrlForVimeo?.includes('X-Frame-Edge-Auth')) {
    yield put(
      showErrorToast({
        header: 'Something went wrong, please try again.',
      })
    );
    yield call(
      [Raven, Raven.captureMessage],
      'SERV-2702: Failed to load asset with non-X-Frame-Edge-Auth url for Vimeo publication',
      {
        level: 'error',
        extra: { assetId },
        tags: { jira_ticket: 'SERV-2702' },
      }
    );
    return;
  }

  yield put(
    openModal(
      <VimeoShareEditor
        description={asset?.description}
        fileUrl={assetUrlForVimeo}
        name={asset?.name}
        thumb={asset?.thumb}
        size={formatBytes(asset?.filesize)}
        vimeoUser={vimeoUser}
      />
    )
  );
}

/**
 * Two flows:
 * 1. "Publish to Vimeo" context menu: has assetId
 * 2. "Connect" on /account?k=apps-integrations: no assetId
 * @param {*} code
 * @param {*} state
 */
function* receiveVimeoOAuthResponse(code, state) {
  const { assetId, csrfToken, originRoute } = state;
  if (csrfToken === (yield call(getCSRFToken))) {
    // Exchange oAuth code for an access token
    try {
      const { access_token: token } = yield call(
        generateVimeoAccessToken,
        code
      );
      yield call(setVimeoToken, token);
    } catch (err) {
      Raven.captureException(err);
      yield put(
        showErrorToast({
          header: 'There was a problem authenticating with Vimeo',
          autoCloseDelay: TOAST_DELAY,
        })
      );
    }
  }

  yield call(goToRoute, originRoute);

  if (assetId) {
    yield call(showPublishToVimeoModal, assetId);
  }
}

function* confirmPublishToVimeo({ payload: { data } }) {
  yield put(closeModal());
  const { metadata, params, tags } = data;
  try {
    const response = yield call(publishToVimeo, params);
    if (response) {
      const { name } = params;
      const { link, uri } = response;
      const videoId = uri.split('/').pop();

      yield put(
        showSuccessToast({
          header: 'Successfully published to Vimeo!',
          subHeader: (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#AAAFC3' }}
            >{`View “${name}”`}</a>
          ),
          autoCloseDelay: TOAST_DELAY,
        })
      );

      // PATCH asset metadata following successful `pull` upload
      patchVideoMetadata(videoId, metadata);

      // PUT asset tags to /tags endpoint following successful `pull` upload
      addVideoTags(videoId, tags);
    }
  } catch (err) {
    yield put(
      showErrorToast({
        header: 'There was a problem publishing your video to Vimeo.',
        subHeader: 'Please try again.',
      })
    );
  }
}

export default [
  takeEvery(VIMEO.AUTH, ({ payload: { assetId } }) => authOnVimeo(assetId)),
  takeEvery(VIMEO.CONFIRM_PUBLISH, confirmPublishToVimeo),
  takeEvery(VIMEO.RECEIVE_OAUTH_RESPONSE, ({ payload: { code, state } }) =>
    receiveVimeoOAuthResponse(code, state)
  ),
  takeEvery(VIMEO.PUBLISH, ({ payload: { assetId } }) =>
    showPublishToVimeoModal(assetId)
  ),
];

export const testExports = {
  authOnVimeo,
  confirmPublishToVimeo,
  receiveVimeoOAuthResponse,
  showPublishToVimeoModal,
};
