import { get } from 'lodash';
import { notificationEntitiesSelector } from '@frameio/core/src/notificationGroups/selectors';
import { NOTIFICATION_STATUS, NOTIFICATIONS_FILTER_OPTIONS } from './constants';

/**
 * Gets the state of store relevant to Notifications Popover.
 * @param {Object} state - Redux store state.
 * @returns {Object} The Notifications slice.
 */
export const notificationsPopoverSelector = (state) =>
  state.notificationsPopover;

/**
 * Returns if a given user has already opened the notifications popover.
 * @param {Object} state - Redux store state.
 * @param {String} accountId - account id.
 * @returns {Boolean} Whether or not a user has already opened the notifications popover for a given
 * accountId
 */
export const hasOpenedNotificationsPopoverSelector = (state, { accountId }) => {
  const notificationsState = notificationsPopoverSelector(state);

  if (!accountId || !notificationsState || !notificationsState[accountId])
    return false;

  const hasOpenedPopover = get(
    notificationsState,
    `${accountId}.hasOpenedPopover`
  );

  return !!hasOpenedPopover;
};

export const notificationsFetchingStatusSelector = (state) =>
  notificationsPopoverSelector(state).isFetching;

export const notificationsFetchingMoreStatusSelector = (state, { accountId }) =>
  get(notificationsPopoverSelector(state), `${accountId}.isFetchingMore`);

/**
 * Gets the filter applied to all notification popovers
 * @param {Object} state - Redux store state.
 * @returns {String} - filter value
 */
export const notificationsFilterSelector = (state) =>
  state.notificationsPopover?.filter || NOTIFICATIONS_FILTER_OPTIONS.ALL;

/**
 * Returns the notification entities for the account's notification results
 * @param {Object} state - Redux store state.
 * @param {String} accountId - account id.
 * @returns {Array} Array of notifications
 */
export const getNotificationsForAccountSelector = (state, { accountId }) => {
  const entities = notificationEntitiesSelector(state);
  const notificationsState = notificationsPopoverSelector(state);
  const filter = notificationsFilterSelector(state);
  const ids = get(notificationsState, `${accountId}.${filter}.results`) || [];
  return ids.map((id) => entities[id]);
};

/**
 * Returns the page info from the previous getNotifications response. This contains `cursor` and `hasNext`
 * @param {Object} state - Redux store state.
 * @param {String} accountId - account id.
 * @returns { endCursor: String, hasNextPage: Boolean } page results from previous fetch
 * accountId
 */
export const getPageInfoForAccountNotifications = (state, { accountId }) => {
  const notificationsState = notificationsPopoverSelector(state);
  const filter = notificationsFilterSelector(state);
  return get(notificationsState, `${accountId}.${filter}.pageInfo`);
};

/**
 * Gets a notification group by id.
 * @param {Object} state - Redux store state.
 * @param {String} accountId - notification group id.
 * @returns {Object} - a notification group.
 */
export const notificationEntitySelector = (state, { notificationId }) =>
  notificationEntitiesSelector(state)[notificationId];

/**
 * Gets the number of new notifications for a given account.
 * @param {Object} state - Redux store state.
 * @param {String} accountId - account id.
 * @returns {Number} The number of new notifications for a given accountId
 */
export const newNotificationsCountSelector = (state, { accountId }) => {
  const notificationsState = notificationsPopoverSelector(state);
  if (!accountId || !notificationsState || !notificationsState[accountId])
    return 0;
  const newNotificationsCount = get(
    notificationsState,
    `${accountId}.newNotificationsCount`,
    0
  );
  return newNotificationsCount;
};

/**
 * Gets unread notifications groups by account.
 * @param {Object} state - Redux store state.
 * @param {String} accountId - account id.
 * @returns {Array} - the list of unread notification groups.
 */
export const unreadNotificationsForAccountSelector = (state, { accountId }) => {
  const notificationGroups = notificationEntitiesSelector(state);
  return Object.values(notificationGroups).filter(
    ({ account_id, status }) =>
      account_id === accountId && status === NOTIFICATION_STATUS.UNREAD
  );
};

/**
 * Gets tracking properties for a given notification group.
 * @param {Object} state - Redux store state.
 * @param {String} notificationId - notification group id.
 * @returns {Object} - tracking properties for a given notification group.
 */
export const trackingPropertiesForNotificationSelector = (
  state,
  { notificationId }
) => {
  const {
    id,
    link_to,
    notification_ids,
    notification_type,
  } = notificationEntitySelector(state, {
    notificationId,
  });

  return {
    notification_asset_id: link_to?.asset_id,
    notification_comment_id: link_to?.comment_id,
    notification_id: id,
    notification_ids,
    notification_presentation_id: link_to?.presentation_id,
    notification_project_id: link_to?.project_id,
    notification_reason: notification_type,
    notification_review_link_id: link_to?.review_link_id,
    notification_team_id: link_to?.team_id,
  };
};

/**
 * Gets the most recent notification id from all notification groups in Redux.
 * @param {Object} state - Redux store state.
 * @param {String} accountId - account id.
 * @returns {Number} - most recent notification id.
 */
export const mostRecentNotificationIdSelector = (state, { accountId }) => {
  const notifications = getNotificationsForAccountSelector(state, {
    accountId,
  });

  // `GET /notifications` endpoint will return the groups sorted in descending
  // order of the most recent notification per group.
  return notifications[0]?.most_recent_notification_id;
};
