import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { noop } from 'lodash';
import { Tooltip } from '@frameio/vapor';

const ORANGE = '#E7924B';

const DivAsButton = styled.div.attrs({
  role: 'button',
  tabIndex: 0,
})`
  outline: none;
  cursor: pointer;
  color: ${(p) => p.theme.color.slateGray};
  display: inline-flex;
  padding: 2px ${(p) => p.theme.spacing.units(1)};
  transition: 150ms color ease;
  width: ${(p) => p.theme.spacing.units(4)};

  ${(p) => p.isSelected && `color: ${p.theme.color.brand};`};

  ${(p) => p.isActive && `color: ${ORANGE};`};

  ${(p) => p.isFocused && `color: ${p.theme.color.white};`};

  &:only-child {
    padding: 2px ${(p) => p.theme.spacing.tiny};
  }

  &:hover,
  &:focus {
    // focus styles will be resolved in CORE-2369
    color: ${(p) => p.theme.color.white};

    ${(p) => p.isSelected && `color: ${p.theme.color.brand};`};

    ${(p) => p.isActive && `color: ${ORANGE};`};
  }
`;

export const Button = React.forwardRef(({ onClick, ...other }, ref) => {
  // emulate button keypress behavior
  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick(e);
      }
    },
    [onClick]
  );

  return (
    <DivAsButton
      onKeyPress={handleKeyPress}
      onClick={onClick}
      ref={ref}
      {...other}
    />
  );
});

const PlayerBarButton = ({
  icon,
  onClick,
  isActive,
  tooltipTitle,
  ...rest
}) => {
  const button = (
    <Button isActive={isActive} onClick={onClick} {...rest}>
      <Flex alignCenter>{icon}</Flex>
    </Button>
  );

  if (tooltipTitle) {
    return (
      <Tooltip
        className="playbar-tooltip"
        delayIn={500}
        title={tooltipTitle}
        placement="top"
        variant="light"
      >
        {button}
      </Tooltip>
    );
  }

  return button;
};

PlayerBarButton.defaultProps = {
  onClick: noop,
};

PlayerBarButton.propTypes = {
  isActive: PropTypes.bool,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  tooltipTitle: PropTypes.string,
};

export default PlayerBarButton;
