import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const getCounterColor = (color, countdown) => {
  if (countdown === 0) {
    return color.error;
  }
  if (countdown < 4) {
    return color.success;
  }

  return color.slateGray;
};

const TrialCounter = styled.span`
  color: ${({ theme, countdown }) => getCounterColor(theme.color, countdown)};
  font-size: ${(p) => p.theme.fontSize[2]};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  margin: ${({ theme }) => `${theme.spacing.tiny} 0`};
  margin-right: ${(p) => p.theme.spacing.small};
  white-space: nowrap;
`;

const TrialRemaining = ({ countdown }) => {
  const isTrialExpired = countdown < 0;

  return (
    <TrialCounter countdown={countdown}>
      {!isTrialExpired && (
        <span>
          <span>{`${countdown} ${countdown === 1 ? 'day' : 'days'} left`}</span>
        </span>
      )}
    </TrialCounter>
  );
};

TrialRemaining.propTypes = {
  countdown: PropTypes.number.isRequired,
};

export const testExports = {
  TrialCounter,
  TrialRemaining,
};

export default TrialRemaining;
