import { connect } from 'react-redux';
import { userEntitySelector } from '@frameio/core/src/users/selectors';
import { teamEntitySelector } from '@frameio/core/src/teams/selectors';
import { TYPE as USER_SEARCH_TYPE } from 'components/UserSearch/UserSearch';
import UserToken from './UserToken';

function mapStateToProps(state, { id: userOrTeamId, type }) {
  switch (type) {
    case USER_SEARCH_TYPE.USER:
      return { user: userEntitySelector(state, { userId: userOrTeamId }) };
    case USER_SEARCH_TYPE.TEAM: {
      const team = teamEntitySelector(state, { teamId: userOrTeamId });
      return { user: { ...team, email: team.name } };
    }
    default:
      return { user: { email: userOrTeamId } };
  }
}

export const testExports = { mapStateToProps };
export default connect(mapStateToProps)(UserToken);
