import { connect } from 'react-redux';
import { assetEntitySelector } from '@frameio/core/src/assets/selectors';
import { type as assetType } from '@frameio/core/src/assets/helpers/constants';
import { promptRenameAsset } from 'actions/assets';

import RenameAsset from './RenameAsset';

function mapStateToProps(state, { assetId }) {
  const asset = assetEntitySelector(state, { assetId });
  return {
    assetId:
      asset.type === assetType.VERSION_STACK ? asset.cover_asset : assetId,
  };
}

const mapDispatchToProps = {
  promptRenameAsset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenameAsset);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
