import CompareSVG from '@frameio/components/src/svgs/icons/24/compare.svg';
import { MenuButton } from '@frameio/vapor';
import PropTypes from 'prop-types';
import React from 'react';
import { getMediaType } from '@frameio/core/src/assets/helpers/mediaTypes';
import { type as assetTypes } from '@frameio/core/src/assets/helpers/constants';
import { supportedComparisonMediaTypes } from 'components/SplitPlayerContainers/shared/constants';

const CompareVersions = ({ asset, compareVersions, tracking, ...rest }) => {
  const isVersionStack = asset.type === assetTypes.VERSION_STACK;
  const rootAsset = isVersionStack ? asset.cover_asset : asset;
  const mediaType = getMediaType(rootAsset);
  const canCompare = supportedComparisonMediaTypes.includes(mediaType);
  const trackingObject = { ...tracking, media_type: mediaType };

  const payload = { tracking: trackingObject, versionStackId: asset.id };

  const handleCompareVersionsSelect = () => compareVersions(payload);

  const shouldShowCompareVersionsButton = isVersionStack && canCompare;

  return (
    <React.Fragment>
      {shouldShowCompareVersionsButton && (
        <MenuButton
          onSelect={handleCompareVersionsSelect}
          iconBefore={<CompareSVG width={16} height={16} />}
          {...rest}
        >
          Compare versions
        </MenuButton>
      )}
    </React.Fragment>
  );
};

CompareVersions.propTypes = {
  asset: PropTypes.object.isRequired,
  compareVersions: PropTypes.func.isRequired,
};

export default CompareVersions;
