import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

const ADOBE_INTEGRATION_CONNECT_DIALOG = generateActionTypes(
  'ADOBE_INTEGRATION_CONNECT_DIALOG',
  ['CLOSE', 'OPEN', 'SUBMIT']
);

export const openModal = createAction(
  ADOBE_INTEGRATION_CONNECT_DIALOG.OPEN,
  ({ projectId, assetIds, integration }) => ({
    assetIds,
    integration,
    projectId,
  })
);

export const closeModal = createAction(
  ADOBE_INTEGRATION_CONNECT_DIALOG.CLOSE,
  () => ({})
);

export const submit = createAction(
  ADOBE_INTEGRATION_CONNECT_DIALOG.SUBMIT,
  ({ options, assetIds, integration, projectId }) => ({
    options,
    assetIds,
    integration,
    projectId,
  })
);

export default ADOBE_INTEGRATION_CONNECT_DIALOG;
