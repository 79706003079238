// @flow

import React from 'react';
import DynamicColorIcon from '@frameio/components/src/components/DynamicColorIcon';

type ToastIconsProps = {
  type: string,
};

const Success = () => (
  <DynamicColorIcon kind="circled_check" color="#39D7AA" size={24} />
);

const Error = () => (
  <DynamicColorIcon kind="circled_x" color="#F35656" size={24} />
);

const Dropbox = () => (
  <DynamicColorIcon kind="dropbox_logo" color="#007EE5" size={24} />
);

const Slack = () => (
  <DynamicColorIcon kind="slack_logo" color="#FFFFFF" size={24} />
);

const Vimeo = () => (
  <DynamicColorIcon kind="vimeo_logo" color="#00ADEF" size={24} />
);

const Email = () => <DynamicColorIcon kind="email" color="#FFFFFF" size={24} />;

const Disconnected = () => (
  <DynamicColorIcon kind="disconnected" color="#F33263" size={24} />
);

const Connected = () => (
  <DynamicColorIcon kind="connected" color="#1FC7AD" size={24} />
);

const Activity = () => (
  <DynamicColorIcon kind="activity" color="#FFFFFF" size={24} />
);

const types = {
  Success,
  Error,
  Dropbox,
  Slack,
  Vimeo,
  Email,
  Disconnected,
  Connected,
  Activity,
};

export const iconTypes = Object.keys(types).reduce((acc, type) => {
  acc[type] = type;
  return acc;
}, {});

export type ToastIconType = {
  // TODO(setsun) upgrade to @frameio/eslint-config which includes Flow linting
  // eslint-disable-next-line no-undef
  iconType: $Keys<typeof iconTypes>,
};

const ToastIcons = ({ type }: ToastIconsProps) => {
  const ToastIcon = types[type];
  return <ToastIcon />;
};

export default ToastIcons;
