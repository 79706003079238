import adobeIntegrationConnectDialogSagas from 'components/Dialog/AdobeIntegrationConnectDialog/sagas';
import simpleDialog from 'components/Dialog/SimpleDialog/sagas';
import customActionFormDialog from 'components/Dialog/CustomActionFormDialog/sagas';
import unconfirmedEmailLockout from 'components/UnconfirmedEmailLockout/sagas';
import deletedAssetsSagas from 'components/DeletedAssetsMenu/sagas';
import accountContainerSagas from 'pages/AccountContainer/sagas';
import playerContainerSagas from 'pages/PlayerContainer/sagas';
import projectContainerSagas from 'pages/ProjectContainer/sagas';
import presentationEditorSagas from 'components/PresentationEditor/sagas';
import presentationContainerSagas from 'pages/PresentationContainer/sagas';
import splitPlayerContainerSagas from 'pages/SplitPlayerContainer/sagas';
import reviewEditorSagas from 'components/ReviewLinkEditor/sagas';
import reviewLinkContainerSagas from 'pages/ReviewLinkContainer/sagas';
import watermarkTemplateEditorSagas from 'pages/WatermarkTemplateEditor/sagas';
import addTeamMembers from 'components/AddTeamMembers/sagas';
import jumpTo from 'components/JumpTo/sagas';
import publishToVimeoSagas from 'components/PublishToVimeo/sagas';
import requestToJoinTeamCardSagas from 'components/RequestToJoinTeamCard/sagas';
import SlackIntegrationSagas from 'components/SlackIntegration/sagas';
import settingsSagas from 'pages/SettingsContainer/sagas';
import accountSettingsSagas from 'pages/SettingsContainer/AccountSettingsContainer/sagas';
import PaymentSagas from 'pages/SettingsContainer/BillingContainer/AddCreditCard/sagas';
import trialCountdownSagas from 'components/TrialCountdown/sagas';
import manageSubscription from 'components/ManageSubscription/sagas';
import projectForms from 'components/ProjectForms/sagas';
import SelectPlanFlow from 'components/SelectPlanFlow/sagas';
import reactivateSubscription from 'components/ReactivateSubscription/sagas';
import paymentFlow from 'components/PaymentFlow/sagas';
import addStorageFlow from 'components/AddStorageFlow/sagas';
import storageLimitReachedFlow from 'components/StorageLimitReachedFlow/sagas';
import lifetimeFileLimitReachedFlow from 'components/LifetimeFileLimitReachedFlow/sagas';
import addSeatsFlow from 'components/AddSeatsFlow/sagas';
import commentComposer from 'components/CommentComposer/sagas';
import joinRequestsSagas from 'components/JoinRequestsPopover/sagas';
import developmentTools from 'components/DevelopmentTools/sagas';
import archiveProjectSagas from 'components/ProjectActions/ArchiveProject/sagas';
import archiveProjectFlowSagas from 'components/ArchiveProjectFlow/sagas';
import unarchiveProjectSagas from 'components/ProjectActions/UnarchiveProject/sagas';
import unarchiveProjectFlowSagas from 'components/UnarchiveProjectFlow/sagas';
import muxSagas from 'components/mux/sagas';
import authedRouteSagas from 'components/AuthedRoute/sagas';
import setupAccountFlowSagas from 'components/SetupAccountFlow/sagas';
import cancelAccountFlowSagas from 'components/CancelAccountFlow/sagas';
import startAccountSagas from 'components/AccountCTA/SetupAccountCTA/sagas';
import requestAccessToProjectSagas from 'components/RequestAccessToEntity/sagas';
import inboxSagas from 'pages/AccountContainer/InboxContainer/sagas';
import userSearchSagas from 'components/UserSearch/sagas';
import adBlockDetectorSagas from 'components/AdBlockDetector/sagas';
import planUpgradeSagas from 'components/AccountCTA/PlanUpgradeCTA/sagas';
import listTeamGroupSagas from 'components/DashboardSidebar/ListTeamGroup/sagas';
import moveToSagas from 'components/AssetActions/MoveTo/sagas';
import notificationsPopoverSagas from 'components/NotificationsPopover/sagas';
import resolutionPickerSagas from 'components/PlayerControlBar/ResolutionPickerButton/sagas';
import listTeamRowSagas from 'components/DashboardSidebar/ListTeamRow/sagas';
import hardBlockSagas from 'components/HardBlock/sagas';
import updateBillingInfoSagas from 'components/UpdateBillingInfo/sagas';
import workfrontDecisionSagas from 'components/AssetWorkfrontApprovalStatus/WorkfrontApprovalDecisionsDropdown/sagas';
import analyticsSagas from 'components/Analytics/sagas';
import workfrontTaskSagas from 'components/AssetActions/AdobeIntegration/sagas';
import aemIntegrationSagas from 'components/Dialog/AdobeIntegrationConnectDialog/Aem/sagas';
import legalModalSagas from 'components/LegalDialog/sagas';

import assets from './assets';
import client from './client';
import authedUser from './authedUser';
import joinRequests from './joinRequests';
import projects from './projects';
import joinSharedProject from './projects/joinSharedProject';
import joinSharedProjectByInvite from './projects/joinSharedProjectByInvite';
import shared from './shared';
import comments from './comments';
import accounts from './accounts';
import uploads from './uploads';
import roles from './roles';
import folders from './folders';
import teams from './teams';
import customActions from './customActions';
import interactions from './interactions';
import subscriptions from './subscriptions';
import sockets from './sockets';

export default [
  ...accountContainerSagas,
  ...aemIntegrationSagas,
  ...adobeIntegrationConnectDialogSagas,
  ...authedUser,
  ...client,
  ...joinRequests,
  ...assets,
  ...projects,
  ...joinSharedProject,
  ...joinSharedProjectByInvite,
  ...simpleDialog,
  ...customActionFormDialog,
  ...unconfirmedEmailLockout,
  ...shared,
  ...playerContainerSagas,
  ...projectContainerSagas,
  ...comments,
  ...accounts,
  ...uploads,
  ...presentationEditorSagas,
  ...roles,
  ...folders,
  ...teams,
  ...presentationContainerSagas,
  ...reviewEditorSagas,
  ...reviewLinkContainerSagas,
  ...customActions,
  ...interactions,
  ...addTeamMembers,
  ...subscriptions,
  ...publishToVimeoSagas,
  ...requestToJoinTeamCardSagas,
  ...SlackIntegrationSagas,
  ...settingsSagas,
  ...splitPlayerContainerSagas,
  ...accountSettingsSagas,
  ...PaymentSagas,
  ...manageSubscription,
  ...projectForms,
  ...SelectPlanFlow,
  ...reactivateSubscription,
  ...paymentFlow,
  ...addStorageFlow,
  ...storageLimitReachedFlow,
  ...lifetimeFileLimitReachedFlow,
  ...addSeatsFlow,
  ...deletedAssetsSagas,
  ...commentComposer,
  ...joinRequestsSagas,
  ...developmentTools,
  ...trialCountdownSagas,
  ...archiveProjectSagas,
  ...archiveProjectFlowSagas,
  ...unarchiveProjectSagas,
  ...unarchiveProjectFlowSagas,
  ...muxSagas,
  ...authedRouteSagas,
  ...setupAccountFlowSagas,
  ...cancelAccountFlowSagas,
  ...startAccountSagas,
  ...jumpTo,
  ...requestAccessToProjectSagas,
  ...inboxSagas,
  ...userSearchSagas,
  ...adBlockDetectorSagas,
  ...planUpgradeSagas,
  ...listTeamGroupSagas,
  ...moveToSagas,
  ...watermarkTemplateEditorSagas,
  ...notificationsPopoverSagas,
  ...sockets,
  ...resolutionPickerSagas,
  ...listTeamRowSagas,
  ...hardBlockSagas,
  ...updateBillingInfoSagas,
  ...workfrontDecisionSagas,
  ...analyticsSagas,
  ...workfrontTaskSagas,
  ...legalModalSagas,
];
