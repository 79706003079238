import { connect } from 'react-redux';

import { player360Enabled } from 'utils/featureFlags';

import QuicklookVideo from './QuicklookVideo';

function mapStateToProps(state) {
  return {
    isPlayer360Enabled: player360Enabled(state),
  };
}

export default connect(mapStateToProps)(QuicklookVideo);
