import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';
import { isFetchingActionFactory } from 'factories/isFetching';

export const UPDATE_BILLING_INFO = generateActionTypes('UPDATE_BILLING_INFO', [
  'IS_FETCHING',
  'SET_STEP',
  'RESET_FLOW',
  'OPEN_MODAL',
  'UPDATE',
  'ADD_CARD',
]);

export const isFetching = isFetchingActionFactory(
  UPDATE_BILLING_INFO.IS_FETCHING
);

export const setStep = createAction(UPDATE_BILLING_INFO.SET_STEP, (step) => ({
  step,
}));

export const resetFlow = createAction(
  UPDATE_BILLING_INFO.RESET_FLOW,
  () => ({})
);

export const openUpdateBillingInfoModal = createAction(
  UPDATE_BILLING_INFO.OPEN_MODAL,
  () => ({})
);

export const addCreditCard = createAction(
  UPDATE_BILLING_INFO.ADD_CARD,
  (stripeResponse) => ({ stripeResponse })
);
