import { connect } from 'react-redux';
import { closeModal } from './actions';
import ModalRoot from './ModalRoot';

const mapStateToProps = (state) => state.modal;
const mapDispatchToProps = { closeModal };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalRoot);
