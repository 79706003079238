import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';
import { COMMENT } from '@frameio/core/src/comments/actions';

export const MOVE_TO = {
  ...generateActionTypes('MOVE_TO', [
    // intercepted saga actions
    'FETCH',
    'PROMPT',
    'PROMPT_COPY',
    'MOVE_ASSETS',
    'COPY_ASSETS',
    'DUPLICATE_ASSETS',
    'CANCEL_PENDING_REQUESTS',
    'CANCEL',
    // reducer actions
    'SELECT_FOLDER',
    'EXPAND_FOLDER',
    'FETCH_START',
    'FETCH_SUCCESS',
    'FETCH_ERROR',
    'IS_SAVING',
    'RESET',
    'UPDATE_FOLDER_CREATION',
    'UPDATE_SELECTED_TREE_ITEM',
  ]),
};

export const FolderCreationEvents = {
  NONE: 'NONE',
  IS_BEING_NAMED: 'IS_BEING_NAMED',
  IS_SAVING: 'IS_SAVING',
  IS_CREATED: 'IS_CREATED',
  IS_FORCE_FETCHING_UPDATE: 'IS_FORCE_FETCHING_UPDATE',
};

/**
 * Intercepted actions
 */

export const promptMoveToFolder = createAction(
  MOVE_TO.PROMPT,
  (selectedAssets) => ({ selectedAssets })
);

export const promptCopyToFolder = createAction(
  MOVE_TO.PROMPT_COPY,
  (selectedAssets) => ({ selectedAssets })
);

export const moveAssets = createAction(
  MOVE_TO.MOVE_ASSETS,
  (selectedAssets, destinationFolder) => ({ selectedAssets, destinationFolder })
);

export const copyAssets = createAction(
  MOVE_TO.COPY_ASSETS,
  (selectedAssets, destinationFolder, shouldIncludeComments) => ({
    selectedAssets,
    destinationFolder,
    shouldIncludeComments,
  })
);

export const duplicateAssets = createAction(
  MOVE_TO.DUPLICATE_ASSETS,
  (selectedAssets, destinationFolder, shouldIncludeComments) => ({
    selectedAssets,
    destinationFolder,
    shouldIncludeComments,
  })
);

export const cancelPendingRequests = createAction(
  MOVE_TO.CANCEL_PENDING_REQUESTS,
  () => ({})
);

export const cancel = createAction(MOVE_TO.CANCEL, () => ({}));
/*
 * For assets which have private comments. [CORE-2743]
 * On asset move we want to clear all comments from the state,
 * The user who moved the asset will not see comments
 * that are contextually not supposed to be in the next location (project...).
 */
export const clearAllComments = createAction(
  COMMENT.RESET_ENTITIES,
  () => ({})
);

/**
 * Reducer actions
 */

export const isSaving = createAction(MOVE_TO.IS_SAVING, (saving) => ({
  isSaving: saving,
}));

export const fetchTree = createAction(
  MOVE_TO.FETCH,
  (projectId, forceNewFetch) => ({
    projectId,
    forceNewFetch,
  })
);

export const fetchStart = createAction(MOVE_TO.FETCH_START, (projectId) => ({
  projectId,
}));

export const fetchSuccess = createAction(
  MOVE_TO.FETCH_SUCCESS,
  (projectId, tree) => ({ projectId, tree })
);

export const fetchError = createAction(MOVE_TO.FETCH_ERROR, (projectId) => ({
  projectId,
}));

export const reset = createAction(MOVE_TO.RESET, () => ({}));

export const setFolderCreationStatus = createAction(
  MOVE_TO.UPDATE_FOLDER_CREATION,
  (folderStatus) => ({
    folderStatus,
  })
);

export const setUserSelectedId = createAction(
  MOVE_TO.UPDATE_SELECTED_TREE_ITEM,
  (selectedId, canCreateFolder) => ({
    selectedId,
    canCreateFolder,
  })
);
