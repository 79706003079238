import { useState, useEffect, useRef } from 'react';
import { noop } from 'lodash';

/* 
  This hook will soon be moved into web-components
  as a part of the POC work around Player Page Unification Merging
*/
const usePersistance = (item = null, duration = null) => {
  const [persistedItem, setPersistedItem] = useState(item);
  const timeout = useRef(null);
  useEffect(() => {
    if (item && item !== persistedItem) {
      clearTimeout(timeout.current);
      setPersistedItem(item);
      if (duration) {
        timeout.current = setTimeout(() => setPersistedItem(null), duration);
      }
    } else if (item === null) {
      return () => clearTimeout(timeout.current);
    }
    return noop;
  }, [duration, item, persistedItem]);
  return item || persistedItem;
};

export default usePersistance;
