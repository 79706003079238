import React from 'react';
import PropTypes from 'prop-types';
import { partial } from 'lodash';
import { hideVisually, rgba } from 'polished';
import Flex from 'styled-flex-component';
import styled from 'styled-components';

const HiddenInput = styled.input`
  ${hideVisually()}
`;

const Label = (props) => {
  const { checked, children, disabled, name, onChange, value, ...rest } = props;
  const htmlFor = `${name}-${value}`;

  return (
    <React.Fragment>
      <HiddenInput
        aria-hidden="true"
        checked={checked && !disabled}
        id={htmlFor}
        name={name}
        onChange={onChange}
        type="radio"
        value={value}
      />
      <label htmlFor={htmlFor} {...rest}>
        {children}
      </label>
    </React.Fragment>
  );
};

const StyledLabel = styled(Label)`
  align-items: center;
  color: ${(p) =>
    p.disabled
      ? rgba(p.theme.color.graphiteGray, 0.5)
      : p.theme.color.graphiteGray};
  cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
  display: flex;
  flex-grow: 1;
  height: ${(p) => p.theme.spacing.units(4)};
  justify-content: center;
  padding: 0 ${(p) => p.theme.spacing.micro};
  pointer-events: ${(p) => p.disabled && 'none'};
  width: ${(p) => p.theme.spacing.units(4)};
  background-color: ${(p) => p.disabled && p.theme.color.silver};
  border: 1px solid ${(p) => p.theme.color.silver};
  border-right-width: 0px;

  &:first-of-type {
    border-radius: ${(p) => p.theme.spacing.micro} 0 0
      ${(p) => p.theme.spacing.micro};
  }

  &:last-of-type {
    border-right-width: 1px;
    border-radius: 0 ${(p) => p.theme.spacing.micro}
      ${(p) => p.theme.spacing.micro} 0;
  }

  /* HACK: transition and background-color copied from https://github.com/Frameio/web-components/blob/716e669ce5bdd6b0aca2d0aec1221f95d3042a14/src/styled-components/Button/Button.js#L52 */
  transition: background-color 0.2s ease, color 0.2s ease, box-shadow 0.2s ease,
    opacity 0.2ms ease;
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  input:checked + & {
    background-color: ${(p) => p.theme.color.brand};
    color: ${(p) => p.theme.color.white};
  }
`;

const SessionWatermarkTemplateButtonSelectInput = (props) => {
  const { disabled, options, onChange, value } = props;
  const renderOption = React.useCallback(
    (option) => (
      <StyledLabel
        key={option.value}
        checked={option.value === value}
        disabled={disabled}
        name={`${option.value}`}
        onChange={partial(onChange, option.value)}
        value={value}
      >
        {option.text}
      </StyledLabel>
    ),
    [disabled, onChange, value]
  );

  return <Flex full>{options.map(renderOption)}</Flex>;
};

SessionWatermarkTemplateButtonSelectInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.element,
      value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

SessionWatermarkTemplateButtonSelectInput.defaultProps = {
  options: [],
};

export default React.memo(SessionWatermarkTemplateButtonSelectInput);
