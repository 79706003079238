import { takeEvery, call, select, put } from 'redux-saga/effects';
import {
  createPaginatedListSaga,
  takeEveryUntil,
} from '@frameio/core/src/shared/sagas/helpers';
import { searchReviewersForAccount } from '@frameio/core/src/users/sagas';
import {
  searchPendingCollaboratorsAsAdmin,
  removePendingCollaboratorFromAccountAsAdmin,
} from '@frameio/core/src/pendingCollaborators/sagas';
import {
  searchPendingReviewersAsAdmin,
  removePendingReviewerFromAccountAsAdmin,
} from '@frameio/core/src/pendingReviewers/sagas';
import {
  removeCollaboratorFromAccountAsAdmin,
  getCollaboratorsForAccount,
} from '@frameio/core/src/collaborators/sagas';
import { removeReviewerFromAccountAsAdmin } from '@frameio/core/src/reviewers/sagas';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import PEOPLE_SETTINGS from './actions';
import {
  shouldFetchCollaboratorsPageSelector,
  shouldFetchReviewersPageSelector,
  shouldFetchPendingCollaboratorsPageSelector,
  shouldFetchPendingReviewersPageSelector,
} from './selectors';

const searchCollaborators = createPaginatedListSaga(
  PEOPLE_SETTINGS.GET_COLLABORATORS,
  getCollaboratorsForAccount,
  { extractPage: (accountId, query, { page }) => page }
);

const searchReviewers = createPaginatedListSaga(
  PEOPLE_SETTINGS.GET_REVIEWERS,
  searchReviewersForAccount,
  { extractPage: (accountId, query, { page }) => page }
);

const searchPendingCollaborators = createPaginatedListSaga(
  PEOPLE_SETTINGS.GET_PENDING_COLLABORATORS,
  searchPendingCollaboratorsAsAdmin,
  { extractPage: (accountId, query, { page }) => page }
);

const searchPendingReviewers = createPaginatedListSaga(
  PEOPLE_SETTINGS.GET_PENDING_REVIEWERS,
  searchPendingReviewersAsAdmin,
  { extractPage: (accountId, query, { page }) => page }
);

function* getCollaborators(accountId, query, page) {
  const shouldFetch = yield select(shouldFetchCollaboratorsPageSelector, {
    page,
  });
  if (!shouldFetch) return;

  yield call(searchCollaborators, accountId, query, {
    pageSize: 20,
    page,
  });
}

function* getReviewers(accountId, query, page) {
  const shouldFetch = yield select(shouldFetchReviewersPageSelector, { page });
  if (!shouldFetch) return;

  yield call(searchReviewers, accountId, query, {
    pageSize: 20,
    role: 'reviewer',
    excludeRoles: ['team_member', 'collaborator'],
    page,
  });
}

function* getPendingCollaborators(accountId, query, page) {
  const shouldFetch = yield select(
    shouldFetchPendingCollaboratorsPageSelector,
    { page }
  );
  if (!shouldFetch) return;

  yield call(searchPendingCollaborators, accountId, query, {
    pageSize: 20,
    page,
  });
}

function* getPendingReviewers(accountId, query, page) {
  const shouldFetch = yield select(shouldFetchPendingReviewersPageSelector, {
    page,
  });
  if (!shouldFetch) return;

  yield call(searchPendingReviewers, accountId, query, {
    pageSize: 20,
    page,
  });
}

function* showStatusToast({ success, failure }, name) {
  if (success) {
    yield put(
      showSuccessToast({
        header: `Successfully removed ${name} from this account`,
      })
    );
  }
  if (failure) {
    yield put(
      showErrorToast({
        header: `There was an error removing ${name} from this account`,
      })
    );
  }
}

function* removeCollaborator(accountId, email, name) {
  const resp = yield call(
    removeCollaboratorFromAccountAsAdmin,
    accountId,
    email
  );
  yield call(showStatusToast, resp, name);
}

function* removeReviewer(accountId, userId, name) {
  const resp = yield call(removeReviewerFromAccountAsAdmin, accountId, userId);
  yield call(showStatusToast, resp, name);
}

function* removePendingCollaborator(accountId, email) {
  const resp = yield call(
    removePendingCollaboratorFromAccountAsAdmin,
    accountId,
    email
  );
  yield call(showStatusToast, resp, email);
}

function* removePendingReviewer(accountId, email) {
  const resp = yield call(
    removePendingReviewerFromAccountAsAdmin,
    accountId,
    email
  );
  yield call(showStatusToast, resp, email);
}

function didQueryChange(
  { payload: { query: lastQuery } },
  { payload: { query } }
) {
  return query !== lastQuery;
}

export default [
  takeEveryUntil(
    PEOPLE_SETTINGS.GET_COLLABORATORS.BASE,
    ({ payload: { accountId, query, page } }) =>
      getCollaborators(accountId, query, page),
    didQueryChange
  ),
  takeEveryUntil(
    PEOPLE_SETTINGS.GET_REVIEWERS.BASE,
    ({ payload: { accountId, query, page } }) =>
      getReviewers(accountId, query, page),
    didQueryChange
  ),
  takeEveryUntil(
    PEOPLE_SETTINGS.GET_PENDING_COLLABORATORS.BASE,
    ({ payload: { accountId, query, page } }) =>
      getPendingCollaborators(accountId, query, page),
    didQueryChange
  ),
  takeEveryUntil(
    PEOPLE_SETTINGS.GET_PENDING_REVIEWERS.BASE,
    ({ payload: { accountId, query, page } }) =>
      getPendingReviewers(accountId, query, page),
    didQueryChange
  ),
  takeEvery(
    PEOPLE_SETTINGS.REMOVE_COLLABORATOR,
    ({ payload: { accountId, email, name } }) =>
      removeCollaborator(accountId, email, name)
  ),
  takeEvery(
    PEOPLE_SETTINGS.REMOVE_REVIEWER,
    ({ payload: { accountId, userId, name } }) =>
      removeReviewer(accountId, userId, name)
  ),
  takeEvery(
    PEOPLE_SETTINGS.REMOVE_PENDING_COLLABORATOR,
    ({ payload: { accountId, email } }) =>
      removePendingCollaborator(accountId, email)
  ),
  takeEvery(
    PEOPLE_SETTINGS.REMOVE_PENDING_REVIEWER,
    ({ payload: { accountId, email } }) =>
      removePendingReviewer(accountId, email)
  ),
];

export const testExports = {
  searchCollaborators,
  searchReviewers,
  searchPendingCollaborators,
  searchPendingReviewers,
  getCollaborators,
  getReviewers,
  getPendingCollaborators,
  getPendingReviewers,
  removeCollaborator,
  removeReviewer,
  removePendingCollaborator,
  removePendingReviewer,
  didQueryChange,
};
