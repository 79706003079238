import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { MEDIUM_UP } from 'utils/mediaQueries';

import CloseIcon from '@frameio/components/src/svgs/raw/ic-universal-close-12px.svg';
import Button from '@frameio/components/src/styled-components/Button';
import ModalHeader from '@frameio/components/src/styled-components/Modal/ModalHeader';
import IconCircle, {
  iconCircleColors,
} from '@frameio/components/src/styled-components/IconCircle';
import { iconSizes } from '@frameio/components/src/utils/sizes';
import { getPublicPresentationUrl } from 'URLs';
import LinkSharingContent from './LinkSharingContent';

const PRESENTATION_HEADER_HEIGHT = '70px';

const Wrapper = styled.header`
  align-items: center;
  background-color: ${(p) => p.theme.color.white};
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  height: ${PRESENTATION_HEADER_HEIGHT};
  padding: 0 ${(p) => p.theme.spacing.medium};
  position: relative; // needed for shadow to show
  z-index: 1;
  flex-shrink: 0; // fixes collapsed height in firefox
  ${ModalHeader} {
    // resolves icons shaking up & down caused by an issue with flex adjusting on an odd px value
    transform: translateZ(0);
    flex: 1;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  path {
    fill: ${(p) => p.theme.color.gray};
  }
  display: block;
  height: ${iconSizes.M}px;
  width: ${iconSizes.M}px;
`;

const PresentationEditorHeader = ({
  className,
  enabled,
  onCloseButtonClick,
  onLinkCopy,
  onSettingsChange,
  shortUrl,
  vanityId,
}) => (
  <Wrapper className={className}>
    <Media query={MEDIUM_UP}>
      <ModalHeader
        icon={
          <IconCircle icon="presentation" color={iconCircleColors.PURPLE} />
        }
      >
        Presentation
      </ModalHeader>
    </Media>
    <LinkSharingContent
      enabled={enabled}
      onSettingsChange={onSettingsChange}
      onSharingLinkClick={onLinkCopy}
      sharingLinkURL={getPublicPresentationUrl(vanityId, shortUrl)}
    />

    <Button icon="true" text onClick={onCloseButtonClick}>
      <StyledCloseIcon />
    </Button>
  </Wrapper>
);

PresentationEditorHeader.defaultProps = {
  className: '',
  shortUrl: undefined,
};

PresentationEditorHeader.propTypes = {
  className: PropTypes.string,
  enabled: PropTypes.bool.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
  onLinkCopy: PropTypes.func.isRequired,
  onSettingsChange: PropTypes.func.isRequired,
  shortUrl: PropTypes.string,
  vanityId: PropTypes.string.isRequired,
};

export default PresentationEditorHeader;

export const testExports = {
  LinkSharingContent,
  ModalHeader,
  StyledCloseIcon,
};
