import { connect } from 'react-redux';
import {
  currentAccountSelector,
  isAccountAdobeBridgeOfferEligible,
} from 'selectors/accounts';

import { closeModal } from 'components/Modal/actions';
import { confirmCancellation } from '../actions';
import Confirmation from './Confirmation';

const mapStateToProps = (state) => {
  const accountId = currentAccountSelector(state).id;
  const isAdobeBridgeOfferEligible = isAccountAdobeBridgeOfferEligible(state, {
    accountId,
  });
  return {
    accountId,
    isAdobeBridgeOfferEligible,
  };
};
const mapDispatchToProps = {
  closeModal,
  confirmCancellation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirmation);
