import { connect } from 'react-redux';
import AddCreditCard from './AddCreditCard';
import { errorMessageSelector } from '../../selectors';

function mapStateToProps(state) {
  return {
    errorMessage: errorMessageSelector(state),
  };
}

export default connect(mapStateToProps)(AddCreditCard);
