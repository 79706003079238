import React from 'react';
import { call, select, spawn, put, take, takeLatest } from 'redux-saga/effects';
import { get, noop, partialRight } from 'lodash';
import track from 'analytics';
import { openModal, closeModal, MODAL } from 'components/Modal/actions';
import {
  getSessionWatermarkTemplate,
  createSessionWatermarkTemplate as createSessionWatermarkTemplateCoreSaga,
  createSessionWatermarkTemplateForReviewLink as createSessionWatermarkTemplateForReviewLinkCoreSaga,
  createSessionWatermarkTemplateForPresentation as createSessionWatermarkTemplateForPresentationCoreSaga,
  deleteSessionWatermarkTemplate as deleteSessionWatermarkTemplateCoreSaga,
  updateSessionWatermarkTemplate as updateSessionWatermarkTemplateCoreSaga,
  updateSessionWatermarkTemplateForReviewLink as updateSessionWatermarkTemplateForReviewLinkCoreSaga,
  updateSessionWatermarkTemplateForPresentation as updateSessionWatermarkTemplateForPresentationCoreSaga,
  deleteSessionWatermarkTemplateForReviewLink as deleteSessionWatermarkTemplateForReviewLinkCoreSaga,
  deleteSessionWatermarkTemplateForPresentation as deleteSessionWatermarkTemplateForPresentationCoreSaga,
} from '@frameio/core/src/sessionWatermarkTemplate/sagas';
import { sessionWatermarkTemplateEntitySelector } from '@frameio/core/src/sessionWatermarkTemplate/selectors';
import { currentAccountSelector } from 'selectors/accounts';
import { isAccountAdminSelector } from 'selectors/roles';
import { createDelegatedSaga } from '@frameio/core/src/shared/sagas/helpers';
import { canSeeDevToolsSelector } from 'components/DevelopmentTools/OpenDevTools/selectors';
import { confirmDelete, confirm } from 'components/Dialog/SimpleDialog/sagas';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import { MEDIUM_DOWN } from 'utils/mediaQueries';
import { updatePresentation } from 'components/PresentationEditor/actions';
import { updateReviewLinkSettings } from 'components/ReviewLinkEditor/actions';
import { reviewLinkEntitySelector } from '@frameio/core/src/reviewLinks/selectors';
import { presentationEntitySelector } from '@frameio/core/src/presentations/selectors';
import { MODAL_MAX_WIDTH, MODAL_HEIGHT } from './constants';
import SessionWatermarkTemplateEditor from './SessionWatermarkTemplateEditor';
import SESSION_WATERMARK_TEMPLATE_EDITOR, { onSubmitSuccess } from './actions';

export function* openSessionWatermarkTemplateEditor({
  payload: {
    initialValues = {},
    onClose = noop,
    sourceName,
    watermarkTemplateId,
    isShareOnlyTemplate,
  },
}) {
  const isSmallScreen = window.matchMedia
    ? window.matchMedia(MEDIUM_DOWN).matches
    : false;

  const modalComponentStyleOverrides = isSmallScreen
    ? {
        margin: 0,
        width: '100%',
        height: '100%',
        overflow: 'visible',
        maxHeight: 'none',
        borderRadius: 0,
      }
    : {
        margin: 0,
        height: MODAL_HEIGHT,
        maxWidth: MODAL_MAX_WIDTH,
        maxHeight: '96vh',
        overflow: 'visible',
      };

  const isDevToolsAllowed = yield select(canSeeDevToolsSelector);
  const modalProps = {
    canBackdropClose: false,
    style: modalComponentStyleOverrides,
    enableFocusLock: false,
  };

  const { id: accountId } = yield select(currentAccountSelector);
  const isAdmin = yield select(isAccountAdminSelector, { accountId });

  yield put(
    openModal(
      <SessionWatermarkTemplateEditor
        initialValues={initialValues}
        isDevToolsAllowed={isDevToolsAllowed}
        watermarkTemplateId={watermarkTemplateId}
        isShareOnlyTemplate={isShareOnlyTemplate}
        isAdmin={isAdmin}
      />,
      modalProps
    )
  );

  yield spawn(track, 'session-watermark-template-editor-opened', {
    source: sourceName,
    watermark_template_id: watermarkTemplateId,
  });

  // TODO [ENT-2246]: This should be refactored to only take one action that wraps MODAL.CLOSE.
  // CREATE.SUCCESS and UPDATE.SUCCESS have nothing themselves to do with the editor closing.
  // This works right now because we dispatch the MODAL.CLOSE in onSessionWatermarkTemplateSuccess
  // but that's brittle. Instead, we could create an `exitSessionWatermarkTemplateEditor` saga
  // that returns an optional `response` argument and dispatches MODAL.CLOSE. That will also
  // address the unreliability of `take`ing MODAL.CLOSE (for more on that see
  // https://github.com/Frameio/web-client/pull/8998).
  const didModalClose = yield take([
    SESSION_WATERMARK_TEMPLATE_EDITOR.SUBMIT.SUCCESS,
    MODAL.CLOSE,
  ]);

  if (didModalClose) {
    yield call(onClose, get(didModalClose, 'payload.result'));
  }
}

function* handleUnsavedChangesDialogResponse({
  payload: { header, body, options },
}) {
  if (yield call(confirm, header, body, options)) {
    yield spawn(track, 'session-watermark-template-editor-closed', {
      trigger: 'abandoned',
    });
    yield put(closeModal());
  }
}

function* deleteSessionWatermarkTemplate({ payload: { watermarkTemplateId } }) {
  const { id: accountId } = yield select(currentAccountSelector);
  const { name: templateName } = yield select(
    sessionWatermarkTemplateEntitySelector,
    watermarkTemplateId
  );

  const response = yield call(
    confirmDelete,
    `Are you sure you want to delete ${templateName}?`,
    `This template will be removed from your account's list of templates. Watermarks that were previously applied using this template will remain unchanged.`
  );

  if (!response) {
    return;
  }

  const { success } = yield call(
    deleteSessionWatermarkTemplateCoreSaga,
    accountId,
    watermarkTemplateId
  );

  if (success) {
    yield put(showSuccessToast({ header: 'Template deleted successfully' }));
  } else {
    yield put(
      showErrorToast({ header: 'There was a problem deleting your template' })
    );
  }
}

const fetchWatermarkTemplateById = createDelegatedSaga(
  SESSION_WATERMARK_TEMPLATE_EDITOR.GET,
  ({ payload: { accountId, watermarkTemplateId } }) =>
    getSessionWatermarkTemplate(accountId, watermarkTemplateId)
);

const createSessionWatermarkTemplate = createDelegatedSaga(
  SESSION_WATERMARK_TEMPLATE_EDITOR.CREATE,
  createSessionWatermarkTemplateCoreSaga
);
const updateSessionWatermarkTemplate = createDelegatedSaga(
  SESSION_WATERMARK_TEMPLATE_EDITOR.UPDATE,
  updateSessionWatermarkTemplateCoreSaga
);
const createSessionWatermarkTemplateForReviewLink = createDelegatedSaga(
  SESSION_WATERMARK_TEMPLATE_EDITOR.CREATE_FOR_REVIEW_LINK,
  createSessionWatermarkTemplateForReviewLinkCoreSaga
);
const createSessionWatermarkTemplateForPresentation = createDelegatedSaga(
  SESSION_WATERMARK_TEMPLATE_EDITOR.CREATE_FOR_PRESENTATION,
  createSessionWatermarkTemplateForPresentationCoreSaga
);
const updateSessionWatermarkTemplateForReviewLink = createDelegatedSaga(
  SESSION_WATERMARK_TEMPLATE_EDITOR.UPDATE_FOR_REVIEW_LINK,
  updateSessionWatermarkTemplateForReviewLinkCoreSaga
);
const updateSessionWatermarkTemplateForPresentation = createDelegatedSaga(
  SESSION_WATERMARK_TEMPLATE_EDITOR.UPDATE_FOR_PRESENTATION,
  updateSessionWatermarkTemplateForPresentationCoreSaga
);
const revertSessionWatermarkTemplateForReviewLink = createDelegatedSaga(
  SESSION_WATERMARK_TEMPLATE_EDITOR.REVERT_FOR_REVIEW_LINK,
  ({ payload: { reviewLinkId, watermarkTemplateId } }) =>
    deleteSessionWatermarkTemplateForReviewLinkCoreSaga(reviewLinkId, {
      session_watermark_template_id: watermarkTemplateId,
    })
);
const revertSessionWatermarkTemplateForPresentation = createDelegatedSaga(
  SESSION_WATERMARK_TEMPLATE_EDITOR.REVERT_FOR_PRESENTATION,
  ({ payload: { presentationId, watermarkTemplateId } }) =>
    deleteSessionWatermarkTemplateForPresentationCoreSaga(presentationId, {
      session_watermark_template_id: watermarkTemplateId,
    })
);

function* handleRevertSuccessResponse({
  payload: {
    response: { result },
  },
}) {
  const {
    id: sessionWatermarkId,
    original_session_watermark_template_id: originalSessionWatermarkId,
    review_link_id: reviewLinkId,
    presentation_id: presentationId,
  } = yield select(sessionWatermarkTemplateEntitySelector, result);

  let sharedEntityId;
  let entitySelector;
  let updateAction;

  if (reviewLinkId) {
    sharedEntityId = reviewLinkId;
    entitySelector = partialRight(reviewLinkEntitySelector, { reviewLinkId });
    updateAction = updateReviewLinkSettings;
  } else if (presentationId) {
    sharedEntityId = presentationId;
    entitySelector = partialRight(presentationEntitySelector, {
      presentationId,
    });
    updateAction = updatePresentation;
  }

  const shareEntity = yield select(entitySelector);
  const wasActiveWatermarkTemplate =
    shareEntity.session_watermark_id === sessionWatermarkId;

  if (wasActiveWatermarkTemplate) {
    yield put(
      updateAction(sharedEntityId, {
        session_watermark_id: originalSessionWatermarkId,
      })
    );
  }
}

// NOTE: the onSubmit saga asynchronously returns the success or failure results
// returned by create/update calls to the API. onSuccess or onError could be any
// function, though they will most likely be Promise.resolve and Promise.reject.
// For more info, see: https://github.com/Frameio/web-client/pull/9090
function* onSubmit({
  payload: { accountId, params, watermarkTemplateId, onSuccess, onError },
}) {
  // we are creating a new template if either no watermarkTemplateId is provided, *or*
  // if the values submitted contain a review_link_id or presentation_id but no value for
  // original_session_watermark_template_id
  const forReviewLink = Boolean(params.review_link_id);
  const forPresentation = Boolean(params.presentation_id);
  const willUpdate =
    Boolean(params.original_session_watermark_template_id) ||
    (watermarkTemplateId && !forReviewLink && !forPresentation);
  const isNewTemplate = !willUpdate;

  const isNewSessionWatermarkTemplateForReviewLink =
    isNewTemplate && forReviewLink;
  const isNewSessionWatermarkTemplateForPresentation =
    isNewTemplate && forPresentation;
  const isNewSessionWatermarkTemplate =
    isNewTemplate && !forReviewLink && !forPresentation;

  const isUpdatingSessionWatermarkTemplateForReviewLink =
    willUpdate && forReviewLink;
  const isUpdatingSessionWatermarkTemplateForPresentation =
    willUpdate && forPresentation;
  const isUpdatingAccountSessionWatermarkTemplate =
    willUpdate && !forReviewLink && !forPresentation;

  let result;

  if (isNewSessionWatermarkTemplate) {
    result = yield call(createSessionWatermarkTemplate, accountId, params);
  } else if (isUpdatingAccountSessionWatermarkTemplate) {
    result = yield call(
      updateSessionWatermarkTemplate,
      accountId,
      watermarkTemplateId,
      params
    );
  } else if (isNewSessionWatermarkTemplateForReviewLink) {
    result = yield call(
      createSessionWatermarkTemplateForReviewLink,
      params.review_link_id,
      { ...params, original_session_watermark_template_id: watermarkTemplateId }
    );
  } else if (isNewSessionWatermarkTemplateForPresentation) {
    result = yield call(
      createSessionWatermarkTemplateForPresentation,
      params.presentation_id,
      { ...params, original_session_watermark_template_id: watermarkTemplateId }
    );
  } else if (isUpdatingSessionWatermarkTemplateForReviewLink) {
    result = yield call(
      updateSessionWatermarkTemplateForReviewLink,
      params.review_link_id,
      { ...params, session_watermark_template_id: watermarkTemplateId }
    );
  } else if (isUpdatingSessionWatermarkTemplateForPresentation) {
    result = yield call(
      updateSessionWatermarkTemplateForPresentation,
      params.presentation_id,
      { ...params, session_watermark_template_id: watermarkTemplateId }
    );
  }

  const { success, failure } = result;

  if (failure) {
    onError(failure.payload.error.response.data);
  } else {
    yield put(onSubmitSuccess(success.payload.response.result));
    onSuccess(success.payload);
  }
}

export default [
  takeLatest(
    SESSION_WATERMARK_TEMPLATE_EDITOR.OPEN,
    openSessionWatermarkTemplateEditor
  ),
  takeLatest(
    SESSION_WATERMARK_TEMPLATE_EDITOR.GET.BASE,
    fetchWatermarkTemplateById
  ),
  takeLatest(SESSION_WATERMARK_TEMPLATE_EDITOR.SUBMIT.BASE, onSubmit),
  takeLatest(
    SESSION_WATERMARK_TEMPLATE_EDITOR.DELETE.BASE,
    deleteSessionWatermarkTemplate
  ),
  takeLatest(
    SESSION_WATERMARK_TEMPLATE_EDITOR.CONFIRM_CLOSE,
    handleUnsavedChangesDialogResponse
  ),
  takeLatest(
    SESSION_WATERMARK_TEMPLATE_EDITOR.REVERT_FOR_REVIEW_LINK.BASE,
    revertSessionWatermarkTemplateForReviewLink
  ),
  takeLatest(
    SESSION_WATERMARK_TEMPLATE_EDITOR.REVERT_FOR_PRESENTATION.BASE,
    revertSessionWatermarkTemplateForPresentation
  ),
  takeLatest(
    [
      SESSION_WATERMARK_TEMPLATE_EDITOR.REVERT_FOR_REVIEW_LINK.SUCCESS,
      SESSION_WATERMARK_TEMPLATE_EDITOR.REVERT_FOR_PRESENTATION.SUCCESS,
    ],
    handleRevertSuccessResponse
  ),
];

export const testExports = {
  deleteSessionWatermarkTemplate,
  openSessionWatermarkTemplateEditor,
};
