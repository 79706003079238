import { slateGray } from '@frameio/components/src/theme/colors';

const getBorderStyle = ({ error, focused, theme }) => {
  if (focused) {
    return `border: 1px solid ${theme.color.brand}`;
  }
  if (error) {
    return `border: 1px solid ${theme.color.error}`;
  }
  return `border: 1px solid ${theme.color.slateGray}`;
};

export const stripeElementStyle = (theme, error, focused) => `
  border-radius: ${theme.radius.large};
  padding: ${theme.spacing.small};
  padding-left: ${theme.spacing.large};
  font-size: 14px;
  color: ${theme.color.dimGray};
  ${getBorderStyle({ error, focused, theme })}
  &.StripeElement--focus {
    border: 1px solid ${theme.color.brand};
  }
  &.StripeElement--invalid {
    border: 1px solid ${theme.color.error};
  }
`;

export const BASE_STYLES = {
  style: {
    base: {
      fontFamily: 'Avenir Next',
      '::placeholder': {
        color: slateGray,
      },
    },
  },
};
