import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { takeLatest, put } from 'redux-saga/effects';
import InfoIcon from '@frameio/components/src/svgs/icons/24/info-filled.svg';
import { showErrorToast } from 'actions/toasts';
import { ADBLOCK_SUPPORT_URL } from 'URLs';
import { ADBLOCK_DETECTOR } from './actions';

const ADBLOCK_SUPPORT_LINK = ADBLOCK_SUPPORT_URL;

const WhiteInfoIcon = styled(InfoIcon).attrs({
  color: ({ theme }) => theme.color.white,
})`
  // center <InfoIcon /> inside of <Toast />.
  position: relative;
  top: 2px;
`;

const Link = styled.a`
  margin-left: ${(p) => p.theme.spacing.micro};
  color: ${(p) => p.theme.color.white};
`;

function ToastHeader() {
  return (
    <Flex alignCenter>
      <span>Frame.io works best when ad blockers are turned off.</span>
      <Link
        href={ADBLOCK_SUPPORT_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        <WhiteInfoIcon />
      </Link>
    </Flex>
  );
}

export function* showAdBlockDisclaimer() {
  yield put(
    showErrorToast({
      header: <ToastHeader />,
      autoCloseDelay: null,
    })
  );
}

export default [
  takeLatest(ADBLOCK_DETECTOR.SHOW_DISCLAIMER, showAdBlockDisclaimer),
];

export const testExports = {
  showAdBlockDisclaimer,
};
