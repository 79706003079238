/* eslint-disable import/prefer-default-export */

/**
 * Obfuscate an email address
 * @param {string} email - The email address to obfuscate
 * @returns {string} The obfuscated email address
 */
export const obfuscateEmail = (email) => {
  const [name, domain] = email.split('@');
  const firstThreeLetters = name.substring(0, 3);
  const lastLetter = name.length > 3 ? name.slice(-1) : '';

  return `${firstThreeLetters}****${lastLetter}@${domain}`;
};
