import { connect } from 'react-redux';
import PeopleList from 'components/PeopleList';

import { searchCollaboratorsInProject } from 'pages/ProjectContainer/ManagePeople/actions';
import { listReviewers } from './actions';
import { invitedEntitiesForItemIdSelector } from './selectors';

function mapStateToProps(state) {
  const invitedEntities = invitedEntitiesForItemIdSelector(state);

  return {
    // personIds requires an array, so if undefined return an empty array.
    personIds: invitedEntities || [],
    // the undefined value is useful to identify when the content has NOT been loaded.
    // we thus do not show the count until we get a value even if it means an empty array.
    totalCount:
      invitedEntities !== undefined ? invitedEntities.length : undefined,
  };
}

function mapDispatchToProps(dispatch, { projectId }) {
  return {
    filterPeople: (query) =>
      dispatch(searchCollaboratorsInProject(projectId, query)),
    fetchPeople: () => dispatch(listReviewers()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeopleList);
