import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import ReasonSelector from './ReasonSelector';
import { selectReason } from '../actions';

const mapDispatchToProps = {
  selectReason,
  closeModal,
};

export default connect(
  null,
  mapDispatchToProps
)(ReasonSelector);
