import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const RESOLUTION_PICKER = generateActionTypes('RESOLUTION_PICKER', [
  'UPGRADE_PLAN',
]);

export const upgradePlan = createAction(
  RESOLUTION_PICKER.UPGRADE_PLAN,
  (onSuccesfulPlanUpgradeCallback) => ({ onSuccesfulPlanUpgradeCallback })
);
