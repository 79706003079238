import { isProduction, isStaging, isEvaluation } from 'config';

const developmentProjects = [
  {
    id: '3eb5e588-9816-44a6-b70f-16e485f74297',
    name: "Demo project - Road's End Film",
  },
  {
    id: 'f8200634-97c5-49b1-b8a3-a0f54749f3df',
    name: 'Test assets project (dev)',
  },
];

const stagingProjects = [
  {
    id: '2b2924c2-f8a8-47db-a8b8-5d99686968d7',
    name: "Demo project - Road's End Film",
  },
];

const evaluationProjects = [
  {
    id: '2b2924c2-f8a8-47db-a8b8-5d99686968d7',
    name: "Demo project - Road's End Film",
  },
];

const productionProjects = [
  {
    id: '11b98a4f-f2b0-48d0-8dd2-2140adcf4505',
    name: "Demo project - Road's End Film",
  },
  {
    id: '356a9832-8bae-4e00-ba57-7bafeb0913bc',
    name: 'HersheyPark Summer Campaign',
  },
  {
    id: '45f9db11-c1cf-44fd-9f8e-2d65ff060094',
    name: 'Dryp 2.0 Campaign',
  },
];

export default () => {
  if (isEvaluation) {
    return evaluationProjects;
  }
  if (isStaging) {
    return stagingProjects;
  }
  if (isProduction) {
    return productionProjects;
  }
  return developmentProjects;
};
