import { combineReducers } from 'redux';
import { SETUP_ACCOUNT_FLOW } from './actions';

const initialState = {
  isUploading: false,
};

function generateUploadReducer(scope) {
  return (state = initialState, action) => {
    switch (action.type) {
      case scope.PENDING: {
        return { ...state, isUploading: true };
      }
      case scope.SUCCESS:
      case scope.FAILURE:
        return { ...state, isUploading: false };
      default:
        return state;
    }
  };
}

function isApplyingPromotion(state = false, action) {
  switch (action.type) {
    case SETUP_ACCOUNT_FLOW.IS_APPLYING_PROMOTION:
      return action.payload.isApplyingPromo;
    default:
      return state;
  }
}

const uploadImageForMe = generateUploadReducer(
  SETUP_ACCOUNT_FLOW.UPLOADING_IMAGE_FOR_ME
);
const uploadTeamImage = generateUploadReducer(
  SETUP_ACCOUNT_FLOW.UPLOADING_TEAM_IMAGE
);

export default combineReducers({
  isApplyingPromotion,
  uploadImageForMe,
  uploadTeamImage,
});
