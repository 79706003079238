import queryString from 'query-string';
import {
  setReqHeader,
  unsetReqHeader,
} from '@frameio/core/src/shared/services/api';
import history from 'browserHistory';

const REVIEW_LINK_ID_HEADER = 'x-review-link-id';
const REVIEW_LINK_PASSWORD_HEADER = 'x-password';

export function setReviewLinkHeaders(reviewLinkId, password) {
  setReqHeader(REVIEW_LINK_ID_HEADER, reviewLinkId);
  if (password) {
    const encodedPassword = encodeURIComponent(password);
    setReqHeader(REVIEW_LINK_PASSWORD_HEADER, encodedPassword);
  }
}

export function unsetReviewLinkHeaders() {
  unsetReqHeader(REVIEW_LINK_ID_HEADER);
  unsetReqHeader(REVIEW_LINK_PASSWORD_HEADER);
}

export function navigateToAsset(reviewLinkId, assetId, versionStackFileId) {
  let url = `/reviews/${reviewLinkId}/${assetId}`;

  if (versionStackFileId) {
    url = `${url}?version=${versionStackFileId}`;
  }

  return history.push(url);
}

export function navigateToReviewLink(reviewLinkId) {
  return history.push(`/reviews/${reviewLinkId}`);
}

export function buildComparePath(reviewLinkId, versionStackId, queryParams) {
  const query = queryString.stringify(queryParams);
  return `/reviews/${reviewLinkId}/${versionStackId}/compare/?${query}`;
}

export function navigateToCompare(reviewLinkId, versionStackId, queryParams) {
  const path = buildComparePath(reviewLinkId, versionStackId, queryParams);
  return history.push(path);
}

export const getVersionId = ({ location }) =>
  queryString.parse(location.search).version;

export const testExports = {
  REVIEW_LINK_ID_HEADER,
  REVIEW_LINK_PASSWORD_HEADER,
};
