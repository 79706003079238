import { v2Instance } from '@frameio/core/src/shared/services/api';

/**
 * Gets aem repositories for user
 *
 * @param {string} projectId - project id.
 * @returns {{Promise<Object>}} - Promise that resolves to the response body.
 */
export function getRepositories(projectId: string) {
  return v2Instance
    .get(`/internal/aem/projects/${projectId}/repositories`)
    .then((resp) => resp.data);
}
/**
 * Gets child folders for the selected aem repository
 *
 * @param {string} projectId - project id.
 * @param {string} aemRepositoryId - aem repository id.
 * @param {string} path - optional param for fetching nested children folders.
 * @returns {{Promise<Array<Object>>}} - Promise that resolves to the response body.
 */
export function getFolders(
  projectId: string,
  aemRepositoryId: string,
  aemPath: string
): Promise<*> {
  return v2Instance
    .post(`/internal/aem/projects/${projectId}/directories/search`, {
      repo_id: aemRepositoryId,
      path: aemPath,
    })
    .then((resp) => resp.data);
}

/**
 * Sends the selected fio assets to the chosen AEM repository and directory
 *
 * @param {String} projectId - id of the current project.
 * @param {Array<string>} assetIds - an array of the selected fio assets to send.
 * @param {String} aemRepositoryId - id of the selected aem repository.
 * @param {String} aemPath - selected folder path of the aem directory within the selected repository.
 * @returns {{Promise<Array<Object>>}} - Promise that resolves to the response body.
 */
export function sendAssets(
  projectId: String,
  assetIds: Array<string>,
  aemRepositoryId: String,
  aemPath: String
): Promise<*> {
  return v2Instance
    .post(`/internal/aem/projects/${projectId}/operations`, {
      operation: {
        operation: 'upload_to_aem',
        aem_directory: {
          repo_id: aemRepositoryId,
          path: aemPath,
        },
        fio_assets: assetIds,
      },
    })
    .then((resp) => resp.data);
}
