/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { flatMap } from 'lodash';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import {
  assetEntitySelector,
  assetEntitiesByAssetIdsSelector,
} from '@frameio/core/src/assets/selectors';
import { currentProjectSelector } from 'selectors/projects';

export const currentFolderSelector = (state) => state.currentFolder;
export const folderAncestorsSelector = (state) => state.folderAncestors;
/**
 * Given a project and folderId, if folderId is undefined, fall back to the project's root asset id.
 * Otherwise, return the folderId.
 * TODO(WK-66): This should really be in pages/ProjectContainer/selectors. Once we remove the
 * dependency on currentFolderOrProjectRootIdSelector we can move it there.
 */
export function folderIdSelector(state, { projectId, folderId }) {
  if (folderId) return folderId;
  const project = projectEntitySelector(state, { projectId }) || {};
  return project.root_asset_id;
}

/**
 * Returns asset id of the folder being displayed in the ProjectContainer.
 * Note how it is different from the currentFolderSelector:
 * 1. When we are in a subfolder, currentFolderSelector and
 * currentFolderOrProjectRootIdSelector will return the same value.
 * 2. When we are in the root folder of a project, currentFolderSelector returns null,
 * but currentFolderOrProjectRootIdSelector will return project.root_asset_id.
 * @param {Object} state -  Redux store state.
 * @param {ProjectContainerProps} ownProps - Props of the component.
 * @returns {?string} Asset id of the folder
 */
export function currentFolderOrProjectRootIdSelector(state) {
  const currentFolder = currentFolderSelector(state) || {};
  const currentProject = currentProjectSelector(state) || {};
  return folderIdSelector(state, {
    projectId: currentProject.id,
    folderId: currentFolder.id,
  });
}

/**
 * Gets the ancestors for a given folder id.
 * @returns {string[]} Folder ancestor ids; NOTE: An empty array is returned
 * for a project root folder id.
 */
export const folderAncestorsForIdSelector = createSelector(
  folderAncestorsSelector,
  (state, { folderId }) => folderId,
  (folderAncestors, folderId) => folderAncestors[folderId]
);

/**
 * Helper function to test if a review link is "active" - active state, not deleted, not expired.
 * @param {Object}  reviewLink - Review link entity.
 * @returns {boolean}
 */
function isActiveReviewLink(reviewLink) {
  const {
    expires_at: expiresAt,
    is_active: isActive,
    deleted_at: deletedAt,
  } = reviewLink;

  const isExpired = Date.parse(expiresAt) <= Date.now();
  const isDeleted = Boolean(deletedAt);
  return isActive && !(isExpired || isDeleted);
}

/**
 * Helper function to return an array of all the active review links an asset has been included in.
 * @param {Object[]} reviewLinks - Array of an asset's review link entities, if any.
 * @returns {Object[]} - Array of active review links entities, if any.
 */
export function getActiveReviewLinks(reviewLinks) {
  return reviewLinks && reviewLinks.length > 0
    ? reviewLinks.filter((rl) => isActiveReviewLink(rl))
    : [];
}

/**
 * Select active review links for a given folder id.
 * @param {Object} state - Redux state.
 * @param {string} folderId - Folder id.
 * @returns {string[]} An array of active review links for the folder's ancestors.
 */
export function activeReviewLinksForFolderIdSelector(state, { folderId }) {
  const asset = assetEntitySelector(state, { assetId: folderId }) || {};
  return getActiveReviewLinks(asset.review_links);
}

/**
 * Select active review links for a given folder id's _ancestors_ (parent and up).
 * @param {Object} state - Redux state.
 * @param {string} folderId - Folder id.
 * @returns {Object[]} An array of active review link entities for the folder's ancestors.
 */

// TODO(meryl): move the filtering action to the Asset serializer in Massdriver. Issue CORE-2506.
export function activeAncestorReviewLinksForFolderIdSelector(
  state,
  { folderId }
) {
  const { parent_id: parentId } =
    assetEntitySelector(state, { assetId: folderId }) || {};

  const ancestorIds = folderAncestorsForIdSelector(state, {
    folderId: parentId,
  });

  if (!ancestorIds) return [];
  const ancestors = assetEntitiesByAssetIdsSelector(state, {
    assetIds: ancestorIds,
  });

  return flatMap(ancestors, (ancestor = {}) =>
    getActiveReviewLinks(ancestor.review_links)
  );
}

/**
 * Returns whether or not active review links exist for a given folder id or its ancestors.
 * @param {string} folderId - Folder id.
 * @returns {boolean}
 */
export const isSharedFolderSelector = createSelector(
  activeAncestorReviewLinksForFolderIdSelector,
  activeReviewLinksForFolderIdSelector,
  (folderAncestorReviewLinks, folderReviewLinks) =>
    folderReviewLinks.concat(folderAncestorReviewLinks).length > 0
);
