/* eslint-disable react/prop-types, react/no-unused-prop-types */
// TODO: Document rest of PropTypes for VimeoShareEditorForm.
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Flex, { FlexItem } from 'styled-flex-component';
import { ellipsis, rgba } from 'polished';
import { withFormik } from 'formik';
import { filter, find } from 'lodash';

import scrollIntoViewIfNeeded from '@frameio/components/src/lib/scrollIntoViewIfNeeded';
import { buttonReset } from '@frameio/components/src/mixins';
import Button from '@frameio/components/src/styled-components/Button';
import Checkbox, {
  CheckCircle,
  CheckIcon,
  Input as CheckInput,
} from '@frameio/components/src/styled-components/Checkbox';
import DropArrowIcon from '@frameio/components/src/svgs/icons/caron.svg';
import Password from '@frameio/components/src/styled-components/Password';
import Select, {
  DefaultOption,
} from '@frameio/components/src/styled-components/Select';
import Switch from '@frameio/components/src/styled-components/Switch';
import TextInput from '@frameio/components/src/styled-components/TextInput';
import { MARGIN as MODAL_MARGIN } from '@frameio/components/src/components/Modal';

import ModalCloseButton from 'components/Modal/ModalCloseButton';
import ShadowContainer from 'components/ShadowContainer';

import { MEDIUM_UP, SMALL } from 'utils/mediaQueries';

const ANIMATION_DURATION = 150;
const CONTENT_RATING = {
  ALL: 'All audiences',
  MATURE: 'Mature audiences',
};

const CONTENT_CATEGORIES = [
  { key: 'language', text: 'Profanity / sexually suggestive content' },
  { key: 'drugs', text: 'Drug / alcohol use' },
  { key: 'violence', text: 'Violence' },
  { key: 'nudity', text: 'Nudity' },
];

const PRIVACY_VIEW = [
  { key: 'anybody', text: 'Anyone can see this video', basic: true },
  { key: 'nobody', text: 'Only I can see this video', basic: true },
  {
    key: 'contacts',
    text: 'Only people I follow can see this video',
    basic: true,
  },
  {
    key: 'users',
    text: 'Only people I choose can see this video',
    basic: true,
  },
  {
    key: 'password',
    text: 'Only people with a password can see this video',
    basic: true,
  },
  {
    key: 'unlisted',
    text: 'Only people with the private link can see this video',
    basic: false,
  },
  { key: 'disable', text: 'Hide this video from Vimeo.com', basic: false },
];

const CHECK_ICON_SIZE = 16;

const EditorForm = styled.form`
  color: ${(p) => p.theme.color.gray};
  position: relative;
  width: ${(p) => p.theme.spacing.units(42)};

  .VimeoEditor_CloseModal {
    // The below styles override to the absolute
    // positioning of the ModalCloseButton.
    top: ${(p) => p.theme.spacing.units(2)};
    right: ${(p) => p.theme.spacing.units(2)};
  }

  @media ${SMALL} {
    // At the mobile breakpoint, ensure that the form a max-height set
    // to allow scrolling of content within the Modal component
    max-height: calc(100vh - 2 * ${MODAL_MARGIN});
    overflow: auto;
  }

  @media ${MEDIUM_UP} {
    width: ${(p) => p.theme.spacing.units(70)};
  }
`;
const ThumbContainer = styled.div`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: ${(p) => p.theme.radius.medium};
  background-image: ${(p) => `url('${p.backgroundImage}')`};
  box-shadow: 0 4px 7px 0 ${(p) => rgba(p.theme.color.black, 0.2)};
  height: ${(p) => p.theme.spacing.units(5)};
  width: ${(p) => p.theme.spacing.units(9)};
`;

const Heading = styled.h2`
  ${(p) => p.theme.fontStyle.headerXS};
  color: ${(p) => p.theme.color.coolBlack};
`;

const AssetName = styled.span`
  ${ellipsis()}
  color: ${(p) => p.theme.color.coolBlack};
`;

const AssetSize = styled.span`
  color: ${(p) => p.theme.color.gray};
`;

const AssetInformation = styled(Flex).attrs(() => ({
  column: true,
  justifyCenter: true,
}))`
  /*
    See https://github.com/philipwalton/flexbugs#flexbug-1:
    By default, flex items won’t shrink below their minimum content size,
    which is why ‘AssetName‘ text grows outside of its flex parent.
    Setting a min-width ensures that ‘AssetName‘ will shrink, proportionally,
    according to its flex-shrink property.
  */
  min-width: 0;

  ${AssetName}, ${AssetSize} {
    ${(p) => p.theme.fontStyle.bodyM};
    line-height: 1.3;
  }

  ${AssetName} {
    margin-bottom: ${(p) => p.theme.spacing.micro};
  }
`;

const AssetContainer = styled(Flex).attrs(() => ({
  justifyStart: true,
}))`
  ${ThumbContainer} {
    margin-right: ${(p) => p.theme.spacing.units(2)};
  }
`;

const Label = styled.label`
  display: block;
`;

const SmallText = styled.span`
  ${(p) => p.theme.fontStyle.bodyS};
  color: ${(p) => p.theme.color.graphiteGray};
  display: inline-block;
`;

const InputContainer = styled.div`
  max-height: ${(p) => (p.isVisible ? `${p.theme.spacing.units(10)}` : '0')};
  overflow: hidden;
  transition: max-height ${ANIMATION_DURATION}ms ease-in-out;
`;

const InputFieldPlaceholder = styled.div`
  height: ${(p) => p.theme.spacing.units(6)};
`;

const StyledTextInput = styled(TextInput)`
  border-radius: ${(p) => p.theme.radius.default};

  &:disabled {
    color: ${(p) => p.theme.color.slateGray};
  }
`;

const Section = styled.section`
  padding: ${(p) => p.theme.spacing.units(2)};

  ${Heading},
  ${Label} {
    margin-bottom: ${(p) => p.theme.spacing.units(2)};
  }

  ${SmallText} {
    margin-bottom: ${(p) => p.theme.spacing.micro};
  }

  @media ${MEDIUM_UP} {
    padding: ${(p) => p.theme.spacing.medium};
  }
`;

const BodySection = styled(Section)`
  padding-top: 0;
  padding-bottom: ${(p) => p.theme.spacing.medium};

  @media ${MEDIUM_UP} {
    overflow: auto;
    height: ${(p) => p.theme.spacing.units(59)};
    padding-bottom: ${(p) => p.theme.spacing.units(13)};
  }
`;

const FooterSection = styled(Section)`
  background-color: ${(p) => p.theme.color.white};
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media ${MEDIUM_UP} {
    // TODO(Anna): This will need to be moved to ShadowContainer, as a new
    // bottom-positioned Shadow element if the container element hasn't yet
    // reached the end of its scrolling distance.
    box-shadow: ${(p) =>
      `0 -7px 14px 0 ${rgba(p.theme.color.graphiteGray, 0.07)}`};
    height: ${(p) => p.theme.spacing.units(10)};
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

const CancelButton = styled(Button)`
  margin-right: ${(p) => `calc(${p.theme.spacing.tiny} * 2)`};
`;

const SettingSwitchRow = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.color.silver};
  padding-bottom: ${(p) => p.theme.spacing.tiny};
`;

const SettingSwitch = styled(FlexItem)`
  margin-left: auto;
  label {
    display: block;
  }
`;

const SettingLabel = styled.label`
  display: flex;
  justify-content: space-between;
  ${(p) => p.theme.fontStyle.body};
  color: ${(p) => p.theme.color.almostBlack};
`;

const Permissions = styled.div`
  padding-bottom: ${(p) => p.theme.spacing.tiny};

  ${SmallText},
  ${SettingSwitchRow} {
    margin-bottom: ${(p) => p.theme.spacing.tiny};
  }
`;

const DropArrow = styled(DropArrowIcon)`
  color: ${(p) => p.theme.color.graphiteGray};
`;

const StyledSelect = styled(Select)`
  padding-bottom: ${(p) =>
    p.smallBottomPadding
      ? `${p.theme.spacing.small}`
      : `${p.theme.spacing.units(2)}`};
`;

const Option = styled(DefaultOption)`
  ${(p) => p.theme.fontStyle.bodyM}
  width: ${(p) => p.theme.spacing.units(35)};
  padding: ${(p) => p.theme.spacing.micro};

  @media ${MEDIUM_UP} {
    width: ${(p) => p.theme.spacing.units(61)};
  }
`;

const SelectedOption = styled.button`
  ${buttonReset()}
  ${(p) => p.theme.fontStyle.bodyM}
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${(p) => p.theme.fontWeight.bold};
  border-radius: ${(p) => p.theme.radius.default};
  border: 1px solid ${(p) => p.theme.color.silver};
  padding: ${(p) => p.theme.spacing.tiny};
  width: 100%;

  // Don't resize drop arrow within flex parent
  ${DropArrow} {
    flex: 0 0 auto;
  }
`;

const ContentCategory = styled.label`
  display: flex;
  align-items: center;

  ${CheckCircle} {
    box-shadow: inset 0 0 0 1px ${(p) => p.theme.color.accentGrayMedium};
    margin-right: ${(p) => p.theme.spacing.tiny};
  }
  ${CheckCircle},
  ${CheckIcon},
  ${CheckInput} {
    width: ${CHECK_ICON_SIZE}px;
    height: ${CHECK_ICON_SIZE}px;
  }

  ${CheckCircle},
  ${CheckIcon} {
    border-radius: ${(p) => p.theme.radius.medium};
  }
`;

const ContentCategories = styled.div`
  ${(p) => p.theme.fontStyle.bodyM}
  border: 1px solid transparent;
  max-height: ${({ isVisible, theme }) =>
    isVisible ? `${theme.spacing.units(20)}` : '0'};
  overflow: hidden;
  padding-left: ${(p) => p.theme.spacing.small};
  transition: max-height ${ANIMATION_DURATION}ms ease-in-out;

  ${SmallText},
  ${ContentCategory} {
    margin-bottom: ${(p) => p.theme.spacing.tiny};
  }
`;

export class VimeoShareEditorForm extends React.Component {
  onContentRatingSelect = (selectedContentRating) => {
    const {
      setFieldValue,
      values: { contentRating, ratingCategories },
    } = this.props;
    // Update `contentRating` in formik _only_ if the selected option
    // differs from the currently set value, otherwise return early.
    if (selectedContentRating === contentRating) return;

    setFieldValue('contentRating', selectedContentRating);

    // If rating categories were previously selected, reset to an empty array
    // if a content rating of `All audiences` is selected from the dropdown
    if (
      selectedContentRating === CONTENT_RATING.ALL &&
      ratingCategories.length > 0
    ) {
      setFieldValue('ratingCategories', []);
    }

    // Scroll to the very last content category item, and attempt to center
    // it within the scrollable distance. This ensures that most of the
    // Category list will be visible within the modal.
    if (selectedContentRating === CONTENT_RATING.MATURE && this.scrollToRef) {
      setTimeout(
        () => scrollIntoViewIfNeeded(this.scrollToRef, true),
        ANIMATION_DURATION
      );
    }
  };

  onPrivacyViewSelect = ({ key: selectedPrivacyView }) => {
    const {
      setFieldValue,
      values: { privacyView },
    } = this.props;
    // Update `privacyView` in formik _only_ if the selected option
    // differs from the currently set value, otherwise return early.
    if (selectedPrivacyView === privacyView) return;

    setFieldValue('privacyView', selectedPrivacyView);
    // Reset password field if the selected privacy view is
    // _anything other than_ 'password'.
    if (selectedPrivacyView !== 'password') {
      setFieldValue('password', '');
    }
  };

  getPrivacyViews = () => {
    const {
      vimeoUser: { account },
    } = this.props;
    return account === 'basic' ? filter(PRIVACY_VIEW, 'basic') : PRIVACY_VIEW;
  };

  updateRatingCategories = (categoryName, isChecked) => {
    const {
      setFieldValue,
      values: { ratingCategories },
    } = this.props;
    let categories;
    if (isChecked) {
      categories = ratingCategories.concat(categoryName);
    } else {
      categories = ratingCategories.filter(
        (category) => category !== categoryName
      );
    }
    setFieldValue('ratingCategories', categories);
  };

  render() {
    const {
      closeModal,
      handleChange,
      handleSubmit,
      size,
      thumb,
      values: {
        canAddToCollections,
        canDownload,
        contentRating,
        description,
        name,
        password,
        placeholderName,
        privacyView,
        ratingCategories,
        tagString,
      },
      vimeoUser,
    } = this.props;

    const selectedPrivacyView = find(PRIVACY_VIEW, { key: privacyView });
    const showPassword = selectedPrivacyView.key === 'password';

    return (
      <EditorForm>
        <Section>
          <ModalCloseButton
            className="VimeoEditor_CloseModal"
            onClick={closeModal}
            type="button"
          />
          <Heading>{`Publish to ${vimeoUser.name}'s Vimeo`}</Heading>
          <AssetContainer>
            <ThumbContainer backgroundImage={thumb} />
            <AssetInformation>
              <AssetName>{name || placeholderName}</AssetName>
              <AssetSize>{size}</AssetSize>
            </AssetInformation>
          </AssetContainer>
        </Section>
        <ShadowContainer>
          <BodySection>
            <Label>
              <SmallText>Title</SmallText>
              <StyledTextInput
                autoFocus
                name="name"
                onChange={handleChange}
                onFocus={(evt) => evt.target.select()}
                placeholder={placeholderName}
                value={name}
              />
            </Label>
            <Label>
              <SmallText>Description</SmallText>
              <StyledTextInput
                name="description"
                value={description}
                onChange={handleChange}
              />
            </Label>
            <SmallText>Privacy</SmallText>
            <StyledSelect
              name="privacyView"
              options={this.getPrivacyViews()}
              renderSelected={() => (
                <SelectedOption type="button">
                  {selectedPrivacyView.text}
                  <DropArrow />
                </SelectedOption>
              )}
              onSelect={this.onPrivacyViewSelect}
            >
              {(option, isHighlighted) => (
                <Option isHighlighted={isHighlighted}>{option.text}</Option>
              )}
            </StyledSelect>
            <InputContainer isVisible={showPassword}>
              {showPassword ? (
                <Label>
                  <SmallText>Password</SmallText>
                  <Password
                    autoComplete="new-password"
                    name="password"
                    value={password}
                    onChange={handleChange}
                  />
                </Label>
              ) : (
                <InputFieldPlaceholder />
              )}
            </InputContainer>

            <Permissions>
              <SmallText>Permissions</SmallText>
              <SettingSwitchRow>
                <SettingLabel>
                  Can download
                  <SettingSwitch>
                    <Switch
                      isOn={canDownload}
                      name="canDownload"
                      onChange={handleChange}
                      value={canDownload}
                    />
                  </SettingSwitch>
                </SettingLabel>
              </SettingSwitchRow>

              <SettingSwitchRow>
                <SettingLabel>
                  Can add to collections
                  <SettingSwitch>
                    <Switch
                      isOn={canAddToCollections}
                      name="canAddToCollections"
                      onChange={handleChange}
                      value={canAddToCollections}
                    />
                  </SettingSwitch>
                </SettingLabel>
              </SettingSwitchRow>
            </Permissions>

            <Label>
              <SmallText>Tags (separate with commas)</SmallText>
              <StyledTextInput
                name="tagString"
                value={tagString}
                onChange={handleChange}
              />
            </Label>

            <SmallText>Content rating</SmallText>
            <StyledSelect
              smallBottomPadding
              name="contentRating"
              options={[CONTENT_RATING.ALL, CONTENT_RATING.MATURE]}
              renderSelected={() => (
                <SelectedOption type="button">
                  {contentRating}
                  <DropArrow />
                </SelectedOption>
              )}
              onSelect={this.onContentRatingSelect}
            >
              {(option) => <Option>{option}</Option>}
            </StyledSelect>
            <ContentCategories
              isVisible={contentRating === CONTENT_RATING.MATURE}
            >
              <SmallText>
                Please choose at least one content category:
              </SmallText>
              {CONTENT_CATEGORIES.map((category, index) => {
                const { key, text } = category;
                const isChecked = ratingCategories.indexOf(key) > -1;
                const isLastCategory = index === CONTENT_CATEGORIES.length - 1;

                return (
                  <ContentCategory
                    key={key}
                    ref={(ref) => {
                      if (isLastCategory) {
                        this.scrollToRef = ref;
                      }
                    }}
                  >
                    <Checkbox
                      checked={isChecked}
                      onChange={(evt) =>
                        this.updateRatingCategories(key, evt.target.checked)
                      }
                    />
                    {text}
                  </ContentCategory>
                );
              })}
            </ContentCategories>
          </BodySection>
        </ShadowContainer>
        <FooterSection>
          <CancelButton onClick={closeModal} type="button">
            Cancel
          </CancelButton>
          <Button onClick={handleSubmit} primary type="button">
            Publish
          </Button>
        </FooterSection>
      </EditorForm>
    );
  }
}

const formikOptions = {
  mapPropsToValues: ({ description, name, size }) => ({
    canAddToCollections: false,
    canDownload: false,
    contentRating: CONTENT_RATING.ALL,
    description: description || '',
    name,
    password: '',
    placeholderName: name,
    privacyView: PRIVACY_VIEW[0].key,
    ratingCategories: [],
    size,
    tagString: '',
  }),
  handleSubmit: (values, { props }) => {
    const { confirmPublishToVimeo, fileUrl, vimeoUser } = props;
    const {
      canAddToCollections,
      canDownload,
      description,
      name,
      password,
      placeholderName,
      privacyView,
      ratingCategories,
      tagString,
    } = values;

    const content_rating =
      ratingCategories.length > 0 ? ratingCategories : ['safe'];
    const privacy = {
      add: canAddToCollections,
      view: privacyView,
    };
    const tags =
      tagString.length > 0 ? tagString.split(',').map((tag) => tag.trim()) : [];

    // Only set the `privacy.download` param for non-basic accounts as the,
    // Vimeo API has an issue processing this key otherwise (returns 500).
    if (vimeoUser.account !== 'basic') {
      privacy.download = canDownload;
    }

    // Asset POST publish/upload parameters
    const params = {
      link: fileUrl,
      name: name || placeholderName,
      type: 'pull',
    };
    // Asset PATCH metdata
    const metadata = {
      content_rating,
      description: `${description}\n\n\nPublished with https://www.frame.io`,
      password,
      privacy,
    };
    confirmPublishToVimeo({ params, metadata, tags });
  },
};

export default withFormik(formikOptions)(VimeoShareEditorForm);

VimeoShareEditorForm.defaultProps = {
  fileUrl: undefined,
  size: undefined,
  thumb: undefined,
  values: {
    description: undefined,
    name: undefined,
    thumb: undefined,
  },
};

VimeoShareEditorForm.propTypes = {
  // Props from component
  closeModal: PropTypes.func.isRequired,
  confirmPublishToVimeo: PropTypes.func.isRequired,
  fileUrl: PropTypes.string,
  size: PropTypes.string,
  thumb: PropTypes.string,
  vimeoUser: PropTypes.object.isRequired,

  // Props from withFormik
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export const testExports = {
  formikOptions,
  AssetName,
  AssetSize,
  ContentCategories,
  ContentCategory,
  CONTENT_RATING,
  Heading,
  InputContainer,
  InputFieldPlaceholder,
  Option,
  PRIVACY_VIEW,
  Section,
  SmallText,
  StyledSelect,
  StyledTextInput,
  ThumbContainer,
};
