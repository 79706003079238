import { connect } from 'react-redux';
import {
  accountPlanIdSelector,
  accountPlanVersionSelector,
  currentAccountSelector,
  currentAccountWithSubscriptionAndPlanSelector,
  isAccountAdobeBridgeOfferEligible,
  isAccountOnLegacyPlanSelector,
  isAccountOnPlanWithUserMaxSelector,
  isAccountOnV6PlanOrGreater,
} from 'selectors/accounts';
import convertCountdownToDays from 'utils/plans/convertCountdownToDays';
import { v8PricingOnlyAndMigrationFlowsUpdatesEnabled } from 'utils/featureFlags';
import { continueFlow, chatWithOurTeam } from '../actions';
import {
  plansWithDisplaySelector,
  legacyPlansWithDisplaySelector,
  isYearlyPlanForbiddenSelector,
} from '../selectors';
import SelectPlan from './SelectPlan';

const mapDispatchToProps = {
  continueFlow,
  chatWithOurTeam,
};

function mapStateToProps(state) {
  const areV8PricingOnlyAndMigrationFlowsUpdatesEnabled = v8PricingOnlyAndMigrationFlowsUpdatesEnabled(
    state
  );
  const accountId = (currentAccountSelector(state) || {}).id;
  const planId = accountPlanIdSelector(state, { accountId });
  const version = accountPlanVersionSelector(state, { accountId });
  const isLegacy = areV8PricingOnlyAndMigrationFlowsUpdatesEnabled
    ? false
    : isAccountOnLegacyPlanSelector(state, { accountId });
  const isAdobeBridgeOfferEligible = isAccountAdobeBridgeOfferEligible(state, {
    accountId,
  });
  const { plan, subscription } = currentAccountWithSubscriptionAndPlanSelector(
    state
  );
  const countdown = convertCountdownToDays(subscription.promotion_expires_at);
  const plans = isLegacy
    ? legacyPlansWithDisplaySelector(state)
    : plansWithDisplaySelector(state, {
        id: planId,
        isAdobeBridgeOfferEligible,
        version,
      });
  const isAccountOnPlanWithUserMax =
    areV8PricingOnlyAndMigrationFlowsUpdatesEnabled ||
    isAccountOnPlanWithUserMaxSelector(state);
  const isOnV6Plan = areV8PricingOnlyAndMigrationFlowsUpdatesEnabled
    ? false
    : isAccountOnV6PlanOrGreater(state);
  const onTrial = !!subscription.on_trial;

  return {
    currentPlan: plan,
    onTrial,
    plans,
    isLegacy,
    countdown,
    isAdobeBridgeOfferEligible,
    isAccountOnPlanWithUserMax,
    isOnV6Plan,
    isYearlyPlanForbidden: isYearlyPlanForbiddenSelector(state),
  };
}

export const testExports = {
  mapDispatchToProps,
  mapStateToProps,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectPlan);
