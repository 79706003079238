import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AddTeamMembers from 'components/AddTeamMembers';
import Flex from 'styled-flex-component';
import Button from '@frameio/components/src/styled-components/Button';

const InviteButton = styled(Button)`
  padding-left: ${(p) => p.theme.spacing.units(2)};
  padding-right: ${(p) => p.theme.spacing.units(2)};
`;

const AddMembers = ({ onClose, onTeamMembersAdded, teamId, trackingPage }) => (
  <AddTeamMembers
    onClose={onClose}
    onTeamMembersAdded={onTeamMembersAdded}
    teamId={teamId}
    trackingPage={trackingPage}
  >
    {({ handleSubmit, isDisabled }) => (
      <Flex justifyEnd>
        <InviteButton
          primary
          onClick={handleSubmit}
          disabled={isDisabled}
          type="submit"
        >
          Invite
        </InviteButton>
      </Flex>
    )}
  </AddTeamMembers>
);

export default AddMembers;

AddMembers.propTypes = {
  onClose: PropTypes.func.isRequired,
  onTeamMembersAdded: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
  trackingPage: PropTypes.string.isRequired,
};
