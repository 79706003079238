import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rgba, size } from 'polished';
import { alignCenter } from '@frameio/components/src/mixins';
import RawBlogIcon from '@frameio/components/src/svgs/icons/blog-layout.svg';
import RawReelIcon from '@frameio/components/src/svgs/icons/reel-layout.svg';
import Badge from '@frameio/components/src/styled-components/Badge';
import Checkbox from '@frameio/components/src/styled-components/Checkbox';
import ScaleFadeTransition from 'components/ScaleFadeTransition';
import { BLOG_LAYOUT, REEL_LAYOUT } from 'components/Presentation/layouts';
import { Tooltip } from '@frameio/vapor';
import Label from './Label';
import Row from './Row';
import { TRANSITION_DURATION, TRANSITION_TIMING_SETTING } from './transition';

const ICON_WRAPPER_SIZE = '80px';
const RADIO_CIRCLE_SIZE = '16px';
const RADIO_CIRCLE_OFFSET = '-5px';

const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  top: ${RADIO_CIRCLE_OFFSET};
  right: ${RADIO_CIRCLE_OFFSET};
`;

const IconWrapper = styled.div`
  ${alignCenter()};
  ${size(ICON_WRAPPER_SIZE)};
  position: relative;
  box-sizing: border-box;
  border-radius: ${(p) => p.theme.radius.large};
  background-color: ${(p) => p.theme.color.white};
  box-shadow: ${({ checked, theme }) =>
    checked && `0 8px 20px 0 ${rgba(theme.color.black, 0.1)}`};
  border: 1px solid transparent;
  transition-duration: ${TRANSITION_DURATION};
  transition-timing-function: ${TRANSITION_TIMING_SETTING};
  transition-property: box-shadow, border;
`;

const layoutIconStyles = css`
  color: ${({ theme, checked }) =>
    checked ? theme.color.brand : theme.color.lightGray};
  width: 58;
  height: 49;
`;

const ReelIcon = styled(RawReelIcon)`
  ${layoutIconStyles}
`;
const BlogIcon = styled(RawBlogIcon)`
  ${layoutIconStyles}
`;

const RadioRow = styled(Row)`
  margin-bottom: ${(p) => p.theme.spacing.medium};
`;

const Name = styled(Label)`
  margin-top: 5px;
  color: ${(p) => p.theme.color.slateGray};
`;

const RadioLabel = styled.label`
  display: block;
  text-align: center;
  cursor: pointer;

  &:hover ${IconWrapper} {
    border: ${({ checked, theme }) =>
      !checked && `1px solid ${theme.color.silver}`};
  }
`;

const PremiumBadge = styled(Badge)`
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: ${(p) => p.theme.color.lightSuccess};
  color: ${(p) => p.theme.color.success};
`;

const LayoutPicker = ({
  isAdmin = false,
  onChange,
  selectedLayout,
  showPremiumBadge,
}) => {
  const isReel = selectedLayout === REEL_LAYOUT;
  const isBlog = selectedLayout === BLOG_LAYOUT;

  return (
    <RadioRow>
      <RadioLabel checked={isBlog}>
        <IconWrapper checked={isBlog}>
          <ScaleFadeTransition
            appear={false}
            in={isBlog}
            mountOnEnter={false}
            unmountOnExit={false}
            start={[0, 0.2]}
            end={[1, 1]}
          >
            <StyledCheckbox
              checked={isBlog}
              data-test-id="blog"
              name="layout"
              onChange={() => onChange(BLOG_LAYOUT)}
              size={RADIO_CIRCLE_SIZE}
            />
          </ScaleFadeTransition>
          <BlogIcon checked={isBlog} />
        </IconWrapper>
        <Name as="div">Blog</Name>
      </RadioLabel>

      <RadioLabel checked={isReel}>
        <IconWrapper checked={isReel}>
          <ScaleFadeTransition
            appear={false}
            in={isReel}
            mountOnEnter={false}
            unmountOnExit={false}
            start={[0, 0.2]}
            end={[1, 1]}
          >
            <StyledCheckbox
              name="layout"
              data-test-id="reel"
              checked={isReel}
              onChange={() => onChange(REEL_LAYOUT)}
            />
          </ScaleFadeTransition>
          <ReelIcon checked={isReel} />
          {showPremiumBadge && (
            <Tooltip
              title="Available on Pro ✨"
              details={isAdmin && 'Click to learn more'}
              variant="dark"
              shouldUsePortal
            >
              <PremiumBadge>Upgrade</PremiumBadge>
            </Tooltip>
          )}
        </IconWrapper>
        <Name as="div">Reel</Name>
      </RadioLabel>
    </RadioRow>
  );
};

LayoutPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedLayout: PropTypes.oneOf([BLOG_LAYOUT, REEL_LAYOUT]).isRequired,
  showPremiumBadge: PropTypes.bool,
};

LayoutPicker.defaultProps = {
  showPremiumBadge: false,
};

export default React.memo(LayoutPicker);

export const testExports = {
  PremiumBadge,
  ReelIcon,
  BlogIcon,
};
