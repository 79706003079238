// @flow

import type { ToastProps } from '@frameio/components/src/components/Toast';
import type { ToastIconType } from 'components/ToastIcons';
import { iconTypes } from 'components/ToastIcons';
import { generateActionTypes } from '../../shared/actions/helpers';

export const TOASTS = generateActionTypes('TOASTS', ['SHOW', 'REMOVE']);

export type ToastPayload = ToastProps & {
  key?: string,
  iconType?: ToastIconType,
};

/**
 * Creates a new toast object with a given configuration.
 * @param {Object} toast - Toast config object.
 */
export function showToast(payload: ToastPayload) {
  let stringHeader;
  try {
    stringHeader = JSON.stringify(payload.header);
  } catch (e) {
    stringHeader = `${payload.header}`;
  }

  return {
    type: TOASTS.SHOW,
    payload: {
      ...payload,
      key: `${stringHeader}-${Date.now()}`,
    },
  };
}

export function showSuccessToast(payload: ToastPayload) {
  return showToast({
    ...payload,
    iconType: iconTypes.Success,
  });
}

export function showErrorToast(payload: ToastPayload) {
  return showToast({
    ...payload,
    iconType: iconTypes.Error,
  });
}

export function showActivityToast(payload: ToastPayload) {
  return showToast({
    ...payload,
    iconType: iconTypes.Activity,
  });
}

export function showEmailToast(payload: ToastPayload) {
  return showToast({
    ...payload,
    iconType: iconTypes.Email,
  });
}

export function showSlackToast(payload: ToastPayload) {
  return showToast({
    ...payload,
    iconType: iconTypes.Slack,
  });
}

export function showDropboxToast(payload: ToastPayload) {
  return showToast({
    ...payload,
    iconType: iconTypes.Dropbox,
  });
}

export function showConnectedToast(payload: ToastPayload) {
  return showToast({
    ...payload,
    iconType: iconTypes.Connected,
  });
}

export function showDisconnectedToast(payload: ToastPayload) {
  return showToast({
    ...payload,
    iconType: iconTypes.Disconnected,
  });
}

export function removeToastsBy(payload: ToastPayload) {
  return {
    type: TOASTS.REMOVE,
    payload,
  };
}
