import React from 'react';
import styled, { css } from 'styled-components';
import { FadeTransition } from 'react-transition-components';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { rgba } from 'polished';
import { trackButtonClick } from 'analytics';
import { alignCenter, absoluteFill } from '@frameio/components/src/mixins';
import PauseIcon from '@frameio/components/src/svgs/raw/ic-player-pause-16px.svg';
import PlayIcon from '@frameio/components/src/svgs/raw/ic-player-play-16px.svg';
import ProgressCircle from '@frameio/components/src/styled-components/ProgressCircle';
import {
  getMediaType,
  mediaTypes,
} from '@frameio/core/src/assets/helpers/mediaTypes';
import { color } from '@frameio/components/src/theme/darkTheme';
import { AspectRatio } from '@frameio/components';
import { getAssetViewTypes } from '@frameio/components/src/components/CompletePlayer/CompositeView/utils';
import PresentationPlayer from 'components/Presentation/PresentationPlayer';
import {
  getClosestMatchingAssetResolution,
  getSuggestedResolution,
} from 'components/PlayerContainers/utils/videoProxyUtils';
import PreviewUnsupportedIcon from '@frameio/components/src/svgs/illustrations/preview-unsupported.svg'; // needed to import styles of controlbar
import { resolutionToResolutionField } from 'utils/downloads';
import { shouldUseHlsHook } from 'utils/media';
import BundleProvider from '../../BundleProvider/BundleProvider';

const Image = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  margin: 0 auto;
  max-width: ${({ maxWidth }) => maxWidth}px;
  background-image: url(${({ src }) => src});
`;

const Wrapper = styled.div`
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.color.black};
  > div {
    width: 100%; // react-html5video creates an extra div from videoConnect
    height: 100%;
  }
`;

const TransparentWrapper = styled(Wrapper)`
  background-color: transparent;
`;

const StyledPreviewUnsupportedIcon = styled(PreviewUnsupportedIcon).attrs(
  () => ({
    height: '54px',
    width: '48px',
  })
)``;

const PreviewUnsupportedWrapper = styled(Wrapper)`
  ${alignCenter()};
  flex-direction: column;
  background-color: ${({ theme }) => rgba(theme.color.black, 0.04)};
  font-size: ${(p) => p.theme.fontSize[1]};
  font-weight: ${(p) => p.theme.fontWeight.bold};

  ${StyledPreviewUnsupportedIcon} {
    margin-bottom: 30px;
  }
`;

const StyledPresentationPlayer = styled(PresentationPlayer)`
  video {
    display: none;
  }
`;

const ProgressCircleWrapper = styled.span`
  ${alignCenter()};
  top: 0;
  right: 0;
  position: absolute;
  width: 42px;
  height: 42px;
  background-color: ${({ theme }) => rgba(theme.color.almostBlack, 0.9)};
  box-shadow: 0 2px 10px 0 ${({ theme }) => rgba(theme.color.black, 0.2)};
  border-radius: ${(p) => p.theme.radius.circle};

  // give indication circle is clickable to start/stop timer
  &:hover {
    cursor: pointer;
  }
`;

const iconStyles = css`
  ${absoluteFill()};
  margin: auto;
`;

const StyledPauseIcon = styled(PauseIcon).attrs(({ theme }) => ({
  height: 16,
  width: 14,
  color: theme.color.lightGray,
}))`
  ${iconStyles};
`;

const StyledPlayIcon = styled(PlayIcon).attrs(({ theme }) => ({
  height: 17,
  width: 14,
  color: theme.color.lightGray,
}))`
  ${iconStyles};
  left: ${(p) => p.theme.spacing.micro};
`;

export default class AssetViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resolution: getSuggestedResolution(
        props.asset,
        false,
        props.canUse4KPlayback
      ),
      isFullscreen: false,
      selectedViewType: null,
      fwmFrameRateSupported: true,
    };
  }

  componentDidMount() {
    // Set selected veiw type if multiple view types and none selected.
    const { asset } = this.props;
    const viewTypes = getAssetViewTypes(asset);
    if (!this.state.selectedViewType && viewTypes) {
      this.onViewTypeChange('side-by-side');
    }

    document.addEventListener('fullscreenchange', this.onFullscreenChange);
    document.addEventListener(
      'webkitfullscreenchange',
      this.onFullscreenChange
    );
    document.addEventListener('mozfullscreenchange', this.onFullscreenChange);
  }

  componentWillUnmount() {
    document.removeEventListener('fullscreenchange', this.onFullscreenChange);
    document.removeEventListener(
      'webkitfullscreenchange',
      this.onFullscreenChange
    );
    document.removeEventListener(
      'mozfullscreenchange',
      this.onFullscreenChange
    );
  }

  handleHlsError = (error = {}) => {
    if (error.response?.code === 515) {
      this.setState({
        fwmFrameRateSupported: false,
      });
    }
  };

  onFullscreenChange = () => {
    this.setState({
      isFullscreen: !this.state.isFullscreen,
    });
  };

  onViewTypeChange = (selectedViewType) => {
    this.setState({ selectedViewType });
    const { trackingPage } = this.props;
    const trackingTitle = `switched to ${selectedViewType}`;
    trackButtonClick(trackingTitle, trackingPage, 'player controls');
  };

  selectResolution = (resolution) => {
    this.setState({ resolution });
  };

  render() {
    const {
      accentColor,
      accountId,
      asset,
      canUse4KPlayback,
      className,
      isActive,
      isAutoPlayEnabled,
      isPlayerPaused,
      isSessionWatermarked,
      isTimerPaused,
      onPlayButtonClick,
      onVideoEnd,
      shouldAutoLoad,
      syncPlayPause,
      timeRemaining,
      toggleTimerPause,
      trackingPage,
    } = this.props;
    const {
      resolution: selectedResolution,
      selectedViewType,
      isFullscreen,
    } = this.state;

    const { name, image_full: image } = asset;
    const isHlsRequired = shouldUseHlsHook(asset);
    const isForensicallyWatermarked = asset?.is_forensically_watermarked;
    const resolution = getClosestMatchingAssetResolution(
      selectedResolution,
      asset
    );
    const streamSrcUrl =
      asset[
        resolutionToResolutionField[
          selectedResolution && selectedResolution.replace('p', '')
        ]
      ];
    const mediaType = getMediaType(asset);
    const maxWidth = get(asset, 'transcodes.original_width');
    switch (mediaType) {
      case mediaTypes.VIDEO:
        return (
          <BundleProvider
            assetId={asset.id}
            selectedViewType={selectedViewType}
          >
            {({ fullscreenSrc }) => {
              // If asset is a bundle we will provide a full res source from
              // the child asset related to the selectedViewType.
              // This allows us to switch to the full res source when entering fullscreen
              // for a higher quality experience.
              const videoSrc =
                fullscreenSrc && isFullscreen ? fullscreenSrc : streamSrcUrl;
              return (
                <AspectRatio targetAspectRatio={16 / 9} className={className}>
                  <Wrapper>
                    <PresentationPlayer
                      accentColor={accentColor}
                      accountId={accountId}
                      asset={asset}
                      assetLayout={asset.layout}
                      autoPlay={isAutoPlayEnabled}
                      canPlay360={false}
                      canUse4KPlayback={canUse4KPlayback}
                      className="w-100 h-100 relative"
                      fwmFrameRateSupported={this.state.fwmFrameRateSupported}
                      handleError={this.handleHlsError}
                      isActive={isActive}
                      isForensicallyWatermarked={isForensicallyWatermarked}
                      isFullscreen={isFullscreen}
                      isHlsPlayerEnabled={isHlsRequired}
                      isPlayerPaused={isPlayerPaused}
                      isSessionWatermarked={isSessionWatermarked}
                      onEnded={onVideoEnd}
                      onPlayButtonClick={onPlayButtonClick}
                      onViewTypeChange={this.onViewTypeChange}
                      poster={image}
                      preload="metadata"
                      resolution={resolution}
                      selectedViewType={selectedViewType}
                      selectResolution={this.selectResolution}
                      shouldAutoLoad={shouldAutoLoad}
                      src={videoSrc}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      syncPlayPause={syncPlayPause}
                      trackingPage={trackingPage}
                    />
                  </Wrapper>
                </AspectRatio>
              );
            }}
          </BundleProvider>
        );

      case mediaTypes.DOCUMENT:
      case mediaTypes.PDF:
      case mediaTypes.IMAGE:
        return (
          <AspectRatio targetAspectRatio={16 / 9}>
            <TransparentWrapper>
              <Image
                onClick={toggleTimerPause}
                src={image}
                alt={name}
                maxWidth={maxWidth}
              />
              <FadeTransition in={isAutoPlayEnabled}>
                <ProgressCircleWrapper onClick={toggleTimerPause}>
                  {isTimerPaused ? <StyledPlayIcon /> : <StyledPauseIcon />}
                  <ProgressCircle
                    value={timeRemaining}
                    backgroundColor={color.gray}
                    foregroundColor={accentColor}
                    size={34}
                    strokeWidth={2}
                  />
                </ProgressCircleWrapper>
              </FadeTransition>
            </TransparentWrapper>
          </AspectRatio>
        );

      case mediaTypes.AUDIO:
        return (
          <AspectRatio targetAspectRatio={16 / 9}>
            <TransparentWrapper>
              <StyledPresentationPlayer
                syncPlayPause={syncPlayPause}
                isActive={isActive}
                isPlayerPaused={isPlayerPaused}
                preload="auto"
                clickToTogglePause
                isHlsPlayerEnabled={isHlsRequired}
                onEnded={onVideoEnd}
                trackingPage={trackingPage}
                canPlay360={false}
                autoPlay={isAutoPlayEnabled}
                type="audio"
                asset={asset}
                accentColor={accentColor}
                src={streamSrcUrl}
              />
            </TransparentWrapper>
          </AspectRatio>
        );

      default:
        return (
          <AspectRatio targetAspectRatio={16 / 9}>
            <PreviewUnsupportedWrapper onClick={toggleTimerPause}>
              <StyledPreviewUnsupportedIcon />
              Preview unsupported
              {isAutoPlayEnabled && (
                <ProgressCircleWrapper onClick={toggleTimerPause}>
                  {isTimerPaused ? <StyledPlayIcon /> : <StyledPauseIcon />}
                  <ProgressCircle
                    value={timeRemaining}
                    backgroundColor={color.gray}
                    foregroundColor={accentColor}
                    size={34}
                    strokeWidth={2}
                  />
                </ProgressCircleWrapper>
              )}
            </PreviewUnsupportedWrapper>
          </AspectRatio>
        );
    }
  }
}

AssetViewer.defaultProps = {
  className: '',
  onVideoEnd: () => {},
  timeRemaining: 0,
  toggleTimerPause: () => {},
  toggleVideoPause: () => {},
  isPlayerPaused: true,
  isTimerPaused: true,
  isAutoPlayEnabled: false,
  isActive: false,
  layout: undefined,
  accentColor: color.brand,
  syncPlayPause: () => {},
};

AssetViewer.propTypes = {
  accentColor: PropTypes.string,
  accountId: PropTypes.string.isRequired,
  asset: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isAutoPlayEnabled: PropTypes.bool,
  isPlayerPaused: PropTypes.bool,
  isSessionWatermarked: PropTypes.bool,
  isTimerPaused: PropTypes.bool,
  onPlayButtonClick: PropTypes.func.isRequired,
  onVideoEnd: PropTypes.func,
  syncPlayPause: PropTypes.func,
  timeRemaining: PropTypes.number,
  toggleTimerPause: PropTypes.func,
  trackingPage: PropTypes.string.isRequired,
};

export const testExports = {
  Image,
  StyledPresentationPlayer,
  StyledPauseIcon,
  StyledPlayIcon,
  PreviewUnsupportedWrapper,
  ProgressCircleWrapper,
};
