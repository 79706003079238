import styled, { css } from 'styled-components';
import Icon from '@frameio/components/src/svgs/icons/link-indicator.svg';

const TRANSITION_SETTINGS = '0.2s ease-in-out';

const StyledGoToIcon = styled(Icon)`
  margin-left: ${(p) => p.theme.spacing.tiny};
  color: ${({ color }) => color};
  position: relative;
  top: 2px;
`;

// we need to wrap the icons in this container so their sizing does not
// affect the center positioning of the text
const AssetIconContainer = styled.span`
  position: absolute;
`;

const hasAttachedLinkIcon = css`
  transition: color ${TRANSITION_SETTINGS};

  ${StyledGoToIcon} {
    opacity: 0;
    transition: opacity ${TRANSITION_SETTINGS}, color ${TRANSITION_SETTINGS};
  }

  &:hover {
    cursor: pointer;
    color: ${({ accentColor }) => accentColor};

    ${StyledGoToIcon} {
      opacity: 1;
      color: ${({ accentColor }) => accentColor};
    }
  }
`;

export {
  TRANSITION_SETTINGS,
  hasAttachedLinkIcon,
  StyledGoToIcon,
  AssetIconContainer,
};
