import PropTypes from 'prop-types';

const VIEW_TYPES = {
  LIST: 'list',
  GRID: 'grid',
};

export default VIEW_TYPES;

export const propType = PropTypes.oneOf(Object.values(VIEW_TYPES));
