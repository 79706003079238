import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  StatusIndicator,
  StatusIndicatorMask,
  getStatusIndicatorVariant,
  MenuRadio,
  useActiveState,
} from '@frameio/vapor';
import { batchUpdateAssetLabels } from '@frameio/core/src/assets/actions';
import { label as WORKFLOW_STATUSES } from '@frameio/core/src/assets/helpers/constants';
import track from 'analytics';
import { getMediaType } from '@frameio/core/src/assets/helpers/mediaTypes';
import { determinePlayerType } from 'analytics/helpers';
import { WORKFLOW_STATUS_POPOVER_DISPLAYS, WORKFLOW_COLORS } from './constants';

export const StyledStatusIndicator = styled(StatusIndicator)`
  padding: 2px;
  ${StatusIndicatorMask} {
    background-color: ${(p) => (p.isSelected ? '#f1f3f6' : 'white')};
  }
`;

function StatusRadio({ checked, onSelect, children, color = '#666A72' }) {
  const { bind, isActive } = useActiveState();
  return (
    <MenuRadio
      {...bind}
      iconBefore={({ isSelected }) => {
        return (
          <StyledStatusIndicator
            isSelected={isSelected}
            variant={getStatusIndicatorVariant({
              isChecked: checked,
              isHovering: isSelected,
              isActive,
            })}
            color={color}
          />
        );
      }}
      checked={checked}
      onSelect={onSelect}
    >
      {children}
    </MenuRadio>
  );
}

export function Dropdown({
  assets,
  batchUpdateAssetLabels: batchSetStatus,
  tracking: { source },
}) {
  const firstAsset = assets[0];
  const firstAssetStatus = (firstAsset.cover_asset || firstAsset).label;
  const isSameStatus = assets.every(
    ({ cover_asset: coverAsset, label }) =>
      (coverAsset ? coverAsset.label : label) === firstAssetStatus
  );

  // Let's hold the state locally, which allows us to optimistically
  // update our selected state before the popover closes.
  const [selected, setSelected] = useState(firstAssetStatus);

  const dropdownOptions = Object.values(WORKFLOW_STATUSES).map(
    (optionValue) => {
      const isSelected = isSameStatus && optionValue === selected;

      const handleSelect = () => {
        setSelected(optionValue);
        const assetIds = assets.map((asset) => {
          const { id, cover_asset: coverAsset } = asset;
          const playerType = determinePlayerType();
          const trackingProperties = {
            source,
            player_type: playerType,
            media_type: coverAsset
              ? getMediaType(coverAsset)
              : getMediaType(asset),
          };

          if (!playerType) {
            delete trackingProperties.player_type;
          }

          track('status-changed-client', trackingProperties);
          return coverAsset ? coverAsset.id : id;
        });

        batchSetStatus(assetIds, optionValue);
      };

      return (
        <StatusRadio
          key={optionValue}
          checked={isSelected}
          color={WORKFLOW_COLORS[optionValue]}
          onSelect={isSelected ? null : handleSelect}
        >
          {WORKFLOW_STATUS_POPOVER_DISPLAYS[optionValue]}
        </StatusRadio>
      );
    }
  );

  return dropdownOptions;
}

Dropdown.propTypes = {
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.oneOf(Object.values(WORKFLOW_STATUSES)),
      cover_asset: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.oneOf(Object.values(WORKFLOW_STATUSES)),
      }),
    })
  ).isRequired,
  batchUpdateAssetLabels: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  batchUpdateAssetLabels,
};

export default connect(
  null,
  mapDispatchToProps
)(Dropdown);
