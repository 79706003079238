import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import AnimateHeight from '@frameio/components/src/styled-components/AnimateHeight';
import { Transformer } from '@frameio/components';
import track from 'analytics';
import { pageTitle } from 'utils/page';
import ReviewLinkPrompt from '../ReviewLink/ReviewLinkPromptContainer';
import PasswordPromptForm from './PasswordPromptForm';
import {
  TRANSLATE_X,
  PromptContainer,
} from '../ReviewLink/ReviewLinkPromptContainer/promptComponents';

class PasswordPrompt extends React.Component {
  componentDidMount() {
    const { reviewLinkId } = this.props;
    track('review-link-passphrase-modal-shown', {
      review_link_id: reviewLinkId,
    });
  }

  render() {
    const { onPasswordSubmit } = this.props;

    return (
      <ReviewLinkPrompt>
        <Helmet>
          <title>{pageTitle('Passphrase required')}</title>
        </Helmet>
        <PromptContainer>
          <AnimateHeight isVisible duration={400}>
            <Transformer
              isVisible
              outTransform={`translate(-${TRANSLATE_X}, 0px)`}
              inDelay={400}
            >
              <PasswordPromptForm onPasswordSubmit={onPasswordSubmit} />
            </Transformer>
          </AnimateHeight>
        </PromptContainer>
      </ReviewLinkPrompt>
    );
  }
}

PasswordPrompt.propTypes = {
  reviewLinkId: PropTypes.string.isRequired,
  onPasswordSubmit: PropTypes.func.isRequired,
};

export default PasswordPrompt;
