import React from 'react';
import LegacyReviewLinkEditorForm from './LegacyReviewLinkEditorForm';
import ReviewLinkEditor from './ReviewLinkEditor';

const FeatureFlaggedReviewLinkEditor = (props) => {
  if (props.newSharingModalEnabled) {
    return <ReviewLinkEditor {...props} />;
  }

  return <LegacyReviewLinkEditorForm {...props} />;
};

export default FeatureFlaggedReviewLinkEditor;
