import React from 'react';
import { select, takeEvery, put, call } from 'redux-saga/effects';
import { accountEntityForProjectIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';

import { alert } from 'components/Dialog/SimpleDialog/sagas';
import { openModal } from 'components/Modal/actions';
import RequestToJoinTeamCard from 'components/RequestToJoinTeamCard';
import { SHOW_BLOCKED_COLLABORATOR_MESSAGE } from './actions';

function* showBlockedCollaboratorMessage(projectId, trackTrigger) {
  const project = yield select(projectEntitySelector, { projectId });
  const account = yield select(accountEntityForProjectIdSelector, {
    projectId,
  });

  // TODO(marvin): remove legacy fallback when on v2
  let isEnterprise;
  if (account.is_enterprise !== undefined) {
    // legacy
    isEnterprise = account.is_enterprise;
  } else {
    // v2
    ({ enterprise: isEnterprise } = account.plan);
  }

  if (isEnterprise) {
    yield call(
      alert,
      'Collaborators cannot share',
      <p>
        You’re currently a collaborator on <strong>{project.name}</strong>.
        <br />
        Sharing is only available to Team Members.
      </p>,
      { primaryText: 'Dismiss' }
    );
  } else {
    yield put(
      openModal(
        <RequestToJoinTeamCard project={project} trackTrigger={trackTrigger} />,
        { className: 'collaborator-cta-modal' }
      )
    );
  }
}

export default [
  takeEvery(
    SHOW_BLOCKED_COLLABORATOR_MESSAGE,
    ({ payload: { projectId, trackTrigger } }) =>
      showBlockedCollaboratorMessage(projectId, trackTrigger)
  ),
];

export const testExports = { showBlockedCollaboratorMessage };
