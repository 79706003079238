class JTCrossPlatform {

  // Requires Modernizr

  constructor() {
    this.touchSupported = Modernizr.touchevents;

    this.startEvent = this.touchSupported ? 'touchstart' : 'mousedown';
    this.moveEvent = this.touchSupported ? 'touchmove' : 'mousemove';
    this.endEvent = this.touchSupported ? 'touchend' : 'mouseup';

    this.is_mobile = window.matchMedia && window.matchMedia("(max-device-width: 480px)")
      .matches ? true : false;

    this.is_retina = window.matchMedia && window.matchMedia("(-webkit-min-device-pixel-ratio: 2)")
      .matches ? true : false;

    this.css_animations = (Modernizr.cssanimations && Modernizr.csstransforms && Modernizr.csstransitions) && this.touchSupported ? true : false;

    this.css_filters = (Modernizr.cssfilters) ? true : false;
  }
}

window.cross_platform = new JTCrossPlatform();
