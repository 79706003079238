import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import UserPill from '@frameio/components/src/styled-components/UserPill';
import { RemovePill } from '@frameio/components/src/styled-components/Pill';
import { TYPE } from 'components/UserSearch/UserSearch';
import { Tooltip } from '@frameio/vapor';

const bounceInAnimation = keyframes`
  0% { transform: scale(1.0); opacity: 0.7; }
  6% { transform: scale(1.07); opacity: 1; }
  100% { transform: scale(1); }
`;

const StyledUserPill = styled(UserPill)`
  animation: ${bounceInAnimation} 300ms ease-out;
`;

const NewUser = styled.span`
  font-size: ${(p) => p.theme.fontSize[1]};
  color: ${(p) => p.theme.color.success};
  margin-right: ${(p) => p.theme.spacing.micro};
  font-weight: bold;
`;

const DotSeparator = styled.span`
  color: ${(p) => p.theme.color.slateGray};
  font-size: ${(p) => p.theme.fontSize[1]};
  margin-right: ${(p) => p.theme.spacing.micro};
`;

export const styledClassName = css`
  ${StyledUserPill}
`[0];

export default function UserToken({ user, type, onRemove, ...rest }) {
  if (!user) return null;
  const { email } = user;
  const isNewUser = type === TYPE.EMAIL;

  return (
    <Tooltip title={email}>
      <StyledUserPill tabIndex="0" user={user} {...rest}>
        {isNewUser && (
          <React.Fragment>
            <DotSeparator>&#183;</DotSeparator>
            <NewUser>new</NewUser>
          </React.Fragment>
        )}
        <RemovePill tabIndex="-1" onClick={onRemove} />
      </StyledUserPill>
    </Tooltip>
  );
}

UserToken.propTypes = {
  // onRemove invokes the given function when the cancel button is clicked
  onRemove: PropTypes.func,
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
  type: PropTypes.string,
};

UserToken.defaultProps = {
  user: undefined,
  onRemove: () => {},
  type: '',
};

export const testExports = { StyledUserPill, NewUser };
