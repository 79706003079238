import React from 'react';
import V4MigrationModal from './V4MigrationModal';
import V4BetaMigrationModal from './V4BetaMigrationModal';
import V4MigrationModalVariantA from './V4MigrationModalVariantA';

const MigrationModal = ({
  isV4MigrationModalEnabled,
  isV4MigrationModalVariantAEnabled,
  ...props
}) => {
  if (isV4MigrationModalEnabled) {
    return isV4MigrationModalVariantAEnabled ? (
      <V4MigrationModalVariantA {...props} />
    ) : (
      <V4MigrationModal {...props} />
    );
  }

  return <V4BetaMigrationModal {...props} />;
};

export default MigrationModal;
