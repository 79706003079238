import { cancel, fork, select, take, takeLatest } from 'redux-saga/effects';
import { MODAL } from 'components/Modal/actions';
import { isAccountAdminSelector } from 'selectors/roles';
import { isAccountFreeSelector } from 'selectors/accounts';
import { ARCHIVED_STORAGE_LIMIT } from '@frameio/core/src/subscriptionLineItems/utils/modifierTypes';
import { STORAGE_LIMIT_REACHED_FLOW } from './actions';
import history from './history';
import {
  OUT_OF_SPACE_URL,
  ADD_STORAGE_FLOW_URL,
  SELECT_PLAN_FLOW_URL,
  OUT_OF_ARCHIVED_SPACE_URL,
  ADD_ARCHIVED_STORAGE_FLOW_URL,
} from './StorageLimitReachedFlow';

function* watchStorageLimitReachedFlow(accountId, type) {
  // Step 1: Show limit reached info screen
  if (type === ARCHIVED_STORAGE_LIMIT) history.push(OUT_OF_ARCHIVED_SPACE_URL);
  else history.push(OUT_OF_SPACE_URL);

  // Step 2: User has decided to proceed with the upgrade
  yield take(STORAGE_LIMIT_REACHED_FLOW.CONTINUE);

  const isAccountAdmin = yield select(isAccountAdminSelector, { accountId });
  const isAccountFree = yield select(isAccountFreeSelector, { accountId });

  // if trying to archive project, trigger add archive storage flow
  if (type === ARCHIVED_STORAGE_LIMIT) {
    history.replace(ADD_ARCHIVED_STORAGE_FLOW_URL);
    return;
  }

  if (isAccountAdmin && isAccountFree) {
    history.replace(SELECT_PLAN_FLOW_URL);
  } else if (isAccountAdmin && !isAccountFree) {
    history.replace(ADD_STORAGE_FLOW_URL);
  } else {
    history.replace(OUT_OF_SPACE_URL);
  }
}

// https://redux-saga.js.org/docs/advanced/TaskCancellation.html
export function* startStorageLimitReachedFlow(accountId, type) {
  const flow = yield fork(watchStorageLimitReachedFlow, accountId, type);
  yield take([MODAL.CLOSE, STORAGE_LIMIT_REACHED_FLOW.END]);
  yield cancel(flow);
}

export default [
  takeLatest(
    STORAGE_LIMIT_REACHED_FLOW.START,
    ({ payload: { accountId, type } }) =>
      startStorageLimitReachedFlow(accountId, type)
  ),
];
