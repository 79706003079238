import { combineReducers } from 'redux';
import {
  generatePaginatedListReducer,
  INITIAL_PAGINATED_LIST_STATE,
} from '@frameio/core/src/shared/reducers/factories';
import { INBOX } from './actions';

export const INITIAL_STATE = {
  reviewers: { ...INITIAL_PAGINATED_LIST_STATE },
  totalUnseenReviews: 0,
};

const reviewersListReducer = generatePaginatedListReducer(
  INBOX.FETCH_REVIEWERS
);

function totalUnseenReviews(state = 0, { type, payload }) {
  if (type === INBOX.FETCH_UNSEEN_REVIEWS_TOTAL.SUCCESS) {
    return payload.response.total;
  }

  return state;
}

export default combineReducers({
  reviewers: reviewersListReducer,
  totalUnseenReviews,
});
