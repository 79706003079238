import styled, { css } from 'styled-components';
import Flex from 'styled-flex-component';
import ConfirmRemovePopover from 'components/ConfirmPopover';
import Button from '@frameio/components/src/styled-components/Button';

import { ROW_HEIGHT } from 'components/PeopleList/PeopleList';
import { MEDIUM_UP } from 'utils/mediaQueries';
import { RoleButton, DisabledRole as DisabledRoleButton } from './RolePopover';

export const StyledRemovePopover = styled(ConfirmRemovePopover)``;

export const RemoveButton = styled(Button).attrs(() => ({
  compact: true,
  text: true,
  icon: 'true',
}))`
  opacity: 0.5;

  &:hover:not(:active):not(:disabled) {
    background-color: transparent;
    opacity: 1;
  }
`;

export const MobileRemoveButton = styled(Button).attrs(() => ({
  destructive: true,
}))`
  border-radius: 0;
  height: ${ROW_HEIGHT}px;
  min-width: 0;
  overflow: hidden;
  padding: 0;
`;

export const fadeTransition = css`
  transition: opacity 0.1s ease-out;
`;

export const Wrapper = styled(Flex).attrs(() => ({
  full: true,
  alignCenter: true,
}))`
  position: relative;

  ${RoleButton},
  ${DisabledRoleButton} {
    flex-shrink: 0;
  }

  ${RoleButton} {
    ${fadeTransition}
    opacity: 0.5;

    svg {
      opacity: 0;
    }
  }

  ${MobileRemoveButton} {
    position: absolute;
    right: -${(p) => p.theme.spacing.medium};
  }

  @media ${MEDIUM_UP} {
    ${StyledRemovePopover} {
      ${fadeTransition}
      opacity: 0;
    }

    &:hover {
      ${RoleButton},
      ${RoleButton} svg,
      ${StyledRemovePopover} {
        opacity: 1;
      }
    }
  }
`;
