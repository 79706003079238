import { connect } from 'react-redux';
import { openManageVersionStackInModal as openManageVersionStack } from '../../../pages/ProjectContainer/ManageVersionStackModal/actions';
import VersionBadge from './VersionBadge';

const mapDispatchToProps = { onClick: openManageVersionStack };

export default connect(
  null,
  mapDispatchToProps
)(VersionBadge);

export const testExports = { mapDispatchToProps };
