/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { noop } from 'lodash';
import Dialog from '@frameio/components/src/styled-components/Dialog';
import Input from '@frameio/components/src/styled-components/TextInput';
import TextArea from '@frameio/components/src/styled-components/TextArea';
import { SelectDropdown, SelectOption } from '@frameio/components';
import DialogModal from '../DialogModal';

const FormGroup = styled.fieldset`
  border: none;
  padding: 0;
  margin-top: 20px;
`;

const FormLabel = styled.label`
  display: block;
  font-size: 14px;
  color: #a3a6a8;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const Description = styled.p`
  white-space: pre-line;
`;

const SelectOptionName = styled.div`
  height: 45px;
  padding: ${(p) => p.theme.spacing.small};
`;

const StyledDialogModal = styled(DialogModal)`
  overflow: auto;
`;

const fieldType = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  SELECT: 'select',
};

class CustomActionFormDialog extends React.Component {
  static getDerivedStateFromProps(props, prevState) {
    // Evaluate the provided form fields to set initial values.
    // Mark the form as open.
    if (props.isOpen && !prevState.isOpen) {
      const { fields } = props;

      return fields.reduce(
        (result, field) => {
          result.fields[field.name] = field.value;
          return result;
        },
        { isOpen: true, fields: [] }
      );
    }

    return null;
  }

  state = {
    fields: [],
    isOpen: false,
  };

  onValueChange = (field, value) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [field]: value,
      },
    });
  };

  onCancel = () => {
    this.setState({ isOpen: false });
    this.props.respond(false);
  };

  onSubmit = () => {
    const { respond, fields } = this.props;
    let response = true;

    if (fields.length > 0) {
      response = fields.reduce((result, field) => {
        result[field.name] = this.state.fields[field.name];
        return result;
      }, {});
    }

    this.setState({ isOpen: false });
    respond(response);
  };

  renderTextInput = (field) => {
    const {
      fields: { [field.name]: text },
    } = this.state;

    return (
      <Input
        id={field.name}
        name={field.name}
        placeholder={field.placeholder || ''}
        value={text === undefined ? field.value : text}
        onChange={(e) => this.onValueChange(field.name, e.target.value)}
      />
    );
  };

  renderTextArea = (field) => {
    const {
      fields: { [field.name]: text },
    } = this.state;

    return (
      <TextArea
        id={field.name}
        name={field.name}
        placeholder={field.placeholder || ''}
        value={text === undefined ? field.value : text}
        onChange={(e) => this.onValueChange(field.name, e.target.value)}
      />
    );
  };

  renderSelectInput = (field) => {
    const {
      fields: { [field.name]: value },
    } = this.state;
    let text = '';
    if (value) {
      text = field.options.find((option) => option.value === value).name;
    }

    return (
      <SelectDropdown
        selectedValue={value || ''}
        selectedText={text}
        onClick={(option) => this.onValueChange(field.name, option.value)}
      >
        {field.options &&
          field.options.map((option) => (
            <SelectOption
              key={option.name}
              text={option.name}
              value={option.value}
            >
              <SelectOptionName>{option.name}</SelectOptionName>
            </SelectOption>
          ))}
      </SelectDropdown>
    );
  };

  render() {
    const { isOpen, header, body, fields } = this.props;
    const hasForm = fields.length > 0;

    return (
      <StyledDialogModal isOpen={isOpen} onCancel={this.onCancel}>
        <Dialog
          header={header}
          primaryButton={React.cloneElement(
            Dialog.primaryButton,
            {
              onClick: this.onSubmit,
            },
            hasForm ? 'Submit' : 'OK'
          )}
          secondaryButton={
            hasForm &&
            React.cloneElement(
              Dialog.secondaryButton,
              {
                onClick: this.onCancel,
              },
              'Cancel'
            )
          }
        >
          <Description>{body}</Description>
          {hasForm &&
            fields.map((field) => {
              let el;
              switch (field.type) {
                case fieldType.TEXT:
                  el = this.renderTextInput(field);
                  break;
                case fieldType.TEXTAREA:
                  el = this.renderTextArea(field);
                  break;
                case fieldType.SELECT:
                  el = this.renderSelectInput(field);
                  break;
                default:
                  el = null;
                  break;
              }
              return (
                <FormGroup key={field.name}>
                  <FormLabel>
                    {field.label || field.name}
                    {el}
                  </FormLabel>
                </FormGroup>
              );
            })}
        </Dialog>
      </StyledDialogModal>
    );
  }
}

CustomActionFormDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  respond: PropTypes.func,
  header: PropTypes.string,
  body: PropTypes.string,
  fields: PropTypes.array.isRequired,
};

CustomActionFormDialog.defaultProps = {
  respond: noop,
  header: 'Success!',
  body: '',
  fields: [],
};

export default CustomActionFormDialog;

export const testExports = {
  FormGroup,
};
